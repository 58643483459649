import firebase from '../firebase';

class Multifactor {
  constructor(recaptchaVerifier, error) {
    this.isLogin = !!error;
    this.resolver = this.isLogin ? firebase.getMfaResolver(error) : firebase.getMfaUser();
    this.hints = this.resolver.hints;
    this.verifier = recaptchaVerifier;
    this.verificationId = null;
    this.onFulfilled = () => {};
    this.onRejected = () => {};
  }

  async sendSMS(multiFactorPhone) {
    if (!this.isLogin || multiFactorPhone.factorId === firebase.factorId) {
      let phoneInfoOptions;
      if (this.isLogin) {
        phoneInfoOptions = {
          multiFactorHint: multiFactorPhone,
          session: this.resolver.session,
        };
      } else {
        phoneInfoOptions = {
          phoneNumber: multiFactorPhone,
          session: await this.resolver.getSession(),
        };
      }
      return firebase.sendPhoneCode(phoneInfoOptions, this.verifier)
        .then((id) => {
          this.verificationId = id;
          return id;
        })
        .catch((error) => {
          this.verificationId = null;
          this.onRejected(error);
          return null;
        });
    }
    this.verificationId = null;
    return null;
  }

  async resolveCode(verificationCode, format) {
    if (this.isLogin) {
      this.resolver.resolveSignIn(
        await firebase.verifyPhoneCode(this.verificationId, verificationCode),
      ).then((loginStatus) => {
        this.onFulfilled(loginStatus);
      }).catch((error) => {
        this.onRejected(error);
      });
    } else {
      firebase.getMfaUser().enroll(
        await firebase.verifyPhoneCode(this.verificationId, verificationCode),
        format,
      ).then((loginStatus) => {
        this.onFulfilled(loginStatus);
      }).catch((error) => {
        this.onRejected(error);
      });
    }
  }

  success(onFulfilled) {
    this.onFulfilled = onFulfilled;
    return this;
  }

  error(onRejected) {
    this.onRejected = onRejected;
    return this;
  }
}

export default Multifactor;
