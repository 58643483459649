import React from 'react';

const TemplateUse = () => {
  return (
    <svg width="17" height="22" viewBox="0 0 17 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.4375 2.75H11C11 1.2332 9.7668 0 8.25 0C6.7332 0 5.5 1.2332 5.5 2.75H2.0625C0.923828 2.75 0 3.67383 0 4.8125V19.9375C0 21.0762 0.923828 22 2.0625 22H14.4375C15.5762 22 16.5 21.0762 16.5 19.9375V4.8125C16.5 3.67383 15.5762 2.75 14.4375 2.75ZM8.25 1.71875C8.82148 1.71875 9.28125 2.17852 9.28125 2.75C9.28125 3.32148 8.82148 3.78125 8.25 3.78125C7.67852 3.78125 7.21875 3.32148 7.21875 2.75C7.21875 2.17852 7.67852 1.71875 8.25 1.71875ZM14.4375 19.6797C14.4375 19.8215 14.3215 19.9375 14.1797 19.9375H2.32031C2.17852 19.9375 2.0625 19.8215 2.0625 19.6797V5.07031C2.0625 4.92852 2.17852 4.8125 2.32031 4.8125H4.125V6.35938C4.125 6.64297 4.35703 6.875 4.64063 6.875H11.8594C12.143 6.875 12.375 6.64297 12.375 6.35938V4.8125H14.1797C14.3215 4.8125 14.4375 4.92852 14.4375 5.07031V19.6797Z" />
      <path d="M8.53423 8V12.9815C8.53423 13.2868 8.28678 13.5342 7.98147 13.5342H3C3.10352 14.4736 3.47035 15.3646 4.05823 16.1046C4.64611 16.8445 5.4311 17.4033 6.32275 17.7165C7.21439 18.0298 8.17639 18.0847 9.09788 17.8749C10.0194 17.6652 10.8629 17.1994 11.5311 16.5311C12.1994 15.8629 12.6652 15.0194 12.8749 14.0979C13.0847 13.1764 13.0298 12.2144 12.7165 11.3227C12.4033 10.4311 11.8445 9.64611 11.1046 9.05823C10.3646 8.47035 9.4736 8.10352 8.53423 8ZM7.43201 8C6.29965 8.12532 5.24373 8.63254 4.43813 9.43813C3.63254 10.2437 3.12532 11.2997 3 12.432H7.43201V8Z" />
    </svg>
  );
};

// ### Old TemplateUse ###
// function TemplateUse() {
//   return (
//     <svg width="22" height="22" viewBox="0 0 22 22" fill="white" xmlns="http://www.w3.org/2000/svg">
//       <path d="M9.5 6.15601H5V9.15601H9.5V6.15601Z" />
//       <path d="M0.5 4.65625C0.5 3.46278 0.974106 2.31818 1.81802 1.47427C2.66193 0.630356 3.80653 0.15625 5 0.15625H14V21.1562H5C3.80653 21.1562 2.66193 20.6821 1.81802 19.8382C0.974106 18.9943 0.5 17.8497 0.5 16.6562V4.65625ZM3.5 6.15625V9.15625C3.5 9.55407 3.65804 9.93561 3.93934 10.2169C4.22064 10.4982 4.60218 10.6562 5 10.6562H9.5C9.89782 10.6562 10.2794 10.4982 10.5607 10.2169C10.842 9.93561 11 9.55407 11 9.15625V6.15625C11 5.75843 10.842 5.37689 10.5607 5.09559C10.2794 4.81429 9.89782 4.65625 9.5 4.65625H5C4.60218 4.65625 4.22064 4.81429 3.93934 5.09559C3.65804 5.37689 3.5 5.75843 3.5 6.15625ZM4.25 12.1562C4.05109 12.1562 3.86032 12.2353 3.71967 12.3759C3.57902 12.5166 3.5 12.7073 3.5 12.9062C3.5 13.1052 3.57902 13.2959 3.71967 13.4366C3.86032 13.5772 4.05109 13.6562 4.25 13.6562H10.25C10.4489 13.6562 10.6397 13.5772 10.7803 13.4366C10.921 13.2959 11 13.1052 11 12.9062C11 12.7073 10.921 12.5166 10.7803 12.3759C10.6397 12.2353 10.4489 12.1562 10.25 12.1562H4.25ZM3.5 15.9062C3.5 16.1052 3.57902 16.2959 3.71967 16.4366C3.86032 16.5772 4.05109 16.6562 4.25 16.6562H10.25C10.4489 16.6562 10.6397 16.5772 10.7803 16.4366C10.921 16.2959 11 16.1052 11 15.9062C11 15.7073 10.921 15.5166 10.7803 15.3759C10.6397 15.2353 10.4489 15.1562 10.25 15.1562H4.25C4.05109 15.1562 3.86032 15.2353 3.71967 15.3759C3.57902 15.5166 3.5 15.7073 3.5 15.9062Z" />
//       <path d="M15.5 21.1562H17C18.1935 21.1562 19.3381 20.6821 20.182 19.8382C21.0259 18.9943 21.5 17.8497 21.5 16.6562V15.1562H15.5V21.1562Z" />
//       <path d="M21.5 13.6562V7.65625H15.5V13.6562H21.5Z" />
//       <path d="M21.5 6.15625V4.65625C21.5 3.46278 21.0259 2.31818 20.182 1.47427C19.3381 0.630356 18.1935 0.15625 17 0.15625H15.5V6.15625H21.5Z" />
//     </svg>

//   );
// }

export default TemplateUse;
