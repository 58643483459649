import React from 'react';

function LineColor() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <path d="M4.999 19v2h-4.999v-2h4.999zm2 2l5.827-1.319-4.403-4.4-1.424 5.719zm17.001-12.466l-9.689 9.804-4.537-4.536 9.69-9.802 4.536 4.534zm-4.48-1.65l-6.916 6.917.707.707 6.916-6.917-.707-.707z" />
    </svg>
  );
}

export default LineColor;
