import React, {
  useCallback, useEffect, useRef,
} from 'react';
import PropTypes from 'prop-types';
import Avatar from '../../juristec-ui/core/Avatar';
import IconButton from '../../juristec-ui/core/IconButton';
import Popover from '../../juristec-ui/core/Popover';
import List from '../../juristec-ui/core/List';
import ListItem from '../../juristec-ui/core/ListItem';
import MuralInput from './MuralInput';

import useToggleState from '../../juristec-ui/hooks/useToggleState';
import { formatDateTime } from '../../juristec-ui/utils/functions/lab';

import { MoreVert } from '../../juristec-ui/icons';

import {
  UserMsg,
  UserMsgContent,
  UserMsgEdit,
} from './styled/Mural.styled';

const EditableMsg = ({
  comment,
  canEdit,
  canDelete,
  archiveComment,
  editComment,
  userAuthor,
  users,
  kpis,
}) => {
  const formattedText = useRef('');
  const msgRef = useRef();
  const didMount = useRef(false);

  const [showOptions, toggleShowOptions, closeShowOptions] = useToggleState(false);
  const [edit, toggleEdit, closeEdit] = useToggleState(false);

  /**
   * Sets a message to be archived (removed)
   * @param {Event} e DOM onClick event
   */
  const handleArchive = (e) => {
    e.stopPropagation();
    archiveComment(comment.id);
  };

  /* Scrolls to message (fixes weird drifting behavior) */
  useEffect(() => {
    if (didMount?.current) {
      msgRef.current.scrollIntoView({ block: 'center', behavior: 'smooth' });
    } else {
      didMount.current = true;
    }
  }, [edit]);

  /**
   * Formats the comment mentions
   * @returns {array} Array with formatted elements
   */
  const formatComment = useCallback(() => {
    const msgRawArray = [];
    const msgJsxArray = comment.text.split(' ').reduce((aux, w) => {
      if (w) {
        const regRes = /([#|@])(\w+)/gi.exec(w);
        if (regRes) {
          if (regRes[1] === '@' && comment.mentions?.users?.includes(regRes[2])) {
            const uName = users.find((u) => u.uid === regRes[2])?.name || '[Usuário Removido]';
            aux.push(<span key={regRes[2]} className="highlight mention">{` @${uName}`}</span>);
            msgRawArray.push(`@${uName.replace(/ /g, '_')}`);
          } else if (regRes[1] === '#' && comment.mentions?.kpis?.includes(regRes[2])) {
            const kName = kpis.find((k) => k.id === regRes[2])?.name || '[Kpi Removido]';
            aux.push(<span key={regRes[2]} className="highlight mention">{` #${kName}`}</span>);
            msgRawArray.push(`#${kName.replace(/ /g, '_')}`);
          } else {
            aux.push(` ${w}`);
            msgRawArray.push(`${w}`);
          }
        } else {
          aux.push(` ${w}`);
          msgRawArray.push(`${w}`);
        }
      }
      return aux;
    }, []);
    formattedText.current = msgRawArray.join(' ');
    return msgJsxArray;
  }, [users, kpis, comment]);

  /**
   * Sends the edited message
   * @param {string} txt edited message
   * @param {object} mentions mentions in the message
   */
  const handleEdit = (txt, mentions) => {
    editComment(txt, mentions, comment.id);
    closeEdit();
  };

  return (
    edit ? (
      <UserMsgEdit ref={msgRef}>
        <MuralInput
          kpis={kpis}
          users={users}
          submitEdit={handleEdit}
          cancelEdit={closeEdit}
          messageToEdit={formattedText.current || ''}
          isEdit
        />
      </UserMsgEdit>
    ) : (
      <UserMsg ref={msgRef}>
        <Avatar size="large" name={userAuthor.name} src={userAuthor.photoUrl} />
        <UserMsgContent>
          <div className="header">
            <span>
              {`${userAuthor.name}:`}
              {comment.edited && (
                <span className="detail"> (editado)</span>
              )}
            </span>
            {(canEdit || canDelete) && (
              <Popover
                open={showOptions}
                closePopover={closeShowOptions}
                offset={[0, 2]}
              >
                <Popover.Action>
                  <IconButton size="small" onClick={toggleShowOptions}>
                    <MoreVert />
                  </IconButton>
                </Popover.Action>
                <Popover.Content style={{ backgroundColor: 'white', boxShadow: '0px -1px 4px #9a9a9a' }}>
                  <List style={{ fontSize: '14px' }}>
                    {canEdit && (
                      <ListItem noOutline onClick={toggleEdit}>
                        Editar
                      </ListItem>
                    )}
                    {canDelete && (
                      <ListItem noOutline onClick={handleArchive}>
                        Remover
                      </ListItem>
                    )}
                  </List>
                </Popover.Content>
              </Popover>
            )}
          </div>
          <span className="content">{formatComment()}</span>
          <span className="timestamp">{formatDateTime(comment.timestamp, { time: 'half' })}</span>
        </UserMsgContent>
      </UserMsg>
    )
  );
};

EditableMsg.propTypes = {
  comment: PropTypes.shape({
    id: PropTypes.string,
    timestamp: PropTypes.number,
    edited: PropTypes.bool,
    mentions: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
    text: PropTypes.string,
  }).isRequired,
  canEdit: PropTypes.bool,
  canDelete: PropTypes.bool,
  archiveComment: PropTypes.func,
  editComment: PropTypes.func,
  userAuthor: PropTypes.shape({
    name: PropTypes.string,
    photoUrl: PropTypes.string,
  }).isRequired,
  users: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  kpis: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
};

EditableMsg.defaultProps = {
  canEdit: false,
  canDelete: false,
  archiveComment: () => {},
  editComment: () => {},
  users: [],
  kpis: [],
};

export default EditableMsg;
