import React from 'react';
import PropTypes from 'prop-types';

// import { Container } from './styles';

const Antecipei = ({ width, height }) => (
  <svg width={width} height={height} viewBox="0 0 45 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_4633_88)">
      <path d="M19.1699 50L9.16992 40L24.1699 25L9.16992 10L19.1699 0L44.1699 25L19.1699 50Z" />
      <path d="M5 37.5L0 32.5L7.5 25L0 17.5L5 12.5L17.5 25L5 37.5Z" />
    </g>
    <defs>
      <clipPath id="clip0_4633_88">
        <rect width="44.17" height="50" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

Antecipei.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

Antecipei.defaultProps = {
  width: '25px',
  height: '25px',
};

export default Antecipei;
