import { useCallback, useMemo, useReducer } from 'react';

import { getRefSummaries } from '../utils/firestore';
import { error as errorLabel } from '../label';

const initialState = {
  summaries: null,
  endpoint: '',
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'setSupportInfo':
      return {
        ...state,
        summaries: action.summaries,
        endpoint: action.endpoint,
      };
    default:
      return state;
  }
};

function useJiraHelpDesk(currentUser) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const newTicket = useCallback(async (message, summary, endpoint) => {
    try {
      const opt = {
        method: 'POST',
        Accept: 'application/json',
        'Content-Type': 'application/json',
        mode: 'no-cors',
        cache: 'no-cache',
        body: JSON.stringify({
          message,
          summary,
          user_mail: currentUser.email,
          user_name: currentUser.displayName,
        }),
      };
      await fetch(
        `https://automation.atlassian.com/pro/hooks/${endpoint}`, opt,
      );

      return { error: false, msg: '' };
    } catch (er) {
      console.log(er);
      return { error: true, msg: errorLabel.useJiraHelpDesk.newTicket, raw: `Erro do sistema: ${er.toString()}` };
    }
  }, [currentUser]);

  const getSupportInfo = useCallback(async () => {
    try {
      const ref = getRefSummaries();
      const tmp = await ref.get();
      const data = tmp.data();
      return {
        error: false,
        msg: '',
        summaries: data?.summaries || [],
        endpoint: data?.endpoint || '',
      };
    } catch (er) {
      console.log(er);
      return { error: true, msg: errorLabel.useJiraHelpDesk.getSupportInfo, raw: `Erro do sistema: ${er.toString()}` };
    }
  }, [currentUser]);

  const middleware = useCallback(async (action) => {
    switch (action.type) {
      case 'newTicket': {
        return newTicket(action.message, action.summary, action.endpoint);
      }
      case 'getSupportInfo': {
        const res = await getSupportInfo();
        if (!res.error) {
          dispatch({
            type: 'setSupportInfo',
            summaries: res.summaries,
            endpoint: res.endpoint,
          });
        }
        return res;
      }
      default:
        return { error: false, msg: '', raw: '' };
    }
  }, [currentUser.uid, newTicket, getSupportInfo]);

  const supportAPI = useMemo(() => ({
    newTicket: async (message, summary, endpoint) => middleware({
      type: 'newTicket', message, summary, endpoint,
    }),
    getSupportInfo: async () => middleware({ type: 'getSupportInfo' }),
  }), [middleware]);

  return [state, supportAPI];
}

export default useJiraHelpDesk;
