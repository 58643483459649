import React, {
  useContext, useState, useEffect,
} from 'react';

import { useParams, useHistory } from 'react-router-dom';
import urlHash from '../../utils/functions/urlHash';
import { BigDataContext } from '../../context/BigDataProvider';
import { AlertContext } from '../../context/AlertProvider';
import { ModalContext } from '../../context/ModalProvider';

import Loader from '../../juristec-ui/core/Loader';
import ProcessToolbar from '../../components/Toolbars/ProcessToolbar';
import ProcessInfo from '../../components/ProcessInfo';
import PartInfo from '../../components/Modals/PartInfo';

const ProcessPage = () => {
  const { state, bigDataAPI } = useContext(BigDataContext);
  const { setAlertConfig } = useContext(AlertContext);
  const { setModalConfig } = useContext(ModalContext);

  const { hash } = useParams();
  const history = useHistory();

  const [processData, setProcessData] = useState({});
  const [tab, setTab] = useState('DATA');

  useEffect(() => {
    const getParams = () => {
      const str = urlHash(hash, true).split('/');
      // return ['0000479-60.2011.5.04.0231'];
      // return ['0000058-20.2019.5.09.0015'];
      // return ['0000006-42.2019.5.09.0009', 'test'];
      return str;
    };
    const getProcess = async () => {
      const [cnj, blockId] = getParams();
      const res = await bigDataAPI.getProcess(cnj);
      if (res.error) {
        setAlertConfig({
          type: 'error',
          text: res.msg,
          child: res.raw,
        });
        return;
      }
      setProcessData({
        ...res.data,
        defaultBlockId: blockId,
      });
    };
    getProcess();
  }, []);

  const goBack = () => {
    history.goBack();
  };

  const handleGetBlock = async (cnj, blockId) => {
    const res = await bigDataAPI.getBlock(cnj, blockId);
    if (res.error) {
      setAlertConfig({
        type: 'error',
        text: res.msg,
        child: res.raw,
      });
      return null;
    }
    return res.data;
  };

  const morePartInfo = (partObj) => {
    setModalConfig({
      title: 'Informações do Polo',
      yesLabel: 'Ok',
      children: (
        <PartInfo
          info={partObj}
        />
      ),
    });
  };

  return (
    <>
      {state.isLoading && (
        <Loader />
      )}
      <ProcessToolbar
        cnj={processData?.cnj || ''}
        goBack={goBack}
        tabState={[tab, setTab]}
        hasBlocks={processData?.bloco?.length > 0}
      />
      <ProcessInfo
        processData={processData || {}}
        getBlockTxt={handleGetBlock}
        tab={tab}
        morePartInfo={morePartInfo}
      />
    </>
  );
};

export default ProcessPage;
