import styled from 'styled-components';

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 700px;
  height: calc(100vh - 350px);
  & input, textarea {
    font-size: 14px !important;
  }
  & > div + div {
    margin-top: .5rem;
  }
`;

export const InputGrid = styled.div`
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(2, 1fr);
  background-color: ${({ theme }) => theme.containerOdd};
  border-radius: 10px;
  padding: 0 1rem 1.5rem 1rem;
  margin-top: .5rem;
  max-height: calc(100vh - 420px);
  overflow: auto;
`;

export const VarTip = styled.span`
  color: ${({ theme }) => theme.lightgrey};
  font-size: 14px;
`;
