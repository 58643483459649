import styled, { css } from 'styled-components';

const verifyAction = ({ $action }) => {
  if ($action === 'delete' || $action === 'remove') return 'default';
  return 'pointer';
};

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const MainContainer = styled(Column)`
  padding: 0.5rem;
  & h2 {
    color: ${({ theme }) => theme.primary};
    text-align: center;
    margin: 0;
    font-size: 20px;
  }
  & button {
    margin: auto;
  }
`;

export const Board = styled(Column)`
  max-height: 50vh;
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 1rem;
`;

export const NotificationCard = styled(Column)`
  margin: 5px 1rem;
  background-color: ${({ theme }) => theme.container};
  border-radius: 5px;
  max-width: 270px;
  min-width: 270px;
  white-space: break-spaces;
  padding: 0.9rem 10px 0.5rem;
  position: relative;
  min-height: max-content;

  cursor: ${verifyAction};

  & p {
    margin-bottom: 2px;
    letter-spacing: -0.5px;
  }
  & span {
    align-self: center;
  }
  & div.date {
    color: ${({ theme }) => theme.grey};
    font-size: 11px;
    letter-spacing: -0.5px;
  }
  & p.message {
    font-size: 13px;
    margin-top: 25px;
  }
  & div.new {
    display: flex;
    justify-content: flex-end;
    padding-right: 10px;
    color: ${({ theme }) => theme.info};
    font-weight: bold;
    margin: 0;
  }
  
  ${({ isNew }) => isNew && css`
    box-shadow: 1px 3px 5px ${({ theme }) => theme.shadow};
  `}
`;

export const EmptyNotificationCard = styled(Column)`
  margin: 5px 1rem;
  background-color: ${({ theme }) => theme.container};
  border-radius: 5px;
  max-width: 270px;
  min-width: 270px;
  white-space: break-spaces;
  padding: 0.9rem 10px;
  position: relative;
  min-height: max-content;
  & span {
    color: ${({ theme }) => theme.grey};
    font-size: 10px;
  }
`;

export const CategoryTag = styled.div`
  background-color: ${({ theme }) => theme.notificationCardLabel};
  border-radius: 5px;
  padding: 5px 20px;
  position: absolute;
  color: ${({ theme }) => theme.white};
  font-weight: bold;
  left: -5px;
  top: 8px;
  &:after {
    content: '';
    width: 0;
    height: 0;
    border-top: 0px solid transparent;
    border-right: 11px solid ${({ theme }) => theme.notificationCardLabelShadow};
    border-bottom: 20px solid transparent;
    position: absolute;
    left: 0px;
    bottom: -15px;
    z-index: -1;
  }
`;

export const SeparatorLine = styled.hr`
  margin: 0.8rem auto;
  width: 15%;
  border: 0.8px solid #ddd;
`;
