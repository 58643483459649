import styled, { css } from 'styled-components';
import { devices } from '../../breakpoints';

const getContrastYIQ = (hexcolor) => {
  // eslint-disable-next-line no-param-reassign
  hexcolor = hexcolor.replace('#', '');
  const r = parseInt(hexcolor.substr(0, 2), 16);
  const g = parseInt(hexcolor.substr(2, 2), 16);
  const b = parseInt(hexcolor.substr(4, 2), 16);
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128 ? 'black' : 'white';
};

const variants = (variant, color, theme, useDynamicColor) => {
  switch (variant) {
    case 'outlined':
      return css`
        background-color: transparent; 
        border: 2px solid ${theme[color] || color};
        box-shadow: 0px 4px 4px ${theme.shadow};
        color: ${theme[color] || color};
        & > svg {
          fill: ${theme[color] || color};
        }
        & > span {
          color: ${theme[color] || color};
        }
        &:hover {
          background-color: ${theme[color] || color};
          color: ${theme.white};
          & > svg {
            fill: ${theme.white};
          }
          & > span {
            color: ${theme.white};
          }
        }
        &:disabled {
          border: 2px solid ${theme.disabled};
          color: ${theme.disabled};
          box-shadow: none;
          pointer-events: none;
          cursor: default;
          & > svg {
            fill: ${theme.disabled};
          }
        }
      `;
    case 'pattern':
      return css`
        color: ${theme[color] || color};
        border: none;
        background-color: transparent;
        border: 2px solid transparent;
        & > svg {
          fill: ${theme[color] || color};
        }
        &:disabled {
          color: ${theme.grey};
          box-shadow: none;
          pointer-events: none;
          cursor: default;
          & > svg {
            fill: ${theme.disabled};
          };
        };
        &:hover {
          background-color: ${theme[color] || color}30;
        }
      `;
    default:
      return css`
        background-color: ${theme[color] || color}; 
        border: 2px solid ${theme[color] || color}; 
        box-shadow: 0px 4px 4px ${theme.shadow};
        & > svg {
          fill: ${useDynamicColor ? getContrastYIQ(theme[color] || color) : theme.white};
        }
        &:disabled {
          color: ${theme.grey};
          border: 2px solid ${theme.disabled};
          background-color: ${theme.disabled};
          box-shadow: none;
          pointer-events: none;
          cursor: default;
        }
      `;
  }
};

const shapes = {
  square: '0',
  rounded: '8px',
  circle: '50%',
  getShape(shape) {
    return this[shape] || this.rounded;
  },
};

const sizes = {
  small: '1px',
  medium: '6px',
  large: '12px',
  getSize(size) {
    return this[size] || this.medium;
  },
};

const iconSize = (size) => {
  if (size === 'small') return '16px';
  if (size === 'medium') return '20px';
  if (size === 'large') return '30px';
  return size;
};

const loaderSize = (size) => {
  if (size === 'small') return '2px';
  if (size === 'medium') return '4px';
  if (size === 'large') return '6px';
  return size;
};

const StyledIconButton = styled.button`
  cursor: pointer;
  margin: 0;
  display: inline-flex;
  outline: 0;
  position: relative;
  align-items: center;
  user-select: none;
  vertical-align: middle;
  justify-content: center;
  text-decoration: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  justify-self: center;
  border-style: none;
  
  

  ${(props) => props.hidePrint && css`
    @media print {
      display: none;
    }
  `}

  ${(props) => props.hideMobile && css`
    @media ${devices.phoneL} and (orientation: landscape) { 
      display: none;
    }
    @media ${devices.phoneS}{
      display: none;
    }
  `}

  ${({
    variant, color, size, shape, theme, useDynamicColor,
  }) => css`
    ${variants(variant, color, theme, useDynamicColor)};
    border-radius: ${shapes.getShape(shape)};
    padding: ${sizes.getSize(size)};
    & svg {
      width: ${iconSize(size)};
      height: ${iconSize(size)};
      pointer-events: none;
    }
  `}

  &:hover {
    box-shadow: 0px 4px 4px ${({ theme }) => theme.shadow};
    opacity: 0.85;
  }

  &:active {
    box-shadow: 0px 3px 1px -2px ${({ theme }) => theme.shadow};
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  & > div {
    width: 100%;
    height: 100%;
  }
  ${({ size }) => css`
      width: ${iconSize(size)};
      height: ${iconSize(size)};
      & > div div {
        margin: 0;
        width: ${iconSize(size)};
        height: ${iconSize(size)};
        border-width: ${loaderSize(size)};
      }
  `};
`;

export { StyledIconButton, LoadingContainer };
