import React from 'react';
import PropTypes from 'prop-types';

import TextArea from '../../../../juristec-ui/core/TextArea';

import { verifyInput } from '../../../../juristec-ui/utils/validators/inputTextValidators';
import { trimString } from '../../../../juristec-ui/utils/functions/formatString';

import {
  MainContainer,
  InputGrid,
  VarTip,
} from './styled/ThirdStep.styled';

const labelTypes = {
  float64: 'Número',
  int64: 'Número',
  'datetime64[ns]': 'Data',
  object: 'Texto',
  category: 'Texto',
  get(k) {
    return this[k] || k;
  },
};

const ThirdStep = ({
  varsDataState,
}) => {
  const [varsData, setVarsData] = varsDataState;

  const handleVarDesc = (e, key) => {
    const val = e.target.value;
    const msg = verifyInput(val, true, 2, 200);
    setVarsData((old) => ({
      ...old,
      [key]: {
        ...old[key],
        description: val,
        error: msg.length !== 0,
        errorMsg: msg,
      },
    }));
  };

  const descriptionBlur = (e, key) => {
    const val = e.target.value;
    setVarsData((old) => ({
      ...old,
      [key]: {
        ...old[key],
        description: trimString(val),
      },
    }));
  };

  return (
    <MainContainer>
      <VarTip>
        Forneça uma descrição para cada uma das colunas utilizadas na
        criação deste template.
        A descrição é fundamental para que os usuários possam
        aplicar o template corretamente.
      </VarTip>
      <InputGrid>
        {Object.keys(varsData).sort((a, b) => a.localeCompare(b, 'pt-BR')).map((variable) => (
          <TextArea
            key={variable}
            label={`${variable} (${labelTypes.get(varsData[variable].type)})`}
            error={varsData[variable].errorMsg?.length > 0}
            errorMessage={varsData[variable].errorMsg || ''}
            value={varsData[variable].description || ''}
            onChange={(e) => handleVarDesc(e, variable)}
            onBlur={(e) => descriptionBlur(e, variable)}
            styleContainer={{ boxSizing: 'border-box', width: '100%', height: '100px' }}
          />
        ))}
      </InputGrid>
    </MainContainer>
  );
};

ThirdStep.propTypes = {
  varsDataState: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.objectOf(
      PropTypes.shape({
        type: PropTypes.string,
        errorMsg: PropTypes.string,
        description: PropTypes.string,
        error: PropTypes.bool,
      }),
    ),
    PropTypes.func,
  ])),
};

ThirdStep.defaultProps = {
  varsDataState: [{}, () => {}],
};

export default ThirdStep;
