import styled from 'styled-components';

export const StyledContentTutorial = styled.div`
  padding: 20px 0;

  h1 {
    margin: 0px 16px;
    color: ${({ theme }) => theme.primary};
    text-align: center;
  };

  .list {
    list-style: none;
    margin: 15px 0px;
    padding: 0px;
    overflow: hidden auto;
    height: calc(100vh - 145px);

  .item-list {
    padding: 0.5em 16px 0.5em;
    margin: 0px;
        
    span {
      font-weight: 500;
      font-size: 0.9rem;
      padding-left: 8px;
    };

    &:hover {
      background-color: ${({ theme }) => theme.containerHover};
      cursor: pointer;
      // border-radius: 50px;
    };
  }
}`;

export const IconWrapper = styled.div`
  width: 20px;
  height: 20px;
  margin-right: 5px;
  & svg {
    width: 20px;
    height: 20px;
    fill: ${({ theme }) => theme.grey}
  }
`;

export const SearchContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.backgroundOdd};
  border-radius: 10px;
  box-shadow: 0px 4px 4px ${({ theme }) => theme.shadow};
  padding: .2rem .6rem;
  margin: 0 16px;
  & .inputTxt {
    padding-top: 5px;
  }
`;

export const SeparatorLine = styled.hr`
  margin: 1.5rem auto 1rem auto;
  width: 80%;
  border: 0.8px solid #ddd;
`;
