import styled from 'styled-components';
import { devices } from '../../../../juristec-ui/core/breakpoints';

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AddUserContainer = styled(Column)`
  margin: 0 .5rem 1rem;
  gap: 1rem;
  min-width: 310px;
  @media ${devices.phoneS} {
    min-width: auto;
  }
`;

export const ItemContainer = styled(Column)`
  width: 100;
`;
