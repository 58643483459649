import React from 'react';
import PropTypes from 'prop-types';

import Button from '../../../juristec-ui/core/Button';
import IconButton from '../../../juristec-ui/core/IconButton';
import Tooltip from '../../../juristec-ui/core/Tooltip';

import { ArrowLeft } from '../../../juristec-ui/icons';

import { Container, LeftArea, TitleArea } from './styled/LabelCreateToolbar.styled';

const LabelCreateToolbar = ({
  isEdit,
  dashboardTitle,
  handleClickReturn,
  handleSubmit,
  disableSave,
}) => (
  <Container>
    <LeftArea>
      <Tooltip text="Voltar" direction="right">
        <IconButton
          variant="contained"
          color="secondary"
          size="medium"
          shape="rounded"
          onClick={handleClickReturn}
        >
          <ArrowLeft />
        </IconButton>
      </Tooltip>
      <TitleArea>
        {`${dashboardTitle || 'Dashboard'} / ${isEdit ? 'Edição de Insight' : 'Novo Insight'}`}
      </TitleArea>
    </LeftArea>

    <Button
      variant="contained"
      color="secondary"
      size="medium"
      shape="rounded"
      onClick={handleSubmit}
      disabled={disableSave}
      className="save_button"
    >
      Salvar
    </Button>
  </Container>
);

LabelCreateToolbar.propTypes = {
  isEdit: PropTypes.bool,
  dashboardTitle: PropTypes.string,
  handleClickReturn: PropTypes.func,
  handleSubmit: PropTypes.func,
  disableSave: PropTypes.bool,
};

LabelCreateToolbar.defaultProps = {
  isEdit: false,
  dashboardTitle: '',
  handleClickReturn: () => {},
  handleSubmit: () => {},
  disableSave: true,
};

export default LabelCreateToolbar;
