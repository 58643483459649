import styled from 'styled-components';
import { devices } from '../../../juristec-ui/core/breakpoints';

export const ContractedPlanContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & h3 {
    margin: 0;
  }

  @media ${devices.phoneL} {
    padding-left: 1rem;
  }
`;

export const RowData = styled.tr`
  height: 40px;
`;

export const LabelContainer = styled.td`
  text-align: start;
  
  & svg {
    vertical-align: middle;
    width: 28px;
    height: 28px;
      fill: ${({ theme }) => theme.primary};
    margin-right: 1rem;
  }

  & span.label {
    font-weight: bold;
  }
`;

export const InfoContainer = styled.td`
  vertical-align: middle;
  text-align: center;
  color: ${({ theme }) => theme.secondary};
  font-weight: bold;

  & .primary {
    font-size: 26px;
  }
  & .secondary {
    font-size: 24px;
  }

  @media ${devices.phoneS} {
    width: 30%;
    & .primary {
      font-size: 20px;
    }
    & .secondary {
      font-size: 16px;
    }
  }
`;

export const PriceContainer = styled.div`
  color: ${({ theme }) => theme.success};
  & .primary {
    font-size: 40px;
  }
  & .secondary {
    font-size: 32px
  }

  @media ${devices.phoneS} {
    & .primary {
    font-size: 32px;
  }
  & .secondary {
    font-size: 24px
  }
  }
  font-weight: bold;
`;
