import styled, { css } from 'styled-components';
import { devices } from '../../../juristec-ui/core/breakpoints';
import bg from '../../../assets/dots-purple.png';

const StyledContainerForm = styled.div`
  background: url(${bg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: ${({ theme }) => theme.background};
`;

const StyledFormPassword = styled.form`
  width: 375px;
  padding: 20px;
  box-shadow: rgba(0,0,0,0.2) 0px 6px 10px 5px;
  background-color: ${({ theme }) => theme.background};
  border-radius: 10px;

  @media ${devices.phoneS} {
    width: 95vw;
  };
`;

const IconType = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  & svg {
    width: 20px;
    height: 20px;
    margin: 2px 5px 2px 0px;
    ${(props) => css`
      fill: ${props.type === 'success' ? '#4BB543' : '#CA0B00'};
    `};
  };
`;

const PasswordInformation = styled.div`
  border: 1px solid;
  border-color: ${({ theme }) => theme.grey};
  border-radius: 8px;
  font-size: 12px;
  padding: 10px;
  margin-top: 20px;
`;

export {
  StyledContainerForm,
  StyledFormPassword,
  IconType,
  PasswordInformation,
};
