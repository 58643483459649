import { useState } from 'react';

/**
 * Provides functions for working with a boolean state
 * @param {*} initialState Initial state (will use its boolean value)
 * @returns {[boolean, function, function, function, function]} In order:
 * - 0: The boolean state value
 * - 1: A function that changes the state value to the opposite boolean value
 * - 2: A function that changes the state value to false
 * - 3: A function that changes the state value to true
 * - 4: A function that sets the state to the boolean value of any value
 */
function useToggleState(initialState) {
  const [state, setState] = useState(Boolean(initialState));

  /** Changes the state value to the opposite boolean value */
  const toggleState = () => setState((o) => !o);

  /** Changes the state value to false */
  const setFalse = () => setState(false);

  /** Changes the state value to true */
  const setTrue = () => setState(true);

  /** Sets the state to the boolean value of any value */
  const setBooleanState = (value) => setState(Boolean(value));

  return [
    state, toggleState, setFalse, setTrue, setBooleanState,
  ];
}

export default useToggleState;
