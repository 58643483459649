import React from 'react';
import PropTypes from 'prop-types';

import ControllersGroup from '../../../controllers/ControllersGroup';
import {
  SelectController,
  RangeController,
  ChoiceBoxController,
} from '../../../controllers/Base';

const GeneralControllers = ({
  handle,
  config,
  data,
  extend,
  setExtend,
}) => (
  <>
    <ControllersGroup
      title="Opções Gerais"
      id="general"
      extend={extend}
      setExtend={setExtend}
      sizeIncrease={config?.SortValuesControl === 'Customizado' ? data?.index?.length : 0}
    >
      <SelectController
        label="Mapa"
        handle={handle}
        kWord="StateControl"
        configValue={config?.StateControl}
        options={['AC', 'AL', 'AM', 'AP', 'BA', 'CE', 'DF', 'ES', 'GO', 'MA', 'MG', 'MS', 'MT', 'PA',
          'PB', 'PE', 'PI', 'PR', 'RJ', 'RN', 'RO', 'RR', 'RS', 'SC', 'SE', 'SP', 'TO', 'Brasil', 'Regioes',
          'SUDESTE', 'SUL', 'NORTE', 'NORDESTE', 'CENTROOESTE'].map((d) => ({ value: d, label: d, id: d }))}
        fullWidth
      />
      <RangeController
        label="Margem vertical"
        handle={handle}
        kWord="MarginTop"
        configValue={config?.Margin?.top}
        min={-500}
        max={500}
        step={1}
        precision={0}
        fullWidth
      />
      <RangeController
        label="Margem horizontal"
        handle={handle}
        kWord="MarginLeft"
        configValue={config?.Margin?.left}
        min={-500}
        max={500}
        step={1}
        precision={0}
        fullWidth
      />
      <ChoiceBoxController
        label="Formato dos dados"
        handle={handle}
        kWord="DataFormatType"
        configValue={config?.DataFormat?.type}
        options={[
          { value: 'absolute', label: 'Absoluto', id: 'absolute' },
          { value: 'percentual', label: 'Percentual', id: 'percentual' },
        ]}
        fullWidth
      />
    </ControllersGroup>
  </>
);

GeneralControllers.propTypes = {
  config: PropTypes.objectOf(PropTypes.any),
  data: PropTypes.objectOf(PropTypes.any),
  handle: PropTypes.func,
  extend: PropTypes.string,
  setExtend: PropTypes.func,
};

GeneralControllers.defaultProps = {
  config: undefined,
  data: undefined,
  handle: () => {},
  extend: '',
  setExtend: () => {},
};

export default GeneralControllers;
