import React from 'react';
import PropTypes from 'prop-types';

const Astrea = ({ width, height }) => (
  <svg width={width} height={height} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M0 0H40V26.6667H26.6667V13.3333H13.3333V26.6667H26.6667V40H0V0Z" fill="#008FD5" />
  </svg>
);

Astrea.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

Astrea.defaultProps = {
  width: '25px',
  height: '25px',
};

export default Astrea;
