import styled, { css } from 'styled-components';
import { gap } from '../../../../juristec-ui/styles/theme';
import { devices } from '../../../../juristec-ui/core/breakpoints';

export const GridDashboardToolbarStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 5% 0;

  & .gap-fix {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    ${gap('15px', 'row')}
  }

  @media ${devices.tablet} {
    ${gap('15px', 'row')}
    padding: 10px 20px 0;
  }

  // input para a baixo
  @media ${devices.phoneS} {
    ${({ editPainel, openTrashCan }) => (editPainel || openTrashCan ? css`
        flex-flow: row;
        ${gap('2px', 'column')}
        ${gap('5px', 'row')}
      ` : css`
        flex-flow: column wrap;
        ${gap('0', 'row')}
        ${gap('5px', 'column')}
      `)};
    padding: 10px 20px;
    align-items: flex-start;
  }
`;

export const DashboardAvatarInformation = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  ${gap('15px', 'row')}

    @media ${devices.phoneS} {
    flex-flow: row nowrap;
    justify-content: space-between;
  } ;
`;

export const DashboardWelcomeInformation = styled.div`
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-start;
  align-items: flex-start;

  @media ${devices.phoneS} {
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: 'center';
  }

  h1 {
    color: ${({ theme }) => theme.primary};
    margin: 0px;
    font-size: 25px;
    white-space: nowrap;
    @media ${devices.tablet} {
      font-size: 22px;
    }
    @media ${devices.phoneS} {
      font-size: 18px;
    }
  }

  h3 {
    color: ${({ theme }) => theme.toolbarFont};
    font-size: 16px;
    margin: 5px 0px;

    @media ${devices.phoneS} {
      display: none;
    }

    span {
      color: ${({ theme }) => theme.secondary};
    }
    @media ${devices.tablet} {
      font-size: 14px;
    }
    @media ${devices.phoneS} {
      font-size: 12px;
    }
  }
`;

export const DashboardActions = styled.div`
  display: flex;
  ${gap('15px', 'row')}
  justify-content: flex-start;
  align-items: center;

  @media ${devices.tablet} {
    align-items: center;
    ${gap('10px', 'row')}
    .action-button {
      display: none;
    }
  }

  @media ${devices.phoneL} {
    .action-trash {
      min-width: 20px;
      & span {
        display: none;
      }
      & svg {
        margin: 0;
        min-width: 24px;
      }
    }
  }

  @media ${devices.phoneS} {
    ${gap('5px', 'row')}
    width: 100%;
    justify-content: ${({ editPainel, openTrashCan }) => (editPainel || openTrashCan ? 'flex-end' : 'flex-start')};
    & > div:first-child {
      margin-right: auto;
    }
  }
`;
