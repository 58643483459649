import styled, { css } from 'styled-components';

export const WrapperTooltip = styled.div`
  position: relative;
  display: inline-flex;
  user-select: none;
  cursor: pointer;
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  ${({ noEvent }) => (noEvent ? css`
    pointer-events:none;
    &>button{
      pointer-events:all
    }
    ` : css`
    pointer-events: all
  `)}
`;

export const ContentTooltip = styled.div`
  color: ${({ theme }) => theme.tooltipColor};
  opacity: 0.9;
  z-index: ${({ atModal }) => (atModal ? 1050 : 100)};
  padding: 12px;
  font-size: 12px;
  cursor: default;
  border-radius: 6px;
  white-space: nowrap;
  font-family: monospace;
  background-color: ${({ theme }) => theme.tooltipBackground};
  box-shadow: 0 0 0.3rem ${({ theme }) => theme.shadow};
  pointer-events: none;
  position: relative;
  top:0;
  left: 0;

  .arrow-tooltip, .arrow-tooltip:before {
    position: absolute;
    width: 10px;
    height: 10px;
    background: inherit;
  }

  .arrow-tooltip {
    visibility: hidden;
  }

  .arrow-tooltip:before {
    visibility: visible;
    content: '';
    transform: rotate(45deg);
  }

  &[data-popper-placement ^= left] > .arrow-tooltip {
    right: -5px;
  }

  &[data-popper-placement ^= right] > .arrow-tooltip {
    left: -5px;
  }

  &[data-popper-placement ^= bottom] > .arrow-tooltip {
    top: -5px;
  }

  &[data-popper-placement ^= top] > .arrow-tooltip {
    bottom: -5px;
  }
`;
