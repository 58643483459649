export default {
  generalLabel: {
    name: 'Volumetria Geral',
    id: 'aGN67INP7prddwVN8QTU',
    style: {
      image: '',
      bgColor: '#ff9626',
      fontFamily: 'arial',
      textPosition: 'center',
      fontColor: '#111',
      UseGlobalColor: true,
      opacityText: 1,
      bold: true,
      imageFit: 'contain',
      underline: false,
      opacityImage: 1,
      fontSize: '22',
      italic: false,
      colorOpacityText: '#ff9626',
    },
    link: '',
    status: 'active',
    type: 'label',
  },
  totalProcessValue: {
    name: 'Número total de processos',
    id: 'bK0lArNbSwhJCqfHNXPS',
    style: {
      TitleControl: 'Número total de processos',
      ShowTitleControl: {
        checked: true,
      },
      StyleTitleControl: {
        fontSize: 20,
        color: '#fff',
        fontFamily: 'arial',
        bold: true,
        italic: false,
        underline: false,
      },
      GlobalValue: {
        fontSize: 20,
        color: '#fff',
        fontFamily: 'arial',
        background: '#4711b2',
        bold: true,
        italic: false,
        underline: false,
      },
      type: 'Value',
    },
    meta: { values: [{ map: 'count' }] },
    data: { columns: [], index: [], data: [] },
    status: 'active',
    type: 'Value',
  },
  totalValuesValue: {
    name: 'Soma dos valores de causa',
    id: 'iwtz3OY7O0oRIHEirHHQ',
    style: {
      TitleControl: 'Soma dos valores de causa',
      ShowTitleControl: {
        checked: true,
      },
      StyleTitleControl: {
        fontSize: 20,
        color: '#fff',
        fontFamily: 'arial',
        bold: true,
        italic: false,
        underline: false,
      },
      GlobalValue: {
        fontSize: 20,
        color: '#fff',
        fontFamily: 'arial',
        background: '#4711b2',
        bold: true,
        italic: false,
        underline: false,
      },
      LabelCurrencyControl: 'R$',
      type: 'Value',
    },
    meta: { values: [{ map: 'count' }] },
    status: 'active',
    type: 'Value',
  },
  ValuesBoxplot: {
    name: 'Distribuição empírica do valor da causa',
    id: '2RChLQGjT6zM3ZSJYQ1B',
    style: {
      TitleControl: 'Distribuição empírica do valor da causa',
      ShowTitleControl: {
        checked: true,
      },
      StyleTitleControl: {
        fontSize: 20,
        color: '#000000',
        fontFamily: 'arial',
        bold: false,
        italic: false,
        underline: false,
      },
      SortValuesControl: 'Não ordenado',
      PaddingControl: {
        value: '0.5',
      },
      Margin: {
        top: 50,
        right: 30,
        bottom: 55,
        left: 70,
      },
      BorderWidthControl: {
        value: '0',
      },
      MedianWidthControl: '2',
      MedianColorControl: {
        from: 'color',
        modifiers: [
          [
            'darker',
            '1',
          ],
        ],
      },
      WhiskerEndSizeControl: '0.5',
      WhiskerColorControl: {
        from: 'color',
        modifiers: [
          [
            'darker',
            '1',
          ],
        ],
      },
      PalletsControl: 'gradient',
      CurrencyControl: 'R$',
      GroupingSelectControl: 'milhao',
      LegendControl: {
        checked: false,
      },
      InteractiveCurrencyControl: 'R$',
      type: 'BoxPlot',
    },
    link: '',
    status: 'active',
    type: 'BoxPlot',
  },
  totalProcessByResultPie: {
    name: 'Quantidade de processos por resultado',
    id: 'ORnUFDQzt8K5sPDNPZCx',
    style: {
      TitleControl: 'Quantidade de processos por resultado',
      ShowTitleControl: {
        checked: true,
      },
      StyleTitleControl: {
        fontSize: 20,
        color: '#000000',
        fontFamily: 'arial',
        bold: true,
        italic: false,
        underline: false,
      },
      SortValuesControl: 'Não ordenado',
      InnerRadiusControl: {
        value: '0.4',
      },
      PaddingAngleControl: {
        value: '3',
      },
      Margin: {
        top: 40,
        right: 0,
        bottom: 50,
        left: 60,
      },
      PalletsControl: 'gradient',
      GradientPickerControl: {
        color1: '#4711B2',
        color2: '#FF9626',
      },
      LabelsFontSizeControl: {
        value: '11',
      },
      LegendControl: {
        checked: false,
      },
      type: 'Pie',
    },
    data: { columns: [], index: [], data: [] },
    status: 'active',
    type: 'Pie',
  },
  totalProcessByYearLine: {
    name: 'Quantidade de processos por Ano de distribuição',
    id: 'qYauzWDSRbTFJ1OuGPsW',
    style: {
      TitleControl: 'Quantidade de processos por Ano de distribuição',
      ShowTitleControl: {
        checked: true,
      },
      StyleTitleControl: {
        fontSize: 20,
        color: '#000000',
        fontFamily: 'arial',
        bold: true,
        italic: false,
        underline: false,
      },
      SortValuesControl: 'Não ordenado',
      Margin: {
        top: 50,
        right: 30,
        bottom: 50,
        left: 60,
      },
      AreaControl: {
        checked: true,
        opacity: 0.2,
      },
      LinesFormatControl: 'cardinal',
      PalletsControl: 'gradient',
      GradientPickerControl: {
        color1: '#4711B2',
        color2: '#FF9626',
      },
      EnableAxisX: {
        axisTop: false,
        axisBot: true,
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: '',
        legendPosition: 'middle',
        legendOffset: 35,
        separadorNumerico: true,
        maxLabelLines: 1,
      },
      EnableAxisY: {
        axisLeft: true,
        axisRight: false,
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: '',
        legendPosition: 'middle',
        legendOffset: -55,
        separadorNumerico: true,
      },
      AxisGlobal: {
        fontSize: 12,
        fontFamily: 'arial',
        legendFontSize: 12,
        color: '#222',
      },
      LegendControl: {
        checked: false,
      },
      type: 'Line',
    },
    data: { columns: [], index: [], data: [] },
    status: 'active',
    type: 'Line',
  },
  totalValuesByYearLine: {
    name: 'Soma do valor da causa por Ano de distribuição',
    id: 'YSdaECCmKscx8nWF3dVk',
    style: {
      TitleControl: 'Soma do valor da causa por Ano de distribuição',
      ShowTitleControl: {
        checked: true,
      },
      StyleTitleControl: {
        fontSize: 20,
        color: '#000000',
        fontFamily: 'arial',
        bold: true,
        italic: false,
        underline: false,
      },
      SortValuesControl: 'Não ordenado',
      Margin: {
        top: 50,
        right: 30,
        bottom: 50,
        left: 65,
      },
      AreaControl: {
        checked: true,
        opacity: 0.2,
      },
      LinesFormatControl: 'cardinal',
      PalletsControl: 'gradient',
      GradientPickerControl: {
        color1: '#4711B2',
        color2: '#FF9626',
      },
      LabelCurrencyControl: 'R$',
      LabelGroupingControl: 'milhao',
      EnableAxisX: {
        axisTop: false,
        axisBot: true,
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: '',
        legendPosition: 'middle',
        legendOffset: 35,
        separadorNumerico: true,
        maxLabelLines: 1,
      },
      EnableAxisY: {
        axisLeft: true,
        axisRight: false,
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: '',
        legendPosition: 'middle',
        legendOffset: -55,
        separadorNumerico: true,
        Decimals: 'fixed',
        separadorNumericoCasasY: 2,
      },
      CurrencyControl: 'R$',
      GroupingSelectControl: 'milhao',
      AxisGlobal: {
        fontSize: 11,
        fontFamily: 'arial',
        legendFontSize: 11,
        color: '#222',
      },
      LegendControl: {
        checked: false,
      },
      InteractiveCurrencyControl: 'R$',
      type: 'Line',
    },
    data: { columns: [], index: [], data: [] },
    status: 'active',
    type: 'Line',
  },
  trtLabel: {
    name: 'Informações por TRTs',
    id: 'dGitR5fBCS2TDGbTpTGU',
    style: {
      italic: false,
      bold: true,
      underline: false,
      image: '',
      colorOpacityText: '#ff9626',
      opacityImage: 1,
      imageFit: 'contain',
      fontFamily: 'arial',
      opacityText: 1,
      fontColor: '#111',
      bgColor: '#ff9626',
      textPosition: 'center',
      fontSize: '22',
    },
    link: '',
    status: 'active',
    type: 'label',
  },
  totalProcessByTrtBar: {
    name: 'Quantidade de processos por tribunal',
    id: '3UFVJ7Xcr7ffH9YuD7ri',
    style: {
      TitleControl: 'Quantidade de processos por tribunal',
      ShowTitleControl: {
        checked: true,
      },
      StyleTitleControl: {
        fontSize: 20,
        color: '#000000',
        fontFamily: 'arial',
        bold: true,
        italic: false,
        underline: false,
      },
      SortValuesControl: 'Não ordenado',
      LayoutControl: 'horizontal',
      Margin: {
        top: 50,
        right: 10,
        bottom: 55,
        left: 70,
      },
      PalletsControl: 'gradient',
      GradientPickerControl: {
        color1: '#4711B2',
        color2: '#FF9626',
      },
      LabelFormatControl: 'Central',
      LabelTextColorControl: '#fff',
      LabelsFontSizeControl: {
        value: '11',
      },
      EnableAxisX: {
        axisTop: false,
        axisBot: true,
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: '',
        legendPosition: 'middle',
        legendOffset: 35,
        separadorNumerico: true,
        maxLabelLines: 1,
      },
      EnableAxisY: {
        axisLeft: true,
        axisRight: false,
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: '',
        legendPosition: 'middle',
        legendOffset: -55,
        separadorNumerico: true,
      },
      AxisGlobal: {
        fontSize: 12,
        fontFamily: 'arial',
        legendFontSize: 12,
      },
      LegendControl: {
        checked: false,
      },
      InteractiveGroupingControl: '',
      type: 'Bar',
    },
    data: { columns: [], index: [], data: [] },
    status: 'active',
    type: 'Bar',
  },
  totalValuesByTrtBar: {
    name: 'Soma do valor da causa por tribunal',
    id: 'jUBEpTY3gqFz8HeVH0vc',
    style: {
      TitleControl: 'Soma do valor da causa por tribunal',
      ShowTitleControl: {
        checked: true,
      },
      StyleTitleControl: {
        fontSize: 20,
        color: '#000000',
        fontFamily: 'arial',
        bold: true,
        italic: false,
        underline: false,
      },
      SortValuesControl: 'Valor Decrescente',
      Margin: {
        top: 50,
        right: 10,
        bottom: 55,
        left: 70,
      },
      PalletsControl: 'gradient',
      GradientPickerControl: {
        color1: '#4711B2',
        color2: '#FF9626',
      },
      LabelTextColorControl: '#000',
      LabelsFontSizeControl: {
        value: '10',
      },
      LabelRotationControl: '-90',
      LabelTranslateY: '-21',
      LabelCurrencyControl: 'R$',
      LabelGroupingControl: 'milhao',
      EnableAxisX: {
        axisTop: false,
        axisBot: true,
        tickSize: 5,
        tickPadding: 5,
        tickRotation: -30,
        legend: '',
        legendPosition: 'middle',
        legendOffset: 35,
        separadorNumerico: true,
        maxLabelLines: 1,
      },
      EnableAxisY: {
        axisLeft: true,
        axisRight: false,
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: '',
        legendPosition: 'middle',
        legendOffset: -55,
        separadorNumerico: true,
        Decimals: 'fixed',
        separadorNumericoCasasY: 2,
      },
      CurrencyControl: 'R$',
      GroupingSelectControl: 'milhao',
      AxisGlobal: {
        fontSize: 12,
        fontFamily: 'arial',
        legendFontSize: 12,
      },
      LegendControl: {
        checked: false,
      },
      InteractiveCurrencyControl: 'R$',
      InteractiveGroupingControl: '',
      type: 'Bar',
    },
    data: { columns: [], index: [], data: [] },
    status: 'active',
    type: 'Bar',
  },
  ufLabel: {
    name: 'Informações por UFs',
    id: '0J8IZNojp5OVcpt0hjb4',
    style: {
      colorOpacityText: '#ff9626',
      textPosition: 'center',
      fontColor: '#111',
      image: '',
      fontSize: '22',
      fontFamily: 'arial',
      imageFit: 'contain',
      opacityText: 1,
      bgColor: '#ff9626',
      underline: false,
      italic: false,
      bold: true,
      opacityImage: 1,
    },
    link: '',
    status: 'active',
    type: 'label',
  },
  totalProcessByUfMap: {
    name: 'Quantidade de processos por UF',
    id: 'mV6upA3NCa5pZeD0Aa9h',
    style: {
      TitleControl: 'Quantidade de processos por UF',
      ShowTitleControl: {
        checked: true,
      },
      StyleTitleControl: {
        fontSize: 20,
        color: '#000000',
        fontFamily: 'arial',
        bold: true,
        italic: false,
        underline: false,
      },
      PalletsControl: 'gradient',
      GradientPickerControl: {
        color1: '#4711B2',
        color2: '#FF9626',
      },
      LegendControl: {
        checked: false,
      },
      type: 'Map',
    },
    data: { columns: [], index: [], data: [] },
    status: 'active',
    type: 'Map',
  },
  totalValuesByUfBar: {
    name: 'Soma do valor da causa por UF',
    id: 'SgQASPV4fEtXMaWya5Oc',
    style: {
      TitleControl: 'Soma do valor da causa por UF',
      ShowTitleControl: {
        checked: true,
      },
      StyleTitleControl: {
        fontSize: 20,
        color: '#000000',
        fontFamily: 'arial',
        bold: true,
        italic: false,
        underline: false,
      },
      SortValuesControl: 'Valor Decrescente',
      Margin: {
        top: 50,
        right: 10,
        bottom: 55,
        left: 70,
      },
      PalletsControl: 'gradient',
      GradientPickerControl: {
        color1: '#4711B2',
        color2: '#FF9626',
      },
      LabelTextColorControl: '#000',
      LabelsFontSizeControl: {
        value: '10',
      },
      LabelRotationControl: '-90',
      LabelTranslateY: '-21',
      LabelCurrencyControl: 'R$',
      LabelGroupingControl: 'milhao',
      EnableAxisX: {
        axisTop: false,
        axisBot: true,
        tickSize: 5,
        tickPadding: 5,
        tickRotation: -30,
        legend: '',
        legendPosition: 'middle',
        legendOffset: 35,
        separadorNumerico: true,
        maxLabelLines: 1,
      },
      EnableAxisY: {
        axisLeft: true,
        axisRight: false,
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: '',
        legendPosition: 'middle',
        legendOffset: -55,
        separadorNumerico: true,
        Decimals: 'fixed',
        separadorNumericoCasasY: 2,
      },
      CurrencyControl: 'R$',
      GroupingSelectControl: 'milhao',
      AxisGlobal: {
        fontSize: 12,
        fontFamily: 'arial',
        legendFontSize: 12,
      },
      LegendControl: {
        checked: false,
      },
      InteractiveCurrencyControl: 'R$',
      InteractiveGroupingControl: '',
      type: 'Bar',
    },
    data: { columns: [], index: [], data: [] },
    status: 'active',
    type: 'Bar',
  },
};
