import styled from 'styled-components';

export const MainContainer = styled.div`
  min-width: 200px;
  margin: 0.5rem 0;
  background-color: ${({ theme }) => theme.popoverBackground};
`;

export const ProfileInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 0.5rem;
  & > h2 {
    margin: 0 0 0.2rem 0;
  }
  & > span {
    display: none;
    letter-spacing: -0.5px;
    color: ${({ theme }) => theme.grey};
    position: absolute;
    bottom: 0.8rem;
  }
`;

export const Item = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  padding: 0 1.5rem;
  cursor: pointer;
  & > h3 {
    margin-right: auto;
  }
  &:hover {
    color: ${({ theme }) => theme.primary};
    background-color: ${({ theme }) => theme.containerHover};
  }
`;

export const ProfileContainer = styled(Item)`
  position: relative;
  justify-content: left;
  padding: 0.5rem 1.5rem;
  &:hover {
    span {
      display: block;
    }
  }
`;

export const SeparatorLine = styled.hr`
  margin: 0.7rem auto;
  width: 15%;
  border: 0.8px solid #ddd;
`;

export const Icon = styled.div`
  background-color: ${({ theme }) => theme.containerHover};
  border: 2px solid transparent;
  margin: 0 0.5rem 0 0;
  border-radius: 50%;
  padding: 6px;
  width: 20px;
  height: 20px;
  & svg {
    width: 20px;
    height: 20px;
    fill: ${({ theme }) => theme.primary};
  }
`;
