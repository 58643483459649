import styled, { css } from 'styled-components';

export const PasswordInformation = styled.div`
  border: 1px solid;
  border-color: ${({ theme }) => theme.grey};
  border-radius: 8px;
  font-size: 12px;
  padding: 10px;
  margin-top: 0.8rem;
  display: flex;
  flex-direction: column;
  & > span {
    font-weight: bold;
    margin: 0 auto;
  }
`;

export const IconType = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 2px 0;

  & svg {
    width: 15px;
    height: 15px;
    margin: 0 5px 0 0px;
    ${({ type, theme }) => css`
      fill: ${theme[type] || theme.error};
    `};
  };
`;
