import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import { startOfToday } from 'date-fns';

import Select from '../../juristec-ui/core/SelectNew';
import Button from '../../juristec-ui/core/Button';
import IconButton from '../../juristec-ui/core/IconButton';
import Drawer from '../../juristec-ui/core/Drawer';
import Popover from '../../juristec-ui/core/Popover';
import Tooltip from '../../juristec-ui/core/Tooltip';
import List from '../../juristec-ui/core/List';
import ListItem from '../../juristec-ui/core/ListItem';
import FilterItem from '../BigDataFilter';
import FilterApplied from '../BigDataFilterApplied';

import useToggleState from '../../juristec-ui/hooks/useToggleState';
import uuidv4 from '../../juristec-ui/utils/functions/randomUUID';
import { formatDateTime } from '../../juristec-ui/utils/functions/lab';
import { verifyCnpj, verifyInput } from '../../juristec-ui/utils/validators/inputTextValidators';
import { ufOptions, orgaoOptions, poloOptions } from '../../options';

import { History } from '../../juristec-ui/icons';

import {
  InternalDrawer,
  DrawerContainer,
  DrawerHeader,
  InputWrapper,
  AppliedList,
} from './styled/MetaFilter.styled';
import 'react-datepicker/dist/react-datepicker.css';

const filterOpts = [
  // FilterByText
  { label: 'CNPJ', value: 'cnpj', id: 'cnpj' },
  { label: 'Advogado', value: 'advogado', id: 'advogado' },
  { label: 'Advogado ativo', value: 'adv_ativo', id: 'adv_ativo' },
  { label: 'Advogado passivo', value: 'adv_passivo', id: 'adv_passivo' },
  { label: 'Interessados', value: 'interessado', id: 'interessado' },
  { label: 'Assunto', value: 'assunto', id: 'assunto' },
  // { label: 'Número OAB', value: 'oab', id: 'oab' },
  // FilterByDateYear
  // { label: 'Ano no CNJ', value: 'ano', id: 'ano' },
  // FilterByDateInterval
  { label: 'Publicação', value: 'publicacao', id: 'publicacao' },
  { label: 'Distribuição', value: 'distribuicao', id: 'distribuicao' },
  // FilterByNumberInverval
  { label: 'Valor da causa', value: 'valor', id: 'valor' },
  // FilterByOptionsSimple
  { label: 'Tribunal', value: 'tribunal', id: 'tribunal' },
  { label: 'Estado', value: 'uf', id: 'uf' },
  { label: 'Classe do processo', value: 'classe', id: 'classe' },
  { label: 'Órgão', value: 'orgao', id: 'orgao' },
  { label: 'Polo', value: 'polo', id: 'polo' },
  { label: 'Tipo de polo', value: 'sinonimos', id: 'sinonimos' },
  // FilterByOptionsAdvanced
  { label: 'Comarca', value: 'comarca', id: 'comarca' },
  { label: 'Vara trabalhista', value: 'vara', id: 'vara' },
];

const initialInfo = (key) => {
  switch (key) {
    case 'comarca':
      return { value: { tribunal: {}, comarca: [] }, error: true, errorMsg: '' };
    case 'vara':
      return { value: { tribunal: {}, comarca: {}, vara: [] }, error: true, errorMsg: '' };
    case 'polo':
    case 'sinonimos':
    case 'classe':
    case 'orgao':
    case 'uf':
    case 'tribunal':
      return { value: [], error: true, errorMsg: '' };
    case 'valor':
      return { value: [0, 0], error: true, errorMsg: '' };
    case 'distribuicao':
    case 'publicacao':
      return { value: [null, startOfToday()], error: false };
    case 'ano':
      return { value: new Date(), error: false, errorMsg: '' };
    default:
      return { value: '', error: true, errorMsg: '' };
  }
};

const getOpts = (key) => {
  switch (key) {
    case 'polo':
      return poloOptions;
    case 'orgao':
      return orgaoOptions;
    case 'uf':
      return ufOptions;
    default:
      return [];
  }
};

const isRepeatableFilter = (key) => ([
  'cnpj', 'advogado', 'adv_ativo', 'adv_passivo',
  'interessado', 'assunto', 'comarca', 'vara',
].includes(key));

const MetaFilter = ({
  lastQueries,
  drawerState,
  filterFieldsState,
  isDirtyState,
  sendQuery,
  getOptions,
  maxValue,
  isMobileSize,
}) => {
  const [showDrawer, closeDrawer] = drawerState;
  const [history, toggleHistory, closeHistory] = useToggleState(false);
  const [filterFields, setFilterFields] = filterFieldsState;
  const [isDirty, setIsDirty] = isDirtyState;
  const [filteredOpts, setFilteredOpts] = useState(filterOpts);
  const [tempFilter, setTempFilter] = useState({
    filterBy: filterOpts[0],
    ...initialInfo(filterOpts[0].value),
  });

  const DrawerComponent = useMemo(() => (
    isMobileSize ? Drawer : InternalDrawer
  ), [isMobileSize]);

  useEffect(() => {
    setFilteredOpts(() => {
      const auxF = filterOpts.filter((f) => {
        if (isRepeatableFilter(f.value)) return true;
        return !Object.keys(filterFields).includes(f.value);
      });
      const selected = auxF.find((f) => tempFilter.filterBy.value === f.value);
      setTempFilter((o) => ({
        filterBy: selected || (auxF[0] || {}),
        ...initialInfo(selected?.value || auxF[0]?.value),
        options: selected && o.options ? new Map([['tribunal', o.options.get('tribunal')]]) : undefined,
      }));
      return auxF;
    });
  }, [filterFields]);

  const addFilterField = () => {
    setIsDirty(true);
    const key = tempFilter.filterBy.value;
    const isRepeatable = isRepeatableFilter(key);

    setFilterFields((o) => {
      let auxVal;
      if (isRepeatable) {
        auxVal = o[key]?.value || {};
        auxVal[uuidv4()] = tempFilter.value;
      } else {
        auxVal = tempFilter.value;
      }
      return ({
        ...o,
        [key]: {
          label: tempFilter.filterBy.label,
          value: auxVal,
        },
      });
    });
  };

  const handleEditFilter = (field, subField, filterData) => {
    setIsDirty(true);
    const isRepeatable = isRepeatableFilter(field);
    setFilterFields((o) => {
      let auxVal;
      if (isRepeatable) {
        auxVal = o[field]?.value || {};
        auxVal[subField] = filterData.value;
      } else {
        auxVal = filterData.value;
      }
      return ({
        ...o,
        [field]: {
          ...o[field],
          value: auxVal,
        },
      });
    });
  };

  const handleRemoveFilter = (field, subField) => {
    setIsDirty(true);
    const isRepeatable = isRepeatableFilter(field);
    setFilterFields((o) => {
      if (isRepeatable) {
        const { [subField]: _, ...newValue } = o[field].value;
        if (Object.keys(newValue).length > 0) {
          return { ...o, [field]: { ...o[field], value: newValue } };
        }
      }
      const { [field]: _, ...newObj } = o;
      return newObj;
    });
  };

  const handleFilterBy = async (selected) => {
    switch (selected.value) {
      case 'comarca':
      case 'vara': {
        const opts = await getOptions('tribunal');
        const optsList = opts.map((dt) => ({ label: dt.toUpperCase(), value: dt, id: dt }));
        setTempFilter({
          filterBy: selected,
          ...initialInfo(selected.value),
          options: new Map([['tribunal', optsList]]),
        });
        break;
      }
      case 'polo':
      case 'orgao':
      case 'uf': {
        setTempFilter({
          filterBy: selected,
          ...initialInfo(selected.value),
          options: getOpts(selected.value),
        });
        break;
      }
      case 'sinonimos': {
        const opts = await getOptions(selected.value);
        const optsList = opts.map((dt) => {
          const str = `${dt.ativo} / ${dt.passivo}`;
          return ({ label: str, value: str, id: str });
        });
        setTempFilter({
          filterBy: selected,
          ...initialInfo(selected.value),
          options: optsList,
        });
        break;
      }
      case 'classe': {
        const opts = await getOptions(selected.value);
        const optsList = opts.map((dt, i) => ({
          label: dt.nome || dt.sigla.toUpperCase(), value: dt.sigla, id: dt.sigla + i,
        }));
        setTempFilter({
          filterBy: selected,
          ...initialInfo(selected.value),
          options: optsList,
        });
        break;
      }
      case 'tribunal': {
        const opts = await getOptions(selected.value);
        const optsList = opts.map((dt) => ({ label: dt.toUpperCase(), value: dt, id: dt }));
        setTempFilter({
          filterBy: selected,
          ...initialInfo(selected.value),
          options: optsList,
        });
        break;
      }
      case 'valor': {
        setTempFilter({
          filterBy: selected,
          ...initialInfo(selected.value),
          value: [0, maxValue],
          error: maxValue < 0,
        });
        break;
      }
      default: {
        setTempFilter({
          filterBy: selected,
          ...initialInfo(selected.value),
        });
        break;
      }
    }
  };

  const handleValue = async (value, key, subKey, stateSetter) => {
    switch (key) {
      case 'comarca':
      case 'vara': {
        if (subKey === 'tribunal') {
          const opts = await getOptions('comarca', { courts: value.tribunal.value });
          return stateSetter((o) => {
            const aux = o.options;
            aux.delete('vara');
            aux.set('comarca', opts.map((dt) => ({ label: dt, value: dt, id: dt })));
            const init = initialInfo(key);
            return {
              filterBy: o.filterBy,
              ...init,
              value: { ...init.value, tribunal: value.tribunal },
              options: aux,
            };
          });
        }
        if (subKey === 'comarca') {
          const opts = await getOptions(
            'vara', { courts: value.tribunal.value, comarca: value.comarca.value },
          );
          return stateSetter((o) => {
            const aux = o.options;
            aux.set('vara', opts.map((dt) => ({ label: dt, value: dt, id: dt })));
            const init = initialInfo(key);
            return {
              filterBy: o.filterBy,
              ...init,
              value: { ...init.value, tribunal: value.tribunal, comarca: value.comarca },
              options: aux,
            };
          });
        }
        const errorMsg = value[key]?.length > 0 ? '' : 'Selecione pelo menos uma opção';
        return stateSetter((o) => ({
          ...o, value, error: errorMsg.length !== 0, errorMsg,
        }));
      }
      case 'polo':
      case 'sinonimos':
      case 'classe':
      case 'orgao':
      case 'uf':
      case 'tribunal': {
        const errorMsg = value?.length > 0 ? '' : 'Selecione pelo menos uma opção';
        return stateSetter((o) => ({
          ...o, value, error: errorMsg.length !== 0, errorMsg,
        }));
      }
      case 'valor':
      case 'distribuicao':
      case 'publicacao': {
        const error = (value[0] === null && value[1] === null) || (
          ((!(value[0] === null) === !(value[1] === null)) && value[0] > value[1])
        );
        return stateSetter((o) => ({
          ...o, value, error,
        }));
      }
      case 'cnpj': {
        const val = value.replace(/\D+/g, '')
          .replace(/(\d{2})(\d)/, '$1.$2')
          .replace(/(\d{3})(\d)/, '$1.$2')
          .replace(/(\d{3})(\d)/, '$1/$2')
          .replace(/(\d{4})(\d)/, '$1-$2')
          .replace(/(-\d{2})\d+?$/, '$1');
        const errorMsg = verifyCnpj(val, true);
        return stateSetter((o) => ({
          ...o, value: val, error: errorMsg.length !== 0, errorMsg,
        }));
      }
      default: {
        const errorMsg = typeof value === 'string' ? verifyInput(value, true, 3) : '';
        return stateSetter((o) => ({
          ...o, value, error: errorMsg.length !== 0, errorMsg,
        }));
      }
    }
  };

  const handleNewValue = (value, subKey) => {
    handleValue(value, tempFilter.filterBy.value, subKey, setTempFilter);
  };

  const handleEditValue = (value, key, subKey, stateSetter) => {
    handleValue(value, key, subKey, stateSetter);
  };

  const loadFilters = (index) => {
    setIsDirty(true);
    const { timestamp: _, ...newObj } = lastQueries[index];
    setFilterFields(newObj);
  };

  const handleSendQuery = async () => {
    setIsDirty(false);
    setIsDirty(await sendQuery());
  };

  return (
    <DrawerComponent
      open={showDrawer}
      direction="right"
      handleCloseDrawer={closeDrawer}
    >
      <DrawerContainer>
        <DrawerHeader>
          <h2>Filtros</h2>
          <Popover
            style={{
              borderRadius: '8px',
              overflow: 'hidden',
            }}
            offset={[0, 0]}
            open={history}
            stayOpened={false}
            closePopover={closeHistory}
            atModal
          >
            <Popover.Action>
              <Tooltip text="Últimos filtros" atModal>
                <IconButton
                  onClick={toggleHistory}
                  disabled={!lastQueries?.length > 0}
                >
                  <History />
                </IconButton>
              </Tooltip>
            </Popover.Action>
            <Popover.Content>
              <List>
                {lastQueries.map((q, i) => (
                  <ListItem
                    key={q.timestamp}
                    onClick={() => loadFilters(i)}
                    noOutline
                  >
                    {formatDateTime(q.timestamp, { time: 'half' })}
                  </ListItem>
                ))}
              </List>
            </Popover.Content>
          </Popover>
        </DrawerHeader>
        <InputWrapper>
          <Select
            label="Filtrar por"
            options={filteredOpts}
            value={tempFilter.filterBy}
            onChange={handleFilterBy}
          />
        </InputWrapper>
        <InputWrapper>
          <FilterItem
            field={tempFilter.filterBy?.value}
            label={tempFilter.filterBy?.label}
            value={tempFilter.value}
            errorMsg={tempFilter.errorMsg}
            options={tempFilter.options}
            handle={handleNewValue}
          />
        </InputWrapper>
        <InputWrapper style={{ marginTop: '.5rem' }}>
          <Button
            color="secondary"
            style={{ width: '100%' }}
            disabled={tempFilter.error}
            onClick={addFilterField}
          >
            Adicionar filtro
          </Button>
        </InputWrapper>
        {Object.keys(filterFields).length > 0 && (
          <>
            <h4>Filtros aplicados:</h4>
            <AppliedList>
              {Object.keys(filterFields).map((fField) => (
                isRepeatableFilter(fField) ? (
                  Object.entries(filterFields[fField].value).map(([vKey, vVal]) => (
                    <FilterApplied
                      key={vKey}
                      field={fField}
                      subField={vKey}
                      label={filterFields[fField].label}
                      value={vVal}
                      removeFilter={handleRemoveFilter}
                      handleValue={handleEditValue}
                      getOptions={getOptions}
                      editFilter={handleEditFilter}
                    />
                  ))
                ) : (
                  <FilterApplied
                    key={fField}
                    field={fField}
                    label={filterFields[fField].label}
                    value={filterFields[fField].value}
                    removeFilter={handleRemoveFilter}
                    handleValue={handleEditValue}
                    getOptions={getOptions}
                    editFilter={handleEditFilter}
                  />
                )
              ))}
            </AppliedList>
          </>
        )}
        <InputWrapper style={{ marginTop: 'auto', position: 'sticky', padding: '.4rem' }}>
          <Button
            color="primary"
            style={{ width: '100%' }}
            disabled={!isDirty}
            onClick={handleSendQuery}
          >
            Buscar
          </Button>
        </InputWrapper>
      </DrawerContainer>
    </DrawerComponent>
  );
};

MetaFilter.propTypes = {
  lastQueries: PropTypes.arrayOf(PropTypes.shape({
    timestamp: PropTypes.string,
  })),
  filterFieldsState: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.func,
  ])),
  drawerState: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.func,
  ])),
  isDirtyState: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.func,
  ])),
  getOptions: PropTypes.func.isRequired,
  maxValue: PropTypes.number,
  sendQuery: PropTypes.func.isRequired,
  isMobileSize: PropTypes.bool,
};

MetaFilter.defaultProps = {
  lastQueries: [],
  filterFieldsState: [{}, () => {}],
  drawerState: [false, () => {}],
  isDirtyState: [false, () => {}],
  maxValue: 0,
  isMobileSize: false,
};

export default MetaFilter;
