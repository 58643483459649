import * as d3 from 'd3';

const legend = (group, data, colors, cHeight, cWidth, style, toggleInfo) => {
  const getPositionSizes = () => {
    const groupSizes = group.node().getBBox();
    switch (style.position) {
      case 'top':
        return [0 - groupSizes.width / 2, -cHeight / 2 - groupSizes.height / 2];
      case 'bottom':
        return [0 - groupSizes.width / 2, cHeight / 2 - groupSizes.height / 2 + 2];
      case 'left':
        return [-cWidth / 2 - groupSizes.width, 0 - groupSizes.height / 2];
      default: // default === right
        return [cWidth / 2, 0 - groupSizes.height / 2];
    }
  };

  // Grupo de cada legenda
  group.selectAll('.legend-group')
    .data(data)
    .enter()
    .append('g')
    .attr('class', 'legend-c')
    .attr('opacity', (d) => (d.data.hide ? '.5' : '1'))
    .style('cursor', 'pointer')
    .on('click', (e, d) => toggleInfo(d.data.label));

  // Figura colorida de cada legenda
  group.selectAll('.legend-c')
    .append('rect')
    .attr('width', style.size)
    .attr('height', style.size)
    .attr('rx', style.shape === 'circle' ? '100' : '0')
    .style('fill', (d) => colors[d.data.label]);

  // Texto de cada legenda
  group.selectAll('.legend-c')
    .append('text')
    .attr('x', style.size + 2)
    .attr('dy', style.size - 2)
    .text((d) => d.data.label)
    .attr('text-anchor', 'left')
    .style('fill', style.fontColor)
    .style('font-size', `${style.size}px`);

  // Posiciona cada item da legenda
  let totalWidth = 0;
  let minWidth = 0;
  group.selectAll('.legend-c').each((d, i, nodes) => {
    minWidth += d3.select(nodes[i]).node().getBBox().width;
  });
  const spacing = Math.min(Math.max((cWidth - minWidth) / (data.length - 1), 0), style.spacing);
  group.selectAll('.legend-c').each((d, i, nodes) => {
    const current = d3.select(nodes[i]);
    if (style.position === 'top' || style.position === 'bottom') {
      current.attr('transform', `translate(${totalWidth}, 0)`);
      totalWidth += current.node().getBBox().width + spacing;
    } else {
      current.attr('transform', `translate(0, ${d.index * (style.size + style.spacing)})`);
    }
  });

  // Formata a posição do container de legenda
  group.attr('transform', `translate(${getPositionSizes()})`);
};

export default legend;
