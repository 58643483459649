import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Select from '../../../../juristec-ui/core/Select';
import Button from '../../../../juristec-ui/core/Button';
import Badge from '../../../../juristec-ui/core/Badge';
import IconButton from '../../../../juristec-ui/core/IconButton';
import { Close } from '../../../../juristec-ui/icons';

import {
  ThirdPageContainer,
  ColumnContainer,
  BadgersContainer,
  ScrollContainer,
  OptionalLabel,
} from './styled/ThirdStep.styled';

const legacyTypes = {
  float: 'float64',
  date: 'datetime64[ns]',
  abc: 'category',
};

/**
 * Third step for the 'ApplyTemplate'. This is where the filters for the columns can be applied.
 */
const ThirdStep = ({
  fileColumns,
  columnData,
  columnToFilter,
  setColumnToFilter,
  filterColumns,
  setFilterColumns,
  dateFormat,
  setDateFormat,
  dateOptions,
}) => {
  const [columnDataSelected, setColumnDataSelected] = useState(null);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    setOptions(Object.keys(legacyTypes).reduce((aux, colType) => {
      aux = [...aux, ...fileColumns[colType].map((col) => ({
        id: col,
        label: col,
        value: col,
        type: legacyTypes[colType],
      }))];
      return aux;
    }, []));
  }, [fileColumns]);

  const handleColumnToFilter = (selected) => {
    setColumnToFilter(selected);
    setColumnDataSelected(null);
  };

  const handleColumnDataSelected = (selected) => {
    setColumnDataSelected(
      selected && selected[0]?.value === 'select_all' ? columnData : selected,
    );
  };

  const applyFilter = () => {
    /* setFilterColumns((old) => ({
      ...old,
      [columnToFilter.value]: columnDataSelected,
    })); */
    const idx = filterColumns.findIndex((f) => f.column === columnToFilter.value);
    const filtersAux = [...filterColumns];
    if (idx > -1) {
      filtersAux.splice(idx, 1);
    }
    filtersAux.push({
      column: columnToFilter.value,
      selector: columnToFilter.type === 'datetime64[ns]' ? dateFormat.value : '',
      values: columnDataSelected.map((d) => d.value),
    });
    setFilterColumns(filtersAux);
    setColumnToFilter({ label: '', value: '' });
    setDateFormat(dateOptions[0]);
    setColumnDataSelected(null);
  };

  const removeFilter = (columnName) => {
    const idx = filterColumns.findIndex((f) => f.column === columnName);
    const filtersAux = [...filterColumns];
    if (idx > -1) {
      filtersAux.splice(idx, 1);
    }
    setFilterColumns(filtersAux);
  };

  return (
    <>
      <OptionalLabel>Não é obrigatório a utilização do filtro!</OptionalLabel>
      <ThirdPageContainer>
        <ColumnContainer style={{ gap: '10px' }}>
          <Select
            placement="start"
            selectLabel="Coluna para filtrar"
            options={options}
            value={columnToFilter}
            onChange={(selected) => handleColumnToFilter(selected)}
            placeholder=""
            alphabeticalOrder
            isSearchable
            fullWidth
            atModal
          />
          {columnToFilter.value?.length > 0 && columnToFilter.type === 'datetime64[ns]' && (
            <Select
              placement="start"
              selectLabel="Selecione um formato"
              options={dateOptions}
              value={dateFormat}
              onChange={(selected) => setDateFormat(selected)}
              placeholder=""
              alphabeticalOrder
              isSearchable
              fullWidth
              atModal
            />
          )}
          {columnToFilter.value?.length > 0 && (
            <>
              <Select
                placement="start"
                selectLabel="Selecione um ou mais valores"
                options={columnData}
                value={columnDataSelected}
                onChange={(selected) => handleColumnDataSelected(selected)}
                placeholder=""
                alphabeticalOrder
                isSearchable
                fullWidth
                isMult
                allowSelectAll
                isClearable
                atModal
              />
              <Button
                disabled={!columnDataSelected}
                onClick={applyFilter}
                color="secondary"
              >
                Adicionar filtro
              </Button>
            </>
          )}
        </ColumnContainer>
        <ColumnContainer>
          <span className="label">Filtros Aplicados:</span>
          <ScrollContainer>
            {filterColumns.length > 0 ? (
              filterColumns.map((filterC) => (
                <BadgersContainer key={filterC.column}>
                  <div className="badgersHeader">
                    <span>{filterC.column}</span>
                    <IconButton
                      color="dark"
                      size="small"
                      onClick={() => removeFilter(filterC.column)}
                    >
                      <Close />
                    </IconButton>
                  </div>
                  {filterC.values.map((value) => (
                    <Badge
                      key={value}
                      color="secondary"
                      textTransform="uppercase"
                      size="small"
                    >
                      <div>{value}</div>
                    </Badge>
                  ))}
                </BadgersContainer>
              ))
            ) : (
              <BadgersContainer>
                <div className="badgersHeader">
                  <span>Nenhum filtro selecionado</span>
                </div>
              </BadgersContainer>
            )}
          </ScrollContainer>
        </ColumnContainer>
      </ThirdPageContainer>
    </>
  );
};

ThirdStep.defaultProps = {
  columnData: [],
};

ThirdStep.propTypes = {
  /**
   * Array of columns names of the selected file
   */
  fileColumns: PropTypes.shape({
    abc: PropTypes.arrayOf(PropTypes.string),
    float: PropTypes.arrayOf(PropTypes.string),
    date: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  /**
   * Column selected to receive filters
   */
  columnToFilter: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    type: PropTypes.string,
  }).isRequired,
  /**
   * Function that deals with the selection of the column
   */
  setColumnToFilter: PropTypes.func.isRequired,
  /**
   * Data options from the selected column to be applied for filter
   */
  columnData: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })),
  /**
   * Array with filters applied to the columns
   */
  filterColumns: PropTypes.arrayOf(
    PropTypes.shape({
      column: PropTypes.string.isRequired,
      selector: PropTypes.string.isRequired,
      values: PropTypes.arrayOf(PropTypes.string.isRequired),
    }),
  ).isRequired,
  /**
   * Function that handles applying filters to columns
   */
  setFilterColumns: PropTypes.func.isRequired,
  /**
   * Format selected for the date filter
   */
  dateFormat: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
  /**
   * Function that handles the date format selection
   */
  setDateFormat: PropTypes.func.isRequired,
  /**
   * Options for the date type select
   */
  dateOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default ThirdStep;
