import styled, { css } from 'styled-components';

export const StyledPopover = styled.div`
  position: relative;
  display: inline-flex;
  user-select: none;
  cursor: pointer;
  margin: 0 5px;
  -webkit-tap-highlight-color: transparent;
`;

export const StyledContentPopover = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 12;
  font-size: 12px;
  cursor: default;
  border-radius: 10px;
  white-space: nowrap;
  background-color: ${({ theme }) => theme.popoverBackground};
  box-shadow: 0px 10px 1rem ${({ theme }) => theme.shadow};
  min-width: 110px;

  position: absolute;
  top:0;
  left: 0;
`;

export const StyledContainerPopover = styled.div`
  position: relative;

  ${(props) => props.absolute && css`
    position: absolute; 
    right: 0; 
    top: 0; 
    z-index: 1;
  `}
`;
