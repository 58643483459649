import React, { useState } from 'react';
import PropTypes from 'prop-types';

import InputTextLine from '../../../juristec-ui/core/InputTextLine';
import ActionsGroup from '../../../juristec-ui/core/ActionsGroup';
import Button from '../../../juristec-ui/core/Button';
import IconButton from '../../../juristec-ui/core/IconButton';

import useToggleState from '../../../juristec-ui/hooks/useToggleState';

import { verifyCurrentPassword } from '../../../juristec-ui/utils/validators/inputTextValidators';

import { Form, InputWrapper } from './styled/ReAuthentication.styled';

import { Visibility, VisibilityOff } from '../../../juristec-ui/icons';

const breakSubmit = (e) => {
  e.preventDefault();
  e.stopPropagation();
};

/** Modal to ask for the password to reauthenticate the user */
const ReAuthentication = ({ close, submitPassword }) => {
  const [currentPassword, setCurrentPassword] = useState({ value: '', error: true, errorMsg: '' });
  const [showPass, toggleShowPass] = useToggleState(false);

  const handlePassword = (val) => {
    const msg = verifyCurrentPassword(val);
    setCurrentPassword({
      value: val,
      error: msg.length !== 0,
      errorMsg: msg,
    });
  };

  const onChangeHandler = (e) => handlePassword(e.target.value);

  const handleSubmit = () => {
    if (!currentPassword.error) {
      submitPassword(currentPassword.value);
      close();
    } else {
      handlePassword(currentPassword.value);
    }
  };

  const breakEnter = (e) => {
    if (e.which === 13 || e.keyCode === 13) {
      breakSubmit(e);
      handleSubmit();
    }
  };

  return (
    <Form onSubmit={breakSubmit} onKeyDown={breakEnter}>
      <span>Para continuar, é necessário fazer login novamente</span>
      <InputWrapper>
        <InputTextLine
          errorMessage={currentPassword.errorMsg}
          error={currentPassword.errorMsg.length > 0}
          name="currentPassword"
          label="Senha atual"
          type={!showPass ? 'password' : 'text'}
          value={currentPassword.value}
          onChange={onChangeHandler}
        />
        <IconButton
          style={{
            position: 'absolute', bottom: 4, right: 2, boxShadow: 'none', padding: 2,
          }}
          type="button"
          variant="pattern"
          iconColor="black"
          onMouseDown={toggleShowPass}
        >
          {showPass ? <VisibilityOff /> : <Visibility />}
        </IconButton>
      </InputWrapper>
      <ActionsGroup style={{ padding: '0' }}>
        <Button
          style={{ margin: '5px' }}
          onClick={close}
          variant="outlined"
          size="large"
        >
          Cancelar
        </Button>
        <Button
          style={{ margin: '5px' }}
          onClick={handleSubmit}
          size="large"
          disabled={currentPassword.error}
        >
          Continuar
        </Button>
      </ActionsGroup>
    </Form>
  );
};

ReAuthentication.propTypes = {
  /** Returns the entered password */
  submitPassword: PropTypes.func.isRequired,
  /** Closes the modal, nothing happens afterwards */
  close: PropTypes.func.isRequired,
};

export default ReAuthentication;
