import styled, { css } from 'styled-components';
import { devices } from '../../../juristec-ui/core/breakpoints';
import { gap } from '../../../juristec-ui/styles/theme';

export const MainContainer = styled.div`
  padding: 0 5%;
  @media ${devices.tablet} {
    padding: 0 20px;
  }

  & .report-icon svg {
    width: 18px;
    height: 18px;
  }
`;

export const TableOverFlow = styled.div`
  max-height: calc(100vh - 185px);
  overflow-y: auto;
  overflow-x: auto;
`;

export const IconFlipWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  & svg {
    width: 25px;
    height: 25px;
    fill: ${({ theme }) => theme.primary};
  }
  transition: all 0.2s;
  transform: rotate(-90deg);
  ${({ flip }) => flip && css`
    transform: rotate(0deg);
  `}
`;

export const CollapsibleContainer = styled.div`
  overflow: hidden;
  max-height: ${({ maxSize, control }) => (control ? maxSize : '0')}px;
  transition: 0.3s max-height ease-in-out;
  background-color: ${({ theme }) => theme.containerHover};
`;

export const Options = styled.div`
  display: flex;
  justify-content: center;
  ${gap('15px', 'row')}
  align-items: center;
`;

export const ExtraInfos = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 10px;
`;

export const ExtraItens = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  & > button {
    margin-left: 5px;
  }
`;

export const PlanLabel = styled.span`
  text-transform: uppercase;
  font-weight: bold;
  color: ${({ theme }) => theme.primary};
`;
