import styled from 'styled-components';
import { devices } from '../../../juristec-ui/core/breakpoints';

export const InstanceInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: flex-start;

  & .column-small {
    width: 40%;
  }

  & .column-large {
    width: 60%;
  }

  @media ${devices.phoneL} {
    flex-direction: column;
    & .column-small, .column-large {
      width: 100%;
    }
  }
`;

export const ScrollContainer = styled.main`
  @page {
      size: auto;
      margin: auto;
  }

  overflow-y: auto; 
  overflow-x: hidden;
  -webkit-overflow-scrolling: auto;
  padding: 5px;
  margin-top: 0rem;
  margin-left: 5%;
  margin-right: 5%;
  
  height: calc(100vh - 175px) !important;
  @media ${devices.tablet} {
    margin: 0px 0px;
    height: calc(100vh - 180px) !important;
  };

  @media ${devices.phoneL} {
    margin: 0px 0px;
    height: calc(100vh - 180px) !important;
  }
`;

export const InstanceCardsRow = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(50%, 1fr));
  grid-template-rows: calc((100vh - 175px) / 2);
  @media ${devices.tablet} {
    grid-template-columns: repeat(auto-fit, 100%);
    grid-auto-rows: calc((100vh - 175px) / 2);
  };
`;

export const ChartCard = styled.div`
  background-color: ${({ theme }) => theme.backgroundOdd};
  border-radius: 1rem;
  padding: .5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & h3 {
    text-align: center;
    margin: 0;
  }
  box-shadow: 0px 4px 5px ${({ theme }) => theme.shadow};
  margin: .5rem;
`;

export const OverflowContainer = styled.div`
  height: 100%;
  width: 100%;
  overflow: hidden auto;
`;
