import React, {
  useContext, useEffect, useState, useRef,
} from 'react';
import {
  useParams, useHistory,
} from 'react-router-dom';

import urlHash from '../../utils/functions/urlHash';
import useGridKPI from '../../hooks/useGridKpi';
import { FilesContext } from '../../context/FilesProvider';
import { AuthContext } from '../../context/AuthProvider';
import { AlertContext } from '../../context/AlertProvider';
import { ModalContext } from '../../context/ModalProvider';

import LabelCreate from '../../components/LabelCreate';
import LabelCreateToolbar from '../../components/Toolbars/LabelCreateToolbar';
import UnsplashPicker from '../../juristec-ui/core/UnsplashPicker';
import Loader from '../../juristec-ui/core/Loader';
import FilePreview from '../../components/Modals/FilePreview';

import handleStyleConfig from '../../components/LabelCreate/handleLabelConfig';
import { labelCreateInitialState } from '../../options';

export default function LabelCreatePage() {
  const history = useHistory();
  const { hash } = useParams();
  const goBack = () => history.goBack();

  const getParams = () => {
    const str = urlHash(hash, true).split('/');
    return { dashboardKey: str[0], kpiKey: str[1] };
  };
  const { dashboardKey, kpiKey } = getParams();

  const { userCompany, currentUser, user } = useContext(AuthContext);
  const { setModalConfig, toggleModal } = useContext(ModalContext);
  const { setAlertConfig } = useContext(AlertContext);
  const { isLoading: fileIsLoading, state: filesState, filesAPI } = useContext(FilesContext);
  const [{
    dashboardDoc,
    kpisLayout,
    isLoading,
  }, gridKpiAPI] = useGridKPI(currentUser.uid, dashboardKey, currentUser, user);

  const refCode = useRef('');

  const [styleConfig, setStyleConfig] = useState(labelCreateInitialState);
  const [rawImg, setRawImg] = useState('');
  const [isSmartLabel, setIsSmartLabel] = useState(false);
  const [content, setContent] = useState('');
  const [question, setQuestion] = useState({ value: '', isDirty: true });
  const [selectedDatabase, setSelectedDatabase] = useState({
    label: '', value: '', id: '', owner: '',
  });
  const [dataFrame, setDataFrame] = useState(null);
  const [gptLoading, setGptLoading] = useState(false);

  useEffect(() => {
    if (kpiKey !== 'new') {
      gridKpiAPI.getKpiInfo(kpiKey).then((kpi) => {
        if (kpi) {
          const captitalize = (s) => s.charAt(0).toUpperCase() + s.slice(1);
          setStyleConfig({
            ...labelCreateInitialState,
            ...kpi.style,
            fontFamily: { value: kpi.style.fontFamily, label: captitalize(kpi.style.fontFamily) },
            name: kpi.name,
            link: kpi.link,
            kpiKey,
          });
          setIsSmartLabel(kpi.type === 'smartLabel');
          setContent(kpi.name || '');
          setQuestion({ value: kpi.question || '', isDirty: false });
          refCode.current = kpi.code || '';
          setRawImg(kpi.style?.image || '');
          setSelectedDatabase({
            label: '', value: kpi.database || '', id: kpi.database || '', owner: kpi.fileOwner || '',
          });
        } else {
          goBack();
        }
      }).catch((err) => {
        console.log('errr', err);
      });
    }
    gridKpiAPI.loadLayoutAndDash();
  }, [gridKpiAPI, kpiKey]);

  useEffect(() => {
    if (!user || !currentUser || !userCompany?.gptApi || filesState.started) return;
    (async () => {
      const filesRes = await filesAPI.init();
      if (filesRes.error) {
        setAlertConfig({
          type: 'error',
          text: filesRes.msg,
        });
      }
    })();
  }, [filesAPI, currentUser, user, filesState.started]);

  useEffect(() => {
    if (selectedDatabase.value !== '') {
      (async () => {
        setGptLoading(true);
        if (dataFrame) {
          setContent('');
        }
        const sampleRes = await filesAPI.getFileSample(selectedDatabase.value, false);
        if (sampleRes.error) {
          goBack();
          setAlertConfig({
            type: 'error',
            text: sampleRes.msg,
            child: sampleRes.raw,
          });
          goBack();
        } else {
          setDataFrame(sampleRes.res);
        }
        setGptLoading(false);
      })();
    }
  }, [selectedDatabase, filesAPI]);

  const submitLabel = async (config) => {
    const LabelObject = {
      name: content,
      link: styleConfig.link || '',
      style: {
        bgColor: styleConfig.bgColor,
        fontColor: styleConfig.fontColor,
        fontSize: styleConfig.fontSize,
        bold: styleConfig.bold,
        underline: styleConfig.underline,
        fontFamily: styleConfig.fontFamily.value,
        italic: styleConfig.italic,
        colorOpacityText: styleConfig.colorOpacityText,
        opacityText: styleConfig.opacityText,
        opacityImage: styleConfig.opacityImage,
        imageFit: styleConfig.imageFit,
        textPosition: styleConfig.textPosition,
        UseGlobalColor: styleConfig.UseGlobalColor ?? true,
      },
      status: 'active',
      type: 'label',
    };

    if (isSmartLabel) {
      LabelObject.type = 'smartLabel';
      LabelObject.question = question.value;
      LabelObject.code = refCode.current;
      LabelObject.database = selectedDatabase.id;
      LabelObject.fileOwner = selectedDatabase.owner;
    }
    const result = await gridKpiAPI.saveInsightCard(
      kpisLayout, kpiKey, LabelObject, rawImg,
    );
    if (result.error) {
      setAlertConfig({
        type: 'error',
        text: result.msg,
      });
      return;
    }

    URL.revokeObjectURL(config?.style?.image);
    goBack();
  };

  const handleStyleChanges = (value, diff) => {
    setStyleConfig(handleStyleConfig(styleConfig, diff, value));
  };

  const handleImgPick = (url) => {
    setStyleConfig((prev) => ({ ...prev, image: url }));
    setRawImg(url);
  };

  const addDesktopImg = (file) => {
    URL.revokeObjectURL(styleConfig.image);
    setStyleConfig((prev) => ({ ...prev, image: URL.createObjectURL(file) }));
    setRawImg(file);
  };

  const removeImg = () => {
    URL.revokeObjectURL(styleConfig.image);
    setStyleConfig((prev) => ({ ...prev, image: '' }));
    setRawImg('');
  };

  const openUnsplashPicker = () => {
    setModalConfig({
      title: 'Selecionar Fotos',
      children: (
        <UnsplashPicker
          closeModal={() => toggleModal('unsplash-picker-modal')}
          selectImg={handleImgPick}
          defaultSearch="Insight"
        />
      ),
      nodeTarget: 'unsplash-picker-modal',
    });
  };

  const checkInputs = () => {
    const val = content?.trim() || '';
    if (isSmartLabel) {
      return (
        (val.length > 0 && val.length <= 2000)
        && selectedDatabase.id?.length > 0
        && !question.isDirty
        && !gptLoading
      );
    }
    return (
      (val.length > 0 && val.length <= 2000)
      || styleConfig?.image?.length > 0
    );
  };

  const openFilePreviewModal = () => {
    setModalConfig({
      title: selectedDatabase.label,
      children: !!dataFrame && <FilePreview dataFrame={dataFrame} />,
    });
  };

  const handleVirtualAssistant = async () => {
    if (selectedDatabase.id !== '') {
      setGptLoading(true);
      const gptRes = await filesAPI.callVirtualAssistant(selectedDatabase.id, question.value, 'insight');
      setContent(gptRes.message);
      setQuestion((q) => ({ ...q, isDirty: false }));
      refCode.current = gptRes.code;
      setGptLoading(false);
    }
  };

  return (
    <>
      {(fileIsLoading || isLoading) && <Loader /> }
      <LabelCreateToolbar
        isEdit={!!dashboardDoc && kpiKey !== 'new'}
        dashboardTitle={dashboardDoc?.name || ''}
        handleClickReturn={goBack}
        handleSubmit={submitLabel}
        disableSave={!checkInputs()}
      />
      <LabelCreate
        allowGpt={userCompany?.gptApi}
        styleConfig={styleConfig}
        handleStyleChanges={handleStyleChanges}
        rawImgState={[rawImg, setRawImg]}
        isSmartLabelState={[isSmartLabel, setIsSmartLabel]}
        contentState={[content, setContent]}
        handleUnsplashPicker={openUnsplashPicker}
        addDesktopImg={addDesktopImg}
        removeImg={removeImg}
        databaseOptions={filesState.files}
        selectedDatabaseState={[selectedDatabase, setSelectedDatabase]}
        openFilePreviewModal={openFilePreviewModal}
        columns={dataFrame?.types || {}}
        questionState={[question, setQuestion]}
        submitQuestion={handleVirtualAssistant}
        gptIsLoading={gptLoading}
        dashboardDoc={dashboardDoc}
      />
    </>
  );
}
