import React from 'react';
import PropTypes from 'prop-types';

const AtSign = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="white"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 0C4.47343 0 0 4.47254 0 10C0 15.5266 4.47254 20 10 20C11.9417 20 13.8444 19.4298 15.46 18.3781C15.9441 18.063 16.0497 17.3988 15.6839 16.9518L15.2735 16.4505C14.9642 16.0726 14.4195 15.98 14.0085 16.2437C12.8194 17.0063 11.424 17.4194 10 17.4194C5.90895 17.4194 2.58065 14.091 2.58065 10C2.58065 5.90895 5.90895 2.58065 10 2.58065C14.0379 2.58065 17.4194 4.90399 17.4194 9.03226C17.4194 10.5962 16.5688 12.2477 15.0738 12.407C14.3742 12.3887 14.3919 11.8885 14.5304 11.1963L15.4753 6.31286C15.5908 5.71573 15.1334 5.16129 14.5252 5.16129H12.7115C12.5775 5.16129 12.4483 5.21058 12.3484 5.29978C12.2485 5.38897 12.1849 5.51182 12.1698 5.64488L12.1694 5.64859C11.5768 4.92677 10.5385 4.77056 9.75125 4.77056C6.74399 4.77056 4.19355 7.28 4.19355 10.8778C4.19355 13.511 5.67681 15.1468 8.06452 15.1468C9.15258 15.1468 10.3778 14.5162 11.0883 13.6011C11.4723 14.9762 12.726 14.9762 13.9396 14.9762C18.331 14.9762 20 12.0886 20 9.03226C20 3.53439 15.5654 0 10 0ZM9.12581 12.2754C8.22867 12.2754 7.67137 11.6454 7.67137 10.6314C7.67137 8.81718 8.91246 7.69879 10.0355 7.69879C10.9344 7.69879 11.471 8.31335 11.471 9.34274C11.471 11.1597 10.1051 12.2754 9.12581 12.2754Z"
    />
  </svg>
);

AtSign.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

AtSign.defaultProps = {
  width: '25px',
  height: '25px',
};

export default AtSign;
