import { initialConfig as BarInitialConfig } from '../../charts/Bar';
import { initialConfig as PieInitialConfig } from '../../charts/Pie';
import { initialConfig as lineInitialConfig } from '../../charts/Line';
import { initialConfig as ValueInitialConfig } from '../../charts/Value';
import { initialConfig as RadarInitialConfig } from '../../charts/Radar';
import { initialConfig as MapInitialConfig } from '../../charts/Map';
import { initialConfig as FunnelInitialConfig } from '../../charts/Funnel';
import { initialConfig as RadialBarInitialConfig } from '../../charts/RadialBar';
import { initialConfig as TableInitialConfig } from '../../charts/Table';
import { initialConfig as CalendarInitialConfig } from '../../charts/Calendar';
import { initialConfig as WaffleInitialConfig } from '../../charts/Waffle';
import { initialConfig as BoxPlotInitialConfig } from '../../charts/BoxPlot';
import { initialConfig as ScatterPlotInitialConfig } from '../../charts/ScatterPlot';
import { initialConfig as SubsetTableInitialConfig } from '../../charts/SubsetTable';

const defaultStyles = {
  Bar: BarInitialConfig,
  Pie: PieInitialConfig,
  Line: lineInitialConfig,
  Value: ValueInitialConfig,
  Radar: RadarInitialConfig,
  Map: MapInitialConfig,
  Funnel: FunnelInitialConfig,
  RadialBar: RadialBarInitialConfig,
  Table: TableInitialConfig,
  Calendar: CalendarInitialConfig,
  Waffle: WaffleInitialConfig,
  BoxPlot: BoxPlotInitialConfig,
  ScatterPlot: ScatterPlotInitialConfig,
  SubsetTable: SubsetTableInitialConfig,
};
Object.freeze(defaultStyles);

export default function getDefaultStyle(type) {
  return defaultStyles[type] || {};
}
