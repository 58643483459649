export default {
  // TITLE
  TitleControl: '',
  ShowTitleControl: { checked: true },
  StyleTitleControl: {
    fontSize: 20,
    color: '#000000',
    fontFamily: 'arial',
    bold: false,
    italic: false,
    underline: false,
  },
  // GENERAL
  LayoutControl: 'horizontal',
  YearSpacingControl: 30,
  MonthSpacingControl: 4,
  DaySpacingControl: 0,
  Margin: {
    top: 30,
    right: 30,
    bottom: 110,
    left: 30,
  },
  MinValueControl: { value: 0, checked: false },
  MaxValueControl: { value: 0, checked: true },
  DataFormat: { type: 'absolute', target: 'global' },
  // STYLE
  EmptyColorControl: '#eee',
  MonthWidthControl: 2,
  MonthColorControl: '#000',
  DayWidthControl: 1,
  DayColorControl: '#000',
  QtdColorsControl: 5,
  GradientPickerControl: {
    color1: '#4711B2',
    color2: '#FF9626',
    color3: null,
  },
  UseGlobalColor: true,
  // LABEL
  EnableLabelControl: { checked: true },
  LabelTextColorControl: '#000',
  LabelsFontFamilyControl: { value: 'sans-serif' },
  LabelsFontSizeControl: { value: 15 },
  YearPositionControl: 'before',
  YearOffsetControl: 10,
  MonthPositionControl: 'before',
  MonthOffsetControl: 10,
  // LEGEND
  LegendControl: { checked: true },
  LegendPlacementControl: {
    value: {
      label: 'Abaixo',
      translateX: 0,
      translateY: -100,
      selectedPosition: 'bot',
      direction: 'row',
      anchor: 'bottom',
    },
  },
  LegendsFontFamilyControl: { value: 'sans-serif' },
  LegendsFontSizeControl: { value: 12 },
  LegendsSpacingControl: { value: 10 },
  LegendSymbolControl: 'circle',
  // TOOLTIP / INTERACTIVE
  InteractiveControl: true,
  InteractivePrefixControl: '',
  InteractiveSufixControl: '',
  InteractiveDecimals: 'auto',
  InteractiveSeparadorNumericoCasas: 0,
  InteractiveCurrencyControl: 'Nenhum',
  InteractiveGroupingControl: '',
};
