import styled from 'styled-components';

export const StyledPublicGridKpisCard = styled.div`
    height: 100%;  
`;

export const Comment = styled.p`
    font-size: 1rem;
    text-align: justify;
    white-space: break-spaces;
    margin: 2px 0;
    padding: 5px 3px;
`;
