import React from 'react';
import PropTypes from 'prop-types';

// import { Container } from './styles';

const ProfileUser = ({ width, height }) => {
  return (
    <svg
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 512 512"
      xmlSpace="preserve"
      width={width}
      height={height}
    >
      <g>
        <path d="M255.999,0c-74.443,0-135,60.557-135,135s60.557,135,135,135s135-60.557,135-135S330.442,0,255.999,0z" />
      </g>
      <g>
        <g>
          <path d="M478.48,398.68C438.124,338.138,370.579,302,297.835,302h-83.672c-72.744,0-140.288,36.138-180.644,96.68l-2.52,3.779V512
            h450h0.001V402.459L478.48,398.68z"
          />
        </g>
      </g>
    </svg>
  );
};

ProfileUser.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

ProfileUser.defaultProps = {
  width: '24px',
  height: '24px',
};

export default ProfileUser;
