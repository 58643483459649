export default function (newConfig, diff, value) {
  const styleConfig = { ...newConfig };

  const linkFormatter = (url) => {
    // eslint-disable-next-line no-param-reassign
    if (url) url = url.startsWith('https://') ? url : `https://${url}`;
    return url;
  };

  switch (diff) {
    case 'fontFamily':
      styleConfig.fontFamily = { value };
      break;
    case 'link':
      styleConfig.link = linkFormatter(value);
      break;
    default:
      styleConfig[diff] = value;
      break;
  }
  return styleConfig;
}
