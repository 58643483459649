import styled, { css } from 'styled-components';

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const Col = styled.div`
  display: flex;
  flex-direction: column;
`;

export const MainContainer = styled(Col)`
  background-color: ${({ theme }) => theme.controllersLabel};
  border-radius: 8px;
  overflow: clip;
`;

export const HeaderContainer = styled(Row)`
  justify-content: flex-start;
  gap: 5px;
  align-items: start;
  padding: 5px;
`;

export const TitleContainer = styled(Col)`
  font-weight: bold;
  overflow: hidden;
  margin: 0 auto 0 0;
  & .header-title {
    color: ${({ theme }) => theme.color};
    font-size: 16px;
  }
  & .header-subtitle {
    color: ${({ theme }) => theme.grey};
    font-size: 14px;
  }
`;

export const CollapsibleContainer = styled(Col)`
  overflow: hidden;
  max-height: ${(props) => (props.control ? (props.customSize || 100) : 0)}px;
  transition: 0.3s max-height ease-in-out;
`;

export const ControlBtn = styled.button`
  height: auto;
  width: 100%;
  background-color: transparent;
  cursor: pointer;
  border: none;
  display: ${(props) => (props.lock ? 'none' : 'initial')};
  & .flip-wrapper {
    transition: all 0.5s;
    ${(props) => props.flip && css`
      transform: rotate(180deg);
    `}
  }
  & svg {
    fill: ${({ theme }) => theme.primary};
  }
  &:hover {
    background-color: ${({ theme }) => theme.grey}22;
  }
`;

export const BadgesContainer = styled(Row)`
  flex-wrap: wrap;
  gap: 5px;
  max-height: auto;
  overflow-y: auto;
  margin: 5px 5px 10px;
  & b {
    width: 100%;
  }
  & button {
    min-width: auto;
    font-size: 12px;
  }
`;

export const OrderControls = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  gap: 4px;
  ${(props) => props.disableFirst && css`
    & div:first-child {
      cursor: normal;
      pointer-events: none;
      & svg {
        fill: ${({ theme }) => theme.grey};
      }
    }
  `}
  ${(props) => props.disableLast && css`
    & div:last-child {
      cursor: normal;
      pointer-events: none;
      & svg {
        fill: ${({ theme }) => theme.grey};
      }
    }
  `}
`;

export const OrderBtn = styled.button`
  height: 14px;
  width: 20px;
  padding: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;
  & svg {
    height: 15px;
    width: 15px;
    fill: ${({ theme }) => theme.primary};
  }
  &:hover svg {
    fill: ${({ theme }) => theme.primaryHover};
  }
  &.order-decrease {
    transform: rotate(180deg);
  }
`;
