import styled from 'styled-components';
import { devices } from '../../../../juristec-ui/core/breakpoints';

export const MoreMemoryContainer = styled.div`
    //min-height: 50px;
    max-width: 500px;
    padding: 0 1rem;
`;

export const RadioGroupContainer = styled.div`
  padding: 0 3rem;
  display: grid;
  grid-template-columns: repeat(2, 50%);
  justify-items: center;
  gap: 0 1rem;

  @media ${devices.phoneS} {
    padding: 0 1rem;
    gap: 0;
  }
`;

export const RadioInputWrapper = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  white-space: nowrap;
`;

export const SeparatorLine = styled.hr`
  margin: 10px auto;
  width: 100%;
  border: 0.8px solid #C4C4C4;
`;
