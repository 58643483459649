import React from 'react';
import PropTypes from 'prop-types';

import IconButton from '../../../juristec-ui/core/IconButton';
import Popover from '../../../juristec-ui/core/Popover';
import GlobalFilter from '../../Popovers/GlobalFilter';
import Tooltip from '../../../juristec-ui/core/Tooltip';

import compare from '../../../utils/functions/sorting';
import { expDateOrder } from '../../../juristec-ui/utils/formatCharts/orderDatetime';

import { Edit, ExpandMore, ArrowLeft } from '../../../juristec-ui/icons';

import {
  MainContainer,
  ContentRow,
  CenteredItens,
  FilterBadge,
  IconFlipWrapper,
  InfoTooltip,
} from './styled/GlobalFiltersToolbar.styled';

const GlobalFiltersToolbar = ({
  toggleToolbar,
  globalFilters,
  globalFiltersOrder,
  globalFilterSelectors,
  setGlobalFilterSelectors,
  getColumnValues,
  filterColumnsModal,
  isOwner,
}) => {
  const closeGlobalFilters = () => {
    toggleToolbar(false);
  };

  const globalFilterUpdate = (fId, data = {}) => {
    setGlobalFilterSelectors((gfilters) => ({
      ...gfilters,
      [fId]: {
        ...gfilters[fId],
        ...data,
      },
    }));
  };

  const closeGlobalFilter = (fId) => globalFilterUpdate(fId, { isOpen: false });

  const loadColumnValues = async (fId, dateFormat) => {
    const gFilter = globalFilterSelectors[fId];
    if (gFilter.column && gFilter.database) {
      const res = await getColumnValues(
        gFilter.database, gFilter.column, dateFormat,
      );
      if (res.fileError) {
        globalFilterUpdate(fId, { fileError: [...res.fileError] });
      } else {
        res.opts.sort((a, b) => (compare(
          expDateOrder(a.label), expDateOrder(b.label),
        )));
        globalFilterUpdate(fId, { options: [...res.opts], lastSelector: dateFormat });
      }
    }
  };

  const toggleGlobalFilter = (fId) => {
    const gFilter = globalFilterSelectors[fId];
    if (!gFilter.isOpen) globalFilterUpdate(fId, { isOpen: true });
    else closeGlobalFilter(fId);
  };

  const handleGlobalFilter = (fId, selected, fType, dateFormat, isInverse) => {
    globalFilterUpdate(fId, {
      selected, fType: isInverse ? `not-${fType}` : fType, selector: dateFormat || '',
    });
    const oldRef = globalFilters.current || {};
    let filterInfo = null;
    const filterData = globalFilterSelectors[fId];
    const auxOrder = new Set(globalFiltersOrder.current || []);
    if (isInverse || selected?.length !== filterData.options?.length) {
      filterInfo = {
        ftype: fType,
        inverse: isInverse,
        values: selected,
        column: filterData.column,
        selector: dateFormat || '',
      };
      auxOrder.add(filterData.uid);
    } else {
      auxOrder.delete(filterData.uid);
    }
    globalFiltersOrder.current = [...auxOrder];
    globalFilters.current = {
      ...oldRef,
      [filterData.database]: {
        ...oldRef[filterData.database],
        [filterData.uid]: filterInfo,
      },
    };
  };

  const filterColumnsModalHandle = () => {
    filterColumnsModal(toggleToolbar);
  };

  const checkIfActive = (filterData) => (
    !!globalFilters?.current[filterData.database]?.[filterData.uid]
  );

  return (
    <MainContainer>
      <ContentRow>
        <Tooltip text="Voltar para toolbar">
          <IconButton
            shape="rounded"
            color="secondary"
            variant="contained"
            onClick={closeGlobalFilters}
          >
            <ArrowLeft />
          </IconButton>
        </Tooltip>
        <CenteredItens>
          {Object.keys(globalFilterSelectors).map((gKey) => {
            const order = globalFiltersOrder?.current?.indexOf(gKey);
            return (
              <Popover
                key={gKey}
                open={globalFilterSelectors[gKey].isOpen || false}
                closePopover={() => closeGlobalFilter(gKey)}
              >
                <Popover.Action>
                  <Tooltip
                    text={(
                      <InfoTooltip>
                        {`Arquivo: ${globalFilterSelectors[gKey].filename}`}
                      </InfoTooltip>
                    )}
                    direction="top"
                  >
                    <FilterBadge
                      onClick={() => toggleGlobalFilter(gKey)}
                      isActive={
                        checkIfActive(globalFilterSelectors[gKey])
                      }
                    >
                      <span>{`${order > -1 ? `${order + 1}º - `: ''}${globalFilterSelectors[gKey].column}`}</span>
                      <IconFlipWrapper flip={globalFilterSelectors[gKey].isOpen || false}>
                        <ExpandMore />
                      </IconFlipWrapper>
                    </FilterBadge>
                  </Tooltip>
                </Popover.Action>
                <Popover.Content stayOpened offset={[-20, 2]} hasSelect>
                  <GlobalFilter
                    close={() => closeGlobalFilter(gKey)}
                    infoFilter={globalFilterSelectors[gKey]}
                    handleFilter={(selected, fType, dateFormat, inverse) => {
                      handleGlobalFilter(gKey, selected, fType, dateFormat, inverse);
                    }}
                    loadSimpleOpts={(dateFormat) => loadColumnValues(gKey, dateFormat)}
                  />
                </Popover.Content>
              </Popover>
            );
          })}
        </CenteredItens>
        {isOwner && (
          <Tooltip text="Gerenciar variáveis">
            <IconButton
              shape="rounded"
              color="secondary"
              variant="contained"
              onClick={filterColumnsModalHandle}
            >
              <Edit />
            </IconButton>
          </Tooltip>
        )}
      </ContentRow>
    </MainContainer>
  );
};

GlobalFiltersToolbar.propTypes = {
  toggleToolbar: PropTypes.func.isRequired,
  globalFilters: PropTypes.shape({
    current: PropTypes.objectOf(PropTypes.any),
  }).isRequired,
  globalFiltersOrder: PropTypes.shape({
    current: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  globalFilterSelectors: PropTypes.objectOf(PropTypes.any),
  setGlobalFilterSelectors: PropTypes.func.isRequired,
  getColumnValues: PropTypes.func.isRequired,
  filterColumnsModal: PropTypes.func.isRequired,
  isOwner: PropTypes.bool.isRequired,
};

GlobalFiltersToolbar.defaultProps = {
  globalFilterSelectors: {},
};


export default GlobalFiltersToolbar;
