import styled from 'styled-components';
import { devices } from '../../../../../juristec-ui/core/breakpoints';
import Tooltip from '../../../../../juristec-ui/core/Tooltip';

const colorBy = (key) => {
  switch (key) {
    case 'abc':
      return 'typeText';
    case 'float':
      return 'typeNumber';
    case 'date':
      return 'typeDate';
    default:
      return 'secondary';
  }
};

export const FirstPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 600px;
  max-height: calc(100vh - 335px);

  @media ${devices.phoneS}, ${devices.phoneL} {
    width: 100%;
  }
`;

export const GridContainer = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  gap: 5px;
  grid-template-columns: repeat(2, minmax(0, 1fr));
`;

export const SelectContainerFile = styled.div`
  width: 100%;
  position: relative;
  margin: 0 0 .5rem;
`;

export const TypesContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  & > div {
    margin-left: .5rem;
    &:first-child {
      margin-left: 0;
    }
  }
`;

export const TypeTooltip = styled(Tooltip)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: .2rem;
  width: 74px;
  box-sizing: border-box;
  background-color: ${({ theme, typeLabel }) => theme[colorBy(typeLabel)]};
  color: ${({ theme, isLess }) => (isLess ? theme.error : theme.white)};
  border-radius: .5rem;
  & h4 {
    margin: 0;
  }
  & > div {
    width: 25px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    & svg {
      fill: ${({ theme }) => theme.white};
      height: 20px;
      width: 20px;
    }
  }
`;

export const ErrorMsg = styled.span`
  font-size: 12px;
  color: ${({ theme }) => theme.error};
`;

export const HeaderTips = styled.span`
  font-size: 14px;
  margin-bottom: 5px;
`;
