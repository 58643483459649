import styled from 'styled-components';

export const Container = styled.div`
    font-size: 14px;
    & > strong { 
        text-transform: uppercase;
    }
`;

export const ContainerRow = styled.div`
    margin: 15px;
`;

export const ConfirmText = styled.div`
    margin-top: 30px;
    display: flex;
    justify-content: center;
`;
