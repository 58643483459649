/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';

import ControllersGroup from '../../../controllers/ControllersGroup';
import {
  InputLineController,
  ButtonLineController,
  SelectController,
  RangeController,
  ChoiceBoxController,
  ToggleSwitchController,
} from '../../../controllers/Base';

import fontOptions from '../../../utils/controllersUtils/options';

const StyleControllers = ({
  handle,
  config,
  extend,
  setExtend,
  isGlobalThemeActive,
}) => (
  <>
    <ControllersGroup title="Estilos" id="styles" extend={extend} setExtend={setExtend}>
      <SelectController
        label="Fonte"
        handle={handle}
        kWord="GlobalValueFontFamily"
        options={fontOptions}
        configValue={config?.GlobalValue?.fontFamily}
      />
      <InputLineController
        label="Tamanho"
        handle={handle}
        kWord="GlobalValueFontSize"
        configValue={config?.GlobalValue?.fontSize}
        min={1}
        max={80}
        step={1}
        precision={2}
        isNumber
      />
      <ButtonLineController
        handle={handle}
        boldKWord="GlobalValueBold"
        boldVal={config?.GlobalValue?.bold}
        italicKWord="GlobalValueItalic"
        italicVal={config?.GlobalValue?.italic}
        underlineKWord="GlobalValueUnderline"
        underlineVal={config?.GlobalValue?.underline}
        fontColorKWord="GlobalValueFontColor"
        fontColorVal={config?.GlobalValue?.color}
        fontColorText="Utilizando tema global"
        fontColorDisabled={config?.UseGlobalColor !== false && isGlobalThemeActive}
        backgroundColorKWord="GlobalValueBackColor"
        backgroundColorVal={config?.GlobalValue?.background}
        backgroundColorText="Utilizando tema global"
        backgroundColorDisabled={config?.UseGlobalColor !== false && isGlobalThemeActive}
        fullWidth
      />
      <InputLineController
        label="Descrição"
        handle={handle}
        kWord="DescriptionControl"
        configValue={config?.DescriptionControl}
        fullWidth
      />
      <InputLineController
        label="Prefixo"
        handle={handle}
        kWord="PrefixControl"
        configValue={config?.PrefixControl}
        fullWidth
      />
      <InputLineController
        label="Sufixo"
        handle={handle}
        kWord="SufixControl"
        configValue={config?.SufixControl}
        fullWidth
      />
      <ChoiceBoxController
        label="Casas decimais"
        handle={handle}
        kWord="Decimals"
        options={[
          { value: 'auto', label: 'Automático', id: 'auto' },
          { value: 'fixed', label: 'Customizável', id: 'fixed' },
        ]}
        configValue={config?.Decimals || 'auto'}
        fullWidth
      />
      <RangeController
        label="Quantidade de casas"
        handle={handle}
        kWord="separadorNumericoCasas"
        configValue={config?.separadorNumericoCasas}
        min={0}
        max={5}
        step={1}
        precision={0}
        fullWidth
        disabled={config?.Decimals !== 'fixed'}
      />
      <SelectController
        label="Moeda"
        handle={handle}
        kWord="LabelCurrencyControl"
        options={['Nenhum', 'R$', 'US$', '€'].map((d) => ({ value: d, label: d, id: d }))}
        configValue={config?.LabelCurrencyControl}
      />
      <SelectController
        label="Agrupamento"
        handle={handle}
        kWord="GroupingSelectControl"
        options={[
          { value: 'Nenhum', label: 'Nenhum', id: 'Nenhum' },
          { value: 'milhar', label: 'Milhar (M)', id: 'Milhar (M)' },
          { value: 'milhao', label: 'Milhão (Mi)', id: 'Milhão (Mi)' },
          { value: 'bilhao', label: 'Bilhão (Bi)', id: 'Bilhão (Bi)' },
        ]}
        configValue={config?.GroupingSelectControl || 'Nenhum'}
      />
      <ToggleSwitchController
        label="Utilizar tema global"
        handle={handle}
        kWord="UseGlobalColor"
        configValue={config?.UseGlobalColor}
        firstOption=""
        secondOption=""
        fullWidth
      />
    </ControllersGroup>
  </>
);

StyleControllers.propTypes = {
  config: PropTypes.objectOf(PropTypes.any),
  handle: PropTypes.func,
  extend: PropTypes.string,
  setExtend: PropTypes.func,
  isGlobalThemeActive: PropTypes.bool,
};

StyleControllers.defaultProps = {
  config: undefined,
  handle: () => {},
  extend: '',
  setExtend: () => {},
  isGlobalThemeActive: false,
};

export default StyleControllers;
