import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const StyledInputRange = styled.input.attrs((props) => ({
  type: 'range',
  min: props.min,
  max: props.max,
  value: props.value ? props.value : 0,
  step: props.step,
}))`
    -webkit-appearance: none;
    width: 100%;
    height: 7px;
    border-radius: 4px;
    background: ${({ theme }) => theme.grey};
    outline: none;
    opacity: 0.7;
    -webkit-transition: .2s;
    transition: opacity .2s;
  
  &:hover {
    opacity: 1;
  }
  
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: ${({ theme }) => theme.primary};
    cursor: pointer;
  }
  
  &::-moz-range-thumb {
    border: none;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: ${({ theme }) => theme.primary};
    cursor: pointer;
  }
`;
