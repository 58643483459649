/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';

import DataTable from '../../../juristec-ui/core/DataTable';
import Tooltip from '../../../juristec-ui/core/Tooltip';
import IconButton from '../../../juristec-ui/core/IconButton';
import TablePagination from '../../../juristec-ui/core/TablePagination';

import { Trash } from '../../../juristec-ui/icons';

import { ProvisionsList, EmptyList, FileName } from './styled/ListFileProvisions.styles';
import { formatDateTime } from '../../../juristec-ui/utils/functions/lab';

/** Transfers all allowed data from one user to another */
const ListFileProvisions = ({
  user,
  provisions,
  provisionOwner,
  parentFilename,
  deleteProvision,
}) => {
  const [_provisions, _setProvisions] = useState([]);
  const [page, setPage] = useState(0);
  const theme = useTheme();

  const _handleDelete = (provision) => {
    const { confirmDelete, modalHandler } = deleteProvision(provision.snapshot_id);

    const removeProvision = () => {
      _setProvisions((prov) => prov.filter((p) => p.snapshot_id !== provision.snapshot_id));
    };

    const tmp = () => confirmDelete(removeProvision);
    modalHandler(tmp);
  };

  const deleteIcon = (item) => (
    <Tooltip text="Remover" atModal>
      <IconButton
        size="medium"
        shape="rounded"
        // color={visualInfo().color}
        style={{ padding: '1px' }}
        onClick={() => _handleDelete(item)}
        disabled={user?.id !== provisionOwner}
      >
        <Trash />
      </IconButton>
    </Tooltip>
  );

  const columns = [
    {
      field: 'snap_tag',
      label: 'Rótulo',
      valueGetter: (param) => param.snap_tag,
    },
    {
      field: 'provision_at',
      label: 'Data da Criação',
      sortable: true,
      valueGetter: (param) => formatDateTime(param.provision_at, { time: 'half' }),
    },
    {
      field: 'options',
      label: 'Opções',
      valueGetter: (param) => deleteIcon(param),
    },
  ];

  useEffect(() => {
    _setProvisions(provisions);
  }, [provisions]);

  return (
    <>
      {parentFilename && parentFilename !== '' && (
        <>
          Arquivo principal:
          {' '}
          <FileName>
            {parentFilename}
          </FileName>
        </>
      )}
      <ProvisionsList>
        {_provisions.length > 0 ? (
          <>
            <DataTable
              columns={columns}
              rowData={_provisions}
              rowColor={theme.tableBackground}
              strippedRowsColor={theme.containerOdd}
              defaultSortField="name"
              headerColor="transparent"
              theadStyle={{
                position: 'sticky',
                top: 0,
                zIndex: 1,
                backgroundColor: theme.background,
              }}
              usePagination
              page={page}
              itemsPerPage={6}
            />
            <TablePagination
              page={page}
              setPage={setPage}
              totalPages={
                Math.floor(_provisions.length % 6 === 0
                  ? (_provisions.length / 6) - 1
                  : _provisions.length / 6)
              }
            />
          </>
        ) : (
          <EmptyList>
            Não existe nenhum histórico associado ao arquivo
            {' '}
            {parentFilename}
          </EmptyList>
        )}
      </ProvisionsList>
    </>
  );
};

ListFileProvisions.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string,
  }),
  provisionOwner: PropTypes.string,
  parentFilename: PropTypes.string,
  provisions: PropTypes.arrayOf(
    PropTypes.shape({
      snap_tag: PropTypes.string,
    }),
  ).isRequired,
  deleteProvision: PropTypes.func.isRequired,
};

ListFileProvisions.defaultProps = {
  parentFilename: '',
  provisionOwner: '',
  user: {},
};

export default ListFileProvisions;
