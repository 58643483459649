import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Popover from '../../juristec-ui/core/Popover';
import IconButton from '../../juristec-ui/core/IconButton';
import List from '../../juristec-ui/core/List';
import ListItem from '../../juristec-ui/core/ListItem';
import {
  Activate, Demote, Desactivate, MoreVert, Promote, Trash,
} from '../../juristec-ui/icons';

import { OptionsContainer } from './styled/UsersOption.styled';

const FileOptions = ({
  user,
  currentUser,
  promoteUser,
  demoteUser,
  toggleUserState,
  deleteUser,
}) => {
  const [openPopover, setOpenPopover] = useState(false);

  return (
    <Popover
      offset={[0, 0]}
      open={openPopover}
      stayOpened={false}
      closePopover={() => setOpenPopover(false)}
    >
      <Popover.Action>
        <IconButton
          size="medium"
          shape="rounded"
          style={{ padding: '1px' }}
          onClick={() => setOpenPopover(!openPopover)}
        >
          <MoreVert />
        </IconButton>
      </Popover.Action>
      <Popover.Content>
        <OptionsContainer>
          <List>
            {currentUser.uid !== user.uid && (
              <>
                {user.role !== 'super' && (
                  <ListItem onClick={() => promoteUser(user)} noOutline>
                    <Promote />
                    Promover
                  </ListItem>
                )}
                {user.role !== 'guest' && (
                  <ListItem onClick={() => demoteUser(user)} noOutline>
                    <Demote />
                    Rebaixar
                  </ListItem>
                )}
                <ListItem onClick={() => toggleUserState(user)} noOutline>
                  {user.disabled ? (
                    <>
                      <Activate />
                      Ativar
                    </>
                  ) : (
                    <>
                      <Desactivate />
                      Inativar
                    </>
                  )}
                </ListItem>
                <ListItem onClick={() => deleteUser(user)} noOutline>
                  <Trash />
                  Excluir
                </ListItem>
              </>
            )}
            {/* TODO Falta fazer o historico
            <ListItem onClick={() => openUserHistory(user)} noOutline>
              <History />
              Histórico
            </ListItem>
            */}
          </List>
        </OptionsContainer>
      </Popover.Content>
    </Popover>
  );
};

FileOptions.propTypes = {
  user: PropTypes.shape({
    role: PropTypes.string,
    disabled: PropTypes.bool,
    uid: PropTypes.string,
  }),
  currentUser: PropTypes.shape({
    uid: PropTypes.string,
  }),
  promoteUser: PropTypes.func,
  demoteUser: PropTypes.func,
  toggleUserState: PropTypes.func,
  deleteUser: PropTypes.func,
};

FileOptions.defaultProps = {
  user: {},
  currentUser: {},
  promoteUser: () => {},
  demoteUser: () => {},
  toggleUserState: () => {},
  deleteUser: () => {},
};

export default FileOptions;
