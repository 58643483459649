/* eslint-disable no-param-reassign */
import React, {
  useContext, useState, useEffect, useLayoutEffect,
} from 'react';

import { useHistory } from 'react-router-dom';
import useDeviceType from '../../juristec-ui/hooks/useDeviceType';

// components
import Loader from '../../juristec-ui/core/Loader';
import BigDataToolbar from '../../components/Toolbars/BigDataToolbar';
import MetaDash from '../../components/MetaDash';
import MetaFilter from '../../components/MetaFilter/MetaFilter';
import PublicationsTable from '../../components/PublicationsTable';
import PublicationsFilter from '../../components/PublicationsFilter';

import { BigDataContext } from '../../context/BigDataProvider';
import { AlertContext } from '../../context/AlertProvider';

const BigDataPage = () => {
  const device = useDeviceType();
  const history = useHistory();
  const { state, bigDataAPI } = useContext(BigDataContext);
  const { setAlertConfig } = useContext(AlertContext);

  const [tab, setTab] = useState('META');
  const [filterDrawer, setFilterDrawer] = useState({ META: false, PUBLIC: false });
  const [isDirty, setIsDirty] = useState({ META: false, PUBLIC: false });

  const [metaFilterFields, setMetaFilterFields] = useState({});

  const [publicFilterFields, setPublicFilterFields] = useState({ unicos: true });
  const [tablePagination, setTablePagination] = useState({ page: 0, qty: 25 });

  const toggleMetaFilterDrawer = () => setFilterDrawer((o) => ({ ...o, META: !o.META }));
  const togglePublicFilterDrawer = () => setFilterDrawer((o) => ({ ...o, PUBLIC: !o.PUBLIC }));
  const closeMetaFilterDrawer = () => setFilterDrawer((o) => ({ ...o, META: false }));
  const closePublicFilterDrawer = () => setFilterDrawer((o) => ({ ...o, PUBLIC: false }));

  const setMetaIsDirty = (val) => setIsDirty((o) => ({ ...o, META: val }));
  const setPublicIsDirty = (val) => setIsDirty((o) => ({ ...o, PUBLIC: val }));

  useLayoutEffect(() => {
    const initTab = new URLSearchParams(window.location.search);
    setTab(initTab.get('tab') === 'PUBLIC' ? 'PUBLIC' : 'META');
  }, []);

  useEffect(() => {
    history.push({
      pathname: '/bigdata',
      search: `?tab=${tab}`,
    });
  }, [tab]);

  useEffect(() => {
    if (state.meta === null) {
      (async () => {
        await bigDataAPI.initMeta();
      })();
    }
  }, [state.meta]);

  useEffect(() => {
    if (state.publications && state.lastPublicationsQueries?.length > 0) {
      const { timestamp: _, ...newObj } = state.lastPublicationsQueries[0];
      setPublicFilterFields(newObj);
    }
    if (state.metaIsFiltered && state.lastMetaQueries?.length > 0) {
      const { timestamp: _, ...newObj } = state.lastMetaQueries[0];
      setMetaFilterFields(newObj);
    }
  }, []);

  const getOptions = async (endpoint, query) => {
    const res = await bigDataAPI.getOptions(endpoint, query);
    if (res.error) {
      setAlertConfig({
        type: 'error',
        text: res.msg,
        child: res.raw,
      });
      return [];
    }
    return res.data;
  };

  const sendMetaQuery = async () => {
    const res = await bigDataAPI.queryMeta(metaFilterFields, state.metaHistoryCursor);
    if (res.error) {
      setAlertConfig({
        type: 'error',
        text: res.msg,
        child: res.raw,
      });
    }
    return Boolean(res.error);
  };

  const downloadMetaQuery = async () => {
    const res = await bigDataAPI.exportMeta(metaFilterFields);
    if (res.error) {
      setAlertConfig({
        type: 'error',
        text: res.msg,
        child: res.raw,
      });
    }
  };

  const sendPublicationQuery = async (page, qty, reset) => {
    const offset = reset ? 0 : state.publications?.offsets[page] ?? state.publicationNextOffset;
    const res = await bigDataAPI.queryPublications(
      publicFilterFields, page, offset,
      qty, reset, state.publicationHistoryCursor,
    );
    if (res.error) {
      setAlertConfig({
        type: 'error',
        text: res.msg,
        child: res.raw,
      });
    }
    return Boolean(res.error);
  };

  const downloadPublicationQuery = async () => {
    const res = await bigDataAPI.exportPublications(
      publicFilterFields, state.publications?.totalResults ?? 0,
    );
    if (res.error) {
      setAlertConfig({
        type: 'error',
        text: res.msg,
        child: res.raw,
      });
    }
  };

  const handlePublicationQuery = () => {
    setTablePagination((o) => ({ ...o, page: 0 }));
    return sendPublicationQuery(0, tablePagination.qty, true);
  };

  const handleTablePagination = (newValueObj, reset) => {
    setTablePagination((o) => {
      const newPg = { ...o, ...newValueObj };
      sendPublicationQuery(newPg.page, newPg.qty, reset);
      return newPg;
    });
  };

  useEffect(() => {
    if (tab === 'PUBLIC' && state.publications === null) {
      setFilterDrawer((o) => ({ ...o, PUBLIC: true }));
    }
  }, [tab]);

  return (
    <>
      {(state.isLoading) && (
        <Loader />
      )}
      <BigDataToolbar
        resultsQty={tab === 'META' ? state.meta?.total_cnj : state.publications?.totalResults}
        tabState={[tab, setTab]}
        toggleFilter={tab === 'META' ? toggleMetaFilterDrawer : togglePublicFilterDrawer}
        hasFilters={tab === 'META' ? state.metaIsFiltered : Boolean(state.publications)}
        searchTime={tab === 'META' ? state.metaSearchTime : state.publicationsSearchTime}
        isMobileSize={device === 'phone'}
        downloadResult={tab === 'META' ? downloadMetaQuery : downloadPublicationQuery}
      />
      {tab === 'META' && (
        <MetaDash
          rawData={state.meta || {}}
        >
          <MetaFilter
            lastQueries={state.lastMetaQueries}
            drawerState={[filterDrawer.META, closeMetaFilterDrawer]}
            filterFieldsState={[metaFilterFields, setMetaFilterFields]}
            isDirtyState={[isDirty.META, setMetaIsDirty]}
            sendQuery={sendMetaQuery}
            getOptions={getOptions}
            maxValue={state.maxMetaValue}
            isMobileSize={device === 'phone'}
          />
        </MetaDash>
      )}
      {tab === 'PUBLIC' && (
        <PublicationsTable
          rawData={state.publications || {}}
          paginationState={[tablePagination, handleTablePagination]}
          isTabletSize={device !== 'desktop'}
        >
          <PublicationsFilter
            lastQueries={state.lastPublicationsQueries}
            drawerState={[filterDrawer.PUBLIC, closePublicFilterDrawer]}
            filterFieldsState={[publicFilterFields, setPublicFilterFields]}
            isDirtyState={[isDirty.PUBLIC, setPublicIsDirty]}
            sendQuery={handlePublicationQuery}
            getOptions={getOptions}
            isMobileSize={device === 'phone'}
          />
        </PublicationsTable>
      )}
    </>
  );
};

export default BigDataPage;
