import React from 'react';

const Notice = () => (
  <>
    <p>
      Ao utilizar este chat, você está interagindo com um sistema alimentado por rede neural
      e inteligência artificial.
    </p>
    <p>
      É importante entender o seguinte:
    </p>
    <p>
      <b>Natureza da Resposta:</b>
      {' '}
      As respostas fornecidas são geradas automaticamente tendo por base um conjunto de dados.
      Elas podem ser imprecisas, incompletas, desatualizadas e, em casos raros, podem ser
      interpretadas como ofensivas ou inadequadas.
    </p>
    <p>
      <b>Sem Garantias:</b>
      {' '}
      Este serviço contém tecnologia pioneira e é fornecido no estado em que se encontra.
      Não garantimos sua precisão, relevância ou adequação para qualquer propósito específico.
    </p>
    <p>
      <b>Responsabilidade do Usuário:</b>
      {' '}
      Ao utilizar este chat, é sua responsabilidade usar discernimento, interpretar as respostas
      com cuidado e consultar fontes adicionais ou profissionais quando necessário.
    </p>
    <p>
      Ao continuar a usar este chat, você declara e concorda que leu, compreendeu e aceitou os
      termos acima, reconhecendo as limitações e capacidades do sistema de rede neural.
    </p>
  </>
);

export default Notice;
