import React from 'react';

function UploadFile() {
  return (
    <svg
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 512 512"
      style={{ enableBackground: 'new 0 0 512 512' }}
      xmlSpace="preserve"
    >
      <g>
        <path d="M264.532,238.933H102.398c-4.713,0-8.533,3.82-8.533,8.533s3.82,8.533,8.533,8.533h162.133
        c4.713,0,8.533-3.82,8.533-8.533S269.244,238.933,264.532,238.933z"
        />
        <path d="M264.532,281.6H102.398c-4.713,0-8.533,3.82-8.533,8.533s3.82,8.533,8.533,8.533h162.133c4.713,0,8.533-3.82,8.533-8.533
        S269.244,281.6,264.532,281.6z"
        />
        <path d="M264.532,324.267H102.398c-4.713,0-8.533,3.821-8.533,8.533c0,4.713,3.82,8.533,8.533,8.533h162.133
        c4.713,0,8.533-3.821,8.533-8.533C273.065,328.087,269.244,324.267,264.532,324.267z"
        />
        <path d="M213.332,366.933H102.398c-4.713,0-8.533,3.82-8.533,8.533s3.82,8.533,8.533,8.533h110.933
        c4.713,0,8.533-3.82,8.533-8.533S218.044,366.933,213.332,366.933z"
        />
        <path d="M213.332,409.6H102.398c-4.713,0-8.533,3.82-8.533,8.533s3.82,8.533,8.533,8.533h110.933c4.713,0,8.533-3.82,8.533-8.533
        S218.044,409.6,213.332,409.6z"
        />
        <path d="M466.261,87.308l-102.4-85.333c-3.166-2.633-7.759-2.633-10.925,0l-79.55,66.292H68.265
        c-14.132,0.015-25.585,11.468-25.6,25.6V486.4c0.015,14.132,11.468,25.585,25.6,25.6h187.733c2.264-0.01,4.433-0.91,6.039-2.506
        l119.454-119.454l0.029-0.036c0.68-0.705,1.231-1.523,1.63-2.418c0.11-0.264,0.205-0.533,0.287-0.807
        c0.254-0.727,0.407-1.485,0.455-2.253c0.012-0.184,0.106-0.34,0.106-0.527v-85.333h17.067c14.132-0.015,25.585-11.468,25.6-25.6
        V102.4h34.133c3.594,0.001,6.804-2.25,8.027-5.629C470.048,93.391,469.023,89.608,466.261,87.308z M264.532,482.867v-81.8
        c0.005-4.711,3.822-8.529,8.533-8.533h81.8L264.532,482.867z M366.932,375.467h-93.867c-14.132,0.015-25.585,11.468-25.6,25.6
        v93.867h-179.2c-4.711-0.005-8.529-3.822-8.533-8.533V93.867c0.005-4.711,3.822-8.529,8.533-8.533h184.641l-2.37,1.975
        c-2.762,2.299-3.788,6.083-2.564,9.463c1.223,3.379,4.433,5.63,8.027,5.629h34.133v170.667c0.015,14.132,11.468,25.585,25.6,25.6
        h51.2V375.467z M418.132,85.333c-2.263-0.001-4.434,0.898-6.035,2.499c-1.6,1.6-2.499,3.771-2.499,6.035v179.2
        c-0.005,4.711-3.823,8.529-8.533,8.533h-85.333c-4.711-0.005-8.529-3.823-8.533-8.533v-179.2
        c0.001-2.263-0.898-4.434-2.499-6.035c-1.6-1.6-3.771-2.499-6.035-2.499h-19.096l78.829-65.692l78.829,65.692H418.132z"
        />
      </g>
    </svg>
  );
}

export default UploadFile;
