import React, {
  useReducer,
  createContext,
  useCallback,
  useMemo,
  useContext,
} from 'react';
import PropTypes from 'prop-types';
import { query } from 'gql-query-builder';

// import firebase from '../../utils/firebase';
import firestoreErrors from '../../utils/firestoreErrors';
import firebase from '../../utils/firebase';
import { error as errorLabels } from '../../label';
import {
  getCompanies,
  getCompany,
  getRefL1ExtractorModules,
  getRefL1ExtractorModuleFields,
  getRefSystemMessages,
  getRefL1ExtractorModuleTemplates,
  getRefCompanySecs,
  getRefGeneralMessages,
} from '../../utils/firestore';
import { ip, ipAdmin } from '../../utils/functions/urls';
import getRequestMeta from '../../utils/functions/generateMeta';
import denormalizeFields from '../../utils/functions/denormalizeFields';
import { isoDateToUTC } from '../../juristec-ui/utils/functions/lab';

//
import { AuthContext } from '../AuthProvider';

const getToken = async (u) => u.getIdToken();

const initialState = {
  instances: [],
  // legalOneReports: null,
  // connectReports: null,
  // legalOneApiReports: null,
  reports: {
    list: [],
    pageTotal: null,
  },
  users: [],
  isLoading: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'setInstances':
      return {
        ...state,
        isLoading: false,
        instances: action.instances,
      };

    case 'setInstancesUsers':
      return {
        ...state,
        instances: state.instances.map((instance) => {
          if (instance.id === action.instanceId) {
            return {
              ...instance,
              users: action.users,
            };
          }

          return instance;
        }),
        isLoading: false,
      };

    case 'editInstance':
      return {
        ...state,
        instances: state.instances.map((instance) => {
          if (instance.id === action.companyinfo.companyid) {
            return {
              ...instance,
              ...action.companyinfo, // outras variáveis n mudou os nomes
              superS: action.companyinfo.supers,
              volumeData: action.companyinfo.volumedata,
            };
          }

          return instance;
        }),
        isLoading: false,
      };

    case 'blockInstance':
      return {
        ...state,
        isLoading: false,
        instances: state.instances.map((instance) => {
          if (instance.id === action.instanceId) {
            return {
              ...instance,
              disabled: action.block,
            };
          }
          return instance;
        }),
      };

    case 'deleteInstance':
      return {
        ...state,
        instances: state.instances.filter((instance) => instance.id !== action.instanceId),
        isLoading: false,
      };

    // users
    case 'blockUser':
      return {
        ...state,
        instances: state.instances.map((instance) => {
          if (instance.id === action.instanceId) {
            const users = instance.users?.map((u) => (u.email === action.email
              ? {
                ...u,
                disabled: action.newValue,
              } : u));

            return {
              ...instance,
              users,
            };
          }

          return instance;
        }),
        isLoading: false,
      };

    case 'deleteUser':
      return {
        ...state,
        instances: state.instances.map((instance) => {
          if (instance.id === action.instanceId) {
            const users = instance.users?.filter((u) => (u.email !== action.email));

            return {
              ...instance,
              users,
            };
          }
          return instance;
        }),
        isLoading: false,
      };

    case 'changeUserRole':
      return {
        ...state,
        instances: state.instances.map((instance) => {
          if (instance.id === action.instanceId) {
            const users = instance.users?.map((u) => (u.email === action.email ? {
              ...u,
              role: action.newRole,
              scientist: action.scientist,
            } : u));

            return {
              ...instance,
              users,
            };
          }

          return instance;
        }),
      };

    // reports
    case 'setReports': {
      const currentReports = action.reset ? [] : [...state.reports.list];
      currentReports[action.page] = action.reports;

      return {
        ...state,
        isLoading: false,
        reports: {
          list: currentReports,
          pageTotal: action.pageTotal,
        },
      };
    }

    case 'updateReports': {
      const flatArray = state.reports.list.flat(1);
      const updatedArray = flatArray.map((rep) => {
        if (rep.id === action.reportId) {
          return ({
            ...rep,
            lastAttemptStatus: action.updated.status,
            updatedAt: action.updated.attemptAt,
            updatedById: action.updated.byId,
            updatedByEmail: action.updated.byEmail,
            attempt: {
              errors: action.updated.errors,
              message: action.updated.message,
            },
          });
        }
        return rep;
      });
      const resultArray = [];
      while (updatedArray.length > 0) resultArray.push(updatedArray.splice(0, 20));

      return {
        ...state,
        isLoading: false,
        reports: {
          ...state.reports,
          list: resultArray,
        },
      };
    }

    case 'filterReport': {
      const flatArray = state.reports.list.flat(1);
      const filteredArray = flatArray.filter((rep) => rep.id !== action.reportId);
      const page = Math.ceil(filteredArray.length / 20);
      const resultArray = [];
      while (filteredArray.length > 0) resultArray.push(filteredArray.splice(0, 20));

      return {
        ...state,
        isLoading: false,
        reports: {
          ...state.reports,
          list: resultArray,
          pageTotal: page,
        },
      };
    }

    case 'setUsers':
      return {
        ...state,
        isLoading: false,
        users: action.users,
      };

    case 'emptyReportList':
      return {
        ...state,
        reports: {
          ...state.reports,
          list: [],
        },
      };

    case 'setApiGedSuccess':
      return {
        ...state,
        instances: state.instances.map((instance) => {
          if (instance.id === action.instanceId) {
            return {
              ...instance,
              apiGedCredentials: true,
            };
          }
          return instance;
        }),
      };

    case 'setLoading':
      return {
        ...state,
        isLoading: action.isLoading,
      };
    default:
      return state;
  }
};

export const InstanceContext = createContext();

function InstanceProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const setLoading = useCallback((l = true) => dispatch({ type: 'setLoading', isLoading: l }), []);

  const { currentUser } = useContext(AuthContext);

  const getInstances = useCallback(async () => {
    const getCompaniesWithSecs = async () => {
      const res = await getCompanies().get();
      const companiesPromises = res.docs.map(async (d) => {
        const secs = [];
        const companyData = d.data();
        const secsRef = await getRefCompanySecs(d.id).get();
        if (secsRef.size > 0) {
          secsRef.forEach((doc) => {
            secs.push(doc.id);
          });
        }
        return { ...companyData, id: d.id, apiGedCredentials: secs.includes('l1reports') };
      });
      return Promise.all(companiesPromises);
    };
    try {
      const instances = await getCompaniesWithSecs();
      return { error: false, msg: '', instances };
    } catch (er) {
      return {
        error: true,
        msg: firestoreErrors(er.code) || errorLabels.instanceProvider.getInstances,
        raw: `Erro do sistema: ${er.toString()}`,
      };
    }
  }, []);

  const getInstance = useCallback(async (instanceId) => {
    try {
      const res = await getCompany(instanceId).get();
      const instance = { id: res.id, ...res.data() };
      return { error: false, msg: '', instance };
    } catch (er) {
      return {
        error: true,
        msg: firestoreErrors(er.code) || errorLabels.instanceProvider.getInstance,
        raw: `Erro do sistema: ${er.toString()}`,

      };
    }
  }, []);

  const getInstanceUsers = useCallback(async (instanceId) => {
    try {
      const token = await getToken(currentUser);
      const opt = {
        ...await getRequestMeta(token, 'POST', 'JSON'),
        body: JSON.stringify({ company_id: instanceId }),
      };

      const resFetch = await fetch(`${ipAdmin}/admin/user/list`, opt);
      const json = await resFetch.json();
      if (resFetch.status !== 200) {
        return { error: true, msg: errorLabels.instanceProvider.getInstanceUsers, raw: json.error };
      }

      return { error: false, users: json.info.map((u) => ({ ...u, instanceId })) };
    } catch (er) {
      return {
        error: true,
        msg: errorLabels.instanceProvider.getInstanceUsers,
        raw: `Erro do sistema: ${er.toString()}`,
      };
    }
  }, [currentUser]);

  const createInstance = useCallback(async (companyinfo, emails_super = [], emails_scientist = [], image = '') => {
    try {
      const token = await getToken(currentUser);
      const opt = {
        ...await getRequestMeta(token, 'POST', 'JSON'),
        body: JSON.stringify({
          ...companyinfo,
          emails_super,
          emails_scientist,
          image,
          signatureDate: companyinfo.signatureDate ? isoDateToUTC(companyinfo.signatureDate) : null,
          operationalDate: companyinfo.operationalDate
            ? isoDateToUTC(companyinfo.operationalDate) : null,
          trainingDate: companyinfo.trainingDate ? isoDateToUTC(companyinfo.trainingDate) : null,
        }),
      };

      const resFetch = await fetch(`${ipAdmin}/admin/instance`, opt);
      const json = await resFetch.json();

      if (resFetch.status === 500) {
        return {
          error: true,
          msg: errorLabels.fetchGeneric,
          raw: 'Verifique se existe algum problema com a sua conexão com a internet e tente novamente mais tarde!',
        };
      }

      if (resFetch.status !== 200) {
        return {
          error: true,
          msg: errorLabels.instanceProvider.createInstance,
          raw: json.error,
        };
      }

      return { error: false, msg: '' };
    } catch (er) {
      console.log(er);
      return {
        error: true,
        msg: errorLabels.instanceProvider.createInstance,
        raw: `Erro do sistema: ${er.toString()}`,
      };
    }
  }, [currentUser]);

  const editInstance = useCallback(async (companyinfo, image = '') => {
    try {
      const token = await getToken(currentUser);
      const opt = {
        ...await getRequestMeta(token, 'PUT', 'JSON'),
        body: JSON.stringify({
          ...companyinfo,
          image,
          signatureDate: isoDateToUTC(companyinfo.signatureDate),
          operationalDate: isoDateToUTC(companyinfo.operationalDate),
          trainingDate: isoDateToUTC(companyinfo.trainingDate),
        }),
      };

      const resFetch = await fetch(`${ipAdmin}/admin/instance`, opt);
      const json = await resFetch.json();

      if (resFetch.status === 500) {
        return {
          error: true,
          msg: errorLabels.fetchGeneric,
          raw: 'Verifique se existe algum problema com a sua conexão com a internet e tente novamente mais tarde!',
        };
      }

      if (resFetch.status !== 200) {
        return {
          error: true,
          msg: errorLabels.instanceProvider.editInstance,
          raw: json.error,
        };
      }

      return { error: false, msg: '' };
    } catch (er) {
      console.log(er);
      return {
        error: true,
        msg: errorLabels.instanceProvider.editInstance,
        raw: `Erro do sistema: ${er.toString()}`,
      };
    }
  }, [currentUser]);

  const blockInstance = useCallback(async (instanceId, block = true) => {
    try {
      const token = await getToken(currentUser);
      const opt = {
        ...await getRequestMeta(token, 'PUT', 'JSON'),
        body: JSON.stringify({
          company_id: instanceId,
          disabled: block,
        }),
      };

      const resFetch = await fetch(`${ipAdmin}/admin/instance`, opt);
      const json = await resFetch.json();

      if (resFetch.status === 500) {
        return {
          error: true,
          msg: errorLabels.fetchGeneric,
          raw: 'Verifique se existe algum problema com a sua conexão com a internet e tente novamente mais tarde!',
        };
      }

      if (resFetch.status !== 200) {
        return {
          error: true,
          msg: errorLabels.instanceProvider.blockInstance,
          raw: json.error,
        };
      }

      return { error: false, msg: '' };
    } catch (er) {
      console.log(er);
      return {
        error: true,
        msg: errorLabels.instanceProvider.blockInstance,
        raw: `Erro do sistema: ${er.toString()}`,
      };
    }
  }, [currentUser]);

  const deleteInstance = useCallback(async (instanceId) => {
    try {
      const token = await getToken(currentUser);
      const opt = {
        ...await getRequestMeta(token, 'DELETE', 'JSON'),
        body: JSON.stringify({ company_id: instanceId }),
      };

      const resFetch = await fetch(`${ipAdmin}/admin/instance`, opt);
      const json = await resFetch.json();

      if (resFetch.status === 500) {
        return {
          error: true,
          msg: errorLabels.fetchGeneric,
          raw: 'Verifique se existe algum problema com a sua conexão com a internet e tente novamente mais tarde!',
        };
      }

      if (resFetch.status !== 200) {
        return {
          error: true,
          msg: errorLabels.instanceProvider.deleteInstance,
          raw: json.error,
        };
      }

      return { error: false, msg: '' };
    } catch (er) {
      console.log(er);
      return {
        error: true,
        msg: errorLabels.instanceProvider.deleteInstance,
        raw: `Erro do sistema: ${er.toString()}`,
      };
    }
  }, [currentUser]);

  // USERS ON INSTANCE
  const blockUser = useCallback(async (
    instanceId,
    scientistId,
    email,
    newValue,
  ) => {
    try {
      const token = await getToken(currentUser);
      const opt = {
        ...await getRequestMeta(token, 'PUT', 'JSON'),
        body: JSON.stringify({
          company_id: instanceId,
          scientist_uid: scientistId,
          email,
          field: 'disabled',
          new_value: newValue,
        }),
      };

      const resFetch = await fetch(`${ipAdmin}/admin/user`, opt);
      const json = await resFetch.json();
      if (resFetch.status !== 200) {
        const parsed = JSON.parse(json);
        return {
          error: true,
          msg: parsed?.errors || errorLabels.instanceProvider.blockInstance,
          raw: json,
        };
      }

      return { error: false, msg: '' };
    } catch (er) {
      console.log(er);
      return {
        error: true,
        msg: errorLabels.instanceProvider.blockInstance,
        raw: `Erro do sistema: ${er.toString()}`,
      };
    }
  }, [currentUser]);

  const multiSessionUser = useCallback(async (
    instanceId,
    scientistId,
    email,
    newValue,
  ) => {
    try {
      const token = await getToken(currentUser);
      const opt = {
        ...await getRequestMeta(token, 'PUT', 'JSON'),
        body: JSON.stringify({
          company_id: instanceId,
          scientist_uid: scientistId,
          email,
          field: 'multiSessions',
          new_value: newValue,
        }),
      };

      const resFetch = await fetch(`${ipAdmin}/admin/user`, opt);
      const json = await resFetch.json();
      if (resFetch.status !== 200) {
        return {
          error: true,
          msg: errorLabels.instanceProvider.multiSessionUser,
          raw: json?.error,
        };
      }

      return { error: false, msg: '' };
    } catch (er) {
      console.log(er);
      return {
        error: true,
        msg: errorLabels.instanceProvider.multiSessionUser,
        raw: `Erro do sistema: ${er.toString()}`,
      };
    }
  }, [currentUser]);

  const addUser = useCallback(async (instance, scientist = '', email, role) => {
    try {
      const token = await getToken(currentUser);
      const opt = {
        ...await getRequestMeta(token, 'POST', 'JSON'),
        body: JSON.stringify({
          company_id: instance,
          scientist_uid: scientist,
          new_email: email,
          role,
          send_email: true,
        }),
      };

      const resFetch = await fetch(`${ipAdmin}/admin/user`, opt);
      const json = await resFetch.json();
      if (resFetch.status !== 200) {
        return {
          error: true,
          msg: errorLabels.instanceProvider.addUser,
          raw: json.error,
        };
      }

      return { error: false, msg: '' };
    } catch (er) {
      return {
        error: true,
        msg: errorLabels.instanceProvider.addUser,
        raw: `Erro do sistema: ${er.toString()}`,
      };
    }
  }, [currentUser]);

  const deleteUser = useCallback(async (instance, email) => {
    try {
      const token = await getToken(currentUser);
      const opt = {
        ...await getRequestMeta(token, 'DELETE', 'JSON'),
        body: JSON.stringify({
          company_id: instance,
          email,
        }),
      };

      const resFetch = await fetch(`${ipAdmin}/admin/user`, opt);
      const json = await resFetch.json();
      if (resFetch.status !== 200) {
        const parsed = JSON.parse(json);
        return {
          error: true,
          msg: parsed?.errors || errorLabels.instanceProvider.deleteUser,
          raw: json,
        };
      }

      return { error: false, msg: '' };
    } catch (er) {
      console.log(er);
      return {
        error: true,
        msg: errorLabels.instanceProvider.deleteUser,
        raw: `Erro do sistema: ${er.toString()}`,
      };
    }
  }, [currentUser]);

  const changeUserRole = useCallback(async (instanceId, email, newRole, scientist) => {
    try {
      const token = await getToken(currentUser);
      const opt = {
        ...await getRequestMeta(token, 'PUT'),
        body: JSON.stringify({
          email,
          companyId: instanceId,
          scientist,
          role: newRole,
          update: 'role',
        }),
      };

      const resFetch = await fetch(`${ip}/manage/user`, opt);
      const json = await resFetch.json();
      if (resFetch.status !== 200) {
        const parsed = JSON.parse(json);
        return {
          error: true,
          msg: parsed?.errors || errorLabels.instanceProvider.deleteUser,
          raw: json,
        };
      }

      return { error: false, msg: '' };
    } catch (er) {
      console.log(er);
      return {
        error: true,
        msg: errorLabels.instanceProvider.deleteUser,
        raw: `Erro do sistema: ${er.toString()}`,
      };
    }
  }, [currentUser]);

  const changeUserRoleByAdmin = useCallback(async (instanceId, scientistId = '', email, newRole) => {
    try {
      const token = await getToken(currentUser);
      const opt = {
        ...await getRequestMeta(token, 'PUT', 'JSON'),
        body: JSON.stringify({
          email,
          company_id: instanceId,
          scientist_uid: scientistId,
          field: 'role',
          new_value: newRole,
        }),
      };

      const resFetch = await fetch(`${ipAdmin}/admin/user`, opt);
      const json = await resFetch.json();
      if (resFetch.status !== 200) {
        return {
          error: true,
          msg: errorLabels.instanceProvider.editUser,
          raw: json.error,
        };
      }

      return { error: false, msg: '' };
    } catch (er) {
      return {
        error: true,
        msg: errorLabels.instanceProvider.editUser,
        raw: `Erro do sistema: ${er.toString()}`,
      };
    }
  }, [currentUser]);

  const transferUserData = useCallback(async (
    instanceId, userSender, userReceiver, viewerOnly = false,
  ) => {
    try {
      const token = await getToken(currentUser);
      const opt = {
        ...await getRequestMeta(token, 'POST', 'JSON'),
        body: JSON.stringify({
          origin_uid: userSender,
          destination_uid: userReceiver,
          company_uid: instanceId,
          viewer_only: viewerOnly,
        }),
      };

      const resFetch = await fetch(`${ipAdmin}/admin/transfer`, opt);
      const json = await resFetch.json();

      if (resFetch.status >= 500) {
        return {
          error: true,
          msg: errorLabels.instanceProvider.transferUser,
          raw: 'Verifique se existe algum problema com a sua conexão com a internet e tente novamente mais tarde!',
        };
      }
      if (resFetch.status !== 200) {
        return {
          error: true,
          msg: errorLabels.instanceProvider.transferUser,
          raw: json.error,
        };
      }
      return { error: false, msg: '' };
    } catch (er) {
      console.log(er);
      return {
        error: true,
        msg: errorLabels.instanceProvider.transferUser,
        raw: `Erro do sistema: ${er.toString()}`,
      };
    }
  }, [currentUser]);

  // REPORTS
  const addAntecipeiReport = useCallback(async (
    filename,
    ownerId,
    companyId,
    url,
    sendWithoutDataProc,
    prettyColumns,
    reportToken,
  ) => {
    try {
      const token = await getToken(currentUser);
      const opt = {
        ...await getRequestMeta(token, 'POST', 'JSON'),
        body: JSON.stringify({
          predefined_connector: 'Antecipei',
          filename: `${filename}.xlsx`,
          ownerId,
          companyId,
          url: `${url}?send_without_dataproc=${sendWithoutDataProc}&pretty_columns=${prettyColumns}`,
          method: 'GET',
          auth: {
            method: 'x-api-token',
            token: reportToken,
          },
          payload: {},
        }),
      };

      const resFetch = await fetch(`${ipAdmin}/connect/create/web`, opt);
      const json = await resFetch.json();

      if (resFetch.status === 500) {
        return {
          error: true,
          msg: errorLabels.fetchGeneric,
          raw: 'Verifique se existe algum problema com a sua conexão com a internet e tente novamente mais tarde!',
        };
      }

      if (resFetch.status !== 200) {
        return {
          error: true,
          msg: errorLabels.instanceProvider.addAntecipeiReport,
          raw: json.error,
        };
      }

      return { error: false, msg: '' };
    } catch (er) {
      return {
        error: true,
        msg: errorLabels.instanceProvider.addAntecipeiReport,
        raw: `Erro do sistema: ${er.toString()}`,
      };
    }
  }, [currentUser]);

  const addBennerReport = useCallback(async (
    filename,
    ownerId,
    companyId,
    url,
    username,
    password,
  ) => {
    try {
      const token = await getToken(currentUser);
      const opt = {
        ...await getRequestMeta(token, 'POST', 'JSON'),
        body: JSON.stringify({
          predefined_connector: 'Benner',
          filename: `${filename}.xlsx`,
          ownerId,
          companyId,
          url,
          method: 'GET',
          auth: {
            method: 'Basic Auth',
            username,
            password,
          },
          payload: {},
          header_skip: 0,
        }),
      };

      const resFetch = await fetch(`${ipAdmin}/connect/create/web`, opt);
      const json = await resFetch.json();

      if (resFetch.status === 500) {
        return {
          error: true,
          msg: errorLabels.fetchGeneric,
          raw: 'Verifique se existe algum problema com a sua conexão com a internet e tente novamente mais tarde!',
        };
      }

      if (resFetch.status !== 200) {
        return {
          error: true,
          msg: errorLabels.instanceProvider.addBennerReport,
          raw: json.error,
        };
      }

      return { error: false, msg: '' };
    } catch (er) {
      return {
        error: true,
        msg: errorLabels.instanceProvider.addBennerReport,
        raw: `Erro do sistema: ${er.toString()}`,
      };
    }
  }, [currentUser]);

  const createOnboardingReport = useCallback(async (
    filename, instance, ownerId, fields, selectedFields, module, alias,
  ) => {
    try {
      const nodeFields = denormalizeFields(selectedFields);
      if (!['id'].includes(nodeFields)) {
        nodeFields.push('id');
      }
      const queryFields = [
        'totalCount',
        {
          pageInfo: ['hasNextPage', 'startCursor', 'endCursor'],
          nodes: [...nodeFields],
        },
      ];
      const queryOptions = {
        operation: module,
        variables: {
          startUpdatedDateUtc: {
            value: isoDateToUTC(new Date(0)),
            type: 'DateTime',
          },
          after: {
            value: null,
            type: 'String',
          },
        },
        fields: queryFields,
      };
      const q = query(queryOptions);
      const token = await getToken(currentUser);
      const opt = {
        ...await getRequestMeta(token, 'POST', 'JSON'),
        body: JSON.stringify({
          filename,
          ownerId,
          method: 'GET',
          predefined_connector: 'LegalOneExtractor',
          payload: {
            query: q,
            l1ReportModule: module,
            l1ReportFields: [...nodeFields],
            alias,
          },
          companyId: instance,
          updatedAt: '',
        }),
      };

      const resFetch = await fetch(`${ipAdmin}/connect/create/web`, opt);
      const json = await resFetch.json();

      if (resFetch.status === 500) {
        return {
          error: true,
          msg: errorLabels.fetchGeneric,
          raw: 'Verifique se existe algum problema com a sua conexão com a internet e tente novamente mais tarde!',
        };
      }

      if (resFetch.status !== 200) {
        return {
          error: true,
          msg: errorLabels.instanceProvider.createOnboardingReport,
          raw: json.error,
        };
      }

      return { error: false, msg: '' };
    } catch (er) {
      console.log(er);
      return {
        error: true,
        msg: errorLabels.instanceProvider.createOnboardingReport,
        raw: `Erro do sistema: ${er.toString()}`,
      };
    }
  }, [currentUser]);

  const getDataExtractorInfo = useCallback(async (companyId) => {
    try {
      const token = await getToken(currentUser);
      const opt = {
        ...await getRequestMeta(token, 'POST', 'JSON'),
        body: JSON.stringify({
          companyId,
          get_defaults: false,
          sort: true,
        }),
      };

      const resFetch = await fetch(`${ipAdmin}/connect/company/l1extr/schema`, opt);
      const json = await resFetch.json();

      if (resFetch.status === 500) {
        return {
          error: true,
          msg: errorLabels.fetchGeneric,
          raw: 'Verifique se existe algum problema com a sua conexão com a internet e tente novamente mais tarde!',
        };
      }

      if (resFetch.status !== 200) {
        return {
          error: true,
          msg: errorLabels.instanceProvider.getDataExtractorInfo,
          raw: json.error,
        };
      }

      return { error: false, msg: '', res: json.info };
    } catch (er) {
      console.log(er);
      return {
        error: true,
        msg: errorLabels.instanceProvider.getDataExtractorInfo,
        raw: `Erro do sistema: ${er.toString()}`,
      };
    }
  }, [currentUser]);

  const getDataExtractorModules = useCallback(async () => {
    try {
      const l1ModulesRef = getRefL1ExtractorModules();
      const data = await l1ModulesRef.get().then((doc) => doc.data())
        .catch((error) => {
          console.log(error);
          return {
            error: true,
            msg: errorLabels.instanceProvider.getDataExtractorModules,
            raw: `Erro ao recuperar dados: ${error.message}`,
          };
        });
      return { error: false, msg: '', res: data };
    } catch (er) {
      console.log(er);
      return {
        error: true,
        msg: errorLabels.instanceProvider.getDataExtractorModules,
        raw: `Erro do sistema: ${er.toString()}`,
      };
    }
  }, [currentUser]);

  const getL1ReportModuleFields = useCallback(async (module, plan) => {
    const l1ModulesRef = getRefL1ExtractorModuleFields(module, plan);
    const data = await l1ModulesRef.get().then((doc) => ({ res: doc.data(), error: false, msg: '' }))
      .catch((error) => {
        console.log(error);
        return {
          error: true,
          msg: errorLabels.instanceProvider.getL1ReportModuleFields,
          raw: `Erro do sistema: ${error.toString()}`,
        };
      });
    return data;
  }, [currentUser]);

  const getExtractorPossibleTemplates = useCallback(async (module, plan) => {
    const l1TemplatesRef = getRefL1ExtractorModuleTemplates(module, plan);
    const data = await l1TemplatesRef.get().then((col) => ({
      res: col.docs.map((doc) => {
        const d = doc.data();
        return {
          id: doc.id,
          value: d.columns,
          label: d.name,
        };
      }),
      error: false,
      msg: '',
    }))
      .catch((error) => {
        console.log(error);
        return {
          error: true,
          msg: errorLabels.instanceProvider.getExtractorPossibleTemplates,
          raw: `Erro do sistema: ${error.toString()}`,
        };
      });
    return data;
  }, [currentUser]);

  const getReports = useCallback(async (page, filters, qty) => {
    try {
      const token = await getToken(currentUser);
      const opt = {
        ...await getRequestMeta(token),
      };

      let strFilters = '';
      strFilters += `&active=${!filters?.inactive?.selected}`;
      if (filters?.origin?.selected?.length > 0) strFilters += `&origin=${filters.origin.selected.join(',')}`;
      if (filters?.status?.selected?.length > 0) strFilters += `&status=${filters.status.selected.join(',')}`;
      if (filters?.connector?.selected?.length > 0) strFilters += `&connector=${filters.connector.selected.join(',')}`;
      if (filters?.companyId?.selected?.length > 0) strFilters += `&companyId=${filters.companyId.selected.join(',')}`;
      if (filters?.filename?.selected?.length > 0) strFilters += `&arquivo=${filters.filename.selected}`;

      const resFetch = await fetch(
        `${ipAdmin}/connect/list?withInfo=true&page=${page + 1}&size=${qty}${strFilters}`, opt,
      );
      const json = await resFetch.json();
      if (resFetch.status >= 500) {
        return {
          error: true,
          msg: errorLabels.instanceProvider.getReports,
          raw: 'Verifique se existe algum problema com a sua conexão com a internet e tente novamente mais tarde!',
        };
      }
      if (resFetch.status !== 200) {
        return {
          error: true,
          msg: errorLabels.instanceProvider.getReports,
          raw: json.error,
        };
      }
      const reps = Object.keys(json.info.reports).map((k) => (
        {
          ...json.info.reports[k],
          status: json.info.reports[k]?.lastAttemptStatus || 'SUCCESS',
        }
      ));
      return {
        error: false,
        msg: '',
        data: {
          reports: reps,
          pageTotal: json.info.pageTotal,
        },
      };
    } catch (er) {
      console.log(er);
      return {
        error: true,
        msg: errorLabels.instanceProvider.getReports,
        raw: `Erro do sistema: ${er.toString()}`,
      };
    }
  }, [currentUser]);

  const getReportStatus = useCallback(async (hashcode) => {
    try {
      if (!hashcode) {
        return {
          error: true,
          msg: errorLabels.instanceProvider.deleteReport,
          raw: 'Hashcode nulo ou não encontrado',
        };
      }

      const token = await getToken(currentUser);
      const opt = {
        ...await getRequestMeta(token),
      };
      let json = {};

      const resFetchF = await fetch(`${ipAdmin}/connect/datasource/${hashcode}`, opt);
      json = await resFetchF.json();
      if (resFetchF.status >= 500) {
        return {
          error: true,
          msg: errorLabels.instanceProvider.getReportStatus,
          raw: 'Verifique se existe algum problema com a sua conexão com a internet e tente novamente mais tarde!',
        };
      }
      if (resFetchF.status !== 200) {
        return {
          error: true,
          msg: errorLabels.instanceProvider.getReportStatus,
          raw: json.error,
        };
      }

      return { error: false, msg: '', data: json.info };
    } catch (er) {
      console.log(er);
      return {
        error: true,
        msg: errorLabels.instanceProvider.getReportStatus,
        raw: `Erro do sistema: ${er.toString()}`,
      };
    }
  }, [currentUser]);

  const deleteReport = useCallback(async (hashcode) => {
    try {
      if (!hashcode) {
        return {
          error: true,
          msg: errorLabels.instanceProvider.deleteReport,
          raw: 'Hashcode nulo ou não encontrado',
        };
      }

      const token = await getToken(currentUser);
      const opt = {
        ...await getRequestMeta(token, 'DELETE', 'JSON'),
      };

      const resFetchF = await fetch(`${ipAdmin}/connect/datasource/${hashcode}`, opt);
      const jsonF = await resFetchF.json();
      if (resFetchF.status >= 500) {
        return {
          error: true,
          msg: errorLabels.instanceProvider.deleteReport,
          raw: 'Verifique se existe algum problema com a sua conexão com a internet e tente novamente mais tarde!',
        };
      }
      if (resFetchF.status !== 200) {
        return {
          error: true,
          msg: errorLabels.instanceProvider.deleteReport,
          raw: jsonF.error,
        };
      }

      return { error: false, msg: '', deletedId: hashcode };
    } catch (er) {
      console.log(er);
      return {
        error: true,
        msg: errorLabels.instanceProvider.deleteReport,
        raw: `Erro do sistema: ${er.toString()}`,
      };
    }
  }, [currentUser]);

  const resetReport = useCallback(async (hashcode) => {
    try {
      if (!hashcode) {
        return {
          error: true,
          msg: errorLabels.instanceProvider.resetReport,
          raw: 'Hashcode nulo ou não encontrado',
        };
      }

      const token = await getToken(currentUser);
      const opt = {
        ...await getRequestMeta(token, 'PUT', 'JSON'),
      };

      const resFetchF = await fetch(`${ipAdmin}/connect/datasource/${hashcode}/reset`, opt);
      const jsonF = await resFetchF.json();
      if (resFetchF.status >= 500) {
        return {
          error: true,
          msg: errorLabels.instanceProvider.resetReport,
          raw: 'Verifique se existe algum problema com a sua conexão com a internet e tente novamente mais tarde!',
        };
      }
      if (resFetchF.status !== 200) {
        return {
          error: true,
          msg: errorLabels.instanceProvider.resetReport,
          raw: jsonF.error,
        };
      }

      return { error: false, msg: '', resetId: hashcode };
    } catch (er) {
      console.log(er);
      return {
        error: true,
        msg: errorLabels.instanceProvider.resetReport,
        raw: `Erro do sistema: ${er.toString()}`,
      };
    }
  }, [currentUser]);

  const convertToGed = useCallback(async (reportId) => {
    try {
      if (!reportId) {
        return {
          error: true,
          msg: errorLabels.instanceProvider.convertToGed,
          raw: 'Hashcode nulo ou não encontrado',
        };
      }

      const token = await getToken(currentUser);
      const opt = {
        ...await getRequestMeta(token, 'PUT', 'JSON'),
      };

      const resFetchF = await fetch(`${ipAdmin}/admin/datasource/${reportId}/convert`, opt);
      const jsonF = await resFetchF.json();
      if (resFetchF.status >= 500) {
        return {
          error: true,
          msg: errorLabels.instanceProvider.convertToGed,
          raw: 'Verifique se existe algum problema com a sua conexão com a internet e tente novamente mais tarde!',
        };
      }
      if (resFetchF.status !== 200) {
        return {
          error: true,
          msg: errorLabels.instanceProvider.convertToGed,
          raw: jsonF.error,
        };
      }

      return { error: false, msg: '', reportId };
    } catch (er) {
      console.log(er);
      return {
        error: true,
        msg: errorLabels.instanceProvider.convertToGed,
        raw: `Erro do sistema: ${er.toString()}`,
      };
    }
  }, [currentUser]);

  const manualReportUpdate = useCallback(async (hashcode, origin, file, filename) => {
    try {
      if (!hashcode) {
        return {
          error: true,
          msg: errorLabels.instanceProvider.manualReportUpdate,
          raw: 'Hashcode nulo ou não encontrado',
        };
      }
      const token = await getToken(currentUser);
      let json = {};
      let resFetch = {};
      const todayDate = new Date();
      const date = todayDate.toISOString().split('T')[0];

      if (origin === 'FileApi' || origin === 'Desktop') {
        const data = new FormData();
        console.log(file);
        data.append('file', file, file.name);
        // data.append('overwrite', overwrite);
        const opt = { ...await getRequestMeta(token, 'PUT'), body: data };

        resFetch = await fetch(`${ipAdmin}/connect/datasource/${hashcode}?date=${date}`, opt);
        json = await resFetch.json();
      } else {
        const opt = {
          ...await getRequestMeta(token, 'PUT', 'JSON'),
        };

        resFetch = await fetch(`${ipAdmin}/connect/datasource/${hashcode}?uploaded_file=${filename}.xlsx&date=${date}`, opt);
        json = await resFetch.json();
      }

      if (resFetch.status >= 500) {
        return {
          error: true,
          msg: errorLabels.instanceProvider.manualReportUpdate,
          raw: 'Verifique se existe algum problema com a sua conexão com a internet e tente novamente mais tarde!',
          type: 'error',
        };
      }

      if (resFetch.status !== 200) {
        return {
          error: true,
          msg: errorLabels.instanceProvider.manualReportUpdate,
          raw: json.error || json.errors,
          type: 'error',
        };
      }

      return {
        error: false,
        msg: '',
        data: json.info,
        type: json.info.status === 'WARNING' ? 'warning' : 'success',
      };
    } catch (er) {
      console.log(er);
      return {
        error: true,
        msg: errorLabels.instanceProvider.manualReportUpdate,
        raw: `Erro do sistema: ${er.toString()}`,
      };
    }
  }, [currentUser]);

  const inactivateReport = useCallback(async (hashcode) => {
    try {
      if (!hashcode) {
        return {
          error: true,
          msg: errorLabels.instanceProvider.inactivateReport,
          raw: 'Hashcode nulo ou não encontrado',
        };
      }

      const token = await getToken(currentUser);
      const opt = {
        ...await getRequestMeta(token, 'DELETE', 'JSON'),
      };

      const resFetchF = await fetch(`${ipAdmin}/connect/datasource/status/${hashcode}`, opt);
      const jsonF = await resFetchF.json();
      if (resFetchF.status >= 500) {
        return {
          error: true,
          msg: errorLabels.instanceProvider.inactivateReport,
          raw: 'Verifique se existe algum problema com a sua conexão com a internet e tente novamente mais tarde!',
        };
      }
      if (resFetchF.status !== 200) {
        return {
          error: true,
          msg: errorLabels.instanceProvider.inactivateReport,
          raw: jsonF.error,
        };
      }

      return { error: false, msg: '', deletedId: hashcode };
    } catch (er) {
      console.log(er);
      return {
        error: true,
        msg: errorLabels.instanceProvider.inactivateReport,
        raw: `Erro do sistema: ${er.toString()}`,
      };
    }
  }, [currentUser]);

  const activateReport = useCallback(async (hashcode) => {
    try {
      if (!hashcode) {
        return {
          error: true,
          msg: errorLabels.instanceProvider.activateReport,
          raw: 'Hashcode nulo ou não encontrado',
        };
      }

      const token = await getToken(currentUser);
      const opt = {
        ...await getRequestMeta(token, 'PUT', 'JSON'),
      };

      const resFetchF = await fetch(`${ipAdmin}/connect/datasource/status/${hashcode}`, opt);
      const jsonF = await resFetchF.json();
      if (resFetchF.status >= 500) {
        return {
          error: true,
          msg: errorLabels.instanceProvider.activateReport,
          raw: 'Verifique se existe algum problema com a sua conexão com a internet e tente novamente mais tarde!',
        };
      }
      if (resFetchF.status !== 200) {
        return {
          error: true,
          msg: errorLabels.instanceProvider.activateReport,
          raw: jsonF.error,
        };
      }

      return { error: false, msg: '', deletedId: hashcode };
    } catch (er) {
      console.log(er);
      return {
        error: true,
        msg: errorLabels.instanceProvider.activateReport,
        raw: `Erro do sistema: ${er.toString()}`,
      };
    }
  }, [currentUser]);

  const restoreReport = useCallback(async (hashcode) => {
    try {
      if (!hashcode) {
        return {
          error: true,
          msg: errorLabels.instanceProvider.restoreReport,
          raw: 'Hashcode nulo ou não encontrado',
        };
      }

      const token = await getToken(currentUser);
      const opt = {
        ...await getRequestMeta(token, 'POST', 'JSON'),
      };

      const resFetchF = await fetch(`${ipAdmin}/connect/datasource/restore/${hashcode}`, opt);
      const jsonF = await resFetchF.json();
      if (resFetchF.status >= 500) {
        return {
          error: true,
          msg: errorLabels.instanceProvider.restoreReport,
          raw: 'Verifique se existe algum problema com a sua conexão com a internet e tente novamente mais tarde!',
        };
      }
      if (resFetchF.status !== 200) {
        return {
          error: true,
          msg: errorLabels.instanceProvider.restoreReport,
          raw: jsonF.error,
        };
      }

      return { error: false, msg: '', deletedId: hashcode };
    } catch (er) {
      console.log(er);
      return {
        error: true,
        msg: errorLabels.instanceProvider.restoreReport,
        raw: `Erro do sistema: ${er.toString()}`,
      };
    }
  }, [currentUser]);

  const changeOwnerReport = useCallback(async (
    companyId,
    fileId,
    currentOwner,
    newOwner,
    removeOldOwner,
  ) => {
    try {
      if (!companyId || !fileId || !currentOwner || !newOwner) {
        return {
          error: true,
          msg: errorLabels.instanceProvider.changeOwnerReport,
          raw: 'Informações incorretas! Verifique o companyId, fileId, proprietário original e novo proprietário!',
        };
      }

      const token = await getToken(currentUser);
      const opt = {
        ...await getRequestMeta(token, 'PUT', 'JSON'),
        body: JSON.stringify({
          companyId,
          file_id: fileId,
          src_uid: currentOwner,
          dst_uid: newOwner,
          src_remove: removeOldOwner,
        }),
      };

      const resFetch = await fetch(`${ipAdmin}/admdb/file/owner/edit`, opt);
      const jsonF = await resFetch.json();
      if (resFetch.status >= 500) {
        return {
          error: true,
          msg: errorLabels.instanceProvider.changeOwnerReport,
          raw: 'Verifique se existe algum problema com a sua conexão com a internet e tente novamente mais tarde!',
        };
      }
      if (resFetch.status !== 200) {
        return {
          error: true,
          msg: errorLabels.instanceProvider.changeOwnerReport,
          raw: jsonF.error,
        };
      }

      return { error: false, msg: '' };
    } catch (er) {
      console.log(er);
      return {
        error: true,
        msg: errorLabels.instanceProvider.changeOwnerReport,
        raw: `Erro do sistema: ${er.toString()}`,
      };
    }
  }, [currentUser]);

  const getReportColumns = useCallback(async (hashcode) => {
    try {
      const token = await getToken(currentUser);
      // const userId = ownerId || currentUser.id || currentUser.uid;
      const opt = {
        ...await getRequestMeta(token),
      };

      const resFetch = await fetch(`${ipAdmin}/connect/datasource/columns/${hashcode}`, opt);

      if (resFetch.status >= 500) {
        return {
          error: true,
          msg: errorLabels.fetchGeneric,
          raw: 'Verifique se existe algum problema com a sua conexão com a internet e tente novamente mais tarde!',
        };
      }

      const json = await resFetch.json();

      if (resFetch.status !== 200) {
        return {
          error: true,
          msg: json?.msg || errorLabels.instanceProvider.getReportColumns,
          raw: json.error,
        };
      }

      return {
        error: false,
        msg: null,
        columns: json.columns,
        alias: json.alias,
        custom: json.custom_columns,
      };
    } catch (er) {
      console.log(er);
      return {
        error: true,
        msg: errorLabels.instanceProvider.getReportColumns,
        raw: `Erro do sistema: ${er.toString()}`,
      };
    }
  }, [currentUser]);

  const editReportColumns = useCallback(async (hashcode, columnMap) => {
    try {
      const token = await getToken(currentUser);
      const opt = {
        ...await getRequestMeta(token, 'PUT', 'JSON'),
        body: JSON.stringify({
          columns: columnMap,
        }),
      };

      const resFetch = await fetch(`${ipAdmin}/connect/datasource/columns/${hashcode}`, opt);

      if (resFetch.status >= 500) {
        return {
          error: true,
          msg: errorLabels.fetchGeneric,
          raw: 'Verifique se existe algum problema com a sua conexão com a internet e tente novamente mais tarde!',
        };
      }

      const json = await resFetch.json();

      if (resFetch.status !== 200) {
        return {
          error: true,
          msg: json?.msg || errorLabels.instanceProvider.editReportColumns,
          raw: json.error,
        };
      }

      return { error: false, msg: null, res: json.columns };
    } catch (er) {
      console.log(er);
      return {
        error: true,
        msg: errorLabels.instanceProvider.editReportColumns,
        raw: `Erro do sistema: ${er.toString()}`,
      };
    }
  }, [currentUser]);

  const previewCustomColumns = useCallback(async (hashcode, newColumnsList) => {
    try {
      const token = await getToken(currentUser);
      const opt = {
        ...await getRequestMeta(token, 'POST', 'JSON'),
        body: JSON.stringify({
          newColumns: newColumnsList,
        }),
      };

      const resFetch = await fetch(`${ipAdmin}/connect/datasource/customcolumns/preview/${hashcode}`, opt);

      if (resFetch.status >= 500) {
        return {
          error: true,
          msg: errorLabels.fetchGeneric,
          raw: 'Verifique se existe algum problema com a sua conexão com a internet e tente novamente mais tarde!',
        };
      }

      const json = await resFetch.json();

      if (resFetch.status !== 200) {
        return {
          error: true,
          msg: json?.msg || errorLabels.instanceProvider.previewCustomColumns,
          raw: json.error,
        };
      }

      return { error: false, msg: null, res: json.info };
    } catch (er) {
      console.log(er);
      return {
        error: true,
        msg: errorLabels.instanceProvider.previewCustomColumns,
        raw: `Erro do sistema: ${er.toString()}`,
      };
    }
  }, [currentUser]);

  const manageCustomColumns = useCallback(async (hashcode, newColumnsList) => {
    try {
      const token = await getToken(currentUser);
      const opt = {
        ...await getRequestMeta(token, 'POST', 'JSON'),
        body: JSON.stringify({
          newColumns: newColumnsList,
        }),
      };

      const resFetch = await fetch(`${ipAdmin}/connect/datasource/customcolumns/${hashcode}`, opt);

      if (resFetch.status >= 500) {
        return {
          error: true,
          msg: errorLabels.fetchGeneric,
          raw: 'Verifique se existe algum problema com a sua conexão com a internet e tente novamente mais tarde!',
        };
      }

      const json = await resFetch.json();

      if (resFetch.status !== 200) {
        return {
          error: true,
          msg: json?.msg || errorLabels.instanceProvider.manageCustomColumns,
          raw: json.error,
        };
      }

      return { error: false, msg: null, res: json.info };
    } catch (er) {
      console.log(er);
      return {
        error: true,
        msg: errorLabels.instanceProvider.manageCustomColumns,
        raw: `Erro do sistema: ${er.toString()}`,
      };
    }
  }, [currentUser]);

  const requestApiCredentials = useCallback(async (hashcode) => {
    try {
      if (!hashcode) {
        return {
          error: true,
          msg: errorLabels.instanceProvider.requestApiCredentials,
          raw: 'Hashcode nulo ou não encontrado',
        };
      }
      const token = await getToken(currentUser);
      const opt = {
        ...await getRequestMeta(token, 'POST', 'JSON'),
      };

      const resFetch = await fetch(`${ip.replace('/analytics', '/l1dataextractor')}/v1/credentials/${hashcode}`, opt);
      const json = await resFetch.json();

      if (resFetch.status === 500) {
        return {
          error: true,
          msg: errorLabels.fetchGeneric,
          raw: 'Verifique se existe algum problema com a sua conexão com a internet e tente novamente mais tarde!',
        };
      }

      if (resFetch.status !== 200) {
        return {
          error: true,
          msg: errorLabels.instanceProvider.requestApiCredentials,
          raw: json.error,
        };
      }

      return { error: false, msg: '' };
    } catch (er) {
      console.log(er);
      return {
        error: true,
        msg: errorLabels.instanceProvider.requestApiCredentials,
        raw: `Erro do sistema: ${er.toString()}`,
      };
    }
  }, [currentUser]);

  const saveApiCredentials = useCallback(async (hashcode, key, secret) => {
    try {
      if (!hashcode) {
        return {
          error: true,
          msg: errorLabels.instanceProvider.requestApiCredentials,
          raw: 'Hashcode nulo ou não encontrado',
        };
      }
      const token = await getToken(currentUser);
      const opt = {
        ...await getRequestMeta(token, 'PUT', 'JSON'),
        body: JSON.stringify({
          key,
          secret,
        }),
      };

      const resFetch = await fetch(`${ip.replace('/analytics', '/l1dataextractor')}/v1/credentials/${hashcode}`, opt);
      const json = await resFetch.json();

      if (resFetch.status === 500) {
        return {
          error: true,
          msg: errorLabels.fetchGeneric,
          raw: 'Verifique se existe algum problema com a sua conexão com a internet e tente novamente mais tarde!',
        };
      }

      if (resFetch.status !== 200) {
        return {
          error: true,
          msg: errorLabels.instanceProvider.saveApiCredentials,
          raw: json.error,
        };
      }

      return { error: false, msg: '' };
    } catch (er) {
      console.log(er);
      return {
        error: true,
        msg: errorLabels.instanceProvider.saveApiCredentials,
        raw: `Erro do sistema: ${er.toString()}`,
      };
    }
  }, [currentUser]);

  const validateApiCredentials = useCallback(async (key, secret) => {
    try {
      const token = await getToken(currentUser);
      const opt = {
        ...await getRequestMeta(token, 'POST', 'JSON'),
        body: JSON.stringify({
          key,
          secret,
        }),
      };

      const resFetch = await fetch(`${ip.replace('/analytics', '/l1dataextractor')}/v1/credentials/test`, opt);
      const json = await resFetch.json();

      if (resFetch.status === 500) {
        return {
          error: true,
          msg: errorLabels.fetchGeneric,
          raw: 'Verifique se existe algum problema com a sua conexão com a internet e tente novamente mais tarde!',
        };
      }

      if (resFetch.status !== 200) {
        return {
          error: true,
          msg: errorLabels.instanceProvider.validateApiCredentials,
          raw: json.error,
        };
      }

      return { error: false, msg: '' };
    } catch (er) {
      console.log(er);
      return {
        error: true,
        msg: errorLabels.instanceProvider.validateApiCredentials,
        raw: `Erro do sistema: ${er.toString()}`,
      };
    }
  }, [currentUser]);

  const saveGedCredentials = useCallback(async (instanceId, key, secret) => {
    try {
      const ref = getRefCompanySecs(instanceId).doc('l1reports');
      const keyObj = {
        createdAt: firebase.serverTimestamp(),
        consumerKey: key,
        consumerSecret: secret,
      };

      await ref.set(keyObj);
      return { error: false, msg: 'ok' };
    } catch (er) {
      console.log(er);
      return {
        error: true,
        msg: errorLabels.instanceProvider.saveGedCredentials,
        raw: `Erro do sistema: ${er.toString()}`,
      };
    }
  }, [currentUser]);

  const writeGeneralMessage = useCallback(async (
    message,
    batch,
  ) => {
    try {
      const ref = getRefGeneralMessages().doc();
      await batch.set(ref, { message });
      return { error: false, msg: 'ok', notificationId: ref.id };
    } catch (er) {
      console.log(er);
      return {
        error: true,
        msg: firestoreErrors(er.code) || errorLabels.instanceProvider.sendUsersMessage,
        raw: `Erro do sistema: ${er.toString()}`,
      };
    }
  }, [currentUser]);

  const sendUsersMessage = useCallback(async (
    instanceId,
    lvl,
    title,
    showUntil,
    link,
    users,
    message,
    batch = undefined,
    notificationId = undefined,
  ) => {
    try {
      const ref = getRefSystemMessages(instanceId).doc();

      const msgObj = {
        timestamp: firebase.serverTimestamp(),
        users: [],
        usersToView: [],
        level: lvl,
        message,
        title,
        showUntil,
      };

      if (link) msgObj.link = link;

      users.forEach((userId) => {
        msgObj.users.push(userId);
        msgObj.usersToView.push(userId);
      });

      if (batch !== undefined) {
        if (notificationId === undefined) {
          return {
            error: true,
            msg: errorLabels.instanceProvider.sendUsersMessage,
            raw: 'NotificationId não definido!',
          };
        }
        await batch.set(ref, { ...msgObj, notificationId });
      } else {
        await ref.set(msgObj);
      }
      return { error: false, msg: 'ok' };
    } catch (er) {
      console.log(er);
      return {
        error: true,
        msg: firestoreErrors(er.code) || errorLabels.instanceProvider.sendUsersMessage,
        raw: `Erro do sistema: ${er.toString()}`,
      };
    }
  }, [currentUser]);

  const getUsers = useCallback(async () => {
    try {
      const token = await getToken(currentUser);
      const opt = {
        ...await getRequestMeta(token, 'GET', 'JSON'),
      };

      const resFetch = await fetch(`${ipAdmin}/admin/user/list`, opt);

      if (resFetch.status >= 500) {
        return {
          error: true,
          msg: errorLabels.fetchGeneric,
          raw: 'Verifique se existe algum problema com a sua conexão com a internet e tente novamente mais tarde!',
        };
      }
      const json = await resFetch.json();
      if (resFetch.status !== 200) {
        return { error: true, msg: errorLabels.instanceProvider.getUsers, raw: json.error };
      }
      const usersJson = json.info.map((user) => ({ ...user, companyName: user?.company?.name || '' }));
      return { error: false, users: usersJson };
    } catch (er) {
      return {
        error: true,
        msg: errorLabels.instanceProvider.getUsers,
        raw: `Erro do sistema: ${er.toString()}`,
      };
    }
  }, [currentUser]);

  const getUserLoginHistory = useCallback(async (userId) => {
    try {
      const token = await getToken(currentUser);
      const opt = {
        ...await getRequestMeta(token, 'GET', 'JSON'),
      };

      const resFetch = await fetch(`${ipAdmin}/admin/user/login_history/${userId}`, opt);

      if (resFetch.status >= 500) {
        return {
          error: true,
          msg: errorLabels.fetchGeneric,
          raw: 'Verifique se existe algum problema com a sua conexão com a internet e tente novamente mais tarde!',
        };
      }
      const json = await resFetch.json();
      if (resFetch.status !== 200) {
        return {
          error: true,
          msg: errorLabels.instanceProvider.getUserHistory,
          raw: json.error,
        };
      }

      return { error: false, res: json.info };
    } catch (er) {
      return {
        error: true,
        msg: errorLabels.instanceProvider.getUserHistory,
        raw: `Erro do sistema: ${er.toString()}`,
      };
    }
  }, [currentUser]);

  const getUserInfo = useCallback(async (email) => {
    try {
      const token = await getToken(currentUser);
      const opt = {
        ...await getRequestMeta(token, 'POST', 'JSON'),
        body: JSON.stringify({
          email,
        }),
      };

      const resFetch = await fetch(`${ipAdmin}/admin/user/info`, opt);

      if (resFetch.status >= 500) {
        return {
          error: true,
          msg: errorLabels.fetchGeneric,
          raw: 'Verifique se existe algum problema com a sua conexão com a internet e tente novamente mais tarde!',
        };
      }
      const json = await resFetch.json();
      if (resFetch.status !== 200) {
        return {
          error: true,
          msg: errorLabels.instanceProvider.getUserInfo,
          raw: json.error,
        };
      }

      return { error: false, res: json.info };
    } catch (er) {
      return {
        error: true,
        msg: errorLabels.instanceProvider.getUserInfo,
        raw: `Erro do sistema: ${er.toString()}`,
      };
    }
  }, [currentUser]);

  const getUserDashboards = useCallback(async (userId) => {
    try {
      const token = await getToken(currentUser);
      const opt = {
        ...await getRequestMeta(token, 'GET', 'JSON'),
      };

      const resFetch = await fetch(`${ipAdmin}/admin/user/dashs/${userId}`, opt);

      if (resFetch.status >= 500) {
        return {
          error: true,
          msg: errorLabels.fetchGeneric,
          raw: 'Verifique se existe algum problema com a sua conexão com a internet e tente novamente mais tarde!',
        };
      }
      const json = await resFetch.json();
      if (resFetch.status !== 200) {
        return {
          error: true,
          msg: errorLabels.instanceProvider.getUserDashboards,
          raw: json.error,
        };
      }

      return { error: false, res: json.info };
    } catch (er) {
      return {
        error: true,
        msg: errorLabels.instanceProvider.getUserDashboards,
        raw: `Erro do sistema: ${er.toString()}`,
      };
    }
  }, [currentUser]);

  const getUserFiles = useCallback(async (companyId, userId) => {
    try {
      const token = await getToken(currentUser);
      const opt = {
        ...await getRequestMeta(token, 'POST', 'JSON'),
        body: JSON.stringify({
          companyId,
          ownerId: userId,
          withInfo: true,
          onlyOwner: false,
        }),
      };

      const resFetch = await fetch(`${ipAdmin}/admdb/list`, opt);

      if (resFetch.status >= 500) {
        return {
          error: true,
          msg: errorLabels.fetchGeneric,
          raw: 'Verifique se existe algum problema com a sua conexão com a internet e tente novamente mais tarde!',
        };
      }
      const json = await resFetch.json();
      if (resFetch.status !== 200) {
        return {
          error: true,
          msg: errorLabels.instanceProvider.getUserFiles,
          raw: json.error,
        };
      }

      return { error: false, res: json.info };
    } catch (er) {
      return {
        error: true,
        msg: errorLabels.instanceProvider.getUserFiles,
        raw: `Erro do sistema: ${er.toString()}`,
      };
    }
  }, [currentUser]);

  const getUserDashFiles = useCallback(async (companyId, userId, dashId) => {
    try {
      const token = await getToken(currentUser);
      const opt = {
        ...await getRequestMeta(token, 'GET', 'JSON'),
      };

      const resFetch = await fetch(`${ipAdmin}/admin/user/reports/${userId}&${dashId}&${companyId}`, opt);

      if (resFetch.status >= 500) {
        return {
          error: true,
          msg: errorLabels.fetchGeneric,
          raw: 'Verifique se existe algum problema com a sua conexão com a internet e tente novamente mais tarde!',
        };
      }
      const json = await resFetch.json();
      if (resFetch.status !== 200) {
        return {
          error: true,
          msg: errorLabels.instanceProvider.getUserFiles,
          raw: json.error,
        };
      }

      return { error: false, res: json.info };
    } catch (er) {
      return {
        error: true,
        msg: errorLabels.instanceProvider.getUserFiles,
        raw: `Erro do sistema: ${er.toString()}`,
      };
    }
  }, [currentUser]);

  const getDashKpis = useCallback(async (userId, dashId) => {
    try {
      const token = await getToken(currentUser);
      const opt = {
        ...await getRequestMeta(token, 'GET', 'JSON'),
      };

      const resFetch = await fetch(`${ipAdmin}/admin/user/dashs/${userId}&${dashId}`, opt);

      if (resFetch.status >= 500) {
        return {
          error: true,
          msg: errorLabels.fetchGeneric,
          raw: 'Verifique se existe algum problema com a sua conexão com a internet e tente novamente mais tarde!',
        };
      }
      const json = await resFetch.json();
      if (resFetch.status !== 200) {
        return {
          error: true,
          msg: errorLabels.instanceProvider.getDashKpis,
          raw: json.error,
        };
      }

      return { error: false, res: json.info };
    } catch (er) {
      return {
        error: true,
        msg: errorLabels.instanceProvider.getDashKpis,
        raw: `Erro do sistema: ${er.toString()}`,
      };
    }
  }, [currentUser]);

  const deleteDashboard = useCallback(async (userId, dashId) => {
    try {
      const token = await getToken(currentUser);
      const opt = {
        ...await getRequestMeta(token, 'DELETE', 'JSON'),
      };

      const resFetch = await fetch(`${ipAdmin}/admin/user/dashs/${userId}&${dashId}`, opt);

      if (resFetch.status >= 500) {
        return {
          error: true,
          msg: errorLabels.fetchGeneric,
          raw: 'Verifique se existe algum problema com a sua conexão com a internet e tente novamente mais tarde!',
        };
      }
      const json = await resFetch.json();
      if (resFetch.status !== 200) {
        return {
          error: true,
          msg: errorLabels.instanceProvider.deleteDashboard,
          raw: json.error,
        };
      }

      return { error: false, res: json.info };
    } catch (error) {
      return {
        error: true,
        msg: errorLabels.instanceProvider.deleteDashboard,
        raw: `Erro do sistema: ${error.toString()}`,
      };
    }
  }, [currentUser]);

  const middleware = useCallback(async (action) => {
    switch (action.type) {
      case 'getInstances': {
        setLoading(true);
        const res = await getInstances();
        if (res.error) {
          setLoading(false);
        } else {
          dispatch({ type: 'setInstances', instances: res.instances });
        }
        return res;
      }

      case 'getInstance': {
        setLoading(true);
        const res = await getInstance(action.instanceId);
        setLoading(false);
        return res;
      }

      case 'getInstanceUsers': {
        setLoading(true);
        const res = await getInstanceUsers(action.instanceId);
        if (res.error) {
          setLoading(false);
        } else {
          dispatch({ type: 'setInstancesUsers', users: res.users, instanceId: action.instanceId });
        }
        return res;
      }

      case 'getUsers': {
        setLoading(true);
        const res = await getUsers();
        if (res.error) {
          setLoading(false);
        } else {
          dispatch({ type: 'setUsers', users: res.users });
        }
        return res;
      }

      case 'createInstance': {
        setLoading(true);
        const res = await createInstance(action.companyinfo, action.emails, action.image);
        setLoading(false);
        // if(res.error) {
        //   setLoading(false);
        // }
        return res;
      }

      case 'editInstance': {
        setLoading(true);
        const res = await editInstance(action.companyinfo);
        if (res.error) {
          setLoading(false);
        } else {
          dispatch({ type: 'editInstance', companyinfo: action.companyinfo });
        }
        return res;
      }

      case 'blockInstance': {
        setLoading(true);
        const res = await blockInstance(action.instanceId, action.block);
        if (res.error) {
          setLoading(false);
        } else {
          dispatch({ type: 'blockInstance', instanceId: action.instanceId, block: action.block });
        }
        return res;
      }

      case 'deleteInstance': {
        setLoading(true);
        const res = await deleteInstance(action.instanceId);
        if (res.error) {
          setLoading(false);
        } else {
          dispatch({ type: 'deleteInstance', instanceId: action.instanceId });
        }
        return res;
      }

      // USERS
      case 'blockUser': {
        setLoading(true);
        const res = await blockUser(
          action.instanceId,
          action.scientistId,
          action.email,
          action.newValue,
        );
        if (res.error) {
          setLoading(false);
        } else {
          dispatch({
            type: 'blockUser',
            email: action.email,
            instanceId: action.instanceId,
            scientistId: action.scientistId,
            newValue: action.newValue,
          });
        }
        return res;
      }

      case 'multiSessionUser': {
        setLoading(true);
        const res = await multiSessionUser(
          action.instanceId,
          action.scientistId,
          action.email,
          action.newValue,
        );
        setLoading(false);
        return res;
      }

      case 'addUser': {
        setLoading(true);
        const res = await addUser(action.instance, action.scientist, action.email, action.role);
        setLoading(false);
        return res;
      }

      case 'deleteUser': {
        setLoading(true);
        const res = await deleteUser(action.instanceId, action.email);
        if (res.error) {
          setLoading(false);
        } else {
          dispatch({ type: 'deleteUser', instanceId: action.instanceId, email: action.email });
        }
        return res;
      }

      case 'changeUserRole': {
        setLoading(true);
        const {
          instanceId, email, newRole, scientist,
        } = action;
        const res = await changeUserRole(instanceId, email, newRole, scientist);
        if (res.error) {
          setLoading(false);
        } else {
          dispatch({
            type: 'changeUserRole',
            instanceId,
            email,
            newRole,
            scientist,
          });
        }

        return res;
      }

      case 'changeUserRoleByAdmin': {
        setLoading(true);
        const {
          instanceId, scientistId, email, newRole,
        } = action;
        const res = await changeUserRoleByAdmin(instanceId, scientistId, email, newRole);
        setLoading(false);
        return res;
      }

      case 'transferUserData': {
        setLoading(true);
        const res = await transferUserData(
          action.instanceId, action.userSender, action.userReceiver, action.viewerOnly,
        );
        setLoading(false);
        return res;
      }

      // REPORTS
      case 'addAntecipeiReport': {
        setLoading(true);
        const res = await addAntecipeiReport(
          action.filename,
          action.ownerId,
          action.companyId,
          action.url,
          action.sendWithoutDataProc,
          action.prettyColumns,
          action.reportToken,
        );
        setLoading(false);
        dispatch({ type: 'emptyReportList' });
        return res;
      }

      case 'addBennerReport': {
        setLoading(true);
        const res = await addBennerReport(
          action.filename,
          action.ownerId,
          action.companyId,
          action.url,
          action.username,
          action.password,
        );
        setLoading(false);
        dispatch({ type: 'emptyReportList' });
        return res;
      }

      case 'createOnboardingReport': {
        setLoading(true);
        const res = await createOnboardingReport(
          action.filename,
          action.instance,
          action.ownerId,
          action.fields,
          action.selectedFields,
          action.module,
          action.alias,
        );
        setLoading(false);
        dispatch({ type: 'emptyReportList' });
        return res;
      }

      case 'getDataExtractorInfo': {
        setLoading(true);
        const res = await getDataExtractorInfo(action.companyId);
        setLoading(false);
        return res;
      }

      case 'getDataExtractorModules': {
        setLoading(true);
        const res = await getDataExtractorModules();
        setLoading(false);
        return res;
      }

      case 'getL1ReportModuleFields': {
        setLoading(true);
        const res = await getL1ReportModuleFields(action.module, action.plan);
        setLoading(false);
        return res;
      }

      case 'getExtractorPossibleTemplates': {
        setLoading(true);
        const res = await getExtractorPossibleTemplates(action.module, action.plan);
        setLoading(false);
        return res;
      }

      case 'getReports': {
        setLoading(true);
        const res = await getReports(action.page, action.filters, action.qty);
        if (res.error) {
          setLoading(false);
        } else {
          dispatch({
            type: 'setReports',
            reports: res.data.reports,
            page: action.page,
            pageTotal: res.data.pageTotal,
            reset: action.reset,
          });
        }
        return res;
      }

      case 'getReportStatus': {
        setLoading(true);
        const res = await getReportStatus(action.hashcode);
        setLoading(false);
        return res;
      }

      case 'deleteReport': {
        setLoading(true);
        const res = await deleteReport(action.hashcode);
        setLoading(false);
        return res;
      }

      case 'resetReport': {
        setLoading(true);
        const res = await resetReport(action.hashcode);
        setLoading(false);
        return res;
      }

      case 'convertToGed': {
        setLoading(true);
        const res = await convertToGed(action.reportId);
        setLoading(false);
        return res;
      }

      case 'manualReportUpdate': {
        setLoading(true);
        const res = await manualReportUpdate(
          action.hashcode, action.origin, action.file, action.filename,
        );
        if (res.error) {
          setLoading(false);
        } else {
          dispatch({
            type: 'updateReports',
            updated: res.data,
            reportId: action.hashcode,
          });
        }
        return res;
      }

      case 'inactivateReport': {
        setLoading(true);
        const res = await inactivateReport(action.hashcode);
        setLoading(false);
        return res;
      }

      case 'activateReport': {
        setLoading(true);
        const res = await activateReport(action.hashcode);
        setLoading(false);
        return res;
      }

      case 'restoreReport': {
        setLoading(true);
        const res = await restoreReport(action.hashcode);
        setLoading(false);
        return res;
      }

      case 'changeOwnerReport': {
        setLoading(true);
        const res = await changeOwnerReport(
          action.companyId,
          action.fileId,
          action.currentOwner,
          action.newOwner,
          action.removeOldOwner,
        );
        setLoading(false);
        return res;
      }

      case 'getReportColumns': {
        setLoading(true);
        const res = await getReportColumns(action.hashcode);
        setLoading(false);
        return res;
      }
      case 'editReportColumns': {
        setLoading(true);
        const res = await editReportColumns(action.hashcode, action.columnMap);
        setLoading(false);
        return res;
      }

      case 'previewCustomColumns': {
        setLoading(true);
        const res = await previewCustomColumns(action.hashcode, action.newColumnsList);
        setLoading(false);
        return res;
      }

      case 'manageCustomColumns': {
        setLoading(true);
        const res = await manageCustomColumns(action.hashcode, action.newColumnsList);
        setLoading(false);
        return res;
      }

      case 'requestApiCredentials': {
        setLoading(true);
        const res = await requestApiCredentials(action.hashcode);
        setLoading(false);
        return res;
      }

      case 'saveApiCredentials': {
        setLoading(true);
        const res = await saveApiCredentials(action.hashcode, action.key, action.secret);
        setLoading(false);
        return res;
      }

      case 'validateApiCredentials': {
        setLoading(true);
        const res = await validateApiCredentials(action.key, action.secret);
        setLoading(false);
        return res;
      }

      case 'saveGedCredentials': {
        setLoading(true);
        const res = await saveGedCredentials(action.instanceId, action.key, action.secret);
        if (!res.error) {
          dispatch({ type: 'setApiGedSuccess', instanceId: action.instanceId });
        }
        setLoading(false);
        return res;
      }

      case 'writeGeneralMessage': {
        setLoading(true);
        const res = await writeGeneralMessage(
          action.message,
          action.batch,
        );
        setLoading(false);
        return res;
      }

      case 'sendUsersMessage': {
        setLoading(true);
        const res = await sendUsersMessage(
          action.instanceId,
          action.lvl,
          action.title,
          action.showUntil,
          action.link,
          action.users,
          action.message,
          action.batch,
          action.notificationId,
        );
        setLoading(false);
        return res;
      }

      case 'getUserLoginHistory': {
        setLoading(true);
        const res = await getUserLoginHistory(action.userId);
        setLoading(false);
        return res;
      }

      case 'getUserInfo': {
        setLoading(true);
        const res = await getUserInfo(action.email);
        setLoading(false);
        return res;
      }

      case 'getUserDashboards': {
        setLoading(true);
        const res = await getUserDashboards(action.userId);
        setLoading(false);
        return res;
      }

      case 'getUserFiles': {
        setLoading(true);
        const res = await getUserFiles(action.companyId, action.userId);
        setLoading(false);
        return res;
      }

      case 'getUserDashFiles': {
        setLoading(true);
        const res = await getUserDashFiles(action.companyId, action.userId, action.dashId);
        setLoading(false);
        return res;
      }

      case 'getDashKpis': {
        setLoading(true);
        const res = await getDashKpis(action.userId, action.dashId);
        setLoading(false);
        return res;
      }

      case 'deleteDashboard': {
        setLoading(true);
        const res = await deleteDashboard(action.userId, action.dashId);
        setLoading(false);
        return res;
      }

      default:
        dispatch(action);
        return { error: false, msg: 'default' };
    }
  },
  [
    getInstances,
    createInstance,
    editInstance,
    blockInstance,
    deleteInstance,
    // users
    blockUser,
    multiSessionUser,
    addUser,
    deleteUser,
    changeUserRole,
    changeUserRoleByAdmin,
    transferUserData,
    getUsers,
    getUserLoginHistory,
    getUserInfo,
    getUserDashboards,
    getUserFiles,
    getUserDashFiles,
    getDashKpis,
    deleteDashboard,
    // reports
    addAntecipeiReport,
    addBennerReport,
    createOnboardingReport,
    getDataExtractorInfo,
    getDataExtractorModules,
    getL1ReportModuleFields,
    getExtractorPossibleTemplates,
    getInstanceUsers,
    getInstance,
    getReports,
    getReportStatus,
    deleteReport,
    resetReport,
    convertToGed,
    manualReportUpdate,
    inactivateReport,
    activateReport,
    restoreReport,
    changeOwnerReport,
    getReportColumns,
    editReportColumns,
    previewCustomColumns,
    manageCustomColumns,
    //
    requestApiCredentials,
    saveApiCredentials,
    validateApiCredentials,
    saveGedCredentials,
    writeGeneralMessage,
    sendUsersMessage,
    setLoading,
  ]);

  const instanceAPI = useMemo(() => ({
    getInstances: async () => middleware({ type: 'getInstances' }),
    getInstance: async (instanceId) => middleware({ type: 'getInstance', instanceId }),
    getInstanceUsers: async (instanceId) => middleware({ type: 'getInstanceUsers', instanceId }),
    createInstance: async (companyinfo, emails, image) => middleware({
      type: 'createInstance', companyinfo, emails, image,
    }),
    editInstance: async (companyinfo) => middleware({ type: 'editInstance', companyinfo }),
    blockInstance: async (instanceId, block) => middleware({ type: 'blockInstance', instanceId, block }),
    deleteInstance: async (instanceId) => middleware({ type: 'deleteInstance', instanceId }),
    //
    blockUser: async (instanceId, scientistId, email, newValue) => middleware({
      type: 'blockUser', instanceId, scientistId, email, newValue,
    }),
    multiSessionUser: async (instanceId, scientistId, email, newValue) => middleware({
      type: 'multiSessionUser', instanceId, scientistId, email, newValue,
    }),
    deleteUser: async (instanceId, email) => middleware({ type: 'deleteUser', instanceId, email }),
    addUser: async (instance, scientist, email, role) => middleware({
      type: 'addUser', instance, scientist, email, role,
    }),
    getUsers: async () => middleware({ type: 'getUsers' }),
    getUserLoginHistory: async (userId) => middleware({
      type: 'getUserLoginHistory', userId,
    }),
    getUserInfo: async (email) => middleware({
      type: 'getUserInfo', email,
    }),
    getUserDashboards: async (userId) => middleware({
      type: 'getUserDashboards', userId,
    }),
    getUserFiles: async (companyId, userId) => middleware({
      type: 'getUserFiles', companyId, userId,
    }),
    getUserDashFiles: async (companyId, userId, dashId) => middleware({
      type: 'getUserDashFiles', companyId, userId, dashId,
    }),
    getDashKpis: async (userId, dashId) => middleware({
      type: 'getDashKpis', userId, dashId,
    }),
    deleteDashboard: async (userId, dashId) => middleware({
      type: 'deleteDashboard', userId, dashId,
    }),
    addAntecipeiReport: async (
      filename,
      ownerId,
      companyId,
      url,
      sendWithoutDataProc,
      prettyColumns,
      reportToken,
    ) => middleware({
      type: 'addAntecipeiReport',
      filename,
      ownerId,
      companyId,
      url,
      sendWithoutDataProc,
      prettyColumns,
      reportToken,
    }),
    addBennerReport: async (
      filename,
      ownerId,
      companyId,
      url,
      username,
      password,
    ) => middleware({
      type: 'addBennerReport',
      filename,
      ownerId,
      companyId,
      url,
      username,
      password,
    }),
    createOnboardingReport: async (
      filename,
      instance,
      ownerId,
      fields,
      selectedFields,
      module,
      alias,
    ) => middleware({
      type: 'createOnboardingReport',
      filename,
      instance,
      ownerId,
      fields,
      selectedFields,
      module,
      alias,
    }),
    getDataExtractorInfo: async (companyId) => middleware({
      type: 'getDataExtractorInfo', companyId,
    }),
    getDataExtractorModules: async () => middleware({
      type: 'getDataExtractorModules',
    }),
    getL1ReportModuleFields: async (module, plan) => middleware({
      type: 'getL1ReportModuleFields', module, plan,
    }),
    getExtractorPossibleTemplates: async (module, plan) => middleware({
      type: 'getExtractorPossibleTemplates', module, plan,
    }),
    changeUserRole: async (instanceId, email, role) => middleware({
      type: 'changeUserRole', instanceId, email, role,
    }),
    changeUserRoleByAdmin: async (instanceId, scientistId, email, newRole) => middleware({
      type: 'changeUserRoleByAdmin', instanceId, scientistId, email, newRole,
    }),
    transferUserData: async (instanceId, userSender, userReceiver, viewerOnly) => middleware({
      type: 'transferUserData', instanceId, userSender, userReceiver, viewerOnly,
    }),
    getReports: async (page, filters, qty, reset) => middleware({
      type: 'getReports', page, filters, qty, reset,
    }),
    getReportStatus: async (hashcode) => middleware({
      type: 'getReportStatus', hashcode,
    }),
    deleteReport: async (hashcode) => middleware({
      type: 'deleteReport', hashcode,
    }),
    resetReport: async (hashcode) => middleware({
      type: 'resetReport', hashcode,
    }),
    convertToGed: async (reportId) => middleware({
      type: 'convertToGed', reportId,
    }),
    manualReportUpdate: async (hashcode, origin, file, filename) => middleware({
      type: 'manualReportUpdate', hashcode, origin, file, filename,
    }),
    inactivateReport: async (hashcode) => middleware({
      type: 'inactivateReport', hashcode,
    }),
    activateReport: async (hashcode) => middleware({
      type: 'activateReport', hashcode,
    }),
    restoreReport: async (hashcode) => middleware({
      type: 'restoreReport', hashcode,
    }),
    changeOwnerReport: async (
      companyId,
      fileId,
      currentOwner,
      newOwner,
      removeOldOwner,
    ) => middleware({
      type: 'changeOwnerReport',
      companyId,
      fileId,
      currentOwner,
      newOwner,
      removeOldOwner,
    }),
    getReportColumns: async (hashcode) => middleware({
      type: 'getReportColumns', hashcode,
    }),
    editReportColumns: async (hashcode, columnMap) => middleware({
      type: 'editReportColumns', hashcode, columnMap,
    }),
    previewCustomColumns: async (hashcode, newColumnsList) => middleware({
      type: 'previewCustomColumns', hashcode, newColumnsList,
    }),
    manageCustomColumns: async (hashcode, newColumnsList) => middleware({
      type: 'manageCustomColumns', hashcode, newColumnsList,
    }),
    //
    requestApiCredentials: async (hashcode) => middleware({
      type: 'requestApiCredentials', hashcode,
    }),
    saveApiCredentials: async (hashcode, key, secret) => middleware({
      type: 'saveApiCredentials', hashcode, key, secret,
    }),
    validateApiCredentials: async (key, secret) => middleware({
      type: 'validateApiCredentials', key, secret,
    }),
    saveGedCredentials: async (instanceId, key, secret) => middleware({
      type: 'saveGedCredentials', instanceId, key, secret,
    }),
    writeGeneralMessage: async (message, batch) => middleware({
      type: 'writeGeneralMessage', message, batch,
    }),
    sendUsersMessage: async (
      instanceId,
      lvl,
      title,
      showUntil,
      link,
      users,
      message,
      batch,
      notificationId,
    ) => middleware({
      type: 'sendUsersMessage', instanceId, lvl, title, showUntil, link, users, message, batch, notificationId,
    }),
  }), [middleware]);

  return (
    <InstanceContext.Provider value={{ state, instanceAPI }}>
      {children}
    </InstanceContext.Provider>
  );
}

InstanceProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.element,
  ]),
};

InstanceProvider.defaultProps = {
  children: null,
};

export default InstanceProvider;
