import React from 'react';

// import { Container } from './styles';

function NewFile() {
  return (
    <svg width="17" height="22" viewBox="0 0 17 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.92614 12.5938H5.40927C5.35982 12.5938 5.3124 12.6135 5.27744 12.6487C5.24248 12.6838 5.22283 12.7315 5.22283 12.7812V13.9062C5.22283 13.956 5.24248 14.0037 5.27744 14.0388C5.3124 14.074 5.35982 14.0938 5.40927 14.0938H7.92614V16.625C7.92614 16.6747 7.94578 16.7224 7.98074 16.7576C8.0157 16.7927 8.06313 16.8125 8.11257 16.8125H9.23118C9.28062 16.8125 9.32804 16.7927 9.36301 16.7576C9.39797 16.7224 9.41761 16.6747 9.41761 16.625V14.0938H11.9345C11.9839 14.0938 12.0313 14.074 12.0663 14.0388C12.1013 14.0037 12.1209 13.956 12.1209 13.9062V12.7812C12.1209 12.7315 12.1013 12.6838 12.0663 12.6487C12.0313 12.6135 11.9839 12.5938 11.9345 12.5938H9.41761V10.0625C9.41761 10.0128 9.39797 9.96508 9.36301 9.92992C9.32804 9.89475 9.28062 9.875 9.23118 9.875H8.11257C8.06313 9.875 8.0157 9.89475 7.98074 9.92992C7.94578 9.96508 7.92614 10.0128 7.92614 10.0625V12.5938ZM16.6559 5.76641C16.7958 5.90703 16.875 6.09687 16.875 6.29609V20.75C16.875 21.1648 16.5417 21.5 16.1293 21.5H1.21449C0.802002 21.5 0.46875 21.1648 0.46875 20.75V1.25C0.46875 0.835156 0.802002 0.5 1.21449 0.5H11.1118C11.3099 0.5 11.501 0.579688 11.6408 0.720313L16.6559 5.76641ZM15.1551 6.64062L10.7693 2.22969V6.64062H15.1551Z" fill="white" />
    </svg>
  );
}

export default NewFile;
