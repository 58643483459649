/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';

import ControllersGroup from '../../../controllers/ControllersGroup';
import {
  InputLineController,
  ToggleSwitchController,
  SelectController,
  RangeController,
  ChoiceBoxController,
  SimpleColorPickerController,
} from '../../../controllers/Base';

import fontOptions from '../../../utils/controllersUtils/options';

const LabelControllers = ({
  handle,
  config,
  extend,
  setExtend,
}) => (
  <>
    <ControllersGroup title="Rótulos" id="labels" extend={extend} setExtend={setExtend}>
      <ToggleSwitchController
        label="Habilitar rótulos"
        handle={handle}
        kWord="EnableLabelControl"
        configValue={config?.EnableLabelControl?.checked}
        firstOption=""
        secondOption=""
        fullWidth
      />
      <SimpleColorPickerController
        label="Cor"
        handle={handle}
        kWord="LabelTextColorControl"
        configValue={config?.LabelTextColorControl}
        orientation="row"
        fullWidth
        disabled={!config?.EnableLabelControl?.checked}
      />
      <SelectController
        label="Fonte"
        handle={handle}
        kWord="LabelsFontFamilyControl"
        options={fontOptions}
        configValue={config?.LabelsFontFamilyControl?.value}
        disabled={!config?.EnableLabelControl?.checked}
      />
      <InputLineController
        label="Tamanho"
        handle={handle}
        kWord="LabelsFontSizeControl"
        configValue={config?.LabelsFontSizeControl?.value}
        min={1}
        max={30}
        step={1}
        precision={0}
        isNumber
        disabled={!config?.EnableLabelControl?.checked}
      />
      <ChoiceBoxController
        label="Posição dos anos"
        handle={handle}
        kWord="YearPositionControl"
        options={config?.LayoutControl === 'horizontal' ? [
          { value: 'before', label: 'Esquerda', id: 'before-1' },
          { value: 'after', label: 'Direita', id: 'after-1' },
        ] : [
          { value: 'before', label: 'Acima', id: 'before-1' },
          { value: 'after', label: 'Abaixo', id: 'after-1' },
        ]}
        configValue={config?.YearPositionControl}
        fullWidth
        disabled={!config?.EnableLabelControl?.checked}
      />
      <RangeController
        label="Distância dos anos"
        handle={handle}
        kWord="YearOffsetControl"
        configValue={config?.YearOffsetControl}
        min={0}
        max={60}
        fullWidth
        disabled={!config?.EnableLabelControl?.checked}
      />
      <ChoiceBoxController
        label="Posição dos meses"
        handle={handle}
        kWord="MonthPositionControl"
        options={config?.LayoutControl === 'vertical' ? [
          { value: 'before', label: 'Esquerda', id: 'before-1' },
          { value: 'after', label: 'Direita', id: 'after-1' },
        ] : [
          { value: 'before', label: 'Acima', id: 'before-1' },
          { value: 'after', label: 'Abaixo', id: 'after-1' },
        ]}
        configValue={config?.MonthPositionControl}
        fullWidth
        disabled={!config?.EnableLabelControl?.checked}
      />
      <RangeController
        label="Distância dos meses"
        handle={handle}
        kWord="MonthOffsetControl"
        configValue={config?.MonthOffsetControl}
        min={0}
        max={36}
        fullWidth
        disabled={!config?.EnableLabelControl?.checked}
      />
    </ControllersGroup>
  </>
);

LabelControllers.propTypes = {
  config: PropTypes.objectOf(PropTypes.any),
  handle: PropTypes.func,
  extend: PropTypes.string,
  setExtend: PropTypes.func,
};

LabelControllers.defaultProps = {
  config: undefined,
  handle: () => {},
  extend: '',
  setExtend: () => {},
};

export default LabelControllers;
