/**
 * Splits a string by a pivot and joins with a custom spacer
 * @param {string} str raw string
 * @param {string|RegExp} pivot custom 'split' separator
 * @param {string} joinSpacer custom spacer
 * @returns formatted string
 */
function formatWhiteSpaces(str, joinSpacer = ' ', pivot = ' ') {
  if (str) return str.split(pivot).filter((v) => v).join(joinSpacer);
  return '';
}

/**
 * Removes unnecessary whitespace from a string
 * @param {string} str raw string
 * @returns formatted string
 */
export const trimString = (str) => formatWhiteSpaces(str);

/**
 * Removes all whitespace from a string
 * @param {string} str raw string
 * @returns formatted string
 */
export const compactString = (str) => formatWhiteSpaces(str, '');

/**
 * Custom formats a string
 * @param {string} str raw string
 * @param {string|RegExp} pivot custom 'split' separator
 * @param {string} joinSpacer custom spacer
 * @returns formatted string
 */
export const customString = (str, join, pivot) => formatWhiteSpaces(str, join, pivot);
