import styled, { css } from 'styled-components';

const placements = {
  start: 'flex-start',
  center: 'center',
  end: 'flex-end',
  getPlacement(placement) {
    return this[placement] || this.start;
  },
};

export const SelectWrapper = styled.div`
  /* background-color: blue; */
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: ${({ clear }) => (!clear ? '25px' : '45px')};
  margin-top: ${({ selectLabel }) => (selectLabel !== '' ? '10px' : '0px')};
  box-sizing: border-box;
  min-height: 35px;
  border-bottom: 1px solid ${({ theme }) => theme.grey};
  outline: none;
  ${({ disabled }) => disabled && css`
    pointer-events: none;
    background-color: ${({ theme }) => theme.disabled};
    opacity: 1;
  `};

  & > span:first-child {
    font-size: 0.85rem;
    color: ${({ theme }) => theme.inputLabel};
    font-weight: bold;
    position: absolute;
    top: -14px;
    z-index: 1;
  }
  &:focus {
    border-bottom: 1px solid ${({ theme }) => theme.highlightTextLine};
    & > span:first-child {
      color: ${({ theme }) => theme.highlightTextLine};
    }
  }

  ${({ fullWidth }) => (fullWidth
    ? css`
          min-width: 120px;
          width: 100%;
        `
    : css`
          min-width: 120px;
          width: 220px;
        `)
}

  ${({ fullHeight }) => fullHeight && css`
    height: 100%;
  `}
`;

export const BtnClearable = styled.div`
  position: absolute;
  top: 50%;
  right: 14px;
  transform: translate(-50%, -50%);
  display: flex;

  & > svg {
    width: 18px;
    height: 18px;
  }

  & > svg, path {
    fill: ${({ theme }) => theme.grey};
  }
`;

export const SelectHeader = styled.div`
  cursor: pointer;
  height: 100%;
  outline: none;
  & > svg:last-child {
    position: absolute;
    top: 50%;
    right: -11px;
    transform: translate(-50%, -50%);
    transition: all 0.5s;
    fill: ${({ theme }) => theme.grey};
    ${({ showList }) => showList && css`
      transform: translate(-50%, -50%) rotate(180deg);
    `};
  }
`;
export const SelectHeaderTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  justify-content: ${({ placement }) => placements.getPlacement(placement)};
  margin: 10px 0px 5px 0px;
  overflow: auto; 
  font-size: 1rem;

  ${({ fullHeight }) => (fullHeight ? css`
    max-height: 120px;
  ` : css`
    max-height: 72px;
  `)}
`;
export const SelectUL = styled.ul`
  padding: 0px;
  box-shadow: 0px 4px 4px ${({ theme }) => theme.shadow};
  border-radius: 4px;
  border-style: none;
  box-sizing: border-box;
  z-index: 100;
  transition: all 0.5s;
  min-width: ${({ width }) => width}px;
  font-size: 1rem;
  font-family: sans-serif !important;
  margin: 0px;
`;

export const LIWrapper = styled.div`
  overflow: hidden auto;
  height: auto;
  max-height: ${({ maxMenuHeight }) => maxMenuHeight}px;
`;

export const SelectLI = styled.li`
  list-style: none;
  padding: 10px 16px;
  background-color: ${({ theme }) => theme.selectOdd};
  word-break: break-all;
  display: flex;
  align-items: center;
  justify-content: ${({ placement }) => placements.getPlacement(placement)};
  &:nth-child(even) {
    background-color: ${({ theme }) => theme.selectEven};
  }
  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.selectHover};
  }
  &:focus { 
    background-color: 'red';
  }
`;

export const SearchInput = styled.input`
  width: 100%;
  padding: 8px 16px;
  background-color: ${({ theme }) => theme.backgroundOdd};
  color: ${({ theme }) => theme.color};
  border: none;
  border-bottom: 1px solid ${({ theme }) => theme.selectOdd};;
  border-top: 1px solid ${({ theme }) => theme.selectOdd};;
  outline: none;
  position: sticky;
  top: 0;
`;

export const Chip = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  .visible-label {
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
  }
  ${({ mult }) => mult && css`
    margin: 2px 2px;
    padding: 2px 4px 2px 8px;
    border: 2px solid ${({ theme }) => theme.secondary};
    color: ${({ theme }) => theme.secondary};
    border-radius: 4px;
    font-size: 0.8rem;
    width: auto;
    background-color: ${({ theme }) => theme.secondary}20;

    &:first-child {
      margin-left: 0px;
    }

    &:last-child {
      margin-right: 0px;
    }

    .btnClose {
      padding: 2px;
      display: flex;
      align-items: center;
      justify-content: center;

      width: 15px;
      height: 15px;
      border-radius: 50%;
      border-style: none;
      background-color: ${({ theme }) => theme.secondary};
      border: 1px solid ${({ theme }) => theme.secondary};
      margin-left: 4px;
      > svg {
        width: 9px;
        height: 9px;
        fill: white;
        top: 2px;
        right: 2px;
        pointer-events: none;
      }
    }
  `}
`;

const types = (type, theme) => {
  switch (type) {
    case 'category':
    case 'object':
      return theme.info;
    case 'datetime64[ns]':
      return theme.warning;
    case 'int64':
    case 'float64':
      return theme.success;
    default:
      return '';
  }
};

export const TypeData = styled.span`
  & > svg:first-child {
    margin-right: 5px;
    padding: 0px 4px;
    width: 35px;
    height: 20px;

    ${({ type, theme }) => css`
      fill: ${types(type, theme)};
    `}
  }
`;
