export default {
  // TITLE
  TitleControl: '',
  ShowTitleControl: { checked: true },
  StyleTitleControl: {
    fontSize: 20,
    color: '#000000',
    fontFamily: 'arial',
    bold: false,
    italic: false,
    underline: false,
  },
  // GENERAL
  SortValuesControl: 'Alfabética A-Z',
  CustomSortControl: {
    checked: false, kpiCategories: [],
  },
  Margin: {
    top: 50,
    right: 110,
    bottom: 50,
    left: 60,
  },
  MinValueControl: { checked: true },
  MaxValueControl: { checked: true },
  DataFormat: { type: 'absolute', target: 'global' },
  // STYLE
  AreaControl: { checked: false, opacity: 0.2 },
  EnablePointsControl: {
    checked: true,
    pointsSize: 10,
    border: 2,
  },
  PointsColorControl: { from: 'color', modifiers: [['darker', 1.6]] },
  LinesFormatControl: 'linear',
  LineWidth: 2,
  PalletsControl: 'default',
  ColorTheme: { scheme: 'nivo' },
  GradientPickerControl: {
    color1: '#4711B2',
    color2: '#FF9626',
    color3: null,
  },
  Colors: {},
  UseGlobalColor: true,
  // LABEL
  EnableLabelControl: { checked: true },
  LabelAxisSelect: 'y',
  DotsLabel: {
    color: '#222222',
    fontFamily: 'sans-serif',
    fontSize: 10,
  },
  LabelRotationControl: 0,
  LabelTranslateX: 0,
  LabelTranslateY: 0,
  PrefixControl: '',
  SufixControl: '',
  Decimals: 'auto',
  separadorNumericoCasas: '0',
  LabelCurrencyControl: 'Nenhum',
  LabelGroupingControl: '',
  // AXIS
  EnableGridY: false,
  EnableGridX: false,
  EnableAxisX: {
    axisTop: false,
    axisBot: true,
    maxLabelLines: 1,
    tickSize: 5,
    tickPadding: 5,
    tickRotation: 0,
    legend: '',
    legendOffset: 35,
  },
  EnableAxisY: {
    axisLeft: true,
    axisRight: false,
    tickSize: 5,
    tickPadding: 5,
    tickRotation: 0,
    legend: '',
    legendOffset: -55,
    Decimals: 'auto',
    separadorNumericoCasasY: 0,
  },
  CurrencyControl: 'Nenhum',
  GroupingSelectControl: '',
  AxisGlobal: {
    fontFamily: 'arial',
    fontSize: 12,
    legendFontSize: 12,
    color: '#333333',
  },
  // LEGEND
  LegendControl: { checked: true },
  LegendPlacementControl: {
    value: {
      label: 'Direita',
      translateX: 110,
      translateY: 0,
      selectedPosition: 'right',
      direction: 'column',
      anchor: 'right',
    },
  },
  LegendsFontFamilyControl: { value: 'sans-serif' },
  LegendsFontSizeControl: { value: 12 },
  LegendsSpacingControl: { value: 10 },
  LegendSymbolControl: 'circle',
  // TOOLTIP / INTERACTIVE
  InteractiveControl: true,
  InteractivePrefixControl: '',
  InteractiveSufixControl: '',
  InteractiveDecimals: 'auto',
  InteractiveSeparadorNumericoCasas: 0,
  InteractiveCurrencyControl: 'Nenhum',
  InteractiveGroupingControl: '',
  Animate: false,
  MotionConfig: 'default',
  // MARKER
  ShowMarkerControl: false,
  MarkersList: [],
};
