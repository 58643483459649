/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';

import ControllersGroup from '../../../controllers/ControllersGroup';
import {
  ChoiceBoxController,
} from '../../../controllers/Base';

const GeneralControllers = ({
  handle,
  config,
  extend,
  setExtend,
  selectedFunction,
}) => (
  <>
    <ControllersGroup
      title="Opções Gerais"
      id="general"
      extend={extend}
      setExtend={setExtend}
    >
      <ChoiceBoxController
        label="Fórmula"
        handle={handle}
        kWord="TotalFunctionControl"
        options={[
          { value: 'avg', label: 'Média dos valores', id: 'totalsAvg' },
          { value: 'sum', label: 'Soma dos valores', id: 'totalsSum' },
        ]}
        configValue={
          config?.TotalFunctionControl || (selectedFunction?.value === 'mean' ? 'avg' : 'sum')
        }
        fullWidth
      />
    </ControllersGroup>
  </>
);

GeneralControllers.propTypes = {
  config: PropTypes.objectOf(PropTypes.any),
  handle: PropTypes.func,
  extend: PropTypes.string,
  setExtend: PropTypes.func,
  selectedFunction: PropTypes.objectOf(PropTypes.any),
};

GeneralControllers.defaultProps = {
  config: undefined,
  handle: () => {},
  extend: '',
  setExtend: () => {},
  selectedFunction: undefined,
};

export default GeneralControllers;
