/* eslint-disable react/forbid-prop-types */
import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import Drawer from '../../juristec-ui/core/Drawer';
import MiniLoading from '../../juristec-ui/core/MiniLoading';
import IconButton from '../../juristec-ui/core/IconButton';
import Popover from '../../juristec-ui/core/Popover';
import Tooltip from '../../juristec-ui/core/Tooltip';
import List from '../../juristec-ui/core/List';
import ListItem from '../../juristec-ui/core/ListItem';
import Checkbox from '../../juristec-ui/core/Checkbox';

import EditableMsg from './EditableMsg';
import MuralInput from './MuralInput';

import useToggleState from '../../juristec-ui/hooks/useToggleState';
import { formatDateTime } from '../../juristec-ui/utils/functions/lab';
import { MuralLogActions } from '../../options';
import { Filter, Reload } from '../../juristec-ui/icons';

import {
  Timeline,
  NoMessagesContainer,
  MsgContainer,
  SystemMsg,
  MuralTitle,
  MuralOpts,
} from './styled/Mural.styled';

/** Translates the user's permission in the message */
const parseParams = {
  visualizador: 'Visualização',
  edit: 'Permissão de cópia',
  get(key) {
    return this[key] || '';
  },
};

/**
 * Removes insights from kpis array (insights are not valid for mentions)
 * @param {array} kpis raw kpis array
 * @returns {array} kpis without insights
 */
const removeInsightKpis = (kpis) => kpis?.filter((k) => !['label', 'smartLabel'].includes(k.type)) || [];

const Mural = ({
  open,
  close,
  users,
  currentUser,
  kpiState,
  addComment,
  editComment,
  archiveComment,
  loadComments,
  loadMoreComments,
}) => {
  const timelineRef = useRef();

  const [popFilter, togglePopFilter, closePopFilter] = useToggleState(false);
  const [showComments, setShowComments] = useState(true);
  const [showLogs, setShowLogs] = useState(true);

  /** Scrolls the timeline to the bottom */
  const scrollToBottom = () => {
    if (timelineRef?.current) {
      timelineRef.current.scrollTop = timelineRef.current.scrollHeight;
    }
  };

  /**
   * @async
   * Loads or reloads latest messages
   */
  const loadMessages = async () => {
    await loadComments();
    scrollToBottom();
  };

  /* Load comments when mural is open */
  useEffect(() => {
    if (open && kpiState.comments?.length === 0) {
      loadMessages();
    } else {
      scrollToBottom();
    }
  }, [loadComments, open]);

  /**
   * Gets the dashboard author data
   * @param {string} uid author id to be found
   * @returns {object} author data
   */
  const getAuthor = (uid) => users?.find((u) => u.uid === uid);

  /**
   * Gets the name inside a user data object
   * @param {string} uid user id to be found
   * @param {string} name fallback name
   * @returns {string} user name
   */
  const getName = (uid, name) => (uid ? getAuthor(uid)?.name || name : name);

  /**
   * Formats the log message target
   * @param {object} log Log data
   * @returns {JSX.Element} Data formatted in JSX html elements
   */
  const MuralLogTarget = useCallback((log) => (
    <>
      {!!log.targetName && <span className="highlight">{getName(log.targetId, log.targetName)}</span>}
      {!!(log.newDashName || log.newUserPermission || log.newLabelName) && (
        <>
          {' para '}
          <span className="highlight">{log.newDashName || log.newLabelName || parseParams.get(log.newUserPermission)}</span>
        </>
      )}
    </>
  ), [users]);

  /**
   * Formats the comments for the timeline
   * @returns {JSX.Element|JSX.Element[]} Data formatted in JSX html elements
   */
  const RenderComments = () => {
    const messages = kpiState.comments.reduce((aux, c) => {
      if (c.isLog) {
        if (showLogs) {
          /* System logs */
          aux.push(
            <SystemMsg key={c.id}>
              <span>
                <span className="highlight">{getName(c.authorId, c.author.split('@')[0])}</span>
                {' '}
                {MuralLogActions.get(c.type, c.action)}
                {' '}
                {MuralLogTarget(c)}
              </span>
              <span className="timestamp">{formatDateTime(c.timestamp, { time: 'half' })}</span>
            </SystemMsg>,
          );
        }
      } else if (showComments) {
        /* Users messages */
        aux.push(
          <EditableMsg
            key={c.id}
            comment={c}
            userAuthor={getAuthor(c.authorId)}
            users={users || []}
            kpis={removeInsightKpis(kpiState.kpiItemList)}
            canEdit={currentUser.uid === c.authorId}
            canDelete={currentUser.uid === c.authorId}
            archiveComment={archiveComment}
            editComment={editComment}
          />,
        );
      }
      return aux;
    }, []);
    if (messages.length === 0) {
      return (
        <NoMessagesContainer>
          {kpiState.commentsLoading ? (
            <MiniLoading fill="lightgrey" />
          ) : (
            <span>
              Nenhuma conversa foi iniciada ainda
              <br />
              Seja o primeiro a começar
            </span>
          )}
        </NoMessagesContainer>
      );
    }
    return messages;
  };

  /**
   * @async
   * Loads older comments when timeline scrolls to top
   * @param {Event} e DOM onScroll event
   */
  const handleCommentsScroll = async (e) => {
    if (!kpiState.commentsCursor || timelineRef.current.children.length === 1) return;
    if (e.target.scrollTop <= 0 && !kpiState.commentsLoadingMore) {
      const beforeMoreScroll = timelineRef.current.scrollHeight;
      await loadMoreComments(kpiState.commentsCursor);
      timelineRef.current.scrollTop = timelineRef.current.scrollHeight - beforeMoreScroll;
    }
  };

  /* Sets scroll to top when loading older messages */
  useEffect(() => {
    if (kpiState.commentsLoadingMore) timelineRef.current.scrollTop = 0;
  }, [kpiState.commentsLoadingMore]);

  /**
   * @async
   * Adds a new comment to the timeline
   * @param {string} text Written message
   * @param {object} mentions Users or kpis mentioned in the comment
   */
  const addNewComment = async (text, mentions) => {
    await addComment(text, mentions);
    scrollToBottom();
  };

  return (
    <Drawer
      open={open}
      direction="left"
      handleCloseDrawer={close}
      customWidth="25rem"
    >
      {/* Timeline filter */}
      <MuralOpts>
        <Popover
          open={popFilter}
          closePopover={closePopFilter}
          direction="bottom-start"
          offset={[16, 2]}
        >
          <Popover.Action>
            <Tooltip text="Filtrar mensagens">
              <IconButton
                color={showComments && showLogs ? 'primary' : 'secondary'}
                onClick={togglePopFilter}
              >
                <Filter />
              </IconButton>
            </Tooltip>
          </Popover.Action>
          <Popover.Content style={{ backgroundColor: 'white', boxShadow: '0px -1px 4px #9a9a9a' }}>
            <List>
              <ListItem noOutline style={{ padding: '0' }}>
                <Checkbox
                  text="Registro"
                  variant="outlined"
                  color="primary"
                  handleCheckboxChange={setShowLogs}
                  checked={showLogs}
                  style={{ padding: '10px', width: '100%', justifyContent: 'start' }}
                />
              </ListItem>
              <ListItem noOutline style={{ padding: '0' }}>
                <Checkbox
                  text="Mensagens"
                  variant="outlined"
                  color="primary"
                  handleCheckboxChange={setShowComments}
                  checked={showComments}
                  style={{ padding: '10px', width: '100%', justifyContent: 'start' }}
                />
              </ListItem>
            </List>
          </Popover.Content>
        </Popover>
        <Tooltip text="Atualizar">
          <IconButton
            color="primary"
            onClick={loadMessages}
            disabled={kpiState.commentsLoading}
          >
            <Reload />
          </IconButton>
        </Tooltip>
        <MuralTitle>Mural</MuralTitle>
      </MuralOpts>

      {/* Messages timeline */}
      <Timeline className="testMural" ref={timelineRef} onScroll={handleCommentsScroll}>
        {kpiState.commentsLoadingMore && (
          <MsgContainer style={{ alignItens: 'center', justifyContent: 'center' }}>
            <MiniLoading fill="lightgrey" />
          </MsgContainer>
        )}
        {RenderComments()}
      </Timeline>

      {/* New message input */}
      <MuralInput
        submitNew={addNewComment}
        users={users || []}
        kpis={removeInsightKpis(kpiState.kpiItemList)}
      />
    </Drawer>
  );
};

Mural.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
  users: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  currentUser: PropTypes.objectOf(PropTypes.any).isRequired,
  kpiState: PropTypes.objectOf(PropTypes.any).isRequired,
  addComment: PropTypes.func,
  editComment: PropTypes.func,
  archiveComment: PropTypes.func,
  loadComments: PropTypes.func,
  loadMoreComments: PropTypes.func,
};

Mural.defaultProps = {
  open: false,
  close: () => {},
  users: [],
  addComment: () => {},
  editComment: () => {},
  archiveComment: () => {},
  loadComments: () => {},
  loadMoreComments: () => {},
};

export default Mural;
