/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';

import ControllersGroup from '../../../controllers/ControllersGroup';
import {
  InputLineController,
  ToggleSwitchController,
  SelectController,
  RangeController,
  ChoiceBoxController,
  SimpleColorPickerController,
} from '../../../controllers/Base';

import fontOptions from '../../../utils/controllersUtils/options';

const LabelControllers = ({
  handle,
  config,
  extend,
  setExtend,
}) => (
  <>
    <ControllersGroup title="Rótulos" id="labels" extend={extend} setExtend={setExtend}>
      <ToggleSwitchController
        label="Habilitar rótulos"
        handle={handle}
        kWord="EnableLabelControl"
        configValue={config?.EnableLabelControl?.checked}
        firstOption=""
        secondOption=""
        fullWidth
      />
      <ChoiceBoxController
        label="Formato"
        handle={handle}
        kWord="LabelAxisSelect"
        options={[
          { label: 'X', value: 'x', id: 'x' },
          { label: 'Y', value: 'y', id: 'y' },
          { label: 'X : Y', value: 'x : y', id: 'x : y' },
          { label: 'Y : X', value: 'y : x', id: 'y : x' },
        ]}
        configValue={config?.LabelAxisSelect}
        fullWidth
        disabled={!config?.EnableLabelControl?.checked}
      />
      <SimpleColorPickerController
        label="Cor"
        handle={handle}
        kWord="PointsLabelColor"
        configValue={config?.DotsLabel?.color}
        orientation="row"
        fullWidth
        disabled={!config?.EnableLabelControl?.checked}
      />
      <SelectController
        label="Fonte"
        handle={handle}
        kWord="PointsLabelFontFamily"
        options={fontOptions}
        configValue={config?.DotsLabel?.fontFamily}
        disabled={!config?.EnableLabelControl?.checked}
      />
      <InputLineController
        label="Tamanho"
        handle={handle}
        kWord="PointsLabelFontSize"
        configValue={config?.DotsLabel?.fontSize}
        min={1}
        max={30}
        step={1}
        precision={0}
        isNumber
        disabled={!config?.EnableLabelControl?.checked}
      />
      <RangeController
        label="Rotação"
        handle={handle}
        kWord="LabelRotationControl"
        configValue={config?.LabelRotationControl}
        min={-180}
        max={180}
        fullWidth
        disabled={!config?.EnableLabelControl?.checked}
      />
      <RangeController
        label="Posição horizontal"
        handle={handle}
        kWord="LabelTranslateX"
        configValue={config?.LabelTranslateX}
        min={-500}
        max={500}
        fullWidth
        disabled={!config?.EnableLabelControl?.checked}
      />
      <RangeController
        label="Posição vertical"
        handle={handle}
        kWord="LabelTranslateY"
        configValue={config?.LabelTranslateY}
        min={-500}
        max={500}
        fullWidth
        disabled={!config?.EnableLabelControl?.checked}
      />
      <InputLineController
        label="Prefixo"
        handle={handle}
        kWord="PrefixControl"
        configValue={config?.PrefixControl}
        fullWidth
        disabled={!config?.EnableLabelControl?.checked}
      />
      <InputLineController
        label="Sufixo"
        handle={handle}
        kWord="SufixControl"
        configValue={config?.SufixControl}
        fullWidth
        disabled={!config?.EnableLabelControl?.checked}
      />
      <ChoiceBoxController
        label="Casas decimais"
        handle={handle}
        kWord="Decimals"
        options={[
          { value: 'auto', label: 'Automático', id: 'auto' },
          { value: 'fixed', label: 'Customizável', id: 'fixed' },
        ]}
        configValue={config?.Decimals || 'auto'}
        fullWidth
        disabled={!config?.EnableLabelControl?.checked}
      />
      <RangeController
        label="Quantidade de casas"
        handle={handle}
        kWord="separadorNumericoCasas"
        configValue={config?.separadorNumericoCasas}
        min={0}
        max={5}
        step={1}
        precision={0}
        fullWidth
        disabled={!config?.EnableLabelControl?.checked || config?.Decimals !== 'fixed'}
      />
      <SelectController
        label="Moeda"
        handle={handle}
        kWord="LabelCurrencyControl"
        options={['Nenhum', 'R$', 'US$', '€'].map((d) => ({ value: d, label: d, id: d }))}
        configValue={config?.LabelCurrencyControl}
        disabled={!config?.EnableLabelControl?.checked || config?.DataFormat?.type === 'percentual'}
      />
      <SelectController
        label="Agrupamento"
        handle={handle}
        kWord="LabelGroupingControl"
        options={[
          { value: 'Nenhum', label: 'Nenhum', id: 'Nenhum' },
          { value: 'milhar', label: 'Milhar (M)', id: 'Milhar (M)' },
          { value: 'milhao', label: 'Milhão (Mi)', id: 'Milhão (Mi)' },
          { value: 'bilhao', label: 'Bilhão (Bi)', id: 'Bilhão (Bi)' },
        ]}
        configValue={config?.LabelGroupingControl || 'Nenhum'}
        disabled={!config?.EnableLabelControl?.checked || config?.DataFormat?.type === 'percentual'}
      />
    </ControllersGroup>
  </>
);

LabelControllers.propTypes = {
  config: PropTypes.objectOf(PropTypes.any),
  handle: PropTypes.func,
  extend: PropTypes.string,
  setExtend: PropTypes.func,
};

LabelControllers.defaultProps = {
  config: undefined,
  handle: () => {},
  extend: '',
  setExtend: () => {},
};

export default LabelControllers;
