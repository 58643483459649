import React from 'react';

function DisplayGrid() {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.2 0C0.88174 0 0.576515 0.126428 0.351472 0.351472C0.126428 0.576515 0 0.88174 0 1.2V3.6C0 3.91826 0.126428 4.22348 0.351472 4.44853C0.576515 4.67357 0.88174 4.8 1.2 4.8H7.2C7.51826 4.8 7.82348 4.67357 8.04853 4.44853C8.27357 4.22348 8.4 3.91826 8.4 3.6V1.2C8.4 0.88174 8.27357 0.576515 8.04853 0.351472C7.82348 0.126428 7.51826 0 7.2 0H1.2Z" />
            <path d="M1.2 7.20001C0.88174 7.20001 0.576515 7.32644 0.351472 7.55148C0.126428 7.77653 0 8.08175 0 8.40001V10.8C0 11.1183 0.126428 11.4235 0.351472 11.6485C0.576515 11.8736 0.88174 12 1.2 12H7.2C7.51826 12 7.82348 11.8736 8.04853 11.6485C8.27357 11.4235 8.4 11.1183 8.4 10.8V8.40001C8.4 8.08175 8.27357 7.77653 8.04853 7.55148C7.82348 7.32644 7.51826 7.20001 7.2 7.20001H1.2Z" />
            <path d="M1.2 14.4C0.88174 14.4 0.576515 14.5264 0.351472 14.7515C0.126428 14.9765 0 15.2817 0 15.6V18C0 18.3183 0.126428 18.6235 0.351472 18.8485C0.576515 19.0736 0.88174 19.2 1.2 19.2H7.2C7.51826 19.2 7.82348 19.0736 8.04853 18.8485C8.27357 18.6235 8.4 18.3183 8.4 18V15.6C8.4 15.2817 8.27357 14.9765 8.04853 14.7515C7.82348 14.5264 7.51826 14.4 7.2 14.4H1.2Z" />
            <path d="M11.9998 0C11.6815 0 11.3763 0.126428 11.1513 0.351472C10.9262 0.576515 10.7998 0.88174 10.7998 1.2V3.6C10.7998 3.91826 10.9262 4.22348 11.1513 4.44853C11.3763 4.67357 11.6815 4.8 11.9998 4.8H17.9998C18.3181 4.8 18.6233 4.67357 18.8483 4.44853C19.0734 4.22348 19.1998 3.91826 19.1998 3.6V1.2C19.1998 0.88174 19.0734 0.576515 18.8483 0.351472C18.6233 0.126428 18.3181 0 17.9998 0H11.9998Z" />
            <path d="M11.9998 7.20001C11.6815 7.20001 11.3763 7.32644 11.1513 7.55148C10.9262 7.77653 10.7998 8.08175 10.7998 8.40001V10.8C10.7998 11.1183 10.9262 11.4235 11.1513 11.6485C11.3763 11.8736 11.6815 12 11.9998 12H17.9998C18.3181 12 18.6233 11.8736 18.8483 11.6485C19.0734 11.4235 19.1998 11.1183 19.1998 10.8V8.40001C19.1998 8.08175 19.0734 7.77653 18.8483 7.55148C18.6233 7.32644 18.3181 7.20001 17.9998 7.20001H11.9998Z" />
        </svg>
    );
}

export default DisplayGrid;
