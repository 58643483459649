import { useEffect } from 'react';

const useOnPressEnter = (disableListener, handler) => {
  const listener = ({ key, keyCode, which }) => {
    if (!disableListener && (which === 13 || keyCode === 13 || key === 13)) {
      handler();
    }
  };
  useEffect(() => {
    document.addEventListener('keyup', listener);

    return () => {
      document.removeEventListener('keyup', listener);
    };
  }, []);
};

export default useOnPressEnter;
