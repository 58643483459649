/* eslint-disable react/no-unescaped-entities */
import React, {
  useState, useEffect, useRef,
} from 'react';
import PropTypes from 'prop-types';

import Select from '../../../juristec-ui/core/Select';
import Button from '../../../juristec-ui/core/Button';
import ActionsGroup from '../../../juristec-ui/core/ActionsGroup';
import Tooltip from '../../../juristec-ui/core/Tooltip';
import IconButton from '../../../juristec-ui/core/IconButton';
import InputTextLine from '../../../juristec-ui/core/InputTextLine';

import {
  MainContainer,
  GridContainer,
  LeftSide,
  RightSide,
  ColumnBadge,
  EmptyMsg,
  InfoTooltip,
} from './styled/ColumnAlias.styled';

import {
  Close, DateIcon, Letters, Number,
} from '../../../juristec-ui/icons';

import { verifyColumnName } from '../../../juristec-ui/utils/validators/inputTextValidators';
import compare from '../../../utils/functions/sorting';

const icons = {
  category: <Letters />,
  float64: <Number />,
  'datetime64[ns]': <DateIcon />,
};

const getType = (typeLabel) => {
  switch (typeLabel) {
    case 'date':
      return 'datetime64[ns]';
    case 'float':
      return 'float64';
    default:
      return 'category';
  }
};

const EMPTYSELECTION = { label: '', value: '' };

const ColumnAlias = ({
  columns,
  alias,
  hide,
  submit,
}) => {
  const usedNames = useRef(new Set());
  const isDirty = useRef(false);
  const [selectedColumn, setSelectedColumn] = useState(EMPTYSELECTION);
  const [columnsOpts, setColumnsOpts] = useState([]);
  const [tempAlias, setTempAlias] = useState({});
  const [newName, setNewName] = useState({ value: '', error: true, errorMsg: '' });

  useEffect(() => {
    if (columns && alias) {
      const auxAlias = {};
      setColumnsOpts(Object.keys(columns).reduce((aux, col) => {
        usedNames.current.add(col);
        if (alias[col]) {
          auxAlias[alias[col]] = { src: alias[col], dst: col, type: getType(columns[col]) };
          usedNames.current.add(alias[col]);
        } else {
          aux.push({
            label: col, value: col, id: col, type: getType(columns[col]),
          });
        }
        return aux;
      }, []).sort((a, b) => (compare(a.label, b.label))));
      setTempAlias(auxAlias);
    }
  }, [columns, alias]);

  const handleNewName = (value) => {
    const msg = verifyColumnName(value, usedNames.current);
    setNewName({
      value,
      error: msg.length !== 0,
      errorMsg: msg,
    });
  };

  const addTempAlias = () => {
    const nName = newName.value.split(' ').filter((v) => v).join(' ');
    setTempAlias((al) => ({
      ...al,
      [selectedColumn.value]: {
        src: selectedColumn.value,
        dst: nName,
        type: selectedColumn.type,
      },
    }));
    usedNames.current.add(nName);
    isDirty.current = true;
    setColumnsOpts((opts) => opts.filter((op) => op.value !== selectedColumn.value));
    setSelectedColumn(EMPTYSELECTION);
    setNewName({ value: '', error: true, errorMsg: '' });
  };

  const removeTempAlias = (e, selected) => {
    e.preventDefault();
    e.stopPropagation();
    setTempAlias((al) => {
      const { [selected.src]: _, ...auxObj } = al;
      return auxObj;
    });
    setColumnsOpts((opts) => [...opts, {
      label: selected.src,
      value: selected.src,
      id: selected.src,
      type: selected.type,
    }].sort((a, b) => (compare(a.label, b.label))));
    usedNames.current.delete(selected.dst);
    isDirty.current = true;
  };

  const saveFilters = () => {
    submit(Object.values(tempAlias));
    hide();
  };

  const disableBtn = () => (selectedColumn.value === '' || newName.error);

  return (
    <MainContainer>
      <GridContainer>
        <LeftSide>
          <span>
            Selecione a coluna que você quer apelidar e insira o novo nome no campo abaixo:
          </span>
          <span className="alias-warning">
            <b>Atenção: </b>
            se você utilizou essas variáveis que estão sendo alteradas em algum KPI,
            esses KPI's deixarão de ser atualizados.
            Para evitar isto, altere os KPI's correspondentes
          </span>
          <Select
            selectLabel="Coluna"
            options={columnsOpts}
            value={selectedColumn}
            onChange={setSelectedColumn}
            atModal
            fullWidth
          />
          <InputTextLine
            label="Novo nome"
            errorMessage={newName.errorMsg}
            error={newName.errorMsg.length > 0}
            onChange={(e) => handleNewName(e.target.value)}
            value={newName.value}
          />
          <Button
            color="secondary"
            onClick={addTempAlias}
            disabled={disableBtn()}
          >
            Apelidar coluna
          </Button>
        </LeftSide>
        <RightSide>
          {Object.keys(tempAlias).length > 0 ? (
            Object.keys(tempAlias).map((aKey) => (
              <Tooltip
                key={aKey}
                text={(
                  <InfoTooltip>
                    <span>{`Nome: ${tempAlias[aKey].dst}`}</span>
                    <span>{`Origem: ${tempAlias[aKey].src}`}</span>
                  </InfoTooltip>
                )}
                direction="top"
                atModal
              >
                <ColumnBadge varType={tempAlias[aKey].type}>
                  {icons[tempAlias[aKey].type]}
                  <div>
                    <h5>{tempAlias[aKey].dst}</h5>
                    <span>{tempAlias[aKey].src}</span>
                  </div>
                  <IconButton
                    size="small"
                    variant="contained"
                    color="secondary"
                    style={{ boxShadow: 'none', margin: '0 2px 0 auto' }}
                    onClick={(e) => removeTempAlias(e, tempAlias[aKey])}
                  >
                    <Close />
                  </IconButton>
                </ColumnBadge>
              </Tooltip>
            ))
          ) : (
            <EmptyMsg>
              Nenhuma coluna foi renomeada.
            </EmptyMsg>
          )}
        </RightSide>
      </GridContainer>
      <ActionsGroup>
        <Button
          style={{ margin: '5px' }}
          onClick={hide}
          variant="outlined"
          size="large"
        >
          Cancelar
        </Button>
        <Button
          style={{ margin: '5px' }}
          size="large"
          onClick={saveFilters}
          disabled={!isDirty.current}
        >
          Salvar
        </Button>
      </ActionsGroup>
    </MainContainer>
  );
};

ColumnAlias.propTypes = {
  columns: PropTypes.objectOf(PropTypes.string),
  alias: PropTypes.objectOf(PropTypes.string),
  hide: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
};

ColumnAlias.defaultProps = {
  columns: {},
  alias: {},
};

export default ColumnAlias;
