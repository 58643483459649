import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';

import Popover from '../../juristec-ui/core/Popover';
import IconButton from '../../juristec-ui/core/IconButton';
import List from '../../juristec-ui/core/List';
import ListItem from '../../juristec-ui/core/ListItem';

import { MoreVert, Edit, Trash } from '../../juristec-ui/icons';

import {
  OptionsContainer,
} from './styled/GridTemplates.styled';

const AdminOptions = ({
  editTemplate, deleteTemplate,
}) => {
  const [openPopover, setOpenPopover] = useState(false);
  const theme = useTheme();

  return (
    <Popover
      style={{
        borderRadius: '8px',
        backgroundColor: theme.popoverBackground,
        overflow: 'hidden',
      }}
      offset={[0, 0]}
      open={openPopover}
      stayOpened={false}
      closePopover={() => setOpenPopover(false)}
    >
      <Popover.Action>
        <IconButton
          size="medium"
          shape="rounded"
          style={{ padding: '1px' }}
          onClick={() => setOpenPopover(!openPopover)}
        >
          <MoreVert />
        </IconButton>
      </Popover.Action>
      <Popover.Content>
        <OptionsContainer>
          <List>
            <ListItem onClick={editTemplate} noOutline>
              <Edit />
              Editar Template
            </ListItem>
            <ListItem onClick={deleteTemplate} noOutline>
              <Trash />
              Remover template
            </ListItem>
          </List>
        </OptionsContainer>
      </Popover.Content>
    </Popover>
  );
};

AdminOptions.propTypes = {
  editTemplate: PropTypes.func.isRequired,
  deleteTemplate: PropTypes.func.isRequired,
};

export default AdminOptions;
