import React from 'react';
import PropTypes from 'prop-types';

import Tooltip from '../../core/Tooltip';
import IconButton from '../../core/IconButton';

import {
  Chain,
} from '../../icons';

import StyledKpiContentLabel from './styled/Insight.styled';

const getInsightStyle = (styles, globalTheme) => ({
  backgroundColor: globalTheme?.useGlobalTheme ? globalTheme.insightBg : styles?.bgColor,
  color: globalTheme?.useGlobalTheme ? globalTheme.insightTxt : styles?.fontColor ?? '#222',
  fontSize: `${styles?.fontSize ?? 16}px`,
  fontFamily: `'${styles?.fontFamily?.value ?? styles?.fontFamily}', Arial`,
  fontWeight: styles?.bold ? 'bold' : 'normal',
  textDecoration: styles?.underline ? 'underline' : 'none',
  fontStyle: styles?.italic ? 'italic' : 'normal',
});

const Insight = ({
  item, globalTheme,
}) => (
  <StyledKpiContentLabel
    style={getInsightStyle(item.style, globalTheme)}
    styles={item.style}
    img={item?.style?.image || ''}
  >
    <span style={{ zIndex: 2 }}>{item.name}</span>
    {item.link?.length > 0 && (
      <div style={{
        position: 'absolute', left: '4px', bottom: '4px', fontSize: 'initial',
      }}
      >
        <Tooltip direction="right" text={`Redirecionar para ${item.link}`}>
          <a href={item.link} target="_blank" rel="noopener noreferrer">
            <IconButton
              variant="outlined"
              size="small"
              shape="rounded"
              iconSize="18px"
              style={{ boxShadow: 'none' }}
            >
              <Chain />
            </IconButton>
          </a>
        </Tooltip>
      </div>
    )}
  </StyledKpiContentLabel>
);

Insight.propTypes = {
  item: PropTypes.shape({
    style: PropTypes.shape({
      image: PropTypes.string,
    }),
    link: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  globalTheme: PropTypes.objectOf(PropTypes.any),
};

Insight.defaultProps = {
  globalTheme: {},
};

export default Insight;
