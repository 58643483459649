import React, {
  useEffect, useState, useRef, useContext,
} from 'react';

// components
import Loader from '../../juristec-ui/core/Loader';
// import InstanceTable from '../../juristec-ui/core/InstanceTable';
import AdminToolbar from '../../components/Toolbars/AdminToolbar';
import InstanceUserTable from '../../juristec-ui/core/InstanceUserTable';
import NewInstance from '../../components/ProgressiveModals/NewInstance';
import AddUser from '../../components/Modals/AddUser';
import AddAntecipei from '../../components/Modals/AddAntecipei';
import OnboardingReport from '../../components/ProgressiveModals/OnboardingReport';
import TransferUser from '../../components/Modals/TransferUser';
import ChangeUserRole from '../../components/Modals/ChangeUserRole';
import ChangeReportOwner from '../../components/Modals/ChangeReportOwner';
import ReportColumns from '../../components/Modals/ReportColumns';
import ReportsTable from '../../components/ReportsTable';
import InstanceTable from '../../components/InstanceTable';
import FileReport from '../../components/FilesView/FileReport';
import FileReportInactive from '../../components/FilesView/FileReportInactive';
import AddBenner from '../../components/Modals/AddBenner/AddBenner';
import InstanceMessage from '../../components/Modals/InstanceMessage';
import AllUsersMessage from '../../components/Modals/AllUsersMessage';
import CustomColumns from '../../components/Modals/CustomColumns';
import ManageCustomColumns from '../../components/ProgressiveModals/ManageCustomColumns';
import UserInfo from '../../components/Modals/UserInfo/UserInfo';
import UsersTable from '../../components/UsersTable/UsersTable';
import UserHistory from '../../components/Modals/UserHistory/UserHistory';

// providers an other
import InstanceProvider, { InstanceContext } from '../../context/InstanceProvider';
import { AlertContext } from '../../context/AlertProvider';
import { FilesContext } from '../../context/FilesProvider';
import { ModalContext } from '../../context/ModalProvider';
import { AuthContext } from '../../context/AuthProvider';
import { verifyFileSheetType } from '../../juristec-ui/utils/validators/fileValidators';
import useDeviceType from '../../juristec-ui/hooks/useDeviceType';
import UserDashboards from '../../components/Modals/UserDashboards/UserDashboards';
import DashSharedWith from '../../components/Modals/DashSharedWith/DashSharedWith';
import DashboardDependencies from '../../components/Modals/DashboardDependencies';
import DashboardKpisList from '../../components/Modals/DashboardKpisList/DashboardKpisList';
import RegisterApiGedCredentials from '../../components/Modals/RegisterApiGedCredentials/RegisterApiGedCredentials';

import { getBatch } from '../../utils/firestore';

const ROLES = {
  SCIENTIST: 'scientist',
  GUEST: 'guest',
  SUPER: 'super',
};

function AdminPage() {
  const [filterText, setFilterText] = useState('');
  const [tab, setTab] = useState('customers');
  const [loading, setLoading] = useState(false);

  const { state: instanceState, instanceAPI } = useContext(InstanceContext);
  const { user, claimsUser } = useContext(AuthContext);
  const { setAlertConfig } = useContext(AlertContext);
  const { filesAPI } = useContext(FilesContext);
  const { setModalConfig, toggleModal, closeModal } = useContext(ModalContext);

  const isMounted = useRef(false);
  const cronRef = useRef(null);

  const device = useDeviceType();

  const handleText = (e) => {
    const { value } = e.target;
    clearTimeout(cronRef.current);
    cronRef.current = setTimeout(() => {
      setFilterText(value);
    }, 200);
  };

  useEffect(() => {
    setFilterText('');
  }, [tab]);

  // USUÁRIOS DA INSTÂNCIA
  async function handleGetInstanceUser(instance) {
    let users = [];
    if (!instance.users) {
      const res = await instanceAPI.getInstanceUsers(instance.id);
      if (res.error) {
        setAlertConfig({
          type: 'error',
          text: res.msg,
        });
        return null;
      }
      users = res.users;
    } else {
      users = instance.users;
    }
    return users;
  }

  /**
   * Transfers all allowed data from one user to another
   * @param {array} usersValids List of allowed users to receive the transfer
   * @param {object} selectedUser User information to be transferred
   * @param {object} instance User instance info
   */
  const handleTransferUser = async (usersValids, selectedUser, instance, viewerOnly) => {
    const options = usersValids.reduce((aux, u) => {
      if ((u.role === ROLES.SUPER || u.role === ROLES.SCIENTIST) && u.uid !== selectedUser.uid) {
        aux.push({ label: u.name, value: u.uid, id: `transfer-${u.uid}` });
      }
      return aux;
    }, []);

    const hideModal = () => toggleModal('transfer-user');

    /** Submit the transfer request */
    const submitTransfer = async (userToReceive) => {
      const res = await instanceAPI.transferUserData(
        instance.id, selectedUser.uid, userToReceive.value, viewerOnly,
      );
      if (res.error) {
        setAlertConfig({
          type: 'error',
          text: res.msg,
          child: res.raw,
        });
        return;
      }
      setAlertConfig({
        type: 'success',
        text: 'Usuário transferido com sucesso!',
        child: `Arquivos e dashboards do usuário ${selectedUser.name} foram transferidos para o usuário ${userToReceive.label}`,
      });
      hideModal();
    };

    setModalConfig({
      title: viewerOnly ? 'Verificar arquivos e dashboards' : 'Transferir arquivos e dashboards',
      nodeTarget: 'transfer-user',
      children: (
        <TransferUser
          selectedUser={selectedUser}
          usersOptions={options}
          close={hideModal}
          submit={submitTransfer}
        />
      ),
    });
  };

  /**
   * @async Allows the admin to change the roles of any user on any instance
   * @param {array} users array of users in an instance
   * @param {object} selectedUser user data to be changed
   * @param {object} instance selected users instance
   */
  const handleChangePermission = async (users, selectedUser, instance) => {
    const usersQtd = { super: 0, scientist: 0, guest: 0 };

    /**
     * @type {array} Counts and filters users based on their roles,
     * and creates a array of option
     */
    const validScientists = users.reduce((aux, u) => {
      if (u.role !== 'guest') {
        if (u.role === 'super') usersQtd.super += 1;
        else usersQtd.scientist += 1;
        if (u.uid !== selectedUser.uid) aux.push({ id: u.uid, label: u.name, value: u.uid });
      } else usersQtd.guest += 1;
      return aux;
    }, []);

    const hideModal = () => toggleModal('change-role');

    /**
     * Checks if the role change can be made
     * @param {string} role the new role
     * @returns {boolean} the verification result
     */
    const checkLimit = (role) => {
      switch (role) {
        case 'super':
          if (usersQtd.super >= instance.superS) {
            setAlertConfig({
              type: 'error',
              text: 'Limite máximo de usuários proprietários atingido nesta instância',
            });
            return false;
          }
          break;
        case 'scientist':
          if (usersQtd.scientist >= instance.scientists) {
            setAlertConfig({
              type: 'error',
              text: 'Limite máximo usuários cientistas atingido nesta instância',
            });
            return false;
          }
          if (selectedUser.role === 'super' && usersQtd.super === 1) {
            setAlertConfig({
              type: 'error',
              text: 'Instância deve ter no mínimo 1 proprietário',
            });
            return false;
          }
          break;
        case 'guest':
          if (usersQtd.guest >= instance.guests) {
            setAlertConfig({
              type: 'error',
              text: 'Limite máximo de usuários convidados atingido nesta instância',
            });
            return false;
          }
          if (selectedUser.role === 'super' && usersQtd.super === 1) {
            setAlertConfig({
              type: 'error',
              text: 'Instância deve ter no mínimo 1 proprietário',
            });
            return false;
          }
          break;
        default:
          break;
      }
      return true;
    };

    /**
     * @async Send the request with the modified user data
     * @param {string} newRole the user new role
     * @param {string} scientistKey the user new responsible scientist.
     * Only used when his role is 'guest'
     */
    const handleSubmitRole = async (newRole, scientistKey) => {
      if (!checkLimit(newRole)) return;
      const res = await instanceAPI.changeUserRoleByAdmin(
        instance.id, scientistKey, selectedUser.email, newRole,
      );
      if (res.error) {
        setAlertConfig({
          type: 'error',
          text: res.msg,
          child: res.raw,
        });
        return;
      }
      setAlertConfig({
        type: 'success',
        text: `Permissão do usuário ${selectedUser.name} alterada com sucesso!`,
      });
      instanceAPI.getInstanceUsers(instance.id);
      hideModal();
      toggleModal();
    };

    setModalConfig({
      title: 'Alterar permissão',
      nodeTarget: 'change-role',
      children: (
        <ChangeUserRole
          selectedUser={selectedUser}
          scientists={validScientists}
          close={hideModal}
          submit={handleSubmitRole}
        />
      ),
    });
  };

  const handleBlockUser = (selectedUser) => {
    const handler = async (set) => {
      const res = await instanceAPI.blockUser(
        selectedUser.instanceId, selectedUser.scientist, selectedUser.email, selectedUser.disabled ? 'False' : 'True',
      );
      if (res.error) {
        setAlertConfig({
          type: 'error',
          text: res.msg,
        });
        // set(false);
        return;
      }

      setAlertConfig({
        type: 'success',
        text: `Usuário ${selectedUser.disabled ? 'desbloqueado' : 'bloqueado'} com sucesso!`,
      });
      set(!selectedUser.disabled);
    };

    const modalHandler = (f) => {
      setAlertConfig({
        type: 'warning',
        text: `Deseja ${selectedUser.disabled ? 'desbloquear' : 'bloquear'} usuário?`,
        child: selectedUser.disabled
          ? `O usuário ${selectedUser.email} voltará a acessar o sistema!`
          : `O usuário ${selectedUser.email} não poderá acessar o sistema!`,
        withoutConfirm: true,
        withFunction: true,
        confirmFunction: f,
      });
    };

    return { modalHandler, handler };
  };

  const handleDeleteUser = (selectedUser) => {
    const handler = async (f) => {
      const companyId = selectedUser?.instanceId || selectedUser?.company?.id;
      const res = await instanceAPI.deleteUser(companyId, selectedUser?.email);
      if (res?.error) {
        setAlertConfig({
          type: 'error',
          text: res?.msg,
          child: res?.raw,
        });
        return;
      }

      setAlertConfig({
        type: 'success',
        text: `Usuário ${selectedUser?.email} apagado com sucesso!`,
      });
      f();
    };

    const modalHandler = (f) => {
      setAlertConfig({
        type: 'warning',
        text: `Deseja apagar o usuário ${selectedUser?.email}?`,
        withoutConfirm: true,
        withFunction: true,
        confirmFunction: f,
      });
    };

    return { handler, modalHandler };
  };

  const handleMultiSession = async (selectedUser, value) => {
    const res = await instanceAPI.multiSessionUser(
      selectedUser.instanceId, selectedUser.scientist, selectedUser.email, value,
    );
    if (res?.error) {
      setAlertConfig({
        type: 'error',
        text: res?.msg,
        child: res?.raw,
      });
    } else {
      setAlertConfig({
        type: 'success',
        text: `Sessão simulânea do usuário ${selectedUser?.email} ${value === 'True' ? 'liberada' : 'bloqueada'} com sucesso!`,
      });
    }
  };

  const openGuestsModal = async (instance) => {
    // fazer fetch somente se n tem os usuários ainda.
    const users = await handleGetInstanceUser(instance);
    if (!users) return;
    const guests = users.filter((u) => u.role === ROLES.GUEST);
    const handlePermission = (selected) => handleChangePermission(users, selected, instance);

    setModalConfig({
      title: 'Convidados',
      children: (
        <InstanceUserTable
          users={guests}
          userRole="convidado"
          handleBlock={handleBlockUser}
          handleDelete={handleDeleteUser}
          handlePermission={handlePermission}
          handleMultiSession={handleMultiSession}
          closeModal={closeModal}
        />
      ),
    });
  };

  const openSupersModal = async (instance) => {
    // fazer fetch somente se n tem os usuários ainda.
    const users = await handleGetInstanceUser(instance);
    if (!users) return;

    const supers = users.filter((u) => u.role === ROLES.SUPER);
    const transferModal = (selected) => { handleTransferUser(users, selected, instance, false); };
    const handlePermission = (selected) => handleChangePermission(users, selected, instance);
    const handleCopyDashboards = (selected) => handleTransferUser(users, selected, instance, true);

    setModalConfig({
      title: 'Proprietários',
      children: (
        <InstanceUserTable
          users={supers}
          userRole="super"
          handleTransfer={transferModal}
          handleBlock={handleBlockUser}
          handleDelete={handleDeleteUser}
          handlePermission={handlePermission}
          handleCopyDashboards={handleCopyDashboards}
          handleMultiSession={handleMultiSession}
          closeModal={closeModal}
        />
      ),
    });
  };

  const openScientistModal = async (instance) => {
    // fazer fetch somente se n tem os usuários ainda.
    const users = await handleGetInstanceUser(instance);
    if (!users) return;

    const scientists = users.filter((u) => u.role === ROLES.SCIENTIST);
    const transferModal = (selected) => { handleTransferUser(users, selected, instance, false); };
    const handlePermission = (selected) => handleChangePermission(users, selected, instance);
    const handleCopyDashboards = (selected) => handleTransferUser(users, selected, instance, true);

    setModalConfig({
      title: 'Cientistas',
      children: (
        <InstanceUserTable
          users={scientists}
          userRole="cientista"
          handleTransfer={transferModal}
          handleBlock={handleBlockUser}
          handleDelete={handleDeleteUser}
          handlePermission={handlePermission}
          handleCopyDashboards={handleCopyDashboards}
          handleMultiSession={handleMultiSession}
          closeModal={closeModal}
        />
      ),
    });
  };

  // INSTÂNCIA
  const handleNewInstance = async () => {
    const handleSubmit = async (instanceConfigs, emailsSuper, emailsScientist, image) => {
      const res = await instanceAPI.createInstance(
        instanceConfigs, emailsSuper, emailsScientist, image,
      );
      if (res.error) {
        setAlertConfig({
          type: 'error',
          text: res.msg,
          child: res.raw,
        });
        return;
      }

      setAlertConfig({
        type: 'success',
        text: `Instância ${instanceConfigs.name} criada com sucesso!`,
      });
      closeModal();
      instanceAPI.getInstances();
    };

    setModalConfig({
      title: 'Nova Instância',
      disableFocus: true,
      children: (
        <NewInstance
          closeModal={closeModal}
          submitNewInstance={handleSubmit}
        />
      ),
    });
  };

  const handleEditInstance = async (instance) => {
    const handleSubmit = async (instanceConfigs) => {
      const res = await instanceAPI.editInstance(instanceConfigs);
      if (res.error) {
        setAlertConfig({
          type: 'error',
          text: res.msg,
          child: res.raw,
        });
        return;
      }

      setAlertConfig({
        type: 'success',
        text: `Instância ${instanceConfigs.name} editado com sucesso!`,
      });
      closeModal();
      instanceAPI.getInstances();
    };

    setModalConfig({
      title: 'Editar Instância',
      disableFocus: true,
      children: (
        <NewInstance
          isEdit
          toEditInstance={instance}
          submitEditInstance={handleSubmit}
          closeModal={closeModal}
        />
      ),
    });
  };

  const handleBlockInstance = async (instance) => {
    const handleBlock = async () => {
      const res = await instanceAPI.blockInstance(instance.id, !instance.disabled);
      if (res.error) {
        setAlertConfig({
          type: 'error',
          text: res.msg,
          child: res.raw,
        });
        return;
      }

      setAlertConfig({
        type: 'success',
        text: `${instance.disabled ? 'Desbloqueado' : 'Bloqueado'} com sucesso!`,
      });
    };

    setAlertConfig({
      type: 'warning',
      text: `${instance.disabled ? 'Desbloquear' : 'Bloquear'} instância ${instance.name}?`,
      withoutConfirm: true,
      withFunction: true,
      confirmFunction: handleBlock,
    });
  };

  const handleDeleteInstance = async (instance) => {
    const handleDelete = async () => {
      const res = await instanceAPI.deleteInstance(instance.id);
      if (res.error) {
        setAlertConfig({
          type: 'error',
          text: res.msg,
          child: res.raw,
        });
        return;
      }

      setAlertConfig({
        type: 'success',
        text: `Instância ${instance.name} apagada com sucesso!`,
      });
    };

    setAlertConfig({
      type: 'warning',
      text: `Apagar a instância ${instance.name}?`,
      withoutConfirm: true,
      withFunction: true,
      confirmFunction: handleDelete,
    });
  };

  const handleMessageInstance = async (instance) => {
    let users = await handleGetInstanceUser(instance);
    if (!users) return;
    users = users.reduce((aux, u) => {
      switch (u.role) {
        case ROLES.SUPER:
          aux.supers.push(u);
          break;
        case ROLES.SCIENTIST:
          aux.scientists.push(u);
          break;
        default:
          aux.guests.push(u);
          break;
      }
      return aux;
    }, { supers: [], scientists: [], guests: [] });

    const handleSubmit = async (lvl, title, showUntil, link, sUsers, message) => {
      const res = instanceAPI.sendUsersMessage(
        instance.id, lvl, title, showUntil, link, sUsers, message,
      );
      if (res.error) {
        setAlertConfig({
          type: 'error',
          text: res.msg,
          child: res.raw,
        });
        return;
      }
      setAlertConfig({
        type: 'success',
        text: 'Mensagem enviada aos usuários selecionados!',
      });
      closeModal();
    };

    if (users.supers.length > 0 || users.scientists.length > 0 || users.guests.length > 0) {
      setModalConfig({
        title: 'Nova Mensagem',
        disableFocus: true,
        children: (
          <InstanceMessage
            hide={closeModal}
            users={users}
            submit={handleSubmit}
          />
        ),
      });
    } else {
      setAlertConfig({
        type: 'warning',
        text: 'Sem usuários na instância!',
      });
    }
  };

  /**
   * Handles removal of a report
   * @param {object} report report info
   */
  const handleDeleteReport = async (report, fetchUpdate) => {
    const handleDelete = async () => {
      const res = await instanceAPI.deleteReport(report.id);
      if (res.error) {
        setAlertConfig({
          type: 'error',
          text: res.msg,
          child: res.raw,
        });
        return;
      }
      setAlertConfig({
        type: 'success',
        text: `Relatório ${report.filename} apagado com sucesso!`,
      });
      fetchUpdate();
    };

    setAlertConfig({
      type: 'warning',
      text: `Tem certeza que deseja apagar o relatório ${report.filename}?`,
      withoutConfirm: true,
      withFunction: true,
      confirmFunction: handleDelete,
    });
  };
  /**
   * Handles removal of a report
   * @param {object} report report info
   */
  const handleResetReport = async (report, fetchUpdate) => {
    const handleReset = async () => {
      const res = await instanceAPI.resetReport(report.id);
      if (res.error) {
        setAlertConfig({
          type: 'error',
          text: res.msg,
          child: res.raw,
        });
        return;
      }
      setAlertConfig({
        type: 'success',
        text: `Relatório ${report.filename} resetado com sucesso, atualize a base!`,
      });
      fetchUpdate();
    };

    setAlertConfig({
      type: 'warning',
      text: `Tem certeza que deseja resetar o relatório ${report.filename}? A base será substituída por uma base vazia.`,
      withoutConfirm: true,
      withFunction: true,
      confirmFunction: handleReset,
    });
  };

  const handleChangeReportOwner = async (report, fetchUpdate) => {
    let users = [];
    if (report.companyId) {
      const res = await instanceAPI.getInstanceUsers(report.companyId);
      if (res.error) {
        setAlertConfig({
          type: 'error',
          text: res.msg,
        });
      } else {
        users = res.users;
      }
    }

    const getOwner = (id) => {
      const currentOwner = users.find((u) => u.uid === id);
      return currentOwner?.email || 'Não encontrado';
    };

    const usersOptions = [];
    users.forEach((u) => {
      if (u.role !== 'guest' && u.uid !== report.ownerId) {
        usersOptions.push({
          id: u.uid,
          label: u.email,
          value: u.email,
        });
      }
    });

    const handleSubmit = async (newOwner, removeOldOwner = true) => {
      const res = await instanceAPI.changeOwnerReport(
        report?.companyId || '',
        report.id.split('_')[1] || '',
        report.ownerId,
        newOwner.id,
        removeOldOwner,
      );
      if (res.error) {
        setAlertConfig({
          type: 'error',
          text: res.msg,
          child: res.raw,
        });
        return;
      }

      setAlertConfig({
        type: 'success',
        text: 'Propriedade do relatório transferida com sucesso!',
        child: `A propriedade do relatório foi transferida para o usuário ${newOwner.label}!`,
      });
      fetchUpdate();
      closeModal();
    };

    setModalConfig({
      title: 'Alterar Proprietário',
      disableFocus: true,
      children: (
        <ChangeReportOwner
          currentOwner={report?.ownerEmail || report?.ownerId ? getOwner(report.ownerId) : 'Não encontrado'}
          usersOptions={usersOptions}
          submit={handleSubmit}
          close={closeModal}
        />
      ),
    });
  };

  /**
   * Opens an alert showing the report's update information
   * @param {object} report report info
   */
  const openReportStatus = async (report, fetchUpdate) => {
    /**
     * Updates a file manually
     * @param {object?} file File object (undefined for 'LegalOne' reports)
     */
    const handleUpdate = async (file) => {
      const res = await instanceAPI.manualReportUpdate(
        report.id, report.origin, file, report.filename,
      );
      if (res.error) {
        setAlertConfig({
          type: res.type === 'warning' ? 'warning' : 'error',
          text: res.type === 'warning' ? `Relatório ${report.filename} atualizado!` : res.msg,
          child: res.raw,
        });
        return;
      }
      setAlertConfig({
        type: res.type,
        text: res.type === 'success'
          ? `Relatório ${report.filename} atualizado com sucesso!`
          : `Relatório ${report.filename} atualizado parcialmente!`,
        child: (res.type === 'warning' && res.data.errors && res.data.errors[0].substring(0, 3) === 'KPI')
          ? `${res.data.errors.length} KPI's não puderam ser atualizados. Houve algum erro na tentativa de construir esses KPI's!`
          : '',
      });
      fetchUpdate();
    };

    /**
     * Handles a file upload for the update
     * @param {object[]?} files File object (undefined for 'LegalOne' reports)
     */
    const updateFileUp = (files = []) => {
      if (report.origin === 'FileApi') {
        const errorMsg = verifyFileSheetType(files[0]);
        if (errorMsg.length > 0) {
          setAlertConfig({
            type: 'error',
            text: 'Erro na atualização!',
            child: errorMsg,
          });
          return;
        }
      }
      setAlertConfig({
        type: 'warning',
        text: `Atualizar o relatório ${report.filename} manualmente?`,
        withoutConfirm: true,
        withFunction: true,
        confirmFunction: () => handleUpdate(files[0]),
      });
    };

    /**
     * Handles inactivation of a report
     */
    const handleInactivate = async () => {
      const handleInactivateSubmit = async () => {
        const res = await instanceAPI.inactivateReport(report.id);
        if (res.error) {
          setAlertConfig({
            type: 'error',
            text: res.msg,
            child: res.raw,
          });
          return;
        }
        setAlertConfig({
          type: 'success',
          text: `Relatório ${report.filename} inativado com sucesso!`,
        });
        fetchUpdate();
      };
      setAlertConfig({
        type: 'warning',
        text: `Tem certeza que deseja inativar o relatório ${report.filename}?`,
        withoutConfirm: true,
        withFunction: true,
        confirmFunction: handleInactivateSubmit,
      });
    };

    const res = await instanceAPI.getReportStatus(report.id);
    if (res.error) {
      setAlertConfig({
        type: 'error',
        text: res.msg,
        child: res.raw,
      });
      return;
    }

    const infoReport = {
      ...res.data,
    };

    let status = '';
    let msg = '';
    if (infoReport.lastAttemptStatus === 'ERROR') {
      status = 'error';
      msg = 'Erro ao atualizar o relatório!';
    } else if (infoReport.lastAttemptStatus === 'WARNING') {
      status = 'warning';
      msg = 'Relatório atualizado!';
    } else {
      status = 'success';
      msg = 'Relatório atualizado com sucesso!';
    }
    setAlertConfig({
      type: status,
      text: msg,
      child: <FileReport
        file={infoReport}
        manualUpdate={updateFileUp}
        inactivateReport={handleInactivate}
      />,
    });
  };

  /**
   * Opens an alert showing the inactive report information
   * @param {object} report report info
   * @param {boolean} isDeleted marks if the report has `deletedAt` field
   * @param {function} fetchUpdate updates the list after request
   */
  const openInactiveReportStatus = async (report, isDeleted, fetchUpdate) => {
    /**
     * Handles activation of a report
     */
    const handleActivate = async () => {
      const handleActivateSubmit = async () => {
        const res = await instanceAPI.activateReport(report.id);
        if (res.error) {
          setAlertConfig({
            type: 'error',
            text: res.msg,
            child: res.raw,
          });
          return;
        }
        setAlertConfig({
          type: 'success',
          text: `Relatório ${report.filename} reativado com sucesso!`,
        });
        fetchUpdate();
      };
      setAlertConfig({
        type: 'warning',
        text: `Tem certeza que deseja reativar o relatório ${report.filename}?`,
        withoutConfirm: true,
        withFunction: true,
        confirmFunction: handleActivateSubmit,
      });
    };

    /**
     * Handles restauration of a report
     */
    const handleRestore = async () => {
      const handleRestoreSubmit = async () => {
        const res = await instanceAPI.restoreReport(report.id);
        if (res.error) {
          setAlertConfig({
            type: 'error',
            text: res.msg,
            child: res.raw,
          });
          return;
        }
        setAlertConfig({
          type: 'success',
          text: `Relatório ${report.filename} restaurado com sucesso!`,
        });
        fetchUpdate();
      };
      setAlertConfig({
        type: 'warning',
        text: `Tem certeza que deseja restaurar o relatório ${report.filename}?`,
        withoutConfirm: true,
        withFunction: true,
        confirmFunction: handleRestoreSubmit,
      });
    };

    setAlertConfig({
      type: 'warning',
      text: isDeleted ? 'Relatório removido!' : 'Relatório inativo!',
      child: <FileReportInactive
        reportInfo={report}
        activateReport={handleActivate}
        restoreReport={handleRestore}
      />,
    });
  };

  useEffect(() => {
    (async () => {
      if (isMounted.current) return;
      instanceAPI.getInstances();
    })();
  }, [instanceAPI]);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  // ### Função para um usuário isAdmin = true adicionar um usuário em uma instância de cliente
  const handleAddUser = async (instance) => {
    const users = await handleGetInstanceUser(instance);

    const res = {
      supers: [],
      scientists: [],
      guests: [],
      supersQtd: 0,
      scientistsQtd: 0,
      guestsQtd: 0,
    };

    const getInfo = (item) => {
      switch (item.role) {
        case 'super':
          res.supers.push({ id: item.uid, label: item.email, value: item.email });
          res.supersQtd += 1;
          break;
        case 'scientist':
          res.scientists.push({ id: item.uid, label: item.email, value: item.email });
          res.scientistsQtd += 1;
          break;
        case 'guest':
          res.guests.push({ id: item.uid, label: item.email, value: item.email });
          res.guestsQtd += 1;
          break;
        default:
          break;
      }
    };

    users.forEach((u) => getInfo(u));

    const checkLimit = (role) => {
      const { supersQtd, scientistsQtd, guestsQtd } = res;
      switch (role) {
        case 'super':
          if (supersQtd >= instance.superS) {
            setAlertConfig({
              type: 'error',
              text: 'Limite máximo de usuários proprietários atingido nesta instância',
            });
            return false;
          }
          break;
        case 'scientist':
          if (scientistsQtd >= instance.scientists) {
            setAlertConfig({
              type: 'error',
              text: 'Limite máximo usuários cientistas atingido nesta instância',
            });
            return false;
          }
          break;
        case 'guest':
          if (guestsQtd >= instance.guests) {
            setAlertConfig({
              type: 'error',
              text: 'Limite máximo de usuários convidados atingido nesta instância',
            });
            return false;
          }
          break;
        default:
          break;
      }
      return true;
    };

    const submitAddUser = async (userInfo) => {
      if (!checkLimit(userInfo.role)) return;
      const { error, msg, raw } = await instanceAPI.addUser(
        instance.id, userInfo.scientist, userInfo.email, userInfo.role,
      );

      if (error) {
        setAlertConfig({
          type: 'error',
          text: msg,
          child: raw,
        });
        instanceAPI.getInstanceUsers(instance.id);
        toggleModal();
        return;
      }

      setAlertConfig({
        type: 'success',
        text: 'Usuário adicionado com sucesso!',
        okFunction: () => {
          instanceAPI.getInstanceUsers(instance.id);
          toggleModal();
        },
      });
    };

    setModalConfig({
      title: 'Adicionar Novo Usuário',
      className: 'newUser_modal',
      children: (
        <AddUser
          user={user}
          userIsAdmin={claimsUser?.is_admin}
          usersEmails={[
            ...res.supers.map((u) => u.value),
            ...res.scientists.map((u) => u.value),
            ...res.guests.map((u) => u.value),
          ]}
          scientistEmails={[...res.supers, ...res.scientists]}
          hide={() => {
            toggleModal();
          }}
          fromAdmin
          submitData={submitAddUser}
        />
      ),
    });
  };

  const handleAddAntecipei = async (instance) => {
    const resGetUsers = await instanceAPI.getInstanceUsers(instance.id);
    if (resGetUsers.error) {
      setAlertConfig({
        type: 'error',
        text: resGetUsers.msg,
        child: resGetUsers.raw,
      });
      toggleModal();
      return;
    }
    const users = resGetUsers.users.filter((u) => u.role !== 'guest');

    const submitAntecipei = async (
      filename,
      ownerId,
      url,
      sendWithoutDataProc,
      prettyColumns,
      reportToken,
    ) => {
      const { error, msg, raw } = await instanceAPI.addAntecipeiReport(
        filename,
        ownerId,
        instance.id,
        url,
        sendWithoutDataProc,
        prettyColumns,
        reportToken,
      );

      if (error) {
        setAlertConfig({
          type: 'error',
          text: msg,
          child: raw,
        });
        toggleModal();
        return;
      }

      setAlertConfig({
        type: 'success',
        text: 'Relatório Antecipei criado com sucesso!',
        okFunction: () => {
          toggleModal();
        },
      });
    };

    setModalConfig({
      title: 'Criar Relatório Antecipei',
      children: (
        <AddAntecipei
          users={users.map((u) => ({
            id: u.uid,
            label: u.email,
            value: u.email,
          }))}
          hide={() => {
            toggleModal();
          }}
          submitData={submitAntecipei}
        />
      ),
    });
  };

  const handleAddBenner = async (instance) => {
    const resGetUsers = await instanceAPI.getInstanceUsers(instance.id);
    if (resGetUsers.error) {
      setAlertConfig({
        type: 'error',
        text: resGetUsers.msg,
        child: resGetUsers.raw,
      });
      toggleModal();
      return;
    }
    const users = resGetUsers.users.filter((u) => u.role !== 'guest');

    const submitBenner = async (
      filename,
      ownerId,
      url,
      username,
      password,
    ) => {
      const { error, msg, raw } = await instanceAPI.addBennerReport(
        filename,
        ownerId,
        instance.id,
        url,
        username,
        password,
      );

      if (error) {
        setAlertConfig({
          type: 'error',
          text: msg,
          child: raw,
        });
        toggleModal();
        return;
      }

      setAlertConfig({
        type: 'success',
        text: 'Relatório Benner criado com sucesso!',
        okFunction: () => {
          toggleModal();
        },
      });
    };

    setModalConfig({
      title: 'Criar Relatório Benner',
      children: (
        <AddBenner
          users={users.map((u) => ({
            id: u.uid,
            label: u.email,
            value: u.email,
          }))}
          hide={() => {
            toggleModal();
          }}
          submitData={submitBenner}
        />
      ),
    });
  };

  const handleOnboarding = async (instance) => {
    const resGetUsers = await instanceAPI.getInstanceUsers(instance.id);
    if (resGetUsers.error) {
      setAlertConfig({
        type: 'error',
        text: resGetUsers.msg,
        child: resGetUsers.raw,
      });
      toggleModal();
      return;
    }
    const users = resGetUsers.users.filter((u) => u.role !== 'guest');

    // Getting users from instance
    const resData = await instanceAPI.getDataExtractorInfo(instance.id);
    if (resData.error) {
      setAlertConfig({
        type: 'error',
        text: resData.msg,
        child: resData.raw,
      });
      toggleModal();
      return;
    }
    const { possibleReports, schemaTypes } = resData.res;

    // Getting modules from database
    const data = await instanceAPI.getDataExtractorModules();
    if (data.error) {
      setAlertConfig({
        type: 'error',
        text: data.msg,
        child: data.raw,
      });
      toggleModal();
      return;
    }
    const l1Modules = possibleReports.name === 'QueryCorporateBrazil'
      ? data.res.corporateModules : data.res.firmsModules;
    const l1ModulesIds = l1Modules.map((mod) => mod.id);

    const modules = possibleReports.fields.filter((f) => {
      if (l1ModulesIds.includes(f.name)) {
        return f;
      }
      return null;
    });

    const fullModules = modules.map((module) => (
      {
        ...module,
        id: module.name,
        label: l1Modules.find((mod) => mod.id === module.name).label,
        value: module.name,
      }
    ));

    const plan = possibleReports.name === 'QueryCorporateBrazil' ? 'Corporate' : 'Firms';

    const getModuleFields = async (module, clientPlan) => {
      const fields = await instanceAPI.getL1ReportModuleFields(module, clientPlan);
      if (fields.error) {
        setAlertConfig({
          type: 'error',
          text: fields.msg,
          child: fields.raw,
        });
        toggleModal();
      }
      return fields?.res;
    };

    const getTemplates = async (module, clientPlan) => {
      const templates = await instanceAPI.getExtractorPossibleTemplates(module, clientPlan);
      if (templates.error) {
        setAlertConfig({
          type: 'error',
          text: templates.msg,
          child: templates.raw,
        });
        toggleModal();
      }
      return templates?.res;
    };

    const submitOnbardingReport = async (
      filename,
      ownerId,
      fields,
      selectedFields,
      module,
      alias,
    ) => {
      const { error, msg, raw } = await instanceAPI.createOnboardingReport(
        filename, instance.id, ownerId, fields, selectedFields, module, alias,
      );

      if (error) {
        setAlertConfig({
          type: 'error',
          text: msg,
          child: raw,
        });
        toggleModal();
        return;
      }

      setAlertConfig({
        type: 'success',
        text: 'Relatório de onboarding adicionado com sucesso!',
        okFunction: () => {
          filesAPI.getFilesList();
          toggleModal();
        },
      });
    };

    setModalConfig({
      title: 'Criar Relatório de Onboarding',
      children: (
        <OnboardingReport
          users={users.map((u) => ({
            id: u.uid,
            label: u.email,
            value: u.email,
          }))}
          modules={fullModules}
          schema={schemaTypes.map((s) => ({
            id: s.name,
            label: s.name,
            value: s.name,
            ...s,
          }))}
          hide={() => toggleModal()}
          submitData={submitOnbardingReport}
          getL1ReportModuleFields={getModuleFields}
          plan={plan}
          getTemplates={getTemplates}
        />
      ),
    });
  };

  const handleRequestApiCredentials = async (instance) => {
    const { error, msg, raw } = await instanceAPI.requestApiCredentials(
      instance.id,
    );

    if (error) {
      setAlertConfig({
        type: 'error',
        text: msg,
        child: raw,
      });
      toggleModal();
      return;
    }

    setAlertConfig({
      type: 'success',
      text: 'Requisição enviada com sucesso!',
      child: 'O e-mail com as requisições foi enviado. Aguarde o cadastro das credenciais para que você possa utilizar a API!',
      okFunction: () => {
        toggleModal();
      },
    });
  };

  const openReportColumns = async (report, fetchUpdate) => {
    const submit = async (columns) => {
      if (columns.length > 0) {
        const res = await instanceAPI.editReportColumns(report.id, columns);
        if (res.error) {
          setAlertConfig({
            type: 'error',
            text: res.msg,
            child: res.raw,
          });
          return;
        }
        setAlertConfig({
          type: 'success',
          text: `Colunas do relatório ${report.filename} alteradas com sucesso!`,
        });
        fetchUpdate();
        closeModal();
      }
    };

    const handleDecision = (columns) => {
      setAlertConfig({
        type: 'warning',
        text: 'Tem certeza que deseja salvar as alterações?',
        withoutConfirm: true,
        withFunction: true,
        confirmFunction: () => submit(columns),
      });
    };

    const res = await instanceAPI.getReportColumns(report.id);
    if (res.error) {
      setAlertConfig({
        type: 'error',
        text: res.msg,
        child: res.raw,
      });
      return;
    }

    setModalConfig({
      title: 'Colunas Originais',
      children: (
        <ReportColumns
          columns={res.columns}
          custom={res.custom}
          hide={toggleModal}
          submitColumns={handleDecision}
        />
      ),
    });
  };

  const openManageCustomColumns = async (reportId, {
    columns, custom, editCColumn, submit,
  }) => {
    const genPreview = async (newColumns) => {
      const res = await instanceAPI.previewCustomColumns(reportId, newColumns);
      if (res.error) {
        setAlertConfig({
          type: 'error',
          text: res.msg,
          child: res.raw,
        });
        return null;
      }
      return res.res;
    };
    const hide = () => toggleModal('manage-custom-columns');
    setModalConfig({
      title: 'Colunas Customizadas',
      nodeTarget: 'manage-custom-columns',
      children: (
        <ManageCustomColumns
          columns={columns}
          custom={custom}
          editCustom={editCColumn}
          genPreview={genPreview}
          submit={submit}
          hide={hide}
        />
      ),
    });
  };

  const openCustomColumns = async (report, fetchUpdate) => {
    const hide = () => toggleModal('list-custom-columns');

    const submit = async (newColumns) => {
      if (newColumns.length > 0) {
        const res = await instanceAPI.manageCustomColumns(report.id, newColumns);
        if (res.error) {
          setAlertConfig({
            type: 'error',
            text: res.msg,
            child: res.raw,
          });
          return;
        }
        setAlertConfig({
          type: 'success',
          text: `Relatório ${report.filename} alterado com sucesso!`,
        });
        fetchUpdate();
        hide();
      }
    };

    const handleDecision = (newColumns) => {
      setAlertConfig({
        type: 'warning',
        text: 'Tem certeza que deseja salvar as alterações?',
        withoutConfirm: true,
        withFunction: true,
        confirmFunction: () => submit(newColumns),
      });
    };

    const res = await instanceAPI.getReportColumns(report.id);
    if (res.error) {
      setAlertConfig({
        type: 'error',
        text: res.msg,
        child: res.raw,
      });
      return;
    }

    const handleManageCustomColumns = (info) => openManageCustomColumns(report.id, info);

    setModalConfig({
      title: 'Colunas Customizadas',
      nodeTarget: 'list-custom-columns',
      children: (
        <CustomColumns
          columns={res.columns}
          custom={res.custom ?? []}
          manageCustomColumns={handleManageCustomColumns}
          hide={hide}
          submit={handleDecision}
        />
      ),
    });
  };

  const handleGetUsers = async () => {
    const res = await instanceAPI.getUsers();
    if (res.error) {
      setAlertConfig({
        type: 'error',
        text: res.msg,
        child: res.raw,
      });
      return null;
    }
    return res.users;
  };

  const handleGetUserHistory = async (userObj) => {
    const res = await instanceAPI.getUserLoginHistory(userObj.user_id);
    if (res.error) {
      setAlertConfig({
        type: 'error',
        text: res.msg,
        child: res.raw,
      });
      return null;
    }

    setModalConfig({
      title: 'Histórico de Acessos',
      yesLabel: 'Ok',
      children: (
        <UserHistory
          user={userObj}
          history={res.res}
        />
      ),
    });
    return null;
  };

  const handleGetUserInfo = async (userObj) => {
    const res = await instanceAPI.getUserInfo(userObj.email);
    if (res.error) {
      setAlertConfig({
        type: 'error',
        text: res.msg,
        child: res.raw,
      });
      return null;
    }
    setModalConfig({
      title: 'Informações do usuário',
      yesLabel: 'Ok',
      children: (
        <UserInfo
          user={res.res}
          device={device}
        />
      ),
    });
    return null;
  };

  const handleGetUsersDashboards = async (userObj, users) => {
    const res = await instanceAPI.getUserDashboards(userObj.user_id);
    if (res.error) {
      setAlertConfig({
        type: 'error',
        text: res.msg,
        child: res.raw,
      });
      return null;
    }

    const getDashSharedUsers = (dash) => {
      const getShared = () => {
        const usersId = Object.keys(dash.sharedWith);
        const sharedUsers = usersId.map((userId) => {
          const aux = users.find((u) => u.user_id === userId);
          return ({
            ...dash.sharedWith[userId],
            ...aux,
          });
        });
        return sharedUsers;
      };
      const shared = dash?.sharedWith ? getShared() : [];
      const hideShared = () => toggleModal('dash-shared-with');
      setModalConfig({
        title: 'Usuários Compartilhados',
        nodeTarget: 'dash-shared-with',
        yesLabel: 'Ok',
        yesFunc: hideShared,
        children: (
          <DashSharedWith
            dash={dash}
            users={shared}
          />
        ),
      });
    };

    const getDashDependencies = async (dash) => {
      const files = await instanceAPI.getUserDashFiles(
        userObj?.company?.id, userObj?.user_id, dash?.dashId,
      );
      if (files.error) {
        setAlertConfig({
          type: 'error',
          text: files.msg,
          child: files.raw,
        });
        return null;
      }
      const dashDependencies = files.res.map((dashFile) => (
        { ...dashFile, created_at: dashFile.createdAt, updated_at: dashFile.updatedAt }));
      const hideDependencies = () => toggleModal('dash-dependencies');
      setModalConfig({
        title: 'Arquivos Utilizados',
        nodeTarget: 'dash-dependencies',
        yesLabel: 'Ok',
        yesFunc: hideDependencies,
        children: (
          <DashboardDependencies
            dashboard={dash}
            files={dashDependencies}
            users={users}
            deviceType={device}
            adminAccess
          />
        ),
      });
      return null;
    };

    const getDashKpis = async (dash) => {
      const kpisList = await instanceAPI.getDashKpis(userObj?.user_id, dash?.dashId);
      if (kpisList.error) {
        setAlertConfig({
          type: 'error',
          text: kpisList.msg,
          child: kpisList.raw,
        });
        return null;
      }
      const hideDashKpis = () => toggleModal('dash-kpis-list');
      setModalConfig({
        title: 'Lista de KPIs',
        nodeTarget: 'dash-kpis-list',
        yesLabel: 'Ok',
        yesFunc: hideDashKpis,
        children: (
          <DashboardKpisList
            dashboard={dash}
            kpis={kpisList.res}
          />
        ),
      });
      return null;
    };

    const handleDeleteDashboard = (dash) => {
      const handler = async (f) => {
        const deleteResult = await instanceAPI.deleteDashboard(userObj?.user_id, dash?.dashId);
        if (deleteResult.error) {
          setAlertConfig({
            type: 'error',
            text: deleteResult.msg,
            child: `${deleteResult.raw}`,
          });
          return;
        }
        setAlertConfig({
          type: 'success',
          text: `Dashboard ${dash?.name} removido com sucesso!`,
        });
        f();
      };

      const modalHandler = (f) => {
        setAlertConfig({
          type: 'warning',
          text: `Deseja apagar o Dashboard "${dash?.name}"?`,
          withoutConfirm: true,
          withFunction: true,
          confirmFunction: f,
        });
      };

      return { handler, modalHandler };
    };

    setModalConfig({
      title: 'Dashboards do usuário',
      yesLabel: 'Ok',
      children: (
        <UserDashboards
          user={userObj}
          dashboards={res.res}
          getDashSharedUsers={getDashSharedUsers}
          getDashDependencies={getDashDependencies}
          getKpiList={getDashKpis}
          handleDelete={handleDeleteDashboard}
        />
      ),
    });
    return null;
  };

  const handleMessageUsers = async () => {
    let allUsers = instanceState?.users ?? [];
    if (!allUsers?.length > 0) {
      const res = await instanceAPI.getUsers();
      if (res.error) {
        setAlertConfig({
          type: 'error',
          text: res.msg,
          child: res.raw,
        });
        return;
      }
      allUsers = res.users;
    }
    if (!instanceState.instances) return;
    const filteredInstances = instanceState.instances.reduce((aux, instance) => {
      const inst = {
        ...instance,
        label: instance.name,
        value: instance.id,
        key: instance.id,
      };
      switch (inst.plan) {
        case 'advanced':
          aux.advanced.push(inst);
          break;
        case 'standard':
          aux.standard.push(inst);
          break;
        case 'premium':
          aux.premium.push(inst);
          break;
        default:
          aux.others.push(inst);
          break;
      }
      return aux;
    }, {
      advanced: [], standard: [], premium: [], others: [],
    });

    const handleSubmit = async (lvl, title, showUntil, link, selectedInstances, message) => {
      try {
        const batch = getBatch();
        setLoading(true);
        const generalRes = await instanceAPI.writeGeneralMessage(message, batch);
        const { notificationId } = generalRes;
        const promises = selectedInstances.map(
          (instanceId) => (
            instanceAPI.sendUsersMessage(
              instanceId, lvl, title, showUntil, link, allUsers.filter(
                (u) => u?.company?.id === instanceId,
              ).map(((u) => u.user_id)), message, batch, notificationId,
            )),
        );
        const awaitedPromises = await Promise.all(promises);
        setLoading(false);
        let errorFlag = false;
        awaitedPromises.forEach((res) => {
          if (res?.error) {
            errorFlag = true;
            setAlertConfig({
              type: 'error',
              text: res?.msg,
              child: res?.raw,
            });
            closeModal();
          }
        });
        if (!errorFlag) {
          await batch.commit();
          setAlertConfig({
            type: 'success',
            text: 'Mensagem enviada aos clientes selecionados!',
          });
          closeModal();
        }
      } catch (error) {
        setAlertConfig({
          type: 'error',
          text: error.message,
        });
        closeModal();
      }
    };
    if (
      filteredInstances?.standard?.length > 0
      || filteredInstances?.advanced?.length > 0
      || filteredInstances?.premium?.length > 0
    ) {
      setModalConfig({
        title: 'Nova Mensagem',
        disableFocus: true,
        children: (
          <AllUsersMessage
            hide={closeModal}
            instances={filteredInstances}
            submit={handleSubmit}
          />
        ),
      });
    } else {
      setAlertConfig({
        type: 'warning',
        text: 'Sem usuários na instância!',
      });
    }
  };

  const handleApiGedRegister = async (instance) => {
    const submit = async (key, secret) => {
      const res = await instanceAPI.saveGedCredentials(instance.id, key, secret);
      if (res.error) {
        setAlertConfig({
          type: 'error',
          text: res.msg,
          child: res.raw,
        });
        return;
      }
      setAlertConfig({
        type: 'success',
        text: 'Credencial GED cadastrada com sucesso!',
      });
      closeModal();
    };

    setModalConfig({
      title: 'Cadastro da API GED',
      children: (
        <RegisterApiGedCredentials
          submit={submit}
          hide={closeModal}
        />
      ),
    });
  };

  const convertToGed = async (report, fetchUpdate) => {
    const handleConvertToGed = async () => {
      const res = await instanceAPI.convertToGed(report.id);
      if (res.error) {
        setAlertConfig({
          type: 'error',
          text: res.msg,
          child: res.raw,
        });
        return;
      }
      setAlertConfig({
        type: 'success',
        text: `Relatório ${report.filename} convertido com sucesso!`,
      });
      fetchUpdate();
    };

    if (report.connector !== 'mail') {
      setAlertConfig({
        type: 'error',
        text: 'Você só pode converter relatórios configurados como e-mail para a API GED.',
      });
      return;
    }
    setAlertConfig({
      type: 'warning',
      text: `Tem certeza que deseja converter o relatório ${report.filename} utilizando a API GED?`,
      withoutConfirm: true,
      withFunction: true,
      confirmFunction: handleConvertToGed,
    });
  };

  return (
    <>
      {(instanceState.isLoading || loading) && <Loader />}
      <AdminToolbar
        tab={tab}
        setTab={setTab}
        handleNewInstance={handleNewInstance}
        setFilterText={handleText}
        handleMessage={handleMessageUsers}
      />
      {tab === 'customers' && (
        <InstanceTable
          instances={instanceState.instances}
          openGuestsModal={openGuestsModal}
          openSupersModal={openSupersModal}
          openScientistsModal={openScientistModal}
          //
          handleEdit={handleEditInstance}
          handleBlock={handleBlockInstance}
          handleDelete={handleDeleteInstance}
          handleAddUser={handleAddUser}
          handleAddAntecipei={handleAddAntecipei}
          handleAddBenner={handleAddBenner}
          handleOnboarding={handleOnboarding}
          handleRequestApiCredentials={handleRequestApiCredentials}
          handleMessage={handleMessageInstance}
          handleApiGedRegister={handleApiGedRegister}
          //
          filterText={filterText}
        />
      )}
      {tab === 'reports' && (
        <ReportsTable
          reports={instanceState.reports}
          instances={instanceState.instances}
          //
          getReports={instanceAPI.getReports}
          deleteReport={handleDeleteReport}
          resetReport={handleResetReport}
          changeReportOwner={handleChangeReportOwner}
          openReportStatus={openReportStatus}
          openInactiveReportStatus={openInactiveReportStatus}
          openReportColumns={openReportColumns}
          openCustomColumns={openCustomColumns}
          convertToGed={convertToGed}
        />
      )}
      {tab === 'users' && (
        <UsersTable
          users={instanceState.users}
          getUsers={handleGetUsers}
          getUserInfo={handleGetUserInfo}
          getUserHistory={handleGetUserHistory}
          getUserDashboards={handleGetUsersDashboards}
          filterText={filterText}
          handleDelete={handleDeleteUser}
        />
      )}
    </>
  );
}

/**
 * Fazendo esse HOC, ao invés de usar o InstanceProvider no App,
 * é possível deixar as funções do instanceProvider somente para a página de admin.
 */

const AdminHOC = ({ ...props }) => (
  <InstanceProvider>
    <AdminPage {...props} />
  </InstanceProvider>
);

export default AdminHOC;
