import React from 'react';
import {
  MoreVert, Add, TemplateUse,
} from '../../juristec-ui/icons';
import IconButton from '../../juristec-ui/core/IconButton';

const tourList = {
  abrindoMenu: [],

  createDashboard: {
    type: 'createDashboard',
    endingMsg: 'criação de dashboard',
    steps: [
      {
        target: '#createDashboard_first',
        text: ['Clique no botão',
          <IconButton
            color="secondary"
            variant="contained"
            shape="rounded"
            style={{ margin: '0px 5px', cursor: 'default' }}
            className="more_options_fileViewer"
          >
            <Add />
          </IconButton>,
          'para adicionar um novo dashboard'],
        targetAction: '#createDashboard_first',
        cantGoBack: true,
      },
      {
        target: 'select_header',
        text: 'Clique no seletor para escolher um grupo (categoria) para o seu dashboard.',
        targetAction: 'select_header',
        backAction: ['newDash_modal_close_btn', 1],

      },
      {
        target: 'select_list',
        text: 'Digite o nome do grupo (categoria) desejado ou escolha entre os grupos disponíveis. Este passo é opcional e o grupo pode ser alterado posteriormente.',
      },
      {
        target: 'dash_color_icon',
        text: 'Você pode associar uma cor ao grupo de dashboard. Para isto, basta clicar aqui para selecionar uma cor',
        // cantGoBack: true,
        targetAction: 'under',
        backAction: [2],
      },
      {
        target: 'color-picker-popover',
        text: 'Você pode escolher a cor que desejar ou digitar uma cor no formato hexadecimal abaixo. Este passo é opcional e a cor do grupo pode ser alterada posteriormente.',
        backAction: ['color-picker-modal_close_btn', 1],
      },
      {
        target: 'pickImage',
        text: 'Você também pode selecionar o fundo do seu cartão de dashboard. Para isto, você pode selecionar uma imagem do seu próprio dispositivo, buscar uma imagem na web ou selecionar uma cor sólida. Basta clicar na opção desejada. Este passo também é opcional. Você pode alterar a imagem posteriormente.',
        // targetAction: 'image_trigger',
        // cantGoBack: true,
        backAction: [2],
      },
      // {
      //   target: 'image_trigger',
      //   text: 'Você também pode selecionar o fundo do seu cartão de dashboard. Para isto, você pode selecionar uma imagem do seu próprio dispositivo, buscar uma imagem na web ou selecionar uma cor sólida. Basta clicar na opção desejada. Este passo também é opcional. Você pode alterar a imagem posteriormente.',
      //   // targetAction: 'image_trigger',
      //   // cantGoBack: true,
      //   backAction: [2],
      // },

      {
        target: 'newDash_modal',
        text: 'Agora, você precisa digitar um nome para o seu dashboard. Em seguida, basta clicar em "Criar" e ele será criado com as configurações escolhidas',
        backAction: [1],
        targetAction: 'dashboard_saveBtn',
        block: true,
      },

    ],
  },
  createKpi: {
    type: 'createKpi',
    endingMsg: 'criação de KPI',
    steps: [
      {
        target: 'createKpi_first',
        delay: 300,
        text: ['Para criar um novo KPI, clique no botão',
          <IconButton
            color="secondary"
            variant="contained"
            shape="rounded"
            style={{ margin: '0px 5px', cursor: 'default' }}
            className="more_options_fileViewer"
          >
            <Add />
          </IconButton>],
        targetAction: 'createKpi_first',
        cantGoBack: true,
      },
      {
        target: 'newKpi_btn',
        text: 'Em seguida, selecione a opção  "Adicionar KPI"',
        targetAction: 'newKpi_btn',
      },
      {
        target: 'select_wrapper',
        delay: 300,
        text: 'Clique no seletor de arquivos para escolher um arquivo a partir do qual será construído o seu KPI',
        targetAction: 'select_header',
        cantGoBack: true,
      },
      {
        target: 'select_list',
        text: 'Escolha o arquivo desejado. Todos os arquivos mostrados aqui, são aqueles disponíveis em sua interface de arquivos',
        targetAction: 'select-options-li',
        block: true,
      },
      {
        target: 'gerar_grafico',
        delay: 500,
        text: "Aqui temos o botao de 'Resetar' e 'Gerar Gráfico'. No momento, eles estão desabilitados, mas ficarão disponíveis para limpar os dados ou gerar o gráfico, respectivamente, assim que as informações dos dados estejam adequadas. Avance para o próximo passo",
        backAction: [2],
      },
      {
        target: 'tabs',
        delay: 0,
        text: 'Nestas abas temos todas as configurações do gráfico. Aqui podemos incluir ou alterar os dados, criar agrupamentos de variáveis selecionadas, filtrar informações e alterar os estilos do gráfico criado. Avance para o próximo passo',
      },

      {
        target: 'sideBar',
        text: 'Inicialmente, na aba dados, você deve selecionar as variáveis relacionadas a valor, linha e coluna relativas as variáveis que você deseja cruzar. Preencha os campos com as variáveis desejadas de seu arquivo e, em seguida, clique em "Gerar Gráfico" para dar continuidade',
        block: true,
        fixedTop: true,
      },
      {
        target: '#kpiType',
        text: 'Agora que as informações selecionadas, apresentamos uma tabela com as informações escolhidas. Você pode selecionar o gráfico desejado na lista de gráficos. Isso deve alterar a tabela para o gráfico desejado. Assim que tiver escolhido o gráfico, avance para o próximo passo',
        cantGoBack: true,
        fixedBottom: true,
      },
      {
        target: 'kpi_preview',
        text: 'Está é uma prévia do gráfico criado. É desta forma que o KPI ficará disponível no seu dashboard após a conclusão. Avance para o próximo passo',
        fixedTop: true,
      },
      /* {
        target: 'sideBar',
        text: 'Agora você pode fazer alterações no seu gráfico. Se precisar alterar os dados, basta selecionar as novos dados. Você também pode agrupar informações do seu gráfico ou aplicar filtros, além de alterar uma série de configurações de estilo do seu gráfico. Se você alterar alguma informação, clique em "Gerar Gráfico" para alterar o gráfico ou avance para o próximo passo',
      }, */
      {
        target: 'save_button',
        text: 'Finalizado o KPI desejado, basta clicar no botão "Salvar"',
        targetAction: 'save_button',
      },
      {
        target: 'title_modal',
        text: 'Caso você não tenha criado um título para o seu KPI, você precisará criá-lo agora. Após a inclusão do título, basta clicar em "Continuar".',
        fixedTop: true,
        targetAction: 'continue-btn',
        backAction: ['title_modal_close_btn', 1],
      },
      {
        target: 'submit_name_btn',
        text: 'Finalmente, confirme a criação do KPI, clicando em Salvar',
        targetAction: 'submit_name_btn',
        cantGoBack: true,
      },

    ],
  },
  uploadFile: {
    type: 'uploadFile',
    endingMsg: 'upload de arquivo',
    steps: [
      {
        target: 'upload_file_btn',
        text: ['Para adicionar um novo arquivo, clique no botão',
          <IconButton
            color="secondary"
            variant="contained"
            shape="rounded"
            style={{ margin: '0px 5px' }}
            className="more_options_fileViewer"
          >
            <Add />
          </IconButton>],
        targetAction: 'upload_file_btn',
        cantGoBack: true,
      },
      // {
      //   target: 'modal_upload_file',
      //   text: 'Certo! Agora você deve selecionar o seu arquivo (xlsx ou csv). Para isto, você pode arrastar um arquivo para a região tracejada ou selecionar um arquivo a partir do seu dispositivo ou de um link do Google Drive. Caso deseje selecionar um link do Google Drive, basta copiar o link correspondente seguindo as instruções. Em seguida, clique em "Adicionar"',
      //   targetAction: 'choose-file',
      //   block: true,
      //   backAction: ['modal_upload_file', 1],
      // },
      {
        target: 'modal_upload_file',
        text: 'Certo! Agora você deve selecionar o seu arquivo (xlsx ou csv). Para isto, clique no botão em destaque e escolha um arquivo do seu dispositivo.',
        targetAction: 'choose-file',
        block: true,
        cantGoBack: true,
      },
      {
        target: 'modal_upload_file_advance',
        text: 'Agora clique em "Adicionar".',
        block: true,
      },
      {
        target: 'container-input-select',
        text: 'Neste passo, você deve indicar a linha do seu arquivo que possui os nomes das colunas do seu arquivo e, caso o seu arquivo tenha extensão csv, selecione o separador decimal correspondente. Em seguida, avance ao próximo passo.',
        scrollToview: true,
      },
      {
        target: 'file-validation-table-scrolls',
        text: 'Caso deseje alterar os tipos de dados de seu arquivo basta selecionar o tipo de dados correspondente para cada variável. Se sua coluna é do tipo texto, selecione "Validar como texto". Se sua coluna é do tipo numérica, selecione "Validar como número". Se sua coluna é do tipo data, selecione "Validar como data". Em seguida, avance ao próximo passo.',
        scrollToview: true,
        fixedBottom: true,
        width: 450,
      },
      {
        target: 'validation_footer',
        text: 'Se o seu arquivo tem extensão csv, selecione o formato de suas colunas do tipo data.',
        scrollToview: true,
        fixedTop: true,
      },
      {
        target: 'modal_validation',
        text: 'Caso deseje realizar qualquer alteração, agora é o momento. Verifique se não há nada faltando e, em seguida, clique em "Validar". Se tudo estiver ok, o seu arquivo será validado e você poderá utilizá-lo para criar seus indicadores. Caso contrário, você receberá uma mensagem de erro para fazer as alterações necessárias.',
        fixedTop: true,
        cantGoBack: true,
        block: true,
        targetAction: 'validate_btn_optimizer',
        width: 500,
      },

    ],
  },
  createTemplate: {
    type: 'createTemplate',
    endingMsg: 'aplicação de template',
    steps: [
      {
        target: 'templates_list',
        // delay: 800,
        text: 'Para começar, você deve escolher qual template deseja aplicar. Em cada template você verá a descrição, categoria, dificuldade, prévia e outras informações. Escolha um template clicando em "Aplicar Este Template".',
        targetAction: 'template_apply_button',
        fixedTop: true,
        width: '400px',
        // cantGoBack: true,
        block: true,
      },
      {
        target: 'template_modal',
        delay: 300,
        text: 'Nesta etapa, é necessário selecionar o arquivo para o qual você deseja aplicar o template. As caixas à direita representam os requisitos mínimos para utilizar um arquivo. Selecione um arquivo que atenda estes requisitos e clique em "Avançar".',
        fixedTop: true,
        block: true,
        cantGoBack: true,
        width: '400px',
        // backAction: ['template_back_button'],
      },
      {
        target: 'template_modal',
        text: 'Após selecionado o arquivo, é necessário associar as colunas de seu conjunto de dados com as colunas necessárias no template. Basta selecionar em cada seletor a variável de sua base de dados correspondente à variável utilizada no template.',
        fixedTop: true,
        block: true,
        // cantGoBack: true,
        width: '400px',
        backAction: ['template_back_button'],
      },
      {
        target: 'template_modal',
        text: 'A seguir, você pode adicionar filtros ao seu arquivo para aplicar o seu template apenas a algum subconjunto da base de dados. Se esta é a sua intenção, basta selecionar a variável da base e os valores correspondentes a aplicação do filtro',
        // fixedTop: true,
        targetAction: 'foward',
        cantGoBack: true,
      },
      {
        target: 'template_modal',
        text: 'Agora, você deve escolher um nome para o seu dashboard. Fique a vontade para nomeá-lo como você quiser.',
        // fixedTop: true,
        block: true,
        backAction: ['step_progress_not', 1],
      },
    ],
  },
  shareDashboard: {
    type: 'shareDashboard',
    endingMsg: 'compartilhamento de dashboard',
    steps: [
      // {
      //   //criar um alerta ou algo para mostrar a msg ja q nao tem nenhum compoenent a ser atrelado
      // },
      {
        target: 'outerGrid',
        text: ['Clique no ícone',
          <IconButton
            color="black"
            variant="pattern"
            shape="circle"
            style={{ margin: '0px 5px' }}
            className="more_options_fileViewer"
          >
            <MoreVert />
          </IconButton>,
          'no dashboard que você deseja compartilhar'],
        block: true,
      },
      {
        target: 'share_dash_opt',
        text: 'Selecione a opção "Compartilhar" ',
        targetAction: 'share_dash_opt',
      },
      {
        target: 'shareModal',
        text: 'Selecione os usuários com os quais você deseja compartilhar este dashboard. Para cada um dos usuários selecionados, escolha o nível de permissão de compartilhamento entre "Visualizador" ou "Editor". Finalizada a seleção, clique em "Concluir" para finalizar o compartilhamento',
        delay: 500,
        block: true,
        backAction: ['share_modal_close_btn', 2],
      },
      // {
      //   target: 'select_list',
      //   text: 'Selecione os usuários com os quais você deseja compartilhar este dashboard. Após finalizar a seleção, avance para o próximo passo',
      // },
      // {
      //   target: 'list_share_user',
      //   text: 'Para cada um dos usuários selecionados, escolha o nível de permissão de compartilhamento entre "Visualizador" ou "Editor". Finalizada a seleção, avance para o próximo passo',
      //   backAction: [2],
      // },
      // {
      //   target: 'select_wrapper',
      //   text: 'Neste seletor voce altera as permissões para cada um dos usuários selecionados',
      //   which: 1,
      // },
      // {
      //   // target: 'share_modal',
      //   target: 'share_btn',
      //   text: 'Clique no botão "Compartilhar" para finalizar o compartilhamento do dashboard',
      //   targetAction: 'share_btn',
      //   block: true,
      // },

    ],
  },
  createUser: {
    type: 'createUser',
    endingMsg: 'criação de usuários',
    steps: [
      {
        target: 'profile_popover',
        text: 'Para criar um novo usuário, primeiro acesse o menu de gerenciamento',
        targetAction: 'profile_popover',
      },
      {
        target: 'gerenciar_instacia',
        text: 'Em seguida, selecione a opção de "Gerenciar Instância"',
        targetAction: 'gerenciar_instacia',
      },
      {
        target: 'add_user_button',
        text: ['Agora você está na página de gerenciamento de sua instância. Para adicionar um novo usuário, selecione o botão',
          <IconButton
            color="secondary"
            variant="contained"
            shape="rounded"
            style={{ margin: '0px 5px' }}
            className="more_options_fileViewer"
          >
            <Add />
          </IconButton>],
        targetAction: 'add_user_button',
        cantGoBack: true,
      },
      {
        target: 'newUser_modal',
        text: 'Preencha o formulário com o e-mail e perfil de usuário que você deseja adicionar e, em seguida, clique em "Adicionar"',
        targetAction: 'newUser_modal',
        // backAction: ['add_user_modal_close_btn', 1],,
        cantGoBack: true,
      },

    ],
  },
  createStories: {
    type: 'createStories',
    endingMsg: 'criação de stories',
    steps: [
      {
        target: 'createKpi_first',
        text: ['Clique no botão',
          <IconButton
            color="secondary"
            variant="contained"
            shape="rounded"
            style={{ margin: '0px 5px' }}
            className="more_options_fileViewer"
          >
            <Add />
          </IconButton>,
          'para selecionar a opção de criação de um Story'],
        delay: 500,
        targetAction: 'createKpi_first',
      }, {
        target: 'newStory_btn',
        text: 'Selecione a opção "Adicionar Story"',
        targetAction: 'newStory_btn',
      },
      {
        target: 'modal_story',
        text: 'Este e o modal de criação de stories. Aqui você pode adicionar o nome para o seu Story e salvá-lo',
        targetAction: 'save_btn_story',
        block: true,
        backAction: ['modal_story_close_btn', 2],
      },
    ],
  },
};

export default tourList;
