import React from 'react';

const getShape = {
  square: (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <path d="M24 0h-24v24h24v-24z" />
    </svg>
  ),
  circle: (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <circle cx="12" cy="12" r="12" />
    </svg>
  ),
  triangle: (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <path d="M24 22h-24l12-20z" />
    </svg>
  ),
  diamond: (
    <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd" viewBox="0 0 24 24">
      <path d="M12 .001l12 12-12 12-12-12 12-12z" />
    </svg>
  ),
};

const Symbols = ({ shape }) => (
  getShape[shape]
);

export default Symbols;
