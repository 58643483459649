import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../../juristec-ui/core/Button';
import ColorPicker from '../../../juristec-ui/core/ColorPicker2';
import RangeBar from '../../../juristec-ui/core/RangeBar';
import InputNumberLine from '../../../juristec-ui/core/InputNumberLine';
import Toggle from '../../../juristec-ui/core/Toggle';

import { BackgroundColor, LineColor } from '../../../juristec-ui/icons';

import {
  Label, SelectButtons, Row, InputRow, InputColumn,
} from '../styled/DashStyles.styled';

const CardStyle = ({ tmpData, handler, timedHandler }) => {
  const handleBorderLine = (e) => handler('borderLine', e.target.value);
  const handleBorderColor = (val) => timedHandler('borderColor', val);
  const handleBorderSize = (val) => handler('borderSize', val);
  const handleBorderRounded = (val) => handler('borderRounded', val);
  const handleSpaceBetween = (val) => handler('spaceBetween', val);
  const handleBackgroundColor = (val) => timedHandler('backgroundColor', val);
  const handleShading = () => handler('shading', !tmpData.shading);

  return (
    <>
      <InputColumn>
        <Label>Tipo da borda</Label>
        <SelectButtons>
          <Button
            shape="square"
            size="small"
            className="choicebox-btn"
            value=""
            variant={tmpData.borderLine === '' ? 'contained' : 'outlined'}
            onClick={handleBorderLine}
          >
            Nenhuma
          </Button>
          <Button
            shape="square"
            size="small"
            className="choicebox-btn"
            value="solid"
            variant={tmpData.borderLine === 'solid' ? 'contained' : 'outlined'}
            onClick={handleBorderLine}
          >
            Contínua
          </Button>
          <Button
            shape="square"
            size="small"
            className="choicebox-btn"
            value="dashed"
            variant={tmpData.borderLine === 'dashed' ? 'contained' : 'outlined'}
            onClick={handleBorderLine}
          >
            Tracejada
          </Button>
          <Button
            shape="square"
            size="small"
            className="choicebox-btn"
            value="dotted"
            variant={tmpData.borderLine === 'dotted' ? 'contained' : 'outlined'}
            onClick={handleBorderLine}
          >
            Pontilhada
          </Button>
        </SelectButtons>
      </InputColumn>
      <InputRow disabled={tmpData.borderLine === ''}>
        <Label>Cor da borda</Label>
        <ColorPicker onChange={handleBorderColor} color={tmpData.borderColor} isIconButton atModal>
          <LineColor />
        </ColorPicker>
      </InputRow>
      <InputColumn disabled={tmpData.borderLine === ''}>
        <Label>Espessura da borda</Label>
        <Row>
          <InputNumberLine
            value={tmpData.borderSize}
            onChange={(e) => handleBorderSize(e.target.value)}
            setValue={handleBorderSize}
            min={0}
            max={10}
            step={1}
            numContainerStyle={{ flex: '1', marginRight: '.5rem' }}
            style={{ textAlign: 'center' }}
          />
          <RangeBar
            min={0}
            max={10}
            step={1}
            value={tmpData.borderSize}
            onChange={handleBorderSize}
            style={{ flex: '5' }}
          />
        </Row>
      </InputColumn>
      <InputColumn>
        <Label>Arredondamento da borda</Label>
        <Row>
          <InputNumberLine
            value={tmpData.borderRounded}
            onChange={(e) => handleBorderRounded(e.target.value)}
            setValue={handleBorderRounded}
            min={0}
            max={20}
            step={1}
            numContainerStyle={{ flex: '1', marginRight: '.5rem' }}
            style={{ textAlign: 'center' }}
          />
          <RangeBar
            min={0}
            max={20}
            step={1}
            value={tmpData.borderRounded}
            onChange={handleBorderRounded}
            style={{ flex: '5' }}
          />
        </Row>
      </InputColumn>
      <InputColumn>
        <Label>Separação entre os cards</Label>
        <Row>
          <InputNumberLine
            value={tmpData.spaceBetween}
            onChange={(e) => handleSpaceBetween(e.target.value)}
            setValue={handleSpaceBetween}
            min={0}
            max={10}
            step={1}
            numContainerStyle={{ flex: '1', marginRight: '.5rem' }}
            style={{ textAlign: 'center' }}
          />
          <RangeBar
            min={0}
            max={10}
            step={1}
            value={tmpData.spaceBetween}
            onChange={handleSpaceBetween}
            style={{ flex: '5' }}
          />
        </Row>
      </InputColumn>
      <InputRow>
        <Label>Fundo dos Kpis</Label>
        <ColorPicker
          onChange={handleBackgroundColor}
          color={tmpData.backgroundColor}
          isIconButton
          atModal
        >
          <BackgroundColor />
        </ColorPicker>
      </InputRow>
      <InputRow>
        <Label>Sombreamento</Label>
        <Toggle
          label=""
          checked={tmpData.shading}
          handleChange={handleShading}
          styleContainer={{ margin: 'auto 0' }}
          highlightOption={false}
          size="small"
        />
      </InputRow>
    </>
  );
};

CardStyle.propTypes = {
  tmpData: PropTypes.shape({
    borderLine: PropTypes.string,
    borderColor: PropTypes.string,
    borderSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    borderRounded: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    spaceBetween: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    backgroundColor: PropTypes.string,
    shading: PropTypes.bool,
  }).isRequired,
  handler: PropTypes.func.isRequired,
  timedHandler: PropTypes.func.isRequired,
};

export default CardStyle;
