import styled, { css } from 'styled-components';

function hexToRgb(hex, opacity) {
  let h = hex.replace('#', '');
  h = h.match(new RegExp(`(.{${h.length / 3}})`, 'g'));

  if (h.length) {
    h.forEach((item, i) => {
      h[i] = parseInt(h[i].length === 1 ? h[i] + h[i] : h[i], 16);
    });
  }

  if (typeof opacity !== 'undefined') h.push(opacity);

  return `rgba(${h.join(',')})`;
}

function getPosition(key) {
  switch (key) {
    case 'top':
      return css`align-items: flex-start; justify-content: center;`;
    case 'top-left':
      return css`align-items: flex-start; justify-content: flex-start;`;
    case 'top-right':
      return css`align-items: flex-start; justify-content: flex-end;`;
    case 'center-left':
      return css`align-items: center; justify-content: flex-start;`;
    case 'center-right':
      return css`align-items: center; justify-content: flex-end;`;
    case 'bottom':
      return css`align-items: flex-end; justify-content: center;`;
    case 'bottom-left':
      return css`align-items: flex-end; justify-content: flex-start;`;
    case 'bottom-right':
      return css`align-items: flex-end; justify-content: flex-end;`;
    default:
      return css`align-items: center; justify-content: center;`;
  }
}

// ${({ isEdit }) => !isEdit && css`height: 100%;`}
const StyledKpiContentLabel = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
  z-index: 0;
  ${({ styles, img }) => css`
    ${getPosition(styles?.textPosition)}
    & span {
      padding: 5px;
      background-color: ${img ? 'transparent' : hexToRgb(styles?.colorOpacityText || '#fff', styles?.opacityText || 0)};
      border-radius: 4px;
    }

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
      background-image: url(${img});
      opacity: ${styles?.opacityImage};
      background-position: center;
      background-repeat: no-repeat;
      background-size: ${styles?.imageFit || 'contain'};
    }
    opacity: 1;
  `};
`;

export default StyledKpiContentLabel;
