export default {
  lg: [
    {
      y: 22,
      x: 0,
      w: 48,
      i: '0J8IZNojp5OVcpt0hjb4',
      h: 2,
    },
    {
      h: 10,
      w: 18,
      i: '2RChLQGjT6zM3ZSJYQ1B',
      y: 2,
      x: 12,
    },
    {
      h: 15,
      y: 24,
      x: 0,
      w: 24,
      i: '3UFVJ7Xcr7ffH9YuD7ri',
    },
    {
      w: 18,
      h: 10,
      i: 'ORnUFDQzt8K5sPDNPZCx',
      x: 30,
      y: 2,
    },
    {
      x: 24,
      h: 15,
      w: 24,
      y: 41,
      i: 'SgQASPV4fEtXMaWya5Oc',
    },
    {
      w: 24,
      i: 'YSdaECCmKscx8nWF3dVk',
      y: 12,
      x: 24,
      h: 10,
    },
    {
      h: 2,
      i: 'aGN67INP7prddwVN8QTU',
      w: 48,
      y: 0,
      x: 0,
    },
    {
      y: 2,
      w: 12,
      i: 'bK0lArNbSwhJCqfHNXPS',
      h: 5,
      x: 0,
    },
    {
      h: 2,
      i: 'dGitR5fBCS2TDGbTpTGU',
      x: 0,
      w: 48,
      y: 39,
    },
    {
      h: 5,
      i: 'iwtz3OY7O0oRIHEirHHQ',
      w: 12,
      y: 7,
      x: 0,
    },
    {
      h: 15,
      x: 24,
      w: 24,
      y: 24,
      i: 'jUBEpTY3gqFz8HeVH0vc',
    },
    {
      i: 'mV6upA3NCa5pZeD0Aa9h',
      x: 0,
      w: 24,
      h: 15,
      y: 41,
    },
    {
      w: 24,
      h: 10,
      i: 'qYauzWDSRbTFJ1OuGPsW',
      y: 12,
      x: 0,
    },
  ],
  sm: [
    {
      x: 0,
      y: 43,
      h: 2,
      w: 12,
      i: '0J8IZNojp5OVcpt0hjb4',
    },
    {
      x: 0,
      i: '2RChLQGjT6zM3ZSJYQ1B',
      y: 6,
      h: 10,
      w: 6,
    },
    {
      h: 15,
      y: 28,
      i: '3UFVJ7Xcr7ffH9YuD7ri',
      x: 0,
      w: 6,
    },
    {
      x: 6,
      w: 6,
      h: 10,
      i: 'ORnUFDQzt8K5sPDNPZCx',
      y: 6,
    },
    {
      x: 6,
      y: 45,
      w: 6,
      h: 15,
      i: 'SgQASPV4fEtXMaWya5Oc',
    },
    {
      y: 16,
      x: 6,
      i: 'YSdaECCmKscx8nWF3dVk',
      w: 6,
      h: 10,
    },
    {
      h: 2,
      x: 0,
      y: 0,
      i: 'aGN67INP7prddwVN8QTU',
      w: 12,
    },
    {
      x: 0,
      h: 4,
      i: 'bK0lArNbSwhJCqfHNXPS',
      y: 2,
      w: 6,
    },
    {
      x: 0,
      i: 'dGitR5fBCS2TDGbTpTGU',
      h: 2,
      w: 12,
      y: 26,
    },
    {
      w: 6,
      y: 2,
      i: 'iwtz3OY7O0oRIHEirHHQ',
      x: 6,
      h: 4,
    },
    {
      i: 'jUBEpTY3gqFz8HeVH0vc',
      y: 28,
      h: 15,
      w: 6,
      x: 6,
    },
    {
      y: 45,
      i: 'mV6upA3NCa5pZeD0Aa9h',
      w: 6,
      h: 15,
      x: 0,
    },
    {
      w: 6,
      i: 'qYauzWDSRbTFJ1OuGPsW',
      h: 10,
      x: 0,
      y: 16,
    },
  ],
  xxs: [
    {
      y: 79,
      h: 2,
      i: '0J8IZNojp5OVcpt0hjb4',
      w: 4,
      x: 0,
    },
    {
      y: 5,
      x: 0,
      w: 4,
      h: 11,
      i: '2RChLQGjT6zM3ZSJYQ1B',
    },
    {
      i: '3UFVJ7Xcr7ffH9YuD7ri',
      x: 0,
      w: 4,
      y: 49,
      h: 15,
    },
    {
      w: 4,
      x: 0,
      h: 11,
      y: 16,
      i: 'ORnUFDQzt8K5sPDNPZCx',
    },
    {
      y: 96,
      h: 15,
      x: 0,
      w: 4,
      i: 'SgQASPV4fEtXMaWya5Oc',
    },
    {
      w: 4,
      h: 10,
      x: 0,
      y: 37,
      i: 'YSdaECCmKscx8nWF3dVk',
    },
    {
      h: 2,
      x: 0,
      y: 0,
      w: 4,
      i: 'aGN67INP7prddwVN8QTU',
    },
    {
      w: 2,
      i: 'bK0lArNbSwhJCqfHNXPS',
      h: 3,
      x: 0,
      y: 2,
    },
    {
      w: 4,
      x: 0,
      i: 'dGitR5fBCS2TDGbTpTGU',
      y: 47,
      h: 2,
    },
    {
      x: 2,
      y: 2,
      h: 3,
      w: 2,
      i: 'iwtz3OY7O0oRIHEirHHQ',
    },
    {
      w: 4,
      h: 15,
      i: 'jUBEpTY3gqFz8HeVH0vc',
      y: 64,
      x: 0,
    },
    {
      y: 81,
      i: 'mV6upA3NCa5pZeD0Aa9h',
      x: 0,
      h: 15,
      w: 4,
    },
    {
      i: 'qYauzWDSRbTFJ1OuGPsW',
      h: 10,
      x: 0,
      w: 4,
      y: 27,
    },
  ],
};
