import styled, { css, keyframes } from 'styled-components';

const inputHighlighter = keyframes`
  from { background: ${({ theme }) => theme.highlightTextLine}; }
  to { width:0; background:transparent; }
`;

export const TextAreaContainer = styled.div`
  position: relative;
  height: 100%;
  padding-top: ${(props) => (props.label === '' ? '0px' : '20px')};
  ${(props) => props.disabled && css`
    background-color: rgba(234,234,234,0.5);
    pointer-events: none;
  `}
`;

export const Label = styled.label`
  position:absolute;
  top:2px;
  left: 0px;
  font-size:0.85rem;
  color:${({ theme }) => theme.inputLabel};
  font-weight: bold;
`;

export const ErrorMessage = styled.span`
  position: absolute;
  font-size: 10px;
  color: ${({ theme }) => theme.error};
`;

export const Highlight = styled.span`
  position:absolute;
  height:60%;
  width:100px;
  top:25%;
  left:0;
  pointer-events:none;
  opacity:0.5;
`;

export const Bar = styled.span`
  position:relative;
  display:block;
  width: 100%;
  margin: 0 auto;

  &:before, &:after {
    content:'';
    height:4px; 
    width:0;
    bottom:0px;
    position:absolute;
    transition:0.2s ease all; 
    -moz-transition:0.2s ease all; 
    -webkit-transition:0.2s ease all;
  }
  &:before {
    left:50%;
  }
  &:after {
    right:50%; 
  }
`;

export const StyledTextArea = styled.textarea`
  resize: none;
  box-sizing: border-box;
  font-size: 1rem;
  padding-top: 5px;
  padding-bottom: 5px;
  display: block;
  width: 100%;
  height: 100%;
  border: 1px solid ${({ theme }) => theme.inputBottomLine};
  border-radius: 5px;
  color: ${({ theme }) => theme.color};
  background-color: ${({ notTransparent, theme }) => (notTransparent ? theme.white : 'transparent')};
  scrollbar-width: thin !important;

  &:focus {
    outline: none;
    +${Label} {
      color: ${({ error, theme }) => (error ? theme.error : theme.highlightTextLine)};
    };
    ~${Bar}:before, ~${Bar}:after {
      width: 50%;
      background: ${({ error, theme }) => (error ? theme.error : theme.highlightTextLine)};
    };
    ~${Highlight} {
      -webkit-animation: ${inputHighlighter} 0.3s ease;
      -moz-animation: ${inputHighlighter} 0.3s ease;
      animation: ${inputHighlighter} 0s ease;
    };
  };

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  };
`;
