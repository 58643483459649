import React, { useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';

// components
import PublicHeader from '../../components/PublicPage/PublicHeader/PublicHeader';
import PublicKpiToolbar from '../../components/PublicPage/PublicKpiToolbar/PublicKpiToolbar';
import PublicGridKpi from '../../components/PublicPage/PublicGridKpi';
import PublicNotFound from '../../components/PublicPage/PublicNotFound';
// import PublicFooter from '../../juristec-ui/metricsComponents/PublicPage/PublicFooter';
import Loader from '../../juristec-ui/core/Loader';

// others
import useGridKpi from '../../hooks/useGridKpi';

import urlHash from '../../utils/functions/urlHash';

const getParams = (h) => {
  const str = urlHash(h, true).split('/');
  return { dashboardKey: str[0], userId: str[1] };
};

function PublicPage() {
  const isMounted = useRef(false);
  const { hash } = useParams();
  const { dashboardKey, userId } = getParams(hash);

  const [gridKpiState, gridKpiAPI] = useGridKpi(userId, dashboardKey);

  useEffect(() => {
    if (!isMounted.current) {
      if (!userId || !dashboardKey) return;
      gridKpiAPI.getPublicKpis(userId, dashboardKey);
    }
  }, [dashboardKey, gridKpiAPI, userId]);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  return (
    <>
      {gridKpiState.isLoading && <Loader />}
      {(!gridKpiState.isLoading && !gridKpiState.dashboardDoc) ? (
        <PublicNotFound />
      ) : (
        <>
          <PublicHeader dashboardDoc={gridKpiState.dashboardDoc} />
          <PublicKpiToolbar dashboardDoc={gridKpiState.dashboardDoc} />
          <PublicGridKpi
            kpiItemList={gridKpiState.kpiItemList}
            dashboardDoc={gridKpiState.dashboardDoc}
            kpisLayout={gridKpiState.kpisLayout}
          />
        </>
      )}
      {/* <PublicFooter /> */}
    </>
  );
}

export default PublicPage;
