/* eslint-disable eqeqeq */
/* eslint-disable no-param-reassign */
import React, {
  useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';

import ProcessBlocks from './Tabs/ProcessBlocks';
import ProcessData from './Tabs/ProcessData';

import {
  MainContainer,
} from './styled/ProcessInfo.styled';

const ProcessInfo = ({
  processData, getBlockTxt, tab, morePartInfo,
}) => {
  const [blocksTimeline, setBlocksTimeline] = useState({
    map: {},
    sorted: [],
    isReady: false,
    defaultBlock: '',
  });
  const [activityTimeline, setActivityTimeline] = useState({
    map: {},
    sorted: [],
    isReady: false,
  });

  const handleGetBlock = async (blockId, datePoint, pg) => {
    const blockTxt = await getBlockTxt(processData.cnj, blockId);
    if (blockTxt) {
      const reCnj = processData.cnj.replace(/^[0]*/, '[0]*').replace(/-|\./g, '(\\.|-| )*');
      const re = new RegExp(reCnj, 'gi');
      const markedTxt = blockTxt.replace(re, (p1) => (
        `*${p1}*`
      ));
      setBlocksTimeline((b) => ({
        ...b,
        map: {
          ...b.map,
          [datePoint]: b.map[datePoint].map((bp, i) => (
            pg === i ? { ...bp, text: markedTxt } : bp
          )),
        },
      }));
    }
  };

  useEffect(() => {
    if (processData.bloco) {
      const defaultBlock = {
        defaultKey: '',
        defaultIdx: 0,
      };
      const map = processData.bloco.reduce((aux, block) => {
        if (block.bloco_id == processData.defaultBlockId) {
          defaultBlock.defaultKey = block.data_publicacao;
        }
        aux[block.data_publicacao] = [
          ...(aux[block.data_publicacao] || []), block,
        ];
        return aux;
      }, {});
      if (defaultBlock.defaultKey) {
        defaultBlock.defaultIdx = map[defaultBlock.defaultKey].findIndex(
          (b) => b.bloco_id == processData.defaultBlockId,
        );
      }
      const dates = Object.keys(map).sort((a, b) => (
        new Date(`${b} 00:00:00`) >= new Date(`${a} 00:00:00`)
      ));
      setBlocksTimeline({
        map,
        sorted: dates,
        isReady: true,
        ...defaultBlock,
      });
    }
  }, [processData.bloco]);

  useEffect(() => {
    if (processData.movimentacao) {
      const map = processData.movimentacao.reduce((aux, mov) => {
        aux[mov.data_movimentacao] = [
          ...(aux[mov.data_movimentacao] || []), mov,
        ];
        return aux;
      }, {});
      const dates = Object.keys(map).sort((a, b) => (
        new Date(`${b} 00:00:00`) >= new Date(`${a} 00:00:00`)
      ));
      setActivityTimeline({
        map,
        sorted: dates,
        isReady: true,
      });
    }
  }, [processData.movimentacao]);

  return (
    <MainContainer>
      {tab === 'DATA' && (
        <ProcessData
          data={processData}
          activities={activityTimeline}
          morePartInfo={morePartInfo}
        />
      )}
      {tab === 'BLOCK' && (
        <ProcessBlocks
          blocksTimeline={blocksTimeline}
          getBlock={handleGetBlock}
        />
      )}
    </MainContainer>
  );
};

ProcessInfo.propTypes = {
  processData: PropTypes.shape({
    cnj: PropTypes.string,
    bloco: PropTypes.arrayOf(PropTypes.shape({
      data_publicacao: PropTypes.string,
      pagina_fim: PropTypes.number,
      pagina_comeco: PropTypes.number,
    })),
    movimentacao: PropTypes.arrayOf(PropTypes.shape({
      data_movimentacao: PropTypes.string,
    })),
    defaultBlockId: PropTypes.string,
  }),
  tab: PropTypes.string,
  getBlockTxt: PropTypes.func.isRequired,
  morePartInfo: PropTypes.func.isRequired,
};

ProcessInfo.defaultProps = {
  processData: {},
  tab: 'data',
};

export default ProcessInfo;
