import React from 'react';

// import { Container } from './styles';

function ArchiveBox({ width='20', height='20'}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1.25 16.25C1.25 16.9414 1.80859 17.5 2.5 17.5H17.5C18.1914 17.5 18.75 16.9414 18.75 16.25V5H1.25V16.25ZM7.5 7.96875C7.5 7.71094 7.71094 7.5 7.96875 7.5H12.0312C12.2891 7.5 12.5 7.71094 12.5 7.96875V8.28125C12.5 8.53906 12.2891 8.75 12.0312 8.75H7.96875C7.71094 8.75 7.5 8.53906 7.5 8.28125V7.96875ZM18.75 0H1.25C0.558594 0 0 0.558594 0 1.25V3.125C0 3.46875 0.28125 3.75 0.625 3.75H19.375C19.7188 3.75 20 3.46875 20 3.125V1.25C20 0.558594 19.4414 0 18.75 0Z" />
    </svg>
  );
}

export default ArchiveBox;
