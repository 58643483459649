import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';
import { gap } from '../../../../juristec-ui/styles/theme';
import { devices } from '../../../../juristec-ui/core/breakpoints';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 5%;

  & .gap-fix {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    ${gap('10px', 'row')}
  }

  @media ${devices.tablet} {
    ${gap('15px', 'row')}
    padding: 10px 20px;
  }

  @media ${devices.phoneS} {
    align-items: center;
    ${gap('0', 'row')}
    padding: 10px 20px;
  }
`;

export const FirstSpace = styled.div`
  display: flex;
  ${gap('10px', 'row')}
`;

export const SecondSpace = styled.div`
  display: Flex;
  ${gap('10px', 'row')}
`;

export const Tabs = styled.div`
  display: flex;
  ${gap('20px', 'row')}
`;

export const NavOpt = styled.div`
  color: ${({ theme }) => theme.toolbarFont};
  text-decoration: none;
  padding: 0 5px;
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-weight: bold;
  border-bottom: 4px solid transparent;
  box-sizing: border-box;
  text-align: center;

  &:hover {
    color: ${({ theme }) => theme.primary};
  }

  ${({ active, theme }) => active && css`
    color: ${theme.primary};
    border-bottom-color: ${theme.primary};
  `}
`;

export const SearchBarContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  ${gap('.5rem', 'row')}
  background-color: ${({ theme }) => theme.backgroundOdd};
  border-radius: 10px;
  box-shadow: 0px 4px 4px ${({ theme }) => theme.shadow};
  padding: 0.2rem 0.6rem;
  & svg {
    height: 20px;
    width: 20px;
    fill: ${({ theme }) => theme.grey};
  }
  & .inputTxt {
    padding-top: 5px;
  }
  @media ${devices.phoneS} {
    width: 100%;
    & div:last-child {
      width: 100%;
    }
    & .inputTxt {
      width: 100%;
    }
  }
`;

export const IconWrapper = styled.div`
  width: 20px;
  height: 20px;
`;
