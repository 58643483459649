import { format } from 'date-fns';

export const printSize = (size) => {
  if (size === 0) {
    return '0 Bytes';
  }
  const k = 1024;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  const i = Math.floor(Math.log(size) / Math.log(k));
  return `${parseFloat((size / (k ** i)).toFixed(2))} ${sizes[i]}`;
};

/**
 * Checks if timestamp is a Firebase timestamp object.
 * @param {object|*} timestamp Object to be checked.
 * @returns {Date|*} If true, instance of Date. If false, no changes.
 */
export const verifyTimestamp = (timestamp) => {
  try {
    return timestamp.toDate();
  } catch (error) {
    return timestamp;
  }
};

/**
 * Gets a Date type object of different formats.
 * @param {*} rawDate Date at different format.
 * @returns {Date} Instance of Date.
 */
function getDateObj(rawDate) {
  if (!rawDate) return 'Invalid Date';
  switch (typeof rawDate) {
    case 'object':
      /* Firebase serverTimestamp */
      return new Date(verifyTimestamp(rawDate));
    case 'string':
      /* No time. Ex.: '2022-04-23' */
      if (/^\d{4}-\d{2}-\d{2}$/.test(rawDate)) return new Date(`${rawDate} 00:00:00`);
      /* Python ISO date */
      if (/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{6}$/.test(rawDate)) return new Date(`${rawDate}Z`);
      /* Traditional ISO date */
      return new Date(rawDate);
    default:
      return new Date(rawDate);
  }
}

/**
 * Format patterns to be used with date-fns 'format'.
 * @TODO US and ISO patterns.
 */
const dateTimePatterns = {
  none: 'dd/MM/yyyy',
  half: 'dd/MM/yyyy, HH:mm',
  full: 'dd/MM/yyyy, HH:mm:ss',
};

/**
 * Formats different types of date.
 * @param {*} date Date to be formatted.
 * @param {Object} options Custom format options.
 * @param {("none"|"half"|"full")} options.time Time options.
 * 'none' = no time, 'half' = only hours and minutes, 'full' = hours, minutes and seconds.
 * @returns {string} Formatted date.
 */
export function formatDateTime(date, options = { time: 'none' }) {
  const dateObj = getDateObj(date);
  if (dateObj.toString() === 'Invalid Date') return 'Data inválida';
  return format(dateObj, dateTimePatterns[options.time]);
}

export const isoDateToUTC = (date) => {
  const iso = date.toISOString();
  return iso.replace('Z', '+00:00');
};
