import React, { useState } from 'react';
import PropTypes from 'prop-types';
import InputTextLine from '../../../juristec-ui/core/InputTextLine';
import Button from '../../../juristec-ui/core/Button';
import ActionsGroup from '../../../juristec-ui/core/ActionsGroup';

import { verifyCurrentPassword } from '../../../juristec-ui/utils/validators/inputTextValidators';

import {
  Container,
  ItemContainer,
} from './styled/RegisterApiGedCredentials.styled';

/**
 * A modal to handle the addition of new users to the instance
 */
const RegisterApiGedCredentials = ({
  submit,
  hide,
}) => {
  const [consumerKey, setConsumerKey] = useState({ value: '', error: true, errorMsg: '' });
  const [consumerSecret, setConsumerSecret] = useState({ value: '', error: true, errorMsg: '' });

  const consumerKeyHandle = (value) => {
    const val = value.trim();
    const msg = verifyCurrentPassword(val);
    setConsumerKey({
      value: val,
      error: msg.length !== 0,
      errorMsg: msg,
    });
  };

  const consumerSecretHandle = (value) => {
    const val = value.trim();
    const msg = verifyCurrentPassword(val);
    setConsumerSecret({
      value: val,
      error: msg.length !== 0,
      errorMsg: msg,
    });
  };

  const handleSubmit = () => {
    submit(consumerKey.value, consumerSecret.value);
  };

  return (
    <>
      <Container>
        <ItemContainer>
          <InputTextLine
            label="Consumer Key"
            placeholder="Insira a consumer key"
            errorMessage={consumerKey.errorMsg}
            error={consumerKey.errorMsg.length > 0}
            onChange={(e) => consumerKeyHandle(e.target.value)}
            value={consumerKey.value}
          />
        </ItemContainer>
        <ItemContainer>
          <InputTextLine
            label="Consumer Secret"
            placeholder="Insira a consumer secret"
            errorMessage={consumerSecret.errorMsg}
            error={consumerSecret.errorMsg.length > 0}
            onChange={(e) => consumerSecretHandle(e.target.value)}
            value={consumerSecret.value}
          />
        </ItemContainer>
      </Container>
      <ActionsGroup>
        <Button
          style={{ margin: '5px' }}
          onClick={hide}
          variant="outlined"
          size="large"
        >
          Cancelar
        </Button>
        <Button
          style={{ margin: '5px' }}
          onClick={() => handleSubmit()}
          size="large"
          disabled={consumerKey.error || consumerSecret.error}
        >
          Adicionar
        </Button>
      </ActionsGroup>
    </>
  );
};

RegisterApiGedCredentials.propTypes = {
  /**
   * A function that sends the new data back to the parent
   */
  submit: PropTypes.func.isRequired,
  /**
   * Function to close the modal
   */
  hide: PropTypes.func.isRequired,
};

RegisterApiGedCredentials.defaultProps = {};

export default RegisterApiGedCredentials;
