import styled from 'styled-components';

import { devices } from '../../../../../juristec-ui/core/breakpoints';
import { gap } from '../../../../../juristec-ui/styles/theme';

export const OnboardingReportContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 .5rem 1rem;
  gap: 1rem;
  min-width: 310px;
  @media ${devices.phoneS} {
    min-width: auto;
  }
`;

export const RowOptions = styled.div`
  display: flex;
  ${gap('10px', 'row')};
  width: 100%;
`;

export const ItemContainer = styled.div`
  display: flex;
  width: 50%;
`;

export const SelectedFields = styled.div`
  display: flex;
  flex-wrap: wrap;
  ${gap('5px', 'row')};
`;

export const MainContainer = styled.div`
  padding: 0 1rem;
  max-width: 1000px;
  min-width: 50vw;
  overflow-x: hidden;
  overflow-y: auto;
  user-select: none;
`;

export const ColumnsTitle = styled.div`
  display: flex;
  flex: 1 1 0;
  max-height: calc(100vh - 380px);
  overflow: auto;
  text-align: center;
  & h4 {
    margin: 0;
  }
  & .list-header {
    position: sticky;
    z-index: 1;
    top: 0;
    padding: .5rem;
    /* background-color: ${({ theme }) => theme.background}; */
  }
`;

export const ColumnsList = styled.div`
  display: flex;
  flex-direction: column;
  ${gap('3px', 'columns')};
`;

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.card};
  & > div {
    width: 100%;
  }
`;
