import React from 'react';

function Error() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="white" xmlns="http://www.w3.org/2000/svg">
      <path d="M23.7205 19.5C23.7205 19.9878 23.5363 20.4025 23.168 20.7439L20.4845 23.2317C20.1162 23.5732 19.669 23.7439 19.1428 23.7439C18.6166 23.7439 18.1694 23.5732 17.8011 23.2317L12 17.8537L6.19897 23.2317C5.83065 23.5732 5.38341 23.7439 4.85723 23.7439C4.33106 23.7439 3.88382 23.5732 3.5155 23.2317L0.832021 20.7439C0.463701 20.4025 0.279541 19.9878 0.279541 19.5C0.279541 19.0122 0.463701 18.5976 0.832021 18.2561L6.63306 12.8781L0.832021 7.50001C0.463701 7.15855 0.279541 6.74391 0.279541 6.25611C0.279541 5.7683 0.463701 5.35367 0.832021 5.01221L3.5155 2.5244C3.88382 2.18294 4.33106 2.01221 4.85723 2.01221C5.38341 2.01221 5.83065 2.18294 6.19897 2.5244L12 7.90245L17.8011 2.5244C18.1694 2.18294 18.6166 2.01221 19.1428 2.01221C19.669 2.01221 20.1162 2.18294 20.4845 2.5244L23.168 5.01221C23.5363 5.35367 23.7205 5.7683 23.7205 6.25611C23.7205 6.74391 23.5363 7.15855 23.168 7.50001L17.367 12.8781L23.168 18.2561C23.5363 18.5976 23.7205 19.0122 23.7205 19.5Z" fill="white" />
    </svg>
  );
}

export default Error;
