import styled, { css } from 'styled-components';
import { devices } from '../../../../juristec-ui/core/breakpoints';

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

export const LabelRow = styled(Row)`
  justify-content: space-between;
  margin: 0 0 1rem;
  align-items: center;
  & h3 {
    width: max-content;
    margin: 0;
    color: ${({ theme }) => theme.toolbarFont};
  }
  & .file-qtd {
    color: ${({ theme }) => theme.primary};
  }
`;

export const FilesCardsContainer = styled.div`
  padding: 0 5%;
  @media ${devices.tablet} {
    padding: 0 20px;
  }
`;

export const CardHeader = styled(Row)`
  justify-content: start;
  align-items: center;
  position: relative;
  gap: 0.6rem;
  & span {
    color: ${({ theme }) => theme.grey};
    font-size: 0.85rem;
  }
`;

export const CardMain = styled(Row)`
  justify-content: center;
  margin: 10px 0px;
  min-height: 70px;
  align-items: center;
  width: 100%;
  word-break: break-all;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const CardFooter = styled(Row)`
  align-items: center;
  justify-content: ${(props) => (props.isOwner ? 'flex-end' : 'space-between')};
  flex-direction: row;
  color: ${({ theme }) => theme.grey};
  font-size: 0.85rem;
`;

export const CardFooterInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: ${(props) => (props.isOwner ? 'center' : 'flex-start')};
  align-items: ${(props) => (props.isOwner ? 'flex-end' : 'flex-end')};
`;

export const CardsDeck = styled.div`
  padding-right: 5px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-auto-rows: auto;
  grid-gap: 2rem;
  box-sizing: border-box;
  padding-bottom: 65px;
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 193px);
  @media ${devices.phoneS} {
    height: calc(100vh - 191px);
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  & svg {
    fill: ${({ theme }) => theme.primary};
  }
`;

export const FileCard = styled.div`
  background-color: ${({ theme }) => theme.backgroundOdd};
  border-radius: 10px;
  padding: 0.5rem;
  box-shadow: 0px 5px 5px ${({ theme }) => theme.shadow};
  max-height: 160px;
`;

export const IconBtnContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  margin-left: auto;
`;

export const SortContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0.3rem 0.8rem;
  justify-content: start;
  font-size: 14px;
  font-weight: bold;
  width: 140px;
  & span {
    margin: 5px 0;
  }
  & h4 {
    margin: 0 1rem;
    color: ${({ theme }) => theme.grey};
    text-align: center;
  }
`;

export const SortBtnsRow = styled(IconBtnContainer)`
  justify-content: center;
  margin: 0;
  gap: 15px;
  & svg {
    fill: ${({ theme }) => theme.grey};
  }
  ${({ field, toggle, theme }) => field === toggle.field && css`
    & div:nth-child(${toggle.order > 0 ? 1 : 2}) button svg {
      fill: ${theme.primary};
    }
  `};
`;

export const FileNameContainer = styled.span`
  position: relative;
  cursor: pointer;
  & svg {
    display: none;
    fill: ${({ theme }) => theme.grey};
    position: absolute;
    top: 0;
    right: -22px;
    & > path {
      fill: ${({ theme }) => theme.grey};
    }
  }
  &:hover {
    background-color: ${({ theme }) => theme.copyTextHover};
    border-radius: 5px;
    & svg {
      display: initial;
    }
  }
`;
