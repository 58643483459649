import React from 'react';

function Export() {
  return (
    <svg width="20" height="18" viewBox="0 0 20 18" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 2.22203C0 0.996441 0.996441 0 2.22203 0H7.7771V4.44406C7.7771 5.05859 8.27359 5.55507 8.88812 5.55507H13.3322V9.99913H7.49935C7.03758 9.99913 6.66609 10.3706 6.66609 10.8324C6.66609 11.2942 7.03758 11.6657 7.49935 11.6657H13.3322V15.5542C13.3322 16.7798 12.3357 17.7762 11.1101 17.7762H2.22203C0.996441 17.7762 0 16.7798 0 15.5542V2.22203ZM13.3322 11.6657V9.99913H17.1548L15.8007 8.64508C15.4744 8.31872 15.4744 7.79099 15.8007 7.4681C16.1271 7.14521 16.6548 7.14174 16.9777 7.4681L19.7552 10.2456C20.0816 10.572 20.0816 11.0997 19.7552 11.4226L16.9777 14.2002C16.6513 14.5265 16.1236 14.5265 15.8007 14.2002C15.4778 13.8738 15.4744 13.3461 15.8007 13.0232L17.1548 11.6691H13.3322V11.6657ZM13.3322 4.44406H8.88812V0L13.3322 4.44406Z" />
    </svg>
  );
}

export default Export;
