import styled from 'styled-components';

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ThirdPageContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
  width: 600px;
  max-height: calc(100vh - 335px);
  gap: 5px;
`;

export const ColumnContainer = styled(Column)`
  display: flex;
  flex-direction: column;
  padding: 0 0.5rem;
  width: 100%;

  & .label {
    font-size: .95rem;
    font-weight: bold;
    display: block;
  }
`;

export const BadgersContainer = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.containerOdd};
  padding: 5px;
  border-radius: 8px;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5px;
  font-size: .9rem;
  align-items: center;
  & span {
    color: ${({ theme }) => theme.color};
    font-weight: bold;
  }
  & button {
    min-width: auto;
    font-size: 10px;
  }

  & .badgersHeader { 
    width: 100%;
    display: flex;
    flex-direction: row;
    position: relative;

    & button {
      position: absolute;
      right: 0;
      top: 0;
    }
  }
`;

export const ScrollContainer = styled.div`
  overflow: hidden auto;
  padding: 5px;
  gap: 10px;
  display: flex;
  flex-direction: column;
`;

export const OptionalLabel = styled.span`
  padding-left: 0.5rem;
  font-weight: bold;
  font-size: 14px;
`;
