import styled from 'styled-components';

const StyledIframe = styled.iframe`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  position: absolute;
  border: 0px;
`;

export default StyledIframe;
