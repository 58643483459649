/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import DataTable from '../../../core/DataTable';

import { genDataSubset, dataSort } from '../../utils/formatCharts/handleData';

const SubsetTable = ({
  data, styleConfig, globalTheme,
}) => {
  const [tableData, setTableData] = useState({ ready: false });

  useEffect(() => {
    setTableData(genDataSubset(data, styleConfig));
  }, [data, styleConfig?.ValueFormatControl]);

  useEffect(() => {
    if (tableData.ready) {
      setTableData((dt) => ({
        ...dt,
        cols: dataSort(tableData.cols, styleConfig),
      }));
    }
  }, [
    ...styleConfig.CustomSortControl.kpiCategories,
    styleConfig.SortValuesControl,
    tableData.ready,
  ]);

  return tableData.ready ? (
    <div
      style={{ padding: '0 5px 5px 5px', position: 'relative', height: '100%' }}
      className="tableWrapper"
    >
      <div style={{ height: '100%', overflow: 'auto' }}>
        <DataTable
          columns={tableData.cols ?? []}
          rowData={tableData.rows ?? []}
          tableStyle={styleConfig}
          headerColor={globalTheme?.useGlobalTheme ? globalTheme.tableHeader : styleConfig.HeaderColor ?? 'white'}
          rowColor={globalTheme?.useGlobalTheme ? globalTheme.tableRows[0] : styleConfig.RowColor ?? 'white'}
          strippedRowsColor={globalTheme?.useGlobalTheme ? globalTheme.tableRows[1] : (
            styleConfig.LineColorControl === 'striped' ? (
              styleConfig.StripedRowsColor || 'white'
            ) : (
              styleConfig.RowColor || 'white'
            )
          )}
          useNumSorting
          compact
        />
      </div>
    </div>
  ) : <></>;
};

SubsetTable.propTypes = {
  data: PropTypes.shape({
    total_pages: PropTypes.number,
  }),
  styleConfig: PropTypes.shape({
    HeaderColor: PropTypes.string,
    RowColor: PropTypes.string,
    LineColorControl: PropTypes.string,
    StripedRowsColor: PropTypes.string,
    ValueFormatControl: PropTypes.string,
    CustomSortControl: PropTypes.shape({
      kpiCategories: PropTypes.arrayOf(PropTypes.string),
    }),
    SortValuesControl: PropTypes.string,
  }),
  globalTheme: PropTypes.shape({
    useGlobalTheme: PropTypes.bool,
    tableHeader: PropTypes.string,
    tableRows: PropTypes.arrayOf(PropTypes.string),
  }),
};

SubsetTable.defaultProps = {
  data: {},
  styleConfig: {},
  globalTheme: {},
};

export default SubsetTable;
