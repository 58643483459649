import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex-wrap: wrap;
  background-color: ${({ background }) => background || '#FFF'}
`;

const ResponsiveValue = styled.span`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  word-break: break-word;
  text-align: center;
  ${({
    fontSize, color, fontFamily, bold, underline, italic,
  }) => css`
    font-size: ${fontSize}px;
    color: ${color};
    font-family: '${fontFamily}', Arial;
    ${bold && 'font-weight: bold'};
    ${underline && `text-decoration: underline ${color}`};
    ${italic && 'font-style: italic'};
  `}
`;

export default ResponsiveValue;
