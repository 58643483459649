import React from 'react';
import PropTypes from 'prop-types';

function Key({ width, height }) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.75 16.5C20.3062 16.5 24 12.8062 24 8.25C24 3.69375 20.3062 0 15.75 0C11.1938 0 7.5 3.69375 7.5 8.25C7.5 9.12656 7.63594 9.975 7.88906 10.7672L0.328125 18.3281C0.117188 18.5391 0 18.825 0 19.125V22.875C0 23.4984 0.501562 24 1.125 24H4.875C5.49844 24 6 23.4984 6 22.875V21H7.875C8.49844 21 9 20.4984 9 19.875V18H10.875C11.175 18 11.4609 17.8828 11.6719 17.6719L13.2328 16.1109C14.025 16.3641 14.8734 16.5 15.75 16.5ZM17.625 8.25C16.5891 8.25 15.75 7.41094 15.75 6.375C15.75 5.33906 16.5891 4.5 17.625 4.5C18.6609 4.5 19.5 5.33906 19.5 6.375C19.5 7.41094 18.6609 8.25 17.625 8.25Z" />
    </svg>
  );
};

Key.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

Key.defaultProps = {
  width: '20px',
  height: '20px',
};

export default Key;
