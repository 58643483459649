import styled from 'styled-components';

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

export const PreviewContainer = styled(Column)`
  height: 65vh;
  width: 60vw;
  padding: 0 1rem 0 .5rem;
`;

export const MainContainer = styled(Row)``;

export const KpiWrapper = styled.div`
  display: flex;
  position: relative;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  overflow: hidden;
  & > svg {
    fill: ${({ theme }) => theme.grey};
    height: 50%;
    width: 50%;
  }
`;

export const SideBar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: 65vh;
  overflow-y: auto;
  width: 22vw;
  max-width: 345px;
  min-width: 245px;
  overflow: hidden;
  & .mainTabs {
    width: 100%;
    overflow: hidden;
    & .tab-content {
      height: calc(100% - 30px);
    }
  }
`;

export const TabContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  box-sizing: border-box;
`;

export const InputWrapper = styled.div`
  width: 100%;
`;

export const GenButtonActions = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 5px;
  margin: 10px auto;

  & > button {
    min-width: 0px;
    align-items: center;
    white-space: nowrap;

    & svg {
      margin-left: auto;
      fill: white;
      width: 20px;
      height: 20px;
      min-width: 20px;
      min-height: 20px;
      padding-left: 5px;
    }
  }
`;

export const InputDoubleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 15px;
  border-left: 5px solid ${({ theme }) => theme.containerLine};
  background-color: ${({ theme }) => theme.container};
  & > div:first-child {
    margin-bottom: 10px;
  }
  &:nth-child(odd) {
    background-color: ${({ theme }) => theme.containerOdd};
    border-left-color: ${({ theme }) => theme.containerLineOdd};
  }
  &.disabled {
    opacity: 0.3;
    pointer-events: none;
    cursor: default;
  }
`;

export const GenChartAlert = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: ${({ theme }) => theme.fadedBackground};
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  padding-top: 10%;
  box-sizing: border-box;
  color: ${({ theme }) => theme.white};
`;

export const IconCircle = styled.div`
  display: flex;
  width: 100px;
  height: 100px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  padding: 2.5px;
  background-clip: content-box;
  border: 2.5px solid #ff8800;
  background-color: #ff8800;
  & svg {
    color: ${({ theme }) => theme.white};
    width: 55px;
    height: 55px;
  }
`;

export const ModifColumn = styled.div`
  display: flex;
  flex-direction: column;
  & > * + * {
    margin-top: 10px;
  }
`;

export const ModifRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  position: relative;
  & > * + * {
    margin-left: 5px;
  }
  & > div {
    width: 100%;
    max-width: 100%;
    min-width: calc(50% - 5px);
  }
`;

export const ModifsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  & > * + * {
    margin-top: 10px;
  }
`;

export const LabelSmall = styled.span`
  font-weight: bold;
  color: ${({ theme }) => theme.primary};
  margin: 0;
  font-size: 14px;
`;

export const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
