/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-param-reassign */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import Select from '../../../juristec-ui/core/Select';
import Button from '../../../juristec-ui/core/Button';
import Tooltip from '../../../juristec-ui/core/Tooltip';
import IconButton from '../../../juristec-ui/core/IconButton';
import ActionsGroup from '../../../juristec-ui/core/ActionsGroup';
import InputTextLine from '../../../juristec-ui/core/InputTextLine';

import { filterTypeOptionsValues } from '../../../options';
import { Reverse, Trash } from '../../../juristec-ui/icons';

import {
  MainContainer,
  ModContainer,
  ModHeader,
  TabGroup,
  TabButton,
  SeparatorLine,
} from './styled/ModifierByValue.styled';

const ModifierByValue = ({
  lineName, columnName, filtersList, groupList, closeModal, submitData,
}) => {
  const isDirtyRef = useRef(false);
  const [isLineFirstF, toggleIsLineFirstF] = useState(true);
  const [tab, setTab] = useState('filter');
  const [filters, setFilters] = useState({
    line: {
      ftype: 'none',
      value1: 0,
      value2: 0,
    },
    column: {
      ftype: 'none',
      value1: 0,
      value2: 0,
    },
  });
  const [group, setGroup] = useState({
    line: {
      ftype: 'none',
      value1: 0,
      value2: 0,
      rule: '',
    },
    column: {
      ftype: 'none',
      value1: 0,
      value2: 0,
      rule: '',
    },
  });

  const getTempTypeFromInverse = (ftype, inverse) => (inverse ? `not-${ftype}` : ftype);

  useEffect(() => {
    if (filtersList?.length > 0) {
      setFilters((old) => ({
        ...old,
        ...filtersList.reduce((aux, f, i) => {
          const varKey = f.variable || 'line';
          if (i === 0) {
            toggleIsLineFirstF(varKey === 'line');
          }
          aux[varKey] = {
            ftype: getTempTypeFromInverse(f.ftype || 'none', f.inverse),
            value1: f.values[0] || 0,
            value2: f.values[1] || 0,
          };
          return aux;
        }, {}),
      }));
    }
    if (groupList?.length > 0) {
      setGroup((old) => ({
        ...old,
        ...groupList.reduce((aux, g) => {
          const varKey = g.variable || 'line';
          aux[varKey] = {
            ftype: getTempTypeFromInverse(g.ftype || 'none', g.inverse),
            value1: g.values[0] || 0,
            value2: g.values[1] || 0,
            rule: g.rule || '',
          };
          return aux;
        }, {}),
      }));
    }
  }, []);

  const handleChange = (setter, variable, value, key) => {
    isDirtyRef.current = true;
    setter((fOld) => {
      const auxOld = { ...fOld };
      if (key) {
        auxOld[variable][key] = value;
      } else {
        auxOld[variable] = {
          ...auxOld[variable],
          ...value,
        };
      }
      return auxOld;
    });
  };

  const formatVal = (setter, state, variable, key, min) => {
    let value = state[variable][key].toString().replace(',', '.');
    const numericVal = (Number(value) || 0);
    if (value && !Number.isNaN(+value)) {
      value = Math.max(numericVal, min);
    } else {
      value = min;
    }
    handleChange(setter, variable, value, key);
  };

  const handleVal = (setter, variable, key, value = '', precision) => {
    const regex = new RegExp(`^-{0,1}\\d*${precision > 0 ? `,{0,1}\\d{0,${precision}}` : ''}`);
    [value] = value.toString().match(regex);
    handleChange(setter, variable, value, key);
  };

  const validadeVals = (state) => {
    let isValid = true;
    switch (state.line.ftype) {
      case 'none':
        break;
      case 'between_inc':
      case 'between':
        if (state.line.rule === undefined || state.line.rule.length > 0) {
          isValid = state.line.value2 > state.line.value1;
        } else {
          isValid = false;
        }
        break;
      default:
        if (state.line.rule === undefined || state.line.rule.length > 0) {
          isValid = !!Number(state.line.value1);
        } else {
          isValid = false;
        }
        break;
    }
    if (columnName && isValid) {
      switch (state.column.ftype) {
        case 'none':
          break;
        case 'between_inc':
        case 'between':
          if (state.column.rule === undefined || state.column.rule.length > 0) {
            isValid = state.column.value2 > state.column.value1;
          } else {
            isValid = false;
          }
          break;
        default:
          if (state.column.rule === undefined || state.column.rule.length > 0) {
            isValid = !!Number(state.column.value1);
          } else {
            isValid = false;
          }
          break;
      }
    }
    return isValid;
  };

  const getActualTypeAndInverse = (ftype) => {
    const ftypeInfo = ftype.split('-');
    const actualFtype = ftypeInfo?.[1] || ftypeInfo?.[0];
    return [
      ftypeInfo?.[0] === 'not',
      actualFtype,
    ];
  };

  const formatResult = (state, orderState, isGroup) => {
    const resList = [];
    if (state.line.ftype !== 'none') {
      const [isInverse, actualType] = getActualTypeAndInverse(state.line.ftype);
      const lineObj = {
        column: lineName,
        variable: 'line',
        ftype: actualType,
        inverse: isInverse,
      };
      if (actualType === 'between' || actualType === 'between_inc') {
        lineObj.values = [state.line.value1, state.line.value2];
      } else {
        lineObj.values = [state.line.value1];
      }
      if (isGroup) lineObj.rule = state.line.rule;
      resList.push(lineObj);
    }
    if (columnName && state.column.ftype !== 'none') {
      const [isInverse, actualType] = getActualTypeAndInverse(state.column.ftype);
      const columnObj = {
        column: columnName,
        variable: 'column',
        ftype: actualType,
        inverse: isInverse,
      };
      if (actualType === 'between' || actualType === 'between_inc') {
        columnObj.values = [state.column.value1, state.column.value2];
      } else {
        columnObj.values = [state.column.value1];
      }
      if (isGroup) columnObj.rule = state.column.rule;
      if (orderState) {
        resList.push(columnObj);
      } else {
        resList.unshift(columnObj);
      }
    }
    return resList;
  };

  const handleSubmit = () => {
    if (!isDirtyRef.current) closeModal();
    const filterResult = formatResult(filters, isLineFirstF, false);
    const groupResult = formatResult(group, true, true);
    submitData(filterResult, groupResult);
  };

  const getInputs = (variable, setter, state) => {
    const ftypeSelected = state[variable].ftype;
    if (['between', 'between_inc', 'not-between', 'not-between_inc'].includes(ftypeSelected)) {
      return (
        <>
          <InputTextLine
            label="Valor de A"
            value={state[variable].value1.toString().replace('.', ',')}
            onChange={(e) => handleVal(setter, variable, 'value1', e.target.value, 2)}
            onBlur={() => formatVal(setter, state, variable, 'value1', -Infinity)}
          />
          <InputTextLine
            label="Valor de B"
            value={state[variable].value2.toString().replace('.', ',')}
            onChange={(e) => handleVal(setter, variable, 'value2', e.target.value, 2)}
            onBlur={() => formatVal(setter, state, variable, 'value2', -Infinity)}
            fullWidth
          />
        </>
      );
    }
    return (
      <InputTextLine
        label="Valor de N"
        value={state[variable].value1.toString().replace('.', ',')}
        onChange={(e) => handleVal(
          setter,
          variable,
          'value1',
          e.target.value,
          ['greater', 'lesser', 'greater_inc', 'lesser_inc'].includes(ftypeSelected) ? 2 : 0,
        )}
        onBlur={() => formatVal(
          setter,
          state,
          variable,
          'value1',
          ['greater', 'lesser', 'greater_inc', 'lesser_inc'].includes(ftypeSelected) ? -Infinity : 0,
        )}
        fullWidth
      />
    );
  };

  return (
    <>
      <TabGroup>
        <TabButton active={tab === 'filter'} onClick={() => setTab('filter')}>
          Filtro
        </TabButton>
        <TabButton active={tab === 'group'} onClick={() => setTab('group')}>
          Agrupamento
        </TabButton>
      </TabGroup>
      {tab === 'filter' ? (
        <MainContainer flip={isLineFirstF}>
          <ModContainer style={{ order: isLineFirstF ? 1 : 3 }}>
            <span className="mod-var-label">Linha</span>
            <ModHeader>
              <span className="mod-var">{`${isLineFirstF ? 1 : 2}°. ${lineName}`}</span>
              {filters.line.ftype !== 'none' && (
                <Tooltip text="Remover filtro" atModal>
                  <IconButton
                    size="small"
                    onClick={() => handleChange(setFilters, 'line', { ftype: 'none', value1: 0, value2: 0 })}
                  >
                    <Trash />
                  </IconButton>
                </Tooltip>
              )}
            </ModHeader>
            <Select
              selectLabel="Tipo de filtro"
              placeholder="Selecione"
              options={filterTypeOptionsValues}
              value={{ label: filters.line.ftype, value: filters.line.ftype }}
              onChange={(selected) => handleChange(setFilters, 'line', { ftype: selected.value, value1: 0, value2: 0 })}
              fullWidth
              atModal
            />
            {filters.line.ftype !== 'none' && (
              <div style={{
                flexDirection: 'row', display: 'flex', gap: '5px', width: '100%',
              }}
              >
                {getInputs('line', setFilters, filters)}
              </div>
            )}
          </ModContainer>
          {columnName && (
            <>
              <div style={{ order: 2 }}>
                <Tooltip text="Inverter ordem" atModal>
                  <IconButton key="filter-flipper" onClick={() => toggleIsLineFirstF((p) => !p)} className="reverse-icon">
                    <Reverse />
                  </IconButton>
                </Tooltip>
              </div>
              <ModContainer style={{ order: isLineFirstF ? 3 : 1 }}>
                <span className="mod-var-label">Coluna</span>
                <ModHeader>
                  <span className="mod-var">{`${isLineFirstF ? 2 : 1}°. ${columnName}`}</span>
                  {filters.column.ftype !== 'none' && (
                    <Tooltip text="Remover filtro" atModal>
                      <IconButton
                        size="small"
                        onClick={() => handleChange(setFilters, 'column', { ftype: 'none', value1: 0, value2: 0 })}
                      >
                        <Trash />
                      </IconButton>
                    </Tooltip>
                  )}
                </ModHeader>
                <Select
                  selectLabel="Tipo de filtro"
                  placeholder="Selecione"
                  options={filterTypeOptionsValues}
                  value={{ label: filters.column.ftype, value: filters.column.ftype }}
                  onChange={(selected) => handleChange(setFilters, 'column', { ftype: selected.value, value1: 0, value2: 0 })}
                  fullWidth
                  atModal
                />
                {filters.column.ftype !== 'none' && (
                  <div style={{
                    flexDirection: 'row', display: 'flex', gap: '5px', width: '100%',
                  }}
                  >
                    {getInputs('column', setFilters, filters)}
                  </div>
                )}
              </ModContainer>
            </>
          )}
        </MainContainer>
      ) : (
        <MainContainer>
          <ModContainer>
            <span className="mod-var-label">Linha</span>
            <ModHeader>
              <span className="mod-var">{lineName}</span>
              {group.line.ftype !== 'none' && (
                <Tooltip text="Remover agrupamento" atModal>
                  <IconButton
                    size="small"
                    onClick={() => handleChange(setGroup, 'line', { ftype: 'none', value1: 0, value2: 0 })}
                  >
                    <Trash />
                  </IconButton>
                </Tooltip>
              )}
            </ModHeader>
            <Select
              selectLabel="Tipo de agrupamento"
              placeholder="Selecione"
              options={filterTypeOptionsValues}
              value={{ label: group.line.ftype, value: group.line.ftype }}
              onChange={(selected) => handleChange(setGroup, 'line', { ftype: selected.value, value1: 0, value2: 0 })}
              fullWidth
              atModal
            />
            {group.line.ftype !== 'none' && (
              <>
                <div style={{
                  flexDirection: 'row', display: 'flex', gap: '5px', width: '100%',
                }}
                >
                  {getInputs('line', setGroup, group)}
                </div>
                <InputTextLine
                  label="Nome"
                  value={group.line.rule}
                  onChange={(e) => handleChange(setGroup, 'line', e.target.value, 'rule')}
                  onBlur={(e) => handleChange(setGroup, 'line', e.target.value.split(' ').filter((v) => v).join(' '), 'rule')}
                  fullWidth
                />
              </>
            )}
          </ModContainer>
          {columnName && (
            <>
              <SeparatorLine />
              <ModContainer>
                <span className="mod-var-label">Coluna</span>
                <ModHeader>
                  <span className="mod-var">{columnName}</span>
                  {group.column.ftype !== 'none' && (
                    <Tooltip text="Remover agrupamento" atModal>
                      <IconButton
                        size="small"
                        onClick={() => handleChange(setGroup, 'column', { ftype: 'none', value1: 0, value2: 0 })}
                      >
                        <Trash />
                      </IconButton>
                    </Tooltip>
                  )}
                </ModHeader>
                <Select
                  selectLabel="Tipo de agrupamento"
                  placeholder="Selecione"
                  options={filterTypeOptionsValues}
                  value={{ label: group.column.ftype, value: group.column.ftype }}
                  onChange={(selected) => handleChange(setGroup, 'column', { ftype: selected.value, value1: 0, value2: 0 })}
                  fullWidth
                  atModal
                />
                {group.column.ftype !== 'none' && (
                  <>
                    <div style={{
                      flexDirection: 'row', display: 'flex', gap: '5px', width: '100%',
                    }}
                    >
                      {getInputs('column', setGroup, group)}
                    </div>
                    <InputTextLine
                      label="Nome"
                      value={group.column.rule}
                      onChange={(e) => handleChange(setGroup, 'column', e.target.value, 'rule')}
                      onBlur={(e) => handleChange(setGroup, 'column', e.target.value.split(' ').filter((v) => v).join(' '), 'rule')}
                      fullWidth
                    />
                  </>
                )}
              </ModContainer>
            </>
          )}
        </MainContainer>
      )}
      <ActionsGroup>
        <Button
          style={{ margin: '5px' }}
          onClick={closeModal}
          variant="outlined"
          size="large"
        >
          Cancelar
        </Button>
        <Button
          style={{ margin: '5px' }}
          onClick={handleSubmit}
          size="large"
          disabled={!validadeVals(filters) || !validadeVals(group)}
        >
          Salvar
        </Button>
      </ActionsGroup>
    </>

  );
};

ModifierByValue.propTypes = {
  lineName: PropTypes.string,
  columnName: PropTypes.string,
  filtersList: PropTypes.arrayOf(PropTypes.any),
  groupList: PropTypes.arrayOf(PropTypes.any),
  closeModal: PropTypes.func,
  submitData: PropTypes.func,
};

ModifierByValue.defaultProps = {
  lineName: '',
  columnName: '',
  filtersList: [],
  groupList: [],
  closeModal: () => {},
  submitData: () => {},
};

export default ModifierByValue;
