import styled from 'styled-components';

const StyledList = styled.ul`
  list-style: none;
  padding: 0px;
  margin: 0px;
  background-color: ${({ theme }) => theme.selectOdd};
  border-radius: 8px;
  user-select: none;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export default StyledList;
