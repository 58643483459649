import React from 'react';
import PropTypes from 'prop-types';
import Loading from '../MiniLoading';
import { StyledListItem, LoadingContainer } from './styled/ListItem.styled';

// import { Container } from './styles';

function ListItem({
  children,
  active,
  closeIconStyle,
  noOutline,
  withoutIconStyle,
  disabled,
  isLoading,
  ...props
}) {
  return (
    <StyledListItem
      noOutline={noOutline}
      active={active}
      withoutIconStyle={withoutIconStyle}
      closeIconStyle={closeIconStyle}
      disabled={disabled || isLoading}
      {...props}
    >
      {children}
      {isLoading && (
        <LoadingContainer>
          <Loading />
        </LoadingContainer>
      )}
    </StyledListItem>
  );
}

ListItem.propTypes = {
  /**
  * ---
  */
  active: PropTypes.bool,
  /**
  * List content
  */
  children: PropTypes.node.isRequired,
  /**
  * ---
  */
  closeIconStyle: PropTypes.bool,
  /**
  * Draw a line outside the element
  */
  noOutline: PropTypes.bool,
  /**
  * signals if the component will receive an icon and changes svg props
  */
  withoutIconStyle: PropTypes.bool,
};

ListItem.defaultProps = {
  active: false,
  closeIconStyle: false,
  noOutline: false,
  withoutIconStyle: false,
  isLoading: false,
};

export default ListItem;
