import React from 'react';

const ChartCalendar = ({ primary = '#4711B2', secondary = '#FF9626' }) => (
  <svg width="64" height="59" viewBox="0 0 64 59" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M62 2H2V12H62V2Z" fill={secondary} stroke={secondary} strokeWidth="4" />
    <path d="M19.1429 47.8571V13.5714M19.1429 47.8571H10.5714M19.1429 47.8571H27.7143M19.1429 47.8571V56.4286H10.5714M19.1429 13.5714H10.5714M19.1429 13.5714H27.7143M10.5714 47.8571V39.2857M10.5714 47.8571H2M10.5714 47.8571V56.4286M10.5714 13.5714V22.1429M10.5714 13.5714H2V22.1429M27.7143 13.5714V47.8571M27.7143 13.5714H36.2857M27.7143 47.8571H36.2857M36.2857 13.5714V47.8571M36.2857 13.5714H44.8571M36.2857 47.8571H44.8571M44.8571 13.5714V47.8571M44.8571 13.5714H53.4286M44.8571 47.8571H53.4286M53.4286 13.5714V22.1429M53.4286 13.5714H62V22.1429M53.4286 47.8571V39.2857M53.4286 47.8571H62V39.2857M53.4286 22.1429V30.7143M53.4286 22.1429H10.5714M53.4286 22.1429H62M53.4286 39.2857H10.5714M53.4286 39.2857V30.7143M53.4286 39.2857H62M10.5714 39.2857V30.7143M10.5714 39.2857H2M10.5714 30.7143H53.4286M10.5714 30.7143V22.1429M10.5714 30.7143H2M53.4286 30.7143H62M10.5714 22.1429H2M62 22.1429V30.7143M62 39.2857V30.7143M2 47.8571V39.2857M2 47.8571V56.4286H10.5714M2 22.1429V30.7143M2 30.7143V39.2857" stroke={primary} strokeWidth="4" />
  </svg>
);

export default ChartCalendar;
