/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';

import Toggle from '../../../../juristec-ui/core/Toggle';
import InputNumberLine from '../../../../juristec-ui/core/InputNumberLine';
import Select from '../../../../juristec-ui/core/Select';

import { frequencyAntecipeiOpt, courtsAntecipeiOpt, weekDayAntecipeiOpt } from '../../../../options';

import {
  InputWrapper,
  SideBySideContainer,
} from './styled/NewInstance.styled';

const ThirdStep = ({
  hasPushState,
  antecipeiValueState,
  numberOfCompaniesState,
  frequencyState,
  courtsState,
  weekDayAntecipeiState,
  limitLegalSuitsState,
  aditionalLegalSuitPriceState,
}) => {
  // Estados que contem o que sera enviado (declarados no componente pai)
  const [hasPush, setHasPush] = hasPushState;
  const [antecipeiValue, setAntecipeiValue] = antecipeiValueState;
  const [numberOfCompanies, setNumberOfCompanies] = numberOfCompaniesState;
  const [frequency, setFrequency] = frequencyState;
  const [courts, setCourts] = courtsState;
  const [weekDayAntecipei, setWeekDayAntecipei] = weekDayAntecipeiState;
  const [limitLegalSuits, setLimitLegalSuits] = limitLegalSuitsState;
  const [aditionalLegalSuitPrice, setAditionalLegalSuitPrice] = aditionalLegalSuitPriceState;

  /**
   * Ativa ou desativa o serviço do push na instancia
   */
  const togglePush = () => {
    const newValue = !hasPush;
    setHasPush((p) => !p);
    if (!newValue) {
      setAntecipeiValue(0);
      setNumberOfCompanies(0);
      setFrequency({
        value: '',
        label: '',
      });
      setCourts({
        value: '',
        label: '',
      });
      setLimitLegalSuits(0);
      setAditionalLegalSuitPrice(0);
    }
  };

  /**
   * Lida com o select de frequência de monitoramento do antecipei
   * @param {event} e Evento de alteracao do input
   */
  const handleFrequency = (select) => {
    setFrequency(select);
  };

  /**
   * Lida com o select de tribunais de monitoramento do antecipei
   * @param {event} e Evento de alteracao do input
   */
  const handleCourts = (select) => {
    setCourts(select);
  };

  /**
   * Lida com o select o dia de monitoramento do antecipei
   * @param {event} e Evento de alteracao do input
   */
  const handleWeekDay = (select) => {
    setWeekDayAntecipei(select);
  };

  return (
    <>
      <SideBySideContainer>
        <InputWrapper className="toggle">
          <span className="custom-label">Cliente possui Antecipei</span>
          <Toggle
            checked={hasPush}
            handleChange={togglePush}
            firstColor="primary"
            size="small"
          />
        </InputWrapper>
        <InputWrapper className="inline">
          <span className="custom-label">Valor Antecipei</span>
          <InputNumberLine
            value={antecipeiValue}
            onChange={(e) => setAntecipeiValue(e.target.value)}
            label=""
            setValue={setAntecipeiValue}
            min={0}
            numContainerStyle={{ width: '50%' }}
            isMoney
            precision={2}
            disabled={!hasPush}
          />
        </InputWrapper>
      </SideBySideContainer>
      <SideBySideContainer>
        <InputWrapper className="inline">
          <span className="custom-label">Número de CNPJs monitorados</span>
          <InputNumberLine
            value={numberOfCompanies}
            onChange={(e) => setNumberOfCompanies(e.target.value)}
            label=""
            setValue={setNumberOfCompanies}
            min={0}
            numContainerStyle={{ width: '50%' }}
            disabled={!hasPush}
          />
        </InputWrapper>
        <InputWrapper className="inline">
          <span className="custom-label">Limite de Processos (Franquia)</span>
          <InputNumberLine
            value={limitLegalSuits}
            onChange={(e) => setLimitLegalSuits(e.target.value)}
            label=""
            setValue={setLimitLegalSuits}
            min={0}
            numContainerStyle={{ width: '50%' }}
            disabled={!hasPush}
          />
        </InputWrapper>
      </SideBySideContainer>
      <SideBySideContainer>
        <InputWrapper>
          <span className="custom-label">Frequência de busca</span>
          <Select
            placeholder="Selecionar frequência"
            placement="start"
            onChange={handleFrequency}
            formatOptionLabel={false}
            options={frequencyAntecipeiOpt}
            value={frequency}
            fullWidth
            fullHeight
            atModal
            style={{ margin: 0 }}
            disabled={!hasPush}
          />
        </InputWrapper>
        <InputWrapper>
          <span className="custom-label">Tribunais</span>
          <Select
            placeholder="Selecionar tribunais"
            placement="start"
            onChange={handleCourts}
            formatOptionLabel={false}
            options={courtsAntecipeiOpt}
            value={courts}
            fullWidth
            fullHeight
            atModal
            style={{ margin: 0 }}
            disabled={!hasPush}
          />
        </InputWrapper>
      </SideBySideContainer>
      <SideBySideContainer>
        <InputWrapper>
          <span className="custom-label">Dia do Monitoramento</span>
          <Select
            placeholder="Selecionar dia"
            placement="start"
            onChange={handleWeekDay}
            formatOptionLabel={false}
            options={weekDayAntecipeiOpt}
            value={weekDayAntecipei}
            fullWidth
            fullHeight
            atModal
            style={{ margin: 0 }}
            disabled={!hasPush}
          />
        </InputWrapper>
        <InputWrapper className="inline">
          <span className="custom-label">Valor por processo excedente</span>
          <InputNumberLine
            value={aditionalLegalSuitPrice}
            onChange={(e) => setAditionalLegalSuitPrice(e.target.value)}
            label=""
            setValue={setAditionalLegalSuitPrice}
            min={0}
            numContainerStyle={{ width: '50%' }}
            isMoney
            precision={2}
            disabled={!hasPush}
          />
        </InputWrapper>
      </SideBySideContainer>
    </>
  );
};

ThirdStep.propTypes = {
  hasPushState: PropTypes.arrayOf(PropTypes.any).isRequired,
  antecipeiValueState: PropTypes.arrayOf(PropTypes.any).isRequired,
  numberOfCompaniesState: PropTypes.arrayOf(PropTypes.any).isRequired,
  frequencyState: PropTypes.arrayOf(PropTypes.any).isRequired,
  courtsState: PropTypes.arrayOf(PropTypes.any).isRequired,
  weekDayAntecipeiState: PropTypes.arrayOf(PropTypes.any).isRequired,
  limitLegalSuitsState: PropTypes.arrayOf(PropTypes.any).isRequired,
  aditionalLegalSuitPriceState: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default ThirdStep;
