import React from 'react';
import PropTypes from 'prop-types';

import { trimString } from '../../../../juristec-ui/utils/functions/formatString';

import Select from '../../../../juristec-ui/core/SelectNew';
import IconButton from '../../../../juristec-ui/core/IconButton';
import DatePicker from '../../../../juristec-ui/core/DatePicker';
import InputNumberLine from '../../../../juristec-ui/core/InputNumberLine';
import Tooltip from '../../../../juristec-ui/core/Tooltip';
import InputTextLine from '../../../../juristec-ui/core/InputTextLine';

import { Add, Close } from '../../../../juristec-ui/icons';

import {
  MainContainer,
  GridContainer,
  ItemWrapper,
  Description,
  OperandsList,
  FormulaWrapper,
  ItemRowContainer,
} from './styled/FirstStep.styled';

const FirstStep = ({
  opOpts,
  columnOpts,
  editMode,
  tmpInputTypeOpts,
  selectedOpState,
  tmpInputTypeState,
  tmpInputValueState,
  selectedInputsState,
  columnNameState,
}) => {
  const [selectedOp, handleSelectedOp] = selectedOpState;
  const [tmpInputType, handleSelectedInputType] = tmpInputTypeState;
  const [tmpInputValue, handleSelectInput, handleStaticInput] = tmpInputValueState;
  const [selectedInputs, addInput, removeInput] = selectedInputsState;
  const [columnName, handleColumnName] = columnNameState;

  const getReadonlyText = () => {
    if (tmpInputTypeOpts?.length === 1) return 'Este tipo é obrigatório';
    if (tmpInputType?.value === 'TODAY') return 'Data de "HOJE" é a data que ocorre a atualização';
    if (selectedInputs?.size >= selectedOp?.maxInputs) return 'Máximo de operandos atingido';
    return 'Operação não selecionada';
  };

  return (
    <MainContainer>
      <GridContainer>
        <ItemWrapper
          className="cc-name"
          as={Tooltip}
          text="Não é permitido editar o nome de uma coluna já existente"
          textWhen={editMode}
          atModal
          direction="top"
        >
          <InputTextLine
            label="Nome da nova coluna"
            value={columnName.value}
            errorMessage={columnName.errorMsg}
            error={columnName.errorMsg.length > 0}
            onChange={(e) => handleColumnName(e.target.value)}
            onBlur={() => handleColumnName(trimString(columnName.value))}
            readOnly={editMode}
          />
        </ItemWrapper>
        <ItemWrapper className="cc-op">
          <Select
            label="Operação"
            value={selectedOp}
            options={opOpts}
            onChange={handleSelectedOp}
            atModal
            searchable
            sortBy="ascending"
          />
        </ItemWrapper>
        <Description className="cc-desc">
          {selectedOp?.description ?? ''}
        </Description>
        <ItemWrapper
          className="cc-optype"
          as={Tooltip}
          text={getReadonlyText()}
          textWhen={
            !selectedOp
            || tmpInputTypeOpts?.length === 1
            || selectedInputs?.size >= selectedOp?.maxInputs
          }
          atModal
          direction="top"
        >
          <Select
            label="Tipo do operando"
            value={tmpInputType}
            options={tmpInputTypeOpts}
            onChange={handleSelectedInputType}
            atModal
          />
        </ItemWrapper>
        <ItemWrapper
          className="cc-setter"
          as={Tooltip}
          text={getReadonlyText()}
          textWhen={
            tmpInputType?.value === 'TODAY'
            || selectedInputs?.size >= selectedOp?.maxInputs
          }
          atModal
          direction="top"
        >
          {tmpInputType?.value === 'COLUMN' && (
            <Select
              label="Coluna"
              value={tmpInputValue}
              options={columnOpts}
              onChange={handleSelectInput}
              atModal
              searchable
              sortBy="ascending"
            />
          )}
          {['TODAY', 'DATE'].includes(tmpInputType?.value) && (
            <DatePicker
              value={tmpInputValue.value}
              label="Data"
              onChange={handleStaticInput}
              dateFormat="dd-MM-yyyy"
              atModal
            />
          )}
          {tmpInputType?.value === 'NUMBER' && (
            <InputNumberLine
              label="Número"
              value={tmpInputValue.value}
              onChange={(e) => handleStaticInput(e.target.value)}
              setValue={handleStaticInput}
              min={-Infinity}
              max={Infinity}
              step={1}
              precision={selectedOp?.numberPrecision ?? 0}
              numContainerStyle={{ width: '100%' }}
            />
          )}
        </ItemWrapper>
        <ItemWrapper className="cc-add">
          <IconButton
            color="secondary"
            shape="rounded"
            variant="contained"
            onClick={addInput}
            disabled={selectedInputs?.size >= selectedOp?.maxInputs}
          >
            <Add />
          </IconButton>
        </ItemWrapper>
        <ItemRowContainer className="cc-inputs">
          <h5>Operandos</h5>
          <OperandsList>
            {selectedInputs?.size ? (
              [...selectedInputs.values()].map((sInpt, i) => (
                <li key={sInpt.id}>
                  <span>{sInpt.label}</span>
                  {!selectedOp?.strictOrder || selectedInputs.size - 1 === i ? (
                    <Tooltip text="Remover" atModal>
                      <IconButton
                        color="error"
                        shape="circle"
                        variant="contained"
                        size="small"
                        onClick={() => removeInput(sInpt.id)}
                      >
                        <Close />
                      </IconButton>
                    </Tooltip>
                  ) : <></>}
                </li>
              ))
            ) : (
              <li className="empty">...</li>
            )}
          </OperandsList>
        </ItemRowContainer>
        <ItemRowContainer className="cc-formula">
          <h5>Visualização da fórmula</h5>
          <FormulaWrapper>
            {selectedOp?.formulaView([...selectedInputs.values()])}
          </FormulaWrapper>
        </ItemRowContainer>
      </GridContainer>
    </MainContainer>
  );
};

FirstStep.propTypes = {
  opOpts: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    value: PropTypes.string,
    label: PropTypes.string,
  })),
  columnOpts: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    value: PropTypes.string,
    label: PropTypes.string,
  })),
  editMode: PropTypes.bool,
  tmpInputTypeOpts: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    value: PropTypes.string,
    label: PropTypes.string,
  })),
  selectedOpState: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.func, PropTypes.shape({}),
  ])),
  tmpInputTypeState: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.func, PropTypes.shape({}),
  ])),
  tmpInputValueState: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.func, PropTypes.shape({}),
  ])),
  selectedInputsState: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.func, PropTypes.shape({}),
  ])),
  columnNameState: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.func, PropTypes.shape({}),
  ])),
};

FirstStep.defaultProps = {
  opOpts: [],
  columnOpts: [],
  editMode: false,
  tmpInputTypeOpts: [],
  selectedOpState: [{}, () => {}],
  tmpInputTypeState: [{}, () => {}],
  tmpInputValueState: [{}, () => {}],
  selectedInputsState: [{}, () => {}, () => {}],
  columnNameState: [{}, () => {}],
};

export default FirstStep;
