import React from 'react';
import PropTypes from 'prop-types';

const VersionMergeDouble = ({ height, width }) => (
  <svg width={width} height={height} viewBox="0 0 25 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.75735 1.93015C2.97674 1.93015 3.18714 2.0173 3.34228 2.17243C3.49741 2.32756 3.58456 2.53796 3.58456 2.75735C3.58456 2.97674 3.49741 3.18714 3.34228 3.34228C3.18714 3.49741 2.97674 3.58456 2.75735 3.58456C2.53796 3.58456 2.32756 3.49741 2.17243 3.34228C2.0173 3.18714 1.93015 2.97674 1.93015 2.75735C1.93015 2.53796 2.0173 2.32756 2.17243 2.17243C2.32756 2.0173 2.53796 1.93015 2.75735 1.93015ZM3.87408 5.28033C4.83915 4.85294 5.51471 3.88442 5.51471 2.75735C5.51471 1.23392 4.28079 0 2.75735 0C1.23392 0 0 1.23392 0 2.75735C0 3.88787 0.678998 4.85983 1.65441 5.28378V12.3633C0.678998 12.7872 0 13.7592 0 14.8897C0 16.4131 1.23392 17.6471 2.75735 17.6471C4.28079 17.6471 5.51471 16.4131 5.51471 14.8897C5.51471 13.7592 4.83571 12.7872 3.86029 12.3633V9.375C4.78056 10.0678 5.92831 10.4779 7.16912 10.4779L19.9368 10.4412C20.3608 11.4166 21.3327 12.0956 22.4632 12.0956C23.9867 12.0956 24.9632 10.8617 24.9632 9.33824C24.9632 7.8148 23.9867 6.58088 22.4632 6.58088C21.3327 6.58088 20.3608 7.25988 19.9368 8.23529L7.16912 8.27206C5.44922 8.27206 4.03263 6.95887 3.87408 5.28033ZM2.75735 14.0625C2.97674 14.0625 3.18714 14.1497 3.34228 14.3048C3.49741 14.4599 3.58456 14.6703 3.58456 14.8897C3.58456 15.1091 3.49741 15.3195 3.34228 15.4746C3.18714 15.6298 2.97674 15.7169 2.75735 15.7169C2.53796 15.7169 2.32756 15.6298 2.17243 15.4746C2.0173 15.3195 1.93015 15.1091 1.93015 14.8897C1.93015 14.6703 2.0173 14.4599 2.17243 14.3048C2.32756 14.1497 2.53796 14.0625 2.75735 14.0625ZM21.636 9.33824C21.636 9.11885 21.7232 8.90844 21.8783 8.75331C22.0334 8.59818 22.2438 8.51103 22.4632 8.51103C22.6826 8.51103 22.893 8.59818 23.0482 8.75331C23.2033 8.90844 23.2904 9.11885 23.2904 9.33824C23.2904 9.55762 23.2033 9.76803 23.0482 9.92316C22.893 10.0783 22.6826 10.1654 22.4632 10.1654C22.2438 10.1654 22.0334 10.0783 21.8783 9.92316C21.7232 9.76803 21.636 9.55762 21.636 9.33824Z" />
    <path d="M2.75735 13.9301C2.97674 13.9301 3.18714 14.0173 3.34228 14.1724C3.49741 14.3276 3.58456 14.538 3.58456 14.7574C3.58456 14.9767 3.49741 15.1871 3.34228 15.3423C3.18714 15.4974 2.97674 15.5846 2.75735 15.5846C2.53796 15.5846 2.32756 15.4974 2.17243 15.3423C2.0173 15.1871 1.93015 14.9767 1.93015 14.7574C1.93015 14.538 2.0173 14.3276 2.17243 14.1724C2.32756 14.0173 2.53796 13.9301 2.75735 13.9301ZM3.87408 17.2803C4.83915 16.8529 5.51471 15.8844 5.51471 14.7574C5.51471 13.2339 4.28079 12 2.75735 12C1.23392 12 0 13.2339 0 14.7574C0 15.8879 0.678998 16.8598 1.65441 17.2838V24.3633C0.678998 24.7872 0 25.7592 0 26.8897C0 28.4131 1.23392 29.6471 2.75735 29.6471C4.28079 29.6471 5.51471 28.4131 5.51471 26.8897C5.51471 25.7592 4.83571 24.7872 3.86029 24.3633V21.375C4.78056 22.0678 5.92831 22.4779 7.16912 22.4779L19.9368 22.4412C20.3608 23.4166 21.3327 24.0956 22.4632 24.0956C23.9867 24.0956 24.9632 22.8617 24.9632 21.3382C24.9632 19.8148 23.9867 18.5809 22.4632 18.5809C21.3327 18.5809 20.3608 19.2599 19.9368 20.2353L7.16912 20.2721C5.44922 20.2721 4.03263 18.9589 3.87408 17.2803ZM2.75735 26.0625C2.97674 26.0625 3.18714 26.1497 3.34228 26.3048C3.49741 26.4599 3.58456 26.6703 3.58456 26.8897C3.58456 27.1091 3.49741 27.3195 3.34228 27.4746C3.18714 27.6298 2.97674 27.7169 2.75735 27.7169C2.53796 27.7169 2.32756 27.6298 2.17243 27.4746C2.0173 27.3195 1.93015 27.1091 1.93015 26.8897C1.93015 26.6703 2.0173 26.4599 2.17243 26.3048C2.32756 26.1497 2.53796 26.0625 2.75735 26.0625ZM21.636 21.3382C21.636 21.1188 21.7232 20.9084 21.8783 20.7533C22.0334 20.5982 22.2438 20.511 22.4632 20.511C22.6826 20.511 22.893 20.5982 23.0482 20.7533C23.2033 20.9084 23.2904 21.1188 23.2904 21.3382C23.2904 21.5576 23.2033 21.768 23.0482 21.9232C22.893 22.0783 22.6826 22.1654 22.4632 22.1654C22.2438 22.1654 22.0334 22.0783 21.8783 21.9232C21.7232 21.768 21.636 21.5576 21.636 21.3382Z" />
  </svg>
);

VersionMergeDouble.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
};

VersionMergeDouble.defaultProps = {
  height: '30px',
  width: '30px',
};

export default VersionMergeDouble;
