import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';
import DataTable from '../../juristec-ui/core/DataTable';
import { formatDateTime } from '../../juristec-ui/utils/functions/lab';
import Tooltip from '../../juristec-ui/core/Tooltip';
import Avatar from '../../juristec-ui/core/Avatar';
import Badge from '../../juristec-ui/core/Badge';
import UserOptions from '../UsersOptions';
import DataCards from '../../juristec-ui/core/DataCards';

import useDeviceType from '../../juristec-ui/hooks/useDeviceType';

import {
  UserInfoContainer,
  UserInfoText,
  UsersTable,
  UsersTableOverFlow,
} from './styled/UsersList.styled';

/**
 * A component that displays the users info and options
 */
const UsersList = ({
  users,
  currentUser,
  promoteUser,
  demoteUser,
  toggleUserState,
  deleteUser,
  openUserHistory,
}) => {
  const device = useDeviceType();
  const theme = useTheme();

  const DataListingComponent = device === 'phone' ? DataCards : DataTable;

  const moreOptions = (user) => (
    // TODO Alterar essa comparacao quando o historico for adicionado
    user.uid !== currentUser.uid ? (
      <Tooltip text="Mais opções">
        <UserOptions
          user={user}
          currentUser={currentUser}
          promoteUser={promoteUser}
          demoteUser={demoteUser}
          toggleUserState={toggleUserState}
          deleteUser={deleteUser}
          openUserHistory={openUserHistory}
        />
      </Tooltip>
    ) : <></>
  );

  const userInfo = (user) => (
    <UserInfoContainer>
      <Avatar name={user.name} src={user.photoUrl} size="medium" />
      <UserInfoText>
        <h4>{user.name}</h4>
        <span>{user.email}</span>
      </UserInfoText>
    </UserInfoContainer>
  );

  const loginDateTime = (datetime) => (
    datetime ? formatDateTime(datetime, { time: 'half' }) : 'Não consta'
  );

  const userState = (disabled) => (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Badge size="small" color={disabled ? 'error' : 'success'}>
        {disabled ? 'Inativo' : 'Ativo'}
      </Badge>
    </div>
  );

  const columns = [
    {
      field: 'name',
      role: 'primaryInfo',
      label: 'Usuário',
      sortable: true,
      valueGetter: (param) => userInfo(param),
    },
    {
      field: 'roleLabel',
      role: 'extraInfo',
      label: 'Permissão',
      sortable: true,
    },
    device === 'desktop' && currentUser.role !== 'scientist'
    && {
      field: 'scientistName',
      role: 'extraInfo',
      label: 'Cientista responsável',
      sortable: true,
    },
    {
      field: 'lastLogin',
      role: 'extraInfo',
      label: 'Último login',
      sortable: true,
      valueGetter: (param) => loginDateTime(param.lastLogin),
    },
    {
      field: 'disabled',
      role: 'extraInfo',
      label: 'Status',
      valueGetter: (param) => userState(param.disabled),
    },
    {
      field: 'options',
      role: 'optionsBtn',
      label: '',
      valueGetter: (param) => moreOptions(param),
    },
  ];

  return (
    <UsersTable>
      <UsersTableOverFlow>
        <DataListingComponent
          columns={columns}
          rowData={users}
          strippedRowsColor={theme.containerOdd}
          defaultSortField="roleLabel"
          defaultSortOrder="ascending"
          headerColor="transparent"
          rowColor={theme.tableBackground}
          theadStyle={{
            position: 'sticky',
            top: 0,
            zIndex: 1,
            backgroundColor: theme.background,
          }}
        />
      </UsersTableOverFlow>
    </UsersTable>
  );
};

UsersList.propTypes = {
  /**
   * Array with the data of the users
   */
  users: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  /**
   * Object with the current user data
   */
  currentUser: PropTypes.objectOf(PropTypes.any).isRequired,
  /**
   * Function for promoting the user
   */
  promoteUser: PropTypes.func.isRequired,
  /**
   * Function for demoting the user
   */
  demoteUser: PropTypes.func.isRequired,
  /**
   * Function to toggle the activation state of the user
   */
  toggleUserState: PropTypes.func.isRequired,
  /**
   * Function for deleting a user
   */
  deleteUser: PropTypes.func.isRequired,
  /**
   * Function to show the history associated with the user
   */
  openUserHistory: PropTypes.func.isRequired,
};

export default UsersList;
