import React from 'react';

function Memory() {
  return (
    <svg width="45" height="30" viewBox="0 0 45 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M45 18.75V26.25C45 28.3211 43.3211 30 41.25 30H3.75C1.67891 30 0 28.3211 0 26.25V18.75C0 16.6789 1.67891 15 3.75 15H41.25C43.3211 15 45 16.6789 45 18.75ZM41.25 12.5C42.0755 12.4993 42.8929 12.663 43.6544 12.9816L36.1133 1.66984C35.7708 1.15619 35.3068 0.735021 34.7625 0.443724C34.2182 0.152427 33.6104 9.76445e-06 32.993 0H12.007C11.3896 1.97729e-06 10.7818 0.152416 10.2375 0.443714C9.69321 0.735012 9.22924 1.15618 8.8868 1.66984L1.34555 12.9816C2.10709 12.663 2.92448 12.4993 3.75 12.5H41.25ZM37.5 20C36.1193 20 35 21.1193 35 22.5C35 23.8807 36.1193 25 37.5 25C38.8807 25 40 23.8807 40 22.5C40 21.1193 38.8807 20 37.5 20ZM30 20C28.6193 20 27.5 21.1193 27.5 22.5C27.5 23.8807 28.6193 25 30 25C31.3807 25 32.5 23.8807 32.5 22.5C32.5 21.1193 31.3807 20 30 20Z" />
    </svg>
  );
}

export default Memory;
