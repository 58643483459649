import styled, { css } from 'styled-components';

export const ContainerMessage = styled.div`
  position: absolute;
  top: ${({ top }) => `${top}px`};
  left: ${({ left }) => `${left}px`};
  background: ${({ theme }) => theme.background};
  z-index: ${({ zIndex }) => zIndex};
  width: ${({ width }) => width || 'min-content'};
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  padding: 30px 25px 15px 25px;
  border-radius: 12px;

  .badge {
    padding: 4px 12px;
    background-color: ${({ theme }) => theme.secondary};
    position: absolute;
    left: -8px;
    top: -8px;
    font-size: 0.9rem;
    font-weight: bold;
    color: ${({ theme }) => theme.white};
    border-radius: 6px;
  }

  .container-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;

    & > button {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      border: none;
      padding: 5px;
      border-radius: 4px;
      > svg {
        width: 18px;
        height: 18px;
        > path {
          fill: ${({ theme }) => theme.primary};
        }
      }

      &:disabled {
        cursor: not-allowed;
        > svg {
          width: 18px;
          height: 18px;
          > path {
            fill: ${({ theme }) => theme.disabled};
          }
        }
      }
    }

    & > .next {
      > svg {
        transform: rotate(180deg);
      }
    }
  }

  .close {
    position: absolute;
    top: 4px;
    right: 4px;
    box-shadow: none;

    & > svg > path {
      fill: ${({ theme }) => theme.grey};
    }
  }
`;

export const Dots = styled.span`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: 0px 5px;
  transition: transform 0.2s ease-in;
  background-color: #666;
  cursor: pointer;

  ${({ current }) => current && css`
    background-color: ${({ theme }) => theme.primary};
    border: 1px solid ${({ theme }) => theme.primary};
    transform: scale(1.2);
    box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14),
      0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  `}
`;
