import React, { memo } from 'react';
import PropTypes from 'prop-types';
import StyledAvatar from './styled/Avatar.styled';

/**
* Avatar is an object that represents the user's identity on screen. If there is no picture associated with the user, it renders the user name initials.
*/
const Avatar = ({
  name, size, src, ...props
}) => {
  const showname = !name || name.length === 0 ? ['Eu'] : name.match(/([a-z0-9]+)/ig) || '';
  return (
    <>
      <StyledAvatar size={size} src={src} style={props.style} onClick={props.onClick} {...props}>
        {!src
          && (showname.length > 1
            ? showname
              .filter((item, idx, ar) => idx === 0 || idx === ar.length - 1)
              .map((item) => item[0])
            : showname[0].slice(0, 2))}
      </StyledAvatar>
    </>
  );
};

Avatar.propTypes = {
  /**
   * String that represents the name of the user.
   */
  name: PropTypes.string,
  /**
   * Size of the avatar. Choose from four different options.
   */
  size: PropTypes.oneOf(['small', 'medium', 'large', 'extralarge', 'profile']),
  /**
   * An optional url to the user image.
   */
  src: PropTypes.string,
};

Avatar.defaultProps = {
  name: 'Eu',
  size: 'medium',
  src: '',
};

export default memo(Avatar);
