import styled from 'styled-components';
import { devices } from '../../../juristec-ui/core/breakpoints';
import Button from '../../../juristec-ui/core/Button';
import TablePagination from '../../../juristec-ui/core/TablePagination';

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const MainContainer = styled(Row)`
  padding: 0 5%;
  width: 100%;
  height: 100%;
  overflow: hidden;
  min-height: calc(100vh - 152px);
  max-height: calc(100vh - 152px);
  box-sizing: border-box;

  @media ${devices.tablet} {
    min-height: calc(100vh - 132px);
    max-height: calc(100vh - 132px);
    flex-direction: column;
    align-items: center;
    padding: 0 20px;
  }
`;

export const InfoGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
  @media ${devices.tablet} {
    max-height: calc(100vh - 132px);
    grid-template-columns: 1fr 1fr;
    height: 100%;
    overflow: auto;
  }
  @media ${devices.phoneL} {
    grid-template-columns: 1fr;
  }
`;

export const InfoContainer = styled(Column)`
  height: 100%;
  overflow-y: auto;
  overflow-x: auto;
  padding: 0;
  box-sizing: border-box;
  & > * + * {
    margin-top: .5rem;
  }
  margin: 0 .5rem;

  @media ${devices.tablet} {
    overflow: unset;
    &:last-child {
      grid-column: span 2;
      margin-top: 1rem;
    }
  }
  @media ${devices.phoneL} {
    grid-column: 1;
    margin-top: 1rem;
    &:first-child {
      margin-top: 0;
    }
  }
`;

export const InfoTitle = styled.h3`
  margin: 0;
  color: ${({ theme }) => theme.primary};
`;

export const PartsContainer = styled(Column)`
`;

export const PartsItem = styled(Column)`
  user-select: none;
  font-size: 16px;
  background-color: ${({ theme }) => theme.container};
  border-radius: .5rem;
  box-sizing: border-box;
  text-transform: capitalize;
  overflow: hidden;
  margin-top: .5rem;
  & .row-tooltip {
    margin-left: auto;
    padding: 0;
    aspect-ratio: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const PartRow = styled(Row)`
  align-items: center;
  justify-content: flex-start;
  padding: .2rem .5rem;
  padding-left: 0;
  background-color: ${({ theme }) => theme.containerOdd};
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.containerHover};
  }
  & > * {
    padding: 0 .2rem;
  }
`;

export const RepRow = styled(Column)`
  overflow: hidden;
  align-items: flex-start;
  justify-content: center;
  transition: all;
  position: relative;
  & span {
    padding: .2rem;
    padding-left: 2rem;
    position: relative;
    &.empty {
      color: ${({ theme }) => theme.grey};
    }
    &:before {
      content: "";
      display: block;
      width: .8rem;
      border: solid ${({ theme }) => theme.grey};
      border-width: 0 0 .1rem .1rem;
      position: absolute;
      left: .9rem;
      height: 100%;
      top: -50%;
    }
    &:last-child:before {
      height: 150%;
      top: -100%;
    }
  }
`;

export const PartEmpty = styled.span`
  padding: .4rem;
  color: ${({ theme }) => theme.grey};
  text-align: center;
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  & svg {
    width: 25px;
    height: 25px;
    fill: ${({ theme }) => theme.primary};
  }
  transition: all 0.2s;
`;

export const ActivityContainer = styled(Column)`
  margin-bottom: .5rem;
  & h5 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0;
    text-align: center;
    font-size: 12px;
    color: ${({ theme }) => theme.grey};
    &:before, :after {
      content: "";
      flex: 1 1;
      border-bottom: .1rem solid ${({ theme }) => theme.grey};
    }
    &:before {
      margin-right: .2rem;
    }
    &:after {
      margin-left: .2rem
    }
  }
  & > * + * {
    margin-top: .5rem;
  }
`;

export const ActivityWrapper = styled(Row)`
  padding: .5rem;
  background-color: ${({ theme }) => theme.container};
  border-radius: .5rem;
  box-sizing: border-box;
  font-size: 14px;
`;

export const InfoLine = styled(Row)`
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  white-space: nowrap;
  text-transform: capitalize;
  flex-wrap: wrap;
  & h4 {
    margin: 0;
    color: ${({ theme }) => theme.primary};
  }
  & span {
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  & .label, .value {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    height: 20px;
    flex: 1 1;
    margin-top: .2rem;
  }
  & .value:before, .label:after {
    content: "";
    flex: 1 1;
    border-bottom: .1rem dashed ${({ theme }) => theme.primary};
  }
  & .label:after {
    margin-left: .2rem;
  }
  & .value:before {
    margin-right: .2rem;
  }
  & .fill-line {
    min-width: 100%;
  }
`;

export const TextContainer = styled(Column)`
  width: 100%;
  height: auto;
  padding: 1rem;
  background-color: ${({ theme }) => theme.container};
  border-radius: 1rem;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  flex: 1 1;
`;

export const TimelineMarker = styled(Column)`
  text-align: center;
  justify-content: center;
  align-items: center;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    margin: auto;
    width: .5rem;
    height: 100%;
    bottom: 50%;
    background-color: ${({ theme }) => theme.stepProgressBase};
  }
`;

export const TimelineContainer = styled(Column)`
  justify-content: flex-start;
  font-weight: bold;
  margin: 0 .5rem 0 0;
  width: fit-content;
  min-width: 135px;
  padding-right: .5rem;
  overflow-y: auto;
  overflow-x: hidden;
  height: auto;
`;

export const TimelineBtn = styled(Button)`
  margin: 1rem 0;
  min-width: 130px;
  white-space: nowrap;
  box-shadow: none;
  z-index: 2;
  &:hover {
    box-shadow: none;
  }
  & em {
    margin-left: 5px;
    font-size: 10px;
    font-weight: normal;
    font-style: normal;
  }
`;

export const TimelineItem = styled(Row)`
  flex-direction: row-reverse;
  align-items: center;
  justify-content: flex-start;
  width: fit-content;
  &:first-child div:before {
    content: none;
  }
`;

export const BlockTxtWrapper = styled(Column)`
  height: 100%;
  width: 100%;
  overflow-y: auto;
  overflow-x: auto;
  font-size: 12px;
  flex: 1 1;
  & p {
    margin: 0;
    & em {
      font-style: normal;
      background-color: ${({ theme }) => theme.secondary};
    }
  }
`;

export const FooterContainer = styled(Row)`
  align-items: center;
  justify-content: center;
  margin-top: .5rem;
`;

export const LoaderContainer = styled(Column)`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  z-index: 100;
  background-color: ${({ theme }) => theme.fadedBackground};
`;

export const BlockPagination = styled(TablePagination)`
  padding: 0;
  margin: 0;
`;
