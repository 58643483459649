import React, {
  createContext,
  useCallback,
  useMemo,
  useReducer,
} from 'react';

// errors labels
import firestoreErrors from '../../utils/firestoreErrors';
import { error as errorLabels } from '../../label';
import { getRefDefaultPolicy, getRefPolicy } from '../../utils/firestore';

const initialState = {
  isLoading: false,
  actualPolicyId: null,
};

const reduce = (state, action) => {
  switch (action.type) {
    case 'init': {
      return {
        ...state,
        isLoading: false,
        actualPolicyId: action.actualPolicyId,
      };
    }
    case 'setLoading': {
      return {
        ...state,
        isLoading: action.loading,
      };
    }

    default:
      return state;
  }
};

export const PolicyContext = createContext({});

const PolicyProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reduce, initialState);
  const setLoading = useCallback((l) => dispatch({ type: 'setLoading', loading: l }), [dispatch]);

  const getActualPolicyId = useCallback(async () => {
    try {
      const ref = await getRefDefaultPolicy().get();
      return {
        error: false,
        actualPolicyId: ref.data()?.id || '',
        msg: '',
      };
    } catch (er) {
      return {
        error: true,
        msg: firestoreErrors(er.code) || errorLabels.policyProvider.getActualPolicyId,
        raw: `Erro do sistema: ${er.toString()}`,
      };
    }
  }, []);

  const getPolicy = useCallback(async (policyId) => {
    try {
      const ref = await getRefPolicy(policyId).get();

      return {
        error: false,
        ...ref.data(),
        msg: '',
      };
    } catch (er) {
      return {
        error: true,
        msg: firestoreErrors(er.code) || errorLabels.notificationsProvider.setViewNotification,
        raw: `Erro do sistema: ${er.toString()}`.AuthContext,
      };
    }
  }, []);

  const middleware = useCallback(async (action) => {
    switch (action.type) {
      case 'init': {
        setLoading(true);
        const res = await getActualPolicyId();
        if (res.error) {
          setLoading(false);
        } else {
          dispatch({
            type: 'init',
            actualPolicyId: res.actualPolicyId,
          });
        }
        return res;
      }

      case 'getPolicy': {
        setLoading(true);
        const res = await getPolicy(action.policyId);
        if (res.error) {
          setLoading(false);
        }
        return res;
      }

      default: {
        dispatch(action);
        return {
          error: false, msg: 'DEFAULT', raw: {}, default: true,
        };
      }
    }
  }, [
    getActualPolicyId,
    getPolicy,
    setLoading,
  ]);

  const policyAPI = useMemo(() => ({
    init: () => middleware({ type: 'init' }),
    getPolicy: (policyId) => middleware({ type: 'getPolicy', policyId }),
  }), [middleware]);

  return (
    <PolicyContext.Provider value={{ state, policyAPI }}>
      {children}
    </PolicyContext.Provider>
  );
};

export default PolicyProvider;
