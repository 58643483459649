import React from 'react';
import PropTypes from 'prop-types';

import Checkbox from '../../../juristec-ui/core/Checkbox';
import TextOverflow from '../../../juristec-ui/core/TextOverflow';

import {
  CheckboxWrapper,
  LvlLabel,
} from './styled/InstanceMessage.styled';

const lvlMap = {
  supers: 'Proprietários',
  scientists: 'Cientistas',
  guests: 'Convidados',
};

const UsersCheckList = ({
  users, selected, handler, lvl,
}) => users.length > 0 && (
  <>
    <LvlLabel>{lvlMap[lvl]}</LvlLabel>
    {users.map((u) => (
      <CheckboxWrapper key={u.uid}>
        <Checkbox
          text={(
            <div>
              <TextOverflow width={200} fontSize={14}>
                {u.name}
              </TextOverflow>
              <TextOverflow width={200} fontSize={14}>
                {u.email}
              </TextOverflow>
            </div>
          )}
          variant="outlined"
          style={{ width: '100%', justifyContent: 'flex-start' }}
          checked={selected?.includes(u.uid)}
          handleCheckboxChange={(check) => handler(check, lvl, u.uid)}
        />
      </CheckboxWrapper>
    ))}
  </>
);

UsersCheckList.propTypes = {
  users: PropTypes.arrayOf(PropTypes.string),
  selected: PropTypes.arrayOf(PropTypes.string),
  handler: PropTypes.func.isRequired,
  lvl: PropTypes.string,
};

UsersCheckList.defaultProps = {
  users: [],
  selected: [],
  lvl: 'scientists',
};

export default UsersCheckList;
