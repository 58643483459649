const FirestoreErrorCodes = {
  unknown: 'Erro desconhecido',
  unimplemented: 'Erro desconhecido',

  unavailable: 'Não foi possível concluir a operação no momento. Tente novamente mais tarde',
  internal: 'Não foi possível concluir a operação no momento. Tente novamente mais tarde',
  aborted: 'Não foi possível concluir a operação no momento. Tente novamente mais tarde',
  'failed-precondition': 'Não foi possível concluir a operação no momento. Tente novamente mais tarde',
  'out-of-range': 'Não foi possível concluir a operação no momento. Tente novamente mais tarde',

  unauthenticated: 'Não autenticado',
  cancelled: 'Operação cancelada',
  'invalid-argument': 'Documento com dado inválido.', // undefined...
  'not-found': 'Entidade não encontrada',
  'already-exists': 'Documento já existe',
  'resource-exhausted': 'Limite de dados alcançado',
  'permission-denied': 'Permissão de acesso ao documento negada',

  'data-loss': 'Erro nos dados. Entre em contato com a administração do sistema',
  'deadline-exceeded': '', // demora no tempo de resposta do servidor. A operação pode ter sido completada com sucesso...
  getError(k) {
    return this[k] || null;
  },
};

export default (code) => FirestoreErrorCodes.getError(code);
