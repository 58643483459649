import React from 'react';
import PropTypes from 'prop-types';

const BennerLogo = ({ height, width }) => (
  <svg width={width} height={height} viewBox="0 0 534 119" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.1334 2.53333C0.133398 4.8 -0.133268 8.8 0.266732 45.4667L0.666732 86L4.40007 93.4667C14.0001 113.333 34.9334 122.533 55.6001 116.133C85.4667 107.2 95.8667 69.8667 75.2001 46.8C66.2667 36.9333 56.8001 32.6667 43.3334 32.6667C32.6667 32.6667 31.3334 33.0667 15.7334 41.0667C15.0667 41.3333 14.6667 33.2 14.6667 22.9333C14.6667 6.66667 14.4001 4.13333 12.2667 2.26667C9.06673 -0.666667 4.9334 -0.533333 2.1334 2.53333ZM54.0001 49.2C73.3334 58 77.4667 81.6 62.1334 95.7333C47.8667 108.8 26.8001 105.067 18.1334 87.8667C14.0001 79.3333 13.7334 72.2667 17.4667 64C21.0667 56 23.4667 53.4667 31.3334 49.8667C39.3334 46.1333 46.8001 45.8667 54.0001 49.2Z" fill="#ED1846" />
    <path d="M34.6665 58.4C25.0665 63.2 21.4665 74.2667 26.3999 83.8667C33.5999 98.2667 53.7332 97.7333 60.2665 83.0667C67.5999 66.4 50.9332 50.1333 34.6665 58.4Z" fill="#4646F0" />
    <path d="M125.867 34.8C123.467 35.4667 118.801 38 115.201 40.2667C93.6005 54.8 89.7338 83.7333 106.667 104.267C111.867 110.533 125.334 117.2 134.934 118.267C152.934 120.133 170.267 108.8 161.601 100.933C158.534 98.1333 156.267 98.1333 150.267 100.667C144.267 103.2 133.334 103.2 127.601 100.933C123.334 99.0667 113.334 89.2 113.334 86.6667C113.334 85.8667 124.667 85.3333 144.667 85.3333C174.801 85.3333 176.134 85.2 178.801 82.5333C185.334 76 178.667 54.2667 167.067 44.1333C156.534 34.6667 139.334 30.9333 125.867 34.8ZM151.467 51.6C156.534 54.1333 165.334 65.0667 165.334 68.8C165.334 70.2667 160.534 70.6667 138.667 70.6667C121.067 70.6667 112.001 70.1333 112.001 69.3333C112.001 68.5333 113.201 65.6 114.667 62.6667C117.601 57.0667 124.401 51.2 130.267 49.3333C135.867 47.4667 145.734 48.5333 151.467 51.6Z" fill="#ED1846" />
    <path d="M192.8 36.2667C190.934 38.8 190.667 44.8 190.667 74.9334C190.667 94.4 191.2 112 191.867 113.733C193.6 118.133 198 119.467 202 116.8C205.067 114.8 205.067 114.267 205.6 90.1334L206 65.6L210 59.8667C220.534 44.9334 240.8 44 252.934 57.8667C258.534 64.1334 260 72.1334 260 95.3334C260 110.933 260.4 114.8 262.134 116.533C264.667 119.2 266.667 119.2 270.4 116.533C273.2 114.533 273.334 113.867 273.334 90.6667C273.334 62.6667 271.734 56 262.267 46.2667C253.467 37.3334 245.467 34 232.667 34C223.067 34 221.2 34.4 214.267 38.4C207.334 42.2667 206.534 42.5334 205.734 40.4C203.2 33.2 196.4 31.0667 192.8 36.2667Z" fill="#ED1846" />
    <path d="M286.133 35.4667C284.267 37.2 284 42.8 284 76C284 114.267 284 114.533 286.933 116.533C290.667 119.2 292.133 119.2 295.467 116.8C297.733 115.067 298 112.667 298.667 89.6L299.333 64.2667L303.467 59.0667C315.6 44 336.4 44.4 347.333 59.8667L351.333 65.6L351.733 90.1333C352.267 114.533 352.267 114.8 355.467 116.933C359.733 119.6 364.933 117.6 366.133 112.8C366.533 110.933 366.667 99.3333 366.4 87.0667C366 65.8667 365.733 64.2667 362.267 57.0667C355.733 43.7333 343.067 35.0667 328 33.7333C319.333 32.9333 312.533 34.6667 304.8 39.6C299.6 42.9333 297.467 42.4 297.333 37.7333C297.333 35.8667 293.2 33.3333 290.133 33.3333C289.067 33.3333 287.2 34.2667 286.133 35.4667Z" fill="#ED1846" />
    <path d="M403.2 36C393.333 40 386.133 46.8 381.067 57.0667C377.2 64.8 376.667 67.2 376.8 76C376.8 88.6667 380.4 97.2 389.067 105.733C400.533 116.933 414.8 120.8 429.867 116.8C434.533 115.467 439.6 113.333 441.2 111.867C444.533 108.8 444.8 103.733 441.867 100.8C439.2 98.1333 436.267 98.1333 429.867 100.8C426.4 102.267 422.133 102.8 416.4 102.4C409.6 102 407.2 101.2 403.067 97.7333C400.267 95.6 396.933 91.8667 395.6 89.4667L393.067 85.3333H425.067C461.6 85.3333 461.333 85.4667 461.333 74.2667C461.333 60.1333 451.467 44.5333 438 37.6C428.8 32.9333 412.933 32.1333 403.2 36ZM432.667 52C438.267 55.4667 443.467 61.8667 444.667 66.9333L445.6 70.6667H418.667H391.867L392.667 67.6C396.133 55.8667 408.933 47.3333 421.067 48.5333C424.933 48.8 430.133 50.4 432.667 52Z" fill="#ED1846" />
    <path d="M474.4 34.8C472.133 36 472 39.4667 472 75.3334C472 109.2 472.267 114.8 474.133 116.533C476.667 119.2 478.667 119.2 482.4 116.533C485.2 114.533 485.333 113.733 485.333 93.6C485.333 82.1334 486 70.4 486.8 67.6C488.533 61.4667 495.733 53.0667 501.867 50.1334C507.067 47.7334 516.4 47.3334 522.4 49.4667C527.2 51.0667 531.333 49.4667 532.667 45.3334C536.8 32.5334 503.333 29.0667 489.333 40.8L485.333 44.2667V40.9334C485.333 34.8 479.733 31.6 474.4 34.8Z" fill="#ED1846" />
  </svg>
);

BennerLogo.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
};

BennerLogo.defaultProps = {
  height: '30px',
  width: '30px',
};

export default BennerLogo;
