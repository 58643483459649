import styled, { css } from 'styled-components';
import { devices } from '../../breakpoints';

const getContrastYIQ = (hexcolor) => {
  // eslint-disable-next-line no-param-reassign
  hexcolor = hexcolor.replace('#', '');
  const r = parseInt(hexcolor.substr(0, 2), 16);
  const g = parseInt(hexcolor.substr(2, 2), 16);
  const b = parseInt(hexcolor.substr(4, 2), 16);
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128 ? 'black' : 'white';
};

const variants = (variant, color, theme, useDynamicColor) => {
  switch (variant) {
    case 'outlined':
      return css`
        background-color: transparent; 
        border: 2px solid ${theme[color] || color};
        box-shadow: 0px 4px 4px ${theme.shadow};
        color: ${theme[color] || color};
        & > svg {
          fill: ${theme[color] || color};
        }
        &:hover {
          background-color: ${theme[color] || color};
          color: ${theme.white};
          & > svg {
            fill: ${theme.white};
          }
        }
        &:disabled {
          border: 2px solid ${theme.disabled};
          color: ${theme.disabled};
          box-shadow: none;
          pointer-events: none;
          cursor: default;
          & > svg {
            fill: ${theme.disabled};
          }
        }
      `;
    case 'pattern':
      return css`
        color: ${theme[color] || color};
        border: none;
        background-color: transparent;
        & > svg {
          fill: ${theme[color] || color};
          &:disabled {
            color: ${theme.disabled};
            & > svg {
              fill: ${theme.disabled};
            };
          };
          &:hover {
            background-color: ${theme[color] || color}30;
          }
        };
      `;
    default:
      return css`
        background-color: ${theme[color] || color}; 
        border: 2px solid ${theme[color] || color}; 
        box-shadow: 0px 4px 4px ${theme.shadow};
        ${useDynamicColor && css`
          color: ${getContrastYIQ(theme[color] || color)};
          & > svg {
            fill: ${getContrastYIQ(theme[color] || color)};
          };
        `}
        &:disabled {
          color: ${theme.white}80;
          border: 2px solid ${theme.disabled};
          background-color: ${theme.disabled};
          box-shadow: none;
          pointer-events: none;
          cursor: default;
          & > svg {
            fill: ${theme.white}80;
          };
        }
      `;
  }
};

const shapes = {
  square: '0',
  rounded: '8px',
  circle: '20px',
  getShape(shape) {
    return this[shape] || this.rounded;
  },
};

const sizes = {
  small: { padding: '2px 4px', 'font-size': '14px' },
  medium: { padding: '4px 8px', 'font-size': '16px' },
  large: { padding: '8px 16px', 'font-size': '18px' },
  getSize(size) {
    return this[size] || this.medium;
  },
};

const textStyle = {
  capitalize: 'capitalize',
  uppercase: 'uppercase',
  none: 'none',
  getText(text) {
    return this[text] || this.capitalize;
  },
};

const StyledButton = styled.button`
  border-style: none;
  cursor: pointer;
  color: ${({ theme }) => theme.white};
  font-weight: 600;
  & > svg {
    fill: ${({ theme }) => theme.white};
    margin: 0px 5px 0px 0px;
  }

  ${(props) => (props.noOutline ? css`
    outline: none;
  ` : css`
    outline-color: ${({ theme }) => theme.outline};
  `)}
  min-width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media ${devices.phoneS} {
    min-width: 120px;
  }

  ${({
  size, variant, shape, color, textTransform, theme,
}) => css`
    ${variants(variant, color, theme)};
    border-radius: ${shapes.getShape(shape)};
    padding: ${sizes.getSize(size).padding};
    text-transform: ${textStyle.getText(textTransform)};
    font-size: ${sizes.getSize(size)['font-size']};
    & > svg {
      width: ${size};
      height: ${size};
      pointer-events: none;
    }
  `}

  &:hover {
    box-shadow: 0px 4px 4px ${({ theme }) => theme.shadow};
    opacity: 0.85;
  }

  &:focus {
    outline: none;
  }

  div {
    display: flex;
    align-items: center;
  }
`;

export default StyledButton;
