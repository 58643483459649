import styled, { css } from 'styled-components';
import { gap } from '../../../../juristec-ui/styles/theme';
import { devices } from '../../../../juristec-ui/core/breakpoints';

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

export const MainContainer = styled(Row)`
  padding: 1rem 5%;
  height: 3rem;
  ${gap('30px', 'row')}
  align-items: center;
  & .left {
    width: 100%;
    flex-shrink: 2;
    position: relative;
    & span {
      position: absolute;
      font-size: 12px;
      font-weight: bold;
      margin-left: 20px;
      color: ${({ theme }) => theme.color};
    }
  }
  & .right {
    width: 100%;
    max-width: 330px;
    flex-grow: 5;
    flex-shrink: 1;
  }
  @media ${devices.tablet} {
    padding: 10px 20px;
    & .right {
      max-width: 270px;
    }
  }
  @media ${devices.phoneS} {
    ${gap('10px', 'row')}
    & .right {
      width: auto;
    }
  }
`;

export const ItensContainer = styled(Row)`
  ${gap('10px', 'row')}
  align-items: center;
  height: 100%;
`;

export const NavOpt = styled.div`
  color: ${({ theme }) => theme.toolbarFont};
  text-decoration: none;
  padding: 0 5px;
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-weight: bold;
  border-bottom: 4px solid transparent;
  box-sizing: border-box;
  text-align: center;

  &:hover {
    color: ${({ theme }) => theme.primary};
  }

  ${({ active, theme }) => active && css`
    color: ${theme.primary};
    border-bottom-color: ${theme.primary};
  `}
`;

export const IconWrapper = styled.div`
  width: 20px;
  height: 20px;
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${gap('.5rem', 'row')}
  margin: 5px;
  background-color: ${({ theme }) => theme.backgroundOdd};
  border-radius: 10px;
  width: 100%;
  box-shadow: 0px 4px 4px ${({ theme }) => theme.shadow};
  padding: 0.2rem 0.6rem;
  & .inputTxt {
    padding: 5px 0;
  }
  & svg {
    fill: ${({ theme }) => theme.grey};
  }
  @media ${devices.phoneS} {
    display: none;
  }
`;
