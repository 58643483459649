import React from 'react';

const Image = () => (
  <svg width="29" height="27" viewBox="0 0 29 27" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M28.05 3.3C28.05 1.48005 26.57 0 24.75 0H3.3C1.48005 0 0 1.48005 0 3.3V23.1C0 24.92 1.48005 26.4 3.3 26.4H24.75C26.5699 26.4 28.05 24.92 28.05 23.1L28.05 3.3ZM24.75 3.3H3.3V23.1H24.75L24.75 3.3Z" />
    <path d="M9.8997 13.1999L4.9497 19.7999H23.0997L16.4997 9.8999L11.5497 16.4999L9.8997 13.1999Z" />
  </svg>
);

export default Image;
