import styled from 'styled-components';
import { devices } from '../../../../juristec-ui/core/breakpoints';

export const FilesTable = styled.div`
  padding: 0 5%;
  @media ${devices.tablet} {
    padding: 0 20px;
  }
`;

// essa outra div serve para que o scroll fique perto da tabela.
// caso queria q o scroll fique na lateral da página,
// jogar esse css para o FilesTable e remover do filesList.
export const FilesTableOverFlow = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  // height: calc(100vh - 164px);
  height: calc(100vh - 141px);
  box-sizing: border-box;
  padding-bottom: 65px;
`;

export const IconWrapper = styled.div`
  display: flex;
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
  margin: auto;
  & svg {
    margin: auto;
    fill: ${({ theme }) => theme.primary};
  }
`;

export const IconBtnContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

export const OwnerLabel = styled.span`
  display: block;
  max-width: 300px;
  margin: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 10px;
  font-style: italic;
`;
