import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useTheme } from 'styled-components';
import { Question } from '../../../juristec-ui/icons';
import { verifyInput } from '../../../juristec-ui/utils/validators/inputTextValidators';
import {
  FooterContainer,
  SupportContainer,
  Title,
  ActionButtons,
  Link,
} from './styled/JiraHelpDesk.styled';
import useToggleState from '../../../juristec-ui/hooks/useToggleState';
import Popover from '../../../juristec-ui/core/Popover';
import IconButton from '../../../juristec-ui/core/IconButton';
import TextArea from '../../../juristec-ui/core/TextArea';
import Tooltip from '../../../juristec-ui/core/Tooltip';
import Button from '../../../juristec-ui/core/Button';
import Select from '../../../juristec-ui/core/Select';

const JiraHelpDesk = ({
  summaries, submit,
}) => {
  const theme = useTheme();
  const [showPopover, togglePopover, closePopover] = useToggleState(false);
  const [message, setMessage] = useState({ value: '', error: true, errorMsg: '' });
  const [summaryOpts, setSummaryOpts] = useState([{ label: 'Outros', value: 'Outros', id: 'others' }]);
  const [summary, setSummary] = useState({ label: 'Outros', value: 'Outros' });

  const handleMessage = (e) => {
    const val = e.target.value;
    const msg = verifyInput(val, true);
    setMessage({
      value: val,
      error: msg.length !== 0,
      errorMsg: msg,
    });
  };

  const handleSubmit = () => {
    if (submit(message.value, summary.value)) {
      setMessage({ value: '', error: true, errorMsg: '' });
      setSummary({ label: 'Outros', value: 'Outros' });
      closePopover();
    }
  };

  useEffect(() => {
    if (summaries && summaries.length > 0) {
      setSummaryOpts([
        ...summaries.map((s) => ({
          label: s, value: s, id: s,
        })),
        { label: 'Outros', value: 'Outros', id: 'others' },
      ]);
    }
  }, [summaries]);

  return (
    <FooterContainer>
      <Popover
        style={{
          borderRadius: '8px',
          backgroundColor: theme.popoverBackground,
          overflow: 'hidden',
        }}
        offset={[-5, 10]}
        open={showPopover}
        closePopover={closePopover}
        stayOpened
        direction="top-end"
      >
        <Popover.Action>
          <Tooltip text="Suporte">
            <IconButton
              size="medium"
              shape="circle"
              variant="contained"
              onClick={togglePopover}
            >
              <Question />
            </IconButton>
          </Tooltip>
        </Popover.Action>
        <Popover.Content hasSelect>
          <SupportContainer>
            <Title>Suporte</Title>
            {process.env.REACT_APP_FIREBASE_PROJECT_LABEL !== 'legalone-analytics' && (
              <>
                <Select
                  selectLabel="Motivo do suporte"
                  placeholder="Selecione"
                  options={summaryOpts}
                  value={summary}
                  onChange={setSummary}
                  fullWidth
                />
                <TextArea
                  label="Como podemos te ajudar?"
                  value={message.value}
                  onChange={handleMessage}
                  errorMessage={message.errorMsg}
                  error={message.errorMsg.length > 0}
                  styleContainer={{
                    width: '300px',
                    height: '200px',
                    margin: '1rem 0',
                  }}
                  style={{ fontSize: '14px', backgroundColor: theme.card }}
                />
                <ActionButtons>
                  <Button
                    onClick={closePopover}
                    style={{ width: '100%', minWidth: '100px', marginRight: '1rem' }}
                    variant="outlined"
                  >
                    Cancelar
                  </Button>
                  <Button
                    onClick={handleSubmit}
                    style={{ width: '100%', minWidth: '100px' }}
                    disabled={message.error}
                  >
                    Enviar
                  </Button>
                </ActionButtons>
              </>
            )}
            {process.env.REACT_APP_FIREBASE_PROJECT_LABEL === 'legalone-analytics' && (
              <div style={{ maxWidth: '300px' }}>
                <div style={{
                  fontSize: '12pt',
                  marginBottom: '5px',
                  wordWrap: 'break-word',
                  whiteSpace: 'normal',
                  overflowWrap: 'break-word',
                  overflow: 'hidden',
                }}
                >
                  Para abrir o ticket de suporte, nos contate através da central de atendimento
                  no link
                </div>
                <Link
                  href="https://suporte.legalone.com.br/login.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://suporte.legalone.com.br/login.html
                </Link>
              </div>
            )}
          </SupportContainer>
        </Popover.Content>
      </Popover>
    </FooterContainer>
  );
};

JiraHelpDesk.propTypes = {
  submit: PropTypes.func.isRequired,
};

export default JiraHelpDesk;
