export default {
  TitleControl: '',
  ShowTitleControl: { checked: false },
  GlobalValue: {
    fontSize: 20,
    color: '#00F',
    fontFamily: 'arial',
    background: '#ddd',
    bold: false,
    italic: false,
    underline: false,
  },
  StyleTitleControl: {
    fontSize: 20,
    color: '#000000',
    fontFamily: 'arial',
    bold: false,
    italic: false,
    underline: false,
  },
  type: 'Value',
};
