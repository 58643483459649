/* eslint-disable react/prop-types */
import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useReducer,
} from 'react';

import { AuthContext } from '../AuthProvider';

import {
  getBatch,
  getRefNotification,
  getRefNotificationCount,
  getRefNotifications,
  getListener,
  getRefSystemMessages,
  getRefSystemMessage,
  getTransaction,
  getRefGeneralMessages
} from '../../utils/firestore';

// errors labels
import firestoreErrors from '../../utils/firestoreErrors';
import { error as errorLabels } from '../../label';
import compare from '../../utils/functions/sorting';

const initialState = {
  isLoading: true,
  systemMessages: [],
  notifications: null,
  notificationsCount: 0,
  detachListener: null,
  isReady: false,
};

const reduce = (state, action) => {
  switch (action.type) {
    case 'setNotifications': {
      return {
        ...state,
        isLoading: false,
        lastElement: action.lastElement,
        notifications: [...action.notifications],
        isReady: true,
      };
    }

    case 'appendNotifications': {
      return {
        ...state,
        isLoading: false,
        lastElement: action.lastElement,
        notifications: [...state.notifications, ...action.notifications],
      };
    }

    case 'prependNotifications': {
      return {
        ...state,
        isLoading: false,
        notifications: [...action.notifications, ...state.notifications],
      };
    }

    case 'resultListener': {
      return {
        ...state,
        notificationsCount: action.count,
      };
    }

    case 'setDetachListener': {
      return {
        ...state,
        detachListener: action.detach,
      };
    }

    case 'setSystemMessages': {
      return {
        ...state,
        systemMessages: action.messages,
        isLoading: false,
      };
    }

    case 'setLocalViewed': {
      return {
        ...state,
        notifications: state.notifications ? state.notifications.map((n) => {
          const tmp = n;
          tmp.viewed[action.userId] = true;
          return tmp;
        }) : null,
      };
    }

    case 'setViewdSystemMessage': {
      return {
        ...state,
        systemMessages: state.systemMessages ? state.systemMessages.filter((m) => (
          m.id !== action.messageId
        )) : [],
      };
    }

    case 'setNotViewdSystemMessage': {
      return {
        ...state,
        systemMessages: state.systemMessages
          ? [...state.systemMessages, action.message] : [action.message],
      };
    }

    case 'setLoading': {
      return {
        ...state,
        isLoading: action.loading,
      };
    }

    default:
      return state;
  }
};

export const NotificationsContext = createContext(null);
const maxPerFetch = 5;

const NotificationsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reduce, initialState);
  const setLoading = useCallback((l) => dispatch({ type: 'setLoading', loading: l }), [dispatch]);

  const { user } = useContext(AuthContext);

  const setNotificationListener = useCallback((handler) => {
    try {
      const countRef = getRefNotificationCount(user.company, user.id);
      const listener = getListener();
      const detach = listener(countRef, handler);
      return { error: false, detach };
    } catch (er) {
      return {
        error: true,
        msg: firestoreErrors(er.code) || errorLabels.notificationsProvider.setNotificationListener,
        raw: `Erro do sistema: ${er.toString()}`,
      };
    }
  }, [user]);

  const getNotifications = useCallback(async (offSet, qty) => {
    try {
      let ref = getRefNotifications(user.company)
        .orderBy('timestamp', 'desc')
        .where('sharedWith', 'array-contains', user.id)
        .limit(qty || maxPerFetch);

      if (offSet) {
        ref.startAfter(offSet);
      }

      const newNotifs = [];

      ref = await ref.get();
      const notifications = ref.docs.map((d) => {
        const data = d.data();
        if (!data.viewed[user.id]) newNotifs.push(d.id);
        return ({ id: d.id, ...data });
      });

      const lastElement = ref.docs.length >= maxPerFetch ? ref.docs[ref.docs.length - 1] : null;

      return {
        error: false,
        msg: '',
        notifications,
        lastElement,
        newNotifs,
      };
    } catch (er) {
      console.log(er);
      return {
        error: true,
        msg: firestoreErrors(er.code) || errorLabels.notificationsProvider.getNotifications,
        raw: `Erro do sistema: ${er.toString()}`,
      };
    }
  }, [user]);

  const setViewedAtAllVisible = useCallback(async (notifications = []) => {
    try {
      const batch = getBatch();

      notifications.forEach((id) => {
        const ref = getRefNotification(user.company, id);
        batch.update(ref, {
          viewed: {
            [user.id]: true,
          },
        }, { merge: true });
      });

      const countRef = getRefNotificationCount(user.company, user.id);
      batch.set(countRef, { new: 0 }, { merge: true });

      await batch.commit();
      return { error: false, msg: 'ok' };
    } catch (er) {
      console.log(er);
      return {
        error: true,
        msg: firestoreErrors(er.code) || errorLabels.notificationsProvider.setViewedAtAllVisible,
        raw: `Erro do sistema: ${er.toString()}`,
      };
    }
  }, [user]);

  const getSystemMessages = useCallback(async () => {
    try {
      let ref = getRefSystemMessages(user.company)
        .where('usersToView', 'array-contains', user.id);
      ref = await ref.get();

      const generalRef = await getRefGeneralMessages().get();
      const generalMessages = {};
      generalRef.forEach((doc) => {
        generalMessages[doc.id] = doc.data();
      });

      const dateNow = new Date();
      // VARIÁVEL FIXED_DATE PODE SER ELIMINADA EM DEPLOYS APÓS 01/11/24
      const FIXED_DATE = new Date('2024-10-03');
      const removeList = [];
      const msgs = ref.docs.reduce((aux, d) => {
        // CONDIÇÃO ORIGINAL PARA SER RESTABELECIDA APÓS 01/11/2024
        // const realMessage = d.data().notificationId
        //   ? generalMessages[d.data().notificationId].message
        //   : d.data().message;
        // TODO ESSE IF/ELSE PODE SER ELIMINADO APÓS 01/11
        let realMessage = '';
        if (d.data().notificationId) {
          realMessage = generalMessages[d.data().notificationId].message;
        } else if (d.data().timestamp.toDate() < FIXED_DATE && d.data().title === 'Comunicado') {
          realMessage = generalMessages.correcaoTR.message;
        } else {
          realMessage = d.data().message;
        }
        // ATÉ AQUI
        const dt = { ...d.data(), message: realMessage, id: d.id };
        if (dt.showUntil && dateNow > dt?.showUntil.toDate()) removeList.push(d.id);
        else aux.push(dt);
        return aux;
      }, []).sort((a, b) => compare(b.timestamp, a.timestamp));

      return {
        error: false,
        messages: msgs,
        toRemove: removeList,
      };
    } catch (er) {
      console.log(er);
      return {
        error: true,
        msg: firestoreErrors(er.code) || errorLabels.notificationsProvider.getSystemMessages,
        raw: `Erro do sistema: ${er.toString()}`,
      };
    }
  }, [user]);

  const setViewdSystemMessage = useCallback(async (msgId) => {
    try {
      const ref = getRefSystemMessage(user.company, msgId);
      await getTransaction((transaction) => transaction.get(ref).then(async (dashdocRaw) => {
        const dashdoc = dashdocRaw.data();
        transaction.set(
          ref,
          { usersToView: dashdoc.usersToView.filter((key) => key !== user.id) },
          { merge: true },
        );
      }));
      return { error: false, msg: 'ok' };
    } catch (er) {
      console.log(er);
      return {
        error: true,
        msg: firestoreErrors(er.code) || errorLabels.notificationsProvider.setViewdSystemMessage,
        raw: `Erro do sistema: ${er.toString()}`,
      };
    }
  }, [user]);

  const middleware = useCallback(async (action) => {
    switch (action.type) {
      case 'getNotifications': {
        setLoading(true);
        const resGet = await getNotifications(action.offSet, action.qty);
        if (resGet.error) {
          setLoading(false);
        } else if (resGet.newNotifs?.length > 0) {
          const resView = await setViewedAtAllVisible(resGet.newNotifs);
          if (resView.error) {
            setLoading(false);
            return resView;
          }
        }
        dispatch({
          type: action.getType,
          notifications: resGet.notifications,
          lastElement: resGet.lastElement,
        });
        return resGet;
      }

      case 'initListener': {
        const handleCount = async (doc) => {
          const count = await doc.data();
          dispatch({
            type: 'resultListener',
            count: count?.new || 0,
          });
        };
        const res = setNotificationListener(handleCount);
        if (!res.error) {
          dispatch({ type: 'setDetachListener', detach: res.detach });
        }
        return res;
      }

      case 'getSystemMessages': {
        setLoading(true);
        const sysRes = await getSystemMessages();
        if (sysRes.error) {
          setLoading(false);
        } else {
          if (sysRes.toRemove?.length > 0) {
            Promise.all(sysRes.toRemove.map((mId) => setViewdSystemMessage(mId)));
          }
          dispatch({
            type: 'setSystemMessages',
            messages: sysRes.messages,
          });
        }
        return sysRes;
      }

      case 'setLocalViewed': {
        dispatch({
          type: 'setLocalViewed',
          userId: user.id,
        });
        return '';
      }

      case 'setViewdSystemMessage': {
        const message = state.systemMessages.find((m) => m.id === action.messageId);

        dispatch({
          type: 'setViewdSystemMessage',
          messageId: action.messageId,
        });

        const sysRes = await setViewdSystemMessage(action.messageId);
        if (sysRes.error) {
          dispatch({
            type: 'setNotViewdSystemMessage',
            message,
          });
        }
        return sysRes;
      }

      default: {
        dispatch(action);
        return {
          error: false, msg: 'DEFAULT', raw: {}, default: true,
        };
      }
    }
  }, [
    getNotifications,
    setViewedAtAllVisible,
    setNotificationListener,
    getSystemMessages,
    setViewdSystemMessage,
    user,
    setLoading,
  ]);

  const notificationsAPI = useMemo(() => ({
    initListener: () => middleware({ type: 'initListener' }),
    getNewNotifications: (qty) => middleware({
      type: 'getNotifications', getType: 'prependNotifications', qty,
    }),
    getMoreNotifications: (offSet) => middleware({
      type: 'getNotifications', getType: 'appendNotifications', offSet,
    }),
    getInitNotifications: () => middleware({
      type: 'getNotifications', getType: 'setNotifications',
    }),
    getSystemMessages: () => middleware({ type: 'getSystemMessages' }),
    setViewdSystemMessage: (messageId) => middleware({
      type: 'setViewdSystemMessage', messageId,
    }),
    setLocalViewed: () => middleware({ type: 'setLocalViewed' }),
  }), [middleware]);

  return (
    <NotificationsContext.Provider value={{ state, notificationsAPI }}>
      {children}
    </NotificationsContext.Provider>
  );
};

export default NotificationsProvider;
