const denormalizeFields = ((selectedFields) => {
  const denormalized = [];

  const addField = (obj, parts) => {
    const fieldname = parts[0];
    const subfield = parts.slice(1);

    if (subfield.length === 0) {
      if (!obj.includes(fieldname)) {
        obj.push(fieldname);
      }
    } else {
      let subObj = obj.find((item) => typeof item === 'object' && item[fieldname]);
      if (!subObj) {
        subObj = { [fieldname]: [] };
        obj.push(subObj);
      }
      addField(subObj[fieldname], subfield);
    }
  };

  selectedFields.forEach((field) => {
    const parts = field.value.split('.');
    addField(denormalized, parts);
  });

  return denormalized;
}
);

export default denormalizeFields;
