import React from 'react';
import PropTypes from 'prop-types';

const VersionMerge = ({ height, width }) => (
  <svg width={height} height={width} viewBox="0 0 30 35" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.35714 3.75C5.78338 3.75 6.19217 3.91932 6.49356 4.22072C6.79496 4.52212 6.96429 4.9309 6.96429 5.35714C6.96429 5.78338 6.79496 6.19217 6.49356 6.49356C6.19217 6.79496 5.78338 6.96429 5.35714 6.96429C4.9309 6.96429 4.52212 6.79496 4.22072 6.49356C3.91932 6.19217 3.75 5.78338 3.75 5.35714C3.75 4.9309 3.91932 4.52212 4.22072 4.22072C4.52212 3.91932 4.9309 3.75 5.35714 3.75ZM7.52679 10.2589C9.40179 9.42857 10.7143 7.54687 10.7143 5.35714C10.7143 2.39732 8.31696 0 5.35714 0C2.39732 0 0 2.39732 0 5.35714C0 7.55357 1.3192 9.44196 3.21429 10.2656V24.0201C1.3192 24.8438 0 26.7321 0 28.9286C0 31.8884 2.39732 34.2857 5.35714 34.2857C8.31696 34.2857 10.7143 31.8884 10.7143 28.9286C10.7143 26.7321 9.39509 24.8438 7.5 24.0201V18.2143C9.28795 19.5603 11.5179 20.3571 13.9286 20.3571H19.7344C20.558 22.2522 22.4464 23.5714 24.6429 23.5714C27.6027 23.5714 30 21.1741 30 18.2143C30 15.2545 27.6027 12.8571 24.6429 12.8571C22.4464 12.8571 20.558 14.1763 19.7344 16.0714H13.9286C10.5871 16.0714 7.83482 13.5201 7.52679 10.2589ZM5.35714 27.3214C5.78338 27.3214 6.19217 27.4908 6.49356 27.7921C6.79496 28.0935 6.96429 28.5023 6.96429 28.9286C6.96429 29.3548 6.79496 29.7636 6.49356 30.065C6.19217 30.3664 5.78338 30.5357 5.35714 30.5357C4.9309 30.5357 4.52212 30.3664 4.22072 30.065C3.91932 29.7636 3.75 29.3548 3.75 28.9286C3.75 28.5023 3.91932 28.0935 4.22072 27.7921C4.52212 27.4908 4.9309 27.3214 5.35714 27.3214ZM23.0357 18.2143C23.0357 17.788 23.205 17.3793 23.5064 17.0779C23.8078 16.7765 24.2166 16.6071 24.6429 16.6071C25.0691 16.6071 25.4779 16.7765 25.7793 17.0779C26.0807 17.3793 26.25 17.788 26.25 18.2143C26.25 18.6405 26.0807 19.0493 25.7793 19.3507C25.4779 19.6521 25.0691 19.8214 24.6429 19.8214C24.2166 19.8214 23.8078 19.6521 23.5064 19.3507C23.205 19.0493 23.0357 18.6405 23.0357 18.2143Z" />
  </svg>
);

VersionMerge.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
};

VersionMerge.defaultProps = {
  height: '25px',
  width: '25px',
};

export default VersionMerge;
