import React from 'react';
import PropTypes from 'prop-types';

import { useTheme } from 'styled-components';

import DataTable from '../../../juristec-ui/core/DataTable';

import {
  TableOverFlow,
} from './styled/AssistantResponse.styled';

const AssistantTable = ({
  dataframe,
}) => {
  const theme = useTheme();

  return (
    <TableOverFlow>
      <DataTable
        columns={dataframe?.columns.reduce((aux, column, i) => {
          if (column === 'index') return aux;
          aux.push({
            label: column,
            valueGetter: (p) => p[i],
          });
          return aux;
        }, []) || []}
        rowData={dataframe?.data || []}
        strippedRowsColor={theme.containerOdd}
        rowColor={theme.tableBackground}
        headerColor="transparent"
        theadStyle={{
          position: 'sticky',
          top: 0,
          zIndex: 2,
          backgroundColor: theme.background,
        }}
      />
    </TableOverFlow>
  );
};

AssistantTable.propTypes = {
  dataframe: PropTypes.shape({
    index: PropTypes.arrayOf(PropTypes.string),
    columns: PropTypes.arrayOf(PropTypes.string),
    data: PropTypes.arrayOf(PropTypes.string),
  }),
};

AssistantTable.defaultProps = {
  dataframe: {},
};

export default AssistantTable;
