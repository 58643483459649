import React from 'react';
import { css } from 'styled-components';
import { MetricsLogo, ThomsonLogo, BennerLogo } from '../icons';
import logoMetrics from '../assets/nova_logo.png';
import logoThomson from '../assets/thomson_logo.png';
import logoBenner from '../assets/benner_logo.png';
import favMetrics from '../assets/logo.svg';
import favThomson from '../assets/icon-thomson.svg';
import favBenner from '../assets/fav_benner.svg';

/** Light theme 'Legal Metrics' */
const lightThemeLM = {
  primary: '#4711B2',
  primaryHover: '#343078',
  primaryDark: '#0D0C1D',
  primaryLight: '#FAFAFA',
  secondary: '#FF9626',
  secondaryHover: '#d67f29',
  secondaryDark: '#ff8000',
  secondaryLight: '#fff6ed',
  container: '#FAFAFA',
  containerOdd: '#fff6ed',
  containerLine: '#B0C4DE',
  containerLineOdd: '#FFA07A',
  containerHover: '#F5F5F5',
  error: '#ba000d',
  success: '#4caf50',
  warning: '#ffd54f',
  info: '#0097a7',
  dark: '#212121',
  white: '#ffffff',
  black: '#000000',
  card: '#F5F5F5',
  grey: '#9a9a9a',
  shadow: '#00000038',
  lightgrey: '#F5F5F5',
  disabled: '#d2d2d2',
  header: '#EEEEEE',
  headerBackground: '#E7E7E7',
  headerFontColor: '#000000',
  footer: '#EEEEEE',
  toolbar: '#4711B2',
  toolbarFont: '#828282',
  sideMenu: '#EEEEEE',
  background: '#FCFCFC',
  backgroundOdd: '#FFFFFF',
  color: '#000000',
  modal: '#FFFFFF',
  modalHeader: '#4711B2',
  scrollbar: '#4711B2',
  scrollbarTrack: '#C9C9C9',
  outline: '#b1adff',
  highlightTextLine: '#5264AE',
  copyTextHover: '#F9D795',
  inputBottomLine: '#757575',
  inputLabel: '#696969',
  inputNumberControl: '#ECECEC',
  inputNumberControlHover: '#F5F5F5',
  tooltipColor: '#FFFFFF',
  tooltipBackground: '#0D0C1D',
  typeText: '#c4c4c4',
  typeNumber: '#b2df8a',
  typeDate: '#ffe187',
  controllersLabel: '#f0ecf9',
  fadedBackground: '#000000df',
  // ### General Properties
  popoverBackground: '#FFFFFF',
  notificationCardLabel: '#FF9626',
  notificationCardLabelShadow: '#ff8000',
  selectEven: '#FAFAFA',
  selectOdd: '#E1E1E1',
  selectHover: '#BFBFBF',
  uploadArea: '#F0F0F0',
  stepProgressBase: '#D7D7D7',
  tableBackground: '#FFFFFF',
  bubbleAnswer: '#abd58e',
  bubbleError: '#ffb3b3',
  bubbleQuestion: '#8ab5e2',

  /* Images and Texts */
  logo: <MetricsLogo height="40px" width="160px" />,
  loginLogo: logoMetrics,
  projectName: 'Legal Metrics',
  favicon: favMetrics,

  /* Theme mode */
  theme: 'light',
};

/** Dark theme 'Legal Metrics' */
const darkThemeLM = {
  primary: '#1880A4',
  primaryHover: '#343078',
  primaryDark: '#0D0C1D',
  primaryLight: '#FAFAFA',
  secondary: '#FF6900',
  secondaryHover: '#d67f29',
  secondaryDark: '#ff8000',
  secondaryLight: '#fff6ed',
  container: '#292929',
  containerOdd: '#323232',
  containerLine: '#393939',
  containerLineOdd: '#595959',
  containerHover: '#434343',
  error: '#e74c3c',
  success: '#00bc8c',
  warning: '#f39c12',
  info: '#3498db',
  dark: '#212121',
  white: '#ffffff',
  black: '#000000',
  card: '#292929',
  grey: '#9a9a9a',
  shadow: '#00000038',
  lightgrey: '#F5F5F5',
  disabled: '#595959',
  header: '#EEEEEE',
  headerBackground: '#1F1B24',
  headerFontColor: '#000000',
  footer: '#EEEEEE',
  toolbar: '#4711B2',
  toolbarFont: '#CDC6C6',
  sideMenu: '#121212',
  background: '#121212',
  backgroundOdd: '#323232',
  color: '#FFFFFF',
  modal: '#212121',
  modalHeader: '#4711B2',
  scrollbar: '#1880A4',
  scrollbarTrack: '#C9C9C9',
  outline: '#b1adff',
  highlightTextLine: '#5264AE',
  copyTextHover: '#5264AE',
  inputBottomLine: '#757575',
  inputLabel: '#696969',
  inputNumberControl: '#434343',
  inputNumberControlHover: '#626262',
  tooltipColor: '#000000',
  tooltipBackground: '#FAFAFA',
  typeText: '#484848',
  typeNumber: '#1E5128',
  typeDate: '#3B185F',
  controllersLabel: '#323232',
  fadedBackground: '#585858df',
  // ### General Properties
  popoverBackground: '#212121',
  notificationCardLabel: '#FF6900',
  notificationCardLabelShadow: '#ff8000',
  selectEven: '#323232',
  selectOdd: '#434343',
  selectHover: '#545454',
  uploadArea: '#323232',
  stepProgressBase: '#434343',
  tableBackground: '#292929',
  bubbleAnswer: '#386731',
  bubbleError: '#b12e2e',
  bubbleQuestion: '#2c4469',

  /* Images and Texts */
  logo: <MetricsLogo height="40px" width="160px" />,
  loginLogo: logoMetrics,
  projectName: 'Legal Metrics',
  favicon: favMetrics,

  /* Theme mode */
  theme: 'dark',
};

/** Light theme 'Thomson Reuters' */
const lightThemeTR = {
  primary: '#2771A0',
  primaryHover: '#1a5275',
  primaryDark: '#0D0C1D',
  primaryLight: '#FAFAFA',
  secondary: '#F99738',
  secondaryHover: '#d67f29',
  secondaryDark: '#ff8000',
  secondaryLight: '#fff6ed',
  container: '#FAFAFA',
  containerOdd: '#FFF6ED',
  containerLine: '#B0C4DE',
  containerLineOdd: '#FFA07A',
  containerHover: '#F5F5F5',
  error: '#ba000d',
  success: '#4caf50',
  warning: '#ffd54f',
  info: '#0097a7',
  dark: '#212121',
  white: '#ffffff',
  black: '#000000',
  card: '#F5F5F5',
  grey: '#9a9a9a',
  shadow: '#00000038',
  lightgrey: '#F5F5F5',
  disabled: '#d2d2d2',
  header: '#ff8000',
  headerBackground: '#E7E7E7',
  headerFontColor: '#FFFFFF',
  footer: '#EEEEEE',
  toolbar: '#828282',
  toolbarFont: '#828282',
  sideMenu: '#FFFFFF',
  background: '#FCFCFC',
  backgroundOdd: '#FFFFFF',
  color: '#000',
  modal: '#FFFFFF',
  modalHeader: '#F99738',
  scrollbar: '#666666',
  scrollbarTrack: '#C9C9C9',
  outline: '#a8c5d8',
  highlightTextLine: '#5264AE',
  copyTextHover: '#F9D795',
  inputBottomLine: '#757575',
  inputLabel: '#696969',
  inputNumberControl: '#ECECEC',
  inputNumberControlHover: '#F5F5F5',
  tooltipColor: '#FFFFFF',
  tooltipBackground: '#0D0C1D',
  typeText: '#c4c4c4',
  typeNumber: '#b2df8a',
  typeDate: '#ffe187',
  controllersLabel: '#f0ecf9',
  fadedBackground: '#000000df',
  // ### General Properties
  popoverBackground: '#FFFFFF',
  notificationCardLabel: '#FF9626',
  notificationCardLabelShadow: '#ff8000',
  selectEven: '#FAFAFA',
  selectOdd: '#E1E1E1',
  selectHover: '#BFBFBF',
  uploadArea: '#F0F0F0',
  stepProgressBase: '#D7D7D7',
  tableBackground: '#FFFFFF',
  bubbleAnswer: '#abd58e',
  bubbleError: '#ffb3b3',
  bubbleQuestion: '#8ab5e2',

  /* Images and Texts */
  logo: <ThomsonLogo height="40px" width="160px" titleColor="#666666" />,
  loginLogo: logoThomson,
  projectName: 'Legal One Analytics',
  favicon: favThomson,

  /* Theme mode */
  theme: 'light',
};

/** Dark theme 'Thomson Reuters' */
const darkThemeTR = {
  ...darkThemeLM,
  /* Images and Texts */
  logo: <ThomsonLogo height="40px" width="160px" titleColor="#FFFFFF" />,
  loginLogo: logoThomson,
  projectName: 'Legal One Analytics',
  favicon: favThomson,
};

/** Light theme 'Thomson Reuters' */
const lightThemeBenner = {
  primary: '#4646F0',
  primaryHover: '#3F3FD9',
  primaryDark: '#0D0C1D',
  primaryLight: '#FAFAFA',
  secondary: '#ED1846',
  secondaryHover: '#CC1B41',
  secondaryDark: '#91102C',
  secondaryLight: '#fff6ed',
  container: '#FAFAFA',
  containerOdd: '#FFF6ED',
  containerLine: '#B0C4DE',
  containerLineOdd: '#FFA07A',
  containerHover: '#F5F5F5',
  error: '#ba000d',
  success: '#4caf50',
  warning: '#ffd54f',
  info: '#0097a7',
  dark: '#212121',
  white: '#ffffff',
  black: '#000000',
  card: '#F5F5F5',
  grey: '#9a9a9a',
  shadow: '#00000038',
  lightgrey: '#F5F5F5',
  disabled: '#d2d2d2',
  header: '#91102C',
  headerBackground: '#E7E7E7',
  headerFontColor: '#FFFFFF',
  footer: '#EEEEEE',
  toolbar: '#828282',
  toolbarFont: '#828282',
  sideMenu: '#FFFFFF',
  background: '#FCFCFC',
  backgroundOdd: '#FFFFFF',
  color: '#000000',
  modal: '#FFFFFF',
  modalHeader: '#4646F0',
  scrollbar: '#4646f0',
  scrollbarTrack: '#C9C9C9',
  outline: '#B1ADFF',
  highlightTextLine: '#5264AE',
  copyTextHover: '#F9D795',
  inputBottomLine: '#757575',
  inputLabel: '#696969',
  inputNumberControl: '#ECECEC',
  inputNumberControlHover: '#F5F5F5',
  tooltipColor: '#FFFFFF',
  tooltipBackground: '#0D0C1D',
  typeText: '#c4c4c4',
  typeNumber: '#b2df8a',
  typeDate: '#ffe187',
  controllersLabel: '#f0ecf9',
  fadedBackground: '#000000df',
  // ### General Properties
  popoverBackground: '#FFFFFF',
  notificationCardLabel: '#ED1846',
  notificationCardLabelShadow: '#91102C',
  selectEven: '#FAFAFA',
  selectOdd: '#E1E1E1',
  selectHover: '#BFBFBF',
  uploadArea: '#F0F0F0',
  stepProgressBase: '#D7D7D7',
  tableBackground: '#FFFFFF',
  bubbleAnswer: '#abd58e',
  bubbleError: '#ffb3b3',
  bubbleQuestion: '#8ab5e2',

  /* Images and Texts */
  logo: <BennerLogo height="40px" width="160px" />,
  loginLogo: logoBenner,
  projectName: 'Benner',
  favicon: favBenner,

  /* Theme mode */
  theme: 'light',
};

/** Dark theme 'Thomson Reuters' */
const darkThemeBenner = {
  ...darkThemeLM,
  secondary: '#ED1846',
  secondaryHover: '#CC1B41',
  secondaryDark: '#91102C',
  notificationCardLabel: '#ED1846',
  notificationCardLabelShadow: '#91102C',
  /* Images and Texts */
  logo: <BennerLogo height="40px" width="160px" />,
  loginLogo: logoBenner,
  projectName: 'Benner',
  favicon: favBenner,
};

const theme = (() => {
  switch (process.env.REACT_APP_FIREBASE_PROJECT_LABEL) {
    case 'legalone-analytics':
      return lightThemeTR;

    case 'benner-metrics':
      return lightThemeBenner;

    default:
      return lightThemeLM;
  }
})();

if (document) {
  const fav = document.getElementById('favicon');
  if (fav) {
    document.title = theme.projectName;
    fav.href = theme.favicon;
  }
}

/**
 * CSS snippet to replace the "gap" rule in "display: flex"
 * to be compatible with Safari 13
 * @param {string} gapSize The gap value ("value" + "unit"). Ex: "5px"
 * @param {string} orientation Direction to apply the gap ("row", "column" or "all")
 * @returns CSS snippet (for "Styled Components" script)
 */
export const gap = (gapSize, orientation = 'all') => css`
    & > * + * {
      ${(orientation === 'all' || orientation === 'row') && css`margin-left: ${gapSize};`}
      ${(orientation === 'all' || orientation === 'column') && css`margin-top: ${gapSize};`}
    }
  `;

export const themeModes = (() => {
  switch (process.env.REACT_APP_FIREBASE_PROJECT_LABEL) {
    case 'legalone-analytics':
      return { light: lightThemeTR, dark: darkThemeTR };

    case 'benner-metrics':
      return { light: lightThemeBenner, dark: darkThemeBenner };

    default:
      return { light: lightThemeLM, dark: darkThemeLM };
  }
})();

export default theme;
