const calculateDiv = (value) => {
  const len = Math.trunc(value).toString().length;
  return len === 1 ? 1 : (10 ** (len - 2));
};

export const getBiggestTick = (biggest, styleConfig) => {
  if (!biggest) return 0;
  if (styleConfig?.DataFormat?.type === 'percentual') return 100;
  const div = calculateDiv(biggest);
  return Math.ceil(biggest / 5 / div) * div * 5;
};

export const getSmallestTick = (smallest, styleConfig) => {
  if (!smallest) return 0;
  if (styleConfig?.DataFormat?.type === 'percentual') return 100;
  const div = calculateDiv(smallest);
  return Math.min((Math.floor(smallest / 5 / div) * div * 5), 0);
};
