import styled from 'styled-components';

export const MoreUsersContainer = styled.div`
    //min-height: 50px;
    max-width: 500px;
    padding: 0 1rem;
`;

export const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
`;

export const OptionRow = styled.div`
  padding: 0 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: baseline;
`;

export const CheckboxInputWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const SeparatorLine = styled.hr`
  margin: 1.5rem auto 10px;
  width: 100%;
  border: 0.8px solid #C4C4C4;
`;

export const InputTextLineWrapper = styled.div`
  width: 30%;
  & .inputTxt {
    padding: 5px 0;
  }
`;
