import React, { useState, useRef, useContext } from 'react';
import IdleTimer from 'react-idle-timer';
import firebase from '../../utils/firebase';
// CONTEXTS
import { AuthContext } from '../../context/AuthProvider';
import { AlertContext } from '../../context/AlertProvider';

function Timer() {
  const { currentUser } = useContext(AuthContext);
  const { setAlertConfig } = useContext(AlertContext);

  const idleTimer = useRef(null);
  const [timeout, setTimeout] = useState(60 * 60 * 1000);

  const onIdle = () => {
    if (currentUser) {
      setAlertConfig({
        type: 'warning',
        text: 'Deslogado',
        child: 'Parece que você ficou muito tempo inativo!',
      });
      // toggleAlert();
      firebase.logout();
    }
  };
  const onActive = () => {
    setTimeout(60 * 60 * 1000);
  };
  return (
    <>
      <IdleTimer
        startOnMount
        ref={idleTimer}
        element={document}
        onIdle={onIdle}
        onActive={onActive}
        onAction={() => window.localStorage.setItem('lastActionTime', idleTimer.current?.getLastActiveTime())}
        debounce={250}
        timeout={timeout}
      />
    </>
  );
}
export default Timer;
