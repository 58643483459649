import React from 'react';
import PropTypes from 'prop-types';

const CodeCompare = ({ height, width, fill }) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill={fill} xmlns="http://www.w3.org/2000/svg">
    <path d="M15 22.875C15 23.3203 14.7375 23.7234 14.3344 23.9016C13.9312 24.0797 13.4531 24.0094 13.125 23.7094L9.375 20.3344C9.13594 20.1187 9.00469 19.8188 9.00469 19.5C9.00469 19.1812 9.14062 18.8766 9.375 18.6656L13.125 15.2906C13.4531 14.9953 13.9312 14.9203 14.3344 15.0984C14.7375 15.2766 15 15.6797 15 16.125V18H15.75C17.4047 18 18.75 16.6547 18.75 15V7.18594C17.4234 6.60938 16.5 5.2875 16.5 3.75C16.5 1.67812 18.1781 0 20.25 0C22.3219 0 24 1.67812 24 3.75C24 5.2875 23.0766 6.60938 21.75 7.18594V15C21.75 18.3141 19.0641 21 15.75 21H15V22.875ZM21.375 3.75C21.375 3.45163 21.2565 3.16548 21.0455 2.9545C20.8345 2.74353 20.5484 2.625 20.25 2.625C19.9516 2.625 19.6655 2.74353 19.4545 2.9545C19.2435 3.16548 19.125 3.45163 19.125 3.75C19.125 4.04837 19.2435 4.33452 19.4545 4.5455C19.6655 4.75647 19.9516 4.875 20.25 4.875C20.5484 4.875 20.8345 4.75647 21.0455 4.5455C21.2565 4.33452 21.375 4.04837 21.375 3.75ZM9 1.125C9 0.679688 9.2625 0.276562 9.66562 0.0984375C10.0687 -0.0796875 10.5469 -0.00937501 10.875 0.290625L14.625 3.66562C14.8641 3.88125 14.9953 4.18125 14.9953 4.5C14.9953 4.81875 14.8594 5.12344 14.625 5.33438L10.875 8.70938C10.5469 9.00469 10.0687 9.07969 9.66562 8.90156C9.2625 8.72344 9 8.32031 9 7.875V6H8.25C6.59531 6 5.25 7.34531 5.25 9V16.8141C6.57656 17.3906 7.5 18.7125 7.5 20.25C7.5 22.3219 5.82188 24 3.75 24C1.67812 24 0 22.3219 0 20.25C0 18.7125 0.923438 17.3906 2.25 16.8141V9C2.25 5.68594 4.93594 3 8.25 3H9V1.125ZM2.625 20.25C2.625 20.5484 2.74353 20.8345 2.9545 21.0455C3.16548 21.2565 3.45163 21.375 3.75 21.375C4.04837 21.375 4.33452 21.2565 4.5455 21.0455C4.75647 20.8345 4.875 20.5484 4.875 20.25C4.875 19.9516 4.75647 19.6655 4.5455 19.4545C4.33452 19.2435 4.04837 19.125 3.75 19.125C3.45163 19.125 3.16548 19.2435 2.9545 19.4545C2.74353 19.6655 2.625 19.9516 2.625 20.25Z" />
  </svg>
);

CodeCompare.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  fill: PropTypes.string,
};

CodeCompare.defaultProps = {
  height: '24',
  width: '24',
  fill: 'none',
};

export default CodeCompare;
