import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Checkbox from '../../../juristec-ui/core/Checkbox';
import ActionsGroup from '../../../juristec-ui/core/ActionsGroup';
import Button from '../../../juristec-ui/core/Button';

import {
  MoreUsersContainer,
  OptionsContainer,
  OptionRow,
  CheckboxInputWrapper,
  SeparatorLine,
  InputTextLineWrapper,
} from './styled/MoreUsers.styled';
import InputTextLine from '../../../juristec-ui/core/InputTextLine';

/**
 * A component to request the addition of more users to the instance
 */
const MoreUsers = ({ hide, submitData }) => {
  const [checkedGroupList, setCheckedGroupList] = useState({
    super: false,
    scientist: false,
    guest: false,
  });
  const [superQtd, setSuperQtd] = useState(1);
  const [scientistQtd, setScientistQtd] = useState(1);
  const [guestQtd, setGuestQtd] = useState(1);

  const handleCheckBox = (check, name) => {
    setCheckedGroupList((p) => {
      const aux = { ...p };
      aux[name] = check;
      return aux;
    });
  };

  const handleSubmit = () => {
    const data = {};
    if (checkedGroupList.super) data.super = superQtd;
    if (checkedGroupList.scientist) data.scientist = scientistQtd;
    if (checkedGroupList.guest) data.guest = guestQtd;
    submitData(data);
  };

  return (
    <>
      <MoreUsersContainer>
        <OptionsContainer>
          <OptionRow>
            <CheckboxInputWrapper>
              <Checkbox
                text="Adicionar usuário proprietário"
                color="secondary"
                variant="outlined"
                checked={checkedGroupList.super}
                handleCheckboxChange={(check) => handleCheckBox(check, 'super')}
              />
            </CheckboxInputWrapper>
            <InputTextLineWrapper>
              <InputTextLine
                type="number"
                disabled={!checkedGroupList.super}
                value={superQtd}
                onChange={(e) => setSuperQtd(parseInt(e.target.value, 10))}
                onBlur={(e) => e.target.value || setSuperQtd(1)}
              />
            </InputTextLineWrapper>
          </OptionRow>
          <OptionRow>
            <CheckboxInputWrapper>
              <Checkbox
                text="Adicionar usuário cientista"
                color="secondary"
                variant="outlined"
                checked={checkedGroupList.scientist}
                handleCheckboxChange={(check) => handleCheckBox(check, 'scientist')}
              />
            </CheckboxInputWrapper>
            <InputTextLineWrapper>
              <InputTextLine
                type="number"
                disabled={!checkedGroupList.scientist}
                value={scientistQtd}
                onChange={(e) => setScientistQtd(parseInt(e.target.value, 10))}
                onBlur={(e) => e.target.value || setScientistQtd(1)}
              />
            </InputTextLineWrapper>
          </OptionRow>
          <OptionRow>
            <CheckboxInputWrapper>
              <Checkbox
                text="Adicionar usuário convidado"
                color="secondary"
                variant="outlined"
                checked={checkedGroupList.guest}
                handleCheckboxChange={(check) => handleCheckBox(check, 'guest')}
              />
            </CheckboxInputWrapper>
            <InputTextLineWrapper>
              <InputTextLine
                type="number"
                disabled={!checkedGroupList.guest}
                value={guestQtd}
                onChange={(e) => setGuestQtd(parseInt(e.target.value, 10))}
                onBlur={(e) => e.target.value || setGuestQtd(1)}
              />
            </InputTextLineWrapper>
          </OptionRow>
        </OptionsContainer>
        <SeparatorLine />
        <div style={{ textAlign: 'justify' }}>
          <span>
            Você está solicitando a inclusão de usuários adicionais. Isso
            implicará em alterações no seu plano atual. Entraremos em contato
            para confirmar sua solicitação antes de executar a alteração. Tem
            certeza que deseja realizar a solicitação?
          </span>
        </div>
      </MoreUsersContainer>
      <ActionsGroup>
        <Button
          style={{ margin: '5px' }}
          onClick={hide}
          variant="outlined"
          size="large"
        >
          Cancelar
        </Button>
        <Button
          style={{ margin: '5px' }}
          size="large"
          disabled={
            !(checkedGroupList.super || checkedGroupList.scientist || checkedGroupList.guest)
          }
          onClick={handleSubmit}
        >
          Solicitar
        </Button>
      </ActionsGroup>
    </>
  );
};

MoreUsers.propTypes = {
  /**
  * A function that sends the new data back to the parent
  */
  submitData: PropTypes.func.isRequired,
  /**
    * A function that closes the modal
    */
  hide: PropTypes.func.isRequired,
};

export default MoreUsers;
