import React, {
  useContext, useEffect, useState,
} from 'react';
import { useTheme } from 'styled-components';

//
import Header from '../Header';
import JiraHelpDesk from '../Popovers/JiraHelpDesk';
//
import Loader from '../../juristec-ui/core/Loader';
import MfaLogin from '../ProgressiveModals/MfaLogin';
import ReAuthentication from '../Modals/ReAuthentication';
import RegisterPhone from '../ProgressiveModals/RegisterPhone';

import firebase from '../../utils/firebase';
import Multifactor from '../../utils/multifactor';
import useRecaptchaV2 from '../../hooks/useRecaptchaV2';
import useJiraHelpDesk from '../../hooks/useJiraHelpDesk';
// import useWebChatTR from '../../hooks/useWebChatTR';

import { AuthContext } from '../../context/AuthProvider';
import { AlertContext } from '../../context/AlertProvider';
import { StyleContext } from '../../context/StyleProvider';
import { ModalContext } from '../../context/ModalProvider';
import { NotificationsContext } from '../../context/NotificationProvider';

import {
  verifyFileImgType,
  verifyFileSize,
} from '../../juristec-ui/utils/validators/fileValidators';

import { getRefCompany, getRefUser } from '../../utils/firestore';
import handleLoginErrors from '../../utils/firestoreErrors/HandleLoginErrors';
import { supportEmail } from '../../options';
import IconButton from '../../juristec-ui/core/IconButton/IconButton';
import { Bell } from '../../juristec-ui/icons';

const imageSizeLimit = 1000000;

const SwitchHeader = () => {
  const {
    currentUser,
    userCompany,
    setCompanyPhotoUrl,
    user: currentUserInfo,
    claimsUser,
  } = useContext(AuthContext);

  const theme = useTheme();

  const v2Verifier = useRecaptchaV2();

  const { setAlertConfig, toggleAlert } = useContext(AlertContext);
  const { setModalConfig, closeModal } = useContext(ModalContext);
  const { state: styleState, styleAPI } = useContext(StyleContext);
  const { state: notificationsState, notificationsAPI } = useContext(NotificationsContext);

  const [showNotifications, setShowNotifications] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // Temporário para exibir a imagem alterada sem recarregar
  const [companyLogo, setCompanyLogo] = useState(theme.loginLogo);
  const [supportState, supportApi] = useJiraHelpDesk(currentUser);
  // useWebChatTR();

  useEffect(() => {
    if (userCompany?.photoUrl) setCompanyLogo(userCompany.photoUrl);
  }, [userCompany]);

  useEffect(() => {
    styleAPI.init();
    if (process.env.REACT_APP_FIREBASE_PROJECT_LABEL !== 'legalone-analytics') {
      supportApi.getSupportInfo();
    }
  }, []);

  const riskyChanges = (afterLogin, showFeedback = true) => {
    const reAuthToSave = (currentPassword) => {
      setIsLoading(true);
      firebase.reAuthentication(currentUser.email, currentPassword)
        .then(() => {
          afterLogin();
        })
        .then(() => {
          setIsLoading(false);
          if (showFeedback) {
            setAlertConfig({
              type: 'success',
              text: 'Alterações feitas com sucesso',
            });
          }
        })
        .catch((err) => {
          setIsLoading(false);
          if (err.code === 'auth/multi-factor-auth-required') {
            const mfa = new Multifactor(v2Verifier.getVerifier(), err)
              .success(() => {
                afterLogin()
                  .then(() => {
                    setIsLoading(false);
                    if (showFeedback) {
                      setAlertConfig({
                        type: 'success',
                        text: 'Alterações feitas com sucesso',
                      });
                    }
                  });
              })
              .error((er) => {
                const { text, message } = handleLoginErrors(er);
                setIsLoading(false);
                setAlertConfig({
                  type: 'error',
                  text,
                  child: message,
                });
              });
            /* Modal sendCode function */
            setModalConfig({
              title: 'Autenticação multifator',
              children: (
                <MfaLogin
                  hide={closeModal}
                  mfa={mfa}
                  setLoading={setIsLoading}
                />
              ),
            });
          } else {
            const { text, message } = handleLoginErrors(err);
            setIsLoading(false);
            setAlertConfig({
              type: 'error',
              text,
              child: message,
            });
          }
        });
    };
    setAlertConfig({
      type: 'warning',
      text: 'Operações críticas',
      child: <ReAuthentication close={toggleAlert} submitPassword={reAuthToSave} />,
      withoutConfirm: true,
    });
  };

  const saveProfileChanges = (changes) => {
    switch (changes.type) {
      case 'profile': {
        if (changes.userName) {
          setIsLoading(true);
          getRefUser(currentUser.uid)
            .update({ name: changes.userName })
            .then(() => firebase.updateProfile({ displayName: changes.userName }))
            .then(() => {
              setAlertConfig({
                type: 'success',
                text: 'Alterações feitas com sucesso',
              });
              setIsLoading(false);
            })
            .catch((err) => {
              setAlertConfig({ type: 'error', text: `Erro inexperado: ${err}` });
              setIsLoading(false);
            });
        }
        break;
      }
      case 'password': {
        if (changes.newPassword) {
          riskyChanges(async () => {
            firebase.updatePassword(changes.newPassword);
          });
        }
        break;
      }
      default:
        break;
    }
  };

  const uploadProfileImage = async (file) => {
    const path = `companies/${userCompany.id}/images/${currentUser.uid}/`;
    return firebase.uploadImageOverwrite(path, file, 'profileImg');
  };

  const uploadCompanyImage = async (file) => {
    const path = `companies/${userCompany.id}/logo/`;
    return firebase.uploadImageOverwrite(path, file, 'companyLogo');
  };

  const saveProfilePic = (image) => {
    getRefUser(currentUser.uid)
      .update({ photoUrl: image })
      .then(() => firebase.updateProfile({ photoURL: image }))
      .then(() => {
        // URL.revokeObjectURL(image);
        setIsLoading(false);
        setAlertConfig({
          type: 'success',
          text: 'Alterações feitas com sucesso',
        });
      })
      .catch((err) => {
        setIsLoading(false);
        setAlertConfig({ type: 'error', text: `Erro inesperado: ${err}` });
      });
  };

  const saveCompanyPic = (image) => {
    getRefCompany(userCompany.id)
      .update({ photoUrl: image })
      .then(() => {
        setCompanyPhotoUrl(image);
        // URL.revokeObjectURL(image);
        setIsLoading(false);
        setCompanyLogo(image);
        setAlertConfig({
          type: 'success',
          text: 'Alterações feitas com sucesso',
        });
      })
      .catch((err) => {
        setIsLoading(false);
        setAlertConfig({ type: 'error', text: `Erro inesperado: ${err}` });
      });
  };

  const checkFileIsImg = (file) => {
    const errorMsg = verifyFileImgType(file) || verifyFileSize(file, imageSizeLimit);
    if (errorMsg.length > 0) {
      setAlertConfig({ type: 'error', text: errorMsg });
      return false;
    }
    return true;
  };

  const changeAvatarFile = async (image) => {
    setIsLoading(true);
    if (typeof image === 'string') {
      saveProfilePic(image);
    } else if (checkFileIsImg(image)) {
      const url = await uploadProfileImage(image);
      saveProfilePic(url);
    } else {
      setIsLoading(false);
    }
  };

  const handleLogoChange = async (e) => {
    const file = e.target.files[0];
    if (file && checkFileIsImg(file)) {
      setIsLoading(true);
      const url = await uploadCompanyImage(file);
      saveCompanyPic(url);
    }
  };

  const logout = async () => {
    await firebase.logout();
  };

  const confirmLogout = () => {
    setAlertConfig({
      type: 'warning',
      child: 'Deseja mesmo deslogar?',
      text: 'Tem certeza?',
      withFunction: true,
      withoutConfirm: true,
      confirmFunction: logout,
    });
  };

  const getMultifactors = () => firebase.getMfaUser().enrolledFactors;

  const newMfaNumber = () => {
    riskyChanges(async () => {
      const mfa = new Multifactor(v2Verifier.getVerifier())
        .success(() => {
          setIsLoading(false);
          closeModal();
          setAlertConfig({
            type: 'success',
            text: 'Número adicionado com sucesso',
          });
        })
        .error((er) => {
          const { text, message } = handleLoginErrors(er);
          setIsLoading(false);
          setAlertConfig({
            type: 'error',
            text,
            child: message,
          });
        });
      setModalConfig({
        title: 'Adicionar multifator',
        children: (
          <RegisterPhone
            hide={closeModal}
            mfa={mfa}
            setLoading={setIsLoading}
          />
        ),
      });
    }, false);
  };

  const delMfaNumber = async (selectedFactor) => {
    const mfa = firebase.getMfaUser();

    riskyChanges(async () => mfa.unenroll(selectedFactor));
  };

  const handleSupport = async (message, summary) => {
    setIsLoading(true);
    const res = await supportApi.newTicket(message, summary, supportState.endpoint);
    setIsLoading(false);
    if (res.error) {
      setAlertConfig({
        type: 'error',
        text: res.msg,
        child: `Por favor, entre em contato diretamente pelo email: ${supportEmail()}`,
      });
      return false;
    }
    setAlertConfig({
      type: 'success',
      text: 'Chamado de suporte realizado com sucesso!',
      child: (
        <span>
          Obrigado por entrar em contato! Logo, você receberá a confirmação do chamado em seu email.
          {' '}
          O acompanhamento do seu atendimento poderá ser feito pelo link enviado no seu e-mail ou através do ícone
          {' '}
          <IconButton style={{ cursor: 'default' }}>
            <Bell />
          </IconButton>
          {' '}
          localizado no canto superior direito do sistema!
        </span>
      ),
    });
    return true;
  };

  const checkNotifRes = (res) => {
    if (res.error) {
      setAlertConfig({
        type: 'error',
        text: res.msg,
        child: res.raw,
      });
    }
  };

  const getNotifications = async () => {
    const res = await notificationsAPI.getInitNotifications();
    checkNotifRes(res);
  };

  const moreNotifications = async () => {
    const offSet = notificationsState.lastElement;
    const res = await notificationsAPI.getMoreNotifications(offSet);
    checkNotifRes(res);
  };

  const newNotifications = async (qty) => {
    const res = await notificationsAPI.getNewNotifications(qty);
    checkNotifRes(res);
  };

  useEffect(() => {
    if (showNotifications && !notificationsState?.notifications) {
      getNotifications();
    }
  }, [showNotifications, notificationsState?.notifications?.length]);

  useEffect(() => {
    if (showNotifications
      && notificationsState.notificationsCount > 0
      && notificationsState.isReady) {
      newNotifications(notificationsState.notificationsCount);
    }
  }, [
    showNotifications,
    notificationsState.notificationsCount,
    notificationsState.isReady,
  ]);

  useEffect(() => {
    if (!showNotifications) {
      notificationsAPI.setLocalViewed();
    }
  }, [showNotifications]);

  useEffect(() => {
    if (!notificationsState.detachListener) {
      (async () => {
        await notificationsAPI.initListener();
        await notificationsAPI.getSystemMessages();
      })();
    }
    return () => {
      if (notificationsState.detachListener) notificationsState.detachListener();
    };
  }, [notificationsState.detachListener]);

  return (
    <>
      {(isLoading || styleState.isLoading) && <Loader />}
      <Header
        user={currentUser}
        userInfo={currentUserInfo}
        userMultifactors={getMultifactors}
        userIsAdmin={claimsUser?.is_admin}
        clientLogo={companyLogo}
        nightMode={styleState.themeMode === 'dark'}
        setNightMode={styleAPI.toggleThemeMode}
        handleProfileChanges={saveProfileChanges}
        handleAvatarChange={changeAvatarFile}
        handleLogout={confirmLogout}
        handleLogoChange={handleLogoChange}
        checkImage={checkFileIsImg}
        newMfaNumber={newMfaNumber}
        delMfaNumber={delMfaNumber}
        moreNotifs={moreNotifications}
        notifsStates={notificationsState}
        showNotifs={showNotifications}
        handleNotifsPop={setShowNotifications}
        bigDataAccess={userCompany?.bigData}
      />
      <JiraHelpDesk submit={handleSupport} summaries={supportState.summaries} />
    </>
  );
};

export default SwitchHeader;
