import React, {
  useState, useEffect, useRef, useContext,
} from 'react';
import PropTypes from 'prop-types';
import InputTextLine from '../../../juristec-ui/core/InputTextLine';
import Tooltip from '../../../juristec-ui/core/Tooltip';
import Button from '../../../juristec-ui/core/Button';
import ActionsGroup from '../../../juristec-ui/core/ActionsGroup';
import IconButton from '../../../juristec-ui/core/IconButton';
import Select from '../../../juristec-ui/core/Select';

// import DashboardColor from '../DashboardColor';
import ColorPicker from '../../../juristec-ui/core/ColorPicker2';
import UnsplashPicker from '../../../juristec-ui/core/UnsplashPicker';
import {
  Close, Finder, UploadImage, BackgroundColor, LineColor,
  Bold, Italic, Underline, FontColor,
} from '../../../juristec-ui/icons';
import { ModalContext } from '../../../context/ModalProvider';
import { verifyInput } from '../../../juristec-ui/utils/validators/inputTextValidators';

import {
  NewDashboardContainer,
  FormLine,
  InputWrapper,
  ColorPickerContainer,
  ImageOverlay,
  ImageContainer,
  CardContainer,
  CardHeader,
  CardMain,
  BackgroundOptions,
  ModalContainer,
  TitleBasicStyles,
} from './styled/NewDashboard.styled';

/**
* A component to inform the attributes of a new dashboard
*/
const NewDashboard = ({
  close,
  toEditDashboard,
  editDashboard,
  createNewDashboard,
  groupNameList,
  currentUser,
  tourContext,
  checkImage,
  isEdit,
}) => {
  const [dashboardGroup, setDashboardGroup] = useState({ label: '', value: '' });
  const [dashboardName, setDashboardName] = useState({ value: '', error: true, errorMsg: '' });
  const [dashboardImg, setDashboardImg] = useState('');
  const [imgFile, setImgFile] = useState(null);
  const [colors, setColors] = useState({ mainColor: '', headerColor: '', titleColor: '#000000' });
  const [titleDecoration, setTitleDecoration] = useState({
    bold: false,
    italic: false,
    underline: false,
  });
  const { setModalConfig, toggleModal } = useContext(ModalContext);

  const wrapperRef = useRef(null);
  const inputFile = useRef(null);
  const inputRef = useRef(null);
  const cron = useRef();

  const handleCreate = (newOpt) => {
    setDashboardGroup({ label: newOpt, value: newOpt });
    groupNameList.push({ label: newOpt, value: newOpt });
  };

  const dashboardGroupHandle = (val) => {
    if (typeof (val) === 'object') {
      setDashboardGroup(val);
    }
  };

  const dashboardNameHandle = (val) => {
    const msg = verifyInput(val, true, 2, 40);
    setDashboardName({
      value: val,
      error: msg.length !== 0,
      errorMsg: msg,
    });
  };

  const handleSubmit = async () => {
    if (!dashboardName.error && dashboardName.value) {
      const dashInfos = {
        group: dashboardGroup,
        name: dashboardName.value,
        image: imgFile,
        owner: currentUser.id || currentUser.uid,
        displayName: currentUser.name,
        status: 'active',
        mainColor: colors.mainColor || '#FFFFFF',
        headerColor: colors.headerColor || '#ABB8C3',
        titleColor: colors.titleColor,
        titleDecorationStyles: titleDecoration,
      };

      if (isEdit) {
        await editDashboard(toEditDashboard.id, toEditDashboard.sharedWith, dashInfos);
      } else {
        await createNewDashboard(dashInfos);
      }
      if (tourContext.tourOpen) tourContext.nextStep();
    } else {
      dashboardNameHandle(dashboardName.value);
    }
  };

  const handleImgPick = (url) => {
    setImgFile(url);
    setDashboardImg(url);
    setColors((p) => ({ ...p, mainColor: '' }));
  };

  const handleUpload = (e) => {
    URL.revokeObjectURL(dashboardImg);
    const file = e.target.files[0];
    if (file && checkImage(file)) {
      setImgFile(file);
      setDashboardImg(URL.createObjectURL(file));
      setColors((p) => ({ ...p, mainColor: '' }));
      if (tourContext.tourOpen) tourContext.nextStep();
    }
  };

  const removeImg = () => {
    setImgFile(null);
    URL.revokeObjectURL(dashboardImg);
    setDashboardImg('');
  };

  const handleColorPicker = (val, key) => {
    clearTimeout(cron.current);
    cron.current = setTimeout(() => {
      setColors((p) => ({ ...p, [key]: val }));
    }, 200);
  };

  const handleTitleDecoration = (key) => {
    setTitleDecoration((p) => ({ ...p, [key]: !p[key] }));
  };

  const openUnsplashPicker = () => {
    if (tourContext.tourOpen) tourContext.remakeTourList('searchImage');
    setModalConfig({
      title: 'Selecionar Fotos',
      className: 'unsplash-modal',
      children: (
        <UnsplashPicker
          closeModal={() => toggleModal('unsplash-picker-modal')}
          selectImg={handleImgPick}
          defaultSearch="Charts"
          tourContext={tourContext}
        />
      ),
      nodeTarget: 'unsplash-picker-modal',
    });
  };

  useEffect(() => {
    if (isEdit) {
      const {
        name,
        group,
        mainColor,
        headerColor,
        titleColor,
        titleDecorationStyles,
        image,
      } = toEditDashboard;
      setDashboardName({ value: name, error: false, errorMsg: '' });
      setDashboardGroup({ label: group.label || '', value: group.label || '' });
      setColors({
        mainColor: mainColor || '',
        headerColor: headerColor || '',
        titleColor: titleColor || '#000000',
      });
      setTitleDecoration({
        bold: Boolean(titleDecorationStyles?.bold),
        italic: Boolean(titleDecorationStyles?.italic),
        underline: Boolean(titleDecorationStyles?.underline),
      });
      setImgFile(image);
      setDashboardImg(image);
    }
  }, [toEditDashboard]);

  useEffect(() => () => {
    URL.revokeObjectURL(dashboardImg);
  }, []);

  return (
    <>
      <ModalContainer>
        <NewDashboardContainer>
          <FormLine>
            <InputWrapper>
              <Select
                selectLabel="Selecione um grupo"
                placeholder=""
                isSearchable
                isCreatable
                fullWidth
                options={groupNameList}
                placement="start"
                value={dashboardGroup}
                onChange={dashboardGroupHandle}
                onCreateOption={handleCreate}
                tourContext={tourContext}
                atModal
              />
            </InputWrapper>

            <ColorPickerContainer className="dash_color_icon">
              <Tooltip text="Cor do grupo" direction="left" atModal>
                <ColorPicker
                  color={colors.headerColor || '#ABB8C3'}
                  onChange={(val) => handleColorPicker(val, 'headerColor')}
                  isIconButton
                  atModal
                  className="color-picker-popover"
                  tourContext={tourContext}
                >
                  <LineColor />
                </ColorPicker>
              </Tooltip>
            </ColorPickerContainer>
          </FormLine>
          <FormLine>
            <InputWrapper>
              <InputTextLine
                ref={inputRef}
                errorMessage={dashboardName.errorMsg}
                error={dashboardName.errorMsg.length > 0}
                name="DashboardName"
                label="Nome do Dashboard"
                type="text"
                value={dashboardName.value}
                setValue={dashboardNameHandle}
                emojiPicker
                emojiPickerPosition="top-end"
                onChange={(e) => dashboardNameHandle(e.target.value)}
              />
            </InputWrapper>
          </FormLine>
          <FormLine style={{ justifyContent: 'space-between', marginTop: '5px' }}>
            <TitleBasicStyles>
              <Tooltip text="Negrito" direction="left" atModal>
                <IconButton
                  variant={titleDecoration.bold ? 'contained' : 'outlined'}
                  color="primary"
                  shape="rounded"
                  onClick={() => handleTitleDecoration('bold')}
                >
                  <Bold />
                </IconButton>
              </Tooltip>
              <Tooltip text="Itálico" direction="left" atModal>
                <IconButton
                  variant={titleDecoration.italic ? 'contained' : 'outlined'}
                  color="primary"
                  shape="rounded"
                  onClick={() => handleTitleDecoration('italic')}
                >
                  <Italic />
                </IconButton>
              </Tooltip>
              <Tooltip text="Sublinhado" direction="left" atModal>
                <IconButton
                  variant={titleDecoration.underline ? 'contained' : 'outlined'}
                  color="primary"
                  shape="rounded"
                  onClick={() => handleTitleDecoration('underline')}
                >
                  <Underline />
                </IconButton>
              </Tooltip>
            </TitleBasicStyles>
            <ColorPickerContainer>
              <Tooltip text="Cor do nome" direction="left" atModal>
                <ColorPicker
                  color={colors.titleColor || '#000000'}
                  onChange={(val) => handleColorPicker(val, 'titleColor')}
                  isIconButton
                  atModal
                  className="color-picker-popover"
                  tourContext={tourContext}
                >
                  <FontColor />
                </ColorPicker>
              </Tooltip>
            </ColorPickerContainer>
          </FormLine>
        </NewDashboardContainer>
        <input
          type="file"
          ref={inputFile}
          accept="image/*"
          onChange={handleUpload}
          style={{ display: 'none' }}
        />
        <CardContainer
          bgColor={colors.mainColor || '#FFFFFF'}
          bgSrc={dashboardImg}
          className="pickImage"
          stopAnimation
          ref={wrapperRef}
          // onClick={() => setShowImgOpts(true)}
          // onMouseOver={() => setShowImgOpts(true)}
        >
          <CardHeader bgColor={colors.headerColor || '#ABB8C3'}>
            <h5>
              {dashboardGroup.label?.length > 0 ? dashboardGroup.label : 'Sem Grupo'}
            </h5>
          </CardHeader>
          <CardMain bgColor={colors.mainColor || '#FFFFFF'}>
            <div style={{
              position: 'absolute', top: '35%', transform: 'translateY(-50%)', zIndex: 10000,
            }}
            >
              <span
                style={{
                  color: colors.titleColor,
                  fontStyle: titleDecoration.italic ? 'italic' : 'normal',
                  fontWeight: titleDecoration.bold ? 'bold' : 'normal',
                  textDecoration: titleDecoration.underline ? 'underline' : 'none',
                }}
              >
                {dashboardName.value}
              </span>
            </div>
            <ImageContainer>
              <ImageOverlay>
                <BackgroundOptions>
                  <Tooltip text="Adicionar nova imagem" direction="left" atModal>
                    <IconButton
                      onClick={() => inputFile.current.click()}
                      color="white"
                      size="medium"
                      className="image_trigger"
                    >
                      <UploadImage />
                    </IconButton>
                  </Tooltip>
                  <Tooltip text="Buscar fotos na web" direction="left" atModal>
                    <IconButton
                      color="white"
                      size="medium"
                      onClick={openUnsplashPicker}
                    >
                      <Finder />
                    </IconButton>
                  </Tooltip>
                  {dashboardImg.length > 0 ? (
                    <Tooltip text="Remover imagem atual" direction="right" atModal>
                      <IconButton
                        onClick={removeImg}
                        color="white"
                        size="medium"
                        className="fix-icon"
                      >
                        <Close />
                      </IconButton>
                    </Tooltip>
                  ) : (
                    <Tooltip text="Cor sólida" direction="left" atModal>
                      <ColorPicker
                        variant="pattern"
                        shape="circle"
                        color={colors.mainColor || '#FFFFFF'}
                        staticButtonColor="white"
                        onChange={(val) => handleColorPicker(val, 'mainColor')}
                        isIconButton
                        atModal
                        direction="right-end"
                      >
                        <BackgroundColor />
                      </ColorPicker>
                    </Tooltip>
                  )}
                </BackgroundOptions>
              </ImageOverlay>
            </ImageContainer>
          </CardMain>
        </CardContainer>
      </ModalContainer>

      <ActionsGroup>
        <Button
          style={{ margin: '5px' }}
          onClick={close}
          variant="outlined"
          size="large"
        >
          Cancelar
        </Button>
        <Button
          style={{ margin: '5px' }}
          onClick={handleSubmit}
          size="large"
        >
          {!isEdit ? 'Criar' : 'Salvar'}
        </Button>
      </ActionsGroup>

    </>
  );
};

NewDashboard.propTypes = {
  /**
    * Array with the names of the current groups
    */
  groupNameList: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  /**
    * Function that sends the new dashboard attributes back to the parent
    */
  createNewDashboard: PropTypes.func,

  /**
   *  Function that sends the infos to edit the targeted dashboard
   */
  editDashboard: PropTypes.func,

  /**
   * Function to close the modal or toggle the component visibility
   */
  close: PropTypes.func,

  /**
   * Boolean whether is to edit a component or to create a new one
   */
  isEdit: PropTypes.bool,

  currentUser: PropTypes.shape({
    id: PropTypes.string,
    uid: PropTypes.string,
    name: PropTypes.string,
  }),

  toEditDashboard: PropTypes.shape({
    name: PropTypes.string,
    group: PropTypes.objectOf(PropTypes.string),
    mainColor: PropTypes.string,
    headerColor: PropTypes.string,
    titleColor: PropTypes.string,
    image: PropTypes.string,
    id: PropTypes.string,
    sharedWith: PropTypes.objectOf(PropTypes.shape({})),
    titleDecorationStyles: PropTypes.shape({
      bold: PropTypes.bool,
      italic: PropTypes.bool,
      underline: PropTypes.bool,
    }),
  }),

  tourContext: PropTypes.shape({
    tourOpen: PropTypes.bool,
    nextStep: PropTypes.func,
    remakeTourList: PropTypes.func,
  }),
  /**
  *  Function to check if an image satisfies the desired conditions
  */
  checkImage: PropTypes.func,
};

NewDashboard.defaultProps = {
  groupNameList: [],
  tourContext: { tourOpen: false, nextStep: () => {}, remakeTourList: () => {} },
  createNewDashboard: () => {},
  editDashboard: () => {},
  close: () => {},
  isEdit: false,
  toEditDashboard: {
    titleColor: '#000000',
    titleDecorationStyles: {
      bold: false,
      italic: false,
      underline: false,
    },
  },
  currentUser: { },
  checkImage: () => {},
};

export default NewDashboard;
