/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';

import ControllersGroup from '../../../controllers/ControllersGroup';
import {
  InputLineController,
  ToggleSwitchController,
  SelectController,
  RangeController,
  ChoiceBoxController,
  CustomSortController,
} from '../../../controllers/Base';

const GeneralControllers = ({
  handle,
  config,
  data,
  extend,
  selectedLine,
  setExtend,
}) => (
  <ControllersGroup
    title="Opções Gerais"
    id="general"
    key="general"
    extend={extend}
    setExtend={setExtend}
    sizeIncrease={config?.SortValuesControl === 'Customizado' ? data?.index?.length : 0}
  >
    {selectedLine && (
      <>
        <SelectController
          label="Ordenar campos"
          handle={handle}
          kWord="SortValuesControl"
          options={[
            { label: 'Automático', value: 'Não ordenado', id: 'nao-ordenado' },
            { label: 'Alfabética A-Z', value: 'Alfabética A-Z', id: 'alfabetica-a-z' },
            { label: 'Alfabética Z-A', value: 'Alfabética Z-A', id: 'alfabetica-z-a' },
            { label: 'Customizado', value: 'Customizado', id: 'customizado' },
          ]}
          configValue={config?.SortValuesControl}
          fullWidth
        />
        {config?.SortValuesControl === 'Customizado' && (
          <CustomSortController
            handle={handle}
            kWord="CustomSortControlUpt"
            oldOrder={[...data?.index.reduce((aux, item) => {
              aux.add(typeof item === 'string' ? item : item[item.length - 1]);
              return aux;
            }, new Set())] || []}
            newOrder={config?.CustomSortControl?.kpiCategories}
            // removeTotal
          />
        )}
      </>
    )}
    <RangeController
      label="Margem Superior"
      handle={handle}
      kWord="MarginTop"
      configValue={config?.Margin?.top}
      min={0}
      max={500}
      step={1}
      precision={0}
      fullWidth
    />
    <RangeController
      label="Margem Inferior"
      handle={handle}
      kWord="MarginBottom"
      configValue={config?.Margin?.bottom}
      min={0}
      max={500}
      step={1}
      precision={0}
      fullWidth
    />
    <RangeController
      label="Margem Esquerda"
      handle={handle}
      kWord="MarginLeft"
      configValue={config?.Margin?.left}
      min={0}
      max={500}
      step={1}
      precision={0}
      fullWidth
    />
    <RangeController
      label="Margem Direita"
      handle={handle}
      kWord="MarginRight"
      configValue={config?.Margin?.right}
      min={0}
      max={500}
      step={1}
      precision={0}
      fullWidth
    />
    <ToggleSwitchController
      label="Valor mínimo horizontal"
      handle={handle}
      kWord="MinValueControlX"
      configValue={config?.MinValueControlX}
      firstOption="Automático"
      secondOption="Manual"
      secondColor="secondary"
    />
    <InputLineController
      isNumber
      precision={2}
      label=""
      handle={handle}
      kWord="MinValueX"
      configValue={config?.MinValueX}
      disabled={config?.MinValueControlX}
    />
    <ToggleSwitchController
      label="Valor máximo horizontal"
      handle={handle}
      kWord="MaxValueControlX"
      configValue={config?.MaxValueControlX}
      firstOption="Automático"
      secondOption="Manual"
      secondColor="secondary"
    />
    <InputLineController
      isNumber
      precision={2}
      label=""
      handle={handle}
      kWord="MaxValueX"
      configValue={config?.MaxValueX}
      disabled={config?.MaxValueControlX}
    />
    <ToggleSwitchController
      label="Valor mínimo vertical"
      handle={handle}
      kWord="MinValueControlY"
      configValue={config?.MinValueControlY}
      firstOption="Automático"
      secondOption="Manual"
      secondColor="secondary"
    />
    <InputLineController
      isNumber
      precision={2}
      label=""
      handle={handle}
      kWord="MinValueY"
      configValue={config?.MinValueY}
      disabled={config?.MinValueControlY}
    />
    <ToggleSwitchController
      label="Valor máximo vertical"
      handle={handle}
      kWord="MaxValueControlY"
      configValue={config?.MaxValueControlY}
      firstOption="Automático"
      secondOption="Manual"
      secondColor="secondary"
    />
    <InputLineController
      isNumber
      precision={2}
      label=""
      handle={handle}
      kWord="MaxValueY"
      configValue={config?.MaxValueY}
      disabled={config?.MaxValueControlY}
    />
    <ChoiceBoxController
      label="Escala horizontal"
      handle={handle}
      kWord="ValueScaleControlX"
      configValue={config?.ValueScaleControlX}
      options={[
        { label: 'linear', value: 'linear', id: 'linear' },
        { label: 'logarítmica', value: 'symlog', id: 'symlog' },
      ]}
      fullWidth
    />
    <ChoiceBoxController
      label="Escala vertical"
      handle={handle}
      kWord="ValueScaleControlY"
      configValue={config?.ValueScaleControlY}
      options={[
        { label: 'linear', value: 'linear', id: 'linear' },
        { label: 'logarítmica', value: 'symlog', id: 'symlog' },
      ]}
      fullWidth
    />
  </ControllersGroup>
);

GeneralControllers.propTypes = {
  config: PropTypes.objectOf(PropTypes.any),
  data: PropTypes.objectOf(PropTypes.any),
  handle: PropTypes.func,
  extend: PropTypes.string,
  setExtend: PropTypes.func,
  selectedLine: PropTypes.objectOf(PropTypes.any),
};

GeneralControllers.defaultProps = {
  config: undefined,
  data: undefined,
  handle: () => {},
  extend: '',
  setExtend: () => {},
  selectedLine: undefined,
};

export default GeneralControllers;
