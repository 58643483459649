import React from 'react';

// import { Container } from './styles';

function Success() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="white" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.15146 20.5971L0.351457 12.7971C-0.117152 12.3285 -0.117152 11.5687 0.351457 11.1L2.04847 9.40297C2.51708 8.93431 3.27693 8.93431 3.74554 9.40297L8.99999 14.6574L20.2544 3.40297C20.7231 2.93436 21.4829 2.93436 21.9515 3.40297L23.6485 5.10003C24.1171 5.56864 24.1171 6.32844 23.6485 6.7971L9.84852 20.5971C9.37986 21.0658 8.62007 21.0658 8.15146 20.5971V20.5971Z" />
    </svg>
  );
}

export default Success;
