import styled from 'styled-components';
import { devices } from '../../../juristec-ui/core/breakpoints';

export const UserInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  margin-left: 6%;
  align-items: center;
`;

export const UserInfoText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  & h4 {
    margin: 0;
  }
  & span {
    font-size: 14px;
  }
`;

export const UsersTable = styled.div`
  padding: 0 5%;
  @media ${devices.tablet} {
    padding: 0 20px;
  }
`;

export const UsersTableOverFlow = styled.div`
  overflow-y: auto;
  overflow-x: auto;
  padding-bottom: 65px;
  box-sizing: border-box;
  height: calc(100vh - 137px);

  @media ${devices.tablet} {
    height: calc(100vh - 125px);
  }
`;
