import styled from 'styled-components';
import { devices } from '../../../juristec-ui/core/breakpoints';

const MainContainer = styled.div`
  margin: 0 5%; 
  padding: 5px;
  overflow: auto; 
  overflow-x: hidden;
  box-sizing: border-box;
  padding-bottom: 65px;
  height: calc(100vh - 138px);

  @media ${devices.tablet} {
    margin: 5px 0 0;
    height: calc(100vh - 130px);
  };

  @media ${devices.phoneS} {
    margin: 10px 0 0;
    height: calc(100vh - 152px);
  }; 
`;

export default MainContainer;
