import styled from 'styled-components';

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  max-width: 400px;
`;

export const Messages = styled.span`
  &.info {
    font-size: 14px;
  }
  &.warning {
    font-size: 10px;
    color: ${({ theme }) => theme.error};
  }
`;

export const InputContainer = styled.div`
  position: relative;
  margin: 0 auto 10px;
  width: 80%;
`;

export const SmallInputContainer = styled(InputContainer)`
  width: 50%;
  margin: 0 auto;
  & .inputTxt {
    padding-right: 40px;
    padding-left: 40px;
    text-align: center;
    font-size: 20px;
  }
`;

export const IconWrapper = styled.div`
  position: absolute;
  bottom: 4px;
  right: 4px;
  & > svg {
    height: 25px;
    width: 25px;
    fill:  ${({ theme }) => theme.primary};
  }
`;
