import React from 'react';
import PropTypes from 'prop-types';
import StyledContainerPopover from './styled/PopoverContainer.styled';

function PopoverContainer({ children, ...props }) {
  return (
    <StyledContainerPopover {...props}>
      {children}
    </StyledContainerPopover>
  );
}

PopoverContainer.propTypes = {
  absolute: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

PopoverContainer.defaultProps = {
  absolute: false,
};

export default PopoverContainer;
