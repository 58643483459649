/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Popover from '../Popover';
import IconButton from '../IconButton';
import Tooltip from '../Tooltip';
import { expDateOrder } from '../../utils/formatCharts/orderDatetime';
import { Sortable, SortArrow } from '../../icons';
import compare from '../../../utils/functions/sorting';

import {
  DataListContainer,
  DataListCard,
  CardIcon,
  OptionsBtn,
  PrimaryInfo,
  ExtraInfo,
  HeaderContainer,
  ColumnHeader,
  IconWrapper,
} from './styled/DataCard.styled';

/** Translate the order string to an operation */
const sortOrderPicker = {
  ascending: 1,
  descending: -1,
};

/**
 * A component for displaying tabulated data sets on mobile
 * (use on mobile size only)
 */
const DataCards = ({
  columns,
  rowData,
  rowTotals,
  defaultSortField,
  defaultSortOrder,
  headerColor,
  rowColor,
  strippedRowsColor,
  theadStyle,
  tbodyStyle,
  indexAsHeader,
  ...props
}) => {
  const [openPopover, setOpenPopover] = useState(false);
  const [dataList, setDataList] = useState(rowData);
  const [sortBy, setSortBy] = useState({
    order: sortOrderPicker[defaultSortOrder],
    field: defaultSortField,
  });

  /**
   * Function that sorts a column
   * @param {string} field the column identifier
   */
  const sortColumn = (field) => {
    setSortBy({
      order: field === sortBy.field ? -1 * sortBy.order : 1,
      field,
    });
  };

  useEffect(() => {
    setSortBy({
      order: sortOrderPicker[defaultSortOrder],
      field: defaultSortField,
    });
  }, [defaultSortField, defaultSortOrder]);

  useEffect(() => {
    setDataList(
      [...rowData].sort(
        (a, b) => sortBy.order * (compare(
          expDateOrder(a[sortBy.field]), expDateOrder(b[sortBy.field]),
        )),
      ),
    );
  }, [sortBy, rowData]);

  /**
   * Returns a component based on column role
   * @param {object} data The row data
   * @param {object} column The column data
   * @returns A component displaying the correct data
   */
  const getComp = (data, column) => {
    switch (column.role) {
      case 'cardIcon':
        // Container for icons (top-left of the card)
        return (
          <CardIcon>
            {column.valueGetter ? column.valueGetter(data) : data[column.field]}
          </CardIcon>
        );
      case 'optionsBtn':
        // Container for the option button, aka 3 vertical dots (top-right of the card)
        return (
          <OptionsBtn>
            {column.valueGetter ? column.valueGetter(data) : data[column.field]}
          </OptionsBtn>
        );
      case 'primaryInfo':
        // Container for important data, main subject (center, without label)
        return (
          <PrimaryInfo>
            {column.valueGetter ? column.valueGetter(data) : data[column.field]}
          </PrimaryInfo>
        );
      case 'extraInfo':
        // Container for extra data (bottom, with label)
        return (
          <>
            <ExtraInfo>
              {column?.label?.length > 0 && <span className="label">{`${column.label}: `}</span>}
              <div>
                {column.valueGetter ? column.valueGetter(data) : data[column.field]}
              </div>
            </ExtraInfo>
          </>
        );
      default:
        return <></>;
    }
  };

  return (
    <>
      {columns && (
        <HeaderContainer
          style={headerColor ? { backgroundColor: headerColor, ...theadStyle } : theadStyle}
        >
          <Popover
            closePopover={() => setOpenPopover(false)}
            open={openPopover}
          >
            <Popover.Action>
              <Tooltip text="Ordenar">
                <IconButton onClick={() => setOpenPopover((o) => !o)}>
                  <Sortable />
                </IconButton>
              </Tooltip>
            </Popover.Action>
            <Popover.Content stayOpened direction="bottom-end" offset={[0, 0]}>
              {columns.map((column, columnKey) => (
                <React.Fragment key={columnKey}>
                  {(column.sortable || column.tools) && (
                    <ColumnHeader
                      {...(column.sortable && {
                        onClick: () => sortColumn(column.field),
                        sortable: true,
                      })}
                    >
                      {column.label}
                      {column.tools}
                      {column.sortable && (
                        <IconWrapper toggle={sortBy} field={column.field}>
                          <SortArrow />
                        </IconWrapper>
                      )}
                    </ColumnHeader>
                  )}
                </React.Fragment>
              ))}
            </Popover.Content>
          </Popover>
        </HeaderContainer>
      )}
      <DataListContainer
        rowColor={rowColor}
        strippedRowsColor={strippedRowsColor}
        {...props}
      >
        {dataList && dataList.map((data, rowKey) => (
          data ? (
            <li key={rowKey}>
              <DataListCard>
                {columns && columns.map((column, columnKey) => (
                  <React.Fragment key={rowKey + columnKey}>
                    {getComp(data, column)}
                  </React.Fragment>
                ))}
              </DataListCard>
            </li>
          ) : (<></>)
        ))}
      </DataListContainer>
    </>
  );
};

DataCards.propTypes = {
  /**
   * A array of objects with the parameters of each column
   * ex.: [{field: string, label: string, valueGetter: func, sortable: boolean},...]
   */
  columns: PropTypes.arrayOf(PropTypes.any).isRequired,
  /**
   * A array of objects with the values of each row
   */
  rowData: PropTypes.arrayOf(PropTypes.any).isRequired,
  /**
   * An object with the totals of the colums in a row
   */
  rowTotals: PropTypes.objectOf(PropTypes.any),
  /**
   * A string that represents which field will be sorted by default
   */
  defaultSortField: PropTypes.string,
  /**
   * A string that represents which order will be sorted by default
   */
  defaultSortOrder: PropTypes.oneOf(['ascending', 'descending'], PropTypes.string),
  /**
   * The background color of the header row. Can be passed theme key names
   */
  headerColor: PropTypes.string,
  /**
   * The background color of each row. Can be passed theme key names
   */
  rowColor: PropTypes.string,
  /**
   * The background color of each odd row. Can be passed theme key names
   */
  strippedRowsColor: PropTypes.string,
  /**
   * Inline style of the table header
   */
  theadStyle: PropTypes.objectOf(PropTypes.any),
  /**
   * Inline style of the table body
   */
  tbodyStyle: PropTypes.objectOf(PropTypes.any),
  /**
   * Indicates whether the index of the rows is part of the header
   */
  indexAsHeader: PropTypes.bool,
};

DataCards.defaultProps = {
  defaultSortField: '',
  defaultSortOrder: 'ascending',
  headerColor: undefined,
  rowColor: 'white',
  rowTotals: undefined,
  strippedRowsColor: undefined,
  theadStyle: undefined,
  tbodyStyle: undefined,
  indexAsHeader: false,
};

export default DataCards;
