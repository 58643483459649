import React, { useState } from 'react';
import PropTypes from 'prop-types';
import InputTextLine from '../../../juristec-ui/core/InputTextLine';
import Select from '../../../juristec-ui/core/Select';
import Button from '../../../juristec-ui/core/Button';
import Toggle from '../../../juristec-ui/core/Toggle';
import ActionsGroup from '../../../juristec-ui/core/ActionsGroup';

import { verifyFileName, verifyMasterCrawlerUrl } from '../../../juristec-ui/utils/validators/inputTextValidators';

import {
  AddUserContainer,
  ItemContainer,
  InputWrapper,
} from './styled/AddAntecipei.styled';

/**
 * A modal to create new antecipei reports to the instance
 */
const AddAntecipei = ({
  users,
  hide,
  submitData,
}) => {
  const [name, setName] = useState({ value: '', error: true, errorMsg: '' });
  const [selectedEmail, setSelectedEmail] = useState({});
  const [url, setUrl] = useState({ value: '', error: true, errorMsg: '' });
  const [token, setToken] = useState({ value: '', error: true, errorMsg: '' });
  const [sendWithoutDataProc, setSendWithoutDataProc] = useState(false);
  const [prettyColumns, setPrettyColumns] = useState(false);

  const handleName = (value) => {
    const val = value.split(' ').filter((v) => v).join('');
    const msg = verifyFileName(val);
    setName({
      value: val,
      error: msg.length !== 0,
      errorMsg: msg,
    });
  };

  const handleUrl = (value) => {
    const val = value.split(' ').filter((v) => v).join('');
    const msg = verifyMasterCrawlerUrl(val);
    setUrl({
      value: val,
      error: msg.length !== 0,
      errorMsg: msg,
    });
  };

  const handleToken = (value) => {
    const val = value.split(' ').filter((v) => v).join('');
    const msg = value.length === 0 ? 'Este campo não pode estar vazio' : '';
    setToken({
      value: val,
      error: msg.length !== 0,
      errorMsg: msg,
    });
  };

  const toggleDataProc = () => setSendWithoutDataProc(!sendWithoutDataProc);

  const togglePrettyColumns = () => setPrettyColumns(!prettyColumns);

  const handleSubmit = () => {
    submitData(
      name.value,
      selectedEmail.id,
      url.value,
      sendWithoutDataProc,
      prettyColumns,
      token.value,
    );
  };

  return (
    <>
      <AddUserContainer>
        <ItemContainer>
          <InputTextLine
            label="Nome do relatório"
            placeholder="Antecipei"
            errorMessage={name.errorMsg}
            error={name.errorMsg.length > 0}
            onChange={(e) => handleName(e.target.value)}
            value={name.value}
          />
        </ItemContainer>
        <ItemContainer>
          <Select
            selectLabel="E-mail do usuário"
            options={users}
            fullWidth
            placement="start"
            value={selectedEmail}
            onChange={(val) => setSelectedEmail(val)}
            atModal
            alphabeticalOrder
          />
        </ItemContainer>
        <ItemContainer>
          <InputTextLine
            label="URL Mastercrawler"
            placeholder="https://mastercrawler.juristecsystems.com/processos/report/client"
            errorMessage={url.errorMsg}
            error={url.errorMsg.length > 0}
            onChange={(e) => handleUrl(e.target.value)}
            value={url.value}
          />
        </ItemContainer>
        <ItemContainer>
          <InputTextLine
            label="Token"
            placeholder=""
            errorMessage={token.errorMsg}
            error={token.errorMsg.length > 0}
            onChange={(e) => handleToken(e.target.value)}
            value={token.value}
          />
        </ItemContainer>
        <InputWrapper className="toggle">
          <span className="custom-label">Sem dados de Capa</span>
          <Toggle
            checked={sendWithoutDataProc}
            handleChange={toggleDataProc}
            firstColor="primary"
            size="small"
          />
        </InputWrapper>
        <InputWrapper className="toggle">
          <span className="custom-label">Colunas organizadas</span>
          <Toggle
            checked={prettyColumns}
            handleChange={togglePrettyColumns}
            firstColor="primary"
            size="small"
          />
        </InputWrapper>
      </AddUserContainer>
      <ActionsGroup>
        <Button
          style={{ margin: '5px' }}
          onClick={hide}
          variant="outlined"
          size="large"
        >
          Cancelar
        </Button>
        <Button
          style={{ margin: '5px' }}
          onClick={handleSubmit}
          size="large"
          disabled={name.error || url.error || !selectedEmail.value || token.error}
        >
          Adicionar
        </Button>
      </ActionsGroup>
    </>
  );
};

AddAntecipei.propTypes = {
  /**
   * array of users admin and scientists
   */
  users: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  /**
   * Function to close the modal
   */
  hide: PropTypes.func,
  submitData: PropTypes.func,
};

AddAntecipei.defaultProps = {
  hide: () => {},
  submitData: () => {},
};

export default AddAntecipei;
