import React from 'react';

// import { Container } from './styles';

function Clone() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.62823 2.11059H16C15.7811 1.49379 15.3767 0.959821 14.8424 0.581944C14.308 0.204067 13.6698 0.000790154 13.0153 0H3.16588C2.32624 0 1.52098 0.333547 0.927264 0.927264C0.333547 1.52098 0 2.32624 0 3.16588V13.0153C0.000790154 13.6698 0.204067 14.308 0.581944 14.8424C0.959821 15.3767 1.49379 15.7811 2.11059 16V5.62823C2.11059 4.69529 2.48119 3.80057 3.14088 3.14088C3.80057 2.48119 4.69529 2.11059 5.62823 2.11059Z" />
      <path d="M16.6739 20H6.32609C5.44395 20 4.59795 19.6496 3.97419 19.0258C3.35043 18.402 3 17.556 3 16.6739V6.32609C3 5.44395 3.35043 4.59795 3.97419 3.97419C4.59795 3.35043 5.44395 3 6.32609 3H16.6739C17.556 3 18.402 3.35043 19.0258 3.97419C19.6496 4.59795 20 5.44395 20 6.32609V16.6739C20 17.556 19.6496 18.402 19.0258 19.0258C18.402 19.6496 17.556 20 16.6739 20ZM6 7.50001V15.5C6 15.7762 6.22386 16 6.5 16H16.5C16.7761 16 17 15.7762 17 15.5C17 15.2239 16.7761 15 16.5 15H16H14H13H11H10H8H7.5C7.22386 15 7 14.7762 7 14.5V7.50001C7 7.22387 6.77614 7.00002 6.5 7.00001C6.22386 7.00002 6 7.22387 6 7.50001ZM8.49993 11C8.22378 11 7.99993 11.2239 7.99993 11.5V15H9.99993V11.5C9.99993 11.2239 9.77607 11 9.49993 11H8.49993ZM11 8.5C11 8.22386 11.2238 8 11.5 8H12.5C12.7761 8 13 8.22386 13 8.5V15H11V8.5ZM14.5 13C14.2238 13 14 13.2239 14 13.5V15H16V13.5C16 13.2239 15.7761 13 15.5 13H14.5Z" />
    </svg>
  );
}

export default Clone;
