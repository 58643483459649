import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import InputTextLine from '../../../../juristec-ui/core/InputTextLine';

import { ThirdPageContainer } from './styled/ThirdStep.styled';

/**
 * Third step for the 'CopyDashboard'. This is where the name of the new dashboard is created.
 */
const ThirdStep = ({ newDashName, newDashNameHandle }) => {

  const inputRef = useRef(null);

  return (
    <ThirdPageContainer>
      <InputTextLine
        ref={inputRef}
        label="Nome do dashboard"
        error={newDashName.errorMsg.length > 0}
        errorMessage={newDashName.errorMsg}
        value={newDashName.value}
        setValue={newDashNameHandle}
        emojiPicker
        emojiPickerPosition="top-end"
        onChange={(e) => newDashNameHandle(e.target.value)}
      />
    </ThirdPageContainer>
  );
};

ThirdStep.propTypes = {
  /**
   * String input of the new name
   */
  newDashName: PropTypes.shape({
    value: PropTypes.string.isRequired,
    error: PropTypes.bool.isRequired,
    errorMsg: PropTypes.string.isRequired,
  }),
  /**
   * Function that deals with the changes on the new name
   */
  newDashNameHandle: PropTypes.func.isRequired,
};

export default ThirdStep;
