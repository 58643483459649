import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import StepProgress from '../../../juristec-ui/core/StepProgress';
import FirstStep from './Steps/FirstStep';
import SecondStep from './Steps/SecondStep';
import ThirdStep from './Steps/ThirdStep';
import FifthStep from './Steps/FifthStep';
import DashStyles from '../../DashStyles';

import { verifyInput } from '../../../juristec-ui/utils/validators/inputTextValidators';
import { dateOptions, defaultDashStyle } from '../../../options';

/**
 * Component with steps to apply a template to a file
 */
const ApplyTemplate = ({
  hide,
  template,
  files,
  loadFile,
  onSelectFilter,
  submitData,
  stylesData,
  tourContext = { tourOpen: false },
}) => {
  const [columnToFilter, setColumnToFilter] = useState({ label: '', value: '' });
  const [dateFormat, setDateFormat] = useState(dateOptions[0]);
  // const [selected, setSelected] = useState(templates[0]);
  const [columnVarBinds, setColumnVarBinds] = useState({});
  //
  const [fileColumns, setFileColumns] = useState({ abc: [], float: [], date: [] });
  const [filterColumns, setFilterColumns] = useState([]);
  const [columnData, setColumnData] = useState([]);
  const [cardStyles, setCardStyles] = useState(defaultDashStyle);
  //
  const [newDashName, setNewDashName] = useState({
    value: '',
    error: true,
    errorMsg: '',
  });
  //
  const [selectedFile, setSelectedFile] = useState({ label: '', value: '' });

  useEffect(() => {
    if (columnToFilter.value) {
      (async () => {
        const aux = await onSelectFilter(
          selectedFile.file_id,
          columnToFilter.value,
          columnToFilter.type === 'datetime64[ns]' ? dateFormat.value : '',
        );
        setColumnData(aux);
      })();
    }
  }, [columnToFilter, dateFormat]);

  useEffect(() => {
    if (selectedFile?.file_id) {
      (async () => {
        setFileColumns(await loadFile(selectedFile.file_id));
      })();
    }
  }, [selectedFile]);

  useEffect(() => {
    if (stylesData) {
      setCardStyles({
        ...defaultDashStyle,
        ...stylesData,
        borderLine: stylesData.borderStyle?.line ?? defaultDashStyle.borderLine,
        borderColor: stylesData.borderStyle?.color ?? defaultDashStyle.borderColor,
        borderSize: stylesData.borderStyle?.size ?? defaultDashStyle.borderSize,
        borderRounded: stylesData.borderStyle?.rounded ?? defaultDashStyle.borderRounded,
        spaceBetween: stylesData.spaceBetween !== undefined ? (
          stylesData.spaceBetween / 10
        ) : defaultDashStyle.spaceBetween,
      });
    }
  }, [stylesData]);

  const checkVarBinds = () => (
    Object.keys(template.columnsRequired).length
      === Object.keys(columnVarBinds).length
  );

  const checkColumnsVar = () => (
    (fileColumns.abc?.length || 0) >= (template.columnsType.abc?.length || 0)
    && (fileColumns.float?.length || 0) >= (template.columnsType.float?.length || 0)
    && (fileColumns.date?.length || 0) >= (template.columnsType.date?.length || 0)
  );

  const newDashNameHandle = (val) => {
    const msg = verifyInput(val, true, 2, 40);
    setNewDashName({
      value: val,
      error: msg.length !== 0,
      errorMsg: msg,
    });
  };

  const checkNewDashName = () => !newDashName.error;

  const handleSubmit = () => {
    submitData({
      file: selectedFile.value,
      name: newDashName.value,
      bindings: columnVarBinds,
      filters: filterColumns,
      cardStyles: {
        backgroundColor: cardStyles.backgroundColor,
        shading: cardStyles.shading,
        borderStyle: {
          color: cardStyles.borderColor,
          line: cardStyles.borderLine,
          rounded: (+cardStyles.borderRounded),
          size: (+cardStyles.borderSize),
        },
        spaceBetween: (+cardStyles.spaceBetween) * 10,
        useGlobalTheme: cardStyles.useGlobalTheme,
        titles: cardStyles.titles,
        charts: cardStyles.charts.filter((c) => Boolean(c)),
        insightBg: cardStyles.insightBg,
        insightTxt: cardStyles.insightTxt,
        valueBg: cardStyles.valueBg,
        valueTxt: cardStyles.valueTxt,
        tableRows: cardStyles.tableRows,
        tableHeader: cardStyles.tableHeader,
      },
    });
  };

  const firstStep = {
    label: 'Arquivo',
    validation: checkColumnsVar,
    content: (
      <FirstStep
        files={files}
        selected={template}
        selectedFile={selectedFile}
        setSelectedFile={setSelectedFile}
        checkColumnsVar={checkColumnsVar}
        fileColumns={fileColumns}
      />
    ),
  };

  const secondStep = {
    label: 'Variáveis',
    validation: checkVarBinds,
    content: (
      <SecondStep
        selected={template}
        columnVarBinds={columnVarBinds}
        setColumnVarBinds={setColumnVarBinds}
        fileColumns={fileColumns}
      />
    ),
  };

  const thirdStep = {
    label: 'Filtro',
    content: (
      <ThirdStep
        fileColumns={fileColumns}
        columnToFilter={columnToFilter}
        setColumnToFilter={setColumnToFilter}
        columnData={columnData}
        filterColumns={filterColumns}
        setFilterColumns={setFilterColumns}
        dateFormat={dateFormat}
        setDateFormat={setDateFormat}
        dateOptions={dateOptions}
      />
    ),
  };

  const fourthStep = {
    label: 'Estilo',
    content: (
      <DashStyles
        tempStylesDataState={[cardStyles, setCardStyles]}
      />
    ),
  };

  const fifthStep = {
    label: 'Criação',
    validation: checkNewDashName,
    content: (
      <FifthStep
        newDashName={newDashName}
        newDashNameHandle={newDashNameHandle}
      />
    ),
  };

  return (
    <>
      <StepProgress
        steps={[firstStep, secondStep, thirdStep, fourthStep, fifthStep]}
        hide={hide}
        complete={handleSubmit}
        markersWidth="large"
        disableUntilValid
        tourContext={tourContext}
      />
    </>
  );
};

ApplyTemplate.propTypes = {
  /**
   * Selected template
   */
  template: PropTypes.shape({
    columnsRequired: PropTypes.shape({}),
    columnsType: PropTypes.shape({
      abc: PropTypes.arrayOf(PropTypes.string),
      float: PropTypes.arrayOf(PropTypes.string),
      date: PropTypes.arrayOf(PropTypes.string),
    }),
  }),
  /**
   * Array of files
   */
  files: PropTypes.arrayOf(PropTypes.shape({})),
  /**
   * Function that loads the selected file columns
   */
  loadFile: PropTypes.func.isRequired,
  /**
   * Function to close the modal
   */
  hide: PropTypes.func.isRequired,
  /**
   * A function that sends the new data back to the parent
   */
  submitData: PropTypes.func.isRequired,

  /**
   * A function that runs when filter column is selected.
   */
  onSelectFilter: PropTypes.func.isRequired,

  tourContext: PropTypes.shape({
    tourOpen: PropTypes.bool,
    nextStep: PropTypes.func,
  }),
  stylesData: PropTypes.shape({
    backgroundColor: PropTypes.string,
    borderStyle: PropTypes.shape({
      color: PropTypes.string,
      line: PropTypes.string,
      rounded: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    shading: PropTypes.bool,
    spaceBetween: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
};

ApplyTemplate.defaultProps = {
  template: {},
  files: [],
  tourContext: {
    tourOpen: false,
  },
  stylesData: {},
};

export default ApplyTemplate;
