import React from 'react';
import PropTypes from 'prop-types';

// components
import PublicGridKpi from '../PublicPage/PublicGridKpi';
import PublicNotFound from '../PublicPage/PublicNotFound';
import Loader from '../../juristec-ui/core/Loader';

const TemplatePreview = ({
  dashState, dashDoc,
}) => (
  <>
    {dashState.isLoading && <Loader />}
    {(!dashState.isLoading && !dashState.dashboardDoc) ? (
      <PublicNotFound />
    ) : (
      <>
        <PublicGridKpi
          kpiItemList={dashState.kpiItemList}
          dashboardDoc={dashDoc ?? dashState.dashboardDoc}
          kpisLayout={dashState.kpisLayout}
        />
      </>
    )}
  </>
);

TemplatePreview.propTypes = {
  dashState: PropTypes.shape({
    kpiItemList: PropTypes.arrayOf(PropTypes.shape({})),
    dashboardDoc: PropTypes.shape({}),
    kpisLayout: PropTypes.shape({}),
    isLoading: PropTypes.bool,
  }),
  dashDoc: PropTypes.shape({}),
};

TemplatePreview.defaultProps = {
  dashState: {
    dashboardDoc: {},
    kpisLayout: {},
    kpiItemList: [],
    isLoading: false,
  },
  dashDoc: {},
};

export default TemplatePreview;
