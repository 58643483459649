import React from 'react';
import PropTypes from 'prop-types';

const NewColumn = ({ width, height }) => (
  <svg width={width} height={height} viewBox="0 0 635 668" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M601 300.667H534.333V234C534.333 225.667 531 215.667 524.333 210.667C517.667 204 509.333 200.667 501 200.667C482.667 200.667 467.667 215.667 467.667 234V300.667H401C392.667 300.667 384.333 304 377.667 310.667C371 315.667 367.667 325.667 367.667 334C367.667 352.333 382.667 367.333 401 367.333H467.667V434C467.667 442.333 471 452.333 477.667 457.333C484.333 464 492.667 467.333 501 467.333C519.333 467.333 534.333 452.333 534.333 434V367.333H601C609.333 367.333 617.667 364 624.333 357.333C631 352.333 634.333 342.333 634.333 334C634.333 315.667 619.333 300.667 601 300.667ZM501 534H451C414.333 534 384.333 504 384.333 467.333V434H367.667C347.667 434 327.667 425.667 316 409C312.667 405.667 307.667 404 304.333 407.333C302.667 409 301 410.667 301 414V667.333H484.333C511 669 532.667 647.333 534.333 620.667C534.333 619 534.333 619 534.333 617.333V567.333C534.333 549 519.333 534 501 534ZM484.333 0.666677H301V254C301 259 304.333 262.333 309.333 262.333C312.667 262.333 314.333 260.667 316 259C327.667 242.333 347.667 234 367.667 234H401V200.667C401 164 431 134 467.667 134H501C519.333 134 534.333 119 534.333 100.667V50.6667C536 24 514.333 2.33334 487.667 0.666677C486 0.666677 486 0.666677 484.333 0.666677ZM1.00005 50.6667V617.333C-0.666616 644 21.0001 665.667 47.6667 667.333C49.3334 667.333 49.3334 667.333 51 667.333H234.333V0.666677H51C24.3334 -0.99999 2.66672 20.6667 1.00005 47.3333C1.00005 49 1.00005 49 1.00005 50.6667Z" />
  </svg>
);

NewColumn.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

NewColumn.defaultProps = {
  width: '635px',
  height: '668px',
};

export default NewColumn;
