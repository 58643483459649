import { useState, useEffect } from 'react';
import useWindowSize from './useWindowSize';
import { breakpoints } from '../core/breakpoints';

/**
 * Sets the device type
 * @param {number} width viewport width;
 * @returns device type
 */
function deviceByWidth(width) {
  if (width <= breakpoints.sm) return 'phone';
  if (width <= breakpoints.md) return 'tablet';
  return 'desktop';
}

/**
 * Returns the device type based on the viewport width
 * @returns {string} device type: 'phone', 'tablet' or 'desktop'
 */
export default function useDeviceType() {
  const { width } = useWindowSize();
  const [device, setDevice] = useState(deviceByWidth(width));

  useEffect(() => {
    setDevice(deviceByWidth(width));
  }, [width]);

  return device;
}
