import React from 'react';
import PropTypes from 'prop-types';

// import { Container } from './styles';

function LegalOne({ color, width, height }) {
  return (
    <svg width={width} height={height} id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 492.42 462.89">
      <defs />
      <g id="Layer_2" data-name="Layer 2">
        <path style={{ fill: color }} d="M1301,548a24.69,24.69,0,1,1-24.61-24.74A24.54,24.54,0,0,1,1301,548Z" transform="translate(-808.62 -316.43)" />
        <path style={{ fill: color }} d="M858,547.88a24.68,24.68,0,1,1-24.66-24.67A24.54,24.54,0,0,1,858,547.88Z" transform="translate(-808.62 -316.43)" />
        <path style={{ fill: color }} d="M841,513.3a22.8,22.8,0,1,1,22.69-22.56A22.9,22.9,0,0,1,841,513.3Z" transform="translate(-808.62 -316.43)" />
        <path style={{ fill: color }} d="M818,605a22.86,22.86,0,1,1,22.77,23.06A23,23,0,0,1,818,605Z" transform="translate(-808.62 -316.43)" />
        <path style={{ fill: color }} d="M1269.07,582.4a22.85,22.85,0,1,1-23,22.77A23,23,0,0,1,1269.07,582.4Z" transform="translate(-808.62 -316.43)" />
        <path style={{ fill: color }} d="M1268.76,513.3a22.8,22.8,0,1,1,23-22.77A22.86,22.86,0,0,1,1268.76,513.3Z" transform="translate(-808.62 -316.43)" />
        <path style={{ fill: color }} d="M1225.94,437.07a20.74,20.74,0,1,1,20.57,20.76A20.9,20.9,0,0,1,1225.94,437.07Z" transform="translate(-808.62 -316.43)" />
        <path style={{ fill: color }} d="M883.72,437.06a20.73,20.73,0,1,1-20.65-20.65A20.89,20.89,0,0,1,883.72,437.06Z" transform="translate(-808.62 -316.43)" />
        <path style={{ fill: color }} d="M1246.85,638a20.64,20.64,0,1,1-20.91,20.33A20.8,20.8,0,0,1,1246.85,638Z" transform="translate(-808.62 -316.43)" />
        <path style={{ fill: color }} d="M863.52,638.05a20.64,20.64,0,1,1-1,41.26c-11.33-.24-20.63-10-20.18-21.17A20.63,20.63,0,0,1,863.52,638.05Z" transform="translate(-808.62 -316.43)" />
        <path style={{ fill: color }} d="M896,597.16a19.32,19.32,0,0,1,19.45,19.22A19.52,19.52,0,0,1,896,635.76a19.24,19.24,0,0,1-19.31-19.33A19,19,0,0,1,896,597.16Z" transform="translate(-808.62 -316.43)" />
        <path style={{ fill: color }} d="M1213.75,460.16a19,19,0,0,1,19.18,19.34,19.22,19.22,0,0,1-19.37,19.27,19.55,19.55,0,0,1-19.33-19.47A19.29,19.29,0,0,1,1213.75,460.16Z" transform="translate(-808.62 -316.43)" />
        <path style={{ fill: color }} d="M865.84,573a17.86,17.86,0,0,1,35.72-.17,18,18,0,0,1-17.82,17.88A17.87,17.87,0,0,1,865.84,573Z" transform="translate(-808.62 -316.43)" />
        <path style={{ fill: color }} d="M1190.62,458.36a17.79,17.79,0,0,1-.11-35.58,17.79,17.79,0,1,1,.11,35.58Z" transform="translate(-808.62 -316.43)" />
        <path style={{ fill: color }} d="M936.87,655.37A17.89,17.89,0,0,1,919,673.06a17.84,17.84,0,1,1,.35-35.68A17.82,17.82,0,0,1,936.87,655.37Z" transform="translate(-808.62 -316.43)" />
        <path style={{ fill: color }} d="M1226.15,540.49a17.66,17.66,0,1,1,17.66-17.78A17.72,17.72,0,0,1,1226.15,540.49Z" transform="translate(-808.62 -316.43)" />
        <path style={{ fill: color }} d="M914.79,391.66a16.77,16.77,0,0,1-16.87,16.21,16.64,16.64,0,0,1,.32-33.28A16.8,16.8,0,0,1,914.79,391.66Z" transform="translate(-808.62 -316.43)" />
        <path style={{ fill: color }} d="M1228,704.66a16.47,16.47,0,0,1-16.45,16.5,16.76,16.76,0,0,1-16.63-16.45A16.53,16.53,0,0,1,1211.68,688,16.3,16.3,0,0,1,1228,704.66Z" transform="translate(-808.62 -316.43)" />
        <path style={{ fill: color }} d="M1228,391.45a16.48,16.48,0,0,1-16.54,16.43,16.63,16.63,0,1,1,.41-33.26A16.46,16.46,0,0,1,1228,391.45Z" transform="translate(-808.62 -316.43)" />
        <path style={{ fill: color }} d="M881.72,704.53a16.54,16.54,0,1,1,33.07.34,16.67,16.67,0,0,1-16.74,16.29A16.5,16.5,0,0,1,881.72,704.53Z" transform="translate(-808.62 -316.43)" />
        <path style={{ fill: color }} d="M899.17,527.8a16.15,16.15,0,1,1-16.26-16.21A16.31,16.31,0,0,1,899.17,527.8Z" transform="translate(-808.62 -316.43)" />
        <path style={{ fill: color }} d="M967.75,686.41a16.17,16.17,0,1,1-16.53-16A16.31,16.31,0,0,1,967.75,686.41Z" transform="translate(-808.62 -316.43)" />
        <path style={{ fill: color }} d="M1210.62,567.72A16,16,0,0,1,1227,551.83,16.29,16.29,0,0,1,1242.79,568c-.07,8.93-7.54,16.35-16.29,16.18A16.1,16.1,0,0,1,1210.62,567.72Z" transform="translate(-808.62 -316.43)" />
        <path style={{ fill: color }} d="M1158,393.15a16,16,0,0,1,16.27,16,16.19,16.19,0,1,1-16.27-16Z" transform="translate(-808.62 -316.43)" />
        <path style={{ fill: color }} d="M1144.64,468.32a16,16,0,1,1,16.14-15.66A16,16,0,0,1,1144.64,468.32Z" transform="translate(-808.62 -316.43)" />
        <path style={{ fill: color }} d="M980.83,643.53a16,16,0,0,1-31.93,0,16,16,0,1,1,31.93,0Z" transform="translate(-808.62 -316.43)" />
        <path style={{ fill: color }} d="M1183.81,485a13.8,13.8,0,0,1-14.19,13.6,13.82,13.82,0,1,1,.45-27.64A13.78,13.78,0,0,1,1183.81,485Z" transform="translate(-808.62 -316.43)" />
        <path style={{ fill: color }} d="M1012.72,666.41a13.91,13.91,0,1,1-27.81,0,13.91,13.91,0,0,1,27.81,0Z" transform="translate(-808.62 -316.43)" />
        <path style={{ fill: color }} d="M940.06,597.19a13.84,13.84,0,0,1,13.61,13.69,14,14,0,0,1-14,13.93,13.81,13.81,0,1,1,.37-27.62Z" transform="translate(-808.62 -316.43)" />
        <path style={{ fill: color }} d="M1124.79,429.14a13.9,13.9,0,1,1-14-13.8A13.91,13.91,0,0,1,1124.79,429.14Z" transform="translate(-808.62 -316.43)" />
        <path style={{ fill: color }} d="M1179.35,356.05a13.72,13.72,0,1,1-13.66-13.71A13.75,13.75,0,0,1,1179.35,356.05Z" transform="translate(-808.62 -316.43)" />
        <path style={{ fill: color }} d="M957.75,355.81a13.68,13.68,0,1,1-13.85-13.47A13.68,13.68,0,0,1,957.75,355.81Z" transform="translate(-808.62 -316.43)" />
        <path style={{ fill: color }} d="M944.22,726.22a13.58,13.58,0,1,1-13.81,13.41A13.57,13.57,0,0,1,944.22,726.22Z" transform="translate(-808.62 -316.43)" />
        <path style={{ fill: color }} d="M1166.21,726.23a13.58,13.58,0,1,1-14.29,13A13.62,13.62,0,0,1,1166.21,726.23Z" transform="translate(-808.62 -316.43)" />
        <path style={{ fill: color }} d="M1105.81,387.22a12.95,12.95,0,0,1,25.89-.38,13.23,13.23,0,0,1-12.78,13.31A13.07,13.07,0,0,1,1105.81,387.22Z" transform="translate(-808.62 -316.43)" />
        <path style={{ fill: color }} d="M1003.85,708.58a12.64,12.64,0,0,1-13,12.85A12.87,12.87,0,0,1,978,708.91a13.32,13.32,0,0,1,12.83-13.28A13,13,0,0,1,1003.85,708.58Z" transform="translate(-808.62 -316.43)" />
        <path style={{ fill: color }} d="M894.19,471.18a12.52,12.52,0,0,1,12.73,13A12.9,12.9,0,0,1,893.78,497a13.39,13.39,0,0,1-12.67-13A12.94,12.94,0,0,1,894.19,471.18Z" transform="translate(-808.62 -316.43)" />
        <path style={{ fill: color }} d="M1215.69,624.62a12.76,12.76,0,0,1-13-12.92,12.93,12.93,0,0,1,12.54-12.91,13.24,13.24,0,0,1,13.32,12.76A13,13,0,0,1,1215.69,624.62Z" transform="translate(-808.62 -316.43)" />
        <path style={{ fill: color }} d="M1082.79,441.06a12.12,12.12,0,0,1,12.15,12.42A12.39,12.39,0,0,1,1082.71,466a12.75,12.75,0,0,1-12.58-12.79A12.54,12.54,0,0,1,1082.79,441.06Z" transform="translate(-808.62 -316.43)" />
        <path style={{ fill: color }} d="M1027.18,654.77a12.42,12.42,0,0,1-.37-24.84,12.42,12.42,0,1,1,.37,24.84Z" transform="translate(-808.62 -316.43)" />
        <path style={{ fill: color }} d="M1083.81,417.85a12.5,12.5,0,0,1-12.43,12.29,12.8,12.8,0,0,1-12.3-12.54,12.37,12.37,0,0,1,24.73.25Z" transform="translate(-808.62 -316.43)" />
        <path style={{ fill: color }} d="M1038.09,690.33a12.41,12.41,0,0,1-12.24-12.44,12.4,12.4,0,1,1,12.24,12.44Z" transform="translate(-808.62 -316.43)" />
        <path style={{ fill: color }} d="M1196.1,523.47a12.3,12.3,0,1,1-24.59-.26,12.3,12.3,0,0,1,24.59.26Z" transform="translate(-808.62 -316.43)" />
        <path style={{ fill: color }} d="M925.84,559.94a12.45,12.45,0,0,1,12.46,12.24A12.25,12.25,0,0,1,926,584.5a11.94,11.94,0,0,1-12.32-12.14A12.14,12.14,0,0,1,925.84,559.94Z" transform="translate(-808.62 -316.43)" />
        <path style={{ fill: color }} d="M1057.79,635.46a10.87,10.87,0,1,1-.23,21.73,10.87,10.87,0,0,1,.23-21.73Z" transform="translate(-808.62 -316.43)" />
        <path style={{ fill: color }} d="M1062.83,449.65a10.83,10.83,0,0,1-10.95,10.64,11,11,0,0,1-10.67-11,11.14,11.14,0,0,1,11-10.72A10.93,10.93,0,0,1,1062.83,449.65Z" transform="translate(-808.62 -316.43)" />
        <path style={{ fill: color }} d="M1110.45,477.39a10.58,10.58,0,0,1-10.71-10.77,10.84,10.84,0,0,1,10.74-10.88,11.07,11.07,0,0,1,10.83,10.88A10.86,10.86,0,0,1,1110.45,477.39Z" transform="translate(-808.62 -316.43)" />
        <path style={{ fill: color }} d="M1010,629.69a10.7,10.7,0,1,1-10.17-11.33A10.8,10.8,0,0,1,1010,629.69Z" transform="translate(-808.62 -316.43)" />
        <path style={{ fill: color }} d="M1204.29,651.44a10.87,10.87,0,0,1-11,10.5,11,11,0,0,1-10.36-10.7,10.69,10.69,0,1,1,21.38.2Z" transform="translate(-808.62 -316.43)" />
        <path style={{ fill: color }} d="M1075.16,365.39a10.65,10.65,0,1,1-11,10.57A10.84,10.84,0,0,1,1075.16,365.39Z" transform="translate(-808.62 -316.43)" />
        <path style={{ fill: color }} d="M916.21,433.91a10.71,10.71,0,0,1,10.54,11,10.67,10.67,0,1,1-10.54-11Z" transform="translate(-808.62 -316.43)" />
        <path style={{ fill: color }} d="M997.57,344.45a10.5,10.5,0,0,1-10.73-10.73,10.62,10.62,0,0,1,10.62-10.41,10.57,10.57,0,1,1,.11,21.14Z" transform="translate(-808.62 -316.43)" />
        <path style={{ fill: color }} d="M1035,730.36a10.58,10.58,0,1,1-.64-21.16,10.59,10.59,0,1,1,.64,21.16Z" transform="translate(-808.62 -316.43)" />
        <path style={{ fill: color }} d="M997.8,751.28a10.57,10.57,0,1,1-11,10.54A10.5,10.5,0,0,1,997.8,751.28Z" transform="translate(-808.62 -316.43)" />
        <path style={{ fill: color }} d="M1122.81,333.91a10.36,10.36,0,0,1-10.35,10.54,10.57,10.57,0,0,1-.54-21.13A10.55,10.55,0,0,1,1122.81,333.91Z" transform="translate(-808.62 -316.43)" />
        <path style={{ fill: color }} d="M1101.65,761.71a10.3,10.3,0,0,1,10.43-10.44,10.58,10.58,0,1,1-.29,21.14A10.24,10.24,0,0,1,1101.65,761.71Z" transform="translate(-808.62 -316.43)" />
        <path style={{ fill: color }} d="M1064.78,326.2a9.95,9.95,0,1,1-19.89.1,9.95,9.95,0,0,1,19.89-.1Z" transform="translate(-808.62 -316.43)" />
        <path style={{ fill: color }} d="M1054.62,759.45a10.07,10.07,0,0,1,10.16,9.62,9.95,9.95,0,1,1-19.89.45A10.13,10.13,0,0,1,1054.62,759.45Z" transform="translate(-808.62 -316.43)" />
        <path style={{ fill: color }} d="M1185.16,574.25a9.74,9.74,0,1,1,9.59-9.59A9.92,9.92,0,0,1,1185.16,574.25Z" transform="translate(-808.62 -316.43)" />
        <path style={{ fill: color }} d="M934.49,531.28a9.77,9.77,0,1,1-9.87-9.81A10,10,0,0,1,934.49,531.28Z" transform="translate(-808.62 -316.43)" />
        <path style={{ fill: color }} d="M1089.15,620.56a9.61,9.61,0,1,1-.34-19.22,9.76,9.76,0,0,1,9.86,9.72A9.62,9.62,0,0,1,1089.15,620.56Z" transform="translate(-808.62 -316.43)" />
        <path style={{ fill: color }} d="M986.62,608.2a9.71,9.71,0,0,1-19.42-.37,9.71,9.71,0,0,1,19.42.37Z" transform="translate(-808.62 -316.43)" />
        <path style={{ fill: color }} d="M1030.49,484.74a9.76,9.76,0,0,1-9.51,9.59,9.93,9.93,0,0,1-10-9.7,9.74,9.74,0,0,1,19.47.11Z" transform="translate(-808.62 -316.43)" />
        <path style={{ fill: color }} d="M1031.49,455.19a9.72,9.72,0,0,1-9.55,9.59,9.83,9.83,0,0,1-9.83-9.36,9.69,9.69,0,1,1,19.38-.23Z" transform="translate(-808.62 -316.43)" />
        <path style={{ fill: color }} d="M1040.13,419.09a9.66,9.66,0,1,1-9.27-9.77A9.5,9.5,0,0,1,1040.13,419.09Z" transform="translate(-808.62 -316.43)" />
        <path style={{ fill: color }} d="M1088.22,631.07a9.71,9.71,0,0,1,9.43,9.71,9.69,9.69,0,1,1-19.37-.55A9.61,9.61,0,0,1,1088.22,631.07Z" transform="translate(-808.62 -316.43)" />
        <path style={{ fill: color }} d="M1132.77,478.26a9.63,9.63,0,0,1,9.76,9.34,9.71,9.71,0,1,1-9.76-9.34Z" transform="translate(-808.62 -316.43)" />
        <path style={{ fill: color }} d="M1069.68,676.8a9.61,9.61,0,0,1,19.21,0,9.5,9.5,0,0,1-9.89,9.6A9.35,9.35,0,0,1,1069.68,676.8Z" transform="translate(-808.62 -316.43)" />
        <path style={{ fill: color }} d="M1011.49,498.45a8.55,8.55,0,1,1-8.51-8.74A8.73,8.73,0,0,1,1011.49,498.45Z" transform="translate(-808.62 -316.43)" />
        <path style={{ fill: color }} d="M1042.22,468.64a8.8,8.8,0,0,1,8.32,8.93,8.66,8.66,0,0,1-9,8.16,8.39,8.39,0,0,1-8-8.6A8.56,8.56,0,0,1,1042.22,468.64Z" transform="translate(-808.62 -316.43)" />
        <path style={{ fill: color }} d="M1076.32,618.41a8.51,8.51,0,1,1-17-.13,8.3,8.3,0,0,1,8.3-8.23A8.44,8.44,0,0,1,1076.32,618.41Z" transform="translate(-808.62 -316.43)" />
        <path style={{ fill: color }} d="M1107.18,588.88a8.52,8.52,0,1,1-8.86,8.3A8.73,8.73,0,0,1,1107.18,588.88Z" transform="translate(-808.62 -316.43)" />
        <path style={{ fill: color }} d="M1030,368.42a8.36,8.36,0,1,1-8.5,8A8.35,8.35,0,0,1,1030,368.42Z" transform="translate(-808.62 -316.43)" />
        <path style={{ fill: color }} d="M1088.23,719.13a8.36,8.36,0,0,1-8.31,8.19,8.1,8.1,0,0,1-8.22-8.17,8.34,8.34,0,0,1,8.47-8.53A8.51,8.51,0,0,1,1088.23,719.13Z" transform="translate(-808.62 -316.43)" />
        <path style={{ fill: color }} d="M1170.51,683.71a8.62,8.62,0,0,1-8.4,8.22,8.43,8.43,0,0,1-8.29-8.24,8.22,8.22,0,0,1,8.56-8.37A8.38,8.38,0,0,1,1170.51,683.71Z" transform="translate(-808.62 -316.43)" />
        <path style={{ fill: color }} d="M955.84,412.35a8,8,0,0,1-8.27,8.08,8.26,8.26,0,0,1-8.35-8.11,8.43,8.43,0,0,1,8.62-8.42A8.3,8.3,0,0,1,955.84,412.35Z" transform="translate(-808.62 -316.43)" />
        <path style={{ fill: color }} d="M1181.48,603.82a8.18,8.18,0,0,1-7.92,8,7.8,7.8,0,0,1-7.8-8,7.55,7.55,0,0,1,7.54-7.64A7.82,7.82,0,0,1,1181.48,603.82Z" transform="translate(-808.62 -316.43)" />
        <path style={{ fill: color }} d="M991.78,440.54a7.88,7.88,0,0,1-7.88-7.91,7.9,7.9,0,0,1,15.79,0A7.88,7.88,0,0,1,991.78,440.54Z" transform="translate(-808.62 -316.43)" />
        <path style={{ fill: color }} d="M943.92,491.65a7.62,7.62,0,0,1-7.7,8,7.8,7.8,0,0,1-.35-15.59A7.69,7.69,0,0,1,943.92,491.65Z" transform="translate(-808.62 -316.43)" />
        <path style={{ fill: color }} d="M1125.78,663.25a8,8,0,0,1-8.22,7.63A8.23,8.23,0,0,1,1110,663a7.86,7.86,0,0,1,8-7.75A7.78,7.78,0,0,1,1125.78,663.25Z" transform="translate(-808.62 -316.43)" />
        <path style={{ fill: color }} d="M1123.41,714.38a7.47,7.47,0,0,1-7.7-7.37,7.67,7.67,0,1,1,7.7,7.37Z" transform="translate(-808.62 -316.43)" />
        <path style={{ fill: color }} d="M986.24,381.35a7.58,7.58,0,0,1,7.79,7.34,7.67,7.67,0,1,1-7.79-7.34Z" transform="translate(-808.62 -316.43)" />
        <path style={{ fill: color }} d="M994.91,462.37a7.78,7.78,0,0,1,7.46,7.73,7.61,7.61,0,0,1-15.22-.31A7.73,7.73,0,0,1,994.91,462.37Z" transform="translate(-808.62 -316.43)" />
        <path style={{ fill: color }} d="M1115.25,633.35a7.51,7.51,0,1,1-.27-15,7.51,7.51,0,0,1,.27,15Z" transform="translate(-808.62 -316.43)" />
        <path style={{ fill: color }} d="M1127.08,578.65a7.43,7.43,0,0,1-7.3,7.73,7.59,7.59,0,0,1-7.81-7.28,7.7,7.7,0,0,1,7.38-7.77A7.46,7.46,0,0,1,1127.08,578.65Z" transform="translate(-808.62 -316.43)" />
        <path style={{ fill: color }} d="M1155.13,515a7.5,7.5,0,0,1-7.8,7.39,7.51,7.51,0,0,1,.24-15A7.49,7.49,0,0,1,1155.13,515Z" transform="translate(-808.62 -316.43)" />
        <path style={{ fill: color }} d="M990.21,524.33a7.51,7.51,0,0,1-.28-15,7.51,7.51,0,1,1,.28,15Z" transform="translate(-808.62 -316.43)" />
        <path style={{ fill: color }} d="M1052.87,619.06a7.3,7.3,0,0,1-7.62,7.38,7.39,7.39,0,0,1-7.46-7.58,7.54,7.54,0,1,1,15.08.2Z" transform="translate(-808.62 -316.43)" />
        <path style={{ fill: color }} d="M1065.15,469.28a7.28,7.28,0,0,1,6.7,8.29,7.53,7.53,0,1,1-6.7-8.29Z" transform="translate(-808.62 -316.43)" />
        <path style={{ fill: color }} d="M969.74,580.92a7.52,7.52,0,0,1-7.64,7.51,7.19,7.19,0,0,1-7.37-7.16,7.39,7.39,0,0,1,7.25-7.86A7.71,7.71,0,0,1,969.74,580.92Z" transform="translate(-808.62 -316.43)" />
        <path style={{ fill: color }} d="M1092.59,483.63a6.91,6.91,0,0,1-7.18,6.1,6.61,6.61,0,0,1-6.06-7.09,6.75,6.75,0,0,1,7.08-6.15A7,7,0,0,1,1092.59,483.63Z" transform="translate(-808.62 -316.43)" />
        <path style={{ fill: color }} d="M1126,564a6.53,6.53,0,0,1-6.54-6.75c.11-3.69,3.27-6.87,6.82-6.49,3.77.41,6.09,2.65,6.35,6.52C1132.88,560.84,1129.59,564,1126,564Z" transform="translate(-808.62 -316.43)" />
        <path style={{ fill: color }} d="M1030.48,612.48a6.66,6.66,0,1,1-13.32.13,6.66,6.66,0,0,1,13.32-.13Z" transform="translate(-808.62 -316.43)" />
        <path style={{ fill: color }} d="M983.9,545a6.67,6.67,0,0,1-.28-13.34A6.67,6.67,0,0,1,983.9,545Z" transform="translate(-808.62 -316.43)" />
        <path style={{ fill: color }} d="M1153.12,551.27c-3.63-.55-5.8-2.58-6-6.24a6,6,0,0,1,6.59-5.95c3.79.36,5.79,2.79,5.63,6.35S1156.76,551,1153.12,551.27Z" transform="translate(-808.62 -316.43)" />
        <path style={{ fill: color }} d="M1142.33,603.57c-.53,3.69-2.44,6-6.22,5.93s-5.81-2.33-6.07-5.94a6.06,6.06,0,0,1,6.42-6.16C1140.16,597.74,1142,600,1142.33,603.57Z" transform="translate(-808.62 -316.43)" />
        <path style={{ fill: color }} d="M956.47,556.81c-3.74-.38-6.09-2.44-6.14-6s2.31-5.84,5.91-6.2a6.16,6.16,0,0,1,6.33,6.27C962.25,554.49,960,556.44,956.47,556.81Z" transform="translate(-808.62 -316.43)" />
        <path style={{ fill: color }} d="M974.39,486.29a5.56,5.56,0,0,1,5.26,6c0,3.63-2.17,5.83-5.89,6.06a5.93,5.93,0,0,1-6.24-6.22C967.63,488.74,970.27,486.25,974.39,486.29Z" transform="translate(-808.62 -316.43)" />
        <path style={{ fill: color }} d="M1150.52,643.71c-3.33-.3-5.61-1.84-5.79-5.44-.19-3.76,1.79-6,5.49-6.17a5.3,5.3,0,0,1,5.91,5.34C1156.36,641.12,1154.19,643.17,1150.52,643.71Z" transform="translate(-808.62 -316.43)" />
        <path style={{ fill: color }} d="M965.17,457.88c-.36,3.68-2.37,5.91-6.06,5.8-3.41-.1-5.58-2.23-5.55-5.72,0-3.69,2.18-5.81,6-5.73C963.1,452.31,964.81,454.47,965.17,457.88Z" transform="translate(-808.62 -316.43)" />
        <path style={{ fill: color }} d="M1131.2,534.82c-.55,3.61-2.27,5.85-5.93,5.67s-5.51-2.44-5.33-5.92c.16-3.26,2.26-5.18,5.57-5.21C1129.17,529.33,1130.78,531.62,1131.2,534.82Z" transform="translate(-808.62 -316.43)" />
        <path style={{ fill: color }} d="M1005.16,594.21c3.54.24,5.71,1.95,5.69,5.57s-2.16,5.51-5.66,5.51c-3.33,0-5.24-2-5.38-5.26C999.67,596.43,1001.72,594.61,1005.16,594.21Z" transform="translate(-808.62 -316.43)" />
        <path style={{ fill: color }} d="M1110,496.4c-.55,3.48-2.56,5.31-6.15,5-3.34-.31-5.15-2.36-5-5.67.18-3.55,2.36-5.5,5.89-5.34S1110,492.82,1110,496.4Z" transform="translate(-808.62 -316.43)" />
        <path style={{ fill: color }} d="M984.54,555.18c3.34.45,5.49,2.25,5.26,5.87s-2.47,5.4-6,5.23-5.13-2.26-5.09-5.56C978.78,557.12,980.87,555.34,984.54,555.18Z" transform="translate(-808.62 -316.43)" />
        <path style={{ fill: color }} d="M991.18,577.28c3.43.18,5.2,1.89,5.24,4.65a4.27,4.27,0,0,1-4.64,4.64A4.35,4.35,0,0,1,987,582C987,579.11,988.86,577.64,991.18,577.28Z" transform="translate(-808.62 -316.43)" />
        <path style={{ fill: color }} d="M1113.31,513.84c.36-2.78,1.6-4.8,4.71-4.71,3,.09,4.69,1.87,4.66,4.75,0,2.65-1.71,4.31-4.42,4.46C1115.21,518.5,1113.79,516.65,1113.31,513.84Z" transform="translate(-808.62 -316.43)" />
        <path style={{ fill: color }} d="M960.29,524.73c-2.71-.35-4.37-1.82-4.37-4.56a4.19,4.19,0,0,1,4.35-4.51c2.88,0,4.66,1.7,4.55,4.75C964.72,523.25,962.9,524.46,960.29,524.73Z" transform="translate(-808.62 -316.43)" />
        <path style={{ fill: color }} d="M1153.86,575.15c-.21,3.06-1.57,4.87-4.26,5-2.94.14-4.73-1.58-4.72-4.55,0-2.72,1.7-4.27,4.42-4.34S1153.4,573,1153.86,575.15Z" transform="translate(-808.62 -316.43)" />
      </g>
    </svg>
  );
};

LegalOne.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
};

LegalOne.defaultProps = {
  width: '25px',
  height: '25px',
  color: '#f69022',
};

export default LegalOne;
