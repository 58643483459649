import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ActionsGroup from '../../../juristec-ui/core/ActionsGroup';
import Button from '../../../juristec-ui/core/Button';
import Select from '../../../juristec-ui/core/Select';

import {
  MainContainer,
} from './styled/ChangeUserRole.styled';

const profileOpts = {
  super: { id: 'super', label: 'Proprietário', value: 'super' },
  scientist: { id: 'scientist', label: 'Cientista', value: 'scientist' },
  guest: { id: 'guest', label: 'Convidado', value: 'guest' },
};

/** Modal to handle user role change */
const ChangeUserRole = ({
  selectedUser, scientists, close, submit,
}) => {
  const [selectedRole, setSelectedRole] = useState(profileOpts[selectedUser?.role || 'guest']);
  const [selectedScientist, setSelectedScientist] = useState({ value: '', label: '' });

  useEffect(() => {
    if (scientists.length > 0) {
      setSelectedScientist(
        scientists.find((s) => s.value === selectedUser?.scientist) || scientists[0],
      );
    }
  }, [selectedRole]);

  const handleSelectRole = (selection) => setSelectedRole(selection);

  const handleSelectScientist = (selection) => setSelectedScientist(selection);

  const handleSubmit = () => {
    if (selectedRole.value === 'guest' && selectedScientist.value) {
      submit(selectedRole.value, selectedScientist.value);
    } else {
      submit(selectedRole.value);
    }
  };

  return (
    <MainContainer>
      <Select
        selectLabel="Perfil do usuário"
        options={Object.values(profileOpts)}
        fullWidth
        placement="start"
        value={selectedRole}
        onChange={handleSelectRole}
        atModal
      />
      {selectedRole.value === 'guest' && (
        <div style={{ marginTop: '1rem' }}>
          <Select
            selectLabel="Cientista responsável"
            options={scientists}
            fullWidth
            placement="start"
            value={selectedScientist}
            onChange={handleSelectScientist}
            atModal
          />
        </div>
      )}
      <ActionsGroup>
        <Button
          style={{ margin: '5px' }}
          onClick={close}
          variant="outlined"
          size="large"
        >
          Cancelar
        </Button>
        <Button
          style={{ margin: '5px' }}
          onClick={handleSubmit}
          size="large"
          disabled={
            selectedRole.value === selectedUser.role && (
              selectedRole.value !== 'guest'
              || selectedScientist.value === selectedUser.scientist
            )
          }
        >
          Alterar
        </Button>
      </ActionsGroup>
    </MainContainer>
  );
};

ChangeUserRole.propTypes = {
  selectedUser: PropTypes.objectOf(PropTypes.any).isRequired,
  scientists: PropTypes.arrayOf(PropTypes.any),
  close: PropTypes.func,
  submit: PropTypes.func,
};

ChangeUserRole.defaultProps = {
  scientists: [],
  close: () => {},
  submit: () => {},
};

export default ChangeUserRole;
