import React from 'react';
import PropTypes from 'prop-types';

import Checkbox from '../../../../juristec-ui/core/Checkbox';
import InputPhoneLine from '../../../../juristec-ui/core/InputPhoneLine';
import { verifyPhone } from '../../../../juristec-ui/utils/validators/inputTextValidators';

import {
  InputContainer,
  MainContainer,
  Messages,
} from './styled/RegisterPhone.styled';

const FirstStep = ({
  checkState,
  setCheckState,
  phoneNumber,
  setPhoneNumber,
}) => {
  const handlePhone = (val, info) => {
    const numbers = val.replace(/[^\d]/g, '');
    const msg = verifyPhone(numbers, true, info.name);
    setPhoneNumber({
      value: numbers,
      format: info.format,
      error: msg.length !== 0,
      errorMsg: msg,
    });
  };

  return (
    <MainContainer>
      <Messages className="info">
        Para aumentar a segurança de sua conta,
        é necessário adicionar e verificar o seu número de celular
      </Messages>
      <InputContainer>
        <InputPhoneLine
          label="Telefone"
          type="tel"
          value={phoneNumber.value}
          errorMessage={phoneNumber.errorMsg}
          error={phoneNumber.errorMsg.length > 0}
          notTransparent={false}
          onChange={handlePhone}
        />
      </InputContainer>
      <Checkbox
        text="Ao adicionar um número, você aceita receber mensagens automáticas de segurança"
        checked={checkState.allow}
        handleCheckboxChange={() => setCheckState((o) => ({ allow: !o.allow, showError: o.allow }))}
        variant="outlined"
        fontSize="14px"
      />
      <Messages
        className="warning"
        style={{
          height: '0',
          marginTop: '-5px',
          visibility: checkState.showError ? 'visible' : 'hidden',
        }}
      >
        Você só pode avançar após aceitar receber as mensagens no seu celular!
      </Messages>
    </MainContainer>
  );
};

FirstStep.propTypes = {
  checkState: PropTypes.shape({
    allow: PropTypes.bool,
    showError: PropTypes.bool,
  }),
  setCheckState: PropTypes.func,
  phoneNumber: PropTypes.shape({
    value: PropTypes.string,
    error: PropTypes.bool,
    errorMsg: PropTypes.string,
  }),
  setPhoneNumber: PropTypes.func,
};

FirstStep.defaultProps = {
  checkState: {
    allow: false,
    showError: false,
  },
  setCheckState: () => {},
  phoneNumber: {
    value: '',
    error: false,
    errorMsg: '',
  },
  setPhoneNumber: () => {},
};

export default FirstStep;
