import React from 'react';

// import { Container } from './styles';

function Share() {
  return (
    <svg
      width="18px"
      height="18px"
      viewBox="0 0 23 25"
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.1353 4.1666C22.1353 6.03638 20.6198 7.55215 18.75 7.55215C16.8802 7.55215 15.3646 6.03638 15.3646 4.1666C15.3646 2.29702 16.8802 0.78125 18.75 0.78125C20.6198 0.78125 22.1353 2.29702 22.1353 4.1666Z"
        // fill={color}
      />
      <path
        d="M18.75 8.3334C16.452 8.3334 14.5834 6.46458 14.5834 4.1666C14.5834 1.86882 16.452 0 18.75 0C21.048 0 22.9166 1.86882 22.9166 4.1666C22.9166 6.46458 21.048 8.3334 18.75 8.3334ZM18.75 1.5625C17.3136 1.5625 16.1459 2.73132 16.1459 4.1666C16.1459 5.60207 17.3136 6.7709 18.75 6.7709C20.1864 6.7709 21.3541 5.60207 21.3541 4.1666C21.3541 2.73132 20.1864 1.5625 18.75 1.5625Z"
        // fill={color}
      />
      <path
        d="M22.1353 20.8334C22.1353 22.703 20.6198 24.2188 18.75 24.2188C16.8802 24.2188 15.3646 22.703 15.3646 20.8334C15.3646 18.9636 16.8802 17.4479 18.75 17.4479C20.6198 17.4479 22.1353 18.9636 22.1353 20.8334Z"
        // fill={color}
      />
      <path
        d="M18.75 25C16.452 25 14.5834 23.1312 14.5834 20.8334C14.5834 18.5354 16.452 16.6666 18.75 16.6666C21.048 16.6666 22.9166 18.5354 22.9166 20.8334C22.9166 23.1312 21.048 25 18.75 25ZM18.75 18.2291C17.3136 18.2291 16.1459 19.3979 16.1459 20.8334C16.1459 22.2687 17.3136 23.4375 18.75 23.4375C20.1864 23.4375 21.3541 22.2687 21.3541 20.8334C21.3541 19.3979 20.1864 18.2291 18.75 18.2291Z"
        // fill={color}
      />
      <path
        d="M7.55215 12.5C7.55215 14.3698 6.03638 15.8854 4.1666 15.8854C2.29702 15.8854 0.78125 14.3698 0.78125 12.5C0.78125 10.6302 2.29702 9.11465 4.1666 9.11465C6.03638 9.11465 7.55215 10.6302 7.55215 12.5Z"
        // fill={color}
      />
      <path
        d="M4.1666 16.6666C1.86882 16.6666 0 14.798 0 12.5C0 10.202 1.86882 8.3334 4.1666 8.3334C6.46458 8.3334 8.3334 10.202 8.3334 12.5C8.3334 14.798 6.46458 16.6666 4.1666 16.6666ZM4.1666 9.8959C2.73018 9.8959 1.5625 11.0645 1.5625 12.5C1.5625 13.9355 2.73018 15.1041 4.1666 15.1041C5.60322 15.1041 6.7709 13.9355 6.7709 12.5C6.7709 11.0645 5.60322 9.8959 4.1666 9.8959Z"
        // fill={color}
      />
      <path
        d="M6.6262 12.0001C6.26361 12.0001 5.91152 11.8114 5.71983 11.474C5.43544 10.9751 5.61054 10.3386 6.1095 10.0531L15.775 4.54273C16.274 4.25624 16.9104 4.43134 17.196 4.93221C17.4804 5.43117 17.3053 6.06765 16.8063 6.35318L7.14061 11.8635C6.97811 11.9562 6.80111 12.0001 6.6262 12.0001Z"
        // fill={color}
      />
      <path
        d="M16.2906 20.5938C16.1155 20.5938 15.9385 20.55 15.776 20.4573L6.11033 14.9469C5.61137 14.6626 5.43647 14.0261 5.72085 13.526C6.00409 13.026 6.64153 12.85 7.14164 13.1365L16.8073 18.6468C17.3063 18.9312 17.4812 19.5677 17.1968 20.0678C17.0041 20.4052 16.652 20.5938 16.2906 20.5938Z"
        // fill={color}
      />
    </svg>
  );
}

export default Share;
