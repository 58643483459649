/* eslint-disable no-case-declarations */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import IconButton from '../../juristec-ui/core/IconButton';
import InputTextLine from '../../juristec-ui/core/InputTextLine';
import Badge from '../../juristec-ui/core/Badge';
import KpiFilter from '../KpiFilter';
import Tooltip from '../../juristec-ui/core/Tooltip';
import {
  ExpandMore, Edit, Trash, Success, Cancel, SortArrow,
} from '../../juristec-ui/icons';

import {
  filterTypeOptionsDate,
  filterTypeOptionsNumber,
  filterTypeOptionsSimple,
  filterTypeOptionsText,
} from '../../options';

import {
  MainContainer,
  HeaderContainer,
  TitleContainer,
  CollapsibleContainer,
  BadgesContainer,
  ControlBtn,
  OrderControls,
  OrderBtn,
} from './styled/KpiFilterApplied.styled';

const KpiFilterApplied = ({
  modifier,
  removeFunc,
  editFunc,
  getOptions,
  usedOpts,
  nameEdit,
  dataFrameTypes,
  position,
  isLast,
  changePosition,
}) => {
  const [extend, setExtend] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [tempValues, setTempValues] = useState([]);
  const [tempName, setTempName] = useState(modifier.rule || '');
  const [optionsValue, setOptionsValue] = useState([]);

  useEffect(() => {
    if (editMode) {
      setTempName(modifier.rule);
      if (modifier.ftype === 'values') {
        setTempValues(modifier.values.map((v) => ({
          label: v, value: v, id: v,
        })));
      } else if (dataFrameTypes[modifier.column] === 'datetime64[ns]'
      && [
        'between', 'between_inc', 'before', 'before_inc', 'after', 'after_inc',
        'not-between', 'not-between_inc', 'not-before', 'not-before_inc', 'not-after', 'not-after_inc',
      ].includes(modifier.ftype)) {
        setTempValues(modifier.values.map((v) => new Date(`${v} 00:00:00`)));
      } else {
        setTempValues(modifier.values);
      }
      (async () => {
        const opts = await getOptions(modifier.column, modifier.selector);
        setOptionsValue(usedOpts ? opts.filter((opt) => (
          !(usedOpts[modifier.column]?.includes(opt.value) && !modifier.values.includes(opt.value))
        )) : opts);
      })();
      setExtend(true);
    }
  }, [editMode]);

  const getLabel = (ftype) => {
    let opts = [...filterTypeOptionsSimple];
    let ftypeFormated = modifier.inverse ? `not-${ftype}` : ftype;
    if (dataFrameTypes[modifier.column] === 'datetime64[ns]') {
      if (['last', 'not-last', 'next', 'not-next'].includes(ftypeFormated)) {
        ftypeFormated = `${ftypeFormated}${modifier.selector?.[0]?.toUpperCase()}`;
      }
      opts = opts.concat(filterTypeOptionsDate);
    } else {
      opts = [...filterTypeOptionsNumber, ...filterTypeOptionsText, ...filterTypeOptionsSimple];
    }
    return opts.find((opt) => opt.value === ftypeFormated)?.label || '';
  };

  const handleSelect = (selected) => {
    const regexFloat = /^-{0,1}\d*\.{0,1}\d*/;
    const regexInt = /^\d*/;
    switch (modifier.ftype) {
      case 'not-values':
      case 'values':
        if (!selected) setTempValues([]);
        else if (selected.length === 0) setTempValues([]);
        else if (selected[0].value === 'select_all') setTempValues(optionsValue);
        else if (selected.length > 0) setTempValues(selected);
        break;
      case 'not-contains':
      case 'contains':
        const auxVals = new Set(tempValues);
        auxVals[selected.operation](selected.val);
        setTempValues([...auxVals]);
        break;
      case 'not-between':
      case 'between':
      case 'not-between_inc':
      case 'between_inc':
        let val1 = selected.start;
        let val2 = selected.end;
        if (dataFrameTypes[modifier.column] !== 'datetime64[ns]') {
          [val1] = selected.start?.toString()?.replace(',', '.')?.match(regexFloat) || '';
          [val2] = selected.end?.toString()?.replace(',', '.')?.match(regexFloat) || '';
        }
        setTempValues([
          val1 || (val1 !== '' ? tempValues[0] : ''), val2 || (val2 !== '' ? tempValues[1] : ''),
        ]);
        break;
      case 'not-before':
      case 'before':
      case 'not-before_inc':
      case 'before_inc':
      case 'not-after':
      case 'after':
      case 'not-after_inc':
      case 'after_inc':
        setTempValues([selected]);
        break;
      case 'not-greater':
      case 'greater':
      case 'not-lesser':
      case 'lesser':
      case 'not-greater_inc':
      case 'greater_inc':
      case 'not-lesser_inc':
      case 'lesser_inc':
        const [valF] = selected.toString().replace(',', '.').match(regexFloat);
        setTempValues([valF || '']);
        break;
      default:
        const [val] = selected.toString().match(regexInt);
        setTempValues([val || '']);
        break;
    }
  };

  return (
    <MainContainer>
      <HeaderContainer>
        {typeof position === 'number' && (
          <OrderControls disableFirst={position === 0} disableLast={isLast}>
            <Tooltip text="Subir prioridade" direction="right">
              <OrderBtn className="order-increase" onClick={() => changePosition(position, -1)}><SortArrow /></OrderBtn>
            </Tooltip>
            <Tooltip text="Descer prioridade" direction="right">
              <OrderBtn className="order-decrease" onClick={() => changePosition(position, 1)}><SortArrow /></OrderBtn>
            </Tooltip>
          </OrderControls>
        )}
        <TitleContainer>
          {nameEdit && editMode ? (
            <InputTextLine
              value={tempName}
              onChange={(e) => setTempName(e.target.value)}
              style={{ padding: '3px 0' }}
            />
          ) : (
            <span className="header-title">
              {typeof position === 'number' ? `${position + 1}°. ` : ''}
              {modifier.rule || modifier.column}
            </span>
          )}
          <span className="header-subtitle">
            {modifier.rule ? `${modifier.column} - ` : ''}
            {getLabel(modifier.ftype || 'values')}
          </span>
        </TitleContainer>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          {editMode ? (
            <>
              <IconButton onClick={() => {
                editFunc(modifier.uid, modifier.column, tempValues, modifier.ftype, modifier.rule, tempName);
                setEditMode(false);
              }}
              >
                <Success />
              </IconButton>
              <IconButton onClick={() => setEditMode(!editMode)}>
                <Cancel />
              </IconButton>
            </>
          ) : (
            <IconButton onClick={() => setEditMode(true)}>
              <Edit />
            </IconButton>
          )}
          <IconButton onClick={() => removeFunc(modifier.uid, modifier.column)}>
            <Trash />
          </IconButton>
        </div>
      </HeaderContainer>
      <CollapsibleContainer
        control={extend}
        customSize={
          modifier.ftype === 'contains' || modifier.ftype === 'not-contains' ? 250 : 0
        }
      >
        {editMode ? (
          <div style={{ margin: '5px 5px 10px' }}>
            <KpiFilter
              filterType={modifier.ftype || 'values'}
              options={optionsValue}
              values={tempValues}
              handle={handleSelect}
              format={dataFrameTypes[modifier.column]}
            />
          </div>
        ) : (
          <BadgesContainer>
            {modifier.values.map((va) => (
              <Badge
                key={va}
                color="secondary"
                size="small"
              >
                {va}
              </Badge>
            ))}
          </BadgesContainer>
        )}
      </CollapsibleContainer>
      <ControlBtn onClick={() => setExtend(!extend)} flip={extend} lock={editMode}>
        <div className="flip-wrapper">
          <ExpandMore />
        </div>
      </ControlBtn>
    </MainContainer>
  );
};

KpiFilterApplied.propTypes = {
  modifier: PropTypes.shape({
    uid: PropTypes.string,
    values: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.any), PropTypes.string]),
    ftype: PropTypes.string,
    column: PropTypes.string,
    selector: PropTypes.string,
    inverse: PropTypes.bool,
    rule: PropTypes.string,
  }),
  removeFunc: PropTypes.func,
  editFunc: PropTypes.func,
  getOptions: PropTypes.func,
  usedOpts: PropTypes.arrayOf(PropTypes.any),
  nameEdit: PropTypes.bool,
  dataFrameTypes: PropTypes.objectOf(PropTypes.any),
  position: PropTypes.number,
  isLast: PropTypes.bool,
  changePosition: PropTypes.func,
};

KpiFilterApplied.defaultProps = {
  modifier: {
    uid: '',
    values: undefined,
    ftype: 'values',
    column: '',
    selector: '',
    inverse: false,
    rule: '',
  },
  removeFunc: () => {},
  editFunc: () => {},
  getOptions: () => {},
  usedOpts: [],
  nameEdit: false,
  dataFrameTypes: {},
  position: 1,
  isLast: false,
  changePosition: () => {},
};

export default KpiFilterApplied;
