import styled, { css } from 'styled-components';

const SystemMessageBanner = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin-bottom: .5rem;
  & > div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  border-radius: .5rem;
  ${({ theme, lvl }) => css`
    background-color: ${theme[lvl]}20;
    & .banner-header {
      padding: .1rem .4rem;
      & h5 {
        margin: 0;
        color: ${theme[lvl]};
        font-size: 16px;
        text-transform: uppercase;
      }
      & span {
        color: ${theme.grey};
        font-size: 12px;
      }
      & svg {
        fill: ${theme.color};
      }
    }
    & .banner-body {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: .1rem .4rem .4rem .4rem;
      & span {
        white-space: pre-wrap;
      }
      & button {
        padding: 1px 0;
        box-shadow: none;
        text-transform: none;
        font-weight: normal;
        border-bottom: 2px solid transparent;
        &:hover {
          border-bottom: 2px solid ${theme.info};
        }
      }
      & svg {
        height: 14px;
        width: 14px;
        margin: 0;
      }
    }
  `}
`;

export default SystemMessageBanner;
