/* eslint-disable react/forbid-prop-types */
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import Checkbox from '../../../juristec-ui/core/Checkbox';
import useOnScreen from '../../../juristec-ui/hooks/useOnScreen';
import TextOverflow from '../../../juristec-ui/core/TextOverflow';

import { OrderRow } from './styled/GlobalFilter.styled';

const GlobalFilterItem = ({
  option, handler, selected,
}) => {
  const itemRef = useRef();
  const isVisible = useOnScreen(itemRef);

  return (
    <OrderRow ref={itemRef} className="hover-bgcolor">
      {isVisible ? (
        <Checkbox
          text={(
            <TextOverflow width={200} fontSize={14}>
              {option.label}
            </TextOverflow>
          )}
          variant="outlined"
          style={{ width: '100%', justifyContent: 'flex-start' }}
          checked={selected?.includes(option.value)}
          handleCheckboxChange={(check) => handler(check, option.value)}
        />
      ) : (<></>)}
    </OrderRow>
  );
};

GlobalFilterItem.propTypes = {
  option: PropTypes.objectOf(PropTypes.any).isRequired,
  handler: PropTypes.func,
  selected: PropTypes.arrayOf(PropTypes.any),
};

GlobalFilterItem.defaultProps = {
  handler: () => {},
  selected: [],
};

export default GlobalFilterItem;
