export const scheduleOptions = [
  { id: 'daily', label: 'Diário', value: 'daily' },
  { id: 'weekly', label: 'Semanal', value: 'weekly' },
  { id: 'monthy', label: 'Mensal', value: 'monthly' },
  { id: 'quarterly', label: 'Trimestral', value: 'quarterly' },
  { id: 'semiannual', label: 'Semestral', value: 'semiannual' },
  { id: 'yearly', label: 'Anual', value: 'yearly' },
];

export const weekDayOptions = [
  {
    id: 'Segunda-feira',
    label: 'Segunda-feira',
    value: 'Segunda-feira',
    cron: '1',
  },
  {
    id: 'Terça-feira',
    label: 'Terça-feira',
    value: 'Terça-feira',
    cron: '2',
  },
  {
    id: 'Quarta-feira',
    label: 'Quarta-feira',
    value: 'Quarta-feira',
    cron: '3',
  },
  {
    id: 'Quinta-feira',
    label: 'Quinta-feira',
    value: 'Quinta-feira',
    cron: '4',
  },
  {
    id: 'Sexta-feira',
    label: 'Sexta-feira',
    value: 'Sexta-feira',
    cron: '5',
  },
];

export const monthDayOptions = Array.from(Array(31).keys()).map((i) => (
  {
    id: i + 1,
    label: i + 1,
    value: i + 1,
    cron: i + 1,
  }
));
