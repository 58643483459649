import React from 'react';

import {
  Container,
  DashboardName,
  OwnerInformation,
  InfoContainer,
  DevelopmentCredits,
} from './styled/PublicKpiToolbar.styled';

import { formatDateTime } from '../../../juristec-ui/utils/functions/lab';

import JuristecLogo from '../../../juristec-ui/icons/JuristecLogo';

function PublicKpiToolbar({ dashboardDoc }) {
  return (
    <Container>
      <InfoContainer>
        <OwnerInformation>
          <div>
            <span className="info-title">Criado por:</span>
            <span className="info-description">
              {' '}
              {dashboardDoc?.displayName}
            </span>
          </div>
          <div>
            <span className="info-title">Última atualização:</span>
            <span className="info-description">
              {' '}
              {(dashboardDoc?.lastUpdateUser || dashboardDoc?.created_at)
                ? formatDateTime(dashboardDoc?.lastUpdateUser || dashboardDoc?.created_at, { time: 'half' })
                : '' }
            </span>
          </div>
        </OwnerInformation>
        <DashboardName>
          {dashboardDoc?.name || ''}
        </DashboardName>
        <DevelopmentCredits>
          {process.env.REACT_APP_FIREBASE_PROJECT_LABEL !== 'benner-metrics' && (
            <>
              <span className="footer-text">
                Desenvolvido por Juristec
              </span>
              <JuristecLogo />
            </>
          )}
        </DevelopmentCredits>
      </InfoContainer>
    </Container>
  );
}

export default PublicKpiToolbar;
