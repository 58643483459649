import React from 'react';

// history, router stuffs
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';

// menu stuffs
// import Menu from '../Menu';

// authentication stuffs
import { AuthProvider } from '../../context/AuthProvider';

import PrivateRoute from '../PrivateRoute';

// timer component
import Timer from '../Timer';

// view pages
import LoginPage from '../../views/LoginPage';
import HandleActionUrlPage from '../../views/HandleActionUrlPage';
import ResetPasswordPage from '../../views/ResetPasswordPage';
import PublicPage from '../../views/PublicPage';
import NotFoundFile from './NotFoundFile';
import ExtractorCredsPage from '../../views/ExtractorCredsPage';

import Navigation from './Navigation';

import { AlertApiProvider } from '../../context/AlertProvider';
import { ModalApiProvider } from '../../context/ModalProvider';
import { TourProvider } from '../../context/TourProvider';
import FilesProvider from '../../context/FilesProvider';
import TemplateProvider from '../../context/TemplateProvider';
import UsersProvider from '../../context/UsersProvider';
import NotificationsProvider from '../../context/NotificationProvider';
import PolicyProvider from '../../context/PolicyProvider';
import StyleProvider from '../../context/StyleProvider';
import BigDataProvider from '../../context/BigDataProvider';

export default function App() {
  return (
    <AuthProvider>
      <UsersProvider>
        <StyleProvider>
          <NotificationsProvider>
            <PolicyProvider>
              <Router>
                <FilesProvider>
                  <BigDataProvider>
                    <TemplateProvider>
                      <AlertApiProvider>
                        <TourProvider>
                          {/* passando como props para n dar dependecia ciclica */}
                          <ModalApiProvider AuthProvider={AuthProvider}>
                            <Timer />
                            <Switch>
                              <Route exact path="/" component={LoginPage} />
                              <Route exact path="/login" component={LoginPage} />
                              <Route exact path="/public/:hash" component={PublicPage} />
                              <Route exact path="/action" component={HandleActionUrlPage} />
                              <Route exact path="/reset_password" component={ResetPasswordPage} />
                              {(process.env.REACT_APP_FIREBASE_PROJECT_LABEL === 'legalone-analytics')
                              && <Route exact path="/extractor_creds/:hash" component={ExtractorCredsPage} />}
                              <PrivateRoute path="/" component={Navigation} />

                              <Route render={() => <NotFoundFile />} />
                            </Switch>
                          </ModalApiProvider>
                        </TourProvider>
                      </AlertApiProvider>
                    </TemplateProvider>
                  </BigDataProvider>
                </FilesProvider>
              </Router>
            </PolicyProvider>
          </NotificationsProvider>
        </StyleProvider>
      </UsersProvider>
    </AuthProvider>
  );
}
