import React from 'react';

const ChartRadialBar = ({ primary = '#4711B2', secondary = '#FF9626' }) => (
  <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M60 30C60 47.5 47.5 60 30 60C12.5 60 0 47.5 0 30H6C6 44 16 54 30 54C44 54 54 44 54 30C54 16 44 6 30 6V0C47.5 0 60 12.5 60 30Z" fill={primary} />
    <path d="M8 30C8 42.5 17.5 52 30 52V46C20 46 14 40 14 30H8Z" fill={secondary} />
    <path d="M30 44C38.5 44 44 38.5 44 30H38C38 34.5 34.5 38 30 38C25.5 38 22 34.5 22 30H16C16 38.5 21.5 44 30 44Z" fill={secondary} />
    <path d="M44 30C44 21.5 38.5 16 30 16V22C34.5 22 38 25.5 38 30H44Z" fill={primary} />
    <path d="M52 30C52 17.5 42.5 8 30 8V14C40 14 46 20 46 30C46 40 40 46 30 46V52C42.5 52 52 42.5 52 30Z" fill={primary} />
  </svg>
);

export default ChartRadialBar;
