import styled, { css } from 'styled-components';

const types = (type, theme) => {
  switch (type) {
    case 'category':
      return theme.info;
    case 'datetime64[ns]':
      return theme.warning;
    case 'float64':
      return theme.success;
    default:
      return '';
  }
};

export const MainContainer = styled.div`
  padding: 0 1rem;
  max-width: 1000px;
  overflow-x: hidden;
  overflow-y: hidden;
`;

export const GridContainer = styled.div`
  display: grid;
  position: relative;
  grid-template-columns: 1fr minmax(0, 30vw);
  grid-gap: 1rem;
  height: calc(100vh - 380px);
`;

const Side = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  & > * {
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const LeftSide = styled(Side)`
  width: 250px;
  & > * {
    margin-bottom: 1rem;
  }
  & > span {
    margin-bottom: .5rem;
  }
  & span.alias-warning {
    color: ${({ theme }) => theme.grey};
    font-size: 12px;
    font-style: italic;
    margin-bottom: 1rem;
    & b {
      color: ${({ theme }) => theme.error};
    }
  }
`;

export const RightSide = styled(Side)`
  padding: .5rem;
  align-items: flex-start;
  align-content: flex-start;
  background-color: ${({ theme }) => theme.card};
  overflow-y: auto;
  & > div {
    width: 100%;
    cursor: default;
  }
  & > * {
    margin-bottom: 10px;
  }
`;

export const EmptyMsg = styled.span`
  text-align: center;
  margin-top: 1rem;
  width: 100%;
  color: ${({ theme }) => theme.grey};
`;

export const InfoTooltip = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

export const ColumnBadge = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border: 2px solid;
  padding: .2rem;
  border-radius: .5rem;
  width: 100%;
  box-sizing: border-box;
  ${({ theme, varType }) => css`
    border-color: ${types(varType, theme)};
    color: ${types(varType, theme)};
    background-color: ${types(varType, theme)}20;
    & > svg {
      fill: ${types(varType, theme)};
      margin-right: 10px;
      height: 30px;
      width: 30px;
    }
  `};
  & > button svg {
    width: 12px;
    height: 12px;
  }
  & > div {
    width: 100%;
    & h5, span {
      display: block;
      margin: 0;
      width: calc(100% - 10px);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    & h5 {
      font-size: 16px;
    }
    & span {
      font-size: 14px;
      font-style: italic;
    }
  }
`;
