import styled, { css } from 'styled-components';

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

export const FormContainer = styled(Column)`
  height: 55vh;
  width: 75vw;
  padding: 0 1rem 0 .5rem;
`;

export const MainContainer = styled(Row)``;

export const InfoLabels = styled(Row)`
  color: ${({ theme }) => theme.grey};
  font-size: 12px;
  margin-top: 5px;
  padding: 0 1rem;
  & span:last-child {
    margin-left: auto;
  }
`;

export const UploadArea = styled.div`
  background-color: ${({ theme }) => theme.uploadArea};;
  border-radius: .5rem;
  padding: .5rem;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  ${(props) => props.dragging
    && css`filter: brightness(60%);`};
`;

export const UploadBorder = styled(Column)`
  border: 2px dashed ${({ theme }) => theme.grey};
  align-items: center;
  padding: 2rem;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  & > span {
    font-size: 18px;
  };
  & svg {
    height: 22px;
    width: 22px;
  }
`;

export const UploadOpts = styled(Column)`
  margin-top: 10px;
  & button {
    margin: 0 auto;
  };
  & span {
    font-size: 12px;
  };
`;

export const FileInfoContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 10px;
  & span {
    font-size: 16px;
  }
  & .errorMsg {
    color: ${({ theme }) => theme.error};
  }
`;

export const InputContainer = styled(Column)`
  background-color: ${({ theme }) => theme.container};
  padding: 1rem;
  padding-top: .5rem;
  border-radius: 5px;
  width: 100%;
  box-sizing: border-box;
  & > span {
    font-size: 14px;
  }
  & > * {
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const FileInfo = styled(Row)`
  padding: 5px;
  background-color: ${({ theme }) => theme.container};
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.containerHover};
  justify-content: center;
  align-items: center;
  gap: 5px;
    
  & svg {
    height: 20px;
    fill: ${({ theme }) => theme.primary};
  }
`;

export const TabGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: 55vh;
  overflow-y: auto;
  width: 22vw;
  & button {
    margin-bottom: 5px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const TabButton = styled.button`
  background-color: ${({ theme }) => theme.container};
  width: 100%;
  height: 45px;
  font-weight: bold;
  border: none;
  box-sizing: border-box;
  color: ${({ theme }) => theme.primary};
  display: flex;
  font-size: 16px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 5px;
  & svg {
    fill: ${({ theme }) => theme.primary};
    height: 24px;
    width: 24px;
    margin-right: 5px;
  }
  & span {
    margin: auto;
  }
  & .icon-detail {
    transform: rotate(270deg);
    height: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    & svg {
      fill: ${({ theme }) => theme.container};
      margin: 0;
    }
  }
  ${({ theme, active }) => (active ? css`
    background-color: ${theme.secondary};
    color: ${theme.white};
    & svg {
      fill: ${theme.white};
    }
    & .icon-detail svg {
      fill: ${theme.white};
    }
  ` : css`
    &:hover {
      background-color: ${theme.selectHover};
      & .icon-detail svg {
        fill: ${theme.selectHover};
      }
    }
  `)}
`;

export const InputRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  & .select-wrapper {
    max-width: 50px;
  }
  & > * {
    margin-right: 1rem;
    &:last-child {
      margin-right: 0;
    }
  }
  & .code-textarea {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    border: 1px solid ${({ theme }) => theme.inputBottomLine};
    border-radius: 5px;
  }
  & .extra-label {
    color: ${({ theme }) => theme.inputLabel};
    margin-left: auto;
  }
`;

export const OptionGroup = styled(Row)`
  width: 100%;
  align-items: center;
  justify-content: center;
`;

export const Option = styled.button`
  background-color: ${({ theme }) => theme.containerOdd};
  color: ${({ theme }) => theme.primary};
  padding: 5px;
  border: none;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 130px;
  font-weight: bold;
  height: 38px;
  cursor: pointer;
  & svg {
    height: 22px;
    width: 22px;
    margin-right: 5px;
  }
  ${({ theme, active }) => (active ? css`
    background-color: ${theme.primary};
    color: ${theme.white};
    & svg {
      fill: ${theme.white};
    }
  ` : css`
    &:hover {
      background-color: ${theme.selectHover};
    }
  `)}
`;
