import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ActionsGroup from '../../../juristec-ui/core/ActionsGroup';
import Button from '../../../juristec-ui/core/Button';
import DashStyles from '../../DashStyles';
import { defaultDashStyle } from '../../../options';

const DashStylesModal = ({
  stylesData, submit, close,
}) => {
  const [tempStylesData, setTempStylesData] = useState(defaultDashStyle);

  useEffect(() => {
    if (stylesData) {
      setTempStylesData({
        ...defaultDashStyle,
        ...stylesData,
        borderLine: stylesData.borderStyle?.line ?? defaultDashStyle.borderLine,
        borderColor: stylesData.borderStyle?.color ?? defaultDashStyle.borderColor,
        borderSize: stylesData.borderStyle?.size ?? defaultDashStyle.borderSize,
        borderRounded: stylesData.borderStyle?.rounded ?? defaultDashStyle.borderRounded,
        spaceBetween: stylesData.spaceBetween !== undefined ? (
          stylesData.spaceBetween / 10
        ) : defaultDashStyle.spaceBetween,
      });
    }
  }, [stylesData]);

  const handleSubmit = () => {
    submit({
      backgroundColor: tempStylesData.backgroundColor,
      shading: tempStylesData.shading,
      borderStyle: {
        color: tempStylesData.borderColor,
        line: tempStylesData.borderLine,
        rounded: (+tempStylesData.borderRounded),
        size: (+tempStylesData.borderSize),
      },
      spaceBetween: (+tempStylesData.spaceBetween) * 10,
      useGlobalTheme: tempStylesData.useGlobalTheme,
      titles: tempStylesData.titles,
      charts: tempStylesData.charts.filter((c) => Boolean(c)),
      insightBg: tempStylesData.insightBg,
      insightTxt: tempStylesData.insightTxt,
      valueBg: tempStylesData.valueBg,
      valueTxt: tempStylesData.valueTxt,
      tableRows: tempStylesData.tableRows,
      tableHeader: tempStylesData.tableHeader,
    });
  };

  return (
    <>
      <DashStyles tempStylesDataState={[tempStylesData, setTempStylesData]} />
      <ActionsGroup>
        <Button style={{ margin: '5px' }} onClick={close} variant="outlined" size="large">
          Cancelar
        </Button>
        <Button
          style={{ margin: '5px' }}
          onClick={handleSubmit}
          size="large"
          className="button_color_selection"
        >
          Confirmar
        </Button>
      </ActionsGroup>
    </>
  );
};

DashStylesModal.propTypes = {
  stylesData: PropTypes.shape({
    backgroundColor: PropTypes.string,
    borderStyle: PropTypes.shape({
      color: PropTypes.string,
      line: PropTypes.string,
      rounded: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    shading: PropTypes.bool,
    spaceBetween: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  submit: PropTypes.func,
  close: PropTypes.func,
};

DashStylesModal.defaultProps = {
  stylesData: {},
  submit: () => {},
  close: () => {},
};

export default DashStylesModal;
