import React from 'react';

const ChartTable = ({ primary = '#4711B2', secondary = '#FF9626' }) => (
  <svg width="60" height="57" viewBox="0 0 60 57" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M14.1176 1.76471C14.1176 0.790086 14.9077 0 15.8824 0H58.2353C59.2099 0 60 0.790086 60 1.76471V10.5882H56.4706V3.52941H45.8824V10.5882H42.3529V3.52941H31.7647V10.5882H28.2353V3.52941H17.6471V10.5882H14.1176V1.76471ZM3.52941 14.1176H14.1176L14.1176 10.5882H1.76471C0.790086 10.5882 0 11.3783 0 12.3529V54.7059C0 55.6805 0.790086 56.4706 1.76471 56.4706H14.1176V52.9412H3.52941V45.8824H14.1176V42.3529H3.52941V35.2941H14.1176V31.7647H3.52941V24.7059H14.1176V21.1765H3.52941V14.1176Z" fill={secondary} />
    <path fillRule="evenodd" clipRule="evenodd" d="M14.1176 10.5882V56.4706H58.2353C59.2099 56.4706 60 55.6805 60 54.7059V10.5882H14.1176ZM56.4706 14.1176H45.8824V21.1765H56.4706V14.1176ZM56.4706 24.7059H45.8824V31.7647H56.4706V24.7059ZM56.4706 35.2941H45.8824V42.3529H56.4706V35.2941ZM56.4706 45.8824H45.8824V52.9412H56.4706V45.8824ZM42.3529 52.9412V45.8824H31.7647V52.9412H42.3529ZM28.2353 52.9412V45.8824H17.6471V52.9412H28.2353ZM17.6471 42.3529H28.2353V35.2941H17.6471V42.3529ZM17.6471 31.7647H28.2353V24.7059H17.6471V31.7647ZM17.6471 21.1765H28.2353V14.1176H17.6471V21.1765ZM31.7647 14.1176V21.1765H42.3529V14.1176H31.7647ZM42.3529 24.7059H31.7647V31.7647H42.3529V24.7059ZM42.3529 35.2941H31.7647V42.3529H42.3529V35.2941Z" fill={primary} />
  </svg>
);

export default ChartTable;
