import React from 'react';
import PropTypes from 'prop-types';

import ControllersGroup from '../../../controllers/ControllersGroup';
import {
  ToggleSwitchController,
  RangeController,
  ChoiceBoxController,
  InputLineController,
} from '../../../controllers/Base';

const GeneralControllers = ({
  handle,
  config,
  extend,
  setExtend,
}) => (
  <>
    <ControllersGroup
      title="Opções Gerais"
      id="general"
      key="general"
      extend={extend}
      setExtend={setExtend}
    >
      <ChoiceBoxController
        label="Formato"
        handle={handle}
        kWord="LayoutControl"
        configValue={config?.LayoutControl}
        options={[
          { label: 'Horizontal', value: 'horizontal', id: 'horizontal' },
          { label: 'Vertical', value: 'vertical', id: 'vertical' },
        ]}
        fullWidth
      />
      <RangeController
        label="Espaço entre os anos"
        handle={handle}
        kWord="YearSpacingControl"
        configValue={config?.YearSpacingControl}
        min={0}
        max={160}
        fullWidth
      />
      <RangeController
        label="Espaço entre os meses"
        handle={handle}
        kWord="MonthSpacingControl"
        configValue={config?.MonthSpacingControl}
        min={0}
        max={45}
        step={1}
        fullWidth
      />
      <RangeController
        label="Espaço entre os dias"
        handle={handle}
        kWord="DaySpacingControl"
        configValue={config?.DaySpacingControl}
        min={0}
        max={10}
        step={1}
        fullWidth
      />
      <RangeController
        label="Margem Superior"
        handle={handle}
        kWord="MarginTop"
        configValue={config?.Margin?.top}
        min={0}
        max={500}
        step={1}
        precision={0}
        fullWidth
      />
      <RangeController
        label="Margem Inferior"
        handle={handle}
        kWord="MarginBottom"
        configValue={config?.Margin?.bottom}
        min={0}
        max={500}
        step={1}
        precision={0}
        fullWidth
      />
      <RangeController
        label="Margem Esquerda"
        handle={handle}
        kWord="MarginLeft"
        configValue={config?.Margin?.left}
        min={0}
        max={500}
        step={1}
        precision={0}
        fullWidth
      />
      <RangeController
        label="Margem Direita"
        handle={handle}
        kWord="MarginRight"
        configValue={config?.Margin?.right}
        min={0}
        max={500}
        step={1}
        precision={0}
        fullWidth
      />
      <ToggleSwitchController
        label="Valor mínimo"
        handle={handle}
        kWord="MinValueControl2"
        configValue={config?.MinValueControl?.checked}
        firstOption="Automático"
        secondOption="Manual"
        secondColor="secondary"
      />
      <InputLineController
        isNumber
        precision={2}
        label=""
        handle={handle}
        kWord="MinValue"
        configValue={config?.MinValueControl?.value}
        disabled={config?.MinValueControl?.checked}
      />
      <ToggleSwitchController
        label="Valor máximo"
        handle={handle}
        kWord="MaxValueControl2"
        configValue={config?.MaxValueControl?.checked}
        firstOption="Automático"
        secondOption="Manual"
        secondColor="secondary"
      />
      <InputLineController
        isNumber
        precision={2}
        label=""
        handle={handle}
        kWord="MaxValue"
        configValue={config?.MaxValueControl?.value}
        disabled={config?.MaxValueControl?.checked}
      />
      <ChoiceBoxController
        label="Formato dos dados"
        handle={handle}
        kWord="DataFormatType"
        configValue={config?.DataFormat?.type}
        options={[
          { value: 'absolute', label: 'Absoluto', id: 'absolute' },
          { value: 'percentual', label: 'Percentual', id: 'percentual' },
        ]}
        fullWidth
      />
    </ControllersGroup>
  </>
);

GeneralControllers.propTypes = {
  config: PropTypes.objectOf(PropTypes.any),
  handle: PropTypes.func,
  extend: PropTypes.string,
  setExtend: PropTypes.func,
};

GeneralControllers.defaultProps = {
  config: undefined,
  handle: () => {},
  extend: '',
  setExtend: () => {},
};

export default GeneralControllers;
