import React, {
  useState, useRef, useEffect,
} from 'react';
import PropTypes from 'prop-types';

import ActionsGroup from '../../../juristec-ui/core/ActionsGroup';
import Button from '../../../juristec-ui/core/Button';
import InputTextLine from '../../../juristec-ui/core/InputTextLine';
import CardKpi from '../../../juristec-ui/kpis/grid/CardKpi';

import { verifyLink } from '../../../juristec-ui/utils/validators/inputTextValidators';

import {
  ExpandMoreWide, AddKpi, Report, Chain,
} from '../../../juristec-ui/icons';
import { defaultDashStyle } from '../../../options';

import {
  FormContainer, InputContainer, KpiWrapper, MainContainer, TabButton, TabGroup,
} from './styled/IframeCreate.styled';

const IframeCreate = ({
  item, hide, submit, stylesData, getPlotlyLink,
}) => {
  const cron = useRef();
  const kpiRef = useRef();
  const [tab, setTab] = useState('general');
  const [inputLink, setInputLink] = useState({ url: '', error: true, errorMsg: '' });
  const [tempItem, setTempItem] = useState({
    id: 'new', type: 'Iframe', link: '', linkType: 'general',
  });

  useEffect(() => {
    if (item) {
      const { linkId, ...newObj } = item;
      setTempItem(newObj);
      setTab(newObj.linkType);
      if (newObj.linkType === 'plotly' && newObj.link) {
        setInputLink({ url: linkId, error: false, errorMsg: '' });
      } else {
        setInputLink({ url: newObj.link ?? '', error: false, errorMsg: '' });
      }
    }
  }, [item]);

  const changeTabs = (key) => {
    setInputLink({ url: '', error: true, errorMsg: '' });
    setTempItem((o) => ({
      ...o,
      link: '',
      linkType: key,
    }));
    setTab(key);
  };

  const setGeneralTab = () => changeTabs('general');
  const setPlotlyTab = () => changeTabs('plotly');

  const handleGeneral = (e) => {
    const val = e.target.value.trim();
    const regRes = val.match(/(?<=src=").*?(?=[*"])/);
    const link = regRes && regRes.length > 0 ? regRes[0] : val;
    const errMsg = verifyLink(link);
    setInputLink({
      url: link,
      error: errMsg.length !== 0,
      errorMsg: errMsg,
    });
    if (errMsg.length === 0) {
      clearTimeout(cron.current);
      cron.current = setTimeout(() => {
        setTempItem((o) => ({
          ...o,
          link,
          linkType: 'general',
        }));
      }, 1000);
    }
  };

  const handleGetPlotlyLink = async (plotlyId) => {
    const link = await getPlotlyLink(plotlyId);
    setTempItem((o) => ({
      ...o,
      link,
      linkType: 'plotly',
    }));
  };

  const handlePlotly = (e) => {
    const val = e.target.value.trim();
    const errMsg = '';
    setInputLink({
      url: val,
      error: errMsg.length !== 0,
      errorMsg: errMsg,
    });
    if (errMsg.length === 0) {
      clearTimeout(cron.current);
      cron.current = setTimeout(() => {
        // setTempItem((o) => ({
        //   ...o,
        //   link: val,
        //   linkType: 'plotly',
        // }));
        handleGetPlotlyLink(val);
      }, 1000);
    }
  };

  const handleSubmit = async () => {
    if (tempItem.link?.length > 0) {
      if (await submit({
        ...tempItem,
        link: inputLink.url,
      })) {
        hide();
      }
    }
  };

  return (
    <>
      <MainContainer>
        <TabGroup>
          <TabButton active={tab === 'general'} onClick={setGeneralTab}>
            <Chain />
            <span>Conteúdo Externo</span>
            <div className="icon-detail">
              <ExpandMoreWide />
            </div>
          </TabButton>
          <TabButton active={tab === 'plotly'} onClick={setPlotlyTab}>
            <AddKpi />
            <span>KPI Customizado</span>
            <div className="icon-detail">
              <ExpandMoreWide />
            </div>
          </TabButton>
          {tab === 'general' && (
            <p>
              Informe um link do conteúdo a ser incorporado ao dashboard.
              Alguns sites não permitem a incorporação em outros sistemas.
              Outros, oferecem a opção na forma de código
              (no YouTube, por exemplo, a opção para inclusão está localizada
              em &quot;Compartilhar &gt; Incorporar &gt; Copiar&quot;).
              Informe esse código e o sistema tentará extrarir um link válido.
            </p>
          )}
          {tab === 'plotly' && (
            <p>
              Essa função só deve ser executada caso o suporte tenha criado
              um KPI customizado para o usuário. Neste caso, informe o ID do
              KPI externo a ser incorporado ao dashboard. Contate o suporte
              para obtenção ou em caso de perda do ID.
            </p>
          )}
        </TabGroup>
        <FormContainer>
          <InputContainer>
            {tab === 'general' && (
              <InputTextLine
                label="Link a ser adicionado"
                error={inputLink.errorMsg.length > 0}
                errorMessage={inputLink.errorMsg}
                value={inputLink.url}
                onChange={handleGeneral}
              />
            )}
            {tab === 'plotly' && (
              <InputTextLine
                label="ID do KPI"
                error={inputLink.errorMsg.length > 0}
                errorMessage={inputLink.errorMsg}
                value={inputLink.url}
                onChange={handlePlotly}
              />
            )}
            <KpiWrapper
              style={{
                borderStyle: stylesData?.borderStyle?.line ?? defaultDashStyle.borderLine,
                borderColor: stylesData?.borderStyle?.color ?? defaultDashStyle.borderColor,
                borderWidth: stylesData?.borderStyle?.size ?? defaultDashStyle.borderSize,
                borderRadius: stylesData?.borderStyle?.rounded ?? defaultDashStyle.borderRounded,
                backgroundColor: stylesData?.backgroundColor ?? defaultDashStyle.backgroundColor,
              }}
            >
              {inputLink.error || inputLink.url.length <= 0 || !tempItem.link ? (
                <Report />
              ) : (
                <CardKpi
                  ref={kpiRef}
                  item={tempItem}
                  editPainel
                  collection="kpis"
                />
              )}
            </KpiWrapper>
          </InputContainer>
        </FormContainer>
      </MainContainer>
      <ActionsGroup>
        <Button
          style={{ margin: '5px' }}
          onClick={hide}
          variant="outlined"
          size="large"
        >
          Cancelar
        </Button>
        <Button
          style={{ margin: '5px' }}
          onClick={handleSubmit}
          size="large"
          disabled={inputLink.error || inputLink.url.length <= 0 || !tempItem.link}
          className="modal_upload_file_advance"
        >
          {item ? 'Adicionar' : 'Salvar'}
        </Button>
      </ActionsGroup>
    </>
  );
};

IframeCreate.propTypes = {
  item: PropTypes.shape({
    linkId: PropTypes.string,
  }),
  hide: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  stylesData: PropTypes.shape({
    borderStyle: PropTypes.shape({
      line: PropTypes.string,
      color: PropTypes.string,
      size: PropTypes.number,
      rounded: PropTypes.number,
    }),
    backgroundColor: PropTypes.string,
  }),
  getPlotlyLink: PropTypes.func.isRequired,
};

IframeCreate.defaultProps = {
  stylesData: {},
  item: null,
};

export default IframeCreate;
