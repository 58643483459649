import styled from 'styled-components';

import { Responsive, WidthProvider } from 'react-grid-layout';

const ResponsiveReactGridLayout = WidthProvider(Responsive);

const StyledResponsiveReactGridLayout = styled(ResponsiveReactGridLayout)`
  position: relative;
  & > div {
    cursor: ${(props) => (props.dragging ? 'grabbing' : 'grab')};
    user-select: none;
    &:before {
      content: '';
      display: block;
      position: absolute;
      top: -2px;
      bottom: -2px;
      left: -2px;
      right: -2px;
      border-radius: 1rem;
      border: ${(props) => (props.editPainel ? 'dashed 2px #a9a9a9' : 'solid 2px transparent')};
    }
    & > *{
      pointer-events: ${(props) => (props.editPainel ? 'none' : 'all')};
    }
  }
`;

export default StyledResponsiveReactGridLayout;
