import styled, { css } from 'styled-components';

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 700px;
  height: calc(100vh - 350px);
  & input, textarea {
    font-size: 14px !important;
  }
  & > div:first-child {
    margin-bottom: .5rem;
  }
  & > div + div {
    margin-top: .5rem;
  }
`;

export const InputRow = styled.div`
  display: flex;
  flex-direction: row;
  & > div {
    flex: 2;
    & + div {
      margin-left: 1rem;
      flex: 1;
    }
  }
`;

export const OptionGroup = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
  & > span {
    font-size: 0.85rem;
    color: ${({ theme }) => theme.inputLabel};
    font-weight: bold;
    margin-right: .5rem;
    display: block;
    white-space: nowrap;
  }
`;

export const Option = styled.button`
  background-color: ${({ theme }) => theme.containerOdd};
  color: ${({ theme, colorKey }) => theme[colorKey]};
  padding: 5px;
  border: none;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-weight: bold;
  height: 38px;
  cursor: pointer;
  ${({ theme, active, colorKey }) => (active ? css`
    background-color: ${theme[colorKey]};
    color: ${theme.white};
  ` : css`
    &:hover {
      background-color: ${theme.selectHover};
    }
  `)}
`;
