/* eslint-disable no-param-reassign */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';

import ControllersGroup from '../../../controllers/ControllersGroup';
import {
  SelectController,
  RangeController,
  ChoiceBoxController,
  SimpleColorPickerController,
  ToggleSwitchController,
  GradientColorPickerController,
} from '../../../controllers/Base';

import { getOptionsThemes } from '../../../utils/controllersUtils/themeColors';

const StyleControllers = ({
  handle,
  config,
  data,
  extend,
  setExtend,
  selectedColumn,
  isGlobalThemeActive,
}) => (
  <ControllersGroup
    title="Estilos"
    id="styles"
    extend={extend}
    setExtend={setExtend}
    sizeIncrease={config?.PalletsControl === 'custom' ? data?.index?.length : 0}
  >
    <RangeController
      label="Borda arredondada"
      handle={handle}
      kWord="BorderRadiusControl"
      configValue={config?.BorderRadiusControl?.value}
      min={0}
      max={36}
      fullWidth
    />
    <RangeController
      label="Tamanho da borda"
      handle={handle}
      kWord="BorderWidthControl"
      configValue={config?.BorderWidthControl?.value}
      min={0}
      max={20}
      fullWidth
    />
    <ChoiceBoxController
      label="Cor da borda"
      handle={handle}
      kWord="BorderColorControl2"
      options={[
        { value: 'brighter', label: 'Clara', id: 'brighter' },
        { value: 'darker', label: 'Escura', id: 'darker' },
        { value: 'custom', label: 'Customizada', id: 'custom' },
      ]}
      configValue={
        typeof config?.BorderColorControl === 'string' ? 'custom' : (
          config?.BorderColorControl?.modifiers?.[0]?.[0]
        )
      }
      fullWidth
      disabled={
        !config?.BorderWidthControl?.value
        || config?.BorderWidthControl?.value.toString() === '0'
      }
    />
    {typeof config?.BorderColorControl !== 'string' ? (
      <RangeController
        label="Opacidade da borda"
        handle={handle}
        kWord="BorderOpacityControl"
        configValue={config?.BorderColorControl?.modifiers?.[0]?.[1]}
        min={0}
        max={2}
        step={0.1}
        precision={1}
        fullWidth
        disabled={
          !config?.BorderWidthControl?.value
          || config?.BorderWidthControl?.value.toString() === '0'
        }
      />
    ) : (
      <SimpleColorPickerController
        label="Cor customizada"
        handle={handle}
        kWord="BorderColorControl"
        configValue={config?.BorderColorControl}
        orientation="row"
        fullWidth
        disabled={
          !config?.BorderWidthControl?.value
          || config?.BorderWidthControl?.value.toString() === '0'
        }
      />
    )}
    <RangeController
      label="Tamanho da mediana"
      handle={handle}
      kWord="MedianWidthControl"
      configValue={config?.MedianWidthControl}
      min={0}
      max={20}
      fullWidth
    />
    <ChoiceBoxController
      label="Cor da mediana"
      handle={handle}
      kWord="MedianColorControl2"
      options={[
        { value: 'brighter', label: 'Clara', id: 'brighter' },
        { value: 'darker', label: 'Escura', id: 'darker' },
        { value: 'custom', label: 'Customizada', id: 'custom' },
      ]}
      configValue={
        typeof config?.MedianColorControl === 'string' ? 'custom' : (
          config?.MedianColorControl?.modifiers?.[0]?.[0]
        )
      }
      fullWidth
      disabled={
        !config?.MedianWidthControl
        || config?.MedianWidthControl.toString() === '0'
      }
    />
    {typeof config?.MedianColorControl !== 'string' ? (
      <RangeController
        label="Opacidade da mediana"
        handle={handle}
        kWord="MedianOpacityControl"
        configValue={config?.MedianOpacityControl?.modifiers?.[0]?.[1]}
        min={0}
        max={2}
        step={0.1}
        precision={1}
        fullWidth
        disabled={
          !config?.MedianWidthControl
          || config?.MedianWidthControl.toString() === '0'
        }
      />
    ) : (
      <SimpleColorPickerController
        label="Cor customizada"
        handle={handle}
        kWord="MedianColorControl"
        configValue={config?.MedianColorControl}
        orientation="row"
        fullWidth
        disabled={
          !config?.MedianWidthControl
          || config?.MedianWidthControl.toString() === '0'
        }
      />
    )}
    <RangeController
      label="Tamanho dos bigodes"
      handle={handle}
      kWord="WhiskerWidthControl"
      configValue={config?.WhiskerWidthControl}
      min={0}
      max={20}
      fullWidth
    />
    <RangeController
      label="Largura dos limites"
      handle={handle}
      kWord="WhiskerEndSizeControl"
      configValue={config?.WhiskerEndSizeControl}
      min={0}
      max={1}
      step={0.1}
      precision={1}
      fullWidth
      disabled={
        !config?.WhiskerWidthControl
        || config?.WhiskerWidthControl.toString() === '0'
      }
    />
    <ChoiceBoxController
      label="Cor dos bigodes"
      handle={handle}
      kWord="WhiskerColorControl2"
      options={[
        { value: 'brighter', label: 'Clara', id: 'brighter' },
        { value: 'darker', label: 'Escura', id: 'darker' },
        { value: 'custom', label: 'Customizada', id: 'custom' },
      ]}
      configValue={
        typeof config?.WhiskerColorControl === 'string' ? 'custom' : (
          config?.WhiskerColorControl?.modifiers?.[0]?.[0]
        )
      }
      fullWidth
      disabled={
        !config?.WhiskerWidthControl
        || config?.WhiskerWidthControl.toString() === '0'
      }
    />
    {typeof config?.WhiskerColorControl !== 'string' ? (
      <RangeController
        label="Opacidade dos bigodes"
        handle={handle}
        kWord="WhiskerOpacityControl"
        configValue={config?.WhiskerColorControl?.modifiers?.[0]?.[1]}
        min={0}
        max={2}
        step={0.1}
        precision={1}
        fullWidth
        disabled={
          !config?.WhiskerWidthControl
          || config?.WhiskerWidthControl.toString() === '0'
        }
      />
    ) : (
      <SimpleColorPickerController
        label="Cor customizada"
        handle={handle}
        kWord="WhiskerColorControl"
        configValue={config?.WhiskerColorControl}
        orientation="row"
        fullWidth
        disabled={
          !config?.WhiskerWidthControl
          || config?.WhiskerWidthControl.toString() === '0'
        }
      />
    )}
    <ChoiceBoxController
      label="Paleta de cores"
      handle={handle}
      kWord="PalletsControl"
      options={[
        { label: 'Padrão', value: 'default', id: 'default' },
        { label: 'Degradê', value: 'gradient', id: 'gradient' },
        { label: 'Customizada', value: 'custom', id: 'custom' },
      ]}
      configValue={config?.PalletsControl}
      fullWidth
      text="Utilizando tema global"
      disabled={config?.UseGlobalColor !== false && isGlobalThemeActive}
    />
    {config?.PalletsControl === 'default' && (
      <SelectController
        label="Tema"
        handle={handle}
        kWord="ColorTheme"
        options={getOptionsThemes()}
        configValue={config?.ColorTheme?.scheme}
        fullWidth
        text="Utilizando tema global"
        disabled={config?.UseGlobalColor !== false && isGlobalThemeActive}
      />
    )}
    {config?.PalletsControl === 'gradient' && (
      <GradientColorPickerController
        handle={handle}
        firstColorVal={config?.GradientPickerControl?.color1 || '#4711B2'}
        firstColorKWord="GradientFirstColor"
        secondColorVal={config?.GradientPickerControl?.color2 || '#FF9626'}
        secondColorKWord="GradientSecondColor"
        thirdColorVal={config?.GradientPickerControl?.color3}
        thirdColorKWord="GradientThirdColor"
        fullWidth
        orientation="row"
        text="Utilizando tema global"
        disabled={config?.UseGlobalColor !== false && isGlobalThemeActive}
      />
    )}
    {config?.PalletsControl === 'custom' && (
      selectedColumn ? (
        Object.values(data?.index?.reduce((aux, idx, i) => {
          const key = typeof idx === 'string' ? idx : idx[
            ['keys', 'auto'].includes(config?.ColorByControl) ? 1 : 0
          ];
          aux[key] = (
            <SimpleColorPickerController
              dataColorTarget={key.toString()}
              key={`${key}_i${i}`}
              label={key}
              handle={handle}
              kWord="Colors"
              configValue={config?.Colors[key]}
              orientation="row"
              fullWidth
              text="Utilizando tema global"
              disabled={config?.UseGlobalColor !== false && isGlobalThemeActive}
            />
          );
          return aux;
        }, {}) || {})
      ) : (
        Object.values((
          Array.isArray(data?.columns?.[0]) && config?.ColorByControl === 'keys'
            ? [data.columns[0][0]] : data?.index
        )?.reduce((aux, key, i) => {
          aux[key] = (
            <SimpleColorPickerController
              dataColorTarget={key.toString()}
              key={`${key}_i${i}`}
              label={key}
              handle={handle}
              kWord="Colors"
              configValue={config?.Colors[key]}
              orientation="row"
              fullWidth
              text="Utilizando tema global"
              disabled={config?.UseGlobalColor !== false && isGlobalThemeActive}
            />
          );
          return aux;
        }, {}) || {})
      ))}
    <ToggleSwitchController
      label="Utilizar tema global"
      handle={handle}
      kWord="UseGlobalColor"
      configValue={config?.UseGlobalColor}
      firstOption=""
      secondOption=""
      fullWidth
    />
  </ControllersGroup>
);

StyleControllers.propTypes = {
  config: PropTypes.objectOf(PropTypes.any),
  data: PropTypes.objectOf(PropTypes.any),
  handle: PropTypes.func,
  extend: PropTypes.string,
  setExtend: PropTypes.func,
  selectedColumn: PropTypes.objectOf(PropTypes.any),
  isGlobalThemeActive: PropTypes.bool,
};

StyleControllers.defaultProps = {
  config: undefined,
  data: undefined,
  handle: () => {},
  extend: '',
  setExtend: () => {},
  selectedColumn: undefined,
  isGlobalThemeActive: false,
};

export default StyleControllers;
