import React from 'react';

// import { Container } from './styles';

function Eye2() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="33" height="21" viewBox="0 0 33 21" fill="#4711B2">
      <path d="M16.1816 0C6.16079 0 0 10.5 0 10.5C0 10.5 6.16079 21 16.1816 21C26.2025 21 32.3633 10.5 32.3633 10.5C32.3633 10.5 26.2025 0 16.1816 0ZM16.1816 17.5C10.3813 17.5 5.99309 13.153 3.85417 10.5C5.38407 8.60125 8.06581 5.85025 11.5846 4.4275C10.7565 5.65343 10.3027 7.17736 10.2974 8.75C10.2974 12.6175 12.9306 15.75 16.1816 15.75C19.4327 15.75 22.0659 12.6175 22.0659 8.75C22.0659 7.11025 21.5731 5.62275 20.7787 4.4275C24.2989 5.85025 26.9807 8.603 28.5076 10.5C26.3658 13.1565 21.9791 17.5 16.1802 17.5H16.1816Z" />
    </svg>
  );
}

export default Eye2;
