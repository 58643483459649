import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

import InputTextLine from '../../../juristec-ui/core/InputTextLine';
import Button from '../../../juristec-ui/core/Button/Button';

import { FilterContainer, ActionGroup } from './styled/InputFilter.styled';

const InputFilter = ({
  controlValue, label, placeholder, close, handleFilter,
}) => {
  const [inputValue, setInputValue] = useState(controlValue);

  /** Check if the filter has any changes */
  const isDirty = useRef(false);

  /**
   * Handles the input value
   * @param {InputEvent} e DOM input event object
   */
  const handleInputValue = (e) => {
    isDirty.current = true;
    setInputValue(e.target.value);
  };

  /** Sends the filter values to be filtered */
  const sendFilter = () => {
    const val = inputValue.split(' ').filter((v) => v).join(' ');
    handleFilter(val);
    close();
  };

  return (
    <FilterContainer>
      <InputTextLine
        onChange={handleInputValue}
        value={inputValue}
        placeholder={placeholder}
        label={label}
      />
      <ActionGroup style={{ marginTop: '5px' }}>
        <Button
          size="small"
          variant="outlined"
          onClick={close}
          style={{ width: '100%', minWidth: '100px' }}
        >
          Cancelar
        </Button>
        <Button
          size="small"
          onClick={sendFilter}
          disabled={!isDirty?.current}
          style={{ width: '100%', minWidth: '100px' }}
        >
          Aplicar
        </Button>
      </ActionGroup>
    </FilterContainer>
  );
};

InputFilter.propTypes = {
  controlValue: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  close: PropTypes.func,
  handleFilter: PropTypes.func,
};

InputFilter.defaultProps = {
  controlValue: '',
  label: '',
  placeholder: '',
  close: () => {},
  handleFilter: () => {},
};

export default InputFilter;
