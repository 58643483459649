import React from 'react';

const ChartLine = ({ primary = '#4711B2', secondary = '#FF9626' }) => (
  <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M58.3963 60C44.7217 60 3.6994 60 3.6994 60C1.65959 60 0 58.4208 0 56.4799C0 56.4799 0 15.4725 0 1.60557C0 -0.53519 4.43912 -0.53519 4.43912 1.60557C4.43912 15.2967 4.43912 55.7761 4.43912 55.7761C4.43912 55.7761 44.907 55.7761 58.3962 55.7761C60.5346 55.7761 60.5346 60 58.3963 60Z" fill={primary} />
    <path d="M18 31C18 33.2091 16.2091 35 14 35C11.7909 35 10 33.2091 10 31C10 28.7909 11.7909 27 14 27C16.2091 27 18 28.7909 18 31Z" fill={secondary} />
    <path d="M31 9C31 11.2091 29.2091 13 27 13C24.7909 13 23 11.2091 23 9C23 6.79086 24.7909 5 27 5C29.2091 5 31 6.79086 31 9Z" fill={secondary} />
    <path d="M45 19C45 21.2091 43.2091 23 41 23C38.7909 23 37 21.2091 37 19C37 16.7909 38.7909 15 41 15C43.2091 15 45 16.7909 45 19Z" fill={secondary} />
    <path d="M60 11C60 13.2091 58.2091 15 56 15C53.7909 15 52 13.2091 52 11C52 8.79086 53.7909 7 56 7C58.2091 7 60 8.79086 60 11Z" fill={secondary} />
    <path fillRule="evenodd" clipRule="evenodd" d="M26.2941 7.52142C26.5778 7.46174 26.8734 7.5281 27.1044 7.70331L41.0714 18.299L54.9943 10.1373L56.0057 11.8627L41.5057 20.3627C41.1564 20.5674 40.7182 20.5414 40.3956 20.2967L26.8142 9.99355L14.8742 31.4856L13.1258 30.5144L25.6258 8.01436C25.7666 7.76095 26.0104 7.5811 26.2941 7.52142Z" fill={secondary} />
    <path d="M19 49C19 51.2091 17.2091 53 15 53C12.7909 53 11 51.2091 11 49C11 46.7909 12.7909 45 15 45C17.2091 45 19 46.7909 19 49Z" fill={primary} />
    <path d="M33 38C33 40.2091 31.2091 42 29 42C26.7909 42 25 40.2091 25 38C25 35.7909 26.7909 34 29 34C31.2091 34 33 35.7909 33 38Z" fill={primary} />
    <path d="M45 41C45 43.2091 43.2091 45 41 45C38.7909 45 37 43.2091 37 41C37 38.7909 38.7909 37 41 37C43.2091 37 45 38.7909 45 41Z" fill={primary} />
    <path d="M57 25C57 27.2091 55.2091 29 53 29C50.7909 29 49 27.2091 49 25C49 22.7909 50.7909 21 53 21C55.2091 21 57 22.7909 57 25Z" fill={primary} />
    <path fillRule="evenodd" clipRule="evenodd" d="M54.2971 25.1039L41.7971 41.6039C41.549 41.9313 41.126 42.0737 40.7304 41.963L28.7321 38.6035L15.1347 49.7727L13.8653 48.2273L27.8653 36.7273C28.1176 36.52 28.4552 36.449 28.7696 36.537L40.614 39.8535L52.7029 23.8961L54.2971 25.1039Z" fill={primary} />
  </svg>
);

export default ChartLine;
