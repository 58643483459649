import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import InputTextLine from '../../../../juristec-ui/core/InputTextLine';
import Button from '../../../../juristec-ui/core/Button';

import {
  Close, Finder, Image, UploadImage,
} from '../../../../juristec-ui/icons';

import { verifyKeywords, verifyLink } from '../../../../juristec-ui/utils/validators/inputTextValidators';
import { customString, trimString } from '../../../../juristec-ui/utils/functions/formatString';

import {
  MainContainer,
  InputRow,
  ImageSquare,
  ImageOptions,
  ImageContainer,
} from './styled/SecondStep.styled';

const SecondStep = ({
  tutorialLinkState,
  keywordsState,
  checkImage,
  fileImageState,
  openUnsplashPicker,
}) => {
  const inputFile = useRef(null);
  const [tutorialLink, setTutorialLink] = tutorialLinkState;
  const [keywords, setKeywords] = keywordsState;
  const [fileImage, setFileImage] = fileImageState;
  const [templateImg, setTemplateImg] = useState('');

  useEffect(() => {
    if (fileImage) {
      setTemplateImg(typeof (fileImage) === 'string' ? fileImage : URL.createObjectURL(fileImage));
    } else {
      setTemplateImg('');
    }
  }, [fileImage]);

  const tutorialLinkHandle = (e) => {
    const val = e.target.value?.trim() || '';
    const msg = verifyLink(val);
    setTutorialLink({
      value: val,
      error: msg.length !== 0,
      errorMsg: msg,
    });
  };

  const keywordsHandle = (e) => {
    const val = e.target.value;
    const msg = verifyKeywords(val);
    setKeywords({
      value: val,
      error: msg.length !== 0,
      errorMsg: msg,
    });
  };

  const keywordsBlur = () => {
    setKeywords({
      ...keywords,
      value: customString(trimString(keywords.value), '; ', / *; */),
    });
  };

  const handleDeviceImage = (e) => {
    URL.revokeObjectURL(templateImg);
    const deviceImage = e.target.files[0];
    if (deviceImage && checkImage(deviceImage)) {
      setFileImage(deviceImage);
      setTemplateImg(URL.createObjectURL(deviceImage));
    }
  };

  return (
    <MainContainer>
      <ImageContainer>
        <ImageSquare src={templateImg}>
          {templateImg.length > 0 || <Image /> }
        </ImageSquare>
        <ImageOptions>
          <input
            type="file"
            ref={inputFile}
            accept="image/*"
            onChange={handleDeviceImage}
            style={{ display: 'none' }}
          />
          <Button onClick={() => inputFile.current.click()} textTransform="none">
            Adicione uma imagem
            <UploadImage />
          </Button>
          <span>ou</span>
          <Button onClick={openUnsplashPicker} textTransform="none">
            Busque imagens na web
            <Finder />
          </Button>
          {templateImg.length > 0 && (
            <>
              <span>ou</span>
              <Button
                onClick={() => setFileImage(null)}
                textTransform="none"
                color="error"
                variant="outlined"
              >
                Remova imagem atual
                <Close />
              </Button>
            </>
          )}
        </ImageOptions>
      </ImageContainer>
      <InputRow>
        <InputTextLine
          label="Tutorial"
          placeholder="URL com o tutorial de aplicação. Ex: www.youtube.com/watch?v=dQw4w9WgXcQ"
          name="tutorialLink"
          error={tutorialLink.errorMsg.length > 0}
          errorMessage={tutorialLink.errorMsg}
          value={tutorialLink.value}
          onChange={tutorialLinkHandle}
        />
      </InputRow>
      <InputRow>
        <InputTextLine
          label="Palavras-chave"
          placeholder='Separe cada palavra com ";". Ex: Contencioso; Negócios;...'
          name="keywords"
          error={keywords.errorMsg.length > 0}
          errorMessage={keywords.errorMsg}
          value={keywords.value}
          onChange={keywordsHandle}
          onBlur={keywordsBlur}
        />
      </InputRow>
    </MainContainer>
  );
};

SecondStep.propTypes = {
  tutorialLinkState: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.shape({
      value: PropTypes.string,
      errorMsg: PropTypes.string,
      error: PropTypes.bool,
    }),
    PropTypes.func,
  ])),
  keywordsState: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.shape({
      value: PropTypes.string,
      errorMsg: PropTypes.string,
      error: PropTypes.bool,
    }),
    PropTypes.func,
  ])),
  fileImageState: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(File),
    PropTypes.func,
  ])),
  checkImage: PropTypes.func.isRequired,
  openUnsplashPicker: PropTypes.func.isRequired,
};

SecondStep.defaultProps = {
  tutorialLinkState: [{}, () => {}],
  keywordsState: [{}, () => {}],
  fileImageState: [null, () => {}],
};

export default SecondStep;
