import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';
import IconButton from '../../juristec-ui/core/IconButton';
import {
  CreateDash, CreateKPI, NewFile, Share, Story, Support, TemplateUse, AddUser, Finder,
} from '../../juristec-ui/icons';
import {
  StyledContentTutorial,
  IconWrapper,
  SearchContainer,
  SeparatorLine,
} from './styled/TutorialGuide.styled';

import { AuthContext } from '../../context/AuthProvider';
import { TourContext } from '../../context/TourProvider';
import tourList from '../../context/TourProvider/tourList';
import InputTextLine from '../../juristec-ui/core/InputTextLine';

function TutorialGuide({ support, close }) {
  const [searchText, setSearchText] = useState('');
  const { init } = useContext(TourContext);

  const theme = useTheme();

  function initTour(param) {
    init(param);
    close();
  }

  const { user } = useContext(AuthContext);

  // Último elemento sempre deve ser a central de ajuda.
  // Adicionar novos tours antes do último elemento
  let list = [
    {
      action: () => { initTour(tourList.createDashboard); },
      icon: <CreateDash />,
      title: 'Como criar novo dashboard?',
    },
    {
      action: () => { initTour(tourList.createKpi); },
      icon: <CreateKPI />,
      title: 'Como criar um novo KPI?',
    },
    {
      action: () => { initTour(tourList.uploadFile); },
      icon: <NewFile />,
      title: 'Como subir um novo arquivo?',
    },
    {
      action: () => { initTour(tourList.createTemplate); },
      icon: <TemplateUse />,
      title: 'Como aplicar um template?',
    },
    {
      action: () => { initTour(tourList.shareDashboard); },
      icon: <Share />,
      title: 'Como compartilhar um dashboard?',
    },
    {
      action: () => { initTour(tourList.createUser); },
      icon: <AddUser />,
      title: 'Como criar um usuário?',
    },
    {
      action: () => { initTour(tourList.createStories); },
      icon: <Story />,
      title: 'Como criar um story?',
    },
    // ...process.env.REACT_APP_FIREBASE_PROJECT_LABEL !== 'benner-metrics' ? [
    //   {
    //     action: support,
    //     icon: <Support />,
    //     title: 'Central de Ajuda',
    //   },
    // ] : [],
  ];

  if (user?.role === 'guest') {
    list = [list[list.length - 1]];
  }

  return (
    <StyledContentTutorial>
      <h1>Guia Interativo</h1>
      <SeparatorLine />
      <SearchContainer>
        <IconWrapper>
          <Finder />
        </IconWrapper>
        <InputTextLine
          placeholder="Procurar"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          style={{ color: theme.color }}
        />
      </SearchContainer>
      <ul className="list">
        {list
          .filter((li) => li.title.toLowerCase().includes(searchText.toLowerCase()))
          .map((item) => (
            <li key={item.title} className="item-list" onClick={item.action} onKeyDown={item.action} role="menuitem">
              <IconButton color="secondary" variant="contained" shape="circle">
                {item.icon}
              </IconButton>
              <span>{item.title}</span>
            </li>
          ))}
      </ul>
    </StyledContentTutorial>
  );
}

TutorialGuide.propTypes = {
  support: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
};

export default TutorialGuide;
