class MeasureText {
  constructor() {
    const canvas = document.createElement('canvas');
    this.ctx = canvas.getContext('2d');
  }

  /**
   * Accurately calculates the width of the rendered text
   * @param {number} fontSize Font size (px)
   * @param {string} text Text to be used
   * @returns {number|0} Width of the rendered text
   */
  calc(fontSize, text) {
    this.ctx.font = `${fontSize}px Arial`;
    return this.ctx.measureText(text)?.width || 0;
  }
}

const MeasureTextInstance = new MeasureText();

Object.freeze(MeasureTextInstance);

export default MeasureTextInstance;
