/* eslint-disable no-param-reassign */
import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';

import Button from '../../../juristec-ui/core/Button';
import ActionsGroup from '../../../juristec-ui/core/ActionsGroup';
import InputTextLine from '../../../juristec-ui/core/InputTextLine';
import IconButton from '../../../juristec-ui/core/IconButton';
import Tooltip from '../../../juristec-ui/core/Tooltip';
import TypePicker from './TypePicker';

import { Restore } from '../../../juristec-ui/icons';

import { MainContainer, ColumnsList, InputWrapper } from './styled/ReportColumns.styled';

const ReportColumns = ({
  columns, custom, submitColumns, hide,
}) => {
  const [tempColumns, setTempColumns] = useState({});

  useEffect(() => {
    const customNames = custom.map((cc) => cc.name);
    setTempColumns(
      Object.keys(columns).reduce((aux, column) => {
        if (customNames.includes(column)) return aux;
        aux[column] = {
          newName: column,
          type: columns[column],
          newType: columns[column],
        };
        return aux;
      }, {}),
    );
  }, [columns]);

  const handleTypeChange = (columnName, newType) => {
    setTempColumns((o) => ({
      ...o,
      [columnName]: {
        ...o[columnName],
        newType,
      },
    }));
  };

  const handleNameChange = (e, columnName) => {
    const val = e.target.value;
    setTempColumns((o) => ({
      ...o,
      [columnName]: {
        ...o[columnName],
        newName: val,
      },
    }));
  };

  const handleReset = (columnName) => {
    setTempColumns((o) => ({
      ...o,
      [columnName]: {
        ...o[columnName],
        newName: columnName,
        newType: o[columnName].type,
      },
    }));
  };

  const submit = () => {
    submitColumns(Object.keys(tempColumns).reduce((aux, colName) => {
      const column = tempColumns[colName];
      if (column.type !== column.newType || colName !== column.newName) {
        aux.push({
          src: colName,
          dst: column.newName,
          type: column.newType,
        });
      }
      return aux;
    }, []));
  };

  return (
    <MainContainer>
      <ColumnsList>
        <span className="list-header" />
        <h4 className="list-header">Nome da coluna</h4>
        <h4 className="list-header">Tipo</h4>
        {Object.keys(tempColumns).map((columnName) => {
          const column = tempColumns[columnName];
          const hasChanges = column.type !== column.newType || columnName !== column.newName;
          return (
            <Fragment key={columnName}>
              <InputWrapper className="revert-btn" hasChanges={hasChanges}>
                <Tooltip text="Reverter mudanças" atModal>
                  <IconButton
                    color="secondary"
                    style={{ padding: '2px', margin: 'auto' }}
                    onClick={() => handleReset(columnName)}
                    disabled={!hasChanges}
                  >
                    <Restore />
                  </IconButton>
                </Tooltip>
              </InputWrapper>
              <InputWrapper style={{ padding: '0 0 .5rem 0' }}>
                <InputTextLine
                  tabIndex="-1"
                  placeholder={columnName}
                  value={column.newName}
                  onChange={(e) => handleNameChange(e, columnName)}
                  wrapperStyle={{ pointerEvents: 'none' }}
                />
              </InputWrapper>
              <InputWrapper>
                <TypePicker
                  column={columnName}
                  type={column.newType}
                  handleTypeChange={handleTypeChange}
                />
              </InputWrapper>
            </Fragment>
          );
        })}
      </ColumnsList>
      <ActionsGroup>
        <Button
          style={{ margin: '5px' }}
          onClick={hide}
          variant="outlined"
          size="large"
        >
          Cancelar
        </Button>
        <Button
          style={{ margin: '5px' }}
          size="large"
          onClick={submit}
        >
          Salvar
        </Button>
      </ActionsGroup>
    </MainContainer>
  );
};

ReportColumns.propTypes = {
  columns: PropTypes.objectOf(PropTypes.string),
  custom: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
  })),
  submitColumns: PropTypes.func.isRequired,
  hide: PropTypes.func.isRequired,
};

ReportColumns.defaultProps = {
  columns: {},
  custom: [],
};

export default ReportColumns;
