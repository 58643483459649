import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';
import InputTextLine from '../../../juristec-ui/core/InputTextLine';
import {
  Add, DisplayGrid, DisplayList, Finder,
} from '../../../juristec-ui/icons';
import IconButton from '../../../juristec-ui/core/IconButton';
import { printSize } from '../../../juristec-ui/utils/functions/lab';
import Tooltip from '../../../juristec-ui/core/Tooltip';

import {
  MainContainer,
  ProgressContainer,
  ProgressBar,
  SearchBarContainer,
  IconWrapper,
  ItensContainer,
} from './styled/FilesToolbar.styled';

/**
* A component that has file management options
*/
const FilesToolbar = ({
  sizeUsed,
  sizeLimit,
  textSearch,
  setTextSearch,
  viewLayout,
  toggleViewLayout,
  uploadFile,
  // importSqlTable,
  isMobileSize,
  tourContext = { tourOpen: false },
}) => {
  const theme = useTheme();

  return (
    <MainContainer>
      <ItensContainer className="left">
        <ProgressContainer>
          <ProgressBar position={(sizeUsed * 100) / sizeLimit} />
        </ProgressContainer>
        <span>
          {printSize(sizeUsed).replace('.', ',')}
          {' '}
          /
          {' '}
          {printSize(sizeLimit).replace('.', ',')}
        </span>
      </ItensContainer>
      <ItensContainer className="right">
        <SearchBarContainer>
          <IconWrapper>
            <Finder />
          </IconWrapper>
          <InputTextLine
            placeholder=""
            value={textSearch}
            autoComplete="off"
            onChange={(e) => setTextSearch(e.target.value)}
            style={{ color: theme.color }}
          />
        </SearchBarContainer>
        {!isMobileSize && (
          <>
            <Tooltip text={viewLayout ? 'Grade de arquivos' : 'Lista de arquivos'}>
              <IconButton
                onClick={toggleViewLayout}
                shape="rounded"
                color="primary"
                variant={isMobileSize ? 'contained' : 'pattern'}
              >
                {viewLayout ? <DisplayGrid /> : <DisplayList />}
              </IconButton>
            </Tooltip>
            <Tooltip text="Upload de arquivos e tabelas">
              <IconButton
                onClick={() => { uploadFile(); if (tourContext.tourOpen) tourContext.nextStep(); }}
                shape="rounded"
                color="secondary"
                variant="contained"
                disabled={sizeUsed > sizeLimit}
                className="upload_file_btn"
              >
                <Add />
              </IconButton>
            </Tooltip>
          </>
        )}
      </ItensContainer>
    </MainContainer>
  );
};

FilesToolbar.propTypes = {
  /**
    * The size in Bytes used by the uploaded files
    */
  sizeUsed: PropTypes.number,
  /**
    * The size limit in Bytes for files upload
    */
  sizeLimit: PropTypes.number,
  /**
    * String input of the search bar
    */
  textSearch: PropTypes.string,
  /**
    * Function that deals with the changes on the search bar
    */
  setTextSearch: PropTypes.func.isRequired,
  /**
    * A boolean state that indicates what layout has been used (true for grid, false for list)
    */
  viewLayout: PropTypes.bool,
  /**
    * A function that toggles the layout types
    */
  toggleViewLayout: PropTypes.func.isRequired,
  /**
    * Function to add a new file
    */
  uploadFile: PropTypes.func.isRequired,
  /**
    * Function to add a new file
    */
  // importSqlTable: PropTypes.func.isRequired,
  /**
   * A boolean state that indicates the user device based on screen size
   */
  isMobileSize: PropTypes.bool,
};

FilesToolbar.defaultProps = {
  sizeUsed: 0,
  sizeLimit: 0,
};

export default FilesToolbar;
