import React from 'react';
import PropTypes from 'prop-types';

import IconButton from '../../../juristec-ui/core/IconButton';
import Tooltip from '../../../juristec-ui/core/Tooltip';
import Button from '../../../juristec-ui/core/Button';

import {
  ArrowLeft,
  Brush,
} from '../../../juristec-ui/icons';

import {
  MainContainer,
  ItensContainer,
} from './styled/TemplatePreviewToolbar.styled';

const TemplatePreviewToolbar = ({
  goBack, template, applyTemplate, openCardStyles,
}) => {
  const handleApplyTemplate = () => {
    applyTemplate(template);
  };

  return (
    <MainContainer>
      <ItensContainer className="left">
        <Tooltip text="Voltar" direction="right">
          <IconButton variant="contained" color="secondary" size="medium" shape="rounded" onClick={goBack}>
            <ArrowLeft />
          </IconButton>
        </Tooltip>
        <span>{`Prévia de ${template?.name || 'template'}`}</span>
      </ItensContainer>
      <ItensContainer className="right">
        <Tooltip text="Estilo dos cards">
          <IconButton size="medium" shape="rounded" onClick={openCardStyles}>
            <Brush />
          </IconButton>
        </Tooltip>
        <Button onClick={handleApplyTemplate}>Aplicar este template</Button>
      </ItensContainer>
    </MainContainer>
  );
};

TemplatePreviewToolbar.propTypes = {
  goBack: PropTypes.func.isRequired,
  template: PropTypes.shape({
    name: PropTypes.string,
  }),
  applyTemplate: PropTypes.func.isRequired,
  openCardStyles: PropTypes.func.isRequired,
};

TemplatePreviewToolbar.defaultProps = {
  template: {},
};

export default TemplatePreviewToolbar;
