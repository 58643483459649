import React from 'react';

// import { Container } from './styles';

function Demote() {
    return (
        <svg width="22" height="22" viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M14.8197 11.0548C13.7677 11.8817 12.4412 12.375 11 12.375C7.58398 12.375 4.8125 9.60352 4.8125 6.1875C4.8125 2.77148 7.58398 0 11 0C13.8613 0 16.2703 1.94441 16.9773 4.58333H11.5962C11.4874 4.58316 11.3806 4.61362 11.2875 4.6714C11.1944 4.72919 11.1185 4.81209 11.068 4.9111C11.0175 5.01011 10.9944 5.12145 11.0012 5.23301C11.0079 5.34457 11.0443 5.45209 11.1064 5.54388L14.8197 11.0548ZM14.1324 13.75H16.5C19.5379 13.75 22 16.2121 22 19.25V19.9375C22 21.0762 21.0762 22 19.9375 22H2.0625C0.923828 22 0 21.0762 0 19.9375V19.25C0 16.2121 2.46211 13.75 5.5 13.75H7.86758C8.82578 14.1883 9.88281 14.4375 11 14.4375C12.1172 14.4375 13.1785 14.1883 14.1324 13.75Z" />
            <path d="M13.2458 5.50008H20.671C20.7461 5.50033 20.8198 5.52187 20.8841 5.56238C20.9484 5.6029 21.0008 5.66086 21.0357 5.73002C21.0706 5.79918 21.0867 5.87692 21.0823 5.95489C21.0778 6.03285 21.053 6.10807 21.0105 6.17246L17.2979 11.7435C17.144 11.9745 16.7736 11.9745 16.6193 11.7435L12.9067 6.17246C12.8637 6.10821 12.8385 6.03294 12.8338 5.95485C12.8291 5.87676 12.8451 5.79883 12.8801 5.72952C12.915 5.66021 12.9676 5.60218 13.0321 5.56173C13.0965 5.52128 13.1704 5.49996 13.2458 5.50008Z" />
        </svg>
    );
}

export default Demote;