/* eslint-disable react/forbid-prop-types */
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import Button from '../../../juristec-ui/core/Button';

import { formatDateTime } from '../../../juristec-ui/utils/functions/lab';
import {
  MainContainer, InfoRow, FooterInfo, ErrorContainer, MessageContainer, ManualUpdateContainer,
} from './styled/FileReport.styled';

/** Shows the file update information of automatically updated files */
const FileReport = ({
  file, manualUpdate, owner, adminAccess, inactivateReport,
}) => {
  const fileInputRef = useRef(null);
  /**
   * Formats the 'updated_at' date, with a fallback to 'created_at' if it doesn't exist
   * @returns {string} Formatted date and time
   */

  /** Handles the file upload */
  const fileSelected = () => {
    if (fileInputRef.current.files.length) {
      manualUpdate(fileInputRef.current.files);
    }
  };

  /** Handles which update is by file origin */
  const triggerUpdate = () => {
    if (file.origin === 'FileApi' || file.origin === 'Desktop') {
      fileInputRef.current.click();
    } else {
      manualUpdate();
    }
  };

  const errorKpiTreatment = (error) => {
    if (error.substring(0, 3) === 'KPI') {
      const text = error.split(':');
      const columnError = text[text.length - 1].trim();
      const kpiError = text[0].split('"')[1].split('/');
      return (
        <>
          <div>
            O KPI
            {' '}
            <strong>{kpiError[2].replace('(', ' (')}</strong>
            {', '}
            pertencente ao dashboard
            {' '}
            <strong>{kpiError[1].replace('(', ' (')}</strong>
            {', '}
            do usuário
            {' '}
            <strong>{kpiError[0]}</strong>
            {', '}
            não foi atualizado.
          </div>
          <div>
            <strong className="error">Erro detectado:</strong>
            {' '}
            {columnError}
          </div>
        </>
      );
    }
    return error;
  };

  return (
    <MainContainer>
      <InfoRow>
        <div>
          <b>Relatório: </b>
          {file.filename}
        </div>
      </InfoRow>
      <InfoRow>
        {adminAccess ? (
          <div>
            <b>Criado por: </b>
            {file.createdByEmail.split('@')[0] || 'Desconhecido'}
            {' - '}
            {formatDateTime(file.createdAt || file.created_at, { time: 'half' })}
          </div>
        ) : (
          <div>
            <b>Proprietário: </b>
            {owner?.name || owner?.email.split('@')[0] || 'Desconhecido'}
            {' '}
            <b>Criação: </b>
            {formatDateTime(file.createdAt || file.created_at, { time: 'half' })}
          </div>
        )}
      </InfoRow>
      {file.lastAttemptStatus === 'ERROR' && (
        <ErrorContainer>
          <ul>
            {(typeof (file?.attempt.errors) === 'object'
              ? file?.attempt.errors.map((error) => (
                <li key={error}>{error}</li>
              ))
              : file.attempt.message) || ('Houve um erro na tentativa de atualização do relatório'
            )}
          </ul>
        </ErrorContainer>
      )}
      {file.lastAttemptStatus === 'WARNING' && (
        <>
          <MessageContainer>
            {file?.attempt.message}
          </MessageContainer>
          <ErrorContainer>
            <ul>
              {(typeof (file?.attempt.errors) === 'object'
                ? file?.attempt.errors.map((error) => (
                  <li key={error}>{errorKpiTreatment(error)}</li>
                ))
                : file?.attempt.message) || (
                <li>
                  Houve um erro na tentativa de atualização do relatório
                </li>
              )}
            </ul>
          </ErrorContainer>
        </>
      )}
      <FooterInfo>
        {file.lastAttemptAt && (
          <span>
            <b>Tentativa de atualização: </b>
            {formatDateTime(file.lastAttemptAt, { time: 'half' })}
          </span>
        )}
      </FooterInfo>
      {!!manualUpdate && (
        <ManualUpdateContainer>
          <input
            ref={fileInputRef}
            className="file-input"
            type="file"
            accept=".xlsx, .xls, .csv"
            style={{ display: 'none' }}
            onChange={fileSelected}
          />
          {!!inactivateReport && (
            <Button
              variant="outlined"
              size="medium"
              onClick={inactivateReport}
            >
              Inativar
            </Button>
          )}
          <Button
            variant="outlined"
            size="medium"
            onClick={triggerUpdate}
          >
            Atualizar
          </Button>
        </ManualUpdateContainer>
      )}
    </MainContainer>
  );
};

FileReport.propTypes = {
  /** Object containing file information */
  file: PropTypes.shape({
    origin: PropTypes.string,
    filename: PropTypes.string,
    createdByEmail: PropTypes.string,
    createdAt: PropTypes.string,
    // if is FilesPage, the attribute is created_at instead of createdAt
    created_at: PropTypes.string,
    lastAttemptStatus: PropTypes.string,
    errors: PropTypes.arrayOf(PropTypes.shape({})),
    message: PropTypes.string,
    lastAttemptAt: PropTypes.string,
    attempt: PropTypes.shape({
      errors: PropTypes.arrayOf(PropTypes.string),
      message: PropTypes.string,
    }),
  }).isRequired,
  /** Update the file manually (admin only) */
  manualUpdate: PropTypes.func,
  inactivateReport: PropTypes.func,
  owner: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
  }),
  adminAccess: PropTypes.bool,
};

FileReport.defaultProps = {
  manualUpdate: undefined,
  inactivateReport: undefined,
  owner: undefined,
  adminAccess: true,
};

export default FileReport;
