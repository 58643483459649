import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '../Tooltip';
import {
  Tabs, Tab, Content,
} from './styled/Tabs.styled';

export default function MainTabs({
  head,
  children,
  blockers,
  check,
  active,
  setActive,
}) {
  const handleClick = (e) => {
    const key = e.target.id;
    if (key !== active) {
      setActive(key);
    }
  };

  const blockMsg = (checkList) => {
    let msg = '';
    if (checkList) {
      checkList.forEach((cl) => {
        if (!blockers[cl]) {
          switch (cl) {
            case 'hasValue':
            case 'hasPivotTable':
              msg = ('*Sem gráfico');
              break;
            case 'hasLine':
              msg = ('*Linha obrigatória');
              break;
            default:
              break;
          }
        }
      });
    }
    return msg;
  };

  return (
    <div className="mainTabs">
      <Tabs columns={head} className="tabs">
        {head.map((item) => {
          const errorMsg = blockMsg(check[item.id]);
          if (errorMsg.length > 0) {
            return (
              <Tooltip key={item.id} text={errorMsg} noEvent direction="bottom">
                <Tab
                  id={item.id}
                  disabled
                >
                  {item.text}
                </Tab>
              </Tooltip>
            );
          } return (
            <Tab
              key={item.id}
              onClick={handleClick}
              active={active}
              id={item.id}
            >
              {item.text}
            </Tab>
          );
        })}
      </Tabs>
      {head.map((item, idx) => (
        <Content active={active === item.id} key={item.id} className="tab-content">
          {children[idx]}
        </Content>
      ))}
    </div>
  );
}

MainTabs.propTypes = {
  active: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.node,
    PropTypes.func,
  ]),
  blockers: PropTypes.objectOf(PropTypes.bool),
  check: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
  head: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string,
    id: PropTypes.string,
  })),
  setActive: PropTypes.func.isRequired,
};

MainTabs.defaultProps = {
  head: [],
  children: '',
  check: {},
  blockers: {},
};
