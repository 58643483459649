import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: 80px;
  overflow: hidden;
  position: relative;
`;

export const CarouselContainer = styled.div`
  display: flex;
  gap: 4px;
  overflow-x: auto;
  scrollbar-width: none !important;
  padding: 2px 5px 0 5px;
  &::-webkit-scrollbar {
    height: 0;
  }
  height: 100%;
  ${({ showControls }) => showControls && css`
    padding: 2px 30px 0 30px;
  `}

  & .kpi-carousel-charts-btns {
    background: ${({ theme }) => theme.container};
    box-shadow: 0px 2px 4px ${({ theme }) => theme.shadow};
    height: 70px;
    width: 70px;
    &:hover {
      background: ${({ theme }) => theme.containerHover};
    }
    & svg {
      fill: none;
    }
  }

  & .active {
    box-shadow: 0px 2px 4px ${({ theme }) => theme.shadow}, 0px -3px 0px 0px ${({ theme }) => theme.secondary} inset;
  }

  & .kpi-carousel-charts-btns:disabled {
    box-shadow: none;
  }

  & .disabled {
    pointer-events: none;
  }
`;

export const CarouselButton = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  width: 20px;
  height: 100%;
  cursor: pointer;
  & svg {
    width: 20px;
    height: 20px;
    fill: ${({ theme }) => theme.primary};
  }
  &:hover {
    & svg {
      fill: ${({ theme }) => theme.primaryHover};
    }
  }

  &.left-arrow {
    left: 0;
    background-image: linear-gradient(to right, ${({ theme }) => theme.background} 80%, transparent);
    padding-right: 5px;
    & svg {
      transform: rotate(90deg);
    }
  }
  &.right-arrow {
    right: 0;
    background-image: linear-gradient(to left, ${({ theme }) => theme.background} 80%, transparent);
    padding-left: 5px;
    & svg {
      transform: rotate(270deg);
    }
  }
`;
