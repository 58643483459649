import React from 'react';
import PropTypes from 'prop-types';

import IconButton from '../../../juristec-ui/core/IconButton';
import Tooltip from '../../../juristec-ui/core/Tooltip';

import { MainContainer, ToolbarItem, NavOpt } from './styled/ProcessToolbar.styled';

import { ArrowLeft } from '../../../juristec-ui/icons';

const ProcessToolbar = ({
  cnj, hasBlocks, tabState, goBack,
}) => {
  const [tab, setTab] = tabState;

  return (
    <MainContainer>
      <ToolbarItem className="left">
        <Tooltip text="Voltar" direction="right">
          <IconButton
            variant="contained"
            color="secondary"
            size="medium"
            shape="rounded"
            onClick={goBack}
          >
            <ArrowLeft />
          </IconButton>
        </Tooltip>
        <NavOpt active={tab === 'DATA'} onClick={() => setTab('DATA')}>
          Detalhes
        </NavOpt>
        {hasBlocks && (
          <NavOpt active={tab === 'BLOCK'} onClick={() => setTab('BLOCK')}>
            Ocorrências
          </NavOpt>
        )}
      </ToolbarItem>
      <ToolbarItem className="right">
        <h2>{cnj}</h2>
      </ToolbarItem>
    </MainContainer>
  );
};

ProcessToolbar.propTypes = {
  cnj: PropTypes.string,
  hasBlocks: PropTypes.bool,
  tabState: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
  ])),
  goBack: PropTypes.func.isRequired,
};

ProcessToolbar.defaultProps = {
  cnj: '',
  hasBlocks: false,
  tabState: [],
};

export default ProcessToolbar;
