/* eslint-disable react/forbid-prop-types */
import React, {
  useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';

import ReactMarkdown from 'react-markdown';
import Loading from '../../../juristec-ui/core/MiniLoading';

import { formatDateTime } from '../../../juristec-ui/utils/functions/lab';

import {
  TextContainer,
  LoaderContainer,
  BlockTxtWrapper,
  FooterContainer,
  TimelineContainer,
  TimelineItem,
  TimelineMarker,
  TimelineBtn,
  BlockPagination,
} from '../styled/ProcessInfo.styled';

const ProcessBlocks = ({
  getBlock, blocksTimeline,
}) => {
  const [timelinePoint, setTimelinePoint] = useState('');
  const [pageBlocks, setPageBlocks] = useState(0);

  const handlePageBlocks = (selected, date) => {
    const pg = typeof selected === 'function' ? selected(pageBlocks) : selected;
    setPageBlocks(pg);
    const datePoint = date || timelinePoint;
    if (!blocksTimeline?.map[datePoint]?.[pg] || !blocksTimeline.map[datePoint][pg]?.text) {
      getBlock(blocksTimeline.map[datePoint][pg].bloco_id, datePoint, pg);
    }
  };

  const handleTimelinePoint = (date, pg = 0) => {
    setTimelinePoint(date);
    handlePageBlocks(pg, date);
  };

  useEffect(() => {
    // TODO receber da url o id do primeiro bloco
    if (blocksTimeline.isReady && blocksTimeline.sorted?.length > 0) {
      handleTimelinePoint(
        blocksTimeline.defaultKey || blocksTimeline.sorted[0], blocksTimeline.defaultIdx || 0,
      );
    }
  }, [blocksTimeline.isReady]);

  return (
    <>
      <TimelineContainer>
        {blocksTimeline.sorted.map((dt) => (
          <TimelineItem key={dt}>
            <TimelineMarker>
              <TimelineBtn
                onClick={() => handleTimelinePoint(dt)}
                size="small"
                color={timelinePoint === dt ? 'secondary' : 'primary'}
              >
                {formatDateTime(dt)}
                <em>{`(${blocksTimeline.map[dt]?.length || 0})`}</em>
              </TimelineBtn>
            </TimelineMarker>
          </TimelineItem>
        ))}
      </TimelineContainer>
      <TextContainer>
        {!blocksTimeline?.map?.[timelinePoint]?.[pageBlocks]?.text && (
          <LoaderContainer>
            <Loading fill="primary" />
          </LoaderContainer>
        )}
        <BlockTxtWrapper>
          <ReactMarkdown>
            {blocksTimeline?.map?.[timelinePoint]?.[pageBlocks]?.text}
          </ReactMarkdown>
        </BlockTxtWrapper>
        <FooterContainer>
          <BlockPagination
            page={pageBlocks}
            setPage={handlePageBlocks}
            totalPages={blocksTimeline.map[timelinePoint]?.length - 1}
            useGoToPage
            label="Bloco"
          />
        </FooterContainer>
      </TextContainer>
    </>
  );
};

ProcessBlocks.propTypes = {
  getBlock: PropTypes.func.isRequired,
  blocksTimeline: PropTypes.shape({
    map: PropTypes.objectOf(PropTypes.array),
    sorted: PropTypes.arrayOf(PropTypes.string),
    isReady: PropTypes.bool,
    defaultKey: PropTypes.string,
    defaultIdx: PropTypes.number,
  }),
};

ProcessBlocks.defaultProps = {
  blocksTimeline: {},
};

export default ProcessBlocks;
