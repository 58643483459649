import styled, { css } from 'styled-components';

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  max-width: 600px;
  max-height: calc(100vh - 350px);
  overflow: auto;
  padding: 5px;

  & .reverse-icon {
    padding: 2px;
    & svg {
      transform: ${(props) => (props.flip ? 'rotate(180deg)' : 'rotate(0deg)')};
      transition: transform 0.3s ease-in-out;
      height: 30px;
      width: 30px;
    }
  }
`;

export const ModContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
  padding: 10px;
  background-color: ${({ theme }) => theme.controllersLabel};
  border-radius: 8px;
  max-Width: 250px;
  box-sizing: border-box;
  position: relative;
  & .mod-var-label{
    text-align: center;
    font-size: 12px;
    font-weight: bold;
    color: gray;
    position: absolute;
    top: 2px;
    left: 0;
    right: 0;
  }
`;

export const ModHeader = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    justify-content: space-between;
    margin-top: 10px;
    & .mod-var {
        font-size: 16px;
        font-weight: bold;
        font-family: sans-serif;
    }
`;

export const TabGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-bottom: 10px;
`;

export const TabButton = styled.button`
  background-color: transparent;
  min-width: 100px;
  height: 30px;
  font-weight: bold;
  border: none;
  border-bottom: 4px solid transparent;
  box-sizing: border-box;
  color: ${({ theme }) => theme.grey};
  cursor: pointer;
  ${(props) => (props.active ? css`
    color: ${({ theme }) => theme.secondary};
    border-bottom-color: ${({ theme }) => theme.secondary};
  ` : css`
    &:hover {
      color: ${({ theme }) => theme.secondaryHover};
      border-bottom-color: ${({ theme }) => theme.grey};
    }
  `)}
`;

export const SeparatorLine = styled.hr`
    margin: .7rem auto;
    width: 80%;
    border: 0.8px solid #ddd;
`;
