import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '../../juristec-ui/core/IconButton';
import Button from '../../juristec-ui/core/Button';
import { formatDateTime } from '../../juristec-ui/utils/functions/lab';
import { Close, Chain } from '../../juristec-ui/icons';
import Tooltip from '../../juristec-ui/core/Tooltip';
import SystemMessageBanner from './styled/SystemMessages.styled';

const SystemMessages = ({ sysMessages, closeMessage }) => {
  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };
  return sysMessages?.map((m) => (
    <SystemMessageBanner key={m.id} lvl={m.level}>
      <div className="banner-header">
        <span>{formatDateTime(m.timestamp, { time: 'half' })}</span>
        <h5>{m.title}</h5>
        <IconButton
          onClick={() => closeMessage(m.id)}
          shape="rounded"
          variant="pattern"
          size="small"
          style={{ boxShadow: 'none' }}
        >
          {(!m.showUntil) && (
            <Close />
          )}
        </IconButton>
      </div>
      <div className="banner-body">
        <span>{m.message}</span>
        {m.link && Object.keys(m.link).map((k) => (
          <Tooltip key={m.id + k} direction="top" text={m.link[k]}>
            <Button
              variant="pattern"
              size="small"
              color="info"
              shape="square"
              onClick={() => openInNewTab(m.link[k])}
              style={{ gap: '5px', minWidth: 'auto' }}
            >
              {k}
              <Chain />
            </Button>
          </Tooltip>
        ))}
      </div>
    </SystemMessageBanner>
  ));
};

SystemMessages.propTypes = {
  sysMessages: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    message: PropTypes.string,
    timestamp: PropTypes.shape({}),
    level: PropTypes.string,
    link: PropTypes.shape({
      Link: PropTypes.string,
    }),
    showUntil: PropTypes.shape({}),
  })),
  closeMessage: PropTypes.func.isRequired,
};

SystemMessages.defaultProps = {
  sysMessages: [],
};

export default SystemMessages;
