import styled from 'styled-components';
import bg from '../../../assets/dots-purple.png';
import { devices } from '../../../juristec-ui/core/breakpoints';
import { gap } from '../../../juristec-ui/styles/theme';

const ContainerLoginPage = styled.main`
  background: url(${bg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: 'center';
  align-items: 'center';
`;

const Paper = styled.form`
  min-width: 25vw;
  max-width: 25vw;
  max-height: 100vh;
  box-sizing: border-box;
  overflow: auto;
  background-color: ${({ theme }) => theme.background};
  padding: 30px 30px;
  position: relative;

  @media ${devices.desktopS} {
    min-width: 35vw;
    max-width: 35vw;
  }

  @media ${devices.tablet} {
    min-width: 45vw;
    max-width: 45vw;
  }

  @media ${devices.phoneL} {
    min-width: 100vw;
    max-width: 100vw;
  }
`;

const Logo = styled.img`
  display: block;
  width: 180px;
  margin-bottom: 20px;
`;

const RightSide = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  @media ${devices.phoneL} {
    display: none;
  } ;
`;

const SideImage = styled.img`
  display: block;
  width: 600px;

  @media ${devices.tablet} {
    width: 450px;
  } ;
`;

const TextOptions = styled.span`
  display: block;
  padding-top: 5px;
  font-size: 0.75rem;
  color: ${({ theme }) => theme.color};
  bottom: 10px;
  left: 10px;
  & > a, span {
    cursor: pointer;
    color: ${({ theme }) => theme.primary};
    font-weight: bold;
    text-decoration-line: none;
  }
`;

const FormColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  ${gap('.8rem', 'column')}

  & .gap-fix {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    ${gap('.8rem', 'column')}
  }
`;

const FormRow = styled.div`
  display: flex;
  flex-direction: row;
  ${gap('15px', 'row')}

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
  }

  & .errorMessage {
    position: absolute;
    padding-top: 1px;
  }

  & .inputTxt {
    padding-left: 5px;
    border-radius: 0px;
  }
`;

export {
  ContainerLoginPage, Paper, Logo, TextOptions, SideImage, RightSide, FormColumn, FormRow,
};
