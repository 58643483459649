import React from 'react';
import { NavLink } from 'react-router-dom';
import { NotFound, Restore } from '../../../juristec-ui/icons';
import NotFoundContainer from './styled/PublicNotFound.styled';

function PublicNotFound() {
  return (
    <NotFoundContainer>
      <h1>ERRO 404</h1>
      <span>
        O dashboard que você está tentando acessar não foi encontrado ou está desabilitado.
      </span>
      <NotFound />
      <NavLink
        to="/home"
        className="navlink"
      >
        Voltar para o início
        <Restore />
      </NavLink>
    </NotFoundContainer>
  );
}

export default PublicNotFound;
