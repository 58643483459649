import styled, { css } from 'styled-components';

import { gap } from '../../../styles/theme';
import { devices } from '../../breakpoints';

export const SearchBarContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  ${gap('.5rem', 'row')}
  background-color: ${({ theme }) => theme.backgroundOdd};
  border-radius: 10px;
  box-shadow: 0px 3px 5px ${({ theme }) => theme.shadow};
  padding: 0.1rem 0.6rem;
  & svg {
    height: 20px;
    width: 20px;
    fill: ${({ theme }) => theme.grey};
  }
  & .inputTxt {
    padding-top: 5px;
  }
  @media ${devices.phoneS} {
    width: 100%;
    & div:last-child {
      width: 100%;
    }
    & .inputTxt {
      width: 100%;
    }
  }
`;

export const ImageBox = styled.div`
  ${({ src }) => css`
    background-image: url(${src});
  `};
  user-select: none;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 120px;
  width: 180px;
  border: 1.6px solid ${({ theme }) => theme.dark};
  background-color: ${({ theme }) => theme.grey};
  overflow: hidden;
  border-radius: 1.5rem;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  justify-content: end;
  flex-direction: column;
  ${({ selected, theme }) => (selected ? css`
    box-shadow: 0 0 0px 5px ${theme.success};
    ` : css`
    &:hover {
      box-shadow: 0px 8px 0.8rem -5px ${theme.shadow};
    }
  `)};
  @media ${devices.phoneS} {
    width: 144px;
    height: 96px;
  }

  & .card-footer {
    display: none;
    background-color: #00000055;
    padding: 5px 10px;
    & span{
      font-size: 14px;
      color: white;
      font-style: italic;
    }
  }
  &:hover .card-footer {
    display: flex;
  }
`;

export const ImageGallery = styled.div`
  display: grid;
  margin-top: 1.2rem;
  align-items: center;
  width: 580px;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 1rem;
  justify-items: center;
  

  @media ${devices.phoneS} {
    width: 100%;
    grid-template-columns: 1fr 1fr;
    grid-gap: .5rem;
  }
`;

export const ImageGalleryPagination = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  ${gap('5px', 'row')}
  margin-left: auto;

  .invert svg {
    transform: rotate(180deg);
  }
`;

export const CenterContainer = styled.div`
  width: 580px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2rem 0 1rem;
  @media ${devices.phoneS} {
    width: 100%;
  }
  & span {
    width: 100%;
    text-align: center;
    background-color: ${({ theme }) => theme.lightgrey};
    padding: 10px;
    border-radius: 10px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const ImageGalleryFooter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  ${gap('8px', 'row')}
  @media ${devices.phoneS} {
    flex-direction: column;
  }
  margin: 1rem 0 0;
`;

export const ImageCredits = styled.div`
  font-size: 14px;
  & a {
    color: ${({ theme }) => theme.color};
  }
`;
