/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import Popover from '../../../juristec-ui/core/Popover';
import IconButton from '../../../juristec-ui/core/IconButton';
import Tooltip from '../../../juristec-ui/core/Tooltip';
import InputFilter from '../InputFilter';
import ControlFilter from '../ControlFilter';

import { Filter } from '../../../juristec-ui/icons';

/** Wrapper com o popover, para manter o conteudo nao renderizado ate ser aberto */
const TableFilters = ({
  field,
  isOpen,
  selected,
  toggle,
  options,
  handleFilter,
  popDirection,
  isRadio,
  isText,
  extraToggle,
}) => {
  const handleToggle = () => toggle(field);
  const handleClose = () => toggle(field, false);
  const handleApply = (sel, key) => {
    if (isText || key) {
      handleFilter(key || field, sel);
    } else if (isRadio) {
      handleFilter(field, sel?.[0].length > 0 ? sel : []);
    } else {
      handleFilter(field, sel.length !== options.length ? sel : []);
    }
  };
  const markFilter = () => (
    selected.length > 0 && selected.length !== options.length
  ) || extraToggle?.value;

  return (
    <div onClick={(e) => e.stopPropagation()} key={field}>
      <Popover
        open={isOpen}
        closePopover={handleClose}
        atModal
        offset={[0, 5]}
        onClick={(e) => e.stopPropagation()}
      >
        <Popover.Action>
          <Tooltip text="Filtrar coluna" direction="top">
            <IconButton
              onClick={handleToggle}
              color={markFilter() ? 'primary' : 'grey'}
              shape="rounded"
              style={{ padding: '0' }}
            >
              <Filter />
            </IconButton>
          </Tooltip>
        </Popover.Action>
        <Popover.Content stayOpened offset={[-20, 2]} direction={popDirection}>
          {isText ? (
            <InputFilter
              controlValue={selected}
              close={handleClose}
              handleFilter={handleApply}
              label="*Informar o nome completo"
              placeholder="Nome do arquivo"
            />
          ) : (
            <ControlFilter
              controlOptions={options}
              controlFilterValues={selected}
              close={handleClose}
              handleFilter={handleApply}
              isRadio={isRadio}
              extraToggle={extraToggle}
            />
          )}
        </Popover.Content>
      </Popover>
    </div>
  );
};

TableFilters.propTypes = {
  field: PropTypes.string,
  isOpen: PropTypes.bool,
  selected: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string,
  ]),
  options: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  popDirection: PropTypes.string,
  toggle: PropTypes.func.isRequired,
  handleFilter: PropTypes.func.isRequired,
  isRadio: PropTypes.bool,
  isText: PropTypes.bool,
  extraToggle: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.bool,
    field: PropTypes.string,
  }),
};

TableFilters.defaultProps = {
  field: '',
  isOpen: false,
  selected: [],
  options: [],
  popDirection: 'bottom-end',
  isRadio: false,
  isText: false,
  extraToggle: undefined,
};

export default TableFilters;
