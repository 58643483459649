import React from 'react';

// import { Container } from './styles';

function TransferUser() {
  return (
    <svg width="82" height="87" viewBox="0 0 82 87" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M44.4604 22.6225L61.2178 11.169L44.4604 -2.05097e-06L44.4604 4.91554C44.4604 4.91554 24.9916 9.0894 21 25.8543C29.4994 17.0784 44.4604 17.4226 44.4604 17.4226L44.4604 22.6225Z" />
      <path d="M53.3 57C44.2416 57 36.9 49.6124 36.9 40.5C36.9 31.3876 44.2416 24 53.3 24C62.3584 24 69.7 31.3876 69.7 40.5C69.7 49.6124 62.3584 57 53.3 57ZM46.8041 60.1875H59.7959C72.0613 60.1875 82 70.1906 82 82.527C82 85.002 80.0115 87 77.5592 87H29.0459C26.5859 87 24.6 85.002 24.6 82.527C24.6 70.1906 34.5425 60.1875 46.8041 60.1875ZM20.6153 62.25H30.0773C24.19 67.1098 20.5 74.3801 20.5 82.527C20.5 84.177 20.9869 85.698 21.7812 87H4.1C1.83219 87 0 85.1437 0 82.759C0 71.4797 9.17375 62.25 20.6153 62.25ZM26.65 57C18.7191 57 12.3 50.5418 12.3 42.5625C12.3 34.5832 18.7191 28.125 26.65 28.125C29.8672 28.125 32.8051 29.2278 35.1985 31.0267C33.7225 33.8781 32.8 37.0711 32.8 40.5C32.8 45.0788 34.3285 49.2837 36.8475 52.7087C34.2478 55.35 30.6475 57 26.65 57Z" />
      <path d="M53.3 57C44.2416 57 36.9 49.6124 36.9 40.5C36.9 31.3876 44.2416 24 53.3 24C62.3584 24 69.7 31.3876 69.7 40.5C69.7 49.6124 62.3584 57 53.3 57ZM46.8041 60.1875H59.7959C72.0613 60.1875 82 70.1906 82 82.527C82 85.002 80.0115 87 77.5592 87H29.0459C26.5859 87 24.6 85.002 24.6 82.527C24.6 70.1906 34.5425 60.1875 46.8041 60.1875ZM20.6153 62.25H30.0773C24.19 67.1098 20.5 74.3801 20.5 82.527C20.5 84.177 20.9869 85.698 21.7812 87H4.1C1.83219 87 0 85.1437 0 82.759C0 71.4797 9.17375 62.25 20.6153 62.25ZM26.65 57C18.7191 57 12.3 50.5418 12.3 42.5625C12.3 34.5832 18.7191 28.125 26.65 28.125C29.8672 28.125 32.8051 29.2278 35.1985 31.0267C33.7225 33.8781 32.8 37.0711 32.8 40.5C32.8 45.0788 34.3285 49.2837 36.8475 52.7087C34.2478 55.35 30.6475 57 26.65 57Z" />
    </svg>
  );
}

export default TransferUser;
