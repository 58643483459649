import React from 'react';

// import { Container } from './styles';

function CloudReload({ width = '24', height = '18', fill = '#4711B2' }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 18" fill={fill}>
      <path d="M22.0641 10.6406C22.2 10.1156 22.2844 9.56719 22.2844 9C22.2844 5.44687 19.4062 2.57344 15.8578 2.57344C14.9437 2.57344 14.0813 2.76563 13.2938 3.10781C12.5063 1.27969 10.6875 0 8.57344 0C5.73281 0 3.43125 2.30156 3.43125 5.14219C3.43125 5.33437 3.46875 5.5125 3.4875 5.7C1.42969 6.65156 0 8.72344 0 11.1422C0 14.4562 2.68594 17.1422 6 17.1422H20.5734C22.4672 17.1422 24 15.6094 24 13.7156C24 12.3562 23.2078 11.1984 22.0641 10.6406ZM17.1422 9.42656H12.8578L14.4234 7.86094C13.8047 7.24219 12.9469 6.85781 12 6.85781C10.1062 6.85781 8.57344 8.39062 8.57344 10.2844C8.57344 12.1781 10.1062 13.7109 12 13.7109C13.1672 13.7109 14.1938 13.125 14.8125 12.2391L16.0266 13.4531C15.0844 14.6484 13.6406 15.4312 12.0047 15.4312C9.16406 15.4312 6.8625 13.1297 6.8625 10.2891C6.8625 7.44844 9.16406 5.14688 12.0047 5.14688C13.425 5.14688 14.7094 5.72344 15.6422 6.65156L17.1469 5.14688V9.42656H17.1422Z" fill={fill} />
    </svg>
  );
}

export default CloudReload;
