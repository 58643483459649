import React from 'react';

// import { Container } from './styles';

function SortArrow() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <path d="M0 21l12-18 12 18z" />
    </svg>
  );
}

export default SortArrow;
