/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {
  useState, useRef, useEffect, useLayoutEffect,
} from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';

import Drawer from '../../juristec-ui/core/Drawer';
import TextArea from '../../juristec-ui/core/TextArea';
import Button from '../../juristec-ui/core/Button';
import Avatar from '../../juristec-ui/core/Avatar';
import MiniLoading from '../../juristec-ui/core/MiniLoading';
import Tooltip from '../../juristec-ui/core/Tooltip';
import IconButton from '../../juristec-ui/core/IconButton';
import Popover from '../../juristec-ui/core/Popover';
import InputTextLine from '../../juristec-ui/core/InputTextLine';
import List from '../../juristec-ui/core/List';
import ListItem from '../../juristec-ui/core/ListItem';

import assistantAvatar from '../../assets/temp.png';

import {
  HeaderContainer,
  TimelineContainer,
  InputContainer,
  IconWrapper,
  MsgRow,
  MsgContainer,
  LoadingWrapper,
  InputControlsContainer,
  ListIconWrapper,
  FullTimelineContainer,
  GPTCredits,
} from './styled/VirtualAssistant.styled';

import {
  Send,
  HashTag,
  Letters,
  Number,
  DateIcon,
} from '../../juristec-ui/icons';

import { formatDateTime } from '../../juristec-ui/utils/functions/lab';
import { trimString } from '../../juristec-ui/utils/functions/formatString';
import uuidv4 from '../../juristec-ui/utils/functions/randomUUID';

const icons = {
  category: <Letters />,
  object: <Letters />,
  float64: <Number />,
  int64: <Number />,
  'datetime64[ns]': <DateIcon />,
};

const VirtualAssistant = ({
  messagesState,
  submitQuestion,
  loadMoreLogs,
  columns,
  isOpen,
  close,
}) => {
  const cron = useRef();
  const inputRef = useRef();
  const specialChar = useRef('');
  const timelineRef = useRef();

  const [question, setQuestion] = useState('');
  const [loading, setLoading] = useState(false);
  const [logsLoading, setLogsLoading] = useState(false);
  const [popControl, setPopControl] = useState(false);
  const [inputFilter, setInputFilter] = useState('');
  const [fCols, setFCols] = useState(columns);
  const [messages, setMessages] = messagesState;

  const theme = useTheme();

  useEffect(() => {
    setFCols(columns);
  }, [columns]);

  const closePop = () => {
    setPopControl(false);
    setInputFilter('');
    setFCols(columns);
    specialChar.current = '';
  };

  const scrollToBottom = () => {
    if (timelineRef?.current) {
      timelineRef.current.scrollTop = timelineRef.current.scrollHeight;
    }
  };

  const handleLogsScroll = async (e) => {
    if (e.target.scrollTop <= 0 && timelineRef.current.children.length > 1 && !logsLoading) {
      setLogsLoading(true);
      const beforeMoreScroll = timelineRef.current.scrollHeight;
      await loadMoreLogs();
      timelineRef.current.scrollTop = timelineRef.current.scrollHeight - beforeMoreScroll;
      setLogsLoading(false);
    }
  };

  useLayoutEffect(() => {
    scrollToBottom();
    if (messages) {
      scrollToBottom();
    }
  }, [loading, messages]);

  const controlQuestionVal = (val) => {
    if (val.length <= 200) {
      setQuestion(val);
      return true;
    }
    return false;
  };

  const handleQuestionInput = (e) => {
    const val = e.target.value;
    controlQuestionVal(val);
  };

  const handleSubmit = async () => {
    closePop();
    const tempQuestion = question;
    setQuestion('');
    setMessages((old) => [
      ...old,
      {
        id: uuidv4(),
        type: 'question',
        message: tempQuestion.trim(),
        timestamp: new Date(),
      },
    ]);
    setLoading(true);
    const msg = await submitQuestion(tempQuestion);
    setMessages((old) => [
      ...old,
      msg,
    ]);
    setLoading(false);
  };

  const handleInputFilter = (e) => {
    const val = e.target.value;
    setInputFilter(val);
    clearTimeout(cron.current);

    cron.current = setTimeout(() => {
      setFCols(Object.keys(columns).filter((name) => (
        name.toLowerCase().includes(val.toLowerCase())
      )).reduce((aux, key) => {
        aux[key] = columns[key];
        return aux;
      }, {}));
    }, 200);
  };

  const toggleColMentionPop = (e, open) => {
    e.stopPropagation();
    setPopControl((o) => open || !o);
  };

  const addColToInput = (colName) => {
    inputRef.current.focus();
    const cursor = inputRef.current.selectionStart;
    const inputValue = inputRef.current.value;
    if (controlQuestionVal(
      trimString(`${inputValue.slice(0, cursor - specialChar.current.length)}${colName}${inputValue.slice(cursor)}`),
    )) {
      setTimeout(() => {
        inputRef.current.selectionStart = cursor + colName.length;
        inputRef.current.selectionEnd = cursor + colName.length;
      }, 0);
    }
    specialChar.current = '';
  };

  const handleEnterKey = (e) => {
    switch (e.key) {
      case 'Enter': {
        e.preventDefault();
        e.stopPropagation();
        if (!!question.trim() && !loading) {
          handleSubmit();
        }
        break;
      }
      case '#':
        toggleColMentionPop(e, true);
        specialChar.current = '#';
        break;
      default:
        closePop();
        break;
    }
  };

  return (
    <Drawer
      open={isOpen}
      direction="left"
      handleCloseDrawer={close}
      customWidth="25rem"
    >
      <HeaderContainer>
        <h2>Assistente Virtual (BETA)</h2>
      </HeaderContainer>
      <TimelineContainer ref={timelineRef} onScroll={handleLogsScroll}>
        {(messages === null || logsLoading) && (
          <FullTimelineContainer style={{
            position: 'fixed',
            zIndex: '1',
            backgroundColor: theme.fadedBackground,
            height: 'calc(100vh - 165px)',
          }}
          >
            <MiniLoading fill="primary" />
          </FullTimelineContainer>
        )}
        {messages?.length > 0 ? messages?.map((m) => (
          <MsgRow key={m.id} isUser={m.type === 'question'}>
            {m.type !== 'question' && (
              <Avatar
                style={{ pointerEvents: 'none' }}
                size="large"
                name="Assistente"
                src={assistantAvatar}
              />
            )}
            <MsgContainer msgType={m.type}>
              <p>{m.message}</p>
              <span>{formatDateTime(m.timestamp, { time: 'half' })}</span>
            </MsgContainer>
          </MsgRow>
        )) : (
          <FullTimelineContainer>
            <span>
              Nenhuma conversa foi iniciada ainda
              <br />
              Seja o primeiro a começar
            </span>
          </FullTimelineContainer>
        )}
        {loading && (
          <MsgRow>
            <Avatar
              style={{ pointerEvents: 'none' }}
              size="large"
              name="Assistente"
              src={assistantAvatar}
            />
            <MsgContainer isLoading>
              <LoadingWrapper>
                <MiniLoading fill="primary" size="small" />
              </LoadingWrapper>
            </MsgContainer>
          </MsgRow>
        )}
        {/* <div ref={timelineEndRef} /> */}
      </TimelineContainer>
      <Popover
        open={popControl}
        closePopover={closePop}
        direction="top-start"
        offset={[16, 2]}
        style={{
          backgroundColor: theme.popoverBackground,
        }}
      >
        <Popover.Action>
          <InputContainer>
            <TextArea
              ref={inputRef}
              value={question}
              onChange={handleQuestionInput}
              onKeyDown={handleEnterKey}
              onClick={closePop}
            />
            <InputControlsContainer>
              <Tooltip text="Colunas" atModal>
                <IconButton
                  onClick={toggleColMentionPop}
                  color={popControl ? 'secondary' : 'primary'}
                  style={{ boxShadow: 'none', padding: '5px' }}
                >
                  <HashTag />
                </IconButton>
              </Tooltip>
              <GPTCredits style={{ display: 'block' }}>
                <span>
                  Alimentado pelo
                  {' '}
                </span>
                <a
                  href="https://openai.com/gpt-4"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  GPT-4
                </a>
              </GPTCredits>
              <Button
                onClick={handleSubmit}
                disabled={!question.trim() || loading}
                style={{ boxShadow: 'none', minWidth: 'auto' }}
              >
                Enviar
                <IconWrapper>
                  <Send />
                </IconWrapper>
              </Button>
            </InputControlsContainer>
          </InputContainer>
        </Popover.Action>
        <Popover.Content style={{ backgroundColor: 'white', boxShadow: '0px -1px 4px #9a9a9a' }}>
          <div onClick={(e) => e.stopPropagation()}>
            <div style={{ padding: '0 5px 5px 5px' }}>
              <InputTextLine
                placeholder="Buscar coluna..."
                value={inputFilter}
                onChange={handleInputFilter}
                style={{ fontSize: '14px' }}
              />
            </div>
            <List style={{
              maxHeight: '200px',
              overflow: 'hidden auto',
              scrollbarWidth: 'thin',
              borderRadius: '0 0 8px 8px',
            }}
            >
              {Object.keys(fCols).map((name) => (
                <ListItem
                  key={`mention-${name}`}
                  noOutline
                  style={{ fontSize: '16px', padding: '8px 10px' }}
                  onClick={() => addColToInput(name)}
                >
                  <ListIconWrapper typeForColor={fCols[name]}>
                    {icons[fCols[name]]}
                  </ListIconWrapper>
                  {name}
                </ListItem>
              ))}
            </List>
          </div>
        </Popover.Content>
      </Popover>
    </Drawer>
  );
};

VirtualAssistant.propTypes = {
  messagesState: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape({
      type: PropTypes.string,
      msg: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    })),
    PropTypes.func,
  ])),
  submitQuestion: PropTypes.func.isRequired,
  columns: PropTypes.objectOf(PropTypes.string),
  isOpen: PropTypes.bool,
  close: PropTypes.func.isRequired,
  loadMoreLogs: PropTypes.func.isRequired,
};

VirtualAssistant.defaultProps = {
  messagesState: [null, () => {}],
  columns: {},
  isOpen: false,
};

export default VirtualAssistant;
