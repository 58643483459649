import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';

// components
import DataTable from '../../../juristec-ui/core/DataTable';
import originIcon from '../../../juristec-ui/utils/originMap';
import Avatar from '../../../juristec-ui/core/Avatar';
import Tooltip from '../../../juristec-ui/core/Tooltip';
import TablePagination from '../../../juristec-ui/core/TablePagination';

// styled
import {
  Container,
  Infos,
  FileName,
  DashboardsList,
  DashboardCount,
  EmptyList,
  IconWrapper,
  FileContainer,
  UpdateDateContainer,
  UpdatedByContainer,
  DashboardNameContainer,
} from './styled/FileInfo.styled';

// utils
import { formatDateTime } from '../../../juristec-ui/utils/functions/lab';

function FileInfo({
  currentUser, file, users, close, onClickDash, deviceType,
}) {
  const [page, setPage] = useState(0);

  const dashsNames = Object.keys(file.dashboards || {});
  const dashsArray = [];
  Object.keys(file.dashboards).forEach((key) => dashsArray.push({
    dashId: key,
    ...file.dashboards[key],
  }));
  const theme = useTheme();

  const getOwnerAvatar = (ownerId) => {
    const userOwner = users.filter((user) => user.id === ownerId);
    if (userOwner.length === 1) {
      return (
        <Tooltip text={userOwner[0].name || ''} direction="right">
          <Avatar
            name={userOwner[0].name || ''}
            src={userOwner[0].photoUrl || ''}
            size="medium"
            onClick={(e) => e.stopPropagation()}
            style={{ cursor: 'default' }}
          />
        </Tooltip>
      );
    }
    return null;
  };

  const getUpdateDate = (dashboard) => {
    let userUpdate = '';
    if (dashboard.updatedBy) {
      if (dashboard.updatedBy === 'SYSTEM') {
        userUpdate = 'Sistema';
      } else {
        const filteredUser = users.filter((user) => user.id === dashboard.updatedBy);
        userUpdate = filteredUser.length > 0 ? filteredUser[0].name || '' : '';
      }
    }
    return (
      <UpdateDateContainer>
        {dashboard.updatedAt ? (
          <span>{formatDateTime(dashboard.updatedAt, { time: 'none' })}</span>
        ) : (
          <span>{formatDateTime(dashboard.lastUpdateUser, { time: 'none' })}</span>
        )}
        {dashboard.updatedBy ? (
          <UpdatedByContainer>
            {userUpdate}
          </UpdatedByContainer>
        ) : (
          null
        )}
      </UpdateDateContainer>
    );
  };

  const getDashboardName = (dashboard) => {
    const sendUserToDashboard = () => {
      if (Object.keys(dashboard.sharedWith || {}).includes(currentUser.uid)
      || currentUser.uid === dashboard.owner) {
        onClickDash(dashboard.dashId, dashboard.owner);
        close();
      }
    };

    return (
      <DashboardNameContainer
        isClickable={Object.keys(dashboard.sharedWith || {}).includes(currentUser.uid)
          || currentUser.uid === dashboard.owner}
        onClick={sendUserToDashboard}
      >
        {dashboard.name}
      </DashboardNameContainer>
    );
  };

  const columns = [
    {
      field: 'origin',
      label: '',
      valueGetter: (param) => getOwnerAvatar(param.owner),
      sortable: true,
    },
    {
      field: 'name',
      label: 'Dashboard',
      valueGetter: (param) => getDashboardName(param),
      sortable: true,
    },
    deviceType === 'desktop' ? {
      field: 'created_at',
      label: 'Criado em',
      valueGetter: (param) => formatDateTime(param.created_at, { time: 'none' }),
      sortable: true,
    } : {},
    deviceType === 'desktop' ? {
      field: 'updatedAt',
      label: 'Atualizado em',
      valueGetter: (param) => getUpdateDate(param),
      sortable: true,
    } : {},
  ];

  return (
    <Container>
      <Infos>
        <FileContainer>
          <IconWrapper>
            {originIcon(file.origin)}
          </IconWrapper>
          <FileName>
            {file.filename}
          </FileName>
        </FileContainer>
        <DashboardCount>
          <span>
            Dashboards:
          </span>
          {' '}
          <FileName>
            {dashsNames.length || 0}
          </FileName>
        </DashboardCount>
      </Infos>
      <DashboardsList>
        {dashsNames.length > 0 ? (
          <>
            <DataTable
              columns={columns}
              rowData={dashsArray}
              rowColor={theme.tableBackground}
              strippedRowsColor={theme.containerOdd}
              defaultSortField="name"
              headerColor="transparent"
              theadStyle={{
                position: 'sticky',
                top: 0,
                zIndex: 1,
                backgroundColor: theme.background,
              }}
              usePagination
              page={page}
              itemsPerPage={5}
            />
            <TablePagination
              page={page}
              setPage={setPage}
              totalPages={
                Math.floor(dashsArray.length % 5 === 0
                  ? (dashsArray.length / 5) - 1
                  : dashsArray.length / 5)
              }
            />
          </>
        ) : (
          <EmptyList>
            Nenhum dashboard foi criado utilizando este arquivo
          </EmptyList>
        )}
      </DashboardsList>
    </Container>
  );
}

FileInfo.propTypes = {
  /**
   * Object with the currentUser information.
  * */
  currentUser: PropTypes.shape({
    uid: PropTypes.string,
  }).isRequired,
  /**
   * Object with the file data.
  * */
  file: PropTypes.shape({
    origin: PropTypes.string,
    filename: PropTypes.string,
    dashboards: PropTypes.shape({
      name: PropTypes.string,
      owner: PropTypes.string,
    }),
  }).isRequired,
  /**
   * Object with the list of users from the instance.
  * */
  users: PropTypes.arrayOf(
    PropTypes.shape({}).isRequired,
  ).isRequired,
  /**
   * Function to close the modal.
   */
  close: PropTypes.func,
  /**
   *  Function triggered when user click one of the dashboards name listed.
   */
  onClickDash: PropTypes.func,
  /**
   * The current device type by viewport width
   */
  deviceType: PropTypes.string.isRequired,
};

FileInfo.defaultProps = {
  close: () => {},
  onClickDash: () => {},
};

export default FileInfo;
