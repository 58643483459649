import styled, { css } from 'styled-components';
import { devices } from '../../../juristec-ui/core/breakpoints';

export const InternalDrawer = styled.aside`
  display: flex;
  flex-direction: column;
  transition: min-width 0.2s ease-in-out;
  width: 0rem;
  min-width: 0rem;
  overflow: hidden;
  background-color: ${({ theme }) => theme.background};
  ${({ open }) => open && css`
    min-width: 20rem;
  `}
`;

export const DrawerContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  height: calc(100vh - 116px);
  & h2, h4 {
    margin: 0;
    color: ${({ theme }) => theme.primary};
    text-align: center;
  }
  & h4 {
    padding-bottom: .1rem;
  }
  @media ${devices.tablet} {
    height: calc(100vh - 116px);
  }
  @media ${devices.phoneL} {
    margin-top: .5rem;
    height: calc(100vh - 8px);
  }
`;

export const DrawerHeader = styled.div`
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: center;
  position: relative;
  pointer-events: none;
  & > div {
    position: absolute;
    right: 2px;
    padding: 2px;
    top: 50%;
    transform: translate(0, -50%);
    @media ${devices.phoneL} {
      flex-direction: row;
      left: 2px;
      right: unset;
    }
  }
`;

export const InputWrapper = styled.div`
  padding: .2rem .4rem;
`;

export const AppliedList = styled.div`
  padding: 0 .4rem;
  overflow: hidden auto;
`;
