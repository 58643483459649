import React from 'react';
import PropTypes from 'prop-types';

const Question = ({ width, height }) => (
  <svg width={width} height={height} viewBox="0 0 20 30" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.28571 8.57143C4.28571 6.20759 6.20759 4.28571 8.57143 4.28571H10.7143C13.0781 4.28571 15 6.20759 15 8.57143V8.8125C15 10.2723 14.2567 11.6317 13.0312 12.4152L10.2054 14.2299C8.51786 15.3147 7.5 17.183 7.5 19.1853V19.2857C7.5 20.471 8.45759 21.4286 9.64286 21.4286C10.8281 21.4286 11.7857 20.471 11.7857 19.2857V19.192C11.7857 18.6429 12.067 18.1339 12.5223 17.8393L15.3482 16.0246C17.7991 14.4442 19.2857 11.7321 19.2857 8.8125V8.57143C19.2857 3.83705 15.4487 0 10.7143 0H8.57143C3.83705 0 0 3.83705 0 8.57143C0 9.7567 0.957589 10.7143 2.14286 10.7143C3.32812 10.7143 4.28571 9.7567 4.28571 8.57143ZM9.64286 30C10.3533 30 11.0346 29.7178 11.5369 29.2155C12.0392 28.7131 12.3214 28.0318 12.3214 27.3214C12.3214 26.611 12.0392 25.9297 11.5369 25.4274C11.0346 24.9251 10.3533 24.6429 9.64286 24.6429C8.93246 24.6429 8.25115 24.9251 7.74882 25.4274C7.24649 25.9297 6.96429 26.611 6.96429 27.3214C6.96429 28.0318 7.24649 28.7131 7.74882 29.2155C8.25115 29.7178 8.93246 30 9.64286 30Z" />
  </svg>
);

Question.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

Question.defaultProps = {
  width: '20px',
  height: '20px',
};

export default Question;
