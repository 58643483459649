import React, { forwardRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';
import EmojiPicker from '../EmojiPicker';
import { EmojiOutlined, EmojiSolid } from '../../icons';
import IconButton from '../IconButton';
import Popover from '../Popover';

import StyledInputTextLine, { EmojiPickerContainer, WrapperContainer } from './styled/InputTextLine.styled';

// import { Container } from './styles';
/**
* It is an input field for inserting short text and numbers. It has multiple functions and supports multiple character types/formats.
 */
const InputTextLine = forwardRef(({
  label,
  error,
  errorMessage,
  styleContainer,
  notTransparent,
  disabled,
  value,
  setValue,
  onChange,
  emojiPicker,
  emojiPickerPosition,
  autocomplete,
  wrapperStyle,
  ...props
}, ref) => {
  const [cursorPosition, setCursorPosition] = useState();
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);

  const theme = useTheme();

  const pickEmoji = (e, { emoji }) => {
    const inputRef = ref.current;
    inputRef.focus();
    const start = value.substring(0, inputRef.selectionStart);
    const end = value.substring(inputRef.selectionStart);
    const text = start + emoji + end;
    setValue(text);
    setCursorPosition(start.length + emoji.length);
  };

  const toggleEmojiPicker = (prev) => {
    setShowEmojiPicker(!prev);
  };

  useEffect(() => {
    if (ref?.current && ref.current?.type !== 'email') {
      ref.current.selectionEnd = cursorPosition;
    }
  }, [cursorPosition]);

  return (
    <WrapperContainer
      className="input-text-line-wrapper"
      style={{
        ...wrapperStyle,
      }}
    >
      <StyledInputTextLine label={label} error={error} style={styleContainer} notTransparent={notTransparent} disabled={disabled}>
        <input ref={ref} className="inputTxt" {...props} value={value} onChange={onChange} autoComplete={autocomplete} id={`inputTextLine${label}`} disabled={disabled} />
        <label className="labelTxt" htmlFor={`inputTextLine${label}`}>{label}</label>
        <span className="highlight" />
        <span className="bar" />
        {error && <span className="errorMessage">{errorMessage}</span>}
      </StyledInputTextLine>
      {emojiPicker && ref && (
        <Popover
          open={showEmojiPicker}
          closePopover={() => setShowEmojiPicker(false)}
          style={{ zIndex: '1050', backgroundColor: theme.popoverBackground }}
        >
          <Popover.Action>
            <div>
              {showEmojiPicker ? (
                <IconButton color="warning" variant="pattern" shape="rounded" disabled={disabled} onClick={() => toggleEmojiPicker(showEmojiPicker)}>
                  <EmojiSolid />
                </IconButton>
              ) : (
                <IconButton color="primary" variant="pattern" shape="rounded" disabled={disabled} onClick={() => toggleEmojiPicker(showEmojiPicker)}>
                  <EmojiOutlined />
                </IconButton>
              )}
            </div>
          </Popover.Action>
          <Popover.Content direction={emojiPickerPosition} stayOpened>
            <EmojiPickerContainer>
              <EmojiPicker onEmojiClick={pickEmoji} />
            </EmojiPickerContainer>
          </Popover.Content>
        </Popover>
      )}
    </WrapperContainer>
  );
});

InputTextLine.propTypes = {
  /**
   * A boolean variable to determine if the input is disabled or not
   */
  disabled: PropTypes.bool,
  /**
   * A boolean variable to determine if there is an error with the inputed value
   */
  error: PropTypes.bool,
  /**
   * The error message to be shown in case of an error
   */
  errorMessage: PropTypes.string,
  /**
   * The label of the input
   */
  label: PropTypes.string,
  /**
   * A boolean variable to show if the background of the input must be transparent
   */
  notTransparent: PropTypes.bool,
  /**
   * A boolean variable to show if this input value is required or not
   */
  required: PropTypes.bool,
  /**
   * A boolean variable to determine wheter or not to include an emoji picker to the input component.
   */
  emojiPicker: PropTypes.bool,
  autocomplete: PropTypes.string,
  /**
   * The position of the emoji picker. It can be positioned on top or on bottom of the button.
   */
  emojiPickerPosition: PropTypes.oneOf(['top-left', 'top-right', 'top-end', 'bottom-right', 'bottom-left', 'bottom-end']),

  styleContainer: PropTypes.shape({}),
};

InputTextLine.defaultProps = {
  label: '',
  required: false,
  error: false,
  errorMessage: '',
  styleContainer: {},
  notTransparent: false,
  disabled: false,
  emojiPicker: false,
  autocomplete: 'off',
  emojiPickerPosition: 'bottom-end',
};

export default InputTextLine;
