/* eslint-disable class-methods-use-this */
/* eslint-disable no-console */
/* eslint-disable no-alert */
import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent } from 'firebase/analytics';
import {
  confirmPasswordReset,
  createUserWithEmailAndPassword,
  EmailAuthProvider,
  getAuth,
  getMultiFactorResolver,
  multiFactor,
  onAuthStateChanged,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  reauthenticateWithCredential,
  RecaptchaVerifier,
  signInWithCustomToken,
  signInWithEmailAndPassword,
  signOut,
  updatePassword,
  updateProfile,
  verifyPasswordResetCode,
} from 'firebase/auth';
import {
  getFirestore, Timestamp, setDoc, doc, getDoc, serverTimestamp, increment, deleteField, onSnapshot,
} from 'firebase/firestore';
import {
  getDownloadURL, getMetadata, getStorage, ref, updateMetadata, uploadBytes,
} from 'firebase/storage';
import {
  getToken, initializeAppCheck, ReCaptchaEnterpriseProvider, // ReCaptchaV3Provider,
} from 'firebase/app-check';

import secureFileName from '../secureFileNames';

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

// Para desativar o envio de eventos caso seja dev
window[`ga-disable-${process.env.REACT_APP_FIREBASE_MEASUREMENT_ID}`] = process.env.NODE_ENV !== 'production';

class Firebase {
  constructor() {
    this.app = initializeApp(config);
    window.FIREBASE_APPCHECK_DEBUG_TOKEN = process.env.REACT_APP_APPCHECK_DEBUG_TOKEN || false;
    this.appCheck = initializeAppCheck(this.app, {
      provider: new ReCaptchaEnterpriseProvider(process.env.REACT_APP_RECAPTCHA_SITE_KEY),
      isTokenAutoRefreshEnabled: true,
    });
    this.analytics = getAnalytics(this.app);
    this.auth = getAuth(this.app);
    this.db = getFirestore(this.app);
    this.store = getStorage(this.app);
    this.bucket = ref(
      this.store,
      `gs://${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}`,
    );
    this.serverTimestamp = serverTimestamp;
    this.increment = increment;
    this.deleteField = deleteField;
    this.timestamp = new Timestamp();
    this.factorId = PhoneMultiFactorGenerator.FACTOR_ID;
  }

  /**
   * Google analytics logs.
   * @param {string} eventName
   * @param {object} value
   */
  logEvent(eventName, value) {
    if (this.analytics) logEvent(this.analytics, eventName, value);
  }

  /**
   * Firebase Auth traditional email+password login.
   * @param {string} email User access email.
   * @param {string} password User access password.
   * @returns User credentials.
   */
  login(email, password) {
    return signInWithEmailAndPassword(this.auth, email, password);
  }

  /**
   * Firebase Auth login using a backend generated access token.
   * @param {string} customToken The custom token to sign in with.
   * @returns User credentials.
   */
  tokenLogin(customToken) {
    return signInWithCustomToken(this.auth, customToken);
  }

  authStateObserver(handler) {
    return onAuthStateChanged(this.auth, handler);
  }

  /** Signs out the current user. */
  logout() {
    return signOut(this.auth);
  }

  async register(name, email, password) {
    await createUserWithEmailAndPassword(this.auth, email, password);
    return updateProfile(this.auth.currentUser, {
      displayName: name,
    });
  }

  async reAuthentication(email, password) {
    return reauthenticateWithCredential(
      this.auth.currentUser,
      EmailAuthProvider.credential(email, password),
    );
  }

  async updatePassword(password) {
    return updatePassword(this.auth.currentUser, password);
  }

  async updateProfile(params={}) {
    return updateProfile(this.auth.currentUser, params);
  }

  async addInfo(data) {
    if (!this.auth.currentUser) {
      return alert('Not authorized');
    }
    console.log(`onregister/${this.auth.currentUser.uid}`);
    return setDoc(doc(this.db, `onregister/${this.auth.currentUser.uid}`), data);
  }

  async getCurrentUserQuote() {
    const quote = await getDoc(doc(this.db, `users/${this.auth.currentUser.uid}`));
    return quote.get('quote');
  }

  /**
   * Upload an image file to Storage.
   * @param {string} path Storage location path.
   * @param {File} file File Object.
   * @return {string} Donwload Url.
   */
  async uploadImage(path, file) {
    let isExists = '';
    try {
      isExists = await getDownloadURL(ref(this.bucket, `${path}/${file.name}`));
    } catch (error) {
      if (error.code !== 'storage/object-not-found') console.log('[firebase.uploadImage] ', error);
    } finally {
      if (!isExists) {
        const uploadTask = await uploadBytes(ref(this.bucket, `${path}/${file.name}`), file);
        isExists = await getDownloadURL(uploadTask.ref);
      }
    }
    return isExists;
  }

  /**
   * Upload an image file to Storage. Overwrites files with the same name.
   * @param {string} path Storage location path.
   * @param {File} file File Object.
   * @param {string} fileName Custom File name.
   * @return {string} Donwload Url.
   */
  async uploadImageOverwrite(path, file, fileName) {
    try {
      const uploadTask = await uploadBytes(ref(this.bucket, `${path}/${fileName || file.name}`), file);
      return await getDownloadURL(uploadTask.ref);
    } catch (error) {
      if (error.code !== 'storage/object-not-found') console.log('[firebase.uploadImage] ', error);
      return '';
    }
  }

  async getFileMetadataByPath(path) {
    try {
      return await getMetadata(ref(this.bucket, path));
    } catch (error) {
      console.log('[firebase.getFileMetadataByPath]: ', error);
      return null;
    }
  }

  async verifyFileExist(path) {
    try {
      return !!(await getDownloadURL(ref(this.bucket, path)));
    } catch (error) {
      console.log('[firebase.verifyFileExist]: ', error);
      return false;
    }
  }

  async updateCustomFileMetadataUsingPath(path, metadata) {
    try {
      // const ref = this.store.refFromURL(fileUrl.split('?')[0]);
      // console.log(metadata);
      // retorna os novos metadados.

      return await updateMetadata(ref(this.bucket, path), { customMetadata: metadata });
    } catch (error) {
      console.log('[firebase.updateImageMetadata]: ', error);
      // console.log(ref);
      // return ref.updateMetadata(metadata); // retorna os novos metadados.
      return false;
    }
  }

  /**
   *
   * @param {String} path ex: users/<userId>/dashboard_image
   * @param {String} fileUrl ex:  'https://firebasestorage.googleapis.com/b/bucket/o/images%20stars.jpg'
   * @param {Object} metadata ex: { "key": "value" }
   */
  async updateCustomFileMetadataUsingUrl(fileUrl, metadata) {
    try {
      const path = fileUrl.split('?')[0];
      // const ref = this.bucket.child(`${path}/${fileName}`);
      // console.log(metadata);
      // retorna os novos metadados.
      return await updateMetadata(ref(this.store, path), { customMetadata: metadata });
    } catch (error) {
      console.log('[firebase.updateImageMetadata]: ', error);
      // console.log(ref);
      // return ref.updateMetadata(metadata); // retorna os novos metadados.
      return false;
    }
  }

  async verifyActionCodeAndReturnEmail(actionCode) {
    try {
      const email = await verifyPasswordResetCode(this.auth, actionCode);
      return { error: false, email, code: null };
    } catch (error) {
      return {
        error: true, email: null, code: error.code, fullError: error,
      };
    }
  }

  async resetPasswordWithActionCode(actionCode, newPassword) {
    try {
      await confirmPasswordReset(this.auth, actionCode, newPassword);
      return { error: false };
    } catch (error) {
      return { error: true, code: error.code, fullError: error };
    }
  }

  async verifyFilesWithSameName(path, filesNames) {
    const promises = [];
    filesNames.forEach((fName) => {
      promises.push(this.getFileMetadataByPath(`${path}/${secureFileName(fName)}`));
    });
    const results = await Promise.all(promises);
    const uniques = [];
    const sameNames = results.reduce((acc, cur, i) => {
      if (!cur) uniques.push(filesNames[i]);
      else acc.push(filesNames[i]);

      return acc;
    }, []);
    return { uniques, sameNames };
  }

  /**
   * Gets an App Check verification token.
   * @returns Token.
   */
  async getAppCheckToken() {
    let appCheckTokenResponse;
    try {
      appCheckTokenResponse = await getToken(this.appCheck, false);
    } catch (err) {
      console.log(err);
    }
    return appCheckTokenResponse.token || '';
  }

  getMfaUser() {
    return multiFactor(this.auth.currentUser);
  }

  getMfaResolver(error) {
    return getMultiFactorResolver(this.auth, error);
  }

  getRecaptchaV2(tagId) {
    return new RecaptchaVerifier(tagId, {
      size: 'invisible',
      callback: () => {
        // console.log(res);
      },
      'expired-callback': (res) => {
        console.log(res);
      },
      'error-callback': (res) => {
        console.log(res);
      },
    }, this.auth);
  }

  async sendPhoneCode(phoneInfoOptions, recaptchaVerifier) {
    const provider = new PhoneAuthProvider(this.auth);
    // Send SMS verification code
    return provider.verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier);
  }

  async verifyPhoneCode(verificationId, verificationCode) {
    const cred = PhoneAuthProvider.credential(verificationId, verificationCode);
    return PhoneMultiFactorGenerator.assertion(cred);
  }

  snapShotListener(doc, handler) {
    return onSnapshot(doc.ref, handler, (error) => {
      console.log(error);
    });
  }
}

/* Singleton */
const FirebaseInstance = new Firebase();

Object.freeze(FirebaseInstance);

export default FirebaseInstance;
