export default {
  // TITLE
  TitleControl: '',
  ShowTitleControl: { checked: true },
  StyleTitleControl: {
    fontSize: 20,
    color: '#000000',
    fontFamily: 'arial',
    bold: false,
    italic: false,
    underline: false,
  },
  // GENERAL
  SortValuesControl: 'Alfabética A-Z',
  CustomSortControl: {
    checked: false, kpiCategories: [],
  },
  ReverseControl: { checked: false },
  LayoutControl: 'vertical',
  ValueScaleControl: 'linear',
  GroupModeControl: 'grouped',
  PaddingControl: { value: 0.1 },
  InnerPaddingControl: { value: 0 },
  Margin: {
    top: 50,
    right: 145,
    bottom: 55,
    left: 70,
  },
  MinValueControl: { value: 0, checked: true, pai: true },
  MaxValueControl: { value: 0, checked: true, pai: true },
  DataFormat: { type: 'absolute', target: 'global' },
  // STYLE
  BorderRadiusControl: { value: 5 },
  BorderWidthControl: { value: 0 },
  BorderColorControl: { from: 'color', modifiers: [['darker', 1.6]] },
  PalletsControl: 'default',
  ColorTheme: { scheme: 'nivo' },
  GradientPickerControl: {
    color1: '#4711B2',
    color2: '#FF9626',
    color3: null,
  },
  Colors: {},
  UseGlobalColor: true,
  // LABEL
  EnableLabelControl: { checked: true },
  LabelFormatControl: 'Superior',
  LabelTextColorControl: {
    from: 'color',
    modifiers: [['darker', 1.6]],
  },
  LabelsFontFamilyControl: { value: 'sans-serif' },
  LabelsFontSizeControl: { value: 12 },
  LabelRotationControl: 0,
  LabelTranslateX: 0,
  LabelTranslateY: 0,
  PrefixControl: '',
  SufixControl: '',
  Decimals: 'auto',
  separadorNumericoCasas: '0',
  LabelCurrencyControl: 'Nenhum',
  LabelGroupingControl: '',
  // AXIS
  EnableGridX: false,
  EnableGridY: true,
  EnableAxisX: {
    axisTop: false,
    axisBot: true,
    maxLabelLines: 1,
    tickSize: 5,
    tickPadding: 5,
    tickRotation: 0,
    legend: '',
    legendOffset: 35,
    Decimals: 'auto',
    separadorNumericoCasasX: 0,
  },
  EnableAxisY: {
    axisLeft: true,
    axisRight: false,
    tickSize: 5,
    tickPadding: 5,
    tickRotation: 0,
    legend: '',
    legendOffset: -55,
    Decimals: 'auto',
    separadorNumericoCasasY: 0,
  },
  CurrencyControl: 'Nenhum',
  GroupingSelectControl: '',
  AxisGlobal: {
    fontFamily: 'arial',
    fontSize: 12,
    legendFontSize: 12,
    color: '#333333',
  },
  // LEGEND
  LegendControl: { checked: true },
  ColorByControl: 'auto',
  LegendPlacementControl: {
    value: {
      label: 'Direita',
      translateX: 110,
      translateY: 0,
      selectedPosition: 'right',
      direction: 'column',
      anchor: 'right',
    },
  },
  LegendsFontFamilyControl: { value: 'sans-serif' },
  LegendsFontSizeControl: { value: 12 },
  LegendsSpacingControl: { value: 10 },
  LegendSymbolControl: 'circle',
  // TOOLTIP / INTERACTIVE
  InteractiveControl: true,
  InteractivePrefixControl: '',
  InteractiveSufixControl: '',
  InteractiveDecimals: 'auto',
  InteractiveSeparadorNumericoCasas: 0,
  InteractiveCurrencyControl: 'Nenhum',
  InteractiveGroupingControl: 'Nenhum',
  Animate: false,
  MotionConfig: 'default',
  // MARKER
  ShowMarkerControl: false,
  MarkersList: [],
};
