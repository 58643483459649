import styled, { css } from 'styled-components';

export const DatePickerContainer = styled.div`
  width: 100%;
  ${({ theme }) => css`
    & .datepicker-style {
      display: flex;
    }
    & .container-style, .container-style-at-modal {
      z-index: 100;
      & .react-datepicker {
        background-color: ${theme.popoverBackground};
        border-color: ${theme.grey};
      }
      & .react-datepicker__triangle {
        &:before {
          border-top-color: ${theme.grey};
        }
        &:after {
          border-top-color: ${theme.popoverBackground};
        }
      }
      & .react-datepicker__header {
        background-color: ${theme.container};
        border-color: ${theme.grey};
        color: ${theme.color};
      }
      & .react-datepicker__current-month, .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__year {
        color: ${theme.color};
      }
      & .react-datepicker__day:hover, .react-datepicker__year-text:hover {
        background-color: ${theme.containerHover};
      }
      & .react-datepicker__day--selected, .react-datepicker__year-text--selected {
        background-color: ${theme.primary};
        color: ${theme.white};
        &:hover {
          background-color: ${theme.primaryHover};
        }
      }
      & [class*="--disabled"] {
        color: ${theme.disabled};
        pointer-events: none;
      }
      & .react-datepicker__month {
        min-height: 195px;
      }
    }
    & .container-style-at-modal {
      z-index: 1050;
    }
    & .react-datepicker__close-icon {
      top: 7px;
      right: 28px;
      &:after {
        background-color: transparent;
        color: ${theme.grey};
        font-size: 20px;
        line-height: 0;
      }
      &:hover:after {
          background-color: ${theme.grey}30;
        }
    }
  `}
`;

export const DatePickerWrapper = styled.div`
  ${({ theme }) => css`
    & .datepicker-style {
      display: flex;
    }
    & .container-style, .container-style-at-modal {
      z-index: 100;
      & .react-datepicker {
        background-color: ${theme.popoverBackground};
        border-color: ${theme.grey};
      }
      & .react-datepicker__triangle {
        &:before {
          border-top-color: ${theme.grey};
        }
        &:after {
          border-top-color: ${theme.popoverBackground};
        }
      }
      & .react-datepicker__header {
        background-color: ${theme.container};
        border-color: ${theme.grey};
        color: ${theme.color};
      }
      & .react-datepicker__current-month, .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__year {
        color: ${theme.color};
      }
      & .react-datepicker__day:hover, .react-datepicker__year-text:hover {
        background-color: ${theme.containerHover};
      }
      & .react-datepicker__day--selected, .react-datepicker__year-text--selected {
        background-color: ${theme.primary};
        color: ${theme.white};
        &:hover {
          background-color: ${theme.primaryHover};
        }
      }
      & [class*="--disabled"] {
        color: ${theme.disabled};
        pointer-events: none;
      }
      & .react-datepicker__month {
        min-height: 195px;
      }
    }
    & .container-style-at-modal {
      z-index: 1050;
    }
    & .react-datepicker__close-icon {
      top: 7px;
      right: 28px;
      &:after {
        background-color: transparent;
        color: ${theme.grey};
        font-size: 20px;
        line-height: 0;
      }
      &:hover:after {
          background-color: ${theme.grey}30;
        }
    }
  `}
`;

export const IconWrapper = styled.div`
  position: absolute;
  bottom: 4px;
  right: 2px;
  box-shadow: none;
  padding: 2px;
  & svg {
    fill: ${({ theme }) => theme.grey};
  }
`;
