import React, { useState } from 'react';
import PropTypes from 'prop-types';

import IconButton from '../../../juristec-ui/core/IconButton';
import Tooltip from '../../../juristec-ui/core/Tooltip';

import {
  InfoContainer,
  InfoLine,
  InfoGrid,
  InfoTitle,
  ActivityContainer,
  ActivityWrapper,
  IconWrapper,
  PartsContainer,
  PartsItem,
  PartRow,
  RepRow,
  PartEmpty,
} from '../styled/ProcessInfo.styled';

import { ExpandMore, PartInfo } from '../../../juristec-ui/icons';

import { formatDateTime } from '../../../juristec-ui/utils/functions/lab';
import uuidv4 from '../../../juristec-ui/utils/functions/randomUUID';

const labelSequence = [
  'data_distribuicao',
  'valor_causa',
  'segredo_justica',
  'justica_gratuita',
  'classe_sigla',
  'classe_primeiro_grau',
  'classe_segundo_grau',
  'tribunal',
  'municipio',
  'estado',
];

const partSequence = [
  'polo_ativo',
  'polo_passivo',
  'polo_outros',
];

const labelMap = {
  data_distribuicao: 'Data de distribuição',
  valor_causa: 'Valor da causa',
  segredo_justica: 'Segredo de justiça',
  justica_gratuita: 'Justiça gratuita',
  classe_sigla: 'Sigla da classe',
  classe_primeiro_grau: 'Classe 1° grau',
  classe_segundo_grau: 'Classe 2° grau',
  tribunal: 'Tribunal',
  municipio: 'Município',
  estado: 'Estado',
  polo_ativo: 'Polo Ativo',
  polo_passivo: 'Polo Passivo',
  polo_outros: 'Outras Interessados',
  get(key) {
    return this[key] || key;
  },
};

const formatValue = (key, data) => {
  switch (key) {
    case 'data_distribuicao':
      return data[key] && formatDateTime(data[key]);
    case 'tribunal':
    case 'estado':
      return data[key]?.toUpperCase();
    case 'segredo_justica':
    case 'justica_gratuita':
      return data[key] ? 'Sim' : 'Não';
    case 'valor_causa':
      return (data[key])?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    default:
      return data[key];
  }
};

function capitalizeFirst(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const ProcessData = ({
  data, activities, morePartInfo,
}) => {
  const [showReps, setShowReps] = useState({});

  const toggleReps = (key) => {
    setShowReps((r) => ({
      ...r,
      [key]: !r[key],
    }));
  };

  const handlePartInfoModal = (e, part) => {
    e.preventDefault();
    e.stopPropagation();
    morePartInfo(part);
  };

  return (
    <InfoGrid>
      <InfoContainer>
        {labelSequence.map((k) => (
          <InfoLine key={k}>
            <div className="label">
              <h4>{`${labelMap.get(k)}: `}</h4>
            </div>
            <div className="value">
              <span>{formatValue(k, data) || '--'}</span>
            </div>
          </InfoLine>
        ))}
        <InfoLine>
          <div className="label">
            <h4>Assuntos: </h4>
          </div>
          {data?.assuntos?.map((act) => (
            <div key={act.descricao} className={`value ${!act.assunto_principal ? 'fill-line' : ''}`}>
              <span>
                {act.assunto_principal ? (
                  <b>{act.descricao || '--'}</b>
                ) : (
                  <i>{act.descricao || '--'}</i>
                )}
              </span>
            </div>
          ))}
        </InfoLine>
      </InfoContainer>
      <InfoContainer>
        {partSequence.map((pKey) => (
          <PartsContainer key={pKey}>
            <InfoTitle>{`${labelMap.get(pKey)}:`}</InfoTitle>
            {data?.[pKey]?.length > 0 ? (
              data[pKey].map((part) => (
                <PartsItem key={part.documento}>
                  <PartRow onClick={() => toggleReps(part.documento)}>
                    <IconWrapper
                      style={{ transform: showReps[part.documento] ? 'rotate(0deg)' : 'rotate(-90deg)' }}
                    >
                      <ExpandMore />
                    </IconWrapper>
                    <span>{part.nome}</span>
                    <Tooltip text="Mais informações" className="row-tooltip">
                      <IconButton
                        shape="rounded"
                        style={{ padding: '4px' }}
                        onClick={(e) => handlePartInfoModal(e, part)}
                      >
                        <PartInfo />
                      </IconButton>
                    </Tooltip>
                  </PartRow>
                  <RepRow
                    style={{
                      maxHeight: showReps[part.documento] ? `${(part.representantes?.length || 1) * 9}vh` : '0',
                      transitionDuration: `${(part.representantes?.length || 1) * 0.1}s`,
                    }}
                  >
                    {part.representantes?.length > 0 ? (
                      part.representantes.map((rep) => (
                        <span key={rep.documento} title={rep.nome}>
                          {`${rep.tipo?.toLowerCase() || ''} ${rep.nome}`}
                        </span>
                      ))
                    ) : (
                      <span className="empty">Sem Informações</span>
                    )}
                  </RepRow>
                </PartsItem>
              ))
            ) : (
              <PartsItem>
                <PartEmpty>Sem Informações</PartEmpty>
              </PartsItem>
            )}
          </PartsContainer>
        ))}
      </InfoContainer>
      <InfoContainer>
        <InfoTitle>Movimentações:</InfoTitle>
        <div style={{ height: '100%', overflow: 'auto' }}>
          {activities?.sorted?.map((dateKey) => (
            <ActivityContainer key={uuidv4()}>
              <h5>{dateKey && formatDateTime(dateKey)}</h5>
              {activities.map[dateKey]?.map((act) => (
                <ActivityWrapper key={uuidv4()}>
                  <span>{capitalizeFirst(act.texto_movimentacao)}</span>
                </ActivityWrapper>
              ))}
            </ActivityContainer>
          ))}
        </div>
      </InfoContainer>
    </InfoGrid>
  );
};

ProcessData.propTypes = {
  data: PropTypes.shape({
    assuntos: PropTypes.arrayOf(PropTypes.shape({
      descricao: PropTypes.string,
      assunto_principal: PropTypes.bool,
    })),
  }),
  activities: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    map: PropTypes.objectOf(PropTypes.array),
    sorted: PropTypes.arrayOf(PropTypes.string),
  }),
  morePartInfo: PropTypes.func.isRequired,
};

ProcessData.defaultProps = {
  data: {},
  activities: {},
};

export default ProcessData;
