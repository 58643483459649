import styled, { css } from 'styled-components';
import { devices } from '../../breakpoints';

export const StyledListItem = styled.div`
  padding: 10px 15px;
  font-size: 1rem;
  border-style: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  margin-right: 0px !important;
  margin-left: 0px !important;
  position: relative;
  color: ${({ theme }) => theme.color};
  cursor: pointer;

  ${({ noOutline, theme }) => (noOutline ? css`
    outline: none;
  ` : css`
    outline-color: ${theme.outline};
  `)}

  ${({ active, theme }) => active && css`
    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0px;
      bottom: 0px;
      left: 0px;
      right: 0px;
      border-left: 5px solid ${theme.primary};
    }
    font-weight: bold;
    color: ${theme.primary};
  `}

  &:first-child {
      border-radius: 4px 4px 0px 0px;
    }

    &:last-child {
      border-radius: 0px 0px 4px 4px;
    }

  &:nth-of-type(even) {
    background-color: ${({ theme }) => theme.selectEven};
  }
  &:hover {
    background-color: ${({ theme }) => theme.selectHover};
  }

  ${({ withoutIconStyle, closeIconStyle, theme }) => !withoutIconStyle && (closeIconStyle ? css`
    & svg {
      fill: black;
      width: 16px;
      height: 16px;
      position: absolute;
      right: 5px;
      pointer-events: none;
    }
  ` : css`
    & > svg {
      fill: ${theme.grey};
      margin: 0px 10px 0px 0px;
      pointer-events: none;
    }
  `)}
  
  & .labelText:hover {
    cursor: pointer;
  }

  ${({ hideMobile }) => hideMobile && css`
    @media ${devices.phoneS}, ${devices.phoneL} and (orientation: landscape){
      display: none;
    }
  `}

  ${({ disabled, theme }) => disabled && css`
    pointer-events: none;
    cursor: default;
    color: ${theme.disabled};
    & .labelText:hover {
      cursor: default;
    }
    & > svg {
      fill: ${theme.disabled};
    }
  `}
`;

export const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  margin-left: auto;
  & > div {
    width: 100%;
    height: 100%;
    & div {
      margin: 0;
      width: 16px;
      height: 16px;
      border-width: 4px;
    }
  }
`;
