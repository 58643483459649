import styled from 'styled-components';
import { devices } from '../../../../juristec-ui/core/breakpoints';

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
  max-width: 1000px;
  overflow-x: hidden;
  overflow-y: hidden;
`;

export const ScrollXContainer = styled.div`
  max-height: 320px;
  @media ${devices.phoneL} {
    max-height: 350px;
  }
  overflow-y: auto;
  overflow-x: auto;
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  & svg {
    width: 25px;
    height: 25px;
    fill: ${({ theme }) => theme.white};
  }
`;

export const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  flex-wrap: wrap;

  & span { 
    width: 100%;
  };

  & .container-input-select { 
    margin-top: .5rem;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    width: 100%;
  };
`;
