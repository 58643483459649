import styled, { css } from 'styled-components';

export const MainContainer = styled.div`
  padding: 0 1rem;
  max-width: 50vw;
  overflow-x: hidden;
  overflow-y: hidden;
  user-select: none;
  height: 100%;
`;

export const GridContainer = styled.div`
  display: grid;
  position: relative;
  grid-template-columns: 1fr minmax(0, 30vw);
  grid-gap: .4rem;
  height: calc(100vh - 280px);
`;

export const InstanceListContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  min-width: 20vw;
  box-sizing: border-box;
`;

export const InstanceList = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden auto;
  height: 100%;
`;

export const TextAreaWrapper = styled.div`
  flex: 1;
  padding-bottom: 5px;
  & > .textAreaContainer {
    width: 100%;
    box-sizing: border-box;
    & textarea {
      width: 100%;
      font-size: 14px;
    }
    & .textAreaBar {
      bottom: 1px;
    }
  }
`;

export const CheckboxWrapper = styled.div`
  & > div {
    padding: .2rem;
    border: 2px solid;
    border-radius: .5rem;
    box-sizing: border-box;
    white-space: nowrap;
    ${({ theme }) => css`
      background-color: ${theme.containerOdd};
      border-color: ${theme.grey};
      &:hover {
        background-color: ${theme.selectHover};
      }
    `}
  }
  margin: .2rem .2rem .2rem 0;
`;

export const LvlLabel = styled.h5`
  margin: .2rem .2rem .2rem 0;
  text-align: center;
`;

export const OptionGroup = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

export const Option = styled.button`
  background-color: ${({ theme }) => theme.containerOdd};
  color: ${({ theme, value }) => theme[value]};
  padding: 5px;
  border: none;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-weight: bold;
  height: 38px;
  cursor: pointer;
  & svg {
    height: 22px;
    width: 22px;
    margin-right: 5px;
  }
  ${({ theme, active, value }) => (active ? css`
    background-color: ${theme[value]};
    color: ${theme.white};
    & svg {
      fill: ${theme.white};
    }
  ` : css`
    &:hover {
      background-color: ${theme.selectHover};
    }
  `)}
`;
