import React from 'react';

function Chart() {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.7143 3.21429C10.7143 1.43973 12.154 0 13.9286 0H16.0714C17.846 0 19.2857 1.43973 19.2857 3.21429V26.7857C19.2857 28.5603 17.846 30 16.0714 30H13.9286C12.154 30 10.7143 28.5603 10.7143 26.7857V3.21429ZM0 16.0714C0 14.2969 1.43973 12.8571 3.21429 12.8571H5.35714C7.1317 12.8571 8.57143 14.2969 8.57143 16.0714V26.7857C8.57143 28.5603 7.1317 30 5.35714 30H3.21429C1.43973 30 0 28.5603 0 26.7857V16.0714ZM24.6429 4.28571H26.7857C28.5603 4.28571 30 5.72545 30 7.5V26.7857C30 28.5603 28.5603 30 26.7857 30H24.6429C22.8683 30 21.4286 28.5603 21.4286 26.7857V7.5C21.4286 5.72545 22.8683 4.28571 24.6429 4.28571Z" />
    </svg>
  );
}

export default Chart;
