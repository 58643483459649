import styled from 'styled-components';
import { gap } from '../../../styles/theme';

export const Container = styled.div`
  min-width: 450px;

  & > .divider {
    width: 70%;
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${gap('.5rem', 'row')}
  margin: 5px;
  background-color: ${({ theme }) => theme.backgroundOdd};
  border-radius: 10px;
  box-shadow: ${({ theme }) => theme.shadow};
  padding: 0.2rem 0.6rem;

  & svg {
    fill: ${({ theme }) => theme.grey};
  }
`;

export const OverflowControl = styled.div`
  height: 290px;
  overflow: auto;
  overflow-x: hidden;
`;

export const TableFooter = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px;
`;
