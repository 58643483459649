import React from 'react';

function DisplayList() {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 14.7585H3.24148V18H0V14.7585ZM0 14.7585H3.24148V18H0V14.7585ZM0 9.83902H3.24148V13.0805H0V9.83902ZM0 4.91951H3.24148V8.16099H0V4.91951ZM0 0H3.24148V3.24147H0V0ZM4.76394 14.7585H18V18H4.76394V14.7585ZM4.76394 14.7585H18V18H4.76394V14.7585ZM4.76394 9.83902H18V13.0805H4.76394V9.83902ZM4.76394 4.91951H18V8.16099H4.76394V4.91951ZM4.76394 0H18V3.24147H4.76394V0V0Z" />
        </svg>
    );
}

export default DisplayList;