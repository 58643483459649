import React from 'react';
import PropTypes from 'prop-types';
import FocusLock, { AutoFocusInside } from 'react-focus-lock';

import {
  StyledModalOverlay, StyledModal, StyledModalContent, StyledModalHeader,
} from './styled/Modal.styled';
import Button from '../Button';
import IconButton from '../IconButton';
import ActionsGroup from '../ActionsGroup';
import Close from '../../icons/Close';

/**
* Modals are floating cards which overlay the primary UI. Modals are heavy UI elements which obscure teh primary user interface - avoid them where possible.
*/
const Modal = ({
  hide, title, children,
  yesFunc, yesLabel, notLabel, notFunc,
  minWidth, noPadding, disableFocus,
  id, className, confirmClassName, transparent,
}) => (
  <StyledModalOverlay
    onKeyPress={(e) => {
      if (yesLabel && (e?.which === 13 || e?.keyCode === 13 || e?.key === 13)) {
        yesFunc(); hide();
      }
    }}
  >
    <StyledModal aria-modal minWidth={minWidth} aria-hidden tabIndex={-1} role="dialog" id={id} className={className} transparent={transparent}>
      <StyledModalHeader>
        <IconButton color="primary" onClick={hide} shape="rounded" variant="pattern" size="small" style={{ boxShadow: 'none' }} className={`${className}_close_btn`}>
          <Close aria-hidden="true" type="button" data-dismiss="modal" aria-label="Close" />
        </IconButton>
        <span>{title}</span>
      </StyledModalHeader>
      <FocusLock disabled={(disableFocus === true && (notLabel === '' && yesLabel === '')) || disableFocus === true}>
        <StyledModalContent noPadding={noPadding} notLabel={notLabel} yesLabel={yesLabel}>
          {children}
        </StyledModalContent>
        {notLabel !== '' || yesLabel !== '' ? (
          <ActionsGroup>
            {notLabel !== '' && (
              <Button
                style={{ margin: '5px' }}
                onClick={() => { notFunc(); hide(); }}
                variant="outlined"
                size="large"
              >
                {notLabel}
              </Button>
            )}
            {yesLabel !== '' && (
              <AutoFocusInside>
                <Button
                  style={{ margin: '5px' }}
                  onClick={() => { yesFunc(); hide(); }}
                  size="large"
                  className={confirmClassName}

                >
                  {yesLabel}
                </Button>
              </AutoFocusInside>
            )}
          </ActionsGroup>
        ) : null}
      </FocusLock>
    </StyledModal>
  </StyledModalOverlay>
);

Modal.propTypes = {
  /**
  * A concise and descriptive title for the modal
  */
  title: PropTypes.string,
  /**
  * Everything that should be included in the modal content
  */
  children: PropTypes.node,
  /**
  * Function to close the modal
  */
  hide: PropTypes.func,
  /**
  * A boolean variable that determine if the minimum width of the modal container should be fixed (true) or not (false)
  */
  minWidth: PropTypes.bool,
  /**
  * A boolean variable to determine whether the content of the modal must have a padding
  */
  noPadding: PropTypes.bool,
  /**
  * The label of a confirm button. If the label is not passed, the confirm button is not rendered in the modal
  */
  yesLabel: PropTypes.string,
  /**
  * A function related with the confirm button if it is provided
  */
  yesFunc: PropTypes.func,
  /**
  * The label of a negative button. If the label is not passed, the negative button is not rendered in the modal.
  */
  notLabel: PropTypes.string,
  /**
  * A function related with the confirm button if it is provided
  */
  notFunc: PropTypes.func,

  disableFocus: PropTypes.bool,
};

Modal.defaultProps = {
  title: '',
  children: '',
  hide: () => { },
  yesFunc: () => { },
  yesLabel: '',
  notFunc: () => { },
  notLabel: '',
  minWidth: false,
  noPadding: false,
  disableFocus: false,
};

export default Modal;
