import React from 'react';
import PropTypes from 'prop-types';

const OneDrive = ({ height, width }) => (
  <svg width={width} height={height} viewBox="0 0 100 65" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M60.4144 43.235L81.0556 23.4953C76.768 6.77684 59.7392 -3.30043 43.0207 0.987169C35.4919 2.91803 28.9528 7.58517 24.6797 14.0776C24.9991 14.0696 60.4144 43.235 60.4144 43.235Z" fill="#0364B8"/>
    <path d="M38.1321 17.7938L38.1311 17.7972C34.187 15.3543 29.6383 14.0632 24.999 14.0695C24.8918 14.0695 24.7865 14.0762 24.6796 14.0775C10.8795 14.2485 -0.168911 25.5741 0.00195546 39.3739C0.0639036 44.3797 1.62799 49.2514 4.49148 53.3578L35.4153 49.4847L59.1241 30.3675L38.1321 17.7938Z" fill="#0078D4"/>
    <path d="M81.0555 23.4954C80.6017 23.4651 80.1458 23.4441 79.6844 23.4441C76.9152 23.4414 74.175 24.007 71.6332 25.1059L71.6326 25.1038L59.124 30.3676L72.9139 47.4015L97.5667 53.3943C102.887 43.5178 99.193 31.1986 89.3165 25.8785C86.7645 24.5038 83.9478 23.6913 81.0557 23.4955L81.0555 23.4954Z" fill="#1490DF"/>
    <path d="M4.49121 53.3578C9.16054 60.0711 16.8214 64.0719 24.9987 64.0676H79.684C87.1542 64.0697 94.0224 59.9704 97.5663 53.3945L59.1237 30.3676L4.49121 53.3578Z" fill="#28A8EA"/>
  </svg>

);

OneDrive.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
};

OneDrive.defaultProps = {
  height: '25px',
  width: '25px',
};

export default OneDrive;
