import styled, { css } from 'styled-components';
import { devices } from '../../breakpoints';

const getContrastYIQ = (hexcolor) => {
  // eslint-disable-next-line no-param-reassign
  hexcolor = hexcolor.replace('#', '');
  const r = parseInt(hexcolor.substr(0, 2), 16);
  const g = parseInt(hexcolor.substr(2, 2), 16);
  const b = parseInt(hexcolor.substr(4, 2), 16);
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128 ? 'black' : 'white';
};

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
`;

export const CardContainer = styled(Column)`
  overflow: hidden;
  border-radius: 1rem;
  height: 100%;
  width: 100%;
  box-shadow: 0px 4px 4px ${({ theme }) => theme.shadow};
  justify-content: space-between;
  cursor: pointer;
  position: relative;
  ${(props) => (props.bgSrc.length === 0
    ? css`
      background: linear-gradient(to top, ${props.bgColor} 90%, transparent 0);
      color: ${getContrastYIQ(props.bgColor)};
    `
    : css`
      :after {
        opacity: 0.35;
        background-image: url(${props.bgSrc});
        content: '';
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        position: absolute;
        z-index: -1;
      }
    `)}
  transition: transform .3s;
  &:hover,
  &:focus {
    transform: scale3d(1.03, 1.03, 1);
  }
  ${(props) => props.isDeleted && css`
    cursor: default;
    opacity: 0.8;
  `}
`;

export const CardHeader = styled(Row)`
  justify-content: center;
  align-items: center;
  position: relative;
  & > div {
    position: absolute;
    right: 0;
  }
  cursor: default;
  ${(props) => css`
    background-color: ${props.bgColor};
    & h5 {
      margin: 0.5rem 0;
      font-size: 14px;
      color: ${getContrastYIQ(props.bgColor)};
    }
    & svg {
      fill: ${getContrastYIQ(props.bgColor)};
    }
  `}
`;

export const CardMain = styled(Column)`
  justify-content: center;
  align-items: center;
  margin: 0.3rem;
`;

export const CardFooter = styled(Row)`
  padding-left: 0.5rem;
  min-height: 3rem;

  .bookmarkButton {
    position: absolute;
    right: 8px;
    bottom: 4px;
    cursor: pointer;
  }
`;

export const CardLastUpdate = styled.div`
  color: #0097a7;
  font-size: 11px;
  font-weight: bold;
  margin-top: 10px;

  @media ${devices.phoneL} {
    font-size: 13px;
  }
`;
