export default {
  // TITLE
  TitleControl: '',
  ShowTitleControl: { checked: true },
  StyleTitleControl: {
    fontSize: 20,
    color: '#000000',
    fontFamily: 'arial',
    bold: false,
    italic: false,
    underline: false,
  },
  // GENERAL
  SortValuesControl: 'Valor Crescente',
  CustomSortControl: {
    checked: false, kpiCategories: [],
  },
  RowsControl: 15,
  ColumnsControl: 10,
  FillDirection: 'top',
  PaddingControl: { value: 1 },
  Margin: {
    top: 20,
    right: 60,
    bottom: 20,
    left: 60,
  },
  DataFormat: { type: 'absolute' },
  // STYLE
  BorderWidthControl: { value: 0 },
  BorderColorControl: { from: 'color', modifiers: [['darker', 1.6]] },
  PalletsControl: 'default',
  ColorTheme: { scheme: 'nivo' },
  GradientPickerControl: {
    color1: '#4711B2',
    color2: '#FF9626',
    color3: null,
  },
  Colors: {},
  UseGlobalColor: true,
  // LABEL
  LegendControl: { checked: true },
  LegendPlacementControl: {
    value: {
      label: 'Direita',
      translateX: 0,
      translateY: 0,
      selectedPosition: 'right',
      direction: 'column',
      anchor: 'right',
    },
  },
  LegendsFontFamilyControl: { value: 'sans-serif' },
  LegendsFontSizeControl: { value: 12 },
  LegendsSpacingControl: { value: 10 },
  LegendSymbolControl: 'circle',
  // TOOLTIP / INTERACTIVE
  InteractiveControl: true,
  InteractivePrefixControl: '',
  InteractiveSufixControl: '',
  InteractiveDecimals: 'auto',
  InteractiveSeparadorNumericoCasas: 0,
  InteractiveCurrencyControl: 'Nenhum',
  InteractiveGroupingControl: '',
};
