import styled, { css } from 'styled-components';
import { devices } from '../../../juristec-ui/core/breakpoints';
import bg from '../../../assets/dots-purple.png';
import { gap } from '../../../juristec-ui/styles/theme';

const ContainerLoginPage = styled.div`
  background: url(${bg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: ${({ theme }) => theme.background};
`;

const Paper = styled.form`
  min-width: 25vw;
  max-width: 25vw;
  max-height: 150vh;
  box-sizing: border-box;
  box-shadow: rgba(0,0,0,0.2) 0px 6px 10px 5px;
  overflow: auto;
  background-color: ${({ theme }) => theme.background};
  padding: 30px 30px;
  border-radius: 10px;


  @media ${devices.phoneS} {
    width: 95vw;
  };
`;

const FormHeader = styled.span`
  color: ${({ theme }) => theme.primary};
  font-size: 20px;
  font-weight: bold;
  padding-bottom: 10px;
`;

const FormSubHeader = styled.span`
  color: ${({ theme }) => theme.primary};
  font-size: 18px;
  padding-bottom: 10px;
`;

const IconType = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  & svg {
    width: 20px;
    height: 20px;
    margin: 2px 5px 2px 0px;
    ${(props) => css`
      fill: ${props.type === 'success' ? '#4BB543' : '#CA0B00'};
    `};
  };
`;

const PasswordInformation = styled.div`
  border: 1px solid;
  border-color: ${({ theme }) => theme.grey};
  border-radius: 8px;
  font-size: 12px;
  padding: 10px;
  margin-top: 20px;
`;

const FormColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  ${gap('.8rem', 'column')}

  & .gap-fix {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    ${gap('.8rem', 'column')}
  }
`;

const FormRow = styled.div`
  display: flex;
  flex-direction: row;
  ${gap('15px', 'row')}

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
  }

  & .errorMessage {
    position: absolute;
    padding-top: 1px;
  }

  & .inputTxt {
    padding-left: 5px;
    border-radius: 0px;
  }
`;

const TextOptions = styled.span`
  display: block;
  padding-top: 5px;
  font-size: 0.75rem;
  color: ${({ theme }) => theme.color};
  bottom: 10px;
  left: 10px;
  & > a, span {
    cursor: pointer;
    color: ${({ theme }) => theme.primary};
    font-weight: bold;
    text-decoration-line: none;
  }
`;

const Logo = styled.img`
  display: block;
  width: 180px;
  margin-bottom: 20px;
`;

export {
  ContainerLoginPage,
  Paper,
  IconType,
  PasswordInformation,
  FormColumn,
  FormRow,
  TextOptions,
  Logo,
  FormHeader,
  FormSubHeader,
};
