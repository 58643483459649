/* eslint-disable react/jsx-props-no-spreading */
import React, {
  useState, useEffect, useRef,
} from 'react';
import PropTypes from 'prop-types';

import InputTextLine from '../InputTextLine';
import IconButton from '../IconButton';
import Popover from '../Popover';

import {
  Close, Letters, Number, DateIcon, ExpandMore, Add,
} from '../../icons';

import compare from '../../../utils/functions/sorting';
import { expDateOrder } from '../../utils/formatCharts/orderDatetime';

import {
  WrapperContainer,
  SelectContainer,
  SelectedWrapper,
  OptionsList,
  OptionsGroup,
  OptionsItem,
  IconWrapper,
} from './styled/Select.styled';

const iconMap = {
  object: { icon: <Letters />, color: 'info' },
  category: { icon: <Letters />, color: 'info' },
  int64: { icon: <Number />, color: 'success' },
  float64: { icon: <Number />, color: 'success' },
  'datetime64[ns]': { icon: <DateIcon />, color: 'warning' },
  abc: { icon: <Letters />, color: 'info' },
  float: { icon: <Number />, color: 'success' },
  date: { icon: <DateIcon />, color: 'warning' },
  //
  getIcon(key) {
    return this[key]?.icon || null;
  },
  getColor(key) {
    return this[key]?.color || null;
  },
};

const Select = ({
  label,
  placeholder,
  options,
  value,
  onChange,
  sortBy,
  maxMenuHeight,
  multiple,
  stayOpen,
  searchable,
  clearable,
  selectAll,
  atModal,
  error,
  errorMessage,
  creatable,
  onCreate,
  disabled,
  textTransform,
  wrapperStyle,
  styleContainer,
  optionsStyle,
  tourContext,
  ...props
}) => {
  const [showList, setShowList] = useState(false);
  const [headerPosition, setHeaderPosition] = useState({});
  const [optList, setOptList] = useState([]);
  const [search, setSearch] = useState('');

  const [selectedValues, setSelectedValues] = useState([]);
  const lastId = useRef(null);
  const heightRef = useRef(null);
  const cron = useRef();

  useEffect(() => {
    if (multiple && value?.length > 0) {
      setSelectedValues(value.map((v) => v.value));
      lastId.current = options.length === value.length ? value[0].id : value[value.length - 1].id;
    } else if (value?.value) {
      setSelectedValues([value.value]);
      lastId.current = value.id;
    } else {
      setSelectedValues([]);
      lastId.current = null;
    }
  }, [value]);

  useEffect(() => {
    if (showList && lastId.current !== null) {
      const node = document.getElementById(lastId.current);
      if (node) node.scrollIntoView({ block: 'center', behavior: 'instant' });
    }
  }, [showList]);

  const closePopover = () => setShowList(false);

  /**
   * Filters and sorts the options
   * @param {string} searchTxt Last search text entered
   */
  const handleOpts = (searchTxt) => {
    const opts = searchTxt?.length > 0 ? options.filter((o) => (
      o.label.toLowerCase().includes(searchTxt.trim().toLocaleLowerCase())
    )) : options;
    if (sortBy) {
      const order = sortBy === 'ascending' ? 1 : -1;
      setOptList(
        opts.sort((a, b) => order * (compare(expDateOrder(a.label), expDateOrder(b.label)))),
      );
    } else {
      setOptList(opts);
    }
  };

  useEffect(() => {
    handleOpts();
  }, [options, sortBy]);

  /**
   * Handles the search input
   * @param {Event} e DOM onChange event
   */
  const handleSearch = (e) => {
    clearTimeout(cron.current);
    const val = e.target.value;
    setSearch(val);
    cron.current = setTimeout(() => {
      handleOpts(val);
    }, 200);
  };

  /**
   * Toggles the option list
   */
  const handleToggleOptions = () => {
    if (tourContext.tourOpen) tourContext.nextStep();
    setShowList(!showList);
    setHeaderPosition(heightRef.current.getBoundingClientRect());
  };

  /**
   * Handles selection of an option
   * @param {object} selected Selected option
   */
  const handleCheck = (selected) => {
    if (tourContext.tourOpen) tourContext.nextStep();
    if (multiple) {
      const auxVal = value || [];
      if (selectedValues.includes(selected.value)) {
        const aux = auxVal.filter((s) => s.value !== selected.value) || [];
        onChange(aux);
      } else {
        onChange([...auxVal, selected]);
      }
    } else if (selected.value !== selectedValues?.[0]) {
      onChange(selected);
    }
  };

  /**
   * Uncheck all options
   * @param {Event} e DOM click event
   */
  const clearSelections = (e) => {
    e.stopPropagation();
    onChange(multiple ? [] : {});
  };

  /**
   * Toggles selection of all options
   * @param {Event} e DOM click event
   */
  const handleSelectAll = (e) => {
    if (options.length === selectedValues.length) {
      clearSelections(e);
    } else {
      onChange(options);
    }
  };

  /**
   * Formats the selected value label
   * @returns {string} Formated label
   */
  const formatSelectedLabel = () => {
    if (multiple && value?.length) {
      return value.length > 1 ? `${value.length} selecionados` : '1 selecionado';
    } if (value?.label) {
      return value.label;
    }
    return placeholder;
  };

  /**
   * Sends the `search` value to be used as a new option
   * @param {Event} e DOM click event
   */
  const handleCreate = (e) => {
    e.stopPropagation();
    onCreate(search.trim());
    setSearch('');
  };

  return (
    <Popover
      open={showList}
      closePopover={closePopover}
      containerStyle={{ width: '100%' }}
    >
      <Popover.Action>
        <WrapperContainer
          className="input-text-line-wrapper"
          style={wrapperStyle}
          tabIndex={0}
          disabled={disabled}
          ref={heightRef}
          {...props}
        >
          <SelectContainer
            label={label}
            error={error}
            isActive={showList}
            style={styleContainer}
            disabled={disabled}
            onClick={handleToggleOptions}
          >
            <SelectedWrapper
              id={`select${label}`}
              style={{
                paddingRight: clearable ? '45px' : '35px',
                textTransform: multiple ? 'none' : textTransform,
              }}
            >
              {formatSelectedLabel()}
            </SelectedWrapper>
            {clearable && selectedValues.length > 0 && (
              <IconButton
                size="small"
                color="grey"
                style={{
                  position: 'absolute',
                  bottom: '7px',
                  right: '22px',
                  boxShadow: 'none',
                  padding: '0',
                  aspectRatio: '1',
                }}
                onClick={clearSelections}
              >
                <Close />
              </IconButton>
            )}
            <ExpandMore />
            <label className="labelTxt" htmlFor={`select${label}`} style={{ textTransform }}>{label}</label>
            <span className="highlight" />
            <span className="bar" />
            {error && <span className="errorMessage">{errorMessage}</span>}
          </SelectContainer>
        </WrapperContainer>
      </Popover.Action>
      <Popover.Content
        offset={[0, 1]}
        direction="bottom-start"
        stayOpened={stayOpen}
        style={{
          boxShadow: 'none',
          zIndex: atModal ? '1050' : '100',
          borderRadius: '0',
        }}
      >
        <OptionsList
          width={headerPosition.right - headerPosition.left}
        >
          {searchable && (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <InputTextLine
                autoFocus
                placeholder={creatable ? 'Procurar ou criar...' : 'Procurar...'}
                wrapperStyle={{ padding: '.3rem', boxSizing: 'border-box' }}
                style={{ paddingTop: '0' }}
                onChange={handleSearch}
                value={search}
              />
              {creatable && (
                <IconButton
                  size="medium"
                  variant="contained"
                  shape="rounded"
                  color="secondary"
                  disabled={search.length < 2}
                  style={{
                    boxShadow: 'none',
                    height: '25px',
                    width: '25px',
                    padding: '0',
                    margin: 'auto 5px auto 0',
                    aspectRatio: '1',
                  }}
                  onClick={handleCreate}
                >
                  <Add />
                </IconButton>
              )}
            </div>
          )}
          <OptionsGroup
            maxMenuHeight={maxMenuHeight}
            style={optionsStyle}
          >
            {optList.length > 0 && multiple && selectAll && search?.length === 0 && (
              <OptionsItem
                value="select_all"
                isSelected={options.length === selectedValues.length}
                onClick={handleSelectAll}
              >
                Selecionar Todos
              </OptionsItem>
            )}
            {optList.map((opt) => {
              const isSelected = selectedValues.includes(opt.value);
              return (
                <OptionsItem
                  id={opt.id}
                  key={opt.id}
                  value={opt.value}
                  isSelected={isSelected}
                  onClick={() => handleCheck(opt)}
                  style={{ textTransform }}
                >
                  {!!opt.type && (
                    <IconWrapper color={iconMap.getColor(opt.type)}>
                      {iconMap.getIcon(opt.type)}
                    </IconWrapper>
                  )}
                  {opt.label}
                </OptionsItem>
              );
            })}
            {optList.length === 0 && (
              <OptionsItem
                value="empty"
                disabled
              >
                Sem Opções
              </OptionsItem>
            )}
          </OptionsGroup>
        </OptionsList>
      </Popover.Content>
    </Popover>
  );
};

Select.propTypes = {
  /**
   * The label of the select. It's like a title.
   */
  label: PropTypes.string,
  /**
   * Placeholder for the select value.
   */
  placeholder: PropTypes.string,
  /**
   * Choose between array of options that populate the select menu
   */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
    }),
  ),
  /**
   * The value of the select; reflected by the selected option. If select
   * has `multiple`, the data must be an array, if not, it must be an object.
   */
  value: PropTypes.oneOfType([
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
    }),
    PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        label: PropTypes.string,
        value: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
        ]),
      }),
    ),
  ]),
  onChange: PropTypes.func.isRequired,
  /**
   * Sort options.
   */
  sortBy: PropTypes.string,
  /**
   * Determine the max-height of the select container.
   */
  maxMenuHeight: PropTypes.number,
  /**
   * If true, the value container should hold multiple values.
   */
  multiple: PropTypes.bool,
  /**
   * If true, select will remain open.
   */
  stayOpen: PropTypes.bool,
  /**
   * If true, it's enable search functionality.
   */
  searchable: PropTypes.bool,
  /**
   * If true, is the select value clearable.
   */
  clearable: PropTypes.bool,
  /**
   * Allow to select all values.
   */
  selectAll: PropTypes.bool,
  /**
   * The CSS position value of the z-index, when used inside a div.
   */
  atModal: PropTypes.bool,
  /**
   * A boolean variable to determine if there is an error with the value
   */
  error: PropTypes.bool,
  /**
   * The error message to be shown in case of an error
   */
  errorMessage: PropTypes.string,
  /**
   * If true, allow to create new values. Use this funcionality with `onCreate`
   */
  creatable: PropTypes.bool,
  /**
   * Handle create values on the select. Use with `creatable` prop.
   */
  onCreate: PropTypes.func,
  /**
   * A boolean variable to determine if the select is disabled or not
   */
  disabled: PropTypes.bool,
  /**
   * Text of the option. Choose between three types.
   */
  textTransform: PropTypes.oneOf(['capitalize', 'uppercase', 'none']),
  wrapperStyle: PropTypes.objectOf(PropTypes.string),
  styleContainer: PropTypes.objectOf(PropTypes.string),
  optionsStyle: PropTypes.objectOf(PropTypes.string),
  /**
   * Object with Tour functions and state.
   */
  tourContext: PropTypes.shape({ tourOpen: PropTypes.bool, nextStep: PropTypes.func }),
};

Select.defaultProps = {
  label: '',
  placeholder: 'Selecione',
  options: [],
  value: undefined,
  sortBy: '',
  maxMenuHeight: 200,
  multiple: false,
  stayOpen: false,
  searchable: false,
  clearable: false,
  selectAll: false,
  atModal: false,
  error: false,
  errorMessage: '',
  wrapperStyle: {},
  styleContainer: {},
  optionsStyle: {},
  disabled: false,
  tourContext: { tourOpen: false, nextStep: () => {} },
  creatable: false,
  textTransform: 'none',
  onCreate: () => {},
};

export default Select;
