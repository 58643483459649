import React from 'react';

const ChartWaffle = ({ primary = '#4711B2', secondary = '#FF9626' }) => (
  <svg width="50" height="63" viewBox="0 0 50 63" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.1111 62.963H0V51.8519H11.1111V62.963Z" fill={primary} />
    <path d="M24.0741 62.963H12.963V51.8519H24.0741V62.963Z" fill={primary} />
    <path d="M37.037 62.963H25.9259V51.8519H37.037V62.963Z" fill={primary} />
    <path d="M11.1111 50H0V38.8889H11.1111V50Z" fill={primary} />
    <path d="M24.0741 50H12.963V38.8889H24.0741V50Z" fill={primary} />
    <path d="M37.037 50H25.9259V38.8889H37.037V50Z" fill={primary} />
    <path d="M11.1111 37.037H0V25.9259H11.1111V37.037Z" fill={primary} />
    <path d="M24.0741 37.037H12.963V25.9259H24.0741V37.037Z" fill={primary} />
    <path d="M50 62.963H38.8889V51.8519H50V62.963Z" fill={primary} />
    <path d="M50 38.8889V50H38.8889V38.8889H50Z" fill={primary} />
    <path d="M37.037 37.037H25.9259V25.9259H37.037V37.037Z" fill={secondary} />
    <path d="M37.037 12.963V24.0741H25.9259V12.963H37.037Z" fill={secondary} />
    <path d="M12.963 24.0741H24.0741V12.963H12.963V24.0741Z" fill={secondary} />
    <path d="M11.1111 12.963V24.0741H0V12.963H11.1111Z" fill={secondary} />
    <path d="M0 0V11.1111H11.1111V0H0Z" fill={secondary} />
    <path d="M24.0741 11.1111H12.963V0H24.0741V11.1111Z" fill={secondary} />
    <path d="M37.037 11.1111H25.9259V0H37.037V11.1111Z" fill={secondary} />
    <path d="M50 37.037H38.8889V25.9259H50V37.037Z" fill={secondary} />
    <path d="M50 24.0741H38.8889V12.963H50V24.0741Z" fill={secondary} />
    <path d="M50 11.1111H38.8889V0H50V11.1111Z" fill={secondary} />
  </svg>
);

export default ChartWaffle;
