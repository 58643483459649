import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Gradient from 'gradient2';
import {
  PreviewContainer,
  PreviewKpi,
  PreviewInsight,
  PreviewValue,
  PreviewTable,
} from '../styled/DashStyles.styled';

const genCardPreviewStyle = (tmpStyle) => ({
  borderStyle: tmpStyle.borderLine,
  borderColor: tmpStyle.borderColor,
  borderRadius: tmpStyle.borderRounded,
  borderWidth: tmpStyle.borderSize,
  boxShadow: tmpStyle.shading ? '0px 4px 4px #686868A6' : '',
});

const Preview = ({ tmpStyle }) => {
  const [gradientPreview, setGradientPreview] = useState([]);

  useEffect(() => {
    const gradient = new Gradient({
      colors: tmpStyle.useGlobalTheme ? (
        tmpStyle.charts.filter((c) => Boolean(c))
      ) : ['#4711B2', '#4711B2'],
      steps: 5,
    });
    setGradientPreview(gradient.toArray());
  }, [tmpStyle.charts, tmpStyle.useGlobalTheme]);

  return (
    <PreviewContainer style={{ gridGap: `${tmpStyle.spaceBetween * 2}px` }}>
      <PreviewKpi
        style={{ ...genCardPreviewStyle(tmpStyle), backgroundColor: tmpStyle.backgroundColor }}
      >
        <i style={tmpStyle.useGlobalTheme ? { color: tmpStyle.titles } : {}}>Gráficos</i>
        <div className="kpi">
          {gradientPreview.map((c, i) => (
            <div key={c + i.toString()} style={{ backgroundColor: c }} />
          ))}
        </div>
      </PreviewKpi>
      <PreviewInsight
        style={{
          ...genCardPreviewStyle(tmpStyle),
          ...(tmpStyle.useGlobalTheme ? { backgroundColor: tmpStyle.insightBg } : {}),
        }}
      >
        <b style={tmpStyle.useGlobalTheme ? { color: tmpStyle.insightTxt } : {}}>Insight</b>
      </PreviewInsight>
      <PreviewValue
        style={{
          ...genCardPreviewStyle(tmpStyle),
          ...(tmpStyle.useGlobalTheme ? { backgroundColor: tmpStyle.valueBg } : {}),
        }}
      >
        <i style={tmpStyle.useGlobalTheme ? { color: tmpStyle.titles } : {}}>Valor</i>
        <b style={tmpStyle.useGlobalTheme ? { color: tmpStyle.valueTxt } : {}}>910,21</b>
      </PreviewValue>
      <PreviewTable
        style={{ ...genCardPreviewStyle(tmpStyle), backgroundColor: tmpStyle.backgroundColor }}
      >
        <i style={tmpStyle.useGlobalTheme ? { color: tmpStyle.titles } : {}}>Tabela</i>
        <div className="table">
          <div style={tmpStyle.useGlobalTheme ? { backgroundColor: tmpStyle.tableHeader } : {}} />
          {[...Array(4).keys()].map((key, i) => (
            <div
              key={`table-${key}`}
              style={
                tmpStyle.useGlobalTheme ? {
                  backgroundColor: tmpStyle.tableRows[(i + 1) % 2],
                } : {}
              }
            />
          ))}
        </div>
      </PreviewTable>
    </PreviewContainer>
  );
};

Preview.propTypes = {
  tmpStyle: PropTypes.shape({
    backgroundColor: PropTypes.string,
    borderLine: PropTypes.string,
    borderColor: PropTypes.string,
    borderRounded: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    borderSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    charts: PropTypes.arrayOf(PropTypes.string),
    insightBg: PropTypes.string,
    insightTxt: PropTypes.string,
    valueBg: PropTypes.string,
    valueTxt: PropTypes.string,
    spaceBetween: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    useGlobalTheme: PropTypes.bool,
    titles: PropTypes.string,
    tableHeader: PropTypes.string,
    tableRows: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};

export default Preview;
