/* eslint-disable react/forbid-prop-types */
import React, {
  useRef, useState, useLayoutEffect, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import * as d3 from 'd3';

import legend from './Legend';
import ChartContainer from './styled/PieChart.styled';

const PieChart = ({
  data, result, dataColors, chartStyle, legendStyle,
}) => {
  const [filteredData, setFilteredData] = useState(data);

  useEffect(() => {
    setFilteredData(data);
  }, [data]);

  const chRef = useRef(null);

  const [sizes, setSizes] = useState({ width: 0, height: 0 });

  useEffect(() => {
    function handleResize() {
      const svgContainer = d3.select(chRef.current).node();
      setSizes(svgContainer.getBoundingClientRect());
    }
    if (chRef.current) {
      handleResize();
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [chRef.current]);

  // Mostra os valores no hover
  const hoverIn = (index) => {
    d3.select(chRef.current).select(`path[data-index='${index}']`)
      .transition()
      .duration('50')
      .attr('opacity', '.85');

    d3.select(chRef.current).select('.tooltip-area')
      .style('display', 'block');
  };

  // Posiciona os valores de acordo com a posicao do mouse
  const hoverMove = (e, label, value) => {
    const [x, y] = d3.pointer(e);
    const area = d3.select(chRef.current).select('.tooltip-area')
      .attr('transform', `translate(${x + 10}, ${y - 10})`)
      .select('.tooltip-text')
      .text(`${label}: ${value}`);
    const areaSizes = area.node().getBBox();

    d3.select(chRef.current).select('.tooltip-area .tooltip-bg')
      .attr('x', areaSizes.x - 5)
      .attr('y', areaSizes.y - 5)
      .attr('width', areaSizes.width + 10)
      .attr('height', areaSizes.height + 10);
  };

  // Esconde os valores
  const hoverOut = (index) => {
    d3.select(chRef.current).select(`path[data-index='${index}']`)
      .transition()
      .duration('50')
      .attr('opacity', '1');

    d3.select(chRef.current).select('.tooltip-area')
      .style('display', 'none');
  };

  // Ativa ou desativa as informacoes no grafico
  const toggleInfo = (tLabel) => {
    setFilteredData((old) => old.reduce((aux, d) => {
      if (d.label === tLabel) {
        aux.push({ ...d, hide: !d.hide });
      } else {
        aux.push(d);
      }
      return aux;
    }, []));
  };

  useLayoutEffect(() => {
    d3.select(chRef.current).selectAll('svg').remove();
    // const svgContainer = d3.select(chRef.current).node();
    // const { width } = sizes;
    // const height = sizes.height - legendStyle.size;
    const { height, width } = sizes;// svgContainer.getBoundingClientRect();
    const minContainerSize = Math.min(width, height);
    const radius = (minContainerSize - legendStyle.size) / 2 - chartStyle.margin;

    // Posicoes distribuidas no grafico
    const pieArc = d3
      .arc()
      .innerRadius(chartStyle.sliceThickness ? radius / chartStyle.sliceThickness : 0)
      .outerRadius(radius);

    // Inicia o SVG
    const svg = d3
      .select(chRef.current)
      .append('svg')
      // .select('#chart')
      .attr('viewBox', `0 0 ${width} ${height}`)
      .attr('height', height)
      .attr('width', width)
      // .attr('height', height)
      // .attr('width', width)
      // .attr('preserveAspectRatio', 'xMinYMin')
      .append('g')
      .attr(
        'transform',
        `translate(${width / 2}, ${(height - legendStyle.size) / 2})`,
      );
    const pie = d3.pie().value((d) => d.value);
    const dataReady = pie(filteredData);
    const filteredDataReady = pie(filteredData.filter((d) => !d.hide));

    // Fatias
    svg
      .selectAll('whatever')
      .data(filteredDataReady)
      .enter()
      .append('path')
      .attr('d', pieArc)
      .attr('data-index', (d) => d.index)
      .attr('fill', (d) => dataColors[d.data.label])
      .on('mouseover', (e, d) => hoverIn(d.index))
      .on('mousemove', (e, d) => hoverMove(e, d.data.label, d.value))
      .on('mouseout', (e, d) => hoverOut(d.index));

    // Valor
    /* svg
      .selectAll('mySlices')
      .data(filteredDataReady)
      .enter()
      .append('text')
      .attr('transform', (d) => `translate(${pieArc.centroid(d)})`)
      .style('user-select', 'none')
      .text((d) => d.data.value)
      .style('fill', chartStyle.fontColor)
      .style('font-size', `${chartStyle.fontSize}px`)
      .style('text-anchor', 'middle')
      .attr('opacity', '0')
      .attr('data-index', (d) => d.index)
      .on('mouseover', (e, d) => hoverIn(d.index))
      .on('mouseout', (e, d) => hoverOut(d.index)); */

    // Grupo de resultado
    svg
      .selectAll('mySlices')
      .data(result)
      .enter()
      .append('g')
      .attr('transform', 'translate(0, 0)')
      .attr('class', 'result-g')
      .style('user-select', 'none')
      .append('text')
      .text((d) => d.value)
      .style('fill', chartStyle.fontColor)
      .style('font-size', `${(chartStyle.fontSize * 2) / chartStyle.sliceThickness}px`)
      .style('text-anchor', 'middle');

    // Resultado
    svg
      .selectAll('.result-g')
      .append('text')
      .text((d) => d.label)
      .style('fill', chartStyle.fontColor)
      .style('font-size', `${chartStyle.fontSize / chartStyle.sliceThickness}px`)
      .style('text-anchor', 'middle')
      .attr('y', chartStyle.fontSize / 2);

    // Grupo do Tooltip
    svg
      .append('g')
      .style('display', 'none')
      .attr('class', 'tooltip-area')
      .append('rect')
      .attr('class', 'tooltip-bg')
      .attr('rx', '5')
      .style('stroke', 'grey')
      .style('stroke-width', '.5')
      .attr('fill', 'white');

    // Texto do tooltip
    svg.select('.tooltip-area')
      .append('text')
      .attr('class', 'tooltip-text')
      .style('fill', 'black');

    // Grupo das legendas
    const legendGroup = svg.append('g').attr('class', 'legend-group');

    legend(
      legendGroup,
      dataReady,
      dataColors,
      height,
      width - chartStyle.margin,
      legendStyle,
      toggleInfo,
    );
  }, [chartStyle, filteredData, dataColors, result, sizes]);

  return (
    <>
      <ChartContainer ref={chRef} />
    </>
  );
};

PieChart.propTypes = {
  /**
   * A array of data to be displayed in the donut chart
   */
  data: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
  })).isRequired,
  /**
   * An object with the result value of the data
   */
  result: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
  })),
  /**
   * An object with the colors for the chart data
   */
  dataColors: PropTypes.objectOf(PropTypes.any),
  /**
   * An object with custom styles for the chart
   */
  chartStyle: PropTypes.objectOf(PropTypes.any),
  /**
   * An object with custom styles for the legend
   */
  legendStyle: PropTypes.objectOf(PropTypes.any),
};

PieChart.defaultProps = {
  dataColors: {},
  result: {},
  chartStyle: {},
  legendStyle: {},
};

export default PieChart;
