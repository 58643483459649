import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Button from '../../../juristec-ui/core/Button';
import Select from '../../../juristec-ui/core/Select';
import Checkbox from '../../../juristec-ui/core/Checkbox';
import ActionsGroup from '../../../juristec-ui/core/ActionsGroup';

import { ChangeReportOwnerContainer, Row, ItemContainer } from './styled/ChangeReportOwner.styled';

const ChangeReportOwner = ({
  currentOwner,
  usersOptions,
  close,
  submit,
}) => {
  const [newOwner, setNewOwner] = useState({});
  const [removeCurrentOwner, setRemoveCurrentOwner] = useState(false);

  return (
    <>
      <ChangeReportOwnerContainer>
        <Row>
          <span><b>Proprietário atual:</b></span>
          {' '}
          <span>{currentOwner}</span>
        </Row>
        <ItemContainer>
          <Select
            selectLabel="Novo proprietário"
            options={usersOptions}
            fullWidth
            placement="start"
            value={newOwner}
            onChange={(val) => setNewOwner(val)}
            atModal
          />
          <Checkbox
            text="Não manter o usuário original compartilhado neste relatório"
            color="primary"
            variant="outlined"
            checked={removeCurrentOwner}
            handleCheckboxChange={setRemoveCurrentOwner}
            style={{ marginTop: '10px' }}
          />
        </ItemContainer>
      </ChangeReportOwnerContainer>
      <ActionsGroup>
        <Button
          style={{ margin: '5px' }}
          onClick={close}
          variant="outlined"
          size="large"
        >
          Cancelar
        </Button>
        <Button
          style={{ margin: '5px' }}
          onClick={() => submit(newOwner, removeCurrentOwner)}
          size="large"
          disabled={Object.keys(newOwner).length === 0}
        >
          Adicionar
        </Button>
      </ActionsGroup>
    </>
  );
};

ChangeReportOwner.propTypes = {
  currentOwner: PropTypes.string,
  usersOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
  close: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
};

ChangeReportOwner.defaultProps = {
  currentOwner: '',
};

export default ChangeReportOwner;
