import React from 'react';
import PropTypes from 'prop-types';

// import { Container } from './styles';

const Report = ({ width, height }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.5 13.5H13.5V15H7.5V13.5Z" />
      <path d="M7.5 9.75H16.5V11.25H7.5V9.75Z" />
      <path d="M7.5 17.25H11.25V18.75H7.5V17.25Z" />
      <path d="M18.75 3.75H16.5V3C16.5 2.60218 16.342 2.22064 16.0607 1.93934C15.7794 1.65804 15.3978 1.5 15 1.5H9C8.60218 1.5 8.22064 1.65804 7.93934 1.93934C7.65804 2.22064 7.5 2.60218 7.5 3V3.75H5.25C4.85218 3.75 4.47064 3.90804 4.18934 4.18934C3.90804 4.47064 3.75 4.85218 3.75 5.25V21C3.75 21.3978 3.90804 21.7794 4.18934 22.0607C4.47064 22.342 4.85218 22.5 5.25 22.5H18.75C19.1478 22.5 19.5294 22.342 19.8107 22.0607C20.092 21.7794 20.25 21.3978 20.25 21V5.25C20.25 4.85218 20.092 4.47064 19.8107 4.18934C19.5294 3.90804 19.1478 3.75 18.75 3.75V3.75ZM9 3H15V6H9V3ZM18.75 21H5.25V5.25H7.5V7.5H16.5V5.25H18.75V21Z" />
    </svg>

  );
};

Report.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

Report.defaultProps = {
  width: '24px',
  height: '24px',
};

export default Report;
