import styled from 'styled-components';
import { devices } from '../../breakpoints';

export const ShareContainer = styled.div`
  padding: 0 0.5rem;
  width: 450px;
  user-select: none;

  @media ${devices.phoneS} {
    width: auto;
  }
`;

export const UserShareRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
`;

export const UserProfile = styled.div`
  display: flex;
  align-items: center;
  max-width: 246px;
`;

export const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-left: 5px;
  overflow: hidden;

  & > span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    &:first-child {
      font-size: 0.9rem;
      font-weight: bold;
    }
    &:last-child {
      font-size: 0.65rem;
    }
  } 
`;

export const UserList = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 450px);
  overflow: auto;
`;

export const SelectWrapper = styled.div`
  width: 165px;
  margin-left: auto;
  padding-left: 1rem;
  display: flex;

  @media ${devices.phoneS} {
    width: 100%;
    margin: 0;
    padding: 0;
  }
`;

export const UserListItem = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${({ theme }) => theme.container};
  padding: .5rem;
  border-radius: .5rem;
  margin-top: 8px;
  &:first-child() {
    margin-top: 0;
  }

  @media ${devices.phoneS} {
    flex-direction: column;
  }
`;
