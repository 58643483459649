import styled from 'styled-components';
import { Responsive, WidthProvider } from 'react-grid-layout';

const ResponsiveReactGridLayout = WidthProvider(Responsive);

const StyledResponsiveReactGridLayout = styled(ResponsiveReactGridLayout)`
  & > div {
    box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14),
      0px 1px 8px 0px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    user-select: none;

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: -2px;
      bottom: -2px;
      left: -2px;
      right: -2px;
      border-radius: 5px;
      border: ${(props) => (props.editPainel ? 'dashed 2px #a9a9a9' : 'solid 2px transparent')};
    }
  }
  & .react-resizable-handle-s {
    cursor: ns-resize !important;
  }
  & .react-resizable-handle-e {
    cursor: ew-resize !important;
  }
`;

const StyledOuterGridLayout = styled.div`
  padding: 0.5%;
  overflow: auto;
  overflow-x: hidden;
  width: 100%;
  padding-bottom: ${(props) => (props.editPainel ? '10px' : 'inherit')};
`;

export { StyledResponsiveReactGridLayout, StyledOuterGridLayout };
