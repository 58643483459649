import {
  query,
  collection,
  getDocs,
  where as queryWhere,
  orderBy as queryOrderBy,
  limit as queryLimit,
  limitToLast as queryLimitToLast,
  startAt as queryStartAt,
  startAfter as queryStartAfter,
  endAt as queryEndAt,
  endBefore as queryEndBefore,
} from 'firebase/firestore';
import firebase from '../firebase';

import Doc from './Doc';

class Collection {
  /**
   * @constructor
   * >### Encapsulates firestore "collection" function for compatibility
   * * * *
   * Gets a `CollectionReference` instance that refers to the collection at
   * the specified absolute path.
   *
   * @param {string} path A slash-separated path to a collection.
   * @throws If the final path has an even number of segments and does not point
   * to a collection.
   * @returns The `CollectionReference` instance.
   */
  constructor(path) {
    this.ref = collection(firebase.db, path);
    this.queryConstraints = [];
  }

  /**
   * >### Wrapper of firestore `getDocs` function
   * * * *
   * Executes the query and returns the results as a `QuerySnapshot`.
   *
   * Note: `getDocs()` attempts to provide up-to-date data when possible by
   * waiting for data from the server, but it may return cached data or fail if
   * you are offline and the server cannot be reached. To specify this behavior,
   * invoke `getDocsFromCache` or `getDocsFromServer`.
   *
   * @returns A `Promise` that will be resolved with the results of the query.
   */
  get() {
    return getDocs(query(this.ref, ...this.queryConstraints));
  }

  /**
   * >### Wrapper of firestore `getDoc` function
   * * * *
   * @constructs Doc
   * @param {string} [path] A slash-separated path to a document.
   * Has to be omitted to use auto-genrated IDs.
   * @returns The `Doc` instance.
   */
  doc(path) {
    return new Doc(path, this.ref);
  }

  /**
   * >### Wrapper of firestore `where` function
   * * * *
   * Creates a `QueryConstraint` that enforces that documents must contain the
   * specified field and that the value should satisfy the relation constraint
   * provided.
   *
   * @param {string} fieldPath The path to compare
   * @param {"<"|"<="|"=="|">"|">="|"!="} opStr The operation
   * string (e.g "&lt;", "&lt;=", "==", "&gt;", "&gt;=", "!=").
   * @param {string} value The value for comparison
   * @returns This `Collection` instance. Used for chaining method calls.
   */
  where(fieldPath, opStr, value) {
    this.queryConstraints.push(queryWhere(fieldPath, opStr, value));
    return this;
  }

  /**
   * >### Wrapper of firestore `orderBy` function
   * * * *
   * Creates a `QueryConstraint` that sorts the query result by the
   * specified field, optionally in descending order instead of ascending.
   *
   * @param {string} fieldPath The field to sort by.
   * @param {'asc'|'desc'} directionStr Optional direction to sort by ('asc' or 'desc'). If
   * not specified, order will be ascending.
   * @returns This `Collection` instance. Used for chaining method calls.
   */
  orderBy(fieldPath, directionStr = 'asc') {
    this.queryConstraints.push(queryOrderBy(fieldPath, directionStr));
    return this;
  }

  /**
   * >### Wrapper of firestore `limit` function
   * * * *
   * Creates a `QueryConstraint` that only returns the first matching documents.
   *
   * @param {number} limit The maximum number of items to return.
   * @returns This `Collection` instance. Used for chaining method calls.
   */
  limit(limit) {
    this.queryConstraints.push(queryLimit(limit));
    return this;
  }

  /**
   * >### Wrapper of firestore `limitToLast` function
   * * * *
   * Creates a `QueryConstraint` that only returns the last matching documents.
   *
   * You must specify at least one `orderBy` clause for `limitToLast` queries,
   * otherwise an exception will be thrown during execution.
   *
   * @param {number} limit The maximum number of items to return.
   * @returns This `Collection` instance. Used for chaining method calls.
   */
  limitToLast(limit) {
    this.queryConstraints.push(queryLimitToLast(limit));
    return this;
  }

  /**
   * >### Wrapper of firestore `startAt` function
   * * * *
   * Creates a `QueryConstraint` that modifies the result set to start at the
   * provided fields relative to the order of the query. The order of the field
   * values must match the order of the order by clauses of the query.
   *
   * @param {*} fieldValues The field values to start this query at, in order
   * of the query's order by.
   * @returns This `Collection` instance. Used for chaining method calls.
   */
  startAt(...fieldValues) {
    this.queryConstraints.push(queryStartAt(...fieldValues));
    return this;
  }

  /**
   * >### Wrapper of firestore `startAfter` function
   * * * *
   * Creates a `QueryConstraint` that modifies the result set to start after the
   * provided fields relative to the order of the query. The order of the field
   * values must match the order of the order by clauses of the query.
   *
   * @param {*} fieldValues The field values to start this query after, in order
   * of the query's order by.
   * @returns This `Collection` instance. Used for chaining method calls.
   */
  startAfter(...fieldValues) {
    this.queryConstraints.push(queryStartAfter(...fieldValues));
    return this;
  }

  /**
   * >### Wrapper of firestore `endAt` function
   * * * *
   * Creates a `QueryConstraint` that modifies the result set to end at the
   * provided fields relative to the order of the query. The order of the field
   * values must match the order of the order by clauses of the query.
   *
   * @param {*} fieldValues The field values to end this query at, in order
   * of the query's order by.
   * @returns This `Collection` instance. Used for chaining method calls.
   */
  endAt(...fieldValues) {
    this.queryConstraints.push(queryEndAt(...fieldValues));
    return this;
  }

  /**
   * >### Wrapper of firestore `endBefore` function
   * * * *
   * Creates a `QueryConstraint` that modifies the result set to end before the
   * provided fields relative to the order of the query. The order of the field
   * values must match the order of the order by clauses of the query.
   *
   * @param {*} fieldValues The field values to end this query before, in order
   * of the query's order by.
   * @returns This `Collection` instance. Used for chaining method calls.
   */
  endBefore(...fieldValues) {
    this.queryConstraints.push(queryEndBefore(...fieldValues));
    return this;
  }
}

export default Collection;
