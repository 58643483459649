import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// components
import IconButton from '../IconButton';
import Button from '../Button';
import Tooltip from '../Tooltip';

// utils
import { formatDateTime } from '../../utils/functions/lab';
import { Copy, Reload, Close } from '../../icons';

import { Container, Actions } from './styled/PublicLink.styled';

function PublicLink({
  link,
  genPublicUrl,
  timestamp,
}) {
  const [blockBtn, setBlockBtn] = useState(false);
  const [_link, _setLink] = useState('');
  const [_timestamp, _setTimestamp] = useState(null);

  const handleGen = async (revoke) => {
    setBlockBtn(true);
    const res = await genPublicUrl(revoke);
    setBlockBtn(false);
    if (!res.error) {
      _setLink(res.publicUrl && `${window.location.origin}${res.publicUrl}`);
      _setTimestamp(res.localTimestamp);
    }
  };

  const genLink = async () => {
    await handleGen(false);
  };

  const revokeLink = async () => {
    await handleGen(true);
  };

  const handleCpy = () => {
    if (!_link) return;
    //
    const copyText = _link;
    const textArea = document.createElement('textarea');
    textArea.textContent = copyText;
    textArea.style.position = 'fixed'; // Prevent scrolling to bottom of page in MS Edge.
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('copy');

    document.body.removeChild(textArea);

    //
    navigator.clipboard.writeText(_link);
  };

  useEffect(() => {
    if (link) {
      _setLink(`${window.location.origin}${link}`);
      _setTimestamp(timestamp);
    }
  }, [link, timestamp]);

  return (
    <Container className="public-link-container">
      <span className="public-link-title">
        Link de compartilhamento externo
      </span>
      <div className="public-link-main">
        <div className="public-link-infos">
          <span className="public-link-link">
            {_link || 'Não há nenhum link gerado'}
          </span>
        </div>
        <Actions className="public-link-actions">
          {_link ? (
            <>
              <Tooltip text="Copiar Link" atModal>
                <IconButton shape="rounded" variant="pattern" onClick={handleCpy}>
                  <Copy />
                </IconButton>
              </Tooltip>
              <Tooltip text="Atualizar link público" atModal>
                <IconButton shape="rounded" variant="pattern" onClick={genLink} disabled={blockBtn}>
                  <Reload />
                </IconButton>
              </Tooltip>
              <Tooltip text="Desabilitar compartilhamento externo" atModal>
                <IconButton shape="rounded" variant="pattern" onClick={revokeLink} disabled={blockBtn}>
                  <Close />
                </IconButton>
              </Tooltip>
            </>
          ) : (
            <Button onClick={genLink} disabled={blockBtn} variant="pattern" color="primary" shape="rounded" className="public-link-gen-link">
              Gerar Link
            </Button>
          )}
        </Actions>
      </div>
      {_link && _timestamp && (
        <span className="public-link-timestamp">
          última atualização
          {' '}
          {formatDateTime(_timestamp)}
        </span>
      )}
    </Container>
  );
}

PublicLink.propTypes = {
  link: PropTypes.string,
  genPublicUrl: PropTypes.func,
  timestamp: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
};

PublicLink.defaultProps = {
  link: '',
  genPublicUrl: () => {},
  timestamp: '',
};

export default PublicLink;
