import styled from 'styled-components';
import { HexColorInput } from 'react-colorful';

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & .react-colorful__last-control {
    border-radius: 0;
  }
`;

export const CustomHexInput = styled(HexColorInput)`
  width: 60px;
  border: none;
  outline: none;
  border-bottom: 1px solid ${({ theme }) => theme.inputBottomLine};
  background-color:  transparent;
  color: ${({ theme }) => theme.color};
  box-sizing: border-box;
  font-size: 1rem;

  &:focus {
    ~.bar:before, ~.bar:after {
      width: 50%;
    };
  };
`;

export const InputWrapper = styled.div`
  position: relative;

  & .bar { 
    position:relative; 
    display:block; 
    width: 100%; 
  };

  & .bar:before, .bar:after {
    content: '';
    height: 2px; 
    width: 0;
    position:absolute;
    background: ${({ theme }) => theme.highlightTextLine};
    transition: 0.2s ease all;
  };
  
  & .bar:before {
    left: 50%;
  };

  & .bar:after {
    right: 50%; 
  };
`;
