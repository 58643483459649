import React from 'react';
import PropTypes from 'prop-types';

import Button from '../../../juristec-ui/core/Button';
import IconButton from '../../../juristec-ui/core/IconButton';
import Tooltip from '../../../juristec-ui/core/Tooltip';

import { Download, Filter, Finder } from '../../../juristec-ui/icons';

import {
  MainContainer,
  NavOpt,
  QueryStatus,
} from './styled/BigDataToolbar.styled';

const BigDataToolbar = ({
  resultsQty,
  tabState,
  hasFilters,
  searchTime,
  toggleFilter,
  isMobileSize,
  downloadResult,
}) => {
  const [tab, setTab] = tabState;

  return (
    <MainContainer>
      <NavOpt active={tab === 'META'} onClick={() => setTab('META')}>
        Geral
      </NavOpt>
      <NavOpt active={tab === 'PUBLIC'} onClick={() => setTab('PUBLIC')} style={{ marginRight: 'auto' }}>
        Publicações
      </NavOpt>
      {resultsQty > 0 && !isMobileSize && (
        <QueryStatus>
          {`${resultsQty.toLocaleString('pt-BR')} resultados `}
          {`(${searchTime.toLocaleString('pt-BR')} segundos)`}
        </QueryStatus>
      )}
      {hasFilters && resultsQty > 0 && !isMobileSize && (
        <Tooltip text="Download do resultado">
          <IconButton
            color="secondary"
            variant="contained"
            shape="rounded"
            onClick={downloadResult}
          >
            <Download />
          </IconButton>
        </Tooltip>
      )}
      {tab === 'META' && (
        isMobileSize ? (
          <Tooltip text="Filtrar">
            <IconButton
              color={hasFilters ? 'secondary' : 'primary'}
              variant={hasFilters ? 'contained' : 'outlined'}
              shape="rounded"
              onClick={toggleFilter}
            >
              <Filter />
            </IconButton>
          </Tooltip>
        ) : (
          <Button
            color={hasFilters ? 'secondary' : 'primary'}
            variant={hasFilters ? 'contained' : 'outlined'}
            shape="rounded"
            onClick={toggleFilter}
            className="filter-btn"
          >
            Filtrar
            <Filter />
          </Button>
        )
      )}
      {tab === 'PUBLIC' && (
        isMobileSize ? (
          <Tooltip text="Buscar">
            <IconButton
              color={hasFilters ? 'secondary' : 'primary'}
              variant={hasFilters ? 'contained' : 'outlined'}
              shape="rounded"
              onClick={toggleFilter}
            >
              <Finder />
            </IconButton>
          </Tooltip>
        ) : (
          <Button
            color={hasFilters ? 'secondary' : 'primary'}
            variant={hasFilters ? 'contained' : 'outlined'}
            shape="rounded"
            onClick={toggleFilter}
            className="filter-btn"
          >
            Buscar
            <Finder />
          </Button>
        )
      )}
    </MainContainer>
  );
};

BigDataToolbar.propTypes = {
  resultsQty: PropTypes.number,
  tabState: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
  ])),
  hasFilters: PropTypes.bool,
  isMobileSize: PropTypes.bool,
  searchTime: PropTypes.number,
  toggleFilter: PropTypes.func.isRequired,
};

BigDataToolbar.defaultProps = {
  resultsQty: 0,
  tabState: ['', () => {}],
  hasFilters: false,
  isMobileSize: false,
  searchTime: 0,
};

export default BigDataToolbar;
