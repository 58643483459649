import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';

// components
import DataTable from '../../../juristec-ui/core/DataTable';
import TablePagination from '../../../juristec-ui/core/TablePagination';

// styled
import {
  Container,
  Infos,
  StyledFont,
  HistoryList,
  HistoryCount,
  EmptyList,
  Title,
} from './styled/UserHistory.styled';

// utils
import { formatDateTime } from '../../../juristec-ui/utils/functions/lab';

const UserHistory = ({ user, history }) => {
  const [page, setPage] = useState(0);

  const theme = useTheme();

  const formatDate = (datetime) => (
    datetime ? formatDateTime(datetime, { time: 'half' }) : 'Não consta'
  );

  const columns = [
    {
      field: 'date',
      label: 'Data',
      sortable: true,
      valueGetter: (param) => formatDate(param?.date || ''),
    },
  ];

  return (
    <Container>
      <Infos>
        <StyledFont>
          <Title>Usuário:</Title>
          {' '}
          <span>{user?.displayName ? user.displayName : user.email.split('@')[0]}</span>
        </StyledFont>
        <HistoryCount>
          <StyledFont>
            <Title>Acessos:</Title>
            {' '}
            <span>
              {history.length}
            </span>
          </StyledFont>
        </HistoryCount>
      </Infos>
      <HistoryList>
        {history.length > 0 ? (
          <>
            <DataTable
              columns={columns}
              rowData={history}
              rowColor={theme.tableBackground}
              strippedRowsColor={theme.containerOdd}
              defaultSortField="date"
              defaultSortOrder="descending"
              headerColor="transparent"
              theadStyle={{
                position: 'sticky',
                top: 0,
                zIndex: 1,
                backgroundColor: theme.background,
              }}
              tbodyStyle={{
                fontSize: '14px',
              }}
              usePagination
              page={page}
              itemsPerPage={5}
            />
            <TablePagination
              page={page}
              setPage={setPage}
              totalPages={
                Math.floor(history.length % 5 === 0
                  ? (history.length / 5) - 1
                  : history.length / 5)
              }
            />
          </>
        ) : (
          <EmptyList>
            Não existe histórico de acesso desse usuário
          </EmptyList>
        )}
      </HistoryList>
    </Container>
  );
};

UserHistory.propTypes = {
  user: PropTypes.shape({
    displayName: PropTypes.string,
    email: PropTypes.string,
  }),
  /**
   * Array of the history data.
  * */
  history: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string,
      session: PropTypes.string,
    }),
  ),
};

UserHistory.defaultProps = {
  user: {},
  history: [],
};

export default UserHistory;
