import styled from 'styled-components';

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 700px;
  height: calc(100vh - 350px);
  & input, textarea {
    font-size: 14px !important;
  }
  & > div + div {
    margin-top: .5rem;
  }
`;

export const InputRow = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ImageContainer = styled(InputRow)`
  flex: 1;
`;

export const ImageSquare = styled.div`
  flex: 1.5;
  background-color: ${({ theme }) => theme.lightgrey};
  background-image: url(${({ src }) => src});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  & svg {
    fill: ${({ theme }) => theme.grey};
    height: 100px;
    width: 100px;
  }
`;

export const ImageOptions = styled.div`
  flex: 1;
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  margin-left: .5rem;
  & span {
    line-height: 11px;
    height: 16px;
    display: block;
    text-align: center;
    margin: .4rem 0;
  }
  & > button {
    justify-content: space-between;
    width: 100%;
    & svg {
      height: 20px;
      width: 20px;
    }
    &:last-of-type {
      & svg {
        stroke: ${({ theme }) => theme.error};
      }
    }
  }
`;
