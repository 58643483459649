import React from 'react';

// import { Container } from './styles';

function Letters() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.8949 16.553L6.89494 8.553C6.55594 7.875 5.44494 7.875 5.10594 8.553L1.10594 16.553C0.987254 16.7902 0.967672 17.0649 1.0515 17.3166C1.13533 17.5682 1.3157 17.7763 1.55294 17.895C1.79017 18.0137 2.06484 18.0333 2.31651 17.9494C2.56819 17.8656 2.77625 17.6852 2.89494 17.448L3.61794 16H8.38194L9.10594 17.447C9.1644 17.5646 9.2455 17.6696 9.3446 17.7558C9.4437 17.842 9.55884 17.9078 9.68342 17.9494C9.80801 17.991 9.93959 18.0077 10.0706 17.9983C10.2016 17.989 10.3295 17.9539 10.4469 17.895C10.9409 17.647 11.1419 17.047 10.8949 16.553ZM4.61794 14L5.99994 11.236L7.38194 14H4.61794ZM21.9999 18H15.9999C15.8144 17.9996 15.6326 17.9477 15.4748 17.85C15.3171 17.7523 15.1896 17.6126 15.1066 17.4467C15.0236 17.2807 14.9884 17.0949 15.0049 16.9101C15.0214 16.7253 15.0889 16.5487 15.1999 16.4L19.9999 10H15.9999C15.7347 10 15.4804 9.89464 15.2928 9.70711C15.1053 9.51957 14.9999 9.26522 14.9999 9C14.9999 8.73478 15.1053 8.48043 15.2928 8.29289C15.4804 8.10536 15.7347 8 15.9999 8H21.9999C22.1855 8.00037 22.3673 8.05231 22.525 8.15002C22.6828 8.24773 22.8103 8.38737 22.8933 8.55334C22.9762 8.71931 23.0115 8.90507 22.995 9.0899C22.9785 9.27472 22.911 9.45133 22.7999 9.6L17.9999 16H21.9999C22.2652 16 22.5195 16.1054 22.707 16.2929C22.8946 16.4804 22.9999 16.7348 22.9999 17C22.9999 17.2652 22.8946 17.5196 22.707 17.7071C22.5195 17.8946 22.2652 18 21.9999 18ZM13.9999 14H11.9999C11.7347 14 11.4804 13.8946 11.2928 13.7071C11.1053 13.5196 10.9999 13.2652 10.9999 13C10.9999 12.7348 11.1053 12.4804 11.2928 12.2929C11.4804 12.1054 11.7347 12 11.9999 12H13.9999C14.2652 12 14.5195 12.1054 14.707 12.2929C14.8946 12.4804 14.9999 12.7348 14.9999 13C14.9999 13.2652 14.8946 13.5196 14.707 13.7071C14.5195 13.8946 14.2652 14 13.9999 14Z" />
    </svg>

  );
}

export default Letters;
