import React, {
  useState, useCallback, useMemo, createContext,
} from 'react';
import PropTypes from 'prop-types';
import useEscape from '../../juristec-ui/hooks/useEscape';
import Alert from '../../juristec-ui/core/Alert';

// useContext
// import AlertContext from '../../../../context/AlertContext';
export const AlertContext = createContext(null);

/**
  * openAlert {boolean}: variável (de estado) que guarda se está aberto/fechado.
  * toggleAlert {func}: inverter estado de aberto/fechado.
      Esse toggle serve caso deseje usar o alerta com botões customs.
  * setAlertConfig {func}: Seta as configurações para o alerta e abre ele.
    setAlertConfig({
      type: 'warning' | 'success' | 'error',
      text: 'string',
      child: node | 'string' | number |,

      withoutConfirm: boolean, usar botão ok caso true que pode usar a okFunction.
      okFunction: function,  Função para ser usado  no ok.

      withFunction: boolean, Mostrar botão de 'confirmar' que espera função confirmFunction.
      confirmFunction: function, Função usada no 'confirmar'
    });

    Como usar custom buttons:
      * Não passar confirmFunction ou passar confirmFunction: false.
      * Passar withFunction: false
      * Passar withoutConfirm: true
      * No child: passar o componente com os botões lá.
      * Para fechar o alerta nesses casos use o toggleAlert que é oferecido no AlertContext.
  */

export const AlertApiProvider = ({ children }) => {
  const [openAlert, setOpenAlert] = useState(false);
  const [alertConfig, setAlertConfig_] = useState(false);

  useEscape(() => setOpenAlert(false));

  const toggleAlert = useCallback(() => {
    setOpenAlert((p) => !p);
  }, []);

  const setAlertConfig = useCallback((content) => {
    setAlertConfig_(content);
    setOpenAlert(true);
  }, []);

  const RenderAlert = () => {
    function handleCloseAlert() {
      toggleAlert();
    }

    return (
      openAlert && (
      <Alert
        openAlert={openAlert}
        closeAlert={handleCloseAlert}
        okFunction={alertConfig?.okFunction}
        withoutConfirm={alertConfig?.withoutConfirm}
        withFunction={alertConfig?.withFunction}
        confirmFunction={alertConfig?.confirmFunction}
        text={alertConfig?.text}
        type={alertConfig?.type}
        noPadding={alertConfig?.noPadding || false}
      >
        {alertConfig?.child}
      </Alert>
      )
    );
  };

  const getAlertApi = useMemo(() => ({ openAlert, toggleAlert, setAlertConfig }), []);

  return (
    <AlertContext.Provider value={getAlertApi}>
      {children}
      <RenderAlert />
    </AlertContext.Provider>
  );
};

AlertApiProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.func,
    PropTypes.object,
  ]),
};

AlertApiProvider.defaultProps = { children: '' };

// export default AlertApiProvider;
