/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';

import ControllersGroup from '../../../controllers/ControllersGroup';
import {
  SelectController,
  CustomSortController,
} from '../../../controllers/Base';

const GeneralControllers = ({
  handle,
  config,
  data,
  extend,
  setExtend,
}) => (
  <>
    <ControllersGroup
      title="Opções Gerais"
      id="general"
      key="general"
      extend={extend}
      setExtend={setExtend}
      sizeIncrease={config?.SortValuesControl === 'Customizado' ? data?.index?.length : 0}
    >
      <SelectController
        label="Ordenar colunas"
        handle={handle}
        kWord="SortValuesControl"
        options={[
          { label: 'Automático', value: 'Não ordenado', id: 'nao-ordenado' },
          { label: 'Alfabética A-Z', value: 'Alfabética A-Z', id: 'alfabetica-a-z' },
          { label: 'Alfabética Z-A', value: 'Alfabética Z-A', id: 'alfabetica-z-a' },
          { label: 'Customizado', value: 'Customizado', id: 'Customizado' },
        ]}
        configValue={config?.SortValuesControl}
        fullWidth
      />
      {config?.SortValuesControl === 'Customizado' && (
        <CustomSortController
          handle={handle}
          kWord="CustomSortControlUpt"
          oldOrder={data?.columns || []}
          newOrder={config?.CustomSortControl?.kpiCategories || []}
        />
      )}
      <SelectController
        label="Ordenar campos"
        handle={handle}
        kWord="SortDataControl"
        options={[
          { label: 'Não ordenado', value: 'Não ordenado', id: 'nao-ordenado' },
          { label: 'Crescente', value: 'Crescente', id: 'Crescente' },
          { label: 'Decrescente', value: 'Decrescente', id: 'Decrescente' },
        ]}
        configValue={config?.SortDataControl}
        fullWidth
      />
      {config?.SortDataControl !== 'Não ordenado' && (
        <SelectController
          label="Coluna a ser ordenada"
          handle={handle}
          kWord="ColumnToSortControl"
          options={data?.columns?.map((col) => (
            { label: col, value: col.toString(), id: `sort-${col}` }
          ))}
          configValue={(config?.ColumnToSortControl === '' ? data?.columns?.[0] : config.ColumnToSortControl) ?? ''}
          fullWidth
        />
      )}
    </ControllersGroup>
  </>
);

GeneralControllers.propTypes = {
  config: PropTypes.objectOf(PropTypes.any),
  data: PropTypes.objectOf(PropTypes.any),
  handle: PropTypes.func,
  extend: PropTypes.string,
  setExtend: PropTypes.func,
};

GeneralControllers.defaultProps = {
  config: undefined,
  data: undefined,
  handle: () => {},
  extend: '',
  setExtend: () => {},
};

export default GeneralControllers;
