import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { StyledSizeContainerSquare, StyledContainerLoader, StyledContainerText } from './styled/Loader.styled';

// import { Container } from './styles';

/**
 * A loading spinner that should be used every time the user is waiting some request.
*/
function Loader({ underText, size, position }) {
  // const [messageIndex, setMessageIndex] = useState(0);
  const [message, setMessage] = useState('');
  const index = useRef(0);
  const timer = useRef();

  useEffect(() => {
    if (Array.isArray(underText)) { // verifica se underText é um array
      if (index.current < underText.length - 1) { // verifica se index é menor que tamanho do array
        if (!timer.current) { // evitar setar multiplos timer para o mesmo component
          timer.current = setInterval(() => {
            if (index.current < underText.length - 1) {
              index.current += 1; // avança pra proxima frase
            } else {
              index.current = 0; // volta pra primeira frase
            }
            setMessage(underText[index.current]);
          }, 2000);
        }
      }
    } else if (typeof underText === 'string') setMessage(underText); // caso nao for array
  }, []);

  useEffect(() => () => {
    clearInterval(timer.current);
  }, []);

  return (
    <StyledContainerLoader position={position}>
      <StyledSizeContainerSquare size={size} position={position}>
        <div />
        <div />
        <div />
        <div />
      </StyledSizeContainerSquare>
      {underText !== '' ? (<StyledContainerText>{message}</StyledContainerText>) : null}
    </StyledContainerLoader>
  );
}
Loader.propTypes = {
  /**
   * Position of the loader. Choose from two options.
   */
  position: PropTypes.oneOf(['relative', 'fixed']),
  /**
   * A number determining the size of the loader.
   */
  size: PropTypes.number,
  /**
   * A string or an array of strings determining the text to be shown under the loader.
   */
  underText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
};
Loader.defaultProps = {
  underText: '',
  position: 'fixed',
  size: 20,
};
export default Loader;
