import React from 'react';

// import { Container } from './styles';

function OpenLock() {
  return (
    <svg
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 477.999 477.999"
      xmlSpace="preserve"
    >
      <path d="M409.666,170.799H119.533v-17.067c-0.052-65.98,53.393-119.509,119.372-119.561
       c48.746-0.038,92.626,29.543,110.874,74.744c3.594,8.714,13.571,12.864,22.285,9.27c8.63-3.559,12.799-13.393,9.357-22.07
       c-31.77-78.657-121.289-116.667-199.946-84.896C123.382,34.684,85.362,91.08,85.4,153.733v17.067H68.333
       c-9.426,0-17.067,7.641-17.067,17.067v273.067c0,9.426,7.641,17.067,17.067,17.067h341.333c9.426,0,17.067-7.641,17.067-17.067
       V187.866C426.733,178.44,419.092,170.799,409.666,170.799z M287.272,324.187c-5.155,14.581-16.624,26.05-31.205,31.205v37.274
       c0,9.426-7.641,17.067-17.067,17.067s-17.067-7.641-17.067-17.067v-37.274c-26.66-9.426-40.631-38.679-31.205-65.339
       s38.679-40.631,65.338-31.205C282.726,268.275,296.697,297.528,287.272,324.187z"
      />
    </svg>
  );
}

export default OpenLock;
