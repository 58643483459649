import styled, { keyframes, css } from 'styled-components';
import { gap } from '../../../styles/theme';

const inputHighlighter = keyframes`
  from { background: ${({ theme }) => theme.highlightTextLine}; }
  to { width:0; background:transparent; }
`;

export const WrapperContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  position: relative;
  width: 100%;
  // gap: 10px;
  ${gap('10px', 'row')}
`;

const StyledInputTextLine = styled.div`
  position: relative;
  width: 100%;
  padding-top: ${({ label }) => (label === '' ? '0px' : '20px')};
  ${(props) => props.disabled && css`
    background-color: rgba(234,234,234,0.5);
    pointer-events: none;
  `}

  .inputTxt {
    color: ${({ theme }) => theme.color};
    box-sizing: border-box;
    font-size: 1rem;
    padding:10px 0px 5px 0px;
    display:block;
    width:100%;
    border:none;
    border-bottom:1px solid ${({ theme }) => theme.inputBottomLine}; 
    background-color: ${({ theme, notTransparent }) => (notTransparent ? theme.white : 'transparent')};
    outline: none;
    &:not([readonly]):focus {
      outline: none;
      +.labelTxt {
          color: ${({ theme, error }) => (error ? theme.error : theme.highlightTextLine)};
      };
      ~.bar:before, ~.bar:after {
          width: 50%;
      };
      ~.highlight {
          -webkit-animation: ${inputHighlighter} 0.3s ease;
          -moz-animation: ${inputHighlighter} 0.3s ease;
          animation: ${inputHighlighter} 0s ease;
      };
    };

    &:disabled {
        opacity: 0.5;
        pointer-events: none;
    }; 
  };

  input[type="password"] {
    padding-right: 40px;
  }

  /* LABEL ======================================= */
  .labelTxt {
    position:absolute;
    top:4px;
    left: 0px;
    font-size:0.85rem;
    color:${({ theme }) => theme.inputLabel};
    font-weight: bold;
  }

  /* ERROR MESSAGE*/
  .errorMessage {
    font-size: 10px;
    color: ${({ theme }) => theme.error};
    position: absolute;
  };

  /* BOTTOM BARS ================================= */
  .bar { 
    position:relative; 
    display:block; 
    width: 100%; 
  };

  .bar:before, .bar:after {
    content:'';
    height:2px; 
    width:0;
    bottom: 0px; 
    position:absolute;
    background: ${({ theme, error }) => (error ? theme.error : theme.highlightTextLine)};
    transition:0.2s ease all; 
    -moz-transition:0.2s ease all; 
    -webkit-transition:0.2s ease all;
  };
  
  .bar:before {
    left:50%;
  };

  .bar:after {
    right:50%; 
  };

  /* HIGHLIGHTER ================================== */
  .highlight {
    position:absolute;
    height:60%; 
    width:100px; 
    top:25%; 
    left:0;
    pointer-events:none;
    opacity:0.5;
  }

  /* active state */
  outline: none;
  .inputTxt:not([readonly]):focus ~ .highlight {
    -webkit-animation: ${inputHighlighter} 0.3s ease;
    -moz-animation: ${inputHighlighter} 0.3s ease;
    animation: ${inputHighlighter} 0s ease;
  }
`;

export const EmojiPickerContainer = styled.div`
  z-index: 1050;
  box-shadow: 0px 4px 4px ${({ theme }) => theme.shadow};
`;

export default StyledInputTextLine;
