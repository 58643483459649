import React, {
  useState, useContext, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';

// contextos
import { useTheme } from 'styled-components';
import { AuthContext } from '../../context/AuthProvider';
import { AlertContext } from '../../context/AlertProvider';
import { ModalContext } from '../../context/ModalProvider';
import useRecaptchaV3 from '../../hooks/useRecaptchaV3';
import useRecaptchaV2 from '../../hooks/useRecaptchaV2';
//
import InputTextLine from '../../juristec-ui/core/InputTextLine';
import Button from '../../juristec-ui/core/Button';
import IconButton from '../../juristec-ui/core/IconButton';
import Loader from '../../juristec-ui/core/Loader';
import MfaLogin from '../../components/ProgressiveModals/MfaLogin';
import FSPolicyContainer from '../../components/ProgressiveModals/Policy/Steps/FirstStep';
import SSTermsContainer from '../../components/ProgressiveModals/Policy/Steps/SecondStep';
import TSTermsContainer from '../../components/ProgressiveModals/Policy/Steps/ThirdStep';

import firebase from '../../utils/firebase';
import Multifactor from '../../utils/multifactor';
import CookiesMessage from '../../components/CookiesMessage';
import uuidv4 from '../../juristec-ui/utils/functions/randomUUID';

import {
  ContainerLoginPage,
  Paper,
  Logo,
  TextOptions,
  SideImage,
  RightSide,
  FormColumn,
  FormRow,
} from './styled/LoginPage.styled';

import img from '../../assets/professionals_charts.png';
import { Visibility, VisibilityOff } from '../../juristec-ui/icons';
import {
  getRefUser, getBatch, getRefSession, getRefUserHistory,
} from '../../utils/firestore';
import handleLoginErrors from '../../utils/firestoreErrors/HandleLoginErrors';
import { compactString } from '../../juristec-ui/utils/functions/formatString';
import { verifyEmail, verifyInput } from '../../juristec-ui/utils/validators/inputTextValidators';
import { CreateInstanceFirstLoginMessages } from '../../options';

import useSessionStorage from '../../hooks/useSessionStorage';

function LoginPage(props) {
  const { currentUser, checkRecaptchaToken } = useContext(AuthContext);

  const { history } = props;
  const theme = useTheme();
  const recaptcha = useRecaptchaV3();
  const v2Verifier = useRecaptchaV2();

  const { setModalConfig, closeModal } = useContext(ModalContext);
  const { setAlertConfig } = useContext(AlertContext);

  const [email, setEmail] = useState({ value: '', error: true, errorMsg: '' });
  const [password, setPassword] = useState({ value: '', error: true, errorMsg: '' });
  const [showPass, setShowPass] = useState(false);
  const [loading, setLoading] = useState(false);

  const [, setSession] = useSessionStorage('session', null);

  useEffect(() => {
    if (currentUser) {
      history.replace('/home');
    }
  }, [currentUser, history]);

  const emailHandle = (val) => {
    const msg = verifyEmail(val, true);
    setEmail({
      value: val,
      error: msg.length !== 0,
      errorMsg: msg,
    });
  };

  const passwordHandle = (val) => {
    const msg = verifyInput(val, true, 6);
    setPassword({
      value: val,
      error: msg.length !== 0,
      errorMsg: msg,
    });
  };

  const successLogin = async (loginStatus) => {
    if (loginStatus) {
      if (!loginStatus.user.emailVerified) {
        setLoading(false);
        setAlertConfig({
          type: 'error',
          text: 'Você precisa confirmar o seu cadastro',
          child: 'Verifique a sua caixa de e-mail para confirmar o seu cadastro antes de efetuar o login',
        });
      }
      const refUser = getRefUser(loginStatus.user.uid);
      if (!loginStatus.user.displayName) {
        const refUserResp = await refUser.get();
        firebase.updateProfile({
          displayName: refUserResp.data()?.name || 'Waiting...',
          photoURL: refUserResp.data()?.photoUrl || null,
        });
      }

      refUser.get().then((doc) => {
        if (doc.exists()) {
          const batch = getBatch();
          const sessionId = uuidv4();
          const refSession = getRefSession(loginStatus.user.uid);
          const lastLogin = new Date(loginStatus.user.metadata.lastSignInTime);
          batch.update(refUser, {
            lastLogin,
            session: sessionId,
          });
          batch.set(refSession, { session: sessionId });
          setSession(sessionId);
          const refUserHistory = getRefUserHistory(loginStatus.user.uid);
          const refUserHistoryDoc = refUserHistory.doc(sessionId);
          batch.set(refUserHistoryDoc, {
            date: new Date(),
            session: sessionId,
          });
          batch.commit();
        }
      });
    }
  };

  const errorAlertModal = (error) => {
    if (error.code === 'auth/internal-error' && error.message?.includes('IP address not allowed.')) {
      setLoading(false);
      setAlertConfig({
        type: 'error',
        text: 'Domínio não autorizado',
      });
    } else {
      const { text, message } = handleLoginErrors(error);
      setLoading(false);
      setAlertConfig({
        type: 'error',
        text,
        child: message,
      });
    }
  };

  const login = async (e) => {
    e.preventDefault();
    if (!email.error || !password.error) {
      try {
        setLoading(true);
        const gToken = await recaptcha.getToken('login');

        const v3Validator = await checkRecaptchaToken(gToken);

        if (v3Validator.error) {
          setLoading(false);
          setAlertConfig({
            type: 'error',
            text: 'Acesso não permitido!',
            child: 'Foi detectado tráfego incomum em sua rede de computadores. Tente novamente mais tarde!',
          });
          return;
        }

        // const loginStatus = await firebase.tokenLogin(validator.res.cuToken);
        firebase.login(email.value, password.value)
          .then((loginStatus) => {
            successLogin(loginStatus);
          })
          .catch((error) => {
            setLoading(false);
            if (error.code === 'auth/multi-factor-auth-required') {
              const mfa = new Multifactor(v2Verifier.getVerifier(), error)
                .success((loginStatus) => {
                  successLogin(loginStatus);
                })
                .error((er) => {
                  errorAlertModal(er);
                });
              /* Modal sendCode function */
              setModalConfig({
                title: 'Autenticação multifator',
                children: (
                  <MfaLogin
                    hide={closeModal}
                    mfa={mfa}
                    setLoading={setLoading}
                  />
                ),
              });
            } else {
              errorAlertModal(error);
            }
          });
        // history.replace('/home');
      } catch (error) {
        errorAlertModal(error);
      }
    } else {
      setAlertConfig({
        type: 'error',
        text: 'Acesso não permitido!',
        child: 'Você deve preencher um e-mail e senha válidos. Verifique e tente novamente!',
      });
      emailHandle(email.value);
      passwordHandle(password.value);
    }
  };

  const accessPrivacyPolicy = () => {
    setModalConfig({
      title: 'Política de Privacidade',
      children: (
        <div style={{ marginBottom: '10px' }}>
          <FSPolicyContainer readOnly />
        </div>
      ),
    });
  };

  const consentTerms = () => {
    setModalConfig({
      title: 'Termo de Consentimento',
      children: (
        <div style={{ marginBottom: '10px' }}>
          <SSTermsContainer readOnly />
        </div>
      ),
    });
  };

  const useTerms = () => {
    setModalConfig({
      title: 'Termos de Uso',
      children: (
        <div style={{ marginBottom: '10px' }}>
          <TSTermsContainer readOnly />
        </div>
      ),
    });
  };

  return (
    <>
      <ContainerLoginPage>
        {(loading || !recaptcha.ready) && <Loader underText={CreateInstanceFirstLoginMessages} />}
        <Paper onSubmit={(e) => login(e)}>
          <FormColumn>
            <div className="gap-fix">
              <Logo alt="logo" src={theme.loginLogo} />
              <span style={{
                color: theme.primary, fontSize: '30px', fontWeight: 'bold', paddingBottom: '10px',
              }}
              >
                Login
              </span>
              <FormRow>
                <InputTextLine
                  label="E-mail*"
                  type="email"
                  value={email.value}
                  errorMessage={email.errorMsg}
                  error={email.errorMsg.length > 0}
                  notTransparent={false}
                  onChange={(e) => emailHandle(compactString(e.target.value))}
                  autoComplete="off"
                />
              </FormRow>
              <FormRow>
                {/* <form onSubmit={login}> */}
                <div style={{ position: 'relative', width: '100%' }}>
                  <InputTextLine
                    label="Senha*"
                    type={!showPass ? 'password' : 'text'}
                    value={password.value}
                    errorMessage={password.errorMsg}
                    error={password.errorMsg.length > 0}
                    notTransparent={false}
                    onChange={(e) => passwordHandle(compactString(e.target.value))}
                    style={{ paddingRight: '35px' }}
                  />
                  <IconButton
                    style={{
                      position: 'absolute', bottom: 4, right: 2, boxShadow: 'none', padding: 2,
                    }}
                    type="button"
                    variant="pattern"
                    iconColor="black"
                    onMouseDown={() => setShowPass(!showPass)}
                  >
                    {showPass ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </div>
              </FormRow>
              <FormRow>
                <Button
                  id="sign-in-button"
                  loading={loading ? 1 : 0}
                  type="submit"
                  onClick={login}
                  textTransform="uppercase"
                  color={theme.primary}
                  size="large"
                  style={{ width: '100%', margin: '.5rem 0 0 0' }}
                >
                  Login
                </Button>
              </FormRow>
              {/* REGISTRO COMENTADO POR ENQUANTO (11/07/2022) - PROBLEMAS COM O LOG */}
              {/* {(process.env.REACT_APP_FIREBASE_PROJECT_LABEL !== 'legalone-analytics') && (
                <TextOptions>
                  Ainda não tem uma conta?
                  {' '}
                  <Link
                    to="/register"
                    style={{
                      cursor: 'pointer',
                      color: theme.primary,
                      fontWeight: 'bold',
                      textDecorationLine: 'none',
                    }}
                    // onClick={() => history.push('/register')}
                  >
                    Registre-se
                  </Link>
                </TextOptions>
              )} */}
            </div>
            <div>
              <TextOptions>
                Esqueceu a senha?
                {' '}
                <Link
                  to="/reset_password"
                >
                  Trocar senha
                </Link>
              </TextOptions>
            </div>
            <div style={{ marginTop: 'auto' }}>
              <TextOptions>
                Ao acessar você aceita o
                {' '}
                <span onClick={consentTerms}>
                  Termo de Consentimento
                </span>
                , os
                {' '}
                <span onClick={useTerms}>
                  Termos de Uso
                </span>
                {' '}
                e a
                {' '}
                <span onClick={accessPrivacyPolicy}>
                  Política de Privacidade
                </span>
                {' '}
                do Juristec+.
              </TextOptions>
              <TextOptions>
                Este site é protegido pelo reCAPTCHA e a
                {' '}
                <a
                  href="https://policies.google.com/privacy"
                  target="_blank"
                  rel="noreferrer"
                >
                  Política de Privacidade
                </a>
                {' '}
                e os
                {' '}
                <a
                  href="https://policies.google.com/terms"
                  target="_blank"
                  rel="noreferrer"
                >
                  Termos de Serviço
                </a>
                {' '}
                do Google se aplicam.
              </TextOptions>
            </div>
          </FormColumn>
        </Paper>
        <RightSide>
          <SideImage alt="professionals_charts" src={img} />
        </RightSide>
      </ContainerLoginPage>
      <CookiesMessage />
    </>
  );
}

LoginPage.propTypes = {
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default withRouter(LoginPage);
