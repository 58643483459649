const fontOptions = [
  { value: 'arial', label: 'Arimo', id: 'arial' },
  { value: 'bookman', label: 'Libre Baskerville', id: 'bookman' },
  { value: 'courier', label: 'Courier Prime', id: 'Courier' },
  { value: 'cursive', label: 'Kalam', id: 'cursive' },
  { value: 'fantasy', label: 'Oswald', id: 'fantasy' },
  { value: 'helvetica', label: 'Lato', id: 'helvetica' },
  { value: 'lucida console', label: 'Old Standard TT', id: 'lucida console' },
  { value: 'monospace', label: 'Fira Mono', id: 'monospace' },
  { value: 'open sans', label: 'Open Sans', id: 'open sans' },
  { value: 'roboto', label: 'Roboto', id: 'roboto' },
  { value: 'rubik', label: 'Rubik', id: 'rubik' },
  { value: 'sans-serif', label: 'Nunito Sans', id: 'sans-serif' },
  { value: 'serif', label: 'Playfair Display', id: 'Serif' },
  { value: 'tahoma', label: 'Signika', id: 'tahoma' },
  { value: 'times new roman', label: 'Tinos', id: 'times new roman' },
  { value: 'verdana', label: 'Jost', id: 'verdana' },
];

export default fontOptions;
