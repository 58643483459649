import React from 'react';
import PropTypes from 'prop-types';
import Picker, { SKIN_TONE_NEUTRAL } from 'emoji-picker-react';
import ContainerPicker from './styled/EmojiPicker.styled';

/** Emoji picker wrapper with required settings */
const EmojiPicker = ({ onEmojiClick }) => (
  <ContainerPicker>
    <Picker
      onEmojiClick={onEmojiClick}
      native
      disableAutoFocus
      skinTone={SKIN_TONE_NEUTRAL}
      groupNames={{
        smileys_people: 'Faces',
        animals_nature: 'Natureza',
        food_drink: 'Comidas e Bebidas',
        travel_places: 'Viagens',
        activities: 'Atividades',
        objects: 'Objetos',
        symbols: 'Símbolos',
        flags: 'Bandeiras',
        recently_used: 'Recentes',
      }}
    />
  </ContainerPicker>
);

EmojiPicker.propTypes = {
  onEmojiClick: PropTypes.func.isRequired,
};

export default EmojiPicker;
