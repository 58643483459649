import React, {
  useReducer, useMemo, useCallback, createContext, useContext,
} from 'react';
import PropTypes from 'prop-types';
//
import { ip } from '../../utils/functions/urls';
//
import { error as errorLabel } from '../../label';
import getRequestMeta from '../../utils/functions/generateMeta';
import firestoreErrors from '../../utils/firestoreErrors';
//
import { getRefTemplateList, getRefUser } from '../../utils/firestore';
//
import { AuthContext } from '../AuthProvider';
import firebase from '../../utils/firebase';

const legacyTypes = {
  float64: 'float',
  int64: 'float',
  'datetime64[ns]': 'date',
  object: 'abc',
  category: 'abc',
  get(k) {
    return this[k] || k;
  },
};

const getToken = async (u) => u.getIdToken();

const initialState = {
  isLoading: false,
  templates: [],
};

const reduce = (state, action) => {
  switch (action.type) {
    case 'getTemplates': {
      return {
        ...state,
        isLoading: false,
        templates: action.templates,
      };
    }

    case 'applyTemplate': {
      return {
        ...state,
        isLoading: false,
      };
    }

    case 'setLoading': {
      return {
        ...state,
        isLoading: action.isLoading,
      };
    }

    default:
      return state;
  }
};

export const TemplateContext = createContext(null);

const TemplateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reduce, initialState);
  const setLoading = (l = true, msg = '') => dispatch({ type: 'setLoading', isLoading: l, msg });

  const { currentUser } = useContext(AuthContext);

  const getTemplates = useCallback(async () => {
    try {
      const templateRef = await getRefTemplateList().get();
      let templates = templateRef.docs.map((d) => {
        const dt = { ...d.data() };
        const types = Object.keys(dt.columnsRequired).reduce((aux, key) => {
          const col = dt.columnsRequired[key];
          const typeLabel = legacyTypes.get(col.type);
          if (aux[typeLabel]) aux[typeLabel].push(key);
          else aux[typeLabel] = [key];
          return aux;
        }, {});
        return {
          ...dt,
          columnsType: types,
          id: d.id,
        };
      });
      templates = templates.sort((a, b) => a.name.localeCompare(b.name, 'pt-BR'));
      return { error: false, msg: '', templates };
    } catch (er) {
      return {
        error: true,
        msg: firestoreErrors(er.code),
        raw: `Erro do sistema: ${er.toString()}`,
        templates: [],
      };
    }
  }, []);

  const applyTemplate = useCallback(async (
    dashTagetId, dashboardName, database, templateMap, filters, group, styles = null,
  ) => {
    try {
      const token = await getToken(currentUser);
      const body = {
        id: dashTagetId,
        dashboardName,
        file_id: database,
        templateMap,
        filters,
        group,
      };
      if (styles) body.cardStyles = styles;
      const opt = {
        ...await getRequestMeta(token, 'POST', 'JSON'),
        body: JSON.stringify(body),
      };
      const res = await fetch(`${ip}/kpis/template`, opt);
      const raw = await res.json();
      if (res.status !== 200) {
        return { error: true, msg: errorLabel.fetchGeneric, raw: raw.error };
      }
      // Atualizando o número de dashboards do usuário após a criação do template
      const refUser = getRefUser(currentUser.uid);
      refUser.update({ nr_dashboards: firebase.increment(1) });
      return { error: false, msg: null, res: raw };
    } catch (er) {
      console.log(er);
      return { error: true, msg: errorLabel.fetchGeneric, raw: `Erro do sistema: ${er.toString()}` };
    }
  }, [currentUser]);

  const middleware = useCallback(async (action) => {
    switch (action.type) {
      case 'getTemplates': {
        setLoading();
        const res = await getTemplates();

        dispatch({ type: 'getTemplates', templates: res.templates });
        return res;
      }

      case 'applyTemplate': {
        const {
          dashTagetId, dashboardName, database, templateMap, filters, group, styles,
        } = action;
        setLoading();
        const res = await applyTemplate(
          dashTagetId,
          dashboardName,
          database,
          templateMap,
          filters,
          group,
          styles,
        );
        dispatch({ type: 'applyTemplate' });
        return res;
      }

      default:
        return dispatch(action);
    }
  }, [getTemplates, applyTemplate]);

  const templateAPI = useMemo(() => ({
    getTemplates: () => middleware({ type: 'getTemplates' }),
    applyTemplate: (
      dashTagetId, dashboardName, database, templateMap, filters, group, styles,
    ) => middleware({
      type: 'applyTemplate', dashTagetId, dashboardName, database, templateMap, filters, group, styles,
    }),
  }), [middleware]);

  return (
    <TemplateContext.Provider value={{ state, templateAPI }}>
      {children}
    </TemplateContext.Provider>
  );
};

TemplateProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.element,
  ]),
};

TemplateProvider.defaultProps = {
  children: null,
};

export default TemplateProvider;
