import React from 'react';

const ChartBar = ({ primary = '#4711B2', secondary = '#FF9626' }) => (
  <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M58.3963 60C44.7217 60 3.6994 60 3.6994 60C1.65959 60 0 58.4208 0 56.4799C0 56.4799 0 15.4725 0 1.60557C0 -0.53519 4.43912 -0.53519 4.43912 1.60557C4.43912 15.2967 4.43912 55.7761 4.43912 55.7761C4.43912 55.7761 44.907 55.7761 58.3962 55.7761C60.5346 55.7761 60.5346 60 58.3963 60Z" fill={primary} />
    <path d="M55.9588 53.1452C56.5692 53.1452 57.0687 52.6701 57.0687 52.0893V14.5205C57.0687 13.9397 56.5692 13.4644 55.9588 13.4644H41.921C41.3107 13.4644 40.8111 13.9397 40.8111 14.5205V52.0893C40.8111 52.6701 41.3107 53.1452 41.921 53.1452H55.9588Z" fill={primary} />
    <path d="M37.0118 53.1452C37.6222 53.1452 38.1219 52.6701 38.1219 52.0893V6.07279C38.1219 5.49201 37.6222 5.01688 37.0118 5.01688H25.5441C24.9337 5.01688 24.4342 5.49201 24.4342 6.07279V52.0896C24.4342 52.6704 24.9337 53.1455 25.5441 53.1455L37.0118 53.1452Z" fill={secondary} />
    <path d="M20.6349 53.1452C21.2453 53.1452 21.7447 52.67 21.7447 52.0893V24.9041C21.7447 24.3234 21.2453 23.8481 20.6349 23.8481H8.14935C7.53897 23.8481 7.03945 24.3234 7.03945 24.9041V52.0896C7.03945 52.6703 7.53897 53.1455 8.14935 53.1455L20.6349 53.1452Z" fill={primary} />
  </svg>
);

export default ChartBar;
