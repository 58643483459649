import styled from 'styled-components';
import { devices } from '../../../../juristec-ui/core/breakpoints';

export const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: .5rem 5%;
  height: 3.7rem;
  align-items: center;
  box-sizing: border-box;
  & .left {
    width: 100%;
    flex-shrink: 2;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    & span {
      margin-left: 15px;
      font-size: 18px;
      font-weight: bold;
    }
  }
  & .right {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    max-width: 330px;
    flex-grow: 5;
    flex-shrink: 1;
  }
  @media ${devices.tablet} {
    padding: 10px 20px;
    & .right {
      max-width: 270px;
    }
  }
  @media ${devices.phoneS} {
    /* ${('10px', 'row')} */
    & .right {
      width: auto;
    }
  }
`;

export const ItensContainer = styled.div`
  display: flex;
  flex-direction: row;
  & > * + * {
    margin-left: 10px;
  }
  align-items: center;
  height: 100%;
`;
