import React from 'react';

const ChartScatterPlot = ({ primary = '#4711B2', secondary = '#FF9626' }) => (
  <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M58.3963 60C44.7217 60 3.6994 60 3.6994 60C1.65959 60 0 58.4208 0 56.4799C0 56.4799 0 15.4725 0 1.60557C0 -0.53519 4.43912 -0.53519 4.43912 1.60557C4.43912 15.2967 4.43912 55.7761 4.43912 55.7761C4.43912 55.7761 44.907 55.7761 58.3962 55.7761C60.5346 55.7761 60.5346 60 58.3963 60Z" fill={primary} />
    <path d="M17.5 29.5C17.5 31.7091 15.7091 33.5 13.5 33.5C11.2909 33.5 9.5 31.7091 9.5 29.5C9.5 27.2909 11.2909 25.5 13.5 25.5C15.7091 25.5 17.5 27.2909 17.5 29.5Z" fill={secondary} />
    <path d="M25.5 9.5C25.5 11.7091 23.7091 13.5 21.5 13.5C19.2909 13.5 17.5 11.7091 17.5 9.5C17.5 7.29086 19.2909 5.5 21.5 5.5C23.7091 5.5 25.5 7.29086 25.5 9.5Z" fill={primary} />
    <path d="M44.5 17.5C44.5 19.7091 42.7091 21.5 40.5 21.5C38.2909 21.5 36.5 19.7091 36.5 17.5C36.5 15.2909 38.2909 13.5 40.5 13.5C42.7091 13.5 44.5 15.2909 44.5 17.5Z" fill={secondary} />
    <path d="M58.5 12.5C58.5 14.7091 56.7091 16.5 54.5 16.5C52.2909 16.5 50.5 14.7091 50.5 12.5C50.5 10.2909 52.2909 8.5 54.5 8.5C56.7091 8.5 58.5 10.2909 58.5 12.5Z" fill={primary} />
    <path d="M25.5 9.5C25.5 11.7091 23.7091 13.5 21.5 13.5C19.2909 13.5 17.5 11.7091 17.5 9.5C17.5 7.29086 19.2909 5.5 21.5 5.5C23.7091 5.5 25.5 7.29086 25.5 9.5Z" fill={primary} />
    <path d="M34.5 25.5C34.5 27.7091 32.7091 29.5 30.5 29.5C28.2909 29.5 26.5 27.7091 26.5 25.5C26.5 23.2909 28.2909 21.5 30.5 21.5C32.7091 21.5 34.5 23.2909 34.5 25.5Z" fill={primary} />
    <path d="M31.5 38.5C31.5 40.7091 29.7091 42.5 27.5 42.5C25.2909 42.5 23.5 40.7091 23.5 38.5C23.5 36.2909 25.2909 34.5 27.5 34.5C29.7091 34.5 31.5 36.2909 31.5 38.5Z" fill={secondary} />
    <path d="M19 49C19 51.2091 17.2091 53 15 53C12.7909 53 11 51.2091 11 49C11 46.7909 12.7909 45 15 45C17.2091 45 19 46.7909 19 49Z" fill={primary} />
    <path d="M47.5 43.5C47.5 45.7091 45.7091 47.5 43.5 47.5C41.2909 47.5 39.5 45.7091 39.5 43.5C39.5 41.2909 41.2909 39.5 43.5 39.5C45.7091 39.5 47.5 41.2909 47.5 43.5Z" fill={primary} />
    <path d="M55.5 29C55.5 31.2091 53.7091 33 51.5 33C49.2909 33 47.5 31.2091 47.5 29C47.5 26.7909 49.2909 25 51.5 25C53.7091 25 55.5 26.7909 55.5 29Z" fill={secondary} />
  </svg>
);

export default ChartScatterPlot;
