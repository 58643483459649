export default {
  TitleControl: '',
  ShowTitleControl: { checked: false },
  AxisGlobalRadialFontColor: '#222',
  ColorTheme: { scheme: 'category10' },
  Animate: true,
  StyleTitleControl: {
    fontSize: 20,
    color: '#000000',
    fontFamily: 'arial',
    bold: false,
    italic: false,
    underline: false,
  },
  type: 'RadialBar',
};
