import styled, { css } from 'styled-components';
import { devices } from '../../../juristec-ui/core/breakpoints';

const colorBy = (key) => {
  switch (key) {
    case 'easy':
    case 'abc':
      return 'info';
    case 'medium':
    case 'float':
      return 'success';
    case 'advanced':
    case 'date':
      return 'warning';
    case 'expert':
      return 'error';
    default:
      return 'secondary';
  }
};

export const MainContainer = styled.div`
  padding: 0 5%;
  @media ${devices.tablet} {
    padding: 0 20px;
  }
  & .tooltip {
    cursor: default;
  }
`;

export const GridOverFlow = styled.div`
  max-height: calc(100vh - 136px);
  overflow-y: auto;
  overflow-x: auto;
`;

export const GridCards = styled.div`
  padding: 5px;
  padding-bottom: 65px;
  margin: 0 auto;
  display: grid;
  gap: 0;
  grid-template-columns: repeat(1, 1fr);
`;

export const TemplateCard = styled.div`
  ${({ theme }) => css`
    border-color: ${theme.grey};
  `};
  position: relative;
  box-sizing: border-box;
  border-style: solid;
  border-width: .1rem 0;
  padding: 1.5rem 0;
  display: flex;
  flex-direction: column;
  &:first-of-type {
    border-top-width: 0;
    padding-top: 0;
  }
  &:last-of-type {
    border-bottom-width: 0;
    padding-bottom: 0;
  }
  & h2 {
    margin: 0 0 .2rem 0;
    text-align: center;
  }
  & > div.content {
    display: flex;
    flex-direction: row;
    height: 350px;
    & > div:first-child {
      margin: 0 1rem 0 0;
    }
    & .card-tooltip {
      position: absolute;
      z-index: 1;
      bottom: .2rem;
      right: .2rem;
      width: min-content;
      height: min-content;
    }
  }
  &:nth-child(even) > div.content {
    flex-direction: row-reverse;
    & > div:first-child {
      margin: 0 0 0 1rem;
    }
    & .card-tooltip {
      left: .2rem;
    }
  }
  overflow: hidden;
  width: 100%;
  height: 100%;
  @media ${devices.tablet} {
    & > div.content {
      height: auto;
      flex-direction: column;
      & > div:first-child {
        margin: 0;
      }
    }
    &:nth-child(even) > div.content {
      flex-direction: column;
      & > div:first-child {
        margin: 0;
      }
    }
  }
`;

export const CardImageContainer = styled.div`
  aspect-ratio: 2/1;
  display: flex;
  height: 100%;
  width: 50%;
  perspective: 1000px;
  position: relative;
  @media ${devices.tablet} {
    width: 100%;
  }
  & .inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
  }
  ${({ setRotate }) => setRotate && css`
    & .inner {
      transform: rotateY(180deg);
    }
  `}
`;

export const CardImage = styled.div`
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  ${({ theme }) => css`
    background-image: url(${theme.loginLogo});
    background-color: ${theme.containerOdd};
  `}
`;

export const CardImageBack = styled.div`
  position: absolute;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  overflow: auto;
  width: 100%;
  height: 100%;
  transform: rotateY(180deg);
  background-color: ${({ theme }) => theme.containerOdd};
  padding-left: .4rem;
  box-sizing: border-box;
  & h3 {
    text-align: center;
  }
  & h4 {
    text-align: left;
    color: ${({ theme }) => theme.secondary};
    margin: 0;
  }
  & h3 {
    margin: 0 0 .2rem 0;
  }
  & .subtitle {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin: 0 0 .2rem 0;
    box-sizing: border-box;
    & > div {
      background-color: ${({ theme }) => theme.secondary};
      width: 25px;
      height: 22px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: .5rem;
      margin-right: .4rem;
      box-sizing: border-box;
      & svg {
        fill: ${({ theme }) => theme.white};
        height: 20px;
        width: 20px;
      }
    }
  }
`;

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  padding: 0 .5rem;
  @media ${devices.tablet} {
    width: auto;
  }
  & h2, h3, h4, h5 {
    margin: 0;
    text-align: left;
  }
  & > * + * {
    margin: .2rem 0 0 0;
  }
  & > span {
    font-size: 14px;
  }
  & h2 {
    margin: 0;
    text-align: center;
  }
`;

export const ColumnsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: .2rem;
  & span {
    font-size: 13px;
  }
`;

export const TagsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: .4rem;
  & span {
    color: ${({ theme }) => theme.grey};
    font-size: 12px;
    text-transform: uppercase;
    font-style: italic;
  }
`;

export const LineRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  & > * + * {
    margin: 0 0 0 .4rem;
  }
`;

export const DifficultLabel = styled.span`
  text-transform: uppercase;
  font-weight: bold;
  color: ${({ theme, colorKey }) => theme[colorBy(colorKey)]};
`;

export const BtnIconWrapper = styled.div`
  width: 18px;
  height: 18px;
  border-radius: 50%;
  ${({ theme }) => css`
    background-color: ${theme.info};
    & svg {
      fill: ${theme.white};
    }
  `}
`;

export const OptionsContainer = styled.div`
  & svg {
    height: 20px;
    width: 20px;
    fill: ${({ theme }) => theme.grey};
  }
`;
