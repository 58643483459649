import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';
import ContractedPlan from '../ContractedPlan';
import BarChart from '../../juristec-ui/core/BarChart';
import PieChart from '../../juristec-ui/core/PieChart';

import { printSize } from '../../juristec-ui/utils/functions/lab';

import {
  InstanceInfoContainer,
  ScrollContainer,
  InstanceCardsRow,
  ChartCard,
  OverflowContainer,
} from './styled/InstanceView.styled';

const InstanceView = ({
  users,
  memoryInfo,
  adminsInfo,
  scientistsInfo,
  guestsInfo,
  dashboardsInfo,
  templatesInfo,
  storiesInfo,
}) => {
  const theme = useTheme();
  const usersChart = [
    { label: 'Proprietário', value: adminsInfo.used },
    { label: 'Cientista', value: scientistsInfo.used },
    { label: 'Convidado', value: guestsInfo.used },
  ];

  const usersColors = {
    Proprietário: '#286EE6',
    Cientista: '#11AA62',
    Convidado: '#FF0000',
  };

  const memoryChart = () => {
    const memoryDash = dashboardsInfo.used * 10;
    const memoryFiles = memoryInfo.used - memoryDash;
    const memoryFree = memoryInfo.max - memoryDash - memoryFiles;
    return [
      { label: 'Dashboards', value: memoryDash.toFixed(2) },
      { label: 'Arquivos', value: memoryFiles.toFixed(2) },
      { label: 'Livre', value: memoryFree.toFixed(2) },
    ];
  };

  const memoryColors = {
    Dashboards: '#FF9626',
    Arquivos: '#4711B2',
    Livre: '#d3d1d1',
  };

  const chartStyle = {
    sliceThickness: 2,
    margin: 5,
    fontSize: 20,
    fontColor: theme.color,
  };

  const legendStyle = {
    size: 15,
    spacing: 35,
    position: 'bottom',
    shape: 'square',
    fontColor: theme.color,
  };

  return (
    <ScrollContainer>
      <InstanceInfoContainer>
        <div className="column-small">
          <ContractedPlan
            memorySize={printSize(memoryInfo.max * 1000 ** 2, 10)}
            adminsInfo={adminsInfo}
            scientistsInfo={scientistsInfo}
            guestsInfo={guestsInfo}
            dashboardsInfo={dashboardsInfo.max}
            templatesInfo={templatesInfo}
            storiesInfo={storiesInfo}
          />
        </div>
        <div className="column-large">
          <div style={{ width: '100%' }}>
            <InstanceCardsRow>
              <ChartCard>
                <h3>Usuários</h3>
                <div style={{ width: '100%', height: '100%' }}>
                  <PieChart
                    data={usersChart}
                    result={[{
                      label: 'Usuários',
                      value:
                        parseInt(adminsInfo.used, 10)
                        + parseInt(scientistsInfo.used, 10)
                        + parseInt(guestsInfo.used, 10),
                    }]}
                    dataColors={usersColors}
                    chartStyle={chartStyle}
                    legendStyle={legendStyle}
                  />
                </div>
                {/* <div>
                  <Button
                    color="primary"
                    variant="outlined"
                    size="large"
                    style={{ marginTop: '.5rem' }}
                    onClick={() => solicitationModal('users')}
                  >
                    Adicionar
                  </Button>
                </div> */}
              </ChartCard>
              <ChartCard>
                <h3>Espaço em Disco</h3>
                <div style={{ width: '100%', height: '100%' }}>
                  <PieChart
                    data={memoryChart()}
                    result={[{
                      label: 'Utilizado',
                      value: `${((memoryInfo.used * 100) / memoryInfo.max).toFixed()}%`,
                    }]}
                    dataColors={memoryColors}
                    chartStyle={chartStyle}
                    legendStyle={legendStyle}
                  />
                </div>
                {/* <div>
                  <Button
                    color="primary"
                    variant="outlined"
                    size="large"
                    style={{ marginTop: '.5rem' }}
                    onClick={() => solicitationModal('memory')}
                  >
                    Adicionar
                  </Button>
                </div> */}
              </ChartCard>
            </InstanceCardsRow>
            <InstanceCardsRow>
              <ChartCard>
                <h3>Dashboards por usuário</h3>
                <OverflowContainer>
                  <BarChart
                    data={users.map((u, i) => ({
                      index: i,
                      label: u.name,
                      value: u.nr_dashboards,
                    }))}
                  />
                </OverflowContainer>
              </ChartCard>
            </InstanceCardsRow>
          </div>
        </div>
      </InstanceInfoContainer>
    </ScrollContainer>
  );
};

InstanceView.propTypes = {
  /**
   * Array with the data of the users
   */
  users: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  /**
   * Memory quantity info of the instance
   */
  memoryInfo: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.shape({
      used: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      max: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
    }),
  ]),
  /**
   * Admins quantity info of the instance
   */
  adminsInfo: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.shape({
      used: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      max: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
    }),
  ]),
  /**
   * Scientists quantity info of the instance
   */
  scientistsInfo: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.shape({
      used: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      max: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
    }),
  ]),
  /**
   * Guests quantity info of the instance
   */
  guestsInfo: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.shape({
      used: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      max: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
    }),
  ]),
  /**
   * Dashboards quantity info of the instance
   */
  dashboardsInfo: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.shape({
      used: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      max: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
    }),
  ]),
  /**
   * Templates quantity info of the instance
   */
  templatesInfo: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.shape({
      used: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      max: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
    }),
  ]),
  /**
   * Stories quantity info of the instance
   */
  storiesInfo: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.shape({
      used: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      max: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
    }),
  ]),
  /**
   * A function to request the addition of more users or memory
   */
  solicitationModal: PropTypes.func,
};

InstanceView.defaultProps = {
  users: [],
  memoryInfo: '-',
  adminsInfo: '-',
  scientistsInfo: '-',
  guestsInfo: '-',
  dashboardsInfo: '-',
  templatesInfo: '-',
  storiesInfo: '-',
  solicitationModal: () => {},
};

export default InstanceView;
