import React, { useState } from 'react';
import PropTypes from 'prop-types';
import InputTextLine from '../../../juristec-ui/core/InputTextLine';
import Select from '../../../juristec-ui/core/Select';
import Button from '../../../juristec-ui/core/Button';
import ActionsGroup from '../../../juristec-ui/core/ActionsGroup';

import { verifyFileName } from '../../../juristec-ui/utils/validators/inputTextValidators';

import {
  AddUserContainer,
  ItemContainer,
} from './styled/AddBenner.styled';

/**
 * A modal to create new antecipei reports to the instance
 */
const AddBenner = ({
  users,
  hide,
  submitData,
}) => {
  const [name, setName] = useState({ value: '', error: true, errorMsg: '' });
  const [selectedEmail, setSelectedEmail] = useState({});
  const [url, setUrl] = useState({ value: '', error: true, errorMsg: '' });
  const [username, setUsername] = useState({ value: '', error: true, errorMsg: '' });
  const [password, setPassword] = useState({ value: '', error: true, errorMsg: '' });

  const handleName = (value) => {
    const val = value.split(' ').filter((v) => v).join('');
    const msg = verifyFileName(val);
    setName({
      value: val,
      error: msg.length !== 0,
      errorMsg: msg,
    });
  };

  const handleUrl = (value) => {
    const val = value.split(' ').filter((v) => v).join('');
    const msg = value.length === 0 ? 'Este campo não pode estar vazio' : '';
    setUrl({
      value: val,
      error: msg.length !== 0,
      errorMsg: msg,
    });
  };

  const handleUsername = (value) => {
    const val = value.split(' ').filter((v) => v).join('');
    const msg = value.length === 0 ? 'Este campo não pode estar vazio' : '';
    setUsername({
      value: val,
      error: msg.length !== 0,
      errorMsg: msg,
    });
  };

  const handlePassword = (value) => {
    const val = value.split(' ').filter((v) => v).join('');
    const msg = value.length === 0 ? 'Este campo não pode estar vazio' : '';
    setPassword({
      value: val,
      error: msg.length !== 0,
      errorMsg: msg,
    });
  };

  const handleSubmit = () => {
    submitData(
      name.value,
      selectedEmail.id,
      url.value,
      username.value,
      password.value,
    );
  };

  return (
    <>
      <AddUserContainer>
        <ItemContainer>
          <InputTextLine
            label="Nome do relatório"
            placeholder="Benner"
            errorMessage={name.errorMsg}
            error={name.errorMsg.length > 0}
            onChange={(e) => handleName(e.target.value)}
            value={name.value}
          />
        </ItemContainer>
        <ItemContainer>
          <Select
            selectLabel="E-mail do usuário"
            options={users}
            fullWidth
            placement="start"
            value={selectedEmail}
            onChange={(val) => setSelectedEmail(val)}
            atModal
            alphabeticalOrder
          />
        </ItemContainer>
        <ItemContainer>
          <InputTextLine
            label="URL"
            placeholder="Insira a URL correspondente"
            errorMessage={url.errorMsg}
            error={url.errorMsg.length > 0}
            onChange={(e) => handleUrl(e.target.value)}
            value={url.value}
          />
        </ItemContainer>
        <ItemContainer>
          <InputTextLine
            label="Username (Usuário / Instância no Benner)"
            placeholder=""
            errorMessage={username.errorMsg}
            error={username.errorMsg.length > 0}
            onChange={(e) => handleUsername(e.target.value)}
            value={username.value}
          />
        </ItemContainer>
        <ItemContainer>
          <InputTextLine
            label="Password (Usuário / Instância no Benner)"
            placeholder=""
            errorMessage={password.errorMsg}
            error={password.errorMsg.length > 0}
            onChange={(e) => handlePassword(e.target.value)}
            value={password.value}
          />
        </ItemContainer>
      </AddUserContainer>
      <ActionsGroup>
        <Button
          style={{ margin: '5px' }}
          onClick={hide}
          variant="outlined"
          size="large"
        >
          Cancelar
        </Button>
        <Button
          style={{ margin: '5px' }}
          onClick={handleSubmit}
          size="large"
          disabled={name.error || url.error || !selectedEmail.value || username.error || password.error}
        >
          Adicionar
        </Button>
      </ActionsGroup>
    </>
  );
};

AddBenner.propTypes = {
  /**
   * array of users admin and scientists
   */
  users: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  /**
   * Function to close the modal
   */
  hide: PropTypes.func,
  submitData: PropTypes.func,
};

AddBenner.defaultProps = {
  hide: () => {},
  submitData: () => {},
};

export default AddBenner;
