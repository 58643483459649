import styled, { css } from 'styled-components';

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

export const FormContainer = styled(Column)`
  height: 55vh;
  width: 50vw;
  padding: 0 1rem 0 .5rem;
`;

export const MainContainer = styled(Row)``;

export const InputContainer = styled(Column)`
  background-color: ${({ theme }) => theme.container};
  padding: 1rem;
  padding-top: .5rem;
  border-radius: 5px;
  width: 100%;
  box-sizing: border-box;
  height: 100%;
  & > span {
    font-size: 14px;
  }
  & > * {
    margin-bottom: 15px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const KpiWrapper = styled.div`
  display: flex;
  position: relative;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  overflow: hidden;
  & > svg {
    fill: ${({ theme }) => theme.grey};
    height: 50%;
    width: 50%;
  }
`;

export const TabGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: 55vh;
  overflow-y: auto;
  width: 22vw;
  & button {
    margin-bottom: 5px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  & > p {
    font-size: 12px;
    margin: 10px 0 0 0;
  }
`;

export const TabButton = styled.button`
  background-color: ${({ theme }) => theme.container};
  width: 100%;
  height: 45px;
  font-weight: bold;
  border: none;
  box-sizing: border-box;
  color: ${({ theme }) => theme.primary};
  display: flex;
  font-size: 16px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 5px;
  & svg {
    fill: ${({ theme }) => theme.primary};
    height: 24px;
    width: 24px;
    margin-right: 5px;
  }
  & span {
    margin: auto;
  }
  & .icon-detail {
    transform: rotate(270deg);
    height: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    & svg {
      fill: ${({ theme }) => theme.container};
      margin: 0;
    }
  }
  ${({ theme, active }) => (active ? css`
    background-color: ${theme.secondary};
    color: ${theme.white};
    & svg {
      fill: ${theme.white};
    }
    & .icon-detail svg {
      fill: ${theme.white};
    }
  ` : css`
    &:hover {
      background-color: ${theme.selectHover};
      & .icon-detail svg {
        fill: ${theme.selectHover};
      }
    }
  `)}
`;
