import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { HexColorPicker } from 'react-colorful';
import Popover from '../Popover';
import Button from '../Button';
import IconButton from '../IconButton';

import { MainContainer, CustomHexInput, InputWrapper } from './styled/ColorPicker.styled';

const ColorPicker = ({
  color,
  children,
  style,
  onChange,
  isIconButton,
  size,
  shape,
  variant,
  atModal,
  tourContext = { tourOpen: false },
  className,
  direction,
  staticButtonColor,
}) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (open && tourContext.tourOpen) tourContext.nextStep();
  }, [open]);

  if (tourContext.tourOpen) tourContext.refreshTour();

  return (
    <Popover closePopover={() => setOpen(false)} open={open} className={className}>
      <Popover.Action>
        {isIconButton ? (
          <IconButton
            variant={variant}
            shape={shape}
            color={staticButtonColor || color}
            style={style}
            size={size}
            onClick={() => setOpen(!open)}
            useDynamicColor={!staticButtonColor}
          >
            {children}
          </IconButton>
        ) : (
          <Button
            variant={variant}
            shape={shape}
            color={staticButtonColor || color}
            style={style}
            size={size}
            onClick={() => setOpen(!open)}
            useDynamicColor={!staticButtonColor}
          >
            {children}
          </Button>
        )}
      </Popover.Action>
      <Popover.Content stayOpened style={{ borderRadius: '8px', zIndex: atModal ? '1050' : '100' }} direction={direction}>
        <MainContainer>
          <HexColorPicker color={color} onChange={onChange} />
          <div style={{
            padding: '5px 0 7px', display: 'flex', flexDirection: 'row', alignItems: 'center',
          }}
          >
            <span style={{ fontSize: '16px', fontWeight: 'bold' }}>#</span>
            <InputWrapper>
              <CustomHexInput color={color} onChange={onChange} />
              <span className="bar" />
            </InputWrapper>
          </div>
        </MainContainer>
      </Popover.Content>
    </Popover>
  );
};

ColorPicker.propTypes = {
  color: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
    PropTypes.number,
  ]),
  style: PropTypes.objectOf(PropTypes.any),
  onChange: PropTypes.func,
  isIconButton: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  variant: PropTypes.oneOf(['contained', 'outlined', 'pattern']),
  shape: PropTypes.oneOf(['square', 'rounded', 'circle']),
  atModal: PropTypes.bool,
  className: PropTypes.string,
  direction: PropTypes.oneOf([
    'left', 'left-start', 'left-end',
    'right', 'right-start', 'right-end',
    'bottom', 'bottom-start', 'bottom-end',
    'top', 'top-start', 'top-end',
  ]),
  staticButtonColor: PropTypes.string,

  tourContext: PropTypes.shape({
    tourOpen: PropTypes.bool,
    nextStep: PropTypes.func,
    remakeTourList: PropTypes.func,
  }),
};

ColorPicker.defaultProps = {
  color: '#000000',
  children: '',
  style: {},
  onChange: () => {},
  isIconButton: false,
  size: 'medium',
  variant: 'contained',
  shape: 'rounded',
  atModal: false,
  className: '',
  direction: 'bottom-end',
  staticButtonColor: '',

  tourContext: { tourOpen: false, nextStep: () => {}, remakeTourList: () => {} },
};

export default ColorPicker;
