import styled, { css, keyframes } from 'styled-components';

const calcTime = (textSize, minSize) => {
  return Math.max((textSize - minSize) / 100, 0);
};

const slider = keyframes`
  from {
    right: 100%;
    transform: translateX(100%);
  }
  to {
    right: 0;
    transform: translateX(0);
  }
`;

const Container = styled.div`
  overflow: hidden;
  display: block;
  position: relative;
  ${({ _width, _fontSize }) => css`
    min-width: ${_width}px;
    max-width: ${_width}px;
    font-size: ${_fontSize}px;
    height: ${_fontSize + 4}px;
  `}
  & span {
    position: absolute;
    right: 100%;
    transform: translateX(100%);
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &:hover span {
    width: auto;
    right: 0;
    transform: translateX(0);
    ${({ _width, textWidth }) => css`
      min-width: ${_width}px;
      animation: ${slider} ${calcTime(textWidth, _width)}s linear;
    `}
  }
`;

export default Container;
