import React from 'react';

const Mobile = () => {
  return (
    <svg width="12" height="18" viewBox="0 0 12 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.5625 0H1.6875C0.755859 0 0 0.755859 0 1.6875V16.3125C0 17.2441 0.755859 18 1.6875 18H9.5625C10.4941 18 11.25 17.2441 11.25 16.3125V1.6875C11.25 0.755859 10.4941 0 9.5625 0ZM5.625 16.875C5.00273 16.875 4.5 16.3723 4.5 15.75C4.5 15.1277 5.00273 14.625 5.625 14.625C6.24727 14.625 6.75 15.1277 6.75 15.75C6.75 16.3723 6.24727 16.875 5.625 16.875ZM9.5625 13.0781C9.5625 13.3102 9.37266 13.5 9.14062 13.5H2.10938C1.87734 13.5 1.6875 13.3102 1.6875 13.0781V2.10938C1.6875 1.87734 1.87734 1.6875 2.10938 1.6875H9.14062C9.37266 1.6875 9.5625 1.87734 9.5625 2.10938V13.0781Z" />
    </svg>
  );
};

export default Mobile;
