import styled from 'styled-components';

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 450px;
  font-size: 14px;
  & span {
    color: ${({ theme }) => theme.grey};
  }
`;

export const ManualActionContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 5px;
`;
