/* eslint-disable react/prop-types */
/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, {
  forwardRef, useEffect, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import InputTextLine from '../InputTextLine';

import { ExpandMore } from '../../icons';

import {
  InputNumContainer,
  NumberControls,
  MoneySign,
} from './styled/InputNumberLine.styled';

const sepReplacer = (val, selector, replace) => val.toString().replace(selector, replace);

/**
 * A wrapper for InputTxtLine to only accept numbers
 */
const InputNumberLine = forwardRef(({
  label,
  error,
  errorMessage,
  styleContainer,
  notTransparent,
  disabled,
  value,
  setValue,
  onChange,
  onBlur,
  wrapperStyle,
  min,
  max,
  step,
  precision,
  isMoney,
  numContainerStyle,
  ...props
}, ref) => {
  const realNum = useRef(Number(sepReplacer(value, ',', '.')) || 0);
  const [viewNum, setViewNum] = useState(sepReplacer((isMoney ? Number(value).toFixed(precision) : value.toString() || ''), '.', ','));

  const numValFormat = (val) => {
    const regex = new RegExp(`^-{0,1}\\d*${precision > 0 ? `,{0,1}\\d{0,${precision}}` : ''}`);
    let [numVal] = val.toString().match(regex);
    setViewNum(numVal);
    numVal = sepReplacer(numVal, ',', '.');
    const numericVal = (Number(numVal) || 0);
    if (numVal && !Number.isNaN(+numVal)) {
      numVal = Math.max(Math.min(numericVal, max), min);
    } else {
      numVal = min;
    }
    setValue(numVal);
    realNum.current = numVal;
  };

  useEffect(() => {
    if (value !== realNum.current) {
      numValFormat(value);
    }
  }, [value]);

  const handleOpsBtns = (op) => {
    if (Number.isNaN(Number(op))) return;
    const val = Math.max(Math.min((value || 0) + op, max), min).toFixed(precision);
    const numVal = Number(val);
    setViewNum(sepReplacer(isMoney ? val : numVal.toString(), '.', ','));
    setValue(numVal);
    realNum.current = numVal;
  };

  const handleBlurFormat = () => {
    setViewNum(sepReplacer((isMoney ? value.toFixed(precision) : value.toString() || ''), '.', ','));
    onBlur();
  };

  return (
    <InputNumContainer isMoney={isMoney} style={numContainerStyle}>
      <InputTextLine
        {...props}
        type="tel"
        ref={ref}
        label={label}
        error={error}
        errorMessage={errorMessage}
        styleContainer={styleContainer}
        notTransparent={notTransparent}
        disabled={disabled}
        value={viewNum}
        setValue={setValue}
        onChange={onChange}
        onBlur={handleBlurFormat}
        wrapperStyle={wrapperStyle}
        emojiPicker={false}
      />
      {isMoney && (
        <MoneySign>
          <span>R$</span>
        </MoneySign>
      )}
      <NumberControls>
        <NumberControls.Button className="number-increase" onClick={() => handleOpsBtns(step)}>
          <ExpandMore />
        </NumberControls.Button>
        <NumberControls.Button className="number-decrease" onClick={() => handleOpsBtns(-step)}>
          <ExpandMore />
        </NumberControls.Button>
      </NumberControls>
    </InputNumContainer>
  );
});

InputNumberLine.propTypes = {
  /**
   * A boolean variable to determine if the input is disabled or not
   */
  disabled: PropTypes.bool,
  /**
   * A boolean variable to determine if there is an error with the inputed value
   */
  error: PropTypes.bool,
  /**
   * The error message to be shown in case of an error
   */
  errorMessage: PropTypes.string,
  /**
   * The label of the input
   */
  label: PropTypes.string,
  /**
   * A boolean variable to show if the background of the input must be transparent
   */
  notTransparent: PropTypes.bool,
  /**
   * A boolean variable to show if this input value is required or not
   */
  required: PropTypes.bool,
  /**
   * The lowest acceptable value
   */
  min: PropTypes.number,
  /**
   * The highest acceptable value
   */
  max: PropTypes.number,
  /**
   * The increment value on the buttons
   */
  step: PropTypes.number,
  /**
   * The quantity of decimals accepted
   */
  precision: PropTypes.number,
  /**
   * Defines if the input represents money (only BRL)
   */
  isMoney: PropTypes.bool,
  /**
   * Function to manipulate the value
   */
  setValue: PropTypes.func.isRequired,

  styleContainer: PropTypes.shape({}),
};

InputNumberLine.defaultProps = {
  label: '',
  required: false,
  error: false,
  errorMessage: '',
  styleContainer: {},
  notTransparent: false,
  disabled: false,
  onBlur: () => {},
  min: 0,
  max: Infinity,
  step: 1,
  precision: 0,
  isMoney: false,
};

export default InputNumberLine;
