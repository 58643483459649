import React from 'react';
import PropTypes from 'prop-types';

import StyledBadge from './styled/Badge.styled';

/**
* Badges are non-actionable elements in the UI, used to indicate the status of an object
*/
const Badge = ({
  color,
  size,
  shape,
  textTransform,
  children,
  ...props
}) => {
  return (
    <StyledBadge
      color={color}
      size={size}
      shape={shape}
      textTransform={textTransform}
      {...props}
    >
      {children}
    </StyledBadge>
  );
};

Badge.propTypes = {
  /**
  * Color of the button. Choose from seven different options or pass a hex color.
  */
  color: PropTypes.oneOfType([PropTypes.oneOf(['primary', 'secondary', 'error', 'success', 'warning', 'info', 'dark']), PropTypes.string]),
  /**
  * Size of the button. Choose among three sizes.
  */
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  /**
  * Shape of the button. Choose among three shapes.
  */
  shape: PropTypes.oneOf(['square', 'rounded', 'circle']),
  /**
  * Text of the button. Choose between two types.
  */
  textTransform: PropTypes.oneOf(['capitalize', 'uppercase']),
  /**
  * Accepts a simple text, a function, an object or a javascript node.
  */
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.node,
    PropTypes.func,
  ]).isRequired,
};

Badge.defaultProps = {
  color: 'success',
  size: 'medium',
  shape: 'rounded',
  textTransform: 'capitalize',
};

export default Badge;
