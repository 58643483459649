import styled from 'styled-components';

export const ProvisionsList = styled.div`
  border-top: 0.5px solid #C4C4C4;
  min-width: 500px;
  overflow-y: auto;
  overflow-x: hidden;
  & > ::-webkit-scrollbar-track { 
    background: white;
  }
`;

export const EmptyList = styled.div`
  background-color: ${({ theme }) => theme.containerOdd};
  width: 100%;
  text-align: center;
  padding: 10px 5px;
  color: ${({ theme }) => theme.color};
  font-size: 14px;
`;

export const FileName = styled.span`
  max-width: max-content;
  font-weight: 500;
  font-size: 16px;
  color: ${({ theme }) => theme.secondary};
`;
