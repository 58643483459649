import React from 'react';

// import { Container } from './styles';

function Boxplot() {
  return (
    <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M70 65.9221V70H0V0H3.81808V65.9219H70V65.9221ZM15.1941 55.0473H17.1034V45.8726H18.8529V24.1265H17.1034V14.6125H15.1941V24.1265H13.4436V45.8727H15.1943V55.0473H15.1941ZM26.0109 41.6248H27.92V32.2801H29.6706V10.5334H27.92V1.18871H26.0109V10.5334H24.2614V32.2801H26.0109V41.6248ZM36.7493 48.421H38.6585V39.4178H40.4081V17.6691H38.6585V7.98493H36.7493V17.6691H34.9988V39.4158H36.7493V48.421ZM48.9196 52.244H50.8292V43.154H52.5792V21.4073H50.8292V11.8078H48.9196V21.4075H47.1702V43.1542H48.9196V52.244ZM61.4089 41.7115H63.3178V32.2817H65.0675V10.5334H63.3178V1.27439H61.4089V10.5335H59.6583V32.2803H61.4089V41.7115Z" fill="#FF9626" />
    </svg>
  );
}

export default Boxplot;
