/* eslint-disable no-console */
/* eslint-disable max-len */
/* eslint-disable react/no-this-in-sfc */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-expressions */
import React, {
  useState, useEffect, useRef, useCallback,
} from 'react';
import PropTypes from 'prop-types';
// import { BsSquare, BsFillEggFill } from 'react-icons/bs';

// importing Styles
import * as S from './styled/Select.styled';

// importing icons
import {
  Close, Letters, Number, DateIcon, ExpandMore,
} from '../../icons';

import Popover from '../Popover';

/**
 * The Select component allows a user to chose a value from a series of options.
*/
export default function Select({
  options,
  fullWidth,
  fullHeight = false,
  maxMenuHeight = 200,
  stayOpen = false,
  placement = 'start',
  placeholder = 'Selecione uma opção',
  selectLabel = '',
  isMult = false,
  isSearchable = false,
  isClearable = false,
  isIcon = false,
  isCreatable = false,
  alphabeticalOrder = false,
  onChange = false,
  onCreateOption = false,
  allowSelectAll = false,
  value = false,
  isDisabled = false,
  debuggerMode = false,
  atModal = false,
  tourContext = { tourOpen: false },
  ...props
}) {
  const [showList, setShowList] = useState(false);
  const [newData, setNewData] = useState([]);
  const [filter, setFilter] = useState();
  const [headerPosition, setHeaderPosition] = useState({});
  const [menuDimension, setMenuDimension] = useState({});

  const [selectedList, setSelectedList] = useState([]);

  const heightRef = useRef(null);
  const menuRef = useRef(null);
  const inputRef = useRef(null);
  const optionsRef = useRef(null);

  // const { height, getHeight } = useHeightSelect(heightRef);

  const getItems = (list) => {
    const l = newData.filter((item) => list.includes(item.value));
    return l;
  };

  const prepareData = useCallback(() => {
    debuggerMode && console.log('prepareData - options', options);

    const _newData = options.map((item) => ({
      ...item,
      icon: isIcon ? item.icon : 'none',
    }));
    if (alphabeticalOrder) {
      _newData.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
    }
    debuggerMode && console.log('prepareData - newData(end)', _newData);

    if (allowSelectAll) {
      setNewData([{
        label: 'Selecionar Todos', value: 'select_all', type: 'string', id: 'SELECT_ALL',
      }, ..._newData]);
      return;
    }
    setNewData([..._newData]);
  }, [allowSelectAll, options, debuggerMode, alphabeticalOrder, isIcon]);

  const getIcon = (icon) => {
    debuggerMode && console.log('getIcon', icon);
    const op = {
      object: <Letters />,
      category: <Letters />,
      int64: <Number />,
      float64: <Number />,
      'datetime64[ns]': <DateIcon />,

      getOption() {
        return this[icon] || null;
      },
    };

    return op.getOption() || null;
  };

  //            T-T
  //      handle functions
  const handleSelect = (item) => {
    try {
      if (tourContext.tourOpen) tourContext.nextStep();
      debuggerMode && console.log('ward', item);
      const newList = selectedList;

      if (!isMult) {
        debuggerMode && console.log('ward1', newList);
        newList[0] = item.value;
        if (stayOpen) {
          setShowList(true);
        } else {
          setShowList(!showList);
        }
        setSelectedList(newList);
        debuggerMode && console.log('ward2', newList);
        onChange && onChange(item);
        return;
      }

      newList.push(item.value);
      debuggerMode && console.log('ward3', newList);
      setSelectedList([...newList]);
      // aqui
      onChange && onChange(getItems(newList));
    } catch (error) {
      // chamar o alerta de erro do metrics
      debuggerMode && console.log(error);
    }
  };
  const handleClear = () => {
    debuggerMode && console.log('triggered on clear');
    setSelectedList([]);
    onChange && onChange(null);
  };
  const handleCreate = (label) => {
    onCreateOption(label);
    handleSelect(label);
  };
  const handleRemove = (v) => {
    const newList = selectedList;

    const index = newList.indexOf(v);
    if (index > -1) newList.splice(index, 1);
    setSelectedList([...newList]);
    onChange && onChange(getItems(newList));
  };

  const handleKeydownEscape = (e) => {
    if (e.key === 'Escape') {
      setShowList(false);
      setFilter();
    }
  };

  const handleInputKeyDown = (e) => {
    e.stopPropagation();
    if (e.key === 'ArrowDown') {
      e.preventDefault();
      const tmp = document.getElementsByClassName('select-options-li');
      if (tmp[0]) tmp[0].focus();
    }
  };

  const handleKeyOnOptions = (e, item, i) => {
    e.stopPropagation();
    if (e.key === 'Enter') {
      handleSelect(item);
      return;
    }
    handleKeydownEscape(e);
    const a = document.getElementsByClassName('select-options-li');
    if (e.key === 'ArrowDown') {
      if (a[i + 1]) a[i + 1].focus();
      return;
    }
    if (e.key === 'ArrowUp') {
      if (i - 1 === -1 && inputRef.current) inputRef.current.focus();
      if (a[i - 1]) a[i - 1].focus();
    }
  };

  //            **-**
  //    funcoes de renderizacao
  const placeholderRender = () => {
    const _list = newData.filter((item) => selectedList.includes(item.value));
    debuggerMode && console.log('_list placeholder', _list);
    debuggerMode && console.log('selectedLisst na placeRender', selectedList);
    if (_list.length === 0) return placeholder || '';

    return (
      <>
        {_list.map((item) => (
          <S.Chip key={item.id} mult={isMult}>
            {isIcon && item.type && (
              <span>
                {item.type}
                {' '}
                :
                {' '}
              </span>
            )}
            <span className="visible-label">
              {item.label}
              {' '}
            </span>
            {isMult && (
              <button
                type="button"
                className="btnClose"
                onClick={(e) => {
                  handleRemove(item.value);
                  e.stopPropagation();
                }}
              >
                <Close />
              </button>
            )}
          </S.Chip>
        ))}
        {isClearable && (
          <S.BtnClearable
            className="closeicon"
            onClick={(e) => {
              handleClear();
              e.stopPropagation();
            }}
          >
            <Close />
          </S.BtnClearable>
        )}
      </>
    );
  };
  const renderList = () => {
    const renderData = filter
      ? newData.filter((item) => item.label.toLowerCase().includes(filter.toLowerCase())
       && !selectedList.includes(item.value))
      : newData.filter((item) => !selectedList.includes(item.value));
    debuggerMode && console.log('RendeList', renderData);
    if (isSearchable && filter && renderData.length === 0 && isCreatable) {
      return (
        <S.SelectLI
          key="create"
          value="non_value"
          onClick={() => handleCreate(filter)}
          tabIndex={0}
          onKeyDown={handleKeydownEscape}
        >
          criar
          {' '}
          {filter}
        </S.SelectLI>
      );
    }

    return renderData.map((item, i) => (
      <S.SelectLI
        className="select-options-li"
        key={item.id}
        value={item.value}
        onClick={() => handleSelect(item)}
        placement={placement}
        tabIndex={0}
        onKeyDown={(e) => handleKeyOnOptions(e, item, i)}
      >
        {item.type && (
          <S.TypeData type={item.type}>
            {getIcon(item.type)}
            {' '}
          </S.TypeData>
        )}
        {item.label}
      </S.SelectLI>
    ));
  };

  const handleOpenOptions = () => {
    if (tourContext.tourOpen) tourContext.nextStep();
    setShowList(!showList);
    setHeaderPosition(heightRef.current.getBoundingClientRect());
  };

  const handleKeyOpenOptions = (e) => {
    if (e.key) {
      if (e.key === 'ArrowDown') {
        e.preventDefault();

        const tmp = document.getElementsByClassName('select-options-li');
        if (tmp[0]) tmp[0].focus();
      }
      if (e.key !== 'Enter') return;
    }
    setShowList(!showList);
    setHeaderPosition(heightRef.current.getBoundingClientRect());
  };

  useEffect(() => {
    prepareData();
    menuRef.current && setMenuDimension(menuRef.current.getBoundingClientRect());
  }, [options, showList, prepareData]);
  useEffect(() => {
    debuggerMode && console.log('useEffect selectedList', selectedList);
    prepareData();
  }, [selectedList, prepareData, debuggerMode]);

  useEffect(() => {
    debuggerMode && console.log('useEffect - value', value);

    if (value) {
      if (Array.isArray(value)) {
        debuggerMode && console.log('is array! set:', value.map((i) => i.value));
        setSelectedList(value.map((i) => i.value));
      } else {
        debuggerMode && console.log('not  array! set:', [value?.value]);
        setSelectedList([value?.value]);
      }
    }
  }, [value, debuggerMode]);

  // useClickOutside(heightRef, showList, handleClickOutside);
  return (
    <Popover closePopover={() => setShowList(false)} open={showList} containerStyle={fullWidth ? { width: '100%' } : {}}>
      <Popover.Action>
        <S.SelectWrapper
          disabled={isDisabled}
          selectLabel={selectLabel}
          fullWidth={fullWidth}
          fullHeight={fullHeight}
          clear={isClearable}
          ref={heightRef}
          {...props}
          tabIndex={0}
          onKeyDown={handleKeyOpenOptions}
          onClick={handleOpenOptions}
          className="select_wrapper"
        >
          <span className="select-label">{selectLabel}</span>
          <S.SelectHeader
            showList={showList}
            className="select_header"
          >
            <S.SelectHeaderTitle
              fullHeight={fullHeight}
              placeholder={placeholder}
              placement={placement}
            >
              {placeholderRender()}
            </S.SelectHeaderTitle>
            <ExpandMore className="arrow-down" />
          </S.SelectHeader>
        </S.SelectWrapper>
      </Popover.Action>
      <Popover.Content
        offset={[0, 1]}
        direction="bottom-start"
        stayOpened
        style={{ boxShadow: 'none', zIndex: atModal ? '1050' : '100' }}
      >
        <S.SelectUL
          showList={showList}
          width={headerPosition.right - headerPosition.left}
          maxMenuHeight={maxMenuHeight}
          menuDimension={menuDimension}
          ref={menuRef}
          className="select_list"
        >
          {isSearchable && (
            <S.SearchInput
              type="search"
              autoFocus
              placeholder={isCreatable ? 'Procurar ou criar...' : 'Procurar...'}
              // onChange={(e) => setFilter(e.target.value.toLowerCase())}
              onChange={(e) => setFilter(e.target.value)}
              onKeyDown={handleInputKeyDown}
              value={filter}
              ref={inputRef}
            />
          )}
          <S.LIWrapper maxMenuHeight={maxMenuHeight} ref={optionsRef}>
            {renderList()}
          </S.LIWrapper>
        </S.SelectUL>
      </Popover.Content>
    </Popover>
  );
}

Select.propTypes = {
  /**
   * Choose between array of options that populate the select menu
   */
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  })).isRequired,
  // options: PropTypes.arrayOf(PropTypes.object).isRequired,
  /**
   * The value of the select; reflected by the selected option. If select
   * has isMult, the data must be an array, if not, it must be an object.
   */
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  /**
   * If true, the select stretches to maxWidth of the container.
   */
  fullWidth: PropTypes.bool,
  /**
   * If true, the select stretches to maxHeight of the select.
   */
  fullHeight: PropTypes.bool,
  /**
   * Determine the max-height of the select container.
   */
  maxMenuHeight: PropTypes.number,
  /**
   * If true, select will remain open.
   */
  stayOpen: PropTypes.bool,
  /**
   * Position of placeholder. Choose between three types.
   */
  placement: PropTypes.oneOf(['start', 'center', 'end']),
  /**
   * The label of the select. It's like a title.
   */
  selectLabel: PropTypes.string,
  /**
   * If true, the value container should hold multiple values.
   */
  isMult: PropTypes.bool,
  /**
   * If true, it's enable search functionality.
   */
  isSearchable: PropTypes.bool,
  /**
   * If true, is the select value clearable.
   */
  isClearable: PropTypes.bool,
  /**
   * If true, options will have icons. It's a specific case.
   */
  isIcon: PropTypes.bool,
  /**
   * If true, allow to create new values. Use this funcionality with onCreateOption
   */
  isCreatable: PropTypes.bool,
  /**
   * Sort options alphabetically.
   */
  alphabeticalOrder: PropTypes.bool,
  /**
   * Handle change events on the select
   */
  onChange: PropTypes.func.isRequired,
  /**
   * Handle create values on the select. Use with isCreatable prop.
   */
  onCreateOption: PropTypes.func,
  /**
   * The CSS position value of the z-index, when used inside a div.
   */
  atModal: PropTypes.bool,
  /**
   * Placeholder for the select value.
   */
  placeholder: PropTypes.string,
  /**
   * Allow to select all values.
   */
  allowSelectAll: PropTypes.bool,
  /**
   * Whether the select is disabled.
   */
  isDisabled: PropTypes.bool,

  /**
   * Object with Tour functions and state.
   */
  tourContext: PropTypes.shape({
    tourOpen: PropTypes.bool,
    nextStep: PropTypes.func,
  }),

  /**
   * Flag to enable/disable debugger messages.
   */
  debuggerMode: PropTypes.bool,
};

Select.defaultProps = {
  fullWidth: false,
  fullHeight: false,
  maxMenuHeight: 200,
  stayOpen: false,
  placement: 'start',
  selectLabel: '',
  isMult: false,
  isSearchable: false,
  isClearable: false,
  isIcon: false,
  isCreatable: false,
  alphabeticalOrder: false,
  onCreateOption: () => { },
  atModal: false,
  placeholder: 'Selecione uma opção',
  allowSelectAll: false,
  isDisabled: false,
  value: '',
  tourContext: { tourOpen: false },
  debuggerMode: false,
};
