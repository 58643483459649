import React from 'react';
import PropTypes from 'prop-types';

import InputRadio from '../../../../juristec-ui/core/InputRadio';

import {
  MainContainer,
  Messages,
  PhoneContainer,
  PhoneItem,
} from './styled/MfaLogin.styled';

const supportEmail = () => {
  switch (process.env.REACT_APP_FIREBASE_PROJECT_LABEL) {
    case 'legalone-analytics':
      return <b>suporte@legaloneanalytics.com.br</b>;

    case 'benner-metrics':
      return <b>suporte@bennermetrics.com.br</b>;

    default:
      return <b>suporte@legalmetrics.com.br</b>;
  }
};

const FirstStep = ({ hints, hintIndexState }) => {
  const [selectedIdx, setSelectedIdx] = hintIndexState;

  return (
    <MainContainer>
      <Messages className="info">
        Escolha um dos números abaixo para enviarmos o código de verificação:
      </Messages>
      <PhoneContainer>
        {hints.map((hint, i) => (
          <PhoneItem key={hint.uid}>
            <InputRadio
              text={hint.phoneNumber}
              color="secondary"
              value={i}
              selected={selectedIdx}
              onChange={setSelectedIdx}
            />
          </PhoneItem>
        ))}
      </PhoneContainer>
      <Messages className="warning">
        *Caso haja problemas com os telefones cadastrados, entre em contato com
        {' '}
        {supportEmail()}
      </Messages>
    </MainContainer>
  );
};

FirstStep.propTypes = {
  /** Array of multifactor hints */
  hints: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  /** Hint selector hook */
  hintIndexState: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.func]),
  ).isRequired,
};

FirstStep.defaultProps = {
  hints: [],
};

export default FirstStep;
