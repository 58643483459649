/* eslint-disable no-param-reassign */
import React, {
  useState, useEffect, useRef, Fragment,
} from 'react';
import PropTypes from 'prop-types';

import Button from '../../../juristec-ui/core/Button';
import ActionsGroup from '../../../juristec-ui/core/ActionsGroup';
import Tooltip from '../../../juristec-ui/core/Tooltip';
import IconButton from '../../../juristec-ui/core/IconButton';
import * as ops from '../../../utils/models/customColumnsOperations';
import uuidv4 from '../../../juristec-ui/utils/functions/randomUUID';

import {
  MainContainer,
  GridContainer,
  ColumnsList,
  ItemsWrapper,
  FormulaWrapper,
  LeftSide,
  RightSide,
  EmptyMsg,
  ItemOptions,
} from './styled/CustomColumns.styled';

import {
  DateIcon, Delete, Edit, Letters, Number,
} from '../../../juristec-ui/icons';

const icons = {
  abc: <Letters />,
  float: <Number />,
  date: <DateIcon />,
};

const CustomColumns = ({
  columns,
  custom,
  hide,
  submit,
  manageCustomColumns,
}) => {
  const isDirty = useRef(false);
  const dependencies = useRef(new Set());
  const [tempCColumns, setTempCColumns] = useState([]);

  useEffect(() => {
    if (custom?.length > 0 && columns) {
      setTempCColumns(custom.map((cc) => (
        {
          ...cc,
          operator: ops[cc.operator],
          inputs: cc.inputs.map((inp) => {
            const inpObj = {
              id: uuidv4(),
              value: inp,
              label: inp,
              type: 'abc',
              origin: 'COLUMN',
            };
            if (typeof inp === 'string') {
              if (columns[inp]) {
                inpObj.type = columns[inp];
                inpObj.origin = 'COLUMN';
                dependencies.current.add(inp);
              } else {
                inpObj.type = 'date';
                inpObj.label = inp === 'TODAY' ? 'HOJE' : inp.split('-').reverse().join('/');
                inpObj.origin = inp === 'TODAY' ? 'TODAY' : 'DATE';
              }
            } else if (typeof inp === 'number') {
              inpObj.type = 'float';
              inpObj.label = inp.toString().replace('.', ',');
              inpObj.origin = 'NUMBER';
            }
            return inpObj;
          }),
        }
      )));
    }
  }, [columns, custom]);

  const addCColumn = (newCColumn) => {
    setTempCColumns((cCols) => [...cCols, { ...newCColumn, status: 'NOVO' }]);
    newCColumn.inputs.forEach((inp) => {
      dependencies.current.add(inp.value);
    });
    isDirty.current = true;
  };

  const editCColumn = (modifCColumn) => {
    dependencies.current = new Set();
    setTempCColumns((cCols) => cCols.reduce((aux, cCol) => {
      if (cCol.name === modifCColumn.name && cCol.operator !== 'REMOVE') {
        aux.push({
          ...modifCColumn,
          status: cCol.status ?? 'MODIFICADO',
        });
        modifCColumn.inputs.forEach((inp) => {
          dependencies.current.add(inp.value);
        });
      } else {
        aux.push(cCol);
        cCol.inputs.forEach((inp) => {
          dependencies.current.add(inp.value);
        });
      }
      return aux;
    }, []));
    isDirty.current = true;
  };

  const removeCColumn = (cColumn) => {
    dependencies.current = new Set();
    setTempCColumns((cCols) => cCols.reduce((aux, cCol) => {
      if (cCol.name !== cColumn.name) {
        aux.push(cCol);
        cCol.inputs.forEach((inp) => {
          dependencies.current.add(inp.value);
        });
      } else if (!cCol.status) {
        aux.push({
          ...cCol,
          operator: 'REMOVE',
        });
      }
      return aux;
    }, []));
    isDirty.current = true;
  };

  const openAddCustomColumns = () => {
    manageCustomColumns({
      columns, custom: tempCColumns, submit: addCColumn,
    });
  };

  const openEditCustomColumns = (item) => {
    manageCustomColumns({
      columns, custom: tempCColumns, submit: editCColumn, editCColumn: item,
    });
  };

  const submitColumns = () => {
    submit(tempCColumns.reduce((aux, c) => {
      aux.push({
        name: c.name ?? '',
        inputs: c.inputs?.map((inp) => inp.value),
        operator: c.operator === 'REMOVE' ? 'REMOVE' : c.operator?.value ?? '',
      });
      return aux;
    }, []));
  };

  return (
    <MainContainer>
      <GridContainer>
        <LeftSide>
          <span>
            Crie colunas novas a partir de operações em colunas já existentes no arquivo.
          </span>
          <span className="obs">
            <b>Observação: </b>
            Alterações nas colunas customizadas utilizadas na costrução de algum indicador
            podem causar a invalidação do mesmo. Certifique que elas não estão em uso ou que
            a alteração é realmente necessária.
          </span>
          <Button
            color="secondary"
            onClick={openAddCustomColumns}
          >
            Nova coluna
          </Button>
        </LeftSide>
        <RightSide>
          <ColumnsList>
            {tempCColumns.length > 0 ? (
              tempCColumns.map((cCol) => {
                if (cCol.operator === 'REMOVE') return null;
                const isDependency = dependencies.current?.has(cCol.name);
                return (
                  <Fragment key={cCol.name}>
                    <ItemsWrapper varType={cCol.operator.type}>
                      {icons[cCol.operator.type]}
                    </ItemsWrapper>
                    <ItemsWrapper>
                      <h4 style={{ fontStyle: cCol?.status ? 'italic' : 'normal' }}>
                        {Boolean(cCol?.status) && <i>{`(${cCol.status}) `}</i>}
                        {cCol.name}
                      </h4>
                      <FormulaWrapper>
                        {cCol.operator.formulaView(cCol.inputs)}
                      </FormulaWrapper>
                    </ItemsWrapper>
                    <ItemsWrapper>
                      <ItemOptions>
                        <Tooltip text="Editar" atModal>
                          <IconButton
                            shape="rounded"
                            onClick={() => openEditCustomColumns(cCol)}
                          >
                            <Edit />
                          </IconButton>
                        </Tooltip>
                        <Tooltip
                          text={
                            isDependency ? 'Esta coluna é utilizada em outra coluna customizada' : 'Remover'
                          }
                          atModal
                        >
                          <IconButton
                            shape="rounded"
                            onClick={() => removeCColumn(cCol)}
                            disabled={isDependency}
                          >
                            <Delete />
                          </IconButton>
                        </Tooltip>
                      </ItemOptions>
                    </ItemsWrapper>
                  </Fragment>
                );
              })
            ) : (
              <EmptyMsg>
                Nenhuma coluna customizada foi adicionada.
              </EmptyMsg>
            )}
          </ColumnsList>
        </RightSide>
      </GridContainer>
      <ActionsGroup>
        <Button
          style={{ margin: '5px' }}
          onClick={hide}
          variant="outlined"
          size="large"
        >
          Cancelar
        </Button>
        <Button
          style={{ margin: '5px' }}
          size="large"
          onClick={submitColumns}
          disabled={!isDirty.current}
        >
          Salvar
        </Button>
      </ActionsGroup>
    </MainContainer>
  );
};

CustomColumns.propTypes = {
  columns: PropTypes.objectOf(PropTypes.string),
  custom: PropTypes.arrayOf(PropTypes.shape({})),
  hide: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  manageCustomColumns: PropTypes.func.isRequired,
};

CustomColumns.defaultProps = {
  columns: {},
  custom: [],
};

export default CustomColumns;
