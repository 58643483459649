import React from 'react';

function Exit() {
    return (
        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M9.56795 5.2302H11.6868V0H0L8.53723 2.83582V15.8672L0.341447 13.8281L0.112752 14.7099L9.56902 17V13.7185L11.6858 13.7376V9.61794H9.56902V5.2302H9.56795Z" />
            <path fillRule="evenodd" clipRule="evenodd" d="M15.8536 7.44591L12.8008 4.32715V6.44071H10.6372V8.47876H12.8008V10.6721L15.8536 7.44591Z" />
            <path fillRule="evenodd" clipRule="evenodd" d="M6.38232 8.50952H7.40135V9.48387H6.38232V8.50952Z" />
        </svg>
    );
}

export default Exit;