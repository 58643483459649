/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';

import ControllersGroup from '../../../controllers/ControllersGroup';
import {
  InputLineController,
  ToggleSwitchController,
  SelectController,
  RangeController,
  ChoiceBoxController,
} from '../../../controllers/Base';

import fontOptions from '../../../utils/controllersUtils/options';
import { Symbols } from '../../../../icons';

const LegendControllers = ({
  handle,
  config,
  extend,
  setExtend,
}) => (
  <>
    <ControllersGroup title="Legenda" id="legend" extend={extend} setExtend={setExtend}>
      <ToggleSwitchController
        label="Habilitar legendas"
        handle={handle}
        kWord="LegendControl"
        configValue={config?.LegendControl?.checked}
        firstOption=""
        secondOption=""
        fullWidth
      />
      <ChoiceBoxController
        label="Posição"
        handle={handle}
        kWord="LegendPlacementControlW"
        options={[
          { value: 'Acima', label: 'Acima', id: 'Acima' },
          { value: 'Esquerda', label: 'Esquerda', id: 'Esquerda' },
          { value: 'Direita', label: 'Direita', id: 'Direita' },
          { value: 'Abaixo', label: 'Abaixo', id: 'Abaixo' },
        ]}
        configValue={config?.LegendPlacementControl?.value?.label}
        fullWidth
        disabled={!config?.LegendControl?.checked}
      />
      <SelectController
        label="Fonte"
        handle={handle}
        kWord="LegendsFontFamilyControl"
        options={fontOptions}
        configValue={config?.LegendsFontFamilyControl?.value}
        disabled={!config?.LegendControl?.checked}
      />
      <InputLineController
        label="Tamanho"
        handle={handle}
        kWord="LegendsFontSizeControl"
        configValue={config?.LegendsFontSizeControl?.value}
        min={1}
        max={50}
        step={1}
        precision={0}
        isNumber
        disabled={!config?.LegendControl?.checked}
      />
      <RangeController
        key={`h-${config?.LegendPlacementControl?.value?.label}`}
        label="Posição horizontal"
        handle={handle}
        kWord="LegendTranslateX"
        configValue={config?.LegendPlacementControl?.value?.translateX}
        min={-500}
        max={500}
        fullWidth
        disabled={!config?.LegendControl?.checked}
      />
      <RangeController
        key={`v-${config?.LegendPlacementControl?.value?.label}`}
        label="Posição vertical"
        handle={handle}
        kWord="LegendTranslateY"
        configValue={config?.LegendPlacementControl?.value?.translateY}
        min={-500}
        max={500}
        fullWidth
        disabled={!config?.LegendControl?.checked}
      />
      <RangeController
        label="Espaçamento dos itens"
        handle={handle}
        kWord="LegendsSpacingControl"
        configValue={config?.LegendsSpacingControl?.value}
        min={-50}
        max={50}
        fullWidth
        disabled={!config?.LegendControl?.checked}
      />
      <ChoiceBoxController
        label="Símbolo"
        handle={handle}
        kWord="LegendSymbolControl"
        options={[
          { value: 'square', label: <Symbols shape="square" />, id: 'square' },
          { value: 'circle', label: <Symbols shape="circle" />, id: 'circle' },
          { value: 'triangle', label: <Symbols shape="triangle" />, id: 'triangle' },
          { value: 'diamond', label: <Symbols shape="diamond" />, id: 'diamond' },
        ]}
        configValue={config?.LegendSymbolControl || 'circle'}
        fullWidth
        disabled={!config?.LegendControl?.checked}
      />
    </ControllersGroup>
  </>
);

LegendControllers.propTypes = {
  config: PropTypes.objectOf(PropTypes.any),
  handle: PropTypes.func,
  extend: PropTypes.string,
  setExtend: PropTypes.func,
};

LegendControllers.defaultProps = {
  config: undefined,
  handle: () => {},
  extend: '',
  setExtend: () => {},
};

export default LegendControllers;
