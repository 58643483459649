import styled from 'styled-components';

// const CookiesMessageContainer = styled.div`
//   button .acceptButton {
//     background-color: #4711B2;
//     color: #FFFFFF;
//     padding: 14px;
//     font-weight: bold;
//     font-size: 15px;
//     border: 2px solid #4711B2;
//     border-radius: 6px;
//   };
// `;

const Link = styled.a`
  text-decoration-line: none;
  color: ${({ theme }) => theme.info};;
  font-weight: bold;
`;

export default Link;
