import React from 'react';

const ChartFunnel = ({ primary = '#4711B2', secondary = '#FF9626' }) => (
  <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M50 0H0C0 3.06433 1.73132 5.86566 4.47214 7.23607L5.52786 7.76393C8.26868 9.13434 10 11.9357 10 15H40C40 11.9357 41.7313 9.13434 44.4721 7.76393L45.5279 7.23607C48.2687 5.86566 50 3.06433 50 0Z" fill={primary} />
    <path d="M40 16.6667H10V18.3333C10 20.9563 11.235 23.4262 13.3333 25C15.4317 26.5738 16.6667 29.0437 16.6667 31.6667V33.3333H33.3333V31.6667C33.3333 29.0437 34.5683 26.5738 36.6667 25C38.765 23.4262 40 20.9563 40 18.3333V16.6667Z" fill={secondary} />
    <path d="M33.3333 35H16.6667V40V45V50H33.3333V45V40V35Z" fill={primary} />
  </svg>
);

export default ChartFunnel;
