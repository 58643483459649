import styled from 'styled-components';

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
  max-width: 1000px;
  overflow-x: hidden;
  overflow-y: hidden;
  max-height: calc(100vh - 220px);
  width: 80vw;
`;

export const KpiWrapper = styled.div`
  background-color: #ffffff;
  border: 1px #9a9a9a solid;
  border-radius: 4px;
`;

export const CarouselWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  user-select: none;
`;

export const TableOverFlow = styled.div`
  max-height: calc(100vh - 185px);
  overflow-y: auto;
  overflow-x: auto;
`;
