import React from 'react';
import PropTypes from 'prop-types';

import IconButton from '../IconButton';
import GotoPage from './GoToPage';

import { Last, Next } from '../../icons';

import { ArrowBtns, PaginationContainer } from './styled/TablePagination.styled';

/** Controls for DataTable pagination */
const TablePagination = ({
  page, totalPages, setPage, useGoToPage, label, disabled, className,
}) => {
  /** Advances 1 page */
  const forwardPage = () => setPage((p) => p + 1);

  /** Returns 1 page */
  const backPage = () => setPage((p) => p - 1);

  /** Advance to the last page */
  const lastPage = () => setPage(totalPages);

  /** Returns to the first page */
  const firstPage = () => setPage(0);

  return (
    <PaginationContainer className={className}>
      <ArrowBtns>
        {totalPages !== Infinity && (
          <IconButton
            shape="rounded"
            className="invert"
            disabled={disabled || page === 0}
            onClick={firstPage}
          >
            <Last />
          </IconButton>
        )}
        <IconButton
          shape="rounded"
          className="invert"
          disabled={disabled || page === 0}
          onClick={backPage}
        >
          <Next />
        </IconButton>

        <span>
          {label}
          {' '}
        </span>
        {useGoToPage ? (
          <GotoPage
            page={page}
            totalPages={totalPages}
            setPage={setPage}
            disabled={disabled}
          />
        ) : (
          <span>
            {page + 1}
          </span>
        )}
        {totalPages !== Infinity && (
          <span>
            {' '}
            de
            {' '}
            {totalPages + 1}
          </span>
        )}

        <IconButton
          shape="rounded"
          disabled={disabled || page === (totalPages)}
          onClick={forwardPage}
        >
          <Next />
        </IconButton>
        {totalPages !== Infinity && (
          <IconButton
            shape="rounded"
            disabled={disabled || page === (totalPages)}
            onClick={lastPage}
          >
            <Last />
          </IconButton>
        )}
      </ArrowBtns>
    </PaginationContainer>
  );
};

TablePagination.propTypes = {
  /** Actual page (starts at 0) */
  page: PropTypes.number.isRequired,
  /** Total of pages (length) */
  totalPages: PropTypes.number,
  /** Function to set a new actual page */
  setPage: PropTypes.func.isRequired,
  /** Boolean that toggles page jump input */
  useGoToPage: PropTypes.bool,
  /** Pagination label */
  label: PropTypes.string,
  /** Boolean indicating whether the pagination should be disabled */
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

TablePagination.defaultProps = {
  totalPages: 1,
  useGoToPage: false,
  label: 'Página',
  disabled: false,
  className: '',
};

export default TablePagination;
