import React, {
  createContext, useCallback, useContext, useMemo,
} from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
// import useEscape from '../../hooks/useEscape';

// global style stuffs
import { ThemeProvider } from 'styled-components';
import GlobalStyles from '../../juristec-ui/styles/globalStyles';
import { themeModes } from '../../juristec-ui/styles/theme';
import { StyleContext } from '../StyleProvider';

// Providers
import { AlertApiProvider } from '../AlertProvider';
import Modal from '../../juristec-ui/core/Modal';
import AuthContext from '../AuthProvider/provider';

/*
    ModalApiProvider

    setModalConfig: = (configs) =>
      usado para abrir modal apartir de um objeto com os parametros:
        title: string q vai aparecer na barra superior do modal.
        closeFunc: função que roda quando clicado no "X".
        children: conteúdo para ser mostrado no modal.

        yesFunc: função para rodar quando se clica em no botão de confirmar. default: () => {}
        yesLabel: string para ficar no botão de confirmar.

        notLabel: função para rodar quando se cancela o modal.
        notFunc: string para ficar no botão de cancelar. default: () => {}

        nodeTarget: id para abrir modal. default: 'new-node-portal'
        (nested modal: passar id unico. caso contrário, vai utilizar um id padrão: new-node-portal).

        minWidth: booleano para definir que vai existir um tamanho mínimo. default: false
        noPadding: booleano para deixar com padding ou não. default: false
        disableFocus: booleano para desativar/ativar focusLock no modal. default: false

    closeModal: = (modalId) =>
      fecha modal que tem o id passado.
      se não for passado nenhum vai usar o id padrão: new-node-portal.

    toggleModal: igual closeModal. Só existe por compatibilidade com o projeto.

  */

export const ModalContext = createContext(null);

export const ModalApiProvider = ({ children: providerChild, AuthProvider }) => {
  const authData = useContext(AuthContext);
  const { state: styleState } = useContext(StyleContext);

  const closeModal = useCallback((modalId) => {
    const target = typeof modalId === 'string' ? modalId : 'new-node-portal';// alguns lugares é passado o evento.
    const aux = document.getElementById(target);
    if (aux) {
      ReactDOM.render(null, aux);
      aux.remove();
    }
  }, []);

  const toggleModal = closeModal; // só para retro-compatibilidade...

  const createNApend = (id, target) => {
    const tmp = document.createElement('div');
    tmp.id = id;
    target.appendChild(tmp);
    return tmp;
  };

  const setModalConfig = useCallback((configs) => {
    const modalId = configs.nodeTarget || 'new-node-portal';

    let pb = document.getElementById('portals');
    if (!pb) pb = createNApend('portals', document.body);

    let newN = document.getElementById(modalId);
    if (!newN) newN = createNApend(modalId, pb);

    ReactDOM.render(
      <ThemeProvider theme={themeModes[styleState.themeMode]}>
        <GlobalStyles />
        <AuthProvider hydrate={authData}>
          <AlertApiProvider>
            {/* Pelo doc desse warning, não tem problema fazer assim.
                só q a config do projeto pega qualquer caso... */}
            {/*  eslint-disable-next-line no-use-before-define */}
            <ModalContext.Provider value={ModalApi}>
              <Modal
                isShowing={!!configs}
                hide={() => {
                  if (configs?.closeFunc) configs.closeFunc();
                  else if (configs?.nodeTarget) closeModal(newN.id);
                  else closeModal();
                }}
                title={configs?.title}
                yesFunc={configs?.yesFunc}
                yesLabel={configs?.yesLabel}
                notLabel={configs?.notLabel}
                notFunc={configs?.notFunc}
                minWidth={configs?.minWidth}
                noPadding={configs?.noPadding}
                transparent={configs?.transparent}
                disableFocus={configs?.disableFocus}
                nodeTarget={configs?.nodeTarget}
                className={configs?.className}
                confirmClassName={configs?.confirmClassName}
                id={configs?.id}
              >
                {configs?.children}
              </Modal>
            </ModalContext.Provider>
          </AlertApiProvider>
        </AuthProvider>
      </ThemeProvider>,
      document.getElementById(newN.id),
    );
  }, [styleState]);

  const ModalApi = useMemo(() => ({
    setModalConfig,
    closeModal,
    toggleModal,
  }), [styleState]);

  return (
    <ModalContext.Provider value={ModalApi}>
      {providerChild}
    </ModalContext.Provider>
  );
};

ModalApiProvider.propTypes = {
  AuthProvider: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.node]),
};

ModalApiProvider.defaultProps = {
  children: '',
};
