import styled from 'styled-components';

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 550px;
  font-size: 14px;
`;

export const InfoRow = styled(Row)`
  justify-content: flex-start;
  align-items: center;
  color: ${({ theme }) => theme.grey};
  font-size: 14px;
  margin-bottom: 5px;
`;

export const FooterInfo = styled(Row)`
  justify-content: flex-start;
  color: ${({ theme }) => theme.grey};
  font-size: 14px;
  align-items: center;
`;

export const ErrorContainer = styled(Column)`
  margin-bottom: 5px;
  padding: 10px;
  font-size: 12px;
  background-color: ${({ theme }) => theme.card};
  border-radius: 10px;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: auto;
  max-height: calc(100vh - 460px);
  & ul {
    margin: 0;
    padding: 0  0 0 13px;
    & li:not(:last-child) {
      margin-bottom: 5px;
    }
    & li::marker {
      color: ${({ theme }) => theme.warning};
      font-size: 16px;
    }
    & strong {
      color: ${({ theme }) => theme.warning};
    }
    & .error {
      color: ${({ theme }) => theme.info};
    }
  }
`;

export const MessageContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  font-size: 14px;
  margin-bottom: 5px;
`;

export const ManualUpdateContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 5px;
  & button {
    margin-left: .5rem;
  }
`;
