import React from 'react';

const Tablet = () => {
  return (
    <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.0625 0H1.6875C0.755859 0 0 0.755859 0 1.6875V16.3125C0 17.2441 0.755859 18 1.6875 18H14.0625C14.9941 18 15.75 17.2441 15.75 16.3125V1.6875C15.75 0.755859 14.9941 0 14.0625 0ZM7.875 16.875C7.25273 16.875 6.75 16.3723 6.75 15.75C6.75 15.1277 7.25273 14.625 7.875 14.625C8.49727 14.625 9 15.1277 9 15.75C9 16.3723 8.49727 16.875 7.875 16.875ZM14.0625 13.0781C14.0625 13.3102 13.8727 13.5 13.6406 13.5H2.10938C1.87734 13.5 1.6875 13.3102 1.6875 13.0781V2.10938C1.6875 1.87734 1.87734 1.6875 2.10938 1.6875H13.6406C13.8727 1.6875 14.0625 1.87734 14.0625 2.10938V13.0781Z" />
    </svg>
  );
};

export default Tablet;
