import firebase from '../utils/firebase';
import { getRefCommentsList } from '../utils/firestore';

const createLog = (type, action, obj, batch) => {
  const dashRef = getRefCommentsList(obj.userDoc.id, obj.dashId).doc();
  const logInfo = {
    archived: false,
    author: obj.userDoc.email,
    authorId: obj.userDoc.id,
    id: dashRef.id,
    serverTimestamp: firebase.serverTimestamp(),
    timestamp: new Date().getTime(),
    isLog: true,
    type,
    action,
  };

  switch (`${type}_${action}`) {
    case 'dashboard_edit':
      logInfo.newDashName = obj.editInfo.name;
      break;

    case 'kpi_add':
      logInfo.targetName = obj.KpiObject.name;
      logInfo.targetId = obj.kpiId;
      break;

    case 'kpi_edit':
      logInfo.targetName = obj.KpiObject.name;
      logInfo.targetId = obj.kpiId;
      break;

    case 'kpi_remove':
      logInfo.targetName = obj.KpiObject.name;
      logInfo.targetId = obj.kpiId;
      break;

    case 'insight_add':
      logInfo.targetName = '';
      logInfo.targetId = obj.labelId;
      break;

    case 'insight_edit':
      logInfo.targetName = '';
      logInfo.targetId = obj.labelId;
      break;

    case 'insight_remove':
      logInfo.targetName = '';
      logInfo.targetId = obj.kpiId;
      break;

    case 'story_add':
      logInfo.targetName = obj.snapName;
      logInfo.targetId = obj.snapId;
      break;

    case 'story_edit':
      logInfo.newLabelName = obj.name;
      break;

    case 'story_remove':
      logInfo.targetName = obj.snapName;
      logInfo.targetId = obj.snapId;
      break;

    case 'user_edit':
      logInfo.targetName = obj.target.targetName;
      logInfo.targetId = obj.target.targetId;
      logInfo.newUserPermission = obj.target.newUserPermission;
      break;

    default:
      console.log('createLog error');
      return batch;
  }
  batch.set(dashRef, logInfo);
  return batch;
};

export default createLog;

// const activity = {
// archived:false,
//     author: obj.userDoc.email,
//     authorId: obj.userDoc.id,
//     id: dashRef.id,
//     serverTimestamp: firebase.serverTimestamp(),
//     timestamp: new Date().getTime(),
//     isLog: true,
//     type:,
//     action:,
// };
