import React, { useState } from 'react';
import PropTypes from 'prop-types';
import StepProgress from '../../../juristec-ui/core/StepProgress';
import FirstStep from './Steps/FirstStep';
import SecondStep from './Steps/SecondStep';

const OnboardingReport = ({
  submitData,
  hide,
  users,
  modules,
  schema,
  getL1ReportModuleFields,
  plan,
  getTemplates,
}) => {
  const [reportName, setReportName] = useState({ value: '', error: false, errorMsg: '' });
  const [selectedEmail, setSelectedEmail] = useState({});
  const [selectedModule, setSelectedModule] = useState({});
  const [disableSubmitButton, setDisableSubmitButton] = useState(true);
  const [selectedFields, setSelectedFields] = useState([]);
  const [fields, setFields] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState({
    id: 'Nenhum', value: 'Nenhum', label: 'Nenhum', columns: [],
  });
  const [templates, setTemplates] = useState([{
    id: 'Nenhum', value: 'Nenhum', label: 'Nenhum', columns: [],
  }]);
  const [aliases, setAliases] = useState({});

  const handleSubmit = () => {
    submitData(
      reportName.value,
      selectedEmail.id,
      schema,
      selectedFields,
      selectedModule.value,
      aliases,
    );
  };

  const checkFirstForm = () => (
    !(disableSubmitButton
      || Object.keys(selectedEmail).length === 0
      || Object.keys(selectedModule).length === 0
      || selectedFields.length === 0)
  );

  const firstStep = {
    label: 'Informações',
    validation: checkFirstForm,
    content: (
      <FirstStep
        reportNameState={[reportName, setReportName]}
        selectedEmailState={[selectedEmail, setSelectedEmail]}
        selectedModuleState={[selectedModule, setSelectedModule]}
        disableSubmitButtonState={[disableSubmitButton, setDisableSubmitButton]}
        selectedFieldsState={[selectedFields, setSelectedFields]}
        fieldsState={[fields, setFields]}
        selectedTemplateState={[selectedTemplate, setSelectedTemplate]}
        templatesState={[templates, setTemplates]}
        users={users}
        modules={modules}
        schema={schema}
        getL1ReportModuleFields={getL1ReportModuleFields}
        plan={plan}
        getTemplates={getTemplates}
      />
    ),
  };

  const checkSecondForm = () => !Object.values(aliases).includes('');

  const secondStep = {
    label: 'Nome das Colunas',
    validation: checkSecondForm,
    content: (
      <SecondStep
        selectedFields={selectedFields
          .sort((a, b) => a.id.localeCompare(b.id))
          .filter((field) => field.id !== 'id')}
        aliasesState={[aliases, setAliases]}
      />
    ),
  };

  const steps = [firstStep, secondStep];

  return (
    <StepProgress
      steps={steps}
      hide={hide}
      complete={handleSubmit}
      markersWidth="Medium"
      disableUntilValid
    />
  );
};

OnboardingReport.propTypes = {
  submitData: PropTypes.func,
  hide: PropTypes.func,
  users: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  modules: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  schema: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  getL1ReportModuleFields: PropTypes.func.isRequired,
  plan: PropTypes.string.isRequired,
  getTemplates: PropTypes.func.isRequired,
};

OnboardingReport.defaultProps = {
  submitData: () => {},
  hide: () => {},
};

export default OnboardingReport;
