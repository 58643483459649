import React from 'react';

// import { Container } from './styles';

function VisibilityOff() {
  return (
    <svg width="30" height="24" viewBox="0 0 30 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.81898 0.239322C1.33149 -0.145046 0.62369 -0.0559855 0.239322 0.431506C-0.145046 0.918997 -0.0559855 1.6268 0.431506 2.01117L28.181 23.7608C28.6685 24.1452 29.3763 24.0561 29.7607 23.5686C30.145 23.0811 30.056 22.3733 29.5685 21.9889L24.6373 18.1265C26.4935 16.2234 27.7498 14.0906 28.3826 12.5766C28.5373 12.2063 28.5373 11.7938 28.3826 11.4235C27.6842 9.75009 26.217 7.31264 24.0233 5.2783C21.8202 3.22521 18.7874 1.50024 15 1.50024C11.8032 1.50024 9.14073 2.73303 7.0642 4.35019L1.81898 0.239322ZM10.4579 7.00795C11.6532 5.91578 13.2516 5.25017 15 5.25017C18.7265 5.25017 21.7499 8.27356 21.7499 12.0001C21.7499 13.1672 21.4546 14.2641 20.9343 15.2203L19.1249 13.8047C19.5187 12.9 19.6218 11.8641 19.3499 10.8376C18.8296 8.89229 17.1093 7.58451 15.1969 7.50482C14.925 7.49544 14.7656 7.79075 14.85 8.05325C14.9484 8.35324 15.0047 8.67199 15.0047 9.00479C15.0047 9.48291 14.8922 9.9329 14.6953 10.3313L10.4626 7.01264L10.4579 7.00795ZM17.4843 18.2765C16.7156 18.5812 15.8765 18.7499 15 18.7499C11.2735 18.7499 8.25012 15.7265 8.25012 12.0001C8.25012 11.6766 8.27356 11.3626 8.31574 11.0532L3.89551 7.57044C2.82678 8.96261 2.06273 10.3501 1.61742 11.4235C1.46274 11.7938 1.46274 12.2063 1.61742 12.5766C2.31585 14.25 3.78301 16.6875 5.97672 18.7218C8.17981 20.7749 11.2126 22.4999 15 22.4999C17.2406 22.4999 19.214 21.8952 20.9155 20.9765L17.4843 18.2765Z" />
    </svg>
    // <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" width="24px" height="24px">
    //   <path d="M0 0h24v24H0zm0 0h24v24H0zm0 0h24v24H0zm0 0h24v24H0z" fill="none" />
    //   <path d="M12 7c2.76 0 5 2.24 5 5 0 .65-.13 1.26-.36 1.83l2.92 2.92c1.51-1.26 2.7-2.89 3.43-4.75-1.73-4.39-6-7.5-11-7.5-1.4 0-2.74.25-3.98.7l2.16 2.16C10.74 7.13 11.35 7 12 7zM2 4.27l2.28 2.28.46.46C3.08 8.3 1.78 10.02 1 12c1.73 4.39 6 7.5 11 7.5 1.55 0 3.03-.3 4.38-.84l.42.42L19.73 22 21 20.73 3.27 3 2 4.27zM7.53 9.8l1.55 1.55c-.05.21-.08.43-.08.65 0 1.66 1.34 3 3 3 .22 0 .44-.03.65-.08l1.55 1.55c-.67.33-1.41.53-2.2.53-2.76 0-5-2.24-5-5 0-.79.2-1.53.53-2.2zm4.31-.78l3.15 3.15.02-.16c0-1.66-1.34-3-3-3l-.17.01z" />
    // </svg>
  );
}

export default VisibilityOff;
