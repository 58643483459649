import React from 'react';

const Computer = () => {
  return (
    <svg width="23" height="18" viewBox="0 0 23 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M21.9375 14.625H13.4135C13.3875 15.3214 12.8964 15.75 12.2625 15.75H10.125C9.46793 15.75 8.96414 15.1358 8.97293 14.625H0.5625C0.253125 14.625 0 14.8781 0 15.1875V15.75C0 16.9875 1.0125 18 2.25 18H20.25C21.4875 18 22.5 16.9875 22.5 15.75V15.1875C22.5 14.8781 22.2469 14.625 21.9375 14.625ZM20.25 1.6875C20.25 0.759375 19.4906 0 18.5625 0H3.9375C3.00938 0 2.25 0.759375 2.25 1.6875V13.5H20.25V1.6875ZM18 11.25H4.5V2.25H18V11.25Z" />
    </svg>
  );
};

export default Computer;
