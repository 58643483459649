import React from 'react';

function CommentExclamation() {
  return (
    <svg width="24" height="21" viewBox="0 0 512 448" fill="white" xmlns="http://www.w3.org/2000/svg">
      <path d="M256 0C114.6 0 0 93.1 0 208C0 257.6 21.4 303 57 338.7C44.5 389.1 2.7 434 2.2 434.5C0 436.8 -0.6 440.2 0.7 443.2C2 446.2 4.8 448 8 448C74.3 448 124 416.2 148.6 396.6C181.3 408.9 217.6 416 256 416C397.4 416 512 322.9 512 208C512 93.1 397.4 0 256 0Z" />
      <path d="M302.75 309.125C302.75 334.972 281.722 356 255.875 356C230.028 356 209 334.972 209 309.125C209 283.278 230.028 262.25 255.875 262.25C281.722 262.25 302.75 283.278 302.75 309.125ZM214.426 70.765L222.395 230.14C222.769 237.624 228.946 243.5 236.439 243.5H275.311C282.804 243.5 288.981 237.624 289.355 230.14L297.324 70.765C297.726 62.7324 291.322 56 283.279 56H228.471C220.428 56 214.024 62.7324 214.426 70.765Z" fill="white" />
    </svg>
  );
}

export default CommentExclamation;
