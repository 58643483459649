/* eslint-disable no-plusplus */
import React, {
  useState, useRef, useEffect, useContext,
} from 'react';
import PropTypes from 'prop-types';
import Avatar from '../../juristec-ui/core/Avatar';
import InputTextLine from '../../juristec-ui/core/InputTextLine';
import IconButton from '../../juristec-ui/core/IconButton';
import Button from '../../juristec-ui/core/Button';
import ActionsGroup from '../../juristec-ui/core/ActionsGroup';
import UnsplashPicker from '../../juristec-ui/core/UnsplashPicker';
import Tooltip from '../../juristec-ui/core/Tooltip';
import PasswordRules from '../PasswordRules';

import { ModalContext } from '../../context/ModalProvider';
import useToggleState from '../../juristec-ui/hooks/useToggleState';

import {
  Finder,
  RemoveImage,
  Trash,
  UploadImage,
  Visibility,
  VisibilityOff,
} from '../../juristec-ui/icons';

import {
  verifyRepeatNewPassword, verifyPassword, verifyInput,
} from '../../juristec-ui/utils/validators/inputTextValidators';

import {
  ProfileEditContainer,
  InputWrapper,
  AvatarContainer,
  AvatarTip,
  Form,
  AvatarOverlay,
  TabGroup,
  TabButton,
  PhoneContainers,
  PhoneItem,
  ScrollContainer,
} from './styled/ProfileEdit.styled';

const labelPermission = {
  guest: 'Convidado',
  scientist: 'Cientista',
  super: 'Proprietário',
  get(key) { return this[key] || key; },
};

const breakSubmit = (e) => {
  e.preventDefault();
  e.stopPropagation();
};

const phoneMask = (val, format, hidden) => {
  if (hidden) {
    return val.replace(/\d(?=\d{4})/g, '*');
  }
  if (format) {
    let count = 1;
    return format.replace(/\./g, () => val[count++]);
  }
  return val;
};

/**
* A component that contains options to change the avatar and profile data. Used inside a modal.
*/
const ProfileEdit = ({
  user, closeModal, submitFormData, submitAvatarImg, checkImage, addMfaNumber, removeMfaNumber,
}) => {
  const [img, setImg] = useState(user.src);
  const [imgSrc, setImgSrc] = useState('');
  const [formTab, setFormTab] = useState('profile');
  const [showImgOpts, setShowImgOpts] = useState(false);
  const [userName, setUserName] = useState({ value: user.name, error: user.name.length === 0, errorMsg: '' });
  const [newPassword, setNewPassword] = useState({ value: '', error: true, errorMsg: '' });
  const [repeatNewPassword, setRepeatNewPassword] = useState({ value: '', error: true, errorMsg: '' });
  const [newPasswordErrors, setNewPasswordErrors] = useState({
    specialCharacterError: true,
    numberError: true,
    capitalError: true,
    lowerError: true,
    lengthError: true,
  });

  const [hidePhone, togglePhone] = useToggleState(true);

  const inputFile = useRef(null);
  const wrapperRef = useRef(null);
  const inputRef = useRef(null);

  const { setModalConfig, toggleModal } = useContext(ModalContext);

  useEffect(() => {
    setImgSrc((old) => {
      URL.revokeObjectURL(old);
      if (img) {
        return typeof (img) === 'string' ? img : URL.createObjectURL(img);
      } return '';
    });
  }, [img]);

  useEffect(() => {
    setImg(user.src);
    setShowImgOpts(false);
    setUserName({ value: user.name, error: user.name.length === 0, errorMsg: '' });
    setNewPassword({ value: '', error: true, errorMsg: '' });
    setRepeatNewPassword({ value: '', error: true, errorMsg: '' });
    setNewPasswordErrors({
      specialCharacterError: true,
      numberError: true,
      capitalError: true,
      lowerError: true,
      lengthError: true,
    });
  }, [formTab]);

  useEffect(() => {
    const closeImgOpts = (e) => {
      if (wrapperRef.current && !wrapperRef.current.contains(e.target)) {
        setShowImgOpts(false);
      }
    };
    document.addEventListener('click', closeImgOpts);
    document.addEventListener('mouseover', closeImgOpts);
    return () => {
      document.removeEventListener('click', closeImgOpts);
      document.removeEventListener('mouseover', closeImgOpts);
    };
  }, [wrapperRef]);

  const openUnsplashPicker = () => {
    setModalConfig({
      title: 'Selecionar Fotos',
      children: (
        <UnsplashPicker
          closeModal={() => toggleModal('unsplash-picker-modal')}
          selectImg={setImg}
        />
      ),
      nodeTarget: 'unsplash-picker-modal',
    });
  };

  const trimUserName = (e) => {
    setUserName({
      ...userName,
      value: e.target.value.split(' ').filter((v) => v).join(' '),
    });
  };

  const userNameHandle = (val) => {
    const msg = verifyInput(val, true, 2, 40);
    setUserName({
      value: val,
      error: msg.length !== 0,
      errorMsg: msg,
    });
  };

  const newPasswordHandle = (val) => {
    const vRes = verifyPassword(val);
    setNewPassword({
      value: val,
      error: vRes.message.length !== 0,
      errorMsg: vRes.message,
    });
    setNewPasswordErrors(vRes);
  };

  const repeatNewPasswordHandle = (val) => {
    const msg = verifyRepeatNewPassword(val, newPassword.value);
    setRepeatNewPassword({
      value: val,
      error: msg.length !== 0,
      errorMsg: msg,
    });
  };

  const handleFormData = (e) => {
    switch (e.target.name) {
      case 'userName':
        userNameHandle(e.target.value);
        break;
      case 'newPassword':
        newPasswordHandle(e.target.value);
        break;
      case 'repeatNewPassword':
        repeatNewPasswordHandle(e.target.value);
        break;
      default:
        break;
    }
  };

  const blockSaveBtn = () => {
    switch (formTab) {
      case 'profile': {
        return userName.error || (userName.value === user.name && img === user.src);
      }
      case 'email': {
        return true;
      }
      case 'password': {
        return newPassword.error || repeatNewPassword.error;
      }
      default:
        return true;
    }
  };

  const saveNewAvatar = () => {
    if (img !== user.src) {
      submitAvatarImg(img);
    }
  };

  const handleDeviceImage = (e) => {
    const deviceImage = e.target.files[0];
    if (deviceImage && checkImage(deviceImage)) {
      setImg(e.target.files[0]);
    }
  };

  const handleSubmit = () => {
    switch (formTab) {
      case 'profile': {
        if (!userName.error) {
          if (userName.value !== user.name) {
            submitFormData({
              type: 'profile',
              userName: userName.value,
            });
          }
          saveNewAvatar();
          closeModal();
        } else {
          userNameHandle(userName.value);
        }
        break;
      }
      case 'password': {
        if (!(newPassword.error || repeatNewPassword.error)) {
          submitFormData({
            type: 'password',
            newPassword: newPassword.value,
          });
          /* saveNewAvatar(); */
          closeModal();
        } else {
          newPasswordHandle(newPassword.value);
          repeatNewPasswordHandle(repeatNewPassword.value);
        }
        break;
      }
      default:
        break;
    }
  };

  return (
    <>
      <ProfileEditContainer>
        <TabGroup>
          <TabButton active={formTab === 'profile'} onClick={() => setFormTab('profile')}>
            Perfil
          </TabButton>
          <TabButton active={formTab === 'email'} onClick={() => setFormTab('email')}>
            Email
          </TabButton>
          <TabButton active={formTab === 'password'} onClick={() => setFormTab('password')}>
            Senha
          </TabButton>
          <TabButton active={formTab === 'mfa'} onClick={() => setFormTab('mfa')}>
            Multifator
          </TabButton>
        </TabGroup>
        <ScrollContainer>
          {formTab === 'profile' && (
            <>
              <AvatarTip>Tamanho máximo de arquivo: 1mb</AvatarTip>
              <AvatarContainer
                ref={wrapperRef}
                onClick={() => setShowImgOpts(true)}
                onMouseOver={() => setShowImgOpts(true)}
              >
                <input
                  type="file"
                  ref={inputFile}
                  accept="image/*"
                  onChange={handleDeviceImage}
                  style={{ display: 'none' }}
                />
                <Avatar name={user.name} src={imgSrc} size="profile" style={{ cursor: 'inherit' }} />
                {showImgOpts
                  && (
                    <AvatarOverlay>
                      {img
                        && (
                          <Tooltip text="Remover imagem atual" direction="right" atModal>
                            <IconButton
                              color="#fff"
                              size="large"
                              onClick={() => setImg('')}
                            >
                              <RemoveImage />
                            </IconButton>
                          </Tooltip>
                        )}
                      <Tooltip text="Adicionar nova imagem" atModal>
                        <IconButton
                          color="#fff"
                          size="large"
                          onClick={() => inputFile.current.click()}
                        >
                          <UploadImage />
                        </IconButton>
                      </Tooltip>
                      <Tooltip text="Buscar fotos na web" direction="left" atModal>
                        <IconButton
                          color="#fff"
                          size="large"
                          onClick={openUnsplashPicker}
                        >
                          <Finder />
                        </IconButton>
                      </Tooltip>
                    </AvatarOverlay>
                  )}
              </AvatarContainer>
              <Form onSubmit={breakSubmit} key="profile">
                <InputWrapper>
                  <InputTextLine
                    ref={inputRef}
                    errorMessage={userName.errorMsg}
                    error={userName.errorMsg.length > 0}
                    name="userName"
                    label="Nome"
                    type="text"
                    value={userName.value}
                    setValue={userNameHandle}
                    emojiPicker
                    emojiPickerPosition="bottom-end"
                    onChange={handleFormData}
                    onBlur={trimUserName}
                  />
                </InputWrapper>
                <InputWrapper className="readonly">
                  <span className="label">Permissão</span>
                  <span className="value">{labelPermission.get(user.permission)}</span>
                </InputWrapper>
              </Form>
            </>
          )}
          {formTab === 'email' && (
            <Form onSubmit={breakSubmit} key="profile">
              {
                /** @todo change email
                <InputWrapper>
                  <InputTextLine
                    label="Email"
                    disabled
                    value={user.email}
                  />
                </InputWrapper>
                */
              }
              <InputWrapper className="readonly">
                <span className="label">Email</span>
                <span className="value">{user.email}</span>
              </InputWrapper>
            </Form>
          )}
          {formTab === 'password' && (
            <Form onSubmit={breakSubmit} key="password">
              <InputWrapper>
                <InputTextLine
                  errorMessage={newPassword.errorMsg}
                  error={newPassword.errorMsg.length > 0}
                  name="newPassword"
                  label="Nova senha"
                  type="password"
                  value={newPassword.value}
                  onChange={handleFormData}
                />
              </InputWrapper>
              <InputWrapper>
                <InputTextLine
                  errorMessage={repeatNewPassword.errorMsg}
                  error={repeatNewPassword.errorMsg.length > 0}
                  name="repeatNewPassword"
                  label="Repita a senha"
                  type="password"
                  value={repeatNewPassword.value}
                  onChange={handleFormData}
                />
              </InputWrapper>
              <PasswordRules errors={newPasswordErrors} />
            </Form>
          )}
          {formTab === 'mfa' && (
            <Form onSubmit={breakSubmit} key="mfa">
              <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
              >
                <Button
                  onClick={addMfaNumber}
                  disabled={user.mfas.length >= 4}
                >
                  Adicionar número
                </Button>
                <Tooltip text={hidePhone ? 'Exibir números' : 'Esconder números'} atModal>
                  <IconButton onClick={togglePhone}>
                    {hidePhone ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </Tooltip>
              </div>
              <PhoneContainers>
                {user.mfas.length > 0 ? (
                  user.mfas.map((factor) => (
                    <PhoneItem key={factor.uid}>
                      <span>{phoneMask(factor.phoneNumber, factor.displayName, hidePhone)}</span>
                      <Tooltip text="Remover número" atModal>
                        <IconButton
                          size="small"
                          onClick={() => removeMfaNumber(factor)}
                        >
                          <Trash />
                        </IconButton>
                      </Tooltip>
                    </PhoneItem>
                  ))) : (
                    <span>
                      Adicione um número de telefone
                      <br />
                      para ativar a autenticação multifator
                    </span>
                )}
              </PhoneContainers>
            </Form>
          )}
        </ScrollContainer>
      </ProfileEditContainer>
      <ActionsGroup>
        <Button
          style={{ margin: '5px' }}
          onClick={closeModal}
          variant="outlined"
          size="large"
        >
          Cancelar
        </Button>
        <Button
          style={{ margin: '5px' }}
          onClick={handleSubmit}
          disabled={blockSaveBtn()}
          size="large"
        >
          Salvar
        </Button>
      </ActionsGroup>
    </>
  );
};

ProfileEdit.propTypes = {
  /**
  * User profile data (only the name, picture, email and permission is used)
  */
  user: PropTypes.shape({
    name: PropTypes.string,
    src: PropTypes.string,
    email: PropTypes.string,
    permission: PropTypes.string,
    mfas: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
  }).isRequired,
  /**
  * Function that closes the parent modal
  */
  closeModal: PropTypes.func.isRequired,
  /**
  * Function that sends the changed data back to the parent
  */
  submitFormData: PropTypes.func.isRequired,
  /**
  * Function that saves the new avatar image
  */
  submitAvatarImg: PropTypes.func.isRequired,
  /**
  * Function that verifies if the image satisties the desired conditions
  */
  checkImage: PropTypes.func,
  /**
   * Function that adds a new phone number to multifactor
   */
  addMfaNumber: PropTypes.func.isRequired,
  /**
   * Function that removes a phone number from multifactor
   */
  removeMfaNumber: PropTypes.func.isRequired,
};

ProfileEdit.defaultProps = {
  checkImage: () => {},
};

export default ProfileEdit;
