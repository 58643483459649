import React from 'react';

function DesktopUpload() {
    return (
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.3438 22.4989H12.6562C11.877 22.4989 11.25 21.8719 11.25 21.0924V11.2473H6.11133C5.06836 11.2473 4.54688 9.9873 5.28516 9.24891L14.1973 0.329638C14.6367 -0.109879 15.3574 -0.109879 15.7969 0.329638L24.7148 9.24891C25.4531 9.9873 24.9316 11.2473 23.8887 11.2473H18.75V21.0924C18.75 21.8719 18.123 22.4989 17.3438 22.4989ZM30 22.0301V28.5935C30 29.373 29.373 30 28.5938 30H1.40625C0.626953 30 0 29.373 0 28.5935V22.0301C0 21.2507 0.626953 20.6236 1.40625 20.6236H9.375V21.0924C9.375 22.9033 10.8457 24.3742 12.6562 24.3742H17.3438C19.1543 24.3742 20.625 22.9033 20.625 21.0924V20.6236H28.5938C29.373 20.6236 30 21.2507 30 22.0301ZM22.7344 27.1871C22.7344 26.5425 22.207 26.015 21.5625 26.015C20.918 26.015 20.3906 26.5425 20.3906 27.1871C20.3906 27.8317 20.918 28.3591 21.5625 28.3591C22.207 28.3591 22.7344 27.8317 22.7344 27.1871ZM26.4844 27.1871C26.4844 26.5425 25.957 26.015 25.3125 26.015C24.668 26.015 24.1406 26.5425 24.1406 27.1871C24.1406 27.8317 24.668 28.3591 25.3125 28.3591C25.957 28.3591 26.4844 27.8317 26.4844 27.1871Z" />
        </svg>
    );
}

export default DesktopUpload;