import styled from 'styled-components';
import { devices } from '../../../../juristec-ui/core/breakpoints';
import { gap } from '../../../../juristec-ui/styles/theme';

export const SeparatorLine = styled.hr`
  margin: 0 auto 10px;
  width: 15%;
  border: 0.8px solid #ddd;
`;

export const HeaderInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: 'flex-start';
  font-weight: bold;
  font-size: 15px;
  text-decoration: underline;
  margin-bottom: 10px;
  & svg {
    fill: ${({ theme }) => theme.secondary};
    margin: 0px 10px 0px 0px;
    pointer-events: none;
    width: 20px;
    height: 20px;
  }
`;

export const ContentInfo = styled.div`
  background-color: ${({ theme, device }) => device !== 'phone' ? theme.containerOdd : 'transparent'};
  padding: ${({ device }) => device !== 'phone' ? '0.5rem' : ''};
  border-radius: 0.5rem;
  margin-bottom: 2px;
  display: flex;
  flex-direction: ${({ device }) => device !== 'phone' ? 'row' : 'column'};
  justify-content: ${({ device }) => device !== 'phone' ? 'space-between' : 'flex-start'};
  align-items: ${({ device }) => device !== 'phone' ? 'center' : 'flex-start'};
  ${({ device }) => device !== 'phone' ? gap('2px', 'row') : gap('2px', 'column')};
  font-size: 13px;

  .child-item {
    background-color: ${({ theme, device }) => device !== 'phone' ? 'transparent' : theme.containerOdd};
    width: ${({ device }) => device !== 'phone' ? '' : 'calc(100% - 1rem)'};
    padding: ${({ device }) => device !== 'phone' ? '' : '0.5rem'};
    border-radius: ${({ device }) => device !== 'phone' ? '' : '0.5rem'};
  }
`;

export const BlockInfo = styled.div`
  margin: 0 1.5rem 1rem;
`;
