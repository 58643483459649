import React from 'react';
import PropTypes from 'prop-types';

import ReactPhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/plain.css';
import pt from 'react-phone-input-2/lang/pt.json';

import StyledInputPhoneLine from './styled/InputPhoneLine.styled';

/**
 * It is an input field for inserting short text and numbers.
 * It has multiple functions and supports multiple character types/formats.
 */
const InputPhoneLine = ({
  label,
  error,
  errorMessage,
  styleContainer,
  notTransparent,
  disabled,
  value,
  onChange,
  autocomplete,
  country,
  ...props
}) => (
  <StyledInputPhoneLine
    label={label}
    error={error}
    style={styleContainer}
    notTransparent={notTransparent}
    disabled={disabled}
  >
    <ReactPhoneInput
      {...props}
      className="react-phone-input"
      value={value}
      onChange={onChange}
      autoComplete={autocomplete}
      id={`inputTextLine${label}`}
      disabled={disabled}
      country={country}
      localization={pt}
      containerClass="tel-container"
      inputClass="tel-inputTxt"
      buttonClass="tel-flagBtn"
      dropdownClass="tel-Dd"
      searchClass="tel-search"
    />
    <label className="tel-labelTxt" htmlFor={`inputTextLine${label}`}>{label}</label>
    <span className="tel-highlight" />
    <span className="tel-bar" />
    {error && <span className="tel-errorMessage">{errorMessage}</span>}
  </StyledInputPhoneLine>
);

InputPhoneLine.propTypes = {
  /**
   * A boolean variable to determine if the input is disabled or not
   */
  disabled: PropTypes.bool,
  /**
   * A boolean variable to determine if there is an error with the inputed value
   */
  error: PropTypes.bool,
  /**
   * The error message to be shown in case of an error
   */
  errorMessage: PropTypes.string,
  /**
   * The label of the input
   */
  label: PropTypes.string,
  /**
   * A boolean variable to show if the background of the input must be transparent
   */
  notTransparent: PropTypes.bool,
  /**
   * A boolean variable to show if this input value is required or not
   */
  required: PropTypes.bool,
  /**
  * Autocomplete option of input tags
  */
  autocomplete: PropTypes.string,
  styleContainer: PropTypes.shape({}),
  /**
  * The default flag to be shown
  */
  country: PropTypes.string,

  value: PropTypes.string,
  onChange: PropTypes.func,
};

InputPhoneLine.defaultProps = {
  label: '',
  required: false,
  error: false,
  errorMessage: '',
  styleContainer: {},
  notTransparent: false,
  disabled: false,
  autocomplete: 'off',
  country: 'br',
  value: '',
  onChange: () => {},
};

export default InputPhoneLine;
