import styled, { css } from 'styled-components';

export const InputRadioContainer = styled.div`
  margin: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  user-select: none;
`;

export const RadioOuterCircle = styled.div`
  min-width: 18px;
  min-height: 18px;
  border: 2px solid;
  ${({
    disabled, $selected, value, color, theme,
  }) => (disabled ? css`
    border-color: ${theme.disabled};
  ` : css`
    border-color: ${$selected === value ? (theme[color] || color) : theme.grey};
  `)}
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
  transition: all 0.1s ease;
`;

export const RadioInnerCircle = styled.div`
  width: ${({ value, $selected }) => ($selected === value ? '8px' : '0')};
  height: ${({ value, $selected }) => ($selected === value ? '8px' : '0')};
  border-radius: 50%;
  ${({
    disabled, $selected, value, color, theme,
  }) => (disabled ? css`
    background-color: ${theme.disabled};
  ` : css`
    background-color: ${$selected === value ? (theme[color] || color) : theme.grey};
  `)}
  transition: all 0.1s ease;
`;

export const RadioOptionLabel = styled.div`
  justify-content: center;
  align-items: center;
  padding-right: 12px;
  color: ${({ disabled, theme }) => (disabled ? theme.disabled : theme.color)};
  font-size: 14px;
  /* font-weight: ${({ value, $selected }) => (value === $selected ? 'bold' : 'normal')}; */
`;
