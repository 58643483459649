import styled from 'styled-components';
import { devices } from '../../../../juristec-ui/core/breakpoints';

export const Container = styled.div`
  padding: 0.5rem 5%;

  @media ${devices.tablet} {
    padding: 10px 20px;
  }

  @media ${devices.phoneS} {
    align-items: flex-end;
  }
`;

export const DashboardName = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  font-weight: bold;
  font-size: 24px;

  @media ${devices.tablet} {
    font-size: 20px;
  }

  @media ${devices.phoneS} {
    font-size: 18px;
  }
`;

export const OwnerInformation = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: rgb(130, 130, 130);

  .info-title {
    font-weight: bold;
    font-size: 16px;

    @media ${devices.phoneL} {
      font-size: 12px;
    }
  }

  .info-description {
    font-size: 14px;
    color: #6e6d6d;

    @media ${devices.phoneL} {
      font-size: 12px;
    }
  }

  @media ${devices.phoneS} {
    display: none;
  };
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const DevelopmentCredits = styled.div`
  display: flex;
  gap: 10px;
  justify-content: flex-start;
  align-items: center;
  min-width: 225px;

  color: ${({ theme }) => theme.grey};
`;
