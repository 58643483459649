/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import ControllersGroup from '../../../controllers/ControllersGroup';
import {
  ToggleSwitchController,
  MarkersSelectorController,
  InputLineController,
  SelectController,
  RangeController,
  ChoiceBoxController,
  ButtonLineController,
} from '../../../controllers/Base';

import fontOptions from '../../../utils/controllersUtils/options';

const MarkerControllers = ({
  handle,
  config,
  extend,
  setExtend,
}) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  return (
    <ControllersGroup title="Linhas de Meta" id="markers" extend={extend} setExtend={setExtend}>
      <ToggleSwitchController
        label="Habilitar linhas de meta"
        handle={handle}
        kWord="ShowMarkerControl"
        configValue={config?.ShowMarkerControl}
        firstOption=""
        secondOption=""
        fullWidth
      />
      <MarkersSelectorController
        handle={handle}
        addKWord="AddMarkerLineControl"
        removeKWord="RemoveMarkerLineControl"
        markersList={config?.MarkersList}
        selectedIndex={selectedIndex}
        setSelectedIndex={setSelectedIndex}
        disabled={!config?.ShowMarkerControl}
      />
      <ToggleSwitchController
        label="Exibir linha"
        handle={(val, kWord) => handle({ attr: 'showLine', target: selectedIndex, val }, kWord)}
        kWord="MarkerAttrControl"
        configValue={config?.MarkersList?.[selectedIndex]?.showLine}
        firstOption=""
        secondOption=""
        fullWidth
        disabled={!config?.ShowMarkerControl || !config?.MarkersList?.length > 0}
      />
      <InputLineController
        key={`name-${selectedIndex}`}
        label="Nome"
        handle={(val, kWord) => handle({ attr: 'legend', target: selectedIndex, val }, kWord)}
        kWord="MarkerAttrControl"
        configValue={config?.MarkersList?.[selectedIndex]?.legend || ''}
        disabled={
          !config?.ShowMarkerControl
          || !config?.MarkersList?.length > 0
          || !config?.MarkersList?.[selectedIndex]?.showLine
        }
      />
      <InputLineController
        key={`value-${selectedIndex}`}
        label="Valor"
        handle={(val, kWord) => handle({ attr: 'value', target: selectedIndex, val }, kWord)}
        kWord="MarkerAttrControl"
        configValue={config?.MarkersList?.[selectedIndex]?.value || ''}
        disabled={
          !config?.ShowMarkerControl
          || !config?.MarkersList?.length > 0
          || !config?.MarkersList?.[selectedIndex]?.showLine
        }
      />
      <SelectController
        label="Posição"
        handle={(val, kWord) => handle({ attr: 'legendPosition', target: selectedIndex, val }, kWord)}
        kWord="MarkerAttrControl"
        options={[
          { id: 'left', label: 'Esquerda', value: 'left' },
          { id: 'right', label: 'Direita', value: 'right' },
          { id: 'top', label: 'Acima', value: 'top' },
          { id: 'top-left', label: 'Acima-Esquerda', value: 'top-left' },
          { id: 'top-right', label: 'Acima-Direita', value: 'top-right' },
          { id: 'bottom', label: 'Abaixo', value: 'bottom' },
          { id: 'bottom-left', label: 'Abaixo-Esquerda', value: 'bottom-left' },
          { id: 'bottom-right', label: 'Abaixo-Direita', value: 'bottom-right' },
        ]}
        configValue={config?.MarkersList?.[selectedIndex]?.legendPosition || 'top'}
        fullWidth
        disabled={
          !config?.ShowMarkerControl
          || !config?.MarkersList?.length > 0
          || !config?.MarkersList?.[selectedIndex]?.showLine
        }
      />
      <ChoiceBoxController
        label="Orientação"
        handle={(val, kWord) => handle({ attr: 'legendOrientation', target: selectedIndex, val }, kWord)}
        kWord="MarkerAttrControl"
        options={[
          { id: 'vertical', label: 'Vertical', value: 'vertical' },
          { id: 'horizontal', label: 'Horizontal', value: 'horizontal' },
        ]}
        configValue={config?.MarkersList?.[selectedIndex]?.legendOrientation || 'horizontal'}
        fullWidth
        disabled={
          !config?.ShowMarkerControl
          || !config?.MarkersList?.length > 0
          || !config?.MarkersList?.[selectedIndex]?.showLine
        }
      />
      <SelectController
        label="Fonte"
        handle={(val, kWord) => handle({ attr: 'fontFamily', target: selectedIndex, val }, kWord)}
        kWord="MarkerTextStyleControl"
        options={fontOptions}
        configValue={config?.MarkersList?.[selectedIndex]?.textStyle?.fontFamily || 'arial'}
        disabled={
          !config?.ShowMarkerControl
          || !config?.MarkersList?.length > 0
          || !config?.MarkersList?.[selectedIndex]?.showLine
        }
      />
      <InputLineController
        key={`fontSize-${selectedIndex}`}
        label="Tamanho"
        handle={(val, kWord) => handle({ attr: 'fontSize', target: selectedIndex, val: Number(val) }, kWord)}
        kWord="MarkerTextStyleControl"
        configValue={config?.MarkersList?.[selectedIndex]?.textStyle?.fontSize || 12}
        min={1}
        max={35}
        isNumber
        disabled={
          !config?.ShowMarkerControl
          || !config?.MarkersList?.length > 0
          || !config?.MarkersList?.[selectedIndex]?.showLine
        }
      />
      <ButtonLineController
        handle={(val, kWord) => handle({ target: selectedIndex, val }, kWord)}
        boldKWord="MarkerBoldControl"
        boldVal={config?.MarkersList?.[selectedIndex]?.textStyle?.fontWeight === 'bold'}
        italicKWord="MarkerItalicControl"
        italicVal={config?.MarkersList?.[selectedIndex]?.textStyle?.fontStyle === 'italic'}
        fontColorKWord="MarkerTextColorControl"
        fontColorVal={config?.MarkersList?.[selectedIndex]?.textStyle?.fill || '#000'}
        lineColorKWord="MarkerLineColorControl"
        lineColorVal={config?.MarkersList?.[selectedIndex]?.lineStyle?.stroke || '#000'}
        disabled={
          !config?.ShowMarkerControl
          || !config?.MarkersList?.length > 0
          || !config?.MarkersList?.[selectedIndex]?.showLine
        }
        fullWidth
      />
      <ChoiceBoxController
        label="Estilo da linha"
        handle={(val, kWord) => handle({ target: selectedIndex, val }, kWord)}
        kWord="MarkerLineFormatControl"
        options={[
          { id: 'normal', label: 'Contínua', value: 'normal' },
          { id: 'dashed', label: 'Tracejada', value: 'dashed' },
          { id: 'dotted', label: 'Pontilhada', value: 'dotted' },
        ]}
        configValue={config?.MarkersList?.[selectedIndex]?.lineStyle?.strokeStyle}
        fullWidth
        disabled={
          !config?.ShowMarkerControl
          || !config?.MarkersList?.length > 0
          || !config?.MarkersList?.[selectedIndex]?.showLine
        }
      />
      <RangeController
        key={`lineSize-${selectedIndex}`}
        label="Tamanho da linha"
        handle={(val, kWord) => handle({ target: selectedIndex, val: Number(val) }, kWord)}
        kWord="MarkerLineSizeControl"
        configValue={config?.MarkersList?.[selectedIndex]?.lineStyle?.strokeWidth || 2}
        min={1}
        max={15}
        fullWidth
        disabled={
          !config?.ShowMarkerControl
          || !config?.MarkersList?.length > 0
          || !config?.MarkersList?.[selectedIndex]?.showLine
        }
      />
    </ControllersGroup>
  );
};

MarkerControllers.propTypes = {
  config: PropTypes.objectOf(PropTypes.any),
  handle: PropTypes.func,
  extend: PropTypes.string,
  setExtend: PropTypes.func,
};

MarkerControllers.defaultProps = {
  config: undefined,
  handle: () => {},
  extend: '',
  setExtend: () => {},
};

export default MarkerControllers;
