import React from 'react';

const ChartPie = ({ primary = '#4711B2', secondary = '#FF9626' }) => (
  <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M64.1669 36.1668H33.8335V5.83341C33.8335 5.18946 33.3109 4.66678 32.6669 4.66678C14.6254 4.66664 0 19.2921 0 37.3336C0 55.375 14.6254 70.0003 32.6668 70.0003C50.7081 70.0003 65.3336 55.3749 65.3336 37.3336C65.3337 36.6895 64.811 36.1668 64.1669 36.1668Z" fill={primary} />
    <path d="M37.3326 0C36.6887 0 36.166 0.522678 36.166 1.16663V32.6668C36.166 33.3107 36.6887 33.8334 37.3326 33.8334H68.8328C69.4767 33.8334 69.9994 33.3107 69.9994 32.6668C69.9784 14.6336 55.3658 0.0210548 37.3326 0Z" fill={secondary} />
  </svg>
);

export default ChartPie;
