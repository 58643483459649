import styled, { css } from 'styled-components';
import { gap } from '../../../../juristec-ui/styles/theme';
import { devices } from '../../../../juristec-ui/core/breakpoints';

export const StyledGridKpisToolbar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0.5rem 5%;
  // height: 90px;
  height: auto;

  @media ${devices.tablet} {
    padding: 10px 20px;
    height: auto;
  }

  @media ${devices.phoneS} {
    flex-flow: column nowrap;
    ${gap('5px', 'column')}
    align-items: flex-start;
    height: auto;
  }

  & .gap-fix {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    ${gap('12px', 'row')}
  }
`;

export const LeftGridKpisToolbar = styled.div`
  display: flex;
  ${gap('10px', 'column')}
  justify-content: flex-start;
  flex-flow: column nowrap;
  align-items: flex-start;
  white-space: nowrap;
`;

export const RightGridKpisToolbar = styled.div`
  display: flex;
  ${gap('15px', 'column')}
  margin-top: ${({ show }) => (show ? '0px' : '-15px')};
  justify-content: space-between;
  align-items: flex-end;
  flex-flow: column nowrap;

  @media ${devices.tablet} {
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: flex-end;
  };

  @media ${devices.phoneL} {
    margin-top: -15px;
  };

  @media ${devices.phoneS} {
    margin-top: -10px;
  };
`;

export const DashboardInformation = styled.div`
  display: flex;
  ${gap('15px', 'row')}
  justify-content: flex-start;
  align-items: center;

  span {
    font-size: 18px;
    font-weight: bold;
    color: ${({ theme }) => theme.toolbarFont};

    @media ${devices.tablet} {
      font-size: 17px;
    }

    @media ${devices.phoneS} {
      font-size: 16px;
    }
  }

  & span.dashName {
    font-size: 18px;

    @media ${devices.tablet} {
      font-size: 16px;
    }

    @media ${devices.phoneS} {
      font-size: 14px;
      max-width: 80%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  @media ${devices.phoneS} {
    .dashGroup {
      display: none;
    }
  } ;
`;

export const OwnerInformation = styled.div`
  display: ${({ show }) => (show ? 'flex' : 'none')};
  flex-direction: column;
  align-items: flex-start;
  color: ${({ theme }) => theme.toolbarFont};

  .info-title {
    font-weight: bold;
    font-size: 16px;

    @media ${devices.phoneL} {
      font-size: 12px;
    }
  }

  .info-description {
    font-size: 14px;
    color: ${({ theme }) => theme.toolbarFont};

    @media ${devices.phoneL} {
      font-size: 12px;
    }
  }

  @media ${devices.phoneL} {
    display: none;
  } ;
`;

export const GridKpisToolbarActions = styled.div`
  display: ${({ show }) => (show ? 'flex' : 'none')};
  align-items: flex-start;
  ${({ show }) => (show ? gap('12px', 'row') : gap('0px', 'row'))};

  @media ${devices.phoneL} {
    display: none;
  }
`;

export const StyledSelectStory = styled.div`
  background-color: ${({ theme }) => theme.containerHover};
  border-radius: 8px;
  box-shadow: 0px 4px 4px ${({ theme }) => theme.shadow};
  outline: none;
  height: 34px;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.color};
  padding: 0px 10px;
  padding-right: 25px;
  font-weight: 500;
  cursor: pointer;
  max-width: 175px;
  min-width: 140px;
  position: relative;
  font-size: 0.9rem;
  transition: width 500ms ease-in-out;
  &:hover {
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.25), 0px 2px 2px 0px rgba(0, 0, 0, 0.27),
      0px 1px 5px 0px rgba(0, 0, 0, 0.27);
    opacity: 0.85;
  }

  & > span {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  & > svg:last-child {
    position: absolute;
    fill: ${({ theme }) => theme.primary};
    top: 50%;
    right: -9px;
    transform: translate(-50%, -50%);
    transition: all 0.5s;
    ${({ open }) => open && css`
      transform: translate(-50%, -50%) rotate(180deg);
    `};
  }

  @media ${devices.phoneS} {
    width: 80px;
  } ;
`;

export const StyledBadgEdit = styled.div`
  position: absolute;
  width: 25px;
  height: 25px;
  background-color: ${({ theme }) => theme.white};
  top: 5px;
  right: 5px;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 3px --2px;
  border-style: none;
  cursor: pointer;

  & > svg {
    width: 15px;
    height: 15px;
    position: absolute;
    top: 5px;
    right: 5px;
    pointer-events: none;
    & > path {
      fill: ${({ theme }) => theme.primary};
    }
  }
`;

export const Icon = styled.div`
  margin: 0 0.5rem 0 0;
  padding: 6px;
  width: 16px;
  height: 16px;
  & svg {
    width: 16px;
    height: 16px;
    fill: ${({ theme }) => theme.grey};
  }
`;
