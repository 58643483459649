import React from "react";

export default function Italic() {
  return (
    <svg
      width="17"
      height="25"
      viewBox="0 0 17 25"
      fill="#FFF"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M16.0807 0.04076C15.7011 0.0680093 15.332 0.0925793 14.9738 0.114128C13.3678 0.211553 12.2067 0.260522 11.4907 0.260522C11.1218 0.260522 10.7256 0.249519 10.3026 0.227971L5.14334 0L4.83402 1.67646C4.94245 1.69801 5.1487 1.7199 5.45254 1.74162C6.56999 1.80649 7.35133 1.95859 7.79621 2.19728V2.8158L7.66601 3.6294L7.30801 5.82668L7.04748 6.85212L6.57547 9.4074C6.57547 9.4184 6.55643 9.48083 6.51846 9.59478C6.48049 9.70863 6.43158 9.87406 6.37207 10.091C6.31227 10.3082 6.2446 10.5605 6.16849 10.848C6.09239 11.1354 6.00568 11.499 5.90797 11.9385C5.81032 12.3779 5.71803 12.8364 5.63132 13.3137L5.4359 14.3555L4.52458 18.7175L4.08506 20.9794C3.9548 21.6742 3.73253 22.2222 3.41785 22.6233C2.9838 22.8405 2.35444 23.052 1.52978 23.2584C0.737727 23.4643 0.320037 23.5729 0.276655 23.5838L0 24.9675C0.260237 24.9458 0.791884 24.8971 1.59494 24.8208C3.03807 24.6907 3.98205 24.631 4.42682 24.6419L7.64954 24.6743C9.0165 24.8155 9.80309 24.9076 10.0091 24.9513C10.2153 24.9836 10.3673 25 10.4648 25C10.6603 25 10.888 24.9891 11.1484 24.9674C11.2026 24.9567 11.3275 24.9513 11.5228 24.9513C11.5444 24.8428 11.5932 24.6202 11.6692 24.2836C11.734 23.969 11.7721 23.6544 11.7832 23.3395C11.4574 23.2855 11.0996 23.231 10.7086 23.1771C10.1122 23.112 9.46629 22.9979 8.77177 22.8353C8.73962 22.6184 8.73409 22.4719 8.75575 22.3958L8.95129 21.6634L9.65122 17.8388L10.2697 15.2671L11.2626 10.2053C11.5011 9.04427 11.8593 7.3844 12.337 5.22486C12.3805 4.81258 12.4508 4.36764 12.5487 3.89015C12.6788 3.27174 12.8093 2.79442 12.9393 2.45785C13.3408 2.29521 13.8889 2.1271 14.5831 1.95346C15.1695 1.82342 15.7606 1.6552 16.3572 1.44906C16.4226 1.21031 16.4934 0.93366 16.5691 0.618981C16.6128 0.412673 16.6452 0.20665 16.6666 0.000570069C16.6558 0.000171021 16.4604 0.0136817 16.0807 0.04076Z" />
    </svg>
  );
}
