/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Checkbox from '../../../juristec-ui/core/Checkbox';
import InputRadio from '../../../juristec-ui/core/InputRadio';
import Button from '../../../juristec-ui/core/Button';
import Loading from '../../../juristec-ui/core/MiniLoading';
import InputTextLine from '../../../juristec-ui/core/InputTextLine';
import Toggle from '../../../juristec-ui/core/Toggle';
import ControlFilterItem from './ControlFilterItem';

import {
  FilterContainer,
  ScrollContainer,
  OrderRow,
  ActionGroup,
  OrderVar,
  ErrorContainer,
} from './styled/ControlFilter.styled';

const ControlFilter = ({
  controlVar,
  controlOptions,
  controlFilterValues,
  close,
  handleFilter,
  isRadio,
  extraToggle,
  errorMsg,
}) => {
  const [selected, setSelected] = useState();
  const [filteredOptions, setFilteredOptions] = useState(controlOptions);
  const [filterInput, setFilterInput] = useState('');
  const [extraToggleVal, setExtraToggleVal] = useState(extraToggle?.value || false);
  /** Check if the filter has any changes */
  const isDirty = useRef(false);
  const cron = useRef();

  /* Check if all the options are selected */
  const checkAllSelected = () => selected?.length === controlOptions?.length;

  /**
   * Handles selection of all options
   * @param {boolean} check Checkbox check state
   */
  const toggleAll = (check) => {
    isDirty.current = true;
    setSelected(() => (check ? controlOptions?.map((opt) => opt.value) : []));
  };

  /**
   * Handles selection of an option
   * @param {boolean} check Checkbox check state
   * @param {string} key Checkbox identifier (value)
   */
  const handleCheck = (check, key) => {
    isDirty.current = true;
    if (isRadio) {
      setSelected([check]);
    } else {
      setSelected((o) => (check ? (
        [...o, key]
      ) : (
        o.filter((k) => k !== key)
      )));
    }
  };

  const handleExtraToggle = (e) => {
    isDirty.current = true;
    setExtraToggleVal(e.target.checked);
  };

  useEffect(() => {
    if (controlOptions) {
      setFilteredOptions(controlOptions);
      if (isRadio) {
        setSelected([controlFilterValues?.[0] || '']);
      } else if (controlFilterValues?.length > 0) {
        setSelected(controlFilterValues);
      } else {
        setSelected(controlOptions?.map((opt) => opt.value));
      }
    }
  }, [controlOptions]);

  /** Sends the filter values to be filtered */
  const sendFilter = () => {
    handleFilter(selected);
    if (extraToggle) handleFilter(extraToggleVal, extraToggle.field);
    close();
  };

  /**
   * Handles search bar input
   * @param {Event} e Input text event
   */
  const handleFilterInput = (e) => {
    const val = e.target.value;
    setFilterInput(val);
    clearTimeout(cron.current);
    cron.current = setTimeout(() => {
      // filters options after 200 milliseconds with no input
      setFilteredOptions(
        controlOptions.filter((cOpt) => (
          cOpt.label.toLowerCase().includes(val.toLowerCase())
        )),
      );
    }, 200);
  };

  return (
    <FilterContainer>
      {errorMsg.length > 0 ? (
        <ErrorContainer>
          {errorMsg.map((msg) => (
            <span key={msg}>{msg}</span>
          ))}
        </ErrorContainer>
      ) : (
        <>
          {controlVar.length > 0 && (
            <OrderVar>
              <span>{controlVar}</span>
            </OrderVar>
          )}
          {!controlOptions ? (
            <div style={{
              width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center',
            }}
            >
              <Loading fill="primary" />
            </div>
          ) : (
            <>
              <OrderRow>
                <InputTextLine
                  value={filterInput}
                  placeholder="Pesquisar..."
                  onChange={handleFilterInput}
                />
              </OrderRow>
              <OrderRow key={`all${checkAllSelected()}`} className="hover-bgcolor">
                {isRadio ? (
                  <InputRadio
                    value=""
                    text="Selecionar todos"
                    color="primary"
                    variant="outlined"
                    selected={selected?.[0]}
                    onChange={handleCheck}
                    style={{ margin: '0' }}
                  />
                ) : (
                  <Checkbox
                    text="Selecionar todos"
                    variant="outlined"
                    style={{ width: '100%', justifyContent: 'flex-start' }}
                    checked={checkAllSelected()}
                    handleCheckboxChange={(check) => toggleAll(check)}
                  />
                )}
              </OrderRow>
              <ScrollContainer>
                {filteredOptions?.map((opt) => (
                  <ControlFilterItem
                    key={opt.value}
                    option={opt}
                    handler={handleCheck}
                    selected={selected}
                    isRadio={isRadio}
                  />
                ))}
              </ScrollContainer>
              {extraToggle && (
                <OrderRow>
                  <span style={{ width: '100%' }}>{extraToggle.label}</span>
                  <div style={{ width: 'min-content' }}>
                    <Toggle
                      checked={extraToggleVal}
                      handleChange={handleExtraToggle}
                      size="small"
                    />
                  </div>
                </OrderRow>
              )}
              <ActionGroup>
                <Button
                  size="small"
                  variant="outlined"
                  onClick={close}
                  style={{ width: '100%', minWidth: '100px' }}
                >
                  Cancelar
                </Button>
                <Button
                  size="small"
                  onClick={sendFilter}
                  disabled={selected?.length === 0 || !isDirty?.current}
                  style={{ width: '100%', minWidth: '100px' }}
                >
                  Aplicar
                </Button>
              </ActionGroup>
            </>
          )}
        </>
      )}
    </FilterContainer>
  );
};

ControlFilter.propTypes = {
  controlVar: PropTypes.string,
  controlOptions: PropTypes.arrayOf(PropTypes.any),
  controlFilterValues: PropTypes.arrayOf(PropTypes.any),
  close: PropTypes.func,
  handleFilter: PropTypes.func,
  isRadio: PropTypes.bool,
  errorMsg: PropTypes.arrayOf(PropTypes.string),
  extraToggle: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.bool,
    field: PropTypes.string,
  }),
};

ControlFilter.defaultProps = {
  controlVar: '',
  controlOptions: undefined,
  controlFilterValues: undefined,
  close: () => {},
  handleFilter: () => {},
  isRadio: false,
  errorMsg: [],
  extraToggle: undefined,
};

export default ControlFilter;
