/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';

import ControllersGroup from '../../../controllers/ControllersGroup';
import {
  SelectController,
  RangeController,
  ChoiceBoxController,
  SimpleColorPickerController,
  ToggleSwitchController,
  GradientColorPickerController,
} from '../../../controllers/Base';

import { getMapThemes } from '../../../utils/controllersUtils/themeColors';

const StyleControllers = ({
  handle,
  config,
  data,
  extend,
  setExtend,
  isGlobalThemeActive,
}) => (
  <>
    <ControllersGroup
      title="Estilos"
      id="styles"
      extend={extend}
      setExtend={setExtend}
      sizeIncrease={config?.PalletsControl === 'custom' ? data?.index?.length : 0}
    >
      <RangeController
        label="Tamanho da borda"
        handle={handle}
        kWord="BorderWidthControl"
        configValue={config?.BorderWidthControl?.value}
        min={0}
        max={20}
        fullWidth
      />
      <SimpleColorPickerController
        label="Cor da borda"
        handle={handle}
        kWord="BordeColorMapsControl"
        configValue={config?.BordeColorMapsControl || '#000'}
        orientation="row"
        fullWidth
        disabled={!config?.BorderWidthControl?.value || config?.BorderWidthControl?.value.toString() === '0'}
      />
      <ChoiceBoxController
        label="Paleta de cores"
        handle={handle}
        kWord="PalletsControl"
        options={[
          { label: 'Padrão', value: 'default', id: 'default' },
          { label: 'Degradê', value: 'gradient', id: 'gradient' },
        ]}
        configValue={config?.PalletsControl}
        fullWidth
        text="Utilizando tema global"
        disabled={config?.UseGlobalColor !== false && isGlobalThemeActive}
      />
      {config?.PalletsControl === 'default' && (
        <SelectController
          label="Tema"
          handle={handle}
          kWord="ColorTheme"
          options={getMapThemes()}
          configValue={config?.ColorTheme?.scheme || 'nivo'}
          fullWidth
          text="Utilizando tema global"
          disabled={config?.UseGlobalColor !== false && isGlobalThemeActive}
        />
      )}
      {config?.PalletsControl === 'gradient' && (
        <GradientColorPickerController
          handle={handle}
          firstColorVal={config?.GradientPickerControl?.color1 || '#4711B2'}
          firstColorKWord="GradientFirstColor"
          secondColorVal={config?.GradientPickerControl?.color2 || '#FF9626'}
          secondColorKWord="GradientSecondColor"
          thirdColorVal={config?.GradientPickerControl?.color3}
          thirdColorKWord="GradientThirdColor"
          fullWidth
          orientation="row"
          text="Utilizando tema global"
          disabled={config?.UseGlobalColor !== false && isGlobalThemeActive}
        />
      )}
      <ToggleSwitchController
        label="Utilizar tema global"
        handle={handle}
        kWord="UseGlobalColor"
        configValue={config?.UseGlobalColor}
        firstOption=""
        secondOption=""
        fullWidth
      />
    </ControllersGroup>
  </>
);

StyleControllers.propTypes = {
  config: PropTypes.objectOf(PropTypes.any),
  data: PropTypes.objectOf(PropTypes.any),
  handle: PropTypes.func,
  extend: PropTypes.string,
  setExtend: PropTypes.func,
  isGlobalThemeActive: PropTypes.bool,
};

StyleControllers.defaultProps = {
  config: undefined,
  data: undefined,
  handle: () => {},
  extend: '',
  setExtend: () => {},
  isGlobalThemeActive: false,
};

export default StyleControllers;
