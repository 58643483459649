import React, {
  useEffect, useState, useRef, useContext,
} from 'react';
import { useTheme } from 'styled-components';
import { useHistory } from 'react-router-dom';
import firebase from '../../utils/firebase';

import { AlertContext } from '../../context/AlertProvider';
import Button from '../../juristec-ui/core/Button';
import IconButton from '../../juristec-ui/core/IconButton';
import Loader from '../../juristec-ui/core/Loader';
import InputTextLine from '../../juristec-ui/core/InputTextLine';
import { Visibility, VisibilityOff, CircleError, CircleSuccess } from '../../juristec-ui/icons';

import detectMobile from '../../utils/detectMobile';
import {
  StyledContainerForm,
  StyledFormPassword,
  IconType,
  PasswordInformation,
} from './styled/HandleActionUrlPage.styled';

import { verifyPassword, verifyRepeatPassword } from '../../juristec-ui/utils/validators/inputTextValidators';

const HandleActionUrl = () => {
  const history = useHistory();

  const { setAlertConfig } = useContext(AlertContext);

  const isMobile = useRef(false);

  const [email, setEmail] = useState('');
  const passwordRef = useRef();
  const confirmRef = useRef();
  const [btnWaiting, setBtnWaiting] = useState(false);
  const [newPassword, setNewPassword] = useState({ value: '', error: true, errorMsg: '' });
  const [confirmPassword, setConfirmPassword] = useState({ value: '', error: true, errorMsg: '' });
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  // if invalid Link
  const [invalidLink, setInvalidLink] = useState(false);
  const [invalidLinkTitle, setInvalidLinkTitle] = useState('');
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState({
    specialCharacterError: true,
    numberError: true,
    capitalError: true,
    lowerError: true,
    lengthError: true,
  });
  //   const [reSendEmail, setReSendEmail] = useState(' ');

  const theme = useTheme();

  const focusIfNotMobile = (ref) => {
    if (!isMobile.current) ref.focus();
  };

  function getParameterByName(name) {
    if (name) {
      let tmp = name;
      tmp = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
      const regexS = `[\\?&]${tmp}=([^&#]*)`;
      const regex = new RegExp(regexS);
      const results = regex.exec(window.location.href);
      if (results == null) return '';
      return decodeURIComponent(results[1].replace(/\+/g, ' '));
    }
    return {};
  }

  const getUrlParameteres = () => ({
    mode: getParameterByName('mode'),
    actionCode: getParameterByName('oobCode'),
    continueUrl: getParameterByName('continueUrl'),
    lang: getParameterByName('lang') || 'pt-BR',
    firstTime: getParameterByName('f') || false,
  });

  const { mode, actionCode, firstTime /* continueUrl, lang, firstTime */ } = getUrlParameteres();

  const redirectToForget = () => history.push('/reset_password');

  const handleSubmitErrorCodes = (code) => {
    if (code === 'auth/weak-password') {
      setAlertConfig({
        text: 'Senha fraca. Tente outra senha.',
        type: 'warning',
        child: '',
        okFunction: () => focusIfNotMobile(passwordRef.current),
      });
    } else if (code === 'auth/invalid-action-code') {
      setAlertConfig({
        text: 'Link Inválido.',
        type: 'error',
        child: '',
        okfunction: () => { setInvalidLink(true); setInvalidLinkTitle('Link inválido'); },
      });
    }
  };

  const passwordHandle = (val) => {
    const {
      message,
      specialCharacterError,
      numberError,
      capitalError,
      lowerError,
      lengthError,
    } = verifyPassword(val);
    setNewPassword({
      value: val,
      error: message.length !== 0,
      errorMsg: message,
    });
    setErrors({
      specialCharacterError,
      numberError,
      capitalError,
      lowerError,
      lengthError,
    });
  };

  const repeatPasswordHandle = (val) => {
    const msg = verifyRepeatPassword(val, newPassword.value);
    setConfirmPassword({
      value: val,
      error: msg.length !== 0,
      errorMsg: msg,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!(newPassword.error || confirmPassword.error || errors.specialCharacterError
      || errors.numberError || errors.capitalError || errors.lowerError || errors.lengthError)) {
      try {
        if (newPassword.length < 8) {
          setAlertConfig({
            text: 'Senha precisa ter 8 ou mais caracteres',
            type: 'warning',
            child: '',
            okFunction: () => focusIfNotMobile(passwordRef.current),
          });
          return;
        }

        setBtnWaiting(true);
        const { error, code } = await firebase.resetPasswordWithActionCode(actionCode, newPassword.value);
        // console.log({ code, error });
        if (error) {
          handleSubmitErrorCodes(code);
        } else {
          setAlertConfig({
            text: 'Sua senha foi cadastrada com sucesso',
            type: 'success',
            child: '',
            okFunction: () => history.push('/login'),
          });
        }

        setBtnWaiting(false);
      } catch (error) {
        setAlertConfig({
          text: 'Algum erro aconteceu. Tente novamente mais tarde  ou entre em contato com o administrador do sistema',
          type: 'error',
          child: '',
          okFunction: () => focusIfNotMobile(passwordRef.current),
        });
        setBtnWaiting(false);
      }
    } else {
      setAlertConfig({
        type: 'error',
        text: 'Não foi possível cadastrar uma senha de acesso',
        child: 'Você precisa preencher todos os campos obrigatórios. Verifique se não existe nenhum campo com erro e tente novamente!',
      });
    }
  };

  useEffect(() => {
    const verifyActionCode = async () => {
      if (!mode || !actionCode) history.push('/'); // sem parametros n acessa essa pág.
      // mode pode ser diferente de resetPassword...
      const {
        error, email: emailFromCode, code, /* fullError, */
      } = await firebase.verifyActionCodeAndReturnEmail(actionCode);

      if (error) {
        if (code === 'auth/expired-action-code') setInvalidLinkTitle('O seu link expirou.');// Entre em contato com o administrador do sistema.';
        if (code === 'auth/invalid-action-code') setInvalidLinkTitle('O seu link perdeu a validade.');// Entre em contato com o administrador do sistema.';
        setLoading(false);
        setInvalidLink(true);
        return;
      }
      setEmail(emailFromCode);
      setLoading(false);
      if (detectMobile()) isMobile.current = true;
    };

    verifyActionCode();
  }, []);

  useEffect(() => {
    if (!loading) focusIfNotMobile(passwordRef.current);
  }, [loading]);

  const handlePrevent = (e) => { e.preventDefault(); };

  useEffect(() => {
    if (confirmRef.current && !invalidLink) {
      confirmRef.current.addEventListener('paste', handlePrevent);
    }
    return () => {
      if (confirmRef.current && !invalidLink) {
        confirmRef.current.removeEventListener('paste', handlePrevent);
      }
    };
  }, []);

  if (loading) return <Loader />;
  return (
    <StyledContainerForm>
      {/* -------------------------------------------------------- */}
      {invalidLink ? (
        <div>
          <h2 style={{ color: 'white' }}>
            {invalidLinkTitle}
          </h2>
          <p style={{ color: 'white' }}>
            Por favor, peça um novo link de recuperação de senha
            {' '}
            <span
              onKeyDown={{}}
              role="button"
              tabIndex="0"
              style={{ fontStyle: 'italic', fontWeight: 'bold', textDecoration: 'underline', cursor: 'pointer' }}
              onClick={() => redirectToForget()}
            >
              aqui.
            </span>
          </p>
        </div>
        /* -------------------------------------------------------- */
      ) : (
        <StyledFormPassword onSubmit={handleSubmit}>
          {theme.logo}
          <p>
            {`Olá ${email.split('@')[0]}! Por favor`}
            {' '}
            {firstTime ? 'cadastre sua senha' : 'digite sua nova senha'}
          </p>
          <div style={{ position: 'relative' }}>
            <InputTextLine
              label="Senha*"
              ref={passwordRef}
              type={!showPassword ? 'password' : 'text'}
              value={newPassword.value}
              error={newPassword.errorMsg.length > 0}
              errorMessage={newPassword.errorMsg}
              // required
              onChange={(e) => passwordHandle(e.target.value)}
            />
            <IconButton
              style={{
                position: 'absolute', bottom: 2, right: 2, boxShadow: 'none',
              }}
              type="button" // N deixar esse btn ativar o form
              variant="pattern"
              iconColor="black"
              onMouseDown={() => setShowPassword(!showPassword)}
            >
              {showPassword ? <Visibility /> : <VisibilityOff /> }
            </IconButton>
          </div>
          <div style={{ position: 'relative', marginTop: 10 }}>
            <InputTextLine
              label="Repetir senha*"
              ref={confirmRef}
              type={!showPasswordConfirm ? 'password' : 'text'}
              value={confirmPassword.value}
              // required
              onChange={(e) => repeatPasswordHandle(e.target.value)}
              error={confirmPassword.errorMsg.length > 0}
              errorMessage={confirmPassword.errorMsg}
              autoComplete="off"
            />
            <IconButton
              style={{
                position: 'absolute', bottom: 2, right: 2, boxShadow: 'none',
              }}
              type="button" // N deixar esse btn ativar o form
              variant="pattern"
              iconColor="black"
              onMouseDown={() => setShowPasswordConfirm(!showPasswordConfirm)}
            >
              {showPasswordConfirm ? <Visibility /> : <VisibilityOff /> }
            </IconButton>
          </div>
          <PasswordInformation>
            <span>
              Sua senha deve incluir:
            </span>
            <IconType type={errors.lengthError ? 'error' : 'success'}>
              {errors.lengthError ? <CircleError /> : <CircleSuccess />}
              <p style={{ margin: '2px' }}>
                No mínimo 8 caracteres
              </p>
            </IconType>
            <IconType type={errors.lowerError ? 'error' : 'success'}>
              {errors.lowerError ? <CircleError /> : <CircleSuccess />}
              <p style={{ margin: '2px' }}>
                Pelo menos uma letra minúscula (a-z)
              </p>
            </IconType>
            <IconType type={errors.capitalError ? 'error' : 'success'}>
              {errors.capitalError ? <CircleError /> : <CircleSuccess />}
              <p style={{ margin: '2px' }}>
                Pelo menos uma letra maiúscula (A-Z)
              </p>
            </IconType>
            <IconType type={errors.numberError ? 'error' : 'success'}>
              {errors.numberError ? <CircleError /> : <CircleSuccess />}
              <p style={{ margin: '2px' }}>
                Pelo menos um número (0-9)
              </p>
            </IconType>
            <IconType type={errors.specialCharacterError ? 'error' : 'success'}>
              {errors.specialCharacterError ? <CircleError /> : <CircleSuccess />}
              <p style={{ margin: '2px' }}>
                Pelo menos um caractere especial (ex: !@#$%*)
              </p>
            </IconType>
          </PasswordInformation>
          <div style={{
            marginTop: '10px', width: '100%', display: 'flex', justifyContent: 'center',
          }}
          >
            <Button
              isLoading={btnWaiting}
              type="submit"
              size="large"
              // disabled={
              //   confirmPassword.length < 6
              //   || newPassword.length < 6
              //   || newPassword !== confirmPassword
              // }
            >
              Cadastrar
            </Button>
          </div>
        </StyledFormPassword>
      )}
    </StyledContainerForm>
  );
};

export default HandleActionUrl;
