import React from 'react';
import PropTypes from 'prop-types';

// import { Container } from './styles';

const Users = ({ width, height, fill }) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill={fill} xmlns="http://www.w3.org/2000/svg">
    <path d="M3.6 10.2857C4.92375 10.2857 6 9.19665 6 7.85714C6 6.51763 4.92375 5.42857 3.6 5.42857C2.27625 5.42857 1.2 6.51763 1.2 7.85714C1.2 9.19665 2.27625 10.2857 3.6 10.2857ZM20.4 10.2857C21.7238 10.2857 22.8 9.19665 22.8 7.85714C22.8 6.51763 21.7238 5.42857 20.4 5.42857C19.0763 5.42857 18 6.51763 18 7.85714C18 9.19665 19.0763 10.2857 20.4 10.2857ZM21.6 11.5H19.2C18.54 11.5 17.9438 11.7694 17.5088 12.2058C19.02 13.0444 20.0925 14.5585 20.325 16.3571H22.8C23.4638 16.3571 24 15.8145 24 15.1429V13.9286C24 12.5891 22.9238 11.5 21.6 11.5ZM12 11.5C14.3213 11.5 16.2 9.59888 16.2 7.25C16.2 4.90112 14.3213 3 12 3C9.67875 3 7.8 4.90112 7.8 7.25C7.8 9.59888 9.67875 11.5 12 11.5ZM14.88 12.7143H14.5688C13.7888 13.0938 12.9225 13.3214 12 13.3214C11.0775 13.3214 10.215 13.0938 9.43125 12.7143H9.12C6.735 12.7143 4.8 14.6723 4.8 17.0857V18.1786C4.8 19.1842 5.60625 20 6.6 20H17.4C18.3938 20 19.2 19.1842 19.2 18.1786V17.0857C19.2 14.6723 17.265 12.7143 14.88 12.7143ZM6.49125 12.2058C6.05625 11.7694 5.46 11.5 4.8 11.5H2.4C1.07625 11.5 0 12.5891 0 13.9286V15.1429C0 15.8145 0.53625 16.3571 1.2 16.3571H3.67125C3.9075 14.5585 4.98 13.0444 6.49125 12.2058Z" />
  </svg>
);

Users.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

Users.defaultProps = {
  width: '18px',
  height: '18px',
  fill: 'none',
};

export default Users;
