import React from 'react';
import PropTypes from 'prop-types';

import {
  Company,
  Info,
  Key,
} from '../../../juristec-ui/icons';

import {
  SeparatorLine,
  BlockInfo,
  HeaderInfo,
  ContentInfo,
} from './styled/UserInfo.styled';

import { formatDateTime } from '../../../juristec-ui/utils/functions/lab';

/**
 * A component for displaying information from a kpi
 */
const UserInfo = ({
  user,
  device,
}) => {
  const getPermission = (role) => {
    let permission = 'Desonhecido';
    switch (role) {
      case 'super':
        permission = 'Proprietário';
        break;
      case 'scientist':
        permission = 'Cientista';
        break;
      case 'guest':
        permission = 'Convidado';
        break;
      default:
        break;
    }
    return permission;
  };

  return (
    <div style={device !== 'phone' ? { minWidth: "500px" } : {}}>
      {/* Main Info */}
      <BlockInfo>
        <HeaderInfo>
          <Info />
          <span>Informações Principais</span>
        </HeaderInfo>
        <ContentInfo device={device}>
          <div className="child-item">
            <span style={{ fontWeight: 'bold' }}>Nome: </span>
            <span>{user?.name ? user.name : user.email.split('@')[0]}</span>
          </div>
        </ContentInfo>
        <ContentInfo>
          <div className="child-item">
            <span style={{ fontWeight: 'bold' }}>E-mail: </span>
            <span>{user?.email ? user.email : ''}</span>
          </div>
        </ContentInfo>
        <ContentInfo>
          <div className="child-item">
            <span style={{ fontWeight: 'bold' }}>Id: </span>
            <span>{user?.uid || ''}</span>
          </div>
        </ContentInfo>
        <ContentInfo device={device}>
          <div className="child-item">
            <span style={{ fontWeight: 'bold' }}>Permissão: </span>
            <span>{user?.role ? getPermission(user.role) : 'Desconhecido'}</span>
          </div>
          <div className="child-item">
            <span style={{ fontWeight: 'bold' }}>Dashboards: </span>
            <span>{user?.nr_dashboards ? user.nr_dashboards : 0}</span>
          </div>
        </ContentInfo>
        <ContentInfo>
          <div className="child-item">
            <span style={{ fontWeight: 'bold' }}>Último Login: </span>
            <span>{user?.lasLogin ? user.lastLogin : '-'}</span>
          </div>
        </ContentInfo>
      </BlockInfo>
      <SeparatorLine />

      {/* Company Info */}
      <BlockInfo>
        <HeaderInfo>
          <Company />
          <span>Informações da Empresa</span>
        </HeaderInfo>
        <ContentInfo device={device}>
          <div className="child-item">
            <span style={{ fontWeight: 'bold' }}>Nome: </span>
            <span>{user?.company?.name ? user.company.name : '-'}</span>
          </div>
          <div className="child-item">
            <span style={{ fontWeight: 'bold' }}>Status </span>
            <span>{user?.company?.operationalStatus ? user.company.operationalStatus : '-'}</span>
          </div>
        </ContentInfo>
        <ContentInfo device={device}>
          <div className="child-item">
            <span style={{ fontWeight: 'bold' }}>Plano: </span>
            <span>{user?.company?.plan ? user.company.plan : '-'}</span>
          </div>
          <div className="child-item">
            <span style={{ fontWeight: 'bold' }}>Antecipei: </span>
            <span>{user?.company?.push ? 'Sim' : 'Não'}</span>
          </div>
        </ContentInfo>
      </BlockInfo>
      <SeparatorLine />

      {/* Security Info */}
      <BlockInfo>
        <HeaderInfo>
          <Key />
          <span>Informações de Segurança</span>
        </HeaderInfo>
        <ContentInfo device={device}>
          <div className="child-item">
            <span style={{ fontWeight: 'bold' }}>Aceite da Política: </span>
            <span>{user?.acceptedPolicyDate ? 'Sim' : 'Não'}</span>
          </div>
          <div className="child-item">
            <span style={{ fontWeight: 'bold' }}>Data </span>
            <span>{user?.acceptedPolicyDate ? formatDateTime(user.acceptedPolicyDate, { time: 'none' }) : '-'}</span>
          </div>
        </ContentInfo>
        <ContentInfo device={device}>
          <div className="child-item">
            <span style={{ fontWeight: 'bold' }}>Aceite Assistente: </span>
            <span>{user?.acceptedGpt ? 'Sim' : 'Não'}</span>
          </div>
          <div className="child-item">
            <span style={{ fontWeight: 'bold' }}>Data: </span>
            <span>{user?.acceptedGptDate ? formatDateTime(user.acceptedGptDate, { time: 'none' }) : '-'}</span>
          </div>
        </ContentInfo>
      </BlockInfo>
    </div>
  );
};

UserInfo.propTypes = {
  /**
   * The date the kpi was created
   */
  user: PropTypes.shape(),
  /**
   * size of the device
   */
  device: PropTypes.string.isRequired,
};

UserInfo.defaultProps = {
  user: {},
};

export default UserInfo;
