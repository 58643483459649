import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import InputTextLine from '../../../juristec-ui/core/InputTextLine';
import ActionsGroup from '../../../juristec-ui/core/ActionsGroup';
import Button from '../../../juristec-ui/core/Button';
import Select from '../../../juristec-ui/core/SelectNew/Select';
import DatePicker from '../../../juristec-ui/core/DatePicker';

import {
  ManageStoryContainer,
  TabGroup,
  TabButton,
  InfoText,
  FrequencyContainer,
  ScheduleFrequency,
  WarningMessage,
} from './styled/ManageStory.styled';

import { scheduleOptions, weekDayOptions, monthDayOptions } from '../../../juristec-ui/utils/cronOptions';

/**
* A component for creating or editing a story.
* The mode is changed from create to edit by informing the non-empty attribute 'storyName'
*/
const ManageStory = ({
  snapshot,
  closeModal,
  submitStoryName,
  deleteStory,
  hasSpecial,
  scheduleSnapshot,
}) => {
  const isEdit = snapshot.name && snapshot.name.length > 0;
  const [name, setName] = useState(
    { value: isEdit ? snapshot.name : '', error: !isEdit, errorMsg: '' },
  );
  const [tab, setTab] = useState('now');
  const [frequency, setFrequency] = useState({ label: 'Mensal', value: 'monthly' });
  const [weekDays, setWeekDays] = useState([]);
  const [monthDays, setMonthDays] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [cronObject, setCronObject] = useState({ value: '* * * * *', text: 'Todos os dias' });

  const inputRef = useRef();

  const trimName = (event) => {
    const { value } = event.target;
    setName((p) => ({
      ...p,
      value: value.split(' ').filter((v) => v).join(' '),
    }));
  };

  const handleNameInput = (val) => {
    const msg = val.trim().length === 0 ? "O campo 'Nome' não pode estar vazio" : '';
    setName({
      value: val,
      error: msg.length > 0,
      errorMsg: msg,
    });
  };

  const handleChangeFrequency = (val) => {
    setWeekDays([]);
    setMonthDays([]);
    setStartDate(new Date());
    setFrequency(val);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!name.error && tab === 'now') {
      await submitStoryName(inputRef.current.value, snapshot.snapId);
    } else if (!name.error && tab === 'schedule') {
      await scheduleSnapshot(cronObject.value, inputRef.current.value);
    } else {
      handleNameInput(name.value);
    }
  };

  const numberToMonth = (number) => {
    const meses = [
      'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
      'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro',
    ];
    return meses[number - 1];
  };

  const getCron = (freq) => {
    const cron = { value: '* * * * *', text: '' };
    switch (freq.value) {
      case 'weekly':
        if (weekDays.length > 0) {
          const weekCron = weekDays.sort((a, b) => a.cron - b.cron).map((day) => day.cron).join(',');
          const weekText = weekDays.sort((a, b) => a.cron - b.cron).map((day) => day.label);
          return ({
            value: `* * * * ${weekCron}`,
            text: weekText.length > 1
              ? `Toda ${weekText.slice(0, -1).join(', ')} e ${weekText[weekText.length - 1]}.`
              : `Toda ${weekText[0]}.`,
          });
        }
        return cron;

      case 'monthly':
        if (monthDays.length > 0) {
          const monthCron = monthDays.sort((a, b) => a.cron - b.cron).map((day) => day.cron).join(',');
          const monthText = monthDays.sort((a, b) => a.cron - b.cron).map((day) => day.label);
          return ({
            value: `* * ${monthCron} * *`,
            text: monthText.length > 1
              ? `Todos os dias ${monthText.slice(0, -1).join(', ')} e ${monthText[monthText.length - 1]} de cada mês.`
              : `Todo dia ${monthText[0]} de cada mês.`,
          });
        }
        return cron;

      case 'quarterly':
        const quarterlyCron = [0,1,2,3].map((i) => {
          const month = (startDate.getMonth() + 1 + 3*i) % 12;
          return month === 0 ? 12 : month;
        }).sort((a, b) => a - b);
        const quarterlyText = quarterlyCron.map((i) => numberToMonth(i));
        return ({
          value: `* * ${startDate.getDate()} ${quarterlyCron.join(',')} *`,
          text: `Todo dia ${startDate.getDate()} dos meses ${quarterlyText.slice(0, -1).join(', ')} e ${quarterlyText[quarterlyText.length - 1]}.`,
        });

      case 'semiannual':
        const semiannualCron = [0,1].map((i) => {
          const month = (startDate.getMonth() + 1 + 6*i) % 12;
          return month === 0 ? 6 : month;
        }).sort((a, b) => a - b);
        const semiannualText = semiannualCron.map((i) => numberToMonth(i));
        return ({
          value: `* * ${startDate.getDate()} ${semiannualCron.join(',')} *`,
          text: `Todo dia ${startDate.getDate()} dos meses ${semiannualText.slice(0, -1).join(', ')} e ${semiannualText[semiannualText.length - 1]}.`,
        });

      case 'yearly':
        return ({
          value: `* * ${startDate.getDate()} ${startDate.getMonth() + 1} *`,
          text: `Todo dia ${startDate.getDate()} de ${numberToMonth(startDate.getMonth() + 1)}.`,
        });

      default:
        return { ...cron, text: 'Todo dia' };
    }
  };

  useEffect(() => {
    const cron = getCron(frequency);
    setCronObject(cron);
  }, [frequency, weekDays, monthDays, startDate]);

  return (
    <>
      <ManageStoryContainer>
        {!isEdit && (
          <TabGroup>
            <TabButton active={tab === 'now'} onClick={() => setTab('now')}>
              <span>Agora</span>
            </TabButton>
            <TabButton active={tab === 'schedule'} onClick={() => setTab('schedule')} disabled>
              <span>Agendar (em construção)</span>
            </TabButton>
          </TabGroup>
        )}
        <div style={{ minHeight: isEdit ? 'auto' : '30vh', minWidth: isEdit ? 'auto' : '500px', maxWidth: isEdit ? 'auto' : '50vw' }}>
          {isEdit
            ? (
              <span className="date" style={{ display: 'block' }}>
                <strong>Data de criação: </strong>
                {new Date(snapshot.snapId).toLocaleString('pt-BR')}
              </span>
            ) : (
              <InfoText>
                Adicione um story para salvar uma configuração de layout do seu dashboard.
              </InfoText>
            )}
          {hasSpecial?.has('plotly') && (
            <span className="special">
              <WarningMessage>
                <strong>Atenção: </strong>
              </WarningMessage>
              *KPIs customizados funcionam apenas com dados mais recentes.
              Portanto, não serão renderizados em um story.
            </span>
          )}
          {hasSpecial?.has('SubsetTable') && (
            <span className="special">
              <WarningMessage>
                <strong>Atenção: </strong>
              </WarningMessage>
              *Subtabelas só conseguem acessar os dados mais recentes.
              Portanto, não serão renderizados em um story.
            </span>
          )}
          <div style={{ display: 'flex' }}>
            <InputTextLine
              label="Nome"
              ref={inputRef}
              errorMessage={name.errorMsg}
              error={name.errorMsg.length > 0}
              value={name.value}
              setValue={handleNameInput}
              emojiPicker
              emojiPickerPosition="top-end"
              onChange={(e) => handleNameInput(e.target.value)}
              onBlur={trimName}
            />
          </div>
          {tab === 'schedule' && !isEdit && (
            <>
              <InfoText style={{ marginTop: '1rem' }}>
                Determine a frequência de criação do story.
              </InfoText>
              <FrequencyContainer>
                <div style={{ width: '50%' }}>
                  <Select
                    label="Frequência"
                    placeholder="Selecione a frequência"
                    options={scheduleOptions}
                    value={frequency}
                    onChange={handleChangeFrequency}
                    atModal
                  />
                </div>
                {frequency.value === 'weekly' && (
                  <div style={{ width: '50%' }}>
                    <Select
                      label="Dias da semana"
                      placeholder="Selecione o(s) dia(s) da semana"
                      options={weekDayOptions}
                      value={weekDays}
                      onChange={setWeekDays}
                      atModal
                      multiple
                      stayOpen
                    />
                  </div>
                )}
                {frequency.value === 'monthly' && (
                  <div style={{ width: '50%' }}>
                    <Select
                      label="Dias do mês"
                      placeholder="Selecione o(s) dia(s) do mês"
                      options={monthDayOptions}
                      value={monthDays}
                      onChange={setMonthDays}
                      atModal
                      multiple
                      stayOpen
                    />
                  </div>
                )}
                {(frequency.value === 'quarterly' || frequency.value === 'semiannual' || frequency.value === 'yearly') && (
                  <div style={{ width: '50%' }}>
                    <DatePicker
                      value={startDate}
                      startDate={new Date()}
                      onChange={setStartDate}
                      label="Data de Início"
                      readOnly={false}
                      dateFormat="dd/MM/yyyy"
                      atModal
                    />
                  </div>
                )}
              </FrequencyContainer>
              {cronObject.text !== '' && (
                <>
                  <div style={{ marginTop: '10px' }}>
                    <ScheduleFrequency>
                      <strong>Agendamento: </strong>
                    </ScheduleFrequency>
                    <span>
                      {`"${cronObject.text}"`}
                    </span>
                  </div>
                  <div style={{ marginTop: '10px' }}>
                    <WarningMessage>
                      <strong>Atenção: </strong>
                    </WarningMessage>
                    <span>
                      se o dashboard depende de um arquivo excel do usuário, o story agendado
                      somente será criado se a base for atualizada nos dias selecionados
                      no agendamento.
                    </span>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </ManageStoryContainer>
      <ActionsGroup>
        {isEdit && (
          <Button
            style={{ margin: '5px' }}
            onClick={() => deleteStory(snapshot)}
            variant="pattern"
            size="large"
          >
            Apagar
          </Button>
        )}
        <Button
          style={{ margin: '5px' }}
          onClick={closeModal}
          variant="outlined"
          size="large"
        >
          Cancelar
        </Button>
        <Button
          style={{ margin: '5px' }}
          onClick={handleSubmit}
          size="large"
          disabled={(tab === 'now' && name.error) || (tab === 'schedule' && name.error && cronObject.text === '')}
        >
          {isEdit ? 'Salvar' : 'Adicionar'}
        </Button>
      </ActionsGroup>
    </>
  );
};

ManageStory.propTypes = {
  /**
    * The current snapshot when it is available
    */
  snapshot: PropTypes.shape({
    name: PropTypes.string,
    snapId: PropTypes.string,
  }),
  /**
    * Function responsible to close the modal
    */
  closeModal: PropTypes.func.isRequired,
  /**
    * Function that sends the story name back to the parent to create or update
    */
  submitStoryName: PropTypes.func.isRequired,
  /**
    * Function that handles the deletion of a story
    */
  deleteStory: PropTypes.func,
  /**
   * A set that contains the special types of KPIs on the dashboard
   */
  hasSpecial: PropTypes.instanceOf(Set),
  /**
    * Function that handles the schedule of a story
    */
  scheduleSnapshot: PropTypes.func,
};

ManageStory.defaultProps = {
//   storyName: '',
  deleteStory: () => {},
  snapshot: { },
  hasSpecial: null,
  scheduleSnapshot: () => {},
};

export default ManageStory;
