import React from 'react';
import PropTypes from 'prop-types';

const UserAtom = ({ width, height }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 25 25" fill="none">
      <g clipPath="url(#clip0)">
        <path d="M0.962485 5.51517C0.908404 4.90733 0.943902 4.2951 1.06731 3.6977C1.23289 2.94745 1.55947 2.32478 2.03785 1.84661C2.70667 1.17737 3.64547 0.810497 4.82504 0.755997C5.3932 0.73449 5.9622 0.773329 6.52223 0.871678L6.65148 0.134791C6.03821 0.027254 5.41534 -0.0149254 4.7933 0.00867003C3.41849 0.0717304 2.31307 0.512317 1.50853 1.31749C0.927824 1.89798 0.533383 2.64447 0.335224 3.54109C0.196367 4.21158 0.156275 4.89877 0.216829 5.58095L0.962485 5.51517Z" />
        <path d="M0.463867 7.15154C0.570778 7.608 0.700657 8.05882 0.853087 8.50233L1.56116 8.26053C1.41708 7.84124 1.2941 7.41485 1.19303 6.98303L0.463867 7.15154Z" />
        <path d="M2.84429 11.1251C2.586 10.6541 2.349 10.1803 2.14018 9.71716L1.45801 10.025C1.67475 10.5057 1.92052 10.997 2.18801 11.4849L2.84429 11.1251Z" />
        <path d="M3.52246 13.6429C3.76593 13.9963 4.02527 14.3544 4.29296 14.7065C4.35561 14.7889 4.41888 14.8712 4.48277 14.9535L5.0737 14.4943C5.01147 14.4143 4.94988 14.3341 4.8889 14.2538C4.62789 13.9107 4.37586 13.5622 4.13865 13.2183L3.52246 13.6429Z" />
        <path d="M6.19119 15.8449C6.15361 15.802 6.11644 15.7594 6.07948 15.717L5.51465 16.208C5.55244 16.2513 5.59044 16.2947 5.62866 16.3384C5.8454 16.5853 6.07113 16.8343 6.29936 17.0783C6.40167 17.1877 6.50503 17.2966 6.60944 17.4053L7.14879 16.8863C7.04669 16.7803 6.94563 16.6738 6.84582 16.567C6.62301 16.3288 6.40271 16.0858 6.19119 15.8449Z" />
        <path d="M23.6973 14.8478L23.0122 15.1486C23.5218 16.3099 23.8634 17.4176 24.0271 18.4409C24.3359 20.371 23.9947 21.8957 23.04 22.8504C22.5663 23.3241 21.9509 23.6488 21.2107 23.8159C20.6151 23.95 19.94 23.9822 19.2042 23.9115C19.0232 23.8942 18.8365 23.8704 18.6492 23.8407L18.5322 24.58C18.7346 24.6118 18.9365 24.6376 19.1329 24.6565C19.4093 24.6831 19.6787 24.6962 19.9407 24.6962C20.4229 24.6991 20.9039 24.6488 21.3752 24.5461C22.2558 24.3476 22.9939 23.9552 23.5694 23.3799C24.7007 22.2486 25.1145 20.4998 24.7663 18.323C24.5923 17.2365 24.2327 16.0674 23.6973 14.8478Z" />
        <path d="M22.6998 12.8723L22.0498 13.2431C22.2563 13.6052 22.4512 13.9702 22.6286 14.3274L23.2989 13.9946C23.1149 13.6244 22.9135 13.2467 22.6998 12.8723Z" />
        <path d="M21.051 11.6435L21.6694 11.2218C21.366 10.7767 21.0461 10.3338 20.7185 9.90552L20.124 10.3599C20.4435 10.7775 20.7555 11.2093 21.051 11.6435Z" />
        <path d="M19.128 9.12938L19.6965 8.64307C19.3462 8.23338 18.9799 7.82808 18.6079 7.43823L18.0664 7.95503C18.4293 8.33486 18.7864 8.73014 19.128 9.12938Z" />
        <path d="M17.454 21.9195L16.7344 22.1254L17.1237 23.4855C16.0657 23.1583 15.0418 22.7301 14.0661 22.2067C12.7551 21.5063 11.5086 20.6917 10.3407 19.7725C9.47456 19.0942 8.646 18.3692 7.85858 17.601L7.33594 18.1364C8.14256 18.9236 8.99138 19.6661 9.8786 20.361C11.0814 21.3077 12.3651 22.1465 13.715 22.8675C14.5523 23.314 15.4222 23.6961 16.3172 24.0107L15.3744 24.2805L15.5804 25.0001L17.767 24.3743C17.9656 24.3173 18.0805 24.1101 18.0236 23.9116L17.454 21.9195Z" />
        <path d="M8.10157 2.94567L8.83324 2.78928L8.53736 1.40466C9.35631 1.68989 10.1525 2.03652 10.9193 2.44182C12.213 3.12483 13.4446 3.91977 14.5997 4.81786C15.1445 5.23799 15.6928 5.69402 16.2299 6.17261C16.5276 6.43863 16.8275 6.71698 17.1208 7.00054C17.2482 7.12353 17.3783 7.25132 17.5074 7.38015L18.0364 6.85082C17.9043 6.71865 17.7713 6.58794 17.641 6.46202C17.3405 6.17178 17.0335 5.88633 16.7281 5.61384C16.1779 5.12334 15.6155 4.65603 15.0582 4.22588C14.4928 3.78634 13.9085 3.37039 13.3049 2.97804C13.6593 2.77654 14.015 2.58652 14.3637 2.41321L14.0309 1.74294C13.6481 1.93295 13.2573 2.14217 12.8693 2.36435L13.171 2.89076C12.5524 2.4936 11.9184 2.12338 11.2687 1.78031C10.6663 1.46166 10.046 1.17748 9.41123 0.929619L10.3369 0.731877L10.1805 0L7.95625 0.475459C7.75411 0.518474 7.62528 0.717468 7.66851 0.919387L8.10157 2.94567Z" />
        <path d="M3.5777 24.7064C4.04586 24.8036 4.52277 24.8525 5.00095 24.8525C5.20642 24.8525 5.4121 24.8434 5.61755 24.8253L5.55178 24.0799C4.94415 24.1335 4.33192 24.0982 3.73431 23.9745C2.98448 23.8092 2.3616 23.4829 1.88343 23.0044C1.21419 22.3355 0.847316 21.3966 0.792817 20.2173C0.771518 19.6489 0.810148 19.0801 0.908497 18.5201L0.17161 18.3904C0.0640729 19.0037 0.0216847 19.6265 0.045489 20.2486C0.108549 21.6234 0.548927 22.7286 1.3541 23.5338C1.935 24.1144 2.68149 24.5087 3.5777 24.7064Z" />
        <path d="M8.53785 24.1888L8.29626 23.4806C7.87676 23.6247 7.45038 23.7477 7.01855 23.8489L7.18769 24.5779C7.64394 24.4709 8.09456 24.341 8.53785 24.1888Z" />
        <path d="M11.161 22.1979C10.6898 22.4559 10.2163 22.6927 9.75293 22.9017L10.0607 23.5839C10.5418 23.3671 11.0327 23.1214 11.5207 22.8539L11.161 22.1979Z" />
        <path d="M13.2539 20.9033L13.6784 21.5197C14.0319 21.2762 14.3898 21.0169 14.742 20.749C14.8247 20.6863 14.907 20.6231 14.9893 20.559L14.5299 19.9683C14.4499 20.0302 14.3697 20.092 14.2893 20.1531C13.9463 20.414 13.5978 20.6665 13.2539 20.9033Z" />
        <path d="M17.1142 18.7432C17.2233 18.6409 17.3323 18.5374 17.441 18.4331L16.9221 17.8937C16.816 17.9959 16.7097 18.0969 16.603 18.1967C16.3649 18.4193 16.1219 18.6398 15.8805 18.8515C15.8381 18.8889 15.7955 18.9261 15.7529 18.9628L16.2435 19.5283C16.2871 19.4902 16.3307 19.4522 16.3744 19.4141C16.6214 19.1967 16.8704 18.971 17.1142 18.7432Z" />
        <path d="M24.2319 7.05103C24.1453 7.00301 24.0428 6.9911 23.9473 7.01846L21.9556 7.58851L22.1614 8.30806L23.5214 7.91884C23.1944 8.97668 22.7661 10.0005 22.2427 10.976C21.5423 12.2869 20.7278 13.5335 19.8084 14.7012C19.1302 15.5674 18.4052 16.3959 17.6367 17.1833L18.1723 17.7061C18.9593 16.8996 19.702 16.0507 20.397 15.1635C21.3437 13.9607 22.1826 12.6769 22.9035 11.3268C23.3497 10.4897 23.7318 9.6198 24.0465 8.72485L24.3165 9.66783L25.0359 9.46195L24.4101 7.2753C24.383 7.17987 24.3188 7.09927 24.2319 7.05103Z" />
        <path d="M18.477 1.01441C20.4068 0.705577 21.9317 1.04698 22.8864 2.00145C23.36 2.47523 23.6847 3.0906 23.8517 3.83083C23.9856 4.42677 24.018 5.10185 23.9471 5.83748C23.9298 6.01811 23.9061 6.20478 23.8764 6.3925L24.6156 6.50943C24.6477 6.30668 24.6734 6.10476 24.6922 5.9089C24.7705 5.09434 24.7334 4.33991 24.5817 3.66649C24.3832 2.78573 23.9908 2.0476 23.4156 1.47233C22.2842 0.340996 20.5355 -0.0728639 18.3586 0.275431C17.2723 0.449368 16.1033 0.808938 14.8838 1.34412L15.1845 2.02943C16.3457 1.51993 17.4534 1.17812 18.477 1.01441Z" />
        <path d="M9.16499 5.91364L8.67846 5.34485C8.26878 5.69522 7.86348 6.06148 7.47363 6.43379L7.99043 6.97502C8.37047 6.61232 8.76553 6.25505 9.16499 5.91364Z" />
        <path d="M11.6791 3.99117L11.2578 3.37268C10.8124 3.67628 10.3695 3.99618 9.94141 4.3236L10.3958 4.91808C10.8132 4.59881 11.245 4.28706 11.6791 3.99117Z" />
        <path d="M0.877007 17.3821C0.903317 17.3821 0.929836 17.3794 0.955519 17.3737L2.9816 16.9409L2.82519 16.2091L1.44059 16.5049C1.72582 15.6859 2.07244 14.8898 2.47773 14.1232C3.16075 12.8293 3.95569 11.5977 4.85377 10.4425C5.27369 9.89816 5.72973 9.34962 6.20853 8.81257C6.47435 8.51481 6.7529 8.21496 7.03646 7.92137C7.16049 7.79316 7.28849 7.66286 7.41628 7.53507L6.88716 7.00574C6.75623 7.13666 6.62511 7.26988 6.49815 7.40144C6.20811 7.70191 5.92288 8.00928 5.65059 8.31414C5.15968 8.86477 4.69237 9.42688 4.26243 9.9842C3.33803 11.1733 2.5195 12.4414 1.81644 13.7734C1.4978 14.376 1.21382 14.9962 0.965751 15.631L0.768009 14.7054L0.0361328 14.8618L0.511383 17.086C0.548133 17.2584 0.700564 17.3821 0.877007 17.3821Z" />
        <path d="M12.5208 11.2082C13.9776 11.2114 15.1607 10.1804 15.1636 8.90481C15.1665 7.62927 13.9881 6.5928 12.5312 6.58949C11.0744 6.58617 9.89133 7.61729 9.88843 8.89283C9.88554 10.1684 11.0639 11.2048 12.5208 11.2082ZM14.3658 11.7896L14.0216 11.7889C13.5638 11.9718 13.0545 12.0754 12.5188 12.0741C11.9831 12.073 11.4764 11.9671 11.0172 11.782L10.6732 11.7813C9.14418 11.7778 7.90124 12.8611 7.89819 14.1997L7.89649 14.9504C7.8954 15.4284 8.33755 15.8173 8.88361 15.8186L16.1369 15.8351C16.683 15.8363 17.1269 15.4494 17.128 14.9713L17.1297 14.2208C17.1328 12.882 15.8947 11.7932 14.3658 11.7896Z" />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="25" height="25" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

UserAtom.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

UserAtom.defaultProps = {
  width: '18px',
  height: '18px',
};

export default UserAtom;
