import React from 'react';
import PropTypes from 'prop-types';

const Code = ({ height, width, fill }) => (
  <svg width={width} height={height} viewBox="0 0 25 20" fill={fill} xmlns="http://www.w3.org/2000/svg">
    <path d="M15.344 0.0489931C14.6799 -0.142428 13.9884 0.244321 13.797 0.908435L8.79659 18.4098C8.60517 19.0739 8.99191 19.7654 9.65603 19.9568C10.3201 20.1482 11.0116 19.7615 11.203 19.0974L16.2034 1.59599C16.3948 0.931875 16.0081 0.240414 15.344 0.0489931ZM18.4927 4.74077C18.0043 5.22909 18.0043 6.02212 18.4927 6.51044L21.9812 10.0029L18.4888 13.4954C18.0004 13.9837 18.0004 14.7767 18.4888 15.265C18.9771 15.7533 19.7701 15.7533 20.2584 15.265L24.6338 10.8897C25.1221 10.4014 25.1221 9.60833 24.6338 9.12001L20.2584 4.74467C19.7701 4.25635 18.9771 4.25635 18.4888 4.74467L18.4927 4.74077ZM6.51125 4.74077C6.02293 4.25245 5.2299 4.25245 4.74158 4.74077L0.36624 9.11611C-0.12208 9.60443 -0.12208 10.3975 0.36624 10.8858L4.74158 15.2611C5.2299 15.7494 6.02293 15.7494 6.51125 15.2611C6.99957 14.7728 6.99957 13.9798 6.51125 13.4915L3.01879 10.0029L6.51125 6.51044C6.99957 6.02212 6.99957 5.22909 6.51125 4.74077Z" />
  </svg>
);

Code.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  fill: PropTypes.string,
};

Code.defaultProps = {
  height: '20',
  width: '25',
  fill: 'none',
};

export default Code;
