import styled, { css } from 'styled-components';
import { devices } from '../../../juristec-ui/core/breakpoints';

export const HeaderContainer = styled.div`
  display: flex;
  padding: 5px 0 0 5px;
  width: 100%;
  align-items: center;
  & h2 {
    margin: 0 auto;
    color: ${({ theme }) => theme.primary};
    @media ${devices.phoneS} {
      font-size: 20px;
    }
  };
`;

export const TimelineContainer = styled.div`
  border: solid #ddd;
  border-width: 1.6px 0;
  height: calc(100vh - 130px);
  width: 100%;
  padding: 0;
  margin: 10px 0;
  overflow: hidden auto;
  scroll-snap-type: mandatory;
  scroll-snap-type: y mandatory;
  & > div {
    scroll-snap-align: end;
    &:first-child {
      scroll-snap-align: start;
    }
  }
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  background-color: ${({ theme }) => theme.backgroundOdd};
  border-radius: 10px;
  box-shadow: 0px 4px 4px ${({ theme }) => theme.shadow};
  padding: .2rem .3rem;
  margin: 0 .5rem .5rem .5rem;
  & > .textAreaContainer {
    width: 100%;
    height: 60px;
    & textarea {
      width: 100%;
      font-size: 14px;
    }
    & .textAreaBar {
      bottom: 1px;
    }
  }
`;

export const IconWrapper = styled.div`
  margin-left: 5px;
  height: 15px;
  width: 15px;
  & > svg {
    fill: ${({ theme }) => theme.white};
  }
`;

const getMsgColors = (msgType, isLoading, theme) => {
  if (isLoading) return theme.container;
  if (msgType === 'question') return theme.bubbleQuestion;
  if (msgType === 'error') return theme.bubbleError;
  return theme.bubbleAnswer;
};

export const MsgContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({
    theme, msgType, isLoading,
  }) => getMsgColors(msgType, isLoading, theme)};
  border-radius: .5rem;
  padding: .3rem .5rem .2rem .5rem;
  position: relative;
  min-height: 2.5rem;
  & p {
    margin: 0;
    font-size: 14px;
    /* word-break: break-all; */
    /* white-space: pre-wrap; */
  }
  & span {
    margin: auto 0 0 auto;
    font-size: 10px;
    color: ${({ theme }) => theme.color}88;
  }
  &:before {
    content: '';
    position: absolute;
    border: 1.2rem solid;
    border-color: transparent ${({
      theme, msgType, isLoading,
    }) => getMsgColors(msgType, isLoading, theme)};
    top: .3rem;
    border-top-width: 0;
  }
`;

export const MsgRow = styled.div`
  display: flex;
  padding: .5rem;
  max-width: 85%;
  ${({ isUser }) => (isUser ? css`
    flex-direction: row-reverse;
    margin-left: auto;
    ${MsgContainer} {
      margin-right: 1rem;
      &:before {
        border-right-color: transparent;
        border-right: 0;
        right: -.9rem;
      }
    }
  ` : css`
    flex-direction: row;
    margin-right: auto;
    ${MsgContainer} {
      margin-left: 1rem;
      &:before {
        border-left-color: transparent;
        border-left: 0;
        left: -.9rem;
      }
    }
  `)}
`;

export const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-width: 50px;
`;

export const InputControlsContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: .2rem;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  & > div {
    display: flex;
    flex-direction: row;
    & > button {
      max-height: 30px;
    }
  }
  @media ${devices.phoneS} {
    justify-content: flex-start;
  }
`;

const colorByType = {
  category: 'info',
  object: 'info',
  float64: 'success',
  int64: 'success',
  'datetime64[ns]': 'warning',
  get(type, theme) {
    return theme[this[type] || 'grey'];
  },
};

export const ListIconWrapper = styled.div`
  height: 22px;
  width: 22px;
  margin-right: 5px;
  & > svg {
    height: 22px;
    width: 22px;
    fill: ${({ theme, typeForColor }) => colorByType.get(typeForColor, theme)};
  }
`;

export const FullTimelineContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  & span {
    color: ${({ theme }) => theme.grey};
    text-align: center;
  }
`;

export const GPTCredits = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.grey};
  margin: 0 .5rem;
  & a {
    color: ${({ theme }) => theme.grey};
  }
  @media ${devices.phoneS} {
    font-size: 12px;
  }
`;
