import styled, { css } from 'styled-components';

const ContainerPicker = styled.div`
  ${({ theme }) => css`
    & .emoji-picker-react {
      background-color: ${theme.popoverBackground};
      border: ${theme.container};
      box-shadow: 0px 4px 4px ${theme.shadow};
      & .skin-tones-list {
        top: 12px;
        right: 25px;
      }
      & .emoji-search {
        color: ${theme.color};
        background-color: transparent;
        border-width: 0 0 2px 0;
        border-radius: 0;
        border-color: ${theme.inputBottomLine};
        padding: 5px 0;
        margin-left: 0;
      }
      
      & .active-category-indicator-wrapper + div {
        background-color: ${theme.backgroundOdd};
        border-radius: 5px;
        box-shadow: 0px 2px 4px ${theme.shadow};
        padding: .2rem .6rem;
        margin: 5px 5px 10px 5px;
      }
      & .emoji-group:before {
        background-color: ${theme.popoverBackground}f5;
      }
    }
    & .emoji-categories button {
      filter: invert(${theme.theme === 'dark' ? 1 : 0});
    }
  `}
`;

export default ContainerPicker;
