import React from 'react';

// import { Container } from './styles';

function Activate() {
    return (
        <svg width="22" height="22" viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M14 4H11V10H14V11.5998C13.1113 12.0937 12.0884 12.375 11 12.375C7.58398 12.375 4.8125 9.60352 4.8125 6.1875C4.8125 2.77148 7.58398 0 11 0C12.0884 0 13.1113 0.281328 14 0.775198V4ZM14 13.8093C13.0807 14.2107 12.0658 14.4375 11 14.4375C9.88281 14.4375 8.82578 14.1883 7.86758 13.75H5.5C2.46211 13.75 0 16.2121 0 19.25V19.9375C0 21.0762 0.923828 22 2.0625 22H19.9375C21.0762 22 22 21.0762 22 19.9375V19.25C22 16.785 20.3789 14.699 18.1445 14H14V13.8093Z" />
            <rect x="12" y="5" width="10" height="4" />
            <rect x="19" y="2" width="10" height="4" transform="rotate(90 19 2)" />
        </svg>
    );
}

export default Activate;