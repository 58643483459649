import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import ActionsGroup from '../../../juristec-ui/core/ActionsGroup';
import Button from '../../../juristec-ui/core/Button';
import CardKpi from '../../../juristec-ui/kpis/grid/CardKpi';
import Select from '../../../juristec-ui/core/SelectNew';
import MainTabs from '../../../juristec-ui/core/Tabs';
import SelectTips from '../../Popovers/SelectTips';
import CustomSide from '../../../juristec-ui/kpis/controllers';
import MiniLoading from '../../../juristec-ui/core/MiniLoading';
import Modifier from './Modifier';

import getDefaultStyle from '../../../juristec-ui/kpis/utils/chartTools/getDefaultStyle';
import styleConfigMap from '../../KpiCreate/handleStyleConfig';

import { controlVarDescription, dateOptions, defaultDashStyle } from '../../../options';
import { Gears, Warning } from '../../../juristec-ui/icons';

import {
  MainContainer,
  SideBar,
  PreviewContainer,
  KpiWrapper,
  InputWrapper,
  GenButtonActions,
  InputDoubleWrapper,
  GenChartAlert,
  IconCircle,
  TabContent,
  LoadingWrapper,
} from './styled/SubsetTableCreate.styled';

const CHARTTYPE = 'SubsetTable';

const tempItemInitial = {
  id: 'new',
  name: '',
  status: 'active',
  updatedAt: '',
  type: CHARTTYPE,
  style: { type: CHARTTYPE },
};

const getSorting = (sConfig, firstColumn) => ([
  !['Não ordenado', undefined].includes(sConfig?.SortDataControl)
    ? (sConfig?.ColumnToSortControl ?? firstColumn) : '',
  sConfig?.SortDataControl === 'Decrescente' ? 'desc' : 'asc',
]);

const SubsetTableCreate = ({
  item,
  hide,
  submit,
  userId,
  stylesData,
  filesOpts,
  getFileColumns,
  genTable,
  getColumnUnique,
}) => {
  const kpiRef = useRef();

  const [activeTab, setActiveTab] = useState('tabData');
  const [lockGen, setLockGen] = useState(true);
  const [alertGen, setAlertGen] = useState(false);
  const [kpiLoading, setKpiLoading] = useState(false);

  const [selectedFile, setSelectedFile] = useState({});
  const [fileColumns, setFileColumns] = useState([]);

  const [selectedColumns, setSelectedColumns] = useState([]);
  const [selectedControl, setSelectedControl] = useState({ column: {}, map: {} });
  const [filtersToSend, setFiltersToSend] = useState([]);
  const [groupsToSend, setGroupsToSend] = useState([]);

  const [styleConfig, setStyleConfig] = useState({
    ...getDefaultStyle(CHARTTYPE), type: CHARTTYPE,
  });

  const [tempItem, setTempItem] = useState(tempItemInitial);
  const [page, setPage] = useState(0);

  useEffect(() => {
    if (item) {
      const loadTable = async () => {
        setLockGen(true);
        setKpiLoading(true);
        setPage(0);
        const data = await genTable(
          item.meta,
          item.database,
          0,
          ...getSorting(item.style, item.meta.values[0]?.column ?? ''),
        );
        if (data) setTempItem({ ...item, data });
        setKpiLoading(false);
      };
      const setParams = async () => {
        const file = filesOpts.find((f) => f.value === item.database);
        if (file?.value !== '') {
          setSelectedFile(file);
          const opts = await getFileColumns(file.value);
          setFileColumns(opts);
          setStyleConfig((s) => ({ ...s, ...item.style }));
          setSelectedColumns(item.meta.values.map((v) => ({
            label: v.column, value: v.column, id: v.column, type: v.type,
          })));
          setSelectedControl(
            item.meta.control?.map((c) => ({
              column: {
                label: c.column, value: c.column, id: c.column, type: c.type,
              },
              map: dateOptions.find((d) => d.value === c.map) ?? {},
            }))?.[0] ?? { column: {}, map: {} },
          );
          setFiltersToSend(item.meta.filters ?? []);
          setGroupsToSend(item.meta.maps ?? []);
          setActiveTab('tabData');
        }
      };
      loadTable();
      setParams();
    }
  }, [item]);

  const closeAlertGen = () => setAlertGen(false);

  const resetPage = () => {
    setSelectedColumns([]);
    setSelectedControl({ column: {}, map: {} });
    setFiltersToSend([]);
    setGroupsToSend([]);
    setTempItem(tempItemInitial);
    setActiveTab('tabData');
    setLockGen(true);
  };

  const handleSelectedFile = async (selected) => {
    setSelectedFile(selected);
    if (selected?.value !== '') {
      resetPage();
      const opts = await getFileColumns(selected.value);
      setFileColumns(opts);
    }
  };

  const handleSelectedColumns = (selected) => {
    setLockGen(selected.length <= 0);
    setSelectedColumns(selected);
    setGroupsToSend((groups) => (
      groups.filter((group) => selected.find((s) => s.value === group.column))
    ));
    setAlertGen(true);
  };

  const handleSelectedControlColumn = (selected) => {
    setSelectedControl((c) => ({
      column: selected, map: { ...c.map },
    }));
  };

  const handleSelectedControlMap = (selected) => {
    setSelectedControl((c) => ({
      map: selected, column: { ...c.column },
    }));
  };

  const handleGenChar = async (pg = 0) => {
    setLockGen(true);
    setKpiLoading(true);
    setPage(pg);
    const meta = {
      values: selectedColumns.map((cols) => ({ column: cols.value, map: '', type: cols.type })),
      control: selectedControl.column?.value?.length > 0 ? ([{
        column: selectedControl.column.value,
        map: selectedControl.column.type === 'datetime64[ns]' ? (
          selectedControl.map?.value ?? 'category'
        ) : 'category',
        type: selectedControl.column.type,
      }]) : [],
      filters: filtersToSend,
      maps: groupsToSend,
      kpi_type: CHARTTYPE,
      database: selectedFile.value,
      fileOwner: selectedFile.owner,
      ownerId: userId,
    };
    const data = await genTable(
      meta,
      selectedFile.value,
      pg,
      ...getSorting(styleConfig, selectedColumns[0]?.value ?? ''),
    );
    if (data) {
      setTempItem((tItem) => ({
        ...tItem, data, meta, style: styleConfig,
      }));
    } else setLockGen(false);
    setKpiLoading(false);
  };

  const handleGenCharBtn = () => handleGenChar();

  const handlePageChange = (pgState) => {
    const pg = typeof pgState === 'function' ? pgState(page) : pgState;
    handleGenChar(pg);
  };

  const handleStyleConfig = (value, diff) => {
    const sConfig = styleConfigMap(styleConfig, diff, value);
    setStyleConfig(sConfig);
    setTempItem((tItem) => ({
      ...tItem, style: sConfig, name: sConfig?.ShowTitleControl?.checked === true ? sConfig?.TitleControl : '',
    }));
    if (['SortDataControl', 'ColumnToSortControl'].includes(diff)) {
      setLockGen(false);
      setAlertGen(true);
    }
  };

  const handleColumnUnique = (column, dFormat) => (
    getColumnUnique(selectedFile.value, column, dFormat)
  );

  const handleFiltersToSend = (newValue) => {
    setLockGen(false);
    setFiltersToSend(newValue);
    setAlertGen(true);
  };

  const handleGroupsToSend = (newValue) => {
    setLockGen(false);
    setGroupsToSend(newValue);
    setAlertGen(true);
  };

  const handleSubmit = () => {
    const body = {
      id: tempItem.id,
      name: tempItem.name,
      status: tempItem.status,
      updatedAt: tempItem.updatedAt,
      type: tempItem.type,
      meta: {
        values: selectedColumns.map((cols) => ({ column: cols.value, map: '', type: cols.type })),
        control: selectedControl.column?.value?.length > 0 ? ([{
          column: selectedControl.column.value,
          map: selectedControl.column.type === 'datetime64[ns]' ? (
            selectedControl.map?.value ?? 'category'
          ) : 'category',
          type: selectedControl.column.type,
        }]) : [],
        filters: filtersToSend,
        maps: groupsToSend,
        kpi_type: CHARTTYPE,
        database: selectedFile.value,
        fileOwner: selectedFile.owner,
        ownerId: userId,
      },
    };
    submit(body, styleConfig);
  };

  return (
    <>
      <MainContainer>
        <SideBar>
          <InputWrapper>
            <Select
              label="Selecione o arquivo"
              value={selectedFile}
              options={filesOpts}
              onChange={handleSelectedFile}
              atModal
              searchable
              sortBy="ascending"
            />
          </InputWrapper>
          <GenButtonActions>
            <Button
              size="large"
              variant="outlined"
              onClick={resetPage}
            >
              Resetar
            </Button>
            <Button
              size="large"
              onClick={handleGenCharBtn}
              disabled={lockGen}
            >
              Gerar Tabela
              <Gears />
            </Button>
          </GenButtonActions>
          <MainTabs
            blockers={{
              hasValue: Boolean(selectedColumns.length),
              hasPivotTable: Boolean(tempItem.data),
            }}
            check={{
              tabGroups: ['hasValue', 'hasPivotTable'],
              tabStyles: ['hasValue', 'hasPivotTable'],
              tabFilters: ['hasValue', 'hasPivotTable'],
            }}
            head={[
              { text: 'Dados', id: 'tabData' },
              { text: 'Agrupamento', id: 'tabGroups' },
              { text: 'Filtros', id: 'tabFilters' },
              { text: 'Estilos', id: 'tabStyles' },
            ]}
            active={activeTab}
            setActive={setActiveTab}
          >
            <TabContent>
              <InputDoubleWrapper className={fileColumns.length > 0 ? '' : 'disabled'}>
                <Select
                  label="Colunas"
                  options={fileColumns}
                  value={selectedColumns}
                  onChange={handleSelectedColumns}
                  multiple
                  stayOpen
                  searchable
                  clearable
                  atModal
                />
              </InputDoubleWrapper>
              <InputDoubleWrapper className={fileColumns.length > 0 ? '' : 'disabled'}>
                <div style={{ position: 'relative' }}>
                  <Select
                    label="Controle (opcional)"
                    onChange={handleSelectedControlColumn}
                    options={selectedColumns}
                    value={selectedControl.column}
                    searchable
                    clearable
                    atModal
                  />
                  <SelectTips
                    description={controlVarDescription}
                    style={{ top: '-5px', right: '2px' }}
                  />
                </div>
                {(selectedControl.column && (selectedControl.column.type === 'datetime64[ns]')) && (
                  <Select
                    label="Formato"
                    onChange={handleSelectedControlMap}
                    value={selectedControl.map}
                    options={dateOptions}
                    searchable
                    clearable
                    atModal
                  />
                )}
              </InputDoubleWrapper>
            </TabContent>
            <TabContent>
              <Modifier
                origin="group"
                columnOpts={selectedColumns}
                getColumnUnique={handleColumnUnique}
                addedModfs={groupsToSend}
                handleAddedModifs={handleGroupsToSend}
              />
            </TabContent>
            <TabContent>
              <Modifier
                origin="filter"
                columnOpts={fileColumns}
                getColumnUnique={handleColumnUnique}
                addedModfs={filtersToSend}
                handleAddedModifs={handleFiltersToSend}
              />
            </TabContent>
            <TabContent>
              <CustomSide
                key={activeTab}
                type={CHARTTYPE}
                config={styleConfig}
                handle={handleStyleConfig}
                data={tempItem.data}
                isGlobalThemeActive={Boolean(stylesData?.useGlobalTheme)}
              />
            </TabContent>
          </MainTabs>
        </SideBar>
        <PreviewContainer>
          <KpiWrapper
            style={{
              borderStyle: stylesData?.borderStyle?.line ?? defaultDashStyle.borderLine,
              borderColor: stylesData?.borderStyle?.color ?? defaultDashStyle.borderColor,
              borderWidth: stylesData?.borderStyle?.size ?? defaultDashStyle.borderSize,
              borderRadius: stylesData?.borderStyle?.rounded ?? defaultDashStyle.borderRounded,
              backgroundColor: stylesData?.backgroundColor ?? defaultDashStyle.backgroundColor,
            }}
          >
            {!tempItem.data && !kpiLoading && (
              <></>
            )}
            {!tempItem.data && kpiLoading && (
              <LoadingWrapper>
                <MiniLoading fill="primary" />
              </LoadingWrapper>
            )}
            {Boolean(tempItem.data) && (
              <>
                {tempItem.data && !lockGen && alertGen && (
                  <GenChartAlert>
                    <IconCircle>
                      <Warning />
                    </IconCircle>
                    <h2>Clique em &quot;Gerar Tabela&quot; para aplicar as mudanças</h2>
                    <Button
                      onClick={closeAlertGen}
                      color="secondary"
                      variant="outlined"
                      size="small"
                      style={{ backgroundColor: 'transparent', boxShadow: 'none' }}
                    >
                      Ok
                    </Button>
                  </GenChartAlert>
                )}
                <CardKpi
                  ref={kpiRef}
                  item={tempItem}
                  editPanel={false}
                  isDataLoading={kpiLoading}
                  globalTheme={
                    stylesData && styleConfig?.UseGlobalColor !== false
                      ? stylesData : { useGlobalTheme: false }
                  }
                  pageState={[page, handlePageChange]}
                  collection="kpis"
                />
              </>
            )}
          </KpiWrapper>
        </PreviewContainer>
      </MainContainer>
      <ActionsGroup>
        <Button
          style={{ margin: '5px' }}
          onClick={hide}
          variant="outlined"
          size="large"
        >
          Cancelar
        </Button>
        <Button
          style={{ margin: '5px' }}
          onClick={handleSubmit}
          size="large"
          disabled={selectedColumns.length <= 0}
        >
          {item ? 'Salvar' : 'Adicionar'}
        </Button>
      </ActionsGroup>
    </>
  );
};

SubsetTableCreate.propTypes = {
  item: PropTypes.shape({
    database: PropTypes.string,
    meta: PropTypes.shape({
      values: PropTypes.arrayOf(PropTypes.shape({
        column: PropTypes.string,
        map: PropTypes.string,
        type: PropTypes.string,
      })),
      control: PropTypes.arrayOf(PropTypes.shape({})),
      filters: PropTypes.arrayOf(PropTypes.shape({})),
      maps: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    style: PropTypes.shape({}),
  }),
  hide: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  userId: PropTypes.string,
  stylesData: PropTypes.shape({
    borderStyle: PropTypes.shape({
      line: PropTypes.string,
      color: PropTypes.string,
      size: PropTypes.number,
      rounded: PropTypes.number,
    }),
    backgroundColor: PropTypes.string,
    useGlobalTheme: PropTypes.bool,
  }),
  filesOpts: PropTypes.arrayOf(PropTypes.shape({})),
  getFileColumns: PropTypes.func.isRequired,
  genTable: PropTypes.func.isRequired,
  getColumnUnique: PropTypes.func.isRequired,
};

SubsetTableCreate.defaultProps = {
  item: undefined,
  userId: '',
  stylesData: {},
  filesOpts: [],
};

export default SubsetTableCreate;
