import React from 'react';

// import { Container } from './styles';

function ClosedLock() {
  return (
    <svg
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 477.867 477.867"
      xmlSpace="preserve"
      width="18px"
      height="18px"
    >
      <path d="M409.6,170.667h-17.067V153.6c0-84.831-68.769-153.6-153.6-153.6s-153.6,68.769-153.6,153.6v17.067H68.267
       c-9.426,0-17.067,7.641-17.067,17.067V460.8c0,9.426,7.641,17.067,17.067,17.067H409.6c9.426,0,17.067-7.641,17.067-17.067
       V187.733C426.667,178.308,419.026,170.667,409.6,170.667z M287.205,324.055c-5.155,14.581-16.624,26.05-31.205,31.205v37.274
       c0,9.426-7.641,17.067-17.067,17.067s-17.067-7.641-17.067-17.067V355.26c-26.66-9.426-40.631-38.679-31.205-65.339
       S229.34,249.29,256,258.716C282.66,268.142,296.631,297.395,287.205,324.055z M358.4,170.667H119.467V153.6
       c0-65.98,53.487-119.467,119.467-119.467S358.4,87.62,358.4,153.6V170.667z"
      />
    </svg>

  );
}

export default ClosedLock;
