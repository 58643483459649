import React from 'react';
import CookieConsent from 'react-cookie-consent';
import { useTheme } from 'styled-components';

import Link from './styled/CookiesMessage.styled';

const getCookieName = () => {
  switch (process.env.REACT_APP_FIREBASE_PROJECT_LABEL) {
    case 'legalone-analytics':
      return 'Legal One Analytics Cookie';

    case 'benner-metrics':
      return 'Benner Metrics Cookie';

    default:
      return 'Metrics Cookie';
  }
};

const CookiesMessage = () => {

  const theme = useTheme();

  return (
    <CookieConsent
      debug={false}
      location="bottom"
      buttonText="Aceitar"
      cookieName={() => getCookieName()}
      style={{
        background: theme.headerBackground,
        alignItems: 'center',
        zIndex: '1000000',
      }}
      contentStyle={{
        color: theme.color,
        textAlign: 'left',
        justifyContent: 'flex-start',
      }}
      buttonClasses="acceptButton"
      buttonStyle={{
        backgroundColor: theme.primary,
        color: '#FFFFFF',
        padding: '14px',
        fontWeight: 'bold',
        fontSize: '15px',
        border: `2px solid ${theme.primary}`,
        borderRadius: '6px',
      }}
      declineButtonStyle={{
        backgroundColor: 'transparent',
        color: theme.primary,
        padding: '14px',
        fontWeight: 'bold',
        fontSize: '15px',
        border: `2px solid ${theme.primary}`,
        borderRadius: '6px',
      }}
      expires={150}
    >
      Nós utilizamos cookies e tecnologias semelhantes para garantir a segurança e melhorar a sua
      experiência com nossos serviços e plataformas, de acordo com nossa
      {' '}
      <Link
        href="https://juristecplus.com/politica-de-privacidade/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Política de Privacidade e Proteção de Dados Pessoais
      </Link>
      . Ao continuar navegando, você concorda com estas condições.
    </CookieConsent>
  );
};

export default CookiesMessage;
