/* eslint-disable react/forbid-prop-types */
import React, {
  useRef, useLayoutEffect, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import * as d3 from 'd3';

import ChartContainer from './styled/BarChart.styled';

const BarChart = ({
  data, result, dataColors, chartStyle,
}) => {
  const chRef = useRef(null);
  const [sizes, setSizes] = useState({ width: 0, height: 0 });

  useEffect(() => {
    function handleResize() {
      const svgContainer = d3.select(chRef.current).node();
      setSizes(svgContainer.getBoundingClientRect());
    }
    if (chRef.current) {
      handleResize();
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [chRef.current]);

  // Mostra os valores no hover
  const hoverIn = (index) => {
    d3.select(chRef.current).select(`path[data-index='${index}']`)
      .transition()
      .duration('50')
      .attr('opacity', '.85');

    d3.select(chRef.current).select('.tooltip-area')
      .style('display', 'block');
  };

  // Posiciona os valores de acordo com a posicao do mouse
  const hoverMove = (e, label, value) => {
    const [x, y] = d3.pointer(e);
    const area = d3.select(chRef.current).select('.tooltip-area')
      .attr('transform', `translate(${x + 20}, ${y + 10})`)
      .select('.tooltip-text')
      .text(`${label}: ${value}`);
    const areaSizes = area.node().getBBox();

    d3.select(chRef.current).select('.tooltip-area .tooltip-bg')
      .attr('x', areaSizes.x - 5)
      .attr('y', areaSizes.y - 5)
      .attr('width', areaSizes.width + 10)
      .attr('height', areaSizes.height + 10);
  };

  // Esconde os valores
  const hoverOut = (index) => {
    d3.select(chRef.current).select(`path[data-index='${index}']`)
      .transition()
      .duration('50')
      .attr('opacity', '1');

    d3.select(chRef.current).select('.tooltip-area')
      .style('display', 'none');
  };

  useLayoutEffect(() => {
    d3.select(chRef.current).selectAll('svg').remove();
    // const svgContainer = d3.select(chRef.current).node();
    const { height, width } = sizes;// svgContainer.getBoundingClientRect();

    data.sort((a, b) => d3.descending(a.value, b.value));

    const yScale = d3.scaleBand()
      .range([0, Math.max(height, (data.length * 12))])
      .padding(0.2)
      .domain(data.map((d) => d.label));
    const colors = d3.scaleSequential()
      .interpolator(d3.interpolateRainbow)
      .domain([0, data.length]);
    // const radius = (minContainerSize - legendStyle.size) / 2 - chartStyle.margin;

    // Inicia o SVG
    const svg = d3
      .select(chRef.current)
      .append('svg')
      // .select('#chart')
      .attr('height', Math.max(height, (data.length * 12)))
      .attr('width', width)
      // .attr('viewBox', `0 0 ${width} ${height}`)
      // .attr('preserveAspectRatio', 'xMinYMin')
      .append('g');
      // .attr('transform', 'translate(100, 0)');

    svg.append('g')
      .attr('class', 'axis-g')
      .call(d3.axisLeft(yScale).tickSizeOuter(0).tickSizeInner(0))
      .call((g) => g.select('.domain').remove())
      .append('text')
      .attr('y', 6)
      .attr('dy', '0.71em')
      .attr('text-anchor', 'end')
      .text('value');

    const legendWidth = svg.select('.axis-g').node().getBBox().width;
    svg.attr('transform', `translate(${legendWidth}, 0)`);

    const xScale = d3.scaleLinear()
      .range([
        width,
        legendWidth + 2,
      ])
      .domain([0, d3.max(data, (d) => d.value)]);

    svg.selectAll('.bar-g')
      .data(data)
      .enter()
      .append('g')
      .attr('class', 'bar-g')
      .append('rect')
      .attr('class', 'bar')
      // .attr('x', (d) => xScale(d.value))
      .attr('rx', '3')
      .attr('y', (d) => yScale(d.label))
      .attr('width', (d) => width - xScale(d.value))
      .attr('height', yScale.bandwidth())
      .attr('fill', (d) => colors(d.index))
      .attr('data-index', (d) => d.index)
      .on('mouseover', (_e, d) => hoverIn(d.index))
      .on('mousemove', (e, d) => hoverMove(e, d.label, d.value))
      .on('mouseout', (_e, d) => hoverOut(d.index));

    /* svg.selectAll('.bar-g')
      .append('text')
      .attr('x', (d) => width - xScale(d.value))
      .attr('y', (d) => yScale(d.label))
      .attr('dy', '0.9em')
      .attr('text-anchor', 'end')
      .attr('data-index', (d) => d.index)
      .attr('opacity', '0')
      .style('user-select', 'none')
      .text((d) => d.value)
      .on('mouseover', (_e, d) => (d.value > 0 ? hoverIn(d.index) : () => {}))
      .on('mouseout', (_e, d) => (d.value > 0 ? hoverOut(d.index) : () => {})); */

    // Grupo do Tooltip
    svg
      .append('g')
      .style('display', 'none')
      .attr('class', 'tooltip-area')
      .append('rect')
      .attr('class', 'tooltip-bg')
      .attr('rx', '5')
      .style('stroke', 'grey')
      .style('stroke-width', '.5')
      .attr('fill', 'white');

    // Texto do tooltip
    svg.select('.tooltip-area')
      .append('text')
      .attr('class', 'tooltip-text')
      .style('fill', 'black');
  }, [data, chartStyle, dataColors, result, sizes]);

  return (
    <>
      <ChartContainer ref={chRef} />
    </>
  );
};

BarChart.propTypes = {
  /**
   * A array of data to be displayed in the donut chart
   */
  data: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
  })).isRequired,
  /**
   * An object with the result value of the data
   */
  result: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
  })),
  /**
   * An object with the colors for the chart data
   */
  dataColors: PropTypes.objectOf(PropTypes.any),
  /**
   * An object with custom styles for the chart
   */
  chartStyle: PropTypes.objectOf(PropTypes.any),
};

// TODO adicionar customização ao gráfico
BarChart.defaultProps = {
  dataColors: {},
  result: [{}],
  chartStyle: {},
};

export default BarChart;
