import React from "react";

export default function Underline() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 448 512"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M32 64h32v160c0 88.22 71.78 160 160 160s160-71.78 160-160V64h32a16 16 0 0 0 16-16V16a16 16 0 0 0-16-16H272a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h32v160a80 80 0 0 1-160 0V64h32a16 16 0 0 0 16-16V16a16 16 0 0 0-16-16H32a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16zm400 384H16a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16z" />
    </svg>
  );
};
  // return (
  //   <svg
  //     width="18"
  //     height="20"
  //     viewBox="0 0 18 20"
  //     fill="#FFF"
  //     xmlns="http://www.w3.org/2000/svg"
  //   >
  //     <path d="M8.722 19.4445C13.3262 19.4445 17.0553 15.7153 17.0553 11.1111V0H13.5831V11.1111C13.5831 13.7986 11.4095 15.9723 8.722 15.9723C6.0345 15.9723 3.86087 13.7986 3.86087 11.1111V0H0.388672V11.1111C0.388672 15.7153 4.11784 19.4445 8.722 19.4445Z" />
  //   </svg>
  // );
//}