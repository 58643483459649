import React from 'react';

const MetricsLogo = ({ height, width }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 504.17 127.15"
  >
    <path
      fill="#FF9626"
      d="M69.25,66.53h48.94c1.98,0,3.58,1.6,3.58,3.58v48.94c0,1.98-1.6,3.58-3.58,3.58H69.25
c-1.98,0-3.58-1.6-3.58-3.58V70.11C65.66,68.14,67.27,66.53,69.25,66.53z"
    />
    <path
      fill="#4712B3"
      d="M7.62,5.1h48.94c1.98,0,3.58,1.6,3.58,3.58v48.94c0,1.98-1.6,3.58-3.58,3.58H7.62c-1.98,0-3.58-1.6-3.58-3.58
V8.68C4.03,6.7,5.64,5.1,7.62,5.1z"
    />
    <g>
      <path
        fill="#FF9626"
        d="M31.99,51.38c-10.1,0-18.32-8.22-18.32-18.32s8.22-18.32,18.32-18.32s18.32,8.22,18.32,18.32
S42.09,51.38,31.99,51.38z M31.99,21.92c-6.15,0-11.15,5-11.15,11.15s5,11.15,11.15,11.15s11.15-5,11.15-11.15
C43.13,26.92,38.13,21.92,31.99,21.92z"
      />
    </g>
    <path
      fill="#4712B3"
      d="M7.62,66.33h48.94c1.98,0,3.58,1.6,3.58,3.58v48.94c0,1.98-1.6,3.58-3.58,3.58H7.62c-1.98,0-3.58-1.6-3.58-3.58
V69.91C4.03,67.94,5.64,66.33,7.62,66.33z"
    />
    <g>
      <path
        fill="#4712B3"
        d="M69.25,5.1h48.94c1.98,0,3.58,1.6,3.58,3.58v48.94c0,1.98-1.6,3.58-3.58,3.58H69.25
c-1.98,0-3.58-1.6-3.58-3.58V8.68C65.66,6.7,67.27,5.1,69.25,5.1z"
      />
      <rect x="75.4" y="29.57" fill="#FF9626" width="36.63" height="7.17" />
    </g>
    <g>
      <path
        fill="#FF9626"
        d="M188.77,95.15c0-4.69-0.6-8.02-1.81-9.97c-1.21-1.96-3.28-2.93-6.21-2.93c-0.91,0-1.86,0.05-2.84,0.15
s-1.92,0.21-2.84,0.34v39.7h-14.56V72.76c1.24-0.33,2.69-0.67,4.35-1.03s3.42-0.68,5.28-0.98c1.86-0.29,3.76-0.52,5.72-0.68
c1.96-0.16,3.88-0.24,5.77-0.24c3.72,0,6.73,0.47,9.04,1.42s4.22,2.07,5.72,3.37c2.09-1.5,4.48-2.67,7.19-3.52
c2.7-0.85,5.2-1.27,7.48-1.27c4.11,0,7.48,0.57,10.12,1.71c2.64,1.14,4.74,2.75,6.31,4.84c1.56,2.09,2.64,4.56,3.23,7.43
s0.88,6.06,0.88,9.58v29.04h-14.57V95.15c0-4.69-0.6-8.02-1.81-9.97c-1.21-1.96-3.28-2.93-6.21-2.93c-0.78,0-1.87,0.2-3.28,0.59
c-1.4,0.39-2.56,0.88-3.47,1.47c0.45,1.5,0.75,3.08,0.88,4.74s0.2,3.44,0.2,5.33v28.06h-14.57L188.77,95.15L188.77,95.15z"
      />
      <path
        fill="#FF9626"
        d="M242.36,97.11c0-4.56,0.7-8.56,2.1-11.98s3.24-6.27,5.52-8.56c2.28-2.28,4.9-4.01,7.87-5.18
c2.97-1.17,6.01-1.76,9.14-1.76c7.3,0,13.07,2.23,17.31,6.7s6.36,11.03,6.36,19.7c0,0.85-0.03,1.78-0.1,2.79s-0.13,1.91-0.2,2.69
h-33.05c0.33,3,1.73,5.38,4.2,7.14c2.48,1.76,5.8,2.64,9.97,2.64c2.67,0,5.3-0.24,7.87-0.73c2.57-0.49,4.68-1.09,6.31-1.81
l1.96,11.83c-0.78,0.39-1.83,0.78-3.13,1.17c-1.3,0.39-2.76,0.73-4.35,1.03c-1.6,0.29-3.31,0.54-5.13,0.73
c-1.83,0.2-3.65,0.29-5.48,0.29c-4.63,0-8.65-0.68-12.08-2.05c-3.42-1.37-6.26-3.24-8.51-5.62c-2.25-2.38-3.91-5.2-4.99-8.46
C242.9,104.41,242.36,100.89,242.36,97.11z M276.58,91.53c-0.07-1.24-0.28-2.44-0.64-3.62c-0.36-1.17-0.91-2.22-1.66-3.13
s-1.7-1.66-2.84-2.25c-1.14-0.59-2.56-0.88-4.25-0.88c-1.63,0-3.03,0.28-4.21,0.83c-1.17,0.55-2.15,1.29-2.93,2.2
s-1.39,1.97-1.81,3.18c-0.42,1.21-0.73,2.43-0.93,3.67L276.58,91.53L276.58,91.53z"
      />
      <path
        fill="#FF9626"
        d="M301.42,58.19l14.57-2.35V71h17.5v12.13h-17.5v18.09c0,3.06,0.54,5.51,1.61,7.33c1.08,1.83,3.24,2.74,6.5,2.74
c1.56,0,3.18-0.15,4.84-0.44c1.66-0.29,3.18-0.7,4.55-1.22l2.05,11.34c-1.76,0.72-3.72,1.34-5.87,1.86s-4.79,0.78-7.92,0.78
c-3.98,0-7.27-0.54-9.88-1.61c-2.61-1.08-4.69-2.57-6.26-4.5c-1.56-1.92-2.66-4.25-3.28-6.99s-0.93-5.77-0.93-9.09V58.19H301.42z"
      />
      <path
        fill="#FF9626"
        d="M376.32,83.91c-1.3-0.33-2.84-0.67-4.6-1.03s-3.65-0.54-5.67-0.54c-0.91,0-2,0.08-3.28,0.24
c-1.27,0.16-2.23,0.34-2.88,0.54v39.31h-14.57v-48.7c2.61-0.91,5.69-1.78,9.24-2.59s7.51-1.22,11.88-1.22
c0.78,0,1.73,0.05,2.84,0.15c1.11,0.1,2.22,0.23,3.32,0.39c1.11,0.16,2.22,0.36,3.32,0.59c1.11,0.23,2.05,0.51,2.84,0.83
L376.32,83.91z"
      />
      <path
        fill="#FF9626"
        d="M402.53,56.14c0,2.67-0.86,4.78-2.59,6.31s-3.76,2.3-6.11,2.3c-2.35,0-4.39-0.77-6.11-2.3
c-1.73-1.53-2.59-3.63-2.59-6.31c0-2.67,0.86-4.77,2.59-6.31c1.73-1.53,3.76-2.3,6.11-2.3c2.35,0,4.38,0.77,6.11,2.3
C401.66,51.36,402.53,53.46,402.53,56.14z M401.16,122.43h-14.57V70.99h14.57V122.43z"
      />
      <path
        fill="#FF9626"
        d="M412.4,96.72c0-3.72,0.6-7.22,1.81-10.51s2.95-6.16,5.23-8.61c2.28-2.44,5.05-4.38,8.31-5.82
c3.26-1.43,6.97-2.15,11.15-2.15c2.74,0,5.25,0.24,7.53,0.73c2.28,0.49,4.5,1.19,6.65,2.1l-3.03,11.64
c-1.37-0.52-2.87-0.98-4.5-1.37s-3.46-0.59-5.48-0.59c-4.3,0-7.51,1.34-9.63,4.01s-3.18,6.19-3.18,10.56
c0,4.63,0.99,8.21,2.98,10.76c1.99,2.54,5.46,3.81,10.41,3.81c1.76,0,3.65-0.16,5.67-0.49c2.02-0.33,3.88-0.85,5.57-1.56
l2.05,11.93c-1.7,0.72-3.81,1.34-6.36,1.86c-2.54,0.52-5.35,0.78-8.41,0.78c-4.69,0-8.74-0.7-12.13-2.1
c-3.39-1.4-6.18-3.31-8.36-5.72c-2.19-2.41-3.78-5.26-4.79-8.56C412.91,104.13,412.4,100.56,412.4,96.72z"
      />
      <path
        fill="#FF9626"
        d="M478.51,111.97c2.67,0,4.56-0.26,5.67-0.78s1.66-1.53,1.66-3.03c0-1.17-0.72-2.2-2.15-3.08
c-1.43-0.88-3.62-1.87-6.55-2.98c-2.28-0.85-4.35-1.73-6.21-2.64c-1.86-0.91-3.44-2-4.74-3.28c-1.3-1.27-2.31-2.79-3.03-4.55
c-0.72-1.76-1.08-3.88-1.08-6.36c0-4.82,1.79-8.64,5.38-11.44c3.58-2.8,8.51-4.2,14.77-4.2c3.13,0,6.13,0.28,9,0.83
s5.15,1.16,6.85,1.81l-2.54,11.34c-1.7-0.59-3.54-1.11-5.52-1.56c-1.99-0.46-4.22-0.68-6.7-0.68c-4.56,0-6.84,1.27-6.84,3.81
c0,0.59,0.1,1.11,0.29,1.56c0.2,0.46,0.59,0.9,1.17,1.32c0.59,0.42,1.38,0.88,2.4,1.37c1.01,0.49,2.3,1.04,3.86,1.63
c3.19,1.18,5.83,2.35,7.92,3.5c2.09,1.15,3.73,2.39,4.94,3.73c1.21,1.33,2.05,2.81,2.54,4.44s0.73,3.51,0.73,5.66
c0,5.07-1.91,8.91-5.72,11.51c-3.81,2.6-9.21,3.9-16.18,3.9c-4.56,0-8.36-0.39-11.39-1.17s-5.13-1.43-6.31-1.96l2.44-11.83
c2.48,0.98,5.02,1.75,7.63,2.3C473.39,111.7,475.96,111.97,478.51,111.97z"
      />
    </g>
    <g>
      <path
        fill="#4712B3"
        d="M165.66,49.21c-2.91-0.07-4.98-0.69-6.2-1.88c-1.22-1.19-1.83-3.03-1.83-5.54V10.08l4.73-0.81v31.77
c0,0.78,0.07,1.42,0.2,1.93c0.14,0.51,0.36,0.91,0.66,1.22s0.71,0.53,1.22,0.69c0.51,0.15,1.14,0.28,1.88,0.38L165.66,49.21z"
      />
      <path
        fill="#4712B3"
        d="M170.13,35.54c0-2.34,0.34-4.38,1.02-6.12c0.68-1.74,1.58-3.19,2.69-4.35c1.12-1.15,2.41-2.02,3.86-2.59
c1.46-0.58,2.95-0.86,4.47-0.86c3.56,0,6.28,1.11,8.18,3.33s2.85,5.6,2.85,10.14c0,0.2,0,0.47,0,0.79s-0.02,0.62-0.05,0.89h-18.09
c0.2,2.74,1,4.83,2.39,6.25s3.56,2.13,6.51,2.13c1.66,0,3.06-0.14,4.19-0.43s1.99-0.57,2.57-0.84l0.66,3.96
c-0.58,0.3-1.58,0.63-3.02,0.97c-1.44,0.34-3.07,0.51-4.9,0.51c-2.3,0-4.29-0.35-5.97-1.04c-1.68-0.69-3.06-1.65-4.14-2.87
s-1.89-2.67-2.41-4.35C170.39,39.38,170.13,37.54,170.13,35.54z M188.28,32.95c0.03-2.13-0.5-3.89-1.6-5.26s-2.62-2.06-4.55-2.06
c-1.08,0-2.04,0.21-2.87,0.64c-0.83,0.42-1.53,0.97-2.11,1.65s-1.03,1.46-1.35,2.34s-0.53,1.78-0.64,2.69H188.28z"
      />
      <path
        fill="#4712B3"
        d="M216.59,46.06c-0.41,0.27-1.19,0.62-2.36,1.04c-1.17,0.42-2.53,0.64-4.09,0.64c-1.59,0-3.09-0.25-4.5-0.76
s-2.63-1.3-3.68-2.36c-1.05-1.07-1.88-2.4-2.49-3.99s-0.91-3.49-0.91-5.69c0-1.93,0.29-3.7,0.86-5.31c0.58-1.61,1.41-3,2.52-4.17
c1.1-1.17,2.45-2.08,4.04-2.74c1.59-0.66,3.39-0.99,5.39-0.99c2.2,0,4.12,0.16,5.77,0.48c1.64,0.32,3.02,0.62,4.14,0.89v23.58
c0,4.07-1.05,7.01-3.15,8.84s-5.29,2.74-9.56,2.74c-1.66,0-3.23-0.14-4.7-0.41c-1.47-0.27-2.75-0.59-3.84-0.97l0.86-4.12
c0.95,0.37,2.11,0.7,3.48,0.99c1.37,0.29,2.8,0.43,4.29,0.43c2.81,0,4.84-0.56,6.07-1.68c1.24-1.12,1.85-2.9,1.85-5.34
L216.59,46.06L216.59,46.06z M216.53,26.44c-0.48-0.14-1.11-0.26-1.91-0.38c-0.8-0.12-1.87-0.18-3.23-0.18
c-2.54,0-4.5,0.83-5.87,2.49c-1.37,1.66-2.06,3.86-2.06,6.61c0,1.52,0.19,2.83,0.58,3.91c0.39,1.08,0.91,1.98,1.58,2.69
c0.66,0.71,1.42,1.24,2.29,1.58c0.86,0.34,1.75,0.51,2.67,0.51c1.25,0,2.4-0.18,3.46-0.53c1.05-0.36,1.88-0.77,2.49-1.25
L216.53,26.44L216.53,26.44z"
      />
      <path
        fill="#4712B3"
        d="M237.93,21.61c1.9,0,3.5,0.25,4.8,0.74s2.35,1.19,3.15,2.08c0.8,0.9,1.36,1.97,1.7,3.2
c0.34,1.24,0.51,2.6,0.51,4.09v16.52c-0.41,0.07-0.97,0.16-1.7,0.28c-0.73,0.12-1.55,0.23-2.46,0.33s-1.91,0.19-2.97,0.28
c-1.07,0.08-2.13,0.13-3.18,0.13c-1.49,0-2.86-0.15-4.12-0.46c-1.25-0.3-2.34-0.79-3.25-1.45c-0.91-0.66-1.63-1.53-2.13-2.62
c-0.51-1.08-0.76-2.39-0.76-3.91c0-1.46,0.3-2.71,0.89-3.76c0.59-1.05,1.4-1.9,2.41-2.54c1.02-0.64,2.2-1.12,3.56-1.42
c1.35-0.3,2.78-0.46,4.27-0.46c0.47,0,0.97,0.03,1.47,0.08c0.51,0.05,0.99,0.12,1.45,0.2c0.46,0.08,0.86,0.16,1.19,0.23
c0.34,0.07,0.58,0.12,0.71,0.15v-1.32c0-0.78-0.09-1.55-0.25-2.31c-0.17-0.76-0.47-1.44-0.91-2.03s-1.04-1.07-1.8-1.42
s-1.75-0.53-2.97-0.53c-1.56,0-2.92,0.11-4.09,0.33c-1.17,0.22-2.04,0.45-2.62,0.69l-0.56-3.91c0.61-0.27,1.63-0.53,3.05-0.79
C234.73,21.74,236.27,21.61,237.93,21.61z M238.34,45.3c1.12,0,2.11-0.03,2.97-0.08c0.86-0.05,1.58-0.14,2.16-0.28v-7.88
c-0.34-0.17-0.89-0.31-1.65-0.43c-0.76-0.12-1.69-0.18-2.77-0.18c-0.71,0-1.47,0.05-2.26,0.15c-0.8,0.1-1.52,0.31-2.19,0.64
c-0.66,0.32-1.21,0.76-1.65,1.32s-0.66,1.3-0.66,2.21c0,1.69,0.54,2.87,1.63,3.53C235,44.97,236.47,45.3,238.34,45.3z"
      />
      <path
        fill="#4712B3"
        d="M263.85,49.21c-2.91-0.07-4.98-0.69-6.2-1.88c-1.22-1.19-1.83-3.03-1.83-5.54V10.08l4.73-0.81v31.77
c0,0.78,0.07,1.42,0.2,1.93c0.14,0.51,0.36,0.91,0.66,1.22c0.3,0.3,0.71,0.53,1.22,0.69c0.51,0.15,1.14,0.28,1.88,0.38
L263.85,49.21z"
      />
    </g>
    <g>
      <polygon
        fill="#FF9626"
        points="13.36,107.58 9.19,103.43 25.61,86.97 36.66,97.89 54.58,80.13 58.72,84.31 36.66,106.16 25.64,95.27"
      />
    </g>
  </svg>
);

export default MetricsLogo;
