import styled from 'styled-components';

export const Timeline = styled.div`
  border: solid #ddd;
  border-width: 1.6px 0;
  height: calc(100vh - 130px);
  width: 100%;
  padding: 0;
  margin: 10px 0;
  overflow: hidden auto;
  scroll-snap-type: mandatory;
  scroll-snap-type: y mandatory;
  & > div {
    scroll-snap-align: end;
    &:first-child {
      scroll-snap-align: start;
    }
  }
`;

export const MuralOpts = styled.div`
  display: flex;
  padding: 5px 0 0 5px;
  width: 100%;
  align-items: center;
`;

export const MuralTitle = styled.h2`
  margin: 0 24%;
  color: ${({ theme }) => theme.primary};
`;

export const MsgContainer = styled.div`
  display: flex;
  padding: 0.5rem 1rem;
  font-size: 14px;
  border: solid ${({ theme }) => theme.sideMenu};
  border-width: 2px 0;
  & .timestamp {
    font-size: 10px;
    white-space: nowrap;
    margin: 4px 0 -4px auto;
  }
  & .highlight {
    color: ${({ theme }) => theme.secondary};
    font-weight: bold;
    white-space: nowrap;
  }
  & .mention {
    font-style: italic;
  }
`;

export const SystemMsg = styled(MsgContainer)`
  flex-direction: column;
  text-align: start;
  font-size: 12px;
  & .timestamp {
    margin: 4px 0 -4px 0;
  }
`;

export const UserMsg = styled(MsgContainer)`
  flex-direction: row;
  background-color: ${({ theme }) => theme.container};
`;

export const UserMsgEdit = styled(UserMsg)`
  padding: .5rem 0 0 0;
  & > div {
    width: 100%;
  }
`;

export const UserMsgContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 5px;
  width: 100%;
  & .header {
    margin-right: -5px;
    margin-bottom: 4px;
    display: flex;
    font-weight: bold;
    & > span {
      margin-right: auto;
    }
    & .detail {
      font-weight: normal;
      color: ${({ theme }) => theme.grey};
      font-size: 12px;
    }
  }
  & .content {
    line-break: anywhere;
  }
`;

export const NoMessagesContainer = styled.div`
  color: ${({ theme }) => theme.grey};
  padding: 40px 10px;
  text-align: center;
  user-select: none;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: ${({ theme }) => theme.backgroundOdd};
  border-radius: 10px;
  box-shadow: 0px 4px 4px ${({ theme }) => theme.shadow};
  padding: .2rem .4rem;
  margin: 0 .5rem .5rem .5rem;
  & > .textAreaContainer {
    width: 100%;
    height: 60px;
    & textarea {
      width: 100%;
      font-size: 14px;
    }
    & .textAreaBar {
      bottom: 1px;
    }
  }
`;

export const InputControlsContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: .2rem;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  & > div {
    display: flex;
    flex-direction: row;
    & > button {
      max-height: 30px;
    }
  }
`;

export const IconWrapper = styled.div`
  margin-left: 5px;
  height: 15px;
  width: 15px;
  & > svg {
    fill: ${({ theme }) => theme.white};
  }
`;
