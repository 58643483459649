import styled from 'styled-components';

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: .5rem;
  max-width: 500px;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  
  & h3 {
    margin: 0;
  }
  & span {
    font-size: 12px;
    color: ${({ theme }) => theme.grey};
  }
`;

export const TextContainer = styled.div`
  max-height: calc(100vh - 325px);
  padding: 0 .5rem;
  overflow: auto;
  background-color: ${({ theme }) => theme.card};
  font-size: 14px;

  &:focus {
    outline: none;
  }

  & h3 {
    text-align: center;
  }
  & h3.section {
    text-align: left;
  }
  & p, li {
    text-align: justify;
  }
  & li {
    margin: .5rem 0;
  }
  & ul, ol {
    padding-left: 1.5rem;
  }
  & a {
    text-decoration-line: none;
    color: ${({ theme }) => theme.info};
    font-weight: bold;
    &:hover {
      text-decoration-line: underline;
    }
  }
  & p.right {
    text-align: right;
    margin-bottom: 2px;
  }
`;

export const CheckboxInputWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;
