import styled from 'styled-components';
import { gap } from '../../../styles/theme';
import { devices } from '../../breakpoints';

export const Container = styled.div`
  padding: 0 .5rem;
  font-weight: 500;

  .public-link-title { 
    font-size: 0.85rem;
    font-weight: bold;
    color: ${({ theme }) => theme.highlightTextLine};
  }

  .public-link-main { 
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 100%;
  }

  .public-link-infos {
    display: flex;
    flex-direction: column;
    ${gap('5px', 'column')}
  }

  .public-link-link {  
    color: ${({ theme }) => theme.color};
    font-size: 12px;
    max-width: 280px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    white-space: nowrap;
    width: 100%;
    @media ${devices.phoneS} {
      max-width: calc(100vw - 165px);
    }
  }

  .public-link-timestamp { 
    color: ${({ theme }) => theme.grey};
    font-size: 12px;
    width: 100%;
  }
`;

export const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  ${gap('5px', 'row')}

  .public-link-gen-link { 
    &:hover { 
      background-color: ${({ theme }) => theme.primary + 30};
    }
  }
`;
