/* eslint-disable no-param-reassign */
import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import InputTextLine from '../../../../juristec-ui/core/InputTextLine';
import TextArea from '../../../../juristec-ui/core/TextArea';
import Select from '../../../../juristec-ui/core/Select';

import { verifyInput } from '../../../../juristec-ui/utils/validators/inputTextValidators';
import { trimString } from '../../../../juristec-ui/utils/functions/formatString';

import {
  MainContainer,
  InputRow,
  OptionGroup,
  Option,
} from './styled/FirstStep.styled';

const FirstStep = ({
  templateNameState,
  categoryOptsState,
  categoryState,
  difficultyState,
  descriptionState,
}) => {
  const templateNameRef = useRef(null);
  const [templateName, setTemplateName] = templateNameState;
  const [category, setCategory] = categoryState;
  const [difficulty, setDifficulty] = difficultyState;
  const [description, setDescription] = descriptionState;
  const [categoryOpts, setCategoryOpts] = categoryOptsState;

  const templateNameHandle = (val) => {
    if (val instanceof Object) val = val.target.value;
    const msg = verifyInput(val, true, 2);
    setTemplateName({
      value: val,
      error: msg.length !== 0,
      errorMsg: msg,
    });
  };

  const templateNameBlur = () => {
    setTemplateName({
      ...templateName,
      value: trimString(templateName.value),
    });
  };

  const categoryHandle = (selected) => {
    if (typeof (selected) === 'object') {
      setCategory(selected);
    }
  };

  const handleCreateCategory = (newLabel) => {
    const newOption = { label: newLabel, value: newLabel, id: `category-${newLabel}` };
    setCategory(newOption);
    setCategoryOpts((old) => [...old, newOption]);
  };

  const descriptionHandle = (e) => {
    const val = e.target.value;
    const msg = verifyInput(val, true, 2, 800);
    setDescription({
      value: val,
      error: msg.length !== 0,
      errorMsg: msg,
    });
  };

  const descriptionBlur = () => {
    setDescription({
      ...description,
      value: trimString(description.value),
    });
  };

  return (
    <MainContainer>
      <InputRow>
        <InputTextLine
          ref={templateNameRef}
          label="Nome do Template"
          name="templateName"
          error={templateName.errorMsg.length > 0}
          errorMessage={templateName.errorMsg}
          value={templateName.value}
          setValue={templateNameHandle}
          emojiPicker
          emojiPickerPosition="bottom-end"
          onChange={templateNameHandle}
          onBlur={templateNameBlur}
        />
        <Select
          selectLabel="Categoria"
          placeholder="Crie ou selecione"
          options={categoryOpts}
          fullWidth
          placement="start"
          isSearchable
          isCreatable
          value={category}
          onChange={categoryHandle}
          onCreateOption={handleCreateCategory}
          style={{ marginTop: '15px' }}
          atModal
        />
      </InputRow>
      <InputRow>
        <OptionGroup>
          <span>Nível</span>
          <Option
            colorKey="info"
            onClick={() => setDifficulty('easy')}
            active={difficulty === 'easy'}
          >
            Iniciante
          </Option>
          <Option
            colorKey="success"
            onClick={() => setDifficulty('medium')}
            active={difficulty === 'medium'}
          >
            Intermediário
          </Option>
          <Option
            colorKey="warning"
            onClick={() => setDifficulty('advanced')}
            active={difficulty === 'advanced'}
          >
            Avançado
          </Option>
          <Option
            colorKey="error"
            onClick={() => setDifficulty('expert')}
            active={difficulty === 'expert'}
          >
            Expert
          </Option>
        </OptionGroup>
      </InputRow>
      <InputRow style={{ height: '100%' }}>
        <TextArea
          label="Descrição"
          name="description"
          error={description.errorMsg.length > 0}
          errorMessage={description.errorMsg}
          value={description.value}
          onChange={descriptionHandle}
          onBlur={descriptionBlur}
          styleContainer={{ boxSizing: 'border-box', width: '100%' }}
        />
      </InputRow>
    </MainContainer>
  );
};

FirstStep.propTypes = {
  templateNameState: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.shape({
      value: PropTypes.string,
      errorMsg: PropTypes.string,
      error: PropTypes.bool,
    }),
    PropTypes.func,
  ])),
  categoryOptsState: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape({})),
    PropTypes.func,
  ])),
  categoryState: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.shape({
      value: PropTypes.string,
      errorMsg: PropTypes.string,
      error: PropTypes.bool,
    }),
    PropTypes.func,
  ])),
  difficultyState: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
  ])),
  descriptionState: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.shape({
      value: PropTypes.string,
      errorMsg: PropTypes.string,
      error: PropTypes.bool,
    }),
    PropTypes.func,
  ])),
};

FirstStep.defaultProps = {
  templateNameState: [{}, () => {}],
  categoryOptsState: [[], () => {}],
  categoryState: [{}, () => {}],
  difficultyState: ['', () => {}],
  descriptionState: [{}, () => {}],
};

export default FirstStep;
