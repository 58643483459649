import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import StyledIframe from './styled/Iframe.styled';

const Iframe = ({
  item,
}) => {
  const [link, setLink] = useState(item?.link ?? '');

  useEffect(() => {
    setLink(item?.link ?? '');
  }, [item]);

  return (
    <StyledIframe
      title="KPI Customizado"
      className="iframeComponent"
      src={link}
      // srcDoc={item?.linkType === 'plotly' ? item.link : null}
    />
  );
};

Iframe.propTypes = {
  item: PropTypes.shape({
    linkType: PropTypes.string,
    link: PropTypes.string,
  }),
};

Iframe.defaultProps = {
  item: {},
};

export default Iframe;
