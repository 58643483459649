import firebase from '../utils/firebase';
import { getRefNotificationsCount, getRefNotifications } from '../utils/firestore';

const populate = (users, company, transaction) => {
  const indiceRef = getRefNotificationsCount(company);

  users.forEach((id) => {
    transaction.set(
      indiceRef.doc(id),
      { new: firebase.increment(1), total: firebase.increment(1) },
      { merge: true },
    );
  });
  return transaction;
};

const notificationTriggers = (type, obj, transaction = {}) => {
  switch (type) {
    case 'create_update': {
      const sharedWithArray = Object.keys(obj.dashData.sharedWith);
      const sharedWithJSON = sharedWithArray.reduce((acc, cur) => {
        acc[cur] = false;
        return acc;
      }, {});
      transaction = populate(sharedWithArray, obj.userDoc.company, transaction);

      const txt = {
        new: {
          message: `O KPI ${obj.KpiObject.name} foi adicionado ao dashboard ${obj.dashData.name}!`,
          action: 'create',
        },
        update: {
          message: ` O KPI ${obj.KpiObject.name} foi atualizado!`,
          action: 'update',
        },
        get(key) {
          return this[key] || this.update;
        },
      };

      const pushJSON = {
        timestamp: firebase.serverTimestamp(),
        sharedWith: sharedWithArray,
        viewed: sharedWithJSON,
        targetId: obj.kpiId,
        targetName: obj.KpiObject.name,
        ownerId: obj.dashData.owner,
        type: 'kpi',
        dashId: obj.dashId,

        ...txt.get(obj.kpiKey),
      };

      const docRef = getRefNotifications(obj.userDoc.company).doc();
      transaction.set(docRef, pushJSON);

      return transaction;
    }
    case 'kpi_delete': {
      const sharedWithArray = Object.keys(obj.dashdoc.sharedWith);
      const sharedWithJSON = sharedWithArray.reduce((acc, cur) => {
        acc[cur] = false;
        return acc;
      }, {});
      transaction = populate(sharedWithArray, obj.userDoc.company, transaction);

      const pushJSON = {
        timestamp: firebase.serverTimestamp(),
        sharedWith: sharedWithArray,
        viewed: sharedWithJSON,
        targetId: obj.cardId,
        targetName: obj.cardData.name,
        ownerId: obj.dashdoc.owner,
        type: 'kpi',
        message: ` O KPI ${obj.cardData.name} foi removido do dashboard ${obj.dashdoc.name}!`,
        action: 'delete',
        dashId: obj.dashId,
      };

      const docRef = getRefNotifications(obj.userDoc.company).doc();
      transaction.set(docRef, pushJSON);
      return transaction;
    }
    case 'editDash': {
      transaction = populate(obj.sharedIds, obj.userDoc.company, transaction);

      const sharedWithJSON = obj.sharedIds.reduce((acc, cur) => {
        acc[cur] = false;
        return acc;
      }, {});

      const pushJSON = {
        timestamp: firebase.serverTimestamp(),
        sharedWith: obj.sharedIds,
        viewed: sharedWithJSON,
        targetId: obj.dashId,
        targetName: obj.editInfo.name,
        ownerId: obj.editInfo.owner,
        type: 'dash',
        message: ` O dashboard ${obj.editInfo.name} foi atualizado!`,
        action: 'update',

      };
      const docRef = getRefNotifications(obj.userDoc.company).doc();
      transaction.set(docRef, pushJSON);
      return transaction;
    }
    case 'comment': {
      transaction = populate(obj.comment.mentions.users, obj.userDoc.company, transaction);

      const sharedWithJSON = obj.comment.mentions.users.reduce((acc, cur) => {
        acc[cur] = false;
        return acc;
      }, {});

      const pushJSON = {
        timestamp: firebase.serverTimestamp(),
        sharedWith: obj.comment.mentions.users,
        viewed: sharedWithJSON,
        targetId: obj.dashDoc.id,
        targetName: obj.dashDoc.name,
        ownerId: obj.dashDoc.owner,
        type: 'mural',
        message: `Você foi citado em uma mensagem pelo usuário ${obj.comment.author} no dashboard ${obj.dashDoc.name}!`,
        action: 'mention',
      };

      const docRef = getRefNotifications(obj.userDoc.company).doc();
      transaction.set(docRef, pushJSON);

      return transaction;
    }

    case 'shareDash': {
      const sharedWithJSON = obj.users.reduce((acc, cur) => {
        acc[cur] = false;
        return acc;
      }, {});

      const depara = obj.usersList.reduce((acc, cur) => {
        acc[cur.id] = cur;
        return acc;
      }, {});

      transaction = populate(obj.users, obj.userDoc.company, transaction);

      const txt = {
        add: {
          message: `O usuário ${depara[obj.users[0]]?.name} foi adicionado ao dashboard ${obj.dashboard.name}!`,
          plural: `Os usuários ${obj.users.map((id) => depara[id]?.name).join(', ')} foram adicionados ao dashboard ${obj.dashboard?.name}!`,
        },
        remove: {
          message: `O usuário ${depara[obj.users[0]]?.name} foi removido do dashboard ${obj.dashboard.name}!`,
          plural: `Os usuários ${obj.users.map((id) => depara[id]?.name).join(', ')} foram removidos do dashboard ${obj?.dashboard?.name}!`,
        },
        get(key, length) {
          if (length > 1) {
            return this[key].plural;
          }
          return this[key].message;
        },
      };

      const pushJSON = {
        timestamp: firebase.serverTimestamp(),
        sharedWith: obj.users,
        viewed: sharedWithJSON,
        targetId: obj.dashboard.id,
        targetName: obj.dashboard.name,
        ownerId: obj.dashboard.owner,
        type: 'dash',
        message: txt.get(obj.key, obj.users.length),
        action: 'share',
      };

      const docRef = getRefNotifications(obj.userDoc.company).doc();
      transaction.set(docRef, pushJSON);
      return transaction;
    }

    default:
      console.log('erro ao criar notificacao');
      return transaction;
  }
};

export default notificationTriggers;

//   const pushJSON = {
//     timestamp: firebase.serverTimestamp(),
//     sharedWith: ,
//     viewed: ,
//     targetId: ,
//     targetName: ,
//     ownerId: ,
//     type: '',
//     message: ``,
//     action: ``,
// }
