import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  .grecaptcha-badge {
    visibility: hidden !important;
  }
  html, body {
    margin: 0;
    padding: 0;
  }
  body {
    overflow: hidden;
    display: flex;
    background: ${({ theme }) => theme.background};
    color: ${({ theme }) => theme.color};
    height: 100vh;
    text-rendering: optimizeLegibility;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;
    /* font-family: 'open sans'; */
    @media print {
      @page {
        size: A4;
        margin: 1rem;
      }
      overflow: visible !important;
      background-color: transparent !important;
    }
    @-moz-document url-prefix() {
      scrollbar-color: ${({ theme }) => `${theme.scrollbar} ${theme.scrollbarTrack}`};
      div, main, .emoji-scroll-wrapper {
        scrollbar-width: thin !important;
      }
    }
  }

  #root {
    width: 100%;
  }

  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.scrollbarTrack};
    border-left: 3px solid ${({ theme }) => theme.background};
    border-right: 3px solid ${({ theme }) => theme.background};
  }

  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.scrollbar};
    border-radius: 8px;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
  }

`;

export default GlobalStyles;
