import React from 'react';
import PropTypes from 'prop-types';

import { StyledInputRange } from './styled/RangeBar.styled';

function RangeBar({
  onChange, min, max, step, value, ...props
}) {
  const handleInputChange = (e) => {
    const { value: changeValue } = e.target;

    if (changeValue >= min && changeValue <= max) {
      onChange(+changeValue);
    }
  };

  return (
    <StyledInputRange
      {...props}
      value={value}
      onChange={handleInputChange}
      min={min}
      max={max}
      step={step}
    />
  );
}

RangeBar.propTypes = {
  onChange: PropTypes.func,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

RangeBar.defaultProps = {
  onChange: () => {},
  value: 0,
  min: 0,
  max: 0,
  step: 0,
};

export default RangeBar;
