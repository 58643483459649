import React from 'react';

function RemoveImage() {
    return (
        <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M30.9375 3.52069L29.4793 2.0625L2.0625 29.4793L3.52069 30.9375L5.58319 28.875H26.8125C27.3593 28.8742 27.8834 28.6566 28.27 28.27C28.6566 27.8834 28.8742 27.3593 28.875 26.8125V5.58319L30.9375 3.52069ZM26.8125 26.8125H7.64569L15.6822 18.776L18.1356 21.2293C18.5223 21.616 19.0468 21.8332 19.5938 21.8332C20.1407 21.8332 20.6652 21.616 21.0519 21.2293L22.6875 19.5938L26.8125 23.7157V26.8125ZM26.8125 20.7982L24.1457 18.1314C23.7589 17.7448 23.2344 17.5276 22.6875 17.5276C22.1406 17.5276 21.6161 17.7448 21.2293 18.1314L19.5938 19.767L17.1425 17.3157L26.8125 7.64569V20.7982Z" />
            <path d="M6.1875 22.6875V19.5938L11.3438 14.4406L12.7597 15.8575L14.2199 14.3973L12.8019 12.9793C12.4152 12.5927 11.8907 12.3754 11.3438 12.3754C10.7968 12.3754 10.2723 12.5927 9.88556 12.9793L6.1875 16.6774V6.1875H22.6875V4.125H6.1875C5.64066 4.12555 5.11637 4.34302 4.7297 4.7297C4.34302 5.11637 4.12555 5.64066 4.125 6.1875V22.6875H6.1875Z" />
        </svg>
    );
}
export default RemoveImage;
