import React from 'react';
import PropTypes from 'prop-types';

import { useTheme } from 'styled-components';

import { StyledDrawer, BackgroundFade } from './styled/Drawer.styled';
import IconButton from '../IconButton';
import { Close } from '../../icons';

/**
 * A drawer provides access to destinations and app functionality.
 * It can either be permanently on-screen or controlled by an icon
 */
const Drawer = ({
  children, direction, open, customHeight, customWidth, handleCloseDrawer, ...props
}) => {

  const theme = useTheme();

  return (
    <>
      <BackgroundFade open={open} onClick={handleCloseDrawer} />
      <StyledDrawer
        {...props}
        direction={direction}
        open={open}
        customHeight={customHeight}
        customWidth={customWidth}
      >
        <IconButton
          onClick={handleCloseDrawer}
          color={theme.grey}
          variant="pattern"
          size="medium"
          className="closeBtn"
        >
          <Close aria-hidden="true" type="button" data-dismiss="modal" aria-label="Close" />
        </IconButton>
        {children}
      </StyledDrawer>
    </>
  );
};

Drawer.propTypes = {
  /**
  * The children is the node that will be available inside the drawer
  */
  children: PropTypes.node.isRequired,
  /**
  * The direction is where the drawer is placed.
  * You can create a top, bottom, left or right drawer.
  */
  direction: PropTypes.oneOf(['left', 'right', 'top', 'bottom']),
  /**
  * A boolean variable to determine whether the drawer must be shown or not
  */
  open: PropTypes.bool,
  /**
  * The height of the drawer.
  * If empty, the app will consider default values for each chosen direction.
  */
  customHeight: PropTypes.string,
  /**
  * The width of the drawer.
  * If empty, the app will consider default values for each chosen direction.
  */
  customWidth: PropTypes.string,
  /**
  * A function responsible to close de drawer
  */
  handleCloseDrawer: PropTypes.func.isRequired,
};

Drawer.defaultProps = {
  direction: 'left',
  open: false,
  customHeight: undefined,
  customWidth: undefined,
};

export default Drawer;
