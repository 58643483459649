import styled, { css } from 'styled-components';
import { devices } from '../../../../juristec-ui/core/breakpoints';

const types = (type, theme) => {
  switch (type) {
    case 'abc':
    case 'category':
      return theme.info;
    case 'date':
    case 'datetime64[ns]':
      return theme.warning;
    case 'float':
    case 'float64':
      return theme.success;
    default:
      return '';
  }
};

export const MainContainer = styled.div`
  padding: 0 1rem;
  max-width: 1000px;
  overflow-x: hidden;
  overflow-y: hidden;
  user-select: none;
`;

export const GridContainer = styled.div`
  display: grid;
  position: relative;
  grid-template-columns: 1fr minmax(0, 35vw);
  grid-gap: .5rem;
  height: calc(100vh - 380px);
`;

const Side = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  & > * + * {
    margin-top: .4rem;
  }
`;

export const LeftSide = styled(Side)`
  width: 200px;
  & span {
    font-size: 14px;
    &.obs {
      font-size: 12px;
    }
    display: flex;
    flex-direction: column;
    & b {
      color: ${({ theme }) => theme.error};
    }
  }
  & button {
    margin-top: auto;
    width: 100%;
  }
`;

export const RightSide = styled(Side)`
  padding: .5rem;
  align-items: flex-start;
  align-content: flex-start;
  /* background-color: ${({ theme }) => theme.card}; */
  overflow-y: auto;
  & > div {
    width: 100%;
    cursor: default;
  }
  & > * {
    margin-bottom: 10px;
  }
`;

export const ColumnsList = styled.div`
  display: grid;
  position: relative;
  grid-template-columns: min-content minmax(0, 30vw) min-content;
  @media ${devices.phoneS} {
    grid-template-columns: min-content auto 130px;
  }
  grid-gap: .4rem 0;
  max-height: calc(100vh - 380px);
  overflow: auto;
  text-align: center;
  & h4 {
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space-collapse: preserve-spaces;
    & > i {
      font-size: 9px;
      color: ${({ theme }) => theme.gray};
    }
  }
`;

export const ItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: .4rem .2rem;
  justify-content: center;
  & > div {
    width: 100%;
  }
  &:first-of-type {
    padding-left: .4rem;
  }
  &:last-of-type {
    padding-right: .4rem;
  }
  ${({ theme, varType }) => css`
    background-color: ${theme.card};
    & > svg {
      fill: ${types(varType, theme)};
      height: 30px;
      width: 30px;
    }
  `};
`;

export const ItemOptions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const EmptyMsg = styled.span`
  text-align: center;
  grid-column: 1 / span 3;
  width: 100%;
  color: ${({ theme }) => theme.grey};
`;

export const FormulaWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  white-space-collapse: preserve-spaces;
  width: 100%;
  margin-top: .2rem;
  /* padding: .2rem 0; */
  font-size: 12px;
  ${({ theme }) => css`
    color: ${theme.color};
    background-color: ${theme.modal};
    & > * {
      margin: .2rem 0;
      box-sizing: border-box;
    }
    & b {
      border: 1px solid;
      font-weight: normal;
      padding: 0 .1rem;
      margin: .2rem .1rem;
    }
    & i {
      border-style: solid;
      border-width: 0 0 1px 0;
      font-style: normal;
      padding: 0 .1rem;
    }
    & sub {
      color: ${theme.grey};
      padding: 0 .1rem;
    }
    & em, .date {
      color: ${theme.warning};
      font-style: normal;
    }
    & .abc {
      color: ${theme.info};
    }
    & .float {
      color: ${theme.success};
    }
  `}
`;
