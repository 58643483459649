import React from 'react';
import PropTypes from 'prop-types';

const Omie = ({ height, width }) => (
  <svg width={width} height={height} viewBox="0 0 30 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 14.2682C0 22.9495 6.67582 28.7737 15 28.7737C23.3516 28.7737 30 22.977 30 14.2682C30 10.0649 28.489 6.30115 26.1813 3.93851C19.2033 -3.17688 13.0769 0.834113 11.5659 4.40554C11.2637 5.11983 11.4286 5.72422 12.3352 6.19126L24.5604 13.8286C25.1099 14.1308 25.1099 14.9 24.5604 15.2022L9.72527 22.4825C9.2033 22.7572 8.5989 22.3726 8.5989 21.7957C8.5989 21.7957 8.5989 11.933 8.5989 9.07587C8.5989 4.29565 9.14835 2.56488 9.56044 1.41104C9.86264 0.531915 9.42308 0.25719 8.79121 0.559388C7.69231 1.10884 0 6.19126 0 14.2682Z" fill="#00E2F4" />
  </svg>
);

Omie.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
};

Omie.defaultProps = {
  height: '25px',
  width: '25px',
};

export default Omie;
