import styled, { css } from 'styled-components';

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const ContentRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  & > * {
    margin-right: 10px;
    &:last-child {
      margin-right: 0;
    }
  }
`;

export const CenteredItens = styled.div`
  display: grid;
  /* grid-template-columns: repeat(auto-fill, 1fr); */
  grid-auto-flow: column;
  grid-auto-columns: auto;
  grid-gap: 10px;
  width: 100%;
  align-items: center;
  justify-content: center;
  & > div {
    overflow: hidden;
    & > div {
      width: 100%;
    }
  }
`;

export const FilterBadge = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 2px solid;
  padding: .2rem;
  border-radius: .5rem;
  cursor: pointer;
  width: 100%;
  white-space: nowrap;
  ${({ theme, isActive }) => css`
    border-color: ${isActive ? theme.secondary : theme.primary};
    color: ${isActive ? theme.secondary : theme.primary}; 
    background-color: ${isActive ? theme.secondary : theme.primary}20;
    & svg {
      fill: ${isActive ? theme.secondary : theme.primary}; 
    }
  `};
  & svg {
    width: 25px;
    height: 25px;
  }
  & > span {
    font-size: 16px;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
  }
`;

export const IconFlipWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  transition: all 0.2s;
  ${({ flip }) => flip && css`
    transform: rotate(180deg);
  `}
`;

export const InfoTooltip = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;
