import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '../../../../juristec-ui/core/Checkbox';

import {
  MainContainer,
  HeaderContainer,
  TextContainer,
  CheckboxInputWrapper,
} from './styled/Policy.styled';

import ConsentTermsLM from './ConsentTermsLM';
import ConsentTermsTR from './ConsentTermsTR';
import ConsentTermsBenner from './ConsentTermsBenner';

import { formatDateTime } from '../../../../juristec-ui/utils/functions/lab';

const switchConsentTerms = () => {
  switch (process.env.REACT_APP_FIREBASE_PROJECT_LABEL) {
    case 'legalone-analytics':
      return <ConsentTermsTR />;

    case 'benner-metrics':
      return <ConsentTermsBenner />;

    default:
      return <ConsentTermsLM />;
  }
};

const SSTermsContainer = ({
  version, date, checkState, setCheckState, readOnly,
}) => {
  return (
    <MainContainer>
      {readOnly || (
        <HeaderContainer>
          <h3>Termo de Consentimento</h3>
          <span>
            {`${version}: ${formatDateTime(date)}`}
          </span>
        </HeaderContainer>
      )}
      <TextContainer tabIndex="0">
        {switchConsentTerms()}
      </TextContainer>
      {readOnly || (
        <CheckboxInputWrapper>
          <Checkbox
            text="Eu concordo com o Termo de Consentimento"
            color="secondary"
            variant="outlined"
            checked={checkState}
            handleCheckboxChange={(check) => setCheckState(check)}
          />
        </CheckboxInputWrapper>
      )}
    </MainContainer>
  );
};

SSTermsContainer.propTypes = {
  /**
   * The version of the policy
   */
  version: PropTypes.string,
  /**
   * The date of the policy
   */
  date: PropTypes.shape({
    nanoseconds: PropTypes.number,
    seconds: PropTypes.number,
  }),
  /**
   * A boolean that represents the state of the checkbox
   */
  checkState: PropTypes.bool,
  /**
   * A function to handle the checkbox check
   */
  setCheckState: PropTypes.func,
  /**
   * A boolean that determines whether or not is show the checkbox
   */
  readOnly: PropTypes.bool,
};

SSTermsContainer.defaultProps = {
  version: '',
  date: {
    nanoseconds: 0,
    seconds: 0,
  },
  checkState: false,
  setCheckState: () => {},
  readOnly: false,
};

export default SSTermsContainer;
