/* eslint-disable no-param-reassign */
import React, {
  useEffect, useState, useContext,
} from 'react';
import PropTypes from 'prop-types';
import StepProgress from '../../../juristec-ui/core/StepProgress';
import UnsplashPicker from '../../../juristec-ui/core/UnsplashPicker';

import FirstStep from './Steps/FirstStep';
import SecondStep from './Steps/SecondStep';
import ThirdStep from './Steps/ThirdStep';

import { ModalContext } from '../../../context/ModalProvider';

const CreateTemplate = ({
  submitData, hide, templateVars, editTemplateData, checkImage, categories,
}) => {
  const [fileImage, setFileImage] = useState(null);
  const [templateName, setTemplateName] = useState({ value: '', error: true, errorMsg: '' });
  const [description, setDescription] = useState({ value: '', error: true, errorMsg: '' });
  const [tutorialLink, setTutorialLink] = useState({ value: '', error: false, errorMsg: '' });
  const [category, setCategory] = useState({ label: '', value: '' });
  const [difficulty, setDifficulty] = useState('easy');
  const [keywords, setKeywords] = useState({ value: '', error: false, errorMsg: '' });
  const [varsData, setVarsData] = useState(templateVars);
  const [categoryOpts, setCategoryOpts] = useState(categories);

  const { setModalConfig, toggleModal } = useContext(ModalContext);

  const handleSubmit = () => {
    submitData({
      name: templateName.value,
      description: description.value,
      tutorialLink: tutorialLink.value,
      category: category.value,
      difficulty,
      keywords: keywords.value?.split('; '),
      imageFile: fileImage,
      variables: Object.keys(varsData).reduce((vars, variable) => {
        const { error, errorMsg, ...data } = varsData[variable];
        vars[variable] = data;
        return vars;
      }, {}),
    });
  };

  const openUnsplashPicker = () => {
    setModalConfig({
      title: 'Selecionar Fotos',
      children: (
        <UnsplashPicker
          closeModal={() => toggleModal('unsplash-picker-modal')}
          selectImg={setFileImage}
          defaultSearch="Templates"
        />
      ),
      nodeTarget: 'unsplash-picker-modal',
    });
  };

  useEffect(() => {
    if (editTemplateData) {
      setTemplateName({ value: editTemplateData.name, error: false, errorMsg: '' });
      setCategory({ label: editTemplateData.category, value: editTemplateData.category });
      setDifficulty(editTemplateData.difficulty);
      setDescription({ value: editTemplateData.description, error: false, errorMsg: '' });
      setTutorialLink({ value: editTemplateData.tutorialLink || '', error: false, errorMsg: '' });
      setKeywords({ value: editTemplateData.keywords?.join('; ') || '', error: false, errorMsg: '' });
      setFileImage(editTemplateData.image || null);
    }
  }, [editTemplateData]);

  const validateFirstPage = () => !(
    templateName.error || description.error || !(category.value?.length > 0)
  );

  const firstStep = ({
    label: 'Obrigatório',
    validation: validateFirstPage,
    content: (
      <FirstStep
        templateNameState={[templateName, setTemplateName]}
        categoryOptsState={[categoryOpts, setCategoryOpts]}
        categoryState={[category, setCategory]}
        difficultyState={[difficulty, setDifficulty]}
        descriptionState={[description, setDescription]}
      />
    ),
  });

  const validateSecondPage = () => !(
    keywords.error || tutorialLink.error
  );

  const secondStep = ({
    label: 'Opcional',
    validation: validateSecondPage,
    content: (
      <SecondStep
        tutorialLinkState={[tutorialLink, setTutorialLink]}
        keywordsState={[keywords, setKeywords]}
        checkImage={checkImage}
        fileImageState={[fileImage, setFileImage]}
        openUnsplashPicker={openUnsplashPicker}
      />
    ),
  });

  const validateThirdPage = () => !(
    Object.values(varsData).filter((variable) => (
      variable.error || variable.description?.length === 0
    )).length > 0
  );

  const thirdStep = ({
    label: 'Variáveis',
    validation: validateThirdPage,
    content: (
      <ThirdStep
        varsDataState={[varsData, setVarsData]}
      />
    ),
  });

  return (
    <StepProgress
      steps={[firstStep, secondStep, thirdStep]}
      hide={hide}
      complete={handleSubmit}
      markersWidth="medium"
      disableUntilValid
    />
  );
};

CreateTemplate.propTypes = {
  /**
  * A function that sends the new data back to the parent
  */
  submitData: PropTypes.func.isRequired,
  /**
  * Object with objects representing the variables of the dashboard
  */
  templateVars: PropTypes.objectOf(
    PropTypes.shape({}),
  ),
  /**
  * Array with the category options
  */
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ),
  /**
  * Object with the template date to edit
  */
  editTemplateData: PropTypes.shape({
    name: PropTypes.string,
    category: PropTypes.string,
    difficulty: PropTypes.string,
    description: PropTypes.string,
    tutorialLink: PropTypes.string,
    keywords: PropTypes.arrayOf(PropTypes.string),
    image: PropTypes.string,
  }),
  /**
  * A function that closes the modal
  */
  hide: PropTypes.func.isRequired,
  /**
  *  Function to check if an image satisfies the desired conditions
  */
  checkImage: PropTypes.func,
};

CreateTemplate.defaultProps = {
  templateVars: {},
  categories: [],
  editTemplateData: null,
  checkImage: () => {},
};

export default CreateTemplate;
