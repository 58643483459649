import React from 'react';

function RemoveFile() {
  return (
    <svg width="34" height="30" viewBox="0 0 34 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 3.75C0 1.68164 1.68164 0 3.75 0H13.125V7.5C13.125 8.53711 13.9629 9.375 15 9.375H22.5V11.6367C18.1699 12.8613 15 16.8398 15 21.5625C15 25.0254 16.7051 28.084 19.3184 29.959C19.1309 29.9883 18.9434 30 18.75 30H3.75C1.68164 30 0 28.3184 0 26.25V3.75ZM22.5 7.5H15V0L22.5 7.5ZM25.3125 13.125C27.5503 13.125 29.6964 14.0139 31.2787 15.5963C32.8611 17.1786 33.75 19.3247 33.75 21.5625C33.75 23.8003 32.8611 25.9464 31.2787 27.5287C29.6964 29.1111 27.5503 30 25.3125 30C23.0747 30 20.9286 29.1111 19.3463 27.5287C17.7639 25.9464 16.875 23.8003 16.875 21.5625C16.875 19.3247 17.7639 17.1786 19.3463 15.5963C20.9286 14.0139 23.0747 13.125 25.3125 13.125ZM28.7871 19.4121C29.1504 19.0488 29.1504 18.4512 28.7871 18.0879C28.4238 17.7246 27.8262 17.7246 27.4629 18.0879L25.3125 20.2383L23.1621 18.0879C22.7988 17.7246 22.2012 17.7246 21.8379 18.0879C21.4746 18.4512 21.4746 19.0488 21.8379 19.4121L23.9883 21.5625L21.8379 23.7129C21.4746 24.0762 21.4746 24.6738 21.8379 25.0371C22.2012 25.4004 22.7988 25.4004 23.1621 25.0371L25.3125 22.8867L27.4629 25.0371C27.8262 25.4004 28.4238 25.4004 28.7871 25.0371C29.1504 24.6738 29.1504 24.0762 28.7871 23.7129L26.6367 21.5625L28.7871 19.4121Z" />
    </svg>
  );
}

export default RemoveFile;
