import React, {
  useState, useEffect, useRef, useContext,
} from 'react';

import { useHistory, Link } from 'react-router-dom';

import { useTheme } from 'styled-components';
import useRecaptchaV3 from '../../hooks/useRecaptchaV3';

import {
  Paper,
  Container,
  RightSide,
  FormColumn,
  Logo,
  FormRow,
  TextOptions,
  SideImage,
} from './styled/ResetPasswordPage.styled';

import img from '../../assets/password.png';

import Button from '../../juristec-ui/core/Button';
import InputTextLine from '../../juristec-ui/core/InputTextLine';
import Loader from '../../juristec-ui/core/Loader';
// UTILS
import detectMobile from '../../utils/detectMobile';
import { verifyEmail } from '../../juristec-ui/utils/validators/inputTextValidators';
import { compactString } from '../../juristec-ui/utils/functions/formatString';

import { AlertContext } from '../../context/AlertProvider';
import { UsersContext } from '../../context/UsersProvider';

const ResetPasswordPage = () => {
  const history = useHistory();
  const inputRef = useRef();
  const isMobile = useRef(false);
  const theme = useTheme();
  const recaptcha = useRecaptchaV3();

  const { setAlertConfig } = useContext(AlertContext);
  const { isLoading, usersAPI } = useContext(UsersContext);

  const [email, setEmail] = useState({ val: '', error: true, errorMsg: '' });
  const [btnWaiting, setBtnWaiting] = useState(false);

  const focusIfNotMobile = (ref) => {
    if (!isMobile.current) ref.focus();
  };

  const handleEmail = (val) => {
    const msg = verifyEmail(val, true);
    setEmail({
      val,
      error: msg.length !== 0,
      errorMsg: msg,
    });
  };

  const handleResetPasswordRequest = async (e) => {
    try {
      e.preventDefault();
      const errorMsg = verifyEmail(email.val, true);
      if (errorMsg.length !== 0) {
        setAlertConfig({
          type: 'error',
          text: errorMsg,
          child: '',
          okFunction: () => focusIfNotMobile(inputRef.current),
        });
        return;
      }
      setBtnWaiting(true);

      const gToken = await recaptcha.getToken('reset_password');

      const {
        error,
        msg,
        raw,
        status,
      } = await usersAPI.resetPassword(email.val.trim(), gToken);
      if (error && status === 500) {
        setAlertConfig({
          type: 'error',
          text: msg,
          child: raw,
          okFunction: () => focusIfNotMobile(inputRef.current),
        });
        setBtnWaiting(false);
        return;
      }

      setAlertConfig({
        type: 'success',
        text: 'Verifique o seu e-mail!',
        child: 'Caso sua credencial seja válida no sistema, você receberá um e-mail para recadastramento da senha! Caso contrário, entre em contato com o suporte!',
      });

      setEmail({ val: '', error: true, errorMsg: '' });
      setBtnWaiting(false);
      history.push('/login');
    } catch (error) {
      // console.log(error);
      const errorText = 'Algum erro aconteceu. Tente novamente mais tarde ou entre em contato com o administrador do sistema';
      setAlertConfig({
        type: 'error',
        text: errorText,
        child: '',
        okFunction: () => focusIfNotMobile(inputRef.current),
      });
      setBtnWaiting(false);
    }
  };

  useEffect(() => {
    if (detectMobile()) isMobile.current = true;
    if (!isMobile.current) inputRef.current.focus();
  }, []);

  return (
    <Container>
      {(isLoading || !recaptcha.ready) && <Loader />}
      <Paper onSubmit={handleResetPasswordRequest}>
        <FormColumn>
          <div className="gap-fix">
            <Logo alt="logo" src={theme.loginLogo} />
            <span style={{
              color: theme.color, fontSize: '14px', fontWeight: 'normal', paddingBottom: '5px',
            }}
            >
              Digite seu email para receber um link de recuperação de senha
            </span>
            <FormRow>
              <InputTextLine
                ref={inputRef}
                label="E-mail*"
                value={email.value}
                errorMessage={email.errorMsg}
                error={email.errorMsg.length > 0}
                type="email"
                onChange={(e) => handleEmail(compactString(e.target.value))}
                // required
              />
            </FormRow>
            <FormRow>
              <Button
                isLoading={btnWaiting}
                type="submit"
                size="large"
                style={{ width: '100%', marginTop: '.5rem' }}
              >
                enviar link
              </Button>
            </FormRow>
          </div>
          <div>
            <TextOptions>
              Voltar para a página de
              {' '}
              <Link
                to="/login"
              >
                Login
              </Link>
            </TextOptions>
          </div>
          <div style={{ marginTop: 'auto' }}>
            <TextOptions>
              Este site é protegido pelo reCAPTCHA e a
              {' '}
              <a
                href="https://policies.google.com/privacy"
                target="_blank"
                rel="noreferrer"
              >
                Política de Privacidade
              </a>
              {' '}
              e os
              {' '}
              <a
                href="https://policies.google.com/terms"
                target="_blank"
                rel="noreferrer"
              >
                Termos de Serviço
              </a>
              {' '}
              do Google se aplicam
            </TextOptions>
          </div>
        </FormColumn>
      </Paper>
      <RightSide>
        <SideImage alt="professionals_charts" src={img} />
      </RightSide>
    </Container>
  );
};

export default ResetPasswordPage;
