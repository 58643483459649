/* eslint-disable no-param-reassign */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Button from '../../juristec-ui/core/Button';
import IconButton from '../../juristec-ui/core/IconButton';
import Tooltip from '../../juristec-ui/core/Tooltip';
import AdminOptions from './AdminOptions';

import {
  MainContainer,
  GridOverFlow,
  GridCards,
  TemplateCard,
  CardImage,
  CardContent,
  ColumnsContainer,
  TagsContainer,
  LineRow,
  DifficultLabel,
  CardImageContainer,
  CardImageBack,
  BtnIconWrapper,
} from './styled/GridTemplates.styled';

import {
  Info, Image, Play, Number, Letters, DateIcon,
} from '../../juristec-ui/icons';

const difficultyMap = {
  easy: 'Iniciante',
  medium: 'Intermediário',
  advanced: 'Avançado',
  expert: 'Expert',
  get(k) {
    return this[k] || this.easy;
  },
};

const labelTypes = {
  abc: 'Texto',
  float: 'Número',
  date: 'Data',
};

const labelIcons = {
  float: <Number />,
  abc: <Letters />,
  date: <DateIcon />,
};

const GridTemplates = ({
  templates, applyTemplate, showPreview, editTemplate, deleteTemplate,
}) => {
  const [imgRotate, setImgRotate] = useState({});

  const toggleRotate = (key) => {
    setImgRotate((r) => ({
      ...r,
      [key]: !r[key],
    }));
  };

  const openInNewTab = (url) => {
    url = url.startsWith('https://') ? url : `https://${url}`;
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  return (
    <MainContainer className="templates_list">
      <GridOverFlow>
        <GridCards>
          {templates.map((t) => (
            <TemplateCard key={t.id}>
              {editTemplate && deleteTemplate && (
                <div style={{ position: 'absolute', right: '0' }}>
                  <AdminOptions
                    editTemplate={() => editTemplate(t)}
                    deleteTemplate={() => deleteTemplate(t)}
                  />
                </div>
              )}
              <h2>{t.name}</h2>
              <div className="content">
                <CardImageContainer setRotate={imgRotate[t.id]}>
                  <Tooltip
                    text={imgRotate[t.id] ? 'Mostrar image' : 'Mais informações'}
                    className="card-tooltip"
                  >
                    <IconButton
                      variant="contained"
                      color="secondary"
                      onClick={() => toggleRotate(t.id)}
                    >
                      {imgRotate[t.id] ? <Image /> : <Info />}
                    </IconButton>
                  </Tooltip>
                  <div className="inner">
                    <CardImage style={t.image ? { backgroundImage: `url(${t.image})` } : {}} />
                    <CardImageBack>
                      <h3>Informações necessárias</h3>
                      {Object.keys(labelTypes).map((l) => {
                        const size = t.columnsType[l]?.length || 0;
                        if (size <= 0) return null;
                        return (
                          <React.Fragment key={t.id + l}>
                            <div className="subtitle">
                              <div>
                                {labelIcons[l]}
                              </div>
                              <h4>
                                {`${size} coluna${size > 1 ? 's' : ''} de ${labelTypes[l].toUpperCase()}:`}
                              </h4>
                            </div>
                            <ColumnsContainer>
                              <span>{t.columnsType[l].join(', ')}</span>
                            </ColumnsContainer>
                          </React.Fragment>
                        );
                      })}
                    </CardImageBack>
                  </div>
                </CardImageContainer>
                <CardContent>
                  <LineRow>
                    <LineRow>
                      <h3>{t.category || 'Legado'}</h3>
                    </LineRow>
                    <LineRow style={{ marginLeft: 'auto' }}>
                      <DifficultLabel colorKey={t.difficulty}>
                        {difficultyMap.get(t.difficulty)}
                      </DifficultLabel>
                    </LineRow>
                  </LineRow>
                  <span style={{ marginBottom: '.4rem', whiteSpace: 'pre-wrap' }}>{t.description}</span>
                  {t.tutorialLink?.length > 0 && (
                    <LineRow style={{ justifyContent: 'flex-end' }}>
                      <Button
                        variant="pattern"
                        size="small"
                        shape="rounded"
                        color="info"
                        onClick={() => openInNewTab(t.tutorialLink)}
                        style={{ gap: '5px', minWidth: 'auto' }}
                      >
                        <BtnIconWrapper>
                          <Play />
                        </BtnIconWrapper>
                        Veja como fazer
                      </Button>
                    </LineRow>
                  )}
                  <LineRow style={{ margin: 'auto 0 0 auto' }}>
                    <Button variant="outlined" onClick={() => showPreview(t)}>
                      Visualizar prévia
                    </Button>
                    <Button onClick={() => applyTemplate(t)} className="template_apply_button">
                      Aplicar este template
                    </Button>
                  </LineRow>
                  {t.keywords?.length > 0 && (
                    <TagsContainer>
                      <span>{t.keywords.join(', ')}</span>
                    </TagsContainer>
                  )}
                </CardContent>
              </div>
            </TemplateCard>
          ))}
        </GridCards>
      </GridOverFlow>
    </MainContainer>
  );
};

GridTemplates.propTypes = {
  templates: PropTypes.arrayOf(PropTypes.shape({})),
  applyTemplate: PropTypes.func.isRequired,
  showPreview: PropTypes.func.isRequired,
  editTemplate: PropTypes.func,
  deleteTemplate: PropTypes.func,
};

GridTemplates.defaultProps = {
  templates: [],
  editTemplate: null,
  deleteTemplate: null,
};

export default GridTemplates;
