import React from 'react';
import PropTypes from 'prop-types';
import StyledActionsGroup from './styled/ActionsGroup.styled';

// import { Container } from './styles';
/**
* ActionsGroup is a div used to encapsulate action elements 
*/
function ActionsGroup({ children, ...props }) {
  return (
    <StyledActionsGroup {...props}>
      {children}
    </StyledActionsGroup>
  );
}

ActionsGroup.propTypes = {
  /**
  * The children element accepts a text, a node, a function or any object inside it
  */
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.func,
    PropTypes.object,
  ]),
};

ActionsGroup.defaultProps = {
  children: '',
};

export default ActionsGroup;
