const formatValue = (value, type) => {
  switch (type) {
    case 'number':
    case 'int64':
    case 'float64':
    case 'float':
      return Number(value).toLocaleString('pt-br', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      });
    case 'datetime64[ns]':
    case 'date': {
      try {
        if (['nat', 'NaT', 'nan', 'NaN', ''].includes(value)) return 'NaT';
        const dateTime = value.split(' ');
        if (dateTime.length > 1) {
          return `${dateTime[0].split('-').reverse().join('/')} ${dateTime[1]}`;
        }
        return dateTime[0].split('-').reverse().join('/');
      } catch (e) {
        return 'NaT';
      }
    }
    default:
      return value;
  }
};

export default formatValue;
