const getMapJson = async (stateControl, fieldIndex='UF') => {
  const json = await import(`../mapData/${stateControl}.json`);
  const features = json.features.map((feature) => ({
    ...feature,
    id: feature.properties[fieldIndex].normalize('NFD').replace(/\p{Diacritic}/gu, '').trim().toLowerCase(),
  }));
  return { ...json, features };
};

export default getMapJson;
