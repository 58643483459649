import styled from 'styled-components';

export const FilterContainer = styled.div`
  padding: 10px;
  font-family: arial;
  min-width: 250px;
  max-width: 250px;
`;

export const ActionGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;
`;

export const ScrollContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 150px;
  overflow: hidden auto;
  margin-bottom: 5px;
`;

export const OrderRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  margin-bottom: 5px;
  /* max-width: 250px; */
  & > span {
    font-size: 16px;
    font-weight: bold;
  }
  &.hover-bgcolor {
    min-height: 32px;
    &:hover {
      background-color: ${({ theme }) => theme.containerHover};
    }
  }
  & > div {
    width: 100%;
    padding: 5px;
    justify-content: flex-start;
  }
`;

export const OrderVar = styled.div`
  max-width: 250px;
  overflow: hidden;
  font-size: 16px;
  height: 20px;
  font-weight: bold;
  display: block;
  position: relative;
  & span {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
  }
`;

export const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  max-width: 400px;
  & span {
    white-space: normal;
    font-size: 12px;
  }
  & span:first-child {
    margin: auto;
    margin-bottom: 5px;
    font-weight: bold;
    font-size: 14px;
  }
`;
