/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';

import ControllersGroup from '../../../controllers/ControllersGroup';
import {
  InputLineController,
  ToggleSwitchController,
  ButtonLineController,
  SelectController,
  RangeController,
} from '../../../controllers/Base';

import fontOptions from '../../../utils/controllersUtils/options';

const AxisControllers = ({
  handle,
  config,
  extend,
  setExtend,
}) => (
  <>
    <ControllersGroup title="Eixos" id="axis" extend={extend} setExtend={setExtend}>
      <ToggleSwitchController
        label="Grid radial"
        handle={handle}
        kWord="EnableGridR"
        configValue={config?.EnableGridR}
        firstOption=""
        secondOption=""
      />
      <ToggleSwitchController
        label="Grid circular"
        handle={handle}
        kWord="EnableGridC"
        configValue={config?.EnableGridC}
        firstOption=""
        secondOption=""
      />
      <ToggleSwitchController
        label="Eixo inicial"
        handle={handle}
        kWord="EnableAxisStart"
        configValue={config?.EnableAxisStart}
        firstOption=""
        secondOption=""
      />
      <ToggleSwitchController
        label="Eixo final"
        handle={handle}
        kWord="EnableAxisEnd"
        configValue={config?.EnableAxisEnd}
        firstOption=""
        secondOption=""
      />
      <RangeController
        label="Marcação do eixo radial"
        handle={handle}
        kWord="AxisRadialTickSize"
        configValue={config?.AxisRadialTickSize}
        min={0}
        max={20}
        fullWidth
        disabled={!config?.EnableAxisStart && !config?.EnableAxisEnd}
      />
      <RangeController
        label="Distância do eixo radial"
        handle={handle}
        kWord="AxisRadialTickPadding"
        configValue={config?.AxisRadialTickPadding}
        min={0}
        max={20}
        fullWidth
        disabled={!config?.EnableAxisStart && !config?.EnableAxisEnd}
      />
      <RangeController
        label="Rotação do eixo radial"
        handle={handle}
        kWord="AxisRadialTickRotation"
        configValue={config?.AxisRadialTickRotation}
        min={-180}
        max={180}
        fullWidth
        disabled={!config?.EnableAxisStart && !config?.EnableAxisEnd}
      />

      <hr style={{ width: '100%', color: 'lightgray', opacity: '0.2' }} />

      <ToggleSwitchController
        label="Eixo externo"
        handle={handle}
        kWord="EnableAxisOuter"
        configValue={config?.EnableAxisOuter}
        firstOption=""
        secondOption=""
      />
      <ToggleSwitchController
        label="Eixo interno"
        handle={handle}
        kWord="EnableAxisInner"
        configValue={config?.EnableAxisInner}
        firstOption=""
        secondOption=""
      />
      <RangeController
        label="Marcação do eixo circular"
        handle={handle}
        kWord="AxisCircularTickSize"
        configValue={config?.AxisCircularTickSize}
        min={0}
        max={20}
        fullWidth
        disabled={!config?.EnableAxisOuter && !config?.EnableAxisInner}
      />
      <RangeController
        label="Distância do eixo circular"
        handle={handle}
        kWord="AxisCircularTickPadding"
        configValue={config?.AxisCircularTickPadding}
        min={0}
        max={20}
        fullWidth
        disabled={!config?.EnableAxisOuter && !config?.EnableAxisInner}
      />
      {/* Componente válido mas ainda não funciona, aguardar o nivo resolver
        <RangeController
          label="Rotação do eixo circular"
          handle={handle}
          kWord="AxisCircularTickRotation"
          configValue={config?.AxisCircularTickRotation}
          min={-180}
          max={180}
          fullWidth
          disabled={!config?.EnableAxisOuter && !config?.EnableAxisInner}
        />
      */}

      <hr style={{ width: '100%', color: 'lightgray', opacity: '0.2' }} />

      <SelectController
        label="Fonte"
        handle={handle}
        kWord="AxisGlobalRadialFontFamily"
        options={fontOptions}
        configValue={config?.AxisGlobalRadialFontFamily}
        fullWidth
        disabled={!config?.EnableAxisStart && !config?.EnableAxisEnd
          && !config?.EnableAxisOuter && !config?.EnableAxisInner}
      />
      <InputLineController
        label="Tamanho"
        handle={handle}
        kWord="AxisGlobalRadialFontSize"
        configValue={config?.AxisGlobalRadialFontSize}
        min={1}
        max={30}
        step={1}
        precision={0}
        isNumber
        disabled={!config?.EnableAxisStart && !config?.EnableAxisEnd
          && !config?.EnableAxisOuter && !config?.EnableAxisInner}
      />
      <ButtonLineController
        handle={handle}
        fontColorVal={config?.AxisGlobalRadialFontColor || '#333333'}
        fontColorKWord="AxisGlobalRadialFontColor"
        disabled={!config?.EnableAxisStart && !config?.EnableAxisEnd
          && !config?.EnableAxisOuter && !config?.EnableAxisInner}
      />
    </ControllersGroup>
  </>
);

AxisControllers.propTypes = {
  config: PropTypes.objectOf(PropTypes.any),
  handle: PropTypes.func,
  extend: PropTypes.string,
  setExtend: PropTypes.func,
};

AxisControllers.defaultProps = {
  config: undefined,
  handle: () => {},
  extend: '',
  setExtend: () => {},
};

export default AxisControllers;
