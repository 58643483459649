import styled, { css } from 'styled-components';
import { devices } from '../../../juristec-ui/core/breakpoints';
import { gap } from '../../../juristec-ui/styles/theme';

export const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 5%;
  @media ${devices.tablet} {
    padding: 0 20px;
  }

  & .report-icon svg {
    width: 18px;
    height: 18px;
  }
`;

export const TableContainer = styled.main`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  min-width: 0;
  & > svg {
    max-height: calc(100vh - 220px);
    margin: auto;
    height: 100%;
    width: 100%;
  }
`;

export const TableOverFlow = styled.div`
  max-height: calc(100vh - 167px);
  overflow-y: auto;
  overflow-x: auto;
`;

export const IconFlipWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  & svg {
    width: 25px;
    height: 25px;
    fill: ${({ theme }) => theme.primary};
  }
  transition: all 0.2s;
  transform: rotate(-90deg);
  ${({ flip }) => flip && css`
    transform: rotate(0deg);
  `}
`;

export const CollapsibleContainer = styled.div`
  overflow: hidden;
  max-height: ${({ control }) => (control ? 'calc(1.4rem * 10)' : '0px')};
  transition: 0.3s max-height ease-in-out;
  background-color: ${({ theme }) => theme.containerHover};
`;

export const Options = styled.div`
  display: flex;
  justify-content: center;
  ${gap('15px', 'row')}
  align-items: center;
`;

export const ExtraInfos = styled.div`
  padding: 10px;
  max-width: 100vw;
  & p {
    text-align: left;
    font-size: .8rem;
    margin: 0;
    display: -webkit-box;
    justify-content: flex-start;
    align-items: flex-start;
    white-space: pre-wrap;
    max-height: calc(1.1rem * 10);
    -webkit-line-clamp: 10;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const ExtraItens = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  & > button {
    margin-left: 5px;
  }
`;

export const PlanLabel = styled.span`
  text-transform: uppercase;
  font-weight: bold;
  color: ${({ theme }) => theme.primary};
`;

export const FooterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  & .select_header > div {
    margin: 0;
  }
`;
