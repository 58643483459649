/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';

import ControllersGroup from '../../../controllers/ControllersGroup';
import {
  GradientColorPickerController,
  RangeController,
  SimpleColorPickerController,
  ToggleSwitchController,
} from '../../../controllers/Base';

const StyleControllers = ({
  handle,
  config,
  data,
  extend,
  setExtend,
  isGlobalThemeActive,
}) => (
  <>
    <ControllersGroup
      title="Estilos"
      id="styles"
      extend={extend}
      setExtend={setExtend}
    >
      <SimpleColorPickerController
        label="Cor do dia vazio"
        handle={handle}
        kWord="EmptyColorControl"
        configValue={config?.EmptyColorControl}
        orientation="row"
        fullWidth
      />
      <RangeController
        label="Tamanho da borda dos meses"
        handle={handle}
        kWord="MonthWidthControl"
        configValue={config?.MonthWidthControl}
        min={0}
        max={20}
        fullWidth
      />
      <SimpleColorPickerController
        label="Cor da borda dos meses"
        handle={handle}
        kWord="MonthColorControl"
        configValue={config?.MonthColorControl}
        orientation="row"
        fullWidth
        disabled={!config?.MonthWidthControl || config?.MonthWidthControl?.toString() === '0'}
      />
      <RangeController
        label="Tamanho da borda dos dias"
        handle={handle}
        kWord="DayWidthControl"
        configValue={config?.DayWidthControl}
        min={0}
        max={20}
        fullWidth
      />
      <SimpleColorPickerController
        label="Cor da borda dos dias"
        handle={handle}
        kWord="DayColorControl"
        configValue={config?.DayColorControl}
        orientation="row"
        fullWidth
        disabled={!config?.DayWidthControl || config?.DayWidthControl?.toString() === '0'}
      />
      <RangeController
        min={2}
        max={Math.max(data.data.length, 5)}
        label="Quantidade de cores do degradê"
        handle={handle}
        kWord="QtdColorsControl"
        configValue={config?.QtdColorsControl}
        fullWidth
      />
      <GradientColorPickerController
        handle={handle}
        firstColorVal={config?.GradientPickerControl?.color1 || '#4711B2'}
        firstColorKWord="GradientFirstColor"
        secondColorVal={config?.GradientPickerControl?.color2 || '#FF9626'}
        secondColorKWord="GradientSecondColor"
        thirdColorVal={config?.GradientPickerControl?.color3}
        thirdColorKWord="GradientThirdColor"
        fullWidth
        orientation="row"
        text="Utilizando tema global"
        disabled={config?.UseGlobalColor !== false && isGlobalThemeActive}
      />
      <ToggleSwitchController
        label="Utilizar tema global"
        handle={handle}
        kWord="UseGlobalColor"
        configValue={config?.UseGlobalColor}
        firstOption=""
        secondOption=""
        fullWidth
      />
    </ControllersGroup>
  </>
);

StyleControllers.propTypes = {
  config: PropTypes.objectOf(PropTypes.any),
  data: PropTypes.objectOf(PropTypes.any),
  handle: PropTypes.func,
  extend: PropTypes.string,
  setExtend: PropTypes.func,
  isGlobalThemeActive: PropTypes.bool,
};

StyleControllers.defaultProps = {
  config: undefined,
  data: undefined,
  handle: () => {},
  extend: '',
  setExtend: () => {},
  isGlobalThemeActive: false,
};

export default StyleControllers;
