export default {
  TitleControl: '',
  ShowTitleControl: { checked: false },
  SortValuesControl: 'Não ordenado',
  Animate: true,
  ColorTheme: { scheme: 'category10' },
  StyleTitleControl: {
    fontSize: 20,
    color: '#000000',
    fontFamily: 'arial',
    bold: false,
    italic: false,
    underline: false,
  },
  type: 'Radar',
};
