/* eslint-disable react/forbid-prop-types */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';

import DataTable from '../../../juristec-ui/core/DataTable';
import formatValue from '../../../utils/functions/formatTableValue';

import {
  TableContainer,
  LegendsContainer,
} from './styled/FilePreview.styled';

const FilePreview = ({
  dataFrame,
}) => {
  const theme = useTheme();

  const getColor = (item) => {
    switch (dataFrame.types[item]) {
      case 'int64':
        return theme.typeNumber;
      case 'float64':
        return theme.typeNumber;
      case 'datetime64[ns]':
        return theme.typeDate;
      default:
        return theme.typeText;
    }
  };

  const columns = useMemo(() => dataFrame.columns.reduce((aux, column) => {
    if (column === 'index') return aux;
    aux.push({
      field: column,
      label: column,
      dataColor: getColor(column),
      sortable: true,
      valueGetter: (p) => formatValue(p[column], dataFrame.types[column]),
    });
    return aux;
  }, []), [dataFrame]);

  const rowData = useMemo(() => dataFrame.data.map((dataList) => (
    dataList.reduce((rowObj, data, index) => {
      // eslint-disable-next-line no-param-reassign
      rowObj[dataFrame.columns[index]] = data;
      return rowObj;
    }, {})
  )), [dataFrame]);

  return (
    <>
      <TableContainer>
        <DataTable
          columns={columns}
          rowData={rowData}
          headerColor="transparent"
          theadStyle={{
            position: 'sticky',
            top: '-5px',
            zIndex: 1,
            backgroundColor: theme.modal,
          }}
          defaultSortField="index"
          style={{ width: 'max-content' }}
          indexAsHeader
        />
      </TableContainer>
      <LegendsContainer>
        <div className="legend-type">
          <div className="square legend-texto" />
          Texto
        </div>
        <div className="legend-type">
          <div className="square legend-numero" />
          Número
        </div>
        <div className="legend-type">
          <div className="square legend-date" />
          Data
        </div>
      </LegendsContainer>
    </>
  );
};

FilePreview.propTypes = {
  dataFrame: PropTypes.shape({
    columns: PropTypes.arrayOf(PropTypes.any),
    data: PropTypes.arrayOf(PropTypes.any),
    types: PropTypes.shape({}),
  }),
};

FilePreview.defaultProps = {
  dataFrame: {
    columns: [],
    data: [],
    types: {},
  },
};

export default FilePreview;
