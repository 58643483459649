import React from 'react';

const ChartRadar = ({ primary = '#4711B2', secondary = '#FF9626' }) => (
  <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M33.8999 17.251L45.9164 24.8978L48.0544 38.2607L34.8128 55.9163L27.7511 41.2498L14.2123 34.7511L33.8999 17.251Z" fill={secondary} />
    <path d="M69.6256 33.109L60.8048 11.8352C60.3061 10.6324 59.3499 9.6771 58.1467 9.1791L36.8668 0.373227C36.2658 0.124469 35.6276 0.000253677 34.9895 0.000253677C34.3502 0.000253677 33.7111 0.125124 33.109 0.3747L11.8352 9.1953C10.6323 9.69396 9.67687 10.6502 9.17903 11.8532L0.373157 33.1335C-0.124849 34.3367 -0.124195 35.6883 0.374467 36.8912L9.19523 58.1648C9.69389 59.3677 10.6501 60.3231 11.8533 60.8209L33.1333 69.6268C33.7342 69.8755 34.3725 69.9998 35.0106 69.9998C35.6498 69.9998 36.2889 69.8749 36.891 69.6253L58.1647 60.8047C59.3676 60.3061 60.323 59.3498 60.8208 58.1468L69.6269 36.8665C70.1247 35.6635 70.1242 34.3117 69.6256 33.109ZM56.2843 56.2696L35.0106 65.0901L13.7305 56.2844L4.90972 35.0108L13.7157 13.7305L34.9895 4.91011L56.2694 13.716L65.0902 34.9895L56.2843 56.2696Z" fill={primary} />
  </svg>
);

export default ChartRadar;
