import urlHash from './urlHash';
import firebase from '../firebase';
import {
  getRefKpiLayout,
  getRefKpiItemList,
  getRefDashboardItem,

  getRefPublicLayout,
  getRefPublicKpiList,
  getRefPublicDashboard,
  getTransaction,
} from '../firestore';

export default async function (userDoc, dashDoc, snapId = 'kpis', revokeLink) {
  const dashId = dashDoc.id;
  if (!dashId) return { error: true };
  const publicUrl = revokeLink ? null : (`/public/${urlHash(`${dashId}/${dashDoc.owner}`)}`);
  // const batch = firebase.db.batch();
  const kpisRef = getRefKpiItemList(userDoc.id, dashId, snapId);
  const kpiLayoutRef = getRefKpiLayout(userDoc.id, dashId, snapId);
  const dashDocRef = getRefDashboardItem(userDoc.id, dashId);

  const publicDashboard = getRefPublicDashboard(userDoc.id, dashId);
  const publicKpis = getRefPublicKpiList(userDoc.id, dashId, snapId);
  const publicLayout = getRefPublicLayout(userDoc.id, dashId);

  await getTransaction(async (transaction) => {
    const dashRaw = await transaction.get(dashDocRef);
    const dash = {
      originalId: dashRaw.id,
      ...dashRaw.data(),
      photoUrl: userDoc.photoUrl,
      displayName: userDoc.name,
      publicUrl,
    };

    const layoutRaw = await transaction.get(kpiLayoutRef);
    const layout = layoutRaw.data() || {
      lg: [],
      md: [],
      sm: [],
      xs: [],
      xxs: [],
    };

    const kpisRaw = await kpisRef.get();

    transaction.set(dashDocRef, {
      publicUrl, publicTimestamp: firebase.serverTimestamp(),
    }, { merge: true });
    transaction.set(publicDashboard, dash);
    transaction.set(publicLayout, layout);

    const oldKpisRaw = await publicKpis.get();

    oldKpisRaw.docs.forEach((d) => {
      const publicDoc = publicKpis.doc(`${d.id}`);
      transaction.delete(publicDoc);
    });

    kpisRaw.docs.forEach((d) => {
      const publicDoc = publicKpis.doc(`${d.id}`);
      const originalKpi = { ...d.data() };
      transaction.set(publicDoc, originalKpi);
    });
  });

  return { publicUrl, localTimestamp: new Date().getTime(), error: false };
}
