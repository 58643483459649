import React, { useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { Container, HeaderAvatar } from './styled/PublicHeader.styled';
import Avatar from '../../../juristec-ui/core/Avatar';

function PublicHeader({ dashboardDoc }) {
  const theme = useTheme();
  const isMounted = useRef();
  const history = useHistory();

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  return (
    <Container>
      <div onClick={() => history.push('/home')} title="Início">
        <img
          src={theme.loginLogo}
          alt="logo"
          width="128px"
          style={{ alignSelf: 'center', cursor: 'pointer' }}
        />
      </div>

      <HeaderAvatar>
        <img src={theme.loginLogo} alt="client_logo" />
        <Avatar
          name={dashboardDoc?.displayName}
          src={dashboardDoc?.photoUrl}
          size="medium"
          style={{
            borderColor: `${theme.grey}`,
            color: `${theme.primary}`,
          }}
          onClick={() => {}}
        />
      </HeaderAvatar>
    </Container>
  );
}

export default PublicHeader;
