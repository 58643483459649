import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Tooltip from '../Tooltip';

import { TextContainer, TextContainerPop } from './styled/TableCellPop.styled';

const TableCellPop = ({ text }) => {
  const [copied, setCopied] = useState(false);

  const copyToClipboard = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const textArea = document.createElement('textarea');
    textArea.textContent = text;
    textArea.style.position = 'fixed'; /* Prevent scrolling to bottom of page in MS Edge. */
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);
    setCopied(true);
  };

  const hoverEnter = () => {
    setCopied(false);
  };

  return (
    <TextContainer onMouseEnter={hoverEnter} onClick={copyToClipboard}>
      <div>{text}</div>
      <Tooltip className="hover-tt" text={copied ? 'Texto copiado!' : 'Clique para copiar'} direction="right">
        <TextContainerPop onClick={copyToClipboard}>
          {text}
        </TextContainerPop>
      </Tooltip>
    </TextContainer>
  );
};

TableCellPop.propTypes = {
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

TableCellPop.defaultProps = {
  text: '',
};

export default TableCellPop;
