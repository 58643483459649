/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';

import ControllersGroup from '../../../controllers/ControllersGroup';
import {
  ToggleSwitchController,
  SelectController,
  RangeController,
  InputLineController,
  ChoiceBoxController,
} from '../../../controllers/Base';

const InteractivityController = ({
  handle,
  config,
  extend,
  setExtend,
}) => (
  <ControllersGroup title="Informações Dinâmicas" id="interactivity" extend={extend} setExtend={setExtend}>
    <ToggleSwitchController
      label="Habilitar informações"
      handle={handle}
      kWord="InteractiveControl"
      configValue={Boolean(config?.InteractiveControl)}
      firstOption=""
      secondOption=""
      fullWidth
    />
    <InputLineController
      label="Prefixo"
      handle={handle}
      kWord="InteractivePrefixControl"
      configValue={config?.InteractivePrefixControl}
      fullWidth
      disabled={!config?.InteractiveControl}
    />
    <InputLineController
      label="Sufixo"
      handle={handle}
      kWord="InteractiveSufixControl"
      configValue={config?.InteractiveSufixControl}
      fullWidth
      disabled={!config?.InteractiveControl}
    />
    <ChoiceBoxController
      label="Casas decimais"
      handle={handle}
      kWord="InteractiveDecimals"
      options={[
        { value: 'auto', label: 'Automático', id: 'auto' },
        { value: 'fixed', label: 'Customizável', id: 'fixed' },
      ]}
      configValue={config?.InteractiveDecimals}
      fullWidth
      disabled={!config?.InteractiveControl}
    />
    <RangeController
      label="Quantidade de casas"
      handle={handle}
      kWord="InteractiveSeparadorNumericoCasas"
      configValue={config?.InteractiveSeparadorNumericoCasas}
      min={0}
      max={5}
      step={1}
      precision={0}
      fullWidth
      disabled={!config?.InteractiveControl || config?.InteractiveDecimals !== 'fixed'}
    />
    <SelectController
      label="Moeda"
      handle={handle}
      kWord="InteractiveCurrencyControl"
      options={['Nenhum', 'R$', 'US$', '€'].map((d) => ({ value: d, label: d, id: d }))}
      configValue={config?.InteractiveCurrencyControl}
      disabled={!config?.InteractiveControl}
    />
    <SelectController
      label="Agrupamento"
      handle={handle}
      kWord="InteractiveGroupingControl"
      options={[
        { value: 'Nenhum', label: 'Nenhum', id: 'Nenhum' },
        { value: 'milhar', label: 'Milhar (M)', id: 'Milhar (M)' },
        { value: 'milhao', label: 'Milhão (Mi)', id: 'Milhão (Mi)' },
        { value: 'bilhao', label: 'Bilhão (Bi)', id: 'Bilhão (Bi)' },
      ]}
      configValue={config?.InteractiveGroupingControl}
      disabled={!config?.InteractiveControl}
    />

    <hr style={{
      width: '100%', color: 'lightgray', opacity: '0.2', marginBottom: '0',
    }}
    />

    <ToggleSwitchController
      label="Habilitar animações"
      handle={handle}
      kWord="Animate"
      configValue={Boolean(config?.Animate)}
      firstOption=""
      secondOption=""
      fullWidth
      disabled={!(config?.InteractiveControl)}
    />
    <SelectController
      label="Estilo de animação"
      handle={handle}
      kWord="MotionConfig"
      options={[
        { value: 'default', label: 'Padrão', id: 'motionDefault' },
        { value: 'gentle', label: 'Suave', id: 'motionGentle' },
        { value: 'wobbly', label: 'Oscilante', id: 'motionWobbly' },
        { value: 'stiff', label: 'Rígido', id: 'motionStiff' },
        { value: 'slow', label: 'Lento', id: 'motionSlow' },
        { value: 'molasses', label: 'Viscoso', id: 'motionMolasses' },
      ]}
      configValue={config?.MotionConfig}
      fullWidth
      disabled={!config?.InteractiveControl || !config?.Animate}
    />
  </ControllersGroup>
);

InteractivityController.propTypes = {
  config: PropTypes.objectOf(PropTypes.any),
  handle: PropTypes.func,
  extend: PropTypes.string,
  setExtend: PropTypes.func,
};

InteractivityController.defaultProps = {
  config: undefined,
  handle: () => {},
  extend: '',
  setExtend: () => {},
};

export default InteractivityController;
