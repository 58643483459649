import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useTheme } from 'styled-components';
import Popover from '../../../juristec-ui/core/Popover';

import { IconWrapper, DescriptionContent, Container } from './styled/SelectTips.styled';

import { Question } from '../../../juristec-ui/icons';

const SelectTips = ({
  style, description,
}) => {
  const theme = useTheme();
  const [popoverState, setPopoverState] = useState(false);

  const openPopover = () => {
    setPopoverState(true);
  };

  const closePopover = () => {
    setPopoverState(false);
  };

  return (
    <Container style={style}>
      <Popover
        open={popoverState}
        closePopover={closePopover}
        offset={[10, 2]}
        style={{
          zIndex: '1050',
          backgroundColor: `${theme.tooltipBackground}`,
        }}
      >
        <Popover.Action>
          <IconWrapper
            onMouseEnter={openPopover}
            onMouseLeave={closePopover}
          >
            <Question />
          </IconWrapper>
        </Popover.Action>
        <Popover.Content direction="bottom-start">
          <DescriptionContent>
            <span>{description.trim()}</span>
          </DescriptionContent>
        </Popover.Content>
      </Popover>
    </Container>
  );
};

SelectTips.propTypes = {
  /**
   * The text that is shown at the tip
   */
  description: PropTypes.string,
  /**
   * A object to help positioning the component on his parent
   */
  style: PropTypes.objectOf(PropTypes.any),
};

SelectTips.defaultProps = {
  description: '',
  style: {},
};

export default SelectTips;
