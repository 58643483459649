/* eslint-disable react/no-array-index-key */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';

import ControllersGroup from '../../../controllers/ControllersGroup';
import {
  ToggleSwitchController,
  SelectController,
  RangeController,
  ChoiceBoxController,
  SimpleColorPickerController,
  GradientColorPickerController,
} from '../../../controllers/Base';

import { getOptionsThemes } from '../../../utils/controllersUtils/themeColors';

const StyleControllers = ({
  handle,
  config,
  data,
  extend,
  setExtend,
  selectedColumn,
  isGlobalThemeActive,
}) => (
  <>
    <ControllersGroup
      title="Estilos"
      id="styles"
      extend={extend}
      setExtend={setExtend}
      sizeIncrease={config?.PalletsControl === 'custom' ? data?.index?.length : 0}
    >
      <RangeController
        label="Tamanho da borda"
        handle={handle}
        kWord="BorderWidthControl"
        configValue={config?.BorderWidthControl?.value}
        min={0}
        max={20}
        fullWidth
      />
      <ChoiceBoxController
        label="Cor da borda"
        handle={handle}
        kWord="BorderColorControl2"
        options={[
          { value: 'brighter', label: 'Clara', id: 'brighter' },
          { value: 'darker', label: 'Escura', id: 'darker' },
          { value: 'custom', label: 'Customizada', id: 'custom' },
        ]}
        configValue={config?.BorderColorControl?.modifiers?.[0]?.[0] || 'custom'}
        fullWidth
        disabled={!config?.BorderWidthControl?.value || config?.BorderWidthControl?.value.toString() === '0'}
      />
      {typeof config?.BorderColorControl !== 'string' && (
        <RangeController
          label="Opacidade da borda"
          handle={handle}
          kWord="BorderOpacityControl"
          configValue={config?.BorderColorControl?.modifiers?.[0]?.[1] || '1'}
          min={0}
          max={2}
          step={0.1}
          precision={1}
          fullWidth
          disabled={!config?.BorderWidthControl?.value || config?.BorderWidthControl?.value.toString() === '0'}
        />
      )}
      {typeof config?.BorderColorControl === 'string' && (
        <SimpleColorPickerController
          label="Cor customizada"
          handle={handle}
          kWord="BorderColorControl"
          configValue={config?.BorderColorControl}
          orientation="row"
          fullWidth
          disabled={!config?.BorderWidthControl?.value || config?.BorderWidthControl?.value.toString() === '0'}
        />
      )}
      <ToggleSwitchController
        label="Exibir trilho"
        handle={handle}
        kWord="EnableTrackControl"
        configValue={config?.EnableTrackControl}
      />
      <SimpleColorPickerController
        label="Cor"
        handle={handle}
        kWord="TrackColorControl"
        configValue={config?.TrackColorControl}
        orientation="row"
        disabled={!config?.EnableTrackControl}
      />
      <RangeController
        label="Opacidade do trilho"
        handle={handle}
        kWord="TrackOpacityControl"
        configValue={config?.TrackOpacityControl}
        min={0}
        max={1}
        step={0.1}
        precision={1}
        fullWidth
        disabled={!config?.EnableTrackControl}
      />
      <ChoiceBoxController
        label="Paleta de cores"
        handle={handle}
        kWord="PalletsControl"
        options={[
          { label: 'Padrão', value: 'default', id: 'default' },
          { label: 'Degradê', value: 'gradient', id: 'gradient' },
          { label: 'Customizada', value: 'custom', id: 'custom' },
        ]}
        configValue={config?.PalletsControl}
        fullWidth
        text="Utilizando tema global"
        disabled={config?.UseGlobalColor !== false && isGlobalThemeActive}
      />
      {config?.PalletsControl === 'default' && (
        <SelectController
          label="Tema"
          handle={handle}
          kWord="ColorTheme"
          options={getOptionsThemes()}
          configValue={config?.ColorTheme?.scheme}
          fullWidth
          text="Utilizando tema global"
          disabled={config?.UseGlobalColor !== false && isGlobalThemeActive}
        />
      )}
      {config?.PalletsControl === 'gradient' && (
        <GradientColorPickerController
          handle={handle}
          firstColorVal={config?.GradientPickerControl?.color1 || '#4711B2'}
          firstColorKWord="GradientFirstColor"
          secondColorVal={config?.GradientPickerControl?.color2 || '#FF9626'}
          secondColorKWord="GradientSecondColor"
          thirdColorVal={config?.GradientPickerControl?.color3}
          thirdColorKWord="GradientThirdColor"
          fullWidth
          orientation="row"
          text="Utilizando tema global"
          disabled={config?.UseGlobalColor !== false && isGlobalThemeActive}
        />
      )}
      {config?.PalletsControl === 'custom'
      && (selectedColumn ? data?.columns?.map((col, i) => {
        const key = col[col.length - 1];
        if (key === 'Totais') return null;
        return (
          <SimpleColorPickerController
            dataColorTarget={key.toString()}
            key={`${key}_i${i}`}
            label={key}
            handle={handle}
            kWord="Colors"
            configValue={config?.Colors[key]}
            orientation="row"
            fullWidth
            text="Utilizando tema global"
            disabled={config?.UseGlobalColor !== false && isGlobalThemeActive}
          />
        );
      }) : (
        data?.index?.map((col, i) => {
          if (col === 'Totais') return null;
          return (
            <SimpleColorPickerController
              dataColorTarget={col.toString()}
              key={`${col}_i${i}`}
              label={col}
              handle={handle}
              kWord="Colors"
              configValue={config?.Colors[col]}
              orientation="row"
              fullWidth
              text="Utilizando tema global"
              disabled={config?.UseGlobalColor !== false && isGlobalThemeActive}
            />
          );
        })
      )
      )}
      <ToggleSwitchController
        label="Utilizar tema global"
        handle={handle}
        kWord="UseGlobalColor"
        configValue={config?.UseGlobalColor}
        firstOption=""
        secondOption=""
        fullWidth
      />
    </ControllersGroup>
  </>
);

StyleControllers.propTypes = {
  config: PropTypes.objectOf(PropTypes.any),
  data: PropTypes.objectOf(PropTypes.any),
  handle: PropTypes.func,
  extend: PropTypes.string,
  setExtend: PropTypes.func,
  selectedColumn: PropTypes.objectOf(PropTypes.any),
  isGlobalThemeActive: PropTypes.bool,
};

StyleControllers.defaultProps = {
  config: undefined,
  data: undefined,
  handle: () => {},
  extend: '',
  setExtend: () => {},
  selectedColumn: undefined,
  isGlobalThemeActive: false,
};

export default StyleControllers;
