import styled from 'styled-components';
import { devices } from '../../../../../juristec-ui/core/breakpoints';

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  max-width: 400px;
`;

export const Messages = styled.span`
  &.info {
    font-size: 14px;
  }
  &.warning {
    font-size: 10px;
    color: ${({ theme }) => theme.error};
  }
`;

export const PhoneContainer = styled.div`
  display: grid;
  background-color: ${({ theme }) => theme.containerOdd};
  border-radius: 10px;
  padding: 10px;
  grid-template-columns: 1fr 1fr;
  margin-top: 10px;
  grid-gap: 10px;

  @media ${devices.phoneS} {
    grid-template-columns: 1fr;
  }
`;

export const PhoneItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  & > div {
    box-sizing: border-box;
    border: 2px solid ${({ theme }) => theme.secondary};
    color: ${({ theme }) => theme.secondary}
    background-color: ${({ theme }) => theme.secondary}20;
    border-radius: 5px;
    font-weight: bold;
    padding: 4px;
    width: min-content;
    white-space: nowrap;
    margin: 0;
    min-width: 170px;
    & * {
      font-weight: bold;
    }
  }
`;

export const InputContainer = styled.div`
  position: relative;
  margin: 0 auto;
  width: 50%;
  & .inputTxt {
    padding-right: 40px;
    padding-left: 40px;
    text-align: center;
    font-size: 20px;
  }
`;

export const IconWrapper = styled.div`
  position: absolute;
  bottom: 4px;
  right: 4px;
  & > svg {
    height: 25px;
    width: 25px;
    fill:  ${({ theme }) => theme.primary};
  }
`;
