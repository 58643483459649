import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import InputTextLine from '../../../../juristec-ui/core/InputTextLine';

import { FifthPageContainer } from './styled/FifthStep.styled';

/**
 * Fifth step for the 'ApplyTemplate'. This is where the name of the new dashboard is created.
 */
const FifthStep = ({
  newDashName, newDashNameHandle,
}) => {
  const inputRef = useRef(null);

  return (
    <FifthPageContainer>
      <InputTextLine
        ref={inputRef}
        label="Dê um nome para o dashboard"
        error={newDashName.errorMsg.length > 0}
        errorMessage={newDashName.errorMsg}
        value={newDashName.value}
        setValue={newDashNameHandle}
        emojiPicker
        emojiPickerPosition="top-end"
        onChange={(e) => newDashNameHandle(e.target.value)}
      />
    </FifthPageContainer>
  );
};

FifthStep.propTypes = {
  /**
   * String input of the new name
   */
  newDashName: PropTypes.shape({
    value: PropTypes.string.isRequired,
    error: PropTypes.bool.isRequired,
    errorMsg: PropTypes.string.isRequired,
  }).isRequired,
  /**
   * Function that deals with the changes on the new name
   */
  newDashNameHandle: PropTypes.func.isRequired,
};

export default FifthStep;
