/* eslint-disable no-plusplus */
import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';

import StepProgress from '../../../juristec-ui/core/StepProgress';
import FirstStep from './Steps/FirstStep';
import SecondStep from './Steps/SecondStep';
import Multifactor from '../../../utils/multifactor';

const RegisterPhone = ({ hide, mfa, setLoading }) => {
  const sended = useRef(false);
  const [acceptNumber, setAcceptNumber] = useState({ allow: false, showError: false });
  const [phoneNumber, setPhoneNumber] = useState({
    value: '', error: true, errorMsg: '', format: '',
  });
  const [verificationId, setVerificationId] = useState(null);
  const [code, setCode] = useState('');

  const sendVerification = async (retry) => {
    if (!sended.current || retry) {
      sended.current = true;
      setVerificationId(await mfa.sendSMS(`+${phoneNumber.value}`));
    }
  };

  const getFormattedPhone = () => {
    let count = 0;
    return phoneNumber.format.replace(/\./g, () => phoneNumber.value[count++]);
  };

  const firstStep = {
    label: 'Informe um telefone',
    validation: () => acceptNumber.allow && !phoneNumber.error,
    content: <FirstStep
      checkState={acceptNumber}
      setCheckState={setAcceptNumber}
      phoneNumber={phoneNumber}
      setPhoneNumber={setPhoneNumber}
    />,
  };

  const secondStep = {
    label: 'Informe o código',
    validation: () => code.length > 0,
    content: <SecondStep
      phoneNumber={getFormattedPhone()}
      inputCodeState={[code, setCode]}
      sendSmsCode={sendVerification}
      captchaFinished={!!verificationId}
    />,
  };

  const handleSubmit = () => {
    setLoading(true);
    mfa.resolveCode(code, phoneNumber.format);
  };

  return (
    <StepProgress
      steps={[firstStep, secondStep]}
      hide={hide}
      complete={handleSubmit}
      removeMarkers
      disableUntilValid
      onlyForward
    />
  );
};

RegisterPhone.propTypes = {
  hide: PropTypes.func,
  mfa: PropTypes.instanceOf(Multifactor).isRequired,
  setLoading: PropTypes.func,
};

RegisterPhone.defaultProps = {
  hide: () => {},
  setLoading: () => {},
};

export default RegisterPhone;
