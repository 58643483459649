import { writeBatch } from 'firebase/firestore';
import firebase from '../firebase';

class Batch {
  /**
   * @constructor
   * >### Encapsulates firestore "writeBatch" method for compatibility
     * * * *
   * Creates a write batch, used for performing multiple writes as a single atomic operation.
   * The maximum number of writes allowed in a single `WriteBatch` is 500.
   *
   * Unlike transactions, write batches are persisted offline and therefore are preferable
   * when you don't need to condition your writes on read data.
   * @returns A `Batch` instance that can be used to atomically execute multiple writes.
   */
  constructor() {
    this.batch = writeBatch(firebase.db);
  }

  /**
   * >### Wrapper of firestore `writeBatch.set` method
   * * * *
   * Writes to the document referred to by the provided * DocumentReference.
   * If the document does not exist yet, it will be created. If you provide `merge`
   * or `mergeFields`, the provided data can be merged into an existing document.
   * @param {DocumentReference<any>} docRef A reference to the document to be set.
   * @param {object} data An object of the fields and values for the document.
   * @param {object} options An object to configure the set behavior.
   * @throws Error - If the provided input is not a valid Firestore document.
   * @returns This `WriteBatch` instance. Used for chaining method calls.
   */
  set(docRef, data, options = {}) {
    return this.batch.set(docRef.ref, data, options);
  }

  /**
   * >### Wrapper of firestore `writeBatch.update` method
   * * * *
   * Updates fields in the document referred to by the provided * DocumentReference.
   * The update will fail if applied to a document that does not exist.
   * @param {documentReference<any>} docRef A reference to the document to be updated.
   * @param {object} data An object containing the fields and values with which to update
   * the document. Fields can contain dots to reference nested fields within the document.
   * @throws Error - If the provided input is not valid Firestore data.
   * @returns This `WriteBatch` instance. Used for chaining method calls.
   */
  update(docRef, data) {
    return this.batch.update(docRef.ref, data);
  }

  /**
   * >### Wrapper of firestore `writeBatch.delete` method
   * * * *
   * Deletes the document referred to by the provided DocumentReference.
   * @param {documentReference<any>} docRef A reference to the document to be deleted.
   * @returns This `WriteBatch` instance. Used for chaining method calls.
   */
  delete(docRef) {
    return this.batch.delete(docRef.ref);
  }

  /**
   * >### Wrapper of firestore `writeBatch.commit` method
   * * * *
   * Commits all of the writes in this write batch as a single atomic unit.
   *
   * The result of these writes will only be reflected in document reads that occur after
   * the returned promise resolves. If the client is offline, the write fails.
   * If you would like to see local modifications or buffer writes until the client is online,
   * use the full Firestore SDK.
   *
   * @returns A `Promise` resolved once all of the writes in the batch have been successfully
   * written to the backend as an atomic unit (note that it won't resolve while you're offline).
   */
  commit() {
    return this.batch.commit();
  }
}

export default Batch;
