import styled from 'styled-components';
import { devices } from '../../../juristec-ui/core/breakpoints';
import { gap } from '../../../juristec-ui/styles/theme';

export const PaginationContainer = styled.div`
  display: flex;
  flex-direction: row;
  user-select: none;
  justify-content: space-between;
  align-items: center;
  ${gap('40px', 'row')}
  padding: .2rem 0 .5rem;

  & .invert svg {
    transform: rotate(180deg);
  }
`;

export const LegendsContainer = styled.div`
  margin-top: 11px;
  margin-right: 120px;
  display: flex;
  gap: 10px;
  justify-content: space-between;
  & .legend-type { 
    display: flex;
    align-items: center;
  };

  @media ${devices.tablet} {
    display: none;
  }

  & .square { 
    height: 20px;
    width: 20px;
    border-radius: 5px;
    margin-right: 5px;
  }
  
  & .legend-texto { 
    background: ${({ theme }) => theme.typeText};
  }

  & .legend-numero { 
    background: ${({ theme }) => theme.typeNumber};
  }

  & .legend-date {
    background: ${({ theme }) => theme.typeDate};
  }
`;
