import styled from 'styled-components';

export const FilterContainer = styled.div`
  padding: 10px;
  font-family: arial;
  min-width: 250px;
  max-width: 250px;
`;

export const ActionGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;
`;
