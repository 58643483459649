import React from 'react';

// import { Container } from './styles';

function AddUser() {
  return (
    // <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    //   <path fillRule="evenodd" clipRule="evenodd" d="M6 7.85714C6 9.19665 4.92375 10.2857 3.6 10.2857C2.27625 10.2857 1.2 9.19665 1.2 7.85714C1.2 6.51763 2.27625 5.42857 3.6 5.42857C4.92375 5.42857 6 6.51763 6 7.85714ZM22.8 7.85714C22.8 9.19665 21.7238 10.2857 20.4 10.2857C19.0763 10.2857 18 9.19665 18 7.85714C18 6.51763 19.0763 5.42857 20.4 5.42857C21.7238 5.42857 22.8 6.51763 22.8 7.85714ZM19.2 11.5H21.6C22.9238 11.5 24 12.5891 24 13.9286V15.1429C24 15.2481 23.9869 15.3501 23.9621 15.4473C23.693 13.4997 22.0216 12 20 12C19.2786 12 18.6019 12.191 18.0175 12.5251C17.8543 12.4099 17.6845 12.3033 17.5088 12.2058C17.9438 11.7694 18.54 11.5 19.2 11.5ZM17.0459 13.303C16.4091 12.9287 15.6692 12.7143 14.88 12.7143H14.5688C13.7888 13.0938 12.9225 13.3214 12 13.3214C11.0775 13.3214 10.215 13.0938 9.43125 12.7143H9.12C6.735 12.7143 4.8 14.6723 4.8 17.0857V18.1786C4.8 19.1842 5.60625 20 6.6 20H17.4C17.78 20 18.1326 19.8807 18.4231 19.6772C16.9981 19.0653 16 17.6492 16 16C16 14.9608 16.3963 14.0142 17.0459 13.303ZM16.2 7.25C16.2 9.59888 14.3213 11.5 12 11.5C9.67875 11.5 7.8 9.59888 7.8 7.25C7.8 4.90112 9.67875 3 12 3C14.3213 3 16.2 4.90112 16.2 7.25ZM4.8 11.5C5.46 11.5 6.05625 11.7694 6.49125 12.2058C4.98 13.0444 3.9075 14.5585 3.67125 16.3571H1.2C0.53625 16.3571 0 15.8145 0 15.1429V13.9286C0 12.5891 1.07625 11.5 2.4 11.5H4.8Z" />
    //   <path d="M20 18V14M18 16H22" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
    // </svg>
    <svg width="18px" height="14.4px" viewBox="0 0 640 512" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M224 256C294.7 256 352 198.69 352 128C352 57.31 294.7 0 224 0C153.3 0 96 57.31 96 128C96 198.69 153.3 256 224 256ZM274.7 304H173.3C77.61 304 0 381.6 0 477.3C0 496.5 15.52 512 34.66 512H413.36C432.5 512 448 496.5 448 477.3C448 381.6 370.4 304 274.7 304ZM616 200H568V152C568 138.8 557.3 128 544 128C530.7 128 520 138.75 520 152V200H472C458.8 200 448 210.8 448 224C448 237.2 458.75 248 472 248H520V296C520 309.3 530.8 320 544 320C557.2 320 568 309.25 568 296V248H616C629.3 248 640 237.3 640 224C640 210.7 629.3 200 616 200Z" />
    </svg>
  );
}

export default AddUser;