import { useEffect, useState } from 'react';

export default function useOnScreen(ref) {
  const [isIntersecting, setIntersecting] = useState(false);

  /**
   * Observa se o componente esta visivel na tela ou nao
   */
  const observer = new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting));

  useEffect(() => {
    observer.observe(ref?.current);
    return () => {
      observer.disconnect();
    };
  }, []);

  return isIntersecting;
}
