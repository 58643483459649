import styled, { css } from 'styled-components';

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  & svg {
    width: 25px;
    height: 25px;
    fill: ${({ theme }) => theme.white};
  }
`;

export const Label = styled.h5`
  margin: 0;
  color: ${({ theme }) => theme.white};
`;

export const IconFlipWrapper = styled(IconWrapper)`
  transition: all 0.5s;
  ${(props) => props.flip
    && css`
      transform: rotate(180deg);
    `}
`;
