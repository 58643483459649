/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { format } from 'date-fns';

import Checkbox from '../../../juristec-ui/core/Checkbox';
import Button from '../../../juristec-ui/core/Button';
import Select from '../../../juristec-ui/core/Select';
import Loading from '../../../juristec-ui/core/MiniLoading';
import InputTextLine from '../../../juristec-ui/core/InputTextLine';
import GlobalFilterItem from './GlobalFilterItem';
import KpiFilter from '../../KpiFilter';

import {
  FilterContainer,
  ScrollContainer,
  OrderRow,
  ActionGroup,
  OrderVar,
  ErrorContainer,
  SeparatorLine,
} from './styled/GlobalFilter.styled';

import {
  dateOptions,
  filterTypeOptionsDate,
  filterTypeOptionsNumber,
  filterTypeOptionsSimple,
  filterTypeOptionsText,
} from '../../../options';

const getTypeOptions = (isSimple, columnType) => {
  if (isSimple) {
    return filterTypeOptionsSimple;
  }
  switch (columnType) {
    case 'datetime64[ns]':
      return filterTypeOptionsDate;
    case 'float64':
      return filterTypeOptionsNumber;
    default:
      return filterTypeOptionsText;
  }
};

const getActualTypeAndInverse = (ftype) => {
  const ftypeInfo = ftype.split('-');
  let actualFtype = ftypeInfo?.[1] || ftypeInfo?.[0];
  if (actualFtype.includes('last')) {
    actualFtype = 'last';
  } else if (actualFtype.includes('next')) {
    actualFtype = 'next';
  }
  return [
    ftypeInfo?.[0] === 'not',
    actualFtype,
  ];
};

const getSelectorByType = (ftype) => {
  switch (ftype) {
    case 'lastY':
    case 'not-lastY':
    case 'nextY':
    case 'not-nextY':
      return 'year';
    case 'lastM':
    case 'not-lastM':
    case 'nextM':
    case 'not-nextM':
      return 'M';
    case 'lastD':
    case 'not-lastD':
    case 'nextD':
    case 'not-nextD':
      return 'D';
    case 'lastQ':
    case 'not-lastQ':
    case 'nextQ':
    case 'not-nextQ':
      return 'Q';
    case 'lastW':
    case 'not-lastW':
    case 'nextW':
    case 'not-nextW':
      return 'W';
    case 'lastS':
    case 'not-lastS':
    case 'nextS':
    case 'not-nextS':
      return 'semester';
    default:
      return '';
  }
};

const GlobalFilter = ({
  infoFilter,
  loadSimpleOpts,
  close,
  handleFilter,
}) => {
  const [filterInput, setFilterInput] = useState('');
  const [filter, _setFilter] = useState({
    values: [],
    simpleOpts: [],
    type: null,
    conditionOpts: [],
    condition: null,
    dateFormat: null,
  });
  /** Check if the filter has any changes */
  const isDirty = useRef(false);
  const cron = useRef();

  const isDate = infoFilter?.type === 'datetime64[ns]';

  const setFilter = (changes) => {
    _setFilter((fState) => ({
      ...fState,
      ...(typeof changes === 'function' ? changes(fState) : changes),
    }));
  };

  /* Check if all the options are selected */
  const checkAllSelected = () => filter.values?.length === infoFilter?.options?.length;

  /**
   * Handles selection of all options
   * @param {boolean} check Checkbox check state
   */
  const toggleAll = (check) => {
    isDirty.current = true;
    setFilter({ values: check ? infoFilter?.options?.map((opt) => opt.value) : [] });
  };

  /**
   * Handles selection of an option
   * @param {boolean} check Checkbox check state
   * @param {string} key Checkbox identifier (value)
   */
  const handleCheck = (check, key) => {
    isDirty.current = true;
    setFilter((o) => ({
      values: check ? [...o.values, key] : o.values.filter((k) => k !== key),
    }));
  };

  useEffect(() => {
    const isSimple = infoFilter?.fType?.includes('values');
    const conditionValue = isDate && isSimple ? (
      infoFilter?.fType
    ) : (
      (infoFilter?.isInverse ? 'not-' : '') + infoFilter?.fType + (infoFilter?.selector === 'year' ? 'Y' : infoFilter?.selector)
    );
    const opts = getTypeOptions(isSimple, infoFilter?.type);
    const selector = isDate && isSimple ? (
      dateOptions.find((opt) => opt.value === infoFilter.selector) || { label: 'Ano (#)', value: 'year' }
    ) : { label: '', value: '' };

    setFilter({
      type: isSimple ? { label: 'Simples', value: 'simple' } : { label: 'Avançado', value: 'advanced' },
      values: infoFilter?.selected || [],
      conditionOpts: opts,
      condition: opts.find((c) => c.value === conditionValue) || opts[0],
      dateFormat: selector,
    });
  }, []);

  useEffect(() => {
    if (infoFilter?.options) {
      if (filter?.type?.value === 'simple' && !infoFilter.selected?.length > 0) {
        setFilter({
          simpleOpts: infoFilter.options,
          values: infoFilter.options?.map((opt) => opt.value),
        });
      } else {
        setFilter({
          simpleOpts: infoFilter.options,
        });
      }
    }
  }, [infoFilter?.options, filter.type]);

  useEffect(() => {
    if (filter.type?.value === 'simple' && infoFilter?.lastSelector !== filter.dateFormat?.value) {
      loadSimpleOpts(filter.dateFormat.value || '');
    }
  }, [filter.type, filter.dateFormat]);

  const formatValue = (value, mFormat, isSimple) => {
    if (isSimple) {
      if (value === 'select_all') return null;
      return value;
    }
    if (isDate && [
      'between', 'between_inc', 'before', 'before_inc', 'after', 'after_inc',
    ].includes(mFormat)) {
      return format(value, 'yyyy-MM-dd');
    }
    if (mFormat !== 'contains') {
      return Number(value);
    }
    return value;
  };

  /** Sends the filter values to be filtered */
  const sendFilter = () => {
    const [isInverse, actualType] = getActualTypeAndInverse(filter.condition.value);
    const isSimple = filter.type.value === 'simple';
    const selector = isSimple ? (filter.dateFormat?.value || '') : getSelectorByType(filter.condition.value);
    const vals = filter.values.reduce((aux, v) => {
      const val = formatValue(v, actualType, isSimple);
      if (val || val === 0) aux.push(val);
      return aux;
    }, []);
    handleFilter(vals, actualType, selector, isInverse);
    close();
  };

  /**
   * Handles search bar input
   * @param {Event} e Input text event
   */
  const handleFilterInput = (e) => {
    const val = e.target.value;
    setFilterInput(val);
    clearTimeout(cron.current);
    cron.current = setTimeout(() => {
      setFilter({
        simpleOpts: infoFilter?.options?.filter((cOpt) => (
          cOpt.label.toLowerCase().includes(val.toLowerCase())
        )),
      });
    }, 200);
  };

  const resetValues = (conditionKey) => {
    switch (conditionKey) {
      case 'not-contains':
      case 'contains':
        return [];
      case 'not-values':
      case 'values':
        return infoFilter?.options?.map((opt) => opt.value);
      case 'not-between':
      case 'between':
      case 'not-between_inc':
      case 'between_inc':
        return isDate ? [new Date(), new Date()] : ['', ''];
      case 'not-before':
      case 'before':
      case 'not-before_inc':
      case 'before_inc':
      case 'not-after':
      case 'after':
      case 'not-after_inc':
      case 'after_inc':
        return new Date();
      default:
        return [''];
    }
  };

  const setFilterType = (selected) => {
    const opts = getTypeOptions(selected.value === 'simple', infoFilter?.type);
    setFilter({
      type: selected,
      conditionOpts: opts,
      condition: opts[0],
      values: resetValues(opts[0].value),
    });
    isDirty.current = true;
  };

  const setCondition = (selected) => {
    setFilter({
      condition: selected,
      values: resetValues(selected.value),
    });
    isDirty.current = true;
  };

  const setDateFormat = (selected) => {
    setFilter((o) => ({
      dateFormat: selected,
      values: resetValues(o.condition.value),
    }));
  };

  const handleFilterChange = (value) => {
    const regexFloat = /^-{0,1}\d*\.{0,1}\d*/;
    const regexInt = /^\d*/;
    switch (filter.condition.value) {
      case 'not-contains':
      case 'contains': {
        setFilter((o) => {
          const auxVals = new Set(o.values);
          auxVals[value.operation](value.val);
          return { values: [...auxVals] };
        });
        break;
      }
      case 'not-between':
      case 'between':
      case 'not-between_inc':
      case 'between_inc': {
        let val1 = value.start;
        let val2 = value.end;
        if (!isDate) {
          [val1] = value.start?.toString()?.replace(',', '.')?.match(regexFloat) || '';
          [val2] = value.end?.toString()?.replace(',', '.')?.match(regexFloat) || '';
        }
        setFilter((o) => ({
          values: [val1 || (val1 !== '' ? o.values[0] : ''), val2 || (val2 !== '' ? o.values[1] : '')],
        }));
        break;
      }
      case 'not-before':
      case 'before':
      case 'not-before_inc':
      case 'before_inc':
      case 'not-after':
      case 'after':
      case 'not-after_inc':
      case 'after_inc':
        setFilter({ values: [value] });
        break;
      case 'not-greater':
      case 'greater':
      case 'not-lesser':
      case 'lesser':
      case 'not-greater_inc':
      case 'greater_inc':
      case 'not-lesser_inc':
      case 'lesser_inc': {
        const [valF] = value.toString().replace(',', '.').match(regexFloat);
        setFilter({ values: [valF || ''] });
        break;
      }
      default: {
        const [val] = value.toString().match(regexInt);
        setFilter({ values: [val || ''] });
        break;
      }
    }
    isDirty.current = true;
  };

  const checkVals = () => {
    switch (filter?.condition?.value) {
      case 'not-contains':
      case 'contains':
      case 'not-values':
      case 'values':
        return filter.values.length > 0;
      case 'not-between':
      case 'between':
      case 'not-between_inc':
      case 'between_inc': {
        let val1 = filter.values[0];
        let val2 = filter.values[1];
        if (!isDate) {
          if (val1?.length > 0 && val2?.length > 0) {
            val1 = Number(filter.values[0]);
            val2 = Number(filter.values[1]);
          } else {
            return false;
          }
        }
        return !Number.isNaN(val1) && !Number.isNaN(val2) && val2 > val1;
      }
      case 'not-before':
      case 'before':
      case 'not-before_inc':
      case 'before_inc':
      case 'not-after':
      case 'after':
      case 'not-after_inc':
      case 'after_inc':
        return filter.values[0] instanceof Date;
      default:
        if (filter.values?.[0]?.length > 0) {
          const val = Number(filter.values[0]);
          return !Number.isNaN(val);
        }
        return false;
    }
  };

  return (
    <FilterContainer>
      {infoFilter?.fileError?.length > 0 ? (
        <ErrorContainer>
          {infoFilter.fileError.map((msg) => (
            <span key={msg}>{msg}</span>
          ))}
        </ErrorContainer>
      ) : (
        <>
          {infoFilter?.column?.length > 0 && (
            <OrderVar>
              <span>{infoFilter.column}</span>
            </OrderVar>
          )}
          {!infoFilter?.options ? (
            <div style={{
              width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center',
            }}
            >
              <Loading fill="primary" />
            </div>
          ) : (
            <>
              <OrderRow>
                <Select
                  selectLabel="Tipo"
                  placeholder="Selecione"
                  options={[
                    { label: 'Simples', value: 'simple', id: 'simple' },
                    { label: 'Avançado', value: 'advanced', id: 'advanced' },
                  ]}
                  onChange={setFilterType}
                  value={filter.type}
                  fullWidth
                  atModal
                />
              </OrderRow>
              <OrderRow>
                <Select
                  isSearchable
                  selectLabel="Condição"
                  onChange={setCondition}
                  value={filter.condition}
                  options={filter.conditionOpts}
                  menuPlacement="auto"
                  placeholder="Selecione"
                  // maxMenuHeight={150}
                  menuPosition="fixed"
                  formatOptionLabel={false}
                  closeMenuOnSelect={false}
                  fullWidth
                />
              </OrderRow>
              <SeparatorLine />
              {filter.type?.value === 'simple' ? (
                <>
                  {isDate && (
                    <OrderRow>
                      <Select
                        isSearchable
                        selectLabel="Formato"
                        placeholder="Selecione"
                        onChange={setDateFormat}
                        value={filter.dateFormat}
                        options={dateOptions}
                        formatOptionLabel={false}
                        fullWidth
                      />
                    </OrderRow>
                  )}
                  <OrderRow>
                    <InputTextLine
                      value={filterInput}
                      placeholder="Pesquisar..."
                      onChange={handleFilterInput}
                    />
                  </OrderRow>
                  <OrderRow key={`all${checkAllSelected()}`} className="hover-bgcolor">
                    <Checkbox
                      text="Selecionar todos"
                      variant="outlined"
                      style={{ width: '100%', justifyContent: 'flex-start' }}
                      checked={checkAllSelected()}
                      handleCheckboxChange={(check) => toggleAll(check)}
                    />
                  </OrderRow>
                  <ScrollContainer>
                    {filter.simpleOpts?.map((opt) => (
                      <GlobalFilterItem
                        key={opt.value}
                        option={opt}
                        handler={handleCheck}
                        selected={filter.values}
                      />
                    ))}
                  </ScrollContainer>
                </>
              ) : (
                <OrderRow style={{ flexDirection: 'column' }}>
                  <KpiFilter
                    filterType={filter?.condition?.value}
                    handle={handleFilterChange}
                    values={filter.values}
                    options={filter.simpleOpts}
                    format={infoFilter?.type}
                    orientation="column"
                  />
                </OrderRow>
              )}
              <ActionGroup>
                <Button
                  size="small"
                  variant="outlined"
                  onClick={close}
                  style={{ width: '100%', minWidth: '100px' }}
                >
                  Cancelar
                </Button>
                <Button
                  size="small"
                  onClick={sendFilter}
                  disabled={!checkVals() || !isDirty?.current}
                  style={{ width: '100%', minWidth: '100px' }}
                >
                  Aplicar
                </Button>
              </ActionGroup>
            </>
          )}
        </>
      )}
    </FilterContainer>
  );
};

GlobalFilter.propTypes = {
  infoFilter: PropTypes.shape({
    column: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.any),
    selected: PropTypes.arrayOf(PropTypes.any),
    type: PropTypes.string,
    fType: PropTypes.string,
    fileError: PropTypes.arrayOf(PropTypes.string),
    selector: PropTypes.string,
    lastSelector: PropTypes.string,
    isInverse: PropTypes.bool,
  }),
  close: PropTypes.func,
  handleFilter: PropTypes.func,
  loadSimpleOpts: PropTypes.func,
};

GlobalFilter.defaultProps = {
  infoFilter: PropTypes.shape({
    column: '',
    options: undefined,
    selected: [],
    type: '',
    fType: 'values',
    fileError: [],
    selector: '',
    lastSelector: '',
  }),
  close: () => {},
  handleFilter: () => {},
  loadSimpleOpts: () => {},
};

export default GlobalFilter;
