import styled from 'styled-components';

export const ThirdPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 600px;
  max-height: calc(100vh - 335px);
  gap: 5px;
  margin: 10px;
  & .errorMessage {
    position: absolute;
  }
`;