import styled from 'styled-components';
import { devices } from '../../../juristec-ui/core/breakpoints';

export const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 5%;
  @media ${devices.tablet} {
    padding: 0 20px;
  }

  & .report-icon svg {
    width: 18px;
    height: 18px;
  }
  & > main {
    width: 100%;
    margin: 0;
  }
`;

export const ScrollContainer = styled.main`
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: auto;
  padding: 5px;
  padding-bottom: 65px;
  margin-top: 0rem;
  box-sizing: border-box;
  width: 100%;
  height: calc(100vh - 137px);
  @media ${devices.tablet} {
    margin: 0px 0px;
    width: 100%;
    height: calc(100vh - 117px);
  }

  @media ${devices.phoneS} {
    margin: 0px 0px;
    width: 100%;
    height: calc(100vh - 117px);
  }
`;
