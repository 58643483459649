import styled, { css } from 'styled-components';

const getContrastYIQ = (hexcolor, dark, light) => {
  // eslint-disable-next-line no-param-reassign
  hexcolor = hexcolor.replace('#', '');
  const r = parseInt(hexcolor.substr(0, 2), 16);
  const g = parseInt(hexcolor.substr(2, 2), 16);
  const b = parseInt(hexcolor.substr(4, 2), 16);
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128 ? dark : light;
};

const getFontColor = (hexcolor) => getContrastYIQ(hexcolor, '#000', '#FFF');

const StyledCardKpiTitle = styled.div`
  border: 0px;
  padding: 0px;
  margin: 0px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
`;

const StyledCardKpiContent = styled.div`
  border: 0px;
  padding: 0px;
  margin: 0px;
  height: 100%;
  ${(props) => (props.type === 'Table' ? css`
      & > div:first-child {
        overflow-y: auto;
        height: 100%;
      }
  ` : '')}
`;

const StyledCardKpi = styled.div`
  border: 0px;  
  width: 100%;
  height: 100%;
  
  display: flex;
  flex-direction: column;

  ${StyledCardKpiTitle} {
    width: 100%;
    height: ${({ titleFontSize }) => `max(${titleFontSize}px, 10%)`};
    
    writing-mode: horizontal-tb;
    text-orientation: mixed;
  }

  ${StyledCardKpiContent} {
    width: 100%;
    height: ${({ titleFontSize, showTitle, kpiType }) => (
  showTitle
    ? `calc(100% - max(${titleFontSize}px, 10%)${kpiType === 'SubsetTable' ? ' - 35px' : ''})`
    : `calc(100% ${kpiType === 'SubsetTable' ? ' - 35px' : ''})`
)};
  }
`;

const PaginationWrapper = styled.div`
  ${({ bgColor }) => css`
    .tableWrapperPagination {
      padding: 0;
      & button {
        padding: 2px;
      }
      & span {
        color: ${getFontColor(bgColor)}
      }
    }
  `}
`;

export {
  StyledCardKpi, StyledCardKpiTitle, StyledCardKpiContent, PaginationWrapper,
};
