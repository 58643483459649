import React from 'react';
import PropTypes from 'prop-types';

const Datajuri = ({ width, height }) => (
  <svg width={width} height={height} viewBox="0 0 40 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19.375 6.39025e-06L14.375 0V18.9474H11.3105C7.5 18.9474 5.625 15.1579 5.625 15.1579L0 15.1579C0 16.8216 5.02015 24 11.3105 24H19.375V6.39025e-06Z" fill="#3D4189" />
    <path d="M20.625 6.39025e-06L25.625 0V18.9474H28.6895C32.5 18.9474 34.375 15.1579 34.375 15.1579L40 15.1579C40 16.8216 34.9799 24 28.6895 24H20.625V6.39025e-06Z" fill="#3D4189" />
  </svg>
);

Datajuri.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

Datajuri.defaultProps = {
  width: '25px',
  height: '25px',
};

export default Datajuri;
