import styled, { css } from 'styled-components';

export const CheckboxContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  user-select: none;
`;

export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  overflow: hidden;
  white-space: nowrap;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  display: none;
`;

export const CheckboxText = styled.label`
  color: ${({ disabled, theme }) => (disabled ? theme.disabled : theme.color)};
  cursor: pointer;
  padding-right: 12px;
  font-size: ${({ fontSize }) => fontSize || '14px'};
`;

export const StyledCheckbox = styled.div`
  width: 18px;
  height: 18px;
  margin-right: 12px;
  border-radius: 4px;
  border: 2px solid;
  ${({
    disabled, checked, theme, color,
  }) => (disabled ? css`
    border-color: ${theme.disabled};
  ` : css`
    border-color: ${checked ? (theme[color] || color) : theme.grey};
  `)}
  background-color: ${({
    variant, checked, disabled, theme, color,
  }) => (variant === 'contained' && checked && !disabled ? (theme[color] || color) : 'transparent')};
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.1s ease;

  & > svg {
    width: 20px;
    height: 20px;
    visibility: ${({ checked }) => (checked ? 'visible' : 'hidden')};

    & > path {
      fill: ${({ variant, color, theme }) => (variant === 'outlined' ? (theme[color] || color) : theme.white)};
    }
  }
`;
