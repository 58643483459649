import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import StepProgress from '../../../juristec-ui/core/StepProgress';
import FirstStep from './Steps/FirstStep';
import SecondStep from './Steps/SecondStep';
import ThirdStep from './Steps/ThirdStep';
// import FourthStep from "./Steps/FourthStep";

import { verifyInput } from '../../../juristec-ui/utils/validators/inputTextValidators';
import { dateOptions } from '../../../options';

/**
 * Component with steps to apply a template to a file
 */
const CopyDashboard = ({
  hide,
  variables,
  files,
  handleFileChange,
  // selectedFile,
  // setSelectedFile,
  // columnToFilter,
  // setColumnToFilter,
  onSelectFilter,
  submitData,
  tourContext,
}) => {
  const [columnVarBinds, setColumnVarBinds] = useState({});
  const [filterColumns, setFilterColumns] = useState([]);
  const [newDashName, setNewDashName] = useState({
    value: '',
    error: true,
    errorMsg: '',
  });
  const [selectedFile, setSelectedFile] = useState({ value: '', label: '', owner: '' });
  const [selectedFileVars, setSelectedFileVars] = useState([]);
  const [columnToFilter, setColumnToFilter] = useState({ value: '', label: '' });
  const [dateFormat, setDateFormat] = useState(dateOptions[0]);
  const [columnData, setColumnData] = useState([]);

  useEffect(() => {
    if (!selectedFile.value) return;
    (async () => {
      setColumnVarBinds({});
      setFilterColumns([]);
      const fileVars = await handleFileChange(selectedFile.value);
      setSelectedFileVars(fileVars);
      setColumnToFilter({ value: '', label: '' });
      setNewDashName({
        value: '',
        error: true,
        errorMsg: '',
      });
    })();
  }, [selectedFile]);

  useEffect(() => {
    if (columnToFilter.value) {
      (async () => {
        const aux = await onSelectFilter(
          selectedFile.value,
          columnToFilter.value,
          columnToFilter.type === 'datetime64[ns]' ? dateFormat.value : '',
        );
        setColumnData(aux);
      })();
    }
  }, [columnToFilter, dateFormat]);

  const checkVarBinds = () => Object.keys(variables).length === Object.keys(columnVarBinds).length;

  const newDashNameHandle = (val) => {
    const msg = verifyInput(val, true, 2, 40);
    setNewDashName({
      value: val,
      error: msg.length !== 0,
      errorMsg: msg,
    });
  };

  const checkNewDashName = () => {
    if (newDashName.error) {
      newDashNameHandle(newDashName.value);
      return false;
    }
    return true;
  };

  const handleSubmit = () => {
    submitData({
      name: newDashName.value,
      bindings: columnVarBinds,
      filters: filterColumns,
      database: selectedFile.value,
    });
  };

  const firstStep = {
    label: 'Arquivo e Variáveis',
    validation: checkVarBinds,
    content: (
      <FirstStep
        variables={variables}
        columnVarBinds={columnVarBinds}
        setColumnVarBinds={setColumnVarBinds}
        files={files}
        selectedFileVars={selectedFileVars}
        selectedFile={selectedFile}
        setSelectedFile={setSelectedFile}
      />
    ),
  };

  const secondStep = {
    label: 'Filtro (Opcional)',
    content: (
      <SecondStep
        fileColumns={selectedFileVars}
        columnToFilter={columnToFilter}
        setColumnToFilter={setColumnToFilter}
        columnData={columnData}
        filterColumns={filterColumns}
        setFilterColumns={setFilterColumns}
        dateFormat={dateFormat}
        setDateFormat={setDateFormat}
        dateOptions={dateOptions}
      />
    ),
  };

  const thirdStep = {
    label: 'Criação',
    validation: checkNewDashName,
    content: <ThirdStep newDashName={newDashName} newDashNameHandle={newDashNameHandle} />,
  };

  return (
    <>
      <StepProgress
        steps={[firstStep, secondStep, thirdStep]}
        hide={hide}
        complete={handleSubmit}
        markersWidth="large"
      />
    </>
  );
};

CopyDashboard.propTypes = {
  /**
   * Function to close the modal
   */
  hide: PropTypes.func.isRequired,
  /**
   * Object with the variables from the dashboard
   */
  variables: PropTypes.objectOf(PropTypes.shape({ description: PropTypes.string.isRequired }))
    .isRequired,
  /**
   * Array of files uploaded
   */
  files: PropTypes.arrayOf(
    PropTypes.shape({
      filename: PropTypes.string.isRequired,
      owner: PropTypes.string.isRequired,
    }),
  ).isRequired,
  /**
   *  Function to run when a file is Selected. This function will return the file variables.
   */
  handleFileChange: PropTypes.func.isRequired,
  /**
   * A function that sends the new data back to the parent
   */
  submitData: PropTypes.func.isRequired,
  /**
   * A function that runs when filter column is selected.
   */
  onSelectFilter: PropTypes.func.isRequired,

  tourContext: PropTypes.shape({
    tourOpen: PropTypes.bool,
    nextStep: PropTypes.func,
  }),
};

CopyDashboard.defaultProps = {
  tourContext: {
    tourOpen: false,
  },
};

export default CopyDashboard;
