import React from 'react';
import PropTypes from 'prop-types';
import Toggle from '../../../juristec-ui/core/Toggle';
import ColorPicker from '../../../juristec-ui/core/ColorPicker2';
import IconButton from '../../../juristec-ui/core/IconButton';
import {
  InputRow, Label, ColorsRow, ArrowIcon,
} from '../styled/DashStyles.styled';
import {
  ArrowRight, BackgroundColor, FontColor, Add, Close,
} from '../../../juristec-ui/icons';

const ThemeStyle = ({ tmpData, handler, timedHandler }) => {
  const handleUseGlobalTheme = () => handler('useGlobalTheme', !tmpData.useGlobalTheme);
  const handleTitles = (val) => timedHandler('titles', val);
  const handleChartsFirst = (val) => timedHandler('charts', [val, tmpData.charts[1], tmpData.charts?.[2]]);
  const handleChartsSecond = (val) => timedHandler('charts', [tmpData.charts[0], val, tmpData.charts?.[2]]);
  const handleChartsThird = (val) => timedHandler('charts', [tmpData.charts[0], tmpData.charts[1], val]);
  const handleInsightBg = (val) => timedHandler('insightBg', val);
  const handleInsightTxt = (val) => timedHandler('insightTxt', val);
  const handleValueBg = (val) => timedHandler('valueBg', val);
  const handleValueTxt = (val) => timedHandler('valueTxt', val);
  const handleTableRowsFirst = (val) => timedHandler('tableRows', [val, tmpData.tableRows[1]]);
  const handleTableRowsSecond = (val) => timedHandler('tableRows', [tmpData.tableRows[0], val]);
  const handleTableHeader = (val) => timedHandler('tableHeader', val);

  const addThirdColor = () => {
    timedHandler('charts', [tmpData.charts[0], tmpData.charts[1], '#12B315']);
  };

  const removeThirdColor = () => {
    timedHandler('charts', [tmpData.charts[0], tmpData.charts[1], null]);
  };

  return (
    <>
      <InputRow>
        <Label>Usar tema global</Label>
        <Toggle
          label=""
          checked={tmpData.useGlobalTheme}
          handleChange={handleUseGlobalTheme}
          styleContainer={{ margin: 'auto 0' }}
          highlightOption={false}
          size="small"
        />
      </InputRow>
      <InputRow disabled={!tmpData.useGlobalTheme}>
        <Label>Títulos</Label>
        <ColorPicker onChange={handleTitles} color={tmpData.titles} isIconButton atModal>
          <FontColor />
        </ColorPicker>
      </InputRow>
      <InputRow disabled={!tmpData.useGlobalTheme}>
        <Label>Categorias</Label>
        <ColorsRow>
          <ColorPicker onChange={handleChartsFirst} color={tmpData.charts[0]} isIconButton atModal>
            <BackgroundColor />
          </ColorPicker>
          <ArrowIcon><ArrowRight /></ArrowIcon>
          <ColorPicker onChange={handleChartsSecond} color={tmpData.charts[1]} isIconButton atModal>
            <BackgroundColor />
          </ColorPicker>
          {tmpData.charts?.[2]?.length > 0 ? (
            <>
              <ArrowIcon><ArrowRight /></ArrowIcon>
              <ColorPicker
                onChange={handleChartsThird}
                color={tmpData.charts[2]}
                isIconButton
                atModal
              >
                <BackgroundColor />
              </ColorPicker>
              <IconButton
                onClick={removeThirdColor}
                variant="contained"
                shape="circle"
                color="error"
                size="small"
                style={{ padding: '0' }}
              >
                <Close />
              </IconButton>
            </>
          ) : (
            <IconButton
              onClick={addThirdColor}
              variant="outlined"
              shape="rounded"
              color="grey"
            >
              <Add />
            </IconButton>
          )}
        </ColorsRow>
      </InputRow>
      <InputRow disabled={!tmpData.useGlobalTheme}>
        <Label>Texto do Insight</Label>
        <ColorPicker onChange={handleInsightTxt} color={tmpData.insightTxt} isIconButton atModal>
          <FontColor />
        </ColorPicker>
      </InputRow>
      <InputRow disabled={!tmpData.useGlobalTheme}>
        <Label>Fundo do Insight</Label>
        <ColorPicker onChange={handleInsightBg} color={tmpData.insightBg} isIconButton atModal>
          <BackgroundColor />
        </ColorPicker>
      </InputRow>
      <InputRow disabled={!tmpData.useGlobalTheme}>
        <Label>Texto do Valor</Label>
        <ColorPicker onChange={handleValueTxt} color={tmpData.valueTxt} isIconButton atModal>
          <FontColor />
        </ColorPicker>
      </InputRow>
      <InputRow disabled={!tmpData.useGlobalTheme}>
        <Label>Fundo do Valor</Label>
        <ColorPicker onChange={handleValueBg} color={tmpData.valueBg} isIconButton atModal>
          <BackgroundColor />
        </ColorPicker>
      </InputRow>
      <InputRow disabled={!tmpData.useGlobalTheme}>
        <Label>Cabeçalho da Tabela</Label>
        <ColorPicker onChange={handleTableHeader} color={tmpData.tableHeader} isIconButton atModal>
          <BackgroundColor />
        </ColorPicker>
      </InputRow>
      <InputRow disabled={!tmpData.useGlobalTheme}>
        <Label>Linhas da Tabela</Label>
        <ColorsRow>
          <ColorPicker
            onChange={handleTableRowsFirst}
            color={tmpData.tableRows[0]}
            isIconButton
            atModal
          >
            <BackgroundColor />
          </ColorPicker>
          <ColorPicker
            onChange={handleTableRowsSecond}
            color={tmpData.tableRows[1]}
            isIconButton
            atModal
          >
            <BackgroundColor />
          </ColorPicker>
        </ColorsRow>
      </InputRow>
    </>
  );
};

ThemeStyle.propTypes = {
  tmpData: PropTypes.shape({
    useGlobalTheme: PropTypes.bool,
    titles: PropTypes.string,
    charts: PropTypes.arrayOf(PropTypes.string),
    insightBg: PropTypes.string,
    insightTxt: PropTypes.string,
    valueBg: PropTypes.string,
    valueTxt: PropTypes.string,
    tableRows: PropTypes.arrayOf(PropTypes.string),
    tableHeader: PropTypes.string,
  }).isRequired,
  handler: PropTypes.func.isRequired,
  timedHandler: PropTypes.func.isRequired,
};

export default ThemeStyle;
