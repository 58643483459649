import styled from 'styled-components';

export const PaginationContainer = styled.div`
  margin-top: 5px;
  display: flex;
  flex-direction: row;
  user-select: none;
  justify-content: flex-start;
  align-items: center;
  gap: 40px;
  padding: .2rem 0 .5rem;
  white-space: nowrap;

  & .invert svg {
    transform: rotate(180deg);
  }
`;

export const ArrowBtns = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;
