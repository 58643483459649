import React from 'react';

const AddStory = () => {
    return (
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.5 6.15601H5V9.15601H9.5V6.15601Z" />
            <path d="M0.5 4.65625C0.5 3.46278 0.974106 2.31818 1.81802 1.47427C2.66193 0.630356 3.80653 0.15625 5 0.15625H14V21.1562H5C3.80653 21.1562 2.66193 20.6821 1.81802 19.8382C0.974106 18.9943 0.5 17.8497 0.5 16.6562V4.65625ZM3.5 6.15625V9.15625C3.5 9.55407 3.65804 9.93561 3.93934 10.2169C4.22064 10.4982 4.60218 10.6562 5 10.6562H9.5C9.89782 10.6562 10.2794 10.4982 10.5607 10.2169C10.842 9.93561 11 9.55407 11 9.15625V6.15625C11 5.75843 10.842 5.37689 10.5607 5.09559C10.2794 4.81429 9.89782 4.65625 9.5 4.65625H5C4.60218 4.65625 4.22064 4.81429 3.93934 5.09559C3.65804 5.37689 3.5 5.75843 3.5 6.15625ZM4.25 12.1562C4.05109 12.1562 3.86032 12.2353 3.71967 12.3759C3.57902 12.5166 3.5 12.7073 3.5 12.9062C3.5 13.1052 3.57902 13.2959 3.71967 13.4366C3.86032 13.5772 4.05109 13.6562 4.25 13.6562H10.25C10.4489 13.6562 10.6397 13.5772 10.7803 13.4366C10.921 13.2959 11 13.1052 11 12.9062C11 12.7073 10.921 12.5166 10.7803 12.3759C10.6397 12.2353 10.4489 12.1562 10.25 12.1562H4.25ZM3.5 15.9062C3.5 16.1052 3.57902 16.2959 3.71967 16.4366C3.86032 16.5772 4.05109 16.6562 4.25 16.6562H10.25C10.4489 16.6562 10.6397 16.5772 10.7803 16.4366C10.921 16.2959 11 16.1052 11 15.9062C11 15.7073 10.921 15.5166 10.7803 15.3759C10.6397 15.2353 10.4489 15.1562 10.25 15.1562H4.25C4.05109 15.1562 3.86032 15.2353 3.71967 15.3759C3.57902 15.5166 3.5 15.7073 3.5 15.9062Z"/>
            <path d="M15.5 21.1562H17C18.1935 21.1562 19.3381 20.6821 20.182 19.8382C21.0259 18.9943 21.5 17.8498 21.5 16.6562V15.1562H15.5V21.1562Z"/>
            <path d="M21.5 13.6562V7.65625H15.5V13.6562H21.5Z"/>
            <path d="M21.5 6.15625V4.65625C21.5 3.46278 21.0259 2.31818 20.182 1.47427C19.3381 0.630356 18.1935 0.15625 17 0.15625H15.5V6.15625H21.5Z"/>
        </svg>

    );
};

export default AddStory;