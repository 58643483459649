import React from 'react';
import PropTypes from 'prop-types';

import { ContainerRow, Container } from './styled';

function ConfirmCopyToMe({ dashName, dashOwner }) {
  return (
    <Container>
      <ContainerRow>
        Todas as informações (KPI's) de
        {' '}
        <strong>
          {dashName}
        </strong>
        {' '}
        do usuário
        {' '}
        <strong>
          {dashOwner}
        </strong>
        {' '}
        serão copiadas.
      </ContainerRow>

      <ContainerRow>
        O novo dashboard será renomeado para:
        {' '}
        <strong>
          Clone de
        </strong>
        {' '}
        <strong>
          {dashName}
        </strong>
        {' '}
        .
      </ContainerRow>

      <ContainerRow>
        Tem certeza que deseja continuar?
      </ContainerRow>
    </Container>
  );
}

ConfirmCopyToMe.propTypes = {
  dashName: PropTypes.string.isRequired,
  dashOwner: PropTypes.string.isRequired,
};

export default ConfirmCopyToMe;
