import styled from 'styled-components';

export const FooterContainer = styled.div`
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  z-index: 100;
`;

export const ActionButtons = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const Link = styled.a`
  text-decoration-line: underline;
  color: ${({ theme }) => theme.secondary};;
  font-weight: bold;
  margin: auto;
  font-size: 10pt;
  display: block;
  text-align: center;
`;

export const SupportContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  user-select: none;
  padding: .5rem 1rem 1rem 1rem;
  & label {
    top: -4px;
    font-size: 1rem;
  }
  & .select-label {
    font-size: 1rem !important;
  }
`;

export const Title = styled.h5`
  font-size: 20px;
  margin: 0 0 .5rem 0;
  color: ${({ theme }) => theme.primary}
`;
