import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import ActionsGroup from '../../../juristec-ui/core/ActionsGroup';
import Button from '../../../juristec-ui/core/Button';
import InputTextLine from '../../../juristec-ui/core/InputTextLine';
import Select from '../../../juristec-ui/core/SelectNew';
import DatePicker from '../../../juristec-ui/core/DatePicker';

import {
  MainContainer,
  InfoText,
  TabGroup,
  TabButton,
  FrequencyContainer,
  ScheduleFrequency,
  WarningMessage,
} from './styled/CreateFileProvision.styled';

import { verifyInput } from '../../../juristec-ui/utils/validators/inputTextValidators';
import { scheduleOptions, weekDayOptions, monthDayOptions } from '../../../juristec-ui/utils/cronOptions';

/** Transfers all allowed data from one user to another */
const CreateFileProvision = ({
  type, filename, close, submitNow, submitSchedule, isDesktopFile,
}) => {
  const [tag, setTag] = useState({ value: '', error: true, errorMsg: '' });
  const [tab, setTab] = useState('now');
  const [frequency, setFrequency] = useState({ label: 'Mensal', value: 'monthly' });
  const [weekDays, setWeekDays] = useState([]);
  const [monthDays, setMonthDays] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [cronObject, setCronObject] = useState({ value: '* * * * *', text: 'Todos os dias' });

  const handleTag = (value) => {
    const msg = verifyInput(value, true);
    setTag({
      value,
      error: msg.length !== 0,
      errorMsg: msg,
    });
  };

  const handleSubmit = () => {
    if (tab === 'now') {
      submitNow(tag.value);
      return;
    }
    submitSchedule(cronObject.value);
  };

  const handleChangeFrequency = (val) => {
    setWeekDays([]);
    setMonthDays([]);
    setStartDate(new Date());
    setFrequency(val);
  };

  const numberToMonth = (number) => {
    const meses = [
      'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
      'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro',
    ];
    return meses[number - 1];
  };

  const getCron = (freq) => {
    const cron = { value: '* * * * *', text: '' };
    switch (freq.value) {
      case 'weekly':
        if (weekDays.length > 0) {
          const weekCron = weekDays.sort((a, b) => a.cron - b.cron).map((day) => day.cron).join(',');
          const weekText = weekDays.sort((a, b) => a.cron - b.cron).map((day) => day.label);
          return ({
            value: `* * * * ${weekCron}`,
            text: weekText.length > 1
              ? `Toda ${weekText.slice(0, -1).join(', ')} e ${weekText[weekText.length - 1]}.`
              : `Toda ${weekText[0]}.`,
          });
        }
        return cron;

      case 'monthly':
        if (monthDays.length > 0) {
          const monthCron = monthDays.sort((a, b) => a.cron - b.cron).map((day) => day.cron).join(',');
          const monthText = monthDays.sort((a, b) => a.cron - b.cron).map((day) => day.label);
          return ({
            value: `* * ${monthCron} * *`,
            text: monthText.length > 1
              ? `Todos os dias ${monthText.slice(0, -1).join(', ')} e ${monthText[monthText.length - 1]} de cada mês.`
              : `Todo dia ${monthText[0]} de cada mês.`,
          });
        }
        return cron;

      case 'quarterly':
        const quarterlyCron = [0,1,2,3].map((i) => {
          const month = (startDate.getMonth() + 1 + 3*i) % 12;
          return month === 0 ? 12 : month;
        }).sort((a, b) => a - b);
        const quarterlyText = quarterlyCron.map((i) => numberToMonth(i));
        return ({
          value: `* * ${startDate.getDate()} ${quarterlyCron.join(',')} *`,
          text: `Todo dia ${startDate.getDate()} dos meses ${quarterlyText.slice(0, -1).join(', ')} e ${quarterlyText[quarterlyText.length - 1]}.`,
        });

      case 'semiannual':
        const semiannualCron = [0,1].map((i) => {
          const month = (startDate.getMonth() + 1 + 6*i) % 12;
          return month === 0 ? 6 : month;
        }).sort((a, b) => a - b);
        const semiannualText = semiannualCron.map((i) => numberToMonth(i));
        return ({
          value: `* * ${startDate.getDate()} ${semiannualCron.join(',')} *`,
          text: `Todo dia ${startDate.getDate()} dos meses ${semiannualText.slice(0, -1).join(', ')} e ${semiannualText[semiannualText.length - 1]}.`,
        });

      case 'yearly':
        return ({
          value: `* * ${startDate.getDate()} ${startDate.getMonth() + 1} *`,
          text: `Todo dia ${startDate.getDate()} de ${numberToMonth(startDate.getMonth() + 1)}.`,
        });

      default:
        return { ...cron, text: 'Todo dia' };
    }
  };

  useEffect(() => {
    const cron = getCron(frequency);
    setCronObject(cron);
  }, [frequency, weekDays, monthDays, startDate]);

  return (
    <MainContainer>
      <TabGroup>
        <TabButton active={tab === 'now'} onClick={() => setTab('now')}>
          <span>Agora</span>
        </TabButton>
        <TabButton active={tab === 'schedule'} onClick={() => setTab('schedule')} disabled={isDesktopFile}>
          <span>Agendar</span>
        </TabButton>
      </TabGroup>
      <div style={{ minHeight: '20vh', minWidth: '500px' }}>
        {tab === 'now' && (
          <>
            <InfoText>
              {type === 'create'
                ? 'Insira um rótulo para criar os dados de histórico do arquivo'
                : 'Insira um rótulo para atualizar os dados de histórico do arquivo'}
              {' '}
              <b>
                {filename}
              </b>
            </InfoText>
            <InputTextLine
              label="Nome do rótulo"
              errorMessage={tag.errorMsg}
              error={tag.errorMsg.length > 0}
              onChange={(e) => handleTag(e.target.value)}
              value={tag.value}
            />
          </>
        )}
        {tab === 'schedule' && (
          <>
            <InfoText>
              {type === 'create'
                ? 'Determine a frequência para criar os dados de histórico do arquivo'
                : 'Determine a frequência para atualizar os dados de histórico do arquivo'}
              {' '}
              <b>
                {filename}
              </b>
            </InfoText>
            <FrequencyContainer>
              <div style={{ width: '50%' }}>
                <Select
                  label="Frequência"
                  placeholder="Selecione a frequência"
                  options={scheduleOptions}
                  value={frequency}
                  onChange={handleChangeFrequency}
                  atModal
                />
              </div>
              {frequency.value === 'weekly' && (
                <div style={{ width: '50%' }}>
                  <Select
                    label="Dias da semana"
                    placeholder="Selecione o(s) dia(s) da semana"
                    options={weekDayOptions}
                    value={weekDays}
                    onChange={setWeekDays}
                    atModal
                    multiple
                    stayOpen
                  />
                </div>
              )}
              {frequency.value === 'monthly' && (
                <div style={{ width: '50%' }}>
                  <Select
                    label="Dias do mês"
                    placeholder="Selecione o(s) dia(s) do mês"
                    options={monthDayOptions}
                    value={monthDays}
                    onChange={setMonthDays}
                    atModal
                    multiple
                    stayOpen
                  />
                </div>
              )}
              {(frequency.value === 'quarterly' || frequency.value === 'semiannual' || frequency.value === 'yearly') && (
                <div style={{ width: '50%' }}>
                  <DatePicker
                    value={startDate}
                    startDate={new Date()}
                    onChange={setStartDate}
                    label="Data de Início"
                    readOnly={false}
                    dateFormat="dd/MM/yyyy"
                    atModal
                  />
                </div>
              )}
            </FrequencyContainer>
            {cronObject.text !== '' && (
              <>
                <div style={{ marginTop: '10px' }}>
                  <ScheduleFrequency>
                    <strong>Agendamento: </strong>
                  </ScheduleFrequency>
                  <span>
                    {`"${cronObject.text}"`}
                  </span>
                </div>
                <div style={{ marginTop: '10px' }}>
                  <WarningMessage>
                    <strong>Atenção: </strong>
                  </WarningMessage>
                  <span>
                    O agendamento do histórico somente será executado caso a base seja atualizada
                    corretamente nos dias de agendamento.
                  </span>
                </div>
              </>
            )}
          </>
        )}
      </div>
      <ActionsGroup>
        <Button
          style={{ margin: '5px' }}
          onClick={close}
          variant="outlined"
          size="large"
        >
          Cancelar
        </Button>
        <Button
          style={{ margin: '5px' }}
          onClick={handleSubmit}
          size="large"
          disabled={tab === 'now' ? tag.error : cronObject.text === ''}
        >
          Criar
        </Button>
      </ActionsGroup>
    </MainContainer>
  );
};

CreateFileProvision.propTypes = {
  type: PropTypes.string,
  filename: PropTypes.string,
  close: PropTypes.func.isRequired,
  submitNow: PropTypes.func,
  submitSchedule: PropTypes.func,
  isDesktopFile: PropTypes.bool,
};

CreateFileProvision.defaultProps = {
  type: 'create',
  filename: '',
  submitNow: () => {},
  submitSchedule: () => {},
  isDesktopFile: false,
};

export default CreateFileProvision;
