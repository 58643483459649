class Transaction {
  /**
   * @constructor
   * >### Encapsulates firestore "Transaction" class for compatibility
   * * * *
   * A reference to a transaction.
   *
   * The `Transaction` object passed to a transaction's `updateFunction` provides
   * the methods to read and write data within the transaction context.
   */
  constructor(transaction) {
    this.transaction = transaction;
  }

  /**
   * >### Wrapper of firestore `transaction.get` method
   * * * *
   * Reads the document referenced by the provided `DocumentReference`.
   *
   * @param {DocumentReference<any>} docRef - A reference to the document to be read.
   * @returns {Promise<DocumentSnapshot<any>>} A `DocumentSnapshot` with the read data.
   */
  get(docRef) {
    return this.transaction.get(docRef.ref);
  }

  /**
   * >### Wrapper of firestore `transaction.set` method
   * * * *
   * Writes to the document referred to by the provided `DocumentReference`.
   * If the document does not exist yet, it will be created. If you provide `merge`
   * or `mergeFields`, the provided data can be merged into an existing document.
   *
   * @param {DocumentReference<any>} docRef A reference to the document to be set.
   * @param {object} data An object of the fields and values for the document.
   * @param {object} options An object to configure the set behavior.
   * @throws Error - If the provided input is not a valid Firestore document.
   * @returns {Transaction} This `Transaction` instance.
   */
  set(docRef, data, options = {}) {
    return this.transaction.set(docRef.ref, data, options);
  }

  /**
   * >### Wrapper of firestore `transaction.update` method
   * * * *
   * Updates fields in the document referred to by the provided `DocumentReference`.
   * The update will fail if applied to a document that does not exist.
   *
   * @param {DocumentReference<any>} docRef A reference to the document to be updated.
   * @param {object} data An object containing the fields and values with which to
   * update the document. Fields can contain dots to reference nested fields
   * within the document.
   * @throws Error - If the provided input is not valid Firestore data.
   * @returns {Transaction} This `Transaction` instance.
   */
  update(docRef, data) {
    return this.transaction.update(docRef.ref, data);
  }

  /**
   * >### Wrapper of firestore `transaction.delete` method
   * * * *
   * Deletes the document referred to by the provided `DocumentReference`.
   *
   * @param {DocumentReference<any>} docRef A reference to the document to be deleted.
   * @returns {Transaction} This `Transaction` instance.
   */
  delete(docRef) {
    return this.transaction.delete(docRef.ref);
  }
}

export default Transaction;
