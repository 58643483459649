import styled from 'styled-components';

const StyledActionsGroup = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding: 10px 0px;
`;

export default StyledActionsGroup;
