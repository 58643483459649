/* eslint-disable react/prop-types */
/* eslint-disable react/forbid-prop-types */
import React, {
  useMemo, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';

import { useTheme } from 'styled-components';
import { useHistory } from 'react-router-dom';
import urlHash from '../../utils/functions/urlHash';

import DataTable from '../../juristec-ui/core/DataTable';
import Select from '../../juristec-ui/core/SelectNew';
import TablePagination from '../../juristec-ui/core/TablePagination';
import Tooltip from '../../juristec-ui/core/Tooltip';
import IconButton from '../../juristec-ui/core/IconButton';

import { ExpandMore, ArrowRight, NoFileDashboard } from '../../juristec-ui/icons';

import {
  MainContainer,
  TableContainer,
  TableOverFlow,
  IconFlipWrapper,
  FooterContainer,
  CollapsibleContainer,
  ExtraInfos,
  Options,
} from './styled/PublicationsTable.styled';

import { formatDateTime } from '../../juristec-ui/utils/functions/lab';

const ContentContainer = ({ open, extraData }) => (
  <CollapsibleContainer control={open}>
    <ExtraInfos>
      <p>{extraData}</p>
    </ExtraInfos>
  </CollapsibleContainer>
);

const OpenProcess = ({ cnj, blockId }) => {
  const history = useHistory();

  const handleOpen = (e) => {
    e.stopPropagation();
    history.push(`/bigdata/${urlHash(`${cnj}/${blockId}`)}`);
  };

  return (
    <Options>
      <Tooltip text="Visualizar detalhes">
        <IconButton variant="pattern" shape="rounded" color="primary" onClick={handleOpen}>
          <ArrowRight />
        </IconButton>
      </Tooltip>
    </Options>
  );
};

const PublicationsTable = ({
  rawData,
  paginationState,
  isTabletSize,
  children,
}) => {
  const theme = useTheme();

  const [rowData, setRowData] = useState([]);
  const [showExtra, setShowExtra] = useState({});
  const [pagination, setPagination] = paginationState;

  const handleExtraContent = (toggle, key) => {
    setShowExtra((old) => ({
      ...old,
      [key]: toggle,
    }));
  };

  const columns = useMemo(() => [
    {
      field: 'flipper',
      label: '',
      valueGetter: (param) => (
        <IconFlipWrapper flip={param.showMoreContent}>
          <ExpandMore />
        </IconFlipWrapper>
      ),
    },
    {
      field: 'processo',
      label: 'CNJ',
    },
    {
      field: 'tribunal',
      label: 'Tribunal',
      valueGetter: (param) => (
        <span style={{ textTransform: 'uppercase' }}>{param.tribunal}</span>
      ),
    },
    {
      field: 'caderno',
      label: 'Caderno',
      valueGetter: (param) => (
        <span style={{ textTransform: 'capitalize' }}>{param.caderno}</span>
      ),
    },
    {
      field: 'edicao',
      label: 'Edição',
    },
    {
      field: 'publicacao',
      label: 'Publicação',
      valueGetter: (param) => (param.publicacao ? formatDateTime(`${param.publicacao}`) : '-'),
    },
    {
      field: 'created_in',
      label: 'Data de criação',
      valueGetter: (param) => (param.created_in ? formatDateTime(`${param.created_in}`) : '-'),
    },
    {
      field: 'options',
      label: '',
      valueGetter: (param) => <OpenProcess cnj={param.processo} blockId={param.bloco_id} />,
    },
  ], [rawData]);

  useEffect(() => {
    if (rawData?.results?.length > 0) {
      setRowData(rawData.results.reduce((aux, dt) => {
        aux.push({
          ...dt,
          moreContent: <ContentContainer open={!!showExtra[dt.id]} extraData={dt.bloco_processo} />,
          showMoreContent: !!showExtra[dt.id],
          toggleMoreContent: (toggle) => handleExtraContent(toggle, dt.id),
        });
        return aux;
      }, []));
    }
  }, [rawData?.results, pagination.page, showExtra]);

  const handlePage = (selected) => {
    const pg = typeof selected === 'function' ? selected(pagination.page) : selected;
    setPagination({ page: pg }, false);
  };

  const handleQty = (selected) => {
    setPagination({ page: 0, qty: selected.value }, true);
  };

  return (
    <MainContainer>
      <TableContainer>
        {rowData.length > 0 ? (
          <>
            <TableOverFlow>
              <DataTable
                columns={columns}
                rowData={rowData || []}
                headerColor="transparent"
                rowColor={theme.tableBackground}
                strippedRowsColor={theme.containerOdd}
                theadStyle={{
                  position: 'sticky',
                  top: 0,
                  zIndex: 2,
                  backgroundColor: theme.background,
                }}
                expandRow
              />
            </TableOverFlow>
            <FooterContainer>
              <TablePagination
                page={pagination.page}
                setPage={handlePage}
                totalPages={Infinity}
              />
              {!isTabletSize && (
                <>
                  <span style={{ marginLeft: '1rem', whiteSpace: 'nowrap' }}>
                    Itens por página:
                  </span>
                  <Select
                    value={{ label: pagination.qty.toString(), value: pagination.qty }}
                    onChange={handleQty}
                    options={[
                      { label: '25', id: '25-itens', value: 25 },
                      { label: '50', id: '50-itens', value: 50 },
                      { label: '100', id: '100-itens', value: 100 },
                    ]}
                    style={{
                      marginLeft: '1rem',
                      minWidth: '65px',
                      width: '65px',
                    }}
                  />
                </>
              )}
            </FooterContainer>
          </>
        ) : (<NoFileDashboard />)}
      </TableContainer>
      {children}
    </MainContainer>
  );
};

PublicationsTable.propTypes = {
  rawData: PropTypes.shape({
    results: PropTypes.arrayOf(PropTypes.array),
    totalPages: PropTypes.number,
  }),
  paginationState: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.shape({
      page: PropTypes.number,
      qty: PropTypes.number,
    }),
    PropTypes.func,
  ])),
  isTabletSize: PropTypes.bool,
  children: PropTypes.node,
};

PublicationsTable.defaultProps = {
  rawData: {},
  paginationState: [{ page: 0, qty: 25 }, () => {}],
  isTabletSize: false,
  children: <></>,
};

export default PublicationsTable;
