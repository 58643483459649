import { useEffect } from 'react';
import firebase from '../utils/firebase';

/** Hook to load the recaptchaV2 badge. */
const useRecaptchaV2 = () => {
  useEffect(() => {
    const loadByFirebase = (id) => {
      const isbadgeExist = document.getElementById(id);
      if (!isbadgeExist) {
        const container = document.createElement('div');
        container.id = id;
        document.body.appendChild(container);
        window.recaptchaVerifier = firebase.getRecaptchaV2('recaptcha-v2-key');
      }
    };
    loadByFirebase('recaptcha-v2-key');
  }, []);

  /** Gets a loaded instance of recaptchaV2 verifier */
  const getVerifier = () => window.recaptchaVerifier;

  return {
    getVerifier,
  };
};

export default useRecaptchaV2;
