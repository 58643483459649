import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';

import StepProgress from '../../../juristec-ui/core/StepProgress';
import FirstStep from './Steps/FirstStep';
import SecondStep from './Steps/SecondStep';
import Multifactor from '../../../utils/multifactor';

const MfaLogin = ({
  hide, mfa, setLoading,
}) => {
  const sended = useRef(false);
  const [selectedIdx, setSelectedIdx] = useState(0);
  const [verificationId, setVerificationId] = useState(null);
  const [code, setCode] = useState('');

  /**
   * Sends a SMS with the verification code.
   * Uses the `verificationId` to confirm the SMS dispatch.
   * @param {boolean} retry Forces the SMS re-dispatch.
   */
  const sendVerification = async (retry) => {
    if (!sended.current || retry) {
      sended.current = true;
      setVerificationId(await mfa.sendSMS(mfa.hints[selectedIdx]));
    }
  };

  /** Checks the SMS code informed */
  const handleSubmit = () => {
    setLoading(true);
    mfa.resolveCode(code);
    hide();
  };

  const firstStep = {
    label: 'Escolha um telefone',
    validation: () => selectedIdx !== null,
    content: <FirstStep
      hints={mfa.hints}
      hintIndexState={[selectedIdx, setSelectedIdx]}
    />,
  };

  const secondStep = {
    label: 'Informe o código',
    validation: () => code.length > 0,
    content: <SecondStep
      selectedHint={mfa.hints[selectedIdx]}
      inputCodeState={[code, setCode]}
      sendSmsCode={sendVerification}
      captchaFinished={!!verificationId}
    />,
  };

  return (
    <StepProgress
      steps={[firstStep, secondStep]}
      startAt={mfa.hints.length > 1 ? 0 : 1}
      hide={hide}
      complete={handleSubmit}
      onlyForward
      removeMarkers
      disableUntilValid
    />
  );
};

MfaLogin.propTypes = {
  /** Hides the modal */
  hide: PropTypes.func,
  /** A multifactor object instance, with all multifactor related operations */
  mfa: PropTypes.instanceOf(Multifactor).isRequired,
  /** A state setter for 'loading' component */
  setLoading: PropTypes.func,
};

MfaLogin.defaultProps = {
  hide: () => {},
  setLoading: () => {},
};

export default MfaLogin;
