import React from "react";

// import { Container } from './styles';

function Boxplot() {
  return (
    <svg
      width="20"
      height="25"
      viewBox="0 0 20 25"
      fill="#FFF"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M15.3571 12.125C17.0803 10.9196 18.3035 8.9732 18.3035 7.14282C18.3035 3.1161 15.1875 0 11.1607 0H0V25H12.5714C16.3125 25 19.1965 21.9643 19.1965 18.2322C19.1964 15.5179 17.6518 13.2053 15.3571 12.125ZM5.35714 4.46425H10.7143C12.1964 4.46425 13.3928 5.66065 13.3928 7.14282C13.3928 8.62499 12.1964 9.82139 10.7143 9.82139H5.35714V4.46425ZM11.6071 20.5357H5.35714V15.1785H11.6072C13.0893 15.1785 14.2857 16.3749 14.2857 17.8571C14.2857 19.3393 13.0892 20.5357 11.6071 20.5357Z" />
    </svg>
  );
}

export default Boxplot;
