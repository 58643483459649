import React from 'react';
import PropTypes from 'prop-types';
import { Return } from '../../icons';
import {
  GroupContainer,
  ButtonHeader,
  ContentContainer,
  CollapsibleContainer,
} from './styled/ControllersGroup.styled';

const ControllersGroup = ({
  children, title, id, extend, setExtend, sizeIncrease, alwaysOpen,
}) => {
  const handleExtend = () => {
    if (extend === id) setExtend('');
    else setExtend(id);
  };

  return (
    <GroupContainer key={id}>
      <ButtonHeader onClick={handleExtend} flip={extend === id} alwaysOpen={alwaysOpen}>
        <span>{title}</span>
        {alwaysOpen || (
          <div className="flip-wrapper">
            <Return />
          </div>
        )}
      </ButtonHeader>
      <CollapsibleContainer
        control={extend === id}
        maxSize={(children.length + sizeIncrease) * 60 + 10}
        alwaysOpen={alwaysOpen}
      >
        <ContentContainer>
          {children}
        </ContentContainer>
      </CollapsibleContainer>
    </GroupContainer>
  );
};

ControllersGroup.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  title: PropTypes.string,
  id: PropTypes.string,
  extend: PropTypes.string,
  setExtend: PropTypes.func,
  sizeIncrease: PropTypes.number,
  alwaysOpen: PropTypes.bool,
};
ControllersGroup.defaultProps = {
  children: <></>,
  title: '',
  id: '1',
  extend: '1',
  setExtend: () => {},
  sizeIncrease: 0,
  alwaysOpen: false,
};

export default ControllersGroup;
