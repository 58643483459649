/* eslint-disable quote-props */
const ufNamesInitials = {
  'acre': 'ac',
  'alagoas': 'al',
  'amapa': 'ap',
  'amazonas': 'am',
  'bahia': 'ba',
  'ceara': 'ce',
  'distrito federal': 'df',
  'espirito santo': 'es',
  'goias': 'go',
  'maranhao': 'ma',
  'mato grosso': 'mt',
  'mato grosso do sul': 'ms',
  'minas gerais': 'mg',
  'para': 'pa',
  'paraiba': 'pb',
  'parana': 'pr',
  'pernambuco': 'pe',
  'piaui': 'pi',
  'rio de janeiro': 'rj',
  'rio grande do norte': 'rn',
  'rio grande do sul': 'rs',
  'rondonia': 'ro',
  'roraima': 'rr',
  'santa catarina': 'sc',
  'sao paulo': 'sp',
  'sergipe': 'se',
  'tocantins': 'to',
};

const getStateInitials = (val) => ufNamesInitials[val] || val;

export default getStateInitials;
