import styled, { css } from 'styled-components';
import { devices } from '../../../juristec-ui/core/breakpoints';

export const MainContainer = styled.div`
  display: grid;
  grid-gap: 0.5rem;
  grid-template-columns: 1fr 1fr;
  user-select: none;
  padding: 0 0.5rem;
  max-height: calc(100vh - 345px);
  overflow: hidden;
  @media ${devices.phoneL} {
    grid-template-columns: 1fr;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const MainColumn = styled(Column)`
  max-width: 300px;
  min-width: 300px;
  max-height: inherit;
  & > * + * {
    margin-top: 0.3rem;
  }
  @media ${devices.phoneS} {
    max-width: 100%;
    width: 100%;
  }
`;

export const ScrollContainer = styled(Column)`
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 0 .2rem;
  box-sizing: border-box;
  & > * + * {
    margin-top: 0.3rem;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const InputRow = styled(Row)`
  justify-content: space-between;
  align-items: center;
  height: 40px;
  min-height: 40px;
  max-height: 40px;
  ${({ disabled }) => disabled && css`
    opacity: 0.3;
    pointer-events: none;
    cursor: default;
  `}
`;

export const ColorsRow = styled(Row)`
  & > * + * {
    margin-left: 0.2rem;
  }
`;

export const InputColumn = styled(Column)`
  height: 55px;
  ${({ disabled }) => disabled && css`
      opacity: 0.3;
      pointer-events: none;
      cursor: default;
    `}
`;

export const TabItem = styled.button`
  background-color: ${({ theme }) => theme.containerOdd};
  color: ${({ theme }) => theme.primary};
  padding: 5px;
  border: none;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 130px;
  width: 100%;
  font-weight: bold;
  height: 30px;
  cursor: pointer;
  ${({ theme, active }) => (active ? css`
    background-color: ${theme.primary};
    color: ${theme.white};
  ` : css`
    &:hover {
      background-color: ${theme.selectHover};
    }
  `)}
`;

export const Label = styled.label`
  font-size: 0.85rem;
  color: ${({ theme }) => theme.grey};
  font-weight: bold;
`;

export const SelectButtons = styled(Row)`
  width: 100%;
  align-items: center;
  box-shadow: 0;
  border-radius: 8px;
  height: 100%;
  margin-top: 3px;
  & .choicebox-btn {
    font-size: 13px;
    min-width: auto;
    width: 100%;
    box-shadow: none;
    white-space: nowrap;
    border-width: 1px;
    border-right-width: 0;
    height: 100%;
    & svg {
      height: 18px;
    }
  }
  & .choicebox-btn:first-child {
    border-radius: 8px 0 0 8px;
  }
  & .choicebox-btn:last-child {
    border-radius: 0 8px 8px 0;
    border-right-width: 1px;
  }
`;

export const NumberControls = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 2px;
  bottom: 2px;
  & button {
    height: 14px;
    width: 20px;
    padding: 0;
    border: none;
    background-color: ${({ theme }) => theme.inputNumberControl};
    &:hover {
      background-color: ${({ theme }) => theme.inputNumberControlHover};
    }
  }
  & svg {
    height: 15px;
    width: 15px;
    fill: ${({ theme }) => theme.grey};
  }
  & .number-increase {
    transform: rotate(180deg);
  }
`;

export const PreviewContainer = styled.div`
  max-width: 300px;
  display: grid;
  grid-template-areas:
    'kpi kpi'
    'insight value'
    'table table';
  background-color: ${({ theme }) => theme.background};
  padding: 0.2rem;
  overflow: hidden;
  aspect-ratio: 3/4;
  @media ${devices.phoneL} {
    display: none;
  }
`;

const PreviewCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  position: relative;
  & i {
    font-size: 15px;
    top: 2px;
    position: absolute;
    font-style: normal;
    color: #000000;
  }
  & b {
    font-size: 20px;
    position: absolute;
  }
  & > div {
    width: 60%;
    height: 70%;
    position: absolute;
    display: grid;
    box-sizing: border-box;
    bottom: 10px;
    &.kpi {
      grid-template-columns: repeat(5, 1fr);
      grid-gap: 10px;
      border: solid ${({ theme }) => theme.grey};
      padding: 5px 10px;
      border-width: 0 0 5px 5px;
      align-items: end;
      & div:nth-child(1) {
        height: 100%;
      }
      & div:nth-child(2) {
        height: 80%;
      }
      & div:nth-child(3) {
        height: 60%;
      }
      & div:nth-child(4) {
        height: 75%;
      }
      & div:nth-child(5) {
        height: 85%;
      }
    }
    &.table {
      grid-template-rows: repeat(5, 1fr);
      & div {
        position: relative;
        background-color: #555;
        &:first-child {
          background-color: #2f2f2f;
        }
        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: 33.3%;
          width: 33.3%;
          height: 100%;
          border: solid ${({ theme }) => theme.grey};
          border-width: 0 2px;
        }
      }
      & > * + * {
        border: solid ${({ theme }) => theme.grey};
        border-width: 2px 0 0 0;
      }
    }
  }
`;

export const PreviewKpi = styled(PreviewCard)`
  grid-area: kpi;
`;
export const PreviewInsight = styled(PreviewCard)`
  grid-area: insight;
  background-color: #4711b2;
  color: #ffffff;
`;
export const PreviewValue = styled(PreviewCard)`
  grid-area: value;
  background-color: #4711b2;
  color: #ffffff;
`;
export const PreviewTable = styled(PreviewCard)`
  grid-area: table;
`;

export const ArrowIcon = styled.div`
  & svg {
    height: 15px;
    width: 15px;
    fill: ${({ theme }) => theme.grey};
  }
`;
