import React from 'react';
import PropTypes from 'prop-types';

import {
  InputRadioContainer, RadioOuterCircle, RadioInnerCircle, RadioOptionLabel,
} from './styled/InputRadio.styled';

/**
* A radio button is a quick way to extract a single coded answer from our users by presenting multiple possibilities and allowing only one option to be chosen.
*/
const InputRadio = ({
  color, text, value, onChange, selected, disabled, clickToReset, ...props
}) => (
  <InputRadioContainer
    value={value}
    onClick={() => {
      if (!disabled && value !== selected) {
        onChange(value);
      }
      if (clickToReset && value === selected) {
        onChange('');
      }
    }}
    {...props}
  >
    <RadioOuterCircle
      color={color}
      value={value}
      $selected={selected}
      disabled={disabled}
    >
      <RadioInnerCircle
        color={color}
        value={value}
        $selected={selected}
        disabled={disabled}
      />
    </RadioOuterCircle>
    <RadioOptionLabel
      value={value}
      $selected={selected}
      disabled={disabled}
    >
      {text}
    </RadioOptionLabel>
  </InputRadioContainer>
);

InputRadio.propTypes = {
  /**
    * Color of the input radio when selected. Choose from seven different colors or pass a hexadecimal color
    */
  color: PropTypes.oneOf(['primary', 'secondary', 'error', 'warning', 'success', 'info', 'dark', PropTypes.string]),
  /**
    * The text associated to that input radio
    */
  text: PropTypes.node.isRequired,
  /**
    * A function responsible to change from one input radio to another
    */
  onChange: PropTypes.func.isRequired,
  /**
    * A string/bool that must be the same of the input value when the input radio is selected. Generally this string changes accordingly to the function onChange
    */
  selected: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]),
  /**
    * The value of the input radio is a string/bool.
    *
    */
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]).isRequired,
  /**
    * A boolean to determine wheter the input radio must be shown disabled or not
    */
  disabled: PropTypes.bool,
  /**
    * A boolean to determine wheter the input radio selected again must reset the radio group
    */
  clickToReset: PropTypes.bool,
};

InputRadio.defaultProps = {
  disabled: false,
  color: 'primary',
  clickToReset: true,
  selected: '',
};

export default InputRadio;
