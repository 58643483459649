import React from 'react';

// import { Container } from './styles';

function RedirectIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path d="M0.924362 5.2945C0.872444 4.71097 0.906522 4.12323 1.02499 3.54972C1.18395 2.82948 1.49747 2.23172 1.95671 1.77268C2.59878 1.13021 3.50003 0.77801 4.63241 0.72569C5.17785 0.705043 5.72409 0.742328 6.26172 0.836743L6.3858 0.129332C5.79706 0.0260964 5.1991 -0.0143958 4.60194 0.00825579C3.28213 0.0687937 2.22092 0.491757 1.44856 1.26472C0.891087 1.82199 0.512424 2.53862 0.322191 3.39938C0.188888 4.04305 0.1504 4.70275 0.208532 5.35764L0.924362 5.2945Z" />
        <path d="M0.445801 6.86544C0.548435 7.30364 0.673119 7.73643 0.819452 8.1622L1.4992 7.93007C1.36089 7.52755 1.24282 7.11822 1.1458 6.70367L0.445801 6.86544Z" />
        <path d="M2.73061 10.6801C2.48265 10.2279 2.25513 9.77307 2.05467 9.32845L1.39978 9.62393C1.60785 10.0854 1.84379 10.5571 2.10058 11.0255L2.73061 10.6801Z" />
        <path d="M3.38196 13.0972C3.61569 13.4364 3.86466 13.7802 4.12164 14.1182C4.18178 14.1973 4.24252 14.2763 4.30386 14.3553L4.87115 13.9145C4.81141 13.8377 4.75228 13.7607 4.69374 13.6836C4.44317 13.3542 4.20122 13.0197 3.9735 12.6895L3.38196 13.0972Z" />
        <path d="M5.94355 15.2111C5.90747 15.17 5.87179 15.1291 5.83631 15.0884L5.29407 15.5597C5.33035 15.6013 5.36683 15.643 5.40352 15.6849C5.61159 15.9219 5.82829 16.161 6.04739 16.3952C6.14561 16.5002 6.24484 16.6048 6.34507 16.7091L6.86285 16.2109C6.76483 16.1091 6.66781 16.0069 6.57199 15.9044C6.3581 15.6757 6.14661 15.4424 5.94355 15.2111V15.2111Z" />
        <path d="M22.7502 14.2539L22.0925 14.5427C22.5817 15.6575 22.9096 16.7209 23.0668 17.7033C23.3632 19.5562 23.0357 21.0199 22.1192 21.9364C21.6644 22.3912 21.0736 22.7029 20.363 22.8633C19.7913 22.992 19.1432 23.0229 18.4368 22.9551C18.263 22.9385 18.0838 22.9156 17.904 22.8871L17.7917 23.5968C17.986 23.6274 18.1798 23.6521 18.3683 23.6703C18.6337 23.6958 18.8923 23.7084 19.1438 23.7084C19.6067 23.7112 20.0685 23.6629 20.521 23.5643C21.3663 23.3737 22.0749 22.997 22.6274 22.4447C23.7134 21.3587 24.1107 19.6798 23.7764 17.5901C23.6094 16.5471 23.2642 15.4247 22.7502 14.2539V14.2539Z" />
        <path d="M21.7921 12.3574L21.1681 12.7134C21.3663 13.061 21.5534 13.4114 21.7237 13.7543L22.3672 13.4348C22.1906 13.0794 21.9972 12.7168 21.7921 12.3574V12.3574Z" />
        <path d="M20.2092 11.1777L20.8029 10.7729C20.5116 10.3456 20.2045 9.9204 19.89 9.50926L19.3193 9.94546C19.626 10.3464 19.9255 10.7609 20.2092 11.1777Z" />
        <path d="M18.3636 8.76417L18.9094 8.29731C18.5731 7.90401 18.2215 7.51492 17.8643 7.14067L17.3445 7.6368C17.6929 8.00143 18.0357 8.3809 18.3636 8.76417V8.76417Z" />
        <path d="M16.7557 21.0427L16.0649 21.2404L16.4386 22.5461C15.4229 22.232 14.44 21.8209 13.5033 21.3184C12.2448 20.646 11.0481 19.864 9.92693 18.9816C9.09544 18.3304 8.30002 17.6344 7.5441 16.8969L7.04236 17.4109C7.81672 18.1666 8.63158 18.8794 9.48332 19.5465C10.638 20.4554 11.8704 21.2606 13.1663 21.9528C13.9701 22.3814 14.8052 22.7482 15.6644 23.0503L14.7593 23.3093L14.957 24.0001L17.0562 23.3993C17.2468 23.3446 17.3571 23.1457 17.3025 22.9551L16.7557 21.0427Z" />
        <path d="M7.77752 2.82784L8.47992 2.6777L8.19588 1.34847C8.98207 1.62229 9.74641 1.95505 10.4825 2.34414C11.7245 2.99983 12.9068 3.76297 14.0157 4.62514C14.5387 5.02846 15.0651 5.46625 15.5807 5.9257C15.8665 6.18108 16.1544 6.44829 16.436 6.72051C16.5583 6.83858 16.6832 6.96126 16.8071 7.08494L17.315 6.57678C17.1881 6.4499 17.0605 6.32441 16.9354 6.20353C16.6469 5.9249 16.3522 5.65087 16.059 5.38928C15.5308 4.9184 14.9909 4.46978 14.4559 4.05684C13.9131 3.63488 13.3522 3.23557 12.7727 2.85891C13.1129 2.66547 13.4544 2.48305 13.7892 2.31667L13.4697 1.67321C13.1022 1.85562 12.727 2.05648 12.3545 2.26977L12.6442 2.77512C12.0503 2.39385 11.4417 2.03844 10.818 1.70909C10.2397 1.40319 9.64417 1.13037 9.03479 0.892427L9.92341 0.702594L9.77327 -7.62939e-06L7.63801 0.456433C7.44396 0.497727 7.32028 0.688762 7.36178 0.882604L7.77752 2.82784Z" />
        <path d="M3.43469 23.7182C3.88412 23.8115 4.34196 23.8584 4.80101 23.8584C4.99826 23.8584 5.19571 23.8497 5.39295 23.8323L5.32981 23.1167C4.74648 23.1682 4.15874 23.1343 3.58504 23.0156C2.8652 22.8569 2.26723 22.5436 1.80819 22.0843C1.16572 21.4421 0.813521 20.5408 0.761202 19.4086C0.740755 18.863 0.77784 18.3169 0.872255 17.7793L0.164843 17.6548C0.0616077 18.2436 0.020915 18.8415 0.0437671 19.4387C0.104305 20.7585 0.527068 21.8195 1.30003 22.5925C1.8577 23.1499 2.57433 23.5284 3.43469 23.7182V23.7182Z" />
        <path d="M8.19692 23.2213L7.965 22.5414C7.56228 22.6797 7.15295 22.7978 6.7384 22.895L6.90077 23.5948C7.33877 23.4921 7.77136 23.3674 8.19692 23.2213V23.2213Z" />
        <path d="M10.715 21.31C10.2627 21.5577 9.80809 21.785 9.36328 21.9857L9.65875 22.6406C10.1206 22.4325 10.5919 22.1966 11.0603 21.9398L10.715 21.31Z" />
        <path d="M12.7245 20.0671L13.132 20.6589C13.4714 20.4251 13.815 20.1762 14.1531 19.919C14.2325 19.8588 14.3115 19.7981 14.3905 19.7366L13.9495 19.1695C13.8727 19.229 13.7957 19.2883 13.7185 19.3469C13.3892 19.5974 13.0546 19.8398 12.7245 20.0671V20.0671Z" />
        <path d="M16.4301 17.9934C16.5349 17.8952 16.6395 17.7959 16.7438 17.6957L16.2457 17.1779C16.1438 17.276 16.0418 17.373 15.9394 17.4688C15.7108 17.6825 15.4775 17.8942 15.2458 18.0974C15.2051 18.1333 15.1642 18.169 15.1233 18.2043L15.5942 18.7471C15.6361 18.7106 15.678 18.6741 15.7199 18.6375C15.957 18.4288 16.1961 18.2121 16.4301 17.9934V17.9934Z" />
        <path d="M23.263 6.76902C23.1798 6.72292 23.0814 6.71149 22.9898 6.73775L21.0777 7.285L21.2753 7.97577L22.5809 7.60212C22.267 8.61764 21.8558 9.60048 21.3533 10.537C20.681 11.7955 19.899 12.9922 19.0164 14.1132C18.3653 14.9447 17.6693 15.7401 16.9316 16.496L17.4458 16.9979C18.2013 16.2236 18.9143 15.4087 19.5815 14.557C20.4903 13.4023 21.2956 12.1699 21.9877 10.8738C22.4161 10.0701 22.7829 9.23504 23.085 8.37589L23.3442 9.28115L24.0348 9.0835L23.434 6.98432C23.408 6.89271 23.3464 6.81533 23.263 6.76902V6.76902Z" />
        <path d="M17.7383 0.973818C19.5909 0.677342 21.0548 1.00509 21.9713 1.92138C22.426 2.37621 22.7377 2.96696 22.898 3.67758C23.0265 4.24969 23.0576 4.89776 22.9896 5.60397C22.973 5.77737 22.9502 5.95658 22.9217 6.13679L23.6313 6.24904C23.6622 6.0544 23.6868 5.86056 23.7049 5.67253C23.78 4.89055 23.7444 4.1663 23.5988 3.51982C23.4082 2.67429 23.0315 1.96568 22.4793 1.41342C21.3932 0.327344 19.7144 -0.0699617 17.6246 0.264401C16.5818 0.431381 15.4595 0.776568 14.2888 1.29034L14.5775 1.94824C15.6922 1.45912 16.7556 1.13098 17.7383 0.973818V0.973818Z" />
        <path d="M8.79912 5.67713L8.33205 5.13109C7.93876 5.46745 7.54967 5.81906 7.17542 6.17647L7.67155 6.69605C8.03638 6.34786 8.41564 6.00488 8.79912 5.67713Z" />
        <path d="M11.2124 3.83153L10.8079 3.23778C10.3803 3.52924 9.95513 3.83634 9.54419 4.15066L9.98038 4.72136C10.3811 4.41486 10.7956 4.11558 11.2124 3.83153Z" />
        <path d="M0.842273 16.6868C0.867531 16.6868 0.892989 16.6842 0.917645 16.6788L2.86268 16.2633L2.71253 15.5607L1.38331 15.8447C1.65713 15.0585 1.98989 14.2942 2.37897 13.5583C3.03467 12.3161 3.79781 11.1338 4.65997 10.0248C5.06309 9.50225 5.50089 8.97565 5.96054 8.46008C6.21572 8.17423 6.48313 7.88637 6.75535 7.60453C6.87442 7.48145 6.9973 7.35636 7.11998 7.23368L6.61202 6.72552C6.48633 6.85121 6.36045 6.9791 6.23857 7.10539C5.96013 7.39385 5.68631 7.68892 5.42491 7.98159C4.95364 8.51019 4.50502 9.04982 4.09228 9.58484C3.20486 10.7264 2.41907 11.9438 1.74413 13.2225C1.43823 13.801 1.16561 14.3964 0.927468 15.0058L0.737635 14.1172L0.0350342 14.2673L0.491274 16.4026C0.526554 16.5681 0.672888 16.6868 0.842273 16.6868V16.6868Z" />
        <path d="M12.0202 10.7598C13.4187 10.7629 14.5545 9.77308 14.5573 8.54856C14.5601 7.32404 13.4288 6.32903 12.0302 6.32585C10.6317 6.32267 9.49592 7.31254 9.49314 8.53706C9.49036 9.76158 10.6216 10.7566 12.0202 10.7598ZM13.7914 11.318L13.461 11.3173C13.0215 11.4929 12.5326 11.5923 12.0183 11.5911C11.504 11.59 11.0176 11.4884 10.5768 11.3107L10.2465 11.31C8.77866 11.3066 7.58543 12.3466 7.58251 13.6317L7.58087 14.3523C7.57983 14.8112 8.00429 15.1846 8.52851 15.1858L15.4917 15.2016C16.0159 15.2028 16.4421 14.8314 16.4431 14.3724L16.4448 13.6519C16.4477 12.3667 15.2592 11.3214 13.7914 11.318Z" />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="24" height="24" />
        </clipPath>
      </defs>
    </svg>

  );
}

export default RedirectIcon;
