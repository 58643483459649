import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';

import uuidv4 from '../../../juristec-ui/utils/functions/randomUUID';

import {
  MainContainer,
  InfoItem,
  InfoRow,
  InfoWrapper,
  IconWrapper,
  AddressItemRow,
} from './styled/PartInfo.styled';

import { ExpandMoreWide } from '../../../juristec-ui/icons';

const Address = ({ address, idx }) => (
  <>
    <h4>{`${idx + 1}º Endereço:`}</h4>
    <InfoWrapper>
      <h5>Logradouro</h5>
      <span>{address.logradouro || '--'}</span>
    </InfoWrapper>
    <InfoWrapper>
      <h5>Número</h5>
      <span>{address.numero || '--'}</span>
    </InfoWrapper>
    <InfoWrapper>
      <h5>Bairro</h5>
      <span>{address.bairro || '--'}</span>
    </InfoWrapper>
    <InfoWrapper>
      <h5>Complemento</h5>
      <span>{address.complemento || '--'}</span>
    </InfoWrapper>
    <InfoWrapper>
      <h5>CEP</h5>
      <span>{address.cep || '--'}</span>
    </InfoWrapper>
    <InfoWrapper>
      <h5>Município</h5>
      <span>{address.municipio || '--'}</span>
    </InfoWrapper>
    <InfoWrapper>
      <h5>Estado</h5>
      <span>{address.estado || '--'}</span>
    </InfoWrapper>
  </>
);

Address.propTypes = {
  address: PropTypes.shape({
    logradouro: PropTypes.string,
    numero: PropTypes.string,
    bairro: PropTypes.string,
    complemento: PropTypes.string,
    cep: PropTypes.string,
    municipio: PropTypes.string,
    estado: PropTypes.string,
  }),
  idx: PropTypes.number,
};

Address.defaultProps = {
  address: {},
  idx: 0,
};

const typeLabelMap = {
  requerentes: 'requerente',
};

const PartInfo = ({
  info,
}) => {
  const [showAddress, setShowAddress] = useState({});

  const toggleAddress = (key) => {
    setShowAddress((r) => ({
      ...r,
      [key]: !r[key],
    }));
  };

  return (
    <MainContainer>
      <h3>{`${typeLabelMap[info.tipo] || info.tipo || 'Polo'}:`}</h3>
      <InfoItem>
        <InfoRow onClick={() => toggleAddress(info.documento)}>
          <InfoWrapper>
            <h5>Nome</h5>
            <span>{info.nome || '--'}</span>
          </InfoWrapper>
          <InfoWrapper>
            <h5>{`${info.tipo_documento || 'Documento'}`}</h5>
            <span>{info.documento || '--'}</span>
          </InfoWrapper>
          <InfoWrapper>
            <h5>Polo</h5>
            <span>{info.polo || '--'}</span>
          </InfoWrapper>
          <InfoWrapper>
            <h5>Situação</h5>
            <span>{info.situacao || '--'}</span>
          </InfoWrapper>
        </InfoRow>
        <AddressItemRow
          style={{
            maxHeight: showAddress[info.documento] ? `${(info.endereco?.length || 1) * 16}vh` : '0',
            transitionDuration: `${Math.min(0.5, (info.endereco?.length || 1) * 0.1)}s`,
          }}
        >
          {info.endereco?.map((address, i) => (
            <Address
              key={uuidv4()}
              address={address}
              idx={i}
            />
          ))}
        </AddressItemRow>
        <InfoRow
          onClick={() => toggleAddress(info.documento)}
          style={{ alignItems: 'center', justifyContent: 'center', padding: '0' }}
        >
          <IconWrapper
            style={{ transform: showAddress[info.documento] ? 'rotate(180deg)' : 'rotate(0deg)' }}
          >
            <ExpandMoreWide />
          </IconWrapper>
        </InfoRow>
      </InfoItem>
      <div>
        {info.representantes?.map((rep, i) => (
          <Fragment key={rep.documento}>
            <h3>{`${i + 1}º Representante:`}</h3>
            <InfoItem>
              <InfoRow onClick={() => toggleAddress(rep.documento)}>
                <InfoWrapper>
                  <h5>Tipo</h5>
                  <span>{rep.tipo?.toLowerCase() || '--'}</span>
                </InfoWrapper>
                <InfoWrapper>
                  <h5>Nome</h5>
                  <span>{rep.nome || '--'}</span>
                </InfoWrapper>
                <InfoWrapper>
                  <h5>{`${rep.tipo_documento || 'Documento'}`}</h5>
                  <span>{rep.documento || '--'}</span>
                </InfoWrapper>
                <InfoWrapper>
                  <h5>Inscrição</h5>
                  <span>{rep.numero_inscricao || '--'}</span>
                </InfoWrapper>
              </InfoRow>
              <AddressItemRow
                style={{
                  maxHeight: showAddress[rep.documento] ? `${(rep.endereco?.length || 1) * 16}vh` : '0',
                  transitionDuration: `${Math.min(0.5, (rep.endereco?.length || 1) * 0.1)}s`,
                }}
              >
                {rep.endereco?.map((address, j) => (
                  <Address
                    key={uuidv4()}
                    address={address}
                    idx={j}
                  />
                ))}
              </AddressItemRow>
              <InfoRow
                onClick={() => toggleAddress(rep.documento)}
                style={{ alignItems: 'center', justifyContent: 'center', padding: '0' }}
              >
                <IconWrapper
                  style={{ transform: showAddress[rep.documento] ? 'rotate(180deg)' : 'rotate(0deg)' }}
                >
                  <ExpandMoreWide />
                </IconWrapper>
              </InfoRow>
            </InfoItem>
          </Fragment>
        ))}
      </div>
    </MainContainer>
  );
};

PartInfo.propTypes = {
  info: PropTypes.shape({
    tipo: PropTypes.string,
    nome: PropTypes.string,
    tipo_documento: PropTypes.string,
    documento: PropTypes.string,
    polo: PropTypes.string,
    situacao: PropTypes.string,
    endereco: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
    representantes: PropTypes.arrayOf(PropTypes.shape({
      tipo: PropTypes.string,
      nome: PropTypes.string,
      tipo_documento: PropTypes.string,
      documento: PropTypes.string,
      numero_inscricao: PropTypes.string,
      endereco: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
    })),
  }),
};

PartInfo.defaultProps = {
  info: {},
};

export default PartInfo;
