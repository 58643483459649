import styled from 'styled-components';
import { devices } from '../../../../juristec-ui/core/breakpoints';

export const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 1rem 5%;
  height: 3rem;
  /* ${('30px', 'row')} */
  align-items: center;
  & .left {
    width: 100%;
    flex-shrink: 2;
    position: relative;
    & span {
      position: absolute;
      font-size: 12px;
      font-weight: bold;
      margin-left: 20px;
      color: ${({ theme }) => theme.color};
    }
  }
  & .right {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    max-width: 330px;
    flex-grow: 5;
    flex-shrink: 1;
  }
  @media ${devices.tablet} {
    padding: 10px 20px;
    & .right {
      max-width: 270px;
    }
  }
  @media ${devices.phoneS} {
    /* ${('10px', 'row')} */
    & .right {
      width: auto;
    }
  }
`;

export const ItensContainer = styled.div`
  display: flex;
  flex-direction: row;
  /* ${('10px', 'row')} */
  align-items: center;
  height: 100%;
`;
