import React from 'react';

const Dashboard = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.67857 18.7143H8.20828L5.58241 11.4357C5.53916 11.3086 5.45719 11.1983 5.34802 11.1201C5.23886 11.042 5.10796 11 4.97371 11H4.02629C3.89204 11 3.76114 11.042 3.65198 11.1201C3.54281 11.1983 3.46084 11.3086 3.41759 11.4357L0.791719 18.7143H0.321429C0.23618 18.7143 0.154424 18.7481 0.0941443 18.8084C0.0338647 18.8687 0 18.9505 0 19.0357L0 19.6786C0 19.7638 0.0338647 19.8456 0.0941443 19.9059C0.154424 19.9661 0.23618 20 0.321429 20H2.89286C2.97811 20 3.05986 19.9661 3.12014 19.9059C3.18042 19.8456 3.21429 19.7638 3.21429 19.6786V19.0357C3.21429 18.9505 3.18042 18.8687 3.12014 18.8084C3.05986 18.7481 2.97811 18.7143 2.89286 18.7143H2.49951L2.96759 17.4286H6.03241L6.50049 18.7143H6.10714C6.02189 18.7143 5.94014 18.7481 5.87986 18.8084C5.81958 18.8687 5.78571 18.9505 5.78571 19.0357V19.6786C5.78571 19.7638 5.81958 19.8456 5.87986 19.9059C5.94014 19.9661 6.02189 20 6.10714 20H8.67857C8.76382 20 8.84558 19.9661 8.90586 19.9059C8.96614 19.8456 9 19.7638 9 19.6786V19.0357C9 18.9505 8.96614 18.8687 8.90586 18.8084C8.84558 18.7481 8.76382 18.7143 8.67857 18.7143ZM3.55279 15.8214L4.5 13.2201L5.44721 15.8214H3.55279Z" />
      <path d="M16.85 5.5H17.525C17.6375 5.5 17.75 5.3875 17.75 5.275V2.9125C17.75 2.8 17.6375 2.6875 17.525 2.6875H16.85C16.7375 2.6875 16.625 2.8 16.625 2.9125V5.275C16.625 5.3875 16.7375 5.5 16.85 5.5ZM18.5375 5.5H19.2125C19.325 5.5 19.4375 5.3875 19.4375 5.275V1.225C19.4375 1.1125 19.325 1 19.2125 1H18.5375C18.425 1 18.3125 1.1125 18.3125 1.225V5.275C18.3125 5.3875 18.425 5.5 18.5375 5.5ZM13.475 5.5H14.15C14.2625 5.5 14.375 5.3875 14.375 5.275V4.0375C14.375 3.925 14.2625 3.8125 14.15 3.8125H13.475C13.3625 3.8125 13.25 3.925 13.25 4.0375V5.275C13.25 5.3875 13.3625 5.5 13.475 5.5ZM15.1625 5.5H15.8375C15.95 5.5 16.0625 5.3875 16.0625 5.275V1.7875C16.0625 1.675 15.95 1.5625 15.8375 1.5625H15.1625C15.05 1.5625 14.9375 1.675 14.9375 1.7875V5.275C14.9375 5.3875 15.05 5.5 15.1625 5.5ZM19.7188 6.625H12.125V1.28125C12.125 1.12586 11.9991 1 11.8438 1H11.2812C11.1259 1 11 1.12586 11 1.28125V7.1875C11 7.49811 11.2519 7.75 11.5625 7.75H19.7188C19.8741 7.75 20 7.62414 20 7.46875V6.90625C20 6.75086 19.8741 6.625 19.7188 6.625Z" />
      <path d="M4.98081 0V4.48332C4.98081 4.7581 4.7581 4.98081 4.48332 4.98081H0C0.093164 5.82624 0.423315 6.62815 0.952406 7.29411C1.4815 7.96007 2.18799 8.46298 2.99047 8.74489C3.79295 9.02679 4.65875 9.07621 5.4881 8.88745C6.31744 8.69869 7.07657 8.27943 7.678 7.678C8.27943 7.07657 8.69869 6.31744 8.88745 5.4881C9.07621 4.65875 9.02679 3.79295 8.74489 2.99047C8.46298 2.18799 7.96007 1.4815 7.29411 0.952406C6.62815 0.423315 5.82624 0.093164 4.98081 0ZM3.98881 0C2.96968 0.11279 2.01935 0.569289 1.29432 1.29432C0.569289 2.01935 0.11279 2.96968 0 3.98881H3.98881V0Z" />
      <path d="M19.8527 14.0156L19.9962 13.212C20.0226 13.0643 19.909 12.9286 19.7589 12.9286H18.256L18.5498 11.2834C18.5761 11.1357 18.4625 11 18.3124 11H17.4962C17.4396 11 17.3848 11.0199 17.3414 11.0562C17.298 11.0926 17.2688 11.143 17.2589 11.1987L16.9499 12.9286H14.9684L15.2622 11.2834C15.2886 11.1357 15.175 11 15.0249 11H14.2086C14.152 11 14.0973 11.0199 14.0539 11.0562C14.0105 11.0926 13.9813 11.143 13.9713 11.1987L13.6624 12.9286H12.0734C12.0168 12.9286 11.962 12.9485 11.9187 12.9848C11.8753 13.0211 11.846 13.0716 11.8361 13.1273L11.6926 13.9308C11.6662 14.0786 11.7798 14.2143 11.9299 14.2143H13.4328L12.9736 16.7857H11.3846C11.328 16.7857 11.2733 16.8056 11.2299 16.8419C11.1865 16.8783 11.1573 16.9287 11.1473 16.9844L11.0038 17.788C10.9774 17.9357 11.091 18.0714 11.2411 18.0714H12.744L12.4502 19.7166C12.4239 19.8643 12.5375 20 12.6876 20H13.5038C13.5604 20 13.6152 19.9801 13.6586 19.9438C13.702 19.9074 13.7312 19.857 13.7411 19.8013L14.0501 18.0714H16.0316L15.7378 19.7166C15.7114 19.8643 15.825 20 15.9751 20H16.7914C16.848 20 16.9028 19.9801 16.9461 19.9438C16.9895 19.9074 17.0187 19.857 17.0287 19.8013L17.3376 18.0714H18.9266C18.9832 18.0714 19.038 18.0515 19.0813 18.0152C19.1247 17.9789 19.154 17.9284 19.1639 17.8727L19.3074 17.0692C19.3338 16.9214 19.2202 16.7857 19.0701 16.7857H17.5672L18.0264 14.2143H19.6154C19.672 14.2143 19.7267 14.1944 19.7701 14.158C19.8135 14.1217 19.8427 14.0713 19.8527 14.0156ZM16.2612 16.7857H14.2797L14.7389 14.2143H16.7203L16.2612 16.7857Z" />
    </svg>
  );
};

// function Dashboard() {
//   return (
//     <svg width="24" height="24" viewBox="0 0 24 24" fill="white" xmlns="http://www.w3.org/2000/svg">
//       <path d="M2 5V19H8V5H2ZM9 5V10H15V5H9ZM16 5V14H22V5H16ZM9 11V19H15V11H9ZM16 15V19H22V15H16Z" />
//     </svg>
//   );
// }

export default Dashboard;
