import React from 'react';
import PropTypes from 'prop-types';
import Loading from '../MiniLoading';
import { StyledIconButton, LoadingContainer } from './styled/IconButton.styled';

// import { Container } from './styles';

/**
 * An icon button is used when some functionality is fully understandable through a simple icon
*/
function IconButton({
  color,
  variant,
  shape,
  size,
  children,
  useDynamicColor,
  isLoading,
  disabled,
  ...props
}) {
  return (
    <StyledIconButton
      color={color}
      variant={variant}
      shape={shape}
      size={size}
      useDynamicColor={useDynamicColor}
      disabled={disabled || isLoading}
      {...props}
    >
      {isLoading ? (
        <LoadingContainer size={size}>
          <Loading fill={variant === 'contained' ? 'white' : 'disabled'} />
        </LoadingContainer>
      ) : children}
    </StyledIconButton>
  );
}

IconButton.propTypes = {
  /**
  * Color of the icon button. Choose from seven different options or pass a hex color.
  */
  color: PropTypes.oneOfType([PropTypes.oneOf(['primary', 'secondary', 'error', 'success', 'warning', 'info', 'dark']), PropTypes.string]),
  /**
  * Variant of the icon button. Choose among three variants.
  */
  variant: PropTypes.oneOf(['contained', 'outlined', 'pattern']),
  /**
  * Shape of the button. Choose among three shapes.
  */
  shape: PropTypes.oneOf(['square', 'rounded', 'circle']),
  /**
  * Size of the button. Choose among three sizes.
  */
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  /**
 * Accepts a simple text, a function, an object or a javascript node.
 */
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.node,
    PropTypes.func,
  ]).isRequired,
  /**
   * A function to perform some action when the user clicks the button 
   */
  onClick: PropTypes.func,
  /**
   * Decides if text color can change based on background color (only in contained button)
   */
  useDynamicColor: PropTypes.bool,
  /**
   * if isLoading is true, the iconbutton loads until it is ready to be displayed
   */
  isLoading: PropTypes.bool,
  /**
   * check if the iconbutton is disabled or not
   */
  disabled: PropTypes.bool,
};

IconButton.defaultProps = {
  color: 'primary',
  variant: 'pattern',
  shape: 'circle',
  size: 'medium',
  useDynamicColor: false,
  onClick: () => {},
  isLoading: false,
  disabled: false,
};

export default IconButton;
