import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { format } from 'date-fns';
import useToggleState from '../../juristec-ui/hooks/useToggleState';

import IconButton from '../../juristec-ui/core/IconButton';
import FilterItem from '../BigDataFilter';

import { poloOptions, orgaoOptions, ufOptions } from '../../options';

import AppliedCard from './styled/BigDataFilterApplied.styled';

import {
  Cancel, Success, Edit, Trash,
} from '../../juristec-ui/icons';

const strValue = (key, value) => {
  switch (key) {
    case 'comarca':
    case 'vara': {
      return Object.keys(value).map((k) => (
        Array.isArray(value[k]) ? value[k].map((v) => v.label).join(', ') : value[k].label
      )).join(' → ');
    }
    case 'polo':
    case 'sinonimos':
    case 'classe':
    case 'orgao':
    case 'uf':
    case 'tribunal': {
      return value.map((v) => v.label)?.join(', ');
    }
    case 'valor': {
      const formatter = { style: 'currency', currency: 'BRL' };
      return `De ${value[0].toLocaleString('pt-BR', formatter)} até ${value[1].toLocaleString('pt-BR', formatter)}`;
    }
    case 'distribuicao':
    case 'publicacao': {
      if (value[0] === null && value[1] !== null) {
        return `Até ${format(value[1], 'yyyy-MM-dd')}`;
      } if (value[1] === null && value[0] !== null) {
        return `A partir de ${format(value[0], 'yyyy-MM-dd')}`;
      }
      return `De ${format(value[0], 'yyyy-MM-dd')} até ${format(value[1], 'yyyy-MM-dd')}`;
    }
    case 'ano':
      return value.getFullYear();
    default:
      return value;
  }
};

const FilterApplied = ({
  field, subField, label, value, removeFilter, handleValue, editFilter, getOptions,
}) => {
  // eslint-disable-next-line no-unused-vars
  const [editMode, _, closeEditMode, openEditMode] = useToggleState(false);
  const [tempFilter, setTempFilter] = useState({
    value,
    error: false,
    errorMsg: '',
    options: [],
  });

  const handleGetOptions = async (key) => {
    switch (key) {
      case 'comarca': {
        const opts = await getOptions(
          'comarca', { courts: value.tribunal.value },
        );
        return new Map([['comarca', opts.map((dt) => ({ label: dt, value: dt, id: dt }))]]);
      }
      case 'vara': {
        const opts = await getOptions(
          'vara', { courts: value.tribunal.value, comarca: value.comarca.value },
        );
        return new Map([['vara', opts.map((dt) => ({ label: dt, value: dt, id: dt }))]]);
      }
      case 'polo':
        return poloOptions;
      case 'orgao':
        return orgaoOptions;
      case 'uf':
        return ufOptions;
      case 'sinonimos': {
        const opts = await getOptions(key);
        return opts.map((dt) => {
          const str = `${dt.ativo} / ${dt.passivo}`;
          return ({ label: str, value: str, id: str });
        });
      }
      case 'classe': {
        const opts = await getOptions(key);
        return opts.map((dt, i) => ({
          label: dt.nome || dt.sigla.toUpperCase(), value: dt.sigla, id: dt.sigla + i,
        }));
      }
      case 'tribunal': {
        const opts = await getOptions(key);
        return opts.map((dt) => ({ label: dt.toUpperCase(), value: dt, id: dt }));
      }
      default:
        return [];
    }
  };

  useEffect(() => {
    if (editMode) {
      (async () => {
        const opts = await handleGetOptions(field);
        setTempFilter({
          value,
          error: false,
          errorMsg: '',
          options: opts,
        });
      })();
    }
  }, [editMode]);

  const handleValueChange = (val, subKey) => {
    handleValue(val, field, subKey, setTempFilter);
  };

  const handleRemoveFilter = () => {
    removeFilter(field, subField);
  };

  const handleClose = () => {
    closeEditMode();
    setTempFilter({
      value,
      error: false,
      errorMsg: '',
      options: [],
    });
  };

  const handleEditFilter = () => {
    editFilter(field, subField, tempFilter);
    handleClose();
  };

  return (
    <AppliedCard>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <h5>{label}</h5>
        {editMode ? (
          <>
            <IconButton
              onClick={handleEditFilter}
              size="small"
            >
              <Success />
            </IconButton>
            <IconButton
              onClick={handleClose}
              size="small"
            >
              <Cancel />
            </IconButton>
          </>
        ) : (
          <IconButton
            onClick={openEditMode}
            size="small"
          >
            <Edit />
          </IconButton>
        )}
        <IconButton
          onClick={handleRemoveFilter}
          size="small"
        >
          <Trash />
        </IconButton>
      </div>
      {editMode ? (
        <div style={{ paddingBottom: '.8rem' }}>
          <FilterItem
            field={field}
            label={label}
            value={tempFilter.value}
            errorMsg={tempFilter.errorMsg}
            options={tempFilter.options}
            handle={handleValueChange}
          />
        </div>
      ) : (
        <span>{strValue(field, value)}</span>
      )}
    </AppliedCard>
  );
};

FilterApplied.propTypes = {
  field: PropTypes.string,
  subField: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.instanceOf(Date),
    PropTypes.shape({}),
    PropTypes.arrayOf(PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.instanceOf(Date),
      PropTypes.shape({}),
    ])),
  ]).isRequired,
  removeFilter: PropTypes.func.isRequired,
  handleValue: PropTypes.func.isRequired,
  editFilter: PropTypes.func.isRequired,
  getOptions: PropTypes.func.isRequired,
};

FilterApplied.defaultProps = {
  field: '',
  subField: undefined,
  label: '',
};

export default FilterApplied;
