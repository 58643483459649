/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';

import ControllersGroup from '../../../controllers/ControllersGroup';
import {
  InputLineController,
  SelectController,
  ChoiceBoxController,
  SimpleColorPickerController,
  ToggleSwitchController,
} from '../../../controllers/Base';

import fontOptions from '../../../utils/controllersUtils/options';

const LabelControllers = ({
  handle,
  config,
  extend,
  setExtend,
  isGlobalThemeActive,
}) => (
  <>
    <ControllersGroup title="Rótulos" id="label" extend={extend} setExtend={setExtend}>
      <SelectController
        label="Fonte"
        handle={handle}
        kWord="LabelsFontFamilyControl"
        options={fontOptions}
        configValue={config?.LabelsFontFamilyControl?.value}
      />
      <InputLineController
        label="Tamanho"
        handle={handle}
        kWord="LabelsFontSizeControl"
        configValue={config?.LabelsFontSizeControl?.value}
        min={1}
        max={30}
        step={1}
        precision={0}
        isNumber
      />
      <ChoiceBoxController
        label="Cor das linhas"
        handle={handle}
        kWord="LineColorControl"
        options={[
          { value: 'single', label: 'Cor única', id: 'single' },
          { value: 'striped', label: 'Intercalada', id: 'striped' },
        ]}
        configValue={config?.LineColorControl || 'single'}
        fullWidth
        text="Utilizando tema global"
        disabled={config?.UseGlobalColor !== false && isGlobalThemeActive}
      />
      <SimpleColorPickerController
        label="Primária"
        handle={handle}
        kWord="RowColor"
        configValue={config?.RowColor || 'white'}
        orientation="row"
        fullWidth={config?.LineColorControl !== 'striped'}
        text="Utilizando tema global"
        disabled={config?.UseGlobalColor !== false && isGlobalThemeActive}
      />
      {config?.LineColorControl === 'striped' && (
        <SimpleColorPickerController
          label="Secundária"
          handle={handle}
          kWord="StripedRowsColor"
          configValue={config?.StripedRowsColor || 'white'}
          orientation="row"
          text="Utilizando tema global"
          disabled={config?.UseGlobalColor !== false && isGlobalThemeActive}
        />
      )}
      <SimpleColorPickerController
        label="Cor do cabeçalho"
        handle={handle}
        kWord="HeaderColor"
        configValue={config?.HeaderColor || 'white'}
        orientation="row"
        fullWidth
        text="Utilizando tema global"
        disabled={config?.UseGlobalColor !== false && isGlobalThemeActive}
      />
      <ChoiceBoxController
        label="Formatação dos valores"
        handle={handle}
        kWord="ValueFormatControl"
        options={[
          { value: 'locale', label: 'Localizada', id: 'locale' },
          { value: 'default', label: 'Valor original', id: 'default' },
        ]}
        configValue={config?.ValueFormatControl ?? 'locale'}
        fullWidth
      />
      <ToggleSwitchController
        label="Utilizar tema global"
        handle={handle}
        kWord="UseGlobalColor"
        configValue={config?.UseGlobalColor}
        firstOption=""
        secondOption=""
        fullWidth
      />
    </ControllersGroup>
  </>
);

LabelControllers.propTypes = {
  config: PropTypes.objectOf(PropTypes.any),
  handle: PropTypes.func,
  extend: PropTypes.string,
  setExtend: PropTypes.func,
  isGlobalThemeActive: PropTypes.bool,
};

LabelControllers.defaultProps = {
  config: undefined,
  handle: () => {},
  extend: '',
  setExtend: () => {},
  isGlobalThemeActive: false,
};

export default LabelControllers;
