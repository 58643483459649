import styled from 'styled-components';
import { gap } from '../../../../juristec-ui/styles/theme';
import { devices } from '../../../../juristec-ui/core/breakpoints';

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const MainContainer = styled(Row)`
  display: flex;
  padding: 0.8rem 5%;
  align-items: center;
  justify-content: space-between;
  
  @media ${devices.tablet} {
    padding: 0.8rem 20px;
  }

  @media ${devices.phoneS} {
    justify-content: flex-start;
    ${gap('10px', 'row')}
  }
`;

export const ItensContainer = styled(Row)`
  width: 50%;
  & h3.filename {
    font-size: 20px;
    color: ${({ theme }) => theme.primary};
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    
    @media ${devices.phoneS} {
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  align-items: center;
  &.left{
    flex: 0;
    justify-content: flex-start;
  }
  &.right{
    flex: 0;
    justify-content: flex-end;
    & > div:last-child {
      margin-left: 10px;
    }
  }
  &.center {
    flex: 1;
    margin: 0 1rem;
    justify-content: center;

    @media ${devices.phoneS} {
      justify-content: flex-start;
    }
  }
`;

export const FileInfo = styled(Column)`
  justify-content: center;
  align-items: flex-start;
  margin-left: 10px;
  & span.fileinfo {
    color: ${({ theme }) => theme.grey};
    font-size: 12px;
    white-space: nowrap;

    @media ${devices.phoneS} {
      font-size: 12px;
    }
  }
`;
