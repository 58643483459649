/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import ResponsiveValue, { Container } from './styled/Value.styled';
import { genDataValue } from '../../utils/formatCharts/handleData';
import { customValueLabel } from '../../utils/formatCharts/handleCustomValue';

const Value = ({
  data, styleConfig, valueFunction, globalTheme,
}) => {
  const [chartData, setChartData] = useState({ ready: false });

  useEffect(() => {
    setChartData(genDataValue(data, styleConfig, valueFunction === 'mean'));
  }, [data, valueFunction, styleConfig?.TotalFunctionControl]);

  const formatValue = (value) => customValueLabel(value, styleConfig);

  return chartData.ready ? (
    <Container
      background={
        globalTheme?.useGlobalTheme ? globalTheme.valueBg : styleConfig?.GlobalValue?.background
      }
    >
      <ResponsiveValue
        fontSize={styleConfig?.GlobalValue?.fontSize || 20}
        color={globalTheme?.useGlobalTheme ? globalTheme.valueTxt : styleConfig?.GlobalValue?.color ?? '#222222'}
        fontFamily={styleConfig?.GlobalValue?.fontFamily || 'Arial'}
        bold={styleConfig?.GlobalValue?.bold}
        underline={styleConfig?.GlobalValue?.underline}
        italic={styleConfig?.GlobalValue?.italic}
      >
        {formatValue(chartData.value)}
        <span className="desc">{styleConfig?.DescriptionControl}</span>
      </ResponsiveValue>
    </Container>
  ) : <></>;
};

Value.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  styleConfig: PropTypes.objectOf(PropTypes.any),
  valueFunction: PropTypes.string.isRequired,
  globalTheme: PropTypes.objectOf(PropTypes.any),
};

Value.defaultProps = {
  globalTheme: {},
};

export default Value;
