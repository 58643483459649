import React from 'react';

// import { Container } from './styles';

function ArrowFit({ fill = '#4711B2', width = '11', height = '20' }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 11 20" fill={fill}>
      <path d="M10.1797 1.77005L8.74862 4.95911e-05L0.744369 9.90005L8.74862 19.8L10.1797 18.03L3.6065 9.90005L10.1797 1.77005Z" fill={fill} />
    </svg>
  );
}

export default ArrowFit;
