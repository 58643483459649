import React from 'react';
import PropTypes from 'prop-types';
import MainContainer from './styled/DashboardCore.styled';
import SystemMessages from '../SystemMessages';
import DashboardGrid from '../../juristec-ui/core/DashboardGrid';
import EmptyList from './EmptyList';

const DashboardCore = ({
  _currentBreakpoint,
  _layout,
  _setCurrentBreakPoint,
  _setLayout,
  canEditLayout,
  dashboardLayout,
  filesState,
  filteredDash,
  filterText,
  handleBookMarkDash,
  handleCloneDash,
  handleCloseMessage,
  handleCopyDashboard,
  handleCreateModal,
  handleEditModal,
  handleExportDashboard,
  handleRemoveDash,
  handleSelfRemove,
  handleShareDash,
  handleToggleTrash,
  handleTrashCan,
  handleUseAsTemplate,
  isLoading,
  nextStep,
  openTrashCan,
  refreshTour,
  selectedGeneral,
  selectedGroups,
  sysMsgState,
  tourOpen,
  user,
}) => (
  <MainContainer editPainel={!!canEditLayout} className="outerGrid">
    <SystemMessages
      sysMessages={sysMsgState?.systemMessages || []}
      closeMessage={handleCloseMessage}
    />
    {
        filteredDash?.length ? (
          <DashboardGrid
            user={user}
            dashboardList={filteredDash}
            // Layout
            currentBreak={_currentBreakpoint}
            setCurrentBreakPoint={_setCurrentBreakPoint}
            isFiltering={(
              !!filterText || Object.keys(selectedGroups).length > 0 || selectedGeneral !== 'all' || !openTrashCan
            )}
            layouts={_layout}
            dbLayout={dashboardLayout}
            setLayouts={_setLayout}
            editPainel={canEditLayout}
            // funcs
            isLoading={!filesState?.started || filesState?.isLoading}
            removeDashboard={handleRemoveDash}
            shareDashboard={handleShareDash}
            exportDashboard={handleExportDashboard}
            handleEditModal={handleEditModal}
            bookMarkDashboard={handleBookMarkDash}
            handleCopyDashboard={handleCopyDashboard}
            handleUseAsTemplate={handleUseAsTemplate}
            handleCloneDash={handleCloneDash}
            dashboardTrashToggle={handleToggleTrash}
            selfRemoveDashboard={handleSelfRemove}
            // users
            // usersList={usersState.users}
            tourContext={{ tourOpen, nextStep, refreshTour }}
          />
        ) : (
          <>
            {!isLoading && (
              <EmptyList
                toggle={openTrashCan ? () => handleTrashCan(false) : handleCreateModal}
                // eslint-disable-next-line no-nested-ternary
                typeMsg={openTrashCan ? 'emptyTrash' : (filterText ? 'emptySearch' : 'empty')}
              />
            )}
          </>
        )
    }
  </MainContainer>
);

DashboardCore.propTypes = {
  canEditLayout: PropTypes.bool,
  sysMsgState: PropTypes.shape({
    systemMessages: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  handleCloseMessage: PropTypes.func.isRequired,
  user: PropTypes.shape({
    role: PropTypes.string,
  }).isRequired,
  filteredDash: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  _currentBreakpoint: PropTypes.string.isRequired,
  _setCurrentBreakPoint: PropTypes.func.isRequired,
  filterText: PropTypes.string.isRequired,
  selectedGroups: PropTypes.shape({}).isRequired,
  selectedGeneral: PropTypes.string.isRequired,
  _layout: PropTypes.shape({}).isRequired,
  dashboardLayout: PropTypes.shape({}).isRequired,
  _setLayout: PropTypes.func.isRequired,
  filesState: PropTypes.shape({
    started: PropTypes.bool,
    isLoading: PropTypes.bool,
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
  openTrashCan: PropTypes.bool.isRequired,
  handleRemoveDash: PropTypes.func.isRequired,
  handleShareDash: PropTypes.func.isRequired,
  handleExportDashboard: PropTypes.func.isRequired,
  handleEditModal: PropTypes.func.isRequired,
  handleBookMarkDash: PropTypes.func.isRequired,
  handleCopyDashboard: PropTypes.func.isRequired,
  handleUseAsTemplate: PropTypes.func.isRequired,
  handleCloneDash: PropTypes.func.isRequired,
  handleToggleTrash: PropTypes.func.isRequired,
  handleTrashCan: PropTypes.func.isRequired,
  handleCreateModal: PropTypes.func.isRequired,
  handleSelfRemove: PropTypes.func.isRequired,
  tourOpen: PropTypes.bool.isRequired,
  nextStep: PropTypes.func.isRequired,
  refreshTour: PropTypes.func.isRequired,
};

DashboardCore.defaultProps = {
  canEditLayout: false,
};

export default DashboardCore;
