import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import {
  TextAreaContainer,
  StyledTextArea,
  Label,
  ErrorMessage,
  Highlight,
  Bar,
} from './styled/TextArea.styled';

/**
 * It is an textarea field for inserting long and multiline texts.
 */
const TextArea = forwardRef(({
  label,
  error,
  errorMessage,
  styleContainer,
  notTransparent,
  disabled,
  ...props
}, ref) => (
  <TextAreaContainer
    label={label}
    error={error}
    style={styleContainer}
    notTransparent={notTransparent}
    disabled={disabled}
    className="textAreaContainer"
  >
    <StyledTextArea
      ref={ref}
      {...props}
      id={`textArea${label}`}
      disabled={disabled}
      error={error}
    />
    <Label htmlFor={`inputTextLine${label}`}>{label}</Label>
    <Highlight />
    <Bar className="textAreaBar" />
    {error && <ErrorMessage>{errorMessage}</ErrorMessage>}
  </TextAreaContainer>
));

TextArea.propTypes = {
  /**
  * A boolean variable to determine if the textarea is disabled or not
  */
  disabled: PropTypes.bool,
  /**
  * A boolean variable to determine if there is an error with the inputed value
  */
  error: PropTypes.bool,
  /**
  * The error message to be shown in case of an error
  */
  errorMessage: PropTypes.string,
  /**
  * The label of the textarea
  */
  label: PropTypes.string,
  /**
  * A boolean variable to show if the background of the textarea must be transparent
  */
  notTransparent: PropTypes.bool,
  /**
  * A boolean variable to show if this input value is required or not
  */
  required: PropTypes.bool,
  /**
  * An option where a custom style can be passed to the container
  */
  styleContainer: PropTypes.shape({}),
};

TextArea.defaultProps = {
  label: '',
  required: false,
  error: false,
  errorMessage: '',
  styleContainer: {},
  notTransparent: false,
  disabled: false,
};

export default TextArea;
