import { useEffect, useState } from 'react';

/** Hook to load the recaptcha script and generate a recaptcha token. */
const useRecaptchaV3 = () => {
  const [ready, setReady] = useState(false);

  useEffect(() => {
    /**
     * Loads the recaptcha script by adding the DOM script tag.
     * @param {string} id DOM id attribute.
     * @param {string} url Google recaptcha script link.
     * @param {function} callback Executed when the script is loaded.
     */
    const loadScriptByURL = (id, url, callback) => {
      const isScriptExist = document.getElementById(id);

      if (!isScriptExist) {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = url;
        script.id = id;
        script.async = true;
        script.onload = () => {
          if (callback) callback();
        };
        document.body.appendChild(script);
      }

      if (isScriptExist && callback) callback();
    };

    loadScriptByURL(
      'recaptcha-key',
      `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`,
      () => {
        setReady(true);
        // console.log('Recaptcha loaded!');
      },
    );
  }, []);

  /**
   * Generates a recaptcha token to be validated.
   * @param {'login'|'reset_password'} action The action name for this request.
   * @returns {string} Google recaptcha token.
   */
  const getToken = async (action) => {
    if (ready) {
      return window.grecaptcha.execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY, { action });
    }
    return '';
  };

  return {
    /** State of the recaptcha script */
    ready,
    getToken,
  };
};

export default useRecaptchaV3;
