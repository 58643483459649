import React from 'react';
import PropTypes from 'prop-types';

const BennerSimple = ({ height, width }) => (
  <svg width={width} height={height} viewBox="0 0 86 118" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.025 2.38584C0.025 4.65251 -0.241667 8.65251 0.158333 45.3192L0.558333 85.8525L4.29167 93.3192C13.8917 113.186 34.825 122.386 55.4917 115.986C85.3583 107.053 95.7583 69.7192 75.0917 46.6525C66.1583 36.7858 56.6917 32.5192 43.225 32.5192C32.5583 32.5192 31.225 32.9192 15.625 40.9192C14.9583 41.1858 14.5583 33.0525 14.5583 22.7858C14.5583 6.51917 14.2917 3.98584 12.1583 2.11918C8.95833 -0.814156 4.825 -0.680824 2.025 2.38584ZM53.8917 49.0525C73.225 57.8525 77.3583 81.4525 62.025 95.5858C47.7583 108.653 26.6917 104.919 18.025 87.7192C13.8917 79.1858 13.625 72.1192 17.3583 63.8525C20.9583 55.8525 23.3583 53.3192 31.225 49.7192C39.225 45.9858 46.6917 45.7192 53.8917 49.0525Z" fill="#ED1846"/>
    <path d="M34.5581 58.2525C24.9581 63.0525 21.3581 74.1191 26.2915 83.7191C33.4915 98.1191 53.6248 97.5858 60.1581 82.9191C67.4915 66.2525 50.8248 49.9858 34.5581 58.2525Z" fill="#4646F0"/>
  </svg>
);

BennerSimple.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
};

BennerSimple.defaultProps = {
  height: '25px',
  width: '25px',
};

export default BennerSimple;
