import React from 'react';

function Minus() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 0H24V24H0V0Z" fill="none" />
      <path d="M19 13H13H11H5V11H11H13H19V13Z" />
    </svg>
  );
}

export default Minus;
