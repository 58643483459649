import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import CardKpi from '../../../juristec-ui/kpis/grid/CardKpi';
import KpiCarousel from '../../KpiCarousel';

import barConfig from './kpisConfig/barConfig';
import funnelConfig from './kpisConfig/funnelConfig';
import lineConfig from './kpisConfig/lineConfig';
import pieConfig from './kpisConfig/pieConfig';
import radarConfig from './kpisConfig/radarConfig';
import radialbarConfig from './kpisConfig/radialbarConfig';
import tableConfig from './kpisConfig/tableConfig';
import valueConfig from './kpisConfig/valueConfig';
import waffleConfig from './kpisConfig/waffleConfig';

import {
  KpiWrapper,
  CarouselWrapper,
} from './styled/AssistantResponse.styled';

const getStyle = (type) => {
  switch (type) {
    case 'Bar':
      return barConfig;
    case 'Pie':
      return pieConfig;
    case 'Line':
      return lineConfig;
    case 'Value':
      return valueConfig;
    case 'Radar':
      return radarConfig;
    case 'Table':
      return tableConfig;
    case 'Funnel':
      return funnelConfig;
    case 'RadialBar':
      return radialbarConfig;
    case 'Waffle':
      return waffleConfig;
    default:
      return {};
  }
};

const AssistantKpi = ({
  dataframe,
}) => {
  const kpiRef = useRef();

  const [selectedType, setSelectedType] = useState('Table');
  const [kpiObject, setKpiObject] = useState({
    id: 'new',
    name: 'gptChart',
    status: 'active',
    updatedAt: '',
    type: 'Table',
    data: dataframe,
    style: tableConfig,
    meta: {
      control: [],
      filters: [],
      linesMap: [],
      columnsMap: [],
      values: [{ map: 'sum' }],
    },
  });

  const [windowDimensions, setWindowDimensions] = useState({
    height: window.innerHeight, width: window.innerWidth,
  });

  useEffect(() => {
    function handleResize() {
      setWindowDimensions({ width: window.innerWidth, height: window.innerHeight });
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    setKpiObject((old) => ({
      ...old,
      data: dataframe,
      type: selectedType,
      style: getStyle(selectedType),
    }));
  }, [dataframe, selectedType]);

  return (
    <>
      <CarouselWrapper>
        <KpiCarousel
          selectedChartType={selectedType}
          setChartType={setSelectedType}
          disabled={false}
          hideList={dataframe?.columns.length > 1 && dataframe?.data.length > 0 ? (
            ['Pie', 'Funnel', 'Waffle', 'Map', 'Calendar', 'BoxPlot', 'ScatterPlot']
          ) : (
            ['Map', 'Calendar', 'BoxPlot', 'ScatterPlot']
          )}
        />
      </CarouselWrapper>
      <KpiWrapper
        style={{
          height: `${windowDimensions.height - 300}px`,
        }}
      >
        <CardKpi
          ref={kpiRef}
          item={kpiObject}
          editPainel={false}
          id={kpiObject.id}
          collection="kpis"
        />
      </KpiWrapper>
    </>
  );
};

AssistantKpi.propTypes = {
  dataframe: PropTypes.shape({
    index: PropTypes.arrayOf(PropTypes.string),
    columns: PropTypes.arrayOf(PropTypes.string),
    data: PropTypes.arrayOf(PropTypes.string),
  }),
};

AssistantKpi.defaultProps = {
  dataframe: {},
};

export default AssistantKpi;
