export default {
  headerColor: '#ABB8C3',
  mainColor: '#FFFFFF',
  name: 'Mock Big Data',
  status: 'active',
  cardStyles: {
    valueBg: '#4711B2',
    charts: [
      '#4711B2',
      '#FF9626',
    ],
    tableHeader: '#555555',
    borderStyle: {
      color: '#9a9a9a',
      line: '',
      rounded: 0,
      size: 1,
    },
    insightTxt: '#FFFFFF',
    titles: '#000000',
    insightBg: '#FF9626',
    valueTxt: '#FFFFFF',
    spaceBetween: 10,
    useGlobalTheme: false,
    backgroundColor: '#ffffff',
    shading: false,
    tableRows: [
      '#FFFFFF',
      '#FFFFFF',
    ],
  },
  group: {
    label: 'Big Data',
    value: 'Big Data',
  },
};
