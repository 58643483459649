import compare from '../../../utils/functions/sorting';

export const ptbrMonths = [
  'Jan',
  'Fev',
  'Mar',
  'Abr',
  'Mai',
  'Jun',
  'Jul',
  'Ago',
  'Set',
  'Out',
  'Nov',
  'Dez',
];

export const expDateOrder = (val) => {
  if (!val) return val;
  switch (val.toString().toLowerCase()) {
    case 'domingo': return 0;
    case 'dom': return 0;
    case 'segunda': return 1;
    case 'seg': return 1;
    case 'segunda-feira': return 1;
    case 'terça': return 2;
    case 'ter': return 2;
    case 'terça-feira': return 2;
    case 'quarta': return 3;
    case 'qua': return 3;
    case 'quarta-feira': return 3;
    case 'quinta': return 4;
    case 'qui': return 4;
    case 'quinta-feira': return 4;
    case 'sexta': return 5;
    case 'sex': return 5;
    case 'sexta-feira': return 5;
    case 'sábado': return 6;
    case 'sab': return 6;

    case 'janeiro': return 0;
    case 'jan': return 0;
    case 'fevereiro': return 1;
    case 'fev': return 1;
    case 'março': return 2;
    case 'mar': return 2;
    case 'abril': return 3;
    case 'abr': return 3;
    case 'maio': return 4;
    case 'mai': return 4;
    case 'junho': return 5;
    case 'jun': return 5;
    case 'julho': return 6;
    case 'jul': return 6;
    case 'agosto': return 7;
    case 'ago': return 7;
    case 'setembro': return 8;
    case 'set': return 8;
    case 'outubro': return 9;
    case 'out': return 9;
    case 'novembro': return 10;
    case 'nov': return 10;
    case 'dezembro': return 11;
    case 'dez': return 11;
    default: return val;
  }
};

export default function orderDatetime(data, keys, columns = []) {
  let dataClone = [...data];
  const keysClone = [...keys];
  const columnsClone = [...columns];

  if (columns.length > 1) {
    columnsClone.sort((a, b) => (compare(expDateOrder(a), expDateOrder(b))));
    const columnsIndex = columnsClone.map((el) => columns.indexOf(el));
    dataClone = dataClone.map((dt) => columnsIndex.map((index) => dt[index]));
  }
  keysClone.sort((a, b) => (compare(expDateOrder(a), expDateOrder(b))));
  const keysIndex = keysClone.map((el) => keys.indexOf(el));
  return {
    data: keysIndex.map((index) => dataClone[index]),
    indexes: keysIndex.map((index) => keys[index]),
    columns: columnsClone,
  };
}
