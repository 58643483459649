import styled, { css } from 'styled-components';

const translate = {
  small: '25.2px',
  medium: '36px',
  large: '46.8px',
  getTranslate(size) {
    return this[size] || this.medium;
  },
};

const sizes = {
  small: { width: 42, height: 16.8, 'font-size': 11 },
  medium: { width: 60, height: 24, 'font-size': 14 },
  large: { width: 78, height: 31.2, 'font-size': 18 },
  getSize(size) {
    return this[size] || this.medium;
  },
};

export const InputContainer = styled.label`
  position: relative;
  display: inline-block;
`;

export const Input = styled.input`
  display: none;
  &:disabled + span {
    opacity: 0.4;
    cursor: not-allowed;
  }
`;

export const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  display: flex;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: 0.2s;
  border-radius: 34px;
  &:before {
    position: relative;
    border-radius: 50%;
    content: '';
    left: 2px;
    background-color: ${({ theme }) => theme.lightgrey};
    transition: 0.2s;
  }
`;

export const Label = styled.span`
  text-shadow: ${({ isChecked }) => (isChecked ? '1px' : '0px')} 0px 0px black;
  color: ${({ theme }) => theme.color};
  &.toggle-label-left {
    padding: 0 10px 0 0;
  }
  &.toggle-label-right {
    padding: 0 0 0 10px;
  }
`;

export const Title = styled.label`
  position: absolute;
  top: 4px;
  left: 0px;
  font-size: 0.85rem;
  color: ${({ theme }) => theme.inputLabel};
  font-weight: bold;
`;

export const StyledToggleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding-top: ${({ title }) => (title === '' ? '0px' : '20px')};
  ${({ theme, size, color }) => css`
    & ${InputContainer} {
      height: ${sizes.getSize(size).height}px;
      width: ${sizes.getSize(size).width}px;
    }
    & ${Input} {
      & + span {
        background-color: ${theme[color] || color};
      }
      &:checked + span:before {
        transform: translateX(${translate.getTranslate(size)});
      }
    }
    & ${Slider} {
      &:before {
        height: ${sizes.getSize(size).height - 4}px;
        width: ${sizes.getSize(size).height - 4}px;
      }
    }
    & ${Label} {
      font-size: ${sizes.getSize(size)['font-size']}px;
    }
  `}
`;
