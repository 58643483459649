import styled from 'styled-components';

const NotFoundContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  font-family: Verdana, Arial, Helvetica, sans-serif;
  font-weight: bold;
  gap: 1rem;

  & > svg {
    height: 20rem;
    width: 100%;
  }

  h1 {
    margin-bottom: 5px;
    margin-top: 15px;
    color: ${({ theme }) => theme.secondary};
    border-radius: 4px;
    font-size: 3rem;
  }

  span {
    padding: 10px;
    font-size: 1rem;
  }

  & .navlink {
    text-decoration: none;
    color: white;
    background-color: ${({ theme }) => theme.secondary};
    border: 2px solid ${({ theme }) => theme.secondary};
    border-radius: 4px;
    padding: 8px 16px;
    font-size: 18px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;

    &:hover {
      opacity: 0.85;
    }

    & > svg,
    path {
      fill: white;
      margin-left: 10px;
    }
  }
`;

export default NotFoundContainer;
