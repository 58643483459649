import React from 'react';
import PropTypes from 'prop-types';

import AssistantKpi from './AssistantKpi';
import AssistantTable from './AssistantTable';

import {
  MainContainer,
} from './styled/AssistantResponse.styled';

const AssistantResponse = ({
  dataframe, isKpi,
}) => (
  <MainContainer>
    {isKpi ? (
      <AssistantKpi dataframe={dataframe} />
    ) : (
      <AssistantTable dataframe={dataframe} />
    )}
  </MainContainer>
);

AssistantResponse.propTypes = {
  dataframe: PropTypes.shape({
    index: PropTypes.arrayOf(PropTypes.string),
  }),
  isKpi: PropTypes.bool,
};

AssistantResponse.defaultProps = {
  dataframe: {},
  isKpi: false,
};

export default AssistantResponse;
