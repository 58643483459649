import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';

import DataTable from '../../juristec-ui/core/DataTable';
import { formatDateTime } from '../../juristec-ui/utils/functions/lab';
import Tooltip from '../../juristec-ui/core/Tooltip';
import Badge from '../../juristec-ui/core/Badge';
import DataCards from '../../juristec-ui/core/DataCards';
import IconButton from '../../juristec-ui/core/IconButton';
import TablePagination from '../../juristec-ui/core/TablePagination';

import {
  UserConfig,
  History,
  Dashboard,
  UserAtom,
  Users,
  Question,
  ProfileUser,
  Trash,
} from '../../juristec-ui/icons';

import useDeviceType from '../../juristec-ui/hooks/useDeviceType';

import {
  UserInfoText,
  Options,
  Table,
  UsersTableOverFlow,
  BadgeContent,
  IconWrapper,
} from './styled/UsersTable.styled';

/**
 * A component that displays the users info and options
 */
const UsersTable = ({
  users,
  getUsers,
  getUserInfo,
  getUserHistory,
  getUserDashboards,
  filterText,
  handleDelete,
}) => {
  const device = useDeviceType();
  const theme = useTheme();

  const [data, setData] = useState(users);
  const [page, setPage] = useState(0);

  const DataListingComponent = device === 'phone' ? DataCards : DataTable;

  useEffect(() => {
    getUsers();
  }, []);

  useEffect(() => {
    setPage(0);
  }, [filterText]);

  useEffect(() => {
    const value = filterText.toLowerCase();
    setData(users.filter((user) => (
      user?.displayName && user?.displayName.toLowerCase().includes(value))
      || (user?.email && user?.email.toLowerCase().includes(value))));
  }, [users, filterText]);

  const getUsersFromSameCompany = (userCompanyId) => users.filter(
    (user) => user?.company?.id === userCompanyId,
  );

  const UserOptions = ({ user }) => {
    const getInfo = (e) => {
      e.stopPropagation();
      getUserInfo(user);
    };
    const getHistory = (e) => {
      e.stopPropagation();
      getUserHistory(user);
    };
    const getDashboards = (e) => {
      const sameCompanyUsers = getUsersFromSameCompany(user?.company?.id);
      e.stopPropagation();
      getUserDashboards(user, sameCompanyUsers);
    };

    const deleteUser = (e) => {
      e.stopPropagation();
      const { modalHandler, handler } = handleDelete(user);

      const auxRemove = () => {
        setData((p) => p.filter((u) => u.user_id !== user?.user_id));
      };

      const tmp = () => handler(auxRemove);
      modalHandler(tmp);
    };

    return (
      <Options>
        <Tooltip text="Informações">
          <IconButton variant="pattern" shape="rounded" color="primary" onClick={getInfo}>
            <ProfileUser />
          </IconButton>
        </Tooltip>
        <Tooltip text="Acessos">
          <IconButton variant="pattern" shape="rounded" color="primary" onClick={getHistory}>
            <History />
          </IconButton>
        </Tooltip>
        <Tooltip text="Dashboards">
          <IconButton variant="pattern" shape="rounded" color="primary" onClick={getDashboards}>
            <Dashboard />
          </IconButton>
        </Tooltip>
        <Tooltip text="Apagar usuário">
          <IconButton variant="pattern" shape="rounded" color="primary" onClick={deleteUser}>
            <Trash />
          </IconButton>
        </Tooltip>
      </Options>
    );
  };

  UserOptions.propTypes = {
    user: PropTypes.shape({
      company: PropTypes.shape({
        id: PropTypes.string,
      }),
      user_id: PropTypes.string,
    }).isRequired,
  };

  const UserInfo = (user) => (
    <UserInfoText>
      <h4>{user?.displayName || user?.email.split('@')[0]}</h4>
      <span>{user?.email}</span>
    </UserInfoText>
  );

  UserInfo.propTypes = {
    user: PropTypes.shape({
      displayName: PropTypes.string,
      email: PropTypes.string,
    }).isRequired,
  };

  const formatDate = (datetime) => (
    datetime ? formatDateTime(datetime, { time: 'none' }) : 'Não consta'
  );

  const formatRole = (role) => {
    let conditionalColor = 'error';
    let icon = <Question height="20px" />;
    let userType = 'Desconhecido';
    switch (role) {
      case 'super':
        conditionalColor = 'primary';
        icon = <UserConfig width="20px" />;
        userType = 'Proprietário';
        break;
      case 'scientist':
        conditionalColor = 'success';
        icon = <UserAtom width="20px" />;
        userType = 'Cientista';
        break;
      case 'guest':
        conditionalColor = 'warning';
        icon = <Users width="20px" />;
        userType = 'Convidado';
        break;
      default:
        break;
    }
    return (
      <Badge color={conditionalColor} style={{ margin: 'auto' }}>
        <BadgeContent>
          <IconWrapper color={conditionalColor}>
            {icon}
          </IconWrapper>
          {userType}
        </BadgeContent>
      </Badge>
    );
  };

  const columns = [
    {
      field: 'email',
      role: 'primaryInfo',
      label: 'Usuário',
      sortable: true,
      valueGetter: (param) => UserInfo(param),
    },
    {
      field: 'companyName',
      role: 'extraInfo',
      label: 'Cliente',
      sortable: true,
      valueGetter: (param) => param?.companyName,
    },
    {
      field: 'role',
      role: 'extraInfo',
      label: 'Permissão',
      sortable: true,
      valueGetter: (param) => formatRole(param?.role),
    },
    {
      field: 'created_at',
      role: 'extraInfo',
      label: 'Data da criação',
      sortable: true,
      valueGetter: (param) => formatDate(param?.created_at || ''),
    },
    {
      field: 'last_login',
      role: 'extraInfo',
      label: 'Último login',
      sortable: true,
      valueGetter: (param) => formatDate(param?.last_login || ''),
    },
    {
      field: 'options',
      role: 'optionsBtn',
      label: 'Opções',
      valueGetter: (param) => <UserOptions user={param} />,
    },
  ];

  return (
    <Table>
      <UsersTableOverFlow>
        <DataListingComponent
          columns={columns}
          rowData={data}
          strippedRowsColor={theme.containerOdd}
          defaultSortField="email"
          defaultSortOrder="ascending"
          headerColor="transparent"
          rowColor={theme.tableBackground}
          theadStyle={{
            position: 'sticky',
            top: 0,
            zIndex: 1,
            backgroundColor: theme.background,
          }}
          usePagination
          page={page}
          itemsPerPage={20}
        />
      </UsersTableOverFlow>
      <TablePagination
        page={page}
        setPage={setPage}
        totalPages={Math.floor(data.length / 20)}
      />
    </Table>
  );
};

UsersTable.propTypes = {
  /**
   * Array with the data of the users
   */
  users: PropTypes.arrayOf(
    PropTypes.shape({
      displayName: PropTypes.string,
      email: PropTypes.string,
      company: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        operationalStatus: PropTypes.string,
      }),
      role: PropTypes.string,
      created_at: PropTypes.string,
      last_login: PropTypes.string,
    }),
  ).isRequired,
  /**
   * Function to retrieve all the users of the project
   */
  getUsers: PropTypes.func,
  /**
   * Function to show the information of an specific user
   */
  getUserInfo: PropTypes.func,
  getUserHistory: PropTypes.func,
  getUserDashboards: PropTypes.func,
  filterText: PropTypes.string,
  handleDelete: PropTypes.func.isRequired,
};

UsersTable.defaultProps = {
  getUsers: () => {},
  getUserInfo: () => {},
  getUserHistory: () => {},
  getUserDashboards: () => {},
  filterText: '',
};

export default UsersTable;
