import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { StyledNoFile, StyledOuterNoFile } from './styled/NoKpis.styled';

import Button from '../../../core/Button';
import { NoFileKpis } from '../../../icons';
import urlHash from '../../../../utils/functions/urlHash';

function NoKpis({ dashboardKey, isShared, role }) {
  const history = useHistory();

  const verifyPermission = () => {
    if (isShared) {
      return (
        <div>
          Pelo jeito, o criador desse dashboard ainda não possui conteúdos.
          Aguarde ou então entre em algum dashboard feito por você para
          adicionar ou editar novos KPIS e Insights!
        </div>
      );
    } if (role === 'guest') {
      return (
        <div className="firstphrase">
          Infelizmente você não possui permissão para criar!
        </div>
      );
    }
    return (
      <div className="firstphrase">
        O que deseja criar primeiro:
        {' '}
        <Button
          color="secondary"
          onClick={() => history.push(`/kpi/${urlHash(`${dashboardKey}/new`)}`)}
          style={{ margin: '0px 10px', height: '30px' }}
          size="small"
        >
          KPI
        </Button>
        <Button
          color="secondary"
          onClick={() => history.push(`/label/${urlHash(`${dashboardKey}/new`)}`)}
          style={{ margin: '0px 10px', height: '30px' }}
          size="small"
        >
          Insight
        </Button>
      </div>
    );
  };

  return (
    <StyledOuterNoFile>
      <StyledNoFile>
        <div className="content">
          <>
            <section>
              <b>Nenhum KPI ou Insight criado!</b>
              <br />
              {verifyPermission()}
            </section>
          </>
          <NoFileKpis />
        </div>

      </StyledNoFile>
    </StyledOuterNoFile>
  );
}

NoKpis.propTypes = {
  dashboardKey: PropTypes.string.isRequired,
  isShared: PropTypes.bool,
  role: PropTypes.string.isRequired,
};

NoKpis.defaultProps = {
  isShared: false,
};

export default NoKpis;
