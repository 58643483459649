import React from 'react';

// import { Container } from './styles';

function Promote() {
    return (
        <svg width="22" height="22" viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M16.4238 9.16667H11.5962C11.4874 9.16684 11.3806 9.13638 11.2875 9.0786C11.1944 9.02081 11.1185 8.93791 11.068 8.8389C11.0175 8.73988 10.9944 8.62855 11.0012 8.51699C11.0079 8.40543 11.0443 8.29791 11.1064 8.20612L15.3984 1.83648C14.2767 0.70267 12.72 0 11 0C7.58398 0 4.8125 2.77148 4.8125 6.1875C4.8125 9.60352 7.58398 12.375 11 12.375C13.3362 12.375 15.3709 11.0788 16.4238 9.16667ZM14.1324 13.75H16.5C19.5379 13.75 22 16.2121 22 19.25V19.9375C22 21.0762 21.0762 22 19.9375 22H2.0625C0.923828 22 0 21.0762 0 19.9375V19.25C0 16.2121 2.46211 13.75 5.5 13.75H7.86758C8.82578 14.1883 9.88281 14.4375 11 14.4375C12.1172 14.4375 13.1785 14.1883 14.1324 13.75Z" />
            <path d="M13.2458 8.24992H20.671C20.7461 8.24967 20.8198 8.22813 20.8841 8.18762C20.9484 8.1471 21.0008 8.08914 21.0357 8.01998C21.0706 7.95082 21.0867 7.87308 21.0823 7.79511C21.0778 7.71715 21.053 7.64193 21.0105 7.57754L17.2979 2.00649C17.144 1.77551 16.7736 1.77551 16.6193 2.00649L12.9067 7.57754C12.8637 7.64179 12.8385 7.71706 12.8338 7.79515C12.8291 7.87324 12.8451 7.95117 12.8801 8.02048C12.915 8.08979 12.9676 8.14782 13.0321 8.18827C13.0965 8.22872 13.1704 8.25004 13.2458 8.24992Z" />
        </svg>
    );
}

export default Promote;