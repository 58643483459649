import React from 'react';
import PropTypes from 'prop-types';
import StyledList from './styled/List.styled';

// import { Container } from './styles';

function List({ children, ...props }) {
  return (
    <StyledList {...props}>
      {children}
    </StyledList>
  );
}

List.propTypes = {
  /**
  * List content
  */
  children: PropTypes.node.isRequired,
};

export default List;
