import React from 'react';
import PropTypes from 'prop-types';

import { CircleError, CircleSuccess } from '../../juristec-ui/icons';

import { IconType, PasswordInformation } from './styled/PasswordRules.styled';

export const PasswordRules = ({ errors }) => (
  <PasswordInformation>
    <span>
      Sua senha deve incluir:
    </span>
    <IconType type={errors.lengthError ? 'error' : 'success'}>
      {errors.lengthError ? <CircleError /> : <CircleSuccess />}
      <span>
        No mínimo 8 caracteres
      </span>
    </IconType>
    <IconType type={errors.lowerError ? 'error' : 'success'}>
      {errors.lowerError ? <CircleError /> : <CircleSuccess />}
      <span>
        Pelo menos uma letra minúscula (a-z)
      </span>
    </IconType>
    <IconType type={errors.capitalError ? 'error' : 'success'}>
      {errors.capitalError ? <CircleError /> : <CircleSuccess />}
      <span>
        Pelo menos uma letra maiúscula (A-Z)
      </span>
    </IconType>
    <IconType type={errors.numberError ? 'error' : 'success'}>
      {errors.numberError ? <CircleError /> : <CircleSuccess />}
      <span>
        Pelo menos um número (0-9)
      </span>
    </IconType>
    <IconType type={errors.specialCharacterError ? 'error' : 'success'}>
      {errors.specialCharacterError ? <CircleError /> : <CircleSuccess />}
      <span>
        Pelo menos um caractere especial (ex: !@#$%*)
      </span>
    </IconType>
  </PasswordInformation>
);

PasswordRules.propTypes = {
  /** Error state object */
  errors: PropTypes.shape({
    lengthError: PropTypes.bool,
    lowerError: PropTypes.bool,
    capitalError: PropTypes.bool,
    numberError: PropTypes.bool,
    specialCharacterError: PropTypes.bool,
  }).isRequired,
};

export default PasswordRules;
