/** @deprecated Use "breakpoints" instead */
const size = {
  xs: 599,
  sm: 600,
  ms: 760,
  mds: 959,
  md: 960,
  lg: 1280,
  ml: 1600,
  xl: 1920,
};

/** @deprecated Use "devices" instead */
const device = {
  /** @deprecated Use "devices.phoneS" instead */
  xs: `(max-width: ${size.xs}px)`,
  /** @deprecated Use "devices.phoneS" or "devices.phoneL" instead */
  sm: `(min-width: ${size.sm}px)`,
  /** @deprecated Use "devices.phoneL" instead */
  ms: `(max-width: ${size.ms}px)`,
  /** @deprecated Use "devices.tablet" instead */
  mds: `(max-width: ${size.mds}px)`,
  /** @deprecated Use "devices.tablet" or "devices.desktopS" instead */
  md: `(min-width: ${size.md}px)`,
  /** @deprecated Use "devices.desktopS" or "devices.desktopL" instead */
  lg: `(min-width: ${size.lg}px)`,
  /** @deprecated Use "devices.ultrawide" instead */
  ml: `(min-width: ${size.ml}px)`,
  /** @deprecated Use "devices.ultrawide" instead */
  xl: `(min-width: ${size.xl}px)`,
};

/**
 * Screen width breakpoints (in pixels)
 * @see {@link https://getbootstrap.com/docs/5.0/layout/breakpoints/} - Inspired by bootstrap v5 breakpoints
 */
const breakpoints = {
  /** Portrait phone, width < 576px */
  xs: 575.98,
  /** Landscape phone or small tablet, width < 768px */
  sm: 767.98,
  /** Tablet or small desktop, width < 992px */
  md: 991.98,
  /** Desktop or landscape tablet, width < 1200px */
  lg: 1199.98,
  /** Large desktop, width < 1400px */
  xl: 1399.98,
  /** Larger desktop (ultrawide), width >= 1400px */
  xxl: 1400,
};

/**
 * Devices for media queries
 * @example
 * // Declaration order
 * |@media ${devices.desktopL}{...} // can be omitted
 * |@media ${devices.desktopS}{...} // can be omitted
 * |@media ${devices.tablet}{...}
 * |@media ${devices.phoneL}{...}
 * |@media ${devices.phoneS}{...}
 * |@media ${devices.ultrawide}{...}
 */
const devices = {
  /** Large desktop, width < 1400px */
  desktopL: `(max-width: ${breakpoints.xl}px)`,
  /** Desktop or landscape tablet, width < 1200px */
  desktopS: `(max-width: ${breakpoints.lg}px)`,
  /** Tablet or small desktop, width < 992px */
  tablet: `(max-width: ${breakpoints.md}px)`,
  /** Landscape phone or small tablet, width < 768px */
  phoneL: `(max-width: ${breakpoints.sm}px)`,
  /** Portrait phone, width < 576px */
  phoneS: `(max-width: ${breakpoints.xs}px)`,
  /** Larger desktop (ultrawide), width >= 1400px */
  ultrawide: `(min-width: ${breakpoints.xxl}px)`,
};

export {
  device, size, devices, breakpoints,
};
