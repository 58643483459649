import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';

import CardStyle from './tabs/CardStyle';
import ThemeStyle from './tabs/ThemeStyle';
import Preview from './tabs/Preview';

import {
  MainContainer, Row, MainColumn, TabItem, ScrollContainer,
} from './styled/DashStyles.styled';

const DashStyles = ({
  tempStylesDataState,
}) => {
  const cron = useRef();
  const [tab, setTab] = useState('CARD');
  const [tempStylesData, setTempStylesData] = tempStylesDataState;

  const handleTmpStyle = (key, value) => {
    setTempStylesData((o) => ({
      ...o,
      [key]: value,
    }));
  };

  const handleTimedTmpStyle = (key, value) => {
    clearTimeout(cron.current);
    cron.current = setTimeout(() => {
      handleTmpStyle(key, value);
    }, 200);
  };

  return (
    <MainContainer>
      <Preview tmpStyle={tempStylesData} />
      <MainColumn>
        <Row>
          <TabItem onClick={() => setTab('CARD')} active={tab === 'CARD'}>
            Cards
          </TabItem>
          <TabItem onClick={() => setTab('THEME')} active={tab === 'THEME'}>
            Temas
          </TabItem>
        </Row>
        <ScrollContainer>
          {tab === 'CARD' && (
            <CardStyle
              tmpData={tempStylesData}
              handler={handleTmpStyle}
              timedHandler={handleTimedTmpStyle}
            />
          )}
          {tab === 'THEME' && (
            <ThemeStyle
              tmpData={tempStylesData}
              handler={handleTmpStyle}
              timedHandler={handleTimedTmpStyle}
            />
          )}
        </ScrollContainer>
      </MainColumn>
    </MainContainer>
  );
};

DashStyles.propTypes = {
  tempStylesDataState: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]),
  ),
};

DashStyles.defaultProps = {
  tempStylesDataState: [{}, () => {}],
};

export default DashStyles;
