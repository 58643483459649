import styled, { css } from 'styled-components';
import { devices } from '../../../../juristec-ui/core/breakpoints';

export const MainContainer = styled.div`
  padding: 0 1rem;
  max-width: 1000px;
  overflow-x: hidden;
  overflow-y: hidden;
  user-select: none;
`;

export const ColumnsList = styled.div`
  display: grid;
  position: relative;
  grid-template-columns: min-content minmax(0, 30vw) 130px;
  @media ${devices.phoneS} {
    grid-template-columns: min-content auto 130px;
  }
  grid-gap: .4rem 0;
  max-height: calc(100vh - 380px);
  overflow: auto;
  text-align: center;
  & h4 {
    margin: 0;
  }
  & .select_wrapper {
    height: 35px;
  }
  & .list-header {
    position: sticky;
    z-index: 1;
    top: 0;
    padding: .5rem;
    background-color: ${({ theme }) => theme.background};
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: .5rem;
  justify-content: center;
  background-color: ${({ theme }) => theme.card};
  & > div {
    width: 100%;
  }
  &.revert-btn {
    padding: .5rem .2rem;
    ${({ hasChanges }) => !hasChanges && css`
      & > div {
        pointer-events: none;
      }
    `}
  }
`;
