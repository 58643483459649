import React, { useState } from 'react';
import PropTypes from 'prop-types';
import InputRadio from '../../../juristec-ui/core/InputRadio';

import {
  MoreMemoryContainer,
  RadioGroupContainer,
  RadioInputWrapper,
  SeparatorLine,
} from './styled/MoreMemory.styled';
import ActionsGroup from '../../../juristec-ui/core/ActionsGroup';
import Button from '../../../juristec-ui/core/Button';

/**
 * A component to request the addition of more memory to the instance
 */
const MoreMemory = ({ hide, submitData }) => {
  const [selected, setSelected] = useState('500');

  const handleSubmit = () => {
    submitData(selected);
  };

  return (
    <>
      <MoreMemoryContainer>
        <RadioGroupContainer>
          <RadioInputWrapper>
            <InputRadio
              text="500 MB"
              color="secondary"
              value="500"
              selected={selected}
              onChange={setSelected}
            />
          </RadioInputWrapper>
          <RadioInputWrapper>
            <InputRadio
              text="1 GB"
              color="secondary"
              value="1000"
              selected={selected}
              onChange={setSelected}
            />
          </RadioInputWrapper>
          <RadioInputWrapper>
            <InputRadio
              text="2 GB"
              color="secondary"
              value="2000"
              selected={selected}
              onChange={setSelected}
            />
          </RadioInputWrapper>
          <RadioInputWrapper>
            <InputRadio
              text="5 GB"
              color="secondary"
              value="5000"
              selected={selected}
              onChange={setSelected}
            />
          </RadioInputWrapper>
        </RadioGroupContainer>
        <SeparatorLine />
        <div style={{ textAlign: 'justify' }}>
          <span>
            Você está solicitando a inclusão adição de mais memória em sua instância. Isso implicará
            em alterações no seu plano atual. Entraremos em contato para confirmar sua solicitação
            antes de executar a alteração. Tem certeza que deseja realizar a solicitação?
          </span>
        </div>
      </MoreMemoryContainer>
      <ActionsGroup>
        <Button style={{ margin: '5px' }} onClick={hide} variant="outlined" size="large">
          Cancelar
        </Button>
        <Button style={{ margin: '5px' }} size="large" onClick={handleSubmit}>
          Solicitar
        </Button>
      </ActionsGroup>
    </>
  );
};

MoreMemory.propTypes = {
  /**
   * A function that sends the new data back to the parent
   */
  submitData: PropTypes.func.isRequired,
  /**
   * A function that closes the modal
   */
  hide: PropTypes.func.isRequired,
};

export default MoreMemory;
