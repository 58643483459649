/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';

import InputTextLine from '../../../../juristec-ui/core/InputTextLine';
import TextArea from '../../../../juristec-ui/core/TextArea';
import InputPhoneLine from '../../../../juristec-ui/core/InputPhoneLine';
import Toggle from '../../../../juristec-ui/core/Toggle';
import DatePicker from '../../../../juristec-ui/core/DatePicker/DatePicker';

import { trimString, compactString } from '../../../../juristec-ui/utils/functions/formatString';
import {
  verifyInput,
  verifyEmail,
  verifyPhone,
} from '../../../../juristec-ui/utils/validators/inputTextValidators';

import {
  MainExtra,
  InputWrapper,
  InputWrapperExtra,
} from './styled/NewInstance.styled';

/**
   * Formata o valor do input, a partir do estado informado
   * @param {function} setter Estado a ser atualizado
   * @param {boolean} compact Informa se a formatacao deve remover espacos em branco ou nao
   */
const handleBlurTrim = (setter, compact = false) => {
  setter((old) => ({
    ...old,
    value: compact ? compactString(old.value) : trimString(old.value),
  }));
};

const SecondStep = ({
  contactNameState,
  contactEmailState,
  contactPhoneState,
  obsState,
  trainingState,
  trainingDateState,
  trainingResponsibleState,
}) => {
  // Estados que contem o que sera enviado (declarados no componente pai)
  const [contactName, setContactName] = contactNameState;
  const [contactEmail, setContactEmail] = contactEmailState;
  const [contactPhone, setContactPhone] = contactPhoneState;
  const [obs, setObs] = obsState;
  const [training, setTraining] = trainingState;
  const [trainingDate, setTrainingDate] = trainingDateState;
  const [trainingResponsible, setTrainingResponsible] = trainingResponsibleState;

  /**
   * Lida com o input de nome do contato, verificando a validade e atualiza o estado informado
   * @param {event} e Evento de alteracao do input
   */
  const contactNameHandle = (e) => {
    const val = e.target.value;
    const msg = verifyInput(val, false);
    setContactName({
      value: val,
      error: msg.length !== 0,
      errorMsg: msg,
    });
  };

  /**
   * Lida com o input de email, verificando a validade e atualiza o estado informado
   * @param {event} e Evento de alteracao do input
   */
  const contactEmailHandle = (e) => {
    const val = e.target.value.trim();
    const msg = verifyEmail(val);
    setContactEmail({
      value: val,
      error: msg.length !== 0,
      errorMsg: msg,
    });
  };

  const handlePhone = (val, info) => {
    const numbers = val.replace(/[^\d]/g, '');
    const msg = verifyPhone(numbers, false, info.name);
    setContactPhone({
      value: numbers,
      format: info.format,
      error: msg.length !== 0,
      errorMsg: msg,
    });
  };

  /**
   * Lida com o textarea de observacoes, atualizando o estado informado
   * @param {event} e Evento de alteracao do textarea
   */
  const handleObs = (e) => {
    setObs({ value: e.target.value });
  };

  /**
   * Formata o input de nome do contato quando ele perde o foco
   */
  const contactNameTrim = () => { handleBlurTrim(setContactName); };

  /**
   * Formata o input de email do contato quando ele perde o foco
   */
  const contactEmailTrim = () => { handleBlurTrim(setContactEmail, true); };

  /**
   * Formata o textarea de observacoes quando ele perde o foco
   */
  const obsTrim = () => { handleBlurTrim(setObs); };

  /**
   * Ativa ou desativa a permissao de compartilhar link publico dos dashboards
   */
  const toggleTraining = () => {
    const newValue = !training;
    setTraining((p) => !p);
    if (!newValue) {
      setTrainingDate(null);
      setTrainingResponsible({ value: '', error: false, errorMessage: '' });
    }
  };

  return (
    <>
      <MainExtra>
        <InputWrapperExtra style={{ gridColumn: '1 / span 2' }}>
          <InputPhoneLine
            label="Telefone para contato"
            type="tel"
            value={contactPhone.value}
            errorMessage={contactPhone.errorMsg}
            error={contactPhone.errorMsg.length > 0}
            onChange={handlePhone}
            notTransparent={false}
          />
        </InputWrapperExtra>
        <InputWrapperExtra style={{ gridColumn: '3 / span 3' }}>
          <InputTextLine
            label="Email para contato"
            onChange={contactEmailHandle}
            onBlur={contactEmailTrim}
            value={contactEmail.value}
            error={contactEmail.errorMsg.length > 0}
            errorMessage={contactEmail.errorMsg}
          />
        </InputWrapperExtra>
        <InputWrapperExtra style={{ gridColumn: '1 / span 5' }}>
          <InputTextLine
            label="Nome do contato"
            onChange={contactNameHandle}
            onBlur={contactNameTrim}
            value={contactName.value}
            error={contactName.errorMsg.length > 0}
            errorMessage={contactName.errorMsg}
          />
        </InputWrapperExtra>
        <InputWrapper className="toggle" style={{ gridColumn: '1 / span 2' }}>
          <span className="custom-label">Treinamento realizado?</span>
          <Toggle
            checked={training}
            handleChange={toggleTraining}
            firstColor="primary"
            size="small"
          />
        </InputWrapper>
        <InputWrapper className="inline" style={{ gridColumn: '3 / span 3' }}>
          <span className="custom-label">Data do treinamento</span>
          <DatePicker
            value={trainingDate}
            onChange={setTrainingDate}
            label=""
            style={{ width: '50%' }}
            readOnly={false}
            dateFormat="dd/MM/yyyy"
            atModal
          />
        </InputWrapper>
        <InputWrapper style={{ gridColumn: '1 / span 5' }}>
          <InputTextLine
            label="Responsável pelo treinamento"
            onChange={(e) => setTrainingResponsible({
              value: e.target.value,
              error: false,
              errorMsg: '',
            })}
            value={trainingResponsible.value}
            error={trainingResponsible.error}
            errorMessage={trainingResponsible.errorMsg}
          />
        </InputWrapper>
        <div style={{ height: '20vh', paddingTop: '.5rem', gridColumn: '1 / span 5' }}>
          <InputWrapperExtra style={{ height: '100%' }}>
            <TextArea
              label="Observações"
              name="observations"
              value={obs.value}
              onChange={handleObs}
              onBlur={obsTrim}
            />
          </InputWrapperExtra>
        </div>
      </MainExtra>
    </>
  );
};

SecondStep.propTypes = {
  contactNameState: PropTypes.arrayOf(PropTypes.any).isRequired,
  contactEmailState: PropTypes.arrayOf(PropTypes.any).isRequired,
  contactPhoneState: PropTypes.arrayOf(PropTypes.any).isRequired,
  obsState: PropTypes.arrayOf(PropTypes.any).isRequired,
  trainingState: PropTypes.arrayOf(PropTypes.any).isRequired,
  trainingDateState: PropTypes.arrayOf(PropTypes.any).isRequired,
  trainingResponsibleState: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default SecondStep;
