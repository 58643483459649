/* eslint-disable indent */
import styled, { css } from 'styled-components';

const getContrastYIQ = (hexcolor, dark, light) => {
  // eslint-disable-next-line no-param-reassign
  hexcolor = hexcolor.replace('#', '');
  const r = parseInt(hexcolor.substr(0, 2), 16);
  const g = parseInt(hexcolor.substr(2, 2), 16);
  const b = parseInt(hexcolor.substr(4, 2), 16);
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128 ? dark : light;
};

const getFontColor = (hexcolor) => getContrastYIQ(hexcolor, '#000', '#FFF');

export const MainContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const KpiContainer = styled.div`
  max-width: calc(100% - 116px);
  max-height: calc(100% - 116px);
  display: flex;
  flex-direction: column;
  overflow: auto;
  scrollbar-width: none !important;
  &::-webkit-scrollbar {
    height: 0;
    width: 0;
  }
  ${({ allowDrag }) => allowDrag && css`
    cursor: grab;
  `};
`;

export const KpiWrapper = styled.div`
  border: 0px;
  padding: 0px;
  margin: 0px;
  height: 100%;
  overflow: hidden;
  width: 100%;
`;

export const KpiTitle = styled.div`
  border: 0px;
  padding: 0px;
  margin: 0px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  writing-mode: horizontal-tb;
  text-orientation: mixed;
`;

export const KpiCard = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: auto;
  ${({
    size, titleFontSize, showTitle, kpiType,
  }) => css`
    height: ${size.rHeight}px;
    width: ${size.rWidth}px;
    overflow: ${['label', 'smartLabel', 'Table'].includes(kpiType) ? 'hidden' : 'initial'};

    ${KpiTitle} {
      height: ${`max(${titleFontSize}px, 10%)`};
    }

    ${KpiWrapper} {
      height: ${showTitle ? (
        `calc(100% - max(${titleFontSize}px, 10%)${kpiType === 'SubsetTable' ? ' - 35px' : ''})`
      ) : (
        `calc(100% ${kpiType === 'SubsetTable' ? ' - 35px' : ''})`
      )};
    }
  `}
`;

export const ActionButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  position: absolute;
  z-index: 1050;
  & .fix-icon svg{
    height: auto;
    width: auto;
  }
  &.top-right {
    top: 10px;
    right: 10px;
  }
  &.middle-left {
    top: 0;
    bottom: 0;
    left: 5px;
    margin: 20% 0;
    & svg {
      transform: rotate(180deg);
    }
  }
  &.middle-right {
    top: 0;
    bottom: 0;
    right: 5px;
    margin: 20% 0;
  }
  &.bottom-center {
    bottom: 10px;
    right: 0;
    left: 0;
    gap: 5px;
  }
  &.bottom-left {
    bottom: 10px;
    left: 10px;
  }
`;

export const BtnText = styled.span`
  color: ${({ theme }) => theme.white};
  font-weight: bold;
  padding: 7px 0;
`;

export const CommentArea = styled.p`
  font-size: 1rem;
  text-align: justify;
  white-space: break-spaces;
  margin: 2px 0;
  padding: 10px;
`;

export const PaginationWrapper = styled.div`
  width: 100%;
  ${({ bgColor }) => css`
    .tableWrapperPagination {
      padding: 0;
      & button {
        padding: 2px;
      }
      & span {
        color: ${getFontColor(bgColor)}
      }
    }
  `}
`;
