import React from 'react';
import PropTypes from 'prop-types';

import ControllersGroup from '../../../controllers/ControllersGroup';
import {
  RangeController,
  ChoiceBoxController,
} from '../../../controllers/Base';

const GridControllers = ({
  handle,
  config,
  extend,
  setExtend,
}) => (
  <>
    <ControllersGroup title="Grid" id="axis" extend={extend} setExtend={setExtend}>
      <ChoiceBoxController
        label="Formato do grid"
        handle={handle}
        kWord="GridShapeControl"
        options={[
          { label: 'Circular', value: 'circular', id: 'circular' },
          { label: 'Poligonal', value: 'linear', id: 'linear' },
        ]}
        configValue={config?.GridShapeControl}
        fullWidth
      />
      <RangeController
        label="Número de níveis"
        handle={handle}
        kWord="GridLevelControl"
        configValue={config?.GridLevelControl?.value}
        min={1}
        max={12}
        fullWidth
      />
      <RangeController
        label="Distância das categorias"
        handle={handle}
        kWord="GridLabelOffsetControl"
        configValue={config?.GridLabelOffsetControl?.value}
        min={0}
        max={60}
        fullWidth
      />
    </ControllersGroup>
  </>
);

GridControllers.propTypes = {
  config: PropTypes.objectOf(PropTypes.any),
  handle: PropTypes.func,
  extend: PropTypes.string,
  setExtend: PropTypes.func,
};

GridControllers.defaultProps = {
  config: undefined,
  handle: () => {},
  extend: '',
  setExtend: () => {},
};

export default GridControllers;
