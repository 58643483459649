import React from 'react';
import PropTypes from 'prop-types';

import Select from '../../../../juristec-ui/core/Select';

import {
  FirstPageContainer,
  SelectContainerFile,
  GridContainer,
  TypesContainer,
  TypeTooltip,
  ErrorMsg,
  HeaderTips,
} from './styled/FirstStep.styled';

import {
  Number, Letters, DateIcon,
} from '../../../../juristec-ui/icons';

const labelTypes = {
  abc: 'Texto',
  float: 'Número',
  date: 'Data',
};

const labelIcons = {
  float: <Number />,
  abc: <Letters />,
  date: <DateIcon />,
};

const genErrorMsg = (selected, fileColumns) => {
  const msg = [];
  Object.keys(labelTypes).forEach((cType) => {
    const size = selected.columnsType[cType]?.length || 0;
    const fSize = fileColumns[cType]?.length || 0;
    if (fSize < size) {
      msg.push(`${size} coluna${size > 1 ? 's' : ''} de ${labelTypes[cType].toUpperCase()}`);
    }
  });
  return ` ${msg.join(', ')}`;
};

/**
 * First step for the 'ApplyTemplate'. This is where the file is chosen.
 */
const FirstStep = ({
  files,
  selected,
  selectedFile,
  setSelectedFile,
  fileColumns,
  checkColumnsVar,
}) => (
  <FirstPageContainer>
    <HeaderTips>
      Selecione abaixo o arquivo base para aplicar o template.
    </HeaderTips>
    <GridContainer>
      <SelectContainerFile>
        <Select
          placement="start"
          selectLabel="Arquivo"
          options={files.map((f) => ({
            ...f,
            id: f.file_id,
            value: f.file_id,
            label: f.filename.replace('.metrics', ''),
          }))}
          value={selectedFile}
          onChange={setSelectedFile}
          placeholder="Selecione o arquivo"
          alphabeticalOrder
          isSearchable
          fullWidth
          atModal
        />
      </SelectContainerFile>
      <TypesContainer>
        {Object.keys(labelTypes).map((cType) => {
          const size = selected.columnsType[cType]?.length || 0;
          const fSize = fileColumns[cType]?.length || 0;
          if (size < 0) return null;
          return (
            <TypeTooltip
              atModal
              key={cType}
              direction="top"
              typeLabel={cType}
              isLess={fSize < size}
              text={`
                ${fSize} coluna${fSize > 1 ? 's' : ''} de ${labelTypes[cType].toUpperCase()} (${size} necessária${size > 1 ? 's' : ''})
              `}
            >
              <div>
                {labelIcons[cType]}
              </div>
              <h4>{`${fSize}/${size}`}</h4>
            </TypeTooltip>
          );
        })}
      </TypesContainer>
    </GridContainer>
    {!checkColumnsVar() && selectedFile.value?.length > 0 && (
      <ErrorMsg>
        Este template necessita de um arquivo com no mínimo
        <b>{genErrorMsg(selected, fileColumns)}</b>
      </ErrorMsg>
    )}
  </FirstPageContainer>
);

FirstStep.propTypes = {
  /**
   * Array of files uploaded
   */
  files: PropTypes.arrayOf(PropTypes.shape({})),
  /**
   * Template selected
   */
  selected: PropTypes.shape({
    columnsType: PropTypes.objectOf(
      PropTypes.arrayOf(PropTypes.string),
    ),
  }),
  /**
   * Object that represents a file selected
   */
  selectedFile: PropTypes.shape({
    value: PropTypes.string,
  }),
  /**
   * Function that deals with the selection of a file
   */
  setSelectedFile: PropTypes.func.isRequired,
  /**
   * Object of columns names of the selected file
   */
  fileColumns: PropTypes.objectOf(
    PropTypes.arrayOf(PropTypes.string),
  ),
  /**
   * Function that checks the template variable against the file columns
   */
  checkColumnsVar: PropTypes.func.isRequired,
};

FirstStep.defaultProps = {
  files: [],
  selected: {},
  selectedFile: {},
  fileColumns: {},
};

export default FirstStep;
