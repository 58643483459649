import styled from 'styled-components';

export const InputNumContainer = styled.div`
  position: relative;
  width: auto;
  display: flex;
  justify-content: end;
  align-items: center;
  & .inputTxt {
    padding-right: 28px;
    padding-left: ${({ isMoney }) => (isMoney ? 20 : 0)}px;
  }
`;

export const MoneySign = styled.div`
  position: absolute;
  bottom: 6px;
  left: 0;
  font-size: .8rem;
  color: ${({ theme }) => theme.grey};
`;

export const NumberControls = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 2px;
  bottom: 2px;
`;

NumberControls.Button = styled.button`
  height: 14px;
  width: 20px;
  padding: 0;
  border: none;
  background-color: ${({ theme }) => theme.inputNumberControl};
  &:hover {
    background-color: ${({ theme }) => theme.inputNumberControlHover};
  }
  & svg {
    height: 15px;
    width: 15px;
    fill: ${({ theme }) => theme.grey};
  }
  &.number-increase {
    transform: rotate(180deg);
  }
`;
