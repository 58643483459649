import styled, { css } from 'styled-components';
import { devices } from '../../breakpoints';

const shapes = {
  square: '0',
  rounded: '8px',
  circle: '20px',
  getShape(shape) {
    return this[shape] || this.rounded;
  },
};

const sizes = {
  small: { padding: '2px 4px', 'font-size': '14px' },
  medium: { padding: '4px 8px', 'font-size': '16px' },
  large: { padding: '8px 16px', 'font-size': '18px' },
  getSize(size) {
    return this[size] || this.medium;
  },
};

const textStyle = {
  capitalize: 'capitalize',
  uppercase: 'uppercase',
  getText(text) {
    return this[text] || this.capitalize;
  },
};

const StyledBadge = styled.button`
  border-style: none;
  border: 2px solid;
  cursor: text;
  font-weight: 600;
  outline: 0;
  min-width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: none;

  @media ${devices.phoneS} {
    min-width: 120px;
  }

  ${({
    size, color, shape, textTransform, theme,
  }) => css`
    color: ${theme[color] || color};
    background-color: ${`${theme[color] || color}20`};
    border-color: ${theme[color] || color};
    border-radius: ${shapes.getShape(shape)};
    padding: ${sizes.getSize(size).padding};
    text-transform: ${textStyle.getText(textTransform)};
    font-size: ${sizes.getSize(size)['font-size']};
    & > svg {
      fill: ${color};
      width: ${size};
      height: ${size};
      pointer-events: none;
    }
  `}

  div {
    display: flex;
    align-items: center;
  }
`;

export default StyledBadge;
