/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
import React from 'react';
import PropTypes from 'prop-types';
import { PaginationContainer, LegendsContainer } from './styled/FilePaginationContainer.styled';
import TablePagination from '../../juristec-ui/core/TablePagination';

const FilePaginationContainer = ({ page, setPage, totalPages }) => (
  <PaginationContainer>
    <TablePagination page={page} setPage={setPage} totalPages={totalPages} />
    <LegendsContainer>
      <div className="legend-type">
        <div className="square legend-texto" />
        Texto
      </div>
      <div className="legend-type">
        <div className="square legend-numero" />
        Número
      </div>
      <div className="legend-type">
        <div className="square legend-date" />
        Data
      </div>
    </LegendsContainer>
  </PaginationContainer>
);

FilePaginationContainer.propTypes = {
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  totalPages: PropTypes.number.isRequired,
};

export default FilePaginationContainer;
