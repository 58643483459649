import React from 'react';
import PropTypes from 'prop-types';

const Connect = ({ width, height }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 27 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.0033 0.644917C16.1258 0.645198 18.7206 3.24 18.7209 6.36246C18.7209 9.17068 16.6905 11.6166 13.9216 12.0772L13.8458 12.0912C13.155 12.2036 12.4979 11.7374 12.3855 11.0466C12.2704 10.3529 12.7366 9.69582 13.4274 9.58349L13.5032 9.56945C15.0477 9.31109 16.1794 7.97719 16.1794 6.4102C16.1794 4.64664 14.7444 3.21163 12.9781 3.20882L6.67361 3.21163C4.92942 3.18608 3.47195 4.64355 3.47223 6.41301C3.46943 7.97719 4.60395 9.3139 6.14566 9.56945L6.24676 9.55822C6.91512 9.69863 7.38409 10.3529 7.26614 11.0438C7.15101 11.7374 6.49669 12.2064 5.80306 12.0912L5.72724 12.0772C2.93305 11.5914 0.930792 9.21842 0.928265 6.41329C0.929136 3.24087 3.50147 0.668535 6.67108 0.670472L13.0033 0.644917ZM14.2979 15.9188C11.1246 15.9188 8.55512 13.3493 8.55512 10.176C8.55512 7.3678 10.5855 4.97239 13.3544 4.51184L13.4302 4.4978C14.0957 4.3602 14.7529 4.82636 14.8905 5.54246C15.0309 6.21081 14.5647 6.86794 13.8486 7.00554L13.7728 7.01958C12.2535 7.25267 11.0966 8.61185 11.0966 10.1788C11.0966 11.9424 12.5316 13.3774 14.2979 13.3802L20.6024 13.3774C22.3716 13.3774 23.829 11.9199 23.8038 10.176C23.8057 8.61269 22.6723 7.27485 21.1303 7.01958L21.0545 7.00554C20.3609 6.89041 19.9172 6.21082 20.0104 5.5447C20.125 4.85164 20.779 4.38294 21.4718 4.49892L21.5482 4.5124C24.3168 4.97326 26.3466 7.36921 26.3469 10.1771C26.348 13.3493 23.7757 15.9217 20.6052 15.9188L14.2979 15.9188Z" />
    </svg>
  );
};

Connect.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

Connect.defaultProps = {
  width: '25px',
  height: '25px',
};

export default Connect;
