import React from 'react';

// import { Container } from './styles';

function Visibility() {
  return (
    <svg width="30" height="24" viewBox="0 0 30 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.9974 0C10.7894 0 7.41993 1.9165 4.96702 4.19755C2.52973 6.45777 0.899662 9.16587 0.128895 11.0251C-0.042965 11.4365 -0.042965 11.8948 0.128895 12.3062C0.899662 14.1654 2.52973 16.8735 4.96702 19.1338C7.41993 21.4148 10.7894 23.3313 14.9974 23.3313C19.2054 23.3313 22.5749 21.4148 25.0278 19.1338C27.4651 16.8683 29.0951 14.1654 29.8711 12.3062C30.043 11.8948 30.043 11.4365 29.8711 11.0251C29.0951 9.16587 27.4651 6.45777 25.0278 4.19755C22.5749 1.9165 19.2054 0 14.9974 0ZM7.49805 11.6657C7.49805 9.6767 8.28816 7.76921 9.69456 6.36281C11.101 4.95641 13.0084 4.16631 14.9974 4.16631C16.9863 4.16631 18.8938 4.95641 20.3002 6.36281C21.7066 7.76921 22.4967 9.6767 22.4967 11.6657C22.4967 13.6546 21.7066 15.5621 20.3002 16.9685C18.8938 18.3749 16.9863 19.165 14.9974 19.165C13.0084 19.165 11.101 18.3749 9.69456 16.9685C8.28816 15.5621 7.49805 13.6546 7.49805 11.6657ZM14.9974 8.33261C14.9974 10.171 13.5027 11.6657 11.6644 11.6657C11.2946 11.6657 10.9405 11.6032 10.6072 11.4938C10.3207 11.4001 9.98741 11.5771 9.99783 11.8792C10.0135 12.2385 10.0655 12.5979 10.1645 12.9572C10.878 15.6236 13.6225 17.2068 16.289 16.4934C18.9554 15.7799 20.5386 13.0353 19.8251 10.3689C19.247 8.20762 17.3357 6.75462 15.2109 6.66609C14.9089 6.65567 14.7318 6.98377 14.8255 7.27541C14.9349 7.60871 14.9974 7.96285 14.9974 8.33261Z" />
    </svg>
    // <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" width="24px" height="24px">
    //   <path d="M0 0h24v24H0z" fill="none" />
    //   <path d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z" />
    // </svg>
  );
};

export default Visibility;
