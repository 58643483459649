import styled, { css } from 'styled-components';
import { devices } from '../../../../core/breakpoints';

export const StyledGridKpiCard = styled.div`
  height: 100%;
  & > div {
    height: 100%;
  }
`;

export const Comment = styled.p`
  font-size: 1rem;
  text-align: justify;
  white-space: break-spaces;
  margin: 2px 0;
  padding: 5px 3px;
  ${(props) => props.isOwner && props.story && css`
    cursor: pointer;
    &:hover {
      background-color: lightgrey;
    }
  `}
`;

export const MenuCard = styled.div`
  user-select: none;
  display: flex;
  padding: 5px;
  box-sizing: border-box;
  margin: 0px;
  /* position: absolute; */
  visibility: visible;
  right: 0px;
  top: 0px;
  bottom: 0px;
  height: 100%;
  flex-direction: column;
  background-color: ${({ theme }) => theme.popoverBackground};
  & .menuItemBottom {
    margin-top: auto;
  }
  & .kpi-options {
    @media ${devices.tablet} {
      transform: rotate(0) !important;
    }
  }
`;
