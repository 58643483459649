import React, {
  useState, useRef, useEffect, useContext,
} from 'react';
import PropTypes from 'prop-types';

import { useTheme } from 'styled-components';
import { AlertContext } from '../../../context/AlertProvider';

import formatValue from '../../../utils/functions/formatTableValue';

// components
import DataTable from '../../../juristec-ui/core/DataTable';
import IconButton from '../../../juristec-ui/core/IconButton';
import InputNumberLine from '../../../juristec-ui/core/InputNumberLine';
import ActionsGroup from '../../../juristec-ui/core/ActionsGroup';
import Button from '../../../juristec-ui/core/Button';

// Others
import {
  DateIcon, Letters, Number,
} from '../../../juristec-ui/icons';
import {
  MainContainer,
  ScrollXContainer,
  IconWrapper,
  ModalHeader,
} from './styled/FileVisualize';

const typeIcon = {
  number: <Number />,
  text: <Letters />,
  date: <DateIcon />,
};

const legacyTypes = {
  float64: 'number',
  int64: 'number',
  object: 'text',
  category: 'text',
  'datetime64[ns]': 'date',
  get(k) {
    return this[k] || k;
  },
};

const FileVisualize = ({
  fileData, reopenFile, fileName, handleSubmit, hide,
}) => {
  const scrollRef = useRef(null);
  const inputRef = useRef(null);
  const cronRef = useRef(null);
  const theme = useTheme();

  const { setAlertConfig } = useContext(AlertContext);

  const [blockInput, setBlockInput] = useState(false);
  const [inputValue, setInputValue] = useState(1);
  const [fileViewData, setFileViewData] = useState(fileData);

  const [columnTypes, setColumnTypes] = useState(
    Object.keys(fileViewData.types).reduce((acc, cur) => {
      acc[cur] = {
        valueType: legacyTypes.get(fileViewData.types[cur]),
        format: '',
      };
      return acc;
    }, {}),
  );

  const getColor = (type) => {
    if (theme) {
      switch (type) {
        case 'number':
          return theme.typeNumber;
        case 'date':
          return theme.typeDate;
        default:
          return theme.typeText;
      }
    } return 'grey';
  };

  // useEffect(() => {
  //   const scrollToHorizontal = (e) => {
  //     if (!e.deltaY) return;
  //     e.preventDefault();
  //     scrollRef.current.scrollLeft += e.deltaY + e.deltaX;
  //   };

  //   scrollRef.current.addEventListener('wheel', scrollToHorizontal, { passive: false });
  //   return () => scrollRef.current.removeEventListener('wheel', scrollToHorizontal);
  // }, []);

  // Seletor de tipos no header da tabela
  const TypePicker = (field) => (
    <IconButton
      shape="rounded"
      variant="contained"
      color={getColor(legacyTypes.get(columnTypes[field])?.valueType)}
      style={{ padding: '0 5px', marginLeft: '5px', cursor: 'default' }}
    >
      <IconWrapper>{typeIcon[legacyTypes.get(columnTypes[field])?.valueType]}</IconWrapper>
    </IconButton>

  );

  const handleReOpen = async (value) => {
    setBlockInput(true);
    // const res = await reopenFile(filename, selectedDecimal?.value || ',', value);
    const res = await reopenFile(fileName, ',', value);
    if (res.error) {
      setAlertConfig({
        type: 'error',
        text: res.msg,
        child: res.raw,
      });
      setInputValue(inputRef?.current?.lastvalue || 1);
    } else {
      /* const aux = Object.keys(res.res.types).reduce((acc, cur) => {
        acc[cur] = {
          valueType: legacyTypes.get(res.res.types[cur]),
          format: '',
        };
        return acc;
      }, {});
      setColumnTypes(aux); */
      setFileViewData(res.res);
      inputRef.current.lastvalue = value + 1;
    }
    setBlockInput(false);
  };

  const handleStartLine = (value) => {
    if (value.length === 0) {
      setInputValue(value);
      return;
    }
    if (value === '0') {
      value = 1;
      setInputValue(1);
    } else if (/^[0-9]+$/.test(value)) {
      setInputValue(value);
    }

    if (value === inputValue) return;

    clearTimeout(cronRef.current);
    cronRef.current = setTimeout(() => {
      if (value) handleReOpen(value - 1);
    }, 300);
  };

  useEffect(() => {
    if (!blockInput) inputRef.current.focus(); // para qnd sair do disable
  }, [blockInput]);

  const submitUpdate = () => {
    handleSubmit(inputValue - 1);
    hide();
  };

  return (
    <>
      <MainContainer className="file-visualizer-main">
        <ModalHeader>
          <span>
            Pronto! A seguir, você precisa apenas verificar a partir de qual linha sua tabela é iniciada.
            Para isto, verifique se os nomes das colunas conferem e, se necessário,
            altere o número da linha inicial de seu arquivo.
          </span>
          <div className="container-input-select">
            <InputNumberLine
              ref={inputRef}
              label="Iniciar a partir da linha"
              disabled={blockInput}
              value={inputValue}
              onChange={(e) => handleStartLine(e.target.value)}
              setValue={handleStartLine}
              min={1}
            />
          </div>
        </ModalHeader>
        <ScrollXContainer ref={scrollRef} className="file-visualizer-scroll">
          <DataTable
            columns={fileViewData.columns.reduce((aux, column, i) => {
              if (column === 'index') return aux;
              aux.push({
                ...column,
                label: column,
                field: column,
                tools: TypePicker(column),
                dataColor: getColor(legacyTypes.get(columnTypes[column]?.valueType)),
                valueGetter: (param) => formatValue(param[i], columnTypes[column]?.valueType),
              });
              return aux;
            }, [])}
            rowData={fileViewData.data.slice(0, 5)}
            headerColor="transparent"
            theadStyle={{
              position: 'sticky',
              top: '-5px',
              zIndex: 1,
              backgroundColor: theme.modal,
            }}
          />
        </ScrollXContainer>
      </MainContainer>
      <ActionsGroup>
        <Button
          style={{ margin: '5px' }}
          onClick={hide}
          variant="outlined"
          size="large"
        >
          Cancelar
        </Button>
        <Button style={{ margin: '5px' }} size="large" onClick={submitUpdate}>
          Atualizar
        </Button>
      </ActionsGroup>
    </>
  );
};

FileVisualize.propTypes = {
  /**
     * A object that contains the data of the file (columns, rows, types)
     */
  fileData: PropTypes.shape({
    columns: PropTypes.arrayOf(
      PropTypes.string,
    ),
    data: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
    types: PropTypes.objectOf(
      PropTypes.string,
    ),
  }).isRequired,
};

export default FileVisualize;
