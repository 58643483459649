import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import Button from '../../../juristec-ui/core/Button';

import urlHash from '../../../juristec-ui/utils/functions/urlHash';

import {
  MainContainer,
  SeparatorLine,
  Board,
  NotificationCard,
  CategoryTag,
  EmptyNotificationCard,
} from './styled/Notifications.styled';

import { formatDateTime } from '../../../juristec-ui/utils/functions/lab';
import Loading from '../../../juristec-ui/core/MiniLoading';

const typeToSufix = {
  kpi: 'KPI',
  dash: 'Dashboard',
  user: 'Usuário',
  mural: 'Mural',
  support: 'Suporte',
  get(k) {
    return this[k] || k;
  },
};

const actionToPrefix = {
  update: 'Atualização de',
  create: 'Criação de',
  delete: 'Remoção do',
  remove: 'Remoção do',
  share: 'Compartilhamento de',
  mention: 'Menção no',
  reply: 'Resposta do',
  get(k) {
    return this[k] || k;
  },
};

const getCategory = (notification) => {
  let prefix = '';
  let sufix = '';

  prefix = actionToPrefix.get(notification.action);
  sufix = typeToSufix.get(notification.type);

  return `${prefix} ${sufix}`;
};

const getCustomMessage = (notif) => {
  if (notif.type === 'support' && notif.action === 'reply') {
    const splittedLink = notif.link.split('/') || [''];
    return (
      <>
        {`O ticket ${splittedLink[splittedLink.length - 1]} foi respondido:`}
        <br />
        <i>
          {notif.message.length > 200 ? `${notif.message?.substring(0, 197)}...` : notif.message}
        </i>
      </>
    );
  }
  return notif.message;
};

/**
 * A component that shows notifications received from a database
 */
const Notifications = ({
  userInfo,
  haveMore,
  notificationsLoading,
  notificationList,
  moreNotifications,
  closePopover,
  ...props
}) => {
  const history = useHistory();

  const getLink = (notification) => {
    if (notification.link) return notification.link;
    if (notification.action === 'delete' || notification.action === 'remove') {
      return '';
    }
    if (notification.type === 'kpi') {
      return (`/home/${urlHash(`${`${notification.ownerId}_${notification.dashId}`}/${notification.ownerId}`)}`);
    } if (notification.type === 'dash') {
      return ('/home');
    } if (notification.type === 'mural') {
      return (`/home/${urlHash(`${`${notification.ownerId}_${notification.targetId}`}/${notification.ownerId}`)}`);
    }
    return '';
  };

  const handleClick = (e, link) => {
    if (!link) {
      e.preventDefault();
      return;
    }

    if (link.startsWith('https')) {
      window.open(link, '_blank', 'noopener,noreferrer');
      return;
    }

    history.push(link);
    closePopover();
  };

  const handleMore = (e) => {
    e.stopPropagation();
    moreNotifications();
  };

  return (
    <MainContainer>
      <h2>Notificações</h2>
      <SeparatorLine />
      <Board>
        {notificationList?.length > 0 ? (
          notificationList.map((notif) => {
            const link = getLink(notif);
            return (
              <NotificationCard
                key={notif.id}
                isNew={!notif.viewed[userInfo.id]}
                $action={notif.action}
                onClick={(e) => handleClick(e, link)}
                {...props}
              >
                <CategoryTag>
                  <span style={{ textTransform: 'uppercase' }}>{getCategory(notif)}</span>
                </CategoryTag>
                <p className="message">{getCustomMessage(notif)}</p>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <div className="date">{formatDateTime(notif.timestamp, { time: 'half' })}</div>
                  {!notif.viewed[userInfo.id] && (
                    <div className="new">Novo!</div>
                  )}
                </div>
              </NotificationCard>
            );
          })
        ) : (
          !notificationsLoading && (
            <EmptyNotificationCard>
              <span>Você ainda não recebeu nenhuma notificação</span>
            </EmptyNotificationCard>
          )
        )}
        {haveMore && !notificationsLoading && (
          <EmptyNotificationCard>
            <Button disabled={!haveMore || notificationsLoading} onClick={handleMore}>
              Ver mais
            </Button>
          </EmptyNotificationCard>
        )}
        {notificationsLoading && (
          <EmptyNotificationCard>
            <div style={{ margin: 'auto' }}>
              <Loading fill="primary" />
            </div>
          </EmptyNotificationCard>
        )}
      </Board>
    </MainContainer>
  );
};

Notifications.propTypes = {
  /**
   * A array of notifications object
   */
  notificationList: PropTypes.arrayOf(PropTypes.shape({})),
  /**
   * A function to load more notifications when called
   */
  moreNotifications: PropTypes.func,

  /**
   * bool that says if have more notifications to load. If false, block the load more button.
   */
  haveMore: PropTypes.bool,

  /**
   * bool that says when the notifications is in a loading/waiting state
   */
  notificationsLoading: PropTypes.bool,

  /**
   *  Function to close the popover
   */
  closePopover: PropTypes.func,
  /**
   * user object, id is necessary
   */
  userInfo: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
};

Notifications.defaultProps = {
  moreNotifications: () => {},
  notificationList: [],
  haveMore: false,
  notificationsLoading: false,
  closePopover: () => {},
};

export default Notifications;
