import React, { useState, useEffect, useRef } from 'react';
import { useTheme } from 'styled-components';
import PropTypes from 'prop-types';
import Button from '../Button';
import IconButton from '../IconButton';
import InputTextLine from '../InputTextLine';
import ActionsGroup from '../ActionsGroup';
import Loading from '../MiniLoading';
import { Last, Next, Send } from '../../icons';

import {
  SearchBarContainer,
  CenterContainer,
  ImageBox,
  ImageGallery,
  ImageGalleryPagination,
  ImageCredits,
  ImageGalleryFooter,
} from './styled/UnsplashPicker.styled';

/**
 * Component to display provided images from Unsplash API
 */
const UnsplashPicker = ({
  closeModal, selectImg, defaultSearch, tourContext = { tourOpen: false },
}) => {
  const [image, setImage] = useState(null);
  const [search, setSearch] = useState(defaultSearch);
  const [result, setResult] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const clientId = process.env.REACT_APP_UNSPLASH_API;
  const clientName = process.env.REACT_APP_UNSPLASH_APP;

  const theme = useTheme();

  const inputRef = useRef(null);

  const handleSubmit = () => {
    setCurrentPage(1);
    setSearch(inputRef.current.value || defaultSearch);
  };

  const handleEnterKey = (e) => {
    if (e.keyCode === 13) {
      handleSubmit();
    }
  };

  const handleChoice = () => {
    setIsLoading(true);
    fetch(`${image.links.download_location}&client_id=${clientId}`)
      .then(() => {
        selectImg(`${image.urls.small}&fm=jpg`);
        setIsLoading(false);
        if (tourContext.tourOpen) tourContext.nextStep();
        closeModal();
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    const url = `https://api.unsplash.com/search/photos?page=${currentPage}&per_page=6&query=${search}&client_id=${clientId}`;

    const fetchData = () => {
      fetch(url)
        .then((res) => res.json())
        .then((data) => {
          setResult(data);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log('Não foi possível realizar a busca!', err);
        });
    };
    setIsLoading(true);
    setImage(null);
    fetchData();
  }, [search, currentPage]);

  if (tourContext.tourOpen) tourContext.refreshTour();

  const tagSelected = (id) => id === image?.id;

  return (
    <>
      <div
        style={{
          display: 'flex', justifyContent: 'flex-start', alignItems: 'center',
        }}
      >
        <SearchBarContainer>
          <InputTextLine
            ref={inputRef}
            placeholder="Buscar imagem"
            autoComplete="off"
            onKeyDown={handleEnterKey}
          />
          <IconButton color="primary" shape="rounded" type="submit" onClick={handleSubmit}>
            <Send />
          </IconButton>
        </SearchBarContainer>
      </div>
      {// eslint-disable-next-line no-nested-ternary
      isLoading ? (
        <CenterContainer>
          <Loading fill={theme.primary} />
        </CenterContainer>
      ) : (
        result.total > 0 ? (
          <ImageGallery>
            {result.results.map((picture) => (
              <ImageBox
                key={picture.id}
                src={`${picture.urls.thumb}&fm=jpg`}
                selected={tagSelected(picture.id)}
                onClick={() => setImage(picture)}
              >
                <div className="card-footer">
                  <span>
                    {picture.user.name}
                  </span>
                </div>
              </ImageBox>
            ))}
          </ImageGallery>
        ) : (
          <CenterContainer>
            <span>Nenhuma imagem foi encontrada</span>
          </CenterContainer>
        ))
      }
      <ImageGalleryFooter>
        <ImageCredits>
          Fornecido por
          {' '}
          <a
            href={`https://unsplash.com/?utm_source=${clientName}&utm_medium=referral`}
            target="_blank"
            rel="noreferrer noopener"
          >
            Unsplash
          </a>
          .
          {' '}
          {image && (
            <>
              Foto de
              {' '}
              <a
                href={`${image.user.links.html}?utm_source=${clientName}&utm_medium=referral`}
                target="_blank"
                rel="noreferrer noopener"
              >
                {image.user.name}
              </a>
              .
            </>
          )}
        </ImageCredits>
        {result.total > 0 && (
          <ImageGalleryPagination>
            <IconButton
              color="primary"
              size="small"
              shape="rounded"
              disabled={currentPage === 1}
              onClick={() => setCurrentPage(1)}
              className="invert"
            >
              <Last style={{ transform: 'rotate(180deg)' }} />
            </IconButton>
            <IconButton
              color="primary"
              size="small"
              shape="rounded"
              disabled={currentPage === 1}
              onClick={() => setCurrentPage((p) => --p)}
              className="invert"
            >
              <Next style={{ transform: 'rotate(180deg)' }} />
            </IconButton>
            <p
              style={{ margin: '0 5px', whiteSpace: 'nowrap' }}
            >
              {'Página '}
              {currentPage}
              {' '}
              {'de '}
              {result.total_pages}
            </p>
            <IconButton
              color="primary"
              size="small"
              shape="rounded"
              disabled={currentPage === result.total_pages}
              onClick={() => setCurrentPage((p) => ++p)}
            >
              <Next />
            </IconButton>
            <IconButton
              color="primary"
              size="small"
              shape="rounded"
              disabled={currentPage === result.total_pages}
              onClick={() => setCurrentPage(result.total_pages)}
            >
              <Last />
            </IconButton>
          </ImageGalleryPagination>
        )}
      </ImageGalleryFooter>
      <ActionsGroup>
        <Button
          style={{ margin: '5px' }}
          onClick={() => { closeModal(); }}
          variant="outlined"
          size="large"
        >
          Cancelar
        </Button>
        <Button
          disabled={!image}
          style={{ margin: '5px' }}
          onClick={() => handleChoice()}
          size="large"
          className="select-image-unsplash"
        >
          Selecionar
        </Button>
      </ActionsGroup>
    </>
  );
};

UnsplashPicker.propTypes = {
  /**
  * Function that handles the choice of a image
  */
  selectImg: PropTypes.func.isRequired,
  /**
  * Function that closes the parent modal
  */
  closeModal: PropTypes.func.isRequired,
  /**
   * String with a term to be searched by default (when open or empty search input)
   */
  defaultSearch: PropTypes.string,
};

UnsplashPicker.defaultProps = {
  defaultSearch: 'Perfil',
};

export default UnsplashPicker;
