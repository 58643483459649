import React from 'react';

// import { Container } from './styles';

function ArrowDown({ size = 24, color = '#471182' }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill={color} width="24px" height="24px">
      <path d="M20 12l-1.41-1.41L13 16.17V4h-2v12.17l-5.58-5.59L4 12l8 8 8-8z" />
    </svg>
  );
}

export default ArrowDown;
