import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';

// components
import DataTable from '../../../juristec-ui/core/DataTable';
import TablePagination from '../../../juristec-ui/core/TablePagination';
import Tooltip from '../../../juristec-ui/core/Tooltip';
import IconButton from '../../../juristec-ui/core/IconButton';
import Badge from '../../../juristec-ui/core/Badge';

// styled
import {
  Container,
  Infos,
  StyledFont,
  DashboardsList,
  HistoryCount,
  EmptyList,
  Title,
  Options,
  UserDashText,
} from './styled/UserDashboards.styled';

// utils
import { formatDateTime } from '../../../juristec-ui/utils/functions/lab';
import {
  Chart, Report, Share, Trash,
} from '../../../juristec-ui/icons';

const UserDashboards = ({
  user,
  dashboards,
  getDashSharedUsers,
  getKpiList,
  getDashDependencies,
  handleDelete,
}) => {
  const [page, setPage] = useState(0);
  const [data, setData] = useState(dashboards);
  const theme = useTheme();

  const DashboardOptions = ({ dash }) => {
    // console.log(dash);
    const getDashShared = (e) => {
      e.stopPropagation();
      getDashSharedUsers(dash);
    };
    const getDashFilesDependencies = (e) => {
      e.stopPropagation();
      getDashDependencies(dash);
    };
    const getDashKpiList = (e) => {
      e.stopPropagation();
      getKpiList(dash);
    };
    const deleteDash = (e) => {
      e.stopPropagation();
      const { handler, modalHandler } = handleDelete(dash);
      const auxRemove = () => {
        setData((p) => p.filter((d) => d?.dashId !== dash?.dashId));
      };
      const tmp = () => handler(auxRemove);
      modalHandler(tmp);
    };

    return (
      <Options>
        <Tooltip text="Usuários compartilhados" atModal>
          <IconButton variant="pattern" shape="rounded" color="primary" onClick={getDashShared}>
            <Share />
          </IconButton>
        </Tooltip>
        <Tooltip text="Arquivos dependentes" atModal>
          <IconButton variant="pattern" shape="rounded" color="primary" onClick={getDashFilesDependencies}>
            <Report />
          </IconButton>
        </Tooltip>
        <Tooltip text="Lista de KPIs" atModal>
          <IconButton variant="pattern" shape="rounded" color="primary" onClick={getDashKpiList}>
            <Chart />
          </IconButton>
        </Tooltip>
        <Tooltip text="Deletar dashboard" atModal>
          <IconButton variant="pattern" shape="rounded" color="primary" onClick={deleteDash}>
            <Trash />
          </IconButton>
        </Tooltip>
      </Options>
    );
  };

  DashboardOptions.propTypes = {
    dash: PropTypes.shape({
      dashId: PropTypes.string,
    }).isRequired,
  };

  const formatDate = (datetime) => (
    datetime ? formatDateTime(datetime, { time: 'half' }) : 'Não consta'
  );

  const formatStatus = (status) => {
    let statusTitle = 'Desconhecido';
    let conditionalColor = 'warning';
    switch (status) {
      case false:
        statusTitle = 'Ativo';
        conditionalColor = 'success';
        break;
      case true:
        statusTitle = 'Inativo';
        conditionalColor = 'error';
        break;
      default:
        break;
    }
    return (
      <Badge color={conditionalColor} style={{ margin: 'auto' }}>
        {statusTitle}
      </Badge>
    );
  };

  const dashInfo = (dash) => (
    <UserDashText>
      <h4>{dash?.name || 'Sem Nome'}</h4>
      <span>{dash?.dashId || 'Id não encontrado'}</span>
    </UserDashText>
  );

  const columns = [
    {
      field: 'name',
      label: 'Nome',
      sortable: true,
      valueGetter: (param) => dashInfo(param),
    },
    {
      field: 'isDeleted',
      label: 'Status',
      sortable: true,
      valueGetter: (param) => formatStatus(param?.isDeleted || false),
    },
    {
      field: 'created_at',
      label: 'Data de Criação',
      sortable: true,
      valueGetter: (param) => formatDate(param?.created_at || ''),
    },
    {
      field: 'updatedAt',
      label: 'Data de Atualização',
      sortable: true,
      valueGetter: (param) => formatDate(param?.updatedAt || ''),
    },
    {
      field: 'options',
      role: 'optionsBtn',
      label: 'Opções',
      valueGetter: (param) => <DashboardOptions dash={param} />,
    },
  ];

  return (
    <Container>
      <Infos>
        <StyledFont>
          {user?.email && (
            <>
              <Title>Usuário:</Title>
              {' '}
              <span>{user.email}</span>
            </>
          )}
        </StyledFont>
        <HistoryCount>
          <StyledFont>
            <Title>Dashboards:</Title>
            {' '}
            <span>
              {data.length}
            </span>
          </StyledFont>
        </HistoryCount>
      </Infos>
      <DashboardsList>
        {data.length > 0 ? (
          <>
            <DataTable
              columns={columns}
              rowData={data}
              rowColor={theme.tableBackground}
              strippedRowsColor={theme.containerOdd}
              defaultSortField="name"
              defaultSortOrder="ascending"
              headerColor="transparent"
              theadStyle={{
                position: 'sticky',
                top: 0,
                zIndex: 1,
                backgroundColor: theme.background,
              }}
              tbodyStyle={{
                fontSize: '14px',
              }}
              usePagination
              page={page}
              itemsPerPage={5}
            />
            <TablePagination
              page={page}
              setPage={setPage}
              totalPages={
                Math.floor(dashboards.length % 5 === 0
                  ? (dashboards.length / 5) - 1
                  : dashboards.length / 5)
              }
            />
          </>
        ) : (
          <EmptyList>
            Esse usuário não possui dashboards
          </EmptyList>
        )}
      </DashboardsList>
    </Container>
  );
};

UserDashboards.propTypes = {
  user: PropTypes.shape({
    displayName: PropTypes.string,
    email: PropTypes.string,
  }),
  /**
   * Array of user dashboards.
  * */
  dashboards: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      status: PropTypes.string,
      created_at: PropTypes.string,
      updated_at: PropTypes.string,
    }),
  ),
  getDashSharedUsers: PropTypes.func.isRequired,
  getKpiList: PropTypes.func.isRequired,
  getDashDependencies: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
};

UserDashboards.defaultProps = {
  user: {},
  dashboards: [],
};

export default UserDashboards;
