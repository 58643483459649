import styled, { css } from 'styled-components';
import { devices } from '../../../../juristec-ui/core/breakpoints';

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

export const MainContainer = styled(Row)`
  padding: .5rem 5%;
  height: 3.8rem;
  align-items: center;
  justify-content: center;
  width: 100%;
  box-sizing: border-box;
  & > * + * {
    margin-left: 1rem;
  }
  @media ${devices.tablet} {
    padding: 10px 20px;
    & > * + * {
      margin-left: .5rem;
    }
    & .left {
      & > * + * {
        margin-left: .5rem;
      }
    }
    & .right {
      & h2{
        font-size: 20px;
      }
    }
  }
  @media ${devices.phoneS} {
    & > * + * {
      margin-left: 10px;
    }
    font-size: 14px;
    & .right {
      width: auto;
      max-width: calc(80% / 2);
      & h2{
        font-size: 18px;
      }
    }
  }
  & .value-button {
    background-color: ${({ theme }) => theme.primary}22;
    padding: 0 0 0 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    white-space: nowrap;
    width: 300px;
    box-shadow: none;
    height: 40px;
    font-family: inherit;
    &:hover svg {
      fill: ${({ theme }) => theme.primary};
    }
  }
  & .filter-btn {
    height: 36px;
    & > svg {
      margin: 0 0 0 5px;
    }
  }
`;

export const ToolbarItem = styled(Row)`
  align-items: center;
  height: 100%;
  & > * + * {
    margin-left: 1rem;
  }
  &.center {
    margin: 0 auto;
  }
  &.right {
    margin: 0 0 0 auto;
  }
  & h2 {
    font-size: 22px;
    color: ${({ theme }) => theme.primary};
    margin: 0;
    white-space: nowrap;
    
    @media ${devices.phoneS} {
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;

export const NavOpt = styled(Row)`
  color: ${({ theme }) => theme.toolbarFont};
  text-decoration: none;
  padding: 0 5px;
  height: 100%;
  cursor: pointer;
  align-items: center;
  font-weight: bold;
  border-bottom: 4px solid transparent;
  box-sizing: border-box;
  text-align: center;

  &:hover {
    color: ${({ theme }) => theme.primary};
  }

  ${({ active, theme }) => active && css`
    color: ${theme.primary};
    border-bottom-color: ${theme.primary};
  `}
`;

export const QueryStatus = styled.span`
  font-size: 14px;
  color: ${({ theme }) => theme.grey};
`;
