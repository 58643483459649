const plans = {
  standard: {
    label: 'Starter (legado)',
    diskSize: 250,
    dashboards: 100000,
    scientists: 0,
    supers: 1,
    guests: 1,
    snapshots: 12,
    monitoring: 0,
    newMonitoring: 3.5,
    newProcess: 2,
    oldProcess: 0.5,
  },

  advanced: {
    label: 'Law Firms',
    diskSize: 1000,
    dashboards: 100000,
    scientists: 2,
    supers: 1,
    guests: 2,
    snapshots: 12,
    monitoring: 0,
    newMonitoring: 3.5,
    newProcess: 2,
    oldProcess: 0.5,
  },

  premium: {
    label: 'Corporate',
    diskSize: 3000,
    dashboards: 100000,
    scientists: 5,
    supers: 1,
    guests: 4,
    snapshots: 12,
    monitoring: 0,
    newMonitoring: 3.5,
    newProcess: 2,
    oldProcess: 0.5,
  },

  push: {
    label: 'Antecipei',
    diskSize: 250,
    dashboards: 100000,
    scientists: 0,
    supers: 1,
    guests: 0,
    snapshots: 12,
    monitoring: 0,
    newMonitoring: 3.5,
    newProcess: 2,
    oldProcess: 0.5,
  },
};

export default function getPlan(plan) {
  return plans[plan] || plans.advanced;
}
