import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import StyledBackdrop from './styled/Backdrop.styled';

/**
* Overlay that can be applied when the user cannot interact with any other element of the screen but the last one opened. Some examples where it can be applied are modals and drawers.
*/
const Backdrop = ({ onClick }) => {
  return ReactDOM.createPortal(
    <StyledBackdrop onClick={onClick} />, document.getElementById('backdrop-hook'),
  );
};

Backdrop.propTypes = {
  /**
  * function to close the main element (and the backdrop) and return to the page 
  */
  onClick: PropTypes.func,
};

export default Backdrop;
