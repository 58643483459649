import React from 'react';
import PropTypes from 'prop-types';

import {
  MainContainer,
  TextContainer,
} from './styled/AssistantNotice.styled';

import Notice from './Notice';

import ActionsGroup from '../../../juristec-ui/core/ActionsGroup';
import Button from '../../../juristec-ui/core/Button';

const AssistantNotice = ({
  hide, submitConsent,
}) => (
  <>
    <MainContainer>
      <TextContainer tabIndex="0">
        <Notice />
      </TextContainer>
    </MainContainer>
    <ActionsGroup>
      <Button
        style={{ margin: '5px' }}
        onClick={hide}
        variant="outlined"
        size="large"
      >
        Cancelar
      </Button>
      <Button
        style={{ margin: '5px' }}
        onClick={submitConsent}
        size="large"
      >
        Concordar
      </Button>
    </ActionsGroup>
  </>
);

AssistantNotice.propTypes = {
  hide: PropTypes.func.isRequired,
  submitConsent: PropTypes.func.isRequired,
};

export default AssistantNotice;
