import styled, { css } from 'styled-components';

const directions = {
  left(open, customHeight, customWidth) {
    if (open) {
      return css`
        transform: translateX(0);
        left: 0;
        top: 0;
        width: ${customWidth || '20rem'};
        height: ${customHeight || '100vh'};
        max-width: 100vw;
      `;
    }
    return css`
      transform: translateX(-100%);
      left: 0;
      top: 0;
      width: ${customWidth || '20rem'};
      height: ${customHeight || '100vh'};
      pointer-events: none;
    `;
  },
  right(open, customHeight, customWidth) {
    if (open) {
      return css`
        transform: translateX(0);
        right: 0;
        top: 0;
        width: ${customWidth || '20rem'};
        height: ${customHeight || '100vh'};
      `;
    }
    return css`
      transform: translateX(100%);
      right: 0;
      top: 0;
      width: ${customWidth || '20rem'};
      height: ${customHeight || '100vh'};
      pointer-events: none;
    `;
  },
  bottom(open, customHeight, customWidth) {
    if (open) {
      return css`
        transform: translateY(0);
        bottom: 0;
        width: ${customWidth || '100vw'};
        height: ${customHeight || '15rem'};
      `;
    }
    return css`
      transform: translateY(100%);
      bottom: 0;
      width: ${customWidth || '100vw'};
      height: ${customHeight || '15rem'};
      pointer-events: none;
    `;
  },
  top(open, customHeight, customWidth) {
    if (open) {
      return css`
        transform: translateY(0);
        top: 0;
        width: ${customWidth || '100vw'};
        height: ${customHeight || '15rem'};
      `;
    }
    return css`
      transform: translateY(-100%);
      top: 0;
      width: ${customWidth || '100vw'};
      height: ${customHeight || '15rem'};
      pointer-events: none;
    `;
  },
  getDirection(direction, open, customHeight, customWidth) {
    return this[direction]
      ? this[direction](open, customHeight, customWidth)
      : this.left(open, customHeight, customWidth);
  },
};

export const StyledDrawer = styled.aside`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background: ${({ theme }) => theme.sideMenu};
  /* border-right: 1px solid #c9c9c9; */
  position: fixed;
  transition: transform 0.2s ease-in-out;
  z-index: 11;
  color: ${({ theme }) => theme.color};

  .closeBtn {
    position: absolute;
    top: 5px;
    right: 5px;
    box-shadow: none;
  }

  ${({ direction, open, customHeight, customWidth }) => css`
      ${directions.getDirection(direction, open, customHeight, customWidth)};
  `}
`;

export const BackgroundFade = styled.div`
  display: ${({ open }) => (open ? 'flex' : 'none')};
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100vh;
  background-color: ${({ theme }) => theme.fadedBackground};
  transition: transform 0.2s ease-in-out;
`;
