import styled from 'styled-components';

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  & span.errorMessage {
    position: absolute;
  }
`;

export const InputWrapper = styled.div`
  position: relative;
  margin: 10px auto 1.2rem auto;
  width: 80%;
`;
