import styled from 'styled-components';
import { devices } from '../../../../juristec-ui/core/breakpoints';

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AddUserContainer = styled(Column)`
  margin: 0 .5rem 1rem;
  gap: 1rem;
  min-width: 310px;
  @media ${devices.phoneS} {
    min-width: auto;
  }
`;

export const ItemContainer = styled(Column)`
  width: 100;
`;

export const InputWrapper = styled.div`
  width: 100%;
  min-height: 55px;
  max-height: 55px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  & .errorMessage {
    position: absolute;
  }
  & .select_wrapper {
    margin-top: 17.6px;
  }
  & span.custom-label {
    font-size:0.85rem;
    color:${({ theme }) => theme.inputLabel};
    font-weight: bold;
    width: 100%;
  }
  &.inline {
    min-height: 35px;
  }
  &.toggle {
    margin: 0px;
    min-height: 35px;
    max-height: 35px;
  }
  &.bottom {
    margin-top: auto;
  }
`;
