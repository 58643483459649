import React from 'react';
import PropTypes from 'prop-types';

import Container from './styled/MiniLoading';

const MiniLoading = ({ fill, size }) => (
  <Container className="loading-ring" fill={fill} size={size}>
    <div />
    <div />
    <div />
    <div />
  </Container>
);

MiniLoading.propTypes = {
  /**
   * Fill Color to the Loading component
   */
  fill: PropTypes.string,
  size: PropTypes.string,
};

MiniLoading.defaultProps = {
  fill: 'grey',
  size: 'large',
};

export default MiniLoading;
