export default {
  // TITLE
  TitleControl: '',
  ShowTitleControl: { checked: true },
  StyleTitleControl: {
    fontSize: 20,
    color: '#000000',
    fontFamily: 'arial',
    bold: false,
    italic: false,
    underline: false,
  },
  // GENERAL
  SortValuesControl: 'Alfabética A-Z',
  CustomSortControl: {
    checked: false, kpiCategories: [],
  },
  Margin: {
    top: 20,
    right: 110,
    bottom: 50,
    left: 60,
  },
  MinValueControlX: true,
  MaxValueControlX: true,
  MinValueControlY: true,
  MaxValueControlY: true,
  ValueScaleControlX: 'linear',
  ValueScaleControlY: 'linear',
  // STYLE
  NodeSizeControl: 9,
  PalletsControl: 'default',
  ColorTheme: { scheme: 'nivo' },
  GradientPickerControl: {
    color1: '#4711B2',
    color2: '#FF9626',
    color3: null,
  },
  Colors: {},
  UseGlobalColor: true,
  // AXIS
  EnableGridY: true,
  EnableGridX: true,
  EnableAxisX: {
    axisTop: false,
    axisBot: true,
    tickSize: 5,
    tickPadding: 5,
    tickRotation: 0,
    legend: '',
    legendOffset: 35,
    legendPosition: 'middle',
    Decimals: 'auto',
    separadorNumericoCasasX: 0,
  },
  EnableAxisY: {
    axisLeft: true,
    axisRight: false,
    tickSize: 5,
    tickPadding: 5,
    tickRotation: 0,
    legend: '',
    legendOffset: -55,
    legendPosition: 'middle',
    Decimals: 'auto',
    separadorNumericoCasasY: 0,
  },
  CurrencyControl: 'Nenhum',
  GroupingSelectControl: '',
  AxisGlobal: {
    fontFamily: 'arial',
    fontSize: 12,
    legendFontSize: 12,
    color: '#333333',
  },
  // LEGEND
  LegendControl: { checked: true },
  LegendPlacementControl: {
    value: {
      label: 'Direita',
      translateX: 110,
      translateY: 0,
      selectedPosition: 'right',
      direction: 'column',
      anchor: 'right',
    },
  },
  LegendsFontFamilyControl: { value: 'sans-serif' },
  LegendsFontSizeControl: { value: 12 },
  LegendsSpacingControl: { value: 10 },
  LegendSymbolControl: 'circle',
  // TOOLTIP / INTERACTIVE
  InteractiveControl: true,
  InteractivePrefixControl: '',
  InteractiveSufixControl: '',
  InteractiveDecimals: 'auto',
  InteractiveSeparadorNumericoCasas: 0,
  InteractiveCurrencyControl: 'Nenhum',
  InteractiveGroupingControl: '',
};
