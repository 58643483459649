/* eslint-disable react/no-array-index-key */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';

import ControllersGroup from '../../../controllers/ControllersGroup';
import {
  SelectController,
  RangeController,
  ChoiceBoxController,
  SimpleColorPickerController,
  ToggleSwitchController,
  GradientColorPickerController,
} from '../../../controllers/Base';

import { getOptionsThemes } from '../../../utils/controllersUtils/themeColors';

const StyleControllers = ({
  handle,
  config,
  data,
  extend,
  setExtend,
  isGlobalThemeActive,
}) => (
  <>
    <ControllersGroup
      title="Estilos"
      id="styles"
      extend={extend}
      setExtend={setExtend}
      sizeIncrease={config?.PalletsControl === 'custom' ? data?.index?.length : 0}
    >
      <RangeController
        label="Opacidade da área"
        handle={handle}
        kWord="FillOpacityControl"
        configValue={config?.FillOpacityControl?.value}
        min={0}
        max={1}
        step={0.05}
        precision={2}
        fullWidth
      />
      <RangeController
        label="Tamanho da borda"
        handle={handle}
        kWord="BorderWidthControl"
        configValue={config?.BorderWidthControl?.value}
        min={0}
        max={20}
        fullWidth
      />
      <SelectController
        label="Formato da borda"
        handle={handle}
        kWord="CurveControl"
        options={[
          { label: 'Linear', value: 'linearClosed', id: 'linearClosed' },
          { label: 'Ajuste Suave 1', value: 'basisClosed', id: 'basisClosed' },
          { label: 'Ajuste Suave 2', value: 'cardinalClosed', id: 'cardinalClosed' },
          { label: 'Ajuste Suave 3', value: 'catmullRomClosed', id: 'catmullRomClosed' },
        ]}
        configValue={config?.CurveControl}
        fullWidth
      />
      <ChoiceBoxController
        label="Paleta de cores"
        handle={handle}
        kWord="PalletsControl"
        options={[
          { label: 'Padrão', value: 'default', id: 'default' },
          { label: 'Degradê', value: 'gradient', id: 'gradient' },
          { label: 'Customizada', value: 'custom', id: 'custom' },
        ]}
        configValue={config?.PalletsControl}
        fullWidth
        text="Utilizando tema global"
        disabled={config?.UseGlobalColor !== false && isGlobalThemeActive}
      />
      {config?.PalletsControl === 'default' && (
        <SelectController
          label="Tema"
          handle={handle}
          kWord="ColorTheme"
          options={getOptionsThemes()}
          configValue={config?.ColorTheme?.scheme}
          fullWidth
          text="Utilizando tema global"
          disabled={config?.UseGlobalColor !== false && isGlobalThemeActive}
        />
      )}
      {config?.PalletsControl === 'gradient' && (
        <GradientColorPickerController
          handle={handle}
          firstColorVal={config?.GradientPickerControl?.color1 || '#4711B2'}
          firstColorKWord="GradientFirstColor"
          secondColorVal={config?.GradientPickerControl?.color2 || '#FF9626'}
          secondColorKWord="GradientSecondColor"
          thirdColorVal={config?.GradientPickerControl?.color3}
          thirdColorKWord="GradientThirdColor"
          fullWidth
          orientation="row"
          text="Utilizando tema global"
          disabled={config?.UseGlobalColor !== false && isGlobalThemeActive}
        />
      )}
      {config?.PalletsControl === 'custom' && (
        data?.columns?.map((col, i) => {
          const key = Array.isArray(col) ? col[col.length - 1] : col;
          if (key === 'Totais') return null;
          return (
            <SimpleColorPickerController
              dataColorTarget={key.toString()}
              key={`${key}_i${i}`}
              label={key}
              handle={handle}
              kWord="Colors"
              configValue={config?.Colors[key]}
              orientation="row"
              fullWidth
              text="Utilizando tema global"
              disabled={config?.UseGlobalColor !== false && isGlobalThemeActive}
            />
          );
        }))}
      <ToggleSwitchController
        label="Utilizar tema global"
        handle={handle}
        kWord="UseGlobalColor"
        configValue={config?.UseGlobalColor}
        firstOption=""
        secondOption=""
        fullWidth
      />
    </ControllersGroup>
  </>
);

StyleControllers.propTypes = {
  config: PropTypes.objectOf(PropTypes.any),
  data: PropTypes.objectOf(PropTypes.any),
  handle: PropTypes.func,
  extend: PropTypes.string,
  setExtend: PropTypes.func,
  isGlobalThemeActive: PropTypes.bool,
};

StyleControllers.defaultProps = {
  config: undefined,
  data: undefined,
  handle: () => {},
  extend: '',
  setExtend: () => {},
  isGlobalThemeActive: false,
};

export default StyleControllers;
