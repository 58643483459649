import React from 'react';
import { NavLink } from 'react-router-dom';
import { NotFound, Dashboard } from '../../juristec-ui/icons';
import NotFoundContainer from './styled/NotFound.styled';

function NotFoundFile() {
  return (
    <NotFoundContainer>
      <h1>ERROR 404</h1>
      <span>Página não encontrada :(</span>
      <NotFound />
      <NavLink
        to="/home"
        className="navlink"
      >
        Acessar Dashboards
        <Dashboard />
      </NavLink>
    </NotFoundContainer>
  );
}

export default NotFoundFile;
