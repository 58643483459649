import React, { useState } from 'react';
import PropTypes from 'prop-types';
import StepProgress from '../../../juristec-ui/core/StepProgress';
import FSPolicyContainer from './Steps/FirstStep';
import SSTermsContainer from './Steps/SecondStep';
import TSTermsContainer from './Steps/ThirdStep';

const Policy = ({
  hide, policyInfo, submitConsent,
}) => {
  const [policyCheck, setPolicyCheck] = useState(false);
  const [termCheck, setTermCheck] = useState(false);
  const [useTermsCheck, setUseTermsCheck] = useState(false);

  const handleSubmit = () => {
    if (policyCheck && termCheck && useTermsCheck) submitConsent();
  };

  const firstStep = {
    label: 'Política de Privacidade',
    validation: () => policyCheck,
    content: <FSPolicyContainer
      version={policyInfo.policy_version}
      date={policyInfo.policy_date}
      checkState={policyCheck}
      setCheckState={setPolicyCheck}
    />,
  };

  const secondStep = {
    label: 'Termo de Consentimento',
    validation: () => termCheck,
    content: <SSTermsContainer
      version={policyInfo.policy_version}
      date={policyInfo.policy_date}
      checkState={termCheck}
      setCheckState={setTermCheck}
    />,
  };

  const thirdStep = {
    label: 'Termos de Uso',
    validation: () => useTermsCheck,
    content: <TSTermsContainer
      version={policyInfo.policy_version}
      date={policyInfo.policy_date}
      checkState={useTermsCheck}
      setCheckState={setUseTermsCheck}
    />,
  };

  return (
    <>
      <StepProgress
        steps={[firstStep, secondStep, thirdStep]}
        hide={hide}
        complete={handleSubmit}
        removeMarkers
        disableUntilValid
      />
    </>
  );
};

Policy.propTypes = {
  /**
  * A function that closes the modal
  */
  hide: PropTypes.func,
  /**
   * An object with the latest policy information
   */
  policyInfo: PropTypes.shape({
    policy_version: PropTypes.string,
    policy_date: PropTypes.shape({
      nanoseconds: PropTypes.number,
      seconds: PropTypes.number,
    }),
  }),
  /**
   * A function that runs when the policy and terms are accepted
   */
  submitConsent: PropTypes.func,
};

Policy.defaultProps = {
  policyInfo: {},
  hide: () => {},
  submitConsent: () => {},
};

export default Policy;
