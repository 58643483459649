import styled, { css } from 'styled-components';
import { Responsive, WidthProvider } from 'react-grid-layout';
import { devices } from '../../../../juristec-ui/core/breakpoints';

const ResponsiveReactGridLayout = WidthProvider(Responsive);

const StyledOuterGridKpiLayout = styled.main`
    @page {
       size: auto;
       margin: auto;
    }

    /* padding: 0.5%;  */
    overflow-y: auto; 
    overflow-x: hidden;
    -webkit-overflow-scrolling: auto;
    padding: 5px;
    margin-top: 0rem;
    margin-left: 5%;
    margin-right: 5%;
    padding-bottom: 65px;
    /* padding-bottom: ${(props) => (props.editPainel ? '300px' : 'inherit')}; */
    
    height: calc(100vh - 188px) !important;
    @media ${devices.tablet} {
        margin: 0px 0px;
        height: calc(100vh - 188px) !important;
    };
    
    @media ${devices.phoneS} {
        margin: 0px 0px;
        height: calc(100vh - 178px) !important;
    }

`;

/**
 * Função que irá retornar css dinamico dependendo se esta em modo edicao ou nao do painel
 * @param {boolean} editPainel
 * @returns css code
 */
const effectGridKpiCard = (editPainel) => {
  if (editPainel) {
    return css`
      box-shadow: 0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12);
      cursor: move; /* fallback if grab cursor is unsupported */
      cursor: grab;
      cursor: -moz-grab;
      cursor: -webkit-grab;

      &:active {
        cursor: grabbing;
        cursor: -moz-grabbing;
        cursor: -webkit-grabbing;
      }
      & .iframeComponent {
        pointer-events: none;
      }
  `;
  }
  return '';
};

const StyledResponsiveReactGridKpiLayout = styled(ResponsiveReactGridLayout)`
    & > div {
        &:hover {
            ${(props) => effectGridKpiCard(props.editPainel)}
        }

        &:before {
            content: '';
            display: block;
            position: absolute;
            top: 0px;
            bottom: 0px;
            left: 0px;
            right: 0px;
            border: ${(props) => (props.editPainel ? 'dashed 2px #a9a9a9' : 'solid 2px transparent')};
        }

        padding: 0px;
        margin: 0px;
        position: relative;
        overflow: hidden;
    
        .menuCard {
            padding: 0px;
            margin: 0px;
            visibility: hidden;
            position: absolute;
            right: 3px;
            top: 3px;
            &.bottom {
                top: initial;
                bottom: 3px;
            }
        }
    }
`;

const StyledGridKpiCard = styled.div`
    height: 100%;  
`;

const StyledPopover2 = styled.div`
    visibility: ${(props) => (props.isOpen ? 'visible' : 'hidden')};
    position: absolute;
    right: 100%;
    top: 0%;
`;

export {
  StyledResponsiveReactGridKpiLayout,
  StyledOuterGridKpiLayout,
  StyledGridKpiCard,
  StyledPopover2,
};
