/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import { gap } from '../../../../juristec-ui/styles/theme';

export const LegendsContainer = styled.div`
  display: flex;
  ${gap('10px', 'row')}
  justify-content: center;
  margin: 10px 0;
  & .legend-type {
    display: flex;
    ${gap('5px', 'row')}
    align-items: center;
  }

  & .square {
    height: 20px;
    width: 20px;
    border-radius: 5px;
  }

  & .legend-texto {
    background: ${({ theme }) => theme.typeText};
  }

  & .legend-numero {
    background: ${({ theme }) => theme.typeNumber};
  }

  & .legend-date {
    background: ${({ theme }) => theme.typeDate};
  }
`;

export const DataTableContainer = styled.div`
  max-height: calc(80vh - 110px);
  overflow: auto;
  padding: 5px;
  width: 100%;
  max-width: 900px;
`;

export const ButtonIconWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  ${gap('7px', 'row')}
    fill: ${({ theme }) => theme.white
  };
}`;
