/* eslint-disable no-param-reassign */

/**
 * Realiza o filtro de acordo com o que foi escolhido.
 * @param {object} filter filtro a ser aplicado.
 * @param {object[]} valueList array com os valores a serem filtrados.
 * @returns {object[]} array com os resultados do filtro.
 */
function doFilter(filter, valueList) {
  // Para retornar um indice definido quando o metodo 'findIndex' retornar -1 (nao encontrado)
  const getSafeIndex = (idx, defaultIdx) => (idx > -1 ? idx : defaultIdx);

  const value1 = filter.values[0];
  const value2 = filter.values[1];
  let start = 0; // Indice de inicio
  let end = Infinity; // Indice de parada

  switch (filter.ftype) {
    // N Maiores (sem empate)
    case 'most':
      start = -1 * value1;
      break;
    // N Maiores (com empate)
    case 'most_inc':
      start = getSafeIndex(
        valueList.findIndex((v) => (+v.value) >= (valueList[valueList.length - Math.min(value1, valueList.length)].value)), 0,
      );
      break;
    // N Menores (sem empate)
    case 'least':
      end = value1;
      break;
    // N Menores (com empate)
    case 'least_inc':
      end = getSafeIndex(
        valueList.findIndex((v) => (+v.value) > (valueList[Math.min(value1 - 1, valueList.length - 1)].value)), Infinity,
      );
      break;
    // Maiores que N
    case 'greater':
      start = getSafeIndex(valueList.findIndex((v) => (+v.value) > (+value1)), 0);
      break;
    // Maiores ou iguais a N
    case 'greater_inc':
      start = getSafeIndex(valueList.findIndex((v) => (+v.value) >= (+value1)), 0);
      break;
    // Menores que N
    case 'lesser':
      end = getSafeIndex(valueList.findIndex((v) => (+v.value) >= (+value1)), Infinity);
      break;
    // Menores ou iguais a N
    case 'lesser_inc':
      end = getSafeIndex(valueList.findIndex((v) => (+v.value) > (+value1)), Infinity);
      break;
    // Entre A e B (exclusive)
    case 'between':
      start = getSafeIndex(valueList.findIndex((v) => (+v.value) > (+value1)), 0);
      end = getSafeIndex(valueList.findIndex((v) => (+v.value) >= (+value2)), Infinity);
      break;
    // Entre A e B (inclusive)
    case 'between_inc':
      start = getSafeIndex(valueList.findIndex((v) => (+v.value) >= (+value1)), 0);
      end = getSafeIndex(valueList.findIndex((v) => (+v.value) > (+value2)), Infinity);
      break;
    default:
      return valueList;
  }
  return valueList.slice(start, end);
}

/**
 * Recalcula os totais com base nos valores filtrados.
 * @param {object} filteredData os dados filtrados sem os totais.
 * @param {string} filterVar marcador para indicar qual filtro foi realizado anteriormente.
 * @param {string[]?} columnTotal nomes utilizados na colona totais, perdidos no filtro por colunas.
 * @returns {object} um objeto com os valores de totais recalculados.
 */
function recalculateTotals(filteredData, filterVar, columnTotal) {
  if (filterVar === 'line') {
    const tValues = [];
    filteredData.index.push('Totais');
    filteredData.data.forEach((dList) => {
      dList.forEach((dVal, i) => {
        tValues[i] = ((+tValues[i] || 0) + (+dVal)).toString();
      });
    });
    filteredData.data.push(tValues);
  } else {
    filteredData.columns.push(columnTotal);
    filteredData.data.forEach((dList) => {
      let rowSum = 0;
      dList.forEach((dVal) => {
        rowSum += (+dVal);
      });
      dList.push(rowSum.toString());
    });
  }
  return filteredData;
}

/**
 * Realiza a filtragem dos valores relacionados as linhas.
 * @param {array} tempIndex array de linhas dos dados.
 * @param {array} tempData array de valores dos dados.
 * @param {object} filter filtro a ser aplicado.
 * @returns {object} um objeto com partes dos dados filtrados e com os totais recalculados.
 */
function filterLine(tempIndex, tempData, filter) {
  let dTotals = tempData.slice(0, -1).map((val, i) => ({
    index: i,
    value: val[val.length - 1],
  }));

  dTotals.sort((a, b) => a.value - b.value);
  dTotals = doFilter(filter, dTotals);

  const filteredData = dTotals.reduce((aux, tObj) => {
    aux.data.push(tempData[tObj.index]);
    aux.index.push(tempIndex[tObj.index]);
    return aux;
  }, { data: [], index: [] });

  return recalculateTotals(filteredData, 'line');
}

/**
 * Realiza a filtragem dos valores relacionados as colunas.
 * @param {array} tempColumns array de colunas dos dados.
 * @param {array} tempData array de valores dos dados.
 * @param {object} filter filtro a ser aplicado.
 * @returns {object} um objeto com partes dos dados filtrados e com os totais recalculados.
 */
function filterColumn(tempColumns, tempData, filter) {
  let dTotals = tempData[tempData.length - 1].slice(0, -1).map((val, i) => ({
    index: i,
    value: val,
  }));

  dTotals.sort((a, b) => a.value - b.value);
  dTotals = doFilter(filter, dTotals);

  const filteredData = dTotals.reduce((aux, tObj) => {
    tempData.forEach((dList, i) => {
      aux.data[i] = aux.data[i] || [];
      aux.data[i].push(dList[tObj.index]);
    });
    aux.columns.push(tempColumns[tObj.index]);
    return aux;
  }, { columns: [], data: [] });

  return recalculateTotals(filteredData, 'column', tempColumns[tempColumns.length - 1]);
}

/**
 * Inicia o processo de filtragem por valores.
 * @param {object[]} fList lista de filtros de valores.
 * @param {object} rawData os dados antes de serem filtrados.
 * @returns {object} um objeto com os dados filtrados.
 */
function filterByValues(fList, rawData) {
  const { columns, index, data } = rawData;
  let tempData = {
    columns,
    index,
    data,
  };

  fList.forEach((filter) => {
    if (filter.inverse === undefined) {
      if (filter.variable === 'column') {
        tempData = { ...tempData, ...filterColumn(tempData.columns, tempData.data, filter) };
      } else {
        tempData = { ...tempData, ...filterLine(tempData.index, tempData.data, filter) };
      }
    }
  });
  return tempData;
}

export default filterByValues;
