import React from 'react';

function Next() {
    return (
      <svg width="14" height="24" viewBox="0 0 14 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 22.3828V1.61718C0 0.179299 1.73848 -0.540772 2.7552 0.475946L13.138 10.8587C13.7683 11.489 13.7683 12.511 13.138 13.1412L2.7552 23.524C1.73848 24.5408 0 23.8207 0 22.3828Z"/>
      </svg>
    );
}

export default Next;