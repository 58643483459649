/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';

import InputTextLine from '../../../../juristec-ui/core/InputTextLine';
import Select from '../../../../juristec-ui/core/SelectNew';
import Badge from '../../../../juristec-ui/core/Badge';

import {
  OnboardingReportContainer,
  RowOptions,
  ItemContainer,
  SelectedFields,
} from './styled/OnbordingReport.styled';

const FirstStep = ({
  reportNameState,
  selectedEmailState,
  selectedModuleState,
  disableSubmitButtonState,
  selectedFieldsState,
  fieldsState,
  selectedTemplateState,
  templatesState,
  users,
  modules,
  schema,
  getL1ReportModuleFields,
  plan,
  getTemplates,
}) => {
  const [reportName, setReportName] = reportNameState;
  const [selectedEmail, setSelectedEmail] = selectedEmailState;
  const [selectedModule, setSelectedModule] = selectedModuleState;
  const [disableSubmitButton, setDisableSubmitButton] = disableSubmitButtonState;
  const [selectedFields, setSelectedFields] = selectedFieldsState;
  const [fields, setFields] = fieldsState;
  const [selectedTemplate, setSelectedTemplate] = selectedTemplateState;
  const [templates, setTemplates] = templatesState;

  const reportInputHandle = (e) => {
    const val = e.target.value.split(' ').filter((v) => v).join('');
    const msg = val.length === 0 ? 'Este campo não pode estar vazio' : '';
    setDisableSubmitButton(msg.length !== 0);
    setReportName({
      value: val,
      error: msg.length !== 0,
      errorMsg: msg,
    });
  };

  const normalizeFields = (schemaTypes, fieldsArray, outputArray, prefix, moduleFields) => {
    fieldsArray.forEach((field) => {
      let key = field.name;
      if (prefix !== '' && !prefix.includes('[') && !prefix.includes(']')) {
        key = `${prefix}.${field.name}`;
      }
      if (field?.type?.kind === 'OBJECT') {
        const obj = field?.type?.name;
        const subField = schemaTypes.find((f) => f?.name === obj);
        return normalizeFields(schemaTypes, subField.fields, outputArray, key, moduleFields);
      }
      if (field?.type?.kind === 'LIST') {
        const obj = field?.type?.ofType?.name;
        const subField = schemaTypes.find((f) => f?.name === obj);
        return normalizeFields(schemaTypes, subField.fields, outputArray, key, moduleFields);
      }
      const output = {
        ...field,
        id: key,
        value: key,
        label: moduleFields?.columnsMap?.[key] || key,
      };
      outputArray.push(output);
      return null;
    });
    return outputArray;
  };

  const fieldsFromModule = async (module) => {
    const moduleFields = await getL1ReportModuleFields(module, plan);
    const selModule = modules.find((m) => m.value === module);
    if (selModule?.type?.kind === 'OBJECT') {
      const obj = selModule?.type?.name;
      const subField = schema.find((f) => f?.name === obj);
      const node = subField?.fields?.find((f) => f?.name === 'nodes');
      const nodeName = node?.type?.ofType?.name;
      const nodeDataField = schema?.find((f) => f?.name === nodeName) || subField?.fields;
      const output = normalizeFields(schema, nodeDataField.fields, [], '', moduleFields);
      return output;
    }
    return [];
  };

  const selectModuleHandle = async (selected) => {
    setSelectedModule(selected);
    setSelectedFields([]);
    setSelectedTemplate({
      id: 'Nenhum',
      label: 'Nenhum',
      value: 'Nenhum',
      columns: [],
    });
    const fieldsData = await fieldsFromModule(selected.value);
    setFields(fieldsData);
    const templatesData = await getTemplates(selected.value, plan);
    const finalTemplates = templatesData.map((template) => ({
      id: template.id,
      label: template.label,
      value: template.label,
      columns: template.value,
    }));
    setTemplates(finalTemplates);
  };

  const handleSelectTemplate = (selected) => {
    setSelectedTemplate(selected);
    setSelectedFields(fields.filter((field) => selected.columns.includes(field.id)));
  };

  return (
    <div style={{ width: '50vw', minHeight: '20vh' }}>
      <OnboardingReportContainer>
        <ItemContainer style={{ width: '100%' }}>
          <InputTextLine
            label="Nome do relatório"
            placeholder="Insira o nome"
            errorMessage={reportName.errorMsg}
            error={reportName.errorMsg.length > 0}
            onChange={reportInputHandle}
            value={reportName.value}
          />
        </ItemContainer>
        <ItemContainer style={{ width: '100%' }}>
          <Select
            label="E-mail do proprietário"
            options={users}
            fullWidth
            placement="start"
            value={selectedEmail}
            onChange={setSelectedEmail}
            atModal
            sortBy="ascending"
            searchable
          />
        </ItemContainer>
        <RowOptions>
          <ItemContainer>
            <Select
              label="Módulo"
              options={modules}
              fullWidth
              placement="start"
              value={selectedModule}
              onChange={selectModuleHandle}
              atModal
              sortBy="ascending"
              searchable
            />
          </ItemContainer>
          <ItemContainer>
            <Select
              label="Template"
              options={templates}
              fullWidth
              placement="start"
              value={selectedTemplate}
              onChange={handleSelectTemplate}
              atModal
              sortBy="ascending"
              searchable
            />
          </ItemContainer>
        </RowOptions>
        <ItemContainer style={{ width: '100%' }}>
          <Select
            label="Campos desejados"
            options={fields}
            fullWidth
            placement="start"
            value={selectedFields}
            onChange={setSelectedFields}
            multiple
            stayOpen
            atModal
            selectAll
            sortBy="ascending"
            searchable
          />
        </ItemContainer>
        {selectedFields.length > 0 && (
          <>
            <span>
              <strong>Campos selecionados: </strong>
            </span>
            <SelectedFields>
              {selectedFields.map((field) => (
                <Badge key={field.id} size="small" color="secondary">{field.label}</Badge>
              ))}
            </SelectedFields>
          </>
        )}
      </OnboardingReportContainer>
    </div>
  );
};

FirstStep.propTypes = {
  reportNameState: PropTypes.arrayOf(PropTypes.any).isRequired,
  selectedEmailState: PropTypes.arrayOf(PropTypes.any).isRequired,
  selectedModuleState: PropTypes.arrayOf(PropTypes.any).isRequired,
  disableSubmitButtonState: PropTypes.arrayOf(PropTypes.any).isRequired,
  selectedFieldsState: PropTypes.arrayOf(PropTypes.any).isRequired,
  fieldsState: PropTypes.arrayOf(PropTypes.any).isRequired,
  selectedTemplateState: PropTypes.arrayOf(PropTypes.any).isRequired,
  templatesState: PropTypes.arrayOf(PropTypes.any).isRequired,
  users: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  modules: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  schema: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  getL1ReportModuleFields: PropTypes.func.isRequired,
  plan: PropTypes.string.isRequired,
  getTemplates: PropTypes.func.isRequired,
};

export default FirstStep;
