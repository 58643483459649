/* eslint-disable no-param-reassign */
/* eslint-disable react/prop-types */
import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useReducer,
} from 'react';

import { format } from 'date-fns';
import firestoreErrors from '../../utils/firestoreErrors';
import { error as errorLabels } from '../../label';

import { ip } from '../../utils/functions/urls';
import { getRefBigDataHistory } from '../../utils/firestore';
import { AuthContext } from '../AuthProvider';
import getRequestMeta from '../../utils/functions/generateMeta';
import uuidv4 from '../../juristec-ui/utils/functions/randomUUID';
import compare from '../../utils/functions/sorting';

const getToken = async (u) => u.getIdToken();

const HISTORYLIMIT = 5;

const initialState = {
  isLoading: false,
  lastPublicationsQueries: [],
  publications: null,
  publicationsSearchTime: 0,
  publicationHistoryCursor: 0,
  publicationNextOffset: 0,
  meta: null,
  lastMetaQueries: [],
  maxMetaValue: 0,
  metaIsFiltered: false,
  metaSearchTime: 0,
  metaHistoryCursor: 0,
};

const reduce = (state, action) => {
  switch (action.type) {
    case 'init': {
      return {
        ...state,
        isLoading: false,
        meta: action.data,
        maxMetaValue: action.maxValue || state.maxValue,
        metaIsFiltered: false,
        metaSearchTime: action.searchTime,
        lastPublicationsQueries: action.publicationHistory?.history ?? [],
        publicationHistoryCursor: action.publicationHistory?.cursor ?? 0,
        lastMetaQueries: action.metaHistory?.history ?? [],
        metaHistoryCursor: action.metaHistory?.cursor ?? 0,
      };
    }

    case 'setPublications': {
      const currentOffsets = action.reset ? [] : [...state.publications.offsets];
      currentOffsets[action.page] = currentOffsets[action.page] ?? state.publicationNextOffset;

      const queries = [...state.lastPublicationsQueries];
      let hCursor = state.metaHistoryCursor;
      if (action.lastQuery && action.nextCursor !== null) {
        hCursor = action.nextCursor;
        if (queries.length === HISTORYLIMIT) queries.pop();
        queries.unshift({
          ...action.lastQuery,
          timestamp: new Date().toISOString(),
        });
      }

      return {
        ...state,
        isLoading: false,
        lastPublicationsQueries: queries,
        publicationHistoryCursor: hCursor,
        publicationsSearchTime: action.searchTime,
        publications: {
          offsets: currentOffsets,
          results: action.data?.items?.map((item) => ({ ...item, id: uuidv4() })) ?? [],
          totalResults: action.data.total_resultado,
          totalProcesso: action.data.total_processo,
          totalCaderno: action.data.total_caderno,
          totalEdicao: action.data.total_edicao,
          totalTribunal: action.data.total_tribunal,
          totalPublicacao: action.data.total_publicacao,
          totalBlocoProcesso: action.data.total_bloco_processo,
        },
        publicationNextOffset: action.data.nextoffset,
      };
    }

    case 'setMeta': {
      const queries = [...state.lastMetaQueries];
      let cursor = state.metaHistoryCursor;
      if (action.lastQuery && action.nextCursor !== null) {
        cursor = action.nextCursor;
        if (queries.length === HISTORYLIMIT) queries.pop();
        queries.unshift({
          ...action.lastQuery,
          timestamp: new Date().toISOString(),
        });
      }
      return {
        ...state,
        isLoading: false,
        meta: action.data,
        maxMetaValue: action.maxValue || state.maxValue,
        metaIsFiltered: action.isFiltered,
        metaSearchTime: action.searchTime,
        lastMetaQueries: queries,
        metaHistoryCursor: cursor,
      };
    }

    case 'setLoading': {
      return {
        ...state,
        isLoading: action.loading,
      };
    }

    default:
      return state;
  }
};

export const BigDataContext = createContext({});

const buildPublicationsQuery = (query, fileType, qty, offset) => {
  const body = {
    unique_cnj: query.unicos ?? true,
    bloco_text: fileType === 'json',
    type_file: fileType,
  };
  if (offset !== undefined) body.offset = offset;
  if (qty !== undefined) body.size = qty;

  let complexity = 'basic';

  if (query?.termo?.value) {
    if (Object.values(query.termo.value).length > 1) complexity = 'advanced';
    body.query = Object.values(query.termo.value).map((v) => v.toLowerCase());
    if (query?.termo_negativo?.value) {
      complexity = 'advanced';
      body.not_query = Object.values(query.termo_negativo.value).map((v) => v.toLowerCase());
    }
    if (query?.publicacao?.value) {
      if (query.publicacao.value[0]) body.publication_after = format(query.publicacao.value[0], 'yyyy-MM-dd');
      if (query.publicacao.value[1]) body.publication_before = format(query.publicacao?.value[1], 'yyyy-MM-dd');
    }
    if (query?.distribuicao?.value) {
      if (query.distribuicao.value[0]) body.distribution_after = format(query.distribuicao.value[0], 'yyyy-MM-dd');
      if (query.distribuicao.value[1]) body.distribution_before = format(query.distribuicao?.value[1], 'yyyy-MM-dd');
    }
    if (query?.tribunal?.value) {
      body.courts = query.tribunal.value.map((op) => op.value);
    }
  }
  return [body, complexity];
};

const buildMetaQuery = (query, fileType) => {
  const body = {
    type_file: fileType,
  };
  if (query?.cnpj?.value) {
    body.cnpj = Object.values(query.cnpj.value);
  }
  if (query?.assunto?.value) {
    body.assunto = Object.values(query.assunto.value).map((v) => v.toLowerCase());
  }
  if (query?.advogado?.value) {
    body.advogado = Object.values(query.advogado.value).map((v) => v.toLowerCase());
  }
  if (query?.adv_ativo?.value) {
    body.advogado_parte_ativa = Object.values(
      query.adv_ativo.value,
    ).map((v) => v.toLowerCase());
  }
  if (query?.adv_passivo?.value) {
    body.advogado_parte_passiva = Object.values(
      query.adv_passivo.value,
    ).map((v) => v.toLowerCase());
  }
  if (query?.interessado?.value) {
    body.terceiro_interessado = Object.values(
      query.interessado.value,
    ).map((v) => v.toLowerCase());
  }
  if (query?.uf?.value) body.uf = query.uf.value.map((op) => op.value);
  if (query?.classe?.value) body.classe_sigla = query.classe.value.map((op) => op.value);
  if (query?.orgao?.value) body.orgao = query.orgao.value.map((op) => op.value);
  if (query?.polo?.value) body.tipo_polo = query.polo.value.map((op) => op.value);
  if (query?.sinonimos?.value) {
    [
      body.tipo_parte_ativa,
      body.tipo_parte_passiva,
    ] = query.sinonimos.value.reduce((aux, op) => {
      const [ativa, passiva] = op.value.split(' / ');
      aux[0].push(ativa);
      aux[1].push(passiva);
      return aux;
    }, [[], []]);
  }
  if (query?.publicacao?.value) {
    if (query.publicacao.value[0]) body.publication_after = format(query.publicacao.value[0], 'yyyy-MM-dd');
    if (query.publicacao.value[1]) body.publication_before = format(query.publicacao?.value[1], 'yyyy-MM-dd');
  }
  if (query?.distribuicao?.value) {
    if (query.distribuicao.value[0]) body.distribution_after = format(query.distribuicao?.value[0], 'yyyy-MM-dd');
    if (query.distribuicao.value[1]) body.distribution_before = format(query.distribuicao?.value[1], 'yyyy-MM-dd');
  }
  if (query?.valor?.value) [body.valor_causa_start, body.valor_causa_end] = query.valor.value;
  if (query?.tribunal?.value) {
    body.courts = body.courts || [];
    body.courts.push({ trt: query.tribunal.value.map((op) => op.value) });
  }
  if (query?.comarca?.value) {
    body.courts = body.courts || [];
    body.courts.push(...Object.values(query.comarca.value).map((val) => ({
      trt: val.tribunal.value,
      comarca: val.comarca.map((op) => op.value),
    })));
  }
  if (query?.vara?.value) {
    body.courts = body.courts || [];
    body.courts.push(...Object.values(query.vara.value).map((val) => ({
      trt: val.tribunal.value,
      comarca: val.comarca.value,
      vara: val.vara.map((op) => op.value),
    })));
  }
  return body;
};

const BigDataProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reduce, initialState);
  const setLoading = useCallback((l) => dispatch({ type: 'setLoading', loading: l }), [dispatch]);
  const { currentUser } = useContext(AuthContext);

  const saveQueryHistory = useCallback(async (endpoint, query, cursor) => {
    try {
      const nextCursor = (cursor + 1) % HISTORYLIMIT;
      const ref = getRefBigDataHistory(currentUser.uid).doc(endpoint);
      await ref.set({
        history: {
          [cursor]: JSON.stringify({
            ...query, timestamp: new Date().toISOString(),
          }),
        },
        cursor: nextCursor,
      }, { merge: true });
      return { error: false, nextCursor };
    } catch (er) {
      console.error(er);
      return {
        error: true,
        msg: firestoreErrors(er.code) || errorLabels.bigDataProvider.saveQueryHistory,
        raw: `Erro do sistema: ${er.toString()}`,
      };
    }
  }, [currentUser]);

  const loadQueryHistory = useCallback(async () => {
    try {
      const ref = await getRefBigDataHistory(currentUser.uid).get();
      const dataRes = ref.docs.reduce((aux, d) => {
        const dt = d.data();
        aux[d.id] = {
          history: Object.entries(dt.history).reduce((hAux, [k, v]) => {
            hAux[k] = JSON.parse(v);
            return hAux;
          }, []).sort((a, b) => compare(b.timestamp, a.timestamp)),
          cursor: dt.cursor,
        };
        return aux;
      }, { meta: { cursor: 0, history: [] }, publication: { cursor: 0, history: [] } });
      return { error: false, meta: dataRes.meta, publication: dataRes.publication };
    } catch (er) {
      console.error(er);
      return {
        error: true,
        msg: firestoreErrors(er.code) || errorLabels.bigDataProvider.loadQueryHistory,
        raw: `Erro do sistema: ${er.toString()}`,
      };
    }
  }, [currentUser]);

  const queryPublications = useCallback(async (query, offset, qty) => {
    try {
      const token = await getToken(currentUser);
      const meta = await getRequestMeta(token, 'POST', 'JSON');

      const [body, complexity] = buildPublicationsQuery(query, 'json', qty, offset);

      const opt = {
        ...meta,
        body: JSON.stringify(body),
      };
      const resFetch = await fetch(`${ip}/bigdata/publications/query?complexity=${complexity}`, opt);
      const json = await resFetch.json();
      if (resFetch.status !== 200) {
        return {
          error: true,
          msg: errorLabels.bigDataProvider.queryPublications,
          raw: resFetch.status >= 500 ? (
            'Verifique se existe algum problema com a sua conexão com a internet e tente novamente mais tarde!'
          ) : json.error,
        };
      }

      return {
        error: false,
        msg: '',
        data: json.info,
      };
    } catch (er) {
      console.error(er);
      return {
        error: true,
        msg: errorLabels.bigDataProvider.queryPublications,
        raw: `Erro do sistema: ${er.toString()}`,
      };
    }
  }, [currentUser]);

  const exportPublications = useCallback(async (query, qty) => {
    try {
      const token = await getToken(currentUser);
      const meta = await getRequestMeta(token, 'POST', 'JSON');

      const [body, complexity] = buildPublicationsQuery(query, 'xlsx', qty);

      const opt = {
        ...meta,
        body: JSON.stringify(body),
      };
      const resFetch = await fetch(`${ip}/bigdata/publications/query?complexity=${complexity}`, opt);
      if (resFetch.status !== 200) {
        const json = await resFetch.json();
        return {
          error: true,
          msg: errorLabels.bigDataProvider.exportPublications,
          raw: resFetch.status >= 500 ? (
            'Verifique se existe algum problema com a sua conexão com a internet e tente novamente mais tarde!'
          ) : json.error,
        };
      }
      const blob = await resFetch.blob();
      const url = URL.createObjectURL(blob);
      const aNode = document.createElement('a');
      aNode.href = url;
      aNode.download = 'Publicacoes.xlsx';
      aNode.click();

      return {
        error: false,
        msg: '',
        data: blob,
      };
    } catch (er) {
      console.error(er);
      return {
        error: true,
        msg: errorLabels.bigDataProvider.exportPublications,
        raw: `Erro do sistema: ${er.toString()}`,
      };
    }
  }, [currentUser]);

  const getProcess = useCallback(async (cnj) => {
    try {
      const token = await getToken(currentUser);
      const opt = {
        ...await getRequestMeta(token),
      };

      const resFetch = await fetch(`${ip}/bigdata/process/${cnj}?type_file=json`, opt);
      const json = await resFetch.json();
      if (resFetch.status !== 200) {
        return {
          error: true,
          msg: errorLabels.bigDataProvider.getProcess,
          raw: resFetch.status >= 500 ? (
            'Verifique se existe algum problema com a sua conexão com a internet e tente novamente mais tarde!'
          ) : json.error,
        };
      }
      return {
        error: false,
        msg: '',
        data: json.info || {},
      };
    } catch (er) {
      console.error(er);
      return {
        error: true,
        msg: errorLabels.bigDataProvider.getProcess,
        raw: `Erro do sistema: ${er.toString()}`,
      };
    }
  }, [currentUser]);

  const getBlock = useCallback(async (cnj, blockId) => {
    try {
      const token = await getToken(currentUser);
      const opt = {
        ...await getRequestMeta(token),
      };

      const resFetch = await fetch(`${ip}/bigdata/block/${cnj}/${blockId}`, opt);
      const json = await resFetch.json();
      if (resFetch.status !== 200) {
        return {
          error: true,
          msg: errorLabels.bigDataProvider.getBlock,
          raw: resFetch.status >= 500 ? (
            'Verifique se existe algum problema com a sua conexão com a internet e tente novamente mais tarde!'
          ) : json.error,
        };
      }
      return {
        error: false,
        msg: '',
        data: json.info?.bloco_text || '',
      };
    } catch (er) {
      console.error(er);
      return {
        error: true,
        msg: errorLabels.bigDataProvider.getBlock,
        raw: `Erro do sistema: ${er.toString()}`,
      };
    }
  }, [currentUser]);

  const initMeta = useCallback(async () => {
    try {
      const token = await getToken(currentUser);
      const opt = {
        ...await getRequestMeta(token, 'POST', 'JSON'),
        body: JSON.stringify({
          type_file: 'json',
        }),
      };
      const resFetch = await fetch(`${ip}/bigdata/meta/query`, opt);
      const json = await resFetch.json();
      if (resFetch.status !== 200) {
        return {
          error: true,
          msg: errorLabels.bigDataProvider.initMeta,
          raw: resFetch.status >= 500 ? (
            'Verifique se existe algum problema com a sua conexão com a internet e tente novamente mais tarde!'
          ) : json.error,
        };
      }
      return {
        error: false,
        msg: '',
        data: json.info,
      };
    } catch (er) {
      console.error(er);
      return {
        error: true,
        msg: errorLabels.bigDataProvider.initMeta,
        raw: `Erro do sistema: ${er.toString()}`,
      };
    }
  }, [currentUser]);

  const getOptions = useCallback(async (endpoint, query) => {
    try {
      const token = await getToken(currentUser);
      const opt = {
        ...await getRequestMeta(token),
      };
      let strQuery = `?field=${endpoint}&`;
      if (query) {
        Object.keys(query).forEach((k) => {
          if (query[k]) strQuery += `${k}=${query[k]}&`;
        });
      }

      const resFetch = await fetch(
        `${ip}/bigdata/options${strQuery}`, opt,
      );
      const json = await resFetch.json();
      if (resFetch.status !== 200) {
        return {
          error: true,
          msg: errorLabels.bigDataProvider.getOptions,
          raw: resFetch.status >= 500 ? (
            'Verifique se existe algum problema com a sua conexão com a internet e tente novamente mais tarde!'
          ) : json.error,
        };
      }

      return {
        error: false,
        msg: '',
        data: json.info,
      };
    } catch (er) {
      console.error(er);
      return {
        error: true,
        msg: errorLabels.bigDataProvider.getOptions,
        raw: `Erro do sistema: ${er.toString()}`,
      };
    }
  }, [currentUser]);

  const queryMeta = useCallback(async (query) => {
    try {
      const token = await getToken(currentUser);
      const meta = await getRequestMeta(token, 'POST', 'JSON');

      const opt = {
        ...meta,
        body: JSON.stringify(buildMetaQuery(query, 'json')),
      };
      const resFetch = await fetch(`${ip}/bigdata/meta/query`, opt);
      const json = await resFetch.json();
      if (resFetch.status !== 200) {
        return {
          error: true,
          msg: errorLabels.bigDataProvider.queryMeta,
          raw: resFetch.status >= 500 ? (
            'Verifique se existe algum problema com a sua conexão com a internet e tente novamente mais tarde!'
          ) : json.error,
        };
      }

      return {
        error: false,
        msg: '',
        data: json.info,
      };
    } catch (er) {
      console.error(er);
      return {
        error: true,
        msg: errorLabels.bigDataProvider.queryMeta,
        raw: `Erro do sistema: ${er.toString()}`,
      };
    }
  }, [currentUser]);

  const exportMeta = useCallback(async (query) => {
    try {
      const token = await getToken(currentUser);
      const meta = await getRequestMeta(token, 'POST', 'JSON');

      const opt = {
        ...meta,
        body: JSON.stringify(buildMetaQuery(query, 'xlsx')),
        responseType: 'blob',
      };
      const resFetch = await fetch(`${ip}/bigdata/meta/query`, opt);
      if (resFetch.status !== 200) {
        const json = await resFetch.json();
        return {
          error: true,
          msg: errorLabels.bigDataProvider.exportMeta,
          raw: resFetch.status >= 500 ? (
            'Verifique se existe algum problema com a sua conexão com a internet e tente novamente mais tarde!'
          ) : json.error,
        };
      }
      const blob = await resFetch.blob();
      const url = URL.createObjectURL(blob);
      const aNode = document.createElement('a');
      aNode.href = url;
      aNode.download = 'Geral.xlsx';
      aNode.click();

      return {
        error: false,
        msg: '',
        data: blob,
      };
    } catch (er) {
      console.error(er);
      return {
        error: true,
        msg: errorLabels.bigDataProvider.exportMeta,
        raw: `Erro do sistema: ${er.toString()}`,
      };
    }
  }, [currentUser]);

  const middleware = useCallback(async (action) => {
    switch (action.type) {
      case 'queryPublications': {
        setLoading(true);
        const timeStart = new Date();
        const res = await queryPublications(action.query, action.offset, action.qty);
        if (res.error) {
          setLoading(false);
        } else {
          const hRes = await saveQueryHistory('publication', action.query, action.cursor);
          const searchTime = (new Date().getTime() - timeStart.getTime()) / 1000;
          dispatch({
            type: 'setPublications',
            data: res.data,
            page: action.page,
            reset: action.reset,
            lastQuery: action.query,
            nextCursor: hRes.error ? null : hRes.nextCursor,
            searchTime,
          });
        }
        return res;
      }

      case 'exportPublications': {
        setLoading(true);
        const res = await exportPublications(action.query, action.qty);
        setLoading(false);
        return res;
      }

      case 'getProcess': {
        setLoading(true);
        const res = await getProcess(action.cnj);
        setLoading(false);
        return res;
      }

      case 'getBlock': {
        const res = await getBlock(action.cnj, action.blockId);
        return res;
      }

      case 'initMeta': {
        setLoading(true);
        const timeStart = new Date();
        const [rMeta, rHistory] = await Promise.all([initMeta(), loadQueryHistory()]);
        if (rMeta.error) {
          setLoading(false);
        } else {
          const searchTime = (new Date().getTime() - timeStart.getTime()) / 1000;
          dispatch({
            type: 'init',
            data: rMeta.data,
            maxValue: rMeta.data?.maximo_valor_causa || 0,
            isFiltered: false,
            metaHistory: rHistory.error ? null : rHistory.meta,
            publicationHistory: rHistory.error ? null : rHistory.publication,
            searchTime,
          });
        }
        return rMeta;
      }

      case 'getOptions': {
        setLoading(true);
        const res = await getOptions(action.endpoint, action.query);
        setLoading(false);
        return res;
      }

      case 'queryMeta': {
        setLoading(true);
        const timeStart = new Date();
        let res = {};
        let isFiltered = false;
        let lastQuery = null;
        if (Object.keys(action.query).length > 0) {
          res = await queryMeta(action.query);
          isFiltered = true;
          lastQuery = action.query;
        } else {
          res = await initMeta();
        }
        if (res.error) {
          setLoading(false);
        } else {
          const hRes = await saveQueryHistory('meta', lastQuery, action.cursor);
          const searchTime = (new Date().getTime() - timeStart.getTime()) / 1000;
          dispatch({
            type: 'setMeta',
            data: res.data,
            nextCursor: hRes.error ? null : hRes.nextCursor,
            lastQuery,
            isFiltered,
            searchTime,
          });
        }
        return res;
      }

      case 'exportMeta': {
        setLoading(true);
        const res = await exportMeta(action.query);
        setLoading(false);
        return res;
      }

      default: {
        dispatch(action);
        return {
          error: false, msg: 'DEFAULT', raw: {}, default: true,
        };
      }
    }
  }, [
    queryPublications,
    exportPublications,
    getProcess,
    getBlock,
    initMeta,
    getOptions,
    queryMeta,
    exportMeta,
    setLoading,
  ]);

  const bigDataAPI = useMemo(() => ({
    queryPublications: async (query, page, offset, qty, reset, cursor) => middleware({
      type: 'queryPublications', query, page, offset, qty, reset, cursor,
    }),
    exportPublications: async (query, qty) => middleware({ type: 'exportPublications', query, qty }),
    getProcess: async (cnj) => middleware({
      type: 'getProcess', cnj,
    }),
    getBlock: async (cnj, blockId) => middleware({
      type: 'getBlock', cnj, blockId,
    }),
    initMeta: async () => middleware({ type: 'initMeta' }),
    getOptions: async (endpoint, query) => middleware({ type: 'getOptions', endpoint, query }),
    queryMeta: async (query, cursor) => middleware({ type: 'queryMeta', query, cursor }),
    exportMeta: async (query) => middleware({ type: 'exportMeta', query }),
  }), [middleware]);

  return (
    <BigDataContext.Provider value={{ state, bigDataAPI }}>
      {children}
    </BigDataContext.Provider>
  );
};

export default BigDataProvider;
