import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import InputTextLine from '../InputTextLine';

const GotoPage = ({
  page, totalPages, setPage, disabled,
}) => {
  const [inputVal, setInputVal] = useState(page + 1);
  const cronRef = useRef(null);

  useEffect(() => {
    setInputVal(page + 1);
  }, [page]);

  const selectOnClick = (e) => {
    e.target.select();
  };

  const handleInput = (e) => {
    clearTimeout(cronRef.current);
    const val = Number(e.target.value.trim());
    setInputVal(val > 0 ? val : '');
    if (!Number.isNaN(val) && val > 0) {
      cronRef.current = setTimeout(() => {
        if (val <= 0) {
          setInputVal(1);
          setPage(0);
        } else if (val > totalPages) {
          setInputVal(totalPages + 1);
          setPage(totalPages);
        } else {
          setPage(val - 1);
        }
      }, 500);
    }
  };

  return (
    <InputTextLine
      wrapperStyle={{ width: '40px' }}
      style={{ textAlign: 'center' }}
      value={inputVal}
      onChange={handleInput}
      onClick={selectOnClick}
      disabled={disabled}
    />
  );
};

GotoPage.propTypes = {
  page: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

GotoPage.defaultProps = {
  disabled: false,
};

export default GotoPage;
