/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';

import ControllersGroup from '../../../controllers/ControllersGroup';
import {
  InputLineController,
  ToggleSwitchController,
  ButtonLineController,
  SelectController,
  RangeController,
  ChoiceBoxController,
} from '../../../controllers/Base';

import fontOptions from '../../../utils/controllersUtils/options';

const AxisControllers = ({
  handle,
  config,
  extend,
  setExtend,
}) => {
  const invert = () => config?.LayoutControl === 'vertical';

  return (
    <ControllersGroup title="Eixos" id="axis" extend={extend} setExtend={setExtend}>
      <ToggleSwitchController
        label={invert() ? 'Grid vertical' : 'Grid horizontal'}
        handle={handle}
        kWord={invert() ? 'EnableGridX' : 'EnableGridY'}
        configValue={invert() ? config?.EnableGridX : config?.EnableGridY}
        firstOption=""
        secondOption=""
      />
      <ToggleSwitchController
        label={invert() ? 'Grid horizontal' : 'Grid vertical'}
        handle={handle}
        kWord={invert() ? 'EnableGridY' : 'EnableGridX'}
        configValue={invert() ? config?.EnableGridY : config?.EnableGridX}
        firstOption=""
        secondOption=""
      />
      <ToggleSwitchController
        label="Eixo superior"
        handle={handle}
        kWord="EnableAxisTop"
        configValue={config?.EnableAxisX?.axisTop}
        firstOption=""
        secondOption=""
      />
      <ToggleSwitchController
        label="Eixo inferior"
        handle={handle}
        kWord="EnableAxisBottom"
        configValue={config?.EnableAxisX?.axisBot}
        firstOption=""
        secondOption=""
      />
      <RangeController
        label="Quebras de linhas das categorias (máx)"
        handle={handle}
        kWord="AxisXMaxLabelLines"
        configValue={config?.EnableAxisX?.maxLabelLines}
        min={1}
        max={4}
        fullWidth
      />
      <RangeController
        label="Marcação do eixo horizontal"
        handle={handle}
        kWord="AxisXTickSize"
        configValue={config?.EnableAxisX?.tickSize}
        min={0}
        max={20}
        fullWidth
        disabled={!config?.EnableAxisX?.axisTop && !config?.EnableAxisX?.axisBot}
      />
      <RangeController
        label="Distância do eixo horizontal"
        handle={handle}
        kWord="AxisXTickPadding"
        configValue={config?.EnableAxisX?.tickPadding}
        min={0}
        max={20}
        fullWidth
        disabled={!config?.EnableAxisX?.axisTop && !config?.EnableAxisX?.axisBot}
      />
      <RangeController
        label="Rotação do eixo horizontal"
        handle={handle}
        kWord="AxisXTickRotation"
        configValue={config?.EnableAxisX?.tickRotation}
        min={-180}
        max={180}
        fullWidth
        disabled={!config?.EnableAxisX?.axisTop && !config?.EnableAxisX?.axisBot}
      />
      <InputLineController
        label="Rótulo do eixo horizontal"
        handle={handle}
        kWord="AxisXLegend"
        placeholder={invert() ? 'Exemplo: Categorias' : 'Exemplo: Valores'}
        configValue={config?.EnableAxisX?.legend}
        disabled={!config?.EnableAxisX?.axisTop && !config?.EnableAxisX?.axisBot}
        fullWidth
      />
      <RangeController
        label="Posição do rótulo horizontal"
        handle={handle}
        kWord="AxisXLegendOffset"
        configValue={config?.EnableAxisX?.legendOffset}
        min={-120}
        max={80}
        fullWidth
        disabled={!config?.EnableAxisX?.axisTop && !config?.EnableAxisX?.axisBot}
      />
      {!invert() && (
        <>
          <ChoiceBoxController
            label="Casas decimais"
            handle={handle}
            kWord="DecimalsX"
            options={[
              { value: 'auto', label: 'Automático', id: 'auto' },
              { value: 'fixed', label: 'Customizável', id: 'fixed' },
            ]}
            configValue={config?.EnableAxisX?.Decimals}
            fullWidth
            disabled={!config?.EnableAxisX?.axisTop && !config?.EnableAxisX?.axisBot}
          />
          <RangeController
            label="Quantidade de casas"
            handle={handle}
            kWord="separadorNumericoCasasX"
            configValue={config?.EnableAxisX?.separadorNumericoCasasX}
            min={0}
            max={5}
            step={1}
            precision={0}
            fullWidth
            disabled={(
              !config?.EnableAxisX?.axisTop && !config?.EnableAxisX?.axisBot
            ) || config?.EnableAxisX?.Decimals !== 'fixed'}
          />
        </>
      )}

      <hr style={{ width: '100%', color: 'lightgray', opacity: '0.2' }} />

      <ToggleSwitchController
        label="Eixo esquerdo"
        handle={handle}
        kWord="EnableAxisLeft"
        configValue={config?.EnableAxisY?.axisLeft}
        firstOption=""
        secondOption=""
      />
      <ToggleSwitchController
        label="Eixo direito"
        handle={handle}
        kWord="EnableAxisRight"
        configValue={config?.EnableAxisY?.axisRight}
        firstOption=""
        secondOption=""
      />
      <RangeController
        label="Marcação do eixo vertical"
        handle={handle}
        kWord="AxisYTickSize"
        configValue={config?.EnableAxisY?.tickSize}
        min={0}
        max={20}
        fullWidth
        disabled={!config?.EnableAxisY?.axisLeft && !config?.EnableAxisY?.axisRight}
      />
      <RangeController
        label="Distância do eixo vertical"
        handle={handle}
        kWord="AxisYTickPadding"
        configValue={config?.EnableAxisY?.tickPadding}
        min={0}
        max={20}
        fullWidth
        disabled={!config?.EnableAxisY?.axisLeft && !config?.EnableAxisY?.axisRight}
      />
      <RangeController
        label="Rotação do eixo vertical"
        handle={handle}
        kWord="AxisYTickRotation"
        configValue={config?.EnableAxisY?.tickRotation}
        min={-180}
        max={180}
        fullWidth
        disabled={!config?.EnableAxisY?.axisLeft && !config?.EnableAxisY?.axisRight}
      />
      <InputLineController
        label="Rótulo do eixo vertical"
        handle={handle}
        kWord="AxisYLegend"
        placeholder={invert() ? 'Exemplo: Valores' : 'Exemplo: Categorias'}
        configValue={config?.EnableAxisY?.legend}
        disabled={!config?.EnableAxisY?.axisLeft && !config?.EnableAxisY?.axisRight}
        fullWidth
      />
      <RangeController
        label="Posição do rótulo vertical"
        handle={handle}
        kWord="AxisYLegendOffset"
        configValue={config?.EnableAxisY?.legendOffset}
        min={-120}
        max={80}
        fullWidth
        disabled={!config?.EnableAxisY?.axisLeft && !config?.EnableAxisY?.axisRight}
      />
      {invert() && (
        <>
          <ChoiceBoxController
            label="Casas decimais"
            handle={handle}
            kWord="DecimalsY"
            options={[
              { value: 'auto', label: 'Automático', id: 'auto' },
              { value: 'fixed', label: 'Customizável', id: 'fixed' },
            ]}
            configValue={config?.EnableAxisY?.Decimals}
            fullWidth
            disabled={!config?.EnableAxisY?.axisLeft && !config?.EnableAxisY?.axisRight}
          />
          <RangeController
            label="Quantidade de casas"
            handle={handle}
            kWord="separadorNumericoCasasY"
            configValue={config?.EnableAxisY?.separadorNumericoCasasY}
            min={0}
            max={5}
            step={1}
            precision={0}
            fullWidth
            disabled={(
              !config?.EnableAxisY?.axisLeft && !config?.EnableAxisY?.axisRight
            ) || config?.EnableAxisY?.Decimals !== 'fixed'}
          />
        </>
      )}

      <hr style={{ width: '100%', color: 'lightgray', opacity: '0.2' }} />

      <SelectController
        label="Moeda"
        handle={handle}
        kWord="CurrencyControl"
        options={['Nenhum', 'R$', 'US$', '€'].map((d) => ({ value: d, label: d, id: d }))}
        configValue={config?.CurrencyControl}
        disabled={
          !config?.EnableAxisY?.axisLeft
          && !config?.EnableAxisY?.axisRight
          && !config?.EnableAxisX?.axisTop
          && !config?.EnableAxisX?.axisBot
        }
      />
      <SelectController
        label="Agrupamento"
        handle={handle}
        kWord="GroupingSelectControl"
        options={[
          { value: 'Nenhum', label: 'Nenhum', id: 'Nenhum' },
          { value: 'milhar', label: 'Milhar (M)', id: 'Milhar (M)' },
          { value: 'milhao', label: 'Milhão (Mi)', id: 'Milhão (Mi)' },
          { value: 'bilhao', label: 'Bilhão (Bi)', id: 'Bilhão (Bi)' },
        ]}
        configValue={config?.GroupingSelectControl}
        disabled={
          !config?.EnableAxisY?.axisLeft
          && !config?.EnableAxisY?.axisRight
          && !config?.EnableAxisX?.axisTop
          && !config?.EnableAxisX?.axisBot
        }
      />
      <SelectController
        label="Fonte"
        handle={handle}
        kWord="AxisGlobalFontFamily"
        options={fontOptions}
        configValue={config?.AxisGlobal?.fontFamily}
        disabled={
          !config?.EnableAxisY?.axisLeft
          && !config?.EnableAxisY?.axisRight
          && !config?.EnableAxisX?.axisTop
          && !config?.EnableAxisX?.axisBot
        }
      />
      <InputLineController
        label="Tamanho"
        handle={handle}
        kWord="AxisGlobalFontSize"
        configValue={config?.AxisGlobal?.fontSize}
        min={1}
        max={30}
        step={1}
        precision={0}
        isNumber
        disabled={
          !config?.EnableAxisY?.axisLeft
          && !config?.EnableAxisY?.axisRight
          && !config?.EnableAxisX?.axisTop
          && !config?.EnableAxisX?.axisBot
        }
      />
      <InputLineController
        label="Tamanho do rótulo"
        handle={handle}
        kWord="AxisGlobalLegendFontSize"
        configValue={config?.AxisGlobal?.legendFontSize}
        min={1}
        max={50}
        step={1}
        precision={0}
        isNumber
        disabled={
          !config?.EnableAxisY?.axisLeft
          && !config?.EnableAxisY?.axisRight
          && !config?.EnableAxisX?.axisTop
          && !config?.EnableAxisX?.axisBot
        }
      />
      <ButtonLineController
        handle={handle}
        fontColorVal={config?.AxisGlobal?.color}
        fontColorKWord="AxisGlobalFontColor"
        disabled={
          !config?.EnableAxisY?.axisLeft
          && !config?.EnableAxisY?.axisRight
          && !config?.EnableAxisX?.axisTop
          && !config?.EnableAxisX?.axisBot
        }
      />
    </ControllersGroup>
  );
};

AxisControllers.propTypes = {
  config: PropTypes.objectOf(PropTypes.any),
  handle: PropTypes.func,
  extend: PropTypes.string,
  setExtend: PropTypes.func,
};

AxisControllers.defaultProps = {
  config: undefined,
  handle: () => {},
  extend: '',
  setExtend: () => {},
};

export default AxisControllers;
