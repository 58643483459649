import React from 'react';
import PropTypes from 'prop-types';

const Csv = ({ width, height }) => (
  <svg width={width} height={height} viewBox="0 0 23 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.75 0C1.68164 0 0 1.68164 0 3.75V26.25C0 28.3184 1.68164 30 3.75 30H18.75C20.8184 30 22.5 28.3184 22.5 26.25V9.375H15C13.9629 9.375 13.125 8.53711 13.125 7.5V0H3.75ZM15 0V7.5H22.5L15 0Z" fill="#169154" />
    <path d="M2.83154 23.2275V25H1.19336V23.2275H2.83154Z" fill="white" />
    <path d="M8.81623 20.0962H6.555V18.5815C6.555 18.1411 6.52994 17.8672 6.4798 17.7598C6.43326 17.6488 6.32762 17.5933 6.16291 17.5933C5.97671 17.5933 5.85855 17.6595 5.80842 17.792C5.75829 17.9245 5.73322 18.2109 5.73322 18.6514V22.6904C5.73322 23.113 5.75829 23.3887 5.80842 23.5176C5.85855 23.6465 5.97134 23.7109 6.1468 23.7109C6.31509 23.7109 6.4243 23.6465 6.47443 23.5176C6.52814 23.3887 6.555 23.0861 6.555 22.6099V21.5195H8.81623V21.8579C8.81623 22.7567 8.75178 23.394 8.62287 23.77C8.49755 24.146 8.21646 24.4754 7.77961 24.7583C7.34634 25.0412 6.81102 25.1826 6.17365 25.1826C5.51122 25.1826 4.96516 25.0627 4.53547 24.8228C4.10578 24.5828 3.82111 24.2516 3.68146 23.8291C3.54182 23.403 3.47199 22.7638 3.47199 21.9116V19.3711C3.47199 18.7445 3.49348 18.2754 3.53645 17.9639C3.57941 17.6488 3.70653 17.3462 3.91779 17.0562C4.13264 16.7661 4.42805 16.5387 4.80402 16.374C5.18358 16.2057 5.61864 16.1216 6.1092 16.1216C6.77522 16.1216 7.32486 16.2505 7.75813 16.5083C8.19139 16.7661 8.47606 17.0884 8.61213 17.4751C8.7482 17.8582 8.81623 18.4562 8.81623 19.269V20.0962Z" fill="white" />
    <path d="M14.4733 18.936H12.3732V18.2915C12.3732 17.9907 12.3463 17.7992 12.2926 17.7168C12.2389 17.6344 12.1494 17.5933 12.0241 17.5933C11.888 17.5933 11.7842 17.6488 11.7125 17.7598C11.6445 17.8708 11.6105 18.0391 11.6105 18.2646C11.6105 18.5547 11.6499 18.7731 11.7287 18.9199C11.8038 19.0667 12.0169 19.244 12.3678 19.4517C13.374 20.0496 14.0078 20.5402 14.2692 20.9233C14.5306 21.3065 14.6613 21.9242 14.6613 22.7764C14.6613 23.3958 14.5879 23.8524 14.4411 24.146C14.2978 24.4396 14.0185 24.6867 13.6032 24.8872C13.1878 25.0841 12.7044 25.1826 12.153 25.1826C11.5478 25.1826 11.0304 25.068 10.6007 24.8389C10.1746 24.6097 9.89532 24.3179 9.76283 23.9634C9.63034 23.6089 9.5641 23.1058 9.5641 22.4541V21.8848H11.6642V22.9429C11.6642 23.2687 11.6928 23.4782 11.7501 23.5713C11.811 23.6644 11.9166 23.7109 12.067 23.7109C12.2174 23.7109 12.3284 23.6519 12.4 23.5337C12.4752 23.4155 12.5128 23.2401 12.5128 23.0073C12.5128 22.4953 12.443 22.1605 12.3034 22.0029C12.1601 21.8454 11.8074 21.5822 11.2453 21.2134C10.6831 20.841 10.3107 20.5706 10.1281 20.4023C9.94545 20.234 9.79327 20.0013 9.67152 19.7041C9.55336 19.4069 9.49428 19.0273 9.49428 18.5654C9.49428 17.8994 9.57842 17.4124 9.74672 17.1045C9.91859 16.7965 10.1943 16.5566 10.5739 16.3848C10.9534 16.2093 11.4118 16.1216 11.9489 16.1216C12.5361 16.1216 13.0356 16.2165 13.4474 16.4062C13.8628 16.596 14.1367 16.8359 14.2692 17.126C14.4052 17.4124 14.4733 17.9012 14.4733 18.5923V18.936Z" fill="white" />
    <path d="M20.8017 16.3042L19.6523 25H16.2202L14.915 16.3042H17.2998C17.5755 18.6997 17.7724 20.7246 17.8906 22.3789C18.0088 20.7067 18.1323 19.2207 18.2612 17.9209L18.417 16.3042H20.8017Z" fill="white" />
  </svg>
);

Csv.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

Csv.defaultProps = {
  width: '25px',
  height: '25px',
};

export default Csv;
