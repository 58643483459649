/* eslint-disable react/prop-types */
import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useReducer,
} from 'react';

import { ThemeProvider } from 'styled-components';
import { AuthContext } from '../AuthProvider';
import { UsersContext } from '../UsersProvider';

import GlobalStyles from '../../juristec-ui/styles/globalStyles';
import { themeModes } from '../../juristec-ui/styles/theme';

const initialState = {
  isLoading: false,
  themeMode: 'light',
};

const reduce = (state, action) => {
  switch (action.type) {
    case 'setMode': {
      return {
        ...state,
        themeMode: action.mode,
        isLoading: false,
      };
    }

    case 'setLoading': {
      return {
        ...state,
        isLoading: action.isLoading,
      };
    }

    default:
      return state;
  }
};

export const StyleContext = createContext({});

const StyleProvider = ({ children }) => {
  const { currentUser, user, changeUserConfigs } = useContext(AuthContext);
  const { usersAPI } = useContext(UsersContext);

  const [state, dispatch] = useReducer(reduce, initialState);
  const setLoading = useCallback((l = true) => dispatch({ type: 'setLoading', isLoading: l }), []);

  /**
   * Retrieves user theme mode
   * @callback init
   */
  const init = useCallback(() => (user.configs && user.configs.theme) || 'light', [currentUser, user]);

  /**
   * [Firestore-request]
   *
   * Changes theme mode and saves to user document
   * @callback toggleThemeMode
   */
  const toggleThemeMode = useCallback(async () => {
    const themeConfig = {
      theme: state.themeMode === 'light' ? 'dark' : 'light',
    };
    /* Saves in localStorage */
    changeUserConfigs(themeConfig);
    /* Saves in firestore */
    const res = await usersAPI.setUserConfigs(themeConfig);
    if (res.error) {
      console.log(res);
    }
    return themeConfig.theme;
  }, [currentUser, user, state]);

  const middleware = useCallback(async (action) => {
    switch (action.type) {
      case 'init': {
        if (state.isLoading) break;
        setLoading(true);
        dispatch({
          type: 'setMode',
          mode: init(),
        });
        break;
      }
      case 'toggleThemeMode': {
        if (state.isLoading) break;
        setLoading(true);
        dispatch({
          type: 'setMode',
          mode: await toggleThemeMode(),
        });
        break;
      }
      default: {
        dispatch(action);
        break;
      }
    }
  }, [
    init,
    toggleThemeMode,
  ]);

  const styleAPI = useMemo(() => ({
    init: () => middleware({ type: 'init' }),
    toggleThemeMode: () => middleware({ type: 'toggleThemeMode' }),
  }), [middleware]);

  return (
    <StyleContext.Provider value={{ state, styleAPI }}>
      <ThemeProvider theme={themeModes[state.themeMode]}>
        <GlobalStyles />
        {children}
      </ThemeProvider>
    </StyleContext.Provider>
  );
};

export default StyleProvider;
