const numericGroupOpts = (type) => {
  switch (type) {
    case 'milhar':
      return { divisor: 1000, suffix: 'm' };
    case 'milhao':
      return { divisor: 1000000, suffix: 'mi' };
    case 'bilhao':
      return { divisor: 1000000000, suffix: 'bi' };
    default:
      return { divisor: 1, suffix: '' };
  }
};

const localeAndCurrencyOpts = (currency) => {
  switch (currency) {
    case 'R$':
      return { style: 'currency', locale: 'pt-BR', currency: 'BRL' };
    case 'US$':
      return { style: 'currency', locale: 'en-US', currency: 'USD' };
    case '€':
      return { style: 'currency', locale: 'de-DE', currency: 'EUR' };
    default:
      return { style: 'decimal', locale: 'pt-BR' };
  }
};

const toLocaleValue = (value, options = {}) => (
  parseFloat(value, 10).toLocaleString(options.locale, options)
);

/**
 * Format the value numbers.
 * @param {number|string} value value of a portion.
 * @param {string} currency Formats the value for the given currency.
 * Also determine the location.
 * @param {string} fDecimals How to handle the fraction digits.
 * @param {string} nDecimals Number of fraction digits if `fDecimals` is 'fixed'.
 * @param {string} formatType Numeric style.
 * @param {string} grouping Numeric grouping style.
 * @returns Formatted value.
 */
const dataValueFormat = (value, currency, fDecimals, nDecimals, formatType, grouping) => {
  if (!Number.isNaN(value)) {
    const localeOpts = localeAndCurrencyOpts(currency);

    if (fDecimals) {
      if (fDecimals === 'fixed') {
        localeOpts.minimumFractionDigits = nDecimals || 0;
        localeOpts.maximumFractionDigits = nDecimals || 0;
      }
    } else localeOpts.minimumFractionDigits = nDecimals || 0;

    if (formatType === 'percentual') {
      localeOpts.style = 'percent';
      return `${toLocaleValue(value / 100, localeOpts)}`;
    }

    const groupingTools = numericGroupOpts(grouping);
    return `${toLocaleValue(value / groupingTools.divisor, localeOpts)}${groupingTools.suffix}`;
  } return null;
};

/**
 * Format the `label values` in the chart and add prefix and/or suffix (visual only).
 * @param {number} value value of a portion.
 * @param {object} config Style config.
 * @param {string} [category] Category name.
 * @returns Formatted value.
 */
export const customValueLabel = (value, config, category) => {
  const fValue = dataValueFormat(
    Number(value),
    config?.LabelCurrencyControl,
    config?.Decimals,
    config?.separadorNumericoCasas,
    config?.DataFormat?.type,
    config?.type === 'Value' ? config?.GroupingSelectControl : config?.LabelGroupingControl,
  );
  const prefix = config?.PrefixControl?.trim() || '';
  const suffix = config?.SufixControl?.trim() || '';

  switch (config?.LabelAxisSelect) {
    case 'x': {
      return `${prefix} ${category} ${suffix}`.trim();
    }
    case 'x : y': {
      return `${prefix} ${category} : ${fValue} ${suffix}`.trim();
    }
    case 'y : x': {
      return `${prefix} ${fValue} : ${category} ${suffix}`.trim();
    }
    default: {
      return `${prefix} ${fValue} ${suffix}`.trim();
    }
  }
};

/**
 * Format the `tooltip values` in the chart and add prefix and/or suffix (visual only).
 * @param {number} value value of a portion.
 * @param {object} config Style config.
 * @returns Formatted value.
 */
export const customValueTooltip = (value, config) => {
  const fValue = dataValueFormat(
    Number(value),
    config?.InteractiveCurrencyControl,
    config?.InteractiveDecimals,
    config?.InteractiveSeparadorNumericoCasas,
    config?.DataFormat?.type,
    config?.InteractiveGroupingControl,
  );
  const prefix = config?.InteractivePrefixControl?.trim() || '';
  const suffix = config?.InteractiveSufixControl?.trim() || '';
  return `${prefix} ${fValue} ${suffix}`.trim();
};

/**
 * Format the `axis values` in the chart and add prefix and/or suffix (visual only).
 * @param {number} value value of a portion.
 * @param {object} config Style config.
 * @returns Formatted value.
 */
export const customValueAxis = (value, config, axisType = 'X') => {
  const configType = config?.[`EnableAxis${axisType}`];
  const fValue = dataValueFormat(
    Number(value),
    config?.CurrencyControl,
    configType?.Decimals,
    configType?.[`separadorNumericoCasas${axisType}`],
    config?.DataFormat?.type,
    config?.GroupingSelectControl,
  );
  return fValue || value;
};
