/* eslint-disable no-param-reassign */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';

import ControllersGroup from '../../../controllers/ControllersGroup';
import {
  SelectController,
  RangeController,
  ChoiceBoxController,
  SimpleColorPickerController,
  ToggleSwitchController,
  GradientColorPickerController,
} from '../../../controllers/Base';

import { getOptionsThemes } from '../../../utils/controllersUtils/themeColors';

const StyleControllers = ({
  handle,
  config,
  data,
  extend,
  setExtend,
  selectedLine,
  isGlobalThemeActive,
}) => (
  <ControllersGroup
    title="Estilos"
    id="styles"
    extend={extend}
    setExtend={setExtend}
    sizeIncrease={config?.PalletsControl === 'custom' ? data?.index?.length : 0}
  >
    <RangeController
      label="Tamanho dos pontos"
      handle={handle}
      kWord="NodeSizeControl"
      configValue={config?.NodeSizeControl}
      min={1}
      max={20}
      fullWidth
    />
    <ChoiceBoxController
      label="Paleta de cores"
      handle={handle}
      kWord="PalletsControl"
      options={[
        { label: 'Padrão', value: 'default', id: 'default' },
        { label: 'Degradê', value: 'gradient', id: 'gradient' },
        { label: 'Customizada', value: 'custom', id: 'custom' },
      ]}
      configValue={config?.PalletsControl}
      fullWidth
      text="Utilizando tema global"
      disabled={config?.UseGlobalColor !== false && isGlobalThemeActive}
    />
    {config?.PalletsControl === 'default' && (
      <SelectController
        label="Tema"
        handle={handle}
        kWord="ColorTheme"
        options={getOptionsThemes()}
        configValue={config?.ColorTheme?.scheme}
        fullWidth
        text="Utilizando tema global"
        disabled={config?.UseGlobalColor !== false && isGlobalThemeActive}
      />
    )}
    {config?.PalletsControl === 'gradient' && (
      <GradientColorPickerController
        handle={handle}
        firstColorVal={config?.GradientPickerControl?.color1 || '#4711B2'}
        firstColorKWord="GradientFirstColor"
        secondColorVal={config?.GradientPickerControl?.color2 || '#FF9626'}
        secondColorKWord="GradientSecondColor"
        thirdColorVal={config?.GradientPickerControl?.color3}
        thirdColorKWord="GradientThirdColor"
        fullWidth
        orientation="row"
        text="Utilizando tema global"
        disabled={config?.UseGlobalColor !== false && isGlobalThemeActive}
      />
    )}
    {config?.PalletsControl === 'custom' && (
      selectedLine ? (
        Object.values([
          ...new Set(typeof data?.index?.[0] === 'string' ? data?.index : []),
        ].reduce((aux, key, i) => {
          aux[key] = (
            <SimpleColorPickerController
              dataColorTarget={key.toString()}
              key={`${key}_i${i}`}
              label={key}
              handle={handle}
              kWord="Colors"
              configValue={config?.Colors[key]}
              orientation="row"
              fullWidth
              text="Utilizando tema global"
              disabled={config?.UseGlobalColor !== false && isGlobalThemeActive}
            />
          );
          return aux;
        }, {}) || {})
      ) : (
        <SimpleColorPickerController
          dataColorTarget="Valores"
          key="Valores_i0"
          label="Valores"
          handle={handle}
          kWord="Colors"
          configValue={config?.Colors.Valores}
          orientation="row"
          fullWidth
          text="Utilizando tema global"
          disabled={config?.UseGlobalColor !== false && isGlobalThemeActive}
        />
      ))}
    <ToggleSwitchController
      label="Utilizar tema global"
      handle={handle}
      kWord="UseGlobalColor"
      configValue={config?.UseGlobalColor}
      firstOption=""
      secondOption=""
      fullWidth
    />
  </ControllersGroup>
);

StyleControllers.propTypes = {
  config: PropTypes.objectOf(PropTypes.any),
  data: PropTypes.objectOf(PropTypes.any),
  handle: PropTypes.func,
  extend: PropTypes.string,
  setExtend: PropTypes.func,
  selectedLine: PropTypes.objectOf(PropTypes.any),
  isGlobalThemeActive: PropTypes.bool,
};

StyleControllers.defaultProps = {
  config: undefined,
  data: undefined,
  handle: () => {},
  extend: '',
  setExtend: () => {},
  selectedLine: undefined,
  isGlobalThemeActive: false,
};

export default StyleControllers;
