import React from 'react';
import PropTypes from 'prop-types';

import Popover from '../../../juristec-ui/core/Popover';
import IconButton from '../../../juristec-ui/core/IconButton';
import ListItem from '../../../juristec-ui/core/ListItem';
import List from '../../../juristec-ui/core/List';

import useToggleState from '../../../juristec-ui/hooks/useToggleState';

import {
  ExpandMore, Letters, Number, DateIcon,
} from '../../../juristec-ui/icons';

import {
  IconFlipWrapper, IconWrapper, Label,
} from './styled/TypePicker.styled';

const TypeLabels = {
  abc: 'Texto',
  float: 'Número',
  date: 'Data',
};

const typeColor = {
  float: 'typeNumber',
  abc: 'typeText',
  date: 'typeDate',
};

const typeIcon = {
  float: <Number />,
  abc: <Letters />,
  date: <DateIcon />,
};

const TypePicker = ({ column, type, handleTypeChange }) => {
  const [showPop, togglePop, closePop] = useToggleState(false);

  const handleChangeToText = () => handleTypeChange(column, 'abc');

  const handleChangeToFloat = () => handleTypeChange(column, 'float');

  const handleChangeToDate = () => handleTypeChange(column, 'date');

  return (
    <Popover
      open={showPop}
      closePopover={closePop}
      offset={[0, 0]}
      style={{ zIndex: '1050' }}
    >
      <Popover.Action>
        <IconButton
          onClick={togglePop}
          shape="rounded"
          variant="contained"
          color={typeColor[type]}
          style={{
            padding: '0 0 0 5px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            whiteSpace: 'nowrap',
            width: '100%',
          }}
        >
          <IconWrapper>{typeIcon[type]}</IconWrapper>
          <Label>{TypeLabels[type]}</Label>
          <IconFlipWrapper flip={showPop}>
            <ExpandMore />
          </IconFlipWrapper>
        </IconButton>
      </Popover.Action>
      <Popover.Content direction="bottom-end">
        <List>
          <ListItem
            noOutline
            onClick={handleChangeToText}
          >
            <Letters />
            Validar como texto
          </ListItem>
          <ListItem
            noOutline
            onClick={handleChangeToFloat}
          >
            <Number />
            Validar como número
          </ListItem>

          <ListItem
            noOutline
            onClick={handleChangeToDate}
          >
            <DateIcon />
            Validar como data
          </ListItem>

        </List>
      </Popover.Content>
    </Popover>
  );
};

TypePicker.propTypes = {
  column: PropTypes.string,
  type: PropTypes.string,
  handleTypeChange: PropTypes.func.isRequired,
};

TypePicker.defaultProps = {
  column: '',
  type: '',
};

export default TypePicker;
