import React, { useState } from 'react';
import PropTypes from 'prop-types';

import InputTextLine from '../../../juristec-ui/core/InputTextLine';
import IconButton from '../../../juristec-ui/core/IconButton';
import ActionsGroup from '../../../juristec-ui/core/ActionsGroup';
import Button from '../../../juristec-ui/core/Button';
import { compactString } from '../../../juristec-ui/utils/functions/formatString';
import { verifyCurrentPassword, verifyRepeatNewPassword } from '../../../juristec-ui/utils/validators/inputTextValidators';

import { Visibility, VisibilityOff } from '../../../juristec-ui/icons';

import { MainContainer, InfoText, FormColumn, FormRow } from './styled/ChangeDatabasePassword.styled';

/** Transfers all allowed data from one user to another */
const ChangeDatabasePassword = ({
  close,
  submit,
}) => {
  const [showPass, setShowPass] = useState(false);
  const [password, setPassword] = useState({ value: '', error: true, errorMsg: '' });
  const [confirmPassword, setConfirmPassword] = useState({ value: '', error: true, errorMsg: '' });

  const passwordHandle = (val) => {
    const msg = verifyCurrentPassword(val, '', false);
    setPassword({
      value: val,
      error: msg.length !== 0,
      errorMsg: msg,
    });
  };

  const repeatPasswordHandle = (val) => {
    const msg = verifyRepeatNewPassword(val, password.value);
    setConfirmPassword({
      value: val,
      error: msg.length !== 0,
      errorMsg: msg,
    });
  };

  const handleSubmit = () => {
    submit(password.value);
  };

  return (
    <MainContainer>
      <InfoText>
        Cadastre a nova senha de acesso ao banco de dados
      </InfoText>
      <FormColumn>
        <FormRow>
          <div style={{ position: 'relative', width: '100%' }}>
            <InputTextLine
              label="Nova Senha*"
              type={!showPass ? 'password' : 'text'}
              value={password.value}
              errorMessage={password.errorMsg}
              error={password.errorMsg.length > 0}
              notTransparent={false}
              onChange={(e) => passwordHandle(compactString(e.target.value))}
              style={{ paddingRight: '35px' }}
            />
            <IconButton
              style={{
                position: 'absolute', bottom: 4, right: 2, boxShadow: 'none', padding: 2,
              }}
              type="button"
              variant="pattern"
              iconColor="black"
              onMouseDown={() => setShowPass(!showPass)}
            >
              {showPass ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </div>
        </FormRow>
        <FormRow>
          <div style={{ position: 'relative', width: '100%' }}>
            <InputTextLine
              label="Confirmar a Senha*"
              type={!showPass ? 'password' : 'text'}
              value={confirmPassword.value}
              errorMessage={confirmPassword.errorMsg}
              error={confirmPassword.errorMsg.length > 0}
              notTransparent={false}
              onChange={(e) => repeatPasswordHandle(compactString(e.target.value))}
              style={{ paddingRight: '35px' }}
            />
            <IconButton
              style={{
                position: 'absolute', bottom: 4, right: 2, boxShadow: 'none', padding: 2,
              }}
              type="button"
              variant="pattern"
              iconColor="black"
              onMouseDown={() => setShowPass(!showPass)}
            >
              {showPass ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </div>
        </FormRow>
      </FormColumn>
      <ActionsGroup>
        <Button
          style={{ margin: '5px' }}
          onClick={close}
          variant="outlined"
          size="large"
        >
          Cancelar
        </Button>
        <Button
          style={{ margin: '5px' }}
          onClick={handleSubmit}
          size="large"
          disabled={password.error || confirmPassword.error}
        >
          Alterar
        </Button>
      </ActionsGroup>
    </MainContainer>
  );
};

ChangeDatabasePassword.propTypes = {
  close: PropTypes.func.isRequired,
  submit: PropTypes.func,
};

ChangeDatabasePassword.defaultProps = {
  submit: () => {},
};

export default ChangeDatabasePassword;
