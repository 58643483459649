/* eslint-disable no-param-reassign */
import React, {
  useState, useRef, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import Select from '../../../juristec-ui/core/SelectNew';
import SelectTips from '../../Popovers/SelectTips';
import KpiFilter from '../../KpiFilter';
import Button from '../../../juristec-ui/core/Button';
import KpiFilterApplied from '../../KpiFilterApplied';
import InputTextLine from '../../../juristec-ui/core/InputTextLine';
import uuidv4 from '../../../juristec-ui/utils/functions/randomUUID';
import { trimString } from '../../../juristec-ui/utils/functions/formatString';
import { verifyInput } from '../../../juristec-ui/utils/validators/inputTextValidators';
import {
  dateOptions,
  filterTypeOptionsSimple,
  filterTypeOptionsDate,
  filterTypeOptionsNumber,
  filterTypeOptionsText,
  getFilterTypeDescription,
  getGroupTypeDescription,
} from '../../../options';
import {
  ModifColumn, ModifRow, ModifsContainer, LabelSmall,
} from './styled/SubsetTableCreate.styled';

const getTypeOptions = (modifType, columnType) => {
  if (modifType === 'simple') return filterTypeOptionsSimple;
  switch (columnType) {
    case 'datetime64[ns]':
      return filterTypeOptionsDate;
    case 'float64':
      return filterTypeOptionsNumber;
    default:
      return filterTypeOptionsText;
  }
};

const getModifierTip = (modifier, mFormat, isDate) => (
  modifier === 'filter' ? getFilterTypeDescription(mFormat, isDate) : getGroupTypeDescription(mFormat, isDate)
);

const formatValue = (value, mFormat, isDate, isSimple) => {
  if (isSimple) {
    if (value === 'select_all') return null;
    return value.value;
  }
  if (isDate && [
    'between', 'between_inc', 'before', 'before_inc', 'after', 'after_inc',
  ].includes(mFormat)) {
    return format(value, 'yyyy-MM-dd');
  }
  if (mFormat !== 'contains') {
    return Number(value);
  }
  return value;
};

const getActualTypeAndInverse = (ftype) => {
  const ftypeInfo = ftype.split('-');
  let actualFtype = ftypeInfo?.[1] || ftypeInfo?.[0];
  if (['lastY', 'lastM', 'lastD', 'lastQ', 'lastW', 'lastS'].includes(actualFtype)) {
    actualFtype = 'last';
  } else if (['nextY', 'nextM', 'nextD', 'nextQ', 'nextW', 'nextS'].includes(actualFtype)) {
    actualFtype = 'next';
  }
  return [
    ftypeInfo?.[0] === 'not',
    actualFtype,
  ];
};

const getSelectorByType = (ftype) => {
  switch (ftype) {
    case 'lastY':
    case 'not-lastY':
    case 'nextY':
    case 'not-nextY':
      return 'year';
    case 'lastM':
    case 'not-lastM':
    case 'nextM':
    case 'not-nextM':
      return 'M';
    case 'lastD':
    case 'not-lastD':
    case 'nextD':
    case 'not-nextD':
      return 'D';
    case 'lastQ':
    case 'not-lastQ':
    case 'nextQ':
    case 'not-nextQ':
      return 'Q';
    case 'lastW':
    case 'not-lastW':
    case 'nextW':
    case 'not-nextW':
      return 'W';
    case 'lastS':
    case 'not-lastS':
    case 'nextS':
    case 'not-nextS':
      return 'semester';
    default:
      return '';
  }
};

const modifInitial = {
  column: {},
  type: { label: 'Simples', value: 'simple' },
  selector: {},
  format: {},
  values: [],
  unique: [],
};

const Modifier = ({
  origin, columnOpts, getColumnUnique, addedModfs, handleAddedModifs,
}) => {
  const dataFrameTypes = useRef({});
  const [modifConfig, setModifConfig] = useState(modifInitial);
  const [groupName, setGroupName] = useState({ value: '', error: true, errorMsg: '' });

  useEffect(() => {
    dataFrameTypes.current = columnOpts.reduce((aux, cOpt) => {
      aux[cOpt.value] = cOpt.type;
      return aux;
    }, {});
  }, [columnOpts]);

  const handleGroupName = (value) => {
    const msg = verifyInput(value, true);
    setGroupName({
      value,
      error: msg.length !== 0,
      errorMsg: msg,
    });
  };

  const handleModifConfig = async (key, val) => {
    const newConfig = { ...modifConfig };
    switch (key) {
      case 'type':
        newConfig.type = val;
        newConfig.selector = null;
        newConfig.values = [];
        if (val.value === 'simple') {
          [newConfig.format] = filterTypeOptionsSimple;
        } else {
          newConfig.format = { value: '', label: '', id: '' };
        }
        break;
      case 'format':
        newConfig.format = val;
        switch (val.value) {
          case 'not-contains':
          case 'contains':
          case 'not-values':
          case 'values':
            newConfig.values = [];
            break;
          case 'not-between':
          case 'between':
          case 'not-between_inc':
          case 'between_inc':
            if (modifConfig.column.type === 'datetime64[ns]') {
              newConfig.values = [new Date(), new Date()];
            } else {
              newConfig.values = ['', ''];
            }
            break;
          case 'not-before':
          case 'before':
          case 'not-before_inc':
          case 'before_inc':
          case 'not-after':
          case 'after':
          case 'not-after_inc':
          case 'after_inc':
            newConfig.values = [new Date()];
            break;
          default:
            newConfig.values = [''];
            break;
        }
        break;
      case 'column':
        newConfig.column = val;
        if (newConfig.type.value === 'simple') {
          [newConfig.format] = filterTypeOptionsSimple;
          newConfig.unique = await getColumnUnique(newConfig.column, newConfig.selector);
        } else {
          newConfig.format = { value: '', label: '', id: '' };
        }
        break;
      case 'selector':
        newConfig.selector = val;
        newConfig.unique = await getColumnUnique(newConfig.column, newConfig.selector);
        break;
      default:
        newConfig[key] = val;
        break;
    }
    setModifConfig(newConfig);
  };

  const handleColumnSelect = (selected) => handleModifConfig('column', selected);
  const handleTypeSelect = (selected) => handleModifConfig('type', selected);
  const handleSelectorSelect = (selected) => handleModifConfig('selector', selected);
  const handleFormatSelect = (selected) => handleModifConfig('format', selected);
  const handleValues = (value) => handleModifConfig('values', value);

  const handleFormulaModifiers = (selected) => {
    const regexFloat = /^-{0,1}\d*\.{0,1}\d*/;
    const regexInt = /^\d*/;
    switch (modifConfig.format.value) {
      case 'not-values':
      case 'values':
        if (!selected) handleValues([]); // qnd limpa tudo
        else if (selected.length === 0) handleValues([]);
        else if (selected[0].value === 'select_all') handleValues(modifConfig.unique);
        else if (selected.length > 0) handleValues(selected);
        break;
      case 'not-contains':
      case 'contains': {
        const auxVals = new Set(modifConfig.values);
        auxVals[selected.operation](selected.val);
        handleValues([...auxVals]);
        break;
      }
      case 'not-between':
      case 'between':
      case 'not-between_inc':
      case 'between_inc': {
        let val1 = selected.start;
        let val2 = selected.end;
        if (modifConfig.column.type !== 'datetime64[ns]') {
          [val1] = selected.start?.toString()?.replace(',', '.')?.match(regexFloat) || '';
          [val2] = selected.end?.toString()?.replace(',', '.')?.match(regexFloat) || '';
        }
        handleValues([
          val1 || (val1 !== '' ? modifConfig.values[0] : ''), val2 || (val2 !== '' ? modifConfig.values[1] : ''),
        ]);
        break;
      }
      case 'not-before':
      case 'before':
      case 'not-before_inc':
      case 'before_inc':
      case 'not-after':
      case 'after':
      case 'not-after_inc':
      case 'after_inc':
        handleValues([selected]);
        break;
      case 'not-greater':
      case 'greater':
      case 'not-lesser':
      case 'lesser':
      case 'not-greater_inc':
      case 'greater_inc':
      case 'not-lesser_inc':
      case 'lesser_inc': {
        const [valF] = selected.toString().replace(',', '.').match(regexFloat);
        handleValues([valF || '']);
        break;
      }
      default: {
        const [val] = selected.toString().match(regexInt);
        handleValues([val || '']);
        break;
      }
    }
  };

  const checkValsModifiers = () => {
    switch (modifConfig.format.value) {
      case 'not-contains':
      case 'contains':
      case 'not-values':
      case 'values':
        return modifConfig.values.length > 0;
      case 'not-between':
      case 'between':
      case 'not-between_inc':
      case 'between_inc': {
        let val1 = modifConfig.values[0];
        let val2 = modifConfig.values[1];
        if (modifConfig.column.type !== 'datetime64[ns]') {
          if (val1?.length > 0 && val2?.length > 0) {
            val1 = Number(modifConfig.values[0]);
            val2 = Number(modifConfig.values[1]);
          } else {
            return false;
          }
        }
        return !Number.isNaN(val1) && !Number.isNaN(val2) && val2 > val1;
      }
      case 'not-before':
      case 'before':
      case 'not-before_inc':
      case 'before_inc':
      case 'not-after':
      case 'after':
      case 'not-after_inc':
      case 'after_inc':
        return modifConfig.values[0] instanceof Date;
      default: {
        if (modifConfig.values[0]?.length > 0) {
          const val = Number(modifConfig.values[0]);
          return !Number.isNaN(val);
        }
        return false;
      }
    }
  };

  const changeOrderModifs = (pos, varOp) => {
    const newPos = pos + varOp;
    if (newPos < 0 && newPos >= addedModfs.lenght) return;
    const tempList = [...addedModfs];
    const [auxItem] = tempList.splice(pos, 1);
    tempList.splice(newPos, 0, auxItem);
    handleAddedModifs(tempList);
  };

  // editFunc(modifier.uid, modifier.column, tempValues, modifier.ftype, modifier.rule, tempName);
  const editModifHandle = (modifUid, modifColumn, newValues, ftype) => {
    if (newValues.length === 0) return;
    handleAddedModifs(addedModfs.map((item) => (
      item.uid === modifUid ? {
        ...item,
        values: newValues.map((v) => (
          formatValue(v, ftype, modifColumn.type === 'datetime64[ns]', ftype === 'values')
        )),
      } : item
    )));
  };

  const removeModifHandle = (modifUid) => {
    handleAddedModifs(addedModfs.filter((item) => item.uid !== modifUid));
  };

  const addModif = () => {
    // const columnType = modifConfig.column.type;
    const [isInverse, actualType] = getActualTypeAndInverse(modifConfig.format.value);
    const isSimple = modifConfig.type.value === 'simple';

    const fObjAux = {
      uid: uuidv4(),
      column: modifConfig.column.value,
      type: modifConfig.column.type,
      ftype: actualType || 'values',
      selector: isSimple ? (modifConfig?.selector?.value || '') : getSelectorByType(modifConfig.format.value),
      values: modifConfig.values.reduce((aux, v) => {
        const val = formatValue(v, actualType, modifConfig.column.type === 'datetime64[ns]', isSimple);
        if (val || val === 0) aux.push(val);
        return aux;
      }, []),
      inverse: isInverse,
    };
    if (origin === 'group') fObjAux.rule = groupName.value;
    const nModifs = [...addedModfs].concat([fObjAux]);
    // console.log(fObjAux);
    handleAddedModifs(nModifs);
    setModifConfig(modifInitial);
    setGroupName({ value: '', error: true, errorMsg: '' });
  };

  return (
    <>
      <ModifColumn>
        <ModifRow>
          <div>
            <Select
              label={`${origin === 'group' ? 'Agrupar' : 'Filtrar'} por`}
              onChange={handleColumnSelect}
              value={modifConfig.column}
              options={columnOpts}
              atModal
              searchable
              clearable
              sortBy="ascending"
            />
          </div>
          <div>
            <Select
              label="Tipo"
              onChange={handleTypeSelect}
              value={modifConfig.type}
              options={[
                { label: 'Simples', value: 'simple', id: 'simple' },
                { label: 'Avançado', value: 'advanced', id: 'advanced' },
              ]}
              atModal
            />
          </div>
        </ModifRow>
        <ModifRow>
          {modifConfig.column?.value?.length > 0
          && origin === 'filter'
          && modifConfig.type.value === 'simple'
          && modifConfig.column.type === 'datetime64[ns]'
          && (
            <div>
              <Select
                label="Formato"
                onChange={handleSelectorSelect}
                value={modifConfig.selector}
                options={dateOptions}
                searchable
                atModal
              />
            </div>
          )}
          {modifConfig.column?.value?.length > 0 && (
            <div style={{ position: 'relative' }}>
              <Select
                label="Condição"
                onChange={handleFormatSelect}
                value={modifConfig.format}
                options={getTypeOptions(modifConfig?.type?.value, modifConfig?.column?.type)}
                searchable
                atModal
              />
              {modifConfig.format.value !== '' && (
                <SelectTips
                  description={getModifierTip(
                    origin,
                    modifConfig.format.value,
                    modifConfig.column.type === 'datetime64[ns]',
                  )}
                  style={{ top: '5px', right: '5px' }}
                />
              )}
            </div>
          )}
        </ModifRow>
        {modifConfig.column?.value?.length > 0
        && modifConfig.format?.value.length > 0
        && (modifConfig.type?.value === 'advanced' || modifConfig?.unique?.length > 0)
        && (
          <>
            <KpiFilter
              filterType={modifConfig.format.value}
              handle={handleFormulaModifiers}
              values={modifConfig.values}
              options={modifConfig.unique}
              format={modifConfig.column.type}
            />
            {origin === 'group' && (
              <InputTextLine
                label="Nome do Agrupamento"
                type="text"
                style={{ marginBottom: 0 }}
                onChange={(e) => handleGroupName(e.target.value)}
                value={groupName.value}
                errorMessage={groupName.errorMsg}
                error={groupName.errorMsg.length > 0}
                styleContainer={{ marginTop: '-8px' }}
                onBlur={(e) => handleGroupName(trimString(e.target.value))}
              />
            )}
            <Button
              onClick={addModif}
              color="secondary"
              disabled={!checkValsModifiers() || (origin === 'group' && groupName.error)}
              style={{ margin: '10px 0 10px', width: '100%' }}
            >
              {`Adicionar ${origin === 'group' ? 'agrupamento' : 'filtro'}`}
            </Button>
          </>
        )}
      </ModifColumn>
      {addedModfs.length > 0 && (
        <ModifsContainer>
          <LabelSmall>
            {`${origin === 'group' ? 'Agrupamentos' : 'Filtros'} Aplicados`}
          </LabelSmall>
          {addedModfs.map((modif, i) => (
            <KpiFilterApplied
              key={modif.uid}
              modifier={modif}
              removeFunc={removeModifHandle}
              editFunc={editModifHandle}
              getOptions={getColumnUnique}
              dataFrameTypes={dataFrameTypes.current ?? {}}
              position={i}
              isLast={addedModfs.length - 1 === i}
              changePosition={changeOrderModifs}
            />
          ))}
        </ModifsContainer>
      )}
    </>
  );
};

Modifier.propTypes = {
  origin: PropTypes.oneOf(['filter', 'group']),
  columnOpts: PropTypes.arrayOf(PropTypes.shape({})),
  getColumnUnique: PropTypes.func.isRequired,
  addedModfs: PropTypes.arrayOf(PropTypes.shape({})),
  handleAddedModifs: PropTypes.func.isRequired,
};

Modifier.defaultProps = {
  origin: 'filter',
  columnOpts: [],
  addedModfs: [],
};

export default Modifier;
