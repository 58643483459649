import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import Container from './styled/TextOverflow.styled';
import MeasureTextInstance from '../../utils/functions/measureText';

/**
 * Makes overflowed text slide to the side on hover
 * @param {object} param0
 * @param {number} param0.width Container static size (px)
 * @param {number} param0.fontSize Font size (px)
 * @param {string|''} param0.className [Optional] Html class (required for styled components)
 * @param {string} param0.children [Default] Text content (Use text only, no html tag or node)
 * @returns {JSX.Element} React Component
 */
const TextOverflow = ({
  width, fontSize, className, children,
}) => {
  const textWidth = useMemo(() => MeasureTextInstance.calc(fontSize, children), []);

  return (
    <Container
      _width={width}
      _fontSize={fontSize}
      className={className}
      textWidth={textWidth}
    >
      <span>
        {children}
      </span>
    </Container>
  );
};

TextOverflow.propTypes = {
  width: PropTypes.number.isRequired,
  fontSize: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

TextOverflow.defaultProps = {
  className: '',
};

export default TextOverflow;
