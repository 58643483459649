import React from 'react';

const HashTag = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 18 18"
    fill="white"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.2136 6.11363L17.4926 4.55113C17.5439 4.26383 17.323 4 17.0311 4H14.1089L14.6801 0.801133C14.7314 0.513828 14.5105 0.25 14.2186 0.25H12.6314C12.5214 0.250002 12.4149 0.288711 12.3305 0.359348C12.2462 0.429985 12.1893 0.528049 12.17 0.636367L11.5693 4H7.71641L8.28762 0.801133C8.33895 0.513828 8.11805 0.25 7.82618 0.25H6.23899C6.12896 0.250002 6.02243 0.288711 5.93807 0.359348C5.85371 0.429985 5.79688 0.528049 5.77754 0.636367L5.17688 4H2.08719C1.97716 4 1.87064 4.03871 1.78627 4.10935C1.70191 4.17999 1.64508 4.27805 1.62575 4.38637L1.34672 5.94887C1.29543 6.23617 1.51633 6.5 1.80817 6.5H4.73043L3.83758 11.5H0.747895C0.637864 11.5 0.53134 11.5387 0.446977 11.6093C0.362614 11.68 0.305788 11.778 0.28645 11.8864L0.00742618 13.4489C-0.0438629 13.7362 0.177036 14 0.468872 14H3.39114L2.81993 17.1989C2.76864 17.4862 2.98954 17.75 3.28137 17.75H4.86856C4.97859 17.75 5.08511 17.7113 5.16948 17.6407C5.25384 17.57 5.31067 17.472 5.33 17.3636L5.93071 14H9.7836L9.21239 17.1989C9.16106 17.4862 9.38196 17.75 9.67383 17.75H11.261C11.3711 17.75 11.4776 17.7113 11.5619 17.6407C11.6463 17.57 11.7031 17.472 11.7225 17.3636L12.3231 14H15.4128C15.5228 14 15.6294 13.9613 15.7137 13.8907C15.7981 13.82 15.8549 13.722 15.8743 13.6136L16.1533 12.0511C16.2046 11.7638 15.9837 11.5 15.6918 11.5H12.7696L13.6624 6.5H16.7521C16.8621 6.5 16.9687 6.46129 17.053 6.39065C17.1374 6.32001 17.1942 6.22195 17.2136 6.11363ZM10.23 11.5H6.37715L7.27 6.5H11.1229L10.23 11.5Z"
    />
  </svg>
);

export default HashTag;
