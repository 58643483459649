/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';

import ControllersGroup from '../../../controllers/ControllersGroup';
import {
  InputLineController,
  ToggleSwitchController,
  SelectController,
  RangeController,
  ChoiceBoxController,
  CustomSortController,
} from '../../../controllers/Base';

const GeneralControllers = ({
  handle,
  config,
  data,
  extend,
  setExtend,
  selectedColumn,
}) => (
  <>
    <ControllersGroup
      title="Opções Gerais"
      id="general"
      extend={extend}
      setExtend={setExtend}
      sizeIncrease={config?.SortValuesControl === 'Customizado' ? data?.index?.length : 0}
    >
      <SelectController
        label="Ordenar campos"
        handle={handle}
        kWord="SortValuesControl"
        options={selectedColumn ? [
          { label: 'Automático', value: 'Não ordenado', id: 'nao-ordenado' },
          { label: 'Alfabética A-Z', value: 'Alfabética A-Z', id: 'alfabetica-a-z' },
          { label: 'Alfabética Z-A', value: 'Alfabética Z-A', id: 'alfabetica-z-a' },
          { label: 'Customizado', value: 'Customizado', id: 'customizado' },
        ] : [
          { label: 'Automático', value: 'Não ordenado', id: 'nao-ordenado' },
          { label: 'Alfabética A-Z', value: 'Alfabética A-Z', id: 'alfabetica-a-z' },
          { label: 'Alfabética Z-A', value: 'Alfabética Z-A', id: 'alfabetica-z-a' },
          { label: 'Valor Crescente', value: 'Valor Crescente', id: 'valor-crescente' },
          { label: 'Valor Decrescente', value: 'Valor Decrescente', id: 'valor-decrescente' },
          { label: 'Customizado', value: 'Customizado', id: 'customizado' },
        ]}
        configValue={config?.SortValuesControl}
        fullWidth
      />
      {config?.SortValuesControl === 'Customizado' && (
        <CustomSortController
          handle={handle}
          kWord="CustomSortControlUpt"
          oldOrder={(selectedColumn ? data?.columns : data?.index) || []}
          newOrder={config?.CustomSortControl?.kpiCategories || []}
          removeTotal
        />
      )}
      <RangeController
        label="Margem Superior"
        handle={handle}
        kWord="MarginTop"
        configValue={config?.Margin?.top}
        min={0}
        max={500}
        step={1}
        precision={0}
        fullWidth
      />
      <RangeController
        label="Margem Inferior"
        handle={handle}
        kWord="MarginBottom"
        configValue={config?.Margin?.bottom}
        min={0}
        max={500}
        step={1}
        precision={0}
        fullWidth
      />
      <RangeController
        label="Margem Esquerda"
        handle={handle}
        kWord="MarginLeft"
        configValue={config?.Margin?.left}
        min={0}
        max={500}
        step={1}
        precision={0}
        fullWidth
      />
      <RangeController
        label="Margem Direita"
        handle={handle}
        kWord="MarginRight"
        configValue={config?.Margin?.right}
        min={0}
        max={500}
        step={1}
        precision={0}
        fullWidth
      />
      <ToggleSwitchController
        label="Valor mínimo"
        handle={handle}
        kWord="MinValueControl2"
        configValue={config?.MinValueControl?.checked}
        firstOption="Automático"
        secondOption="Manual"
        secondColor="secondary"
      />
      <InputLineController
        isNumber
        precision={2}
        label=""
        handle={handle}
        kWord="MinValue"
        configValue={config?.MinValueControl?.value}
        disabled={config?.MinValueControl?.checked}
      />
      <ToggleSwitchController
        label="Valor máximo"
        handle={handle}
        kWord="MaxValueControl2"
        configValue={config?.MaxValueControl?.checked}
        firstOption="Automático"
        secondOption="Manual"
        secondColor="secondary"
      />
      <InputLineController
        isNumber
        precision={2}
        label=""
        handle={handle}
        kWord="MaxValue"
        configValue={config?.MaxValueControl?.value}
        disabled={config?.MaxValueControl?.checked}
      />
      <ChoiceBoxController
        label="Formato dos dados"
        handle={handle}
        kWord="DataFormatType"
        configValue={config?.DataFormat?.type}
        options={[
          { value: 'absolute', label: 'Absoluto', id: 'absolute' },
          { value: 'percentual', label: 'Percentual', id: 'percentual' },
        ]}
        fullWidth
      />
      {selectedColumn && (
        <ChoiceBoxController
          label="Formato percentual"
          handle={handle}
          kWord="DataFormatTarget"
          configValue={config?.DataFormat?.target}
          options={[
            { label: 'Global', value: 'global', id: 'global' },
            { label: 'Categórico', value: 'categoric', id: 'categoric' },
          ]}
          disabled={config?.DataFormat?.type === 'absolute'}
          fullWidth
        />
      )}
    </ControllersGroup>
  </>
);

GeneralControllers.propTypes = {
  config: PropTypes.objectOf(PropTypes.any),
  data: PropTypes.objectOf(PropTypes.any),
  handle: PropTypes.func,
  extend: PropTypes.string,
  setExtend: PropTypes.func,
  selectedColumn: PropTypes.objectOf(PropTypes.any),
};

GeneralControllers.defaultProps = {
  config: undefined,
  data: undefined,
  handle: () => {},
  extend: '',
  setExtend: () => {},
  selectedColumn: undefined,
};

export default GeneralControllers;
