import styled from 'styled-components';
import { devices } from '../../../../juristec-ui/core/breakpoints';
import { gap } from '../../../../juristec-ui/styles/theme';

export const RowInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  ${gap('4px', 'row')};
  margin: 1rem 0;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.containerOdd};
  padding: 0.5rem;
  border-radius: 0.5rem;
  margin: 0;
`;

export const BadgersContainer = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${({ theme }) => theme.containerOdd};
  padding: 0.5rem;
  border-radius: 0.5rem;
  margin: 0;
  flex-wrap: wrap;
  justify-content: start;
  ${gap('4px', 'row')};
  & .principal {
    width: 100%;
    display: flex;
    justify-content: center;
    font-weight: bold;
  }

  & .secundario {
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: 12px;
    color: ${({ theme }) => theme.grey};
  }

  & button {
    min-width: auto;
    font-size: 10px;
  }

  & .row-div {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 0.5rem;
  }

  @media ${devices.phoneS} {
    flex-direction: row;
  }
`;

export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${gap('2px', 'column')};
  margin-top: 1rem;
  font-size: 13px;
  & > span {
    margin-bottom: 0.5rem;
  }
  width: 100%;
`;

export const SeparatorLine = styled.hr`
  margin: 0 auto 10px;
  width: 15%;
  border: 0.8px solid #ddd;
`;

export const HeaderInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: 'flex-start';
  font-weight: bold;
  font-size: 15px;
  text-decoration: underline;
  margin-bottom: 10px;
  & svg {
    fill: ${({ theme }) => theme.secondary};
    margin: 0px 10px 0px 0px;
    pointer-events: none;
    width: 20px;
    height: 20px;
  }
`;

export const ContentInfo = styled.div`
  background-color: ${({ theme, device }) => device !== 'phone' ? theme.containerOdd : 'transparent'};
  padding: ${({ device }) => device !== 'phone' ? '0.5rem' : ''};
  border-radius: 0.5rem;
  margin-bottom: 2px;
  display: flex;
  flex-direction: ${({ device }) => device !== 'phone' ? 'row' : 'column'};
  justify-content: ${({ device }) => device !== 'phone' ? 'space-between' : 'flex-start'};
  align-items: ${({ device }) => device !== 'phone' ? 'center' : 'flex-start'};
  ${({ device }) => device !== 'phone' ? gap('2px', 'row') : gap('2px', 'column')};
  font-size: 13px;

  .child-item {
    background-color: ${({ theme, device }) => device !== 'phone' ? 'transparent' : theme.containerOdd};
    width: ${({ device }) => device !== 'phone' ? '' : 'calc(100% - 1rem)'};
    padding: ${({ device }) => device !== 'phone' ? '' : '0.5rem'};
    border-radius: ${({ device }) => device !== 'phone' ? '' : '0.5rem'};
  }
`;

export const BlockInfo = styled.div`
  margin: 0 1.5rem 1rem;
`;

export const SideBySideInfo = styled.div`
  margin: 0 1.5rem 1rem;
  display: flex;
  flex-direction: ${({ device }) => device !== 'phone' ? 'row' : 'column'};
  justify-content: flex-start;
  align-items: flex-start;
  ${({ device }) => device !== 'phone' ? gap('10px', 'row') : gap('15px', 'column')};
`;
