import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../../juristec-ui/core/Button';

import { formatDateTime } from '../../../juristec-ui/utils/functions/lab';
import { MainContainer, ManualActionContainer } from './styled/FileReportInactive.styled';

const calcDayInterval = (strIsoDate) => {
  const dateA = new Date(strIsoDate);
  const dateToday = new Date();
  return (dateToday - dateA) / 86400000;
};

const FileReportInactive = ({
  reportInfo, activateReport, restoreReport,
}) => (
  <MainContainer>
    <span>
      <b>Relatório: </b>
      {reportInfo?.filename}
    </span>
    {reportInfo.deletedAt ? (
      <>
        <span>
          <b>Removido por: </b>
          {reportInfo?.deletedByEmail?.split('@')?.[0] || 'Desconhecido'}
          {' - '}
          {formatDateTime(reportInfo?.deletedAt, { time: 'half' })}
        </span>
        {calcDayInterval(reportInfo?.deletedAt) < 30 && (
          <ManualActionContainer>
            <Button
              variant="outlined"
              size="medium"
              onClick={restoreReport}
            >
              Restaurar Relatório
            </Button>
          </ManualActionContainer>
        )}
      </>
    ) : (
      <>
        <span>
          <b>Inativado por: </b>
          {reportInfo?.deactivatedByEmail?.split('@')?.[0] || 'Desconhecido'}
          {' - '}
          {formatDateTime(reportInfo?.deactivatedAt, { time: 'half' })}
        </span>
        <ManualActionContainer>
          <Button
            variant="outlined"
            size="medium"
            onClick={activateReport}
          >
            Reativar Relatório
          </Button>
        </ManualActionContainer>
      </>
    )}
  </MainContainer>
);

FileReportInactive.propTypes = {
  reportInfo: PropTypes.shape({
    filename: PropTypes.string,
    deletedAt: PropTypes.string,
    deletedByEmail: PropTypes.string,
    deactivatedByEmail: PropTypes.string,
    deactivatedAt: PropTypes.string,
  }).isRequired,
  activateReport: PropTypes.func,
  restoreReport: PropTypes.func,
};

FileReportInactive.defaultProps = {
  activateReport: undefined,
  restoreReport: undefined,
};

export default FileReportInactive;
