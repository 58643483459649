import React from 'react';
import PropTypes from 'prop-types';

// import { Container } from './styles';

const Credentials = ({ width, height }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 2.66667H24C24 1.19583 22.8042 0 21.3333 0H2.66667C1.19583 0 0 1.19583 0 2.66667ZM0 4V16C0 17.4708 1.19583 18.6667 2.66667 18.6667H21.3333C22.8042 18.6667 24 17.4708 24 16V4H0ZM2.66667 15.5542C2.66667 14.325 3.6625 13.3333 4.8875 13.3333H9.77917C11.0083 13.3333 12 14.3292 12 15.5542C12 15.8 11.8 16 11.5542 16H3.1125C2.86667 16 2.66667 15.8 2.66667 15.5542ZM7.33333 12C5.8625 12 4.66667 10.8042 4.66667 9.33333C4.66667 7.8625 5.8625 6.66667 7.33333 6.66667C8.80417 6.66667 10 7.8625 10 9.33333C10 10.8042 8.80417 12 7.33333 12ZM14.6667 7.33333C14.6667 6.96667 14.9667 6.66667 15.3333 6.66667H20.6667C21.0333 6.66667 21.3333 6.96667 21.3333 7.33333C21.3333 7.7 21.0333 8 20.6667 8H15.3333C14.9667 8 14.6667 7.7 14.6667 7.33333ZM14.6667 10C14.6667 9.63333 14.9667 9.33333 15.3333 9.33333H20.6667C21.0333 9.33333 21.3333 9.63333 21.3333 10C21.3333 10.3667 21.0333 10.6667 20.6667 10.6667H15.3333C14.9667 10.6667 14.6667 10.3667 14.6667 10ZM14.6667 12.6667C14.6667 12.3 14.9667 12 15.3333 12H20.6667C21.0333 12 21.3333 12.3 21.3333 12.6667C21.3333 13.0333 21.0333 13.3333 20.6667 13.3333H15.3333C14.9667 13.3333 14.6667 13.0333 14.6667 12.6667Z" />
    </svg>
  );
};

Credentials.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

Credentials.defaultProps = {
  width: '24px',
  height: '19px',
};

export default Credentials;
