import React from 'react';
import PropTypes from 'prop-types';

import StyledButton from './styled/Button.styled';

/**
* A button allows us to highlight actions in the product and make them easily perceived and achievable through just one click.
*/
const Button = ({
  color, variant, size, shape, textTransform, children, noOutline, useDynamicColor, ...props
}) => {
  return (
    <StyledButton
      color={color}
      variant={variant}
      size={size}
      shape={shape}
      textTransform={textTransform}
      useDynamicColor={useDynamicColor}
      {...props}
      noOutline={noOutline}
    >
      {children}
    </StyledButton>
  );
};

Button.propTypes = {
  /**
   * Color of the button. Choose from seven different options or pass a hex color.
   */
  color: PropTypes.oneOfType([PropTypes.oneOf(['primary', 'secondary', 'error', 'warning', 'success', 'info', 'dark']), PropTypes.string]),
  /**
   * Variant of the button. Choose among three variants.
   */
  variant: PropTypes.oneOf(['contained', 'outlined', 'pattern']),
  /**
   * Shape of the button. Choose among three shapes.
   */
  shape: PropTypes.oneOf(['square', 'rounded', 'circle']),
  /**
   * Size of the button. Choose among three sizes.
   */
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  /**
   * Text of the button. Choose between two types.
   */
  textTransform: PropTypes.oneOf(['capitalize', 'uppercase', 'none']),
  /**
   * Accepts a simple text, a function, an object or a javascript node.
   */
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.node,
    PropTypes.func,
  ]).isRequired,
  /**
   * A boolean variable to remove the default browser outline
   */
  noOutline: PropTypes.bool,
  /**
   * A function to perform some action when the user clicks the button
   */
  onClick: PropTypes.func,
  /**
   * Decides if text color can change based on background color (only in contained button)
   */
  useDynamicColor: PropTypes.bool,
};

Button.defaultProps = {
  color: 'primary',
  variant: 'contained',
  shape: 'rounded',
  size: 'medium',
  textTransform: 'capitalize',
  noOutline: true,
  useDynamicColor: false,
  onClick: () => {},
};

export default Button;
