/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';

import Select from '../../juristec-ui/core/SelectNew';
import InputTextLine from '../../juristec-ui/core/InputTextLine';
import DatePicker from '../../juristec-ui/core/DatePicker';
import InputNumberLine from '../../juristec-ui/core/InputNumberLine';

import { trimString } from '../../juristec-ui/utils/functions/formatString';

import 'react-datepicker/dist/react-datepicker.css';

const FilterByOptionsAdvanced = ({
  values, handle, options, errorMsg,
}) => (
  <>
    {[...options.entries() || []].map(([k, opt]) => {
      const isMult = Array.isArray(values[k]);
      return (
        <Select
          key={k}
          label={k}
          options={opt}
          value={values[k]}
          onChange={(val) => handle({ ...values, [k]: val }, isMult ? null : k)}
          errorMessage={errorMsg}
          error={isMult ? errorMsg.length > 0 : false}
          textTransform="capitalize"
          multiple={isMult}
          stayOpen={isMult}
          searchable
        />
      );
    })}
  </>
);

const FilterByOptionsSimple = ({
  label, value, handle, options, errorMsg,
}) => (
  <>
    <Select
      label={label}
      options={options}
      value={value}
      onChange={handle}
      errorMessage={errorMsg}
      error={errorMsg.length > 0}
      textTransform="capitalize"
      multiple
      stayOpen
      searchable
    />
  </>
);

const FilterByNumberInverval = ({
  values, handle,
}) => {
  const valA = values[0] || 0;
  const valB = values[1] || 0;
  return (
    <>
      <InputNumberLine
        label="Valor inicial"
        value={valA}
        onChange={(e) => handle([trimString(e.target.value), valB])}
        setValue={(val) => handle([val, valB])}
        precision={2}
        isMoney
      />
      <InputNumberLine
        label="Valor final"
        value={valB}
        onChange={(e) => handle([valA, trimString(e.target.value)])}
        setValue={(val) => handle([valA, val])}
        precision={2}
        isMoney
      />
    </>
  );
};

const FilterByDateInterval = ({
  values, handle,
}) => {
  const valA = values[0];
  const valB = values[1];
  return (
    <>
      <DatePicker
        value={valA}
        onChange={(val) => handle([val, valB])}
        label="Data inicial"
        startDate={valA}
        endDate={valB}
        maxDate={valB}
        isClearable
      />
      <DatePicker
        value={valB}
        onChange={(val) => handle([valA, val])}
        label="Data final"
        startDate={valA}
        endDate={valB}
        minDate={valA}
        isClearable
      />
    </>
  );
};

const FilterByDateYear = ({
  label, value, handle,
}) => (
  <>
    <DatePicker
      label={label}
      value={value}
      onChange={(val) => handle(val)}
      onlyYears
    />
  </>
);

const FilterByText = ({
  label, value, errorMsg, handle,
}) => (
  <>
    <InputTextLine
      label={label}
      value={value}
      errorMessage={errorMsg}
      error={errorMsg.length > 0}
      onChange={(e) => handle(e.target.value)}
      onBlur={() => handle(trimString(value))}
    />
  </>
);

const FilterItem = ({
  field, label, handle, value, options, errorMsg,
}) => {
  switch (field) {
    case 'comarca':
    case 'vara':
      return (
        <FilterByOptionsAdvanced
          values={value}
          handle={handle}
          options={options}
          errorMsg={errorMsg}
        />
      );
    case 'polo':
    case 'sinonimos':
    case 'classe':
    case 'orgao':
    case 'uf':
    case 'tribunal':
      return (
        <FilterByOptionsSimple
          value={value}
          label={label}
          handle={handle}
          options={options}
          errorMsg={errorMsg}
        />
      );
    case 'valor':
      return (
        <FilterByNumberInverval
          values={value}
          handle={handle}
        />
      );
    case 'distribuicao':
    case 'publicacao':
      return (
        <FilterByDateInterval
          values={value}
          handle={handle}
        />
      );
    case 'ano':
      return (
        <FilterByDateYear
          label={label}
          value={value}
          handle={handle}
        />
      );
    default:
      return (
        <FilterByText
          label={label}
          value={value}
          errorMsg={errorMsg}
          handle={handle}
        />
      );
  }
};

FilterItem.propTypes = {
  field: PropTypes.string,
  label: PropTypes.string,
  handle: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.instanceOf(Date),
    PropTypes.shape({}),
    PropTypes.arrayOf(PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.instanceOf(Date),
      PropTypes.shape({}),
    ])),
  ]).isRequired,
  options: PropTypes.oneOfType([
    PropTypes.instanceOf(Map),
    PropTypes.arrayOf(PropTypes.shape({})),
  ]),
  errorMsg: PropTypes.string,
};

FilterItem.defaultProps = {
  field: '',
  label: '',
  options: [],
  errorMsg: '',
};

export default FilterItem;
