import React from 'react';

// import { Container } from './styles';

function Info() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M12 24C15.1826 24 18.2348 22.7357 20.4853 20.4853C22.7357 18.2348 24 15.1826 24 12C24 8.8174 22.7357 5.76516 20.4853 3.51472C18.2348 1.26428 15.1826 0 12 0C8.8174 0 5.76516 1.26428 3.51472 3.51472C1.26428 5.76516 0 8.8174 0 12C0 15.1826 1.26428 18.2348 3.51472 20.4853C5.76516 22.7357 8.8174 24 12 24V24ZM13.395 9.882L9.96 10.3125L9.837 10.8825L10.512 11.007C10.953 11.112 11.04 11.271 10.944 11.7105L9.837 16.9125C9.546 18.258 9.9945 18.891 11.049 18.891C11.8665 18.891 12.816 18.513 13.2465 17.994L13.3785 17.37C13.0785 17.634 12.6405 17.739 12.3495 17.739C11.937 17.739 11.787 17.4495 11.8935 16.9395L13.395 9.882V9.882ZM12 8.25C12.3978 8.25 12.7794 8.09196 13.0607 7.81066C13.342 7.52936 13.5 7.14782 13.5 6.75C13.5 6.35218 13.342 5.97064 13.0607 5.68934C12.7794 5.40804 12.3978 5.25 12 5.25C11.6022 5.25 11.2206 5.40804 10.9393 5.68934C10.658 5.97064 10.5 6.35218 10.5 6.75C10.5 7.14782 10.658 7.52936 10.9393 7.81066C11.2206 8.09196 11.6022 8.25 12 8.25Z" />
    </svg>
  );
}

export default Info;
