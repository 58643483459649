import styled from 'styled-components';
import { devices } from '../../../../juristec-ui/core/breakpoints';

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const MainContainer = styled(Column)`
  height: calc(100vh - 300px);
  width: 50vw;
  text-transform: capitalize;
  overflow: hidden auto;
  padding: 0 .25rem .5rem;
  @media ${devices.phoneL} {
    width: auto;
  }
  & h3 {
    width: 100%;
    margin: .25rem .5rem;
    color: ${({ theme }) => theme.primary};
    &:first-child {
      margin-top: 0;
    }
  }
`;

export const InfoItem = styled(Column)`
  user-select: none;
  background-color: ${({ theme }) => theme.container};
  border-radius: .5rem;
  & > div:first-child {
    border-radius: .5rem .5rem 0 0;
  }
  & > div:last-child {
    border-radius: 0 0 .5rem .5rem;
  }
  &:hover{
    & > div:first-child, > div:last-child {
      background-color: ${({ theme }) => theme.containerHover};
    }
  }
`;

export const InfoRow = styled(Row)`
  flex-wrap: wrap;
  text-transform: capitalize;
  background-color: ${({ theme }) => theme.containerOdd};
  padding: .5rem 0;
  position: relative;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.containerHover};
  }
  & h4 {
    width: 100%;
    margin: 0 .75rem;
    color: ${({ theme }) => theme.primary};
    display: flex;
    align-items: center;
    &:after {
      content: "";
      flex: 1 1;
      border-bottom: .1rem solid ${({ theme }) => theme.inputLabel}20;
      margin-left: .2rem;
    }
  }
`;

export const InfoWrapper = styled(Column)`
  white-space: nowrap;
  box-sizing: border-box;
  text-transform: capitalize;
  margin: .25rem .75rem;
  & h5 {
    margin: 0;
    color: ${({ theme }) => theme.inputLabel};
  }
  & span {
    margin: 0;
  }
`;

export const AddressItemRow = styled(Row)`
  overflow: hidden;
  align-items: flex-start;
  justify-content: flex-start;
  transition: all;
  position: relative;
  flex-wrap: wrap;
  text-transform: capitalize;
  border-radius: .5rem;
  background-color: ${({ theme }) => theme.container};
  /* padding: .5rem 0 .5rem 1rem; */
  position: relative;
  & h4 {
    width: 100%;
    margin: 0 .75rem;
    color: ${({ theme }) => theme.primary};
    display: flex;
    align-items: center;
    &:after {
      content: "";
      flex: 1 1;
      border-bottom: .1rem solid ${({ theme }) => theme.inputLabel}20;
      margin-left: .2rem;
    }
  }
`;
export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  & svg {
    width: 25px;
    height: 25px;
    fill: ${({ theme }) => theme.primary};
  }
  transition: all 0.2s;
`;
