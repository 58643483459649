const collator = new Intl.Collator('pt-br', { numeric: true, sensitivity: 'base' });

/**
 * Compares two values for the array sort method.
 * @param {*} a First value.
 * @param {*} b Second value.
 * @param {boolean|False} isNumberOnly If true, compares mathematically
 * (considers negative numbers).
 * @returns {Number} Comparation result in number.
 */
export default function compare(a, b, isNumberOnly = false) {
  if (isNumberOnly) return a - b;
  return collator.compare(a, b);
}
