import React, { useState } from 'react';
import PropTypes from 'prop-types';

import SearchFilter from '../../../juristec-ui/core/SearchFilter';
import IconButton from '../../../juristec-ui/core/IconButton';
import Tooltip from '../../../juristec-ui/core/Tooltip';

import {
  ArrowLeft,
} from '../../../juristec-ui/icons';

import {
  MainContainer,
  ItensContainer,
} from './styled/TemplatesToolbar.styled';

const TemplatesToolbar = ({
  goBack,
  categories,
  setInputFilter,
  radioSelected,
  setRadioSelected,
  checkboxSelected,
  setCheckboxSelected,
}) => {
  const [tempInput, setTempInput] = useState('');

  const handleTempInput = (val) => {
    setTempInput(val);
    setInputFilter(val);
  };

  return (
    <MainContainer>
      <ItensContainer className="left">
        <Tooltip text="Voltar" direction="right">
          <IconButton variant="contained" color="secondary" size="medium" shape="rounded" onClick={goBack}>
            <ArrowLeft />
          </IconButton>
        </Tooltip>
      </ItensContainer>
      <ItensContainer className="right">
        <SearchFilter
          radioLabel="Nível"
          radioOptions={[
            { label: 'Todos os níveis', value: 'all' },
            { label: 'Iniciante', value: 'easy' },
            { label: 'Intermediário', value: 'medium' },
            { label: 'Avançado', value: 'advanced' },
            { label: 'Expert', value: 'expert' },
          ]}
          radioSelected={radioSelected}
          setRadioSelected={setRadioSelected}
          checkboxLabel="Categoria"
          checkboxOptions={categories}
          checkboxSelected={checkboxSelected}
          setCheckboxSelected={setCheckboxSelected}
          textSearch={tempInput}
          setTextSearch={handleTempInput}
        />
      </ItensContainer>
    </MainContainer>
  );
};

TemplatesToolbar.propTypes = {
  goBack: PropTypes.func.isRequired,
  categories: PropTypes.shape({}),
  setInputFilter: PropTypes.func.isRequired,
  radioSelected: PropTypes.string,
  setRadioSelected: PropTypes.func.isRequired,
  checkboxSelected: PropTypes.objectOf(PropTypes.bool),
  setCheckboxSelected: PropTypes.func.isRequired,
};

TemplatesToolbar.defaultProps = {
  categories: {},
  radioSelected: '',
  checkboxSelected: {},
};

export default TemplatesToolbar;
