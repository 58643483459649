/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';
import InputTextLine from '../../core/InputTextLine';
import Toggle from '../../core/Toggle';
import Tooltip from '../../core/Tooltip';
import IconButton from '../../core/IconButton';
import Select from '../../core/Select';
import RangeBar from '../../core/RangeBar';
import Button from '../../core/Button';
import Badge from '../../core/Badge';
import ColorPicker from '../../core/ColorPicker2';

import { trimString } from '../../utils/functions/formatString';

import {
  ExpandMore,
  Underline,
  Italic, Bold,
  FontColor,
  LineColor,
  BackgroundColor,
  Add,
  Close,
  SortArrow,
  ArrowRight,
} from '../../icons';

import {
  ControlContainer,
  LabelController,
  FlexRow,
  NumberControls,
  OrderControls,
  OrderBtn,
  ArrowIcon,
  TooltipContainer,
} from './styled/Base.styled';

const basePropTypes = {
  label: PropTypes.string,
  handle: PropTypes.func,
  kWord: PropTypes.string,
  configValue: PropTypes.any,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  text: PropTypes.string,
};

const baseDefaultProps = {
  label: '',
  handle: () => {},
  kWord: '',
  configValue: undefined,
  fullWidth: false,
  disabled: false,
  text: '',
};

const periodReplacer = (isNumber, val) => (isNumber ? val.toString().replace('.', ',') : val);
// const commaReplacer = (isNumber, val) => (isNumber ? val.toString().replace(',', '.') : val);

/**
 * Tradicional input line that handles strings or numbers
 */
export const InputLineController = ({
  isNumber,
  min,
  max,
  defaultNumber,
  step,
  precision,
  handle,
  kWord,
  label,
  configValue,
  orientation,
  fullWidth,
  disabled,
  placeholder,
  text,
}) => {
  const cron = useRef();
  const [val, setVal] = useState(periodReplacer(isNumber, (configValue || (isNumber ? defaultNumber : ''))));

  const handleConfigVal = (value) => {
    clearTimeout(cron.current);
    cron.current = setTimeout(() => {
      handle(trimString(value.toString()), kWord);
    }, 200);
  };

  const handleOpsBtns = (value = '', op = 0) => {
    value = value.toString().replace(',', '.');
    const numericVal = Math.max(Math.min((Number(value) || 0) + op, max), min).toFixed(precision);
    setVal(periodReplacer(isNumber, numericVal));
    handleConfigVal(numericVal);
  };

  const handleVal = (value = '') => {
    if (isNumber) {
      const regex = new RegExp(`^-{0,1}\\d*${precision > 0 ? `,{0,1}\\d{0,${precision}}` : ''}`);
      [value] = value.toString().match(regex);
      setVal(value);
      value = value.replace(',', '.');
      const numericVal = (Number(value) || 0);
      if (value && !Number.isNaN(+value)) {
        value = Math.max(Math.min(numericVal, max), min);
      } else {
        value = defaultNumber;
      }
    } else {
      setVal(value);
    }
    handleConfigVal(value);
  };

  return (
    <ControlContainer
      orientation={orientation}
      fullWidth={fullWidth}
      isNumber={isNumber}
      disabled={disabled}
      as={Tooltip}
      text={text}
      textWhen={Boolean(disabled && text)}
      direction="right"
    >
      <LabelController htmlFor={kWord}>{label}</LabelController>
      <FlexRow style={{ width: '100%' }}>
        <InputTextLine
          id={kWord}
          value={val}
          onChange={(e) => handleVal(e.target.value)}
          onBlur={() => setVal(periodReplacer(isNumber, (configValue || '')))}
          label=""
          placeholder={placeholder}
        />
        {isNumber && (
          <NumberControls>
            <button type="button" className="number-increase" onClick={() => handleOpsBtns(val, step)}><ExpandMore /></button>
            <button type="button" className="number-decrease" onClick={() => handleOpsBtns(val, -step)}><ExpandMore /></button>
          </NumberControls>
        )}
      </FlexRow>
    </ControlContainer>
  );
};

InputLineController.propTypes = {
  ...basePropTypes,
  isNumber: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number,
  defaultNumber: PropTypes.number,
  step: PropTypes.number,
  precision: PropTypes.number,
  orientation: PropTypes.oneOf(['row', 'column']),
  placeholder: PropTypes.string,
};

InputLineController.defaultProps = {
  ...baseDefaultProps,
  isNumber: false,
  min: -Infinity,
  max: Infinity,
  defaultNumber: 0,
  step: 1,
  precision: 0,
  orientation: 'column',
  placeholder: '',
};

/**
 * Toggle input in a switch style
 */
export const ToggleSwitchController = ({
  handle,
  kWord,
  label,
  configValue,
  firstOption,
  secondOption,
  firstColor,
  secondColor,
  fullWidth,
  disabled,
  text,
}) => {
  const theme = useTheme();
  return (
    <ControlContainer
      className={fullWidth ? '' : 'fullwidth-inline'}
      orientation={fullWidth ? 'row' : 'column'}
      fullWidth={fullWidth}
      disabled={disabled}
      as={Tooltip}
      text={text}
      textWhen={Boolean(disabled && text)}
      direction="right"
    >
      <LabelController htmlFor={kWord}>{label}</LabelController>
      <Toggle
        label=""
        checked={configValue || false}
        handleChange={() => handle(!configValue, kWord)}
        styleOption={{ color: theme.grey, fontSize: 14 }}
        styleContainer={{ margin: 'auto 0' }}
        highlightOption={false}
        secondOption={secondOption}
        firstOption={firstOption}
        firstColor={firstColor}
        secondColor={secondColor}
        size="small"
        optionPosition={fullWidth ? 'left' : 'right'}
      />
    </ControlContainer>
  );
};

ToggleSwitchController.propTypes = {
  ...basePropTypes,
  firstOption: PropTypes.string,
  secondOption: PropTypes.string,
  firstColor: PropTypes.string,
  secondColor: PropTypes.string,
};

ToggleSwitchController.defaultProps = {
  ...baseDefaultProps,
  firstOption: '',
  secondOption: '',
  firstColor: undefined,
  secondColor: undefined,
};

/**
 * Button controllers inline
 */
export const ButtonLineController = ({
  handle,
  boldVal,
  boldKWord,
  boldText,
  boldDisabled,
  italicVal,
  italicKWord,
  italicText,
  italicDisabled,
  underlineVal,
  underlineKWord,
  underlineText,
  underlineDisabled,
  fontColorVal,
  fontColorKWord,
  fontColorText,
  fontColorDisabled,
  lineColorVal,
  lineColorKWord,
  lineColorText,
  lineColorDisabled,
  backgroundColorVal,
  backgroundColorKWord,
  backgroundColorText,
  backgroundColorDisabled,
  fullWidth,
  disabled,
  text,
}) => {
  const cron = useRef();

  const handleColorVal = (value, kWord) => {
    clearTimeout(cron.current);
    cron.current = setTimeout(() => {
      handle(value, kWord);
    }, 200);
  };

  return (
    <ControlContainer
      orientation="row"
      fullWidth={fullWidth}
      style={{ gap: '5px' }}
      disabled={disabled}
      as={Tooltip}
      text={text}
      textWhen={Boolean(disabled && text)}
      direction="right"
    >
      <FlexRow style={{ width: '100%', gap: '5px', justifyContent: 'start' }}>
        {boldKWord && (
          <TooltipContainer
            direction="right"
            text={boldDisabled && boldText ? boldText : 'Negrito'}
            disabled={boldDisabled}
          >
            <IconButton
              shape="rounded"
              variant={!boldVal ? 'outlined' : 'contained'}
              onClick={() => handle(!boldVal, boldKWord)}
            >
              <Bold />
            </IconButton>
          </TooltipContainer>
        )}
        {italicKWord && (
          <TooltipContainer
            direction="right"
            text={italicDisabled && italicText ? italicText : 'Itálico'}
            disabled={italicDisabled}
          >
            <IconButton
              shape="rounded"
              variant={!italicVal ? 'outlined' : 'contained'}
              onClick={() => handle(!italicVal, italicKWord)}
            >
              <Italic />
            </IconButton>
          </TooltipContainer>
        )}
        {underlineKWord && (
          <TooltipContainer
            direction="right"
            text={underlineDisabled && underlineText ? underlineText : 'Sublinhado'}
            disabled={underlineDisabled}
          >
            <IconButton
              shape="rounded"
              variant={!underlineVal ? 'outlined' : 'contained'}
              onClick={() => handle(!underlineVal, underlineKWord)}
            >
              <Underline />
            </IconButton>
          </TooltipContainer>
        )}
      </FlexRow>
      <FlexRow style={{ width: '100%', gap: '5px', justifyContent: 'end' }}>
        {fontColorKWord && (
          <TooltipContainer
            direction="right"
            text={fontColorDisabled && fontColorText ? fontColorText : 'Cor da fonte'}
            disabled={fontColorDisabled}
          >
            <ColorPicker
              onChange={(val) => handleColorVal(val, fontColorKWord)}
              color={fontColorVal}
              isIconButton
              atModal
            >
              <FontColor />
            </ColorPicker>
          </TooltipContainer>
        )}
        {lineColorKWord && (
          <TooltipContainer
            direction="right"
            text={lineColorDisabled && lineColorText ? lineColorText : 'Cor da linha'}
            disabled={lineColorDisabled}
          >
            <ColorPicker
              onChange={(val) => handleColorVal(val, lineColorKWord)}
              color={lineColorVal}
              isIconButton
              atModal
            >
              <LineColor />
            </ColorPicker>
          </TooltipContainer>
        )}
        {backgroundColorKWord && (
          <TooltipContainer
            direction="right"
            text={backgroundColorDisabled && backgroundColorText ? backgroundColorText : 'Cor de fundo'}
            disabled={backgroundColorDisabled}
          >
            <ColorPicker
              onChange={(val) => handleColorVal(val, backgroundColorKWord)}
              color={backgroundColorVal}
              isIconButton
              atModal
            >
              <BackgroundColor />
            </ColorPicker>
          </TooltipContainer>
        )}
      </FlexRow>
    </ControlContainer>
  );
};

ButtonLineController.propTypes = {
  handle: PropTypes.func,
  boldVal: PropTypes.bool,
  boldKWord: PropTypes.string,
  boldText: PropTypes.string,
  boldDisabled: PropTypes.bool,
  italicVal: PropTypes.bool,
  italicKWord: PropTypes.string,
  italicText: PropTypes.string,
  italicDisabled: PropTypes.bool,
  underlineVal: PropTypes.bool,
  underlineKWord: PropTypes.string,
  underlineText: PropTypes.string,
  underlineDisabled: PropTypes.bool,
  fontColorVal: PropTypes.string,
  fontColorKWord: PropTypes.string,
  fontColorText: PropTypes.string,
  fontColorDisabled: PropTypes.bool,
  lineColorVal: PropTypes.string,
  lineColorKWord: PropTypes.string,
  lineColorText: PropTypes.string,
  lineColorDisabled: PropTypes.bool,
  backgroundColorVal: PropTypes.string,
  backgroundColorKWord: PropTypes.string,
  backgroundColorText: PropTypes.string,
  backgroundColorDisabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  text: PropTypes.string,
};

ButtonLineController.defaultProps = {
  handle: () => {},
  boldVal: false,
  boldKWord: '',
  boldText: '',
  boldDisabled: false,
  italicVal: false,
  italicKWord: '',
  italicText: '',
  italicDisabled: false,
  underlineVal: false,
  underlineKWord: '',
  underlineText: '',
  underlineDisabled: false,
  fontColorVal: '',
  fontColorKWord: '',
  fontColorText: '',
  fontColorDisabled: false,
  lineColorVal: '',
  lineColorKWord: '',
  lineColorText: '',
  lineColorDisabled: false,
  backgroundColorVal: '',
  backgroundColorKWord: '',
  backgroundColorText: '',
  backgroundColorDisabled: false,
  disabled: false,
  fullWidth: false,
  text: '',
};

/**
 * Tradicional select controller for multiple options
 */
export const SelectController = ({
  handle,
  kWord,
  label,
  configValue,
  options,
  fullWidth,
  disabled,
  text,
}) => (
  <ControlContainer
    fullWidth={fullWidth}
    disabled={disabled}
    as={Tooltip}
    text={text}
    textWhen={Boolean(disabled && text)}
    direction="right"
  >
    <LabelController htmlFor={kWord}>{label}</LabelController>
    <Select
      selectLabel=""
      placeholder="Selecione"
      options={options}
      value={{ label: configValue, value: configValue }}
      onChange={(selected) => handle(selected.value, kWord)}
      fullWidth
      atModal
    />
  </ControlContainer>
);

SelectController.propTypes = {
  ...basePropTypes,
  options: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
};

SelectController.defaultProps = {
  ...baseDefaultProps,
  options: [],
};

/**
 * Input range to handle numerical values accurately
 */
export const RangeController = ({
  min,
  max,
  step,
  precision,
  handle,
  kWord,
  label,
  configValue,
  fullWidth,
  disabled,
  text,
}) => {
  const cron = useRef();
  const [val, setVal] = useState((configValue || 0).toString().replace('.', ','));

  const handleConfigVal = (value) => {
    clearTimeout(cron.current);
    cron.current = setTimeout(() => {
      handle(trimString(value.toString()), kWord);
    }, 200);
  };

  const handleOpsBtns = (value = '', op = 0) => {
    value = value.toString().replace(',', '.');
    const numericVal = Math.max(Math.min((Number(value) || 0) + op, max), min).toFixed(precision);
    setVal(numericVal.toString().replace('.', ','));
    handleConfigVal(numericVal);
  };

  const handleVal = (value = '') => {
    const regex = new RegExp(`^-{0,1}\\d*${precision > 0 ? `,{0,1}\\d{0,${precision}}` : ''}`);
    [value] = value.toString().match(regex);
    setVal(value);
    value = value.replace(',', '.');
    const numericVal = (Number(value) || 0);
    if (value && !Number.isNaN(+value)) {
      value = Math.max(Math.min(numericVal, max), min);
    } else {
      value = min;
    }
    handleConfigVal(value);
  };

  return (
    <ControlContainer
      fullWidth={fullWidth}
      disabled={disabled}
      isNumber
      as={Tooltip}
      text={text}
      textWhen={Boolean(disabled && text)}
      direction="right"
    >
      <LabelController htmlFor={kWord}>{label}</LabelController>
      <FlexRow style={{ width: '100%', alignItems: 'center', gap: '10px' }}>
        <FlexRow style={{ width: '35%', position: 'relative' }}>
          <InputTextLine
            value={val}
            onChange={(e) => handleVal(e.target.value)}
            onBlur={() => setVal((configValue || 0).toString().replace('.', ','))}
            // wrapperStyle={{ width: '30%' }}
            style={{ textAlign: 'center' }}
          />
          <NumberControls>
            <button type="button" className="number-increase" onClick={() => handleOpsBtns(val, step)}><ExpandMore /></button>
            <button type="button" className="number-decrease" onClick={() => handleOpsBtns(val, -step)}><ExpandMore /></button>
          </NumberControls>
        </FlexRow>
        <RangeBar
          min={min}
          max={max}
          step={step}
          value={val.toString().replace(',', '.')}
          onChange={(value) => handleVal(value.toString().replace('.', ','))}
          // onBlur={() => setVal((configValue || '').toString().replace('.', ','))}
        />
      </FlexRow>
    </ControlContainer>
  );
};

RangeController.propTypes = {
  ...basePropTypes,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  precision: PropTypes.number,
};

RangeController.defaultProps = {
  ...baseDefaultProps,
  min: 0,
  max: Infinity,
  step: 1,
  precision: 0,
};

/**
 * A selector for few options
 */
export const ChoiceBoxController = ({
  handle,
  kWord,
  label,
  configValue,
  options,
  fullWidth,
  disabled,
  text,
}) => {
  const theme = useTheme();
  return (
    <ControlContainer
      fullWidth={fullWidth}
      disabled={disabled}
      as={Tooltip}
      text={text}
      textWhen={Boolean(disabled && text)}
      direction="right"
    >
      <LabelController htmlFor={kWord}>{label}</LabelController>
      <FlexRow style={{
        width: '100%',
        alignItems: 'center',
        boxShadow: `0px 2px 4px 0px ${theme.shadow}`,
        borderRadius: '8px',
        height: '100%',
        marginTop: '3px',
      }}
      >
        {options.map((opt) => (
          <Button
            key={opt.id + opt.label}
            shape="square"
            size="small"
            className="choicebox-btn"
            variant={configValue === opt.value ? 'contained' : 'outlined'}
            onClick={() => handle(opt.value, kWord)}
          >
            {opt.label}
          </Button>
        ))}
      </FlexRow>
    </ControlContainer>
  );
};

ChoiceBoxController.propTypes = {
  ...basePropTypes,
  options: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
};

ChoiceBoxController.defaultProps = {
  ...baseDefaultProps,
  options: [],
};

/**
 * A single color picker inline
 */
export const SimpleColorPickerController = ({
  handle,
  kWord,
  label,
  configValue,
  fullWidth,
  orientation,
  disabled,
  dataColorTarget,
  text,
}) => {
  const cron = useRef();

  const handleColorVal = (value) => {
    clearTimeout(cron.current);
    cron.current = setTimeout(() => {
      handle((dataColorTarget ? { value, target: dataColorTarget } : value), kWord);
    }, 200);
  };

  return (
    <ControlContainer
      orientation={orientation}
      fullWidth={fullWidth}
      disabled={disabled}
      as={Tooltip}
      text={text}
      textWhen={Boolean(disabled && text)}
      direction="right"
    >
      <LabelController htmlFor={kWord}>{label}</LabelController>
      <ColorPicker
        onChange={(val) => handleColorVal(val)}
        color={configValue}
        style={{ height: '32.5px', minWidth: '60px' }}
        atModal
      />
    </ControlContainer>
  );
};

SimpleColorPickerController.propTypes = {
  ...basePropTypes,
  orientation: PropTypes.oneOf(['row', 'column']),
  dataColorTarget: PropTypes.string,
};

SimpleColorPickerController.defaultProps = {
  ...baseDefaultProps,
  orientation: 'column',
  dataColorTarget: undefined,
};

/**
 * A sequence of color pickers inline
 */
export const GradientColorPickerController = ({
  handle,
  firstColorVal,
  firstColorKWord,
  secondColorVal,
  secondColorKWord,
  thirdColorVal,
  thirdColorKWord,
  fullWidth,
  orientation,
  disabled,
  text,
}) => {
  const cron = useRef();

  const handleColorVal = (value, kWord) => {
    clearTimeout(cron.current);
    cron.current = setTimeout(() => {
      handle(value, kWord);
    }, 200);
  };

  const addThirdColor = () => {
    handle('#12B315', thirdColorKWord);
  };

  const removeThirdColor = () => {
    handle(null, thirdColorKWord);
  };

  return (
    <ControlContainer
      orientation={orientation}
      fullWidth={fullWidth}
      disabled={disabled}
      as={Tooltip}
      text={text}
      textWhen={Boolean(disabled && text)}
      direction="right"
    >
      <Tooltip text="Primeira cor" direction="right">
        <ColorPicker
          onChange={(val) => handleColorVal(val, firstColorKWord)}
          color={firstColorVal}
          style={{ height: '32.5px', minWidth: '60px' }}
          atModal
        />
      </Tooltip>
      <ArrowIcon><ArrowRight /></ArrowIcon>
      <Tooltip text="Segunda cor" direction="right">
        <ColorPicker
          onChange={(val) => handleColorVal(val, secondColorKWord)}
          color={secondColorVal}
          style={{ height: '32.5px', minWidth: '60px' }}
          atModal
        />
      </Tooltip>
      {thirdColorVal?.length > 0 ? (
        <>
          <ArrowIcon><ArrowRight /></ArrowIcon>
          <Tooltip text="Terceira cor" direction="right">
            <ColorPicker
              onChange={(val) => handleColorVal(val, thirdColorKWord)}
              color={thirdColorVal}
              style={{ height: '32.5px', minWidth: '60px' }}
              atModal
            />
          </Tooltip>
          <Tooltip text="Remover terceira cor" direction="right">
            <IconButton
              onClick={removeThirdColor}
              variant="contained"
              shape="circle"
              color="error"
              size="small"
              style={{ padding: '0' }}
            >
              <Close />
            </IconButton>
          </Tooltip>
        </>
      ) : (
        <>
          <Tooltip text="Adicionar terceira cor" direction="right">
            <IconButton
              onClick={addThirdColor}
              variant="outlined"
              shape="rounded"
              color="grey"
              style={{ height: '32.5px', minWidth: '60px' }}
            >
              <Add />
            </IconButton>
          </Tooltip>
        </>
      )}
    </ControlContainer>
  );
};

GradientColorPickerController.propTypes = {
  ...basePropTypes,
  orientation: PropTypes.oneOf(['row', 'column']),
  firstColorVal: PropTypes.string,
  firstColorKWord: PropTypes.string,
  secondColorVal: PropTypes.string,
  secondColorKWord: PropTypes.string,
  thirdColorVal: PropTypes.string,
  thirdColorKWord: PropTypes.string,
};

GradientColorPickerController.defaultProps = {
  ...baseDefaultProps,
  orientation: 'column',
  firstColorVal: '#FFFFFF',
  firstColorKWord: '',
  secondColorVal: '#FFFFFF',
  secondColorKWord: '',
  thirdColorVal: null,
  thirdColorKWord: '',
};

/**
 * A controller to customize the ordering of data
 */
export const CustomSortController = ({
  handle,
  kWord,
  oldOrder,
  newOrder,
  removeTotal,
  disabled,
  text,
}) => {
  useEffect(() => {
    if (newOrder.length === 0 || newOrder.length !== oldOrder.length - (removeTotal ? 1 : 0)) {
      handle({
        kpiCategories: oldOrder.reduce((aux, item) => {
          const fItem = typeof item === 'string' ? item : item[item.length - 1];
          if (!(removeTotal && fItem === 'Totais')) aux.push(fItem);
          return aux;
        }, []),
      }, kWord);
    }
  }, [newOrder]);

  const changePosition = (pos, op) => {
    const newPos = pos + op;
    if (newPos < 0 && newPos >= newOrder.lenght) return;
    const tempList = [...newOrder];
    const [auxItem] = tempList.splice(pos, 1);
    tempList.splice(newPos, 0, auxItem);
    handle({ kpiCategories: [...tempList] }, kWord);
  };

  return (
    <ControlContainer
      fullWidth
      disabled={disabled}
      fullHeight
      as={Tooltip}
      text={text}
      textWhen={Boolean(disabled && text)}
      direction="right"
    >
      <FlexRow style={{ width: '100%', gap: '10px' }}>
        <div style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          gap: '5px',
          alignItems: 'center',
        }}
        >
          <LabelController htmlFor={kWord}>Ordem customizada</LabelController>
          {newOrder.map((item, i) => (
            <FlexRow style={{ width: '100%', gap: '10px' }} key={`ini-${item}`}>
              <OrderControls
                disableFirst={i === 0}
                disableLast={(newOrder.length - 1) === i}
              >
                <Tooltip text="Subir de posição" direction="right">
                  <OrderBtn className="order-increase" onClick={() => changePosition(i, -1)}><SortArrow /></OrderBtn>
                </Tooltip>
                <Tooltip text="Descer de posição" direction="right">
                  <OrderBtn className="order-decrease" onClick={() => changePosition(i, 1)}><SortArrow /></OrderBtn>
                </Tooltip>
              </OrderControls>
              <Button
                shape="square"
                size="small"
                variant="contained"
                // disabled={isTotal}
                // onClick={() => addInSort(item)}
                style={{ width: '100%', cursor: 'default' }}
              >
                {item}
              </Button>
            </FlexRow>
          ))}
        </div>
      </FlexRow>
    </ControlContainer>
  );
};

CustomSortController.propTypes = {
  handle: PropTypes.func,
  kWord: PropTypes.string,
  oldOrder: PropTypes.arrayOf(PropTypes.any),
  newOrder: PropTypes.arrayOf(PropTypes.any),
  disabled: PropTypes.bool,
  removeTotal: PropTypes.bool,
};

CustomSortController.defaultProps = {
  handle: () => {},
  kWord: '',
  oldOrder: [],
  newOrder: [],
  disabled: false,
  removeTotal: false,
};

// antigo
/* export const CustomSortController = ({
  handle,
  kWord,
  oldOrder,
  newOrder,
  removeTotal,
  disabled,
}) => {
  const removeFromSort = (item) => {
    if (newOrder.includes(item)) {
      handle({ kpiCategories: [...newOrder.filter((i) => i !== item)] }, kWord);
    }
  };

  const addInSort = (item) => {
    if (!newOrder.includes(item)) {
      handle({ kpiCategories: [...newOrder, item] }, kWord);
    }
  };

  return (
    <ControlContainer
      fullWidth
      disabled={disabled}
      fullHeight
    >
      <FlexRow style={{ width: '100%', gap: '10px' }}>
        <div style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          gap: '5px',
          alignItems: 'center',
        }}
        >
          <LabelController htmlFor={kWord}>Ordem atual</LabelController>
          {oldOrder.map((item) => (
            removeTotal && item === 'Totais' ? null : (
              <Button
                key={`ini-${item}`}
                shape="square"
                size="small"
                variant="contained"
                disabled={newOrder.includes(item)}
                onClick={() => addInSort(item)}
              >
                {item}
              </Button>
            )))}
        </div>
        <div style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          gap: '5px',
          alignItems: 'center',
        }}
        >
          <LabelController htmlFor={kWord}>Nova ordem</LabelController>
          {newOrder.map((item) => (
            <Button
              key={`new-${item}`}
              shape="square"
              size="small"
              variant="contained"
              onClick={() => removeFromSort(item)}
            >
              {item}
            </Button>
          ))}
        </div>
      </FlexRow>
    </ControlContainer>
  );
}; */

export const MarkersSelectorController = ({
  handle,
  addKWord,
  removeKWord,
  markersList,
  selectedIndex,
  setSelectedIndex,
  disabled,
  text,
}) => {
  const addHandle = () => {
    handle(undefined, addKWord);
    setSelectedIndex((markersList?.length || 1) - 1);
  };

  const deleteHandle = (e, idx) => {
    e.stopPropagation();
    handle(idx, removeKWord);
    setSelectedIndex((markersList?.length || 1) - 1);
  };

  return (
    <ControlContainer
      fullWidth
      disabled={disabled}
      fullHeight
      as={Tooltip}
      text={text}
      textWhen={Boolean(disabled && text)}
      direction="right"
    >
      <FlexRow style={{
        width: '100%', rowGap: '6px', columnGap: '3px', flexWrap: 'wrap',
      }}
      >
        {markersList.map((line, i) => (
          <Badge
            key={`line-${i}-badge`}
            active={selectedIndex === i}
            className={`marker-badge ${selectedIndex === i ? 'marker-badge-active' : ''}`}
            color="secondary"
            size="small"
            onClick={() => setSelectedIndex(i)}
          >
            <span style={{ margin: '0 auto' }}>
              Linha
              {' '}
              {i + 1}
            </span>
            <IconButton
              color={selectedIndex === i ? 'info' : 'secondary'}
              variant="outlined"
              style={{
                padding: '0', height: '15px', width: '15px', boxShadow: 'none',
              }}
              onClick={(e) => deleteHandle(e, i)}
            >
              <Close />
            </IconButton>
          </Badge>
        ))}
        {markersList.length < 4 && (
          <Badge
            className="marker-badge marker-add-badge"
            color="secondary"
            size="small"
            onClick={addHandle}
          >
            <Add />
          </Badge>
        )}
      </FlexRow>
    </ControlContainer>
  );
};

MarkersSelectorController.propTypes = {
  handle: PropTypes.func,
  addKWord: PropTypes.string,
  removeKWord: PropTypes.string,
  markersList: PropTypes.arrayOf(PropTypes.any),
  selectedIndex: PropTypes.number,
  setSelectedIndex: PropTypes.func,
  disabled: PropTypes.bool,
  text: PropTypes.string,
};

MarkersSelectorController.defaultProps = {
  handle: () => {},
  addKWord: '',
  removeKWord: '',
  markersList: [],
  selectedIndex: 0,
  setSelectedIndex: () => {},
  disabled: false,
  text: '',
};
