import styled, { css } from 'styled-components';
import { devices } from '../../../juristec-ui/core/breakpoints';

export const ProfileEditContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ScrollContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden auto;
  width: 100%;
  max-height: calc(100vh - 272px);
  box-sizing: border-box;
  padding: 0 1rem;
`;

export const AvatarTip = styled.span`
  color: ${({ theme }) => theme.grey};
  font-size: 12px;
  margin-bottom: 0.5rem;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  & span.errorMessage {
    position: absolute;
  }
`;

export const AvatarOverlay = styled.div`
  align-items: center;
  position: absolute;
  display: flex;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  justify-content: center;
  background-color: #00000080;
`;

export const AvatarContainer = styled.div`
  position: relative;
  border-radius: 50%;
  display: flex;
`;

export const InputWrapper = styled.div`
  margin: 0.8rem 0;
  height: 55px;
  &.readonly {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    & span.label {
      font-size:0.85rem;
      color:${({ theme }) => theme.inputLabel};
      font-weight: bold;
    }
    & span.value {
      font-size: 1rem;
    }
  }
`;

export const TabGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-bottom: 10px;
  box-sizing: border-box;
  padding: 0 1rem;
`;

export const TabButton = styled.button`
  background-color: transparent;
  min-width: 80px;
  height: 30px;
  font-weight: bold;
  border: none;
  border-bottom: 4px solid transparent;
  box-sizing: border-box;
  color: ${({ theme }) => theme.grey};
  cursor: pointer;

  @media ${devices.phoneL} {
    min-width: auto;
    padding: 0 8px;
  }

  ${(props) => (props.active ? css`
    color: ${({ theme }) => theme.secondary};
    border-bottom-color: ${({ theme }) => theme.secondary};
  ` : css`
    &:hover {
      color: ${({ theme }) => theme.secondaryHover};
      border-bottom-color: ${({ theme }) => theme.grey};
    }
  `)}
`;

export const PhoneContainers = styled.div`
  display: grid;
  background-color: ${({ theme }) => theme.containerOdd};
  border-radius: 10px;
  padding: 10px;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: "info info";
  margin-top: 10px;
  grid-gap: 10px;

  @media ${devices.phoneS} {
    grid-template-columns: 1fr;
  }

  & > span {
    color: ${({ theme }) => theme.grey};
    font-size: 14px;
    text-align: center;
    grid-area: info;
  }
`;

export const PhoneItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  border: 2px solid ${({ theme }) => theme.secondary};
  color: ${({ theme }) => theme.secondary};
  background-color: ${({ theme }) => theme.secondary}20;
  border-radius: 5px;
  font-weight: bold;
  padding: 4px;
  width: min-content;
  white-space: nowrap;
  height: min-content;
  width: 100%;
  min-width: 170px;
  & span {
    overflow: hidden;
    max-width: 245px;
    font-size: 14px;
    margin: 0 auto;
  }
  & button, button:hover {
    padding: 0;
    box-shadow: none;
  }
`;
