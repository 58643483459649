export const getOptionsThemes = () => ([
  { label: 'Padrão', value: 'nivo', id: 'nivo' },
  { label: 'Distintas', value: 'category10', id: 'category10' },
  { label: 'Destaque', value: 'accent', id: 'accent' },
  { label: 'Escuras', value: 'dark2', id: 'dark2' },
  { label: 'Emparelhadas', value: 'paired', id: 'paired' },
  { label: 'Pastel 1', value: 'pastel1', id: 'pastel1' },
  { label: 'Pastel 2', value: 'pastel2', id: 'pastel2' },
  { label: 'Grupo 1', value: 'set1', id: 'set1' },
  { label: 'Grupo 2', value: 'set2', id: 'set2' },
  { label: 'Grupo 3', value: 'set3', id: 'set3' },
  { label: 'Spectral', value: 'spectral', id: 'spectral' },
  { label: 'Marrom → Verde', value: 'brown_blueGreen', id: 'brown_blueGreen' },
  { label: 'Roxo → Verde', value: 'purpleRed_green', id: 'purpleRed_green' },
  { label: 'Rosa → Verde', value: 'pink_yellowGreen', id: 'pink_yellowGreen' },
  { label: 'Roxo → Laranja', value: 'purple_orange', id: 'purple_orange' },
  { label: 'Vermelho → Azul', value: 'red_blue', id: 'red_blue' },
  { label: 'Vermelho → Cinza', value: 'red_grey', id: 'red_grey' },
  { label: 'Vermelho → Amarelo → Azul', value: 'red_yellow_blue', id: 'red_yellow_blue' },
  { label: 'Vermelho → Amarelo → Verde', value: 'red_yellow_green', id: 'red_yellow_green' },
  { label: 'Tons de Azul', value: 'blues', id: 'blues' },
  { label: 'Tons de Verde', value: 'greens', id: 'greens' },
  { label: 'Tons de Cinza', value: 'greys', id: 'greys' },
  { label: 'Tons de Laranja', value: 'oranges', id: 'oranges' },
  { label: 'Tons de Roxo', value: 'purples', id: 'purples' },
  { label: 'Tons de Vermelho', value: 'reds', id: 'reds' },
  { label: 'Azul → Verde', value: 'blue_green', id: 'blue_green' },
  { label: 'Azul → Roxo', value: 'blue_purple', id: 'blue_purple' },
  { label: 'Verde → Azul', value: 'green_blue', id: 'green_blue' },
  { label: 'Laranja → Vermelho', value: 'orange_red', id: 'orange_red' },
  { label: 'Roxo → Azul → Verde', value: 'purple_blue_green', id: 'purple_blue_green' },
  { label: 'Roxo → Azul', value: 'purple_blue', id: 'purple_blue' },
  { label: 'Roxo → Vermelho', value: 'purple_red', id: 'purple_red' },
  { label: 'Vermelho → Roxo', value: 'red_purple', id: 'red_purple' },
  { label: 'Amarelo → Verde → Azul', value: 'yellow_green_blue', id: 'yellow_green_blue' },
  { label: 'Amarelo → Verde', value: 'yellow_green', id: 'yellow_green' },
  { label: 'Amarelo → Laranja → Marrom', value: 'yellow_orange_brown', id: 'yellow_orange_brown' },
  { label: 'Amarelo → Laranja → Vermelho', value: 'yellow_orange_red', id: 'yellow_orange_red' },
]);

export const getMapThemes = () => ([
  { label: 'Padrão', value: 'nivo', id: 'nivo' },
  { label: 'Spectral', value: 'spectral', id: 'spectral' },
  { label: 'Marrom → Verde', value: 'BrBG', id: 'BrBG' },
  { label: 'Roxo → Verde', value: 'PRGn', id: 'PRGn' },
  { label: 'Rosa → Verde', value: 'PiYG', id: 'PiYG' },
  { label: 'Roxo → Laranja', value: 'PuOr', id: 'PuOr' },
  { label: 'Vermelho → Azul', value: 'RdBu', id: 'RdBu' },
  { label: 'Vermelho → Cinza', value: 'RdGy', id: 'RdGy' },
  { label: 'Vermelho → Amarelo → Azul', value: 'RdYlBu', id: 'RdYlBu' },
  { label: 'Vermelho → Amarelo → Verde', value: 'RdYlGn', id: 'RdYlGn' },
  { label: 'Tons de Azul', value: 'blues', id: 'blues' },
  { label: 'Tons de Verde', value: 'greens', id: 'greens' },
  { label: 'Tons de Cinza', value: 'greys', id: 'greys' },
  { label: 'Tons de Laranja', value: 'oranges', id: 'oranges' },
  { label: 'Tons de Roxo', value: 'purples', id: 'purples' },
  { label: 'Tons de Vermelho', value: 'reds', id: 'reds' },
  { label: 'Azul → Verde', value: 'BuGn', id: 'BuGn' },
  { label: 'Azul → Roxo', value: 'BuPu', id: 'BuPu' },
  { label: 'Verde → Azul', value: 'GnBu', id: 'GnBu' },
  { label: 'Laranja → Vermelho', value: 'OrRd', id: 'OrRd' },
  { label: 'Roxo → Azul → Verde', value: 'PuBuGn', id: 'PuBuGn' },
  { label: 'Roxo → Azul', value: 'PuBu', id: 'PuBu' },
  { label: 'Roxo → Vermelho', value: 'PuRd', id: 'PuRd' },
  { label: 'Vermelho → Roxo', value: 'RdPu', id: 'RdPu' },
  { label: 'Amarelo → Verde → Azul', value: 'YlGnBu', id: 'YlGnBu' },
  { label: 'Amarelo → Verde', value: 'YlGn', id: 'YlGn' },
  { label: 'Amarelo → Laranja → Marrom', value: 'YlOrBr', id: 'YlOrBr' },
  { label: 'Amarelo → Laranja → Vermelho', value: 'YlOrRd', id: 'YlOrRd' },
]);

export const getColorSchemes = (isMap = false) => {
  const themes = isMap ? getMapThemes() : getOptionsThemes();
  return themes.map((t) => t.value);
};
