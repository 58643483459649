import React from 'react';
import PropTypes from 'prop-types';
import {
  AddStory,
  Admin,
  Dashboard,
  Memory,
  Scientist,
  TemplateUse,
  Users,
} from '../../juristec-ui/icons';

import {
  ContractedPlanContainer,
  RowData,
  LabelContainer,
  InfoContainer,
  // PriceContainer,
} from './styled/ContractedPlan.styled';

/**
 * A component to display the instance plan info
 */
const ContractedPlan = ({
  memorySize,
  adminsInfo,
  scientistsInfo,
  guestsInfo,
  dashboardsInfo,
  templatesInfo,
  storiesInfo,
  // price,
}) => {
  const infoDisplay = (info) => {
    if (typeof (info) === 'object') {
      return (
        <>
          <span className="primary">
            {info.used || 0}
            {'/'}
          </span>
          <span className="secondary">{info.max || 0}</span>
        </>
      );
    }
    return (
      <span className={info === 'Ilimitado' ? 'secondary' : 'primary'}>
        {info}
      </span>
    );
  };

  return (
    <ContractedPlanContainer>
      <h3>Plano Atual Contratado</h3>
      <table style={{ width: '100%', borderSpacing: '0 .6rem' }}>
        <tbody>
          <RowData>
            <LabelContainer>
              <Memory />
            </LabelContainer>
            <LabelContainer>
              <span className="label">Memória</span>
            </LabelContainer>
            <InfoContainer>{infoDisplay(memorySize)}</InfoContainer>
          </RowData>

          <RowData>
            <LabelContainer>
              <Admin />
            </LabelContainer>
            <LabelContainer>
              <span className="label">Usuários proprietários</span>
            </LabelContainer>
            <InfoContainer>{infoDisplay(adminsInfo)}</InfoContainer>
          </RowData>

          <RowData>
            <LabelContainer>
              <Scientist />
            </LabelContainer>
            <LabelContainer>
              <span className="label">Usuários cientistas</span>
            </LabelContainer>
            <InfoContainer>{infoDisplay(scientistsInfo)}</InfoContainer>
          </RowData>

          <RowData>
            <LabelContainer>
              <Users />
            </LabelContainer>
            <LabelContainer>
              <span className="label">Usuários convidados</span>
            </LabelContainer>
            <InfoContainer>{infoDisplay(guestsInfo)}</InfoContainer>
          </RowData>

          <RowData>
            <LabelContainer>
              <Dashboard />
            </LabelContainer>
            <LabelContainer>
              <span className="label">Quantidade de dashboards</span>
            </LabelContainer>
            <InfoContainer>{infoDisplay(dashboardsInfo === 100000 ? 'Ilimitado' : dashboardsInfo)}</InfoContainer>
          </RowData>

          <RowData>
            <LabelContainer>
              <TemplateUse />
            </LabelContainer>
            <LabelContainer>
              <span className="label">Quantidade de templates</span>
            </LabelContainer>
            <InfoContainer>{infoDisplay(templatesInfo)}</InfoContainer>
          </RowData>

          <RowData>
            <LabelContainer>
              <AddStory />
            </LabelContainer>
            <LabelContainer>
              <span className="label">Stories por dashboard</span>
            </LabelContainer>
            <InfoContainer>{infoDisplay(storiesInfo)}</InfoContainer>
          </RowData>
        </tbody>
      </table>

      {/* TODO ver precos dos planos
      <PriceContainer>
        <span className='primary'>{price} / </span>
        <span className='secondary'>mês</span>
      </PriceContainer>
      */}
    </ContractedPlanContainer>
  );
};

ContractedPlan.propTypes = {
  /**
   * Memory quantity info of the instance
   */
  memorySize: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.shape({
      used: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      max: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
    }),
  ]),
  /**
   * Admins quantity info of the instance
   */
  adminsInfo: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.shape({
      used: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      max: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
    }),
  ]),
  /**
   * Scientists quantity info of the instance
   */
  scientistsInfo: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.shape({
      used: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      max: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
    }),
  ]),
  /**
   * Guests quantity info of the instance
   */
  guestsInfo: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.shape({
      used: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      max: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
    }),
  ]),
  /**
   * Dashboards quantity info of the instance
   */
  dashboardsInfo: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.shape({
      used: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      max: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
    }),
  ]),
  /**
   * Templates quantity info of the instance
   */
  templatesInfo: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.shape({
      used: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      max: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
    }),
  ]),
  /**
   * Stories quantity info of the instance
   */
  storiesInfo: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.shape({
      used: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      max: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
    }),
  ]),
  /**
   * Price of the the instance plan
   */
  // price: PropTypes.string,
};

ContractedPlan.defaultProps = {
  memorySize: '-',
  adminsInfo: '-',
  scientistsInfo: '-',
  guestsInfo: '-',
  dashboardsInfo: '-',
  templatesInfo: '-',
  storiesInfo: '-',
  // price: '-',
};

export default ContractedPlan;
