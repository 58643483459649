import {
  deleteDoc, doc, getDoc, setDoc, updateDoc,
} from 'firebase/firestore';
import firebase from '../firebase';

class Doc {
  /**
   * @constructor
   * >### Encapsulates firestore "doc" function for compatibility
   * * * *
   * Gets a `DocumentReference` instance that refers to the document at the
   * specified absolute or relative path. If no path is specified,
   * an automatically-generated unique ID will be used for the returned `DocumentReference`.
   * @param {string} [path] A slash-separated path to a document.
   * Has to be omitted to use auto-genrated IDs.
   * @param {CollectionReference<any>} [ref] A reference to a collection.
   * @throws If the final path has an odd number of segments and does not point to a document.
   * @returns The `Doc` instance.
   */
  constructor(path, ref) {
    if (!ref) {
      this.ref = doc(firebase.db, path);
    } else if (path) {
      this.ref = doc(ref, path);
    } else {
      this.ref = doc(ref);
    }
    this.id = this.ref.id;
    this.data = this.ref.data;
  }

  /**
   * >### Wrapper of firestore `getDoc` function
   * * * *
   * Reads the document referred to by this `DocumentReference`.
   * Note: `getDoc()` attempts to provide up-to-date data when possible
   * by waiting for data from the server, but it may return cached data
   * or fail if you are offline and the server cannot be reached.
   * To specify this behavior, invoke `getDocFromCache` or `getDocFromServer`.
   * @returns A `Promise` resolved with a `DocumentSnapshot`
   * containing the current document contents.
   */
  get() {
    return getDoc(this.ref);
  }

  /**
   * >### Wrapper of firestore `setDoc` function
   * * * *
   * Writes to the document referred to by the specified `DocumentReference`.
   * If the document does not yet exist, it will be created. If you provide `merge`
   * or `mergeFields`, the provided data can be merged into an existing document.
   * @param {object} data A map of the fields and values for the document.
   * @param {object} options An object to configure the set behavior.
   * @returns A `Promise` resolved once the data has been successfully written to the backend
   * (note that it won't resolve while you're offline).
  */
  set(data, options = {}) {
    return setDoc(this.ref, data, options);
  }

  /**
   * >### Wrapper of firestore `updateDoc` function
   * * * *
   * Updates fields in the document referred to by the specified `DocumentReference`.
   * The update will fail if applied to a document that does not exist.
   * @param {object} data An object containing the fields and values with which to update
   * the document. Fields can contain dots to reference nested fields within the document.
   * @returns A `Promise` resolved once the data has been successfully written to the backend
   * (note that it won't resolve while you're offline).
   */
  update(data) {
    return updateDoc(this.ref, data);
  }

  /**
   * >### Wrapper of firestore `deleteDoc` function
   * * * *
   * Deletes the document referred to by the specified `DocumentReference`.
   * @returns A `Promise` resolved once the document has been successfully deleted from the backend
   * (note that it won't resolve while you're offline).
   */
  delete() {
    return deleteDoc(this.ref);
  }
}

export default Doc;
