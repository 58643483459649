export const verifyFileSheetType = (file) => {
  const validTypes = [
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'text/csv',
    'csv',
    'application/vnd.ms-excel',
  ];
  if (validTypes.indexOf(file.type) === -1 && validTypes.indexOf(file.name.split('.').pop()) === -1) { return 'Arquivo com o formato inválido'; }
  return '';
};

export const verifyFileZipType = (file) => {
  const validTypes = [
    'application/zip',
    'application/octet-stream',
    'multipart/x-zip',
    'application/zip-compressed',
    'application/x-zip-compressed',
    'application/x-zip',
    'zip',
  ];
  if (validTypes.indexOf(file.type) === -1 && validTypes.indexOf(file.name.split('.').pop()) === -1) { return 'Arquivo deve ter extensão .zip'; }
  return '';
};

// export const verifyFileImgType = (file) => (!file.type.includes('image/') ? 'Arquivo com o formato inválido' : '');

export const verifyFileImgType = (file) => {
  // it verifies if the file name has more than 50 characters
  if (file.name.length > 50) {
    return 'O nome do arquivo não pode conter mais de 50 caracteres!';
  }
  // it verifies if the name has a period "."
  if (file.name.split('.').length > 2) {
    return 'Nome do arquivo inválido! O nome do arquivo não pode conter "."!';
  }
  // it verifies if the name has some special character.
  const regex = new RegExp('^(?=.*[;,/⁄∕:><*%$])');
  if (regex.test(file.name)) return 'Nome do arquivo inválido! O nome do arquivo não pode conter nenhum caractere especial (;,:></*%$)!';
  const fileExtension = file.name.split('.').pop().toLowerCase();
  const extensionExpected = fileExtension === 'jpg' || fileExtension === 'jpeg' || fileExtension === 'png' || fileExtension === 'gif';
  // it verifies if it is of image type and with expected extension
  if (!file.type.includes('image/') || (!extensionExpected)) {
    return 'Arquivo com o formato inválido! Somente são aceitos arquivos com extensão jpg, jpeg, png ou gif.';
  }
  return '';
};

export const verifyFileSize = (file, sizeLimit) => {
  if (file.size > sizeLimit) return `Arquivo excede o tamanho limite disponível (${sizeLimit / 1000000} MB)`;
  return '';
};
