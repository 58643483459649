import styled, { keyframes, css } from 'styled-components';

const animation = keyframes`
   0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
`;

const Container = styled.div`
  display: inline-block;
  position: relative;

  & > div {
    box-sizing: border-box;
    display: block;
    position: absolute;
  }

  ${({ size }) => {
    if (size === 'large') {
      return css`
        width: 80px;
        height: 80px;

        & > div {
          width: 64px;
          height: 64px;
          margin: 8px;
          border: 8px solid #FFF;
          border-radius: 50%;
          animation: ${animation} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
          border-color: ${({ fill, theme }) => (fill ? `${theme[fill] || fill} transparent transparent transparent` : '#fff transparent transparent transparent')};
        }
      `;
    }
    if (size === 'medium') {
      return css`
        width: 55px;
        height: 55px;

        & > div {
          width: 44px;
          height: 44px;
          margin: 6px;
          border: 6px solid #FFF;
          border-radius: 50%;
          animation: ${animation} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
          border-color: ${({ fill, theme }) => (fill ? `${theme[fill] || fill} transparent transparent transparent` : '#fff transparent transparent transparent')};
        }
      `;
    }
    return css`
      width: 30px;
      height: 30px;

      & > div {
        width: 24px;
        height: 24px;
        margin: 4px;
        border: 4px solid #FFF;
        border-radius: 50%;
        animation: ${animation} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: ${({ fill, theme }) => (fill ? `${theme[fill] || fill} transparent transparent transparent` : '#fff transparent transparent transparent')};
      }
    `;
  }};

  & > div:nth-child(1) { 
    animation-delay: -0.45s;
  }

  & > div:nth-child(1) {
    animation-delay: -0.45s;
  }
  & > div:nth-child(2) {
    animation-delay: -0.3s;
  }
  & > div:nth-child(3) {
    animation-delay: -0.15s;
  }
`;

export default Container;
