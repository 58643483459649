import styled from 'styled-components';
import { devices } from '../../../../juristec-ui/core/breakpoints';
import { gap } from '../../../../juristec-ui/styles/theme';

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  user-select: none;
  margin: 0 .5rem;
  max-width: auto
  @media ${devices.phoneS} {
    max-width: auto;
  }
`;

export const InfoText = styled.div`
  margin-bottom: 1rem;
`;

export const FormColumn = styled.form`
    display: flex;
    flex-direction: column;
    ${gap('.8rem', 'column')}
`;

export const FormRow = styled.div`
    display: flex;
    flex-direction: row;
    ${gap('15px', 'row')}
    
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        transition: background-color 5000s ease-in-out 0s;
    }

    & .errorMessage {
        position: absolute;
        padding-top: 1px;
    }

    & .inputTxt {
        padding-left: 5px;
        border-radius: 0px;
    }
`;
