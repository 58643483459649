import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Select from '../../../../juristec-ui/core/Select';
import Tooltip from '../../../../juristec-ui/core/Tooltip';
import { Question } from '../../../../juristec-ui/icons';

import {
  HeaderTips,
  SecondPageContainer,
  VarContainer,
  SelectContainer,
  IconWrapper,
} from './styled/SecondStep.styled';

const legacyTypes = {
  float: 'float64',
  date: 'datetime64[ns]',
  abc: 'category',
};

/**
 * Second step for the 'ApplyTemplate'. This is where the binding between columns and variables are made.
 */
const SecondStep = ({
  selected,
  fileColumns,
  columnVarBinds,
  setColumnVarBinds,
}) => {
  const [options, setOptions] = useState({ abc: [], float: [], date: [] });

  useEffect(() => {
    setOptions(Object.keys(legacyTypes).reduce((aux, colType) => {
      aux[colType] = fileColumns[colType].map((col) => ({
        id: col,
        label: col,
        value: col,
        type: legacyTypes[colType],
      }));
      return aux;
    }, { abc: [], float: [], date: [] }));
  }, [fileColumns]);

  const handleBinding = (variable, column) => {
    if (column) {
      setColumnVarBinds((old) => ({ ...old, [variable]: column.value }));
    } else {
      setColumnVarBinds((old) => {
        const { [variable]: _, ...newObj } = old;
        return newObj;
      });
    }
  };

  const filterOptions = (variable, cType) => (options[cType]?.filter((opt) => (
    !Object.values(columnVarBinds).includes(opt.value) || opt.value === columnVarBinds[variable]
  ))) || [];

  return (
    <SecondPageContainer>
      <HeaderTips>
        <span>
          Selecione as colunas do seu arquivo correspondentes as variáveis
          necessárias para a aplicação do template.
        </span>
        <span>
          <span className="danger">Observação: </span>
          A associação de variáveis
          que possuem outro significado em seu arquivo, pode gerar indicadores
          errados
        </span>
      </HeaderTips>
      <VarContainer>
        {Object.keys(legacyTypes).map((cType) => {
          if (selected.columnsType[cType]?.length > 0) {
            return (
              selected.columnsType[cType].sort((a, b) => a.localeCompare(b, 'pt-BR')).map((variable) => (
                <SelectContainer key={variable}>
                  <Tooltip
                    className="description"
                    text={(
                      <div style={{ whiteSpace: 'pre-wrap', maxWidth: '250px' }}>
                        {selected.columnsRequired[variable]?.description}
                      </div>
                    )}
                    direction="top"
                    atModal
                  >
                    <IconWrapper>
                      <Question />
                    </IconWrapper>
                  </Tooltip>
                  <Select
                    placement="start"
                    selectLabel={variable}
                    options={filterOptions(variable, cType)}
                    value={{
                      id: columnVarBinds[variable],
                      label: columnVarBinds[variable],
                      value: columnVarBinds[variable],
                    }}
                    onChange={(columnSelected) => handleBinding(variable, columnSelected)}
                    placeholder=""
                    alphabeticalOrder
                    isClearable
                    isSearchable
                    fullWidth
                    atModal
                  />
                </SelectContainer>
              ))
            );
          } return null;
        })}
      </VarContainer>
    </SecondPageContainer>
  );
};

SecondStep.propTypes = {
  /**
   * Array of columns names of the selected file
   */
  fileColumns: PropTypes.shape({
    abc: PropTypes.arrayOf(PropTypes.string),
    float: PropTypes.arrayOf(PropTypes.string),
    date: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  /**
   * Object with the association between column name and template variable
   */
  columnVarBinds: PropTypes.objectOf(PropTypes.string.isRequired),
  /**
   * Function that deals with the association between column name and template variable
   */
  setColumnVarBinds: PropTypes.func.isRequired,
  /**
   * Template selected
   */
  selected: PropTypes.shape({
    columnsType: PropTypes.objectOf(
      PropTypes.arrayOf(PropTypes.string),
    ),
    columnsRequired: PropTypes.shape({
      description: PropTypes.string,
    }),
  }),
};

SecondStep.defaultProps = {
  columnVarBinds: {},
  selected: {},
};

export default SecondStep;
