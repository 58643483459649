import styled, { css } from 'styled-components';
import { devices } from '../../../../../juristec-ui/core/breakpoints';

export const MainContainer = styled.div`
  padding: 0 1rem;
  max-width: 1000px;
  overflow-x: hidden;
  overflow-y: hidden;
  user-select: none;
`;

export const TableWrapper = styled.div`
  max-height: calc(100vh - 405px);
  overflow: auto;
  padding: 0 5px;
  width: 100%;
  max-width: 900px;
`;

export const FilterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 0 0 5px 0;
`;

export const LegendsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 5px 0 0 0;
  & > div {
    display: flex;
    align-items: center;
    & + div {
      margin-left: 10px;
    }
    & > div {
      height: 20px;
      width: 20px;
      border-radius: 5px;
      margin-right: 5px;
      ${({ theme }) => css`
        &.legend-texto {
          background: ${theme.typeText};
        }
      
        &.legend-numero {
          background: ${theme.typeNumber};
        }
      
        &.legend-date {
          background: ${theme.typeDate};
        }
      `}
    }
  }
`;

export const ErrorMsg = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.grey};
  min-height: 50px;
`;
