import React from 'react';
import PropTypes from 'prop-types';

const ExpandDiagonally = ({ height, width, fill }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} fill={fill} height={height} viewBox="0 0 512 512">
    <path d="M344 0L488 0c13.3 0 24 10.7 24 24l0 144c0 9.7-5.8 18.5-14.8 22.2s-19.3 1.7-26.2-5.2l-39-39-87 87c-9.4 9.4-24.6 9.4-33.9 0l-32-32c-9.4-9.4-9.4-24.6 0-33.9l87-87L327 41c-6.9-6.9-8.9-17.2-5.2-26.2S334.3 0 344 0zM168 512L24 512c-13.3 0-24-10.7-24-24L0 344c0-9.7 5.8-18.5 14.8-22.2s19.3-1.7 26.2 5.2l39 39 87-87c9.4-9.4 24.6-9.4 33.9 0l32 32c9.4 9.4 9.4 24.6 0 33.9l-87 87 39 39c6.9 6.9 8.9 17.2 5.2 26.2s-12.5 14.8-22.2 14.8z" />
  </svg>
);

ExpandDiagonally.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  fill: PropTypes.string,
};

ExpandDiagonally.defaultProps = {
  height: '512',
  width: '512',
  fill: 'none',
};

export default ExpandDiagonally;
