import {
  getBatch, getRefSharedItemList, getRefSharedItem, getRefDashboardItem,
} from '../firestore';

export default async (userId) => {
  try {
    console.log('demoting: ', userId);
    const sharedDashsRef = await getRefSharedItemList(userId).get();

    if (sharedDashsRef.size === 0) return;

    const batch = getBatch();

    sharedDashsRef.forEach((doc) => {
      const [owner, dashId] = doc.id.split('_');
      //   console.log({ owner, dashId });
      const dashRef = getRefDashboardItem(owner, dashId);
      batch.set(dashRef, {
        sharedWith: {
          [userId]: {
            label: 'Visualizador',
            value: 'visualizador',
          },
        },
      }, { merge: true });
      batch.update(getRefSharedItem(userId, doc.id), { dashPermission: 'visualizador' });
    });

    await batch.commit();
    console.log('demoted');
  } catch (error) {
    console.log('[demoteDashPermission]: ', error);
  }
};
