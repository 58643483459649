import React from 'react';
import PropTypes from 'prop-types';
import StyledBurger from './styled/Burger.styled';

/**
* A hamburguer button for menus. It morphs into a close icon when active.
*/
const Burger = ({
  open,
  onClick,
  color,
  ...props
}) => (
  <StyledBurger aria-label="Toggle menu" aria-expanded={!!open} open={open} color={color} onClick={onClick} {...props}>
    <span />
    <span />
    <span />
  </StyledBurger>
);

Burger.propTypes = {
  /**
  * A boolean that shows if the hamburger buttons is open or not
  */
  open: PropTypes.bool,
  /**
  * Color of the hamburger. Choose from seven different options or pass a hex color
  */
  color: PropTypes.oneOfType([PropTypes.oneOf(['primary', 'secondary', 'error', 'warning', 'success', 'info', 'dark']), PropTypes.string]),
  /**
  * A function to be passed when the hamburger is clicked
  */
  onClick: PropTypes.func,
};

Burger.defaultProps = {
  open: false,
  color: 'primary',
  onClick: () => {},
};

export default Burger;
