import React from 'react';
import PropTypes from 'prop-types';

const Company = ({ width, height }) => (
  <svg width={width} height={height} viewBox="0 0 23 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.8125 0C1.25977 0 0 1.25977 0 2.8125V27.1875C0 28.7402 1.25977 30 2.8125 30H8.4375V25.3125C8.4375 23.7598 9.69727 22.5 11.25 22.5C12.8027 22.5 14.0625 23.7598 14.0625 25.3125V30H19.6875C21.2402 30 22.5 28.7402 22.5 27.1875V2.8125C22.5 1.25977 21.2402 0 19.6875 0H2.8125ZM3.75 14.0625C3.75 13.5469 4.17187 13.125 4.6875 13.125H6.5625C7.07813 13.125 7.5 13.5469 7.5 14.0625V15.9375C7.5 16.4531 7.07813 16.875 6.5625 16.875H4.6875C4.17187 16.875 3.75 16.4531 3.75 15.9375V14.0625ZM10.3125 13.125H12.1875C12.7031 13.125 13.125 13.5469 13.125 14.0625V15.9375C13.125 16.4531 12.7031 16.875 12.1875 16.875H10.3125C9.79687 16.875 9.375 16.4531 9.375 15.9375V14.0625C9.375 13.5469 9.79687 13.125 10.3125 13.125ZM15 14.0625C15 13.5469 15.4219 13.125 15.9375 13.125H17.8125C18.3281 13.125 18.75 13.5469 18.75 14.0625V15.9375C18.75 16.4531 18.3281 16.875 17.8125 16.875H15.9375C15.4219 16.875 15 16.4531 15 15.9375V14.0625ZM4.6875 5.625H6.5625C7.07813 5.625 7.5 6.04687 7.5 6.5625V8.4375C7.5 8.95313 7.07813 9.375 6.5625 9.375H4.6875C4.17187 9.375 3.75 8.95313 3.75 8.4375V6.5625C3.75 6.04687 4.17187 5.625 4.6875 5.625ZM9.375 6.5625C9.375 6.04687 9.79687 5.625 10.3125 5.625H12.1875C12.7031 5.625 13.125 6.04687 13.125 6.5625V8.4375C13.125 8.95313 12.7031 9.375 12.1875 9.375H10.3125C9.79687 9.375 9.375 8.95313 9.375 8.4375V6.5625ZM15.9375 5.625H17.8125C18.3281 5.625 18.75 6.04687 18.75 6.5625V8.4375C18.75 8.95313 18.3281 9.375 17.8125 9.375H15.9375C15.4219 9.375 15 8.95313 15 8.4375V6.5625C15 6.04687 15.4219 5.625 15.9375 5.625Z" />
  </svg>
);

Company.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

Company.defaultProps = {
  width: '20px',
  height: '20px',
};

export default Company;
