export default {
  // TITLE
  TitleControl: '',
  ShowTitleControl: { checked: true },
  StyleTitleControl: {
    fontSize: 20,
    color: '#000000',
    fontFamily: 'arial',
    bold: false,
    italic: false,
    underline: false,
  },
  // GENERAL
  SortValuesControl: 'Alfabética A-Z',
  CustomSortControl: {
    checked: false, kpiCategories: [],
  },
  Margin: {
    top: 70,
    right: 80,
    bottom: 40,
    left: 80,
  },
  DataFormat: { type: 'absolute', target: 'global' },
  // STYLE
  FillOpacityControl: { value: 0.25 },
  BorderWidthControl: { value: 0 },
  CurveControl: 'linearClosed',
  PalletsControl: 'default',
  ColorTheme: { scheme: 'nivo' },
  GradientPickerControl: {
    color1: '#4711B2',
    color2: '#FF9626',
    color3: null,
  },
  Colors: {},
  UseGlobalColor: true,
  // LABEL
  DotControl: true,
  DotSizeControl: { value: 6 },
  DotPointsColorControl: '#000',
  DotBorderWidthControl: { value: 2 },
  DotLabelControl: true,
  DotColorValue: '#000000',
  LabelsFontFamilyControl: { value: 'arial' },
  LabelsFontSizeControl: { value: 16 },
  DotLabelYOffsetControl: { value: -12 },
  LabelRotationControl: 0,
  LabelTranslateX: 0,
  LabelTranslateY: 0,
  PrefixControl: '',
  SufixControl: '',
  Decimals: 'auto',
  separadorNumericoCasas: '0',
  LabelCurrencyControl: 'Nenhum',
  LabelGroupingControl: '',
  // GRID
  GridShapeControl: 'circular',
  GridLevelControl: { value: 5 },
  GridLabelOffsetControl: { value: 16 },
  // LEGEND
  LegendControl: { checked: true },
  LegendPlacementControl: {
    value: {
      label: 'Direita',
      translateX: -92,
      translateY: 45,
      selectedPosition: 'right',
      direction: 'column',
      anchor: 'right',
    },
  },
  LegendsFontFamilyControl: { value: 'sans-serif' },
  LegendsFontSizeControl: { value: 12 },
  LegendsSpacingControl: { value: 10 },
  LegendSymbolControl: 'circle',
  // TOOLTIP / INTERACTIVE
  InteractiveControl: true,
  InteractivePrefixControl: '',
  InteractiveSufixControl: '',
  InteractiveDecimals: 'auto',
  InteractiveSeparadorNumericoCasas: 0,
  InteractiveCurrencyControl: 'Nenhum',
  InteractiveGroupingControl: '',
  Animate: false,
  MotionConfig: 'default',
};
