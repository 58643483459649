import React from 'react';

function Groupings() {
  return (
    <svg width="30" height="27" viewBox="0 0 30 27" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.66667 6.21875C0.671875 5.64583 0 4.56771 0 3.33333C0 1.49479 1.49479 0 3.33333 0C4.56771 0 5.64583 0.671875 6.21875 1.66667H23.7812C24.3542 0.671875 25.4323 0 26.6667 0C28.5052 0 30 1.49479 30 3.33333C30 4.56771 29.3281 5.64583 28.3333 6.21875V20.4479C29.3281 21.026 30 22.099 30 23.3333C30 25.1719 28.5052 26.6667 26.6667 26.6667C25.4323 26.6667 24.3542 25.9948 23.7812 25H6.21875C5.64062 25.9948 4.56771 26.6667 3.33333 26.6667C1.49479 26.6667 0 25.1719 0 23.3333C0 22.099 0.671875 21.0208 1.66667 20.4479V6.21875ZM23.7812 5H6.21875C5.92708 5.50521 5.50521 5.92708 5 6.21875V20.4479C5.50521 20.7396 5.92708 21.1615 6.21875 21.6667H23.7812C24.0729 21.1615 24.4948 20.7396 25 20.4479V6.21875C24.4948 5.92708 24.0729 5.50521 23.7812 5ZM6.66667 8.33333C6.66667 7.41146 7.41146 6.66667 8.33333 6.66667H15C15.9219 6.66667 16.6667 7.41146 16.6667 8.33333V13.3333C16.6667 14.2552 15.9219 15 15 15H8.33333C7.41146 15 6.66667 14.2552 6.66667 13.3333V8.33333ZM13.3333 16.6667H15C16.8385 16.6667 18.3333 15.1719 18.3333 13.3333V11.6667H21.6667C22.5885 11.6667 23.3333 12.4115 23.3333 13.3333V18.3333C23.3333 19.2552 22.5885 20 21.6667 20H15C14.0781 20 13.3333 19.2552 13.3333 18.3333V16.6667Z" />
    </svg>
  );}

export default Groupings;
