import styled, { css } from 'styled-components';

const markerWidth = {
  small: '250px',
  medium: '400px',
  large: '550px',
  getSize(size) {
    return this[size] || 'auto';
  },
};

const colorState = (actual, completed, theme) => {
  if (actual) return theme.secondary;
  if (completed) return theme.success;
  return theme.stepProgressBase;
};

export const MainContainer = styled.div`
  padding: .5rem;
`;

export const StepMarkersContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  font-weight: bold;
  margin: auto auto 10px;
  width: ${({ width }) => markerWidth.getSize(width)};
`;

export const StepMarker = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  & span {
    color: ${({ actual, completed, theme }) => colorState(actual, completed, theme)};
  }
  & .marker-circle {
    height: 50px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: ${({ actual, completed, theme }) => colorState(actual, completed, theme)};
    margin-bottom: 5px;
    & span {
      color: white;
    }
  }
  &:before {
    content: "";
    position: absolute;
    top: 25px;
    height: 10px;
    width: 100%;
    right: 50%;
    transform: translateY(-50%);
    z-index: -1;
    ${({ theme }) => css`
      background: linear-gradient(to left, ${theme.stepProgressBase} 50%, ${theme.success} 50%);
    `}
    background-size: 200% 100%;
    background-position: ${({ actual, completed }) => ((actual || completed) ? 'left' : 'right')} bottom;
    transition: background-position 500ms ease-in-out;
    
  }
  &:first-child::before {
    content: none;
  }
`;
