import styled from 'styled-components';

const ChartContainer = styled.div`
  height: auto;
  min-height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export default ChartContainer;
