/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import InputTextLine from '../../../../juristec-ui/core/InputTextLine';

import {
  MainContainer,
  ColumnsTitle,
  InputWrapper,
  ColumnsList,
} from './styled/OnbordingReport.styled';

const SecondStep = ({ selectedFields, aliasesState }) => {
  const [aliases, setAliases] = aliasesState;
  const obj = selectedFields.reduce((o, field) => ({
    ...o,
    [field.id]: {
      original: field.label,
      newValue: field.label,
    },
  }), {});
  const [formData, setFormData] = useState(obj);

  const handleNameChange = (e, columnName) => {
    const val = e.target.value;
    setFormData((o) => ({
      ...o,
      [columnName]: {
        ...o[columnName],
        newValue: val,
      },
    }));
  };

  useEffect(() => {
    setAliases(Object.keys(formData).reduce((acc, cur) => ({
      ...acc,
      [cur]: formData[cur].newValue,
    }), {}));
  }, [formData]);

  return (
    <MainContainer>
      <ColumnsTitle>
        <h4 className="list-header" style={{ width: '50%' }}>Nome da coluna</h4>
        <h4 className="list-header" style={{ width: '50%' }}>Novo Nome</h4>
      </ColumnsTitle>
      <ColumnsList>
        {Object.keys(formData).map((key) => (
          <InputWrapper key={key}>
            <span style={{ width: '50%', textAlign: 'center' }}>{formData[key].original}</span>
            <div style={{ width: '50%' }}>
              <InputTextLine
                placeholder={formData[key].original}
                errorMessage=""
                error={false}
                onChange={(e) => handleNameChange(e, key)}
                value={formData[key].newValue}
              />
            </div>
          </InputWrapper>
        ))}
      </ColumnsList>
    </MainContainer>
  );
};

SecondStep.propTypes = {
  selectedFields: PropTypes.arrayOf(
    PropTypes.shape({}),
  ).isRequired,
  aliasesState: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default SecondStep;
