import React, {
  useReducer, useCallback, useMemo, useEffect, createContext, useContext,
} from 'react';

import { ip } from '../../utils/functions/urls';
import getRequestMeta, { getPublicRequestMeta } from '../../utils/functions/generateMeta';
import { getRefUsersList, getRefUser } from '../../utils/firestore';
import firebase from '../../utils/firebase';

import { AuthContext } from '../AuthProvider';

// errors labels
import firestoreErrors from '../../utils/firestoreErrors';
import { error as errorLabels } from '../../label';
import Loader from '../../juristec-ui/core/Loader';

const initialState = {
  users: [],
  init: false,
  isLoading: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'setUsers': {
      return {
        ...state,
        users: action.users,
        init: true,
        isLoading: false,
      };
    }

    case 'addUsers': {
      return {
        ...state,
        isLoading: false,
        users: [...state.users, action.addedUser],
      };
    }

    case 'editUser': {
      return {
        ...state,
        isLoading: false,
        users: state.users.map((u) => {
          if (u.email === action.email) {
            const { scientist, role, email } = action;
            return {
              ...u,
              scientist,
              role,
              email,
            };
          }
          return u;
        }),
      };
    }

    case 'deleteUser': {
      return {
        ...state,
        isLoading: false,
        users: state.users.filter((u) => u.email !== action.email),
      };
    }

    case 'setLoading': {
      return {
        ...state,
        isLoading: action.isLoading,
      };
    }

    case 'clear':
      return initialState;

    default:
      return state;
  }
};

export const UsersContext = createContext(null);

function useUsers({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const setLoading = useCallback((l = true) => dispatch({ type: 'setLoading', isLoading: l }), []);

  // users.company
  const { currentUser, user, changeUserAttrs } = useContext(AuthContext);

  // **********************  FUNÇÔES ************************** //
  const getUsers = useCallback(async () => {
    try {
      const users = await getRefUsersList().where('company', '==', user.company).get();
      return { error: false, msg: '', users: users.docs.map((u) => ({ ...u.data(), id: u.id })) };
    } catch (er) {
      return {
        error: true,
        msg: firestoreErrors(er.code) || errorLabels.useUsers.getUsers,
        raw: `Erro do sistema: ${er.toString()}`,
      };
    }
  }, [user]);

  // o back vai guartdar no doc.
  const toggleUserBlock = useCallback(async (email, disabled) => {
    try {
      const token = await currentUser.getIdToken();
      const opt = {
        ...await getRequestMeta(token, 'PUT', 'JSON'),
        body: JSON.stringify({
          email,
          field: 'disabled',
          new_value: disabled,
        }),
      };
      const resFetch = await fetch(`${ip}/manage/user`, opt);
      if (resFetch.status === 500) {
        return {
          error: true,
          msg: errorLabels.fetchGeneric,
          raw: 'Verifique se existe algum problema com a sua conexão com a internet e tente novamente mais tarde!'
        };
      }
      const json = await resFetch.json();
      if (resFetch.status !== 200) {
        return { error: true, msg: json?.msg || errorLabels.fetchGeneric, raw: json.error };
      }

      return { error: false, msg: '', res: json.info };
    } catch (error) {
      return { error: true, msg: errorLabels.useUsers.toggleBlock, raw: `Erro do sistema: ${error.toString()}` };
    }
  }, [user, currentUser]);

  const addUser = useCallback(async (userInfo) => {
    if (!userInfo.role || !userInfo.email || !userInfo.send_email || !userInfo.volumedata) {
      const msg = 'Missing fields ( try: { role, email, companyinfo, volumedata } )';
      return { error: true, msg, raw: msg };
    }
    try {
      const token = await currentUser.getIdToken();
      const opt = {
        ...await getRequestMeta(token, 'POST', 'JSON'),
        body: JSON.stringify({ ...userInfo }),
      };
      const resFetch = await fetch(`${ip}/manage/user`, opt);
      if (resFetch.status === 500) {
        return { error: true, msg: errorLabels.fetchGeneric, raw: 'Verifique se existe algum problema com a sua conexão com a internet e tente novamente mais tarde!'}
      }
      const json = await resFetch.json();
      // const jsonResponse = JSON.parse(json);
      if (resFetch.status !== 200) {
        return { error: true, msg: errorLabels.useUsers.addUser, raw: json.error };
      }
      return { error: false, msg: '', res: json.info };
    } catch (error) {
      return { error: true, msg: errorLabels.useUsers.addUser, raw: `Erro do sistema: ${error.toString()}` };
    }
  }, [user, currentUser]);

  // const editUser = useCallback(async (email, role, scientist, companyId) => {
  const editUser = useCallback(async (email, role) => {
    try {
      const token = await currentUser.getIdToken();
      const opt = {
        ...await getRequestMeta(token, 'PUT', 'JSON'),
        body: JSON.stringify({
          // companyId,
          // scientist,
          // role,
          email,
          // update: 'role',
          field: 'role',
          new_value: role,
        }),
      };
      const resFetch = await fetch(`${ip}/manage/user`, opt);
      if (resFetch.status === 500) {
        return {
          error: true,
          msg: errorLabels.fetchGeneric,
          raw: 'Verifique se existe algum problema com a sua conexão com a internet e tente novamente mais tarde!',
        };
      }
      const json = await resFetch.json();
      // const jsonResponse = JSON.parse(json);
      if (resFetch.status !== 200) {
        return { error: true, msg: errorLabels.useUsers.editUser, raw: json.error };
      }
      return {
        error: false, msg: '', raw: '', res: json.info,
      };
    } catch (error) {
      return { error: true, msg: errorLabels.useUsers.editUser, raw: `Erro do sistema: ${error.toString()}` };
    }
  }, [user, currentUser]);

  const deleteUser = useCallback(async (email) => {
    try {
      const token = await currentUser.getIdToken();
      const opt = {
        ...await getRequestMeta(token, 'DELETE', 'JSON'),
        body: JSON.stringify({ email }),
      };
      const resFetch = await fetch(`${ip}/manage/user`, opt);
      if (resFetch.status === 500) {
        return {
          error: true,
          msg: errorLabels.fetchGeneric,
          raw: 'Verifique se existe algum problema com a sua conexão com a internet e tente novamente mais tarde!'}
      }
      const json = await resFetch.json();
      if (resFetch.status !== 200) {
        return { error: true, msg: errorLabels.useUsers.deleteUser, raw: json.error };
      }
      return {
        error: false, msg: '', raw: '', res: json.info,
      };
    } catch (error) {
      return { error: true, msg: errorLabels.useUsers.deleteUser, raw: `Erro do sistema: ${error.toString()}` };
    }
  }, [user, currentUser]);

  const resetPassword = useCallback(async (email, gToken) => {
    try {
      const opt = {
        ...getPublicRequestMeta(gToken, 'POST', 'JSON'),
        body: JSON.stringify({
          email,
        }),
      };
      const resFetch = await fetch(`${ip}/manage/user/reset_password`, opt);
      const json = await resFetch.json();

      if (resFetch.status === 500) {
        return {
          error: true,
          msg: errorLabels.fetchGeneric,
          raw: 'Verifique se existe algum problema com a sua conexão com a internet e tente novamente mais tarde!',
          status: 500,
        };
      }

      // Esse erro não será retornado no front para evitar enumeração de usuários. O erro no backend deve ser alterado para uma mensagem genérica
      if (resFetch.status !== 200) {
        return {
          error: true,
          msg: errorLabels.useUsers.resetPW,
          raw: json.error,
        };
      }

      return {
        error: false, msg: '', raw: '', res: json.info,
      };
    } catch (error) {
      console.log(error);
      return { error: true, msg: errorLabels.useUsers.resetPW, raw: `Erro do sistema: ${error.toString()}` };
    }
  }, []);

  const setUserConfigs = useCallback(async (configs) => {
    try {
      const userRef = getRefUser(user.id);
      await userRef.set({ configs: { ...configs } }, { merge: true });
      return { error: false, msg: '' };
    } catch (er) {
      console.log(er);
      return {
        error: true,
        msg: firestoreErrors(er.code),
        raw: `Erro do sistema: ${er.toString()}`,
      };
    }
  }, [user]);

  const setUserGpt = useCallback(async () => {
    try {
      const userRef = getRefUser(user.id);
      await userRef.set({
        acceptedGpt: true,
        acceptedGptDate: firebase.serverTimestamp(),
      }, { merge: true });
      changeUserAttrs({ acceptedGpt: true });
      return { error: false, msg: '' };
    } catch (er) {
      console.log(er);
      return {
        error: true,
        msg: firestoreErrors(er.code),
        raw: `Erro do sistema: ${er.toString()}`,
      };
    }
  }, [user]);

  const middleware = useCallback(async (action) => {
    switch (action.type) {
      case 'getUsers': {
        setLoading(true);
        const {
          error, msg, raw, users,
        } = await getUsers();
        if (!error) {
          dispatch({ type: 'setUsers', users });
        }

        setLoading(false);
        return { error, msg, raw };
      }

      case 'toggleUserBlock': {
        setLoading(true);
        const { email, disabled } = action;
        const {
          error, msg, raw,
        } = await toggleUserBlock(email, disabled);

        setLoading(false);
        return { error, msg, raw };
      }

      case 'addUser': {
        setLoading(true);
        const {
          error, msg, raw,
        } = await addUser(action.userInfo);
        setLoading(false);
        return { error, msg, raw };
      }

      case 'editUser': {
        setLoading(true);
        const {
          email, role, scientist, companyId,
        } = action;
        const {
          error, msg, raw,
        } = await editUser(email, role, scientist, companyId);
        if (!error) {
          dispatch({
            type: 'editUser', scientist, role, email,
          });
        }
        setLoading(false);
        return { error, msg, raw };
      }

      case 'deleteUser': {
        setLoading(true);
        const {
          error, msg, raw,
        } = await deleteUser(action.email);
        if (!error) {
          dispatch({ type: 'deleteUser', email: action.email });
        }
        setLoading(false);
        return { error, msg, raw };
      }

      case 'resetPassword': {
        setLoading(true);
        const {
          error, msg, raw,
        } = await resetPassword(action.email, action.gToken);
        setLoading(false);
        return { error, msg, raw };
      }

      case 'setUserConfigs': {
        return setUserConfigs(action.configs);
      }

      case 'setUserGpt': {
        return setUserGpt();
      }

      default: {
        dispatch(action);
        return { error: false, msg: '', raw: '' };
      }
    }
  }, [
    getUsers,
    editUser,
    toggleUserBlock,
    deleteUser,
    resetPassword,
    setUserConfigs,
    setUserGpt,
    setLoading,
  ]);

  const usersAPI = useMemo(() => ({
    getUsers: () => middleware({ type: 'getUsers' }),
    toggleUserBlock: (email, disabled) => middleware({ type: 'toggleUserBlock', email, disabled }),
    addUser: (userInfo) => middleware({ type: 'addUser', userInfo }),
    editUser: (email, role, scientist, companyId) => middleware({
      type: 'editUser', email, role, scientist, companyId,
    }),
    deleteUser: (email) => middleware({ type: 'deleteUser', email }),
    resetPassword: (email, gToken) => middleware({ type: 'resetPassword', email, gToken }),
    setUserConfigs: (configs) => middleware({ type: 'setUserConfigs', configs }),
    setUserGpt: () => middleware({ type: 'setUserGpt' }),
  }), [middleware]);

  const contextValue = useMemo(() => ({
    state, usersAPI,
  }), [state, usersAPI]);

  useEffect(() => {
    if (!user || state.init || state.isLoading) {
      return;
    }
    if (!currentUser) {
      // console.log('clear do users');
      dispatch({ type: 'clear' });
      return;
    }

    // pegando usuários no começo.
    (async () => {
      setLoading(true);
      const { error, users } = await getUsers();

      if (error) {
        setLoading(false);
      } else {
        dispatch({ type: 'setUsers', users });
      }
    })();
  }, [currentUser, state, user, setLoading]);

  return (
    <UsersContext.Provider value={contextValue}>
      {state.isLoading && <Loader debug="usersProviders" />}
      {children}
    </UsersContext.Provider>
  );
}

export default useUsers;
