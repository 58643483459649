import React from 'react';

const Brush = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.52461 12.0836C4.95742 12.1844 3.53516 12.7813 2.72813 14.9078C2.63633 15.1504 2.41563 15.2977 2.1582 15.2977C1.72422 15.2977 0.382422 14.2168 0 13.9559C0.000390625 17.1727 1.48203 20 5.00039 20C7.96367 20 10.0004 18.2902 10.0004 15.3051C10.0004 15.1836 9.975 15.0676 9.9625 14.9484L6.52461 12.0836V12.0836ZM17.8867 0C17.2945 0 16.7395 0.262109 16.316 0.642578C8.33125 7.77539 7.50039 7.94297 7.50039 10.0426C7.50039 10.5777 7.62734 11.0879 7.84141 11.5543L10.3344 13.6316C10.616 13.702 10.9063 13.75 11.209 13.75C13.6352 13.75 15.0414 11.9738 19.4574 3.73203C19.7457 3.17148 20.0004 2.56602 20.0004 1.93555C20.0004 0.80625 18.9848 0 17.8867 0Z" />
    </svg>
  );
};

export default Brush;
