export default {
  // TITLE
  TitleControl: '',
  ShowTitleControl: { checked: true },
  StyleTitleControl: {
    fontSize: 20,
    color: '#000000',
    fontFamily: 'arial',
    bold: false,
    italic: false,
    underline: false,
  },
  // GENERAL
  StateControl: 'Brasil',
  Margin: {
    top: 50,
    right: 130,
    bottom: 50,
    left: 70,
  },
  DataFormat: { type: 'absolute', target: 'global' },
  // STYLE
  BorderWidthControl: { value: 0 },
  BordeColorMapsControl: '',
  PalletsControl: 'default',
  ColorTheme: { scheme: 'nivo' },
  GradientPickerControl: {
    color1: '#4711B2',
    color2: '#FF9626',
    color3: null,
  },
  UseGlobalColor: true,
  // LEGEND
  LegendControl: { checked: true },
  LegendPlacementControl: {
    value: {
      label: 'Direita',
      translateX: -92,
      translateY: 45,
      selectedPosition: 'right',
      direction: 'column',
      anchor: 'right',
    },
  },
  LegendsFontFamilyControl: { value: 'sans-serif' },
  LegendsFontSizeControl: { value: 12 },
  PrefixControl: '',
  SufixControl: '',
  Decimals: 'auto',
  separadorNumericoCasas: '0',
  LabelCurrencyControl: 'Nenhum',
  LabelGroupingControl: '',
  LegendsSpacingControl: { value: 10 },
  LegendSymbolControl: 'circle',
  // TOOLTIP / INTERACTIVE
  InteractiveControl: true,
  InteractivePrefixControl: '',
  InteractiveSufixControl: '',
  InteractiveDecimals: 'auto',
  InteractiveSeparadorNumericoCasas: 0,
  InteractiveCurrencyControl: 'Nenhum',
  InteractiveGroupingControl: '',
};
