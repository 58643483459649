import React from 'react';
import PropTypes from 'prop-types';

const Excel = ({ width, height }) => (
  <svg width={width} height={height} viewBox="0 0 23 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.75 0C1.68164 0 0 1.68164 0 3.75V26.25C0 28.3184 1.68164 30 3.75 30H18.75C20.8184 30 22.5 28.3184 22.5 26.25V9.375H15C13.9629 9.375 13.125 8.53711 13.125 7.5V0H3.75ZM15 0V7.5H22.5L15 0Z" fill="#0C8045" />
    <path d="M9.13637 14.3636L11.0455 17.75H11.1364L13.0682 14.3636H16.5909L13.1136 20.1818L16.7273 26H13.1136L11.1364 22.5455H11.0455L9.06819 26H5.47728L9.04546 20.1818L5.59092 14.3636H9.13637Z" fill="white" />
  </svg>
);

Excel.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

Excel.defaultProps = {
  width: '25px',
  height: '25px',
};

export default Excel;
