/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';

import ControllersGroup from '../../../controllers/ControllersGroup';
import {
  InputLineController,
  ToggleSwitchController,
  SelectController,
  RangeController,
  ChoiceBoxController,
  SimpleColorPickerController,
} from '../../../controllers/Base';

import fontOptions from '../../../utils/controllersUtils/options';

const LabelControllers = ({
  handle,
  config,
  extend,
  setExtend,
}) => (
  <>
    <ControllersGroup title="Rótulos" id="labels" extend={extend} setExtend={setExtend}>
      <ToggleSwitchController
        label="Habilitar rótulos e pontos"
        handle={handle}
        kWord="DotControl"
        configValue={config?.DotControl}
        firstOption=""
        secondOption=""
        fullWidth
      />
      <RangeController
        label="Tamanho dos pontos"
        handle={handle}
        kWord="DotSizeControl"
        configValue={config?.DotSizeControl?.value}
        min={2}
        max={64}
        fullWidth
        disabled={!config?.DotControl}
      />
      <ChoiceBoxController
        label="Cor dos pontos"
        handle={handle}
        kWord="DotPointsColorControl2"
        options={[
          { value: 'brighter', label: 'Clara', id: 'brighter' },
          { value: 'darker', label: 'Escura', id: 'darker' },
          { value: 'custom', label: 'Customizada', id: 'custom' },
        ]}
        configValue={config?.DotPointsColorControl?.modifiers?.[0]?.[0] || 'custom'}
        fullWidth
        disabled={!config?.DotControl}
      />
      {typeof config?.DotPointsColorControl === 'object' && (
        <RangeController
          label="Opacidade dos pontos"
          handle={handle}
          kWord="DotPointsOpacityControl"
          configValue={config?.DotPointsColorControl?.modifiers?.[0]?.[1] || '1'}
          min={0}
          max={2}
          step={0.1}
          precision={1}
          fullWidth
          disabled={!config?.DotControl}
        />
      )}
      {typeof config?.DotPointsColorControl !== 'object' && (
        <SimpleColorPickerController
          label="Cor customizada"
          handle={handle}
          kWord="DotPointsColorControl"
          configValue={config?.DotPointsColorControl || '#000'}
          orientation="row"
          fullWidth
          disabled={!config?.DotControl}
        />
      )}
      <RangeController
        label="Tamanho do contorno"
        handle={handle}
        kWord="DotBorderWidthControl"
        configValue={config?.DotBorderWidthControl?.value}
        min={0}
        max={10}
        fullWidth
        disabled={!config?.DotControl}
      />
      <ToggleSwitchController
        label="Habilitar rótulos"
        handle={handle}
        kWord="DotLabelControl"
        configValue={config?.DotLabelControl}
        firstOption=""
        secondOption=""
        fullWidth
        disabled={!config?.DotControl}
      />
      <SimpleColorPickerController
        label="Cor dos rótulos"
        handle={handle}
        kWord="DotColorValue"
        configValue={config?.DotColorValue}
        orientation="row"
        fullWidth
        disabled={!config?.DotControl || !config?.DotLabelControl}
      />
      <SelectController
        label="Fonte"
        handle={handle}
        kWord="LabelsFontFamilyControl"
        options={fontOptions}
        configValue={config?.LabelsFontFamilyControl?.value}
        disabled={!config?.DotControl || !config?.DotLabelControl}
      />
      <InputLineController
        label="Tamanho"
        handle={handle}
        kWord="LabelsFontSizeControl"
        configValue={config?.LabelsFontSizeControl?.value}
        min={1}
        max={30}
        step={1}
        precision={0}
        isNumber
        disabled={!config?.DotControl || !config?.DotLabelControl}
      />
      <RangeController
        label="Distância entre rótulo e ponto"
        handle={handle}
        kWord="DotLabelYOffsetControl"
        configValue={config?.DotLabelYOffsetControl?.value}
        min={-24}
        max={24}
        fullWidth
        disabled={!config?.DotControl || !config?.DotLabelControl}
      />
      <RangeController
        label="Rotação"
        handle={handle}
        kWord="LabelRotationControl"
        configValue={config?.LabelRotationControl}
        min={-180}
        max={180}
        fullWidth
        disabled={!config?.DotControl || !config?.DotLabelControl}
      />
      <RangeController
        label="Posição horizontal"
        handle={handle}
        kWord="LabelTranslateX"
        configValue={config?.LabelTranslateX}
        min={-500}
        max={500}
        fullWidth
        disabled={!config?.DotControl || !config?.DotLabelControl}
      />
      <RangeController
        label="Posição vertical"
        handle={handle}
        kWord="LabelTranslateY"
        configValue={config?.LabelTranslateY}
        min={-500}
        max={500}
        fullWidth
        disabled={!config?.DotControl || !config?.DotLabelControl}
      />
      <InputLineController
        label="Prefixo"
        handle={handle}
        kWord="PrefixControl"
        configValue={config?.PrefixControl}
        fullWidth
        disabled={!config?.DotControl || !config?.DotLabelControl}
      />
      <InputLineController
        label="Sufixo"
        handle={handle}
        kWord="SufixControl"
        configValue={config?.SufixControl}
        fullWidth
        disabled={!config?.DotControl || !config?.DotLabelControl}
      />
      <ChoiceBoxController
        label="Casas decimais"
        handle={handle}
        kWord="Decimals"
        options={[
          { value: 'auto', label: 'Automático', id: 'auto' },
          { value: 'fixed', label: 'Customizável', id: 'fixed' },
        ]}
        configValue={config?.Decimals || 'auto'}
        fullWidth
        disabled={!config?.DotControl || !config?.DotLabelControl}
      />
      <RangeController
        label="Quantidade de casas"
        handle={handle}
        kWord="separadorNumericoCasas"
        configValue={config?.separadorNumericoCasas}
        min={0}
        max={5}
        step={1}
        precision={0}
        fullWidth
        disabled={!config?.DotControl || !config?.DotLabelControl || config?.Decimals !== 'fixed'}
      />
      <SelectController
        label="Moeda"
        handle={handle}
        kWord="LabelCurrencyControl"
        options={['Nenhum', 'R$', 'US$', '€'].map((d) => ({ value: d, label: d, id: d }))}
        configValue={config?.LabelCurrencyControl}
        disabled={!config?.DotControl || !config?.DotLabelControl || config?.DataFormat?.type === 'percentual'}
      />
      <SelectController
        label="Agrupamento"
        handle={handle}
        kWord="LabelGroupingControl"
        options={[
          { value: 'Nenhum', label: 'Nenhum', id: 'Nenhum' },
          { value: 'milhar', label: 'Milhar (M)', id: 'Milhar (M)' },
          { value: 'milhao', label: 'Milhão (Mi)', id: 'Milhão (Mi)' },
          { value: 'bilhao', label: 'Bilhão (Bi)', id: 'Bilhão (Bi)' },
        ]}
        configValue={config?.LabelGroupingControl || 'Nenhum'}
        disabled={!config?.DotControl || !config?.DotLabelControl || config?.DataFormat?.type === 'percentual'}
      />
    </ControllersGroup>
  </>
);

LabelControllers.propTypes = {
  config: PropTypes.objectOf(PropTypes.any),
  handle: PropTypes.func,
  extend: PropTypes.string,
  setExtend: PropTypes.func,
};

LabelControllers.defaultProps = {
  config: undefined,
  handle: () => {},
  extend: '',
  setExtend: () => {},
};

export default LabelControllers;
