import React from 'react';
import PropTypes from 'prop-types';

function File({ width, height }) {
  return (
    <svg width={width} height={height} viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.422501 6.92911L7.6375 0.429226C7.78943 0.294558 7.97491 0.186819 8.182 0.112936C8.3891 0.0390531 8.61324 0.000662735 8.84 0.000233965H21.84C22.3799 -0.00491796 22.9158 0.0750607 23.4171 0.235603C23.9183 0.396145 24.3752 0.634106 24.7615 0.935897C25.1478 1.23769 25.456 1.5974 25.6685 1.99448C25.881 2.39157 25.9936 2.81826 26 3.25018V22.7498C25.9936 23.1817 25.881 23.6084 25.6685 24.0055C25.456 24.4026 25.1478 24.7623 24.7615 25.0641C24.3752 25.3659 23.9183 25.6039 23.4171 25.7644C22.9158 25.9249 22.3799 26.0049 21.84 25.9998H4.16C3.62009 26.0049 3.08421 25.9249 2.58294 25.7644C2.08167 25.6039 1.62485 25.3659 1.23855 25.0641C0.852247 24.7623 0.544039 24.4026 0.331528 24.0055C0.119019 23.6084 0.00636482 23.1817 0 22.7498V7.80009C0.00105095 7.47791 0.151617 7.16752 0.422501 6.92911ZM17.875 13H13C12.569 13 12.1557 13.137 11.851 13.3808C11.5462 13.6245 11.375 13.9552 11.375 14.3C11.375 14.6448 11.5462 14.9754 11.851 15.2192C12.1557 15.463 12.569 15.6 13 15.6H17.875C18.306 15.6 18.7193 15.463 19.024 15.2192C19.3288 14.9754 19.5 14.6448 19.5 14.3C19.5 13.9552 19.3288 13.6245 19.024 13.3808C18.7193 13.137 18.306 13 17.875 13ZM8.125 20.7999H17.875C18.306 20.7999 18.7193 20.6629 19.024 20.4191C19.3288 20.1753 19.5 19.8447 19.5 19.4999C19.5 19.1551 19.3288 18.8245 19.024 18.5807C18.7193 18.3369 18.306 18.1999 17.875 18.1999H8.125C7.69402 18.1999 7.2807 18.3369 6.97595 18.5807C6.67121 18.8245 6.5 19.1551 6.5 19.4999C6.5 19.8447 6.67121 20.1753 6.97595 20.4191C7.2807 20.6629 7.69402 20.7999 8.125 20.7999ZM8.59625 7.80009C8.93125 7.7737 9.2399 7.6431 9.4557 7.43642C9.6715 7.22975 9.77719 6.96353 9.75 6.69511V2.60019L3.6725 7.80009H8.59625Z" />
    </svg>
  );
};

File.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

File.defaultProps = {
  width: '25px',
  height: '25px',
};

export default File;
