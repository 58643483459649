import React from 'react';

// import { Container } from './styles';

function CreateKPI() {
  return (
    <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M12.1219 10.9611V0.5C14.0946 0.717383 15.9657 1.48774 17.5196 2.72228C19.0735 3.95683 20.247 5.60531 20.9047 7.47776C21.5625 9.35022 21.6778 11.3704 21.2374 13.3056C21.1646 13.6251 21.0772 13.9403 20.9757 14.25H20.2188V11.6562C20.2188 10.5517 19.3233 9.65625 18.2188 9.65625C17.1142 9.65625 16.2188 10.5517 16.2188 11.6562V14.25H13.625C12.5204 14.25 11.625 15.1454 11.625 16.25C11.625 17.3546 12.5204 18.25 13.625 18.25H16.2188V20.0979C15.3142 20.6181 14.333 21.0035 13.3056 21.2374C11.3704 21.6778 9.35022 21.5625 7.47776 20.9047C5.60531 20.247 3.95683 19.0735 2.72228 17.5196C1.48774 15.9657 0.717383 14.0946 0.5 12.1219H10.9611C11.6022 12.1219 12.1219 11.6022 12.1219 10.9611ZM3.52008 3.52008C5.21182 1.82834 7.42926 0.763176 9.80723 0.5V9.80723H0.5C0.763176 7.42926 1.82834 5.21182 3.52008 3.52008Z" fill="white" />
      <path d="M18.2188 20.8438V11.6562M13.625 16.25H22.8125" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

export default CreateKPI;
