import styled, { css } from 'styled-components';
import { devices } from '../../../../../juristec-ui/core/breakpoints';

export const MainContainer = styled.div`
  padding: 0 1rem;
  max-width: 1000px;
  overflow-x: hidden;
  overflow-y: auto;
  user-select: none;
  max-height: calc(100vh - 380px);
  min-height: 350px;
`;

export const GridContainer = styled.div`
  display: grid;
  position: relative;
  grid-template-columns: minmax(200px, 250px) minmax(200px, 250px) 40px;
  grid-gap: .5rem;
  grid-template-areas:
    'name desc desc'
    'op desc desc'
    'optype setter add'
    'inputs inputs inputs'
    'formula formula formula';
  & h5 {
    margin: 0;
    font-size: 0.85rem;
    color: ${({ theme }) => theme.inputLabel};
    font-weight: bold;
    width: 100%;
    margin-bottom: 4px;
  }
  & .cc-name { grid-area: name; }
  & .cc-desc { grid-area: desc; }
  & .cc-op { grid-area: op; }
  & .cc-optype { grid-area: optype; }
  & .cc-setter { grid-area: setter; }
  & .cc-add { grid-area: add; }
  & .cc-inputs { grid-area: inputs; }
  & .cc-formula { grid-area: formula; }
`;

export const ItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  ${({ textWhen, theme }) => textWhen && css`
    cursor: not-allowed;
    user-select: none;
    & > * {
      pointer-events: none;
      & svg {
        opacity: 0;
      }
      & div {
        color: ${theme.inputLabel};
      }
      & input {
        color: ${theme.inputLabel} !important;
      }
    }
  `}
`;

export const Description = styled.p`
  margin: 0;
  display: flex;
  flex-direction: row;
  white-space: wrap;
  font-size: 12px;
`;

export const ItemRowContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding: 5px;
  min-height: 62px;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.card};
`;

export const OperandsList = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  & li {
    border-radius: 5px;
    margin: 2px;
    padding: 2px 4px;
    font-size: 14px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 2px solid;
    ${({ theme }) => css`
      border-color: ${theme.secondary};
      background-color: ${theme.secondary}20;
      color: ${theme.secondary};
    `}
    & button {
      margin-left: 4px;
      padding: 0;
      height: 15px;
      width: 15px;
    }
  }
  & li.empty {
    color: ${({ theme }) => theme.grey};
    background-color: transparent;
    border: none;
  }
`;

export const FormulaWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  white-space-collapse: preserve-spaces;
  width: 100%;
  min-height: 29px;
  ${({ theme }) => css`
    color: ${theme.color};
    background-color: ${theme.modal};
    & > * {
      margin: .2rem 0;
      box-sizing: border-box;
    }
    & b {
      border: 1px solid;
      font-weight: normal;
      padding: 0 .1rem;
      margin: .2rem .1rem;
    }
    & i {
      border-style: solid;
      border-width: 0 0 1px 0;
      font-style: normal;
      padding: 0 .1rem;
    }
    & sub {
      color: ${theme.grey};
      padding: 0 .1rem;
    }
    & em, .date {
      color: ${theme.warning};
      font-style: normal;
    }
    & .abc {
      color: ${theme.info};
    }
    & .float {
      color: ${theme.success};
    }
  `}
`;
