import React from 'react';

// import { Container } from './styles';

function Desactivate() {
    return (
        <svg width="22" height="22" viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0)">
                <path fillRule="evenodd" clipRule="evenodd" d="M15.8729 10C14.7401 11.4457 12.9781 12.375 11 12.375C7.58398 12.375 4.8125 9.60352 4.8125 6.1875C4.8125 2.77148 7.58398 0 11 0C13.646 0 15.9053 1.66282 16.7894 4H12V10H15.8729ZM14.1324 13.75H16.5C19.5379 13.75 22 16.2121 22 19.25V19.9375C22 21.0762 21.0762 22 19.9375 22H2.0625C0.923828 22 0 21.0762 0 19.9375V19.25C0 16.2121 2.46211 13.75 5.5 13.75H7.86758C8.82578 14.1883 9.88281 14.4375 11 14.4375C12.1172 14.4375 13.1785 14.1883 14.1324 13.75Z" />
                <rect x="13" y="5" width="10" height="4" />
            </g>
        </svg>
    );
}

export default Desactivate;