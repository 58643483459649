import styled from 'styled-components';

export const TableContainer = styled.div`
  max-height: calc(90vh - 150px);
  overflow: auto;
  padding: 5px;
  width: 100%;
  max-width: 900px;
`;

export const LegendsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 10px 0;
  & .legend-type {
    margin-right: 10px;
    display: flex;
    align-items: center;
    &:last-of-type {
      margin-right: 0;
    }
  }

  & .square {
    height: 20px;
    width: 20px;
    border-radius: 5px;
  }

  & .legend-texto {
    background: ${({ theme }) => theme.typeText};
    margin-right: 5px;
  }

  & .legend-numero {
    background: ${({ theme }) => theme.typeNumber};
    margin-right: 5px;
  }

  & .legend-date {
    background: ${({ theme }) => theme.typeDate};
  }
`;
