/* eslint-disable no-param-reassign */
import { useCallback, useMemo } from 'react';

import getRequestMeta from '../utils/functions/generateMeta';

import { ip } from '../utils/functions/urls';
import { error as errorLabels } from '../label';

const getToken = async (u) => u.getIdToken();

function useKpiData(currentUser) {
  /**
   * [Backend-request]
   *
   * Generates a temporary kpi data
   * @param {object} kpiMeta Kpi meta data
   * @callback genKpiData
   */
  const genKpiData = useCallback(async (kpiMeta, database) => {
    try {
      const token = await getToken(currentUser);
      const opt = {
        ...await getRequestMeta(token, 'POST', 'JSON'),
        body: JSON.stringify({
          ...kpiMeta,
          file_id: database,
        }),
      };
      const res = await fetch(`${ip}/kpis/create`, opt);
      const json = await res.json();
      if (res.status !== 200) {
        return { error: true, msg: errorLabels.useKpiData.genKpiData, raw: json };
      }
      return { error: false, data: json.info };
    } catch (er) {
      return { error: true, msg: errorLabels.generic, raw: `Erro do sistema: ${er.toString()}` };
    }
  }, [currentUser]);

  const genSubsetData = useCallback(async (kpiMeta, database, page = 0, sortBy = '', sortOrder = 'asc') => {
    try {
      const token = await getToken(currentUser);
      const opt = {
        ...await getRequestMeta(token, 'POST', 'JSON'),
        body: JSON.stringify({
          ...kpiMeta,
          file_id: database,
          page: page + 1,
          sortBy,
          sortOrder,
        }),
      };
      const res = await fetch(`${ip}/kpis/subset`, opt);
      const json = await res.json();
      if (res.status !== 200) {
        return { error: true, msg: errorLabels.useKpiData.genSubsetData, raw: json };
      }
      return { error: false, data: json.info };
    } catch (er) {
      return { error: true, msg: errorLabels.generic, raw: `Erro do sistema: ${er.toString()}` };
    }
  }, [currentUser]);

  const genMultData = useCallback(async (kpiData = {}, database) => {
    try {
      const token = await getToken(currentUser);
      const opt = {
        ...await getRequestMeta(token, 'POST', 'JSON'),
        body: JSON.stringify({
          fileId: database,
          items: Object.values(kpiData).map((kpiDt) => {
            const aux = {
              ...kpiDt.body,
              kpiId: kpiDt.id,
              kpiType: kpiDt.type,
            };
            if (kpiDt.type === 'SubsetTable') {
              aux.page = (kpiDt.extra.page ?? 1) + 1;
              aux.sortBy = kpiDt.extra.sortBy ?? '';
              aux.sortOrder = kpiDt.extra.sortOrder ?? 'asc';
            }
            return aux;
          }),
        }),
      };
      const res = await fetch(`${ip}/kpis/multi`, opt);
      const json = await res.json();
      if (res.status !== 200) {
        return { error: true, msg: errorLabels.useKpiData.genMultData, raw: json };
      }
      return { error: false, data: json.info };
    } catch (er) {
      return { error: true, msg: errorLabels.generic, raw: `Erro do sistema: ${er.toString()}` };
    }
  }, [currentUser]);

  const middleware = useCallback(async (action) => {
    switch (action.type) {
      case 'genKpiData': {
        const getRes = await genKpiData(action.kpiMeta, action.database);
        if (getRes.error) return getRes;
        return getRes.data;
      }
      case 'genSubsetData': {
        const getRes = await genSubsetData(
          action.kpiMeta, action.database, action.page, action.sortBy, action.sortOrder,
        );
        if (getRes.error) return getRes;
        return getRes.data;
      }
      case 'genMultData': {
        const getRes = await genMultData(action.kpiData, action.database);
        if (getRes.error) return getRes;
        Object.entries(action.kpiData).forEach(([k, dt]) => {
          const datum = getRes.data?.[k] ?? null;
          if (datum) {
            dt.callback((it) => ({ ...it, data: datum }));
          }
        });
        return getRes.data;
      }
      default:
        return {
          error: false, msg: 'DEFAULT', raw: {}, default: true,
        };
    }
  }, [genKpiData]);

  const kpiAPI = useMemo(() => ({
    genKpiData: async (kpiMeta, database) => middleware({
      type: 'genKpiData', kpiMeta, database,
    }),
    genSubsetData: async (kpiMeta, database, page, sortBy, sortOrder) => middleware({
      type: 'genSubsetData', kpiMeta, database, page, sortBy, sortOrder,
    }),
    genMultData: async (kpiData, database) => middleware({
      type: 'genMultData', kpiData, database,
    }),
  }), [middleware]);

  return [kpiAPI];
}

export default useKpiData;
