import styled from 'styled-components';
import { devices } from '../../../../juristec-ui/core/breakpoints';

const StyledNoFile = styled.div`
  align-items: center;
  position: relative;
  color: ${({ theme }) => theme.color};
  margin: 1.5% auto;
  transition: all 0.5s;
  border-radius: 4px;

  & .title {
    background-color: ${({ theme }) => theme.primary};
    font-size: 1.5rem;
    color: white;
    padding: 10px;
    font-weight: bold;
    text-align: center;
    border-radius: 4px 4px 0px 0px;
  }

  & .content {
    position: relative;
    padding: 0px 20px;
    min-height: 20.5rem;
    & > svg {
      width: 100%;
      height: 19rem;
      bottom: 0;
      z-index: -1;
    }
    & p {
      margin: 0px;
      padding: 5px 0px;
      line-height: 2rem;
      text-align: center;
      font-size: 1.2rem;
    }
  }

  & .navlink {
    text-decoration: none;
    color: ${({ theme }) => theme.primary};
    background-color: transparent;
    border: 2px solid ${({ theme }) => theme.primary};
    border-radius: 4px;
    padding: 8px 16px;
    font-size: 18px;
    font-weight: bold;
  }

  width: 50vw;
  @media ${devices.tablet} {
    width: 60vw;
  }
  @media ${devices.phoneL} {
    width: 80vw;
  }
  @media ${devices.phoneS} {
    width: 95vw;
  }
  @media ${devices.ultrawide} {
    width: 40vw;
  }

  @media ${devices.phoneS}, ${devices.phoneL} and (orientation: landscape) {
    & button {
      display: none;
    }
    & .firstphrase {
      display: none;
    }
  }
`;

const StyledOuterNoFile = styled.main`
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: auto;
  padding: 5px;
  margin-top: 0rem;
  margin-left: 5%;
  margin-right: 5%;
  height: calc(100vh - 195px) !important;

  @media ${devices.tablet} {
    margin: 10px 0px;
    height: calc(100vh - 199px) !important;
  }

  @media ${devices.phoneL} {
    margin: 10px 0px;
    height: calc(100vh - 199px) !important;
  }

  @media ${devices.phoneS} {
    margin: 10px 0px;
    height: calc(100vh - 160px);
  }
`;

export { StyledNoFile, StyledOuterNoFile };
