import React from 'react';
import PropTypes from 'prop-types';

const Advbox = ({ width, height }) => (
  <svg width={width} height={height} viewBox="0 0 35 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M17.5 0C18.8673 0 20.1005 0.809108 20.6264 2.05124L34.7386 35.3838C35.458 37.0831 34.6415 39.0347 32.9149 39.7427C31.1882 40.4508 29.2052 39.6472 28.4858 37.9479L17.5 11.9997L6.5142 37.9479C5.79476 39.6472 3.8118 40.4508 2.08515 39.7427C0.358486 39.0347 -0.458025 37.0831 0.261416 35.3838L14.3736 2.05124C14.8995 0.809108 16.1327 0 17.5 0Z" fill="#00AEC1" />
  </svg>
);

Advbox.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

Advbox.defaultProps = {
  width: '25px',
  height: '25px',
};

export default Advbox;
