import React from 'react';
import PropTypes from 'prop-types';

import IconButton from '../../../juristec-ui/core/IconButton';
import { NoFileArch, Add } from '../../../juristec-ui/icons';
import { StyledNoFile, StyledOuterNoFile } from './styled/NoFiles.styled';

export default function NoFiles({ toggle, typeMsg }) {
  return (
    <StyledOuterNoFile>
      <StyledNoFile>
        <div className="content">
          {typeMsg !== 'empty' ? (
            <>

              <p className="firstphrase">
                <b>Nenhum arquivo criado!</b>
                <br />
                Faça upload de um arquivo
                {' '}
                <b>.csv ou .xlsx</b>
                {' '}
                no botão
                {' '}
                <IconButton
                  onClick={toggle}
                  color="secondary"
                  variant="contained"
                  shape="rounded"
                >
                  <Add />
                </IconButton>
                {' '}
                no canto superior direito para ter acesso a todos os recursos do legalmetrics.
              </p>
            </>
          ) : (
            <p>
              <b>Nenhum arquivo encontrado!</b>
              {' '}
              <br />
              {' '}
              Tente outro nome de arquivo.
            </p>
          )}
          <NoFileArch />
        </div>

      </StyledNoFile>
    </StyledOuterNoFile>
  );
}

NoFiles.propTypes = {
  toggle: PropTypes.func.isRequired,
  typeMsg: PropTypes.string,
};

NoFiles.defaultProps = {
  typeMsg: 'empty',
};
