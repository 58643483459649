import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import ActionsGroup from '../../../juristec-ui/core/ActionsGroup';
import Button from '../../../juristec-ui/core/Button';
import InputTextLine from '../../../juristec-ui/core/InputTextLine';

import {
  DesktopUpload,
  File,
} from '../../../juristec-ui/icons';

import { verifyFileZipType } from '../../../juristec-ui/utils/validators/fileValidators';
import { verifyDashName } from '../../../juristec-ui/utils/validators/inputTextValidators';

import {
  MainContainer,
  FormContainer,
  UploadArea,
  UploadBorder,
  UploadOpts,
  FileInfoContainer,
  FileInfo,
  InputRow,
  InputContainer,
} from './styled/ImportDashboard.styled';

/**
* A modal for uploading dashboards from desktop
*/
const ImportDashboard = ({
  uploadFile,
  hide,
  tourContext = { tourOpen: false },
}) => {
  const [dragging, setDragging] = useState(false);
  const [selectedFile, setSelectedFile] = useState({ file: null, errorMsg: '' });
  const [dashName, setDashName] = useState({ value: '', errorMsg: '' });

  const fileInputRef = useRef(null);

  const dragOver = (e) => {
    e.preventDefault();
    setDragging(true);
  };

  const dragEnter = (e) => {
    e.preventDefault();
  };

  const dragLeave = (e) => {
    e.preventDefault();
    setDragging(false);
  };

  const handleFile = (files) => {
    const errorMsg = verifyFileZipType(files[0]);
    setSelectedFile({
      file: errorMsg.length === 0 ? files[0] : null,
      errorMsg,
    });
    if (tourContext.tourOpen && errorMsg.length === 0) tourContext.nextStep();
  };

  const handleDashName = (e) => {
    const val = e.target.value;
    const errorMsg = verifyDashName(val);
    setDashName({
      value: val,
      errorMsg,
    });
  };

  const fileDrop = (e) => {
    e.preventDefault();
    setDragging(false);
    const file = e.dataTransfer.files;
    if (file.length) {
      handleFile(file);
    }
  };

  const fileSelected = () => {
    if (fileInputRef.current.files.length) {
      handleFile(fileInputRef.current.files);
    }
  };

  const toggleUploadBtn = () => selectedFile.file && selectedFile.errorMsg.length === 0
    && dashName.errorMsg.length === 0 && dashName.value.length !== 0;

  const handleUpload = () => uploadFile(selectedFile.file, dashName.value);

  const getProject = () => {
    switch (process.env.REACT_APP_FIREBASE_PROJECT_LABEL) {
      case 'legalmetrics':
        return 'Legal Metrics';

      case 'bennermetrics':
        return 'Benner Metrics';

      case 'legalone-analytics':
        return 'Legal One Analytics';

      default:
        return 'Legal Metrics';
    }
  };

  return (
    <>
      <MainContainer>
        <FormContainer>
          <UploadArea
            onDragOver={dragOver}
            onDragEnter={dragEnter}
            onDragLeave={dragLeave}
            onDrop={fileDrop}
            dragging={dragging}
          >
            <UploadBorder>
              <span>
                Arraste um arquivo
                {' '}
                <b>.zip</b>
                {' '}
                exportado do
                {' '}
                {getProject()}
              </span>
              <span><b>ou</b></span>
              <UploadOpts>
                <input
                  ref={fileInputRef}
                  className="file-input"
                  type="file"
                  accept=".zip"
                  style={{ display: 'none' }}
                  onChange={fileSelected}
                />
                <Button
                  size="small"
                  shape="rounded"
                  onClick={() => fileInputRef.current.click()}
                  className="modal_upload_file"
                >
                  <DesktopUpload />
                  Procure no dispositivo...
                </Button>
              </UploadOpts>
              <FileInfoContainer>
                <>
                  {selectedFile.errorMsg.length > 0 ? (
                    <span className="errorMsg">{selectedFile.errorMsg}</span>
                  ) : (
                    selectedFile.file && (
                      <FileInfo>
                        <File />
                        <span>{selectedFile.file.name}</span>
                      </FileInfo>
                    ))}
                </>
              </FileInfoContainer>
            </UploadBorder>
          </UploadArea>
          <InputContainer>
            <InputRow>
              <InputTextLine
                label="Digite o nome do Dashboard*"
                value={dashName.value}
                onChange={handleDashName}
                error={dashName.errorMsg.length > 0}
                errorMessage={dashName.errorMsg}
              />
            </InputRow>
          </InputContainer>
        </FormContainer>
      </MainContainer>
      <ActionsGroup>
        <Button
          style={{ margin: '5px' }}
          onClick={hide}
          variant="outlined"
          size="large"
        >
          Cancelar
        </Button>
        <Button
          style={{ margin: '5px' }}
          onClick={() => handleUpload()}
          size="large"
          disabled={!toggleUploadBtn()}
          className="modal_upload_file_advance"
        >
          Importar
        </Button>
      </ActionsGroup>
    </>
  );
};

ImportDashboard.propTypes = {
  /**
    * A function to handle a file upload from desktop
    */
  uploadFile: PropTypes.func.isRequired,
  /**
    * A function that closes the modal
    */
  hide: PropTypes.func.isRequired,

  tourContext: PropTypes.shape({
    tourOpen: PropTypes.bool,
    nextStep: PropTypes.func,
  }),
};

ImportDashboard.defaultProps = {
  tourContext: { tourOpen: false, nextStep: () => {} },
};

export default ImportDashboard;
