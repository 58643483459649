import React from 'react';

const ChartValue = ({ primary = '#4711B2', secondary = '#FF9626' }) => (
  <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="30" cy="30" r="30" fill={primary} />
    <path d="M15.826 20.2448V40.3846H10.3584V25.2797H10.2404L5.83479 27.9152V23.2736L10.7911 20.2448H15.826Z" fill={secondary} />
    <path d="M19.9612 40.3846V36.4511L27.4743 30.236C27.9726 29.823 28.3987 29.4362 28.7527 29.0756C29.1133 28.7085 29.3887 28.3315 29.5788 27.9447C29.7755 27.5579 29.8738 27.1285 29.8738 26.6565C29.8738 26.1386 29.7623 25.696 29.5394 25.3289C29.3231 24.9618 29.0215 24.6799 28.6347 24.4832C28.2479 24.2799 27.8021 24.1783 27.2973 24.1783C26.7925 24.1783 26.3467 24.2799 25.9599 24.4832C25.5797 24.6864 25.2846 24.9847 25.0748 25.3781C24.8651 25.7714 24.7602 26.25 24.7602 26.8138H19.5679C19.5679 25.3977 19.8858 24.1783 20.5217 23.1556C21.1577 22.1329 22.0558 21.3462 23.2162 20.7955C24.3766 20.2448 25.737 19.9694 27.2973 19.9694C28.9101 19.9694 30.3065 20.2284 31.4866 20.7463C32.6732 21.2577 33.5877 21.9788 34.2302 22.9098C34.8793 23.8407 35.2038 24.9323 35.2038 26.1844C35.2038 26.9581 35.0432 27.7284 34.7219 28.4954C34.4007 29.2559 33.8238 30.0983 32.9911 31.0227C32.1585 31.9471 30.9752 33.0485 29.4411 34.3269L27.553 35.9004V36.0184H35.4201V40.3846H19.9612Z" fill={secondary} />
    <path d="M45.731 40.66C44.1444 40.66 42.7349 40.3879 41.5024 39.8438C40.2764 39.2931 39.3127 38.5358 38.6112 37.5721C37.9097 36.6084 37.5557 35.5004 37.5492 34.2483H43.0562C43.0627 34.6351 43.1807 34.9825 43.4102 35.2907C43.6462 35.5922 43.9674 35.8282 44.3739 35.9987C44.7804 36.1692 45.2458 36.2544 45.7703 36.2544C46.2751 36.2544 46.7209 36.1659 47.1077 35.9889C47.4945 35.8053 47.7961 35.5529 48.0125 35.2317C48.2288 34.9104 48.3337 34.5433 48.3271 34.1303C48.3337 33.7238 48.2091 33.3632 47.9534 33.0485C47.7043 32.7338 47.3536 32.488 46.9012 32.311C46.4489 32.134 45.9277 32.0455 45.3376 32.0455H43.2922V28.2692H45.3376C45.8818 28.2692 46.3604 28.1807 46.7734 28.0037C47.193 27.8267 47.5175 27.5809 47.7469 27.2662C47.983 26.9515 48.0977 26.5909 48.0911 26.1844C48.0977 25.7911 48.0026 25.4436 47.8059 25.1421C47.6093 24.8405 47.3339 24.6045 46.9799 24.434C46.6324 24.2636 46.2292 24.1783 45.7703 24.1783C45.2721 24.1783 44.8263 24.2668 44.4329 24.4438C44.0461 24.6209 43.7413 24.8667 43.5184 25.1814C43.2955 25.4961 43.1807 25.8567 43.1742 26.2631H37.9425C37.9491 25.0306 38.2867 23.9423 38.9554 22.9983C39.6307 22.0542 40.5551 21.3134 41.7286 20.7758C42.9021 20.2382 44.2493 19.9694 45.7703 19.9694C47.2585 19.9694 48.573 20.2218 49.7137 20.7266C50.861 21.2314 51.7559 21.9264 52.3984 22.8114C53.0474 23.6899 53.3687 24.6963 53.3621 25.8304C53.3752 26.9581 52.9884 27.8824 52.2017 28.6036C51.4215 29.3247 50.4316 29.7509 49.2319 29.882V30.0393C50.8577 30.2164 52.0804 30.7146 52.8999 31.5341C53.7194 32.347 54.1226 33.3698 54.1095 34.6023C54.116 35.7824 53.762 36.828 53.0474 37.7393C52.3394 38.6506 51.3527 39.3652 50.0874 39.8831C48.8287 40.401 47.3765 40.66 45.731 40.66Z" fill={secondary} />
  </svg>
);

export default ChartValue;
