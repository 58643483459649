import styled, { css } from 'styled-components';

export const GroupContainer = styled.div`
  background-color: ${({ theme }) => theme.container};
  overflow: hidden;
  &:first-of-type {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  &:last-of-type {
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }
`;

export const ButtonHeader = styled.div`
  display: flex;
  flex-direction: 'row';
  align-items: center;
  justify-content: space-between;
  height: auto;
  padding: 10px 15px 5px;
  background-color: ${({ theme }) => theme.controllersLabel};
  cursor: ${(props) => (props.alwaysOpen ? 'normal' : 'pointer')};
  border: none;
  color: ${({ theme }) => theme.primary};
  font-weight: bold;
  & .flip-wrapper {
    height: 20px;
    transition: all 0.5s;
    ${(props) => props.flip && css`
      transform: rotate(180deg);
    `}
  }
  & svg {
    fill: ${({ theme }) => theme.primary};
    transform: rotate(-90deg);
    width: 16px;
    height: 16px;
  }
  &:hover {
    ${(props) => props.alwaysOpen || css`
      background-color: ${({ theme }) => theme.containerHover};
    `};
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding: 10px;
  flex-wrap: wrap;
`;

export const CollapsibleContainer = styled.div`
  overflow: hidden;
  ${(props) => props.alwaysOpen || css`
    max-height: ${props.control ? props.maxSize : '0'}px;
  `}
  transition: 0.3s max-height ease-in-out;
`;
