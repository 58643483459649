import React, { useState } from 'react';
import PropTypes from 'prop-types';
import InputTextLine from '../../../juristec-ui/core/InputTextLine';
import Button from '../../../juristec-ui/core/Button';

import { verifyInput } from '../../../juristec-ui/utils/validators/inputTextValidators';
import { trimString } from '../../../juristec-ui/utils/functions/formatString';

const TitleModal = ({ submit }) => {
  const [title, setTitle] = useState({ value: '', error: true, errorMsg: '' });

  const handleTitle = (value) => {
    const msg = verifyInput(value, true);
    setTitle({
      value,
      error: msg.length !== 0,
      errorMsg: msg,
    });
  };

  const handleSubmit = () => {
    if (!title.error) submit(title.value);
  };

  return (
    <div
      style={{
        display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column',
      }}
    >
      <span style={{ fontSize: '1rem', paddingTop: '10px' }}>
        <b>Você não colocou nenhum título para a sua Subtabela</b>
      </span>
      <span style={{ fontSize: '0.9rem', padding: '10px 0px', width: '100%' }}>
        Para continuar, adicione um abaixo!
      </span>
      <InputTextLine
        label="Nome da Subtabela"
        type="text"
        onChange={(e) => handleTitle(e.target.value)}
        value={title.value}
        errorMessage={title.errorMsg}
        error={title.errorMsg.length > 0}
        styleContainer={{ width: '100%' }}
        onBlur={(e) => handleTitle(trimString(e.target.value))}
      />
      <Button
        style={{ margin: '10px 0px' }}
        onClick={handleSubmit}
        disabled={title.error || title.value.length <= 0}
      >
        Continuar
      </Button>
    </div>
  );
};

TitleModal.propTypes = {
  submit: PropTypes.func.isRequired,
};

export default TitleModal;
