import React from 'react';

function Moon() {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.9998 7.8456C6.99322 6.83862 6.30771 5.55587 6.0299 4.15944C5.7521 2.763 5.89446 1.31555 6.439 0C4.88641 0.305641 3.46028 1.06745 2.343 2.188C-0.781 5.312 -0.781 10.3776 2.343 13.5016C5.4678 16.6264 10.5326 16.6256 13.6574 13.5016C14.7777 12.3844 15.5394 10.9586 15.8454 9.4064C14.5298 9.95083 13.0824 10.0931 11.686 9.81534C10.2896 9.53753 9.00683 8.85209 7.9998 7.8456Z" />
        </svg>
    );
}

export default Moon;