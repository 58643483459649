import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
`;

export const IconWrapper = styled.div`
  background-color: ${({ theme }) => theme.info};
  border-radius: 50%;
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  & svg {
    fill: ${({ theme }) => theme.white};
    height: 10px;
    width: 10px;
  }
  &:hover {
    background-color: ${({ theme }) => theme.white};
    box-shadow: 0px 3px 3px ${({ theme }) => theme.shadow};
    & svg {
      fill: ${({ theme }) => theme.info};
    }
  }
`;

export const DescriptionContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px;
  gap: 5px;
  align-items: center;
  max-width: 200px;
  white-space: pre-line;
  & * {
    margin: 0;
  }
  & h2 {
    color: ${({ theme }) => theme.secondary};
  }
  & span {
    color: ${({ theme }) => theme.tooltipColor};
    font-size: 14px;
  }
`;
