import React from 'react';

function Last() {
    return (
      <svg width="18" height="24" viewBox="0 0 18 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M17.1424 0.643179V23.3568C17.1424 23.7104 16.8531 23.9997 16.4995 23.9997H13.9282C13.5746 23.9997 13.2853 23.7104 13.2853 23.3568V13.9071L2.81242 23.6032C1.70888 24.5193 0 23.7532 0 22.2854V1.71458C0 0.246762 1.70888 -0.519288 2.81242 0.396758L13.2853 10.034V0.643179C13.2853 0.289618 13.5746 0.000340123 13.9282 0.000340123H16.4995C16.8531 0.000340123 17.1424 0.289618 17.1424 0.643179Z"/>
      </svg>
    );
}

export default Last;