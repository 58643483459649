import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import PublicGridKpi from '../PublicPage/PublicGridKpi';

import {
  MainContainer,
  // ScrollContainer,
} from './styled/MetaDash.styled';

import kpisLayout from './kpisConfig/layout';
import kpisDoc from './kpisConfig/doc';
import kpisConfigs from './kpisConfig/kpis';

const MetaDash = ({
  rawData, children,
}) => {
  const [formatedData, setFormatedData] = useState(kpisConfigs);

  useEffect(() => {
    setFormatedData((o) => (
      {
        ...o,
        totalProcessValue: {
          ...o.totalProcessValue,
          data: { columns: ['Qtde Processos'], index: ['Totais'], data: [[rawData.total_cnj]] },
        },
        totalValuesValue: {
          ...o.totalValuesValue,
          data: { columns: ['Valor da causa'], index: ['Totais'], data: [[rawData.total_valor_causa]] },
        },
        ValuesBoxplot: {
          ...o.ValuesBoxplot,
          data: {
            columns: [
              'count', 'mean', 'std', 'min', '25%', '50%', '75%', 'max', 'lower', 'upper',
            ],
            index: ['Valor da causa'],
            data: [[
              rawData.total_cnj,
              rawData.media_valor_causa,
              rawData.dvp_valor_causa,
              rawData.minino_valor_causa,
              rawData.quartil_vc_1,
              rawData.mediana_valor_causa,
              rawData.quartil_vc_3,
              rawData.maximo_valor_causa,
              Math.max(
                rawData.minino_valor_causa,
                rawData.quartil_vc_1 - 1.5 * (
                  rawData.quartil_vc_3 - rawData.quartil_vc_1
                ),
              ),
              Math.min(
                rawData.maximo_valor_causa,
                rawData.quartil_vc_3 + 1.5 * (
                  rawData.quartil_vc_3 - rawData.quartil_vc_1
                ),
              ),
            ]],
          },
        },
        totalProcessByResultPie: {
          ...o.totalProcessByResultPie,
          data: ['procedente', 'improcedente', 'parcial_procedente', 'sem_classificacao'].reduce((aux, key) => {
            if (rawData?.[key]?.[0]?.total_cnj) {
              const label = key.charAt(0).toUpperCase() + key.slice(1).replace('_', ' ');
              aux.index.push(label);
              aux.data.push([rawData[key][0].total_cnj]);
            }
            return aux;
          }, { columns: ['Qtde Processos'], index: [], data: [] }),
        },
        totalProcessByYearLine: {
          ...o.totalProcessByYearLine,
          data: rawData.ano_distribuicao?.reduce((aux, datum) => {
            aux.index.push(datum.ano.toString());
            aux.data.push([datum.total_cnj]);
            return aux;
          }, { columns: ['Qtde Processos'], index: [], data: [] }),
        },
        totalValuesByYearLine: {
          ...o.totalValuesByYearLine,
          data: rawData.ano_distribuicao?.reduce((aux, datum) => {
            aux.index.push(datum.ano.toString());
            aux.data.push([datum.total_valor_causa]);
            return aux;
          }, { columns: ['Valor da causa'], index: [], data: [] }),
        },
        totalProcessByTrtBar: {
          ...o.totalProcessByTrtBar,
          data: rawData.total_cnj_trt?.reduce((aux, datum) => {
            aux.index.push(datum.sigla.toUpperCase());
            aux.data.push([datum.total_cnj]);
            return aux;
          }, { columns: ['Qtde Processos'], index: [], data: [] }),
        },
        totalValuesByTrtBar: {
          ...o.totalValuesByTrtBar,
          data: rawData.total_cnj_trt?.reduce((aux, datum) => {
            aux.index.push(datum.sigla.toUpperCase());
            aux.data.push([datum.total_valor_causa]);
            return aux;
          }, { columns: ['Valor da causa'], index: [], data: [] }),
        },
        totalProcessByUfMap: {
          ...o.totalProcessByUfMap,
          data: rawData.total_cnj_uf?.reduce((aux, datum) => {
            aux.index.push(datum.sigla);
            aux.data.push([datum.total_cnj]);
            return aux;
          }, { columns: ['Qtde Processos'], index: [], data: [] }),
        },
        totalValuesByUfBar: {
          ...o.totalValuesByUfBar,
          data: rawData.total_cnj_uf?.reduce((aux, datum) => {
            aux.index.push(datum.sigla);
            aux.data.push([datum.total_valor_causa]);
            return aux;
          }, { columns: ['Valor da causa'], index: [], data: [] }),
        },
      }
    ));
  }, [kpisConfigs, rawData]);

  return (
    <MainContainer>
      <PublicGridKpi
        kpiItemList={Object.values(formatedData)}
        dashboardDoc={kpisDoc}
        kpisLayout={kpisLayout}
        style={{ width: '100%' }}
      />
      {children}
    </MainContainer>
  );
};

MetaDash.propTypes = {
  rawData: PropTypes.shape({
    total_cnj_trt: PropTypes.arrayOf(PropTypes.shape({})),
    total_cnj_uf: PropTypes.arrayOf(PropTypes.shape({})),
    ano_distribuicao: PropTypes.arrayOf(PropTypes.shape({})),
    total_cnj: PropTypes.number,
    total_valor_causa: PropTypes.number,
    media_valor_causa: PropTypes.number,
    dvp_valor_causa: PropTypes.number,
    minino_valor_causa: PropTypes.number,
    quartil_vc_1: PropTypes.number,
    mediana_valor_causa: PropTypes.number,
    quartil_vc_3: PropTypes.number,
    maximo_valor_causa: PropTypes.number,
  }),
  children: PropTypes.node,
};

MetaDash.defaultProps = {
  rawData: {},
  children: <></>,
};

export default MetaDash;
