import styled, { keyframes, css } from 'styled-components';

const inputHighlighter = keyframes`
  from { background: ${({ theme }) => theme.highlightTextLine}; }
  to { width: 0; background: transparent; }
`;

export const WrapperContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  position: relative;
  width: 100%;
  & > * + * {
    margin-left: 10px;
  }
`;

export const SelectedWrapper = styled.div`
  color: ${({ theme }) => theme.color};
  box-sizing: border-box;
  font-size: 1rem;
  padding: 10px 35px 5px 0px;
  display: block;
  height: 35px;
  width: 100%;
  line-height: 100%;
  border: none;
  border-bottom: 1px solid ${({ theme }) => theme.inputBottomLine}; 
  background-color: transparent;
  user-select: none;
  pointer-events: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const SelectContainer = styled.div`
  position: relative;
  width: 100%;
  padding-top: ${({ label }) => (label === '' ? '0px' : '20px')};
  cursor: pointer;
  overflow: hidden;
  ${({ disabled }) => disabled && css`
    background-color: rgba(234,234,234,0.5);
    pointer-events: none;
    cursor: default;
  `}
  & > svg {
    position: absolute;
    transition: all 0.5s;
    fill: ${({ theme }) => theme.grey};
    bottom: 4px;
    right: 0;
    box-shadow: none;
    padding: 2px;
  }
  & > button > svg {
    height: 18px;
    width: 18px;
  }
  ${({ isActive, theme, error }) => isActive && css`
    & > svg {
      transform: rotate(180deg);
    }
    ${SelectedWrapper} {
      +.labelTxt {
        color: ${error ? theme.error : theme.highlightTextLine};
      };
      ~.bar:before, ~.bar:after {
        width: 50%;
      };
      ~.highlight {
        -webkit-animation: ${inputHighlighter} 0.3s ease;
        -moz-animation: ${inputHighlighter} 0.3s ease;
        animation: ${inputHighlighter} 0s ease;
      };

      &:disabled {
        opacity: 0.5;
        pointer-events: none;
      };
    };
  `}

  /* LABEL ======================================= */
  .labelTxt {
    position:absolute;
    top:4px;
    left: 0px;
    font-size:0.85rem;
    color:${({ theme }) => theme.inputLabel};
    font-weight: bold;
  }

  /* ERROR MESSAGE*/
  .errorMessage {
    font-size: 10px;
    color: ${({ theme }) => theme.error};
    position: absolute;
  };

  /* BOTTOM BARS ================================= */
  .bar { 
    position:relative;
    display:block; 
    width: 100%; 
  };

  .bar:before, .bar:after {
    content:'';
    height:2px; 
    width:0;
    bottom: 0px; 
    position:absolute;
    background: ${({ theme, error }) => (error ? theme.error : theme.highlightTextLine)};
    transition:0.2s ease all; 
    -moz-transition:0.2s ease all; 
    -webkit-transition:0.2s ease all;
  };
  
  .bar:before {
    left:50%;
  };

  .bar:after {
    right:50%; 
  };

  /* HIGHLIGHTER ================================== */
  .highlight {
    position:absolute;
    height:60%; 
    width:100px; 
    top:25%; 
    left:0;
    pointer-events:none;
    opacity:0.5;
  }

  /* active state */
  /* .inputTxt:focus ~ .highlight {
    -webkit-animation: ${inputHighlighter} 0.3s ease;
    -moz-animation: ${inputHighlighter} 0.3s ease;
    animation: ${inputHighlighter} 0s ease;
  } */
`;

export const OptionsList = styled.div`
  padding: 0px;
  box-shadow: 0px 4px 4px ${({ theme }) => theme.shadow};
  border-radius: 4px;
  border-style: none;
  box-sizing: border-box;
  z-index: 100;
  transition: all 0.5s;
  min-width: ${({ width }) => width}px;
  font-size: 1rem;
  margin: 0px;
  
`;

export const OptionsGroup = styled.ul`
  overflow: hidden auto;
  height: auto;
  max-height: ${({ maxMenuHeight }) => maxMenuHeight || 200}px;
  list-style-type: none;
  margin: 0;
  padding: 0;
  scrollbar-width: thin !important;
`;

export const OptionsItem = styled.li`
  list-style: none;
  padding: .5rem 1rem .5rem .5rem;
  background-color: ${({ theme }) => theme.selectOdd};
  word-break: break-all;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  border: solid transparent;
  border-width: 0 0 0 .5rem;
  &:nth-child(even) {
    background-color: ${({ theme }) => theme.selectEven};
  }
  &:hover {
    background-color: ${({ theme }) => theme.selectHover};
  }
  ${({ isSelected, theme }) => isSelected && css`
    border-color: ${theme.info};
    color: ${theme.info};
  `}
  ${({ disabled, theme }) => disabled && css`
    color: ${theme.grey};
    background-color: ${theme.selectEven};
    font-style: italic;
    font-size: 14px;
    cursor: default;
  `}
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: .4rem;
  & svg {
    fill: ${({ color, theme }) => theme[color] || theme.grey };
  }
`;
