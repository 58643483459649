/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {
  useEffect, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';
import EmojiPicker from '../../juristec-ui/core/EmojiPicker';
import TextArea from '../../juristec-ui/core/TextArea';
import IconButton from '../../juristec-ui/core/IconButton';
import Button from '../../juristec-ui/core/Button';
import Popover from '../../juristec-ui/core/Popover';
import List from '../../juristec-ui/core/List';
import ListItem from '../../juristec-ui/core/ListItem';
import Tooltip from '../../juristec-ui/core/Tooltip';
import InputTextLine from '../../juristec-ui/core/InputTextLine';

import { trimString } from '../../juristec-ui/utils/functions/formatString';

import {
  AtSign, Cancel, Check, EmojiOutlined, HashTag, Send,
} from '../../juristec-ui/icons';

import {
  InputContainer,
  InputControlsContainer,
  IconWrapper,
} from './styled/Mural.styled';

const MuralInput = ({
  kpis,
  users,
  submitNew,
  submitEdit,
  cancelEdit,
  messageToEdit,
  isEdit,
}) => {
  const inputRef = useRef();
  const specialChar = useRef('');
  const cron = useRef();

  const [inputText, setInputText] = useState(isEdit ? messageToEdit : '');
  const [popControl, setPopControl] = useState(false);
  const [inputFilter, setInputFilter] = useState('');

  const theme = useTheme();

  const [fUsers, setFUsers] = useState(users);

  const [fKpis, setFKpis] = useState(kpis);

  /** Closes the mention/emojis popover and resets their filters */
  const closePop = () => {
    setPopControl(false);
    specialChar.current = '';
    setInputFilter('');
    setFUsers(users);
    setFKpis(kpis);
  };

  /**
   * Toggles emoji popover state
   * @param {Event} e DOM click event
   * @param {boolean} open if true, always sets state to open
   */
  const toggleEmojiPop = (e, open) => {
    e.stopPropagation();
    setPopControl((o) => (!open && o === 'emojis' ? false : 'emojis'));
  };

  /**
   * Toggles users popover state
   * @param {Event} e DOM click event
   * @param {boolean} open if true, always sets state to open
   */
  const toggleUserMentionPop = (e, open) => {
    e.stopPropagation();
    setPopControl((o) => (!open && o === 'users' ? false : 'users'));
  };

  /**
   * Toggles kpis popover state
   * @param {Event} e DOM onClick event
   * @param {boolean} open if true, always sets state to open
   */
  const toggleKpiMentionPop = (e, open) => {
    e.stopPropagation();
    setPopControl((o) => (!open && o === 'kpis' ? false : 'kpis'));
  };

  /**
   * Handles the input filter value and filters elements accordingly
   * @param {Event} e DOM onChange event
   */
  const handleInputFilter = (e) => {
    const val = e.target.value;
    setInputFilter(val);
    clearTimeout(cron.current);
    cron.current = setTimeout(() => {
      if (popControl === 'users') {
        setFUsers(users.filter((u) => u.name.toLowerCase().includes(val.toLowerCase())));
      } else {
        setFKpis(kpis.filter((u) => u.name.toLowerCase().includes(val.toLowerCase())));
      }
    }, 200);
  };

  /* Resets filter array values when original is updated */
  useEffect(() => {
    setFUsers(users);
    setFKpis(kpis);
  }, [users, kpis]);

  /**
   * Handles the input message value by controlling the length limit
   * @param {string} val written value
   * @returns {boolean} message length limit validation
   */
  const controlInputVal = (val) => {
    if (val.length <= 130) {
      setInputText(val);
      return true;
    }
    return false;
  };

  /**
   * Sets the text area value
   * @param {Event} e DOM onChange event
   */
  const handleInput = (e) => controlInputVal(e.target.value);

  /**
   * Adds a string to the cursor position in the input
   * @param {string} val value
   * @param {number} shiftStartPos cursor position to be shifted backwards
   */
  const addValToText = (val, shiftStartPos = 0) => {
    inputRef.current.focus();
    const cursor = inputRef.current.selectionStart;
    const inputValue = inputRef.current.value;
    if (controlInputVal(trimString(`${inputValue.slice(0, cursor - shiftStartPos)}${val}${inputValue.slice(cursor)}`))) {
      setTimeout(() => {
        inputRef.current.selectionStart = cursor + val.length;
        inputRef.current.selectionEnd = cursor + val.length;
      }, 0);
    }
  };

  /**
   * Handles specific key entries in the textarea
   * @param {Event} e DOM onKeyDown event
   */
  const handleSpecialKeys = (e) => {
    switch (e.key) {
      case '@':
        toggleUserMentionPop(e, true);
        specialChar.current = '@';
        break;
      case '#':
        toggleKpiMentionPop(e, true);
        specialChar.current = '#';
        break;
      case ':':
        toggleEmojiPop(e, true);
        specialChar.current = ':';
        break;
      default:
        closePop();
        break;
    }
  };

  /**
   * Adds the user name mention to message
   * @param {string} val user name
   */
  const addUserToComment = (val) => {
    addValToText(` @${val.replace(/ /g, '_')} `, specialChar.current.length);
    specialChar.current = '';
  };

  /**
   * Adds the kpi name mention to message
   * @param {string} val kpi name
   */
  const addKpiToComment = (val) => {
    addValToText(` #${val.replace(/ /g, '_')} `, specialChar.current.length);
    specialChar.current = '';
  };

  /**
   * Adds the emoji to message
   * @param {string} val emoji
   */
  const addEmojiToComment = (_e, val) => {
    addValToText(val.emoji, specialChar.current.length);
    specialChar.current = '';
  };

  /**
   * Finds any mention in the message and format to their respective ids
   * @param {string} txt written message
   * @returns {object} formatted message and mention arrays
   */
  const findMentions = (txt) => {
    const mentions = { users: new Set(), kpis: new Set() };
    const msgArray = txt.split(' ').reduce((aux, w) => {
      if (w) {
        const regRes = /([#|@])(.+)/gi.exec(w);
        if (regRes) {
          if (regRes[1] === '@') {
            const mention = users.find((u) => u.name.replace(/ /g, '_') === regRes[2]);
            if (mention) {
              mentions.users.add(mention.id);
              aux.push(`@${mention.id}`);
              return aux;
            }
          } else if (regRes[1] === '#') {
            const mention = kpis.find((u) => u.name.replace(/ /g, '_') === regRes[2]);
            if (mention) {
              mentions.kpis.add(mention.id);
              aux.push(`#${mention.id}`);
              return aux;
            }
          }
        }
        aux.push(w);
      }
      return aux;
    }, []);
    return { text: msgArray.join(' '), mentions: { users: [...mentions.users], kpis: [...mentions.kpis] } };
  };

  /** Handles the message to be send as new or edit */
  const handleSubmit = () => {
    const text = trimString(inputText);
    closePop();
    if (!text) return;
    const converted = findMentions(text);
    if (isEdit) {
      submitEdit(converted.text, converted.mentions);
    } else {
      submitNew(converted.text, converted.mentions);
    }
    setInputText('');
  };

  return (
    <>
      <Popover
        open={Boolean(popControl)}
        closePopover={closePop}
        direction="top-start"
        offset={[16, 2]}
        style={{
          backgroundColor: theme.popoverBackground,
        }}
      >
        <Popover.Action>
          <InputContainer onClick={closePop}>
            <TextArea
              ref={inputRef}
              value={inputText}
              onChange={handleInput}
              onKeyDown={handleSpecialKeys}
            />
            <InputControlsContainer>
              <div>
                <Tooltip text="Emojis" atModal>
                  <IconButton
                    onClick={toggleEmojiPop}
                    color={popControl === 'emojis' ? 'secondary' : 'primary'}
                    style={{ boxShadow: 'none', padding: '5px' }}
                  >
                    <EmojiOutlined />
                  </IconButton>
                </Tooltip>
                <Tooltip text="Usuários" atModal>
                  <IconButton
                    onClick={toggleUserMentionPop}
                    color={popControl === 'users' ? 'secondary' : 'primary'}
                    style={{ boxShadow: 'none', padding: '5px' }}
                  >
                    <AtSign />
                  </IconButton>
                </Tooltip>
                <Tooltip text="Kpis" atModal>
                  <IconButton
                    onClick={toggleKpiMentionPop}
                    color={popControl === 'kpis' ? 'secondary' : 'primary'}
                    style={{ boxShadow: 'none', padding: '5px' }}
                  >
                    <HashTag />
                  </IconButton>
                </Tooltip>
              </div>
              <div>
                {isEdit ? (
                  <>
                    <IconButton
                      onClick={cancelEdit}
                      style={{ boxShadow: 'none', minWidth: 'auto', marginRight: '5px' }}
                      color="error"
                      variant="outlined"
                      shape="rounded"
                    >
                      <Cancel />
                    </IconButton>
                    <Button
                      onClick={handleSubmit}
                      disabled={!inputText.trim()}
                      style={{ boxShadow: 'none', minWidth: 'auto' }}
                    >
                      Salvar
                      <IconWrapper>
                        <Check />
                      </IconWrapper>
                    </Button>
                  </>
                ) : (
                  <Button
                    onClick={handleSubmit}
                    disabled={!inputText.trim()}
                    style={{ boxShadow: 'none', minWidth: 'auto' }}
                  >
                    Enviar
                    <IconWrapper>
                      <Send />
                    </IconWrapper>
                  </Button>
                )}
              </div>
            </InputControlsContainer>
          </InputContainer>
        </Popover.Action>
        <Popover.Content style={{ backgroundColor: 'white', boxShadow: '0px -1px 4px #9a9a9a' }}>
          <div onClick={(e) => e.stopPropagation()}>
            {/* Emojis popover */}
            {popControl === 'emojis' && (
              <EmojiPicker onEmojiClick={addEmojiToComment} />
            )}
            {/* Users popover */}
            {popControl === 'users' && (
              <>
                <div style={{ padding: '0 5px 5px 5px' }}>
                  <InputTextLine
                    placeholder="Buscar usuários..."
                    value={inputFilter}
                    onChange={handleInputFilter}
                    style={{ fontSize: '14px' }}
                  />
                </div>
                <List style={{ maxHeight: '200px', overflow: 'hidden auto', scrollbarWidth: 'thin' }}>
                  {fUsers.map((u) => (
                    <ListItem
                      key={`mention-${u.id}`}
                      noOutline
                      style={{ fontSize: '14px' }}
                      onClick={() => addUserToComment(u.name)}
                    >
                      {u.name}
                    </ListItem>
                  ))}
                </List>
              </>
            )}
            {/* Kpis popover */}
            {popControl === 'kpis' && (
              <>
                <div style={{ padding: '0 5px 5px 5px' }}>
                  <InputTextLine
                    placeholder="Buscar kpis..."
                    value={inputFilter}
                    onChange={handleInputFilter}
                    style={{ fontSize: '14px' }}
                  />
                </div>
                <List style={{ maxHeight: '200px', overflow: 'hidden auto', scrollbarWidth: 'thin' }}>
                  {fKpis.map((k) => (
                    <ListItem
                      key={`mention-${k.id}`}
                      noOutline
                      style={{ fontSize: '14px' }}
                      onClick={() => addKpiToComment(k.name)}
                    >
                      {k.name}
                    </ListItem>
                  ))}
                </List>
              </>
            )}
          </div>
        </Popover.Content>
      </Popover>
    </>
  );
};

MuralInput.propTypes = {
  kpis: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  users: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  submitNew: PropTypes.func,
  submitEdit: PropTypes.func,
  cancelEdit: PropTypes.func,
  messageToEdit: PropTypes.string,
  isEdit: PropTypes.bool,
};

MuralInput.defaultProps = {
  kpis: [],
  users: [],
  submitNew: () => {},
  submitEdit: () => {},
  cancelEdit: () => {},
  messageToEdit: '',
  isEdit: false,
};

export default MuralInput;
