import React from 'react';
import PropTypes from 'prop-types';

import ControllersGroup from '../../../controllers/ControllersGroup';
import {
  ToggleSwitchController,
  SimpleColorPickerController,
} from '../../../controllers/Base';

const AxisControllers = ({
  handle,
  config,
  extend,
  setExtend,
}) => (
  <>
    <ControllersGroup title="Eixos" id="axis" extend={extend} setExtend={setExtend}>
      <ToggleSwitchController
        label="Grid Horizontal"
        handle={handle}
        kWord="EnableGridX"
        configValue={config?.EnableGridX}
        firstOption=""
        secondOption=""
      />
      <ToggleSwitchController
        label="Grid Vertical"
        handle={handle}
        kWord="EnableGridY"
        configValue={config?.EnableGridY}
        firstOption=""
        secondOption=""
      />
      <SimpleColorPickerController
        label="Cor do grid"
        handle={handle}
        kWord="GridColor"
        configValue={config?.GridColor || '#EEE'}
        orientation="row"
        fullWidth
        disabled={!(config?.EnableGridY || config?.EnableGridX)}
      />
    </ControllersGroup>
  </>
);

AxisControllers.propTypes = {
  config: PropTypes.objectOf(PropTypes.any),
  handle: PropTypes.func,
  extend: PropTypes.string,
  setExtend: PropTypes.func,
};

AxisControllers.defaultProps = {
  config: undefined,
  handle: () => {},
  extend: '',
  setExtend: () => {},
};

export default AxisControllers;
