import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Select from '../../../juristec-ui/core/Select';
import ActionsGroup from '../../../juristec-ui/core/ActionsGroup';
import Button from '../../../juristec-ui/core/Button';

import { MainContainer, InfoText } from './styled/TransferUser.styled';

/** Transfers all allowed data from one user to another */
const TransferUser = ({
  selectedUser, usersOptions, close, submit,
}) => {
  const [selected, setSelected] = useState({ value: '', label: '' });

  const handleSubmit = () => {
    submit(selected);
  };

  return (
    <MainContainer>
      <InfoText>
        Selecione o usuário para o qual você deseja transferir os arquivos e dashboards de
        {' '}
        <b>
          {selectedUser.name}
        </b>
      </InfoText>
      <Select
        selectLabel="Usuários"
        value={selected}
        onChange={setSelected}
        options={usersOptions}
        fullWidth
        atModal
      />
      <ActionsGroup>
        <Button
          style={{ margin: '5px' }}
          onClick={close}
          variant="outlined"
          size="large"
        >
          Cancelar
        </Button>
        <Button
          style={{ margin: '5px' }}
          onClick={handleSubmit}
          size="large"
          disabled={!selected.value}
        >
          Transferir
        </Button>
      </ActionsGroup>
    </MainContainer>
  );
};

TransferUser.propTypes = {
  selectedUser: PropTypes.objectOf(PropTypes.any),
  usersOptions: PropTypes.arrayOf(PropTypes.any),
  close: PropTypes.func,
  submit: PropTypes.func,
};

TransferUser.defaultProps = {
  selectedUser: {},
  usersOptions: [],
  close: () => {},
  submit: () => {},
};

export default TransferUser;
