import React from 'react';

const ChartBoxPlot = ({ primary = '#4711B2', secondary = '#FF9626' }) => (
  <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M58.3963 60C44.7217 60 3.6994 60 3.6994 60C1.65959 60 0 58.4208 0 56.4799C0 56.4799 0 15.4725 0 1.60557C0 -0.53519 4.43912 -0.53519 4.43912 1.60557C4.43912 15.2967 4.43912 55.7761 4.43912 55.7761C4.43912 55.7761 44.907 55.7761 58.3962 55.7761C60.5346 55.7761 60.5346 60 58.3963 60Z" fill={primary} />
    <path d="M46 7C45.4477 7 45 7.44772 45 8C45 8.55228 45.4477 9 46 9H49V16H51V9H54C54.5523 9 55 8.55228 55 8C55 7.44772 54.5523 7 54 7H46Z" fill={primary} />
    <path d="M56 16.7452C56 16.3354 55.6313 16 55.1808 16H51H49H44.8192C44.3687 16 44 16.3354 44 16.7452V29.5H56V16.7452Z" fill={primary} />
    <path d="M55.1808 44C55.6313 44 56 43.6647 56 43.2549V31.5H44V43.2549C44 43.6647 44.3687 44 44.8192 44H49H51H55.1808Z" fill={primary} />
    <path d="M54 51H51V44H49V51H46C45.4477 51 45 51.4477 45 52C45 52.5523 45.4477 53 46 53H54C54.5523 53 55 52.5523 55 52C55 51.4477 54.5523 51 54 51Z" fill={primary} />
    <path d="M38 11.6143C38 11.2764 37.5619 11 37.0268 11H33H31H26.973C26.4379 11 26 11.2764 26 11.6143V23.5H38V11.6143Z" fill={secondary} />
    <path d="M28 3C27.4477 3 27 3.44772 27 4C27 4.55228 27.4477 5 28 5H31V11H33V5H36C36.5523 5 37 4.55228 37 4C37 3.44772 36.5523 3 36 3H28Z" fill={secondary} />
    <path d="M37.0268 38.9998C37.5619 38.9998 38 38.7234 38 38.3855V25.5H26V38.3857C26 38.7236 26.4379 39 26.973 39L31 38.9999L33 38.9999L37.0268 38.9998Z" fill={secondary} />
    <path d="M36 45H33V38.9999L31 38.9999V45H28C27.4477 45 27 45.4477 27 46C27 46.5523 27.4477 47 28 47H36C36.5523 47 37 46.5523 37 46C37 45.4477 36.5523 45 36 45Z" fill={secondary} />
    <path d="M19.0944 41.9998C19.5925 41.9998 20 41.6917 20 41.315V33H8V41.3152C8 41.6919 8.40763 42 8.90572 42L13 41.9999L15 41.9999L19.0944 41.9998Z" fill={primary} />
    <path d="M20 23.6849C20 23.3082 19.5925 23 19.0944 23H15H13H8.90572C8.40763 23 8 23.3082 8 23.6849V31H20V23.6849Z" fill={primary} />
    <path d="M18 47H15V41.9999L13 41.9999V47H10C9.44772 47 9 47.4477 9 48C9 48.5523 9.44772 49 10 49H18C18.5523 49 19 48.5523 19 48C19 47.4477 18.5523 47 18 47Z" fill={primary} />
    <path d="M10 16C9.44772 16 9 16.4477 9 17C9 17.5523 9.44772 18 10 18H13V23H15V18H18C18.5523 18 19 17.5523 19 17C19 16.4477 18.5523 16 18 16H10Z" fill={primary} />
  </svg>
);

export default ChartBoxPlot;
