/* eslint-disable no-param-reassign */
import cloneDeep from 'lodash.clonedeep';
import { legendPositionOptions } from '../../options';

/**
 * Sets controller values for the styleConfig object
 * @todo `check if the value can be structured at the controllers level`
 * @param {object} newConfig Existent styleConfig object
 * @param {string} diff Attribution key from a controller
 * @param {*} value Value to be assigned
 * @returns {object} Modified styleConfig object
 */
export default function (newConfig, diff, value) {
  const styleConfig = cloneDeep(newConfig);
  switch (diff) {
    /**
     * Nested checked */
    case 'ReverseControl':
    case 'EnableLabelControl':
    case 'RadialLabelControl':
    case 'LegendControl':
    case 'ShowTitleControl':
      styleConfig[diff] = { checked: value };
      break;
    /**
     * Nested value */
    case 'PaddingControl':
    case 'InnerPaddingControl':
    case 'PaddingAngleControl':
    case 'BorderRadiusControl':
    case 'InnerRadiusControl':
    case 'BodyRadiusControl':
    case 'CategorySpacingControl':
    case 'CornerRadiusControl':
    case 'BorderWidthControl':
    case 'LegendsFontSizeControl':
    case 'LegendsFontFamilyControl':
    case 'LegendsSpacingControl':
    case 'LabelsFontSizeControl':
    case 'LabelsFontFamilyControl':
    case 'DotSizeControl':
    case 'DotLabelYOffsetControl':
    case 'FillOpacityControl':
    case 'GridLevelControl':
    case 'GridLabelOffsetControl':
      styleConfig[diff] = { value };
      break;
    /**
     * Nested value (numeric) */
    case 'separadorNumericoCasas':
    case 'InteractiveSeparadorNumericoCasas':
      styleConfig[diff] = +value;
      break;
    /**
     * Custom attributions */
    case 'CustomSortControlUpt':
      styleConfig.CustomSortControl = { ...styleConfig.CustomSortControl, ...value };
      break;
    case 'SortValuesControl':
      if (value === 'Customizado') {
        styleConfig.CustomSortControl = { ...styleConfig.CustomSortControl, checked: true };
      } else {
        styleConfig.CustomSortControl = { ...styleConfig.CustomSortControl, checked: false };
      }
      styleConfig.SortValuesControl = value;
      break;
    case 'LayoutControl':
      if (value === 'horizontal') {
        styleConfig.LabelFormatControl = 'Central';
      }
      styleConfig.LayoutControl = value;
      if (styleConfig.MarkersList) {
        styleConfig.MarkersList = styleConfig.MarkersList.map((line) => ({
          ...line,
          axis: value === 'horizontal' ? 'x' : 'y',
        }));
      }
      break;
    case 'LabelTextColorControl':
      if (value === false) {
        styleConfig.LabelTextColorControl = {
          from: 'color',
          modifiers: [['darker', 1.6]],
        };
      } else {
        styleConfig.LabelTextColorControl = value === true ? 'black' : value;
      }
      break;
    case 'LabelTextColorControl2':
      if (value === 'custom') styleConfig.LabelTextColorControl = '#000';
      else {
        styleConfig.LabelTextColorControl = {
          from: 'color',
          modifiers: [[value, styleConfig.LabelTextColorControl.modifiers?.[0]?.[1] || '1']],
        };
      }
      break;
    case 'LabelTextOpacityControl':
      styleConfig.LabelTextColorControl = { from: 'color', modifiers: [[styleConfig.LabelTextColorControl.modifiers[0][0], value]] };
      break;
    case 'SliceTextColorControl2':
      if (value === 'custom') styleConfig.SliceTextColorControl = '#000';
      else {
        styleConfig.SliceTextColorControl = {
          from: 'color',
          modifiers: [[value, styleConfig.SliceTextColorControl.modifiers?.[0]?.[1] || '1']],
        };
      }
      break;
    case 'SliceTextOpacityControl':
      styleConfig.SliceTextColorControl = { from: 'color', modifiers: [[styleConfig.SliceTextColorControl.modifiers[0][0], value]] };
      break;
    case 'BorderColorControl2':
      if (value === 'custom') styleConfig.BorderColorControl = '#000';
      else {
        styleConfig.BorderColorControl = {
          from: 'color',
          modifiers: [[value, styleConfig.BorderColorControl.modifiers?.[0]?.[1] || '1']],
        };
      }
      break;
    case 'BorderOpacityControl':
      styleConfig.BorderColorControl = {
        from: 'color',
        modifiers: [[styleConfig.BorderColorControl.modifiers?.[0]?.[0] || 'darker', value]],
      };
      break;
    case 'MedianColorControl2':
      if (value === 'custom') styleConfig.MedianColorControl = '#000';
      else {
        styleConfig.MedianColorControl = {
          from: 'color',
          modifiers: [[value, styleConfig.MedianColorControl.modifiers?.[0]?.[1] || '1']],
        };
      }
      break;
    case 'MedianOpacityControl':
      styleConfig.MedianColorControl = {
        from: 'color',
        modifiers: [[styleConfig.MedianColorControl.modifiers?.[0]?.[0] || 'darker', value]],
      };
      break;
    case 'WhiskerColorControl2':
      if (value === 'custom') styleConfig.WhiskerColorControl = '#000';
      else {
        styleConfig.WhiskerColorControl = {
          from: 'color',
          modifiers: [[value, styleConfig.WhiskerColorControl.modifiers?.[0]?.[1] || '1']],
        };
      }
      break;
    case 'WhiskerOpacityControl':
      styleConfig.WhiskerColorControl = {
        from: 'color',
        modifiers: [[styleConfig.WhiskerColorControl.modifiers?.[0]?.[0] || 'darker', value]],
      };
      break;
    case 'DotPointsColorControl2':
      if (value === 'custom') styleConfig.DotPointsColorControl = '#000';
      else {
        styleConfig.DotPointsColorControl = {
          from: 'color',
          modifiers: [[value, styleConfig.DotPointsColorControl?.modifiers?.[0]?.[1] || '1']],
        };
      }
      break;
    case 'DotPointsOpacityControl':
      styleConfig.DotPointsColorControl = {
        from: 'color',
        modifiers: [[styleConfig.DotPointsColorControl?.modifiers?.[0]?.[0] || 'darker', value]],
      };
      break;
    case 'GradientFirstColor':
      styleConfig.GradientPickerControl = { ...styleConfig.GradientPickerControl, color1: value };
      break;
    case 'GradientSecondColor':
      styleConfig.GradientPickerControl = { ...styleConfig.GradientPickerControl, color2: value };
      break;
    case 'GradientThirdColor':
      styleConfig.GradientPickerControl = { ...styleConfig.GradientPickerControl, color3: value };
      break;
    case 'LegendPlacementControl2':
      styleConfig.LegendPlacementControl = { value: legendPositionOptions(value) };
      break;
    case 'LegendPlacementControlRM':
      styleConfig.LegendPlacementControl = {
        value: legendPositionOptions(value, { isMapOrRadar: true }),
      };
      break;
    case 'LegendPlacementControlW':
      styleConfig.LegendPlacementControl = {
        value: legendPositionOptions(value, { isWaffle: true }),
      };
      break;
    case 'LegendTranslateX':
      styleConfig.LegendPlacementControl = {
        value: {
          ...styleConfig.LegendPlacementControl.value,
          translateX: parseInt(value, 10),
        },
      };
      break;
    case 'LegendTranslateY':
      styleConfig.LegendPlacementControl = {
        value: {
          ...styleConfig.LegendPlacementControl.value,
          translateY: parseInt(value, 10),
        },
      };
      break;
    case 'MarginTop':
      styleConfig.Margin = { ...styleConfig.Margin, top: +value };
      break;
    case 'MarginRight':
      styleConfig.Margin = { ...styleConfig.Margin, right: +value };
      break;
    case 'MarginBottom':
      styleConfig.Margin = { ...styleConfig.Margin, bottom: +value };
      break;
    case 'MarginLeft':
      styleConfig.Margin = { ...styleConfig.Margin, left: +value };
      break;
    case 'AreaControl':
      if (typeof value === 'boolean') {
        styleConfig.AreaControl = { ...styleConfig.AreaControl, checked: value };
      } else {
        styleConfig.AreaControl = {
          ...styleConfig.AreaControl,
          opacity: +value,
        };
      }
      break;
    case 'EnablePoints':
      if (typeof value === 'boolean') {
        styleConfig.EnablePointsControl = {
          ...styleConfig.EnablePointsControl,
          checked: value,
        };
      } else {
        styleConfig.EnablePointsControl = {
          ...styleConfig.EnablePointsControl,
          pointsSize: +value,
        };
      }
      break;
    case 'EnablePointsBorder':
      styleConfig.EnablePointsControl = {
        ...styleConfig.EnablePointsControl,
        border: +value,
      };
      break;
    case 'PointsLabelColor':
      styleConfig.DotsLabel = {
        ...styleConfig.DotsLabel,
        color: value,
      };
      break;
    case 'PointsLabelFontFamily':
      styleConfig.DotsLabel = {
        ...styleConfig.DotsLabel,
        fontFamily: value,
      };
      break;
    case 'PointsLabelFontSize':
      styleConfig.DotsLabel = {
        ...styleConfig.DotsLabel,
        fontSize: +value,
      };
      break;
    case 'ColorTheme':
      styleConfig.ColorTheme = { scheme: value };
      break;
    case 'PointsColorControlColorPicker':
      styleConfig.PointsColorControl = value;
      break;
    case 'PointsColorControl2':
      if (value === 'custom') styleConfig.PointsColorControl = '#000';
      else {
        styleConfig.PointsColorControl = {
          from: 'color',
          modifiers: [[value, styleConfig.PointsColorControl.modifiers?.[0]?.[1] || '1']],
        };
      }
      break;
    case 'PointsOpacityControl':
      styleConfig.PointsColorControl = { from: 'color', modifiers: [[styleConfig.PointsColorControl.modifiers[0][0], value]] };
      break;
    case 'EnableAxisTop':
      styleConfig.EnableAxisX = { ...styleConfig.EnableAxisX, axisTop: value };
      break;
    case 'EnableAxisBottom':
      styleConfig.EnableAxisX = { ...styleConfig.EnableAxisX, axisBot: value };
      break;
    case 'AxisXTickSize':
      styleConfig.EnableAxisX = { ...styleConfig.EnableAxisX, tickSize: +value };
      break;
    case 'AxisXTickPadding':
      styleConfig.EnableAxisX = {
        ...styleConfig.EnableAxisX,
        tickPadding: +value,
      };
      break;
    case 'AxisXLegendOffset':
      styleConfig.EnableAxisX = { ...styleConfig.EnableAxisX, legendOffset: value };
      break;
    case 'AxisXTickRotation':
      styleConfig.EnableAxisX = {
        ...styleConfig.EnableAxisX,
        tickRotation: +value,
      };
      break;
    case 'AxisXMaxLabelLines':
      styleConfig.EnableAxisX = {
        ...styleConfig.EnableAxisX,
        maxLabelLines: +value,
      };
      break;
    case 'AxisXLegend':
      styleConfig.EnableAxisX = { ...styleConfig.EnableAxisX, legend: value };
      break;
    case 'EnableAxisLeft':
      styleConfig.EnableAxisY = { ...styleConfig.EnableAxisY, axisLeft: value };
      break;
    case 'EnableAxisRight':
      styleConfig.EnableAxisY = { ...styleConfig.EnableAxisY, axisRight: value };
      break;
    case 'AxisYTickSize':
      styleConfig.EnableAxisY = { ...styleConfig.EnableAxisY, tickSize: +value };
      break;
    case 'AxisYTickPadding':
      styleConfig.EnableAxisY = {
        ...styleConfig.EnableAxisY,
        tickPadding: +value,
      };
      break;
    case 'AxisYTickRotation':
      styleConfig.EnableAxisY = {
        ...styleConfig.EnableAxisY,
        tickRotation: +value,
      };
      break;
    case 'AxisYLegend':
      styleConfig.EnableAxisY = { ...styleConfig.EnableAxisY, legend: value };
      break;
    case 'AxisYLegendOffset':
      styleConfig.EnableAxisY = { ...styleConfig.EnableAxisY, legendOffset: value };
      break;
    case 'AxisGlobalFontSize':
      styleConfig.AxisGlobal = { ...styleConfig.AxisGlobal, fontSize: +value };
      break;
    case 'AxisGlobalLegendFontSize':
      styleConfig.AxisGlobal = {
        ...styleConfig.AxisGlobal,
        legendFontSize: +value,
      };
      break;
    case 'AxisGlobalFontFamily':
      styleConfig.AxisGlobal = { ...styleConfig.AxisGlobal, fontFamily: value };
      break;
    case 'AxisGlobalFontColor':
      styleConfig.AxisGlobal = { ...styleConfig.AxisGlobal, color: value };
      break;
    case 'GlobalValueFontSize':
      styleConfig.GlobalValue = { ...styleConfig.GlobalValue, fontSize: value };
      break;
    case 'GlobalValueFontFamily':
      styleConfig.GlobalValue = { ...styleConfig.GlobalValue, fontFamily: value };
      break;
    case 'GlobalValueFontColor':
      styleConfig.GlobalValue = { ...styleConfig.GlobalValue, color: value };
      break;
    case 'GlobalValueBackColor':
      styleConfig.GlobalValue = { ...styleConfig.GlobalValue, background: value };
      break;
    case 'GlobalValueBold':
      styleConfig.GlobalValue = { ...styleConfig.GlobalValue, bold: value };
      break;
    case 'GlobalValueItalic':
      styleConfig.GlobalValue = { ...styleConfig.GlobalValue, italic: value };
      break;
    case 'GlobalValueUnderline':
      styleConfig.GlobalValue = { ...styleConfig.GlobalValue, underline: value };
      break;
    case 'MinValueControl2':
      styleConfig.MinValueControl = { ...styleConfig.MinValueControl, checked: value };
      break;
    case 'MaxValueControl2':
      styleConfig.MaxValueControl = { ...styleConfig.MaxValueControl, checked: value };
      break;
    case 'MinValue':
      styleConfig.MinValueControl = { ...styleConfig.MinValueControl, value };
      break;
    case 'MaxValue':
      styleConfig.MaxValueControl = { ...styleConfig.MaxValueControl, value };
      break;
    case 'separadorNumericoCasasY':
      styleConfig.EnableAxisY = { ...styleConfig.EnableAxisY, separadorNumericoCasasY: +value };
      break;
    case 'DecimalsY':
      styleConfig.EnableAxisY = { ...styleConfig.EnableAxisY, Decimals: value };
      break;
    case 'separadorNumericoCasasX':
      styleConfig.EnableAxisX = { ...styleConfig.EnableAxisX, separadorNumericoCasasX: +value };
      break;
    case 'DecimalsX':
      styleConfig.EnableAxisX = { ...styleConfig.EnableAxisX, Decimals: value };
      break;
    case 'DataFormatType':
      styleConfig.DataFormat = { ...styleConfig.DataFormat, type: value };
      break;
    case 'DataFormatTarget':
      styleConfig.DataFormat = { ...styleConfig.DataFormat, target: value };
      break;
    case 'Colors':
      styleConfig.Colors[value.target] = value.value;
      break;
    case 'TitleFontSize':
      styleConfig.StyleTitleControl = { ...styleConfig.StyleTitleControl, fontSize: value };
      break;
    case 'TitleFontFamily':
      styleConfig.StyleTitleControl = { ...styleConfig.StyleTitleControl, fontFamily: value };
      break;
    case 'TitleFontColor':
      styleConfig.StyleTitleControl = { ...styleConfig.StyleTitleControl, color: value };
      break;
    case 'TitleBold':
      styleConfig.StyleTitleControl = { ...styleConfig.StyleTitleControl, bold: value };
      break;
    case 'TitleItalic':
      styleConfig.StyleTitleControl = { ...styleConfig.StyleTitleControl, italic: value };
      break;
    case 'TitleUnderline':
      styleConfig.StyleTitleControl = { ...styleConfig.StyleTitleControl, underline: value };
      break;
    case 'AddMarkerLineControl':
      if (!styleConfig.MarkersList) {
        styleConfig.MarkersList = [];
      }
      styleConfig.MarkersList.push({
        showLine: true,
        axis: styleConfig?.LayoutControl === 'horizontal' ? 'x' : 'y',
        value: 0,
        lineStyle: {
          stroke: '#000',
          strokeWidth: 2,
          strokeDasharray: '0, 0',
          strokeStyle: 'normal',
        },
        legend: '',
        legendOrientation: 'horizontal',
        legendPosition: 'top',
        textStyle: {
          fill: '#000',
          fontStyle: 'normal',
          fontWeight: 'normal',
          fontSize: 12,
          fontFamily: 'arial',
        },
      });
      break;
    case 'RemoveMarkerLineControl':
      styleConfig.MarkersList.splice(value, 1);
      break;
    case 'MarkerAttrControl':
      if (styleConfig.MarkersList[value.target]) {
        styleConfig.MarkersList[value.target][value.attr] = value.val;
      }
      break;
    case 'MarkerTextStyleControl':
      if (styleConfig.MarkersList[value.target]) {
        styleConfig.MarkersList[value.target].textStyle = {
          ...styleConfig.MarkersList[value.target].textStyle,
          [value.attr]: value.val,
        };
      }
      break;
    case 'MarkerBoldControl':
      if (styleConfig.MarkersList[value.target]) {
        styleConfig.MarkersList[value.target].textStyle = {
          ...styleConfig.MarkersList[value.target].textStyle,
          fontWeight: value.val ? 'bold' : 'normal',
        };
      }
      break;
    case 'MarkerItalicControl':
      if (styleConfig.MarkersList[value.target]) {
        styleConfig.MarkersList[value.target].textStyle = {
          ...styleConfig.MarkersList[value.target].textStyle,
          fontStyle: value.val ? 'italic' : 'normal',
        };
      }
      break;
    case 'MarkerTextColorControl':
      if (styleConfig.MarkersList[value.target]) {
        styleConfig.MarkersList[value.target].textStyle = {
          ...styleConfig.MarkersList[value.target].textStyle,
          fill: value.val,
        };
      }
      break;
    case 'MarkerLineColorControl':
      if (styleConfig.MarkersList[value.target]) {
        styleConfig.MarkersList[value.target].lineStyle = {
          ...styleConfig.MarkersList[value.target].lineStyle,
          stroke: value.val,
        };
      }
      break;
    case 'MarkerLineFormatControl':
      if (styleConfig.MarkersList[value.target]) {
        const setMarkerLineStyle = (markerStyle, size) => {
          switch (markerStyle) {
            case 'dashed':
              return `${size * 2}, ${size}`;
            case 'dotted':
              return `${size}, ${size}`;
            default:
              return '0, 0';
          }
        };
        styleConfig.MarkersList[value.target].lineStyle = {
          ...styleConfig.MarkersList[value.target].lineStyle,
          strokeDasharray: setMarkerLineStyle(
            value.val,
            styleConfig.MarkersList[value.target].lineStyle.strokeWidth,
          ),
          strokeStyle: value.val,
        };
      }
      break;
    case 'MarkerLineSizeControl':
      if (styleConfig.MarkersList[value.target]) {
        const setMarkerLineStyle = (markerStyle, size) => {
          switch (markerStyle) {
            case 'dashed':
              return `${size * 2}, ${size}`;
            case 'dotted':
              return `${size}, ${size}`;
            default:
              return '0, 0';
          }
        };
        styleConfig.MarkersList[value.target].lineStyle = {
          ...styleConfig.MarkersList[value.target].lineStyle,
          strokeDasharray: setMarkerLineStyle(
            styleConfig.MarkersList[value.target].lineStyle.strokeStyle,
            value.val,
          ),
          strokeWidth: value.val,
        };
      }
      break;
    default:
      styleConfig[diff] = value;
      break;
  }
  return styleConfig;
}
