import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import InputTextLine from '../../../../juristec-ui/core/InputTextLine';
import MiniLoading from '../../../../juristec-ui/core/MiniLoading';
import Button from '../../../../juristec-ui/core/Button';

import {
  IconWrapper,
  SmallInputContainer,
  MainContainer,
  Messages,
} from './styled/RegisterPhone.styled';

import { Key } from '../../../../juristec-ui/icons';

const SecondStep = ({
  phoneNumber, inputCodeState, sendSmsCode, captchaFinished,
}) => {
  const cron = useRef();
  const [retryTimer, setRetryTimer] = useState(45);
  const [code, setCode] = inputCodeState;

  /**
   * Handles the input code event.
   * @param {Event} e DOM event.
   */
  const handleCode = (e) => {
    const val = e.target.value.trim();
    if (/^(\s*|\d+)$/.test(val) && val.length <= 6) {
      setCode(e.target.value.trim());
    }
  };

  /**
   * Starts a timer to unlock `retrySms`.
   * @param {number} [addTime=0] Additional seconds to add to the current 45 seconds.
   */
  const startTimer = (addTime = 0) => {
    setRetryTimer(45 + addTime);
    cron.current = setInterval(() => {
      setRetryTimer((t) => t - 1);
    }, 1000);
  };

  /* Removes timer when it reaches 0. */
  useEffect(() => {
    if (retryTimer === 0) clearInterval(cron.current);
  }, [retryTimer]);

  /* Automatically sends a verification sms when rendered. */
  useEffect(() => {
    sendSmsCode();
    return () => {
      clearInterval(cron.current);
    };
  }, []);

  /* Starts timer when captcha is solved. */
  useEffect(() => {
    if (captchaFinished) startTimer();
  }, [captchaFinished]);

  /** Resends another verification sms. */
  const retrySms = () => {
    if (retryTimer === 0) {
      sendSmsCode(true);
      startTimer(15);
    }
  };

  return (
    <MainContainer>
      {captchaFinished ? (
        <>
          <Messages className="info">
            SMS enviado para
            {' '}
            <b>{phoneNumber}</b>
          </Messages>
          <Messages className="info">
            Informe o código que aparece na mensagem para confirmar o seu acesso:
          </Messages>
          <SmallInputContainer>
            <InputTextLine
              label="Código de confirmação"
              value={code}
              onChange={handleCode}
            />
            <IconWrapper>
              <Key />
            </IconWrapper>
          </SmallInputContainer>
          <Button
            onClick={retrySms}
            disabled={retryTimer > 0}
            variant="outlined"
            size="small"
            color="info"
            textTransform="none"
            style={{ width: 'fit-content', margin: 'auto' }}
          >
            Enviar novamente
            {retryTimer > 0 ? ` em ${retryTimer} segundos` : ''}
          </Button>
        </>
      ) : (
        <div style={{ margin: 'auto' }}>
          <MiniLoading fill="primary" />
        </div>
      )}
    </MainContainer>
  );
};

SecondStep.propTypes = {
  /** Phone to be verified */
  phoneNumber: PropTypes.string.isRequired,
  /** Input hook */
  inputCodeState: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.func]),
  ).isRequired,
  /** Function that sends a new SMS to the selected hint */
  sendSmsCode: PropTypes.func.isRequired,
  /** Boolean indicating whether the captcha is resolved */
  captchaFinished: PropTypes.bool,
};

SecondStep.defaultProps = {
  captchaFinished: false,
};

export default SecondStep;
