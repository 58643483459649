const rotationByState = {
  AC: {
    x: 69,
    y: 10,
    zoom: 800,
  },
  AL: {
    x: 35,
    y: 10,
    zoom: 1400,
  },
  AM: {
    x: 62,
    y: 6,
    zoom: 300,
  },
  AP: {
    x: 51,
    y: -1,
    zoom: 800,
  },
  BA: {
    x: 40,
    y: 14,
    zoom: 400,
  },
  CE: {
    x: 39,
    y: 6,
    zoom: 800,
  },
  DF: {
    x: 47,
    y: 16,
    zoom: 1600,
  },
  ES: {
    x: 40,
    y: 20,
    zoom: 1000,
  },
  GO: {
    x: 48,
    y: 17,
    zoom: 600,
  },
  MA: {
    x: 44,
    y: 7,
    zoom: 500,
  },
  MG: {
    x: 43,
    y: 20,
    zoom: 500,
  },
  MS: {
    x: 53,
    y: 22,
    zoom: 500,
  },
  MT: {
    x: 54,
    y: 14,
    zoom: 400,
  },
  PA: {
    x: 52,
    y: 5,
    zoom: 350,
  },
  PB: {
    x: 36,
    y: 7,
    zoom: 1400,
  },
  PE: {
    x: 37,
    y: 9,
    zoom: 1000,
  },
  PI: {
    x: 41,
    y: 8,
    zoom: 500,
  },
  PR: {
    x: 50,
    y: 25,
    zoom: 900,
  },
  RJ: {
    x: 42,
    y: 22.5,
    zoom: 1400,
  },
  RN: {
    x: 36,
    y: 6,
    zoom: 1400,
  },
  RO: {
    x: 61,
    y: 11,
    zoom: 600,
  },
  RR: {
    x: 60,
    y: -1,
    zoom: 500,
  },
  RS: {
    x: 51,
    y: 31,
    zoom: 600,
  },
  SC: {
    x: 50,
    y: 28,
    zoom: 900,
  },
  SE: {
    x: 37,
    y: 11,
    zoom: 1600,
  },
  SP: {
    x: 47,
    y: 23,
    zoom: 600,
  },
  TO: {
    x: 47,
    y: 10,
    zoom: 500,
  },
  Brasil: {
    x: 45,
    y: 18,
    zoom: 100,
  },
  Regioes: {
    x: 45,
    y: 18,
    zoom: 90,
  },
  SUDESTE: {
    x: 40,
    y: 23,
    zoom: 300,
  },
  SUL: {
    x: 50,
    y: 30,
    zoom: 350,
  },
  NORTE: {
    x: 62,
    y: 6,
    zoom: 200,
  },
  NORDESTE: {
    x: 37,
    y: 11,
    zoom: 200,
  },
  CENTROOESTE: {
    x: 53,
    y: 18,
    zoom: 260,
  },
};

const getRotationByState = (field) => rotationByState[field] || rotationByState.Brasil;

export default getRotationByState;
