import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ActionsGroup from '../ActionsGroup';
import Button from '../Button';

import {
  MainContainer,
  StepMarkersContainer,
  StepMarker,
} from './styled/StepProgress.styled';

/**
* A component to guide the user interaction by steps
*/
const StepProgress = ({
  steps,
  startAt,
  hide,
  complete,
  markersWidth,
  removeMarkers,
  disableUntilValid,
  additionalButton,
  onlyForward,
  tourContext = { tourOpen: false },
}) => {
  const [stepIdx, setStepIdx] = useState(startAt);

  const changeStep = (moveStep) => {
    if (moveStep === 1 && steps[stepIdx].validation && !steps[stepIdx].validation()) return;
    if (stepIdx + moveStep < steps.length) setStepIdx((prev) => prev + moveStep);
    else complete();
    // if (tourContext?.tourOpen) tourContext.nextStep();
  };

  return (
    <>
      {removeMarkers || (
        <StepMarkersContainer width={markersWidth}>
          {steps.map((step, i) => (
            <StepMarker
              key={step.label}
              completed={stepIdx > i}
              actual={stepIdx === i}
            >
              <div className="marker-circle">
                <span>{i + 1}</span>
              </div>
              <span>{step.label}</span>
            </StepMarker>
          ))}
        </StepMarkersContainer>
      )}
      <MainContainer>
        {steps[stepIdx]?.content}
      </MainContainer>
      <ActionsGroup>
        {onlyForward || stepIdx === 0 ? (
          <Button
            style={{ margin: '5px' }}
            onClick={hide}
            variant="outlined"
            size="large"
          >
            Cancelar
          </Button>
        ) : (
          <Button
            style={{ margin: '5px' }}
            onClick={() => { changeStep(-1); if (tourContext?.tourOpen) tourContext.nextStep(-1); }}
            variant="outlined"
            size="large"
            className="template_back_button"

          >
            Voltar
          </Button>
        )}
        {additionalButton && additionalButton?.steps?.includes(stepIdx) && (
          <Button
            style={{ margin: '5px' }}
            onClick={additionalButton?.action}
            variant="outlined"
            size="large"
            className="template_additional_button"
          >
            {additionalButton?.label}
          </Button>
        )}
        <Button
          disabled={disableUntilValid && steps[stepIdx].validation ? (
            !steps[stepIdx].validation()
          ) : false}
          style={{ margin: '5px' }}
          onClick={() => { changeStep(1); if (tourContext?.tourOpen) tourContext.nextStep(); }}
          size="large"
          className="template_confirm_button"
        >
          {stepIdx + 1 === steps.length ? 'Concluir' : 'Avançar'}
        </Button>
      </ActionsGroup>
    </>
  );
};

StepProgress.propTypes = {
  /**
  * A array of objects that concentrate all the steps elements and their functionalities
  */
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      content: PropTypes.element.isRequired,
      validation: PropTypes.func,
    }),
  ).isRequired,
  /**
  * A number that indicates the index of the step when the component renders
  */
  startAt: PropTypes.number,
  /**
  * Function to close the modal
  */
  hide: PropTypes.func.isRequired,
  /**
  * A function that executes at the end of all the steps progression
  */
  complete: PropTypes.func.isRequired,
  /**
  * The width the markers will occupy at the top
  */
  markersWidth: PropTypes.oneOfType([PropTypes.oneOf(['small', 'medium', 'large']), PropTypes.string]),
  /**
   * A boolean that, if true, hides the markers
   */
  removeMarkers: PropTypes.bool,
  /**
   * A boolean that, if true, blocks the advance button until the validation is true
   */
  disableUntilValid: PropTypes.bool,
  /**
  * An object with the details for a aditional button
  */
  additionalButton: PropTypes.shape({
    label: PropTypes.string,
    action: PropTypes.func,
    steps: PropTypes.arrayOf(PropTypes.number),
  }),

  /**
   * A boolean that, if true, changes return to cancel
   */
  onlyForward: PropTypes.bool,

  tourContext: PropTypes.shape({
    tourOpen: PropTypes.bool,
    nextStep: PropTypes.func,
  }),
};

StepProgress.defaultProps = {
  startAt: 0,
  tourContext: {
    tourOpen: false,
  },
  markersWidth: '',
  removeMarkers: false,
  disableUntilValid: false,
  additionalButton: null,
  onlyForward: false,
};

export default StepProgress;
