import styled, { keyframes, css } from 'styled-components';
import { gap } from '../../../styles/theme';

const inputHighlighter = keyframes`
  from { background: ${({ theme }) => theme.highlightTextLine}; }
  to { width:0; background:transparent; }
`;

export const WrapperContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  position: relative;
  width: 100%;
  // gap: 10px;
  ${gap('10px', 'row')}
`;

const StyledInputPhoneLine = styled.div`
  position: relative;
  width: 100%;
  padding-top: ${({ label }) => (label === '' ? '0px' : '20px')};
  ${(props) => props.disabled && css`
    background-color: rgba(234,234,234,0.5);
    pointer-events: none;
  `}

  .tel-container {
    font-family: inherit;
  }

  .tel-flagBtn {
    border: 2px solid transparent;
    border-radius: 8px;
    background-color: transparent;
    & .arrow {
      border-top-color: ${({ theme }) => theme.primary};
    }
    &:hover {
      background-color: ${({ theme }) => theme.primary}30;
      & .arrow {
        border-top-color: ${({ theme }) => theme.primary};
      }
    }
    &.open {
      background-color: transparent;
      & .arrow {
        border-bottom-color: ${({ theme }) => theme.primary};
      }
    }
    & .selected-flag {
      background-color: transparent;
      &.open {
        background-color: transparent;
      }
    }
  }

  .tel-Dd {
    margin-top: 2px;
    max-height: 125px;
    border: none;
    scrollbar-width: thin;
    background-color: ${({ theme }) => theme.selectEven};
    box-shadow: 0px 4px 4px ${({ theme }) => theme.shadow};
    & .dial-code {
      color: ${({ theme }) => theme.color} !important;
      font-weight: bold;
    }
    & .country:nth-child(odd) {
      background-color: ${({ theme }) => theme.selectOdd};
    }
    & .country:hover {
      background-color: ${({ theme }) => theme.selectHover};
    }
    & .country.highlight {
      background-color: ${({ theme }) => theme.selectHover};
    }
  }

  & .react-phone-input:focus-within {
    outline: none;
    +.tel-labelTxt {
      color: ${({ theme, error }) => (error ? theme.error : theme.highlightTextLine)};
    };
    ~.tel-bar:before, ~.tel-bar:after {
      width: 50%;
    };
    ~.tel-highlight {
      -webkit-animation: ${inputHighlighter} 0.3s ease;
      -moz-animation: ${inputHighlighter} 0.3s ease;
      animation: ${inputHighlighter} 0s ease;
    };
  };

  .tel-inputTxt {
    color: ${({ theme }) => theme.color};
    box-sizing: border-box;
    font-size: 1rem;
    padding: 10px 0px 8px 55px;
    display: block;
    width: 100%;
    border: none;
    border-bottom: 1px solid ${({ theme }) => theme.inputBottomLine};
    background-color: ${({ theme, notTransparent }) => (notTransparent ? theme.white : 'transparent')};

    &:disabled {
      opacity: 0.5;
      pointer-events: none;
    }; 
  };

  input[type="password"] {
    padding-right: 40px;
  }

  /* LABEL ======================================= */
  .tel-labelTxt {
    position:absolute;
    top: 2px;
    left: 0px;
    font-size:0.85rem;
    color:${({ theme }) => theme.inputLabel};
    font-weight: bold;
  }

  /* ERROR MESSAGE*/
  .tel-errorMessage {
    font-size: 10px;
    color: ${({ theme }) => theme.error};
    position: absolute;
  };

  /* BOTTOM BARS ================================= */
  .tel-bar:before, .tel-bar:after {
    content:'';
    height:2px; 
    width:0;
    bottom: 0px; 
    position:absolute;
    background: ${({ theme, error }) => (error ? theme.error : theme.highlightTextLine)};
    transition:0.2s ease all; 
    -moz-transition:0.2s ease all; 
    -webkit-transition:0.2s ease all;
  };
  
  .tel-bar:before {
    left:50%;
  };

  .tel-bar:after {
    right:50%; 
  };

  /* HIGHLIGHTER ================================== */
  .tel-highlight {
    position:absolute;
    height:60%; 
    width: 100px; 
    top:25%; 
    left:0;
    pointer-events:none;
    opacity:0.5;
  }

  /* active state */
  .tel-inputTxt:focus ~ .tel-highlight {
    -webkit-animation: ${inputHighlighter} 0.3s ease;
    -moz-animation: ${inputHighlighter} 0.3s ease;
    animation: ${inputHighlighter} 0s ease;
  }
`;

export const EmojiPickerContainer = styled.div`
  z-index: 1050;
  box-shadow: 0px 4px 4px ${({ theme }) => theme.shadow};
`;

export default StyledInputPhoneLine;
