import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';
import Popover from '../Popover';
import InputTextLine from '../InputTextLine';
import InputRadio from '../InputRadio';
import Checkbox from '../Checkbox';
import IconButton from '../IconButton';
import { ExpandMore, Finder, Filter } from '../../icons';

import {
  MainContainer,
  SeparatorLine,
  CategoryContainer,
  GroupContainer,
  SearchBarContainer,
  IconWrapper,
  IconFlipWrapper,
} from './styled/SearchFilter.styled';

/**
 * A search bar with an extended filter option
 */
const SearchFilter = ({
  radioLabel,
  radioOptions,
  radioSelected,
  setRadioSelected,
  checkboxLabel,
  checkboxOptions,
  checkboxSelected,
  setCheckboxSelected,
  textSearch,
  setTextSearch,
}) => {
  const [openPopover, setOpenPopover] = useState(false);

  const theme = useTheme();

  const handleCheckBox = (check, name) => {
    setCheckboxSelected((p) => {
      const aux = p;
      if (!check) delete aux[name];
      else aux[name] = true;
      return aux;
    });
  };

  return (
    <Popover
      style={{
        borderRadius: '5px',
        backgroundColor: theme.popoverBackground,
        overflow: 'hidden',
      }}
      offset={[0, 2]}
      open={openPopover}
      stayOpened
      closePopover={() => setOpenPopover(false)}
    >
      <Popover.Action>
        <SearchBarContainer>
          <IconWrapper>
            <Finder />
          </IconWrapper>
          <InputTextLine
            placeholder="Filtre ou pesquise"
            value={textSearch}
            onChange={(e) => setTextSearch(e.target.value)}
            style={{ color: theme.color }}
          />
          <IconButton shape="rounded" onClick={() => setOpenPopover(!openPopover)} style={{ padding: 0 }}>
            <Filter />
            <IconFlipWrapper flip={openPopover}>
              <ExpandMore />
            </IconFlipWrapper>
          </IconButton>
        </SearchBarContainer>
      </Popover.Action>
      <Popover.Content direction="bottom-end">
        <MainContainer>
          {radioOptions.length > 0 && (
            <CategoryContainer>
              <span>{radioLabel}</span>
              <GroupContainer>
                {radioOptions.map((r) => (
                  <InputRadio
                    key={r.value}
                    value={r.value}
                    text={r.label}
                    color="primary"
                    selected={radioSelected}
                    onChange={setRadioSelected}
                  />
                ))}
              </GroupContainer>
            </CategoryContainer>
          )}
          {Object.keys(checkboxOptions).length > 0 && (
            <>
              {radioOptions.length > 0 && <SeparatorLine />}
              <CategoryContainer>
                <span>{checkboxLabel}</span>
                <GroupContainer>
                  {Object.keys(checkboxOptions).map((c) => (
                    <Checkbox
                      key={c}
                      text={c}
                      color="primary"
                      variant="outlined"
                      checked={checkboxSelected[checkboxOptions[c]]}
                      handleCheckboxChange={(check) => handleCheckBox(check, checkboxOptions[c])}
                    />
                  ))}
                </GroupContainer>
              </CategoryContainer>
            </>
          )}
        </MainContainer>
      </Popover.Content>
    </Popover>
  );
};

SearchFilter.propTypes = {
  /**
   * Label for the radio filter
   */
  radioLabel: PropTypes.string,
  /**
   * Array with the options for the radio filter
   */
  radioOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ),
  /**
   * String with the selected radio filter value
   */
  radioSelected: PropTypes.string,
  /**
   * Function that deals with changing the value of the radio filter
   */
  setRadioSelected: PropTypes.func.isRequired,
  /**
   * Label for the checkbox filter
   */
  checkboxLabel: PropTypes.string,
  /**
   * Object with the options for the checkbox filter
   */
  checkboxOptions: PropTypes.shape({}),
  /**
   * A Set collection with the names of the selected checkboxes (iniciate with 'new Set()')
   */
  checkboxSelected: PropTypes.objectOf(PropTypes.bool),
  /**
   * Function that deals with the changes of the checkboxes
   */
  setCheckboxSelected: PropTypes.func.isRequired,
  /**
   * String input of the search bar
   */
  textSearch: PropTypes.string,
  /**
   * Function that deals with the changes on the search bar
   */
  setTextSearch: PropTypes.func.isRequired,
};

SearchFilter.defaultProps = {
  radioLabel: '',
  radioOptions: [],
  radioSelected: '',
  checkboxLabel: '',
  checkboxOptions: {},
  checkboxSelected: {},
  textSearch: '',
};

export default SearchFilter;
