export default {
  TitleControl: '',
  ShowTitleControl: { checked: false },
  SortValuesControl: 'Não ordenado',
  EnableAxisX: {
    axisTop: false,
    axisBot: true,
    tickSize: 5,
    tickPadding: 5,
    tickRotation: 0,
    legend: '',
    legendPosition: 'middle',
    legendOffset: 35,
    separadorNumerico: true,
    maxLabelLines: 1,
  },
  EnableAxisY: {
    axisLeft: true,
    axisRight: false,
    tickSize: 5,
    tickPadding: 5,
    tickRotation: 0,
    legend: '',
    legendPosition: 'middle',
    legendOffset: -55,
    separadorNumerico: true,
  },
  AxisGlobal: {
    fontSize: 12,
    fontFamily: 'arial',
    legendFontSize: 12,
    color: '#222',
  },
  ColorTheme: { scheme: 'category10' },
  Animate: true,
  StyleTitleControl: {
    fontSize: 20,
    color: '#000000',
    fontFamily: 'arial',
    bold: false,
    italic: false,
    underline: false,
  },
  type: 'Line',
};
