import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';

import IconButton from '../../../juristec-ui/core/IconButton';
import Button from '../../../juristec-ui/core/Button';
import Tooltip from '../../../juristec-ui/core/Tooltip';
import List from '../../../juristec-ui/core/List';
import ListItem from '../../../juristec-ui/core/ListItem';
import Popover from '../../../juristec-ui/core/Popover';
import PopoverContainer from '../../../juristec-ui/core/PopoverContainer';
import GlobalFiltersToolbar from '../GlobalFiltersToolbar';
import ManageStory2 from '../../Modals/ManageStory';
import { ModalContext } from '../../../context/ModalProvider';
import { AlertContext } from '../../../context/AlertProvider';
import DashStylesModal from '../../Modals/DashStylesModal';
import {
  ArrowLeft,
  Add,
  Chat,
  Printer,
  Edit,
  ExpandMore,
  AddKpi,
  LightBulb,
  AddStory,
  Share,
  TemplateUse,
  Brush,
  Computer,
  Tablet,
  Mobile,
  Visibility,
  VisibilityOff,
  Report,
  MoreVert,
  Fullscreen,
  Filter,
  Export,
  Chain,
  Columns,
} from '../../../juristec-ui/icons';
import { formatDateTime } from '../../../juristec-ui/utils/functions/lab';

import {
  StyledGridKpisToolbar,
  LeftGridKpisToolbar,
  RightGridKpisToolbar,
  GridKpisToolbarActions,
  DashboardInformation,
  OwnerInformation,
  StyledBadgEdit,
  StyledSelectStory,
} from './styled/GridKpisToolbar.styled';

import { TourContext } from '../../../context/TourProvider';

const getSpecialKpiPresence = (kpiItemList) => (
  kpiItemList.reduce((aux, kItem) => {
    if (kItem.type === 'SubsetTable') aux.add(kItem.type);
    else if (kItem.linkType === 'plotly') aux.add(kItem.linkType);
    return aux;
  }, new Set())
);

/**
* The GridKpis Toolbar contains the main information of the Dashboard Page.
*  It also contains accesses to lots of action, as adding and editing the kpi's, for example.
*/
const GridKpisToolbar = ({
  gridKpiState,
  editPainel,
  setEditPainel,
  goBack,
  openMural,
  handleEditSnapshot,
  handleDeleteSnapshot,
  handleCardStyles,
  changeCurrentSnap,
  handleCreateSnapshot,
  handleScheduleSnapshot,
  openShare,
  printFunction,
  isOwner,
  isAdmin,
  isGuest,
  redirectToInsightCreation,
  redirectToKpiCreation,
  openSaveAsTemplate,
  gridBreakpoint,
  showToolbar,
  handleShowToolbar,
  getDependencies,
  handleFullScreen,
  handleExportDashboard,
  globalFilters,
  globalFiltersOrder,
  globalFilterSelectors,
  setGlobalFilterSelectors,
  getColumnValues,
  filterColumnsModal,
  fileDependencies,
  handleIframe,
  handleSubsetTable,
}) => {
  const [openPopoverStories, setOpenPopoverStories] = useState(false);
  const [openPopoverEdit, setOpenPopoverEdit] = useState(false);
  const [openPopoverAdd, setOpenPopoverAdd] = useState(false);
  const [openPopoverMoreOptions, setOpenPopoverMoreOptions] = useState(false);
  const [toggleGlobalFilters, setToggleGlobalFilters] = useState(false);

  const { dashboardDoc, collection, kpiItemList } = gridKpiState;
  const { setModalConfig, closeModal } = useContext(ModalContext);
  const { setAlertConfig } = useContext(AlertContext);
  const { tourOpen, nextStep, refreshTour } = useContext(TourContext);
  if (tourOpen) refreshTour();
  const openEditPainel = (layoutType) => {
    setEditPainel(layoutType);
  };

  const closeEditPainel = () => {
    setEditPainel(false);
  };

  const openCardStylesEdit = () => {
    setModalConfig({
      title: 'Estilo dos Cards',
      children: (
        <DashStylesModal
          stylesData={dashboardDoc.cardStyles}
          submit={handleCardStyles}
          close={closeModal}
        />
      ),
    });
  };
  // Função para edição de snapshot
  const openEditSnapshot = (snap) => {
    setModalConfig({
      title: 'Edição de Story',
      children: (
        <ManageStory2
          snapshot={snap}
          closeModal={closeModal}
          submitStoryName={handleEditSnapshot}
          deleteStory={handleDeleteSnapshot}
          hasSpecial={getSpecialKpiPresence(kpiItemList)}
        />
      ),
    });
  };

  // Função para adicionar um novo snapshot
  const openAddSnapshot = () => {
    if (kpiItemList.length === 0) {
      setAlertConfig({
        type: 'error',
        text: 'Seu Dashboard não possui nenhum KPI!',
        child: 'Antes de incluir um novo story, você deve criar algum KPI para o seu dashboard!',
      });
      return;
    }
    setModalConfig({
      title: 'Adicionar Story',
      className: 'modal_story',
      children: (
        <ManageStory2
          snapshot={{ name: '', snapId: '' }}
          closeModal={closeModal}
          submitStoryName={handleCreateSnapshot}
          hasSpecial={getSpecialKpiPresence(kpiItemList)}
          scheduleSnapshot={handleScheduleSnapshot}
        />
      ),
    });
  };

  const handlePrint = () => {
    printFunction();
  };

  // Story Item
  const storyItem = (item) => {
    if (typeof item === 'object' && item !== null) {
      return (
        <ListItem
          key={item.snapId}
          style={{ flexDirection: 'column', alignItems: 'center', paddingRight: '35px' }}
          onClick={() => changeCurrentSnap(item.snapId)}
          disabled={item.snapId === collection}
        >
          <span
            style={{
              display: 'inline-block',
              fontWeight: 'bold',
              fontSize: '0.9rem',
              textAlign: 'center',
            }}
          >
            {item.name}
          </span>
          <span style={{ fontSize: '0.85rem' }}>{formatDateTime(item.snapId, { time: 'half' })}</span>
          <StyledBadgEdit
            onClick={(e) => {
              e.stopPropagation();
              openEditSnapshot(item);
            }}
          >
            <Edit />
          </StyledBadgEdit>
        </ListItem>
      );
    }
    return null;
  };

  const addInsight = () => {
    redirectToInsightCreation();
  };

  const addKpi = () => {
    if (tourOpen) nextStep();
    redirectToKpiCreation();
  };

  const addIframe = () => {
    handleIframe();
  };

  const addSubsetTable = () => {
    handleSubsetTable();
  };

  const handleGlobalFilters = () => {
    if (Object.keys(globalFilterSelectors).length > 0) setToggleGlobalFilters(true);
    else if (isOwner) {
      filterColumnsModal(setToggleGlobalFilters);
    }
  };

  const hasAppliedFilters = () => (
    !!globalFilters?.current && (
      Object.values(globalFilters.current).filter((f) => Object.values(f).join())
    ).length > 0
  );

  return (
    <StyledGridKpisToolbar show={showToolbar}>
      {toggleGlobalFilters ? (
        <>
          <GlobalFiltersToolbar
            toggleToolbar={setToggleGlobalFilters}
            globalFilters={globalFilters}
            globalFiltersOrder={globalFiltersOrder}
            globalFilterSelectors={globalFilterSelectors}
            setGlobalFilterSelectors={setGlobalFilterSelectors}
            getColumnValues={getColumnValues}
            filterColumnsModal={filterColumnsModal}
            isOwner={isOwner}
          />
        </>
      ) : (
        <>
          <LeftGridKpisToolbar>
            <DashboardInformation>
              <Tooltip text="Voltar para dashboards" direction="right">
                <IconButton variant="contained" color="secondary" size="medium" shape="rounded" onClick={goBack}>
                  <ArrowLeft />
                </IconButton>
              </Tooltip>
              <span className="dashGroup">
                {dashboardDoc?.group?.label || 'Sem Grupo'}
              </span>
              <span className="dashGroup">
                {' '}
                /
                {' '}
              </span>
              <span className="dashName">
                {dashboardDoc?.name || ''}
              </span>
            </DashboardInformation>
            <OwnerInformation show={showToolbar}>
              <div>
                <span className="info-title">Criado por:</span>
                <span className="info-description">
                  {' '}
                  {dashboardDoc?.displayName}
                </span>
              </div>
              <div>
                <span className="info-title">Última atualização:</span>
                <span className="info-description">
                  {' '}
                  {(dashboardDoc?.lastUpdateUser || dashboardDoc?.created_at)
                    ? formatDateTime(dashboardDoc?.lastUpdateUser || dashboardDoc?.created_at, { time: 'half' })
                    : '' }
                </span>
              </div>
            </OwnerInformation>
          </LeftGridKpisToolbar>
          <RightGridKpisToolbar show={showToolbar}>
            {/* Utilizando dummy information para renderizar o select de stories */}
            {!editPainel
              ? (
                <>
                  <GridKpisToolbarActions show={showToolbar}>
                    {(fileDependencies?.length > 0 && collection === 'kpis' && (
                      isOwner || Object.keys(globalFilterSelectors).length > 0
                    )) && (
                      <Tooltip text="Filtro Global" direction="left">
                        <IconButton
                          variant="pattern"
                          color={hasAppliedFilters() ? 'secondary' : 'primary'}
                          size="medium"
                          shape="rounded"
                          onClick={handleGlobalFilters}
                        >
                          <Filter />
                        </IconButton>
                      </Tooltip>
                    )}
                    {isAdmin && (
                      <Tooltip text={kpiItemList.length ? 'Salvar como Template' : "Não é possível criar template sem Kpi's"} direction="left">
                        <IconButton variant="pattern" color="primary" size="medium" shape="rounded" onClick={openSaveAsTemplate} disabled={!kpiItemList.length}>
                          <TemplateUse />
                        </IconButton>
                      </Tooltip>
                    )}

                    {isOwner && (
                      <Tooltip text="Compartilhar" direction="left">
                        <IconButton variant="pattern" color="primary" size="medium" shape="rounded" onClick={openShare}>
                          <Share />
                        </IconButton>
                      </Tooltip>
                    )}
                    {isOwner && collection === 'kpis' && (
                      <Popover
                        open={openPopoverEdit}
                        closePopover={() => setOpenPopoverEdit(false)}
                      >
                        <Popover.Action>
                          <Tooltip text="Editar posição dos KPI's" direction="left">
                            <IconButton
                              variant="pattern"
                              color="primary"
                              size="medium"
                              shape="rounded"
                              onClick={() => setOpenPopoverEdit(!openPopoverEdit)}
                            >
                              <Edit />
                            </IconButton>
                          </Tooltip>
                        </Popover.Action>
                        <Popover.Content direction="bottom-end">
                          <List>
                            {gridBreakpoint !== 'sm' && (
                              <ListItem onClick={() => openEditPainel('desktop')}>
                                <Computer />
                                Layout Computador
                              </ListItem>
                            )}
                            <ListItem onClick={() => openEditPainel('tablet')}>
                              <Tablet />
                              Layout Tablet
                            </ListItem>
                            <ListItem onClick={() => openEditPainel('mobile')}>
                              <Mobile />
                              Layout Celular
                            </ListItem>
                          </List>
                        </Popover.Content>
                      </Popover>
                    )}
                    {/* --------------- EDIÇÃO  ----------------  */}

                    <Tooltip text="Mural" direction="left">
                      <IconButton variant="pattern" color="primary" size="medium" shape="rounded" onClick={openMural}>
                        <Chat />
                      </IconButton>
                    </Tooltip>

                    <Tooltip text="Diminuir Toolbar" direction="left">
                      <IconButton variant="pattern" color="primary" size="medium" shape="rounded" onClick={handleShowToolbar}>
                        <VisibilityOff />
                      </IconButton>
                    </Tooltip>

                    <Popover
                      open={openPopoverMoreOptions}
                      closePopover={() => setOpenPopoverMoreOptions(false)}
                    >
                      <Popover.Action>
                        <Tooltip text="Mais opções" direction="left">
                          <IconButton
                            variant="pattern"
                            color="primary"
                            size="medium"
                            shape="rounded"
                            onClick={() => setOpenPopoverMoreOptions(!openPopoverMoreOptions)}
                          >
                            <MoreVert />
                          </IconButton>
                        </Tooltip>
                      </Popover.Action>
                      <Popover.Content direction="bottom-end">
                        <List>
                          {isOwner && (
                            <ListItem onClick={handleExportDashboard}>
                              <Export />
                              Exportar Dashboard
                            </ListItem>
                          )}
                          {!isGuest && (
                            <ListItem onClick={getDependencies}>
                              <Report />
                              Dependências
                            </ListItem>
                          )}
                          {isOwner && collection === 'kpis' && (
                            <ListItem onClick={openCardStylesEdit}>
                              <Brush />
                              Estilo dos cards
                            </ListItem>
                          )}
                          <ListItem onClick={handlePrint}>
                            <Printer />
                            Impressão
                          </ListItem>
                          <ListItem onClick={handleFullScreen}>
                            <Fullscreen />
                            Tela Cheia
                          </ListItem>
                        </List>
                      </Popover.Content>
                    </Popover>

                    {/* --------------- ADD  ----------------  */}
                    {isOwner && collection === 'kpis' && (
                      <Popover open={openPopoverAdd} closePopover={() => setOpenPopoverAdd(false)}>
                        <Popover.Action>
                          <Tooltip text="Adicionar" direction="left">
                            <IconButton
                              variant="contained"
                              color="secondary"
                              size="medium"
                              shape="rounded"
                              onClick={() => {
                                setOpenPopoverAdd(!openPopoverAdd);
                                if (tourOpen) nextStep();
                              }}
                              disabled={!isOwner}
                              className="createKpi_first"
                            >
                              <Add />
                            </IconButton>
                          </Tooltip>
                        </Popover.Action>
                        <Popover.Content direction="bottom-end">
                          <List>
                            <ListItem onClick={addKpi} className="newKpi_btn">
                              <AddKpi />
                              Adicionar KPI
                            </ListItem>
                            <ListItem onClick={addInsight}>
                              <LightBulb />
                              Adicionar Insight
                            </ListItem>
                            <ListItem onClick={addIframe} className="newIframe_btn">
                              <Chain />
                              Adicionar Outros Conteúdos
                            </ListItem>
                            <ListItem onClick={addSubsetTable} className="newSubsetTable_btn">
                              <Columns />
                              Adicionar Subtabela
                            </ListItem>
                            <ListItem onClick={openAddSnapshot} className="newStory_btn">
                              <AddStory />
                              Adicionar Story
                            </ListItem>
                          </List>
                        </Popover.Content>
                      </Popover>
                    )}
                  </GridKpisToolbarActions>
                  <div className="gap-fix">
                    {!showToolbar && (
                      <Tooltip text="Aumentar Toolbar" direction="left">
                        <IconButton variant="pattern" color="primary" size="medium" shape="rounded" onClick={handleShowToolbar}>
                          <Visibility />
                        </IconButton>
                      </Tooltip>
                    )}
                    {dashboardDoc?.snapshots?.length > 0 && (
                      <PopoverContainer>
                        <Popover
                          open={openPopoverStories}
                          closePopover={() => setOpenPopoverStories(false)}
                        >
                          <Popover.Action>
                            <StyledSelectStory
                              open={openPopoverStories}
                              onClick={() => setOpenPopoverStories(!openPopoverStories)}
                            >
                              <span>
                                {(!collection || collection === 'kpis')
                                  ? 'Selecione o story'
                                  : dashboardDoc?.snapshots.reduce((acc, cur) => {
                                    let aux = acc;
                                    if (cur.snapId === collection) { aux = cur.name; }
                                    return aux;
                                  }, '')}
                              </span>
                              <ExpandMore />
                            </StyledSelectStory>
                          </Popover.Action>
                          <Popover.Content direction="bottom-end">
                            <List style={{ maxHeight: 300, overflow: 'auto' }}>
                              <ListItem style={{ flexDirection: 'column', alignItems: 'center' }} onClick={() => changeCurrentSnap('kpis')}>Atual</ListItem>
                              {dashboardDoc?.snapshots.map((item) => storyItem(item))}
                            </List>
                          </Popover.Content>
                        </Popover>
                      </PopoverContainer>
                    )}
                  </div>
                  {/* --------------- ADD  ----------------  */}
                </>
              )
              : (
                <Button variant="contained" color="secondary" onClick={closeEditPainel}>
                  Salvar
                </Button>
              )}
          </RightGridKpisToolbar>
        </>
      )}
    </StyledGridKpisToolbar>
  );
};

GridKpisToolbar.propTypes = {
  gridKpiState: PropTypes.shape({
    dashboardDoc: PropTypes.shape({
      name: PropTypes.string,
      group: PropTypes.shape({
        label: PropTypes.string,
      }),
      cardStyles: PropTypes.shape({}),
      displayName: PropTypes.string,
      lastUpdateUser: PropTypes.shape({}),
      created_at: PropTypes.shape({}),
      snapshots: PropTypes.arrayOf(
        PropTypes.shape({}),
      ),
    }),
    collection: PropTypes.string.isRequired,
    kpiItemList: PropTypes.arrayOf(
      PropTypes.shape({
        clonned_at: PropTypes.shape({}),
        color: PropTypes.string,
        createdAt: PropTypes.shape({}),
        data: PropTypes.shape({}),
        database: PropTypes.string,
        id: PropTypes.string,
        meta: PropTypes.shape({}),
        name: PropTypes.string,
        status: PropTypes.string,
        style: PropTypes.shape({}),
        titlePortion: PropTypes.string,
        titlePosition: PropTypes.string,
        type: PropTypes.string,
        updatedAt: PropTypes.shape({}),
      }),
    ).isRequired,
  }),
  editPainel: PropTypes.bool,
  setEditPainel: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired,
  openMural: PropTypes.func.isRequired,
  handleEditSnapshot: PropTypes.func.isRequired,
  handleDeleteSnapshot: PropTypes.func.isRequired,
  handleScheduleSnapshot: PropTypes.func.isRequired,
  handleCardStyles: PropTypes.func.isRequired,
  changeCurrentSnap: PropTypes.func.isRequired,
  handleCreateSnapshot: PropTypes.func.isRequired,
  openShare: PropTypes.func.isRequired,
  printFunction: PropTypes.func.isRequired,
  isOwner: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool,
  isGuest: PropTypes.bool,
  redirectToInsightCreation: PropTypes.func.isRequired,
  redirectToKpiCreation: PropTypes.func.isRequired,
  openSaveAsTemplate: PropTypes.func.isRequired,
  gridBreakpoint: PropTypes.string.isRequired,
  showToolbar: PropTypes.bool.isRequired,
  handleShowToolbar: PropTypes.func.isRequired,
  getDependencies: PropTypes.func.isRequired,
  handleFullScreen: PropTypes.func,
  handleExportDashboard: PropTypes.func.isRequired,
  //
  globalFilters: PropTypes.shape({
    current: PropTypes.objectOf(PropTypes.shape({})),
  }).isRequired,
  globalFiltersOrder: PropTypes.shape({
    current: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  globalFilterSelectors: PropTypes.objectOf(PropTypes.shape({})),
  setGlobalFilterSelectors: PropTypes.func.isRequired,
  getColumnValues: PropTypes.func.isRequired,
  filterColumnsModal: PropTypes.func.isRequired,
  fileDependencies: PropTypes.arrayOf(PropTypes.shape({})),
  handleIframe: PropTypes.func,
  handleSubsetTable: PropTypes.func,
};

GridKpisToolbar.defaultProps = {
  gridKpiState: {
    dashboardDoc: {},
  },
  editPainel: false,
  isAdmin: false,
  isGuest: false,
  handleFullScreen: () => {},
  globalFilterSelectors: {},
  fileDependencies: [],
  handleIframe: () => {},
  handleSubsetTable: () => {},
};

export default GridKpisToolbar;
