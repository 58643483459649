import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';
import DataTable from '../../../../juristec-ui/core/DataTable';
import InputRadio from '../../../../juristec-ui/core/InputRadio';

import formatValue from '../../../../utils/functions/formatTableValue';

import {
  MainContainer, TableWrapper, LegendsContainer, FilterWrapper, ErrorMsg,
} from './styled/SecondStep.styled';

const getDfTableColumns = (df, theme) => {
  const getTypeColor = (item) => {
    switch (df.types[item]) {
      case 'int64':
        return theme.typeNumber;
      case 'float64':
        return theme.typeNumber;
      case 'datetime64[ns]':
        return theme.typeDate;
      default:
        return theme.typeText;
    }
  };
  return df.columns.reduce((aux, column) => {
    if (column === 'index') return aux;
    aux.push({
      field: column,
      label: column,
      dataColor: getTypeColor(column),
      sortable: true,
      valueGetter: (p) => formatValue(p[column], df.types[column]),
    });
    return aux;
  }, []);
};

const getDfTableRows = (df) => (
  df.data.map((dataList) => (
    dataList.reduce((rowObj, data, index) => {
      // eslint-disable-next-line no-param-reassign
      rowObj[df.columns[index]] = data;
      return rowObj;
    }, {})
  ))
);

const SecondStep = ({
  custom, newCColumn, genPreview, previewCheck,
}) => {
  const theme = useTheme();
  const dfColumns = useRef([]);
  const [filteredDfColumns, setFilteredDfColumns] = useState([]);
  const [dfRows, setDfRows] = useState([]);
  const [filter, setFilter] = useState('ALL');

  useEffect(() => {
    const getPreview = async () => {
      const info = await genPreview([
        ...custom.reduce((aux, c) => {
          if (c.name === newCColumn.name) return aux;
          aux.push({
            ...c,
            inputs: c.inputs?.map((inp) => inp.value),
            operator: c.operator === 'REMOVE' ? 'REMOVE' : c.operator?.value ?? '',
          });
          return aux;
        }, []),
        {
          ...newCColumn,
          inputs: newCColumn.inputs?.map((inp) => inp.value),
          operator: newCColumn.operator?.value ?? '',
        },
        // ...custom.filter((c) => c.name !== newCColumn.name),
        // newCColumn,
      ]);
      if (info) {
        dfColumns.current = getDfTableColumns(info, theme);
        setFilteredDfColumns(dfColumns.current);
        setDfRows(getDfTableRows(info));
        previewCheck(true);
      } else {
        previewCheck(false);
      }
    };
    if (newCColumn && Array.isArray(custom)) {
      // console.log(newCColumn, custom);
      getPreview();
    }
  }, []);

  const handleFilter = (value) => {
    setFilter(value);
    if (value === 'NEW') {
      const colsNames = custom.map((col) => col.name);
      colsNames.push(newCColumn.name);
      setFilteredDfColumns((cols) => (
        cols.filter((col) => colsNames.includes(col.field))
      ));
    } else {
      setFilteredDfColumns(dfColumns.current);
    }
  };

  return (
    <MainContainer>
      {filteredDfColumns.length > 0 ? (
        <>
          <FilterWrapper>
            <InputRadio
              value="ALL"
              text="Todas as colunas"
              color="primary"
              variant="outlined"
              selected={filter}
              onChange={handleFilter}
              style={{ margin: '0' }}
            />
            <InputRadio
              value="NEW"
              text="Apenas as novas"
              color="primary"
              variant="outlined"
              selected={filter}
              onChange={handleFilter}
              style={{ margin: '0' }}
            />
          </FilterWrapper>
          <TableWrapper>
            <DataTable
              columns={filteredDfColumns}
              rowData={dfRows}
              headerColor="transparent"
              theadStyle={{
                position: 'sticky',
                top: '0',
                zIndex: 1,
                backgroundColor: theme.modal,
              }}
              defaultSortField="index"
              indexAsHeader
            />
          </TableWrapper>
          <LegendsContainer>
            <div>
              <div className="legend-texto" />
              <span>Texto</span>
            </div>
            <div>
              <div className="legend-numero" />
              <span>Número</span>
            </div>
            <div>
              <div className="legend-date" />
              <span>Data</span>
            </div>
          </LegendsContainer>
        </>
      ) : (
        <ErrorMsg>
          Erro ao gerar a prévia
        </ErrorMsg>
      )}
    </MainContainer>
  );
};

SecondStep.propTypes = {
  custom: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    operator: PropTypes.oneOfType([
      PropTypes.shape({
        value: PropTypes.string,
      }),
      PropTypes.string,
    ]),
    inputs: PropTypes.arrayOf(PropTypes.shape({})),
  })),
  newCColumn: PropTypes.shape({
    name: PropTypes.string,
    operator: PropTypes.oneOfType([
      PropTypes.shape({
        value: PropTypes.string,
      }),
      PropTypes.string,
    ]),
    inputs: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  genPreview: PropTypes.func.isRequired,
  previewCheck: PropTypes.func.isRequired,
};

SecondStep.defaultProps = {
  custom: [],
  newCColumn: {},
};

export default SecondStep;
