import styled from 'styled-components';

const AppliedCard = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.controllersLabel};
  border-radius: 8px;
  overflow: clip;
  padding: .1rem .4rem .2rem .4rem;
  margin: .2rem 0;
  & h5 {
    color: ${({ theme }) => theme.color};
    margin: 0 auto 0 0;
    font-size: 16px;
  }
  & > span {
    text-transform: capitalize;
    color: ${({ theme }) => theme.grey};
    font-size: 14px;
  }
`;

export default AppliedCard;
