import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Add, ArrowLeft, Finder } from '../../../juristec-ui/icons';
import IconButton from '../../../juristec-ui/core/IconButton';
import Tooltip from '../../../juristec-ui/core/Tooltip';
import InputTextLine from '../../../juristec-ui/core/InputTextLine';

import {
  MainContainer, NavOpt, ItensContainer, InputWrapper,
} from './styled/InstanceToolbar.styled';

/**
 * A component to manage instance options
 */
const InstanceToolbar = ({
  goBack, tab, setTab, addFunc, currentUser, setInputFilter,
}) => {
  const cronRef = useRef();

  const handleFilter = (e) => {
    const { value } = e.target;
    clearTimeout(cronRef.current);
    cronRef.current = setTimeout(() => {
      setInputFilter(value);
    }, 200);
  };

  return (
    <MainContainer>
      <ItensContainer className="left">
        <Tooltip text="Voltar" direction="right">
          <IconButton variant="contained" color="secondary" size="medium" shape="rounded" onClick={goBack}>
            <ArrowLeft />
          </IconButton>
        </Tooltip>
        <NavOpt active={tab === 'users'} onClick={() => setTab('users')}>
          Gerenciar Usuários
        </NavOpt>
        {currentUser?.role === 'super' && (
          <NavOpt active={tab === 'instance'} onClick={() => setTab('instance')}>
            Gerenciar Instância
          </NavOpt>
        )}
      </ItensContainer>
      {tab === 'users' && (
        <ItensContainer className="right">
          <InputWrapper>
            <InputTextLine
              placeholder="Buscar usuário"
              onChange={handleFilter}
              // style={{ color: theme.managementSearchFontColor }}
            />
            <Finder />
          </InputWrapper>
          <Tooltip text="Adicionar Usuário">
            <IconButton onClick={addFunc} shape="rounded" color="secondary" variant="contained" className="add_user_button">
              <Add />
            </IconButton>
          </Tooltip>
        </ItensContainer>
      )}
    </MainContainer>
  );
};

InstanceToolbar.propTypes = {
  /**
   * A function to return the browser history back
   */
  goBack: PropTypes.func.isRequired,
  /**
   * A string that indicates what option of the toolbar has been selected
   */
  tab: PropTypes.oneOf(['users', 'instance'], PropTypes.string),
  /**
   * A function that toggles toolbar options
   */
  setTab: PropTypes.func.isRequired,
  /**
   * A function to add users to a instance
   */
  addFunc: PropTypes.func.isRequired,
  /**
   * Object with the current user data
   */
  currentUser: PropTypes.objectOf(PropTypes.any).isRequired,
  /**
   * 
   */
  setInputFilter: PropTypes.func.isRequired
};

InstanceToolbar.defaultProps = {
  tab: 'users',
};

export default InstanceToolbar;
