import styled, { css } from 'styled-components';
import { Responsive, WidthProvider } from 'react-grid-layout';
import { devices } from '../../../juristec-ui/core/breakpoints';

const ResponsiveReactGridLayout = WidthProvider(Responsive);

const getDeviceLayoutSizes = (layoutType) => {
  switch (layoutType) {
    case 'tablet':
      return css`
        margin-left: auto;
        margin-right: auto;
        width: 80%;
      `;
    case 'mobile':
      return css`
        margin-left: auto;
        margin-right: auto;
        width: 50%;
      `;
    default:
      return css`
        margin-left: 5%;
        margin-right: 5%;
      `;
  }
};

const StyledOuterGridKpiLayout = styled.main`
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: auto;
  padding: 5px;
  padding-bottom: 65px;
  margin-top: 0rem;
  box-sizing: border-box;
  ${(props) => getDeviceLayoutSizes(props.layoutType)}
  height: ${({ isFullScreen, showToolbar }) => (isFullScreen ? '100vh' : `calc(100vh - ${showToolbar ? 166 : 108}px)`)};
  @media ${devices.tablet} {
    margin: 0px 0px;
    width: 100%;
    height: calc(100vh - ${({ showToolbar }) => (showToolbar ? 170 : 112)}px);
  }

  @media ${devices.phoneS} {
    margin: 0px 0px;
    width: 100%;
    height: calc(100vh - 112px);
  } ;
`;

/**
 * Função que irá retornar css dinamico dependendo se esta em modo edicao ou nao do painel
 * @param {boolean} editPainel
 * @returns css code
 */
const effectGridKpiCard = (editPainel) => {
  if (editPainel) {
    return css`
      box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14),
        0px 1px 8px 0px rgba(0, 0, 0, 0.12);
      cursor: move;
      cursor: grab;
      cursor: -moz-grab;
      cursor: -webkit-grab;

      &:active {
        cursor: grabbing;
        cursor: -moz-grabbing;
        cursor: -webkit-grabbing;
      }
      & .iframeComponent {
        pointer-events: none;
      }
    `;
  }
  return '';
};

const StyledResponsiveReactGridKpiLayout = styled(ResponsiveReactGridLayout)`
  & > div {
    &:hover {
      ${(props) => effectGridKpiCard(props.editPainel)}
    }

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0px;
      bottom: 0px;
      left: 0px;
      right: 0px;
      border: ${(props) => (props.editPainel ? 'dashed 2px #a9a9a9' : 'solid 2px transparent')};
    }

    padding: 0px;
    margin: 0px;
    position: relative;
    overflow: hidden;
  }
  & .react-resizable-handle-s {
    cursor: ns-resize !important;
  }
  & .react-resizable-handle-e {
    cursor: ew-resize !important;
  }
`;

const StyledGridKpiCard = styled.div`
  height: 100%;
`;

const StyledPopover2 = styled.div`
  visibility: ${(props) => (props.isOpen ? 'visible' : 'hidden')};
  position: absolute;
  right: 100%;
  top: 0%;
`;

const ExitFullScreen = styled.div`
  position: fixed;
  top: 50px;
  left: 50px;
`;

export {
  StyledResponsiveReactGridKpiLayout,
  StyledOuterGridKpiLayout,
  StyledGridKpiCard,
  StyledPopover2,
  ExitFullScreen,
};
