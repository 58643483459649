/* eslint-disable no-param-reassign */
import React, {
  useContext, useEffect, useState, useRef,
} from 'react';
import { useHistory } from 'react-router-dom';

import { AuthContext } from '../../context/AuthProvider';
import { TemplateContext } from '../../context/TemplateProvider';
import { FilesContext } from '../../context/FilesProvider';
import { AlertContext } from '../../context/AlertProvider';
import { ModalContext } from '../../context/ModalProvider';
import { TourContext } from '../../context/TourProvider';
import useGridKpi from '../../hooks/useGridKpi';

import GridTemplates from '../../components/GridTemplates';
import TemplatesToolbar from '../../components/Toolbars/TemplatesToolbar';
import TemplatePreview from '../../components/TemplatePreview';
import TemplatePreviewToolbar from '../../components/Toolbars/TemplatePreviewToolbar';
import ApplyTemplate from '../../components/ProgressiveModals/ApplyTemplate';
import Loader from '../../juristec-ui/core/Loader';
import CreateTemplate from '../../components/ProgressiveModals/CreateTemplate';
import DashStylesModal from '../../components/Modals/DashStylesModal';

import { verifyFileImgType, verifyFileSize } from '../../juristec-ui/utils/validators/fileValidators';

const difficultyMap = {
  easy: 'Iniciante',
  medium: 'Intermediário',
  advanced: 'Avançado',
  expert: 'Expert',
  get(k) {
    return this[k] || this.easy;
  },
};

const TemplatesPage = () => {
  const history = useHistory();
  const cronRef = useRef();

  const { userCompany, claimsUser } = useContext(AuthContext);
  const { state: templateState, templateAPI } = useContext(TemplateContext);
  const { state: filesState, filesAPI } = useContext(FilesContext);
  const { setModalConfig, closeModal } = useContext(ModalContext);
  const { setAlertConfig } = useContext(AlertContext);
  const { tourOpen, nextStep } = useContext(TourContext);
  const [gridKpiState, gridKpiAPI] = useGridKpi();
  const [tempDashDoc, setTempDashDoc] = useState(null);

  const [filteredTemplates, setFilteredTemplates] = useState([]);
  const [inputFilter, setInputFilter] = useState('');
  const [templatePreview, setTemplatePreview] = useState(null);
  const [templateCategories, setTemplateCategories] = useState({});
  const [radioSelected, setRadioSelected] = useState('all');
  const [checkboxSelected, setCheckboxSelected] = useState({ run: false });

  const handleFilter = (value) => {
    clearTimeout(cronRef.current);
    cronRef.current = setTimeout(() => {
      setInputFilter(value);
    }, 200);
  };

  useEffect(() => {
    if (!filesState.started && !filesState.isLoading) {
      (async () => {
        await filesAPI.init();
      })();
    }
  }, [filesState.started, filesAPI, filesState.isLoading]);

  const loadTemplates = async () => {
    const result = await templateAPI.getTemplates();
    if (result.error) {
      setAlertConfig({
        type: 'error',
        msg: result.msg,
        child: result.raw,
      });
    }
  };

  useEffect(() => {
    if (!templateState.templates.length > 0) {
      loadTemplates();
    }
  }, []);

  useEffect(() => {
    if (templateState.templates.length > 0) {
      setTemplateCategories(templateState.templates.reduce((aux, tc) => {
        if (tc.category) aux[tc.category] = tc.category;
        return aux;
      }, {}));
    }
  }, [templateState?.templates]);

  useEffect(() => {
    if (templateState.templates.length > 0) {
      const text = inputFilter.trim().toLowerCase();
      setFilteredTemplates(
        templateState.templates.filter((tmp) => (
          ( // categories
            Object.keys(checkboxSelected).length === 1 || !!checkboxSelected[tmp.category]
          ) && ( // difficult
            radioSelected === 'all' || radioSelected.includes(tmp.difficulty)
          ) && ( // text
            text.length <= 0
            || tmp.name?.toLowerCase().includes(text)
            || tmp.category?.toLowerCase().includes(text)
            || difficultyMap.get(tmp.difficulty).toLowerCase().includes(text)
            || tmp.keywords?.toString().toLowerCase().includes(text)
            || Object.keys(tmp.columnsRequired || []).toString().toLowerCase().includes(text)
          )
        )),
      );
    }
  }, [templateState?.templates, inputFilter, radioSelected, checkboxSelected?.run]);

  const handleShowPreview = async (template) => {
    if (!template.id) return;
    setTemplatePreview(template);
    if (template.id !== tempDashDoc?.id) {
      const res = await gridKpiAPI.getTemplateKpis(template.id);
      if (res.error) {
        setAlertConfig({
          type: 'error',
          msg: res.msg,
          child: res.raw,
        });
        setTemplatePreview(null);
        return;
      }
      setTempDashDoc(res.dashDoc);
    }
  };

  const hidePreview = () => setTemplatePreview(null);

  const handleCheckbox = (res) => {
    setCheckboxSelected((o) => ({ ...res(o), run: !o.run }));
  };

  const handleEditTemplate = async (selectedTemplate) => {
    const submitEditTemplate = async (template) => {
      const result = await gridKpiAPI.editTemplate(selectedTemplate.id, template);
      if (result.error) {
        setAlertConfig({
          type: 'error',
          text: result.msg,
          child: `${result.raw}`,
        });
        return;
      }

      setAlertConfig({
        type: 'success',
        text: 'Template editado com sucesso!',
      });
      await loadTemplates();
      closeModal('edit_template_modal');
    };

    // Function to check if the image satisfies the specified conditions
    const checkFileIsImg = (file) => {
      const errorMsg = verifyFileImgType(file) || verifyFileSize(file, 1000000);
      if (errorMsg.length > 0) {
        setAlertConfig({ type: 'error', text: errorMsg });
        return false;
      }
      return true;
    };

    const tpCategories = Object.keys(templateCategories).map((c) => (
      { label: c, value: c, id: `category-${c}` }
    ));

    setModalConfig({
      nodeTarget: 'edit_template_modal',
      title: 'Editar Template',
      children: (
        <CreateTemplate
          hide={() => closeModal('edit_template_modal')}
          submitData={submitEditTemplate}
          editTemplateData={selectedTemplate}
          templateVars={selectedTemplate.columnsRequired}
          categories={tpCategories}
          checkImage={checkFileIsImg}
        />
      ),
    });
  };

  const handleDeleteTemplate = async (selectedTemplate) => {
    const submitDeleteTemplate = async () => {
      const result = await gridKpiAPI.deleteTemplate(selectedTemplate.id);
      if (result.error) {
        setAlertConfig({
          type: 'error',
          text: result.msg,
          child: `${result.raw}`,
        });
        return;
      }

      setAlertConfig({
        type: 'success',
        text: 'Template removido com sucesso!',
      });
      await loadTemplates();
    };

    setAlertConfig({
      type: 'warning',
      child: 'Deseja mesmo remover esse template?',
      text: 'Tem certeza?',
      withFunction: true,
      withoutConfirm: true,
      confirmFunction: submitDeleteTemplate,
    });
  };

  const handleApplyTemplate = async (template) => {
    let dashDoc = tempDashDoc ?? {};
    if (template.id !== dashDoc.id) {
      const resKpis = await gridKpiAPI.getTemplateKpis(template.id);
      if (resKpis.error) {
        setAlertConfig({
          type: 'error',
          msg: resKpis.msg,
          child: resKpis.raw,
        });
        return;
      }
      setTempDashDoc(resKpis.dashDoc);
      dashDoc = resKpis.dashDoc;
    }
    if (filesState.size + 10 > userCompany?.volumeData) {
      setAlertConfig({
        type: 'error',
        text: 'Não há memória suficiente!',
        child: 'A sua instância já atingiu o limite máximo de memória disponível!',
      });
      return;
    }

    if (filesState.dashboards >= userCompany?.dashboards) {
      setAlertConfig({
        type: 'error',
        text: 'Número máximo de dashboards atingido!',
        child: `O número máximo de dashboards do seu plano (${userCompany?.dashboards} dashboards) foi atingido!`,
      });
      return;
    }

    if (tourOpen) nextStep();

    const loadFileColumns = async (fileId) => {
      const colRes = await filesAPI.getColumns(fileId);
      if (colRes.error) {
        setAlertConfig({
          type: 'error',
          text: colRes.msg,
        });
        return [];
      }
      return (Object.keys(colRes.columns).reduce((aux, col) => {
        if (['abc', 'float', 'date'].includes(colRes.columns[col])) {
          aux[colRes.columns[col]].push(col);
        }
        return aux;
      }, { abc: [], float: [], date: [] }));
    };

    const handleColumnChange = async (fileId, column, format) => {
      // Recebendo valores unicos
      const { error: columnError, msg: columnMsg, res: columnRes } = await filesAPI.getUnique(
        fileId,
        column,
        format || '',
      );
      if (columnError) {
        setAlertConfig({ type: 'error', text: columnMsg });
      }
      return columnRes.map((val) => ({ label: val, value: val, id: val }));
    };

    const submitNewTemplate = async ({
      file, name, bindings, filters, cardStyles,
    }) => {
      if (tourOpen) nextStep();
      const { error: createError, msg: createMsg } = await templateAPI.applyTemplate(
        template.id,
        name,
        file,
        bindings,
        filters,
        {},
        cardStyles,
      );

      if (createError) {
        setAlertConfig({
          type: 'error',
          text: createMsg,
        });
        closeModal('template_modal');
        return;
      }

      setAlertConfig({
        type: 'success',
        text: 'Template aplicado com sucesso',
        okFunction: () => {
          closeModal('template_modal');
          history.push('/home');
        },
      });
    };

    const hide = () => { closeModal('template_modal'); };

    setModalConfig({
      nodeTarget: 'template_modal',
      title: 'Aplicar Template',
      className: 'template_modal',
      children: (
        <ApplyTemplate
          files={filesState.files || []}
          template={template}
          hide={hide}
          loadFile={loadFileColumns}
          onSelectFilter={handleColumnChange}
          submitData={submitNewTemplate}
          tourContext={{ tourOpen, nextStep }}
          stylesData={dashDoc?.cardStyles}
        />
      ),
    });
  };

  const openCardStyles = () => {
    const hide = () => { closeModal('card_style_modal'); };
    const handleCardStyles = (newStyles) => {
      hide();
      setTempDashDoc((t) => ({
        ...t,
        cardStyles: newStyles,
      }));
      // console.log('new', newStyles);
    };

    setModalConfig({
      nodeTarget: 'card_style_modal',
      title: 'Estilo dos Cards',
      className: 'card_style_modal',
      children: (
        <DashStylesModal
          stylesData={tempDashDoc?.cardStyles}
          submit={handleCardStyles}
          close={hide}
        />
      ),
    });
  };

  return (
    <>
      {(filesState.isLoading || templateState.isLoading) && <Loader />}
      {templatePreview ? (
        <>
          <TemplatePreviewToolbar
            goBack={hidePreview}
            template={templatePreview || {}}
            applyTemplate={handleApplyTemplate}
            openCardStyles={openCardStyles}
          />
          <TemplatePreview
            dashState={gridKpiState}
            dashDoc={tempDashDoc}
          />
        </>
      ) : (
        <>
          <TemplatesToolbar
            goBack={history.goBack}
            setInputFilter={handleFilter}
            categories={templateCategories}
            radioSelected={radioSelected}
            setRadioSelected={setRadioSelected}
            checkboxSelected={checkboxSelected}
            setCheckboxSelected={handleCheckbox}
          />
          <GridTemplates
            templates={filteredTemplates || []}
            applyTemplate={handleApplyTemplate}
            showPreview={handleShowPreview}
            editTemplate={claimsUser?.is_admin ? handleEditTemplate : null}
            deleteTemplate={claimsUser?.is_admin ? handleDeleteTemplate : null}
          />
        </>
      )}
    </>
  );
};

export default TemplatesPage;
