export default {
  // TITLE
  TitleControl: '',
  ShowTitleControl: { checked: true },
  StyleTitleControl: {
    fontSize: 20,
    color: '#000000',
    fontFamily: 'arial',
    bold: false,
    italic: false,
    underline: false,
  },
  // GENERAL
  // STYLE
  GlobalValue: {
    fontFamily: 'arial',
    fontSize: 20,
    bold: false,
    italic: false,
    underline: false,
    color: '#00F',
    background: '#ddd',
  },
  DescriptionControl: '',
  PrefixControl: '',
  SufixControl: '',
  Decimals: 'auto',
  separadorNumericoCasas: '0',
  LabelCurrencyControl: 'Nenhum',
  GroupingSelectControl: '',
  UseGlobalColor: true,
};
