const getCardStyle = ({ backgroundColor, borderStyle, shading }) => ({
  backgroundColor: backgroundColor || '#ffffff',
  border: borderStyle
    ? `${borderStyle.size}px ${borderStyle.color} ${borderStyle.line}`
    : '1px #9a9a9a solid',
  borderRadius: `${Number.isNaN(+borderStyle?.rounded) ? '4' : borderStyle.rounded}px`,
  boxShadow: shading ? '0px 4px 4px #686868A6' : '',
  boxSizing: 'border-box',
});

export default getCardStyle;
