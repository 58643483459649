import React from 'react';
import PropTypes from 'prop-types';

const Jira = ({ height, width }) => (
  <svg width={width} height={height} viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.64723 20.5235H6.96024C2.90737 20.5235 0 18.0412 0 14.4061H14.4496C15.198 14.4061 15.6823 14.9388 15.6823 15.692V30.2313C12.0702 30.2313 9.64723 27.3057 9.64723 23.2274V20.5235ZM16.7843 13.2981H14.0961C10.0444 13.2981 7.13703 10.8605 7.13703 7.22543H21.5855C22.3338 7.22543 22.863 7.71221 22.863 8.4666V23.0059C19.2509 23.0059 16.7843 20.0803 16.7843 16.002V13.2981ZM23.9649 6.11746H21.2767C17.2238 6.11746 14.3164 3.63512 14.3164 0H28.7661C29.5144 0 30 0.531584 30 1.24117V15.7804C26.3879 15.7804 23.9649 12.8549 23.9649 8.77659V6.11746Z" fill="#357DE8" />
  </svg>

);

Jira.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
};

Jira.defaultProps = {
  height: '25px',
  width: '25px',
};

export default Jira;
