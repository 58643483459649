import React from 'react';

const Columns = () => (
  <svg width="24" height="24" viewBox="0 0 667 667" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 33.3333V633.333C0 642.174 3.51189 650.652 9.76311 656.904C16.0143 663.155 24.4928 666.667 33.3333 666.667H183.333V0H33.3333C24.4928 0 16.0143 3.51189 9.76311 9.76311C3.51189 16.0143 0 24.4928 0 33.3333Z" />
    <path d="M416.667 0H250V666.667H416.667V0Z" />
    <path d="M633.333 0H483.333V666.667H633.333C642.174 666.667 650.652 663.155 656.904 656.904C663.155 650.652 666.667 642.174 666.667 633.333V33.3333C666.667 24.4928 663.155 16.0143 656.904 9.76311C650.652 3.51189 642.174 0 633.333 0Z" />
  </svg>
);

export default Columns;
