import React from 'react';
import PropTypes from 'prop-types';

import ControllersGroup from '../../../controllers/ControllersGroup';
import {
  SelectController,
  RangeController,
  ChoiceBoxController,
  CustomSortController,
} from '../../../controllers/Base';

const GeneralControllers = ({
  handle,
  config,
  data,
  extend,
  setExtend,
}) => (
  <>
    <ControllersGroup
      title="Opções Gerais"
      id="general"
      extend={extend}
      setExtend={setExtend}
      sizeIncrease={config?.SortValuesControl === 'Customizado' ? data?.index?.length : 0}
    >
      <SelectController
        label="Ordenar campos"
        handle={handle}
        kWord="SortValuesControl"
        options={[
          { label: 'Automático', value: 'Não ordenado', id: 'nao-ordenado' },
          { label: 'Alfabética A-Z', value: 'Alfabética A-Z', id: 'alfabetica-a-z' },
          { label: 'Alfabética Z-A', value: 'Alfabética Z-A', id: 'alfabetica-z-a' },
          { label: 'Valor Crescente', value: 'Valor Crescente', id: 'valor-crescente' },
          { label: 'Valor Decrescente', value: 'Valor Decrescente', id: 'valor-decrescente' },
          { label: 'Customizado', value: 'Customizado', id: 'customizado' },
        ]}
        configValue={config?.SortValuesControl}
        fullWidth
      />
      {config?.SortValuesControl === 'Customizado' && (
        <CustomSortController
          handle={handle}
          kWord="CustomSortControlUpt"
          oldOrder={data?.index || []}
          newOrder={config?.CustomSortControl?.kpiCategories || []}
          removeTotal
        />
      )}
      <RangeController
        label="Raio interno"
        handle={handle}
        kWord="InnerRadiusControl"
        configValue={config?.InnerRadiusControl?.value}
        min={0}
        max={0.9}
        step={0.1}
        precision={1}
        fullWidth
      />
      <RangeController
        label="Espaço entre fatias"
        handle={handle}
        kWord="PaddingAngleControl"
        configValue={config?.PaddingAngleControl?.value}
        min={0}
        max={45}
        step={1}
        precision={0}
        fullWidth
      />
      <RangeController
        label="Borda arredondada"
        handle={handle}
        kWord="CornerRadiusControl"
        configValue={config?.CornerRadiusControl?.top}
        min={0}
        max={45}
        step={1}
        precision={0}
        fullWidth
      />
      <RangeController
        label="Margem Superior"
        handle={handle}
        kWord="MarginTop"
        configValue={config?.Margin?.top}
        min={0}
        max={500}
        step={1}
        precision={0}
        fullWidth
      />
      <RangeController
        label="Margem Inferior"
        handle={handle}
        kWord="MarginBottom"
        configValue={config?.Margin?.bottom}
        min={0}
        max={500}
        step={1}
        precision={0}
        fullWidth
      />
      <RangeController
        label="Margem Esquerda"
        handle={handle}
        kWord="MarginLeft"
        configValue={config?.Margin?.left}
        min={0}
        max={500}
        step={1}
        precision={0}
        fullWidth
      />
      <RangeController
        label="Margem Direita"
        handle={handle}
        kWord="MarginRight"
        configValue={config?.Margin?.right}
        min={0}
        max={500}
        step={1}
        precision={0}
        fullWidth
      />
      <ChoiceBoxController
        label="Formato dos dados"
        handle={handle}
        kWord="DataFormatType"
        configValue={config?.DataFormat?.type}
        options={[
          { value: 'absolute', label: 'Absoluto', id: 'absolute' },
          { value: 'percentual', label: 'Percentual', id: 'percentual' },
        ]}
        fullWidth
      />
    </ControllersGroup>
  </>
);

GeneralControllers.propTypes = {
  config: PropTypes.objectOf(PropTypes.any),
  data: PropTypes.objectOf(PropTypes.any),
  handle: PropTypes.func,
  extend: PropTypes.string,
  setExtend: PropTypes.func,
};

GeneralControllers.defaultProps = {
  config: undefined,
  data: undefined,
  handle: () => {},
  extend: '',
  setExtend: () => {},
};

export default GeneralControllers;
