import React from 'react';

function ExpandMoreWide() {
  return (
    <svg width="60" height="18" viewBox="0 0 60 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 8V0L30 10L60 0V8L30 18L0 8Z" />
    </svg>
  );
}

export default ExpandMoreWide;
