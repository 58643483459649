import styled, { keyframes } from 'styled-components';
import { devices } from '../../breakpoints';
import square1 from '../img/Square1.png';
import square2 from '../img/Square2.png';
import square3 from '../img/Square3.png';

const calcSizeContainer = (size) => (size ? `calc(${size}px * 10)` : '200px');
const calcSizeSquare = (size) => (size ? `calc(((${size}px * 10) / 2) - 10px)` : '90px');
const calcSizeAnime = (size) => (size ? (size * 10) / 2 : 100);

function anime1(size) {
  return keyframes`
    0% {transform: translate(0, 0)} 
    5% {transform: translate(0, 0)} 
    10% {transform: translate(0, ${size}px)} //<<<<<<<<<<<<<<<<<<<<<<<<<< 2
    20% {transform: translate(0, ${size}px)}  
    25% {transform: translate(${size}px, ${size}px)}  //<<<<<<<<<<<<<<<<<<<<<<<<<< 5<<<<<<<<<<<<<<<<<<<<<<<<<<<<
    40% {transform: translate(${size}px, ${size}px)} 
    45% {transform: translate(${size}px, 0)}  //<<<<<<<<<<<<<<<<<<<<<<<<<< 9
    60% {transform: translate(${size}px, 0)}
    65% {transform: translate(0, 0)}  //<<<<<<<<<<<<<<<<<<<<<<<<<< 13
    100% {transform: translate(0, 0)} 
  `;
}

function anime2(size) {
  return keyframes`
    0%  {transform: translate(0, 0)} 
    10% {transform: translate(0, 0)} 
    15% {transform: translate(-${size}px, 0)}  //<<<<<<<<<<<<<<<<<<<<<<<<<< 3
    30% {transform: translate(-${size}px, 0)} 
    35% {transform: translate(-${size}px, ${size}px)}  //<<<<<<<<<<<<<<<<<<<<<<<<<< 7
    45% {transform: translate(-${size}px, ${size}px)} 
    50% {transform: translate(0, ${size}px)}  //<<<<<<<<<<<<<<<<<<<<<<<<<< 10<<<<<<<<<<<<<<<<<<
    65% {transform: translate(0, ${size}px)}
    70% {transform: translate(0, 0)} //<<<<<<<<<<<<<<<<<<<<<<<<<< 14
    100% {transform: translate(0, 0)}
  `;
}

function anime3(size) {
  return keyframes`
    0% {transform: translate(0, 0)} 
    15% {transform: translate(0, 0)} 
    20% {transform: translate(0, -${size}px)}  //<<<<<<<<<<<<<<<<<<<<<<<<<< 4
    35% {transform: translate(0, -${size}px)} 
    40% {transform: translate(-${size}px, -${size}px)}  //<<<<<<<<<<<<<<<<<<<<<<<<<< 8
    55% {transform: translate(-${size}px, -${size}px)}  
    60% {transform: translate(-${size}px, 0)}  //<<<<<<<<<<<<<<<<<<<<<<<<<< 12
    70% {transform: translate(-${size}px, 0)}
    75% {transform: translate(0, 0)}
    100% {transform: translate(0, 0)} 
  `;
}

function anime4(size) {
  return keyframes`
    0%   {transform: translate(0, 0)} 
    5%   {transform: translate(-${size}px, 0)} //<<<<<<<<<<<<<<<<<<<<<<<<<< 1
    20%  {transform: translate(-${size}px, 0)} 
    25%  {transform: translate(0, 0)} 
    30%  {transform: translate(-${size}px, 0)} //<<<<<<<<<<<<<<<<<<<<<<<<<< 6
    45%  {transform: translate(-${size}px, 0)} 
    50%  {transform: translate(0, 0)} 
    55%  {transform: translate(-${size}px, 0)} //<<<<<<<<<<<<<<<<<<<<<<<<<< 11
    70%  {transform: translate(-${size}px, 0)} 
    75%  {transform: translate(0, 0)}
    85%  {transform: rotate(90deg)}
    95%  {transform: rotate(0deg)}
    100% {transform: translate(0, 0)}
  `;
}

const positions = {
  relative: 'relative',
  fixed: 'fixed',
  getPosition(position) {
    return this[position] || this.fixed;
  },
};

export const StyledSizeContainerSquare = styled.div`
  position: ${({ position }) => positions.getPosition(position)};
  width: ${({ size }) => calcSizeContainer(size)};
  height: ${({ size }) => calcSizeContainer(size)};
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;

  @media ${devices.phoneS} {
    width: ${calcSizeContainer(12)};
    height: ${calcSizeContainer(12)};
  }

  & div { 
    width: ${({ size }) => calcSizeSquare(size)};
    height: ${({ size }) => calcSizeSquare(size)};
    position: absolute;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 4px;
    margin: 5px;

    @media ${devices.phoneS} {
      width: ${calcSizeSquare(12)};
      height: ${calcSizeSquare(12)};
    }
  }

  & div:nth-child(1) {
    background-image: url(${square1});
    background-color: #4711B2;
    top: 0;
    left: 0;
    z-index: 1;
    animation: ${(props) => anime1(calcSizeAnime(props.size))} 3.6s cubic-bezier(.6,.01,.4,1) infinite;

    @media ${devices.phoneS} {
      animation: ${anime1(calcSizeAnime(12))} 3.6s cubic-bezier(.6,.01,.4,1) infinite;
    }
  }
  & div:nth-child(2) {
    background-image: url(${square2});
    background-color: #4711B2;
    top: 0;
    right: 0;
    animation: ${(props) => anime2(calcSizeAnime(props.size))} 3.6s cubic-bezier(.6,.01,.4,1) infinite;

    @media ${devices.phoneS} {
      animation: ${anime2(calcSizeAnime(12))} 3.6s cubic-bezier(.6,.01,.4,1) infinite;
    }
  }
  & div:nth-child(3) {
    background-image: url(${square3});
    background-color: #4711B2;
    bottom: 0;
    right: 0;
    z-index: 1;
    animation: ${(props) => anime3(calcSizeAnime(props.size))} 3.6s cubic-bezier(.6,.01,.4,1) infinite;

    @media ${devices.phoneS} {
      animation: ${anime3(calcSizeAnime(12))} 3.6s cubic-bezier(.6,.01,.4,1) infinite;
    }
  }
  & div:nth-child(4) {
    background-color: #FF9626;
    bottom: 0;
    left: 0;
    animation: ${(props) => anime4(calcSizeAnime(props.size))} 3.6s cubic-bezier(.6,.01,.4,1) infinite;

    @media ${devices.phoneS} {
      animation: ${anime4(calcSizeAnime(12))} 3.6s cubic-bezier(.6,.01,.4,1) infinite;
    }
  }
`;

export const StyledContainerLoader = styled.div`
  position: ${(props) => positions.getPosition(props.position)};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: ${({ theme }) => theme.fadedBackground};
  z-index: 1100;
`;

export const StyledContainerText = styled.span`
  color: ${({ theme }) => theme.white};
  font-size: 1.3rem;
  margin-top: 265px;
  margin-right: 30px;
  margin-left: 30px;
  text-align: center;
`;
