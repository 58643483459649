import styled from 'styled-components';
import { devices } from '../../../../juristec-ui/core/breakpoints';

export const Container = styled.div`
  background: ${({ theme }) => theme.headerBackground};
  display: flex;
  padding: 0 5%;
  height: 56px;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;

  @media ${devices.tablet} {
    padding: 0 20px;
  }

  @media ${devices.phoneS} {
    height: 56px;
  }
`;

export const CompanyLogoOverlay = styled.div`
  display: none;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  justify-content: left;
  background-color: #00000080;
  padding: 0 25%;
`;

export const HeaderAvatar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 120px;
  height: 40px;
  background: ${({ theme }) => theme.primary}22;
  border-radius: 28px;
  position: relative;
  overflow: hidden;
  & > button { 
      cursor: default !important;
  }

  &:hover ${CompanyLogoOverlay} {
    display: flex;
  }

  & button {
    z-index: 1;
  }

  & div:hover + div {
    display: none !important;
  }

  img {
    margin: 0 auto;
    max-height: 38px;
    max-width: 68px;
  }

  span {
    position: absolute;
    bottom: -5px;
    right: -5px;
  }

  @media ${devices.phoneS} {
    width: 120px;
    height: 40px;
    border-radius: 20px;

    img {
      max-height: 38px;
      max-width: 68px;
    }

    span {
      bottom: -5px;
      right: -8px;
    }
  } ;
`;
